import HomeAssetDetails from '@taaleri/components/src/screens/home/HomeAssetDetails'
import HomeEmpty from '@taaleri/components/src/screens/home/HomeEmpty'
import HomeProgress from '@taaleri/components/src/screens/home/HomeProgress'
import RecommendationsBox from '@taaleri/components/src/screens/home/RecommendationsBox'
import { portfolioChange } from '@taaleri/components/src/screens/home/portfolioChange'
import PortfolioChartContainer from '@taaleri/components/src/screens/portfolio/PortfolioChartContainer'
import PortfolioValueBox from '@taaleri/components/src/screens/portfolio/PortfolioValueBox'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import Spacer from '@taaleri/components/src/ui/Spacer'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { dayTimeText } from '@taaleri/core/src/utils/dayTimeText'
import { observer } from 'mobx-react'
import useQueryParams from 'navigation/useQueryParams'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import styled from 'styled-components'
import GradientContainer from 'ui/GradientContainer'

import FrontModals from './FrontModals'
import HomeFeed from './HomeFeed'

const WelcomeTexts = styled.div`
  text-align: center;
`

const Grid = styled.div`
  display: grid;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`

function HomeScreen() {
  const portfolio = AppStore.portfolio
  const yieldHistory = AppStore.yieldHistory
  const { isSmall } = useLayout()
  const { paymentResult } = useQueryParams()

  const { t } = useTranslation()

  useEffect(() => {
    if (paymentResult && paymentResult === 'true') {
      AppStore.showPaymentReceivedModal = true
      AppStore.updateFeedCache = true
    }
  }, [])

  if (!portfolio || !yieldHistory) {
    return <LoadingIndicator />
  }

  if (AppStore.showEmptyHomeScreen) {
    return (
      <>
        <HomeEmpty />
        <FrontModals />
      </>
    )
  }

  const { customerFirstName, isAdmin } = AppStore
  const hideGreeting = !customerFirstName || isAdmin
  const dayTimeGreeting = dayTimeText(new Date())
  const { date } = portfolioChange(t)
  return (
    <GradientContainer>
      <>
        {hideGreeting || (
          <WelcomeTexts style={{ marginBottom: 50 }}>
            <TextDefault
              type="h1"
              style={{
                marginBottom: 4,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {`${t(`app.greeting.${dayTimeGreeting}`)}, ${customerFirstName}`}
            </TextDefault>
            <TextDefault>{t('app.lastvisit', { date })}</TextDefault>
          </WelcomeTexts>
        )}
        <Grid>
          <View>
            <View>
              <PortfolioValueBox
                value={portfolio.marketValue}
                loading={AppStore.loading}
                style={{ flex: 1, marginBottom: Spacings.S24 }}
              />
              {isSmall && (
                <HomeProgress style={{ marginBottom: Spacings.S24 }} />
              )}
              <PortfolioChartContainer
                yieldHistory={yieldHistory}
                portfolio={portfolio}
              ></PortfolioChartContainer>
            </View>
            {isSmall ? (
              <>
                <Spacer />
                <RecommendationsBox />
              </>
            ) : (
              <Spacer />
            )}

            <HomeAssetDetails />
          </View>

          <View>
            {!isSmall && (
              <>
                <HomeProgress style={{ marginBottom: Spacings.S24 }} />
                <RecommendationsBox />
              </>
            )}

            <HomeFeed />
          </View>
        </Grid>
        <FrontModals />
      </>
    </GradientContainer>
  )
}

export default observer(HomeScreen)
