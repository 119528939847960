import { getConfig } from '@taaleri/core/src/config'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Identity } from '@taaleri/core/src/models/Identity'
import {
  AccountStatement,
  ArchiveMap,
  ArchiveResponseObject,
  BANK_STATEMENT,
  CONTENT_OF_PORTFOLIO,
  PreserverAsset,
  ReportArchiveFolder,
  REPORT_ARCHIVE_TYPE,
  toReportArchive,
  toReportArchiveFromAccountStatements,
  updateReportArchive,
  YEARLY_BANK_STATEMENT,
  YearStatement,
  AKTIA_PORTFOLIO,
  AktiaCustomerPortfolio,
} from '@taaleri/core/src/models/ReportArchive'
import { reportsArchive } from '@taaleri/core/src/services/reports'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView, Image } from 'react-native'

import { BottomView, SectionDivider, TopView } from './BoxSections'
import MonthsBox from './MonthsBox'
import { PreserverBox } from './PreserverBox'
import ReportListItem from './ReportListItem'
import YearsBox from './YearsBox'
import { TabButton } from '../../components/TabBox'
import { isWeb } from '../../constants/Platforms'
import { BorderedContainer } from '../../ui/BorderedContainer'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { FlexContainer } from '../../ui/FlexContainer'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { ButtonSecondaryWithChildren } from '../../ui/UiButton'
import Box, { BoxWithPaddingMargin } from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import { AktiaCustomerBox } from './AktiaCustomerBox'

export function toReportLink(filename: string, token: string) {
  return `${
    getConfig().apiUrl
  }/Document/archive/file.pdf?token=${token}&path=${encodeURIComponent(
    filename
  )}`
}

function ReportArchiveContent() {
  const { t } = useTranslation()
  const [year, setYear] = useState<string>()
  const [token, setToken] = useState<string>('')
  const { isSmall } = useLayout()

  const [reportArchive, setReportArchive] = useState<ArchiveMap>({})
  const [fetchError, setFetchError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const getPublicationServiceItems = (
    archiveResponse: ArchiveResponseObject[]
  ) => {
    const accountStatementsResponse = archiveResponse.filter((archiveItem) => {
      if (archiveItem.reportId === BANK_STATEMENT) return archiveItem
    }) as AccountStatement[]

    const preserverResponse = archiveResponse.filter((archiveItem) => {
      if (
        archiveItem.reportId === CONTENT_OF_PORTFOLIO ||
        archiveItem.reportId === YEARLY_BANK_STATEMENT
      )
        return archiveItem
    }) as (PreserverAsset | YearStatement)[]

    const aktiaCustomerResponse = archiveResponse.filter((archiveItem) => {
      if (archiveItem.reportId === AKTIA_PORTFOLIO) return archiveItem
    }) as AktiaCustomerPortfolio[]

    return {
      accountStatementsResponse,
      preserverResponse,
      aktiaCustomerResponse,
    }
  }

  async function findReportArchive() {
    setFetchError(false)
    setLoading(true)
    try {
      const reports = await reportsArchive(AppStore.customerId)
      if (reports.response) {
        const archiveResponse = reports.response

        let folder: ReportArchiveFolder | undefined

        if (archiveResponse && archiveResponse.length > 0) {
          folder = archiveResponse.find(
            (archiveItem) => archiveItem.type === REPORT_ARCHIVE_TYPE
          ) as ReportArchiveFolder
        }

        const {
          accountStatementsResponse,
          preserverResponse,
          aktiaCustomerResponse,
        } = getPublicationServiceItems(archiveResponse)

        if (folder) {
          const response = toReportArchive(folder, t)

          if (
            accountStatementsResponse.length > 0 ||
            preserverResponse.length > 0 ||
            aktiaCustomerResponse.length > 0
          ) {
            const updatedArchiveMap = updateReportArchive(
              response,
              accountStatementsResponse,
              preserverResponse,
              aktiaCustomerResponse
            )
            setReportArchive(updatedArchiveMap)
          } else {
            setReportArchive(response)
          }
        } else {
          if (
            accountStatementsResponse.length > 0 ||
            preserverResponse.length > 0 ||
            aktiaCustomerResponse.length > 0
          ) {
            const dummyReportArchive = toReportArchiveFromAccountStatements(
              accountStatementsResponse,
              preserverResponse,
              aktiaCustomerResponse
            )
            setReportArchive(dummyReportArchive)
          }
        }
      }
      const identity: Identity = await getIdentity()
      setToken(identity.accessToken)
    } catch (error) {
      console.log(error)
      setFetchError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (reportArchive && Object.keys(reportArchive).length > 0) {
      setYear(Object.keys(reportArchive).reverse()[0])
    }
  }, [reportArchive])

  useEffect(() => {
    findReportArchive()
  }, [AppStore.customerId])

  if (fetchError) {
    return <ErrorWithRetry onRetry={findReportArchive} />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const {
    months,
    otherTax,
    otherInsurance,
    otherOwnProperty,
    otherKupara,
    years,
    preserver,
  } = reportArchive[Number(year)] || {}

  if (Object.keys(reportArchive ?? {}).length === 0) {
    return (
      <BoxWithPaddingMargin>
        <TextDefault type="h6" style={{ alignContent: 'center' }}>
          {t('reportArchive.no-reports')}
        </TextDefault>
      </BoxWithPaddingMargin>
    )
  }

  const onlyPreserverItems = () => {
    return (preserver?.filter(
      (report) =>
        report.reportId === CONTENT_OF_PORTFOLIO ||
        report.reportId === YEARLY_BANK_STATEMENT
    ) ?? []) as (PreserverAsset | YearStatement)[]
  }

  const onlyAktiaItems = () => {
    return (preserver?.filter(
      (report) => report.reportId === AKTIA_PORTFOLIO
    ) ?? []) as AktiaCustomerPortfolio[]
  }

  return (
    <>
      <ScrollView
        style={[
          {
            paddingTop: isWeb ? 0 : Spacings.S10,
            marginBottom: Spacings.S24,
          },
        ]}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <View
          style={{
            marginHorizontal: isWeb ? 0 : Spacings.S8,
            flexDirection: 'row',
          }}
        >
          {Object.keys(reportArchive)
            .sort((a, b) => Number(b) - Number(a))
            .map((key: string, index: number) => {
              return (
                <TabButton
                  key={index}
                  isSmall={true}
                  title={key}
                  selectedCategory={year ?? ''}
                  setSelectedCategory={(_) => {
                    setYear(key)
                  }}
                />
              )
            })}
        </View>
      </ScrollView>

      <View
        style={{
          borderTopStartRadius: isSmall ? 6 : 0,
          marginHorizontal: isWeb ? 0 : Spacings.S8,
        }}
      >
        {otherKupara && (
          <BoxWithPaddingMargin
            style={{
              marginHorizontal: isWeb ? 0 : Spacings.S8,
            }}
          >
            <TextDefault type={'h4'} style={{ paddingBottom: Spacings.S16 }}>
              {t(otherKupara.title)}
            </TextDefault>
            <BorderedContainer style={styles.yearReportItem}>
              {otherKupara.reports.map((report, index) => (
                <FlexContainer key={index} style={{ margin: Spacings.S8 }}>
                  <View style={{ flex: 1, overflow: 'hidden' }}>
                    <Image
                      source={require('./ethan.jpg')}
                      resizeMode="cover"
                      style={{ minHeight: 112 }}
                    />
                  </View>
                  <View
                    style={{
                      flex: isSmall ? 1 : 3,
                      paddingLeft: isSmall ? 0 : Spacings.S8,
                      justifyContent: 'center',
                    }}
                  >
                    <TextDefault type={'h5'}>
                      {t(report.title ?? '')}
                    </TextDefault>
                    <TextDefault type={'t1'}>
                      {`${t('reportArchive.retrospective', {
                        year: report.year,
                      })}`}
                    </TextDefault>
                  </View>
                  <View
                    style={{
                      alignSelf: isSmall ? 'stretch' : 'center',
                      backgroundColor: Colors.white,
                      borderRadius: BorderRadius.default,
                    }}
                  >
                    <ButtonSecondaryWithChildren
                      onPress={() => openUrl(toReportLink(report.path, token))}
                    >
                      <FlexRow>
                        <TextDefault
                          type="h6"
                          style={{ flex: 1, color: Colors.textPrimary }}
                        >
                          PDF
                        </TextDefault>
                        <IconDownload />
                      </FlexRow>
                    </ButtonSecondaryWithChildren>
                  </View>
                </FlexContainer>
              ))}
            </BorderedContainer>
          </BoxWithPaddingMargin>
        )}

        {months && (
          <Box
            style={[
              styles.box,
              {
                zIndex: 1,
              },
            ]}
          >
            <MonthsBox months={months} token={token} isSmall={isSmall} />
          </Box>
        )}

        {otherTax && (
          <Box style={styles.box}>
            <TopView>
              <TextDefault type={'h4'}>{t(otherTax.title)}</TextDefault>
            </TopView>
            <SectionDivider />
            <BottomView>
              <View>
                {otherTax.reports.map((report, index) => (
                  <ReportListItem
                    key={index}
                    report={report}
                    onPress={() => openUrl(toReportLink(report.path, token))}
                  />
                ))}
              </View>
            </BottomView>
          </Box>
        )}

        {otherInsurance && (
          <Box style={styles.box}>
            <TopView>
              <TextDefault type={'h4'} style={{ paddingBottom: Spacings.S16 }}>
                {t(otherInsurance.title)}
              </TextDefault>
            </TopView>
            <SectionDivider />
            <BottomView>
              <View>
                {otherInsurance.reports.map((report, index) => (
                  <ReportListItem
                    key={index}
                    report={report}
                    onPress={() => openUrl(toReportLink(report.path, token))}
                  />
                ))}
              </View>
            </BottomView>
          </Box>
        )}

        {otherOwnProperty && (
          <Box style={styles.box}>
            <TopView>
              <TextDefault type={'h4'} style={{ paddingBottom: Spacings.S16 }}>
                {t(otherOwnProperty.title)}
              </TextDefault>
            </TopView>
            <SectionDivider />
            <BottomView>
              <View>
                {otherOwnProperty.reports.map((report, index) => (
                  <ReportListItem
                    key={index}
                    report={report}
                    onPress={() => openUrl(toReportLink(report.path, token))}
                  />
                ))}
              </View>
            </BottomView>
          </Box>
        )}

        {years && (
          <Box style={styles.box}>
            <YearsBox years={years} token={token} />
          </Box>
        )}

        {preserver &&
          onlyPreserverItems().length > 0 &&
          !AppStore.isDigiCustomer && (
            <Box style={styles.box}>
              <PreserverBox reports={onlyPreserverItems()} />
            </Box>
          )}

        {preserver && onlyAktiaItems().length > 0 && (
          <Box style={styles.box}>
            <AktiaCustomerBox reports={onlyAktiaItems()} />
          </Box>
        )}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  yearReportItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.backgroundLightBlue,
    padding: Spacings.S8,
  },
  reportListItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
  },
  monthItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    marginHorizontal: isWeb ? 0 : Spacings.S8,
    marginBottom: Spacings.S32,
  },
})

export default observer(ReportArchiveContent)
