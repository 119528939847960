import { PaddingHorizontal } from '../PaddingHorizontal'

import { TextBold } from '../text/UiText'

import Colors from '@taaleri/core/src/constants/Colors'

import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'

export const CheckListError: React.FC<{
  error?: string
  touched?: boolean
}> = ({ error, touched }) => {
  const showError = touched && error
  if (!showError) {
    return null
  }
  return (
    <PaddingHorizontal>
      <TextBold style={{ color: Colors.error, marginTop: Spacings.S16 }}>
        {error}
      </TextBold>
    </PaddingHorizontal>
  )
}
