import React from 'react'
import DueDateContent from '@taaleri/components/src/screens/funds/DueDateContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import { ROUTE_MONTHLY_CONTRACT } from '@taaleri/components/src/screens/funds/FundsRoutes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'
import { useTranslation } from 'react-i18next'

function DueDateScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <>
      <PaymentStepsFund currentStep={1} recurring={true} />
      <ContentContainerWide>
        <DueDateContent />
        <Buttons
          title={t('button.continue')}
          onPress={() => {
            navigateApp(ROUTE_MONTHLY_CONTRACT)
          }}
        />
      </ContentContainerWide>
    </>
  )
}

export default observer(DueDateScreen)
