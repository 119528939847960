import { Customer } from '@taaleri/core/src/models/Customer'
import isAfter from 'date-fns/is_after'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import { formatDateFull, formatDateDash } from '@taaleri/core/src/utils/format'

export function toIdentityCardDate(dateTime: string) {
  return dateTime.substr(0, 10)
}

export function identityCardDateFormatted(customer?: Customer) {
  if (!customer || !customer.idValidity) {
    return ''
  }
  return formatDateFull(customer.idValidity)
}

export function identityCardUpdateNeeded(now: string, date: string): boolean {
  const days = differenceInCalendarDays(now, date)
  return days > -60
}

export function identityCardValid(date: string): boolean {
  return isAfter(date.substring(0, 10), formatDateDash(new Date()))
}
