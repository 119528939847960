import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { IconInfo } from './Icons'
import { InfoButtonProps } from './InfoButtonProps'
import UiTouchable from './UiTouchable'
import UiModal from './modal/UiModal'
import useModal from './modal/useModal'
import { Paragraph } from './text/Paragraph'
import { TextDefault } from './text/UiText'

export default function InfoButton(props: PropsWithChildren<InfoButtonProps>) {
  const { isVisible, toggle } = useModal()

  const {
    infoElement,
    title,
    text,
    children,
    style,
    iconColor,
    styledParagraphs,
  } = props

  const { t } = useTranslation()

  return (
    <>
      <UiTouchable onPress={() => toggle(true)} style={style}>
        {infoElement && infoElement}
        {!infoElement && <IconInfo color={iconColor || Colors.gray40} />}
      </UiTouchable>

      <View style={styles.container}>
        <UiModal isVisible={isVisible} onBackdropPress={() => toggle(false)}>
          {children && children}
          {title && (
            <TextDefault type="h3" style={styles.title}>
              {t(title)}
            </TextDefault>
          )}
          {text && <Paragraph>{t(text)}</Paragraph>}
          {styledParagraphs &&
            styledParagraphs.length > 0 &&
            styledParagraphs.map((sp, index) => {
              return (
                <React.Fragment key={`paragraph-${index}`}>
                  <View style={{ flexDirection: 'row' }}>
                    {sp.frontDecorator && (
                      <Paragraph style={{ marginRight: 10 }}>
                        {sp.frontDecorator}
                      </Paragraph>
                    )}
                    <Paragraph>{t(sp.text)}</Paragraph>
                  </View>
                </React.Fragment>
              )
            })}
        </UiModal>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
  },
  title: {
    marginBottom: Spacings.S16,
    textAlign: 'center',
    width: '100%',
  },
  modalContent: {
    paddingTop: Spacings.S24,
    alignItems: 'flex-start',
  },
})
