import Analytics from '@taaleri/core/src/analytics/Analytics'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { CustomerAuthorization } from '@taaleri/core/src/models/Customer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, ScrollView } from 'react-native'

import UiTouchable from '../../ui/UiTouchable'
import UiModal, { modalContentStyles } from '../../ui/modal/UiModal'
import { TextBold, TextDefault } from '../../ui/text/UiText'

interface AuthorizationsModalProps {
  defaultCustomerSelection?: boolean
  isVisible: boolean
  onCancel?: () => void
  onSelectCustomer: (customer: CustomerAuthorization) => void
}

function AuthorizationsModal(props: AuthorizationsModalProps) {
  const { t } = useTranslation()
  const { isVisible, onCancel, defaultCustomerSelection } = props
  const selectCustomer = async (customer: CustomerAuthorization) => {
    Analytics.event('Portfolio', 'Select customer')
    props.onSelectCustomer(customer)
  }

  const activeCustomer = AppStore.activeCustomer
  const authorizations = AppStore.customer.customerAuthorizations
  const showDefaultText = defaultCustomerSelection
  const itemStyles = [
    styles.authorizationItem,
    { paddingHorizontal: showDefaultText ? Spacings.S16 : Spacings.S32 },
  ]
  return (
    <UiModal
      isVisible={isVisible}
      onBackButtonPress={onCancel}
      useNativeDriver={true}
      onBackdropPress={onCancel}
      style={{ justifyContent: 'flex-start', marginTop: 130 }}
    >
      <View style={styles.wrapper}>
        <View style={styles.content}>
          {showDefaultText && (
            <TextBold
              style={{
                paddingHorizontal: Spacings.S16,
                marginBottom: Spacings.S24,
                lineHeight: 22,
              }}
            >
              {t('portfolio.default-customer')}
            </TextBold>
          )}
          <ScrollView>
            {authorizations.map((authCustomer, index: number) => {
              const isCurrent =
                Number(authCustomer.id) === Number(activeCustomer.customerId)
              return (
                <UiTouchable
                  key={index}
                  style={itemStyles}
                  onPress={() => {
                    selectCustomer(authCustomer)
                  }}
                >
                  <TextDefault
                    style={{
                      color: isCurrent ? Colors.primary : Colors.text,
                    }}
                  >
                    {authCustomer.name}
                  </TextDefault>
                </UiTouchable>
              )
            })}
          </ScrollView>
        </View>
      </View>
    </UiModal>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  content: {
    ...modalContentStyles,
    alignItems: 'stretch',
    paddingTop: Spacings.S16,
    paddingBottom: Spacings.S16,
    paddingHorizontal: 0,
  },
  authorizationItem: {
    paddingVertical: Spacings.S16,
  },
})

export default observer(AuthorizationsModal)
