import { TimeSerieItem } from '@taaleri/core/src/models/YieldHistory'
import {
  formatDate,
  formatDateWithoutYear,
} from '@taaleri/core/src/utils/format'
import {
  calculateFrequency,
  takeEveryNth,
} from '@taaleri/core/src/utils/yieldHistory'
import { DateValue } from '@taaleri/core/src/models/DateValue'

function convertToDateValue(items: TimeSerieItem[]): DateValue[] {
  return items.map((d) => {
    return { value: d.value, date: new Date(d.date) }
  })
}

function rebaseTimeseries(timeSeries: TimeSerieItem[]) {
  const startDateValue = timeSeries.length > 0 ? timeSeries[0].value : 100
  return timeSeries.map((historyItem: TimeSerieItem) => ({
    date: historyItem.date,
    value: 100 * (historyItem.value / startDateValue),
  }))
}

export function filterData(
  benchMark: TimeSerieItem[],
  data: TimeSerieItem[],
  portfolioData: TimeSerieItem[],
  rebaseIndex: boolean
): [DateValue[], DateValue[], DateValue[]] {
  const freq = calculateFrequency(400, data)
  const filteredData = freq > 1 ? takeEveryNth(data, freq) : data
  const dates = filteredData.map((d) => d.date)
  const filteredBenchMark = benchMark.filter((b) => dates.includes(b.date))
  const filteredPortfolioData =
    portfolioData?.filter((b) => dates.includes(b.date)) ?? []
  return [
    convertToDateValue(
      rebaseIndex ? rebaseTimeseries(filteredData) : filteredData
    ),
    convertToDateValue(
      rebaseIndex ? rebaseTimeseries(filteredBenchMark) : filteredBenchMark
    ),
    convertToDateValue(
      rebaseIndex
        ? rebaseTimeseries(filteredPortfolioData)
        : filteredPortfolioData
    ),
  ]
}

export function toReportChartProps(
  history: TimeSerieItem[],
  benchMarkTimeSeries: TimeSerieItem[],
  portfolioHistory: TimeSerieItem[],
  rebaseIndex: boolean = false
) {
  const [data, benchMark, portfolioData] = filterData(
    benchMarkTimeSeries,
    history,
    portfolioHistory,
    rebaseIndex
  )
  const showFullDates = history.length < 31
  const dateFormatFunction = showFullDates ? formatDateWithoutYear : formatDate

  return {
    data,
    benchMark,
    portfolioData,
    dateFormatFunction,
  }
}
