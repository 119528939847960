import Colors from '@taaleri/core/src/constants/Colors'
import styled from 'styled-components'

const LegendHover = styled.div`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Colors.background};
    }
  }
`

export default LegendHover
