import ReportArchiveContent from '@taaleri/components/src/screens/reportarchive/ReportArchiveContent'
import { BackButtonRow } from '@taaleri/components/src/ui/BackButton'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ReportArchiveScreen() {
  const { t } = useTranslation()
  return (
    <>
      <BackButtonRow title={`${t('menu.report-archive')}`} />
      <ReportArchiveContent />
    </>
  )
}
