import React from 'react'
import {
  ROUTE_PORTFOLIO_PAYMENT_SUM,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import PaymentSelectContent from '@taaleri/components/src/screens/portfolio/PaymentSelectContent'
import { View } from 'react-native'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import BoxCheck from '@taaleri/components/src/ui/box/BoxCheck'
import AppStore from '@taaleri/core/src/stores/AppStore'
import {
  ROUTE_FUNDS_INVEST_START_MONTHLY,
  ROUTE_FUNDS_INVEST_START,
} from '@taaleri/components/src/screens/funds/FundsRoutes'
import { PaymentSteps } from '@taaleri/components/src/components/Steps'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { InfoContainer } from '@taaleri/components/src/ui/InfoContainer'
import Colors from '@taaleri/core/src/constants/Colors'
import { ParagraphBoldFirst } from '@taaleri/components/src/ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function PaymentSelectScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const isFundInvestmentMode = AppStore.investmentMode === 'fund'
  return (
    <>
      <PaymentSteps currentStep={0} />
      <ContentContainerWide>
        <View>
          <PaymentSelectContent />

          <BoxCheck
            isSelected={false}
            onPress={() =>
              isFundInvestmentMode
                ? navigateApp(ROUTE_FUNDS_INVEST_START)
                : navigateApp(ROUTE_PORTFOLIO_PAYMENT_SUM)
            }
            backgroundColor="background"
            option={{ label: t('app.calculator.oneoffinvestment'), value: 'kertasijoitus' }}
          />
        </View>

        <BoxCheck
          isSelected={false}
          backgroundColor="background"
          onPress={() =>
            isFundInvestmentMode
              ? navigateApp(ROUTE_FUNDS_INVEST_START_MONTHLY)
              : navigateApp(ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM)
          }
          option={{ label: t('app.calculator.monthlyinvestment'), value: 'kuukausisijoitus' }}
        />
        <InfoContainer>
          <ParagraphBoldFirst color={Colors.textSecondary}>
            {t('invest.monthly-invest-desc')}
          </ParagraphBoldFirst>
        </InfoContainer>
      </ContentContainerWide>
    </>
  )
}
