import pick from 'lodash/pick'
import countBy from 'lodash/countBy'
import uniqBy from 'lodash/uniqBy'
import {
  SdgWithCount,
  SustainableDevelopmentGoal,
} from '../models/investmentProposal'
import { Allocation } from '../models/InvestmentStrategy'

export function getSdgs(securities: Allocation[]): SdgWithCount[] {
  const sdgs = securities.reduce((acc, allocation) => {
    return [...acc, ...allocation.sustainableDevelopmentGoals]
  }, [] as SustainableDevelopmentGoal[])

  const sdgCount = pick(
    countBy(sdgs, 'name'),
    sdgs.map((e) => e.name)
  )

  // Create array of unique SDGs and add frequency
  return uniqBy(sdgs, 'number').map((s) => {
    return { ...s, count: sdgCount[s.name] }
  })
}
