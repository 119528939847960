import React, { useEffect } from 'react'
import QuestionContainer from './QuestionContainer'
import { observer } from 'mobx-react'
import { nextOnboardingRoute } from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import Buttons from 'ui/Buttons'
import { ROUTE_QUESTION_INVESTMENT_PROPOSAL } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import InvestmentProposalContent from '@taaleri/components/src/screens/questions/InvestmentProposalContent'
import useQueryParams from 'navigation/useQueryParams'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { useTranslation } from 'react-i18next'
import { setLanguage } from '@taaleri/core/src/i18n'
import AppStore from '@taaleri/core/src/stores/AppStore'

function InvestmentProposalScreen() {
  const { t } = useTranslation()
  const { result } = useQueryParams()

  /*
  VR: 26.1.2021
  Email must be initialized, if customer has previously tried onboarding.
  Otherwise register form will be skipped and customer is left without access token
  */
  useEffect(() => {
    if (QuestionStore.email && result === 'newCustomer') {
      QuestionStore.email = undefined
    }
    setLanguage(AppStore.currentLanguage)
  }, [result])

  return (
    <QuestionContainer>
      <InvestmentProposalContent>
        <Buttons
          title={t('button.next')}
          route={nextOnboardingRoute(ROUTE_QUESTION_INVESTMENT_PROPOSAL)}
        />
      </InvestmentProposalContent>
    </QuestionContainer>
  )
}

export default observer(InvestmentProposalScreen)
