import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet } from 'react-native'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import { customers } from '@taaleri/core/src/services/customers'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import { useTranslation } from 'react-i18next'

export default function SignInForgot() {
  async function onTupas() {
    Analytics.event('Forgot password', 'Click')
    const tupasUrl = await customers.getTupasUrlPassword()
    window.location.href = tupasUrl
  }

  const { t } = useTranslation()

  return (
    <UiTouchable style={[styles.forgotContainer]} onPress={onTupas}>
      <TextDefault
        type="t1"
        color={Colors.primary}
        style={{ textAlign: 'center' }}
      >
        {t('signin.forget-credentials')}{'\n'} {t('signin.use-tupas')}
      </TextDefault>
    </UiTouchable>
  )
}

const styles = StyleSheet.create({
  forgotContainer: {
    alignSelf: 'center',
    paddingBottom: Spacings.S32,
  },
})
