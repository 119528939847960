import { SmsResponse } from './../models/ServiceResponse'
import { CustomerResponse } from '../models/Customer'
import {
  Identity,
  IdentityResponse,
  Login,
  LoginResponse,
  Registration,
  SmsVerification,
} from '../models/Identity'
import IAuthenticationApi from './IAuthenticationApi'
import { sleep } from './mockHelper'
import { toIdentity } from './normalization/toIdentity'
import { toLogin } from './normalization/toLogin'
import AppStore from '../stores/AppStore'
import { TEST_CUSTOMER_ID } from '../models/testCustomers'
import { TwoFactorResponse } from '../models/TwoFactorResponse'
import { TeapotError } from '../errors'

async function getOnboardingProcess(): Promise<string> {
  await sleep(500)
  return new Promise<string>((resolve) => {
    return resolve('mock-api-customer-id')
  })
}

function toLoginMock(email?: string) {
  const identity =
    require(`../fixtures/identity/token.json`) as IdentityResponse
  let customer =
    require(`../fixtures/customers/customerResponse.json`) as CustomerResponse

  const authorizationCustomers =
    require(`../fixtures/customers/authorizationCustomers.json`) as {
      [key: string]: CustomerResponse
    }
  // Set logged in customer as base customer so that contract update etc are shown
  if (email) {
    const customerMaybe = Object.values(authorizationCustomers).find(
      (i) => i.emailAddress === email
    )
    if (customerMaybe) {
      customer = customerMaybe
      identity.customer = customer
      AppStore.defaultCustomerId = Number(customer.customerId)
    }
  } else if (AppStore.defaultCustomerId) {
    customer =
      authorizationCustomers[AppStore.defaultCustomerId || TEST_CUSTOMER_ID]
    identity.customer = customer
  }

  return toLogin({
    ...identity,
    customer,
    isOnboardingInProgress: false,
  })
}

async function login(username: string): Promise<Login> {
  return toLoginMock(username)
}

async function loginAdmin(): Promise<Login> {
  return login('admin@taaleri.com')
}

async function refresh(): Promise<Login> {
  return toLoginMock()
}

async function registration(
  _: string,
  register: Registration
): Promise<Identity | null> {
  await sleep(1000)
  const success = register.email !== 'vaara@iki.fi'
  const fakeCrmError = register.email === 'teapot@taaleri.com'
  if (fakeCrmError) {
    throw new TeapotError('Teapot')
  }
  return new Promise<Identity | null>((resolve) => {
    const identity: IdentityResponse = require(`../fixtures/identity/token.json`)
    return resolve(success ? toIdentity(identity) : null)
  })
}

async function registrationExisting(
  _: string,
  register: Registration
): Promise<Login | null> {
  await sleep(500)
  const success = register.email !== 'vaara@iki.fi'

  return new Promise<Login | null>((resolve) => {
    const identity =
      require(`../fixtures/identity/token.json`) as IdentityResponse
    const customer =
      require(`../fixtures/customers/customerResponse.json`) as CustomerResponse
    customer.basicContractSigned = null

    // @ts-ignore
    return resolve(success ? toLogin({ ...identity, customer }) : null)
  })
}

async function resetInfo(_: string): Promise<string> {
  await sleep(500)
  return new Promise<string>((resolve) => {
    return resolve('sauli@sijoittaja.com')
  })
}

async function changePassword(
  _: string,
  _1: Registration
): Promise<Login | null> {
  await sleep(500)

  return new Promise<Login | null>((resolve) => {
    const loginResponse: LoginResponse = require(`../fixtures/authentication/changePasswordResponse.json`)
    return resolve(toLogin(loginResponse))
  })
}

async function sendVerificationsCode(
  _: Identity,
  _1: string
): Promise<boolean> {
  await sleep(500)
  return true
}

async function checkVerificationCode(
  _: Identity,
  _1: string,
  pin: string
): Promise<SmsVerification> {
  await sleep(500)
  return new Promise<SmsVerification>((resolve) => {
    const success = pin !== '9999'
    resolve({
      success,
      status: success ? 'Success' : 'WrongPin',
    })
  })
}

async function checkVerificationCode2FA(
  _: string,
  pin: string
): Promise<SmsVerification> {
  await sleep(500)
  return new Promise<SmsVerification>((resolve) => {
    const success = pin !== '9999'
    resolve({
      success,
      status: success ? 'Success' : 'WrongPin',
    })
  })
}

async function sendAuthenticationHint(_: string): Promise<boolean> {
  await sleep(500)
  return true
}

async function changeUsername(
  _: string,
  _1: number,
  _3: string
): Promise<SmsResponse> {
  await sleep(500)
  return { smsConfirmationId: '123456489', validationErrors: [] }
}

async function changePasswordSimple(): Promise<string> {
  await sleep(500)
  return '123456'
}

async function setTwoFactorAuth(
  _: Identity,
  _1: boolean
): Promise<TwoFactorResponse> {
  return Promise.resolve({ validationErrors: [], smsConfirmationId: '' })
}

async function refreshAccessToken(_: string): Promise<Identity> {
  return Promise.resolve({
    accessToken: '',
    refreshToken: '',
    expiresOn: 10,
    tokenType: '',
    customerId: 12345,
  })
}

export const authenticationApiMock: IAuthenticationApi = {
  getOnboardingProcess,
  setTwoFactorAuth,
  refreshAccessToken,
  checkVerificationCode,
  login,
  loginAdmin,
  sendVerificationsCode,
  refresh,
  registration,
  registrationExisting,
  changePassword,
  resetInfo,
  sendAuthenticationHint,
  changeUsername,
  changePasswordSimple,
  checkVerificationCode2FA,
}
