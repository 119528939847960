import React from 'react'
import Title from '@taaleri/components/src/ui/text/Title'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import { PaymentSteps } from '@taaleri/components/src/components/Steps'
import { Formik } from 'formik'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import SumModel from '@taaleri/components/src/screens/portfolio/SumModel'
import paymentSumValidationSchema from '@taaleri/components/src/screens/portfolio/paymentSumValidationSchema'
import Buttons from 'ui/Buttons'
import {
  ROUTE_PORTFOLIO_PAYMENT_BANK,
  ROUTE_PORTFOLIO_PAYMENT_MONTHLY,
  ROUTE_PORTFOLIO_PAYMENT_SELECT,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import {
  Paragraph,
  ParagraphBoldFirst,
} from '@taaleri/components/src/ui/text/Paragraph'
import { InfoContainer } from '@taaleri/components/src/ui/InfoContainer'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

export default function PaymentSumScreen({
  isMonthly,
}: {
  isMonthly: boolean
}) {
  const initialValues: SumModel = {
    sum: '',
  }
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()

  return (
    <>
      <PaymentSteps currentStep={1} />
      <ContentContainerWide>
        <Formik
          initialValues={initialValues}
          isInitialValid={false}
          validationSchema={paymentSumValidationSchema(t)}
          onSubmit={(values: SumModel) => {
            AppStore.newInvestment = Number(values.sum)
            navigateApp(
              isMonthly
                ? ROUTE_PORTFOLIO_PAYMENT_MONTHLY
                : ROUTE_PORTFOLIO_PAYMENT_BANK
            )
          }}
        >
          {({ isValid }) => (
            <UiForm>
              <TopBottomContainer>
                <View>
                  <Title style={{ marginBottom: Spacings.S16 }}>
                    {isMonthly ? t('app.calculator.monthlyinvestment') : t('app.calculator.oneoffinvestment')}
                  </Title>
                  <Paragraph>{t('invest.sum-title')}</Paragraph>
                  <View>
                    <UiTextInputForm
                      name="sum"
                      label={`${t('steps.sum')}`}
                      type="digits"
                      grayBackground={true}
                      returnKeyType="done"
                      ignoreTouched={true}
                    />
                  </View>
                  {isMonthly && (
                    <InfoContainer>
                      <ParagraphBoldFirst color={Colors.textSecondary}>
                        {t('invest.monthly-info')}
                      </ParagraphBoldFirst>
                    </InfoContainer>
                  )}
                </View>
                <Buttons
                  title={t('button.continue')}
                  titleSecondary={`${t('button.return')}`}
                  onPressSecondary={() =>
                    navigateApp(ROUTE_PORTFOLIO_PAYMENT_SELECT)
                  }
                  primaryButtonType={isValid ? 'primary' : 'disabled'}
                />
              </TopBottomContainer>
            </UiForm>
          )}
        </Formik>
      </ContentContainerWide>
    </>
  )
}
