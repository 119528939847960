import React, { useState } from 'react'
import { View, StyleProp, ViewStyle, Animated } from 'react-native'
import { Title3 } from '../../ui/text/Title'
import Box from '../../ui/box/Box'
import PortfolioChart, {
  toPortfolioChartProps,
  PortfolioChartProps,
} from '../portfolio/PortfolioChart'
import {
  FROM_BEGINNING,
  YEAR_TO_DATE,
  PAST_THREE_YEARS,
  PAST_FIVE_YEARS,
} from '@taaleri/core/src/constants/YieldHistory'
import { TimeSerieItem } from '@taaleri/core/src/models/YieldHistory'
import { filterYieldHistoryByScale } from '@taaleri/core/src/utils/yieldHistory'
import LoadingIndicator from '../../ui/LoadingIndicator'
import Colors from '@taaleri/core/src/constants/Colors'
import HistoryScaleButtons from '../portfolio/HistoryScaleButtons'
import { useTranslation } from 'react-i18next'

interface Props {
  history: TimeSerieItem[]
  containerStyle?: StyleProp<ViewStyle>
}

const opacity = new Animated.Value(1)

export default function FundChart({ history, containerStyle }: Props) {
  const { t } = useTranslation()
  const [scale, setScale] = useState<string>(FROM_BEGINNING)
  const [loading, setLoading] = useState<boolean>(false)
  const [chartProps, setChartProps] = useState<PortfolioChartProps>()

  const onScaleChange = (newScale: string) => {
    opacity.setValue(0)
    setScale(newScale)
    setLoading(true)
    setTimeout(() => {
      setChartProps(toPortfolioChartProps(history, newScale))
      setLoading(false)
      Animated.timing(opacity, {
        toValue: 1,
        duration: 1000,
      }).start()
    })
  }
  const portfolioData = chartProps || toPortfolioChartProps(history, scale)
  const showYearToDateFilter =
    filterYieldHistoryByScale(YEAR_TO_DATE, history).length > 1
  const showThreeYears =
    filterYieldHistoryByScale(PAST_THREE_YEARS, history).length > 1
  const showFiveYears =
    filterYieldHistoryByScale(PAST_FIVE_YEARS, history).length > 1

  return (
    <View style={containerStyle}>
      <Title3>{t('fundChart.value-title')}</Title3>
      <Box>
        {loading && (
          <LoadingIndicator
            style={{
              position: 'absolute',
              top: 0,
              height: '100%',
              width: '100%',
              backgroundColor: Colors.white,
              zIndex: 1000,
            }}
          />
        )}
        <Animated.View style={{ opacity }}>
          <HistoryScaleButtons
            yearToDate={showYearToDateFilter}
            threeYears={showThreeYears}
            fiveYears={showFiveYears}
            onChange={onScaleChange}
            type="fund"
            initialScale={scale}
          />
          <View style={{ height: 40 }} />
          <PortfolioChart {...portfolioData} />
        </Animated.View>
      </Box>
    </View>
  )
}
