import React from 'react'
import { View } from 'react-native'
import ImpactToggleDoneContent from '@taaleri/components/src/screens/impact/ImpactToggleDoneContent'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { ImpactToggleSteps } from '@taaleri/components/src/components/Steps'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

export function ImpactToggleDoneScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <>
      <ImpactToggleSteps currentStep={2} />
      <View style={{ marginTop: Spacings.S40 }}>
        <ContentContainerBox>
          <TopBottomContainer>
            <ImpactToggleDoneContent />
            <Buttons
              title={t('button.continue')}
              onPress={() => navigateApp('')}
            />
          </TopBottomContainer>
        </ContentContainerBox>
      </View>
    </>
  )
}
