import AsyncStorage from '@react-native-async-storage/async-storage'
import { observable, makeAutoObservable } from 'mobx'
import { create, persist } from 'mobx-persist'
import { Platform } from 'react-native'

import { TestEnvironment } from '../models/Environment'

class ConfigStoreInternal {
  constructor() {
    makeAutoObservable(this)
  }
  @persist
  @observable
  environment: TestEnvironment = 'Test'
  @persist
  @observable
  useActualTupasResult = false
}

// export default new ConfigStore()
// prettier-ignore
const hydrate = create({storage: Platform.OS === 'web' ? localStorage : AsyncStorage,jsonify: true,})

const ConfigStore = new ConfigStoreInternal()
export default ConfigStore

const result = hydrate('configStore', ConfigStore)
export async function rehydrateConfigStore() {
  await result.rehydrate()
}
