import React from 'react'
import { ButtonAction } from '@taaleri/components/src/ui/UiButton'
import { ExistingAuthorizationContent } from '@taaleri/components/src/screens/questions/ExistingAuthorizationContent'
import { ROUTE_SIGN_IN } from '@taaleri/components/src/navigation/routes'
import QuestionContainer from './QuestionContainer'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ExistingAuthorizationScreen = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <>
      <QuestionContainer>
        <ExistingAuthorizationContent />
        <ButtonAction
          title={t('navigator.to-login')}
          onPress={() => {
            history.push('/' + ROUTE_SIGN_IN)
          }}
        />
      </QuestionContainer>
    </>
  )
}
