import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import {
  NativeSyntheticEvent,
  NativeTouchEvent,
  StyleProp,
  StyleSheet,
  SwitchProps,
  TextStyle,
  View,
} from 'react-native'
import {
  IconEmail,
  IconHome,
  IconPhone,
  IconProfile,
  IconCheckmarkCircle,
  IconCheckmarkDisabled,
} from './Icons'
import { TextMedium } from './text/UiText'
import UiTouchable from './UiTouchable'
import { UiSwitch } from './form/UiSwitch'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import FlexRow from './FlexRow'
import GlobalStyles from '../constants/GlobalStyles'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { IconChevronRight } from './icons/ChevronIcons'
import { IconCircle } from './icons/CircleIcons'
import { formatElementId } from '@taaleri/core/src/utils/format'

export interface ListItemLinkData {
  props?: ListItemProps
  title: string
  link?: string
  value?: string
  onPress?: () => void
}

type ListItemType = 'default' | 'shadow'

interface ListItemProps {
  onPress?: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void
  icon?: 'profile' | 'email' | 'phone' | 'address'
  title?: string
  subTitle?: string
  titleRight?: string
  titleRightStyle?: StyleProp<TextStyle>
  switchProps?: SwitchProps
  textStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<TextStyle>
  checkmark?: boolean
  checkmarkNotChecked?: boolean
  chevron?: boolean
  bottomDivider?: boolean
  topDivider?: boolean
  image?: Element
  rightComponent?: any
  reserveSpaceForRightCheckmark?: boolean
  open?: boolean
  borderLeftColor?: string
  children?: any
  noPadding?: boolean
  type?: ListItemType
  borderTopRadius?: boolean
  borderBottomRadius?: boolean
  disabled?: boolean
}

export default function ListItem(props: ListItemProps) {
  const {
    icon,
    title,
    subTitle,
    titleRight,
    titleRightStyle,
    onPress,
    switchProps,
    checkmark,
    checkmarkNotChecked,
    chevron,
    topDivider,
    bottomDivider,
    image,
    reserveSpaceForRightCheckmark,
    open,
    rightComponent,
    borderLeftColor,
    noPadding,
    children,
    type,
    borderTopRadius,
    borderBottomRadius,
    disabled,
  } = props
  const listItemType = type || 'default'
  const ContainerComponent = onPress ? UiTouchable : View
  const showBorderBottom = bottomDivider !== false
  const showBorderTop = topDivider !== false
  const useSpaceForRightCheckmark =
    reserveSpaceForRightCheckmark &&
    !switchProps &&
    !titleRight &&
    !chevron &&
    !checkmark
  return (
    <FlexRow>
      {borderLeftColor && (
        <View
          style={[
            {
              borderLeftColor,
              borderLeftWidth: 4,
            },
          ]}
        />
      )}

      <ContainerComponent
        nativeID={title && formatElementId(title)}
        style={[
          styles.container,
          switchProps && styles.containerVerticalPaddingWhenSwitch,
          showBorderBottom && styles.borderBottom,
          showBorderTop && styles.borderTop,
          noPadding && { paddingHorizontal: 0 },
          props.containerStyle,
          listItemType === 'shadow' && {
            ...GlobalStyles.shadow,
            borderTopWidth: 0,
            borderBottomWidth: 0,
          },
          borderTopRadius && {
            borderTopLeftRadius: BorderRadius.default,
            borderTopRightRadius: BorderRadius.default,
            borderTopWidth: 0,
          },
          borderBottomRadius && {
            borderBottomLeftRadius: BorderRadius.default,
            borderBottomRightRadius: BorderRadius.default,
            borderBottomWidth: 0,
          },
        ]}
        {...props}
      >
        {icon && (
          <View style={{ marginLeft: -4, marginRight: 2 }}>
            <Icon type={icon} />
          </View>
        )}
        {image}
        {children}
        {!children && (
          <>
            {subTitle ? (
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <TextMedium style={[styles.text, props.textStyle]}>
                  {title}
                </TextMedium>
                {subTitle && (
                  <TextMedium style={[styles.subTitle]}>{subTitle}</TextMedium>
                )}
              </View>
            ) : (
              <TextMedium
                style={[
                  disabled ? styles.textDisabled : styles.text,
                  props.textStyle,
                ]}
              >
                {title}
              </TextMedium>
            )}
          </>
        )}

        <View
          style={{
            alignItems: 'flex-end',
          }}
        >
          {switchProps && <UiSwitch {...switchProps} />}
          {titleRight && (
            <TextMedium style={titleRightStyle}>{titleRight}</TextMedium>
          )}
          {chevron && (
            <View
              style={[
                { marginRight: -6 },
                open && {
                  transform: [{ rotate: '-90deg' }],
                },
              ]}
            >
              <IconChevronRight />
            </View>
          )}
          {checkmark &&
            (disabled ? <IconCheckmarkDisabled /> : <IconCheckmarkCircle />)}
          {checkmarkNotChecked && <IconCircle />}
          {rightComponent}

          {useSpaceForRightCheckmark && <View style={{ width: 24 }} />}
        </View>
      </ContainerComponent>
    </FlexRow>
  )
}

const nameToIconMap = {
  profile: () => <IconProfile color={Colors.text} />,
  email: () => <IconEmail color={Colors.text} />,
  phone: () => <IconPhone color={Colors.text} />,
  address: () => <IconHome color={Colors.text} />,
}

const Icon = (props: { type: 'profile' | 'email' | 'phone' | 'address' }) => {
  const { type } = props
  return nameToIconMap[type] ? nameToIconMap[type]() : null
}

// Common extensions
export const ListItemLink: React.FC<ListItemProps> = (props) => (
  <ListItem chevron={true} {...props} />
)

const styles = StyleSheet.create({
  container: {
    paddingVertical: Spacings.S16,
    paddingHorizontal: Spacings.PADDING,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    flex: 1,
  },
  containerVerticalPaddingWhenSwitch: {
    paddingVertical: Spacings.S16 - 3,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  text: {
    flex: 0.9,
    lineHeight: 22,
  },
  textDisabled: {
    flex: 0.9,
    lineHeight: 22,
    color: Colors.textDisabled,
  },
  subTitle: {
    flex: 0.9,
    lineHeight: 18,
    fontSize: FontSize.S12,
    color: Colors.textDisabled,
  },
})
