import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import { HiddenSmall } from '@taaleri/components/src/ui/Responsive'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import styled from 'styled-components'

import ContentContainer from './ContentContainer'
import stylesContainer from './background-container.module.css'

export interface BackgroundContainerProps {
  image?: BackgroundImage
  hideImage?: boolean
  showMobile?: boolean
  noPadding?: boolean
  children: React.ReactNode
  contentSize?: ContentSize
  contentType?: ContentType
  hideLogo?: boolean
  noBackgroundColor?: boolean
}

type ContentSize = 'default' | 'large'
type ContentType = 'full' | 'centered' | 'centered-top'

type BackgroundImage = 'primary' | 'not-found' | 'pier'

const LogoContainer = styled.div`
  position: fixed;
  left: ${Spacings.S24}px;
  top: ${Spacings.S40}px;
`

function BackgroundContainer(props: BackgroundContainerProps) {
  const { image = 'primary', hideImage, children, hideLogo } = props
  return (
    <BackgroundImage hideImage={hideImage} image={image}>
      {!hideLogo && (
        <HiddenSmall>
          <LogoContainer>
            <AktiaLogo />
          </LogoContainer>
        </HiddenSmall>
      )}

      <span style={{ marginTop: 30, width: '100%' }}>
        <ContentBox {...props}>
          <View style={styles.container}>{children}</View>
        </ContentBox>
      </span>
    </BackgroundImage>
  )
}

export const BackgroundContainerApp: React.FC<BackgroundContainerProps> = (
  props
) => {
  const { children } = props
  return (
    <BackgroundContainer contentType="centered-top" {...props} hideLogo={true}>
      <ContentContainer {...props} noPadding={true}>
        {children}
      </ContentContainer>
    </BackgroundContainer>
  )
}

export const BackgroundImage: React.FC<BackgroundContainerProps> = ({
  children,
  image,
  showMobile,
}) => {
  // tslint:disable-next-line:no-string-literal
  const bgStyle = stylesContainer[`bg-${image}`]
  return (
    <div
      className={[
        stylesContainer.containerBackground,
        bgStyle,
        !showMobile && stylesContainer.hideBackground,
      ].join(' ')}
    >
      {children}
    </div>
  )
}

export const ContentBox: React.FC<BackgroundContainerProps> = ({
  children,
  noPadding,
  contentSize = 'default',
  contentType = 'full',
  noBackgroundColor,
}) => {
  return (
    <div
      className={[
        stylesContainer.content,
        stylesContainer[`content-${contentSize}`],
        stylesContainer[`contenttype-${contentType}`],
        noPadding !== true && stylesContainer.contentPadding,
      ].join(' ')}
      style={{
        backgroundColor: noBackgroundColor ? 'transparent' : Colors.background,
      }}
    >
      {children}
    </div>
  )
}

export function BackgroundContainerGrey(props: BackgroundContainerProps) {
  return (
    <div className={stylesContainer.containerBackground}>
      <ContentBox {...props}>{props.children}</ContentBox>
    </div>
  )
}

export default BackgroundContainer

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingBottom: '20px',
  },
})
