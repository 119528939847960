import React from 'react'
import { ViewStyle, StyleProp } from 'react-native'
import UiTouchable from '../ui/UiTouchable'
import Colors from '@taaleri/core/src/constants/Colors'
import useNavigation from '../navigation/useNavigation'
import { IconArrowLeft } from './icons/ArrowIcons'
import FlexRow from './FlexRow'
import Title from './text/Title'
import Spacings from '@taaleri/core/src/constants/Spacings'

interface Props {
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

export function BackButton({ onPress, style }: Props) {
  const { navigateBack } = useNavigation()
  return (
    <UiTouchable
      style={[
        {
          width: 40,
          height: 32,
          borderRadius: 6,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.primary,
        },
        style,
      ]}
      onPress={onPress || navigateBack}
    >
      <IconArrowLeft color={Colors.white} />
    </UiTouchable>
  )
}

interface RowProps {
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  title: string
}

export function BackButtonRow({ onPress, style, title }: RowProps) {
  return (
    <FlexRow
      style={{
        alignItems: 'center',
      }}
    >
      <BackButton onPress={onPress} style={style} />
      <Title style={{ paddingLeft: Spacings.S16 }}>{title}</Title>
    </FlexRow>
  )
}
