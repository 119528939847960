import { Image, StyleSheet, View } from 'react-native'

import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useState } from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Box from '../../../ui/box/Box'
import FlexRow from '../../../ui/FlexRow'
import { IconClose } from '../../../ui/Icons'
import { TextBold } from '../../../ui/text/UiText'

import Analytics from '@taaleri/core/src/analytics/Analytics'
import { EmojiResponse } from '@taaleri/core/src/models/EmojiResponse'
import UiTouchable from '../../../ui/UiTouchable'
import { BOTTOM_WITHOUT_KEYBOARD } from '@taaleri/core/src/utils/dimensions'
import FadingFeedback from './FadingFeedback'
import SimpleFeedbackForm from './SimpleFeedbackForm'
import { customers } from '@taaleri/core/src/services/customers'
import Colors from '@taaleri/core/src/constants/Colors'
import { IconRecommend } from '../../../ui/RecommendationIcons'
import useLayout from '../../../ui/useLayout'
import { FlexContainer } from '../../../ui/FlexContainer'
import { useTranslation } from 'react-i18next'

function EmojiItem({ image, onPress }: { image: any; onPress: () => void }) {
  return (
    <UiTouchable
      onPress={onPress}
      style={{
        backgroundColor: Colors.backgroundLightBlue,
        borderColor: Colors.borderLightBlue,
        borderWidth: 1,
        borderRadius: 6,
        padding: Spacings.S8,
        marginHorizontal: Spacings.S4,
      }}
    >
      <Image source={image} style={{ width: 50, height: 50 }} />
    </UiTouchable>
  )
}

export default function SimpleFeedbackContent({ bottom }: { bottom?: number }) {
  const { t } = useTranslation()
  const [response, setResponse] = useState<EmojiResponse>()
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false)
  const { isNonSmall } = useLayout()

  const onEmojiPress = async (emojiResponse: EmojiResponse) => {
    setResponse(emojiResponse)
    await customers.sendEmoji(emojiResponse, AppStore.customerId)
    Analytics.event('Feedback', 'emoji', emojiResponse)
  }

  const onFeedbackSend = () => {
    setFeedbackSent(true)
  }

  const neutral = require('./neutral.png')
  const happy = require('./happy.png')
  const veryHappy = require('./very-happy.png')
  const question =
    response === 'neutral_face'
      ? `${t('feedback.how-to-improve')}`
      : `${t('feedback.what-liked')}`
  if (feedbackSent) {
    return <FadingFeedback />
  }
  return (
    <Box style={[styles.container, { bottom }]}>
      {!feedbackSent && (
        <>
          <FlexRow
            style={{
              justifyContent: 'space-between',
            }}
          >
            <FlexContainer justifyContent={response ? 'flex-start' : undefined}>
              {isNonSmall && (
                <View
                  style={{
                    justifyContent: 'center',
                    padding: Spacings.S8,
                    backgroundColor: Colors.primary,
                    borderRadius: 6,
                  }}
                >
                  <IconRecommend
                    style={{
                      alignSelf: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 50,
                      width: 50,
                    }}
                    color={Colors.white}
                  />
                </View>
              )}
              <TextBold
                style={{
                  alignSelf: 'center',
                  marginHorizontal: response ? Spacings.S8 : 0,
                }}
              >
                {t('feedback.what-like-about-app')}
              </TextBold>
              {!response && (
                <FlexRow
                  style={{
                    justifyContent: 'space-between',
                    paddingVertical: isNonSmall ? 0 : Spacings.S24,
                    paddingHorizontal: isNonSmall ? 0 : Spacings.S24,
                  }}
                >
                  <EmojiItem
                    image={neutral}
                    onPress={() => onEmojiPress('neutral_face')}
                  />
                  <EmojiItem
                    image={happy}
                    onPress={() => onEmojiPress('slightly_smiling_face')}
                  />
                  <EmojiItem
                    image={veryHappy}
                    onPress={() => onEmojiPress('heart_eyes')}
                  />
                </FlexRow>
              )}
            </FlexContainer>
            <UiTouchable
              style={styles.close}
              onPress={() => {
                AppStore.simpleFeedbackDismissed = true
                Analytics.event(
                  'Feedback',
                  'dismiss',
                  response ? 'emoji' : 'noAnswer'
                )
              }}
            >
              <IconClose />
            </UiTouchable>
          </FlexRow>
          {response && (
            <SimpleFeedbackForm
              placeholder={question}
              onSuccess={onFeedbackSend}
              emojiResponse={response}
            />
          )}
        </>
      )}
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Spacings.S24,
    paddingVertical: Spacings.S16,
    right: 0,
    left: 0,
    bottom: BOTTOM_WITHOUT_KEYBOARD,
    zIndex: 1000,
  },
  close: {},
})
