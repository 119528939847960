import React from 'react'
import { StyleSheet } from 'react-native'
import PercentagePieWithNumber from '../../components/PercentagePieWithNumber'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextBold } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import {
  ASSET_CLASS_ID_CASH,
  ASSET_CLASS_ID_STOCKS,
} from '@taaleri/core/src/constants/AssetClass'
import { useTranslation } from 'react-i18next'

interface ImageBackgroundWithPercentagePieProps {
  image: any
  assetClasses: AssetClass[] | undefined
  selectedAssetClass: AssetClass
}

function getBackgroundPosition(assetClass: AssetClass): string {
  switch (assetClass.id) {
    case ASSET_CLASS_ID_CASH:
      return 'center 75%'
    case ASSET_CLASS_ID_STOCKS:
      return 'center center'
    default:
      return 'center top'
  }
}

const ImageBackgroundWithPercentagePie: React.FC<ImageBackgroundWithPercentagePieProps> =
  ({ image, assetClasses, selectedAssetClass }) => {
    const backgroundImageStyle: React.CSSProperties = {
      width: '100%',
      height: 285,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundImage: `url(${image})`,
      backgroundPosition: getBackgroundPosition(selectedAssetClass),
      backgroundSize: 'cover',
    }
    const { t } = useTranslation()

    return (
      <div style={backgroundImageStyle}>
        <TextBold style={styles.title}>{t(selectedAssetClass.name)}</TextBold>
        {assetClasses && (
          <PercentagePieWithNumber
            percentages={
              assetClasses.length > 0
                ? assetClasses.map((p) => p.percentageOfTotal)
                : [100]
            }
            percentage={
              selectedAssetClass ? selectedAssetClass.percentageOfTotal : 0
            }
            theme="white"
            size={88}
          />
        )}
      </div>
    )
  }

export default ImageBackgroundWithPercentagePie

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: 285,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: Colors.white,
    fontSize: FontSize.S24,
    lineHeight: 30,
    letterSpacing: -0.24,
    marginBottom: Spacings.S24,
  },
})
