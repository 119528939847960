import React from 'react'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextBold, TextMedium, TextDefault } from '../../ui/text/UiText'
import { observer } from 'mobx-react'
import { View, StyleSheet } from 'react-native'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import GlobalStyles from '../../constants/GlobalStyles'
import { useTranslation } from 'react-i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  endAge: number
  finalBalance: number
  investedCapital: number
  revenue: number
}

function FinanceChartIntro({
  endAge,
  finalBalance,
  investedCapital,
  revenue,
}: Props) {
  const { t } = useTranslation()
  const EndText = t('questions.proposal.tucked-away', { age: endAge })
  let introText = null
  if (QuestionStore.initialInvestment === 0) {
    introText = (
      <Paragraph>
        {t('questions.proposal.by-invest')}{' '}
        <TextBold>{formatCurrency(QuestionStore.monthlyInvestment)}</TextBold>{' '}
        {t('questions.proposal.month')} {EndText}
      </Paragraph>
    )
  } else if (QuestionStore.monthlyInvestment === 0) {
    introText = (
      <Paragraph>
        <TextBold>{formatCurrency(QuestionStore.initialInvestment)}</TextBold>{' '}
        {t('questions.proposal.first-invest')} {EndText}
      </Paragraph>
    )
  } else {
    introText = (
      <Paragraph>
        {AppStore.currentLanguage === fiKey && (
          <>
            <TextBold>
              {formatCurrency(QuestionStore.initialInvestment)}
            </TextBold>{' '}
            {t('questions.proposal.invest-part1')}{' '}
            <TextBold>
              {formatCurrency(QuestionStore.monthlyInvestment)}
            </TextBold>{' '}
            {t('questions.proposal.month')} {EndText}
          </>
        )}
        {AppStore.currentLanguage !== fiKey && (
          <>
            {t('questions.proposal.invest-part1sv')}{' '}
            <TextBold>
              {formatCurrency(QuestionStore.initialInvestment)}
            </TextBold>{' '}
            {t('questions.proposal.invest-part2sv')}{' '}
            <TextBold>
              {formatCurrency(QuestionStore.monthlyInvestment)}
            </TextBold>{' '}
            {t('questions.proposal.month')} {EndText}
          </>
        )}
      </Paragraph>
    )
  }
  return (
    <View>
      {introText}
      <FlexRow style={styles.finalBalanceRow}>
        <TextDefault type="h1">
          {formatCurrency(Math.round(finalBalance))}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.capitalRow}>
        <TextMedium style={GlobalStyles.smallText}>
          {t('questions.proposal.capital-saved')}
        </TextMedium>
        <TextBold>{formatCurrency(Math.round(investedCapital))}</TextBold>
      </FlexRow>
      <FlexRow style={styles.revenueRow}>
        <TextMedium style={GlobalStyles.smallText}>
          {t('questions.proposal.expected-profit')}
        </TextMedium>
        <TextBold>{formatCurrency(Math.round(revenue))}</TextBold>
      </FlexRow>
    </View>
  )
}

const styles = StyleSheet.create({
  finalBalanceRow: {
    alignItems: 'baseline',
    flexDirection: 'row',
    marginBottom: Spacings.S16,
    justifyContent: 'space-between',
  },
  revenueRow: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    paddingVertical: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  capitalRow: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    paddingVertical: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopColor: Colors.border,
    borderTopWidth: 1,
  },
})

export default observer(FinanceChartIntro)
