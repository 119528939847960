import ReportArchiveBox from '@taaleri/components/src/screens/report/ReportArchiveBox'
import ReportContractBox from '@taaleri/components/src/screens/report/ReportContractBox'
import ReportContractOrderBox from '@taaleri/components/src/screens/report/ReportContractOrderBox'
import ReportFeeBox from '@taaleri/components/src/screens/report/ReportFeeBox'
import ReportOrderBox from '@taaleri/components/src/screens/report/ReportOrderBox'
import ReportRecurringOrderBox from '@taaleri/components/src/screens/report/ReportRecurringOrderBox'
import ReportTaxBox from '@taaleri/components/src/screens/report/ReportTaxBox'
import ReportTransactionBox from '@taaleri/components/src/screens/report/ReportTransactionBox'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import GradientContainer from 'ui/GradientContainer'

const UpperGrid = styled.div`
  display: grid;
  grid-gap: ${Spacings.S20}px;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
  }
`

const LowerGrid4c = styled.div`
  margin-top: ${Spacings.S20}px;
  display: grid;
  grid-gap: ${Spacings.S20}px;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`

const LowerGrid3c = styled.div`
  margin-top: ${Spacings.S20}px;
  display: grid;
  grid-gap: ${Spacings.S20}px;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`

function ReportScreen() {
  useEffect(() => {
    AppStore.fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])
  return (
    <GradientContainer>
      <>
        <UpperGrid>
          {/* {AppStore.isDigiDiscretionary && <ReportFeeBox />} */}
          {/* {AppStore.isDigiOrConsultative && <ReportRecurringOrderBox />} */}
          <ReportTransactionBox />
          <ReportTaxBox />
        </UpperGrid>
        {AppStore.IsReportOrdersForbidden() !== true ? (
          <LowerGrid4c>
            <ReportOrderBox />
            <ReportArchiveBox />
            <ReportContractBox />
            <ReportContractOrderBox />
          </LowerGrid4c>
        ) : (
          <LowerGrid3c>
            <ReportArchiveBox />
            <ReportContractBox />
            <ReportContractOrderBox />
          </LowerGrid3c>
        )}
      </>
    </GradientContainer>
  )
}

export default observer(ReportScreen)
