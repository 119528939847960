import React from 'react'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import { SubtitleHome } from '../../../../../screens/home/SubtitleHome'
import AssetDetails from './AssetDetails'
import UiTouchable from '../../ui/UiTouchable'
import { TextDefault } from '../../ui/text/UiText'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_PORTFOLIO } from '../portfolio/PortfolioRoutes'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'

export default function HomeAssetDetails() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  if (!AppStore.hasPortfolio) {
    return null
  }
  return (
    <View style={{ marginBottom: Spacings.S24 }}>
      <FlexRow
        style={{
          alignItems: 'center',
          marginBottom: Spacings.S16,
          justifyContent: 'space-between',
        }}
      >
        <SubtitleHome style={{ marginRight: Spacings.S16, marginBottom: 0 }}>
          {t('menu.portfolio')}
        </SubtitleHome>
        <UiTouchable
          onPress={() => {
            navigateApp(ROUTE_PORTFOLIO)
          }}
        >
          <TextDefault type="t2" color={Colors.textPrimary}>
            {t('portfolio.show-all')} ›
          </TextDefault>
        </UiTouchable>
      </FlexRow>
      <View style={{ marginBottom: Spacings.S24 }}>
        <AssetDetails />
      </View>
    </View>
  )
}
