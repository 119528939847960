import React, { useState } from 'react'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import FlexRow from '../../ui/FlexRow'

import PercentagePieWithNumber from '../../components/PercentagePieWithNumber'
import Title, { Title3 } from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import Subtitle from '../../ui/text/Subtitle'
import UiButton from '../../ui/UiButton'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { BoxWithPadding } from '../../ui/box/Box'
import {
  TextMedium,
  TextBold,
  TextDemiBold,
  TextSmall,
  TextError,
  TextDefault,
  TextType,
} from '../../ui/text/UiText'
import LabelValue from '../../ui/text/LabelValue'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import Colors from '@taaleri/core/src/constants/Colors'
import { sleep } from '@taaleri/core/src/api/mockHelper'
import { SdgIcon } from '../../ui/icons/SdgIcons'
import { useTranslation } from 'react-i18next'

const percentages = [10, 20, 50]

const StyleGuideSection: React.FC<{ title?: string }> = ({
  title,
  children,
}) => {
  return (
    <View style={{ marginBottom: Spacings.S24 }}>
      {title && <Subtitle>{title}</Subtitle>}
      {children}
    </View>
  )
}

const TextSection = ({ type }: { type: TextType }) => {
  const { t } = useTranslation()
  return (
    <TextDefault type={type} style={{ marginBottom: Spacings.S24 }}>
      {type} {t('impactIntro.infobox2.header')}
    </TextDefault>
  )
}

const ColorBox: React.FC<{
  title: string
  color: string
  borderColor?: {}
}> = ({ title, color }) => {
  return (
    <View>
      <View
        style={{
          marginRight: Spacings.S8,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: 150,
          width: 150,
          backgroundColor: color,
        }}
      />

      <TextMedium
        style={{
          color: Colors.text,
          marginBottom: Spacings.S16,

          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {title}
      </TextMedium>
    </View>
  )
}

export default function StyleGuideContent() {
  const [loading, setLoading] = useState<boolean>(false)

  async function onPress() {
    setLoading(true)
    await sleep(2000)
    setLoading(false)
  }
  return (
    <PaddingHorizontal>
      <StyleGuideSection title="Colors">
        <FlexRow style={{ flexWrap: 'wrap' }}>
          {Object.entries(Colors).map((item, value) => (
            <>
              <ColorBox title={item[0]} color={item[1]} key={value} />
            </>
          ))}
        </FlexRow>
      </StyleGuideSection>
      <StyleGuideSection title="Fonts">
        <TextMedium>TextMedium</TextMedium>
        <TextBold>TextBold</TextBold>
        <TextDemiBold>TextDemiBold</TextDemiBold>
      </StyleGuideSection>

      <StyleGuideSection title="Texts styles new">
        <TextSection type="h1" />
        <View
          style={{ backgroundColor: Colors.gray20, paddingTop: Spacings.S16 }}
        >
          <TextSection type="h1white" />
        </View>
        <TextSection type="h2" />
        <TextSection type="h3" />
        <TextSection type="h4" />
        <TextSection type="h5" />
        <TextSection type="h6" />
        <TextSection type="ingress" />
        <TextSection type="t1" />
        <TextSection type="t2" />
        <TextSection type="t3" />
        <TextSection type="t4" />
        <TextSection type="t5" />
      </StyleGuideSection>
      <StyleGuideSection title="Texts">
        <Title>Title</Title>
        <Title3>Title3</Title3>
        <Subtitle>Subtitle</Subtitle>
        <TextSmall>TextSmall</TextSmall>
        <TextError>TextError</TextError>
        <Paragraph>Paragraph usually with multiple line of text</Paragraph>
      </StyleGuideSection>
      <StyleGuideSection title="Buttons">
        <UiButton
          type="primary"
          title="Primary"
          onPress={onPress}
          loading={loading}
          containerStyle={{ marginBottom: Spacings.S16 }}
        />
        <UiButton
          type="secondary"
          title="Secondary"
          onPress={onPress}
          loading={loading}
          containerStyle={{ marginBottom: Spacings.S16 }}
        />

        <UiButton
          type="disabled"
          title="Disabled"
          onPress={onPress}
          loading={loading}
          containerStyle={{ marginBottom: Spacings.S16 }}
        />
        <UiButton
          type="secondaryplain"
          title="Secondaryplain"
          onPress={onPress}
          loading={loading}
          containerStyle={{ marginBottom: Spacings.S16 }}
        />
        <View style={{ backgroundColor: 'black', padding: Spacings.S16 }}>
          <UiButton
            type="secondarywhite"
            title="Secondarywhite"
            onPress={onPress}
            loading={loading}
          />
        </View>
      </StyleGuideSection>
      <StyleGuideSection title="Boxes">
        <BoxWithPadding style={{ marginBottom: Spacings.S16 }}>
          <TextMedium>Box with padding</TextMedium>
        </BoxWithPadding>

        <BoxWithPadding style={{ marginBottom: Spacings.S16 }}>
          <LabelValue label="Arvo" value={formatCurrency(6864.24)} />
        </BoxWithPadding>
      </StyleGuideSection>
      <FlexRow
        style={{
          backgroundColor: Colors.primary,
          marginTop: 50,
          padding: 10,
        }}
      >
        {percentages.map((p) => {
          return (
            <PercentagePieWithNumber
              key={p}
              percentages={percentages}
              percentage={p}
              theme="white"
            />
          )
        })}
      </FlexRow>
      <StyleGuideSection>
        <Title>First title of the page including top margin (Title)</Title>
        <Paragraph>
          Aseta passi tai kuvallinen henkilökortti kokonaan kuva-alueen
          sisäpuolelle. (Paragraph)
        </Paragraph>

        <Subtitle>Yhteenveto (Subtitle)</Subtitle>
        <Paragraph>
          Aseta passi tai kuvallinen henkilökortti kokonaan kuva-alueen
          sisäpuolelle. (Paragraph)
        </Paragraph>
      </StyleGuideSection>
      <StyleGuideSection title="SDG Icons">
        <FlexRow style={{ justifyContent: 'space-between' }}>
          {new Array(17).fill(0).map((_, i) => (
            <SdgIcon sdgNumber={i + 1} key={i} />
          ))}
        </FlexRow>
      </StyleGuideSection>
    </PaddingHorizontal>
  )
}
