import React from 'react'
import OptionQuestionScreen from './OptionQuestionScreen'
import {
  findQuestionById,
  isTagSelectQuestion,
} from '@taaleri/components/src/screens/questions/questions'

import { useParams, useHistory } from 'react-router-dom'
import { OptionQuestion } from '@taaleri/core/src/models/Question'
import TagSelectQuestionScreen from './TagSelectQuestionScreen'
import { useTranslation } from 'react-i18next'

export default function OptionQuestionByParamScreen() {
  const { t } = useTranslation()
  const { questionId } = useParams<{ questionId: string }>()
  const history = useHistory()
  const question = findQuestionById(questionId, t)

  if (isTagSelectQuestion(question)) {
    return (
      <TagSelectQuestionScreen question={question} navigate={history.push} />
    )
  }
  return (
    <OptionQuestionScreen
      history={history}
      optionQuestion={question as OptionQuestion}
    />
  )
}
