import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from '../../ui/text/UiText'
import UiButton from '../../ui/UiButton'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import {
  QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY,
  nextQuestionRoute,
} from './questions'
import { ProductKnowledgeOption } from '@taaleri/core/src/constants/Options'
import useNavigation from '../../navigation/useNavigation'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { WarningContainer } from '../../ui/WarningContainer'
import { useTranslation } from 'react-i18next'

export default function AssetManagementInfo() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <WarningContainer>
      <TextDefault type="h5" style={{ marginBottom: Spacings.S16 }}>
        {t('questions.asset-management-info-title')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.asset-management-info-text1')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.asset-management-info-text2')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.asset-management-info-text3')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.asset-management-info-text4')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S32 }}>
        {t('questions.asset-management-info-text5')}
      </TextDefault>
      <UiButton
        title={t('button.yes-continue')}
        onPress={() => {
          Analytics.event('Onboarding', 'AssetManagementInfo', 'Yes')
          QuestionStore.setAnswer(
            QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY,
            ProductKnowledgeOption.Knows
          )
          navigate(
            nextQuestionRoute(
              QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY
            )
          )
        }}
      />
    </WarningContainer>
  )
}
