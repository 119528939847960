import React from 'react'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import * as Yup from 'yup'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, View } from 'react-native'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import {
  INTENDED_USE_OF_CASH_OPTIONS,
  PurposeOfForeignMoneyTransfers,
} from '@taaleri/core/src/constants/Options'
import CheckListMultiple from '../../ui/form/CheckListMultiple'
import { useTranslation } from 'react-i18next'

export const questionCashPurposeValidationSchema = Yup.object().shape({
  intendedUseOfCash: Yup.array()
    .required(ErrorMessages.required)
    .test(
      'hasElements',
      ErrorMessages.required,
      (val) => val !== undefined && val.length > 0
    ),
  intendedUseOfCashOther: Yup.string(),
})

export function toInitArrayValue(value: string[]) {  
  if (!value) {    
    return []
  }
  return value
}

export function toInitValue(value: string) {  
  if (!value) {    
    return ''
  }
  return value
}

export function toSaveValue(value: any) {
  if (!value) {
    if (value.length === 0) return []
    return ''
  }
  return value
}

export interface CashPurpose {
  intendedUseOfCash: string[]
  intendedUseOfCashOther: string
}

export default function QuestionCashPurposeContent(props: {
  values: CashPurpose
}) {
  const { t } = useTranslation()
  return (
    <View>
      <BoxWithPaddingMargin
        title={`${t('questions.cashPurpose.question')}`}
        titleStyle={styles.titleMargin}
      >
        <CheckListMultiple
          name="intendedUseOfCash"
          options={INTENDED_USE_OF_CASH_OPTIONS}
          lastBottomDivider={true}
        />
        {props.values.intendedUseOfCash.length > 0 &&
          props.values.intendedUseOfCash.some(
            (i) => i === PurposeOfForeignMoneyTransfers.Other
          ) && (
            <UiTextInputForm
              name="intendedUseOfCashOther"
              label={`${t('questions.cashPurpose.additional-info')}`}
              type="name"
              infoLabel={[
                {
                  text: 'questions.cashPurpose.additional-info-info',
                },
              ]}
            />
          )}
      </BoxWithPaddingMargin>
    </View>
  )
}

const styles = StyleSheet.create({
  titleMargin: {
    marginBottom: Spacings.S24,
  },
})
