import React from 'react'
import { TextDefault, TextType } from '../../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'

interface Props {
  text: string
  flex?: number
  type?: TextType
}

export function HeaderItem({ text, flex = 1, type = 't5' }: Props) {
  return (
    <TextDefault type={type} style={{ flex, paddingHorizontal: Spacings.S2 }}>
      {text}
    </TextDefault>
  )
}
