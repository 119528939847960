import React, { useEffect } from 'react'
import { View, StyleSheet, ImageBackground } from 'react-native'
import UiButton from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_IMPACT_TOGGLE_ON } from './ImpactRoutes'
import Colors from '@taaleri/core/src/constants/Colors'
import ImpactBenefits from './ImpactBenefits'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Title3 } from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ImpactCompensationCard from './ImpactCompensationCard'
import { impactDefaultImage } from './impact'
import ImpactAssetDetails from './ImpactAssetDetails'
import { TextDefault, TextWithBorder } from '../../ui/text/UiText'
import useImpactStrategy from './useImpactStrategy'
import LoadingIndicator from '../../ui/LoadingIndicator'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import {
  calculateCo2Compensation,
  ImpactParameters,
} from '../questions/impactCalculator'
import useLayout from '../../ui/useLayout'
import ImpactAsNumbers from './ImpactAsNumbers'
import ImpactIntroGradient from './ImpactIntroGradient'
import { useTranslation } from 'react-i18next'

function InvestmentPlanContent() {
  const { t } = useTranslation()
  const { isWide } = useLayout()
  const textAlign = isWide ? 'center' : 'left'
  const maxWidth = 720
  return (
    <View style={{ maxWidth, marginHorizontal: 'auto' }}>
      <TextWithBorder variant="centeredDesktop">
        {t('profile.investmentSection.invest-plan')}
      </TextWithBorder>

      <TextDefault type="h1" style={{ marginBottom: Spacings.S24, textAlign }}>
        {t('impactIntro.infobox5.investmentplan.header')}
      </TextDefault>
      <Paragraph style={{ textAlign }}>
        {t('impact.investmentplan-text1')}
      </Paragraph>
      <Paragraph style={{ textAlign }}>
        {t('impact.investmentplan-text2')}
      </Paragraph>
    </View>
  )
}

interface BackgroundProps {
  isWide: boolean
  children: any
  backgroundColor?: string
  isGradient?: boolean
}

function ColoredBackground({
  children,
  backgroundColor,
  isWide,
}: BackgroundProps) {
  return (
    <View style={{ backgroundColor }}>
      <View style={isWide ? styles.wideContainer : styles.mobileContainer}>
        {children}
      </View>
    </View>
  )
}

function GradientBackground({ children, isWide }: BackgroundProps) {
  return (
    <ImpactIntroGradient>
      <View style={isWide ? styles.wideContainer : styles.mobileContainer}>
        {children}
      </View>
    </ImpactIntroGradient>
  )
}

function ChangeToImpactButton(props: any) {
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()
  return (
    <UiButton
      {...props}
      title={`${t('impactIntro.infobox1.impakti-call-to-action-button')}`}
      onPress={() => {
        navigateApp(ROUTE_IMPACT_TOGGLE_ON)
      }}
    />
  )
}

export default function ImpactIntroContent() {
  const { impactStrategy, error, setRetry } = useImpactStrategy()
  const { isWide } = useLayout()
  const { t } = useTranslation()

  useEffect(() => {
    setRetry(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  if (!impactStrategy) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const impactParameters: ImpactParameters = {
    impactShare: 100 * impactStrategy.impactShare,
    expectedReturn: impactStrategy.expectedReturn,
    initialInvestment: AppStore.portfolioMarketValue,
    monthlyInvestment: 0,
    investmentTime: 20,
    actualizedCo2Kilos: AppStore.customerCo2Actualized,
  }
  const co2Compensation = calculateCo2Compensation(impactParameters)
  const co2CompensationFirstYear = calculateCo2Compensation({
    ...impactParameters,
    investmentTime: 1,
  })
  const desktopButtonWidth = 250

  return (
    <>
      <ImageBackground
        source={impactDefaultImage}
        style={[
          styles.image,
          {
            height: isWide ? 214 : 128,
            paddingBottom: Spacings.S16,
          },
        ]}
      />
      <PaddingHorizontal
        style={{
          maxWidth: 640,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: -30,
          marginBottom: Spacings.S48,
        }}
      >
        <ImpactCompensationCard
          co2Compensation={co2CompensationFirstYear}
          isWide={isWide}
          sdgCount={impactStrategy.sustainableDevelopmentGoals.length}
        />
        {/* <ChangeToImpactButton
          containerStyle={{
            marginTop: Spacings.S24,
            maxWidth: isWide ? desktopButtonWidth : 445,
            width: '100%',
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }}
        /> */}
      </PaddingHorizontal>
      <ColoredBackground backgroundColor={Colors.white} isWide={isWide}>
        <ImpactBenefits isWide={isWide} />
        {/* <ChangeToImpactButton
          containerStyle={{
            marginTop: isWide ? Spacings.S48 : Spacings.S16,
            marginBottom: isWide ? Spacings.S64 : 0,
            maxWidth: isWide ? desktopButtonWidth : 445,
            width: '100%',
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }}
        /> */}
      </ColoredBackground>
      <ColoredBackground backgroundColor={Colors.greenLight} isWide={isWide}>
        <ImpactAsNumbers
          impactStrategy={impactStrategy}
          co2Compensation={co2Compensation}
        />
      </ColoredBackground>
      <GradientBackground isWide={isWide}>
        <InvestmentPlanContent />
        <Title3 style={{ marginTop: Spacings.S16, marginBottom: Spacings.S24 }}>
          {t('impactIntro.responsiblestocks.preheader')}
        </Title3>
        <ImpactAssetDetails impactStrategy={impactStrategy} />
        {/* <ChangeToImpactButton
          containerStyle={{
            marginTop: Spacings.S24,
            marginBottom: Spacings.S48,
            maxWidth: isWide ? desktopButtonWidth : 445,
            width: '100%',
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }}
        /> */}
      </GradientBackground>
    </>
  )
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: 285,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wideContainer: {
    paddingVertical: 60,
    maxWidth: 1200,
    marginHorizontal: 'auto',
    paddingHorizontal: Spacings.S32,
    width: '100%',
  },
  mobileContainer: {
    paddingVertical: 60,
    marginHorizontal: Spacings.S24,
  },
})
