import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet } from 'react-native'

import { PaddingHorizontal } from '../PaddingHorizontal'
import { TextDefault } from './UiText'

const HelpText: React.FC = ({ children }) => (
  <PaddingHorizontal>
    <TextDefault type="t2" style={styles.title}>
      {children}
    </TextDefault>
  </PaddingHorizontal>
)

export const HelpTextNoPadding: React.FC = ({ children }) => (
  <TextDefault type="t2" style={styles.title}>
    {children}
  </TextDefault>
)

export default HelpText

export const styles = StyleSheet.create({
  title: {
    color: Colors.textSecondary,
    paddingVertical: Spacings.S16,
  },
})
