import React, { useEffect, useState } from 'react'

import { customers } from '@taaleri/core/src/services/customers'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import LoadingIndicator from '../../ui/LoadingIndicator'
import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_CREATING_CUSTOMER,
} from './OnboardingRoutes'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { useTranslation } from 'react-i18next'

function CreatingCustomerContent({
  navigate,
}: {
  navigate: (path: string) => void
}) {
  const { t } = useTranslation()
  const [retry, setRetry] = useState<boolean>(false)
  const [hasError, setError] = useState<boolean>(false)
  useEffect(() => {
    let didSuccess = false
    const customerPoll = setInterval(async () => {
      setRetry(false)
      const customerId = QuestionStore.customer.customerId
      const { success, response, error } = await customers.pollCustomer(
        customerId
      )
      if (didSuccess) {
        return
      }

      if (success && response) {
        didSuccess = true
        clearInterval(customerPoll)
        const customer = response
        AppStore.setCustomer(customer)
        await AppStore.signInCustomer(customer)
        AppStore.investmentProposalSaved = QuestionStore.investmentProposal
        AppStore.impactShare = QuestionStore.impactShare
        AppStore.riskLevel = QuestionStore.riskLevel
        AppStore.showOnboardingSuccessModal = true
        AppStore.isOnboardingSignedIn = false
        QuestionStore.preventRestart = true
        navigate(nextOnboardingRoute(ROUTE_ONBOARDING_CREATING_CUSTOMER))
        return
      }
      if (error !== 'CONFLICT') {
        setError(true)
        clearInterval(customerPoll)
      }
    }, 1000)
    return () => {
      didSuccess = true
      clearInterval(customerPoll)
    }
  }, [retry])

  if (hasError) {
    return (
      <ErrorWithRetry
        onRetry={async () => {
          setError(false)
          setRetry(true)
        }}
      />
    )
  }

  return <LoadingIndicator text={`${t('questions.accountCreation.confirming')}`} />
}

export default CreatingCustomerContent
