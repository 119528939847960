import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '../../ui/useLayout'
import { TextDefault } from '../../ui/text/UiText'
import UiTouchable from '../../ui/UiTouchable'
import { useTranslation } from 'react-i18next'

export interface InvestmentTimeItem {
  title: string
  time: number
}

export function InvestmentTimeSelector({
  item,
  investmentTime,
  setInvestmentTime,
}: {
  item: InvestmentTimeItem
  investmentTime: number
  setInvestmentTime: (time: number) => void
}) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const isSelected = investmentTime === item.time
  const style = {
    backgroundColor: isSelected ? Colors.white : Colors.backgroundPale,
    paddingVertical: Spacings.S10,
    paddingHorizontal: Spacings.S20,
    marginRight: Spacings.S8,
    borderTopColor: Colors.primary,
    borderTopWidth: isSelected ? Spacings.S2 : 0,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: isSmall ? 6 : 0,
    borderBottomLeftRadius: isSmall ? 6 : 0,
  }
  return (
    <UiTouchable style={style} onPress={() => setInvestmentTime(item.time)}>
      <TextDefault type="h6">{t(item.title, { year: item.time })}</TextDefault>
    </UiTouchable>
  )
}
