import React from 'react'
import { ScrollView, ScrollViewProps } from 'react-native'

const UiScrollView: React.FC<ScrollViewProps> = (props) => (
  <ScrollView
    showsVerticalScrollIndicator={false}
    {...props}
    keyboardShouldPersistTaps="always"
  />
)

export default UiScrollView
