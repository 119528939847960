import React, { useEffect, useState } from 'react'
import Title from '@taaleri/components/src/ui/text/Title'
import QuestionContainer from './QuestionContainer'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import { customers } from '@taaleri/core/src/services/customers'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { View } from 'react-native'
import { removeIdentity } from '@taaleri/core/src/services/storage'
import { IntroImage } from '@taaleri/components/src/screens/questions/IntroImage'
import { getConfig } from '@taaleri/core/src/config'
import ConfigStore from '@taaleri/core/src/stores/ConfigStore'
import UiListItemSwitch from '@taaleri/components/src/ui/form/UiListItemSwitch'
import { Formik } from 'formik'
import useQueryParams from 'navigation/useQueryParams'
import ErrorMessage from '@taaleri/components/src/ui/form/ErrorMessage'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { useDidUpdateEffect } from '@taaleri/components/src/ui/hooks/useDidUpdateEffect'
import logger from '@taaleri/core/src/utils/logger'
import { pushIdentificationClickToDataLayer } from 'utils/DataLayer'
import { useTranslation } from 'react-i18next'

export default function ToInvestmentProposalScreen() {
  const { t } = useTranslation()
  const [url, setUrl] = useState<string | undefined>()
  removeIdentity()
  useEffect(() => {
    const getTupasUrl = async () => {
      const tupasUrl = await customers.getTupasUrl()
      setUrl(tupasUrl)
    }
    getTupasUrl()
  }, [])
  const image = require('./identification.png')
  const params = useQueryParams()

  useDidUpdateEffect(() => {
    if (params?.result === 'error') {
      const message = 'Authentication failed'
      logger.error(new Error(message), message)
    }
  }, [params])

  return (
    <QuestionContainer>
      <TopBottomContainer>
        <View>
          <IntroImage image={image} />
          <Title>{t('questions.investmentProposal.title')}</Title>
          <Paragraph>
            {t('questions.investmentProposal.thanks')}
          </Paragraph>
          <Paragraph>
            {t('questions.investmentProposal.tupas-text')}
          </Paragraph>
        </View>
        {params?.result === 'error' ? (
          <ErrorMessage
            message={ErrorMessages.authenticationFailed}
            hideIcon={true}
          />
        ) : null}
        {getConfig().testMode && (
          <Formik
            initialValues={{
              useActualTupasResult: ConfigStore.useActualTupasResult,
            }}
            onSubmit={() => {
              return
            }}
          >
            <UiListItemSwitch
              name="useActualTupasResult"
              title={t('questions.investmentProposal.use-actual-tupas')}
              onChangeText={async (value) => {
                ConfigStore.useActualTupasResult = value
                const tupasUrl = await customers.getTupasUrl()
                setUrl(tupasUrl)
              }}
            />
          </Formik>
        )}
        {url && (
          <Buttons
            title={t('button.next')}
            onPress={() => {
              pushIdentificationClickToDataLayer()
              window.location.href = url
            }}
          />
        )}
      </TopBottomContainer>
    </QuestionContainer>
  )
}
