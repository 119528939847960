import React from 'react'
import DoneScreen from 'screens/profile/DoneScreen'
import SuccessMessage from '@taaleri/components/src/components/SuccessMessage'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import { PaymentSteps } from '@taaleri/components/src/components/Steps'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

interface Props {
  isRecurring: boolean
}

export default function PaymentDoneScreen({ isRecurring }: Props) {
  const { t } = useTranslation()
  return (
    <>
      <PaymentSteps currentStep={3} style={{ marginBottom: Spacings.S24 }} />
      <DoneScreen nextScreen={''}>
        <SuccessMessage
          title={
            isRecurring
              ? t('invest.monthly-invest-done-title')
              : t('invest.payment-done-title')
          }
        >
          <Paragraph>{t('invest.payment-done-text')}</Paragraph>
        </SuccessMessage>
      </DoneScreen>
    </>
  )
}
