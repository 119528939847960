import Colors from '@taaleri/core/src/constants/Colors'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import AppStore from '@taaleri/core/src/stores/AppStore'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'

import { getTransactionPdfUrl } from './TransactionPdfUrl'
import { IconExternalLink } from '../../ui/IconExternalLink'
import OpenLink from '../../ui/OpenLink'
import UiTouchable from '../../ui/UiTouchable'

interface Props {
  transaction: Transaction
  style?: StyleProp<ViewStyle>
}

function TransactionPdfButton({ transaction, style }: Props) {
  const { transactionId, isDocumentAvailable } = transaction
  if (!isDocumentAvailable) {
    return <View style={style} />
  }

  return (
    <UiTouchable
      style={style}
      onPress={() => {
        const url = getTransactionPdfUrl(
          AppStore.customerId,
          transactionId,
          TransactionStore.token
        )
        OpenLink(url, 'transaction-pdf')
      }}
    >
      <IconExternalLink color={Colors.gray50} />
    </UiTouchable>
  )
}

export default TransactionPdfButton
