import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  ROUTE_QUESTIONS,
  ROUTE_SIGN_IN,
  ROUTE_APP,
} from '@taaleri/components/src/navigation/routes'
import {
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
  ROUTE_QUESTION_EXISTING_CUSTOMER,
  ROUTE_QUESTION_EXISTING_AUTHORIZATION,
} from '@taaleri/components/src/screens/questions/QuestionRoutes'
import {
  ROUTE_PORTFOLIO_PAYMENT_BANK,
  ROUTE_PORTFOLIO_WAIT_PAYMENT_STATUS,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import useQueryParams from './useQueryParams'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import {
  ROUTE_PROFILE_CHANGE_PHONE_DONE,
  ROUTE_PROFILE_CHANGE_PHONE_ERROR,
  ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE,
} from '@taaleri/components/src/screens/profile/ProfileRoutes'
import routePath from '@taaleri/components/src/navigation/routeHelper.native'

function IdentificationRouter() {
  const params = useQueryParams()
  const { result, token, paymentResult, firstPayment, context, requestId } =
    params

  const bankSelectionPath = ROUTE_APP + '/' + ROUTE_PORTFOLIO_PAYMENT_BANK
  const waitPaymentStatusPath = routePathApp(
    ROUTE_PORTFOLIO_WAIT_PAYMENT_STATUS
  )
  const homePath = ROUTE_APP
  const investmentProposalPath =
    ROUTE_QUESTIONS + '/' + ROUTE_QUESTION_INVESTMENT_PROPOSAL + 'Show'
  const investmentProposalErrorPath =
    ROUTE_QUESTIONS + '/' + ROUTE_QUESTION_INVESTMENT_PROPOSAL
  const existingCustomerPath =
    ROUTE_QUESTIONS + '/' + ROUTE_QUESTION_EXISTING_CUSTOMER
  const existingAuthorizationPath =
    ROUTE_QUESTIONS + '/' + ROUTE_QUESTION_EXISTING_AUTHORIZATION

  if (context && context === 'phonenumber') {
    if (result && result === 'error') {
      return <Redirect to={routePathApp(ROUTE_PROFILE_CHANGE_PHONE_ERROR)} />
    }
    return <Redirect to={routePathApp(ROUTE_PROFILE_CHANGE_PHONE_DONE)} />
  }
  if (context && context === 'bankaccount') {
    if (result && result === 'error') {
      return <Redirect to={routePathApp(ROUTE_PROFILE_CHANGE_PHONE_ERROR)} />
    }
    return (
      <Redirect to={routePathApp(ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE)} />
    )
  }
  if (
    context &&
    context === 'existingAuthorizations' &&
    result &&
    result === 'error'
  ) {
    return <Redirect to={{ pathname: existingAuthorizationPath }} />
  }

  if (token) {
    return <Redirect to={{ pathname: existingCustomerPath }} />
  }

  if (paymentResult) {
    if (paymentResult === 'true') {
      let search = '?paymentResult=true'
      if (firstPayment === 'true') {
        search += '&firstPayment=true'
      }
      return (
        <Redirect
          to={{
            pathname: homePath,
            search,
          }}
        />
      )
    } else {
      if (requestId) {
        return (
          <Redirect
            to={{
              pathname: waitPaymentStatusPath,
              search: `?paymentResult=false&requestId=${requestId}`,
            }}
          />
        )
      }
      return (
        <Redirect
          to={{ pathname: bankSelectionPath, search: '?paymentResult=false' }}
        />
      )
    }
  }

  switch (result) {
    case 'newCustomer':
      return (
        <Redirect
          to={{
            pathname: investmentProposalPath,
            search: '?result=newCustomer',
          }}
        />
      )
    case 'error':
      return (
        <Redirect
          to={{
            pathname: investmentProposalErrorPath,
            search: `?result=${result}`,
          }}
        />
      )
    case 'notfound':
      return (
        <Redirect to={{ pathname: ROUTE_SIGN_IN, search: '?error=notfound' }} />
      )
    default:
      return <Redirect to={routePath(ROUTE_SIGN_IN)} />
  }
}

export default IdentificationRouter
