import React, { useEffect, useState } from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'

interface Props extends RedirectProps {
  condition: boolean
}

export function RedirectIf({ condition, ...redirectProps }: Props) {
  const [redirect, setRedirect] = useState<boolean>(false)

  useEffect(() => {
    function isInvestmentGoalGiven() {
      if (condition) {
        setRedirect(true)
      }
    }
    isInvestmentGoalGiven()
  }, [condition])

  if (redirect) {
    return <Redirect {...redirectProps} />
  }
  return null
}
