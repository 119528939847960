import React from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import PaymentInfoSection from './PaymentInfoSection'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useFund } from '../funds/useFunds'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function PaymentInformationContent() {
  const { t } = useTranslation()
  const { fund } = useFund(AppStore.fundId)
  if (!AppStore.fundId) {
    return null
  }
  if (!fund) {
    return null
  }

  return (
    <View>
      <Title>{t('steps.payment')}</Title>
      <Paragraph>
        {t('invest.payment-info', {
          amount: formatCurrency(AppStore.newInvestment),
          fund: fund.name,
        })}        
      </Paragraph>
      <PaymentInfoSection isConsultative={true} sum={AppStore.newInvestment} />
    </View>
  )
}

export default observer(PaymentInformationContent)
