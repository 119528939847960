import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import useLayout from '../../ui/useLayout'
import { OwnershipRowStyles } from './OwnershipRow'
import { HeadingItem, HeadingText } from './OwnerShipHeadingSimple'

const styles = OwnershipRowStyles

const headingItems: HeadingItem[] = [
  {
    text: 'portfolio.instrument-name',
    style: styles.title,
  },
  {
    text: 'netWorth',
  },
  {
    text: 'portfolio.share',
    style: styles.numberNarrow,
  },
]

export function CashHeading() {
  const { isSmall } = useLayout()

  if (isSmall) {
    return null
  }

  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingBottom: Spacings.S16,
        marginRight: Spacings.S24,
      }}
    >
      {headingItems.map((props) => (
        <HeadingText {...props} key={props.text} />
      ))}
    </FlexRow>
  )
}
