import { Identity } from '../models/Identity'
import { api } from '../api/api'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { ServiceResponse } from '../models/ServiceResponse'
import { DiscountCodeValidationResponse } from '../models/DiscountCodeValidationResponse'
import { Discount } from '../models/Discount'
import { DiscountCodeResponse } from '../models/DiscountCodeResponse'

export async function useDiscountCode(
  customerId: number,
  discountCode: string
): Promise<ServiceResponse<DiscountCodeValidationResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().discountCode.useDiscountCode(
      identity,
      customerId,
      discountCode
    )
    return { success: true, response }
  } catch (error) {
    handleErrors(error, 'useDiscountCode')
    return { success: false, error }
  }
}

export async function validateDiscountCode(
  discountCode: string
): Promise<ServiceResponse<DiscountCodeValidationResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().discountCode.validateDiscountCode(
      identity,
      discountCode
    )
    return { success: true, response }
  } catch (error) {
    handleErrors(error, 'validateDiscountCode')
    return { success: false, error }
  }
}

export async function getDiscounts(
  customerId: number
): Promise<ServiceResponse<Discount[]>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().discountCode.getDiscounts(identity, customerId)
    return { success: true, response }
  } catch (error) {
    handleErrors(error, 'getDiscounts')
    return { success: false, error }
  }
}

export async function getDiscountCode(
  customerId: number
): Promise<ServiceResponse<DiscountCodeResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().discountCode.getDiscountCode(
      identity,
      customerId
    )
    return { success: true, response }
  } catch (error) {
    handleErrors(error, 'getDiscountCode')
    return { success: false, error }
  }
}
