import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { AssetClassIconEmpty } from '../../report/AssetClassIcon'
import PositionContainer from '../../report/PositionContainer'
import FilterButton from '../../report/FilterButton'

function InstrumentFilter() {
  const { allInstruments, selectedInstruments } = TransactionStore

  if (allInstruments.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allInstruments
        .filter((a) => !selectedInstruments.some((e) => e.value === a.value))
        .map((instrument) => (
          <FilterButton
            key={instrument.value}
            onPress={() => TransactionStore.addSelectedInstrument(instrument)}
            selected={false}
          >
            <AssetClassIconEmpty />
            <TextDefault
              type="t2"
              style={{
                color: Colors.text,
                letterSpacing: 0.2,
              }}
            >
              {instrument.label}
            </TextDefault>
          </FilterButton>
        ))}
    </PositionContainer>
  )
}

export default observer(InstrumentFilter)
