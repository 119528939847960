import { View, StyleProp, ViewStyle } from 'react-native'

import Svg from 'react-native-svg'

import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'

import {
  AssetClassPercentage,
  assetClassColors,
} from '@taaleri/core/src/models/assetClassColors'
import PercentagePie from './PercentagePie'
import Colors from '@taaleri/core/src/constants/Colors'

interface Props {
  assetClasses: AssetClassPercentage[]
  percentage: number
  size?: number
  borderWidth?: number
  style?: StyleProp<ViewStyle>
  colorScale?: string[]
}

export interface PercentagePieProps {
  pieRadius: number
  size: number
  borderSize: number
  colorScale: string[]
  data: { x: string; y: number }[]
}

function PercentagePieAsset({
  assetClasses,
  percentage,
  size = 68,
  style,
  borderWidth,
  colorScale,
}: Props) {
  const pieRadius = size / 2
  const data = assetClasses.map((p) => {
    const x = p.percentage === percentage ? 'current' : 'other'
    return { x, y: Math.ceil(p.percentage) }
  })
  const colorScaleSelected =
    colorScale || assetClasses.map((p) => assetClassColors[p.id] || Colors.text)

  const borderSize = borderWidth ? borderWidth : Spacings.S16

  return (
    <View style={style}>
      <Svg width={size} height={size}>
        <PercentagePie
          size={size}
          pieRadius={pieRadius}
          data={data}
          colorScale={colorScaleSelected}
          borderSize={borderSize}
        />
      </Svg>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </View>
  )
}

export default PercentagePieAsset
