import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { View } from 'react-native'
import * as Yup from 'yup'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { UiTextInputForm } from '../../../ui/form/UiTextInput'
import UiButton from '../../../ui/UiButton'
import { EmojiResponse } from '@taaleri/core/src/models/EmojiResponse'
import UiForm from '../../../ui/form/UiForm'
import { customers } from '@taaleri/core/src/services/customers'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ErrorMessage from '../../../ui/form/ErrorMessage'
import { isWeb } from '../../../constants/Platforms'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    answer: Yup.string().required(`${t(ErrorMessages.required)}`),
  })

interface Props {
  placeholder: string
  emojiResponse: EmojiResponse
  onSuccess: () => void
}

interface SimpleFeedback {
  answer: string
}

export default function SimpleFeedbackForm(props: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)
  const initialValues: SimpleFeedback = { answer: '' }
  return (
    <View style={{ marginVertical: Spacings.S24 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={async (
          values: SimpleFeedback,
          actions: FormikHelpers<SimpleFeedback>
        ) => {
          actions.setSubmitting(true)
          setError(false)
          const { success } = await customers.sendFeedbackWithEmoji(
            props.placeholder,
            values.answer,
            AppStore.customerId,
            props.emojiResponse
          )
          if (!success) {
            setError(true)
          } else {
            actions.setSubmitting(false)
            props.onSuccess()
            Analytics.event('Feedback', 'sendSimple')
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <UiForm>
            <UiTextInputForm
              name="answer"
              type="name"
              hideCheckCircle={true}
              multiline={true}
              numberOfLines={5}
              height={88}
              placeholder={props.placeholder}
            />
            {error && <ErrorMessage message={t(ErrorMessages.unexpectedError)} />}
            <UiButton
              title={t('feedback.send')}
              loading={isSubmitting}
              disabled={isSubmitting}
              // tslint:disable-next-line
              onPress={isWeb ? () => {} : handleSubmit}
            />
          </UiForm>
        )}
      </Formik>
    </View>
  )
}
