import { Formik, FormikHelpers } from 'formik'
import ErrorMessage from '@taaleri/components/src/ui/form/ErrorMessage'
import React, { useState } from 'react'

import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Spacings from '@taaleri/core/src/constants/Spacings'
import ChangePassword from '@taaleri/core/src/models/ChangePassword'
import * as Yup from 'yup'

import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import UiButton from '@taaleri/components/src/ui/UiButton'
import { UiTextInputFormAutoFocus } from '@taaleri/components/src/ui/form/UiTextInput'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Title from '../../ui/text/Title'
import { authentication } from '@taaleri/core/src/services/authentication'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper.native'
import { ROUTE_PROFILE_CHANGE_PASSWORD_DONE } from './ProfileRoutes'
import { isWeb } from '../../constants/Platforms'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    oldPassword: Yup.string()
      .min(10, `${t(ErrorMessages.passwordAtLeastNChars)}`)
      .required(`${t(ErrorMessages.missingPassword)}`),
    newPassword: Yup.string()
      .min(10, `${t(ErrorMessages.passwordAtLeastNChars)}`)
      .required(`${t(ErrorMessages.missingPassword)}`)
      .notOneOf(
        [Yup.ref('oldPassword'), null],
        `${t('validation.new-not-same-as-old')}`
      ),
  })

function ChangePasswordForm() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const [error, setError] = useState<string | undefined>()
  const { isNonSmall } = useLayout()

  const initialValues: ChangePassword = {
    oldPassword: '',
    newPassword: '',
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={async (
          values: ChangePassword,
          actions: FormikHelpers<ChangePassword>
        ) => {
          try {
            const apiResponse = await authentication.changePasswordSimple(
              values
            )
            Analytics.event('Profile', 'Change Password')
            actions.setSubmitting(false)

            if (apiResponse.success) {
              navigate(routePathApp(ROUTE_PROFILE_CHANGE_PASSWORD_DONE))
            } else {
              actions.setFieldError(
                'oldPassword',
                `${t('validation.wrong-password')}`
              )
            }
          } catch (err) {
            actions.setSubmitting(false)
            setError(ErrorMessages.unexpectedError)
          }
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <UiForm>
            <FormNextInputAutofocus>
              <Title>{t('profile.settingsSection.change-password')}</Title>
              <Paragraph style={{ marginBottom: Spacings.S24 }}>
                {t(
                  'app.questions.onboardingregister.createsmartusername-short'
                )}
              </Paragraph>
              <UiTextInputFormAutoFocus
                name="oldPassword"
                type="password"
                textContentType="password"
                label={`${t('profile.current-password')}`}
                secureTextEntry={isSubmitting}
                useEyeOnOff={!isSubmitting}
                grayBackground={!isNonSmall}
                editable={!isSubmitting}
              />
              <UiTextInputFormAutoFocus
                name="newPassword"
                type="password"
                textContentType="password"
                label={`${t('question.credentials.new-password')}`}
                secureTextEntry={isSubmitting}
                useEyeOnOff={!isSubmitting}
                grayBackground={!isNonSmall}
                editable={!isSubmitting}
              />
              {error && <ErrorMessage message={error} />}

              <UiButton
                title={`${t('profile.settingsSection.change-password')}`}
                loading={isSubmitting}
                disabled={isSubmitting}
                onPress={isWeb ? () => '' : handleSubmit}
                containerStyle={[
                  { marginTop: Spacings.S32 },
                  isNonSmall && { alignSelf: 'flex-end' },
                ]}
              />
            </FormNextInputAutofocus>
          </UiForm>
        )}
      </Formik>
    </>
  )
}

export default ChangePasswordForm
