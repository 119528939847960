import React from 'react'
import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_CAPITAL_INCOME } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import QuestionCapitalIncomeContent, {
  CapitalIncome,
  questionCapitalIncomeValidationSchema,
  toInitValue,
  toSaveValue,
} from '@taaleri/components/src/screens/questions/QuestionCapitalIncomeContent'
import { useHistory } from 'react-router-dom'
import UiForm from '@taaleri/components/src/ui/form/UiForm'

function QuestionCapitalIncomeScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const capitalIncome = toInitValue(QuestionStore.monthlyCapitalIncome)
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <Formik
          initialValues={{ capitalIncome }}
          validationSchema={questionCapitalIncomeValidationSchema}
          onSubmit={async (values: CapitalIncome) => {
            QuestionStore.monthlyCapitalIncome = toSaveValue(values.capitalIncome)
            history.push(nextQuestionRoute(ROUTE_QUESTION_CAPITAL_INCOME))
          }}
        >
          {({ values }) => {
            return (
              <UiForm>
                <QuestionCapitalIncomeContent values={values} />
                <Buttons title={t('button.next')} />
              </UiForm>
            )
          }}
        </Formik>
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(QuestionCapitalIncomeScreen)
