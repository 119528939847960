import React, { useState } from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import styled from 'styled-components'
import Colors from '@taaleri/core/src/constants/Colors'
import {
  TextBold,
  TextDefault,
  TextMedium,
} from '@taaleri/components/src/ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const FileContainer = styled.div`
  margin-top: 40px;
  border: 1px solid ${Colors.text};
  cursor: pointer;
  width: 100%;
  @media (min-width: ${BreakPoint.S}px) {
    width: 200px;
  }
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (min-width: ${BreakPoint.S}px) {
    :hover {
      background: rgba(71, 55, 41, 0.05);
    }
    :active {
      background: rgba(71, 55, 41, 0.15);
    }
  }
`

const FileInput = styled.input`
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  height: 56px;
  width: 200px;
`

let currentFile: any

function UploadButton() {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string>()
  const fileMaxSize = 30000000 // 30 MB is the maximum .NET can handle

  const onFileChange = (e: any) => {
    const files = e.target.files
    const file = files[0]
    const reader = new FileReader()
    if (!file) {
      return null
    }
    reader.readAsDataURL(file)
    AppStore.photoId = null
    setErrorMessage(undefined)
    if (file.size < fileMaxSize) {
      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file,
        }

        currentFile = file.name

        const base64 = (fileInfo.base64 as string).split(',').pop() || ''

        AppStore.photoId = {
          uri: '',
          base64,
          width: 200,
          height: 200,
        }
      }
    } else {
      setErrorMessage(
        `${t('validation.file-too-large', {
          size: Math.round(file.size / 1000000),
          mazSize: Math.round(fileMaxSize / 1000000),
        })}`
      )
    }
  }

  return (
    <div>
      <FileContainer>
        <TextBold>{t('idUpdate.upload')}</TextBold>
        <FileInput
          type="file"
          accept="image/jpeg,image/png,application/pdf"
          onChange={onFileChange}
        />
      </FileContainer>
      {errorMessage ? (
        <TextDefault
          style={{
            marginTop: Spacings.S24,
            display: 'flex',
            color: Colors.error,
          }}
        >
          {errorMessage}
        </TextDefault>
      ) : null}
      {AppStore.photoId && (
        <TextMedium style={{ marginTop: Spacings.S24, display: 'flex' }}>
          {currentFile}
        </TextMedium>
      )}
    </div>
  )
}

export default observer(UploadButton)
