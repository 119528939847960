import React from 'react'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import { useHistory } from 'react-router-dom'
import TwoFactorToggleStartContent from '@taaleri/components/src/screens/profile/TwoFactorToggleStartContent'
import { ROUTE_TWO_FACTOR_TOGGLE } from '@taaleri/components/src/navigation/routes'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import { useTranslation } from 'react-i18next'

export default function TwoFactorToggleStartScreen() {
  const { t } = useTranslation()
  const { push } = useHistory()
  return (
    <BackgroundContainerApp>
      <TopBottomContainer>
        <TwoFactorToggleStartContent />
        <Buttons
          title={t('button.continue')}
          onPress={() => push(ROUTE_TWO_FACTOR_TOGGLE)}
        />
      </TopBottomContainer>
    </BackgroundContainerApp>
  )
}
