import { useState, useEffect } from 'react'
import { getFilteredCombinedIndex } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ABORTERROR } from '@taaleri/core/src/models/ServiceResponse'
import { CombinedIndex } from '@taaleri/core/src/models/CombinedIndex'

export function useCombinedIndexByTicker(ticker: string) {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [combinedIndex, setCombinedIndex] = useState<CombinedIndex>()

  useEffect(() => {
    loadCombinedIndex()
  }, [retry])

  async function loadCombinedIndex() {
    setRetry(false)
    setLoading(true)
    setError(false)
    try {
      const res = await getFilteredCombinedIndex(
        AppStore.customerId,
        [],
        '',
        '',
        '',
        ticker,
        '',
        ''
      )
      setCombinedIndex(res)
      setError(false)
      setLoading(false)
    } catch (error) {
      if (error === ABORTERROR) {
        setError(false)
      }
      setError(true)
      setLoading(false)
    }
  }
  return { error, loading, combinedIndex, setRetry }
}
