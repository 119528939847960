import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'

export type LinkAppType = 'primary' | 'white' | 'text'

const colorMap = {
  primary: { color: Colors.primary, hover: Colors.textPrimaryHover },
  white: { color: Colors.white, hover: Colors.white },
  text: { color: Colors.text, hover: Colors.textHover },
}

interface LinkAppProps extends NavLinkProps {
  type?: LinkAppType
}

const StyledLink = styled(NavLink)<{ type?: LinkAppType }>`
  font-family: 'calibre-regular';
  text-decoration: none;
  color: ${(props) =>
    props.type ? colorMap[props.type].color : Colors.primary};
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${(props) =>
        props.type ? colorMap[props.type].hover : Colors.primary};
        text-decoration: ${(props) =>
          props.type === 'white' ? 'underline' : 'none'};    
      }    
    }
  }
`

const LinkApp: React.FC<LinkAppProps> = (props: LinkAppProps) => {
  return (
    <StyledLink {...props} type={props.type}>
      {props.children}
    </StyledLink>
  )
}

export default LinkApp
