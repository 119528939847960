import React from 'react'
import { StyleSheet, View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from '../../ui/text/UiText'
import { useTranslation } from 'react-i18next'

export default function ImpactDisableContent() {
  const { t } = useTranslation()
  return (
    <View>
      <TextDefault type="h2" style={styles.title}>
        {t('profile.investmentSection.change-etf')}
      </TextDefault>
      <TextDefault type="t1" style={styles.paragraph}>
        {t('impact.disable-content-text1')}
      </TextDefault>
      <TextDefault type="t1" style={styles.paragraph}>
        {t('impact.disable-content-text2')}
      </TextDefault>
    </View>
  )
}

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: Spacings.S24,
  },
  title: {
    marginVertical: Spacings.S24,
  },
})
