import { Identity, IdentityResponse } from '../../models/Identity'
import { nowInSeconds } from '../../utils/date'

export function toIdentity(identityResponse: IdentityResponse): Identity {
  const fiveMinutesInSeconds = 60 * 5
  const fiveMinuteExpiration = Math.round(nowInSeconds() + fiveMinutesInSeconds)

  return {
    accessToken: identityResponse.token ? identityResponse.token : '',
    refreshToken: identityResponse.refreshToken
      ? identityResponse.refreshToken
      : '',
    expiresOn: identityResponse.expiresOn || fiveMinuteExpiration,
    tokenType: 'Bearer',
    // TODO: Remove defaulting to test customer 10000
    customerId: identityResponse.customerId
      ? identityResponse.customerId
      : 492967,
  }
}
