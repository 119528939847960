import React from 'react'
import QuestionContainer from 'screens/questions/QuestionContainer'
import BankTransferContent from '@taaleri/components/src/screens/onboarding/BankTransferContent'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'

export default function OnboardingTransactionScreen() {
  return (
    <QuestionContainer>
      <BankTransferContent
        nextRoute={routePath(ROUTE_APP)}
        isOnboarding={true}
      />
    </QuestionContainer>
  )
}
