import { getConfig } from '@taaleri/core/src/config'

import IOrdersApi from './IOrdersApi'
import { get } from './rest'
import { Identity } from '../models/Identity'
import { Order } from '../models/Order'
import AppStore from '../stores/AppStore'

async function orders(
  identity: Identity,
  customerId: number
): Promise<Order[]> {
  const { accessToken } = identity

  const response = await get(
    `${getConfig().apiUrl}/CustomerOrders/${customerId}?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  )

  return response
}

export const ordersApi: IOrdersApi = {
  orders,
}
