import React from 'react'
import { View } from 'react-native'
import { IntroImage } from './IntroImage'
import Spacings from '@taaleri/core/src/constants/Spacings'

export default function CreatingContractContent() {
  const image = require('./contract.png')
  return (
    <View style={{ marginBottom: Spacings.S24 }}>
      <IntroImage image={image} />
    </View>
  )
}
