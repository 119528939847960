import React from 'react'
import Title from '../../ui/text/Title'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Paragraph } from '../../ui/text/Paragraph'
import { View } from 'react-native'
import { TextWithBorder } from '../../ui/text/UiText'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'
import UiButton from '../../ui/UiButton'
import { changeLanguage, getChangeText } from '@taaleri/core/src/i18n'

interface Props {
  color?: string
}

export function QuestionStartContent(props: Props) {
  const { t } = useTranslation()
  const color = props.color || Colors.white
  return (
    <View>
      {isWeb && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <TextWithBorder>
              {t('questions.step-title', { step: 1 })}
            </TextWithBorder>
          </View>
          <UiButton
            title={t(getChangeText())}
            onPress={() => {
              changeLanguage()
            }}
            type="secondaryplain"
            containerStyle={{
              padding: 0,
            }}
          />
        </View>
      )}
      <Title
        style={{
          color,
          marginBottom: Spacings.S40,
        }}
      >
        {t('questions.step1.title')}
      </Title>
      <Paragraph
        style={{
          color,
        }}
      >
        {t('questions.step1.text')}
      </Paragraph>
    </View>
  )
}
