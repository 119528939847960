import React from 'react'
import { View } from 'react-native'
import { IconThumb } from '../ui/Icons'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Title from '../ui/text/Title'

interface Props {
  title: string
  children?: any
}

export default function SuccessMessage({ title, children }: Props) {
  return (
    <View style={{ alignItems: 'center' }}>
      <IconThumb
        style={{
          alignSelf: 'center',
          marginTop: 48,
          marginBottom: Spacings.S16,
        }}
      />
      <Title style={{ textAlign: 'center' }}>{title}</Title>
      {children}
    </View>
  )
}
