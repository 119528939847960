import styled, { css } from 'styled-components'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import Spacings from '@taaleri/core/src/constants/Spacings'

export const Content = styled.div<{
  padding?: boolean
  marginTop?: boolean
  gridWidth?: number
}>`
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${Spacings.S32}px;
    `}
  display: grid;
  flex: 1;
  @media (min-width: ${BreakPoint.L}px) {
    grid-template-columns: 960px;
    justify-content: center;
  }

  ${(props) =>
    props.padding &&
    css`
      padding: 0 ${Spacings.S24}px;
    `}

  @media (min-width: ${({ gridWidth }) =>
    gridWidth && gridWidth > 1300 ? gridWidth : 1300}px) {
    ${(props) =>
      css`
        grid-template-columns: ${props.gridWidth || 1200}px;
      `}
  }
`
