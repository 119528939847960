import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native'

export const Divider: React.FC<{ style?: StyleProp<ViewStyle> }> = ({
  style,
}) => {
  return <View style={[styles.divider, styles.margin, style]} />
}

export const DividerNoMargin: React.FC<{}> = () => {
  return <View style={styles.divider} />
}

const styles = StyleSheet.create({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.border,
  },
  margin: { marginBottom: Spacings.S16 },
})
