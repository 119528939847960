import React from 'react'
import ImpactToggleContent from '@taaleri/components/src/screens/questions/impact/ImpactToggleContent'
import ContentContainer from 'ui/container/ContentContainer'
import { ImpactToggleSteps } from '@taaleri/components/src/components/Steps'
import { ImpactToggleProps } from '@taaleri/components/src/screens/questions/impact/ImpactToggleProps'

function ImpactToggleScreen({ withImpact }: ImpactToggleProps) {
  return (
    <>
      <ImpactToggleSteps currentStep={0} />
      <ContentContainer>
        <ImpactToggleContent withImpact={withImpact} />
      </ContentContainer>
    </>
  )
}

export default ImpactToggleScreen
