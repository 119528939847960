import { TextDefault } from '../text/UiText'
import React from 'react'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { IconExclamationCircle } from '../icons/CircleIcons'
import FlexRow from '../FlexRow'

interface Props {
  message: string
  style?: StyleProp<ViewStyle>
  hideIcon?: boolean
}

function ErrorMessage({ message, style, hideIcon }: Props) {
  return (
    <FlexRow style={[styles.box, style]}>
      <TextDefault type="h5" style={styles.text}>
        {message}
      </TextDefault>
      {hideIcon || <IconExclamationCircle color={Colors.error} />}
    </FlexRow>
  )
}

export default ErrorMessage

const styles = StyleSheet.create({
  box: {
    padding: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: Spacings.S24,
    borderWidth: 2,
    borderColor: Colors.error,
    borderRadius: BorderRadius.default,
  },
  text: {
    color: Colors.error,
  },
})
