import {
  Identity,
  IdentityResponse,
  Login,
  LoginResponse,
  Registration,
  SmsVerification,
} from '../models/Identity'
import { getConfig } from '../config'
import logger from '../utils/logger'
import IAuthenticationApi from './IAuthenticationApi'
import { toIdentity } from './normalization/toIdentity'
import { toLogin } from './normalization/toLogin'
import { get, post, unauthenticatedPost, put } from './rest'
import { TwoFactorResponse } from '../models/TwoFactorResponse'
import ChangePassword from '../models/ChangePassword'
import AppStore from '../stores/AppStore'

async function getOnboardingProcess(identity: Identity): Promise<string> {
  const { accessToken } = identity
  const url = `${getConfig().apiUrl}/Customers/initonboarding`

  // Use unautheticated post if accessToken is undefined
  const { processId } = accessToken
    ? await post(url, accessToken, {}, true)
    : await unauthenticatedPost(url, {})

  return processId
}

async function login(username: string, password: string): Promise<Login> {
  const body = {
    username,
    password,
    language: AppStore.currentLanguage,
  }
  const loginResponse = (await unauthenticatedPost(
    `${getConfig().apiUrl}/Authentication/login`,
    body
  )) as LoginResponse
  return toLogin(loginResponse)
}

async function loginAdmin(customerId: number, token: string): Promise<Login> {
  const loginResponse = (await post(
    `${getConfig().apiUrl}/Authentication/adminlogin/${customerId}`,
    token,
    {},
    true
  )) as LoginResponse

  return toLogin(loginResponse)
}

async function setTwoFactorAuth(
  identity: Identity,
  isTwoFactor: boolean
): Promise<TwoFactorResponse> {
  const { accessToken, customerId } = identity
  const body = {
    preference: isTwoFactor,
  }
  const url = `${
    getConfig().apiUrl
  }/CustomerPreferences/twofactor/${customerId}`
  return (await post(url, accessToken, body)) as TwoFactorResponse
}

async function refresh(refreshToken: string): Promise<Login> {
  const body = {
    value: refreshToken,
  }

  const loginResponse = (await unauthenticatedPost(
    `${getConfig().apiUrl}/Authentication/refresh`,
    body
  )) as LoginResponse

  return toLogin(loginResponse)
}

async function refreshAccessToken(refreshToken: string): Promise<Identity> {
  const body = {
    value: refreshToken,
    language: AppStore.currentLanguage,
  }

  const identityResponse = (await unauthenticatedPost(
    `${getConfig().apiUrl}/Authentication/refresh/token`,
    body
  )) as IdentityResponse

  return toIdentity(identityResponse)
}

async function registration(
  processId: string,
  register: Registration,
  token?: string
): Promise<Identity | null> {
  const url = `${getConfig().apiUrl}/Authentication/newlogin/${processId}`
  const body = {
    username: register.email,
    password: register.password,
    processId,
  }

  const identityResponse: IdentityResponse =
    token !== undefined
      ? await post(url, token, body)
      : await unauthenticatedPost(url, body)

  logger.devInfo('identity', identityResponse)

  return identityResponse.isSuccess ? toIdentity(identityResponse) : null
}

async function registrationExisting(
  token: string,
  register: Registration
): Promise<Login | null> {
  const url = `${getConfig().apiUrl}/Authentication/CreateNewLogin`
  const body = {
    username: register.email,
    password: register.password,
    customerId: register.customerid,
  }

  const loginResponse: LoginResponse = await post(url, token, body)
  logger.devInfo('login', loginResponse)

  return loginResponse.isSuccess ? toLogin(loginResponse) : null
}

async function resetInfo(token: string): Promise<string> {
  const url = `${getConfig().apiUrl}/Authentication/resetinfo`
  //  logger.devInfo('identity', identityResponse)
  const res = await get(url, token, {}, 60000, true)
  return res.value
}

async function changePassword(
  accessToken: string,
  register: Registration
): Promise<Login | null> {
  const url = `${getConfig().apiUrl}/Authentication/changepassword`
  const body = {
    value: register.password,
  }

  const loginResponse: LoginResponse = await post(url, accessToken, body, true)

  logger.devInfo('login', loginResponse)

  return loginResponse.isSuccess ? toLogin(loginResponse) : null
}

/*
VR 18.11.2020: Send verification code without access token when doing 2FA
*/
async function checkVerificationCode2FA(
  smsConfirmationId: string,
  pin: string
): Promise<SmsVerification> {
  const body = {
    requestId: smsConfirmationId,
    pin,
  }

  const requestUrl = `${getConfig().apiUrl}/Authentication/smsconfirmation`
  const response = await unauthenticatedPost(requestUrl, body)

  return {
    success: response.isSuccess,
    status: response.status,
    refreshToken: response.value,
  }
}

async function checkVerificationCode(
  identity: Identity,
  smsConfirmationId: string,
  pin: string
): Promise<SmsVerification> {
  const { accessToken } = identity
  const body = {
    requestId: smsConfirmationId,
    pin,
  }

  const requestUrl = `${getConfig().apiUrl}/Authentication/smsconfirmation`
  const response = await post(requestUrl, accessToken, body)

  return {
    success: response.isSuccess,
    status: response.status,
    refreshToken: response.value,
  }
}

async function sendVerificationsCode(
  identity: Identity,
  smsConfirmationId: string
): Promise<boolean> {
  const { accessToken } = identity
  const response = await post(
    `${getConfig().apiUrl}/Authentication/sendsms/${smsConfirmationId}`,
    accessToken
  )
  return response.isSuccess ? true : false
}

async function sendAuthenticationHint(email: string): Promise<boolean> {
  const url = `${getConfig().apiUrl}/Authentication/hint`

  const response = await unauthenticatedPost(url, { value: email })

  return response.isSuccess ? true : false
}

async function changeUsername(
  accessToken: string,
  customerId: number,
  username: string
) {
  const url = `${getConfig().apiUrl}/User/username/${customerId}`
  const res = await put(url, accessToken, { value: username })
  return res
}

async function changePasswordSimple(
  accessToken: string,
  customerId: number,
  changePasswordRequest: ChangePassword
): Promise<string> {
  const url = `${getConfig().apiUrl}/User/password/${customerId}`
  const res = await put(url, accessToken, changePasswordRequest)
  return res
}

export const authenticationApi: IAuthenticationApi = {
  getOnboardingProcess,
  checkVerificationCode,
  checkVerificationCode2FA,
  login,
  loginAdmin,
  setTwoFactorAuth,
  refresh,
  refreshAccessToken,
  sendVerificationsCode,
  registration,
  registrationExisting,
  resetInfo,
  changePassword,
  sendAuthenticationHint,
  changeUsername,
  changePasswordSimple,
}
