import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Colors from '@taaleri/core/src/constants/Colors'
import {
  formatFundProfit,
  nonBreakingSpaces,
} from '@taaleri/core/src/utils/format'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'
import { translateHistoricalChangeId } from '@taaleri/core/src/services/translationService'

interface Props {
  value?: number
  id: string
  style?: StyleProp<ViewStyle>
}

export function HistoricalChangeSection({ id, value, style }: Props) {
  const { t } = useTranslation()
  const color = value
    ? value > 0
      ? Colors.textPrimary
      : Colors.error
    : Colors.text
  return (
    <FlexRow style={style}>
      <TextDefault type="t5" color={Colors.gray70}>
        {translateHistoricalChangeId(id, t)}
      </TextDefault>
      <TextDefault style={{ color, paddingLeft: Spacings.S8 }} type="h6">
        {value ? nonBreakingSpaces(formatFundProfit(value)) : '- %'}
      </TextDefault>
    </FlexRow>
  )
}
