import { getConfig } from '@taaleri/core/src/config'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Identity } from '@taaleri/core/src/models/Identity'
import {
  AccountStatement,
  BANK_STATEMENT,
  PreserverAsset,
  YEARLY_BANK_STATEMENT,
  YearStatement,
} from '@taaleri/core/src/models/ReportArchive'
import { getIdentity } from '@taaleri/core/src/services/storage'
import { getDate } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { StyleProp, ViewStyle, View } from 'react-native'
import { isWeb } from '../../constants/Platforms'
import { DividerNoMargin } from '../../ui/Divider'
import { FlexContainer } from '../../ui/FlexContainer'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronUp, IconChevronDown } from '../../ui/icons/ChevronIcons'
import { openUrl } from '../../ui/openUrl'
import { TextDefault } from '../../ui/text/UiText'
import { OpacityContainer } from './OpacityContainer'

export async function toPublicationServiceLink(
  fileId: number,
  type: string,
  customerId: string
) {
  const { accessToken }: Identity = await getIdentity()
  return `${
    getConfig().apiUrl
  }/Document/publicationservice/file?token=${accessToken}&documentFileId=${fileId}&type=${type}&customerId=${customerId}`
}

export function CollapsibleReportListItemComponent({
  onPress,
  title,
  containerStyle,
}: {
  onPress: () => void
  title?: string
  containerStyle?: StyleProp<ViewStyle>
  borderedContainer?: boolean
}) {
  const Container = isWeb ? OpacityContainer : React.Fragment

  return (
    <Container>
      <UiTouchable style={containerStyle} onPress={onPress}>
        <FlexContainer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: 16,
            paddingRight: 16,
            paddingLeft: 24,
          }}
        >
          <TextDefault
            type={'t2'}
            color={Colors.text}
            style={{ flex: 3, paddingRight: Spacings.S4 }}
          >
            {title}
          </TextDefault>
          <FlexRow>
            <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
              PDF
            </TextDefault>
            <IconDownload />
          </FlexRow>
        </FlexContainer>
      </UiTouchable>
    </Container>
  )
}

function CollapsibleReportList({
  items,
  title,
}: {
  items: (AccountStatement | YearStatement | PreserverAsset)[]
  title: string
}) {
  const [listOpen, setListOpen] = useState<boolean>(false)

  const openDocument = async (
    item: AccountStatement | YearStatement | PreserverAsset
  ) => {
    openUrl(
      await toPublicationServiceLink(
        item.documentFileId,
        item.reportId,
        item.customerId
      )
    )
  }

  useEffect(() => {
    if(listOpen) {
      setListOpen(false)
    }
  },[items])

  return (
    <View
      style={{
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderColor: Colors.gray40,
        borderRadius: 6,
        marginBottom: Spacings.S8,
      }}
    >
      <UiTouchable
        onPress={() => (listOpen ? setListOpen(false) : setListOpen(true))}
      >
        <FlexContainer
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
          }}
        >
          <TextDefault
            type={'t2'}
            style={{ flex: 3, paddingRight: Spacings.S4 }}
          >
            {title}
          </TextDefault>
          {listOpen ? (
            <IconChevronUp color={Colors.text} />
          ) : (
            <IconChevronDown color={Colors.text} />
          )}
        </FlexContainer>
        {listOpen && <DividerNoMargin />}
      </UiTouchable>
      {listOpen && (
        <FlexContainer style={{ flexDirection: 'column' }}>
          {items.map((item, index) => {
            let title = ''
            if (item.reportId === BANK_STATEMENT) {
              title = `Tiliote - ${item.iban}`
            }
            if (item.reportId === YEARLY_BANK_STATEMENT) {
              const newDate = new Date(item.endDate)
              const day = getDate(item.endDate)
              const month = newDate.getMonth() + 1
              const year = newDate.getFullYear()
              title = `${item.name} - ${day}.${month}.${year}`
            }
            return (
              <React.Fragment key={item.documentFileId + index}>
                <CollapsibleReportListItemComponent
                  onPress={() => openDocument(item)}
                  title={title}
                />
                {index < items.length - 1 && <DividerNoMargin />}
              </React.Fragment>
            )
          })}
        </FlexContainer>
      )}
    </View>
  )
}

export default CollapsibleReportList
