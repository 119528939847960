import React from 'react'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import { TextDefault } from '../../../ui/text/UiText'
import TransactionRowContainerMobile from './TransactionRowContainerMobile'
import { formatDateFull, formatCurrency } from '@taaleri/core/src/utils/format'
import useNavigation from '../../../navigation/useNavigation'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { ROUTE_REPORT_TRANSACTION_DETAIL } from '../../report/ReportRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  transaction: Transaction
}

function CashTransactionRowMobile({ transaction }: Props) {
  const { navigateApp } = useNavigation()
  const getReasonName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.reason
      : transaction.reasonSwedish
  return (
    <TransactionRowContainerMobile
      onPress={() => {
        TransactionStore.selectedTransaction = transaction
        navigateApp(ROUTE_REPORT_TRANSACTION_DETAIL)
      }}
    >
      <TextDefault type="t5" style={{ flex: 1 }}>
        {formatDateFull(transaction.paymentDate) +
          ' | ' +
          formatCurrency(transaction.amount)}
      </TextDefault>
      <TextDefault type="t2" style={{ flex: 1 }}>
        {getReasonName()}
      </TextDefault>
    </TransactionRowContainerMobile>
  )
}

export default CashTransactionRowMobile
