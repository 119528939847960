import { useEffect, useRef } from 'react'

// Runs the effect only when input
export function useDidUpdateEffect(fn: () => void, inputs: any[]) {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      fn()
    } else didMountRef.current = true
  }, inputs)
}
