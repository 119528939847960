import Svg, { Path } from 'react-native-svg'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'

export const AktiaLogo: React.FC<{
  style?: StyleProp<ViewStyle>
  color?: string
  small?: boolean
}> = ({ style, color = Colors.black, small }) => {
  const scaleDownFactor = small ? 1.5 : 1
  const width = 86 / scaleDownFactor
  const height = 30 / scaleDownFactor
  return (
    <View style={style}>
      <Svg viewBox={`0 0 ${width} ${height}`} height={height} width={width}>
        <Path
          fill={color}
          scale={1 / scaleDownFactor}
          d="M66.4089 9.36295H61.837V29.5432H66.4089V9.36295ZM16.8722 17.7262H9.19131L13.0318 7.40878L16.8722 17.7262ZM21.3071 29.544H26.153L15.3614 1.63613H10.7916L0 29.544H4.80057L7.68091 21.8172H18.4267L21.3071 29.544ZM37.2744 18.0915L45.9155 9.3638H40.0636L32.1081 17.6356V0H27.5363V29.544H32.1081V22.8624L33.9826 20.9538L40.7033 29.544H46.2354L37.2744 18.0915ZM55.2879 30C56.705 30 57.9396 29.7275 58.7168 29.2728V25.1355C58.0745 25.5897 57.0253 25.9533 56.1106 25.9533C54.6019 25.9533 53.6876 25.2261 53.6876 23.59V13.1355H58.2612V9.36295H53.6876V3.95601H49.1157V9.36295H45.9155V13.1355H49.1157V24.0898C49.1157 28.0918 51.6761 29.9987 55.2879 29.9987V30ZM66.9579 3.27269C66.9579 1.72681 65.6303 0.499822 64.1229 0.499822C62.6155 0.499822 61.2884 1.72681 61.2884 3.27269C61.2884 4.81856 62.616 6.04513 64.1229 6.04513C65.6299 6.04513 66.9579 4.81814 66.9579 3.27269ZM81.6564 24.1805C81.1536 25.4535 79.325 26.2262 77.496 26.2262C75.4405 26.2262 73.2898 25.3624 73.2898 23.3171C73.2898 21.3161 75.4387 20.4536 77.496 20.4536C79.325 20.4536 81.1536 21.2263 81.6564 22.4971V24.1805ZM86 29.5449V16.3192C86 11.7284 82.9822 8.91123 77.8634 8.91123C73.7031 8.91123 70.5029 11.5023 69.9085 14.9568H74.2975C74.8002 13.548 75.9432 12.7749 77.7718 12.7749C80.3781 12.7749 81.6581 14.3659 81.6581 16.4111V18.0473C80.835 17.4568 78.7777 16.8203 76.9474 16.8203C72.4668 16.8203 69.0378 19.5024 69.0378 23.3201C69.0378 27.4562 72.4668 29.9106 76.6275 29.9106C78.9135 29.9106 80.9708 29.0927 81.6564 28.4107V29.547L86 29.5449Z"
        />
      </Svg>
    </View>
  )
}
