import { FundAction } from '@taaleri/components/src/screens/funds/FundAction'
import { Fund, FundOrder } from '../models/Fund'
import { ServiceResponse, CONFLICT } from '../models/ServiceResponse'
import { Identity } from '../models/Identity'
import { getIdentity } from './storage'
import { api } from '../api/api'
import AppStore from '../stores/AppStore'
import { handleErrors } from './handleErrors'
import { FundProposition } from '../models/FundProposition'
import { Order } from '../models/Order'

export async function customerFundRecommendations(): Promise<Fund[]> {
  try {
    const identity: Identity = await getIdentity()
    const recommendations = await api().funds.fundRecommendations(
      AppStore.customerId,
      identity.accessToken
    )
    return recommendations
  } catch (e) {
    handleErrors(e, 'customerFundRecommendations')
    throw e
  }
}

export async function getCustomerFunds(): Promise<Fund[]> {
  try {
    const identity: Identity = await getIdentity()
    const funds = await api().funds.fundList(
      AppStore.customerId,
      identity.accessToken
    )
    return funds
  } catch (e) {
    handleErrors(e, 'customerFunds')
    throw e
  }
}

export async function fundListAll(): Promise<ServiceResponse<Fund[]>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().funds.fundListAll(identity.accessToken)
    return { response, success: true }
  } catch (e) {
    handleErrors(e, 'fundAll')
    return { success: false }
  }
}

export async function getOpenOrders(customerId: number): Promise<Order[]> {
  try {
    const identity: Identity = await getIdentity()
    const openOrders = await api().funds.getOpenOrders(
      customerId,
      identity.accessToken
    )
    return openOrders
  } catch (e) {
    handleErrors(e, 'openOrders')
    throw e
  }
}

export async function findFund(fundId: string, shouldCheckIfNotFound: boolean): Promise<ServiceResponse<Fund>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().funds.findFund(fundId, identity.accessToken)
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'findFund', null, null, shouldCheckIfNotFound)
    return { success: false, error }
  }
}

export async function fundProposition(
  fundAction: FundAction
): Promise<ServiceResponse<FundProposition>> {
  try {
    const isSell = fundAction === 'Sell'
    const identity: Identity = await getIdentity()
    const customerId = AppStore.customerId
    const amount = isSell ? AppStore.newWithdrawal : AppStore.newInvestment
    const ticker = AppStore.fundId || ''
    const response = await api().funds.fundProposition(
      customerId,
      amount,
      ticker,
      identity.accessToken,
      fundAction,
      AppStore.withdrawal !== undefined &&
        AppStore.withdrawal.sellEverything === true
    )

    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'fundProposition')
    return { success: false, error }
  }
}

export async function cancelFundOrder(
  orderId: string
): Promise<ServiceResponse<{ smsConfirmationId?: string }>> {
  try {
    const identity: Identity = await getIdentity()
    const customerId = AppStore.customerId
    const response = await api().funds.cancelFundOrder(
      customerId,
      orderId,
      identity.accessToken
    )

    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'cancelFundOrder')
    return { success: false, error }
  }
}

export async function createFundOrder(fundOrder: FundOrder): Promise<
  ServiceResponse<{
    smsConfirmationId?: string
    feeValueEstimate: number
    fee: number
  }>
> {
  try {
    const identity: Identity = await getIdentity()
    const { smsConfirmationId, feeValueEstimate, fee } =
      await api().funds.createFundOrder(
        identity.accessToken,
        AppStore.customerId,
        fundOrder
      )

    if (smsConfirmationId) {
      return {
        success: true,
        response: {
          smsConfirmationId,
          feeValueEstimate,
          fee,
        },
      }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e, 'createFundOrder', 'fatal')
    return { success: false, error }
  }
}
