import React from 'react'
import { StyleSheet } from 'react-native'
import { TextBold } from '../../ui/text/UiText'
import { Paragraph } from '../../ui/text/Paragraph'
import Colors from '@taaleri/core/src/constants/Colors'
import styled from 'styled-components'
import ContactButtons from './ContactButtons'
import useLayout from '../../ui/useLayout'
import { ContactCustomerServiceBox } from '../profile/ContactCustomerServiceBox'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  padding: 20px;

  padding: 64px 20px;
  @media (min-width: 768px) {
    align-items: center;
  }
  @media (min-width: 1024px) {
    padding: 128px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-width: 720px;
    align-items: center;
    text-align: center;
  }
`

export default function FaqContactCustomerService() {
  const { isSmall } = useLayout()
  const { t } = useTranslation()
  return (
    <Container>
      <Content>
        <TextBold type="h3" size={20} style={styles.title}>
          {t('faq.title')}
        </TextBold>
        <Paragraph>{t('faq.customer-service-helps')}</Paragraph>
        <Paragraph style={[{ marginBottom: 60 }]}>
          {t('faq.customer-service-open-hours')}
        </Paragraph>
      </Content>
      {isSmall ? <ContactCustomerServiceBox /> : <ContactButtons />}
    </Container>
  )
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 24,
  },
})
