import { observer } from 'mobx-react'
import React, { CSSProperties } from 'react'
import { View } from 'react-native'
import Box from '@taaleri/components/src/ui/box/Box'
import styled from 'styled-components'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import {
  IconPortfolioReport,
  IconCalendarFilter,
  IconCircleMedium,
} from '@taaleri/components/src/ui/Icons'
import Fonts from '@taaleri/core/src/constants/Fonts'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import PortfolioFilter from './PortfolioFilter'
import RestrictionFilter from './RestrictionFilter'
import DateFilter from './DateFilter'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { RestrictionProps } from './ReportFilters'
import { SideScrollContainer } from '@taaleri/components/src/screens/impact/SideScrollContainer'
import { translateFilterItem } from '@taaleri/core/src/services/translationService'
import { useTranslation } from 'react-i18next'

const SelectBox = styled.div<{ isOpen: boolean }>`
  flex: 1;
  border: 1px solid ${Colors.border};
  border-radius: ${BorderRadius.default}px;
  padding: 15px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.backgroundPale};
  }
  background-color: ${(props) =>
    props.isOpen ? Colors.backgroundPale : Colors.white};
`

const Count = styled.div`
  position: absolute;
  top: 8px;
  display: flex;
  justify-content: center;
  width: 24px;
  font-size: 10px;
  color: ${Colors.primary};
  font-family: ${Fonts.avenirNextBold};
`

interface SelectorProps {
  onToggle: () => void
  isOpen: boolean
  style?: CSSProperties
}

export interface ToggleProps {
  onToggle: (value: boolean) => void
}

export const FilterBox = styled.div`
  margin-top: 20px;
  border: 1px solid ${Colors.border};
  border-radius: ${BorderRadius.default}px;
  padding: 12px;
`

function PortfolioSelectorInternal({ onToggle, isOpen, style }: SelectorProps) {
  const { t } = useTranslation()
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center', flex: 1 }}>
          <View>
            <IconPortfolioReport />
            <Count>{ReportStore.selectedReportingPortfolios.length}</Count>
          </View>
          <TextDefault
            type="h6"
            style={{ marginLeft: 10, flex: 1 }}
            numberOfLines={1}
          >
            {ReportStore.allReportingPortfoliosSelected
              ? `${t('portfolio.all')}`
              : `${t('portfolio.selected')}`}
          </TextDefault>
        </FlexRow>
      </FlexRow>
    </SelectBox>
  )
}

const PortfolioSelector = observer(PortfolioSelectorInternal)

function DateSelectorInternal({ onToggle, isOpen, style }: SelectorProps) {
  const { t } = useTranslation()
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center', flex: 1 }}>
          <View>
            <IconCalendarFilter />
          </View>
          <TextDefault
            type="h6"
            style={{ marginLeft: 10, flex: 1 }}
            numberOfLines={1}
          >
            {typeof ReportStore.dateRangeTitle === 'string'
              ? ReportStore.dateRangeTitle
              : translateFilterItem(ReportStore.dateRangeTitle, t)}
          </TextDefault>
        </FlexRow>
      </FlexRow>
    </SelectBox>
  )
}

const DateSelector = observer(DateSelectorInternal)

const RestrictionSelector = observer(RestrictionSelectorInternal)

function RestrictionSelectorInternal({
  onToggle,
  isOpen,
  style,
}: SelectorProps) {
  const { t } = useTranslation()
  const assetClassCount = ReportStore.allAssetClasses().length
  if (assetClassCount === 0) {
    return null
  }
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center', flex: 1 }}>
          <View>
            <IconCircleMedium />
            <Count style={{ top: 5, fontSize: 12 }}>{assetClassCount}</Count>
          </View>
          <TextDefault
            type="h6"
            style={{ marginLeft: 10, flex: 1 }}
            numberOfLines={1}
          >
            {t('portfolio.limit')}
          </TextDefault>
        </FlexRow>
      </FlexRow>
    </SelectBox>
  )
}

function ReportFiltersMobile({
  restrictionOpen,
  setRestrictionOpen,
  portfolioOpen,
  setPortfolioOpen,
  dateOpen,
  setDateOpen,
}: RestrictionProps) {
  if (!AppStore.reportingPortfolioList) {
    return null
  }

  const { reportingPortfoliosList } = ReportStore

  const { isAdmin } = AppStore
  const filtersOpen = restrictionOpen || dateOpen || portfolioOpen
  return (
    <>
      <SideScrollContainer
        style={{ marginBottom: filtersOpen ? Spacings.S16 : Spacings.S24 }}
        containerStyle={{ flexWrap: 'nowrap' }}
      >
        {(reportingPortfoliosList.length > 1 || isAdmin) && (
          <PortfolioSelector
            onToggle={() => {
              setPortfolioOpen(!portfolioOpen)
              setDateOpen(false)
              setRestrictionOpen(false)
            }}
            isOpen={portfolioOpen}
            style={{ marginRight: Spacings.S16 }}
          />
        )}

        <DateSelector
          onToggle={() => {
            setDateOpen(!dateOpen)
            setPortfolioOpen(false)
            setRestrictionOpen(false)
          }}
          isOpen={dateOpen}
          style={{ marginRight: Spacings.S16 }}
        />
        <RestrictionSelector
          onToggle={() => {
            setRestrictionOpen(!restrictionOpen)
            setPortfolioOpen(false)
            setDateOpen(false)
          }}
          isOpen={restrictionOpen}
        />
      </SideScrollContainer>
      {filtersOpen && (
        <Box style={{ marginBottom: Spacings.S24 }}>
          {portfolioOpen && (
            <PortfolioFilter onToggle={setPortfolioOpen} isMobile={true} />
          )}
          {dateOpen && (
            <DateFilter onToggle={setDateOpen} isPortfolioScreen={true} />
          )}
          {restrictionOpen && <RestrictionFilter />}
        </Box>
      )}
    </>
  )
}

export default observer(ReportFiltersMobile)
