import React from 'react'
import TransactionRowContainer from '../Rows/TransactionRowContainer'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

function CashTransactionHeader() {
  const { t } = useTranslation()
  return (
    <TransactionRowContainer hideBorder={true}>
      <TextDefault type="h6" style={{ flex: 4 }}>
        {t('portfolio.text')}
      </TextDefault>
      <TextDefault type="t5" style={{ flex: 3, color: Colors.text }}>
        {t('reportTransaction.reason-code')}
      </TextDefault>
      <TextDefault type="t5" style={{ flex: 2, color: Colors.text }}>
        {t('report.term')}
      </TextDefault>
      <TextDefault type="t5" style={{ flex: 2, color: Colors.text }}>
        {t('steps.sum')}
      </TextDefault>
      <TextDefault type="t5" style={{ flex: 2, color: Colors.text }}>
        {t('reportTransaction.definition')}
      </TextDefault>
    </TransactionRowContainer>
  )
}

export default CashTransactionHeader
