import Spacings from '@taaleri/core/src/constants/Spacings'
import { Feed } from '@taaleri/core/src/models/Feed'
import { getFeed } from '@taaleri/core/src/services/feeds'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { getDateYearAgo } from '@taaleri/core/src/utils/format'
import range from 'lodash/range'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { EmptyFeed, FeedRow } from './FeedRow'
import ContentLoader from '../../ui/ContentLoader'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import UiButton from '../../ui/UiButton'
import { BoxWithPadding } from '../../ui/box/Box'
import useLayout from '../../ui/useLayout'

function FeedContent() {
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [useOldFeed, setUseOldFeed] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [loadingFirstPage, setLoadingFirstPage] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const { isNonSmall } = useLayout()
  const { t } = useTranslation()

  async function fetchFirstPage() {
    let feeds: Feed[] = []
    setLoadingFirstPage(true)
    try {
      feeds = await getFeed(AppStore.customerId, 1)
      // Has more also when try old feed
      if (!feeds) {
        setLoadingFirstPage(false)
        return
      }
      setHasMore(feeds.length > 9 || feeds.length === 0)
      setUseOldFeed(feeds.length === 0)
    } catch (error) {
      setError(true)
      // ignore
    }
    AppStore.feeds = feeds
    AppStore.hasNewMessages = AppStore.hasUnreadMessages()
    if (feeds.length > 0) {
      AppStore.lastFeedDate = feeds[0].date
    }
    setLoadingFirstPage(false)
  }

  async function loadNextPage() {
    setLoadingMore(true)
    try {
      const nextPage = page + 1
      const startDate = useOldFeed ? new Date(0) : getDateYearAgo()
      const newFeeds = await getFeed(AppStore.customerId, nextPage, startDate)
      setHasMore(newFeeds.length > 9)
      AppStore.feeds = (AppStore.feeds || []).concat(newFeeds)
      setPage(nextPage)
    } catch (error) {
      setError(true)
      return
    }
    setLoadingMore(false)
  }

  useEffect(() => {
    async function fetchFeed() {
      await fetchFirstPage()
    }
    fetchFeed()
  }, [AppStore.currentLanguage, AppStore.customerId])

  const feed = AppStore.feeds?.filter(
    (feed) =>
      feed.itemType !== 'SecurityTransaction' ||
      (feed.securityTransactionItems &&
        feed.securityTransactionItems.some(
          (st) =>
            !AppStore.hideTransactions(
              st.securityTransaction.portfolio.portfolioId
            )
        ))
  )

  if (error) {
    return <ErrorWithRetry onRetry={() => fetchFirstPage()} />
  }

  if (loadingFirstPage) {
    return range(1, 10).map((i) => (
      <BoxWithPadding key={i} style={{ marginBottom: Spacings.S24 }}>
        <ContentLoader variant="facebook" />
      </BoxWithPadding>
    ))
  }

  const rows = feed?.map((f, index) => <FeedRow feed={f} key={index} t={t} />)

  const feedList =
    !feed || feed.length === 0 ? (
      <EmptyFeed t={t} />
    ) : (
      <View style={{ paddingBottom: Spacings.S32 }}>
        {AppStore.currentLanguage === 'sv' ? (
          <>
            {/* <SvRow /> */}
            {rows}
          </>
        ) : (
          rows
        )}
      </View>
    )

  const buttonLoadOldTitle = t('app.lastupdates.load-old-events')
  const buttonDefaultTitle = t('app.lastupdates.button')

  const buttonTitle = useOldFeed ? buttonLoadOldTitle : buttonDefaultTitle
  return (
    <View>
      {feedList}
      {hasMore && !AppStore.showFirstInvestment && (
        <UiButton
          type="secondary"
          containerStyle={[
            { marginBottom: Spacings.S32 },
            isNonSmall && { maxWidth: 330 },
          ]}
          title={buttonTitle}
          onPress={loadNextPage}
          loading={loadingMore}
        />
      )}
    </View>
  )
}

export default observer(FeedContent)
