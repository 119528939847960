import { observable, makeAutoObservable } from 'mobx'

class UserStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable
  loggedIn: boolean = false
}

export default new UserStore()
