import React from 'react'

export function HamburgerClosed() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="6" width="32" height="4" rx="2" fill="#473729" />
      <rect y="14" width="32" height="4" rx="2" fill="#473729" />
      <rect y="22" width="32" height="4" rx="2" fill="#473729" />
    </svg>
  )
}

export function HamburgerOpen() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.29297"
        y="26.6274"
        width="32"
        height="3"
        rx="1.5"
        transform="rotate(-45 3.29297 26.6274)"
        fill="#473729"
      />
      <rect
        x="5.12109"
        y="4"
        width="32"
        height="3"
        rx="1.5"
        transform="rotate(45 5.12109 4)"
        fill="#473729"
      />
    </svg>
  )
}

export function LogoutImage() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: '10px' }}
    >
      <path
        d="M16 15.5V18.5H5V4.5H16V7.5M11.5 11.5H20.5M20.5 11.5L18 8.5M20.5 11.5L18 14.5"
        stroke="#473729"
        strokeWidth="1.5"
      />
    </svg>
  )
}
