import { ServiceResponse } from '../models/ServiceResponse'

import { getIdentity } from './storage'

import { api } from '../api/api'

import { handleErrors } from './handleErrors'

export async function validateIban(
  iban: string
): Promise<ServiceResponse<string>> {
  try {
    const identity = await getIdentity()
    const validation = await api().customer.validateIban(identity, iban)

    return {
      success: validation.isSuccess,
      response: validation.formattedValue,
    }
  } catch (e) {
    const error = handleErrors(e, 'validateIban')
    return { success: false, response: iban, error }
  }
}
