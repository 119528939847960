import Spacings from '@taaleri/core/src/constants/Spacings'
import { observer } from 'mobx-react'
import React, { PropsWithChildren } from 'react'
import { StyleSheet, View } from 'react-native'
import FlexRow from '../../ui/FlexRow'
import { PropsOnPress } from '../../components/PropsChildren'
import useLayout from '../../ui/useLayout'
import UiTouchable from '../../ui/UiTouchable'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextDefault } from '../../ui/text/UiText'
import { IconUpdateSmall } from '../../ui/icons/UpdateIcons'
import Box from '../../ui/box/Box'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

function ActionBox({ children, onPress }: PropsWithChildren<PropsOnPress>) {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()

  function updateButton() {
    return (
      <UiTouchable
        style={
          isWeb && {
            alignSelf: 'center',
          }
        }
        onPress={onPress}
      >
        <TextDefault type="h6" color={Colors.primary}>
          {t('home.action-box-update')}
        </TextDefault>
      </UiTouchable>
    )
  }

  function boxContent() {
    if (isNonSmall) {
      return (
        <TextDefault type="h6" style={{ alignSelf: 'center' }}>
          {children}
        </TextDefault>
      )
    } else if (isWeb) {
      return (
        <TextDefault type="h6" style={styles.paragraph}>
          {children} {updateButton()}
        </TextDefault>
      )
    } else {
      return (
        <View style={{ flex: 1 }}>
          <TextDefault type="h6" style={styles.paragraph}>
            {children}
          </TextDefault>
          {updateButton()}
        </View>
      )
    }
  }

  if (isNonSmall) {
    return (
      <Box style={styles.box} onPress={onPress}>
        <FlexRow style={styles.container}>
          <FlexRow style={{ flex: 1 }}>
            <View
              style={{
                justifyContent: 'center',
                padding: Spacings.S8,
                backgroundColor: Colors.primary,
                borderRadius: 6,
                marginRight: Spacings.S8,
              }}
            >
              <IconUpdateSmall
                style={{
                  width: 50,
                  height: 50,
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                width={24}
                color={Colors.white}
              />
            </View>
            {boxContent()}
          </FlexRow>
          {updateButton()}
        </FlexRow>
      </Box>
    )
  } else {
    return (
      <Box onPress={onPress} style={styles.box}>
        <View style={styles.container}>
          <View
            style={{
              justifyContent: 'center',
              padding: Spacings.S8,
              backgroundColor: Colors.primary,
              borderRadius: 6,
              marginRight: Spacings.S8,
              maxWidth: 40,
              maxHeight: 40,
            }}
          >
            <IconUpdateSmall
              style={{
                width: 32,
                height: 32,
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              width={20}
              color={Colors.white}
            />
          </View>
          {boxContent()}
        </View>
      </Box>
    )
  }
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 2,
    marginRight: 20,
  },
  box: {
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
    shadowOpacity: 0.06,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
    backgroundColor: Colors.white,
    borderColor: Colors.borderLightBlue,
    borderRadius: 6,
    marginBottom: isWeb ? Spacings.S10 : Spacings.S24,
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    flex: 1,
    paddingHorizontal: Spacings.S16,
    paddingVertical: Spacings.S16,
    backgroundColor: Colors.backgroundLightBlue,
  },
  paragraph: {
    marginBottom: 0,
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
})

export default observer(ActionBox)
