import React from 'react'

import { Paragraph } from '../../ui//text/Paragraph'
import { BoxWithPadding } from '../../ui//box/Box'

import Spacings from '@taaleri/core/src/constants/Spacings'

import PortfolioDistributionEdit from '../../screens/questions/investmentproposal/PortfolioDistributionEdit'
import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'
import Spacer from '../../ui/Spacer'
import { useTranslation } from 'react-i18next'

export default function ImpactInvestmentProposal({
  investmentProposal,
}: {
  investmentProposal: InvestmentProposal
}) {
  const { t } = useTranslation()
  return (
    <>
      <Paragraph>
        {t('impact.investment-proposal')}
      </Paragraph>
      <BoxWithPadding
        style={{ marginBottom: Spacings.S24 }}
        title={`${t('app.ImpactToggleOn.comparebox.header')}`}
      >
        <Spacer />
        <PortfolioDistributionEdit
          investmentProposal={investmentProposal}
          hideRiskInfo={true}
          hideRiskLevel={true}
          impactShare={investmentProposal.impactShare}
          t={t}
        />
      </BoxWithPadding>
    </>
  )
}
