import { View } from 'react-native'

import Colors from '@taaleri/core/src/constants/Colors'

import Spacings from '@taaleri/core/src/constants/Spacings'

import React from 'react'
import { IconCheckCircleSm, IconPlus } from './Icons'
import { TextMedium } from './text/UiText'
import { FontSize } from '@taaleri/core/src/constants/Fonts'

const CheckRow: React.FC<{ text: string; plusSign?: boolean }> = ({
  text,
  plusSign,
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: Colors.border,
        borderBottomWidth: 1,
        paddingVertical: Spacings.S8,
      }}
    >
      {!plusSign && (
        <IconCheckCircleSm
          color={Colors.primary}
          style={{ marginRight: Spacings.S10 }}
        />
      )}
      {plusSign && (
        <IconPlus
          color={Colors.primary}
          style={{ marginRight: Spacings.S10 }}
        />
      )}

      <TextMedium
        style={{
          paddingRight: Spacings.S24,
          fontSize: FontSize.S12,
          lineHeight: 18,
        }}
      >
        {text}
      </TextMedium>
    </View>
  )
}

export default CheckRow
