import Analytics from '@taaleri/core/src/analytics/Analytics'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  PortfolioType,
  portfolioTypeName,
} from '@taaleri/core/src/models/PortfolioType'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { formatCo2Kilos } from '@taaleri/core/src/utils/format'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ImageBackground, StyleProp, ViewStyle } from 'react-native'

import {
  ROUTE_QUESTION_ETF_COMPARISON,
  ROUTE_QUESTION_PORTFOLIO_TYPE,
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
} from './QuestionRoutes'
import { QUESTION_PORTFOLIO_TYPE, nextQuestionRoute } from './questions'
import usePreliminaryProposal from './usePreliminaryProposal'
import { isWeb } from '../../constants/Platforms'
import { routePathQuestions } from '../../navigation/routeHelper'
import useNavigation from '../../navigation/useNavigation'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import FlexRow from '../../ui/FlexRow'
import {
  IconCo2,
  IconUnitedNations,
  IconCheckCircleWhite,
  IconCheckCircleWhiteNotChecked,
} from '../../ui/Icons'
import LoadingIndicator from '../../ui/LoadingIndicator'
import UiButton from '../../ui/UiButton'
import UiTouchable from '../../ui/UiTouchable'
import Box from '../../ui/box/Box'
import Title from '../../ui/text/Title'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

function InfoRow({
  title,
  subTitle,
  icon,
  portfolioType,
}: {
  title: string
  subTitle: string
  icon: 'flagheart' | 'co2'
  portfolioType: PortfolioType
}) {
  const isImpact = portfolioType === 'impact'
  const iconSize = 24
  return (
    <View
      style={[
        {
          alignItems: 'center',
          flex: 1,
          paddingHorizontal: 10,
        },
        icon === 'co2' && {
          borderRightWidth: 1,
          borderRightColor: Colors.border,
        },
      ]}
    >
      <View style={[{ alignItems: 'center' }]}>
        {icon === 'co2' && (
          <IconCo2
            size={iconSize}
            color={isImpact ? Colors.primary : Colors.inactiveTint}
          />
        )}
        {icon === 'flagheart' && (
          <IconUnitedNations
            size={iconSize}
            color={isImpact ? Colors.primary : Colors.inactiveTint}
          />
        )}

        <TextDefault
          type="h5"
          style={[
            {
              color: isImpact ? Colors.text : Colors.inactiveTint,
              marginTop: Spacings.S4,
            },
          ]}
        >
          {title}
        </TextDefault>
      </View>

      <TextMedium
        style={{
          fontSize: FontSize.S12,
          lineHeight: 16,
          textAlign: 'center',
          color: Colors.textSecondary,
        }}
      >
        {subTitle}
      </TextMedium>
    </View>
  )
}

function Card({
  title,
  text,
  onPress,
  selected,
  portfolioType,
  co2,
  expenses,
}: {
  title: string
  text: string
  co2: string
  expenses: string
  selected: boolean
  onPress: () => void
  portfolioType: PortfolioType
}) {
  const { t } = useTranslation()
  const { isSmall, isNonSmall } = useLayout()
  const imageImpact = require('./impact.jpg')
  const imageEtf = require('./etf.jpg')
  const isImpact = portfolioType === 'impact'
  const image = isImpact ? imageImpact : imageEtf
  return (
    <UiTouchable
      onPress={onPress}
      style={[
        { flex: 1 },
        isImpact && isNonSmall && { marginRight: Spacings.S16 },
        isSmall && { marginBottom: Spacings.S24 },
      ]}
    >
      <View
        style={[
          {
            flex: 1,
            borderWidth: 2,
            borderColor: selected ? Colors.primary : Colors.background,
            borderRadius: BorderRadius.default,
          },
        ]}
      >
        <Box style={{ flex: 1 }}>
          <ImageBackground
            resizeMode="cover"
            source={image}
            imageStyle={{
              borderTopLeftRadius: BorderRadius.small,
              borderTopRightRadius: BorderRadius.small,
            }}
            style={{
              width: '100%',
              height: 86,
            }}
          >
            <FlexRow
              style={{
                padding: 20,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View>
                <TextDefault
                  type="h5"
                  style={[
                    {
                      color: Colors.white,
                    },
                  ]}
                >
                  {title}
                </TextDefault>
                <TextDefault
                  type="t2"
                  numberOfLines={4}
                  style={[
                    {
                      color: Colors.white,
                    },
                  ]}
                >
                  {text}
                </TextDefault>
              </View>
              {selected && <IconCheckCircleWhite />}
              {!selected && <IconCheckCircleWhiteNotChecked />}
            </FlexRow>
          </ImageBackground>
          <View
            style={[
              {
                paddingVertical: Spacings.S16,
              },
              isNonSmall && { justifyContent: 'center' },
            ]}
          >
            <View
              style={[
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
              ]}
            >
              <InfoRow
                title={co2}
                subTitle={t('questions.portfolios.average-co')}
                icon="co2"
                portfolioType={portfolioType}
              />
              <InfoRow
                title={`${expenses}`}
                subTitle={t('questions.portfolios.un')}
                icon="flagheart"
                portfolioType={portfolioType}
              />
            </View>
          </View>
        </Box>
      </View>
    </UiTouchable>
  )
}

export function questionPortfolioTypeNextRoute() {
  if (QuestionStore.proposalScreen) {
    return isWeb &&
      QuestionStore.proposalScreen === ROUTE_QUESTION_INVESTMENT_PROPOSAL
      ? ROUTE_QUESTION_INVESTMENT_PROPOSAL + 'Show'
      : QuestionStore.proposalScreen
  }
  return nextQuestionRoute(ROUTE_QUESTION_PORTFOLIO_TYPE)
}

export default function QuestionPortfolioTypeContent({
  children,
  containerStyle,
}: {
  children?: any
  containerStyle?: StyleProp<ViewStyle>
}) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { isSmall, isNonSmall } = useLayout()
  const [portfolioType, setPortfolioType] = useState<PortfolioType>(() => {
    const currentAnswer = QuestionStore.getAnswer(
      QUESTION_PORTFOLIO_TYPE
    ) as PortfolioType
    const defaultPortfolioType: PortfolioType = 'impact'

    if (!currentAnswer) {
      QuestionStore.setAnswer(QUESTION_PORTFOLIO_TYPE, defaultPortfolioType)
    }

    return currentAnswer || defaultPortfolioType
  })

  const { preliminaryProposal, error, setRetry } = usePreliminaryProposal()

  if (!preliminaryProposal) {
    return <LoadingIndicator type={isWeb ? 'inline' : 'full'} />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const { expectedReturn } = preliminaryProposal.impactEtfDistribution
  const { impactShare } = preliminaryProposal
  const co2Compensation = QuestionStore.getCo2Compensation(
    expectedReturn,
    impactShare
  )

  function onChange(type: PortfolioType) {
    Analytics.event('Onboarding', 'Select portfolio', type)
    setPortfolioType(type)
    QuestionStore.setAnswer(QUESTION_PORTFOLIO_TYPE, type)
  }
  return (
    <View style={containerStyle}>
      <Title>{t('questions.portfolios.title')}</Title>
      <TextDefault type="t2" style={{ marginBottom: 40 }}>
        {t('questions.portfolios.description')}
      </TextDefault>
      <View style={{ flexDirection: isSmall ? 'column' : 'row' }}>
        <Card
          title={t(portfolioTypeName('impact'))}
          text={t('questions.portfolios.impact-description')}
          onPress={() => {
            onChange('impact')
          }}
          selected={portfolioType === 'impact'}
          portfolioType="impact"
          co2={formatCo2Kilos(co2Compensation)}
          expenses="6 SDG"
        />
        <Card
          title={portfolioTypeName('etf')}
          text={t('questions.portfolios.etf-description')}
          onPress={() => {
            onChange('etf')
          }}
          selected={portfolioType === 'etf'}
          portfolioType="etf"
          co2="0 t"
          expenses="0"
        />
      </View>
      <UiButton
        containerStyle={{
          marginTop: isNonSmall ? Spacings.S16 : 0,
          paddingBottom: isWeb ? 0 : 64,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        type="primaryplain"
        onPress={() =>
          navigate(routePathQuestions(ROUTE_QUESTION_ETF_COMPARISON))
        }
        title={t('questions.portfolios.compare')}
      />
      {children}
    </View>
  )
}
