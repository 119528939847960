import React from 'react'

import InfoButton from '../../../ui/InfoButton'

import Spacings from '@taaleri/core/src/constants/Spacings'
import { Image, View } from 'react-native'
import { Paragraph } from '../../../ui/text/Paragraph'
import { TextBold } from '../../../ui/text/UiText'
import { highRiskImage, lowRiskImage } from './riskImage'
import { useTranslation } from 'react-i18next'

export default function RiskInfoButton() {
  const { t } = useTranslation()
  const highRisk = highRiskImage
  const lowRisk = lowRiskImage

  return (
    <InfoButton
      style={{
        alignItems: 'flex-end',
        flex: 1,
      }}
    >
      <View style={{ paddingBottom: Spacings.S24 }}>
        <TextBold style={{ marginBottom: Spacings.S16 }}>
          {t('riskInfoModal.risk-title')}
        </TextBold>
        <Paragraph>{t('riskInfoModal.share-description')}</Paragraph>
        <Paragraph>{t('riskInfoModal.hig-risk-description')}</Paragraph>
        <Paragraph>{t('riskInfoModal.risk-level-effect')}</Paragraph>
        <TextBold style={{ marginTop: Spacings.S24 }}>
          {t('riskInfoModal.high-risk')}
        </TextBold>

        <Image source={highRisk} style={{ width: '100%', minHeight: 220 }} />

        <TextBold style={{ marginTop: Spacings.S24 }}>
          {t('riskInfoModal.low-risk')}
        </TextBold>

        <Image source={lowRisk} style={{ width: '100%', minHeight: 100 }} />
      </View>
    </InfoButton>
  )
}
