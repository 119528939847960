import React from 'react'
import { observer } from 'mobx-react'
import Box from '../../ui/box/Box'
import { StyleSheet, ImageBackground, View } from 'react-native'
import ReportBoxHeader from './ReportBoxHeader'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiButton from '../../ui/UiButton'
import useLayout from '../../ui/useLayout'
import { isWeb } from '../../constants/Platforms'

interface Props {
  title: string
  subtitle: string
  children?: any
  buttonTitle?: string
  secondaryButtonTitle?: string
  onButtonPress?: () => void
  onSecondaryButtonPress?: () => void
  image?: any
}

function ReportBoxLarge({
  title,
  subtitle,
  children,
  buttonTitle,
  secondaryButtonTitle,
  onButtonPress,
  onSecondaryButtonPress,
  image,
}: Props) {
  const { isWide } = useLayout()
  const showMobileButton = Boolean(buttonTitle && onButtonPress && !isWide)
  const showSecondaryMobileButton = Boolean(
    secondaryButtonTitle && onSecondaryButtonPress && !isWide
  )
  const showDesktopButton = Boolean(
    (onButtonPress || onSecondaryButtonPress) && isWide
  )
  return (
    <Box style={{ marginBottom: isWeb ? 0 : Spacings.S20 }}>
      <ImageBackground
        source={image}
        resizeMode="cover"
        style={styles.backgroundImage}
      >
        <View style={{ flex: 1, justifyContent: 'space-between' }}>
          <ReportBoxHeader
            title={title}
            subtitle={subtitle}
            showButton={showDesktopButton}
            onPrimaryButtonPress={onButtonPress}
            onSecondaryButtonPress={onSecondaryButtonPress}
          />
          <View>
            {children}
            {showSecondaryMobileButton && (
              <UiButton
                title={secondaryButtonTitle || ''}
                onPress={onSecondaryButtonPress}
                containerStyle={{ marginTop: Spacings.S20 }}
                type="secondary"
                size="small"
              />
            )}
            {showMobileButton && (
              <UiButton
                title={buttonTitle || ''}
                onPress={onButtonPress}
                containerStyle={{ marginTop: Spacings.S16 }}
                size="small"
              />
            )}
          </View>
        </View>
      </ImageBackground>
    </Box>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    padding: Spacings.PADDING,
  },
})

export default observer(ReportBoxLarge)
