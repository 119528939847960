import { OptionType } from '@taaleri/core/src/models/OptionType'
import React from 'react'
import { ViewStyle, StyleProp } from 'react-native'
import { useField } from 'formik'
import ListItem from '../ListItem'
import { CheckListError } from './CheckListError'
import { useTranslation } from 'react-i18next'

interface Props {
  lastBottomDivider?: boolean
  name: string
  options: OptionType[]
}

interface PropsStandalone {
  onChangeText?: (value: any) => void
  value: string[]
  disabled: string[]
  itemStyle?: StyleProp<ViewStyle>
}

const without = (ary: string[], value: string): string[] =>
  ary.filter((x) => x !== value)

const CheckListMultiple: React.FC<Props> = ({
  lastBottomDivider,
  options,
  name,
}) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField(name)
  const value = field.value
  const items = options.map((item, index) => {
    const checked = value !== undefined && value.includes(String(item.value))
    return (
      <ListItem
        key={item.value}
        title={`${t(item.label)}`}
        checkmark={checked}
        checkmarkNotChecked={!checked}
        reserveSpaceForRightCheckmark={true}
        bottomDivider={index === options.length - 1 ? lastBottomDivider : false}
        topDivider={index > 0}
        noPadding={true}
        onPress={() => {
          const itemValue = String(item.value)
          const currentValue = value || []
          const newValue = currentValue.includes(itemValue)
            ? without(currentValue, itemValue)
            : currentValue.concat([itemValue])
          helpers.setValue(newValue)
        }}
      />
    )
  })
  return (
    <>
      {items}
      <CheckListError error={`${t(meta.error ?? '')}`} touched={meta.touched} />
    </>
  )
}

export const CheckListMultipleStandalone: React.FC<Props & PropsStandalone> = ({
  lastBottomDivider,
  options,
  value,
  disabled,
  onChangeText,
  itemStyle,
}) => {
  const { t } = useTranslation()
  const items = options.map((item, index) => {
    const disable = value !== undefined && disabled.includes(String(item.value))
    const checked = value !== undefined && value.includes(String(item.value))
    return (
      <ListItem
        containerStyle={itemStyle}
        key={item.value}
        title={`${t(item.label)}`}
        checkmark={checked}
        checkmarkNotChecked={!checked}
        reserveSpaceForRightCheckmark={true}
        disabled={disable}
        bottomDivider={index === options.length - 1 ? lastBottomDivider : false}
        topDivider={index > 0}
        onPress={() => {
          if (onChangeText) {
            const itemValue = String(item.value)
            const currentValue = value || []
            const newValue = currentValue.includes(itemValue)
              ? without(currentValue, itemValue)
              : currentValue.concat([itemValue])
            onChangeText(newValue)
          }
        }}
      />
    )
  })
  return <>{items}</>
}

export default CheckListMultiple
