import { DiscountCodeValidationResponse } from '../models/DiscountCodeValidationResponse'
import { formatDateWithoutLeadingZeros } from './format'
import { TFunction } from 'i18next'

export function getDiscountSuccessMessage(
  { customerMonths, endDate }: DiscountCodeValidationResponse,
  t: TFunction
): string {
  if (customerMonths === 1) {
    return `${t('utils.discount-one-month')}`
  } else if (customerMonths) {
    return `${t('utils.discount-months', { months: customerMonths })}`
  } else if (endDate) {
    return `${t('utils.discount-until', {
      day: formatDateWithoutLeadingZeros(endDate),
    })}`
  }
  return `${t('utils.discount')}`
}

export function getDiscountErrorMessage(
  {
    isCodeExpired,
    isCodeInvalid,
    isUsageLimitExceeded,
    isInvalidCustomerId,
    isOnboardingOnly,
    isNotDigiCustomer,
    isDiscountInEffect,
  }: DiscountCodeValidationResponse,
  t: TFunction
): string {
  if (isCodeExpired) {
    return `${t('utils.discount-expired')}`
  } else if (isCodeInvalid) {
    return `${t('utils.code-invalid')}`
  } else if (isUsageLimitExceeded) {
    return `${t('utils.out-of-codes')}`
  } else if (isInvalidCustomerId) {
    return `${t('utils.can-not-use-code')}`
  } else if (isOnboardingOnly) {
    return `${t('utils.onboarding-only')}`
  } else if (isNotDigiCustomer) {
    return `${t('utils.only-not-digi')}`
  } else if (isDiscountInEffect) {
    return `${t('utils.discount-already-in-use')}`
  }
  return `${t('validation.something-went-wrong')}`
}
