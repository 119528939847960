import { Feed } from './../models/Feed'
import INewsfeedApi from './INewsfeedApi'
import { getWithRetry } from './rest'
import { getConfig } from '../config'
import AppStore from '../stores/AppStore'

async function feed(
  accessToken: string,
  customerId: number,
  page: number,
  startDate: string,
  refreshOrders: boolean
): Promise<Feed[]> {
  const url = `${
    getConfig().apiUrl
  }/Newsfeed/combined/${customerId}?Page=${page}&StartDate=${startDate}&RefreshCustomerOrders=${refreshOrders}&Language=${
    AppStore.currentLanguage
  }`
  const newsfeedList = await getWithRetry(url, accessToken)
  return newsfeedList
}

export const newsfeedApi: INewsfeedApi = {
  feed,
}
