import React from 'react'
import FurtherClarificationContent from '@taaleri/components/src/screens/onboarding/FurtherClarificationContent'
import QuestionContainer from 'screens/questions/QuestionContainer'

export function FurtherClarificationScreen() {
  return (
    <QuestionContainer>
      <FurtherClarificationContent />
    </QuestionContainer>
  )
}
