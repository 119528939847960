import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { LabelValueWithMargin } from '../../ui/text/LabelValue'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const QuestionAgeContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Title>{t('questions.age-title')}</Title>
      <Paragraph style={{ marginBottom: 0 }}>
        {t('questions.tell-your-age')}
      </Paragraph>
      <LabelValueWithMargin
        label={t('questions.your-age')}
        labelComponent="bold"
        value={`${t('filter.years', { value: QuestionStore.age })}`}
      />
    </>
  )
}

export default observer(QuestionAgeContent)
