import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import Selector from './Selector'
import { StyleProp, ViewStyle } from 'react-native'
import { SelectorTitleString } from './SelectorTitle'
import { useTranslation } from 'react-i18next'

interface Props {
  onToggle: () => void
  isOpen: boolean
  containerStyle?: StyleProp<ViewStyle>
}

function ReasonSelector({ onToggle, isOpen, containerStyle }: Props) {
  const { t } = useTranslation()
  const { selectedReasons, allReasons, filterByReasons } = TransactionStore
  const count = filterByReasons ? selectedReasons.length : allReasons.length
  const titleComponent = (
    <SelectorTitleString
      title={`${t('selector.search-by-reason-code')}`}
      onRemove={(s: string) => TransactionStore.removeSelectedReason(s)}
      selectedItems={selectedReasons}
    />
  )

  return (
    <Selector
      {...{ onToggle, isOpen, count, titleComponent, containerStyle }}
    />
  )
}

export default observer(ReasonSelector)
