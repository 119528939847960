import React from 'react'
import { View } from 'react-native'
import Title from '@taaleri/components/src/ui/text/Title'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import PhotoIdHelp from '@taaleri/components/src/screens/onboarding/PhotoIdHelp'
import AcceptedIdentifications from '@taaleri/components/src/screens/onboarding/AcceptedIdentifications'
import IdentityCardHelpImages from '@taaleri/components/src/screens/identitycard/IdentityCardHelpImages'
import UploadButton from './UploadButton'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function PhotoIdUpload() {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('idUpdate.id')}</Title>
      <Paragraph>{t('idUpdate.update-disclaimer-1')}</Paragraph>
      <IdentityCardHelpImages />
      <Paragraph>{t('idUpdate.upload-helptext')}</Paragraph>
      <AcceptedIdentifications />
      <PhotoIdHelp />
      <UploadButton />
    </View>
  )
}

export default observer(PhotoIdUpload)
