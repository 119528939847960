import React from 'react'
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Colors from '@taaleri/core/src/constants/Colors'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import PropsChildren from '@taaleri/components/src/components/PropsChildren'

interface Props {
  noPadding?: boolean
  leadingTitle?: boolean
  greyBackground?: boolean
  style?: StyleProp<ViewStyle>
}

const ContentContainer: React.FunctionComponent<Props> = ({
  children,
  noPadding,
  leadingTitle,
  style,
}) => {
  const { isNonSmall } = useLayout()
  return (
    <View
      style={[
        styles.container,
        noPadding !== true && styles.padding,
        isNonSmall && styles.containerLarge,
        leadingTitle && styles.leadingTitle,
        style,
      ]}
    >
      {children}
    </View>
  )
}

export function ContentContainerWide(props: Props & PropsChildren) {
  const { isNonSmall } = useLayout()
  return (
    <ContentContainer
      {...{ props }}
      style={[
        { maxWidth: '100%' },
        isNonSmall && {
          width: 688,
          marginHorizontal: 'auto',
        },
        props.style,
      ]}
    >
      {props.children}
    </ContentContainer>
  )
}

export const ContentContainerBoxWide: React.SFC<Props> = ({ children }) => {
  return (
    <ContentContainerBox leadingTitle={false} greyBackground={true}>
      {children}
    </ContentContainerBox>
  )
}

export const ContentContainerBox: React.SFC<Props> = ({
  children,
  leadingTitle,
  greyBackground,
}) => {
  const { isNonSmall, isSmall } = useLayout()
  return (
    <View
      style={[
        styles.box,
        isNonSmall && styles.boxLarge,
        leadingTitle && isSmall && styles.leadingTitleBox,
        isNonSmall && !greyBackground && styles.boxLargeWhite,
      ]}
    >
      {children}
    </View>
  )
}

export default ContentContainer

const styles = StyleSheet.create({
  box: {
    paddingTop: Spacings.S16,
    paddingBottom: 40,
    paddingHorizontal: Spacings.S24,
    borderRadius: BorderRadius.default,
    marginBottom: Spacings.S32,
  },
  boxLarge: {
    minHeight: '50vh',
    width: 684,
    marginHorizontal: 'auto',
  },
  boxLargeWhite: {
    paddingHorizontal: 48,
    backgroundColor: Colors.white,
  },

  leadingTitle: {
    marginTop: -Spacings.S24,
  },
  leadingTitleBox: {
    marginTop: -Spacings.S40,
  },
  container: {
    position: 'relative',
    maxWidth: 556,
    marginBottom: Spacings.S32,
  },
  containerLarge: {
    width: 556,
    marginHorizontal: 'auto',
  },
  padding: {
    paddingHorizontal: Spacings.S24,
  },
})
