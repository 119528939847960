import React, { useEffect } from 'react'
import { QuestionStartContent } from '@taaleri/components/src/screens/questions/QuestionStartContent'
import QuestionContainer from './QuestionContainer'
import { ROUTE_QUESTION_START } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import Colors from '@taaleri/core/src/constants/Colors'
import { View, StyleSheet } from 'react-native'
import Buttons from 'ui/Buttons'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import useStartOnboarding from '@taaleri/components/src/screens/questions/useStartOnboarding'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { getConfig } from '@taaleri/core/src/config'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { routePathQuestions } from '@taaleri/components/src/navigation/routeHelper'
import useQueryParams from 'navigation/useQueryParams'
import FaqSection from '@taaleri/components/src/screens/questions/FaqSection'
import { findStartFaqs } from '@taaleri/core/src/models/Faq'
import { useTranslation } from 'react-i18next'
import { setLanguage } from '@taaleri/core/src/i18n'
import AppStore from '@taaleri/core/src/stores/AppStore'

function QuestionStartScreen() {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  const { navigate } = useNavigation()
  const { discountCode } = useQueryParams()

  useEffect(() => {
    QuestionStore.reset()
    setLanguage(AppStore.currentLanguage)
  }, [])

  useEffect(() => {
    if (discountCode) {
      QuestionStore.setDiscountCode(discountCode as string)
    }
  }, [discountCode])
  const {
    loading,
    initOnboardingFailed,
    setInitOnboardingFailureState,
    reset,
  } = useStartOnboarding()
  if (loading) {
    return <LoadingIndicator />
  }

  if (initOnboardingFailed) {
    return (
      <ErrorWithRetry
        onRetry={async () => setInitOnboardingFailureState(false)}
      />
    )
  }

  const nextRoute = nextQuestionRoute(ROUTE_QUESTION_START)

  return (
    <View>
      <QuestionContainer noBackgroundColor={true} noPadding={true}>
        <View style={[isNonSmall && styles.container]}>
          <View
            style={[
              {
                backgroundColor: Colors.background,
                paddingHorizontal: Spacings.S20,
                borderRadius: BorderRadius.default,
                paddingVertical: Spacings.S20,
              },
              isNonSmall && styles.box,
              isNonSmall && {
                marginBottom: Spacings.S64,
                minHeight: '50vh',
                paddingBottom: Spacings.S24,
              },
            ]}
          >
            <TopBottomContainer
              style={{
                minHeight: 'initial',
                justifyContent: isNonSmall ? 'initial' : 'flex-end',
              }}
            >
              <View style={{ paddingTop: Spacings.S32 }}>
                <QuestionStartContent color={Colors.text} />
              </View>
              <View>
                <Buttons
                  title={t('button.continue')}
                  onPress={() => {
                    QuestionStore.tupasDone = false
                    navigate(routePathQuestions(nextRoute))
                  }}
                  titleSecondary={
                    isNonSmall
                      ? getConfig().testMode && QuestionStore.allowRestart
                        ? `${t('questions.reset')}`
                        : undefined
                      : `${t('menu.faq')}`
                  }
                  secondaryGray={true}
                  onPressSecondary={() => {
                    if (getConfig().testMode && QuestionStore.allowRestart) {
                      reset()
                    } else {
                      document
                        ?.getElementById('faq')
                        ?.scrollIntoView({ behavior: 'smooth' })
                    }
                  }}
                />
              </View>
            </TopBottomContainer>
          </View>
          <View
            style={[
              {
                borderRadius: isNonSmall ? BorderRadius.default : 0,
                backgroundColor: isNonSmall
                  ? Colors.backgroundPale
                  : Colors.gray10,
                paddingHorizontal: Spacings.S20,
                paddingTop: isNonSmall ? Spacings.S40 : Spacings.S56,
                paddingBottom: 88,
              },
              isNonSmall && styles.box,
            ]}
          >
            <div id="faq">
              <FaqSection
                title={t('menu.faq')}
                items={findStartFaqs()}
              />
            </div>
          </View>
        </View>
      </QuestionContainer>
    </View>
  )
}

export default observer(QuestionStartScreen)

const styles = StyleSheet.create({
  container: {
    width: 812,
    marginLeft: -Spacings.S64,
    marginTop: -Spacings.S24,
  },
  box: {
    paddingHorizontal: Spacings.S64,
  },
})
