import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { usePurchaseLots } from '@taaleri/components/src/screens/portfolio/usePurchaseLots'
import { useCombinedIndexByTicker } from '@taaleri/components/src/screens/portfolio/useCombinedIndex'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import PurchaseLotsContent from '@taaleri/components/src/screens/portfolio/PurchaseLotsContent'
import PurchaseLotsContentWide from '@taaleri/components/src/screens/portfolio/PurchaseLotsContentWide'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { useParams } from 'react-router-dom'
import { usePdfToken } from '@taaleri/components/src/screens/portfolio/usePdfToken'

interface Parameters {
  ticker: string
  assetClassId: string
}

function PurchaseLotsScreen() {
  const { ticker, assetClassId } = useParams<Parameters>()
  const assetClassItem = AppStore.getAssetClassItemByTicker(
    ticker,
    assetClassId
  )

  const combindeIndexResponse = useCombinedIndexByTicker(ticker)
  const purchaseLotsResponse = usePurchaseLots(ticker)
  const tokenResponse = usePdfToken()
  const { isSmall } = useLayout()

  if (!assetClassItem) {
    return null
  }

  if (combindeIndexResponse.error) {
    return (
      <ErrorWithRetry
        onRetry={async () => combindeIndexResponse.setRetry(true)}
      />
    )
  }
  if (purchaseLotsResponse.error) {
    return (
      <ErrorWithRetry
        onRetry={async () => purchaseLotsResponse.setRetry(true)}
      />
    )
  }

  if (tokenResponse.error) {
    return <ErrorWithRetry onRetry={async () => tokenResponse.setRetry(true)} />
  }

  if (
    purchaseLotsResponse.loading ||
    combindeIndexResponse.loading ||
    tokenResponse.loading
  ) {
    return <LoadingIndicator />
  }

  const { purchaseLots } = purchaseLotsResponse
  const { combinedIndex } = combindeIndexResponse
  const { token } = tokenResponse

  return isSmall ? (
    <PurchaseLotsContent
      purchaseLots={purchaseLots}
      combinedIndex={combinedIndex}
      assetClassItem={assetClassItem}
      token={token}
    />
  ) : (
    <PurchaseLotsContentWide
      purchaseLots={purchaseLots}
      combinedIndex={combinedIndex}
      assetClassItem={assetClassItem}
      token={token}
    />
  )
}

export default PurchaseLotsScreen
