import React from 'react'
import { View } from 'react-native'
import UiTouchable from '../../ui/UiTouchable'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import useLayout from '../../ui/useLayout'
import {
  getAssetClassWideImageById,
  isMoney,
} from '@taaleri/core/src/constants/AssetClass'
import useNavigation from '../../navigation/useNavigation'
import Box from '../../ui/box/Box'
import { OwnershipRow } from './OwnershipRow'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import TouchableShrink from '../../ui/TouchableShrink'
import AssetDetailBox from '../home/AssetDetailBox'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { OwnershipHeadingSimple } from './OwnerShipHeadingSimple'
import useAssetClassDetailRoute from './useAssetClassDetailRoute'
import { sum } from '@taaleri/core/src/utils/format'
import { useTranslation } from 'react-i18next'

interface Props {
  assetClass: AssetClass
  onPress: (ticker: string) => void
}

export function AssetDetailSimple({ assetClass, onPress }: Props) {
  const { isSmall, screenWidth } = useLayout()
  const { routeName, params } = useAssetClassDetailRoute(assetClass)
  const { navigateApp } = useNavigation()
  const image = getAssetClassWideImageById(assetClass.id)
  const { t } = useTranslation()
  const portfolio = AppStore.portfolio
  if (!portfolio) {
    return null
  }

  const assetClasses = portfolio.assetClasses
  const weights = assetClasses.map((s) => s.percentageOfTotal)

  const totalMarketValue = sum(assetClass.items.map((e) => e.marketValue))
  const totalPurchaseValue = sum(
    assetClass.items.map((e) => e.purchaseValue ?? 0)
  )
  const marketValueChangePercent = totalPurchaseValue
    ? (100 * (totalMarketValue - totalPurchaseValue)) / totalPurchaseValue
    : 0

  return (
    <View>
      {isSmall ? (
        <TouchableShrink onPress={() => navigateApp(routeName, params)}>
          <AssetDetailBox
            image={image}
            weights={weights}
            weight={assetClass.percentageOfTotal}
            title={t(assetClass.name)}
            marketValue={assetClass.marketValue}
            marketValueChangePercentage={assetClass.marketValueChangePercent}
            boxType="special"
          />
        </TouchableShrink>
      ) : (
        <OwnershipHeadingSimple />
      )}
      <Box
        style={{
          borderTopLeftRadius: isSmall ? 0 : 6,
          borderTopRightRadius: isSmall ? 0 : 6,
        }}
      >
        {assetClass.items.map((assetClassItem, index) => {
          const topBorderRadius = !isSmall && index === 0
          return isMoney(assetClassItem.ticker) ? (
            <OwnershipRow
              borderBottom={true}
              key={index}
              color={assetClassColors[assetClass.id]}
              assetClassItem={assetClassItem}
              screenWidth={screenWidth}
              topBorderRadius={topBorderRadius}
              isCash={true}
            />
          ) : (
            <UiTouchable
              key={index}
              onPress={() => onPress(assetClassItem.ticker)}
            >
              <OwnershipRow
                borderBottom={true}
                key={index}
                color={assetClassColors[assetClass.id]}
                assetClassItem={assetClassItem}
                screenWidth={screenWidth}
                topBorderRadius={topBorderRadius}
                showHover={true}
                bottomBorderRadius={false}
              />
            </UiTouchable>
          )
        })}
        {isSmall || (
          <OwnershipRow
            borderBottom={true}
            key="Yhteensä"
            color={assetClassColors[assetClass.id]}
            total={true}
            assetClassItem={{
              marketValue: totalMarketValue,
              purchaseValue: totalPurchaseValue,
              friendlyName: `${t('portfolio.total')}`,
              name: `${t('portfolio.total')}`,
              ticker: `${t('portfolio.total')}`,
              securityName: `${t('portfolio.total')}`,
              assetCategoryId: '',
              assetCategoryName: '',
              assetAdditionalInfoId: '',
              assetAdditionalInfoName: '',
              sharePercentage: sum(
                assetClass.items.map((e) => e.sharePercentage)
              ),
              marketValueChangePercent,
              nameFi: `${t('portfolio.total')}`,
              nameSv: `${t('portfolio.total')}`,
              nameEn: `${t('portfolio.total')}`
            }}
            screenWidth={screenWidth}
            topBorderRadius={false}
            showHover={true}
            bottomBorderRadius={true}
          />
        )}
      </Box>
    </View>
  )
}
