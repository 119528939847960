import useAdvancedPortfolio from '@taaleri/components/src/screens/portfolio/useAdvancedPortfolio'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import PortfolioContentDigi from './PortfolioContentDigi'
import PortfolioContentReport from './PortfolioContentReport'

function PortfolioScreen() {
  const { loading } = AppStore
  const showAdvancedPortfolio = useAdvancedPortfolio()
  if (loading) {
    return <LoadingIndicator type="inline" />
  }

  if (showAdvancedPortfolio) {
    return <PortfolioContentReport />
  }
  return <PortfolioContentDigi />
}

export default observer(PortfolioScreen)
