import { Fund } from '@taaleri/core/src/models/Fund'

import { sleep } from '@taaleri/core/src/api/mockHelper'
import IFundApi from './IFundApi'

import funds from '../fixtures/funds/funds.json'
import fundsList from '../fixtures/funds/fundsList.json'
import fundRecommendationList from '../fixtures/funds/fundRecommendationList.json'
import { toFundProposition } from './normalization/toPortfolio'
import { FundProposition } from '../models/FundProposition'
import { PortfolioResponse } from '../models/Portfolio'
import { TEST_CUSTOMER_ID } from '../models/testCustomers'
import { Order } from '../models/Order'
import { toOrders } from '../models/OpenOrderResponse'
import { FundOrderResponse } from '../models/Contract'

async function createFundOrder(): Promise<FundOrderResponse> {
  await sleep(500)
  return Promise.resolve({
    smsConfirmationId: '123456',
    assetContractId: '123546',
    feeValueEstimate: 1,
    fee: 1,
  })
}

async function fundRecommendations() {
  await sleep(500)
  return Promise.resolve(fundRecommendationList as unknown as Fund[])
}

async function fundList() {
  return Promise.resolve(fundsList as unknown as Fund[])
}

async function fundListAll() {
  return Promise.resolve(fundsList as unknown as Fund[])
}

async function findFund(ticker: string) {
  await sleep(500)
  const fund = funds.find((f) => f.ticker === ticker)
  return Promise.resolve(fund as unknown as Fund)
}

async function getOpenOrders(_1: number, _2: string): Promise<Order[]> {
  await sleep(300)
  const openOrders = require(`../fixtures/funds/openOrdersResponse.json`)
  return Promise.resolve(toOrders(openOrders))
}

async function fundProposition(customerId: number): Promise<FundProposition> {
  await sleep(100)
  const positions = require(`../fixtures/reports/positions/positions.json`)
  const portfolio =
    positions[customerId] || (positions[TEST_CUSTOMER_ID] as PortfolioResponse)
  const response = {
    positions: portfolio,
    actualRisk: 5.0,
    actualVolatility: 0.14,
    simulatedRisk: 5.0,
    simulatedVolatility: 0.14,
  }
  return toFundProposition(response)
}

async function cancelFundOrder(_: number, _1: string, _2: string) {
  return Promise.resolve({
    smsConfirmationId: '',
    assetContractId: '',
    feeValueEstimate: 1,
    fee: 1,
  })
}

export const fundApiMock: IFundApi = {
  createFundOrder,
  fundRecommendations,
  fundList,
  fundListAll,
  findFund,
  fundProposition,
  getOpenOrders,
  cancelFundOrder,
}
