import Colors from '@taaleri/core/src/constants/Colors'
import CustomerService from '@taaleri/core/src/constants/CustomerService'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import FlexRow from '../../ui/FlexRow'
import { IconPhone, IconEmail } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { BoxWithPadding } from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { Title3 } from '../../ui/text/Title'
import { TextMedium, TextDefault } from '../../ui/text/UiText'

function onCall(phoneNumber: string) {
  const url = `tel:${phoneNumber}`
  openUrl(url)
}

function onMessage() {
  openUrl(CustomerService.messageUrl)
}

export function ContactCustomerServiceBox() {
  const { t } = useTranslation()
  const { phoneNumber } = CustomerService
  return (
    <BoxWithPadding
      style={{ marginBottom: Spacings.S24, paddingBottom: 0 }}
      title={`${t('profile.customerServiceSection.contact')}`}
    >
      <FlexRow
        style={[
          {
            alignItems: 'center',
            paddingVertical: Spacings.S24,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
          },
        ]}
      >
        <View style={{ flex: 1, alignItems: 'center' }}>
          <Title3 style={[{ marginBottom: 4, marginTop: 0 }]}>
            {t('profile.customerServiceSection.customerService')}
          </Title3>

          <TextDefault type="t2" style={[{ marginBottom: Spacings.S4 }]}>
            {phoneNumber}
          </TextDefault>
        </View>
      </FlexRow>
      <FlexRow
        style={{
          justifyContent: 'space-between',
          paddingVertical: Spacings.S16,
        }}
      >
        <UiTouchable
          onPress={() => onCall(phoneNumber.split(' ').join(''))}
          style={[
            styles.contactContainer,
            {
              borderRightWidth: 1,
              borderRightColor: Colors.border,
            },
          ]}
        >
          <IconPhone />
          <TextMedium style={styles.contactText}>
            {t('profile.customerServiceSection.call')}
          </TextMedium>
        </UiTouchable>
        <UiTouchable
          style={styles.contactContainer}
          onPress={() => onMessage()}
        >
          <IconEmail />
          <TextMedium style={styles.contactText}>
            {t('profile.customerServiceSection.message')}
          </TextMedium>
        </UiTouchable>
      </FlexRow>
    </BoxWithPadding>
  )
}

const styles = StyleSheet.create({
  contactContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactText: {
    fontSize: FontSize.S14,
    marginLeft: Spacings.S16,
  },
})
