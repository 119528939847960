import { Identity } from '../models/Identity'
import { getConfig } from '../config'
import { deleteApi, get, post } from './rest'
import ISavingsPlanApi from './ISavingsPlanApi'
import { SavingsPlan } from '../models/SavingsPlan'

function getBaseUrl() {
  return `${getConfig().apiUrl}/SavingsPlans/`
}

async function getSavingsPlan(
  identity: Identity,
  customerId: number
): Promise<SavingsPlan> {
  const { accessToken } = identity
  return get(`${getBaseUrl()}${customerId}`, accessToken)
}

// Can also be used to update savings plan
async function createSavingsPlan(
  identity: Identity,
  customerId: number,
  targetAmount: number
): Promise<SavingsPlan> {
  const { accessToken } = identity
  return post(
    `${getBaseUrl()}createOrUpdate/${customerId}?targetAmount=${targetAmount}`,
    accessToken
  )
}

async function deleteSavingsPlan(
  identity: Identity,
  customerId: number
): Promise<void> {
  const { accessToken } = identity
  return deleteApi(`${getBaseUrl()}delete/${customerId}`, accessToken)
}

export const savingsPlanApi: ISavingsPlanApi = {
  getSavingsPlan,
  createSavingsPlan,
  deleteSavingsPlan,
}
