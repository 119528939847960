import { ImageSourcePropType } from 'react-native'
import { ImpactProduct } from '@taaleri/core/src/models/ImpactProduct'

export const impactDefaultImage = require('../../../../../../packages/app/assets/products/impact.jpg')

export function getImpactImage(
  impactProduct: ImpactProduct
): ImageSourcePropType {
  return {
    uri: impactProduct.imageUrl ? impactProduct.imageUrl : impactDefaultImage,
  }
}
