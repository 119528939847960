import {
  TaxationCountry,
  RegulatoryQuestions,
} from '@taaleri/core/src/models/RequlatoryQuestions'
import { USA, FINLAND } from '@taaleri/core/src/models/Countries'
import { ServiceResponse } from '@taaleri/core/src/models/ServiceResponse'
import { FurtherClarificationMessages } from '@taaleri/core/src/constants/FurtherClarificationMessages'

export function taxCountryInvalid(
  countries: TaxationCountry[]
): ServiceResponse<string> {
  const isUsa = countries.filter((tax) => tax.country === USA.code).length > 0
  const firstCountryNotFinland: boolean =
    countries.length > 0 && countries[0].country !== FINLAND.code
  return {
    success: firstCountryNotFinland || isUsa,
    response: isUsa
      ? FurtherClarificationMessages.taxationCountryUsa
      : FurtherClarificationMessages.primaryTaxationCountryNotFinland,
  }
}

export function validateRegulatoryQuestions(
  values: RegulatoryQuestions
): ServiceResponse<string> {
  if (values.incompetent) {
    return { success: true, response: FurtherClarificationMessages.incompetent }
  }
  return taxCountryInvalid(values.taxationCountries)
}
