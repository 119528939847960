import React from 'react'
import { View, StyleSheet } from 'react-native'
import { IconWarning } from '../../ui/Icons'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextBold } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Paragraph } from '../../ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function ContractUnsuccesfulContent() {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <IconWarning color={Colors.text} style={styles.icon} />
      <TextBold style={{ marginBottom: Spacings.S8 }}>
        {t('contract.failed-confirmation-title')}
      </TextBold>
      <Paragraph style={styles.paragraph}>
        {t('contract.failed-confirmation-description')}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: Spacings.S40,
  },

  icon: { marginBottom: Spacings.S16 },
  paragraph: { textAlign: 'center' },
})
