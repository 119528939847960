import React from 'react'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { useFund } from '@taaleri/components/src/screens/funds/useFunds'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import MonthlyContractContent from '@taaleri/components/src/screens/funds/MonthlyContractContent'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'

function MonthlyContractScreen() {
  const { fund } = useFund(AppStore.fundId)
  if (!AppStore.fundId) {
    return null
  }

  if (!fund) {
    return <LoadingIndicator />
  }

  return (
    <>
      <PaymentStepsFund currentStep={2} recurring={true} />
      <ContentContainerWide>
        <MonthlyContractContent fund={fund} />
      </ContentContainerWide>
    </>
  )
}

export default observer(MonthlyContractScreen)
