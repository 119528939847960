import React from 'react'

import Nav from '../../navigation/Nav'
import HeaderSmallContainer from './HeaderSmallContainer'

export default function HeaderSmall() {
  return (
    <HeaderSmallContainer>
      <Nav type="mobile" />
    </HeaderSmallContainer>
  )
}
