import React from 'react'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import SmsVerification from '../contract/SmsVerification'
import { useTranslation } from 'react-i18next'

import {
  ROUTE_ONBOARDING_CREATING_CUSTOMER,
  ROUTE_ONBOARDING_CONTRACTS_VERIFICATION,
} from './OnboardingRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'

export interface OnboardingVerificationProps {
  navigate: (route: string, params?: any) => void
}
export function OnboardingVerification(props: OnboardingVerificationProps) {
  const { t } = useTranslation()
  const onSuccess = async () => {
    props.navigate(ROUTE_ONBOARDING_CREATING_CUSTOMER)
  }
  const phone = QuestionStore.customer.phone
  const title = 'questions.accountCreation.contract-signing'
  const description = 'questions.accountCreation.confirm-sign-with-text-code'
  return (
    <SmsVerification
      t={t}
      title={t(title)}
      description={t(description, { phone: phone })}
      onSuccess={onSuccess}
      phone={phone}
      afterConflictNavigateTo={ROUTE_ONBOARDING_CONTRACTS_VERIFICATION}
      onPrepareVerification={async () => {
        if (AppStore.smsConfirmationId) {
          return Promise.resolve(AppStore.smsConfirmationId)
        }
        return Promise.reject('SMS confirmation missing')
      }}
      navigate={props.navigate}
    />
  )
}
