import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'

export function IconCheckCircleWhiteBg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', bottom: 16, right: Spacings.S16 }}
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
        fill="#2BC9AF"
      />
      <path
        d="M10 17.0001L5 12.0001L6.41 10.5901L10 14.1701L17.59 6.58008L19 8.00008L10 17.0001Z"
        fill="white"
      />
    </svg>
  )
}
