import React from 'react'
import TransactionRowContainer from '../../reporttransaction/Rows/TransactionRowContainer'
import { HeaderItem } from './HeaderItem'
import { useTranslation } from 'react-i18next'

export function CapitalGainHeader() {
  const { t } = useTranslation()
  return (
    <TransactionRowContainer hideBorder={true}>
      <HeaderItem text={t('portfolio.text')} flex={2} />
      <HeaderItem text={t('security')} flex={2} />
      <HeaderItem text={t('report.pcs')} />
      <HeaderItem text={t('report.acquisition-period')} />
      <HeaderItem text={t('report.delivery-time')} />
      <HeaderItem text={t('report.purchase-value-price')} />
      <HeaderItem text={t('report.surrender-value-price')} />
      <HeaderItem text={t('report.profit-acquisition-cost')} />
      <HeaderItem text={`${t('report.acquisition-cost-assumption')}`} />
      <HeaderItem text={t('report.profit-loss')} />
    </TransactionRowContainer>
  )
}
