import React from 'react'
import ContentContainer from '../../ui/container/ContentContainer'
import TopBottomContainer from '../../ui/container/TopBottomContainer'
import Buttons from '../../ui/Buttons'
import ExistingOrderContent from '@taaleri/components/src/screens/portfolio/ExistingOrderContent'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import { useTranslation } from 'react-i18next'

export default function ExistingOrderScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <ContentContainer>
      <TopBottomContainer>
        <ExistingOrderContent />
        <Buttons
          title={t('button.close')}
          onPress={() => {
            navigate(routePath(ROUTE_APP))
          }}
        />
      </TopBottomContainer>
    </ContentContainer>
  )
}
