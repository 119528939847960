export const FROM_BEGINNING = 'FROM_BEGINNING'
export const PAST_YEAR = 'PAST_YEAR'
export const PAST_MONTH = 'PAST_MONTH'
export const SIX_MONTHS = 'SIX_MONTHS'
export const YEAR_TO_DATE = 'YEAR_TO_DATE'
export const LAST_YEAR = 'LAST_YEAR'
export const PAST_THREE_YEARS = 'PAST_THREE_YEARS'
export const PAST_FIVE_YEARS = 'PAST_FIVE_YEARS'
export const CUSTOM_DATE_RANGE = 'CUSTOM'

export type DATE_RANGE =
  | typeof FROM_BEGINNING
  | typeof PAST_YEAR
  | typeof PAST_MONTH
  | typeof YEAR_TO_DATE
  | typeof LAST_YEAR
  | typeof PAST_THREE_YEARS
  | typeof PAST_FIVE_YEARS
  | typeof CUSTOM_DATE_RANGE
  | typeof SIX_MONTHS
