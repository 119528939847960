import { FINLAND } from '@taaleri/core/src/models/Countries'

import { toAnswers } from './toAnswers'
import { toRequlatoryQuestions } from './toRequlatoryQuestions'
import {
  Customer,
  CustomerBasicInfo,
  CustomerBasicInfoResponse,
  emptyAddress,
} from '../../models/Customer'
import { formatIban } from '../../utils/format'

export function toCustomerBasicInfo(
  customerBasicInfoResponse: CustomerBasicInfoResponse
): CustomerBasicInfo {
  const customer = toCustomerContractUpdate(customerBasicInfoResponse)

  const requlatoryQuestions = toRequlatoryQuestions(customerBasicInfoResponse)
  const answers = toAnswers(customerBasicInfoResponse)

  const monthlyExpenses = customerBasicInfoResponse.basicInfo.monthlyExpenditure
  const monthlyIncome = customerBasicInfoResponse.basicInfo.monthlyIncome
  const monthlyCapitalIncome =
    customerBasicInfoResponse.basicInfo.monthlyCapitalIncome
  const privateMonetaryTransactions =
    customerBasicInfoResponse.basicInfo
      .privateCustomerMonetaryTransactionsInAktia

  return {
    customer,
    requlatoryQuestions,
    answers,
    monthlyExpenses,
    monthlyIncome,
    monthlyCapitalIncome,
    privateMonetaryTransactions,
  }
}

export function toCustomerContractUpdate(
  customerBasicInfoResponse: CustomerBasicInfoResponse
): Customer {
  const { customer: customerInformation, basicInfo } = customerBasicInfoResponse
  const {
    bankAccountNumber,
    levelOfEducation,
    socioeconomicStatus,
    profession,
    accountPurpose,
    accountPurposeDescription,
  } = basicInfo
  const {
    address,
    customerId,
    countryOfBirth,
    email: emailAddress,
    ssnBic: ssn,
    fullName,
    isMarketingPermitted,
    nationality,
    phoneNumber,
    postalAddress,
    firstName,
    lastName,
  } = customerInformation

  const homeAddress = address && address.street != null ? address : emptyAddress

  const postAddress =
    postalAddress && postalAddress.street != null ? postalAddress : emptyAddress

  if (!homeAddress.country) {
    homeAddress.country = FINLAND.code
  }
  if (!postAddress.country) {
    postAddress.country = FINLAND.code
  }

  return {
    accountPurpose: accountPurpose || '',
    accountPurposeDescription,
    basicContractSigned: null,
    digiContractSigned: null,
    consultativeContractSigned: null,
    onboardingId: null,
    customerId: parseFloat(customerId),
    firstName: firstName || '',
    lastName: lastName || '',
    customerName: '',
    identityNumber: ssn || '',
    email: emailAddress || '',
    fullName: fullName || '',
    phone: phoneNumber || '',
    nationality: nationality || '',
    countryOfBirth: countryOfBirth || '',
    profession: profession || '',
    socioeconomicStatus: socioeconomicStatus || '',
    levelOfEducation: levelOfEducation || '',
    bankAccountNumber: bankAccountNumber ? formatIban(bankAccountNumber) : '',
    marketingAllowance: isMarketingPermitted || false,
    address: homeAddress,
    isPostAddress:
      address &&
      postAddress.street !== null &&
      postAddress.street.length > 0 &&
      postAddress.street !== address.street,
    postAddress,
    customerAuthorizations: [],
    isRepresentative: false,
    accountType: '',
    isDigiCustomer: false,
    isConsultative: false,
    isDigiConversionAllowed: false,
    isDiscretionary: false,
    isCustomer: true,
    isBlocked: false,
    isDigiConverted: false,
    hasDigiBanker: false,
    age: 35,
  }
}
