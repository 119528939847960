import React, { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
} from '@taaleri/components/src/ui/Victory'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import useLayout from '../../ui/useLayout'
import { formatYLabel } from '../calculator/CalculatorGraph'
import { DateValue } from '@taaleri/core/src/models/DateValue'
import { isWeb } from '../../constants/Platforms'
import { formatDate } from '@taaleri/core/src/utils/format'
import { ChartLegend } from '../questions/ChartLegend'
import { useTranslation } from 'react-i18next'

function getTextAnchor(tick: Date, timeSeries: DateValue[]) {
  const last = timeSeries[timeSeries.length - 1].date.toString()
  if (last === tick.toString() && !isWeb) {
    return 'end'
  }
  return 'start'
}

function getTicks(timeSeries: DateValue[]) {
  const first = timeSeries[0].date
  const last = timeSeries[timeSeries.length - 1].date
  return isWeb ? undefined : [first, last]
}

const labelFontSize = FontSize.S10
const yAxisStyle = {
  axis: { stroke: 'transparent' },
  grid: {
    stroke: Colors.inactiveTint,
    strokeDasharray: '1,3',
    strokeWidth: 1,
    strokeOpacity: 1,
  },
  tickLabels: {
    fill: Colors.text,
    fontSize: labelFontSize,
    textAnchor: 'start',
  },
}

interface Props {
  revenueTimeseries: DateValue[]
  capitalTimeseries: DateValue[]
  style?: StyleProp<ViewStyle>
}

export function MarketValueGraph({
  revenueTimeseries,
  capitalTimeseries,
  style,
}: Props) {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>(450)
  const { isSmall } = useLayout()

  const xAxisStyle = {
    axis: { stroke: Colors.border, strokeWidth: 1 },
    axisLabel: { stroke: Colors.border, strokeWidth: 1 },
    ticks: { color: Colors.error },
    tickLabels: {
      fill: Colors.text,

      fontSize: labelFontSize,
      textAnchor: (o: any) => getTextAnchor(o.tick, revenueTimeseries),
    },
  }

  return (
    <View style={style}>
      <View
        pointerEvents="none"
        onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
      >
        <VictoryChart
          padding={{
            top: isSmall ? 0 : Spacings.S24,
            bottom: Spacings.S32,
          }}
          width={width}
          height={isSmall ? 250 : 300}
        >
          <VictoryLine
            data={revenueTimeseries}
            style={{ data: { stroke: Colors.primary } }}
            x="date"
            y="value"
          />
          <VictoryArea
            data={capitalTimeseries}
            style={{ data: { fill: Colors.primaryLight } }}
            x="date"
            y="value"
          />
          <VictoryAxis
            crossAxis={false}
            tickFormat={(date: Date) => formatDate(date)}
            fixLabelOverlap={true}
            style={xAxisStyle}
            tickValues={getTicks(revenueTimeseries)}
          />
          <VictoryAxis
            dependentAxis={true}
            tickLabelComponent={<VictoryLabel x={0} dy={-9} />}
            tickFormat={(value: number) => formatYLabel(value)}
            style={yAxisStyle}
          />
        </VictoryChart>
      </View>
      <ChartLegend
        items={[
          { title: t('netWorth'), color: Colors.primary },
          { title: t('graph.savings'), color: Colors.primaryLight },
        ]}
      />
    </View>
  )
}
