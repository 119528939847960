import {
  createContract,
  updateContract,
  getContractDocument,
  getMultipleContracts,
  updateStrategy,
} from '@taaleri/core/src/services/contracts'
import { useState, useEffect } from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ServiceResponse } from '@taaleri/core/src/models/ServiceResponse'

function useContract(apiCall: () => Promise<ServiceResponse<any>>) {
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>()
  const [urlBasicInfo, setUrlBasicInfo] = useState<string | undefined>()

  useEffect(() => {
    const loadContract = async () => {
      setError(false)
      setRetry(false)
      const { success, response } = await apiCall()
      if (!success) {
        setError(true)
        return
      }
      if (response.smsConfirmationId) {
        AppStore.smsConfirmationId = response.smsConfirmationId
      }

      if (response.basicInfoContractId && response.assetContractId) {
        const contractResponse = await getMultipleContracts([
          response.assetContractId,
          response.basicInfoContractId,
        ])
        if (!contractResponse.success) {
          setError(true)
          return
        }
        setUrl(contractResponse.response)
        return
      } else if (response.assetContractId) {
        const contractAssetResponse = await getContractDocument(
          response.assetContractId
        )
        if (!contractAssetResponse.success) {
          setError(true)
          return
        }
        setUrl(contractAssetResponse.response)
      } else if (response.basicInfoContractId) {
        const contractBasicInfoResponse = await getContractDocument(
          response.basicInfoContractId
        )
        if (!contractBasicInfoResponse.success) {
          setError(true)
          return
        }
        setUrlBasicInfo(contractBasicInfoResponse.response)
      }
    }

    loadContract()
  }, [retry])
  return {
    url,
    error,
    setRetry,
    urlBasicInfo,
  }
}

export function useUpdateContract() {
  return useContract(updateContract)
}

export function useCreateContract() {
  return useContract(createContract)
}

export function useUpdateStrategy() {
  return useContract(updateStrategy)
}
