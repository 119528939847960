import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { NumberedSectionBox } from '@taaleri/components/src/screens/portfolio/NumberedSectionBox'
import { InfoContainer } from '@taaleri/components/src/ui/InfoContainer'
import {
  ParagraphBoldFirst,
  Paragraph,
} from '@taaleri/components/src/ui/text/Paragraph'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { BackButton } from '@taaleri/components/src/ui/BackButton'
import Title from '@taaleri/components/src/ui/text/Title'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import { copyToClipboard } from '@taaleri/components/src/ui/copyToClipboard'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { getDiscountCode } from '@taaleri/core/src/services/discountCode'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { getDiscountCodeUrl } from '@taaleri/components/src/screens/reporttransaction/TransactionPdfUrl'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

function ReportDiscountContent() {
  const { t } = useTranslation()
  const [discountCode, setDiscountCode] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  async function fetchDiscountCode() {
    setLoading(false)
    setError(false)
    const { success, response } = await getDiscountCode(AppStore.customerId)
    if (success && response) {
      setDiscountCode(response.code)
    } else {
      setDiscountCode(undefined)
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDiscountCode()
  }, [AppStore.customerId])

  if (error) {
    return <ErrorWithRetry onRetry={() => fetchDiscountCode()} />
  }

  if (loading || !discountCode) {
    return <LoadingIndicator />
  }

  return (
    <View>
      {isWeb && <BackButton />}
      <Title style={{ marginTop: Spacings.S40, marginBottom: Spacings.S16 }}>
        {t('reportFee.recommend-title')}
      </Title>
      <Paragraph style={{ marginBottom: Spacings.S40 }}>
        {t('reportFee.recommend-friend')}
      </Paragraph>
      <NumberedSectionBox
        count={1}
        title={t('reportFee.share-link')}
        paragraph={t('reportFee.share-link-text')}
        style={{ marginBottom: Spacings.S40 }}
      />
      <NumberedSectionBox
        count={2}
        title={t('reportFee.friend-become-customer')}
        paragraph={t('reportFee.friend-become-customer-text')}
        style={{ marginBottom: Spacings.S20 }}
      >
        <UiTouchable
          onPress={() =>
            copyToClipboard(
              getDiscountCodeUrl(discountCode),
              `${t('reportFee.link-copied')}`
            )
          }
          style={{
            paddingVertical: Spacings.S16,
            borderTopWidth: 1,
            borderTopColor: Colors.border,
            marginTop: Spacings.S16,
          }}
        >
          <TextDefault color={Colors.primary}>
            {t('reportFee.copy-link')}
          </TextDefault>
        </UiTouchable>

        <FlexRow
          style={{
            justifyContent: 'space-between',
            paddingVertical: Spacings.S16,
            borderTopWidth: 1,
            borderTopColor: Colors.border,
            borderBottomColor: Colors.border,
            borderBottomWidth: 1,
          }}
        >
          <TextDefault type="h5">{t('reportFee.recommend-code')}</TextDefault>
          <TextDefault>{discountCode}</TextDefault>
        </FlexRow>
      </NumberedSectionBox>
      <InfoContainer style={{ marginBottom: Spacings.S40 }}>
        <ParagraphBoldFirst color={Colors.textSecondary}>
          {t('reportFee.who-to-recommend')}
        </ParagraphBoldFirst>
      </InfoContainer>
      <NumberedSectionBox
        count={3}
        title={t('reportFee.friend-first-invest')}
        paragraph={t('reportFee.friend-first-invest-text')}
        style={{ marginBottom: Spacings.S40 }}
      />
    </View>
  )
}

export default observer(ReportDiscountContent)
