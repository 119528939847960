import { Portfolio } from '@taaleri/core/src/models/Portfolio'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { formatNumber, nonBreakingSpaces } from '@taaleri/core/src/utils/format'
import {
  portfolioToRoundedMarketValue,
  portfolioToRoundedMarketValueDrop,
} from './portfolioMarketValue'
import { TFunction } from 'i18next'

function lossToleranceQuestion(value: number, drop: number, t: TFunction) {
  return t('questions.lossToleranceBehaviour.question', {
    value: nonBreakingSpaces(formatNumber(value)),
    drop: nonBreakingSpaces(formatNumber(drop)),
  })
}

export function lossToleranceQuestionContractUpdate(
  t: TFunction,
  portfolio?: Portfolio
) {
  const roundedMarketValue = portfolioToRoundedMarketValue(portfolio)
  const roundedMarketValueDrop = portfolioToRoundedMarketValueDrop(portfolio)
  return lossToleranceQuestion(roundedMarketValue, roundedMarketValueDrop, t)
}

export function lossToleranceQuestionOnboarding(t: TFunction) {
  const initialInvestment = QuestionStore.initialInvestment
  let value = 5000
  let drop = 4000
  if (initialInvestment > 5000) {
    value = 50000
    drop = 40000
  }
  if (initialInvestment > 50000) {
    value = 100000
    drop = 80000
  }

  return lossToleranceQuestion(value, drop, t)
}
