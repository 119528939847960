import React from 'react'
import SmsVerification from '../contract/SmsVerification'
import {
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_PAYMENT_INFORMATION,
} from './PortfolioRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import { useTranslation } from 'react-i18next'

export default function PaymentRecurringConfirmContent() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const phone = AppStore.customer.phone

  function onSuccess() {
    navigate(routePathApp(ROUTE_PORTFOLIO_PAYMENT_INFORMATION))
  }

  return (
    <SmsVerification
      t={t}
      title={t('invest.confirm-transaction')}
      description={t('invest.confirm-transaction-info', { phone: phone })}
      onSuccess={onSuccess}
      phone={phone}
      afterConflictNavigateTo={ROUTE_PORTFOLIO}
      onPrepareVerification={async () => {
        return AppStore.smsConfirmationId || ''
      }}
      navigate={navigate}
    />
  )
}
