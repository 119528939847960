import React, { useEffect, useState } from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import FlexRow from '../../ui/FlexRow'
import useLayout from '../../ui/useLayout'
import Selector from '../reporttransaction/Selector/Selector'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import DateFilter from '../reporttransaction/Filters/DateFilter'
import {
  CUSTOM_DATE_RANGE,
  DATE_RANGE,
  YEAR_TO_DATE,
} from '@taaleri/core/src/constants/YieldHistory'
import {
  dateRangeToDates,
  dateYearAgo,
  firstDayOfThisYear,
  yesterday,
} from '@taaleri/core/src/utils/date'
import {
  formatCurrency,
  formatDateDash,
  formatDateFull,
  formatPercentageTwoDecimal,
} from '@taaleri/core/src/utils/format'
import { dateRangeValues } from '@taaleri/core/src/constants/DateRange'
import { getFeeReport } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { FeeReport } from '@taaleri/core/src/models/FeeReport'
import { SumBoxes } from '../reportprofit/SumBoxes'
import ReportFeeList from './ReportFeeList'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { TFunction } from 'i18next'

interface DateFilter {
  startDate: Date
  endDate: Date
}

function getSumBoxSubtitle(value: string, t: TFunction) {
  return (
    <FlexRow style={{ alignItems: 'baseline' }}>
      <TextDefault type="t4" style={{ color: Colors.textSecondary }}>
        {t('form.in-year').toUpperCase()}
      </TextDefault>
      <TextDefault type="t3" style={{ color: Colors.textSecondary }}>
        {' ' + value}
      </TextDefault>
    </FlexRow>
  )
}

function getDateRangeTitle(
  startDate: Date,
  endDate: Date,
  dateRange: DATE_RANGE,
  t: TFunction
): string {
  const customRange = `${formatDateFull(startDate)} - ${formatDateFull(
    endDate
  )}`
  if (dateRange !== CUSTOM_DATE_RANGE) {
    return (
      dateRangeValues(t).find((d) => d.id === dateRange)?.value || customRange
    )
  }
  return customRange
}

function ReportFeeContent(props: { t: TFunction }) {
  const { t } = props
  const [dateRange, setDateRange] = useState<DATE_RANGE>(YEAR_TO_DATE)

  const [dates, setDates] = useState<DateFilter>({
    startDate: firstDayOfThisYear(),
    endDate: yesterday(),
  })
  const [isDateOpen, setIsDateOpen] = useState<boolean>(false)
  const [feeReport, setFeeReport] = useState<FeeReport>()

  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { isWide } = useLayout()

  function getDateRange(tDateRange: DATE_RANGE) {
    const { startDate, endDate } = dateRangeToDates(tDateRange as DATE_RANGE)
    if (startDate && endDate) {
      setDateRange(tDateRange)
      setDates({ startDate, endDate })
    }
  }

  async function fetchData() {
    const feeResponse = await getFeeReport(
      AppStore.customerId,
      formatDateDash(dates.startDate),
      formatDateDash(dates.endDate)
    )
    if (feeResponse.success && feeResponse.response) {
      setFeeReport(feeResponse.response)
    } else {
      setError(true)
    }
    setLoading(false)
    setError(false)
  }

  useEffect(() => {
    fetchData()
  }, [AppStore.customerId, dates])

  const totalFeesPercentage = isNaN(feeReport?.totalFeesPercentage ?? 0)
    ? 0
    : feeReport?.totalFeesPercentage ?? 0

  const averageTerPercentage = isNaN(feeReport?.averageTerPercentage ?? 0)
    ? 0
    : feeReport?.averageTerPercentage ?? 0

  const SelectorContainer = isWide ? FlexRow : View
  return (
    <>
      <View style={{ alignItems: 'center' }}>
        <View
          style={{
            backgroundColor: Colors.white,
            padding: isWide ? Spacings.S40 : Spacings.S16,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            maxWidth: 1200,
            width: '100%',
          }}
        >
          <SelectorContainer>
            <Selector
              titleComponent={
                <TextDefault type="h6" style={{ marginLeft: 10 }}>
                  {getDateRangeTitle(
                    dates.startDate,
                    dates.endDate,
                    dateRange,
                    t
                  )}
                </TextDefault>
              }
              isDateFilter={true}
              containerStyle={{
                marginTop: Spacings.S24,
                marginBottom: Spacings.S24,
                justifyContent: 'center',
              }}
              isOpen={isDateOpen}
              onToggle={() => setIsDateOpen(!isDateOpen)}
            />
          </SelectorContainer>
          {isDateOpen && (
            <DateFilter
              onDateRangePress={(tDateRange: string) => {
                getDateRange(tDateRange as DATE_RANGE)
                setIsDateOpen(false)
              }}
              onConfirm={(start: Date, end: Date) => {
                setDates({ startDate: start, endDate: end })
                setDateRange(CUSTOM_DATE_RANGE)
                setIsDateOpen(false)
              }}
              initialStartDate={dates.startDate ?? dateYearAgo()}
              initialEndDate={dates.endDate ?? yesterday()}
              dateRange={dateRange}
            />
          )}
        </View>
      </View>
      {error ? (
        <ErrorWithRetry onRetry={fetchData} />
      ) : loading ? (
        <LoadingIndicator type="inline" style={{ marginTop: Spacings.S40 }} />
      ) : (
        <>
          <SumBoxes
            items={[
              {
                value: formatCurrency(feeReport?.managementFee ?? 0, true),
                title: `${t('reportFee.management-fees')}`,
                color: Colors.text,
                subtitle: getSumBoxSubtitle(
                  formatPercentageTwoDecimal(
                    totalFeesPercentage - averageTerPercentage
                  ),
                  t
                ),
              },
              {
                value: formatCurrency(feeReport?.productTerValue ?? 0, true),
                title: `${t('reportBox.product-costs')}`,
                color: Colors.text,
                subtitle: getSumBoxSubtitle(
                  formatPercentageTwoDecimal(averageTerPercentage),
                  t
                ),
              },
              {
                value: formatCurrency(feeReport?.totalFees ?? 0, true),
                title: `${t('portfolio.total')}`,
                color: Colors.text,
                subtitle: getSumBoxSubtitle(
                  formatPercentageTwoDecimal(totalFeesPercentage),
                  t
                ),
              },
            ]}
          />
          <ReportFeeList list={feeReport?.events ?? []} />
        </>
      )}
    </>
  )
}

export default observer(ReportFeeContent)
