export const ROUTE_IMPACT = 'Impact'
export const ROUTE_IMPACT_INTRO = 'ImpactIntro'
export const ROUTE_IMPACT_DISABLE = 'ImpactDisable'
export const ROUTE_IMPACT_COMPARISON = 'ImpactComparison'
export const ROUTE_IMPACT_PRODUCT_DETAIL = 'ImpactProductDetail'
export const ROUTE_IMPACT_FUND_DETAIL = 'ImpactFundDetail'
export const ROUTE_IMPACT_TOGGLE = 'ImpactToggle'
export const ROUTE_IMPACT_TOGGLE_VERIFICATION = 'ImpactToggleVerification'
export const ROUTE_IMPACT_TOGGLE_DONE = 'ImpactToggleDone'
export const ROUTE_IMPACT_TOGGLE_CONTRACT = 'ImpactToggleContract'
export const ROUTE_IMPACT_TOGGLE_ON = 'ImpactToggleOn'
export const ROUTE_IMPACT_TOGGLE_OFF = 'ImpactToggleOff'
