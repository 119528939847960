import React from 'react'

// @ts-ignore
import {
  VictoryContainer,
  VictoryPie,
} from '@taaleri/components/src/ui/Victory'
import { PercentagePieProps } from './PercentagePieAsset'

export default function PercentagePie({
  pieRadius,
  size,
  borderSize,
  colorScale,
  data,
}: PercentagePieProps) {
  return (
    <VictoryPie
      radius={pieRadius}
      width={size}
      height={size}
      origin={{ x: pieRadius, y: pieRadius }}
      innerRadius={pieRadius - borderSize}
      data={data}
      labels={[]}
      colorScale={colorScale}
      containerComponent={<VictoryContainer />}
      padAngle={3}
      standalone={false}
    />
  )
}
