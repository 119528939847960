import React, { useState } from 'react'
import { View, ViewStyle, StyleProp } from 'react-native'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IdValuePair } from '@taaleri/core/src/models/IdValuePair'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronDown, IconChevronUp } from '../../ui/icons/ChevronIcons'
import { HistoricalChangeSection } from './HistoricalChangeSection'
import GlobalStyles from '../../constants/GlobalStyles'
import { useTranslation } from 'react-i18next'

interface Props {
  historicalChanges: IdValuePair[]
  style?: StyleProp<ViewStyle>
}

export function HistoricalChangeMobile({ historicalChanges, style }: Props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  if (!historicalChanges.some((e) => e.value)) {
    return null
  }
  return (
    <View {...{ style }}>
      <UiTouchable
        style={{
          flexDirection: 'row',
          borderBottomWidth: open ? 1 : 0,
          borderBottomColor: Colors.border,
          paddingHorizontal: Spacings.S20,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onPress={() => setOpen(!open)}
      >
        <TextDefault type="t2" style={{ marginTop: 12, marginBottom: 12 }}>
          {t('portfolio.historic-profit')}
        </TextDefault>
        {open ? <IconChevronUp /> : <IconChevronDown color={Colors.text} />}
      </UiTouchable>
      {open && (
        <View
          style={{
            ...GlobalStyles.bottomBorder,
            paddingHorizontal: Spacings.S20,
            paddingVertical: Spacings.S8,
          }}
        >
          {historicalChanges.map(({ value, id }) => {
            return value ? (
              <HistoricalChangeSection
                {...{ value, id }}
                key={id}
                style={{
                  alignItems: 'center',
                }}
              />
            ) : null
          })}
        </View>
      )}
    </View>
  )
}
