import React from 'react'
import { UiModalProps } from './UiModalProps'
// @ts-ignore
import Modal from 'modal-enhanced-react-native-web'
import Colors from '@taaleri/core/src/constants/Colors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { View, StyleSheet, ViewStyle } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import UiScrollView from '../UiScrollView'

function UiModal(props: UiModalProps) {
  const { children, style, ...rest } = props
  return (
    <View>
      <Modal
        animationInTiming={0.001}
        animationIn={'slideInDown'}
        animationOut={'slideOutUp'}
        backdropColor={Colors.backdrop}
        backdropOpacity={0.4}
        {...rest}
        onModalHide={() => {
          AppStore.checkMultipleModals()
        }}
      >
        <View style={[styles.modal, style]}>
          <UiScrollView
            style={{
              paddingVertical: Spacings.S24,
              paddingHorizontal: Spacings.S24,
              width: '100%',
            }}
          >
            {children}
          </UiScrollView>
        </View>
      </Modal>
    </View>
  )
}

export default UiModal

const styles = StyleSheet.create({
  modal: {
    height: 'auto',
    maxHeight: '80vh',
    backgroundColor: 'white',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: BorderRadius.default,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 3,
    shadowOpacity: 0.06,
    maxWidth: 400,
    width: '85vw',
    marginHorizontal: Spacings.S24,
    justifyContent: 'flex-start',
    marginBottom: Spacings.S32,
  },
  modalLarger: {
    marginHorizontal: 'auto',
  },
})

export const modalContentStyles: ViewStyle = styles.modalLarger
