import React from 'react'

import { SwitchProps } from 'react-native'
import Switch from 'react-switch'
export function UiSwitch(props: SwitchProps) {
  return (
    <Switch
      offColor="#E5E5E5"
      onColor="#2BC9AF"
      width={52}
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={(value: boolean) => {
        if (props.onValueChange) {
          props.onValueChange(value)
        }
      }}
      checked={props.value === true}
      style={{ display: 'none' }}
    />
  )
}
