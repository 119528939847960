import React, { useState } from 'react'
import { View } from 'react-native'
import { Formik, FormikHelpers, Form } from 'formik'
import Appear from '@taaleri/components/src/ui/animation/Appear'
import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import BackgroundContainer from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Title from '@taaleri/components/src/ui/text/Title'
import { Registration } from '@taaleri/core/src/models/Identity'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import UiButton from '@taaleri/components/src/ui/UiButton'
import { authentication } from '@taaleri/core/src/services/authentication'
// @ts-ignore
import { HiddenGtSmall } from '@taaleri/components/src/ui/Responsive'
import Spacings from '@taaleri/core/src/constants/Spacings'
import SignInForgot from './SignInForgot'
import styled from 'styled-components'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import signinValidationSchema from '@taaleri/components/src/screens/signin/signinValidationSchema'
import ErrorMessage from '@taaleri/components/src/ui/form/ErrorMessage'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { routePathAfterSignIn } from '@taaleri/components/src/navigation/routeHelper'
import useLayout from '@taaleri/components/src/ui/useLayout'
import useQueryParams from 'navigation/useQueryParams'
import siginInRoute from '@taaleri/components/src/screens/signin/signInRoute'
import { useHistory, useLocation } from 'react-router-dom'
import { SmallVersionNumber } from '@taaleri/components/src/components/VersionNumber'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import { loginToDataLayer } from 'utils/DataLayer'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import {
  getEnvironment,
  isDev,
  isLocal,
  isProd,
  isStg,
} from '@taaleri/core/src/environment'
import { useTranslation } from 'react-i18next'
import { changeLanguage, getChangeText } from '@taaleri/core/src/i18n'
import { FontSize } from '@taaleri/core/src/constants/Fonts'

export default function SignInScreen() {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { error } = useQueryParams()
  const { isNonSmall } = useLayout()
  const history = useHistory()
  const { pathname } = useLocation()

  const initialValues: Registration = {
    email: '',
    password: '',
    customerid: '',
  }

  if (error && !errorMessage) {
    if (error === 'notfound') {
      setErrorMessage(`${t(ErrorMessages.noCredentials)}`)
    }
    if (error === 'error') {
      setErrorMessage(`${t(ErrorMessages.authenticationCancelledByUser)}`)
    }
  }

  async function signIn(registration: Registration) {
    AppStore.isTwoFactor = false
    AppStore.twoFactorEnabled = false
    const response = await authentication.signIn(
      registration.email,
      registration.password
    )
    if (response.success) {
      loginToDataLayer(AppStore.baseCustomerId, AppStore.customerId)
      if (pathname.indexOf(ROUTE_APP) === -1) {
        const route = await siginInRoute()
        history.push(routePathAfterSignIn(route))
      }

      return true
    } else {
      const errorMessageMaybe = response.error
      const errorMessageString = (() => {
        if (errorMessageMaybe) {
          if (errorMessageMaybe === 'CONFLICT') {
            return ErrorMessages.incorrectCredentials
          }
          if (errorMessageMaybe === 'EXPIRED') {
            return ErrorMessages.expiredCredentials
          }
        }

        return ErrorMessages.unexpectedError
      })()
      setErrorMessage(`${t(errorMessageString)}`)
    }
    return false
  }

  React.useEffect(() => {
    QuestionStore.reset()
  }, [])

  return (
    <BackgroundContainer contentType="centered">
      <TopBottomContainer>
        <View>
          <HiddenGtSmall>
            <Appear>
              <AktiaLogo
                style={{
                  alignSelf: 'center',
                  marginBottom: 64,
                  marginTop: 78,
                }}
              />
            </Appear>
          </HiddenGtSmall>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title
              style={{
                justifyContent: 'center',
                marginBottom: Spacings.S40,
                fontSize: FontSize.S24,
                paddingTop: Spacings.S16,
              }}
            >
              {t('signin.title')}
            </Title>
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  borderColor: 'black',
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              >
                <UiButton
                  title={t(getChangeText())}
                  onPress={() => {
                    changeLanguage()
                  }}
                  type="secondaryplain"
                  containerStyle={{
                    padding: 0,
                  }}
                />
              </View>
            </View>
          </View>
          <Formik
            initialValues={initialValues}
            validationSchema={signinValidationSchema(t)}
            onSubmit={async (
              values: Registration,
              actions: FormikHelpers<Registration>
            ) => {
              actions.setSubmitting(true)
              const response = await signIn(values)
              Analytics.event('Login', 'Click')
              if (!response) {
                actions.setStatus('invalid')
                actions.setSubmitting(false)
              }
            }}
          >
            {({ handleSubmit, isSubmitting, setStatus }) => (
              // TODO: Find out why submitting on enter doesnt work and remove onKeyDownCapture
              <Form
                noValidate={true}
                onKeyDownCapture={(event) =>
                  event.key === 'Enter' && handleSubmit()
                }
              >
                <UiTextInputForm
                  name="email"
                  label={`${t('email')}`}
                  type="email"
                  onChange={() => setStatus('valid')}
                  editable={!isSubmitting}
                  textContentType="username"
                  grayBackground={true}
                />
                <UiTextInputForm
                  name="password"
                  type="password"
                  label={`${t('password')}`}
                  autoCapitalize="none"
                  editable={!isSubmitting}
                  textContentType="password"
                  useEyeOnOff={!isSubmitting}
                  grayBackground={true}
                />
                {errorMessage && <ErrorMessage message={errorMessage} />}
                <ButtonContainer>
                  <UiButton
                    title={`${t('signin.login')}`}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    containerStyle={[
                      isNonSmall && { maxWidth: 160 },
                      !isNonSmall && { width: '100%' },
                    ]}
                  />
                </ButtonContainer>
              </Form>
            )}
          </Formik>
          {/* <SelectEnvButtons /> */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {getEnvironment() !== 'production' && !isProd && (
              <>
                {(isDev || isLocal) && (
                  <div>
                    <p
                      style={{
                        fontSize: FontSize.S24,
                        fontWeight: 'bold',
                      }}
                    >
                      Tämä on DEV (devtest) ympäristö!
                    </p>
                    <UiButton
                      title="luo asiakas"
                      onPress={() => {
                        history.push('questions')
                      }}
                    />
                  </div>
                )}
                {isStg && (
                  <p
                    style={{
                      fontSize: FontSize.S24,
                      fontWeight: 'bold',
                    }}
                  >
                    Tämä on STAGING (inttest) ympäristö!
                  </p>
                )}
              </>
            )}
          </View>
        </View>

        <SignInForgot />
      </TopBottomContainer>
      <View style={{ alignSelf: 'flex-end' }}>
        <SmallVersionNumber />
      </View>
    </BackgroundContainer>
  )
}

const ButtonContainer = styled.div`
  margin-bottom: ${Spacings.S24}px;
  @media (min-width: ${BreakPoint.S}px) {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
`
