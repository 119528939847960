class AbortService {
  _controllers: Map<string, AbortController>
  constructor() {
    this._controllers = new Map()
  }
  private getController(controllerId: string): AbortController | undefined {
    const controller = this._controllers.get(controllerId)
    // Create new controller if necessary
    if (controller) {
      return controller
    }
    return this.setController(controllerId)
  }

  private abort(controllerId: string): void {
    const controller = this.getController(controllerId)
    if (controller) {
      controller.abort()
    }
  }

  private getSignal(controllerId: string): AbortSignal | undefined {
    const controller = this.getController(controllerId)
    if (controller) {
      return controller.signal
    }
  }

  private setController(controllerId: string): AbortController | undefined {
    try {
      const controller = new AbortController()
      this._controllers.set(controllerId, controller)
      return controller
    } catch (e) {
      return
    }
  }

  abortAndGetNewSignal(controllerId: string): AbortSignal | undefined {
    this.abort(controllerId)
    this.setController(controllerId)
    return this.getSignal(controllerId)
  }
}

const abortService = new AbortService()
export default abortService
