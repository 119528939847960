import React from 'react'
import BankSelection from '@taaleri/components/src/screens/portfolio/BankSelection'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import useQueryParams from 'navigation/useQueryParams'
import { useHistory } from 'react-router-dom'
import {
  PaymentSteps,
  PaymentStepsFund,
} from '@taaleri/components/src/components/Steps'
import AppStore from '@taaleri/core/src/stores/AppStore'

export default function PaymentBankScreen() {
  const params = useQueryParams()
  const history = useHistory()
  const paymentResult = params.paymentResult
  const isFundPayment = AppStore.investmentMode === 'fund'

  return (
    <>
      {isFundPayment ? (
        <PaymentStepsFund currentStep={2} />
      ) : (
        <PaymentSteps currentStep={2} />
      )}
      <ContentContainerWide>
        <BankSelection
          onSuccess={() => {
            history.push(routePath(ROUTE_APP))
          }}
          error={paymentResult ? true : false}
        />
      </ContentContainerWide>
    </>
  )
}
