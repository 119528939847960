import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { OptionType } from '@taaleri/core/src/models/OptionType'
import { useField } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ScrollView } from 'react-native'

import { CheckListError } from './CheckListError'
import FlexRow from '../FlexRow'
import ListItem from '../ListItem'
import UiTouchable from '../UiTouchable'
import { IconChevronDown } from '../icons/ChevronIcons'
import { TextMedium } from '../text/UiText'

interface Props {
  lastBottomDivider?: boolean
  name: string
  options: OptionType[]
  padding?: boolean
  onChange?: (value: OptionType) => void
}

export const Dropdown: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const { lastBottomDivider, options, name, padding } = props
  const [field, meta, helpers] = useField(name)
  const value = field.value

  const items = options.map((item, index) => (
    <ListItem
      key={item.value}
      title={item.label}
      bottomDivider={index === options.length - 1 ? lastBottomDivider : false}
      topDivider={true}
      checkmark={value !== undefined && value === item.value}
      reserveSpaceForRightCheckmark={true}
      onPress={() => {
        helpers.setValue(item.value)
        setOpen(false)
        if (props.onChange) {
          props.onChange(item)
        }
      }}
    />
  ))

  const selected = options.find((o) => o.value === value)
  return (
    <View>
      <View style={padding && { paddingHorizontal: Spacings.S24 }}>
        <UiTouchable
          onPress={() => setOpen(!open)}
          style={{ marginBottom: Spacings.S24 }}
        >
          <FlexRow style={{ alignItems: 'center' }}>
            <TextMedium
              style={{ color: Colors.primary, marginRight: Spacings.S8 }}
            >
              {selected ? selected.label : t('dropdown.choose')}
            </TextMedium>
            <IconChevronDown color={Colors.primary} />
          </FlexRow>
        </UiTouchable>
      </View>

      {open && (
        <ScrollView
          style={{
            height: 300,
            backgroundColor: Colors.white,
          }}
          nestedScrollEnabled={true}
        >
          {items}
        </ScrollView>
      )}
      <CheckListError error={meta.error} touched={meta.touched} />
    </View>
  )
}
