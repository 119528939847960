import React from 'react'
import { View, StyleSheet } from 'react-native'
import UiButton from '@taaleri/components/src/ui/UiButton'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { useHistory } from 'react-router-dom'
import Spacings from '@taaleri/core/src/constants/Spacings'
import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import { ButtonType } from '@taaleri/components/src/ui/UiButtonProps'

interface Props {
  title: string
  route?: string
  onPress?: (ev: any) => void
  titleSecondary?: string
  onPressSecondary?: (ev: any) => void
  loading?: boolean
  secondaryGray?: boolean
  primaryButtonType?: ButtonType
}

function Buttons(props: Props) {
  const {
    title,
    route,
    onPress,
    titleSecondary,
    onPressSecondary,
    loading,
    secondaryGray,
    primaryButtonType,
  } = props
  const { isNonSmall } = useLayout()
  const history = useHistory()

  return (
    <ButtonsContainer>
      <View>
        {titleSecondary && onPressSecondary && (
          <UiButton
            containerStyle={[{ marginTop: isNonSmall ? 0 : Spacings.S16 }]}
            onPress={(evn: any) => onPressSecondary(evn)}
            title={titleSecondary}
            type="secondaryplain"
            textStyle={
              secondaryGray && {
                color: Colors.gray50,
                fontFamily: Fonts.avenirNextMedium,
              }
            }
          />
        )}
      </View>
      <UiButton
        type={primaryButtonType}
        title={title}
        loading={loading}
        onPress={(event: any) => {
          if (route) {
            history.push(route)
          } else if (onPress) {
            onPress(event)
          }
        }}
      />
    </ButtonsContainer>
  )
}

export function ButtonsContainer(props: PropsChildren) {
  const { isNonSmall } = useLayout()
  return (
    <View style={[styles.container, isNonSmall && styles.buttonContainerWide]}>
      <View />
      {props.children}
    </View>
  )
}

export default Buttons

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    marginBottom: Spacings.S24,
    flexDirection: 'column-reverse',
  },
  buttonContainerWide: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
