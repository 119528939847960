import { Portfolio } from '@taaleri/core/src/models/Portfolio'
import {
  roundToNearestThousandValue,
  roundToNearestHundredValue,
} from '@taaleri/core/src/utils/format'
export function portfolioToRoundedMarketValue(
  portfolio?: Portfolio,
  multiplier: number = 1,
  roundLimit: number = 5000
) {
  const marketValue =
    portfolio && portfolio.marketValue
      ? Math.max(1000, portfolio.marketValue)
      : 10000
  return marketValue > roundLimit
    ? roundToNearestThousandValue(marketValue * multiplier)
    : roundToNearestHundredValue(marketValue * multiplier)
}

export function portfolioToRoundedMarketValueDrop(portfolio?: Portfolio) {
  return portfolioToRoundedMarketValue(portfolio, 0.8)
}
