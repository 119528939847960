import FlexRow from '@taaleri/components/src/ui/FlexRow'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import UiButton from '@taaleri/components/src/ui/UiButton'
import { UiSwitch } from '@taaleri/components/src/ui/form/UiSwitch'
import UiTextInput from '@taaleri/components/src/ui/form/UiTextInput'
import Title from '@taaleri/components/src/ui/text/Title'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isLocal } from '@taaleri/core/src/environment'
import AdminCustomer from '@taaleri/core/src/models/AdminCustomer'
import { searchCustomers } from '@taaleri/core/src/services/admin'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import * as Yup from 'yup'

import CustomerList from './CustomerList'

interface Search {
  query: string
  token: string
  surename: string
}

const validationSchema = Yup.object().shape({
  query: Yup.string()
    .min(3, ErrorMessages.queryAtLeastNChars)
    .required(ErrorMessages.emptyField),
})

export default function SearchCustomerForm() {
  const [customers, setCustomers] = useState<AdminCustomer[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string | undefined>()
  const [includeLostCustomers, setIncludeLostCustomers] =
    useState<boolean>(false)

  const { isNonSmall } = useLayout()

  async function search(
    query: string,
    userCustomersOnly: boolean,
    includeLost: boolean,
    token?: string,
    adminSurename?: string
  ) {
    AdminStore.customerId = undefined
    if (isLocal) {
      AdminStore.token = token
      AdminStore.adminSurname = adminSurename
    }

    setMessage(undefined)
    setCustomers([])
    setLoading(true)
    const { success, response } = await searchCustomers(
      token ?? AdminStore.token ?? '',
      userCustomersOnly,
      includeLost,
      query
    )
    if (success) {
      if (!response || response.length === 0) {
        setMessage(`Ei tuloksia hakusanalla ${query}`)
      } else {
        setCustomers(response)
      }
    } else {
      setMessage(`Istuntosi on vanhentunut. Päivitä sivu.`)
      AdminStore.logoutAdmin()
    }
    setLoading(false)
  }

  if (AdminStore.customerId && !isLocal) {
    setLoading(true)
    search(AdminStore.customerId ?? '', false, false)
  }

  return (
    <View>
      <Title style={{ marginTop: 0 }}>Hae asiakasta</Title>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{
          query: AdminStore.customerId ?? '',
          token: AdminStore.token ?? '',
          surename: AdminStore.adminSurname ?? '',
        }}
        onSubmit={async (
          { query, token, surename }: Search,
          actions: FormikHelpers<Search>
        ) => {
          await search(query, false, includeLostCustomers, token, surename)
          actions.setSubmitting(false)
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form
              noValidate={true}
              onKeyDownCapture={(event) =>
                event.key === 'Enter' && handleSubmit()
              }
              style={{ flex: 1 }}
            >
              {isLocal && (
                <>
                  <FlexRow
                    style={{
                      maxWidth: 800,
                      flexDirection: isNonSmall ? 'row' : 'column',
                    }}
                  >
                    <UiTextInput
                      name="token"
                      placeholder="Mock token"
                      containerStyle={{
                        flex: 1,
                        marginBottom: isNonSmall ? Spacings.S8 : Spacings.S24,
                      }}
                      hideCheckCircle={true}
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  </FlexRow>
                  <FlexRow
                    style={{
                      maxWidth: 800,
                      flexDirection: isNonSmall ? 'row' : 'column',
                    }}
                  >
                    <UiTextInput
                      name="surename"
                      placeholder="Mock surename"
                      containerStyle={{
                        flex: 1,
                        marginBottom: isNonSmall ? Spacings.S8 : Spacings.S24,
                      }}
                      hideCheckCircle={true}
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  </FlexRow>
                </>
              )}

              <FlexRow
                style={{
                  maxWidth: 800,
                  flexDirection: isNonSmall ? 'row' : 'column',
                  justifyContent: 'space-between',
                }}
              >
                <FlexRow
                  style={{
                    maxWidth: 800,
                    flexDirection: 'column',
                    flex: 2,
                  }}
                >
                  <View
                    style={{
                      flexDirection: isNonSmall ? 'row' : 'column',
                    }}
                  >
                    <UiTextInput
                      name="query"
                      placeholder="Hae nimellä tai asiakastunnuksella"
                      containerStyle={{
                        flex: 1,
                        marginBottom: isNonSmall ? Spacings.S8 : Spacings.S24,
                      }}
                      hideCheckCircle={true}
                      focus={!isSubmitting}
                      style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                    <UiButton
                      containerStyle={{
                        borderTopLeftRadius: isNonSmall ? 0 : 6,
                        borderBottomLeftRadius: isNonSmall ? 0 : 6,
                      }}
                      title="Hae"
                    />
                  </View>
                  <Switch
                    style={{
                      height: 56,
                      alignItems: 'center',
                    }}
                    label="Näytä menetetyt asiakkuudet"
                    value={includeLostCustomers}
                    onValueChange={async (value: boolean) =>
                      setIncludeLostCustomers(value)
                    }
                    hidden={!isNonSmall}
                  />
                </FlexRow>
                <UiButton
                  containerStyle={{
                    marginLeft: isNonSmall ? Spacings.S24 : 0,
                    marginTop: isNonSmall ? 0 : Spacings.S24,
                  }}
                  title="Hae omat asiakkaat"
                  onPress={async () => {
                    await search('', true, includeLostCustomers)
                  }}
                />
              </FlexRow>
            </Form>
          )
        }}
      </Formik>

      {loading && (
        <LoadingIndicator type="inline" style={{ minHeight: '20vh' }} />
      )}
      {message && (
        <TextDefault style={{ marginTop: Spacings.S16 }}>{message}</TextDefault>
      )}
      {customers && !loading && (
        <CustomerList
          customers={customers}
          adminToken={AdminStore.token ?? ''}
          adminSurname={AdminStore.adminSurname ?? ''}
        />
      )}
    </View>
  )
}

interface SwitchProps {
  label: string
  value: boolean
  onValueChange: (value: boolean) => void
  hidden: boolean
  style?: StyleProp<ViewStyle>
}

function Switch({ style, label, value, onValueChange, hidden }: SwitchProps) {
  if (hidden) {
    return null
  }
  return (
    <FlexRow {...{ style }}>
      <TextDefault style={{ marginRight: Spacings.S8 }}>{label}</TextDefault>
      <UiSwitch value={value} onValueChange={onValueChange} />
    </FlexRow>
  )
}
