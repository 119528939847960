import CustomerForm from '@taaleri/components/src/screens/onboarding/CustomerForm'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import QuestionContainer from 'screens/questions/QuestionContainer'
import ContentContainer from 'ui/container/ContentContainer'

interface Props {
  isOnboarding: boolean
}

function CustomerFormScreen({ isOnboarding }: Props) {
  const history = useHistory()
  return (
    <QuestionContainer noPadding={true}>
      <ContentContainer noPadding={true}>
        <CustomerForm navigate={history.push} isOnboarding={isOnboarding} />
      </ContentContainer>
    </QuestionContainer>
  )
}

export default observer(CustomerFormScreen)
