import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiTouchable from '../../../ui/UiTouchable'

interface Props {
  children: Element[]
  hideBorder?: boolean
  onPress?: () => void
}

function TransactionRowContainerMobile({
  children,
  hideBorder,
  onPress,
}: Props) {
  return (
    <UiTouchable
      style={{
        flex: 1,
        borderBottomWidth: hideBorder ? 0 : 1,
        borderBottomColor: Colors.border,
        padding: Spacings.S16,
      }}
      onPress={onPress}
    >
      {children}
    </UiTouchable>
  )
}

export default TransactionRowContainerMobile
