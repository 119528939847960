import PercentagePieAsset from '@taaleri/components/src/components/PercentagePieAsset'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import { TextDefault, TextMedium } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import { Fund, PositionsData } from '@taaleri/core/src/models/Fund'
import { FundPosition } from '@taaleri/core/src/models/FundPosition'
import {
  assetClassColors,
  assetClassColorsRgb,
} from '@taaleri/core/src/models/assetClassColors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import {
  formatAssetCategoryName,
  formatDateWithoutLeadingZeros,
  formatPercentageTwoDecimal,
  sum,
  toFullPercentageText,
} from '@taaleri/core/src/utils/format'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleProp, ViewStyle } from 'react-native'

import { IconProps } from '../../ui/IconProps'
import UiScrollView from '../../ui/UiScrollView'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronRight, IconChevronDown } from '../../ui/icons/ChevronIcons'
import { IconCircleClose } from '../../ui/icons/CircleIcons'

type FilterLevel = 'assetClass' | 'category' | 'instrument'

interface InstrumentRowProps {
  id: string
  share: number
  name: string
}

function InstrumentRow({ id, share, name }: InstrumentRowProps) {
  return (
    <View
      key={id}
      style={{
        flexDirection: 'row',
        paddingTop: Spacings.S10,
        alignItems: 'center',
        marginBottom: 0,
      }}
    >
      <TextDefault
        type="t2"
        style={{
          flex: 1,
        }}
        numberOfLines={1}
        color={Colors.textSecondary}
      >
        {name}
      </TextDefault>
      {share < 100 && (
        <TextDefault
          type="t2"
          style={{
            marginLeft: Spacings.S16,
            textAlign: 'right',
            paddingLeft: Spacings.S8,
            marginRight: Spacings.S8,
          }}
        >{`${formatPercentageTwoDecimal(share)} %`}</TextDefault>
      )}
    </View>
  )
}

interface RowProps {
  id: string
  share: number
  name: string
  onPress: (value: string) => void
  Icon?: React.FC<IconProps>
  color?: string
  style?: StyleProp<ViewStyle>
  hideBorder?: boolean
}

function Row({ id, share, name, onPress, Icon, color, style }: RowProps) {
  const lineHeight = FontSize.S14

  return (
    <UiTouchable
      key={id}
      style={[
        {
          flexDirection: 'row',
          marginTop: Spacings.S10,
          alignItems: 'center',
          marginBottom: 0,
          minHeight: 24,
        },
        style,
      ]}
      onPress={() => onPress(id)}
    >
      <View style={{ width: 22 }}>
        <View
          style={{
            width: 10,
            height: 10,
            marginTop: isWeb ? 0 : -1,
            marginRight: 12,
            backgroundColor: color,
            borderRadius: 5,
          }}
        />
      </View>
      <TextMedium
        style={{
          flex: 1,
          fontSize: FontSize.S14,
          lineHeight,
        }}
        numberOfLines={1}
      >
        {name}
      </TextMedium>
      {share < 100 && (
        <TextMedium
          style={{
            marginLeft: Spacings.S16,
            width: 60,
            textAlign: 'right',
            paddingLeft: Spacings.S8,
            borderLeftColor: Colors.border,
            borderLeftWidth: 1,
            fontSize: FontSize.S14,
            lineHeight,
            marginRight: Spacings.S8,
          }}
        >{`${toFullPercentageText(share)} %`}</TextMedium>
      )}
      {Icon ? <Icon color={Colors.text} /> : null}
    </UiTouchable>
  )
}

interface InstrumentDistributionProps {
  category: FundPosition
  onPress: () => void
  positionsData: PositionsData
  color?: string
}

function InstrumentDistribution({
  category,
  onPress,
  positionsData,
  color,
}: InstrumentDistributionProps) {
  const { positions, marketValue } = positionsData

  const filteredPositions = sortBy(
    positions
      .filter((p) => p.financialInstrument.assetCategoryId === category.id)
      .map((position) => {
        return {
          name:
            AppStore.currentLanguage === fiKey
              ? position.financialInstrument.securityNameFinnish
              : position.financialInstrument.securityNameSwedish,
          id: position.financialInstrument.ticker,
          share:
            (100 * position.marketValue.baseCurrency.value) / marketValue.value,
        }
      }),
    'share'
  ).reverse()

  return (
    <View
      style={{
        flex: 1,
        flexBasis: 0,
        justifyContent: 'space-between',
        minHeight: 200,
      }}
    >
      <Row
        id={category.id}
        share={category.share}
        name={category.name}
        onPress={onPress}
        Icon={IconCircleClose}
        color={color}
        style={{
          paddingBottom: 12,
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        }}
      />
      <UiScrollView showsVerticalScrollIndicator={true}>
        <View style={{ justifyContent: 'flex-end' }}>
          {filteredPositions.map((position) => {
            const { id, name, share } = position
            return <InstrumentRow key={id} id={id} share={share} name={name} />
          })}
        </View>
      </UiScrollView>
    </View>
  )
}

interface FundDistibutionProps {
  filterLevel: FilterLevel
  positionsData: PositionsData
  onPress: (id: FundPosition, color: string) => void
  Icon: React.FC<IconProps>
  assetClassId?: string
}

function FundDistibution({
  filterLevel,
  positionsData,
  onPress,
  Icon,
  assetClassId,
}: FundDistibutionProps) {
  const { positions, marketValue } = positionsData

  let filteredPositions: FundPosition[] = []
  let colorScale: string[] = []
  if (filterLevel === 'assetClass') {
    const groupedPositions = groupBy(
      positions,
      'financialInstrument.assetClassId'
    )
    const assetClassIds = Object.keys(groupedPositions)
    filteredPositions = sortBy(
      assetClassIds.map((id) => {
        const selectedPositions = groupedPositions[id]
        const { assetClassName } = selectedPositions[0].financialInstrument
        return {
          name: assetClassName,
          id,
          share:
            (100 *
              sum(
                selectedPositions.map((e) => e.marketValue.baseCurrency.value)
              )) /
            marketValue.value,
        }
      }),
      'share'
    ).reverse()
    colorScale = filteredPositions.map((p) => assetClassColors[p.id])
  } else if (filterLevel === 'category') {
    const groupedPositions = groupBy(
      positions.filter(
        (p) => p.financialInstrument.assetClassId === assetClassId
      ),
      'financialInstrument.assetCategoryId'
    )
    const categoryIds = Object.keys(groupedPositions)
    filteredPositions = sortBy(
      categoryIds.map((categoryId) => {
        const selectedPositions = groupedPositions[categoryId]
        const { assetCategoryName } = selectedPositions[0].financialInstrument
        return {
          name: formatAssetCategoryName(assetCategoryName),
          id: categoryId,
          share:
            (100 *
              sum(
                selectedPositions.map((e) => e.marketValue.baseCurrency.value)
              )) /
            marketValue.value,
        }
      }),
      'share'
    ).reverse()
    colorScale = filteredPositions.map((_, index) =>
      assetClassColorsRgb[assetClassId || Colors.text].replace(
        ', 1)',
        `, ${1 - index / categoryIds.length})`
      )
    )
  } else {
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          marginVertical: 'auto',
          justifyContent: 'center',
        }}
      >
        <PercentagePieAsset
          style={{
            flex: 1,
            alignItems: 'center',
            marginVertical: Spacings.S16,
          }}
          assetClasses={filteredPositions.map(({ id, name, share }) => {
            return {
              id,
              name,
              percentage: share,
            }
          })}
          percentage={0}
          size={192}
          colorScale={colorScale}
        />
      </View>
      <UiScrollView style={{ flexGrow: 0 }}>
        <View style={{ justifyContent: 'flex-end' }}>
          {filteredPositions.map((position, index) => {
            const { id, name, share } = position
            const color = colorScale[index]
            return (
              <Row
                key={id}
                id={id}
                share={share}
                name={name}
                onPress={() => onPress(position, color)}
                Icon={Icon}
                color={color}
              />
            )
          })}
        </View>
      </UiScrollView>
    </View>
  )
}

interface Props {
  fund: Fund
  containerStyle?: StyleProp<ViewStyle>
}

export function FundPie({ fund, containerStyle }: Props) {
  const { t } = useTranslation()
  const { positionsData, holdingsDate } = fund
  const [assetClass, setAssetClassId] = useState<FundPosition>()
  const [category, setCategory] = useState<FundPosition>()
  const [filterLevel, setFilterLevel] = useState<FilterLevel>('assetClass')
  const [color, setColor] = useState<string>()

  function selectAssetClass(f: FundPosition) {
    setAssetClassId(f)
    setCategory(undefined)
    setFilterLevel('category')
    setColor(undefined)
  }

  function selectCategory(f: FundPosition, categoryColor: string) {
    setAssetClassId(undefined)
    setCategory(f)
    setFilterLevel('instrument')
    setColor(categoryColor)
  }

  function unselectAll() {
    setAssetClassId(undefined)
    setCategory(undefined)
    setFilterLevel('assetClass')
    setColor(undefined)
  }

  return (
    <BoxWithPadding
      title={
        holdingsDate
          ? `${t(
              'portfolio.fund-distribution'
            )} ${formatDateWithoutLeadingZeros(holdingsDate)}`
          : `${t('portfolio.fund-distribution')}`
      }
      titleStyle={{ marginHorizontal: -20, paddingLeft: 20 }}
      style={containerStyle}
    >
      {filterLevel === 'assetClass' ? (
        <FundDistibution
          positionsData={positionsData}
          filterLevel={filterLevel}
          Icon={IconChevronRight}
          onPress={selectAssetClass}
        />
      ) : null}
      {filterLevel === 'category' && assetClass ? (
        <FundDistibution
          positionsData={positionsData}
          filterLevel={filterLevel}
          Icon={IconChevronDown}
          onPress={selectCategory}
          assetClassId={assetClass.id}
        />
      ) : null}

      {filterLevel === 'instrument' && category ? (
        <InstrumentDistribution
          positionsData={positionsData}
          category={category}
          onPress={unselectAll}
          color={color}
        />
      ) : null}
    </BoxWithPadding>
  )
}
