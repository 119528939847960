import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import PaymentWithdrawalDoneContent from '@taaleri/components/src/screens/portfolio/PaymentWithdrawalDoneContent'
import AppStore from '@taaleri/core/src/stores/AppStore'
import React from 'react'
import Buttons from 'ui/Buttons'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

export default function PaymentWithdrawalDoneScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <ContentContainerBox>
      <TopBottomContainer>
        <PaymentWithdrawalDoneContent />
        <Buttons
          title={t('button.close')}
          onPress={() => {
            navigate(routePath(ROUTE_APP))
            AppStore.fetchOrders()
          }}
        />
      </TopBottomContainer>
    </ContentContainerBox>
  )
}
