import Colors from '@taaleri/core/src/constants/Colors'
import React, { useState } from 'react'
import useLayout from '../../ui/useLayout'
import { View, StyleProp, ViewStyle } from 'react-native'
import {
  LineSegment,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryCursorContainer,
} from '@taaleri/components/src/ui/Victory'
import {
  formatDate,
  formatDateWithoutYear,
  formatNumber,
  formatDateWithoutLeadingZeros,
} from '@taaleri/core/src/utils/format'
import { Defs, Svg, G } from 'react-native-svg'
import {
  PAST_MONTH,
  YEAR_TO_DATE,
} from '@taaleri/core/src/constants/YieldHistory'
import { TimeSerieItem } from '@taaleri/core/src/models/YieldHistory'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import { filterYieldHistoryByScale } from '@taaleri/core/src/utils/yieldHistory'
import Spacings from '@taaleri/core/src/constants/Spacings'

const areaChartStyle = {
  axis: { stroke: Colors.text },
  data: {
    strokeWidth: 2,
    fill: 'transparent',
    stroke: Colors.primary,
  },
}

const getLabel = (tickValue: number) => {
  const change = tickValue - 100
  const possiblePlusSign = change > 0 ? '+' : ''
  const formattedChange = formatNumber(parseFloat(change.toFixed(2))) + ' %'

  return `${possiblePlusSign}${formattedChange}`
}

export interface PortfolioChartProps {
  domainBottom: any
  domainTop: any
  data: any
  dateFormatFunction: any
  marketValueChangePercent: number
  containerStyle?: StyleProp<ViewStyle>
  height?: number
}

const lineStyle = {
  stroke: Colors.chartLineGrey,
  strokeWidth: 1,
}

// Plot dash line when y = 0 and normal line otherwise
const CustomLineSegment = (props: any) => {
  const dashStyle = {
    stroke: Colors.gray70,
    strokeDasharray: '1,3',
    strokeWidth: 1,
    strokeOpacity: 1,
  }

  const style = props.datum === 100 ? dashStyle : lineStyle
  return <LineSegment {...props} style={style} />
}

export function toPortfolioChartProps(
  wholeHistory: TimeSerieItem[],
  scale: string
): PortfolioChartProps {
  const history = filterYieldHistoryByScale(scale, wholeHistory)

  const historyItems = history || []
  const showFullDates =
    scale === PAST_MONTH ||
    (scale === YEAR_TO_DATE && historyItems.length <= 31)
  const dateFormatFunction = showFullDates ? formatDateWithoutYear : formatDate
  const startDateValue =
    historyItems.length > 0 ? historyItems[0].value / 100 : 1
  const minValue =
    Math.min.apply(
      null,
      historyItems.map((historyItem: TimeSerieItem) => historyItem.value)
    ) / startDateValue
  const maxValue =
    Math.max.apply(
      null,
      historyItems.map((historyItem: TimeSerieItem) => historyItem.value)
    ) / startDateValue

  const toData = () => {
    return historyItems.map((historyItem: TimeSerieItem) => ({
      time: historyItem.date,
      value: historyItem.value / startDateValue,
    }))
  }

  const marketValueChangePercent =
    history.length > 0 ? history.slice(-1)[0].value / startDateValue - 100 : 0

  const data = toData()

  const domainBottom = Math.min(99, minValue - (maxValue - minValue) * 0.1)
  const domainTop = Math.max(101, maxValue + (maxValue - minValue) * 0.1)

  return {
    domainBottom,
    domainTop,
    data,
    dateFormatFunction,
    marketValueChangePercent,
  }
}

const CustomLabelComponent = (props: any) =>
  props.text.date ? (
    <Svg style={props.style}>
      <Defs>
        <filter id="dropShadow">
          <feDropShadow
            dx={0}
            dy={2}
            stdDeviation={4}
            floodColor="rgba(19, 26, 45)"
            floodOpacity={0.08}
          />
        </filter>
      </Defs>
      <G>
        <rect
          style={{ filter: 'url(#dropShadow)' }}
          x={props.x - 50}
          y={-10}
          rx={2}
          ry={2}
          width={100}
          height={50}
          fill={Colors.white}
        />
        <VictoryLabel
          {...props}
          text={props.text.value}
          style={{ fontFamily: 'calibre-semibold', color: Colors.text }}
          y={10}
          textAnchor={'middle'}
        />
        <VictoryLabel
          {...props}
          text={props.text.date}
          style={{ fontFamily: 'calibre-regular', color: Colors.text }}
          y={25}
          textAnchor={'middle'}
        />
      </G>
    </Svg>
  ) : null

const CustomCursorComponent = (props: any) => (
  <LineSegment {...props} style={props.style} y1={40} />
)

export default function PortfolioChart({
  domainBottom,
  domainTop,
  data,
  dateFormatFunction,
  containerStyle,
}: PortfolioChartProps) {
  const [width, setWidth] = useState(0)
  const { isSmall, isWide } = useLayout()
  const tickCount = isSmall ? 3 : 5
  const fontSize = isSmall ? FontSize.S10 : FontSize.S12
  const margin = isSmall
    ? { marginLeft: -Spacings.S32, marginRight: -Spacings.S8 }
    : { marginLeft: -Spacings.S24 }

  const padding = {
    top: Spacings.S24,
    bottom: Spacings.S32,
    left: 50,
    right: 50,
  }

  const getCursorLabel = (props: any) => {
    const cursorValue = data[Math.round(props.datum.x)]
    return cursorValue
      ? {
          value: `${getLabel(cursorValue.value)}`,
          date: `${formatDateWithoutLeadingZeros(cursorValue.time)}`,
        }
      : { value: '', date: '' }
  }

  const yAxisStyle = {
    axis: { stroke: 'transparent' },
    tickLabels: {
      fill: Colors.text,
      fontFamily: Fonts.avenirNextMedium,
      fontSize,
      textAnchor: 'end',
    },
  }

  const xAxisStyle = {
    axis: { stroke: 'transparent' },
    tickLabels: {
      fill: Colors.text,
      fontFamily: Fonts.avenirNextMedium,
      fontSize,
      textAnchor: 'start',
    },
  }

  const cursorStyle = { ...lineStyle }

  return (
    <View
      onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
      style={[{ ...margin, ...{ flex: 1 } }, containerStyle]}
      pointerEvents="none"
    >
      <VictoryChart
        padding={padding}
        maxDomain={{ y: domainTop }}
        minDomain={{ y: domainBottom }}
        // Use hardcoded height for medium and small screen
        height={isWide ? 334 : 224}
        width={width}
        containerComponent={
          <VictoryCursorContainer
            cursorLabelComponent={<CustomLabelComponent />}
            cursorComponent={<CustomCursorComponent style={cursorStyle} />}
            cursorDimension="x"
            cursorLabel={(coordinates: any) => getCursorLabel(coordinates)}
          />
        }
      >
        <VictoryAxis
          dependentAxis={true}
          orientation={'right'}
          style={yAxisStyle}
          tickFormat={(t: number): string => getLabel(t)}
          gridComponent={<CustomLineSegment />}
          tickLabelComponent={
            <VictoryLabel dx={isSmall ? Spacings.S16 : Spacings.S32} />
          }
        />
        <VictoryAxis
          style={xAxisStyle}
          tickFormat={(date: Date): string => dateFormatFunction(date)}
          tickCount={tickCount}
        />
        <VictoryArea
          style={areaChartStyle}
          interpolation="basis"
          data={data}
          x="time"
          y="value"
        />
      </VictoryChart>
    </View>
  )
}
