import React from 'react'
import { TextDefault, TextWithBorder } from '../../ui/text/UiText'
import FaqList from '../faq/FaqList'
import Faq from '@taaleri/core/src/models/Faq'

export default function FaqSection({
  title,
  items,
}: {
  title: string
  items: Faq[]
}) {
  return (
    <>
      <TextWithBorder>FAQ</TextWithBorder>

      <TextDefault type="h3" style={{ marginBottom: 40, display: 'flex' }}>
        {title}
      </TextDefault>
      <FaqList items={items} />
    </>
  )
}
