import React from 'react'
import SelectContractContent, {
  SelectContractContentProps,
} from '@taaleri/components/src/screens/portfolio/SelectContractContent'
import { ContentContainerBox } from 'ui/container/ContentContainer'

export default function SelectContractScreen(
  props: SelectContractContentProps
) {
  return (
    <ContentContainerBox leadingTitle={true}>
      <SelectContractContent {...props} />
    </ContentContainerBox>
  )
}
