import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { AssetClassIconEmpty } from '../../report/AssetClassIcon'
import PositionContainer from '../../report/PositionContainer'
import FilterButton from '../../report/FilterButton'

function ReasonsFilter() {
  const { allReasons, selectedReasons } = TransactionStore

  if (allReasons.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allReasons
        .filter((a) => !selectedReasons.includes(a))
        .map((t) => {
          return (
            <FilterButton
              key={t}
              onPress={() => TransactionStore.addSelectedReason(t)}
              selected={false}
            >
              <AssetClassIconEmpty />

              <TextDefault
                type="t2"
                style={{
                  color: Colors.text,
                  letterSpacing: 0.2,
                }}
              >
                {t}
              </TextDefault>
            </FilterButton>
          )
        })}
    </PositionContainer>
  )
}

export default observer(ReasonsFilter)
