import { getConfig } from '../config'
import { get } from './rest'
import ISecurityPriceApi from './ISecurityPriceApi'
import { Identity } from '../models/Identity'
import abortService from './abortService'
import { SecurityPrice } from '../models/SecurityPriceResponse'

async function getSecurityPrices(
  identity: Identity,
  ticker: string,
  startDate: string,
  endDate: string
): Promise<SecurityPrice> {
  const { accessToken } = identity
  const signal = abortService.abortAndGetNewSignal('securityPrice')
  return get(
    `${
      getConfig().apiUrl
    }/SecurityPrices/${ticker}?startDate=${startDate}&endDate=${endDate}`,
    accessToken,
    { signal }
  )
}

export const securityPricesApi: ISecurityPriceApi = {
  getSecurityPrices,
}
