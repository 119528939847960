import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import SmsVerification from '../contract/SmsVerification'
import { ROUTE_SIGN_IN, ROUTE_APP } from '../../navigation/routes'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import useNavigation from '../../navigation/useNavigation'
import { useTranslation } from 'react-i18next'

export function TwoFactorVerification() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const onSuccess = async () => {
    // TODO: Fetch login data and do the login magic
    AppStore.isTwoFactor = false
    Analytics.event('Login', '2FA')
    navigate(ROUTE_APP)
  }
  return (
    <SmsVerification
      t={t}
      title={t('profile.two-way-authentication')}
      description={t('signin.sent-verification-code')}
      onSuccess={() => onSuccess()}
      afterConflictNavigateTo={ROUTE_SIGN_IN}
      onPrepareVerification={async () =>
        AppStore.smsConfirmationId ? AppStore.smsConfirmationId : null
      }
      navigate={navigate}
      isTwoFactor={true}
    />
  )
}
