import React, { useState } from 'react'
import Box from '@taaleri/components/src/ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ImageBackground, StyleSheet, View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import useLayout from '@taaleri/components/src/ui/useLayout'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import PortfolioDistributionEdit from '@taaleri/components/src/screens/questions/investmentproposal/PortfolioDistributionEdit'
import { NewCustomerSteps } from '@taaleri/components/src/components/Steps'
import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'
import { dayTimeText } from '@taaleri/core/src/utils/dayTimeText'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import CalculatorContent from '@taaleri/components/src/screens/calculator/CalculatorContent'
import InvestmentButton from '../../ui/InvestmentButton'
import { Divider } from '../../ui/Divider'
import UiTouchable from '../../ui/UiTouchable'
import { startInvest, investStartRoute } from '../portfolio/PortfolioRoutes'
import useNavigation from '../../navigation/useNavigation'
import {
  PropsChildrenWithStyle,
  PropsOnPress,
} from '../../components/PropsChildren'
import FlexRow from '../../ui/FlexRow'
import { isWeb } from '../../constants/Platforms'
import { IconChevronRight } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'

const image = require('@taaleri/components/src/screens/home/emptyHomeBackground.jpg')
const MINIMUM_INVESTMENT = 1000

interface NotificationBoxProps {
  investment: number
  isWide: boolean
  navigate: (path: string) => void
}

function NotificationBox({
  investment,
  isWide,
  navigate,
}: NotificationBoxProps) {
  let title = ''
  let body = ''
  let warning = false
  const { t } = useTranslation()

  if (investment === 0) {
    return null
  } else if (investment < MINIMUM_INVESTMENT) {
    title = `${t('home.notification-box-title')} `
    body = `${t('home.notification-box-body', {
      value: formatCurrency(MINIMUM_INVESTMENT - investment),
    })}`
    warning = true
  } else {
    title = `${t('home.notification-box-good-news-title')} `
    body = `${t('home.notification-box-good-news-body')}`
  }

  const Container = ({
    children,
    style,
    onPress,
  }: PropsChildrenWithStyle & PropsOnPress) =>
    warning ? (
      <UiTouchable {...{ style, onPress }}>{children}</UiTouchable>
    ) : (
      <View {...{ style }}>{children}</View>
    )

  return (
    <Box
      style={[
        styles.firstTransaction,
        warning ? styles.warningBorder : styles.successBorder,
      ]}
    >
      <Container
        onPress={() => {
          startInvest()
          navigate(investStartRoute())
        }}
      >
        <View
          style={{
            margin: isWide ? Spacings.S32 : Spacings.S20,
            flexDirection: isWide ? 'row' : 'column',
          }}
        >
          <View
            style={{ flex: 1, alignItems: isWide ? 'flex-start' : 'center' }}
          >
            <TextDefault>
              <TextDefault type="h5">{title}</TextDefault>
              <TextDefault type="t1">{body}</TextDefault>
            </TextDefault>
            <View
              style={{
                flexDirection: isWide ? 'row' : 'column',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <TextDefault style={{ marginTop: Spacings.S4 }}>
                <TextDefault
                  type="t5"
                  style={{
                    lineHeight: 22,
                    borderRightWidth: 1,
                    borderRightColor: Colors.border,
                  }}
                >
                  {t('app.your-invest')}
                </TextDefault>
                <TextDefault type="t2">
                  {' '}
                  {formatCurrency(investment)}
                </TextDefault>
              </TextDefault>
              {/* {warning && (
                <FlexRow>
                  <TextDefault type="h5">
                    {t('app.calculator.additionalpurchase')}
                  </TextDefault>
                  <IconChevronRight />
                </FlexRow>
              )} */}
            </View>
          </View>
        </View>
      </Container>
    </Box>
  )
}

interface FirstInvestmentProps {
  investmentProposalSaved?: InvestmentProposal
  impactShare: number
  investment: number
  isWide: boolean
}

function Greeting({
  customerFirstName,
}: {
  customerFirstName: string | undefined
}) {
  const { t } = useTranslation()
  const [greeting] = useState(dayTimeText(new Date()))
  const welcomeText = `${t('home.greeting-text')}`
  return (
    <>
      <TextDefault type="h1" style={styles.greeting}>
        {customerFirstName
          ? `${t(`app.greeting.${greeting}`)}, ${customerFirstName}`
          : greeting}
      </TextDefault>
      <TextDefault type="t1" style={styles.welcome}>
        {welcomeText}
      </TextDefault>
    </>
  )
}

function FirstInvestmentBox({
  investmentProposalSaved,
  investment,
  impactShare,
  isWide,
}: FirstInvestmentProps) {
  const { t } = useTranslation()
  const firstInvestmentDone = investment !== 0
  const warning = firstInvestmentDone && investment < MINIMUM_INVESTMENT
  const gap = isWide ? Spacings.S48 : Spacings.S20
  const containerStyle = {
    flex: 1,
    marginVertical: gap,
    paddingHorizontal: gap,
  }
  return (
    <Box style={{ paddingBottom: isWide ? Spacings.S64 : 0 }}>
      <View style={{ width: '100%', flexDirection: isWide ? 'row' : 'column' }}>
        <View style={[containerStyle, styles.rightBorder]}>
          <TextDefault type="h2" style={{ marginBottom: Spacings.S24 }}>
            {t('app.portfolio-almost-ready')}
          </TextDefault>
          <TextDefault type="t1" style={{ marginBottom: Spacings.S24 }}>
            {t('app.start-investing')}
          </TextDefault>
          <TextDefault type="t1">{t('app.know-your-portfolio')}</TextDefault>
          {isWide || (
            <>
              <View style={styles.mobileSteps}>
                <TextDefault
                  type="h6"
                  style={{ paddingHorizontal: Spacings.S20 }}
                >
                  {t('questions.step-title', { step: '3/3' })}
                </TextDefault>
                <TextDefault
                  type="t5"
                  style={{ paddingHorizontal: Spacings.S20 }}
                >
                  {t('app.make-first-invest')}
                </TextDefault>
                <NewCustomerSteps
                  currentStep={firstInvestmentDone && !warning ? 3 : 2}
                  style={{
                    backgroundColor: 'none',
                    borderBottomWidth: 0,
                    borderTopWidth: 0,
                  }}
                  hideTitle={true}
                  warning={warning}
                />
              </View>
              <Divider style={{ marginTop: Spacings.S32, marginBottom: 0 }} />
            </>
          )}
        </View>
        {investmentProposalSaved ? (
          <View style={[containerStyle, { alignItems: 'center' }]}>
            <View style={{ maxWidth: 220, width: '100%' }}>
              <PortfolioDistributionEdit
                investmentProposal={investmentProposalSaved}
                hideRiskLevel={true}
                impactShare={impactShare}
                singlePie={true}
                hideRiskInfo={true}
                title={`${t('app.your-portfolio')}`}
                t={t}
              />
            </View>
          </View>
        ) : null}
      </View>
      {isWide && (
        <NewCustomerSteps
          currentStep={firstInvestmentDone && !warning ? 3 : 2}
          style={styles.desktopSteps}
          warning={warning}
        />
      )}
    </Box>
  )
}

function HomeEmpty() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { isWide } = useLayout()
  const {
    newInvestmentConfirmed,
    investmentProposalSaved,
    impactShare,
    customerFirstName,
    discreationaryPortfolioMarketValue,
  } = AppStore

  // Current cash value if available or incoming cash
  const investment = discreationaryPortfolioMarketValue
    ? discreationaryPortfolioMarketValue
    : newInvestmentConfirmed
  return (
    <ImageBackground
      source={image}
      style={styles.imageBackground}
      imageStyle={{ height: 1200 }}
      resizeMode="cover"
    >
      <View style={{ paddingHorizontal: Spacings.S24 }}>
        <View style={styles.container}>
          <Greeting {...{ customerFirstName }} />
          <NotificationBox {...{ investment, isWide, navigate }} />
          {/* <FirstInvestmentBox
            {...{ investmentProposalSaved, investment, impactShare, isWide }}
          /> */}
          {/* <InvestmentButton
            style={isWide ? styles.buttonDesktop : styles.buttonMobile}
          /> */}
          {/* <TextDefault type="h2" style={styles.calculatorTitle}>
            {t('app.investment-counter')}
          </TextDefault>
          <TextDefault type="t1" style={styles.calculatorParagraph}>
            {t('app.investment-counter-description')}
          </TextDefault> */}
        </View>
        <CalculatorContent containerStyle={styles.calculatorContainer} />
      </View>
    </ImageBackground>
  )
}

export default observer(HomeEmpty)

const styles = StyleSheet.create({
  imageBackground: {
    paddingTop: isWeb ? 0 : Spacings.S24,
    width: '100%',
    flex: 1,
    display: 'flex',
    paddingBottom: 124,
    marginBottom: -100,
    backgroundColor: Colors.white,
  },
  buttonDesktop: { marginTop: -28, zIndex: 1, minWidth: 273 },
  buttonMobile: { marginTop: Spacings.S32, width: '100%' },
  calculatorTitle: {
    justifyContent: 'center',
    marginTop: 110,
    marginBottom: Spacings.S24,
  },
  calculatorParagraph: {
    textAlign: 'center',
    marginBottom: Spacings.S64,
    maxWidth: 630,
  },
  rightBorder: { borderRightWidth: 1, borderRightColor: Colors.border },
  greeting: {
    marginBottom: Spacings.S4,
    marginTop: Spacings.S48,
    textAlign: 'center',
    paddingHorizontal: Spacings.S24,
    lineHeight: 42,
  },
  welcome: { color: Colors.textSecondary, marginBottom: Spacings.S48 },
  firstTransaction: { width: '100%', marginBottom: Spacings.S24 },
  calculatorContainer: {
    maxWidth: 1200,
    width: '100%',
    display: 'flex',
    marginHorizontal: 'auto',
  },
  container: {
    alignItems: 'center',
    maxWidth: 840,
    width: '100%',
    marginHorizontal: 'auto',
  },
  successBorder: { borderWidth: 2, borderColor: Colors.primary },
  warningBorder: { borderWidth: 2, borderColor: Colors.warning },
  // TODO: Create a separate gradient component
  desktopSteps: {
    borderBottomWidth: 0,
    borderRadius: 3,
    backgroundColor:
      'linear-gradient(31.43deg, rgba(174, 205, 200, 0.2) -8.57%, rgba(174, 205, 200, 0.1) 61.79%)',
    marginHorizontal: Spacings.S48,
  },
  mobileSteps: {
    borderBottomWidth: 0,
    borderRadius: 3,
    backgroundColor:
      'linear-gradient(31.43deg, rgba(174, 205, 200, 0.2) -8.57%, rgba(174, 205, 200, 0.1) 61.79%)',
    marginTop: Spacings.S20,
    paddingTop: Spacings.S20,
  },
})
