import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { DiscountCodeForm } from '@taaleri/components/src/screens/profile/DiscountCodeForm'

export default function DiscountScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <DiscountCodeForm />
    </ContentContainerBox>
  )
}
