import { sleep } from '@taaleri/core/src/api/mockHelper'

import { AppVersion } from '../models/AppVersion'
import { IVersionApi } from './IVersionApi'
import { MaintenanceWithWarning } from '../models/Maintenance'

async function appVersion() {
  await sleep(100)
  const version: AppVersion = {
    currentVersion: '1.28.2',
    requiredVersion: '1.28.2',
  }

  return Promise.resolve(version)
}

async function maintenance() {
  await sleep(100)
  const maintenanceDetail: MaintenanceWithWarning = {
    ongoing: false,
    header: 'Huoltokatko',
    paragraph:
      'Palvelussamme on huoltokatko ja palvelumme  ovat tilapäisesti poissa käytöstä. Pahoittelemme katkosta aiheutuvaa häiriötä.',
    warning: {
      ongoing: false,
      header: 'Varoitus',
      paragraph:
        'Palvelussamme on huoltokatko ja palvelumme  ovat tilapäisesti poissa käytöstä. Pahoittelemme katkosta aiheutuvaa häiriötä.',
    },
  }

  return Promise.resolve(maintenanceDetail)
}

export const versionApiMock: IVersionApi = {
  appVersion,
  maintenance,
}
