import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { FurtherClarificationMessages } from '@taaleri/core/src/constants/FurtherClarificationMessages'
import {
  PURPOSE_OPTIONS,
  AssetSource,
  ASSET_SOURCES,
  AccountPurpose,
  POLITICAL_PERSON_OPTIONS,
  POLITICAL_RELATIONSHIP_OPTIONS,
  TAXATION_OPTIONS,
  YES_NO_OPTIONS,
  TAXATION_OPTIONS_ABROAD,
} from '@taaleri/core/src/constants/Options'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { FINLAND } from '@taaleri/core/src/models/Countries'
import { RegulatoryQuestions } from '@taaleri/core/src/models/RequlatoryQuestions'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { Formik, FormikHelpers } from 'formik'
import { TFunction } from 'i18next'
import { toJS } from 'mobx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import * as Yup from 'yup'

import {
  ROUTE_ONBOARDING_FURTHER_CLARIFICATION,
  nextOnboardingRoute,
  ROUTE_ONBOARDING_REQULATORY_QUESTIONS,
} from './OnboardingRoutes'
import Taxation from './Taxation'
import { validateRegulatoryQuestions } from './taxCountryInvalid'
import { isWeb } from '../../constants/Platforms'
import InfoButton from '../../ui/InfoButton'
import Spacer from '../../ui/Spacer'
import { InfoButtonStyledProps } from '../../ui/InfoButtonProps'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import { ButtonActionWithErrors } from '../../ui/UiButton'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import CheckListMultiple from '../../ui/form/CheckListMultiple'
import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import CountryLink from '../../ui/form/CountryLink'
import UiForm from '../../ui/form/UiForm'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import Title from '../../ui/text/Title'
import { QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND } from '../questions/questions'

const politicallyExposedInfo: InfoButtonStyledProps[] = [
  {
    text: 'questions.onboardingRegulatoryForm.pep-info-1',
  },
  {
    text: 'questions.onboardingRegulatoryForm.pep-info-2',
  },
  {
    text: 'questions.onboardingRegulatoryForm.pep-info-3',
    frontDecorator: '•',
  },
  {
    text: 'questions.onboardingRegulatoryForm.pep-info-4',
    frontDecorator: '•',
  },
]
const foreignActivityInfo: InfoButtonStyledProps[] = [
  {
    text: 'questions.onboardingRegulatoryForm.foreign-activity-info-1',
  },
  {
    text: 'questions.onboardingRegulatoryForm.foreign-activity-info2',
  },
]

const isUnderUsTaxationInfo =
  'questions.onboardingRegulatoryForm.is-us-taxation-info'

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    isAktiaPrimaryBank: Yup.string().required(`${t(ErrorMessages.required)}`),
    sourceOfFunds: Yup.array()
      .of(Yup.string())
      .required()
      .min(1, `${t(ErrorMessages.chooseOneSourceOfFunds)}`),
    sourceOfFundsOtherDescription: Yup.string().when('sourceOfFunds', {
      is: (val: string) => val.includes(AssetSource.other),
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    isPep: Yup.boolean(),
    pepConnection: Yup.string().when('isPep', {
      is: true,
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    pepCountry: Yup.string().when('isPep', {
      is: true,
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    pepPosition: Yup.string().when('isPep', {
      is: true,
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    accountPurpose: Yup.string().required(
      `${t(ErrorMessages.chooseAccountPurpose)}`
    ),
    accountPurposeOther: Yup.string().when('accountPurpose', {
      is: (value: string) => value === AccountPurpose.Other,
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    taxationChoise: Yup.string().required(`${t(ErrorMessages.required)}`),
    taxationCountries: Yup.array().of(
      Yup.object({
        country: Yup.string().required(`${t(ErrorMessages.required)}`),
        tin: Yup.string().when('country', {
          is: (val: string) => val !== FINLAND.code,
          then: Yup.string().required(`${t(ErrorMessages.required)}`),
        }),
      })
    ),
    hasDualCitizenship: Yup.boolean(),
    dualCitizenshipCountry: Yup.string().when('hasDualCitizenship', {
      is: true,
      then: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
  })

interface Props {
  navigate: (path: string) => void
}

export default function RequlatoryQuestionsForm(props: Props) {
  const { t } = useTranslation()
  function onSubmit(
    values: RegulatoryQuestions,
    actions: FormikHelpers<RegulatoryQuestions>
  ) {
    actions.setSubmitting(true)
    const isContractUpdate = QuestionStore.questionMode === 'contract'

    const { success, response } = validateRegulatoryQuestions(values)
    if (success && response) {
      QuestionStore.setFurtherClarificationReason(response)
      props.navigate(ROUTE_ONBOARDING_FURTHER_CLARIFICATION)
      return
    }
    QuestionStore.regulatoryQuestions = values

    props.navigate(
      isContractUpdate
        ? QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND
        : nextOnboardingRoute(ROUTE_ONBOARDING_REQULATORY_QUESTIONS)
    )
  }

  return (
    <>
      <PaddingHorizontal>
        <Title>
          {t('questions.onboardingRegulatoryForm.law-requires-text')}
        </Title>

        <Formik
          initialValues={toJS(QuestionStore.regulatoryQuestions)}
          validationSchema={validationSchema(t)}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, errors, touched }) => {
            const isOtherPurpose =
              values.accountPurpose === AccountPurpose.Other
            const isAssetSourceOther = values.sourceOfFunds.includes(
              AssetSource.other
            )

            return (
              <UiForm>
                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.is-main-bank-aktia'
                  )}`}
                  titleRightComponent={
                    <InfoButton
                      title="questions.onboardingRegulatoryForm.is-main-bank-aktia"
                      text="questions.onboardingRegulatoryForm.main-bank-info"
                    />
                  }
                >
                  <CheckListSingleForm
                    name="isAktiaPrimaryBank"
                    options={YES_NO_OPTIONS}
                  />
                </BoxWithPaddingMargin>
                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.account-purpose'
                  )}`}
                >
                  <CheckListSingleForm
                    name="accountPurpose"
                    options={PURPOSE_OPTIONS}
                  />

                  {isOtherPurpose && (
                    <UiTextInputForm
                      name="accountPurposeOther"
                      label={`${t(
                        'questions.onboardingRegulatoryForm.account-purpose'
                      )}`}
                      type="name"
                    />
                  )}
                </BoxWithPaddingMargin>
                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.source-of-funds'
                  )}`}
                >
                  <CheckListMultiple
                    name="sourceOfFunds"
                    options={ASSET_SOURCES}
                    lastBottomDivider={!isAssetSourceOther}
                  />
                  {isAssetSourceOther && (
                    <UiTextInputForm
                      name="sourceOfFundsOtherDescription"
                      label={`${t(
                        'questions.onboardingRegulatoryForm.source-of-funds-description'
                      )}`}
                      type="name"
                    />
                  )}
                </BoxWithPaddingMargin>

                <BoxWithPaddingMargin
                  title={`${t('questions.onboardingRegulatoryForm.trustee')}`}
                  titleRightComponent={
                    <InfoButton
                      title="questions.onboardingRegulatoryForm.trustee"
                      text="questions.onboardingRegulatoryForm.trustee-info"
                    />
                  }
                >
                  <UiListItemSwitch
                    name="incompetent"
                    title={`${t(
                      'questions.onboardingRegulatoryForm.has-trustee'
                    )}`}
                    topDivider={false}
                    bottomDivider={true}
                    onChangeText={(value) => {
                      if (value) {
                        QuestionStore.setFurtherClarificationReason(
                          FurtherClarificationMessages.incompetent
                        )
                        props.navigate(ROUTE_ONBOARDING_FURTHER_CLARIFICATION)
                      }
                    }}
                  />
                </BoxWithPaddingMargin>
                <BoxWithPaddingMargin
                  title={`${t('questions.onboardingRegulatoryForm.pep')}`}
                  titleRightComponent={
                    <InfoButton
                      title="questions.onboardingRegulatoryForm.pep"
                      styledParagraphs={politicallyExposedInfo}
                    />
                  }
                >
                  <UiListItemSwitch
                    name="isPep"
                    title={`${t('questions.onboardingRegulatoryForm.is-pep')}`}
                    topDivider={false}
                    bottomDivider={true}
                    onChangeText={(ip) => {
                      if (!ip) {
                        values.pepConnection = ''
                        values.pepCountry = ''
                        values.pepPosition = ''
                      }
                    }}
                  />
                  {values.isPep && (
                    <View>
                      <BoxWithPaddingMargin
                        title={`${t(
                          'questions.onboardingRegulatoryForm.pep-connection'
                        )}`}
                      >
                        <CheckListSingleForm
                          name="pepConnection"
                          options={POLITICAL_RELATIONSHIP_OPTIONS}
                        />
                      </BoxWithPaddingMargin>
                      <CountryLink
                        name="pepCountry"
                        title={
                          isWeb
                            ? `${t(
                                'questions.onboardingRegulatoryForm.pep-country-web'
                              )}`
                            : `${t(
                                'questions.onboardingRegulatoryForm.pep-country-app'
                              )}`
                        }
                      />
                      <BoxWithPaddingMargin
                        title={`${t(
                          'questions.onboardingRegulatoryForm.pep-position'
                        )}`}
                      >
                        <CheckListSingleForm
                          name="pepPosition"
                          options={POLITICAL_PERSON_OPTIONS}
                        />
                      </BoxWithPaddingMargin>
                    </View>
                  )}
                </BoxWithPaddingMargin>

                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.is-us-taxation'
                  )}`}
                  titleRightComponent={
                    <InfoButton
                      title="questions.onboardingRegulatoryForm.is-us-taxation"
                      text={isUnderUsTaxationInfo}
                    />
                  }
                >
                  <CheckListSingleForm
                    name="isUnderUsTaxation"
                    options={YES_NO_OPTIONS}
                  />
                </BoxWithPaddingMargin>

                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.taxation-countries'
                  )}`}
                  titleStyle={styles.titleMargin}
                >
                  <CheckListSingleForm
                    name="taxationChoise"
                    options={
                      values.isUnderUsTaxation === 'Yes'
                        ? TAXATION_OPTIONS_ABROAD
                        : TAXATION_OPTIONS
                    }
                  />
                  <Spacer />
                  <Taxation values={values} navigate={props.navigate} />
                </BoxWithPaddingMargin>

                <BoxWithPaddingMargin
                  title={`${t(
                    'questions.onboardingRegulatoryForm.foreign-activity'
                  )}`}
                  titleRightComponent={
                    <InfoButton
                      title="questions.onboardingRegulatoryForm.foreign-activity"
                      styledParagraphs={foreignActivityInfo}
                    />
                  }
                >
                  <UiListItemSwitch
                    name="hasDualCitizenship"
                    title={t('questions.onboardingRegulatoryForm.have-dual-citizenship')}
                    topDivider={false}
                  />
                  {values.hasDualCitizenship && (
                    <View>
                      <CountryLink
                        name="dualCitizenshipCountry"
                        title={`${t('form.country-short')}`}
                      />
                    </View>
                  )}
                </BoxWithPaddingMargin>

                <ButtonActionWithErrors
                  title={t('button.next')}
                  onPress={isWeb ? () => '' : handleSubmit}
                  errors={errors}
                  touched={touched}
                />
              </UiForm>
            )
          }}
        </Formik>
      </PaddingHorizontal>
    </>
  )
}

const styles = StyleSheet.create({
  titleMargin: {
    marginBottom: Spacings.S24,
  },
})
