import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, ViewStyle } from 'react-native'

import { IconInfo } from './Icons'
import InfoButton from './InfoButton'
import { TextDefault } from './text/UiText'
import { isWeb } from '../constants/Platforms'
import useNavigation from '../navigation/useNavigation'
import { ROUTE_PROFILE_FAQ } from '../screens/profile/ProfileRoutes'

interface Props {
  style?: StyleProp<ViewStyle>
}

function MarketValueInfoButton({ style }: Props) {
  const { navigateApp } = useNavigation()
  const showLink = AppStore.isDigiOrConsultative
  const { t } = useTranslation()

  return (
    <InfoButton
      style={style}
      infoElement={<IconInfo color={Colors.gray40} width={16} height={16} />}
    >
      {AppStore.showEuroProfit && (
        <TextDefault style={{ marginBottom: Spacings.S16 }}>
          {t('portfolio.profit-info')}
        </TextDefault>
      )}
      <TextDefault style={{ marginBottom: showLink ? Spacings.S16 : 0 }}>
        {t('portfolio.profit-info-2')}
      </TextDefault>
      {showLink ? (
        <TextDefault
          onPress={() => navigateApp(ROUTE_PROFILE_FAQ)}
          style={{ marginBottom: isWeb ? 0 : Spacings.S24 }}
          color={Colors.textPrimary}
        >
          {t('more')}
        </TextDefault>
      ) : null}
    </InfoButton>
  )
}

export default observer(MarketValueInfoButton)
