import React from 'react'
import { StyleProp, ViewStyle, StyleSheet, View } from 'react-native'
import {
  KeyFigure,
  KeyFigureType,
} from '@taaleri/core/src/constants/KeyFigures'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import {
  TextDefault,
  UiTextProps,
} from '@taaleri/components/src/ui/text/UiText'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { isNullOrUndefined } from '@taaleri/core/src/utils/keyFigureHelper'
import { IconChevronRight } from '@taaleri/components/src/ui/icons/ChevronIcons'
import { KeyFigureBox } from '@taaleri/components/src/screens/portfolio/KeyFiguresBox'

interface ColorFormatedProps extends UiTextProps {
  value: number
  format: (value: number) => string
  inverted?: boolean
}

function ColorFormatedNumber(props: ColorFormatedProps) {
  const { value, inverted, format } = props
  const percentUp = inverted ? value < 0 : value > 0
  const color = percentUp
    ? Colors.textPrimary
    : value === 0
    ? Colors.text
    : Colors.error
  return (
    <TextDefault {...props} style={[props.style, { color }]}>
      {format(value)}
    </TextDefault>
  )
}

interface Props {
  keyFigures: KeyFigure[]
  setGraphType: (type: KeyFigureType) => void
}

interface BoxProps {
  keyFigure: KeyFigure
  style: StyleProp<ViewStyle>
  setGraphType: (type: KeyFigureType) => void
}

function KeyFigureContainer({ keyFigure, style, setGraphType }: BoxProps) {
  const {
    value,
    label,
    difference,
    format,
    inverted,
    id,
    touchable,
    description,
  } = keyFigure
  const makeBoxTouchable = touchable && !isNullOrUndefined(value)
  return (
    <BoxWithPadding
      {...{ style }}
      onPress={makeBoxTouchable ? () => setGraphType(id) : undefined}
    >
      <KeyFigureBox keyFigure={keyFigure} />
      {makeBoxTouchable && (
        <IconChevronRight style={{ justifyContent: 'center' }} />
      )}
    </BoxWithPadding>
  )
}

function KeyFigures({ keyFigures, setGraphType }: Props) {
  return (
    <FlexRow style={styles.container}>
      {keyFigures.map((keyFigure, index) => (
        <KeyFigureContainer
          {...{ keyFigure }}
          key={keyFigure.id}
          style={[styles.box, { marginLeft: index === 0 ? 0 : Spacings.S20 }]}
          setGraphType={setGraphType}
        />
      ))}
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: -1,
  },
  box: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
})

export default KeyFigures
