import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import styled from 'styled-components'

const TopBottomContainer = styled.div<{ topImage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${(props) => (props.topImage ? '50vh' : '75vh')};
  @media (min-width: ${BreakPoint.S}px) {
    height: auto;
    min-height: 50vh;
  }
`

export default TopBottomContainer
