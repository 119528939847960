export type PortfolioType = 'etf' | 'impact'

export function portfolioTypeName(portfolioType: PortfolioType) {
  if (portfolioType === 'impact') {
    return 'impact-portfolio'
  }

  return 'etf-portfolio'
}

export function portfolioTypeDescription(portfolioType: PortfolioType) {
  if (portfolioType === 'impact') {
    return 'Tehokas päästöjä vähentävä salkku'
  }

  return 'Tehokas ja hajautettu salkku'
}
