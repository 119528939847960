import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, ViewStyle } from 'react-native'

import { PdfButton } from './SecurityPdfButton'
import FlexRow from '../../ui/FlexRow'
import OpenLink from '../../ui/OpenLink'
import UiTouchable from '../../ui/UiTouchable'
import { TextDefault } from '../../ui/text/UiText'

export function PdfLink({
  pdfUrl,
  style,
  color,
  fontSize,
}: {
  pdfUrl?: string
  style?: StyleProp<ViewStyle>
  color?: string
  fontSize?: number
}) {
  const { t } = useTranslation()
  return pdfUrl ? (
    <FlexRow style={[{ alignItems: 'center' }, style]}>
      <UiTouchable style={style} onPress={() => OpenLink(pdfUrl, 'report-pdf')}>
        <TextDefault
          type="t5"
          style={{
            letterSpacing: 0.9,
            color: color ?? Colors.gray50,
            fontSize,
          }}
        >
          {t('portfolio.pdf-link')}
        </TextDefault>
      </UiTouchable>
      <PdfButton
        url={pdfUrl}
        style={{ marginLeft: Spacings.S8 }}
        color={color}
      />
    </FlexRow>
  ) : null
}
