import Colors from '@taaleri/core/src/constants/Colors'
import { avenirNextBold, FontSize } from '@taaleri/core/src/constants/Fonts'
import { FieldArray, useField } from 'formik'
import * as React from 'react'
import { FC } from 'react'
import { View } from 'react-native'
import { isWeb } from '../constants/Platforms'
import CountryLink from '../ui/form/CountryLink'
import { IconCloseCircle } from '../ui/Icons'
import { Paragraph } from '../ui/text/Paragraph'
import UiTouchable from '../ui/UiTouchable'
import { useTranslation } from 'react-i18next'

interface MultiCountryProps {
  name: string
  title?: string
  followingTitle?: string
  excludedCountryCodes?: string[]
}

const MultiCountry: FC<MultiCountryProps> = (props) => {
  const { t } = useTranslation()
  const { name, title, followingTitle } = props
  const [field, meta, _helpers] = useField(name)

  const renderTitle = () => {
    if (field && field.value && field.value.length > 0) {
      return followingTitle ? followingTitle : t('button.next')
    }

    return title ? title : t('chooseCountry')
  }

  return (
    <FieldArray name={name}>
      {({ push, remove }) => {
        return (
          <>
            <View
              style={{
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View
                style={{
                  width: '100%',
                  flex: 1,
                }}
              >
                <CountryLink
                  name={`${props.name}Dropdown`}
                  title={renderTitle()}
                  onCountryChange={(value) => {
                    if (value && !field.value.some((c:any) => c.code === value.code)) {                      
                      push(value)
                    }
                  }}
                  clearOnChange={true}
                  excludeFinland={true}
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flex: 3,
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                {field &&
                  field.value &&
                  field.value.length > 0 &&
                  field.value.map((country: any, index: number) => {
                    return (
                      <View
                        key={`${name}-chosen-countries-${index}`}
                        style={{
                          flexBasis: isWeb ? '32%' : '49%',
                          justifyContent: 'flex-start',
                          borderRadius: 10,
                          borderWidth: 2,
                          borderStyle: 'solid',
                          borderColor: '#2BC9AF',
                          width: '100%',
                          marginRight: isWeb
                            ? (index + 1) % 3 === 0
                              ? '0%'
                              : '2%'
                            : (index + 1) % 2 === 0
                            ? '0%'
                            : '2%',
                          marginBottom: 5,
                        }}
                      >
                        <UiTouchable
                          onPress={() => {
                            remove(index)
                          }}
                          style={{
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            paddingRight: 5,
                            paddingTop: 5,
                          }}
                        >
                          <IconCloseCircle />
                        </UiTouchable>
                        <Paragraph
                          size={FontSize.S14}
                          style={{
                            fontSize: 14,
                            paddingLeft: 10,
                            marginBottom: 10,
                            marginTop: -5,
                          }}
                        >
                          {t(country.name)}
                        </Paragraph>
                      </View>
                    )
                  })}
                {meta.error && (
                  <Paragraph
                    style={{
                      color: Colors.error,
                      fontFamily: avenirNextBold,
                      paddingLeft: 20
                    }}
                  >
                    {t(meta.error)}
                  </Paragraph>
                )}
              </View>
            </View>
          </>
        )
      }}
    </FieldArray>
  )
}

export default MultiCountry
