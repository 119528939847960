import React from 'react'
import { ScrollView, StyleSheet, StyleProp, ViewStyle } from 'react-native'

interface Props {
  children: any
  style?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
}

export function SideScrollContainer(props: Props) {
  return (
    <ScrollView
      style={props.style}
      contentContainerStyle={[styles.contentContainer, props.containerStyle]}
      scrollEnabled={true}
      horizontal={true}
      showsHorizontalScrollIndicator={false}
    >
      {props.children}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
