import React from 'react'
import { ImpactVerification } from '@taaleri/components/src/screens/impact/ImpactVerification'
import ContentContainer from 'ui/container/ContentContainer'
import { ImpactToggleSteps } from '@taaleri/components/src/components/Steps'

export function ImpactVerificationScreen() {
  return (
    <>
      <ImpactToggleSteps currentStep={1} />
      <ContentContainer>
        <ImpactVerification />
      </ContentContainer>
    </>
  )
}
