import React from 'react'
import { View } from 'react-native'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { Paragraph } from '../../ui/text/Paragraph'

import { observer } from 'mobx-react'
import Title from '../../ui/text/Title'
import UiSlider from '../../ui/form/UiSlider'
import { LabelValueWithMargin } from '../../ui/text/LabelValue'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import Spacer from '../../ui/Spacer'
import range from 'lodash/range'
import indexOf from 'lodash/indexOf'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

const initialValues = [
  ...range(0, 1000, 100),
  ...range(1000, 10000, 1000),
  ...range(10000, 100000, 10000),
  ...range(100000, 600000, 100000),
]

const monthlyValues = [
  ...range(0, 100, 10),
  ...range(100, 1000, 50),
  ...range(1000, 5100, 100),
]

function valueFromPosition(values: number[], position: number) {
  return values[position]
}

function positionFromValue(values: number[], value: number) {
  return indexOf(values, value)
}

export const InitialInvestmentSlider = observer(() => {
  const { t } = useTranslation()
  return (
    <>
      <LabelValueWithMargin
        label={t('app.calculator.oneoffinvestment')}
        labelComponent="bold"
        value={`${formatCurrency(QuestionStore.initialInvestment)} `}
        containerStyle={{ marginVertical: Spacings.S8 }}
      />
      <UiSlider
        min={0}
        max={initialValues.length - 1}
        step={1}
        initialValue={positionFromValue(
          initialValues,
          QuestionStore.initialInvestment
        )}
        onChange={(value: string) => {
          QuestionStore.initialInvestment = valueFromPosition(
            initialValues,
            Number(value)
          )
        }}
      />
    </>
  )
})

export const MonthlyInvestmentSlider = observer(() => {
  const { t } = useTranslation()
  return (
    <>
      <LabelValueWithMargin
        label={t('app.calculator.monthlyinvestment')}
        labelComponent="bold"
        value={`${formatCurrency(QuestionStore.monthlyInvestment)} `}
        containerStyle={{ marginVertical: Spacings.S8 }}
      />
      <UiSlider
        min={0}
        max={monthlyValues.length - 1}
        step={1}
        initialValue={positionFromValue(
          monthlyValues,
          QuestionStore.monthlyInvestment
        )}
        onChange={(value: string) => {
          QuestionStore.monthlyInvestment = valueFromPosition(
            monthlyValues,
            Number(value)
          )
        }}
      />
    </>
  )
})

function AmountContent() {
  const { t } = useTranslation()
  const showMessage = Number(QuestionStore.initialInvestment) < 1000

  return (
    <View>
      <Title>{t('questions.how-much-invest-title')}</Title>
      <InitialInvestmentSlider />
      {showMessage && (
        <Paragraph style={{ color: Colors.error, marginTop: Spacings.S24 }}>
          {t('invest.invest-remark')}
        </Paragraph>
      )}
      <Spacer />
      <MonthlyInvestmentSlider />
      <Spacer />
      <Paragraph>
        {t('questions.amount-invest-text')}
      </Paragraph>
    </View>
  )
}

export default observer(AmountContent)
