import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportBox from './ReportBox'
import { getReportOrders } from '@taaleri/core/src/services/reports'
import ReportOrderStore from '@taaleri/core/src/stores/ReportOrderStore'
import { ReportOrder } from '@taaleri/core/src/models/ReportOrders'
import useNavigation from '../../navigation/useNavigation'
import {
  ROUTE_REPORT_ORDERS,
  ROUTE_REPORT_ORDERS_PACKAGE,
} from '../../navigation/routes'
import ContentLoader from '../../ui/ContentLoader'
import UiButton from '../../ui/UiButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isWeb } from '../../constants/Platforms'
import { IconBriefcaseLarge } from '../../ui/Icons'
import Colors from '@taaleri/core/src/constants/Colors'
import Box from '../../ui/box/Box'
import { useTranslation } from 'react-i18next'

const icon = (
  <IconBriefcaseLarge
    style={{
      position: 'absolute',
      top: Spacings.S16,
      right: -Spacings.S32,
    }}
    color={Colors.backgroundIcon}
    scale={4}
  />
)

function ReportOrderBox() {
  const { t } = useTranslation()
  const [orders, setOrders] = useState<ReportOrder[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { navigateApp } = useNavigation()
  async function findReportOrders() {
    setLoading(true)
    const { success, response } = await getReportOrders(AppStore.customerId)
    ReportOrderStore.reportPackage = undefined
    if (success && response) {
      setOrders(response)
      ReportOrderStore.orders = response
    }
    setLoading(false)
  }

  useEffect(() => {
    findReportOrders()
  }, [AppStore.customerId])

  return (
    <ReportBox
      title={t('menu.report-orders')}
      subtitle={`${t('reportBox.order-amount',{orders: orders?.length})}`}
      buttonTitle={`${t('reportBox.show-orders')}`}
      onButtonPress={() => navigateApp(ROUTE_REPORT_ORDERS)}
      icon={icon}
      backgroundColor={Colors.backgroundBlue}
    >
      {loading ? (
        <Box style={{ marginBottom: Spacings.S16 }}>
          <ContentLoader variant={isWeb ? 'flex' : 'facebook'} />
        </Box>
      ) : (
        <UiButton
          type="secondary"
          title={t('reportBox.new-order')}
          onPress={() => navigateApp(ROUTE_REPORT_ORDERS_PACKAGE)}
          containerStyle={{
            marginTop: 'auto',
            marginBottom: Spacings.S16,
          }}
          size="small"
        />
      )}
    </ReportBox>
  )
}

export default observer(ReportOrderBox)
