import {
  Customer,
  CustomerAuthorization,
  CustomerResponse,
} from '../../models/Customer'

export function toCustomer(
  customerResponse: CustomerResponse,
  customerAuthorizations?: CustomerAuthorization[]
): Customer {
  const {
    accountPurpose,
    accountPurposeDescription,
    basicContractSigned,
    digiContractSigned,
    iban,
    customerId,
    firstName,
    lastName,
    fullName,
    emailAddress,
    phoneNumber,
    address,
    accountManager,
    identityNumber,
    incompetent,
    representativeType,
    accountType,
    idValidity,
    isDigiCustomer,
    isCustomer,
    isConsultative,
    consultativeContractSigned,
    isDigiConversionAllowed,
    isDigiConverted,
    hasDigiBanker,
    isDiscretionary,
    age,
    isBlocked,
    rules,
  } = customerResponse

  // logger.devInfo('customerResponse', customerResponse)
  return {
    accountPurpose,
    accountPurposeDescription,
    basicContractSigned, //: '2020-11-11', // uncomment to F2F testing
    digiContractSigned,
    consultativeContractSigned,
    onboardingId: null,
    customerId: parseFloat(customerId),
    customerName: fullName,
    firstName,
    lastName,
    fullName,
    identityNumber,
    email: emailAddress,
    phone: phoneNumber,
    nationality: '',
    countryOfBirth: '',
    profession: '',
    socioeconomicStatus: '',
    levelOfEducation: '',
    bankAccountNumber: iban || '',
    marketingAllowance: false,
    address,
    isPostAddress: false,
    postAddress: {
      street: '',
      city: '',
      country: '',
      postalCode: '',
    },
    customerAuthorizations: customerAuthorizations
      ? customerAuthorizations
      : [],
    accountManager,
    incompetent: incompetent || false,
    isRepresentative: representativeType === 'Representative',
    accountType,
    idValidity,
    isDigiCustomer, //: false, // uncomment to F2F testing
    isCustomer,
    isConsultative,
    isDigiConversionAllowed,
    isDigiConverted,
    isDiscretionary,
    hasDigiBanker,
    age,
    isBlocked,
    rules,
  }
}
