import React from 'react'
import GradientContainer from 'ui/GradientContainer'
import TransactionDetailContent from '@taaleri/components/src/screens/reporttransaction/TransactionDetailContent'
import { ROUTE_REPORT_TRANSACTION } from '@taaleri/components/src/screens/report/ReportRoutes'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { BackButton } from '@taaleri/components/src/ui/BackButton'

function TransactionDetailScreen() {
  const { navigateApp } = useNavigation()
  return (
    <GradientContainer>
      <>
        <BackButton
          onPress={() => {
            TransactionStore.skipFetching = true
            navigateApp(ROUTE_REPORT_TRANSACTION)
          }}
          style={{ marginBottom: Spacings.S24 }}
        />
        <TransactionDetailContent />
      </>
    </GradientContainer>
  )
}

export default TransactionDetailScreen
