import { useState, useEffect } from 'react'
import { PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import { getPurchaseLotsByTicker } from '@taaleri/core/src/services/reports'

import AppStore from '@taaleri/core/src/stores/AppStore'
import { ABORTERROR } from '@taaleri/core/src/models/ServiceResponse'

export function usePurchaseLots(ticker: string, date: string = '') {
  const [e, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [purchaseLots, setPurchaseLots] = useState<PurchaseLot[]>([])

  useEffect(() => {
    loadPurchaseLots()
  }, [retry])

  async function loadPurchaseLots() {
    setRetry(false)
    setLoading(true)
    setError(false)
    const { success, response, error } = await getPurchaseLotsByTicker(
      AppStore.activeCustomer.customerId || 0,
      ticker,
      date
    )

    if (success && response) {
      setPurchaseLots(response)
      setError(false)
      AppStore.showPicker = false
      setLoading(false)
    } else if (error === ABORTERROR) {
      setError(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }
  return { error: e, loading, purchaseLots, setRetry }
}
