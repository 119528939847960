import React from 'react'
import Title from '../../ui/text/Title'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiTouchable from '../../ui/UiTouchable'
import groupBy from 'lodash/groupBy'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import useLayout from '../../ui/useLayout'
import { View } from 'react-native'
import {
  ASSET_CATEGORY_ID_STRUCTURED,
  ASSET_CLASS_ID_BOND,
  ASSET_CLASS_ID_CASH,
  CREDIT_RISK_LOAN,
} from '@taaleri/core/src/constants/AssetClass'
import { AssetCategoryDetail } from './AssetCategoryDetail'
import { AssetDetailSimple } from './AssetDetailSimple'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import useNavigation from '../../navigation/useNavigation'
import useAssetClassDetailRoute from './useAssetClassDetailRoute'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_PURCHASE_LOTS } from './PortfolioRoutes'
import { useTranslation } from 'react-i18next'

interface Props {
  assetClass: AssetClass
  showAdvanced?: boolean
  linkTitle?: boolean
}

function AssetDetailAdvanced({ assetClass }: Props) {
  const { t } = useTranslation()
  const assetClassCategories = groupBy(assetClass.items, 'assetCategoryId')
  const isBond = assetClass.id === ASSET_CLASS_ID_BOND

  return (
    <>
      {Object.keys(assetClassCategories).map((key) => {
        const items = assetClassCategories[key]
        const { assetCategoryId, assetCategoryName } = items[0]
        if (assetCategoryId === ASSET_CATEGORY_ID_STRUCTURED) {
          const structuredCategories = groupBy(items, 'assetAdditionalInfoName')
          return Object.keys(structuredCategories).map((k) => {
            const structuredItems = structuredCategories[k]
            const { assetAdditionalInfoId, assetAdditionalInfoName } =
              structuredItems[0]
            return (
              <AssetCategoryDetail
                key={assetAdditionalInfoId}
                assetCategoryId={assetAdditionalInfoId}
                assetCategoryName={`${t(
                  'portfolio.structured-category'
                )} - ${assetAdditionalInfoName}`}
                isBond={true}
                isStructured={assetAdditionalInfoName !== CREDIT_RISK_LOAN}
                items={structuredItems}
                assetClass={assetClass}
                onPress={(ticker: string) => {
                  if (isWeb) {
                    ReportStore.setRestrictionParameters({
                      assetClass: assetClass.id,
                      selectedCategory: assetCategoryId,
                      selectedAdditionalInfo: assetAdditionalInfoId,
                      selectedPosition: ticker,
                    })
                  }
                }}
              />
            )
          })
        }
        return (
          <AssetCategoryDetail
            key={assetCategoryId}
            {...{
              assetCategoryId,
              assetCategoryName,
              items,
              assetClass,
              isBond,
            }}
            onPress={(ticker: string) => {
              if (isWeb) {
                ReportStore.setRestrictionParameters({
                  assetClass: assetClass.id,
                  selectedCategory: assetCategoryId,
                  selectedPosition: ticker,
                })
              }
            }}
          />
        )
      })}
    </>
  )
}

function AssetDetail({ assetClass, linkTitle, showAdvanced }: Props) {
  const { isNonSmall, isSmall } = useLayout()
  const { navigateApp } = useNavigation()
  const { routeName, params } = useAssetClassDetailRoute(assetClass)
  const { t } = useTranslation()

  const titleStyle = {
    marginBottom: isSmall ? Spacings.S24 : Spacings.S16,
    paddingHorizontal: isSmall && !linkTitle ? Spacings.S24 : 0,
  }

  const selectAssetClass = () => {
    if (showAdvanced) {
      if (assetClass.id === ASSET_CLASS_ID_CASH) {
        ReportStore.resetRestrictionFilters()
        ReportStore.fetchIndex()
      } else {
        ReportStore.selectAssetClass(assetClass.id)
        ReportStore.setRestrictionOpen(true)
      }
    } else {
      navigateApp(routeName, params)
    }
  }

  const selectPosition = (ticker: string) => {
    if (showAdvanced) {
      if (isWeb) {
        const assetClassItem = assetClass.items.find((a) => a.ticker === ticker)
        if (assetClassItem) {
          ReportStore.setRestrictionParameters({
            assetClass: assetClass.id,
            selectedCategory: assetClassItem.assetCategoryId,
            selectedPosition: assetClassItem.ticker,
          })
        }
      }
    } else {
      if (isWeb) {
        navigateApp(ROUTE_PURCHASE_LOTS + '/' + assetClass.id + '/' + ticker)
      } else {
        const assetClassItem = assetClass.items.find((a) => a.ticker === ticker)
        if (assetClassItem) {
          navigateApp(ROUTE_PURCHASE_LOTS, {
            assetClassItem,
          })
        }
      }
    }
  }

  const listByCategories = isNonSmall && showAdvanced

  const title =
    linkTitle && isNonSmall ? (
      <UiTouchable onPress={selectAssetClass}>
        <Title hover={true} style={titleStyle}>
          {t(assetClass.name)}
        </Title>
      </UiTouchable>
    ) : (
      <View style={{ marginBottom: Spacings.S16 }} />
    )

  return (
    <View
      key={assetClass.id}
      style={{ marginBottom: isNonSmall ? Spacings.S32 : Spacings.S16 }}
    >
      {title}

      {listByCategories ? (
        <AssetDetailAdvanced assetClass={assetClass} />
      ) : (
        <AssetDetailSimple assetClass={assetClass} onPress={selectPosition} />
      )}
    </View>
  )
}

export default AssetDetail
