import React from 'react'
import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_FINANCE } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { Formik } from 'formik'

import QuestionFinanceContent, {
  IncomeAndExpenses,
  questionFinanceValidationSchema,
  toInitValue,
  toSaveValue,
} from '@taaleri/components/src/screens/questions/QuestionFinanceContent'
import { useHistory } from 'react-router-dom'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { useTranslation } from 'react-i18next'

function QuestionFinanceScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const monthlyIncome = toInitValue(QuestionStore.monthlyIncome)
  const monthlyExpenses = toInitValue(QuestionStore.monthlyExpenses)
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <Formik
          initialValues={{ monthlyIncome, monthlyExpenses }}
          validationSchema={questionFinanceValidationSchema}
          onSubmit={async (values: IncomeAndExpenses) => {
            QuestionStore.monthlyIncome = toSaveValue(values.monthlyIncome)
            QuestionStore.monthlyExpenses = toSaveValue(values.monthlyExpenses)
            history.push(nextQuestionRoute(ROUTE_QUESTION_FINANCE))
          }}
        >
          {({ values }) => {
            return (
              <UiForm>
                <QuestionFinanceContent values={values} />
                <Buttons title={t('button.next')} />
              </UiForm>
            )
          }}
        </Formik>
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(QuestionFinanceScreen)
