import React from 'react'

import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FinanceChart from './FinanceChart'
import Spacer from '../../ui/Spacer'
import useFirstProposal from './useFirstProposal'
import LoadingIndicator from '../../ui/LoadingIndicator'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import PropsChildren from '../../components/PropsChildren'
import { Paragraph } from '../../ui/text/Paragraph'
import PortfolioTypeBox from './PortfolioTypeBox'
import { ROUTE_QUESTION_FIRST_PROPOSAL } from './QuestionRoutes'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { portfolioTypeName } from '@taaleri/core/src/models/PortfolioType'
import { TextSmall } from '../../ui/text/UiText'
import FirstProposalPieSection from './FirstProposalPieSection'
import { useTranslation } from 'react-i18next'

export default function FirstProposalContent({ children }: PropsChildren) {
  const { t } = useTranslation()
  const { investmentProposal, error, setRetry } = useFirstProposal()

  if (!investmentProposal) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const typeName = t(portfolioTypeName(QuestionStore.portfolioType))

  return (
    <View>
      <Title>{t('questions.proposal.title')}</Title>
      <Paragraph>
        {t('questions.proposal.description')}
      </Paragraph>
      <BoxWithPadding
        title={`${t('portfolio.distribution')}`}
        titleStyle={{ marginBottom: Spacings.S24 }}
        titleRightComponent={<TextSmall>{typeName.toUpperCase()}</TextSmall>}
      >
        <FirstProposalPieSection investmentProposal={investmentProposal} />
      </BoxWithPadding>
      <Spacer />
      <BoxWithPadding
        title={`${t('questions.proposal.forecast')}`}
        titleStyle={{ marginBottom: Spacings.S24 }}
        titleRightComponent={<TextSmall>{typeName.toUpperCase()}</TextSmall>}
      >
        <FinanceChart investmentProposal={investmentProposal} />
      </BoxWithPadding>
      <Spacer />

      <PortfolioTypeBox
        investmentProposal={investmentProposal}
        currentScreen={ROUTE_QUESTION_FIRST_PROPOSAL}
      />

      {children}
    </View>
  )
}
