import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from '../ui/text/UiText'
import useLayout from '../ui/useLayout'
import { useTranslation } from 'react-i18next'

export interface IconBoxItem {
  title?: string
  text: string
  icon: any
  disabled?: boolean
}

interface Props {
  title?: string
  title2?: string
  note?: string
  text: string
  disabled?: boolean
  icon?: React.StatelessComponent<any>
  iconComponent?: any
  index: number
  amount?: string
  hideBottomBorder?: boolean
  hideRightBorder?: boolean
  columnsDesktop?: number
  itemCount?: number
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<ViewStyle>
}

export default function IconBox({
  title,
  title2,
  text,
  disabled,
  icon: Component,
  iconComponent,
  amount,
  index,
  hideBottomBorder,
  hideRightBorder,
  columnsDesktop,
  itemCount,
  style,
  textStyle,
  note,
}: Props) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const columnsDesktopCount = columnsDesktop || 3
  const borderRight = isSmall
    ? index % 2 === 0
    : index % columnsDesktopCount !== columnsDesktopCount - 1 &&
      hideRightBorder === undefined
  const allItems = itemCount || 6
  const borderBottom =
    (isSmall ? index < allItems - 2 : index < allItems - columnsDesktopCount) &&
    hideBottomBorder === undefined
  const color = disabled ? Colors.textDisabled : Colors.text
  const iconColor = disabled ? Colors.inactiveTint : Colors.primary
  const flexBasis = isSmall ? '50%' : `${100 / columnsDesktopCount}%`
  return (
    <View
      style={[
        { flex: 1, flexDirection: 'row', flexBasis, flexWrap: 'wrap' },
        borderBottom && {
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
        },
      ]}
    >
      <View
        style={[
          {
            flex: 1,
            flexBasis,
            justifyContent: note ? 'space-between' : 'center',
            alignItems: 'center',
            marginTop: Spacings.S16,
            marginBottom: borderBottom ? Spacings.S16 : 0,
            paddingVertical: 0,
            paddingHorizontal: isSmall ? Spacings.S8 : Spacings.S16,
          },
          borderRight && {
            borderRightWidth: 1,
            borderRightColor: Colors.border,
          },
          style,
        ]}
      >
        {Component && <Component color={iconColor} />}
        {iconComponent}

        {title && (
          <TextDefault
            type="h5"
            style={{
              marginTop: Spacings.S8,
              color,
              textAlign: 'center',
            }}
          >
            {t(title)}
          </TextDefault>
        )}

        {title2 && (
          <TextDefault
            type="t3"
            style={{
              marginBottom: Spacings.S4,
              textAlign: 'center',
              color: Colors.gray80,
            }}
          >
            {t(title2)}
          </TextDefault>
        )}
        {amount && (
          <TextDefault
            type="h3"
            style={{
              fontSize: 32,
              lineHeight: 48,
              letterSpacing: -1,
              color,
            }}
          >
            {amount}
          </TextDefault>
        )}

        <TextDefault
          type="t3"
          style={[
            {
              marginTop: 2,
              marginBottom: Spacings.S4,
              textAlign: 'center',
              color: Colors.textSecondary,
            },
            textStyle,
          ]}
        >
          {t(text)}
        </TextDefault>
        {note && (
          <TextDefault
            type="t3"
            style={{
              fontSize: 9,
              lineHeight: 18,
              letterSpacing: 0.6,
              color: Colors.warning,
              marginTop: 10,
            }}
          >
            {t(note)}
          </TextDefault>
        )}
      </View>
    </View>
  )
}
