import React, { useState } from 'react'
import ReportOrderStore from '@taaleri/core/src/stores/ReportOrderStore'
import {
  ReportOrder,
  REPORT_DELIVERY_OPTIONS,
  REPORT_DELIVERY_WEEKDAYS,
  REPORT_DELIVERY_MONTH_DAYS,
  REPORT_DELIVERY_MONTHS,
  REPORT_FILE_TYPES,
  DELIVERY_OPTIONS_WITH_DELAY,
} from '@taaleri/core/src/models/ReportOrders'
import { saveReportOrder } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { Paragraph } from '../../ui/text/Paragraph'
import { Formik, FieldArray } from 'formik'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import UiForm from '../../ui/form/UiForm'
import AllFormErrors from '../../ui/form/AllFormErrors'
import UiButton from '../../ui/UiButton'
import { Dropdown } from '../../ui/form/Dropdown'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import { SubtitleSectionNoPadding } from '../../ui/text/SubtitleSection'

import AddRow from '../../ui/form/AddRow'
import { reportOrderNew } from './reportOrderNew'
import { observer } from 'mobx-react'
import Title from '../../ui/text/Title'
import ErrorMessage from '../../ui/form/ErrorMessage'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import * as Yup from 'yup'
import { isWeb } from '../../constants/Platforms'
import useLayout from '../../ui/useLayout'
import FlexRow from '../../ui/FlexRow'
import UiTouchable from '../../ui/UiTouchable'
import { IconCloseCircle, IconInfo } from '../../ui/Icons'
import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import UiListItemSwitchText from '../../ui/form/UiListItemSwitchText'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import { TextDefault } from '../../ui/text/UiText'
import Fonts from '@taaleri/core/src/constants/Fonts'
import { WarningContainer } from '../../ui/WarningContainer'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import InfoButton from '../../ui/InfoButton'
import Colors from '@taaleri/core/src/constants/Colors'

const emailProperty = 'email'

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    receivers: Yup.array().of(
      Yup.object({
        email: Yup.string()
          .email(`${t(ErrorMessages.incorrectEmail)}`)
          .required(`${t(ErrorMessages.missingEmail)}`),
      }).test('unique', function (receivers) {
        if (!receivers || !receivers[emailProperty]) {
          return true
        }
        const options = [...this.parent]
        const currentIndex = options.indexOf(receivers)

        const subOptions = options.slice(0, currentIndex)
        if (
          subOptions.some(
            (option) => option[emailProperty] === receivers[emailProperty]
          )
        ) {
          throw this.createError({
            path: `${this.path}.email`,
            message: `${t(ErrorMessages.uniqueEmail)}`,
          })
        }

        return true
      })
    ),
    deliveryDay: Yup.number()
      .nullable()
      .when('delivery', {
        is: (value: string) =>
          value === 'Weekly' ||
          value === 'Monthly' ||
          value === 'YearlySpecificDay',
        then: Yup.number()
          .required(`${t(ErrorMessages.required)}`)
          .nullable(),
      })
      .when('delivery', {
        is: (value: string) => value === 'Weekly',
        then: Yup.number()
          .required(`${t(ErrorMessages.required)}`)
          .moreThan(0, `${t(ErrorMessages.required)}`)
          .lessThan(8, `${t(ErrorMessages.required)}`)
          .nullable(),
      })
      .when('delivery', {
        is: (value: string) => value === 'YearlySpecificDay',
        then: Yup.number()
          .required(`${t(ErrorMessages.required)}`)
          .moreThan(0, `${t(ErrorMessages.required)}`)
          .nullable(),
      }),
    deliveryMonth: Yup.string()
      .nullable()
      .when('delivery', {
        is: (value: string) => value === 'YearlySpecificDay',
        then: Yup.string()
          .required(`${t(ErrorMessages.required)}`)
          .nullable(),
      }),
  })

interface Props {
  navigateToReports: () => void
}

function ReportOrderDeliveryContent({ navigateToReports }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)
  const [focusIndex, setFocusIndex] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const { isNonSmall } = useLayout()

  return (
    <Formik
      initialValues={ReportOrderStore.reportOrder || reportOrderNew()}
      validationSchema={validationSchema(t)}
      onSubmit={async (values: ReportOrder) => {
        setLoading(true)
        const { success } = await saveReportOrder(AppStore.customerId, values)
        Analytics.event(
          'ReportOrders',
          values.id ? 'Update report order' : 'Create report order'
        )
        if (success) {
          navigateToReports()
        } else {
          setError(true)
        }
      }}
    >
      {({ values, errors, touched, handleSubmit }) => (
        <UiForm>
          <View>
            <Title>{t('reportOrder.send-information')}</Title>
            {ReportOrderStore.reportPackage === 'Bookkeeper' && (
              <View>
                <View style={{ marginBottom: Spacings.S16 }}>
                  <SubtitleSectionNoPadding>
                    {t('file-format')}
                  </SubtitleSectionNoPadding>
                  <CheckListSingleForm
                    padding={false}
                    name="format"
                    options={REPORT_FILE_TYPES}
                  />
                </View>
                {values.format === 'Pdf' && (
                  <UiListItemSwitchText
                    name="level"
                    title={t('reportOrder.by-portfolio')}
                    yesValue="ByPortfolio"
                    noValue="Aggregated"
                    topDivider={false}
                  />
                )}
              </View>
            )}
            <SubtitleSectionNoPadding>
              {t('reportOrder.recipients')}
            </SubtitleSectionNoPadding>
            <Paragraph>{t('reportOrder.send-reports-recipients')}</Paragraph>

            <FieldArray name="receivers">
              {({ push, remove }) => {
                return (
                  <>
                    <View>
                      {values.receivers.map((_, index) => (
                        <FlexRow
                          key={index}
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <UiTextInputForm
                              type="email"
                              name={`receivers[${index}].email`}
                              placeholder={`${t(
                                'reportOrder.recipient-email'
                              )}`}
                              focus={focusIndex > -1 && focusIndex === index}
                              onBlur={() => {
                                setFocusIndex(-1)
                              }}
                            />
                          </View>

                          {index > 0 && (
                            <UiTouchable
                              onPress={() => {
                                remove(index)
                              }}
                            >
                              <IconCloseCircle
                                style={{
                                  marginTop: 16,
                                  marginLeft: 14,
                                  marginBottom: Spacings.S24,
                                }}
                              />
                            </UiTouchable>
                          )}
                        </FlexRow>
                      ))}
                    </View>
                    <AddRow
                      title={t('reportOrder.add-recipient')}
                      onPress={() => {
                        push({ email: '', token: '' })
                        setTimeout(() => {
                          setFocusIndex(values.receivers.length)
                        }, 100)
                      }}
                    />
                  </>
                )
              }}
            </FieldArray>

            <View>
              <SubtitleSectionNoPadding>
                {t('reportOrder.send-frequence')}
              </SubtitleSectionNoPadding>
              <Paragraph>{t('reportOrder.how-often-reports-send')}</Paragraph>

              <Dropdown
                name="delivery"
                options={REPORT_DELIVERY_OPTIONS.map((option) => ({
                  description: option.description,
                  value: option.value,
                  label: t(option.label),
                }))}
              />
              {values.delivery === 'Weekly' && (
                <View>
                  <SubtitleSectionNoPadding>
                    {t('reportOrder.weekday')}
                  </SubtitleSectionNoPadding>
                  <Dropdown
                    name="deliveryDay"
                    options={REPORT_DELIVERY_WEEKDAYS.map((day) => ({
                      value: day.value,
                      label: t(day.label),
                    }))}
                  />
                </View>
              )}
              {values.delivery === 'Monthly' && (
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <SubtitleSectionNoPadding>
                      {t('reportOrder.send-day')}
                    </SubtitleSectionNoPadding>
                    <InfoButton
                      style={{
                        alignSelf: 'center',
                        paddingLeft: 15,
                        paddingTop: 14,
                      }}
                      infoElement={
                        <IconInfo
                          color={Colors.gray40}
                          width={16}
                          height={16}
                        />
                      }
                    >
                      <TextDefault style={{ marginBottom: Spacings.S24 }}>
                        {t('reportOrder.send-day-info')}
                      </TextDefault>
                    </InfoButton>
                  </View>
                  <Dropdown
                    name="deliveryDay"
                    options={REPORT_DELIVERY_MONTH_DAYS.map((day) => ({
                      value: day.value,
                      label: t(day.label, { day: day.value }),
                    }))}
                  />
                </View>
              )}
              {DELIVERY_OPTIONS_WITH_DELAY.includes(values.delivery) && (
                <>
                  <UiListItemSwitch
                    name="addDelay"
                    title={t('reportOrder.add-delay')}
                    topDivider={false}
                    containerStyle={{
                      backgroundColor: 'transparent',
                    }}
                    textStyle={{ fontFamily: Fonts.avenirNextBold }}
                  />
                  {values.addDelay && (
                    <WarningContainer>
                      <TextDefault>
                        {t('reportOrder.send-delay-text')}
                      </TextDefault>
                    </WarningContainer>
                  )}
                </>
              )}

              {values.delivery === 'YearlySpecificDay' && (
                <View>
                  <SubtitleSectionNoPadding>
                    {t('form.month')}
                  </SubtitleSectionNoPadding>
                  <Dropdown
                    name="deliveryMonth"
                    options={REPORT_DELIVERY_MONTHS.map((day) => ({
                      value: day.value,
                      label: t(day.label),
                    }))}
                  />
                  <SubtitleSectionNoPadding>
                    {t('form.day')}
                  </SubtitleSectionNoPadding>
                  <Dropdown
                    name="deliveryDay"
                    options={REPORT_DELIVERY_MONTH_DAYS.map((day) => ({
                      value: day.value,
                      label: t(day.label, { day: day.value }),
                    })).slice(1)}
                  />
                </View>
              )}
            </View>

            <SubtitleSectionNoPadding>
              {t('reportOrder.accompany-message')}
            </SubtitleSectionNoPadding>
            <Paragraph>{t('reportOrder.accompany-message-text')}</Paragraph>

            <UiTextInputForm
              name="message"
              type="name"
              hideCheckCircle={true}
              multiline={true}
              numberOfLines={5}
              height={150}
            />
          </View>

          {error && <ErrorMessage message={ErrorMessages.unexpectedError} />}

          {!loading && <AllFormErrors errors={errors} touched={touched} />}

          <UiButton
            title={t('button.save')}
            onPress={isWeb ? () => '' : handleSubmit}
            loading={loading}
            containerStyle={{
              marginTop: Spacings.S24,
              marginBottom: Spacings.S24,
              alignSelf: isNonSmall ? 'flex-end' : 'stretch',
            }}
          />
        </UiForm>
      )}
    </Formik>
  )
}

export default observer(ReportOrderDeliveryContent)
