import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import Faq from '@taaleri/core/src/models/Faq'
import UiTouchable from '../../ui/UiTouchable'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextMedium } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import { IconChevronDown, IconChevronUp } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'
export interface FaqListProps {
  items: Faq[]
}

function FaqItem({
  question,
  answer,
  index,
  itemsCount,
}: Faq & { index: number; itemsCount: number }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const { isNonSmall } = useLayout()
  const formattedAnwer = t(answer).replace(/\r\r/g, '\n')

  return (
    <UiTouchable
      onPress={() => {
        if (!open) {
          Analytics.event('Faq', question)
        }
        setOpen(!open)
      }}
      style={[
        styles.container,
        open && styles.containerOpen,
        index === 0 && styles.containerFirst,
        index === itemsCount - 1 && styles.containerLast,
      ]}
    >
      <View
        style={[
          styles.questionContainer,
          isNonSmall && styles.questionContainerLarge,
        ]}
      >
        <TextMedium style={[styles.question]}>{t(question)}</TextMedium>
        {!open && <IconChevronDown />}
        {open && <IconChevronUp />}
      </View>
      <TextMedium
        style={[
          styles.answer,
          open && styles.answerOpen,
          isNonSmall && styles.answerLarge,
        ]}
      >
        {formattedAnwer}
      </TextMedium>
    </UiTouchable>
  )
}

export default function FaqList({ items }: FaqListProps) {
  return (
    <>
      {items.map((item, index) => (
        <FaqItem
          {...item}
          key={item.question}
          index={index}
          itemsCount={items.length}
        />
      ))}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    elevation: 2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    backgroundColor: Colors.white,
  },
  containerOpen: {
    marginBottom: 20,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  containerFirst: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  containerLast: {
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  questionContainer: {
    flexDirection: 'row',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  questionContainerLarge: {
    padding: 24,
  },

  question: {
    paddingRight: 10,
    flex: 1,
  },
  answer: {
    overflow: 'hidden',
    height: 0,
    paddingHorizontal: 20,
    fontSize: 14,
    lineHeight: 22,
  },
  answerLarge: {
    fontSize: 16,
    lineHeight: 24,
  },
  answerOpen: {
    padding: 20,
    height: 'auto',
    backgroundColor: Colors.backgroundPale,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    elevation: 2,
  },
})
