const text = '#473729'
const gray10 = 'rgba(51,50,36,0.1)' // #EBEBEA;
const gray20 = 'rgba(74,59,45,0.2)' // #DBD8D5;
const gray30 = 'rgba(66,60,48,0.3)' // #C7C5C2
const gray40 = 'rgba(82,73,65,0.4)'
const gray50 = 'rgba(74,62,51,0.5)' // #A49E99
const gray70 = 'rgba(61,52,45,0.7)' // #78716C
const gray80 = 'rgba(46,39,34,0.8)' // #58524E
const WarningRed = '#FF4411'
const AttentionOrange = '#FFAA4D'
const NeonGreen = '#31DCC0'
const CtaGreen = '#2BC9AF'
const CtaGreenLight = 'rgba(43, 201, 175, 0.35)'
const TextGreen = '#00C2A1'
const chartLineGrey = '#E3E1DF'
const iconBlue = 'rgba(43, 201, 175, 0.2)'
const backgroundBlue = 'rgba(174, 205, 200, 0.15)'
const brightGreen = '#00EB64'
const black = 'black'
const backgroundLightBlue = 'rgba(214, 230, 227, 0.2)'
const borderLightBlue = '#DBD8D5'

export default {
  text,
  textHover: gray70,
  textPrimary: TextGreen,
  textPrimaryHover: NeonGreen,
  textSecondary: gray70,
  textDisabled: gray50,
  background: '#F9F9F8',
  backgroundGray: '#F7F7F7',
  backgroundGreen: '#AECDC8',
  backgroundPale: '#F9F9F8',
  backdrop: '#04040F',
  shadow: 'rgba(19,26,45,1)',
  border: gray10,
  chartLineGrey,
  gray80,
  gray70,
  gray50,
  gray40,
  gray30,
  gray20,
  gray10,
  white: '#FFFFFF',
  white80: 'rgba(255, 255, 255, 0.8)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white40: 'rgba(255,255,255,0.4)',
  primary: CtaGreen,
  primaryLight: CtaGreenLight,
  greenLight: '#E8EFEE',
  error: WarningRed,
  warning: AttentionOrange,
  activeTint: text,
  inactiveTint: '#A49E99',
  imageBackground: gray20,
  backgroundIcon: iconBlue,
  backgroundBlue,
  aktiaGreen: brightGreen,
  black,
  backgroundLightBlue,
  borderLightBlue,
}

export function getChangeColor(value?: number) {
  if (!value || Math.abs(value) < 0.01) {
    return text
  } else if (value < 0) {
    return WarningRed
  }
  return TextGreen
}
