import React from 'react'
import { View } from 'react-native'
import { TextDefault, TextWithBorder } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import ImpactFactsCard from './ImpactFactsCard'
import { PortfolioDistributions } from '@taaleri/core/src/models/investmentProposal'
import UiButton from '../../ui/UiButton'
import { ROUTE_IMPACT_COMPARISON } from './ImpactRoutes'
import useNavigation from '../../navigation/useNavigation'
import useLayout from '../../ui/useLayout'
import { useTranslation } from 'react-i18next'

interface Props {
  impactStrategy: PortfolioDistributions
  co2Compensation: number
}

export default function ImpactAsNumbers({
  impactStrategy,
  co2Compensation,
}: Props) {
  const { navigateApp } = useNavigation()
  const { isWide } = useLayout()
  const { t } = useTranslation()
  return (
    <View style={{ flexDirection: isWide ? 'row' : 'column' }}>
      <View style={{ flex: 1, margin: isWide ? Spacings.S32 : 0 }}>
        <TextWithBorder>{t('impact.impact-as-numbers-title')}</TextWithBorder>

        <TextDefault type="h1">
          {t('impact.impact-as-numbers-difference')}
        </TextDefault>
        <TextDefault
          type="t1"
          style={{ marginTop: Spacings.S16, marginBottom: Spacings.S32 }}
        >
          {t('impact.impact-as-numbers-description')}
        </TextDefault>
        {isWide && (
          <UiButton
            type="secondary"
            title={t('impactIntro.infobox3.compareportfolios')}
            onPress={() => navigateApp(ROUTE_IMPACT_COMPARISON)}
            containerStyle={{
              maxWidth: 226,
            }}
          />
        )}
      </View>
      <View
        style={{
          marginVertical: 'auto',
          paddingHorizontal: isWide ? Spacings.S32 : 0,
          flex: 1,
        }}
      >
        <ImpactFactsCard
          title={t('impact.impact-as-numbers-title')}
          portfolioDistribution={impactStrategy}
          // sdgCount={impactStrategy.sustainableDevelopmentGoals.length}
          // User hardcoded sdgCount, until we get real amount from backend
          sdgCount={6}
          co2Compensation={co2Compensation}
          isImpact={true}
          showComparisonButton={!isWide}
          columns={2}
          mode={'impact'}
        />
      </View>
    </View>
  )
}
