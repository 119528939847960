import { YieldHistory, YieldHistoryResponse } from '../../models/YieldHistory'
import { formatDateDash } from '../../utils/format'

export function toYieldHistory(
  yieldHistoryResponse: YieldHistoryResponse
): YieldHistory {
  return {
    beginDate: yieldHistoryResponse.beginDate
      ? yieldHistoryResponse.beginDate
      : '1970-01-01T00:00:00',
    endDate: yieldHistoryResponse.endDate
      ? yieldHistoryResponse.endDate
      : formatDateDash(new Date()) + 'T00:00:00',
    twrChange: {
      percentage: yieldHistoryResponse.twrChangeValue,
      date: yieldHistoryResponse.twrChangeDate,
    },
    timeSerieItems: yieldHistoryResponse.twrTimeSerie
      ? yieldHistoryResponse.twrTimeSerie
      : [],
  }
}
