import React from 'react'
import ContentContainer from 'ui/container/ContentContainer'
import ChangeAccountNumberForm from '@taaleri/components/src/screens/profile/ChangeAccountNumberForm'

export default function ChangeAccountNumberScreen() {
  return (
    <ContentContainer leadingTitle={true} noPadding={true}>
      <ChangeAccountNumberForm />
    </ContentContainer>
  )
}
