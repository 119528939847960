import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_REGISTER,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import Title from '@taaleri/components/src/ui/text/Title'
import { Registration } from '@taaleri/core/src/models/Identity'
import { authentication } from '@taaleri/core/src/services/authentication'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import React from 'react'
import { useHistory } from 'react-router-dom'
import QuestionContainer from 'screens/questions/QuestionContainer'
import RegisterForm from './../signin/RegisterForm'
import { useTranslation } from 'react-i18next'

export default function RegisterScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const onSuccess = async (values: Registration) => {
    QuestionStore.email = values.email
  }

  return (
    <>
      <QuestionContainer>
        <Title>{t('app.questions.onboardingregister.createusername')}</Title>
        <RegisterForm
          isOnboarding={true}
          toNextRoute={() =>
            history.push(nextOnboardingRoute(ROUTE_ONBOARDING_REGISTER))
          }
          onSuccess={onSuccess}
          buttonTitle={t('button.next')}
          apiCall={authentication.doRegistration}
          analyticsCategory="Register"
          analyticsAction="Register"
          emailDisabled={false}
        />
      </QuestionContainer>
    </>
  )
}
