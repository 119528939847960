import ProfitFilter from '@taaleri/components/src/screens/reportprofit/ProfitFilter'
import ProfitList from '@taaleri/components/src/screens/reportprofit/ProfitList'
import TotalProfit from '@taaleri/components/src/screens/reportprofit/TotalProfit'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { getTransactionToken } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import GradientContainer from 'ui/GradientContainer'

function ProfitScreen() {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    AppStore.currentLanguage
  )
  async function getProfitReport() {
    if (ProfitStore.skipFetching) {
      ProfitStore.skipFetching = false
    } else {
      ProfitStore.initializeDateRange()
      ProfitStore.initializeFilters()
      await ProfitStore.fetchProfitReport(AppStore.customerId)
    }
    ProfitStore.token = ''
    ProfitStore.loadingToken = true
    const result = await getTransactionToken(AppStore.customerId)
    ProfitStore.loadingToken = false
    if (result.response && result.success) {
      ProfitStore.token = result.response
    }
  }

  useEffect(() => {
    if (currentLanguage !== AppStore.currentLanguage) {
      setCurrentLanguage(AppStore.currentLanguage)
      getProfitReport()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  useEffect(() => {
    getProfitReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.customerId])

  return (
    <GradientContainer width={1280}>
      <>
        <ProfitFilter />
        {ProfitStore.fetchingError ? (
          <ErrorWithRetry onRetry={getProfitReport} />
        ) : ProfitStore.loadingProfitReport || AppStore.loading ? (
          <LoadingIndicator style={{ marginTop: Spacings.S64 }} type="inline" />
        ) : (
          <>
            <TotalProfit />
            <ProfitList />
          </>
        )}
      </>
    </GradientContainer>
  )
}

export default observer(ProfitScreen)
