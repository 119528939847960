import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { TFunction } from 'i18next'
import * as Yup from 'yup'

export const getRegisterSchema = (terms: boolean, t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(`${t(ErrorMessages.incorrectEmail)}`)
      .required(`${t(ErrorMessages.missingEmail)}`),
    password: Yup.string()
      .min(10, `${t(ErrorMessages.passwordAtLeastNChars)}`)
      .required(`${t(ErrorMessages.missingPassword)}`),
    termsAccepted: Yup.boolean().when('email', {
      is: () => terms,
      then: Yup.boolean()
        .required(`${t(ErrorMessages.termsUnaccepted)}`)
        .oneOf([true], `${t(ErrorMessages.termsUnaccepted)}`),
    }),
    privacyAccepted: Yup.boolean().when('email', {
      is: () => terms,
      then: Yup.boolean()
        .required(`${t(ErrorMessages.privacyAccepted)}`)
        .oneOf([true], `${t(ErrorMessages.privacyAccepted)}`),
    }),
    infoCorrect: Yup.boolean().when('email', {
      is: () => terms,
      then: Yup.boolean()
        .required(`${t(ErrorMessages.infoVerified)}`)
        .oneOf([true], `${t(ErrorMessages.infoVerified)}`),
    }),
    primaryReporting: Yup.boolean().when('email', {
      is: () => terms,
      then: Yup.boolean()
        .required(`${t(ErrorMessages.primaryReportingVerified)}`)
        .oneOf([true], `${t(ErrorMessages.primaryReportingVerified)}`),
    }),
  })
}
