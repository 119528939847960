import React from 'react'
import { PaddingHorizontal } from '@taaleri/components/src/ui/PaddingHorizontal'
import RecurringOrderList from '@taaleri/components/src/screens/recurringorders/RecurringOrderList'
import { TitleLarge } from '@taaleri/components/src/ui/text/Title'
import { useTranslation } from 'react-i18next'

export default function RecurringOrderListScreen() {
  const { t } = useTranslation()
  return (
    <>
      <PaddingHorizontal>
        <TitleLarge>{t('questions.recurring-month-saving')}</TitleLarge>
        <RecurringOrderList />
      </PaddingHorizontal>
    </>
  )
}
