import React from 'react'
import { View } from 'react-native'
import Box, { BoxWithPadding } from '../../ui/box/Box'
import useLayout from '../../ui/useLayout'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'

export interface SumBoxItem {
  value: string
  color: string
  title: string
  subtitle?: any
  hidden?: boolean
  invisible?: boolean
}

interface Props {
  items: SumBoxItem[]
}

export function SumBoxes({ items }: Props) {
  const { isWide } = useLayout()
  if (isWide) {
    return (
      <View style={{ alignItems: 'center', marginTop: Spacings.S24 }}>
        <View style={{ maxWidth: 1200, width: '100%' }}>
          <FlexRow
            style={{
              alignItems: 'center',
              flexWrap: 'wrap',
              marginRight: -Spacings.S20,
            }}
          >
            {items.map(({ title, value, color, invisible, subtitle }) => {
              return (
                <BoxWithPadding
                  style={{
                    flex: 1,
                    marginRight: Spacings.S20,
                    marginBottom: Spacings.S20,
                    minWidth: '23%',
                    opacity: invisible ? 0 : 1,
                  }}
                  key={title}
                >
                  <TextDefault
                    type="t2"
                    style={{ color: Colors.textSecondary }}
                  >
                    {title}
                  </TextDefault>
                  <TextDefault type="h3" style={{ color }}>
                    {value}
                  </TextDefault>
                  {subtitle && subtitle}
                </BoxWithPadding>
              )
            })}
          </FlexRow>
        </View>
      </View>
    )
  }

  return (
    <Box style={{ marginTop: Spacings.S16 }}>
      {items.map(({ title, value, color, hidden }, index) => {
        if (hidden) {
          return null
        }
        const isFirst = index === 0
        return (
          <FlexRow
            key={title}
            style={{
              justifyContent: 'space-between',
              paddingVertical: Spacings.S16,
              borderTopColor: Colors.border,
              borderTopWidth: isFirst ? 0 : 1,
              paddingHorizontal: Spacings.S16,
            }}
          >
            <TextDefault type="t2" style={{ color: Colors.textSecondary }}>
              {title}
            </TextDefault>
            <TextDefault type="h5" style={{ color, textAlign: 'right' }}>
              {value}
            </TextDefault>
          </FlexRow>
        )
      })}
    </Box>
  )
}
