import React from 'react'
import { Fund } from '@taaleri/core/src/models/Fund'
import UiTouchable from '../../ui/UiTouchable'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import { ROUTE_FUNDS_DETAIL } from './FundsRoutes'
import { isWeb } from '../../constants/Platforms'
import Box from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View, ImageBackground, StyleSheet } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import { Padding } from '../../ui/Padding'
import CardHover from '../../ui/CardHover'
import { getFundImageSource } from '@taaleri/core/src/constants/AssetClass'
import FundReadMoreRow from './FundReadMoreRow'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { useTranslation } from 'react-i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

export function FundCard({ fund }: { fund: Fund }) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const getDescription = (): string =>
    (AppStore.currentLanguage === fiKey
      ? fund.shortDescription
      : fund.shortDescriptionSv) ?? ''

  const getName = (): string =>
    (AppStore.currentLanguage === fiKey ? fund.name : fund.nameSv) ?? ''
  return (
    <CardHover
      style={{
        flex: 1,
        marginBottom: isWeb ? 0 : Spacings.S24,
        display: 'flex',
        alignSelf: 'stretch',
      }}
    >
      <Box
        key={fund.ticker}
        style={{ flex: 1, borderRadius: BorderRadius.default }}
      >
        <UiTouchable
          onPress={() => {
            if (isWeb) {
              navigate(routePathApp(ROUTE_FUNDS_DETAIL + '/' + fund.ticker))
            } else {
              navigate(ROUTE_FUNDS_DETAIL, { id: fund.ticker })
            }
          }}
          style={{ justifyContent: 'space-between', flex: 1 }}
        >
          <View style={{ flex: 8 }}>
            <View
              style={[
                { overflow: 'hidden', height: '100%', flex: 1 },
                styles.borderRadius,
              ]}
            >
              <ImageBackground
                source={getFundImageSource(fund)}
                style={styles.backgroundImage}
                imageStyle={[
                  styles.borderRadius,
                  { backgroundColor: Colors.imageBackground },
                ]}
                resizeMode="cover"
              />
            </View>
            <Padding>
              <TextDefault type="t5">{t('invest.funds-popular')}</TextDefault>
              <TextDefault
                type="h5"
                style={[{ marginTop: Spacings.S4, marginBottom: Spacings.S2 }]}
              >                
                {getName()}
              </TextDefault>
              <TextMedium
                numberOfLines={2}
                style={{ color: Colors.textSecondary, lineHeight: 22 }}
              >                
                {getDescription()}
              </TextMedium>
            </Padding>
          </View>
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: Colors.border,
              justifyContent: 'center',
              height: 56,
            }}
          >
            <Padding>
              <FundReadMoreRow fund={fund} />
            </Padding>
          </View>
        </UiTouchable>
      </Box>
    </CardHover>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    height: 168,
  },
  borderRadius: {
    borderTopLeftRadius: BorderRadius.default,
    borderTopRightRadius: BorderRadius.default,
  },
})
