import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { cancelFundOrder } from '@taaleri/core/src/services/funds'
import { observer } from 'mobx-react'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { useTranslation } from 'react-i18next'

const RecurringOrderCancelContent = () => {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)

  useEffect(() => {
    async function cancelRecurringOrder() {
      setError(false)
      AppStore.loadingFund = true
      const { success, response } = await cancelFundOrder(
        AppStore.recurringOrderId ?? ''
      )

      if (!success) {
        setError(true)
      }
      if (response && response.smsConfirmationId) {
        AppStore.smsConfirmationId = response.smsConfirmationId
        setError(false)
      }
      AppStore.loadingFund = false
    }

    cancelRecurringOrder()
  }, [retry])

  if (error) {
    return <ErrorWithRetry onRetry={() => setRetry(true)} />
  }

  if (AppStore.loading) {
    return <LoadingIndicator />
  }

  return (
    <View>
      <Title>
        {t('questions.recurring-order-cancel-title')}
      </Title>
      <Paragraph>
        {t('questions.recurring-order-cancel-text')}
      </Paragraph>
    </View>
  )
}

export default observer(RecurringOrderCancelContent)
