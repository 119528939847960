import React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import { ColorFormattedPercentage } from '../../ui/text/ColorFormattedNumber'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import { TextMedium } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import { Fund } from '@taaleri/core/src/models/Fund'
import { IconArrowDownSlim, IconArrowUpSlim } from '../../ui/icons/ArrowIcons'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import { useTranslation } from 'react-i18next'

type ChangeColumnType = 'normal' | 'compact'

export function ChangeColumn({
  title,
  percentage,
}: {
  title: string
  percentage: number
}) {
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
      }}
    >
      <ChangeColumnCompact title={title} percentage={percentage} />
    </View>
  )
}

export function ChangeColumnCompact({
  title,
  percentage,
}: {
  title: string
  percentage: number
}) {
  return (
    <FlexRow style={{ alignItems: 'center' }}>
      <TextMedium
        style={[
          styles.title,
          {
            marginRight: Spacings.S8,
          },
        ]}
      >
        {title.toUpperCase()}
      </TextMedium>

      <ColorFormattedPercentage
        value={percentage}
        style={[styles.percentage, { marginRight: Spacings.S8 }]}
      />
      {percentage > 0 && <IconArrowUpSlim />}
      {percentage < 0 && <IconArrowDownSlim />}
    </FlexRow>
  )
}

export function FundYearColumn({
  fund,
  type,
}: {
  fund: Fund
  type?: ChangeColumnType
}) {
  const { t } = useTranslation()
  const value = fund?.fundPerformanceHistorySummary?.performance1Y
  if (!value) {
    return null
  }
  const title = t('filter.year')
  if (type === 'compact') {
    return <ChangeColumnCompact title={title} percentage={value} />
  }

  return <ChangeColumn title={title} percentage={value} />
}

export function FundSubscriptionDateColumn({
  fund,
  isSmallCard,
}: {
  fund: Fund
  isSmallCard: boolean
}) {
  const { t } = useTranslation()
  const date = fund.fundSubscription
  if (!date || isSmallCard) {
    return null
  }

  if (fund.isAvailableForSubscription) {
    return (
      <FlexRow style={styles.dateRow}>
        <TextMedium
          style={[
            styles.title,
            {
              alignItems: 'center',
              marginRight: Spacings.S4,
              justifyContent: 'center',
            },
          ]}
        >
          {t('invest.fund-investable')}
        </TextMedium>
        <TextMedium
          style={[
            styles.date,
            {
              alignItems: 'center',
              marginRight: Spacings.S4,
              justifyContent: 'center',
              fontSize: FontSize.S12,
            },
          ]}
        >
          {t('immediately').toUpperCase()}
        </TextMedium>
      </FlexRow>
    )
  }
  return (
    <FlexRow style={styles.dateRow}>
      <TextMedium
        style={[
          styles.title,
          {
            alignItems: 'center',
            marginRight: Spacings.S4,
          },
        ]}
      >
        {t('invest.fund-investable')}
      </TextMedium>
      <TextMedium style={styles.date}>{formatDateFull(date)}</TextMedium>
    </FlexRow>
  )
}

export default function FundReadMoreRow({
  fund,
  isSmallCard,
}: {
  fund: Fund
  isSmallCard?: boolean
}) {
  return (
    <FlexRow
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FundSubscriptionDateColumn fund={fund} isSmallCard={isSmallCard ?? false} />
      <FundYearColumn fund={fund} />
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  percentage: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S12,
  },
  title: {
    color: Colors.textSecondary,
    fontSize: FontSize.S10,
    textAlign: 'right',
  },
  date: {
    color: Colors.primary,
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S12,
    paddingRight: Spacings.S8,
  },
  dateRow: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: Colors.border,
  },
})
