import React from 'react'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  IconTravel,
  IconPeopleSm,
  IconBriefcaseEco,
  IconUnitedNations,
} from '../../ui/Icons'
import FlexRow from '../../ui/FlexRow'
import IconBox, { IconBoxItem } from '../../components/IconBox'
import { Paragraph } from '../../ui/text/Paragraph'
import {
  averageCo2Footprint,
  thailandFlights,
} from '../questions/impactCalculator'
import {
  formatNumber,
  formatCo2Kilos,
  formatPercentageFromDecimal,
} from '@taaleri/core/src/utils/format'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { useTranslation } from 'react-i18next'

export default function ImpactCompensationCard({
  co2Compensation,
  isWide,
  sdgCount,
}: {
  co2Compensation: number
  isWide: boolean
  sdgCount: number
}) {
  const { t } = useTranslation()
  const averageCo2FootprintCount = averageCo2Footprint(co2Compensation, false)
  const thailandFlightCount = thailandFlights(co2Compensation)

  const showFootprintAsPercentage = averageCo2FootprintCount < 1

  const items: IconBoxItem[] = [
    {
      title: formatNumber(thailandFlightCount),
      text: `${t('impact.flight-example')}`,
      icon: IconTravel,
    },
    {
      title: showFootprintAsPercentage
        ? formatPercentageFromDecimal(averageCo2FootprintCount, 0)
        : formatNumber(averageCo2FootprintCount),
      text: showFootprintAsPercentage
        ? `${t('impact.compensation-co2')}`
        : `${t('impact.finnish-yearly-carbon')}`,
      icon: IconPeopleSm,
    },
    ...insertIf(isWide, {
      // title: formatNumber(sdgCount),
      // User hardcoded sdgCount, until we get real amount from backend
      title: formatNumber(6),
      text: `${t('impact.social-effect')}`,
      icon: IconUnitedNations,
    }),
  ]

  return (
    <BoxWithPadding
      title={`${t('menu.impact')}`}
      titleStyle={{ marginBottom: Spacings.S16 }}
      titleRightComponent={<IconBriefcaseEco />}
    >
      <Paragraph>
        {t('impact.change-to-reduce-co2')}
      </Paragraph>
      <TextDefault
        type="h2"
        color={Colors.textPrimary}
        style={{
          marginBottom: Spacings.S16,
        }}
      >
        {formatCo2Kilos(co2Compensation)} CO₂
      </TextDefault>
      <Paragraph>{t('impact.corresponds')}</Paragraph>

      <FlexRow
        style={{
          flexWrap: 'wrap',
          borderTopWidth: 1,
          borderTopColor: Colors.border,
        }}
      >
        {items.map((i, index) => (
          <IconBox
            key={i.text}
            {...i}
            index={index}
            columnsDesktop={items.length}
            itemCount={items.length}
          />
        ))}
      </FlexRow>
    </BoxWithPadding>
  )
}
