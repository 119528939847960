import { ASSET_SOURCES, PURPOSE_OPTIONS } from '../constants/Options'

export const DEFAULT_COUNTRY = 'FI'
export const US_COUNTRY = 'US'

export interface TaxationCountry {
  country: string
  tin: string
}

export interface RegulatoryQuestions {
  isAktiaPrimaryBank: string
  accountPurpose: string
  accountPurposeOther: string
  dualCitizenshipCountry: string
  foreignStreetAddressCountry: string
  foreignWorkOrResidencePermitCountry: string
  hasDualCitizenship: boolean
  hasForeignWorkOrResidencePermit: boolean
  hasForeignStreetAddress: boolean
  hasForeignPowerOfAttorney: boolean
  foreignPowerOfAttorneyCountry: string
  isPep: boolean
  incompetent: boolean
  hasUsShareOfEstate: boolean
  pepConnection: string
  pepCountry: string
  pepPosition: string
  sourceOfFunds: string[]
  sourceOfFundsOtherDescription: string
  isUnderUsTaxation: string
  taxationChoise: string
  taxationCountries: TaxationCountry[]
}

export interface IdentifiedCustomerResponse {
  onboardingCustomer: {
    id: string
    firstName: string
    lastName: string
    fullName: string
    identityNumber: string
    emailAddress: string
    phoneNumber: string
    nationality: string
    countryOfBirth: string
    customAddress: {
      street: string
      postalCode: string
      city: string
      country: string
      state: string
    }
    representativeType: string | null
  }
}

export function requlatoryQuestionsDefault(): RegulatoryQuestions {
  return {
    isAktiaPrimaryBank: '',
    incompetent: false,
    isPep: false,
    pepCountry: DEFAULT_COUNTRY,
    pepConnection: '',
    pepPosition: '',
    hasForeignPowerOfAttorney: false,
    foreignPowerOfAttorneyCountry: '',
    hasUsShareOfEstate: false,
    hasDualCitizenship: false,
    hasForeignWorkOrResidencePermit: false,
    dualCitizenshipCountry: '',
    foreignWorkOrResidencePermitCountry: '',
    accountPurpose: String(PURPOSE_OPTIONS[0].value),
    accountPurposeOther: '',
    taxationCountries: [],
    sourceOfFunds: [String(ASSET_SOURCES[0].value)],
    sourceOfFundsOtherDescription: '',
    hasForeignStreetAddress: false,
    foreignStreetAddressCountry: '',
    taxationChoise: '',
    isUnderUsTaxation: '',
  }
}
