import { Identity } from '../models/Identity'
import { WithdrawalResponse, PaymentInfo, PaymentStatus, Withdrawal } from '../models/Payment'
import IPaymentsApi from './IPaymentsApi'
import { sleep } from './mockHelper'

async function setupWithdrawal(
  _: Identity,
  _1: number,
  _2: Withdrawal
): Promise<WithdrawalResponse> {
  await sleep(2000)
  return new Promise<WithdrawalResponse>((resolve) => {
    return resolve({ smsConfirmationId: '' })
  })
}

async function getPaymentMeta(
  _: Identity,
  _1: number,
  _2: number
): Promise<PaymentInfo> {
  await sleep(2000)
  return new Promise<PaymentInfo>((resolve) => {
    return resolve({
      reference: '',
      accountNumber: '',
      barCode: '',
    })
  })
}

async function getPaymentStatus(
  _: Identity,
  _1: number,
  _2: string
): Promise<PaymentStatus> {
  await sleep(2000)
  return new Promise<PaymentStatus>((resolve) => {
    return resolve({
      value: true,
    })
  })
}

export const paymentsApiMock: IPaymentsApi = {
  setupWithdrawal,
  getPaymentMeta,
  getPaymentStatus,
}
