import React from 'react'
import PaymentInfoSection from '@taaleri/components/src/screens/portfolio/PaymentInfoSection'
import { ContentContainerWide } from 'ui/container/ContentContainer'

interface Props {
  isConsultative: boolean
}

export default function ProfilePaymentInfoScreen({ isConsultative }: Props) {
  return (
    <ContentContainerWide>
      <PaymentInfoSection hideSum={true} isConsultative={isConsultative} />
    </ContentContainerWide>
  )
}
