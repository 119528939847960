import { OptionType } from '@taaleri/core/src/models/OptionType'
import React from 'react'
import ListItem from '../ListItem'
import { CheckListError } from './CheckListError'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

interface Props {
  lastBottomDivider?: boolean
  name: string
  options: OptionType[]
  padding?: boolean
}

const CheckListSingle: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { lastBottomDivider, options, name, padding } = props
  const [field, meta, helpers] = useField(name)
  const value = field.value

  const items = options.map((item, index) => {
    const checked = value !== undefined && value === item.value
    return (
      <ListItem
        key={item.value}
        title={`${t(item.label)}`}
        bottomDivider={index === options.length - 1 ? lastBottomDivider : false}
        topDivider={index > 0}
        checkmark={checked}
        checkmarkNotChecked={!checked}
        reserveSpaceForRightCheckmark={true}
        noPadding={padding === true ? undefined : true}
        onPress={() => {
          helpers.setValue(item.value)
        }}
      />
    )
  })
  return (
    <>
      {items}
      <CheckListError error={meta.error} touched={meta.touched} />
    </>
  )
}

export const CheckListSingleForm = CheckListSingle
