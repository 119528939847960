import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import UiModal from '../../ui/modal/UiModal'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import UiForm from '../../ui/form/UiForm'
import { OptionType } from '@taaleri/core/src/models/OptionType'
import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import UiButton from '../../ui/UiButton'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import { TextDefault } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ReportValues } from './DownloadReportButton'
import { useTranslation } from 'react-i18next'

interface Props {
  isVisible: boolean
  setVisible: (visible: boolean) => void
  onSubmit: (values: ReportValues, actions: FormikHelpers<ReportValues>) => void
}

export type ReportFileFormat = 'pdf' | 'excel'

const FILE_FORMAT_OPTIONS: OptionType[] = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Excel', value: 'excel' },
]

const initialValues: ReportValues = {
  fileFormat: 'pdf',
  byPortfolio: false,
  reportType: 'transactions',
  byPurchaselots: false,
  loadBenchmarkIndexData: false,
}

export function DownloadReportModal({
  isVisible,
  setVisible,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  return (
    <UiModal
      isVisible={isVisible}
      onBackButtonPress={() => setVisible(false)}
      useNativeDriver={true}
      onBackdropPress={() => setVisible(false)}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => {
          const isPdf = values.fileFormat === 'pdf'
          return (
            <UiForm>
              <FlexRow style={{ marginBottom: Spacings.S20 }}>
                <TextDefault type="h3">{t('portfolio.load-report')}</TextDefault>
              </FlexRow>

              <BoxWithPaddingMargin title={`${t('portfolio.report-format')}`}>
                <CheckListSingleForm
                  name="fileFormat"
                  options={FILE_FORMAT_OPTIONS}
                  lastBottomDivider={false}
                />
              </BoxWithPaddingMargin>
              {isPdf && (
                <BoxWithPaddingMargin title={`${t('portfolio.report-itemized')}`}>
                  <UiListItemSwitch
                    name="byPortfolio"
                    title={t('portfolio.report-per-portfolio')}
                    topDivider={false}
                    bottomDivider={false}
                  />
                </BoxWithPaddingMargin>
              )}
              <UiButton title={t('portfolio.load-report')} onPress={handleSubmit} />
            </UiForm>
          )
        }}
      </Formik>
      <UiButton
        title={t('button.cancel')}
        type="secondaryplain"
        onPress={() => setVisible(false)}
      />
    </UiModal>
  )
}
