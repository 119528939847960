import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Box from '@taaleri/components/src/ui/box/Box'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { fiKey } from '@taaleri/core/src/i18n'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import AppStore from '@taaleri/core/src/stores/AppStore'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import {
  formatDateWithoutLeadingZeros,
  formatCurrency,
} from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TransactionPdfButton from './TransactionPdfButton'

export function TransactionRow({
  label,
  value = '',
  hideBorder,
}: {
  label: string
  value?: string | number
  hideBorder?: boolean
}) {
  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 2,
        marginHorizontal: 12,
        paddingVertical: 18,
        borderBottomColor: Colors.border,
        borderBottomWidth: hideBorder ? 0 : 1,
      }}
    >
      <TextDefault type="t3" style={{ flex: 1, maxWidth: '50%' }}>
        {label}
      </TextDefault>
      <TextDefault
        type="h6"
        style={{ flex: 1, maxWidth: '50%', textAlign: 'right' }}
      >
        {value}
      </TextDefault>
    </FlexRow>
  )
}

function TransactionPdfRow({ transaction }: { transaction: Transaction }) {
  const { t } = useTranslation()
  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 2,
        marginHorizontal: 12,
        paddingVertical: 18,
      }}
    >
      <TextDefault type="t3" style={{ flex: 1, maxWidth: '50%' }}>
        {t('reportTransaction.reinforcements')}
      </TextDefault>
      {transaction.isDocumentAvailable ? (
        <TransactionPdfButton transaction={transaction} />
      ) : (
        <TextDefault
          type="h6"
          style={{ flex: 1, maxWidth: '50%', textAlign: 'right' }}
        >
          -
        </TextDefault>
      )}
    </FlexRow>
  )
}

function CashTransactionTable(transaction: Transaction) {
  const { t } = useTranslation()

  const getPortfolioName = (): string =>
    (AppStore.currentLanguage === fiKey
      ? transaction?.portfolio?.portfolioNameForCustomer
      : transaction?.portfolio?.portfolioNameForCustomerSv) ?? ''

  const getReasonCode = (): string =>
    (AppStore.currentLanguage === fiKey
      ? transaction.reason
      : transaction.reasonSwedish) ?? ''

  return (
    <>
      <TransactionRow label={t('portfolio.text')} value={getPortfolioName()} />
      <TransactionRow
        label={t('report.term')}
        value={formatDateWithoutLeadingZeros(transaction.paymentDate)}
      />
      <TransactionRow
        label={t('reportTransaction.reason-code')}
        value={getReasonCode()}
      />
      <TransactionRow
        label={t('steps.sum')}
        value={formatCurrency(transaction.amount)}
      />
      <TransactionRow
        label={t('reportTransaction.definition')}
        value={transaction.note}
        hideBorder={true}
      />
    </>
  )
}

function PaidTransactionTable(transaction: Transaction) {
  const { t } = useTranslation()
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.securityNameFinnish
      : transaction.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction?.portfolio?.portfolioNameForCustomer
      : transaction?.portfolio?.portfolioNameForCustomerSv

  const getTransactionTypeName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.transactionTypeName
      : transaction.transactionTypeNameSwedish
  return (
    <>
      <TransactionRow label={t('portfolio.text')} value={getPortfolioName()} />
      <TransactionRow
        label={t('report.event')}
        value={getTransactionTypeName()}
      />
      <TransactionRow label={t('security')} value={getName()} />
      <TransactionRow
        label={t('report.event-date')}
        value={formatDateWithoutLeadingZeros(transaction.transactionDate)}
      />
      <TransactionRow label={t('report.pcs')} value={transaction.amount} />
      <TransactionRow
        label={t('reportTransaction.pcs-price')}
        value={formatCurrency(transaction.price?.reportCurrency?.value || 0)}
      />
      <TransactionRow
        label={t('portfolio.total')}
        value={formatCurrency(transaction.value)}
      />
      <TransactionRow
        label={t('reportBox.expenses')}
        value={formatCurrency(transaction.fees)}
      />
      <TransactionRow
        label={t('report.net')}
        value={formatCurrency(transaction.totalValue)}
      />
      <TransactionPdfRow transaction={transaction} />
    </>
  )
}

function OpenTransactionTable(transaction: Transaction) {
  const { t } = useTranslation()
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.securityNameFinnish
      : transaction.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction?.portfolio?.portfolioNameForCustomer
      : transaction?.portfolio?.portfolioNameForCustomerSv

  const getTransactionTypeName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.transactionTypeName
      : transaction.transactionTypeNameSwedish
  return (
    <>
      <TransactionRow label={t('portfolio.text')} value={getPortfolioName()} />
      <TransactionRow
        label={t('reportTransaction.event-type')}
        value={getTransactionTypeName()}
      />
      <TransactionRow label={t('security')} value={getName()} />
      <TransactionRow
        label={t('report.term')}
        value={formatDateWithoutLeadingZeros(transaction.paymentDate)}
      />
      <TransactionRow
        label={t('reportTransaction.total-value')}
        value={formatCurrency(transaction.totalValue)}
        hideBorder={true}
      />
      <TransactionPdfRow transaction={transaction} />
    </>
  )
}

function TransactionDetailContent() {
  const transaction = TransactionStore.selectedTransaction
  const category = transaction?.transactionSource

  if (!transaction) {
    return null
  }

  return (
    <Box>
      {category === 'Cash' && <CashTransactionTable {...transaction} />}
      {category === 'Paid' && <PaidTransactionTable {...transaction} />}
      {category === 'Open' && <OpenTransactionTable {...transaction} />}
    </Box>
  )
}

export default observer(TransactionDetailContent)
