import { useHistory } from 'react-router-dom'
import { routePathApp } from './routeHelper'

export default function useNavigation() {
  const history = useHistory()

  return {
    navigate: (route: string, params?: any) => history.push(route),
    navigateApp: (routeName: string, params?: any) => {
      history.push(routePathApp(routeName))
    },
    navigateBack: () => history.goBack(),
  }
}
