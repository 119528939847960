import React from 'react'

import { StyleSheet, ImageSourcePropType } from 'react-native'
import AppStore from '@taaleri/core/src/stores/AppStore'
import {
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import { LabelValueWithPadding } from '../../ui/text/LabelValue'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { observer } from 'mobx-react'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import Box from '../../ui/box/Box'
import GlobalStyles from '../../constants/GlobalStyles'
import HeaderImage from './HeaderImage'
import { useFund } from '../funds/useFunds'
import { isWeb } from '../../constants/Platforms'
import {
  getFundImageSource,
  getAssetClassImageById,
} from '@taaleri/core/src/constants/AssetClass'
import { ColorFormattedPercentage } from '../../ui/text/ColorFormattedNumber'
import PropsChildren from '../../components/PropsChildren'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { fundRedemptionDateFormattedByFund } from '@taaleri/core/src/models/Fund'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  imageSource: ImageSourcePropType
}
function DefaultHeader({
  title,
  imageSource,
  children,
}: Props & PropsChildren) {
  return (
    <>
      <HeaderImage imageSource={imageSource} title={title} imageSize="small" />
      <PaddingHorizontal
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box style={styles.box}>{children}</Box>
      </PaddingHorizontal>
    </>
  )
}

function FundWithdrawalHeader() {
  const { t } = useTranslation()
  const { fund } = useFund(AppStore.fundId)
  if (!AppStore.fundId) {
    return null
  }

  if (!fund || AppStore.loadingFund) {
    return <LoadingIndicator />
  }
  const assetClassItem = AppStore.getAssetClassItemByTickerFirst(
    fund.ticker,
    'consultative'
  )

  if (!assetClassItem) {
    return null
  }
  const { marketValueChangePercent, marketValue } = assetClassItem
  const { fundFeeInformation } = fund
  const redemptionFee = fundFeeInformation && fundFeeInformation.redemptionFee

  return (
    <DefaultHeader title={fund.name} imageSource={getFundImageSource(fund)}>
      <LabelValueWithPadding
        label={`${t('netWorth')}`}
        value={formatCurrency(marketValue)}
      />

      {marketValueChangePercent !== undefined &&
        marketValueChangePercent !== 0 && (
          <LabelValueWithPadding
            label={`${t('change')}`}
            valueComponent={() => (
              <ColorFormattedPercentage value={marketValueChangePercent} />
            )}
            containerStyle={GlobalStyles.topBorder}
          />
        )}

      {redemptionFee ? (
        <LabelValueWithPadding
          label={`${t('fund.redemptionfee')}`}
          value={formatFundProfit(redemptionFee)}
          containerStyle={GlobalStyles.topBorder}
        />
      ) : null}

      <LabelValueWithPadding
        label={`${t('withdraw.assingment-will-be-done', {
          date: '',
        }).trimEnd()}`}
        value={fundRedemptionDateFormattedByFund(fund)}
        containerStyle={GlobalStyles.topBorder}
      />
    </DefaultHeader>
  )
}

function DigiWithdrawalHeader() {
  const { t } = useTranslation()
  const image = getAssetClassImageById('A')
  const portfolio = AppStore.portfolioList.find(
    (p) => p.portfolioType === 'discretionary'
  )

  return (
    <DefaultHeader title={`${t('app.your-portfolio')}`} imageSource={image}>
      <LabelValueWithPadding
        label={`${t('netWorth')}`}
        value={formatCurrency(portfolio?.marketValue || 0)}
      />
    </DefaultHeader>
  )
}

const FundWithdrawalHeaderO = observer(FundWithdrawalHeader)

function WithdrawalHeader() {
  if (AppStore.investmentMode === 'withdrawfund') {
    return <FundWithdrawalHeaderO />
  }
  return <DigiWithdrawalHeader />
}

const styles = StyleSheet.create({
  box: {
    marginTop: -29,
    marginHorizontal: Spacings.S24,
    marginBottom: isWeb ? Spacings.S32 : Spacings.S16,
    maxWidth: 448,
    width: '100%',
  },
})

export default observer(WithdrawalHeader)
