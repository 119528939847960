import React from 'react'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import useLayout from '../../ui/useLayout'
import { OwnerShipRowItem } from './OwnerShipRowItem'
import { OwnershipRowStyles } from './OwnershipRow'

interface Props {
  borderBottom?: boolean
  total?: boolean
  topBorderRadius?: boolean
  bottomBorderRadius?: boolean
  color: string
  marketValue: number
  sharePercentage: number
  name: string
}

const styles = OwnershipRowStyles

export function CashRow({
  borderBottom,
  color,
  total,
  bottomBorderRadius,
  topBorderRadius,
  marketValue,
  sharePercentage,
  name,
}: Props) {
  const numberStyles = [styles.text, total && styles.bold, styles.number]

  const { isSmall } = useLayout()

  return (
    <FlexRow
      style={[
        {
          borderLeftColor: color,
          borderLeftWidth: isSmall ? 0 : 4,
        },
        topBorderRadius && { borderTopLeftRadius: BorderRadius.default },
        bottomBorderRadius && {
          borderBottomLeftRadius: BorderRadius.default,
        },
      ]}
    >
      <View />

      {!isSmall && (
        <FlexRow
          style={[
            styles.main,
            styles.mainWide,
            borderBottom && styles.borderBottom,
            { justifyContent: 'space-between' },
          ]}
        >
          <View style={[styles.title]}>
            <TextDefault
              type="h7"
              style={[styles.text, { paddingLeft: Spacings.S20 }]}
            >
              {name}
            </TextDefault>
          </View>
          <OwnerShipRowItem
            value={marketValue}
            style={numberStyles}
            format={formatCurrency}
          />

          <OwnerShipRowItem
            value={sharePercentage}
            style={[numberStyles, { flex: 2 }]}
            format={formatFundProfit}
          />
        </FlexRow>
      )}

      {isSmall && (
        <CashRowMobile
          name={name}
          marketValue={marketValue}
          sharePercentage={sharePercentage}
          borderBottom={borderBottom}
          total={total}
          color={color}
        />
      )}
    </FlexRow>
  )
}

export function CashRowMobile({
  marketValue,
  name,
  sharePercentage,
  borderBottom,
  total,
}: Props) {
  const { isSmall } = useLayout()

  if (!isSmall) {
    return null
  }

  return (
    <FlexRow style={[styles.main, borderBottom && styles.borderBottom]}>
      <View style={{ flex: 80 }}>
        <TextDefault type="t2" style={[styles.text, total && styles.bold]}>
          {name}
        </TextDefault>

        <FlexRow style={{ alignItems: 'center' }}>
          <OwnerShipRowItem
            value={marketValue}
            style={styles.text}
            format={formatCurrency}
            type="h5"
          />
        </FlexRow>
      </View>
      <OwnerShipRowItem
        value={sharePercentage}
        style={[
          styles.percentageSmall,
          total && styles.bold,
          { opacity: sharePercentage === 0 ? 0 : 1 },
        ]}
        format={formatFundProfit}
      />
    </FlexRow>
  )
}
