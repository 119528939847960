import React from 'react'
import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_CASH_PURPOSE } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { Formik } from 'formik'

import QuestionForeignMoneyTransferContent, {
  CashPurpose,
  questionCashPurposeValidationSchema,
  toInitArrayValue,
  toInitValue,
  toSaveValue,
} from '@taaleri/components/src/screens/questions/QuestionCashPurposeContent'
import { useHistory } from 'react-router-dom'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { PurposeOfForeignMoneyTransfers } from '@taaleri/core/src/constants/Options'
import { useTranslation } from 'react-i18next'

function QuestionCashPurposeScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const intendedUseOfCash = toInitArrayValue(QuestionStore.intendedUseOfCash)
  const intendedUseOfCashOther = toInitValue(
    QuestionStore.intendedUseOfCashOther
  )
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <Formik
          initialValues={{
            intendedUseOfCash,
            intendedUseOfCashOther,
          }}
          validationSchema={questionCashPurposeValidationSchema}
          onSubmit={async (values: CashPurpose) => {
            QuestionStore.intendedUseOfCash = toSaveValue(
              values.intendedUseOfCash
            )
            QuestionStore.intendedUseOfCashOther =
              values.intendedUseOfCash.some(
                (p) => p === PurposeOfForeignMoneyTransfers.Other
              )
                ? values.intendedUseOfCashOther
                : ''
            history.push(nextQuestionRoute(ROUTE_QUESTION_CASH_PURPOSE))
          }}
        >
          {({ values }) => {
            return (
              <UiForm>
                <QuestionForeignMoneyTransferContent values={values} />
                <Buttons
                  title={t('button.next')}
                  primaryButtonType="primary"
                />
              </UiForm>
            )
          }}
        </Formik>
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(QuestionCashPurposeScreen)
