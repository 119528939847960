import { ContractPdf, OrderPdf } from './../models/ContractPdf'
import { IContractApi } from './IContractApi'
import { get } from './rest'
import { getConfig } from '../config'
import { Identity } from '../models/Identity'
import AppStore from '../stores/AppStore'

async function contracts(identity: Identity, customerId: number) {
  const response = await get(
    `${getConfig().apiUrl}/Contracts/${customerId}`,
    identity.accessToken
  )

  return response as ContractPdf[]
}

async function contractOrders(identity: Identity, customerId: number) {
  const response = await get(
    `${getConfig().apiUrl}/Contracts/${customerId}/orders?language=${
      AppStore.currentLanguage
    }`,
    identity.accessToken
  )

  return response as OrderPdf[]
}

export const contractApi: IContractApi = {
  contracts,
  contractOrders,
}
