import Spacings from '@taaleri/core/src/constants/Spacings'
import some from 'lodash/some'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { CheckListMultipleStandalone } from '../../ui/form/CheckListMultiple'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { isNarrowDevice } from '@taaleri/core/src/utils/dimensions'
import {
  findQuestionById,
  findTagAnswerById,
  QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
  questionsWithAnswers,
  nextQuestionOrContractRoute,
} from './questions'
import {
  Question,
  Tag,
  TagSelectQuestion,
} from '@taaleri/core/src/models/Question'
import { TitleWithInfo } from '../../ui/text/TitleWithInfo'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Box from '../../ui/box/Box'
import useLayout from '../../ui/useLayout'
import { TextDefault } from '../../ui/text/UiText'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

export interface TagQuestionProps {
  question: TagSelectQuestion
}

function filterOut(
  previousTagAnswers: Tag[],
  tags: Tag[],
  answer: Tag[]
): Tag[] {
  const selectedTagValues = previousTagAnswers
    .filter((it: Tag) => it.selected)
    .map((it: Tag) => it.value)

  const answeredTagValues = answer
    .filter((it: Tag) => it.selected)
    .map((it: Tag) => it.value)

  const forcedTags = answer
    .filter((it: Tag) => it.forced)
    .map((it: Tag) => it.value)

  return tags
    .filter((it: Tag) => selectedTagValues.includes(it.value))
    .map((it: Tag) => ({
      ...it,
      selected: answeredTagValues.includes(it.value) ? true : false,
      forced: forcedTags.includes(it.value) ? true : false,
    }))
}

export function useTagQuestion(
  question: TagSelectQuestion,
  navigate: (path: string) => void
) {
  const { t } = useTranslation()
  const { id, filterTagsAccordingPreviousAnswers } = question

  const [tags, setTags] = useState<Tag[]>([])
  const [noProductKnowledgeSelected, setNoProductKnowledgeSelected] =
    useState<boolean>(false)

  useEffect(() => {
    const filteredTags = filterTagsAccordingPreviousAnswers
      ? filterOut(
          QuestionStore.getAnswer(QUESTION_PRODUCT_KNOWLEDGE_PART_ONE) as Tag[],
          question.tags,
          (QuestionStore.getAnswer(
            QUESTION_PRODUCT_KNOWLEDGE_PART_TWO
          ) as Tag[]) || []
        )
      : (QuestionStore.getAnswer(
          QUESTION_PRODUCT_KNOWLEDGE_PART_ONE
        ) as Tag[]) || question.tags
    setTags(filteredTags)
  }, [question.id])

  function productKnowledgeValidation() {
    const questionOption = findQuestionById(id, t)
    if (
      questionOption &&
      questionOption.id === QUESTION_PRODUCT_KNOWLEDGE_PART_TWO
    ) {
      const questions = questionsWithAnswers(
        QuestionStore.answers,
        t
      ) as Question[]
      const partOneAnswer = findTagAnswerById(
        questions,
        QUESTION_PRODUCT_KNOWLEDGE_PART_ONE
      )
      const someSelected = some(partOneAnswer, (tag: Tag) => tag.selected)
      setNoProductKnowledgeSelected(!someSelected)
    }
  }

  function onAnswer() {
    QuestionStore.setAnswer(question.id, tags)
    const nextRoute = nextQuestionOrContractRoute(
      id,
      AppStore.customerMaybe && AppStore.isDigiDiscretionary,
      AppStore.customerMaybe && AppStore.isConsultativeWithoutContract,
      AppStore.customerMaybe && AppStore.isConsultativeOrOpening,
      QuestionStore.tupasDone && isWeb
    )
    navigate(nextRoute)
  }

  const onValueChange = (tagIds: string[]) => {
    const newTags = tags.map((tag: Tag) => ({
      ...tag,
      selected: tag.forced || tagIds.includes(tag.value),
    }))
    QuestionStore.setAnswer(question.id, tags)
    setTags(newTags)
  }

  const buttonTitle = noProductKnowledgeSelected
    ? 'button.yes-continue'
    : 'button.next'

  useEffect(() => {
    productKnowledgeValidation()
  }, [question.id])

  return {
    tags,
    onValueChange,
    noProductKnowledgeSelected,
    buttonTitle,
    onAnswer,
  }
}

interface Props {
  question: TagSelectQuestion
  tags: Tag[]
  onValueChange: (tagIds: string[]) => void
  noProductKnowledgeSelected: boolean
}

function sortByForced(a: Tag, b: Tag): number {
  return a.forced !== b.forced && a.forced ? -1 : 1
}

function SelectionListMobile(props: any) {
  const { t } = useTranslation()
  const {
    forcedTags,
    forcedHelp,
    id,
    help,
    unforcedTags,
    onValueChange,
    noProductKnowledgeSelected,
  } = props
  return (
    <>
      {forcedTags.length > 0 && (
        <Box
          style={{
            paddingHorizontal: Spacings.S16,
            paddingBottom: Spacings.S16,
            marginBottom: Spacings.S24,
          }}
          title={forcedHelp}
          titleStyle={{ marginTop: Spacings.S16 }}
        >
          <CheckListMultipleStandalone
            name={id}
            options={forcedTags}
            value={forcedTags
              .filter((it: Tag) => it.selected && it.forced)
              .map((it: Tag) => it.value)}
            onChangeText={(tagIds: string[]) => onValueChange(tagIds)}
            disabled={forcedTags
              .filter((it: Tag) => it.forced)
              .map((it: Tag) => it.value)}
            itemStyle={{ paddingHorizontal: 0 }}
          />
        </Box>
      )}
      <Box
        style={{ paddingHorizontal: Spacings.S16, paddingBottom: Spacings.S16 }}
        title={`${t(help)}`}
        titleStyle={{ marginTop: Spacings.S16 }}
      >
        <CheckListMultipleStandalone
          name={id}
          options={unforcedTags}
          value={unforcedTags
            .filter((it: Tag) => it.selected)
            .map((it: Tag) => it.value)}
          onChangeText={(tagIds: string[]) => onValueChange(tagIds)}
          disabled={unforcedTags
            .filter((it: Tag) => it.forced)
            .map((it: Tag) => it.value)}
          itemStyle={{ paddingHorizontal: 0 }}
        />
        {noProductKnowledgeSelected &&
          id === QUESTION_PRODUCT_KNOWLEDGE_PART_TWO && (
            <Paragraph style={{ marginTop: Spacings.S8 }}>
              {t('questions.no-knowledge-question')}
            </Paragraph>
          )}
      </Box>
    </>
  )
}

function SelectionListDesktop(props: any) {
  const { t } = useTranslation()
  const {
    forcedTags,
    forcedHelp,
    id,
    help,
    unforcedTags,
    onValueChange,
    noProductKnowledgeSelected,
  } = props
  return (
    <>
      {forcedHelp && <Paragraph>{forcedHelp}</Paragraph>}
      {forcedTags.length > 0 && (
        <View
          style={{
            marginHorizontal: isNarrowDevice() ? -Spacings.S16 : -Spacings.S24,
            marginBottom: Spacings.S40,
          }}
        >
          <CheckListMultipleStandalone
            name={id}
            options={forcedTags}
            value={forcedTags
              .filter((it: Tag) => it.selected && it.forced)
              .map((it: Tag) => it.value)}
            onChangeText={(tagIds: string[]) => onValueChange(tagIds)}
            disabled={forcedTags
              .filter((it: Tag) => it.forced)
              .map((it: Tag) => it.value)}
          />
        </View>
      )}
      {help && (
        <TextDefault type="h3" style={{ marginBottom: Spacings.S24 }}>
          {t(help)}
        </TextDefault>
      )}
      <View
        style={{
          marginHorizontal: isNarrowDevice() ? -Spacings.S16 : -Spacings.S24,
        }}
      >
        <CheckListMultipleStandalone
          name={id}
          options={unforcedTags}
          value={unforcedTags
            .filter((it: Tag) => it.selected)
            .map((it: Tag) => it.value)}
          onChangeText={(tagIds: string[]) => onValueChange(tagIds)}
          disabled={unforcedTags
            .filter((it: Tag) => it.forced)
            .map((it: Tag) => it.value)}
        />
        {noProductKnowledgeSelected &&
          id === QUESTION_PRODUCT_KNOWLEDGE_PART_TWO && (
            <PaddingHorizontal>
              <Paragraph>{t('questions.no-knowledge-question')}</Paragraph>
            </PaddingHorizontal>
          )}
      </View>
    </>
  )
}

function TagSelectQuestionContent(props: Props) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const { question, tags, onValueChange, noProductKnowledgeSelected } = props
  const { id, questionInfo, help, forcedHelp, separeteForcedTags } = question
  const forcedTags = tags.filter((tag) => tag.forced && separeteForcedTags)
  const unforcedTags = tags
    .filter((tag) => !(tag.forced && separeteForcedTags))
    .sort(sortByForced)

  return (
    <>
      {questionInfo ? (
        <TitleWithInfo infoText={t(questionInfo)}>
          {t(question.question)}
        </TitleWithInfo>
      ) : (
        <Title>{t(question.question)}</Title>
      )}

      {isSmall ? (
        <SelectionListMobile
          {...{
            forcedTags,
            forcedHelp: t(forcedHelp ?? ''),
            id,
            help,
            unforcedTags,
            onValueChange,
            noProductKnowledgeSelected,
          }}
        />
      ) : (
        <SelectionListDesktop
          {...{
            forcedTags,
            forcedHelp: t(forcedHelp ?? ''),
            id,
            help,
            unforcedTags,
            onValueChange,
            noProductKnowledgeSelected,
          }}
        />
      )}
    </>
  )
}

export default observer(TagSelectQuestionContent)
