import React, { useState } from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { Formik } from 'formik'
import UiForm from '../../ui/form/UiForm'
import { View } from 'react-native'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import UiButton from '../../ui/UiButton'
import * as Yup from 'yup'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import useNavigation from '../../navigation/useNavigation'
import useLayout from '../../ui/useLayout'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useDiscountCode } from '@taaleri/core/src/services/discountCode'
import { ROUTE_PROFILE_DISCOUNT_CODE_DONE } from './ProfileRoutes'
import ErrorMessage from '../../ui/form/ErrorMessage'
import { isWeb } from '../../constants/Platforms'
import { getDiscountErrorMessage } from '@taaleri/core/src/utils/discountMessages'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface DiscountCode {
  code: string
}

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    code: Yup.string().required(`${t(ErrorMessages.required)}`),
  })

export function DiscountCodeForm() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const { navigateApp } = useNavigation()
  const { isSmall } = useLayout()
  return (
    <Formik
      validationSchema={validationSchema(t)}
      initialValues={{ code: '' }}
      onSubmit={async (values: DiscountCode) => {
        setLoading(true)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { success, response } = await useDiscountCode(
          AppStore.customerId,
          values.code
        )
        if (success && response) {
          if (response.isSuccess) {
            navigateApp(ROUTE_PROFILE_DISCOUNT_CODE_DONE)
          } else {
            setErrorMessage(getDiscountErrorMessage(response, t))
          }
        } else {
          setErrorMessage(`${t('validation.something-went-wrong')}`)
        }
        setLoading(false)
      }}
    >
      {({ handleSubmit }) => (
        <UiForm>
          <FormNextInputAutofocus>
            <View>
              <Title>{t('profile.investmentSection.campaign-code')}</Title>
              <Paragraph>{t('profile.discount-code-usage-info')}</Paragraph>
              <View>
                <UiTextInputFormAutoFocus
                  name="code"
                  label={`${t('onboarding.discount-code')}`}
                  type="name"
                  returnKeyType="done"
                  grayBackground={isSmall}
                />
              </View>
            </View>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <UiButton
              containerStyle={{ marginTop: Spacings.S24 }}
              title={t('button.continue')}
              onPress={isWeb ? () => '' : handleSubmit}
              loading={loading}
            />
          </FormNextInputAutofocus>
        </UiForm>
      )}
    </Formik>
  )
}
