import React from 'react'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import MonthlyContractPaymentContent from '@taaleri/components/src/screens/funds/MonthlyContractPaymentContent'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { ROUTE_RECURRING_ORDERS_LIST } from '@taaleri/components/src/screens/recurringorders/RecurringOrderRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useTranslation } from 'react-i18next'

export default function MonthlyContractPaymentScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <>
      <PaymentStepsFund currentStep={3} recurring={true} />
      <ContentContainerWide>
        <MonthlyContractPaymentContent />
        <Buttons
          title={t('button.payment-set')}
          onPress={async () => {
            await AppStore.fetchOrders()
            navigateApp(ROUTE_RECURRING_ORDERS_LIST)
          }}
        />
      </ContentContainerWide>
    </>
  )
}
