import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import { BorderContainer, BorderContainerProps } from './BorderContainer'

export function WarningContainer({ style, children }: BorderContainerProps) {
  return (
    <BorderContainer style={[{ borderColor: Colors.warning }, style]}>
      {children}
    </BorderContainer>
  )
}
