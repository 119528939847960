import React from 'react'
import ChangePhoneErrorContent from '@taaleri/components/src/screens/profile/ChangePhoneErrorContent'
import DoneScreen from './DoneScreen'

export default function ChangePhoneErrorScreen() {
  return (
    <DoneScreen>
      <ChangePhoneErrorContent />
    </DoneScreen>
  )
}
