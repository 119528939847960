import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { IconMoneyHand } from '../../ui/Icons'
import YesNoModal from '../../ui/modal/YesNoModal'
import { useTranslation } from 'react-i18next'

function PaymentReceivedModal() {
  const { t } = useTranslation()
  useEffect(() => {
    if (AppStore.showPaymentReceivedModal) {
      Analytics.event('Payment', 'Show payment received modal')
    }
  }, [])

  if (!AppStore.showPaymentReceivedModal) {
    return null
  }
  return (
    <YesNoModal
      isVisible={AppStore.showPaymentReceivedModal}
      title={`${t('withdraw.payment-received')}`}
      content={
        AppStore.investmentMode === 'fund'
          ? t('invest.invest-asap')
          : t('invest.invest-in-3-days')
      }
      icon={<IconMoneyHand />}
      onYes={() => {
        AppStore.showPaymentReceivedModal = false
      }}
      yesText={`${t('button.continue')}`}
    />
  )
}

export default observer(PaymentReceivedModal)
