import React from 'react'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import { TextDefault } from '../../../ui/text/UiText'
import TransactionRowContainerMobile from './TransactionRowContainerMobile'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import useNavigation from '../../../navigation/useNavigation'
import { ROUTE_REPORT_TRANSACTION_DETAIL } from '../../report/ReportRoutes'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  transaction: Transaction
}

function OpenTransactionRowMobile({ transaction }: Props) {
  const { navigateApp } = useNavigation()
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.securityNameFinnish
      : transaction.securityNameSwedish

  const getTransactionTypeName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.transactionTypeName
      : transaction.transactionTypeNameSwedish
  return (
    <TransactionRowContainerMobile
      onPress={() => {
        TransactionStore.selectedTransaction = transaction
        navigateApp(ROUTE_REPORT_TRANSACTION_DETAIL)
      }}
    >
      <TextDefault type="t5" style={{ flex: 1 }}>
        {formatDateFull(transaction.paymentDate) +
          ' | ' +
          getTransactionTypeName()}
      </TextDefault>
      <TextDefault type="t2" style={{ flex: 1 }}>
        {getName()}
      </TextDefault>
    </TransactionRowContainerMobile>
  )
}

export default OpenTransactionRowMobile
