import React from 'react'
import { View } from 'react-native'

import Spacings from '@taaleri/core/src/constants/Spacings'

import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { IntroImage } from './IntroImage'
import { useTranslation } from 'react-i18next'

export default function QuestionIntroContent() {
  const { t } = useTranslation()
  const image = require('./start.png')
  return (
    <View>
      <IntroImage image={image} />
      <Title style={{ marginTop: Spacings.S32, marginBottom: Spacings.S8 }}>
        {t('questions.start.title')}
      </Title>
      <Paragraph>
        {t('questions.start.description')}
      </Paragraph>
    </View>
  )
}
