import { BenchMark } from './BenchMark'

interface BenchmarkName {
  friendlyName: string
  category: BenchmarkCategory
}

export type BenchmarkCategory =
  | 'Osakeindeksit'
  | 'Korkoindeksit'
  | 'Rahamarkkinaindeksit'
  | 'Muut indeksit'

const benchmarks: {
  [key: string]: BenchmarkName
} = {
  MSDEEMUN: {
    friendlyName: 'graph.benchmark.sxxt',
    category: 'Osakeindeksit',
  },
  M7EU: {
    friendlyName: 'graph.benchmark.sxxr',
    category: 'Osakeindeksit',
  },
  MSDEUSN: {
    friendlyName: 'graph.benchmark.sptrne',
    category: 'Osakeindeksit',
  },
  QW1A: {
    friendlyName: 'graph.benchmark.qw1a',
    category: 'Korkoindeksit',
  },
  MSDEEEMN: {
    friendlyName: 'graph.benchmark.msdeeemn',
    category: 'Osakeindeksit',
  },
  NDEEWNR: {
    friendlyName: 'graph.benchmark.ndeewnr',
    category: 'Osakeindeksit',
  },
  MSDEPN: {
    friendlyName: 'graph.benchmark.msdepn',
    category: 'Osakeindeksit',
  },
  IBOXXMJA: {
    friendlyName: 'graph.benchmark.iboxxmja',
    category: 'Korkoindeksit',
  },
  LECPTREU: {
    friendlyName: 'graph.benchmark.lecptreu',
    category: 'Korkoindeksit',
  },
  HEXYP: {
    friendlyName: 'graph.benchmark.hexyp',
    category: 'Osakeindeksit',
  },
  DBDCONIA: {
    friendlyName: 'graph.benchmark.dbdconia',
    category: 'Rahamarkkinaindeksit',
  },
  LBEATREU: {
    friendlyName: 'graph.benchmark.lbeatreu',
    category: 'Korkoindeksit',
  },
}

export function addFriendlyBenchmarkName({
  securityId,
  name,
  nameSv,
}: BenchMark): BenchmarkName & BenchMark {
  const additionalInfo = benchmarks[securityId]
  return additionalInfo
    ? {
        name,
        securityId,
        friendlyName: additionalInfo.friendlyName,
        category: additionalInfo.category,
        nameSv,
      }
    : {
        friendlyName: name,
        name,
        category: 'Muut indeksit',
        securityId,
        nameSv,
      }
}
