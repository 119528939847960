import React from 'react'
import styled from 'styled-components'

interface Props {
  onPress: (option: any) => void
  children?: any
}

const Link = styled.div`
  cursor:pointer;
`

const BoxCheckTouchable: React.FC<Props> = ({ onPress, children }) => (
  <Link onClick={onPress}>{children}</Link>
)

export default BoxCheckTouchable
