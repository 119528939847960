import Analytics from '@taaleri/core/src/analytics/Analytics'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { paymentUrl } from '@taaleri/core/src/services/payments'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import styled from 'styled-components'
import { BoxWithPadding } from '../../ui/box/Box'
import ErrorCard from '../../ui/ErrorCard'
import { TextBold } from '../../ui/text/UiText'
import UiTouchable from '../../ui/UiTouchable'
import { BankIds, BANKS } from './Bank'
import { BankSelectionProps } from './BankSelection'
import { useTranslation } from 'react-i18next'

const Grid = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${Spacings.S32}px;
`

export default function BankList(props: BankSelectionProps) {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)

  async function openBank(id: string) {
    setError(false)
    Analytics.event('Payment', 'bank', id)
    let customerId = AppStore.customerId
    if (!customerId) {
      const identity = await getIdentity()
      customerId = identity.customerId
    }
    const url = paymentUrl(id, customerId)
    // @ts-ignore
    window.location.href = url
  }

  return (
    <>
      {(error || props.error) && (
        <View style={{ marginBottom: Spacings.S24 }}>
          <ErrorCard text={t('validation.payment-interrupted')}/>
        </View>
      )}
      <BoxWithPadding style={{ marginBottom: 1 }}>
        <TextBold>{t('invest.online-payment')}</TextBold>
      </BoxWithPadding>
      <Grid>
        {BANKS.map((b, index) => (
          <UiTouchable
            key={b.id}
            style={[
              styles.bank,
              {
                marginRight: index % 0 === 0 ? 2 : 0,
                marginLeft: index % 1 === 0 ? 2 : 0,
              },
            ]}
            onPress={() => openBank(b.id)}
          >
            <Image
              style={{
                width: '100%',
                height: b.id === BankIds.op ? 80 : 150,
                resizeMode: 'contain',
              }}
              source={b.image}
            />
          </UiTouchable>
        ))}
      </Grid>
    </>
  )
}

const styles = StyleSheet.create({
  bank: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    height: 108,
    marginBottom: 1,
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    borderRadius: BorderRadius.default,
  },
})
