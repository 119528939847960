import React from 'react'
import { Redirect } from 'react-router-dom'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import {
  ROUTE_SIGN_IN,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CUSTOMER_NOT_FOUND,
} from '@taaleri/components/src/navigation/routes'
import { PasswordRecoveryMode } from '@taaleri/core/src/models/Identity'
import useQueryParams from 'navigation/useQueryParams'

export default function SignInTupasReturn() {
  const params = useQueryParams()
  const { result, token, username, customerid } = params

  if (token || result === 'success') {
    const mode =
      result === 'new'
        ? PasswordRecoveryMode.NewCustomer
        : PasswordRecoveryMode.ForgotPassword
    return (
      <Redirect
        to={{
          pathname: routePath(ROUTE_CHANGE_PASSWORD),
          state: {
            mode,
            token,
            email: username,
            customerid,
          },
        }}
      />
    )
  }

  switch (result) {
    case PasswordRecoveryMode.NotFound.toString():
      return <Redirect to={routePath(ROUTE_CUSTOMER_NOT_FOUND)} />
    case PasswordRecoveryMode.NewCustomer.toString():
      return (
        <Redirect
          to={{
            pathname: routePath(ROUTE_CHANGE_PASSWORD),
            state: {
              mode: PasswordRecoveryMode.NewCustomer,
              token,
              email: username,
              customerid,
            },
          }}
        />
      )
    case 'error':
      return (
        <Redirect to={{ pathname: ROUTE_SIGN_IN, search: '?error=error' }} />
      )
    default:
      return <Redirect to={routePath(ROUTE_SIGN_IN)} />
  }
}
