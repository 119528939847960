enum Spacings {
  S2 = 2,
  S4 = 4,
  S8 = 8,
  S10 = 10,
  S16 = 16,
  S20 = 20,
  S24 = 24,
  S32 = 32,
  S40 = 40,
  S48 = 48,
  S56 = 56,
  S64 = 64,
  S128 = 128,
  PADDING = 20,
}

export default Spacings
