import React, { useState } from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import ImpactInvestmentProposal from './ImpactInvestmentProposal'
import { Formik } from 'formik'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import { TextError } from '../../ui/text/UiText'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ROUTE_IMPACT_TOGGLE_CONTRACT } from './ImpactRoutes'
import { BoxWithPadding } from '../../ui/box/Box'
import { Divider } from '../../ui/Divider'
import { selectedPortfolioDistribution } from '../questions/investmentproposal/selectedPortfolioDistrubution'
import {
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  QUESTION_PORTFOLIO_TYPE,
} from '../questions/questions'
import useNavigation from '../../navigation/useNavigation'
import { useTranslation } from 'react-i18next'

export const NOT_ACCEPTED_ERROR = 'impact.impact-not-accepted'

export function useUpdateImpact() {
  const [accept, setAccept] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const { navigate } = useNavigation()

  async function updateImpact() {
    setError(undefined)
    setLoading(true)
    const proposal = QuestionStore.investmentProposal
    const riskLevel = AppStore.investmentProposalSaved?.riskLevel
    QuestionStore.setAnswer(
      QUESTION_ILLIQUID_ASSETS_ALLOWED,
      accept ? 'SomeAllowed' : 'None'
    )
    if (proposal) {
      const selectedDistribution = proposal.portfolioDistributions.find(
        (d) => d.riskLevel === riskLevel
      )
      if (selectedDistribution) {
        const strategyIdentifier = selectedDistribution.strategyIdentifier
        QuestionStore.strategyIdentifier = strategyIdentifier
        const portfolioType =
          selectedDistribution.impactShare > 0 ? 'impact' : 'etf'
        QuestionStore.setAnswer(QUESTION_PORTFOLIO_TYPE, portfolioType)
      }
    }

    navigate(ROUTE_IMPACT_TOGGLE_CONTRACT)
  }

  return {
    loading,
    error,
    accept,
    setAccept,
    setError,
    updateImpact,
  }
}

export default function ImpactChangeForm({
  setAccept,
  setError,
  error,
}: {
  setAccept: (val: boolean) => void
  setError: (val: string | undefined) => void
  error: string | undefined
}) {
  const investmentProposal = QuestionStore.investmentProposal
  const etfToImpact = !AppStore.hasImpactStrategy
  const { t } = useTranslation()

  const selectedDistribution = selectedPortfolioDistribution(
    investmentProposal,
    QuestionStore.riskLevel
  )

  const impactShare = selectedDistribution.impactShare
  const hasImpact = impactShare !== 0
  return (
    <>
      <Title>{t('impact.form-summary')}</Title>

      {investmentProposal && (
        <ImpactInvestmentProposal investmentProposal={investmentProposal} />
      )}

      {hasImpact && (
        <Formik
          initialValues={{ accept: false }}
          onSubmit={() => {
            return
          }}
        >
          <BoxWithPadding
            style={{ marginBottom: Spacings.S24 }}
            title={`${t('impact.form-redemption')}`}
          >
            {hasImpact && (
              <Paragraph style={{ marginTop: Spacings.S24 }}>
                {t('impact.form-has-impact')}
              </Paragraph>
            )}
            <Divider />
            <UiListItemSwitch
              title={t('impact.form-has-impact-accept')}
              name="accept"
              topDivider={false}
              onChangeText={(value: boolean) => {
                setAccept(value)
                setError(
                  value && etfToImpact ? undefined : `${t(NOT_ACCEPTED_ERROR)}`
                )
              }}
            />
            {error && <TextError>{error}</TextError>}
          </BoxWithPadding>
        </Formik>
      )}
    </>
  )
}
