import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import {
  ROUTE_APP_INFO,
  ROUTE_APP_OPINION,
  ROUTE_CALCULATOR,
  ROUTE_CONTRACTS,
  ROUTE_FEED,
  ROUTE_ORDERS,
  ROUTE_REPORT_ARCHIVE,
  ROUTE_REPORT_ORDERS,
  ROUTE_REPORT_ORDERS_PACKAGE,
  ROUTE_TWO_FACTOR_TOGGLE,
  ROUTE_TWO_FACTOR_TOGGLE_DONE,
  ROUTE_TWO_FACTOR_TOGGLE_START,
} from '@taaleri/components/src/navigation/routes'
import { ROUTE_SAVINGS_PLAN } from '@taaleri/components/src/screens/SavingsPlan/SavingsPlanRoutes'
import { ROUTE_CONTRACT } from '@taaleri/components/src/screens/contract/ContractRoutes'
import { ROUTE_FEED_DETAIL } from '@taaleri/components/src/screens/feed/FeedRoutes'
import {
  ROUTE_BLOCKED_CONS_CUSTOMER,
  ROUTE_DUE_DATE,
  ROUTE_FUNDS_DETAIL,
  ROUTE_FUNDS_INTRO,
  ROUTE_FUNDS_INVEST_DISTRIBUTION,
  ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW,
  ROUTE_FUNDS_INVEST_START,
  ROUTE_FUNDS_INVEST_START_MONTHLY,
  ROUTE_MONTHLY_CONTRACT,
  ROUTE_MONTHLY_CONTRACT_PAYMENT,
  ROUTE_MONTHLY_CONTRACT_VERIFICATION,
} from '@taaleri/components/src/screens/funds/FundsRoutes'
import {
  ROUTE_IDENTITY_CARD_PHOTO_ID,
  ROUTE_IDENTITY_CARD_SUMMARY,
  ROUTE_IDENTITY_CARD_VALIDITY,
} from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'
import {
  ROUTE_IMPACT_COMPARISON,
  ROUTE_IMPACT_DISABLE,
  ROUTE_IMPACT_INTRO,
  ROUTE_IMPACT_TOGGLE_CONTRACT,
  ROUTE_IMPACT_TOGGLE_DONE,
  ROUTE_IMPACT_TOGGLE_OFF,
  ROUTE_IMPACT_TOGGLE_ON,
  ROUTE_IMPACT_TOGGLE_VERIFICATION,
} from '@taaleri/components/src/screens/impact/ImpactRoutes'
import {
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL,
  ROUTE_PORTFOLIO_BANK_TRANSFER,
  ROUTE_PORTFOLIO_BANK_TRANSFER_DONE,
  ROUTE_PORTFOLIO_EXISTING_ORDER,
  ROUTE_PORTFOLIO_INVEST_SELECT_CONTRACT,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_DONE,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM,
  ROUTE_PORTFOLIO_PAYMENT_BANK,
  ROUTE_PORTFOLIO_PAYMENT_CONFIRM,
  ROUTE_PORTFOLIO_PAYMENT_INFORMATION,
  ROUTE_PORTFOLIO_PAYMENT_MONTHLY,
  ROUTE_PORTFOLIO_PAYMENT_RECURRING_CONFIRM,
  ROUTE_PORTFOLIO_PAYMENT_SELECT,
  ROUTE_PORTFOLIO_PAYMENT_SUM,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION,
  ROUTE_PORTFOLIO_PHOTO_ID,
  ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL,
  ROUTE_PORTFOLIO_WAIT_PAYMENT_STATUS,
  ROUTE_PORTFOLIO_WITHDRAW_SELECT_CONTRACT,
  ROUTE_PURCHASE_LOTS,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import {
  ROUTE_PROFILE,
  ROUTE_PROFILE_ACCOUNT_REMOVAL,
  ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER,
  ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE,
  ROUTE_PROFILE_CHANGE_PASSWORD,
  ROUTE_PROFILE_CHANGE_PASSWORD_DONE,
  ROUTE_PROFILE_CHANGE_PHONE,
  ROUTE_PROFILE_CHANGE_PHONE_DONE,
  ROUTE_PROFILE_CHANGE_PHONE_ERROR,
  ROUTE_PROFILE_CHANGE_USERNAME,
  ROUTE_PROFILE_CHANGE_USERNAME_DONE,
  ROUTE_PROFILE_CHANGE_USERNAME_VERIFICATION,
  ROUTE_PROFILE_CONS_PAYMENT_INFO,
  ROUTE_PROFILE_DIGI_PAYMENT_INFO,
  ROUTE_PROFILE_DISCOUNT_CODE,
  ROUTE_PROFILE_DISCOUNT_CODE_DONE,
  ROUTE_PROFILE_FAQ,
  ROUTE_PROFILE_INVESTMENT_PROPOSAL,
  ROUTE_PROFILE_STYLE_GUIDE,
} from '@taaleri/components/src/screens/profile/ProfileRoutes'
import {
  ROUTE_RECURRING_ORDERS_LIST,
  ROUTE_RECURRING_ORDER_CANCEL,
  ROUTE_RECURRING_ORDER_CANCEL_DONE,
  ROUTE_RECURRING_ORDER_CANCEL_VERIFICATION,
  ROUTE_RECURRING_ORDER_DETAILS,
} from '@taaleri/components/src/screens/recurringorders/RecurringOrderRoutes'
import {
  ROUTE_REPORT,
  ROUTE_REPORT_DISCOUNT,
  ROUTE_REPORT_PROFIT,
  ROUTE_REPORT_PROFIT_DETAIL,
  ROUTE_REPORT_TRANSACTION,
  ROUTE_REPORT_TRANSACTION_DETAIL,
  ROUTE_REPORT_FEE,
} from '@taaleri/components/src/screens/report/ReportRoutes'
import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SavingsPlanScreen } from 'screens/SavingsPlan/SavingsPlanScreen'
import AppContainer from 'screens/app/AppContainer'
import NotFoundContent from 'screens/app/NotFoundContent'
import AssetClassScreen from 'screens/assetclass/AssetClassScreen'
import CalculatorScreen from 'screens/calculator/CalculatorScreen'
import ContractUnsuccessfullScreen from 'screens/contract/ContractUnsuccessfullScreen'
import ContractOrderScreen from 'screens/contractOrders/ContractOrderScreen'
import ContractScreen from 'screens/contracts/ContractScreen'
import FaqScreen from 'screens/faq/FaqScreen'
import FeedDetailScreen from 'screens/feed/FeedDetailScreen'
import FeedScreen from 'screens/feed/FeedScreen'
import BlockedConsCustomerScreen from 'screens/funds/BlockedConsCustomerScreen'
import DueDateScreen from 'screens/funds/DueDateScreen'
import FundDetailScreen from 'screens/funds/FundDetailScreen'
import FundInvestDistributionScreen from 'screens/funds/FundInvestDistributionScreen'
import FundInvestStartMonthlyScreen from 'screens/funds/FundInvestStartMonthlyScreen'
import FundInvestStartScreen from 'screens/funds/FundInvestStartScreen'
import FundsIntroScreen from 'screens/funds/FundsIntroScreen'
import MonthlyContractPaymentScreen from 'screens/funds/MonthlyContractPaymentScreen'
import MonthlyContractScreen from 'screens/funds/MonthlyContractScreen'
import MonthlyContractVerificationScreen from 'screens/funds/MonthlyContractVerificationScreen'
import HomeScreen from 'screens/home/HomeScreen'
import IdentityCardSummaryScreen from 'screens/identitycard/IdentityCardSummaryScreen'
import IdentityPhotoIdScreen from 'screens/identitycard/IdentityPhotoIdScreen'
import IdentityValidityScreen from 'screens/identitycard/IdentityValidityScreen'
import ImpactComparisonScreen from 'screens/impact/ImpactComparisonScreen'
import ImpactDisableScreen from 'screens/impact/ImpactDisableScreen'
import ImpactIntroScreen from 'screens/impact/ImpactIntroScreen'
import ImpactToggleContractScreen from 'screens/impact/ImpactToggleContractScreen'
import { ImpactToggleDoneScreen } from 'screens/impact/ImpactToggleDoneScreen'
import { ImpactVerificationScreen } from 'screens/impact/ImpactVerificationScreen'
import ExistingOrderScreen from 'screens/portfolio/ExistingOrderScreen'
import PaymentBankScreen from 'screens/portfolio/PaymentBankScreen'
import PaymentBankTransferScreen from 'screens/portfolio/PaymentBankTransferScreen'
import PaymentConfirmScreen from 'screens/portfolio/PaymentConfirmScreen'
import PaymentDoneScreen from 'screens/portfolio/PaymentDoneScreen'
import PaymentInformationScreen from 'screens/portfolio/PaymentInformationScreen'
import PaymentMonthlyScreen from 'screens/portfolio/PaymentMonthlyScreen'
import PaymentRecurringConfirmScreen from 'screens/portfolio/PaymentRecurringConfirmScreen'
import PaymentSelectScreen from 'screens/portfolio/PaymentSelectScreen'
import PaymentSumScreen from 'screens/portfolio/PaymentSumScreen'
import PaymentWaitStatusScreen from 'screens/portfolio/PaymentWaitStatusScreen'
import PaymentWithdrawalDoneScreen from 'screens/portfolio/PaymentWithdrawalDoneScreen'
import PaymentWithdrawalScreen from 'screens/portfolio/PaymentWithdrawalScreen'
import PaymentWithdrawalVerificationScreen from 'screens/portfolio/PaymentWithdrawalVerificationScreen'
import PortfolioScreen from 'screens/portfolio/PortfolioScreen'
import PurchaseLotsScreen from 'screens/portfolio/PurchaseLotsScreen'
import SelectContractScreen from 'screens/portfolio/SelectContractScreen'
import AppInfoScreen from 'screens/profile/AppInfoScreen'
import ChangeAccountNumberDoneScreen from 'screens/profile/ChangeAccountNumberDoneScreen'
import ChangeAccountNumberScreen from 'screens/profile/ChangeAccountNumberScreen'
import ChangePasswordDoneScreen from 'screens/profile/ChangePasswordDoneScreen'
import ChangePasswordScreen from 'screens/profile/ChangePasswordScreen'
import ChangePhoneDoneScreen from 'screens/profile/ChangePhoneDoneScreen'
import ChangePhoneErrorScreen from 'screens/profile/ChangePhoneErrorScreen'
import ChangePhoneScreen from 'screens/profile/ChangePhoneScreen'
import ChangeUsernameDoneScreen from 'screens/profile/ChangeUsernameDoneScreen'
import ChangeUsernameScreen from 'screens/profile/ChangeUsernameScreen'
import ChangeUsernameVerificationScreen from 'screens/profile/ChangeUsernameVerificationScreen'
import { DiscountCodeDoneScreen } from 'screens/profile/DiscountCodeDoneScreen'
import DiscountScreen from 'screens/profile/DiscountScreen'
import OpinionsScreen from 'screens/profile/OpinionsScreen'
import ProfileAccountRemovalScreen from 'screens/profile/ProfileAccountRemovalScreen'
import ProfileInvestmentProposalScreen from 'screens/profile/ProfileInvestmentProposalScreen'
import ProfilePaymentInfoScreen from 'screens/profile/ProfilePaymentInfoScreen'
import ProfileScreen from 'screens/profile/ProfileScreen'
import { TwoFactorToggleDoneScreen } from 'screens/profile/TwoFactorToggleDoneScreen'
import TwoFactorToggleStartScreen from 'screens/profile/TwoFactorToggleStartScreen'
import TwoFactorToggleVerificationScreen from 'screens/profile/TwoFactorToggleVerificationScreen'
import ImpactToggleScreen from 'screens/questions/ImpactToggleScreen'
import RecurringOrderCancelDoneScreen from 'screens/recurringorders/RecurringOrderCancelDoneScreen'
import RecurringOrderCancelScreen from 'screens/recurringorders/RecurringOrderCancelScreen'
import RecurringOrderCancelVerificationScreen from 'screens/recurringorders/RecurringOrderCancelVerificationScreen'
import RecurringOrderDetailScreen from 'screens/recurringorders/RecurringOrderDetailScreen'
import RecurringOrderListScreen from 'screens/recurringorders/RecurringOrderListScreen'
import ReportScreen from 'screens/report/ReportScreen'
import ReportArchiveScreen from 'screens/reportarchive/ReportArchiveScreen'
import { ReportDiscountScreen } from 'screens/reportfee/ReportDiscountScreen'
import { ReportFeeScreen } from 'screens/reportfee/ReportFeeScreen'
import ReportOrderDeliveryScreen from 'screens/reportorders/ReportOrderDeliveryScreen'
import ReportOrderPackageScreen from 'screens/reportorders/ReportOrderPackageScreen'
import ReportOrderReportsScreen from 'screens/reportorders/ReportOrderReportsScreen'
import ReportOrdersScreen from 'screens/reportorders/ReportOrdersScreen'
import { ProfitDetailScreen } from 'screens/reportprofit/ProfitDetailScreen'
import ProfitScreen from 'screens/reportprofit/ProfitScreen'
import TransactionDetailScreen from 'screens/reporttransaction/TransactionDetailScreen'
import TransactionsScreen from 'screens/reporttransaction/TransactionsScreen'
import StyleGuideScreen from 'screens/styleguide/StyleGuideScreen'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'

const ContractRouter = lazy(() => import('screens/contract/ContractRouter'))

function AppRouter() {
  return (
    <AppContainer>
      <Switch>
        <Route path={routePathApp(ROUTE_PORTFOLIO)} exact={true}>
          <PortfolioScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PURCHASE_LOTS) + '/:assetClassId/:ticker'}
          exact={true}
        >
          <PurchaseLotsScreen />
        </Route>
        <Route
          path={
            routePathApp(ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL) + '/:assetClassId'
          }
          exact={true}
        >
          <AssetClassScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT)} exact={true}>
          <ReportScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_DISCOUNT)} exact={true}>
          <ReportDiscountScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_PROFIT)} exact={true}>
          <ProfitScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_TRANSACTION)} exact={true}>
          <TransactionsScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_REPORT_TRANSACTION_DETAIL)}
          exact={true}
        >
          <TransactionDetailScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_PROFIT_DETAIL)} exact={true}>
          <ProfitDetailScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_FEE)} exact={true}>
          <ReportFeeScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE)} exact={true}>
          <ProfileScreen />
        </Route>
        <Route path={routePathApp(ROUTE_CALCULATOR)} exact={true}>
          <CalculatorScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_CHANGE_USERNAME)} exact={true}>
          <ChangeUsernameScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_DISCOUNT_CODE)} exact={true}>
          <DiscountScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_DISCOUNT_CODE_DONE)}
          exact={true}
        >
          <DiscountCodeDoneScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_USERNAME_VERIFICATION)}
          exact={true}
        >
          <ChangeUsernameVerificationScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_USERNAME_DONE)}
          exact={true}
        >
          <ChangeUsernameDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_CHANGE_PASSWORD)} exact={true}>
          <ChangePasswordScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_PASSWORD_DONE)}
          exact={true}
        >
          <ChangePasswordDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_CHANGE_PHONE)} exact={true}>
          <ChangePhoneScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_DIGI_PAYMENT_INFO)}
          exact={true}
        >
          <ProfilePaymentInfoScreen isConsultative={false} />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CONS_PAYMENT_INFO)}
          exact={true}
        >
          <ProfilePaymentInfoScreen isConsultative={true} />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_INVESTMENT_PROPOSAL)}
          exact={true}
        >
          <ProfileInvestmentProposalScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_ACCOUNT_REMOVAL)} exact={true}>
          <ProfileAccountRemovalScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_PHONE_DONE)}
          exact={true}
        >
          <ChangePhoneDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_RECURRING_ORDERS_LIST)} exact={true}>
          <RecurringOrderListScreen />
        </Route>
        <Route path={routePathApp(ROUTE_SAVINGS_PLAN)} exact={true}>
          <SavingsPlanScreen />
        </Route>
        <Route path={routePathApp(ROUTE_RECURRING_ORDER_DETAILS)} exact={true}>
          <RecurringOrderDetailScreen />
        </Route>
        <Route path={routePathApp(ROUTE_RECURRING_ORDER_CANCEL)} exact={true}>
          <RecurringOrderCancelScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_RECURRING_ORDER_CANCEL_VERIFICATION)}
          exact={true}
        >
          <RecurringOrderCancelVerificationScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_RECURRING_ORDER_CANCEL_DONE)}
          exact={true}
        >
          <RecurringOrderCancelDoneScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_PHONE_ERROR)}
          exact={true}
        >
          <ChangePhoneErrorScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER)}
          exact={true}
        >
          <ChangeAccountNumberScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE)}
          exact={true}
        >
          <ChangeAccountNumberDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_CONTRACTS)}>
          <ContractScreen />
        </Route>
        <Route path={routePathApp(ROUTE_ORDERS)}>
          <ContractOrderScreen />
        </Route>
        <Route path={routePathApp(ROUTE_CONTRACT)}>
          <ContractRouter />
        </Route>
        {/* <Route path={routePathApp(ROUTE_IMPACT)} exact={true}>
          <ImpactScreen />
        </Route> */}
        <Route path={routePathApp(ROUTE_IMPACT_INTRO)} exact={true}>
          <ImpactIntroScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_COMPARISON)} exact={true}>
          <ImpactComparisonScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL)}>
          <ContractUnsuccessfullScreen />
        </Route>
        {/* <Route
          path={
            routePathApp(ROUTE_IMPACT + '/' + ROUTE_IMPACT_PRODUCT_DETAIL) +
            '/:productId'
          }
          exact={true}
        >
          <ImpactProductDetailScreen />
        </Route> */}
        {/* <Route
          path={
            routePathApp(ROUTE_IMPACT + '/' + ROUTE_IMPACT_FUND_DETAIL) +
            '/:productId' +
            '/:customerOwns'
          }
          exact={true}
        >
          <ImpactFundDetailScreen />
        </Route> */}
        <Route path={routePathApp(ROUTE_PORTFOLIO_INVEST_SELECT_CONTRACT)}>
          <SelectContractScreen mode="invest" />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_WITHDRAW_SELECT_CONTRACT)}>
          <SelectContractScreen mode="withdraw" />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_SELECT)}>
          <PaymentSelectScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_EXISTING_ORDER)}>
          <ExistingOrderScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_SUM)}>
          <PaymentSumScreen isMonthly={false} />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM)}>
          <PaymentSumScreen isMonthly={true} />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_CONFIRM)}>
          <PaymentConfirmScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_INFORMATION)}>
          <PaymentInformationScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_MONTHLY)}>
          <PaymentMonthlyScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_BANK)}>
          <PaymentBankScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_WAIT_PAYMENT_STATUS)}>
          <PaymentWaitStatusScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_MONTHLY_PAYMENT_DONE)}>
          <PaymentDoneScreen isRecurring={true} />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_BANK_TRANSFER_DONE)}>
          <PaymentDoneScreen isRecurring={false} />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_BANK_TRANSFER)}>
          <PaymentBankTransferScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL)}>
          <PaymentWithdrawalScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE)}>
          <PaymentWithdrawalDoneScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION)}
        >
          <PaymentWithdrawalVerificationScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PAYMENT_RECURRING_CONFIRM)}>
          <PaymentRecurringConfirmScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PORTFOLIO_PHOTO_ID)}>
          <IdentityPhotoIdScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IDENTITY_CARD_PHOTO_ID)}>
          <IdentityPhotoIdScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IDENTITY_CARD_VALIDITY)}>
          <IdentityValidityScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IDENTITY_CARD_SUMMARY)}>
          <IdentityCardSummaryScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_TOGGLE_VERIFICATION)}>
          <ImpactVerificationScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_TOGGLE_DONE)}>
          <ImpactToggleDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FEED)}>
          <FeedScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FEED_DETAIL + '/:id')}>
          <FeedDetailScreen />
        </Route>
        <Route path={routePathApp(ROUTE_TWO_FACTOR_TOGGLE_START)}>
          <TwoFactorToggleStartScreen />
        </Route>
        <Route path={routePathApp(ROUTE_TWO_FACTOR_TOGGLE)}>
          <TwoFactorToggleVerificationScreen />
        </Route>
        <Route path={routePathApp(ROUTE_TWO_FACTOR_TOGGLE_DONE)}>
          <TwoFactorToggleDoneScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_ARCHIVE)} exact={true}>
          <ReportArchiveScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_ORDERS)} exact={true}>
          <ReportOrdersScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_ORDERS_PACKAGE)} exact={true}>
          <ReportOrderPackageScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_REPORT_ORDERS + '/:id/reports/:customerId')}
          exact={true}
        >
          <ReportOrderReportsScreen />
        </Route>
        <Route
          path={routePathApp(ROUTE_REPORT_ORDERS + '/:id/reports')}
          exact={true}
        >
          <ReportOrderReportsScreen />
        </Route>
        <Route path={routePathApp(ROUTE_REPORT_ORDERS + '/:id/delivery')}>
          <ReportOrderDeliveryScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_INTRO)}>
          <FundsIntroScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_DETAIL + '/:id')}>
          <FundDetailScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_INVEST_START_MONTHLY)}>
          <FundInvestStartMonthlyScreen />
        </Route>
        <Route path={routePathApp(ROUTE_DUE_DATE)}>
          <DueDateScreen />
        </Route>
        <Route path={routePathApp(ROUTE_MONTHLY_CONTRACT)}>
          <MonthlyContractScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_TOGGLE_ON)} exact={true}>
          <ImpactToggleScreen withImpact={true} />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_TOGGLE_CONTRACT)} exact={true}>
          <ImpactToggleContractScreen />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_TOGGLE_OFF)} exact={true}>
          <ImpactToggleScreen withImpact={false} />
        </Route>
        <Route path={routePathApp(ROUTE_IMPACT_DISABLE)} exact={true}>
          <ImpactDisableScreen />
        </Route>
        <Route path={routePathApp(ROUTE_MONTHLY_CONTRACT_VERIFICATION)}>
          <MonthlyContractVerificationScreen />
        </Route>
        <Route path={routePathApp(ROUTE_MONTHLY_CONTRACT_PAYMENT)}>
          <MonthlyContractPaymentScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_INVEST_START)}>
          <FundInvestStartScreen />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_INVEST_DISTRIBUTION)}>
          <FundInvestDistributionScreen action="Buy" />
        </Route>
        <Route path={routePathApp(ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW)}>
          <FundInvestDistributionScreen action="Sell" />
        </Route>
        <Route path={routePathApp(ROUTE_BLOCKED_CONS_CUSTOMER)}>
          <BlockedConsCustomerScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_STYLE_GUIDE)}>
          <StyleGuideScreen />
        </Route>
        <Route path={routePathApp(ROUTE_PROFILE_FAQ)}>
          <FaqScreen />
        </Route>
        <Route path={routePathApp(ROUTE_APP_INFO)}>
          <AppInfoScreen />
        </Route>
        <Route path={routePathApp(ROUTE_APP_OPINION)}>
          <OpinionsScreen />
        </Route>
        <Route path="/App" exact={true}>
          <HomeScreen />
        </Route>
        <Route>
          <BackgroundContainerApp image="not-found">
            <NotFoundContent />
          </BackgroundContainerApp>
        </Route>
      </Switch>
    </AppContainer>
  )
}

export default AppRouter
