import { toHistoricalChange } from '@taaleri/components/src/screens/portfolio/toHistoricalChange'
import flatten from 'lodash/flatten'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import {
  action,
  computed,
  observable,
  runInAction,
  makeAutoObservable,
} from 'mobx'

import AppStore from './AppStore'
import { ASSET_CLASS_ID_CASH, isPrivateEquity } from '../constants/AssetClass'
import Colors from '../constants/Colors'
import {
  createKeyFigure,
  KeyFigure,
  MARKET_VALUE,
  PERFORMANCE,
  SHARPE,
  UNREALIZED_PROFIT,
  VOLATILITY,
} from '../constants/KeyFigures'
import {
  CUSTOM_DATE_RANGE,
  FROM_BEGINNING,
  PAST_MONTH,
  PAST_YEAR,
  YEAR_TO_DATE,
  LAST_YEAR,
  DATE_RANGE,
  SIX_MONTHS,
} from '../constants/YieldHistory'
import { fiKey } from '../i18n'
import { BenchMark, IndexInfo } from '../models/BenchMark'
import { CombinedIndex } from '../models/CombinedIndex'
import { IdValuePair } from '../models/IdValuePair'
import {
  AssetClass,
  Portfolio,
  AssetClassItem,
  PurchaseLot,
} from '../models/Portfolio'
import {
  ReportingPortfolio,
  ReportingPortfolioType,
} from '../models/ReportingPortfolio'
import { RestrictionFilterParameters } from '../models/RestrictionFilterParameters'
import { SecurityPrice } from '../models/SecurityPriceResponse'
import { ABORTERROR } from '../models/ServiceResponse'
import { TimeSerieItem } from '../models/YieldHistory'
import {
  assetClassColors,
  assetClassColorsRgb,
  PercentagePieItem,
} from '../models/assetClassColors'
import {
  getBenchMarkByPortfolioId,
  getBenchMarkByTicker,
  getBenchMarkList,
  getFilteredCombinedIndex,
  getFilteredPositions,
  getPortfoliosWithBenchMark,
  getTransactionToken,
  getPurchaseLotsByTicker,
} from '../services/reports'
import { getSecurityPrices } from '../services/securityPrice'
import {
  dateRangeToDates,
  EARLIEST_POSSIBLE_DATE,
  EARLIEST_POSSIBLE_DATE_CONVERTED,
} from '../utils/date'
import {
  formatAssetCategoryName,
  formatDateDash,
  formatDateFull,
  sum,
} from '../utils/format'
import logger from '../utils/logger'

export interface ReportTimeSeries {
  twrTimeSeries: TimeSerieItem[]
  volatilityTimeSeries: TimeSerieItem[]
  marketValueTimeSeries: TimeSerieItem[]
}

export interface DateRangeValue {
  id: DATE_RANGE
  value: string
}

class ReportStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable
  selectedReportingPortfolios: string[] = []
  @observable
  customerBenchmarkId?: string
  @observable
  portfolioBenchmarkIds: string[] = []
  @observable
  selectedCategory?: string
  @observable
  selectedBenchMark?: string
  @observable
  selectedAdditionalInfo?: string
  @observable
  restrictionOpen = false
  @observable
  selectedPosition?: string
  @observable
  benchmarkComposition: IndexInfo[] = []
  @observable
  portfolio?: Portfolio
  @observable
  combinedIndex?: CombinedIndex
  @observable
  purchaseLots: PurchaseLot[] = []
  @observable
  earliestPossibleDate: Date = new Date(2007, 0, 1)
  @observable
  earliestPossibleConvertedDate: Date = new Date(2022, 0, 1)
  @observable
  benchMarkList: BenchMark[] = []
  @observable
  portfolioCombinedIndex?: CombinedIndex
  @observable
  loading = false
  @observable
  loadingBenchMarkIndex = false
  @observable
  loadingPurchaselots = false
  @observable
  loadingBenchMarkList = false
  @observable
  customerBenchmarkExist = false
  @observable
  loadingCombinedIndex = false
  @observable
  loadingPositions = false
  @observable
  loadingPortfolioIndex = false
  @observable
  benchMarkIndex: TimeSerieItem[] = []
  @observable
  securityPrice?: SecurityPrice = undefined
  @observable
  loadingSecurityPrice = false
  @observable
  customerId = 0
  @observable
  dateRange: string = PAST_YEAR
  @observable
  startDate = ''
  @observable
  endDate = ''
  @observable
  token = ''
  @observable
  loadingToken = false

  @observable
  assetClass?: string

  @observable
  showEuroProfit = false

  @computed get reportingPortfoliosList(): ReportingPortfolio[] {
    return AppStore.reportingPortfolioList || []
  }

  @action
  setRestrictionOpen = (open: boolean) => {
    this.restrictionOpen = open
  }

  @computed get chartColor() {
    if (this.assetClass) {
      return assetClassColors[this.assetClass]
    }
    return Colors.primary
  }

  @computed get selectedReportingPortfoliosList(): ReportingPortfolio[] {
    return this.reportingPortfoliosList.filter((s) =>
      this.selectedReportingPortfolios.includes(s.id)
    )
  }

  @computed get allReportingPortfoliosSelected(): boolean {
    return (
      this.selectedReportingPortfoliosList.length ===
      this.reportingPortfoliosList.length
    )
  }

  @computed get showEuroProfitForSingleSelectedPortfolio(): boolean {
    return (
      this.selectedReportingPortfoliosList.length === 1 &&
      this.selectedReportingPortfoliosList[0].showMarketValue
    )
  }

  @computed get totalPurchaseValue(): number {
    return this.portfolio?.purchaseValue ?? 0
  }

  selectedReportingPortfoliosByType(
    type: ReportingPortfolioType
  ): ReportingPortfolio[] {
    return this.selectedReportingPortfoliosList.filter((p) => p.type === type)
  }

  @action
  selectAllReportingPortfolios = () => {
    this.selectedReportingPortfolios = this.reportingPortfoliosList.map(
      (s) => s.id
    )
  }

  @action
  unSelectAllReportingPortfolios = () => {
    this.selectedReportingPortfolios = []
  }

  @action
  unSelectAllReportingPortfoliosByType = (type: ReportingPortfolioType) => {
    this.selectedReportingPortfolios = this.reportingPortfoliosList
      .filter(
        (p) =>
          p.type !== type && this.selectedReportingPortfolios.includes(p.id)
      )
      .map((p) => p.id)
  }

  @action
  selectAllReportingPortfoliosByType = (type: ReportingPortfolioType) => {
    this.selectedReportingPortfolios = this.reportingPortfoliosList
      .filter(
        (p) =>
          p.type === type || this.selectedReportingPortfolios.includes(p.id)
      )
      .map((p) => p.id)
  }

  @computed get reportingPortfolioTypes(): ReportingPortfolioType[] {
    return sortBy(
      uniq(this.reportingPortfoliosList.map((p) => p.type)),
      (reportingPortfolioType: ReportingPortfolioType) => {
        if (reportingPortfolioType === 'Own') {
          return -1
        }
        if (reportingPortfolioType === 'Insurance') {
          return 0
        }
        return 1
      }
    )
  }

  @action
  setDateRange = (value: DATE_RANGE) => {
    const { startDate, endDate } = dateRangeToDates(
      value,
      this.additionalFilterRestrictions
    )
    if (startDate && endDate) {
      this.startDate = formatDateDash(startDate)
      this.endDate = formatDateDash(endDate)
      this.dateRange = value
    }
  }

  @action
  reportingPortfoliosByType = (type: ReportingPortfolioType) => {
    return this.reportingPortfoliosList.filter((p) => p.type === type)
  }

  @action
  toggleReportingPortfolio = async (id: string) => {
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.selectedReportingPortfolios.includes(id)) {
      this.selectedReportingPortfolios =
        this.selectedReportingPortfolios.filter((i) => i !== id)
    } else {
      this.selectedReportingPortfolios = [
        ...this.selectedReportingPortfolios,
        id,
      ]
    }
  }

  @action
  selectAssetClass = async (id: string) => {
    this.selectedPosition = undefined
    this.selectedAdditionalInfo = undefined
    this.selectedCategory = undefined
    this.assetClass = id
    this.fetchIndex()
  }

  @action
  selectCategory = async (id: string) => {
    this.selectedPosition = undefined
    this.selectedAdditionalInfo = undefined
    this.selectedCategory = id
    this.fetchIndex()
  }

  @action
  selectAdditionalInfo = async (id: string) => {
    this.selectedPosition = undefined
    this.selectedAdditionalInfo = id
    this.fetchIndex()
  }

  @action
  selectPosition = async (id: string) => {
    this.selectedPosition = id
    this.fetchPurchaseLots(id)
    this.fetchSecurityTimeSerie(id)
    this.fetchIndex()
  }

  isOwnFund(ticker: string): boolean {
    return (
      this.currentAssetClassItems()?.find((item) => item.ticker === ticker)
        ?.ownFund || false
    )
  }

  @computed get isSelectedPositionPrivateEquity(): boolean {
    if (this.selectedPosition) {
      return (
        this.portfolioFiltered?.assetClasses.some(
          (e) => !e.items.some((f) => !isPrivateEquity(f.securityTypeId))
        ) ?? false
      )
    }
    return false
  }

  @computed
  get portfolioFiltered(): Portfolio | undefined {
    if (!this.portfolio) {
      return undefined
    }
    if (this.selectedPosition) {
      const assetClass = this.currentAssetClass()
      return {
        ...this.portfolio,
        assetClasses: [
          {
            ...assetClass,
            items: assetClass.items.filter(
              (i) => i.ticker === this.selectedPosition
            ),
          },
        ],
      }
    } else if (this.selectedAdditionalInfo) {
      const assetClass = this.currentAssetClass()
      return {
        ...this.portfolio,
        assetClasses: [
          {
            ...assetClass,
            items: assetClass.items.filter(
              (i) => i.assetAdditionalInfoId === this.selectedAdditionalInfo
            ),
          },
        ],
      }
    } else if (this.selectedCategory) {
      const assetClass = this.currentAssetClass()
      return {
        ...this.portfolio,
        assetClasses: [
          {
            ...assetClass,
            items: assetClass.items.filter(
              (i) => i.assetCategoryId === this.selectedCategory
            ),
          },
        ],
      }
    } else if (this.assetClass) {
      return {
        ...this.portfolio,
        assetClasses: [this.currentAssetClass()],
      }
    } else {
      return { ...this.portfolio }
    }
  }

  @action
  resetRestrictionFilters() {
    this.assetClass = undefined
    this.selectedCategory = undefined
    this.selectedAdditionalInfo = undefined
    this.selectedPosition = undefined
  }

  @action
  resetDateFilter() {
    this.setDateRange(PAST_YEAR)
  }

  @action
  goBack() {
    if (this.assetClass && !this.selectedCategory) {
      this.assetClass = undefined
    }
    if (!this.selectedAdditionalInfo) {
      this.selectedCategory = undefined
    }
    this.selectedPosition = undefined
    this.selectedAdditionalInfo = undefined
    this.fetchIndex()
  }

  @action
  reset() {
    this.benchMarkIndex = []
    this.benchMarkList = []
    this.loadingCombinedIndex = false
    this.loadingPortfolioIndex = false
    this.selectedBenchMark = undefined
    this.benchmarkComposition = []
    this.portfolioBenchmarkIds = []
    this.customerBenchmarkId = undefined
    this.customerBenchmarkExist = false
    this.combinedIndex = undefined
    this.securityPrice = undefined
    this.portfolioCombinedIndex = undefined
    this.selectedReportingPortfolios = []
    this.customerId = 0
    this.restrictionOpen = false
  }

  @action
  async init() {
    const { customerId } = AppStore
    const hasCustomerChanged = this.customerId !== customerId
    /* 
    Initialize variables and fetch data if customer has changed. 
    Doesn't matter if PortolioContentReport is mounted twice, 
    fetching is only done once.
    */
    if (hasCustomerChanged) {
      this.reset()
      this.resetDateFilter()
      this.resetRestrictionFilters()
      this.fetchToken()
      this.fetchBenchMarkList()
      this.fetchPortfoliosWithBenchmark()
      await this.fetchData()
      this.customerId = customerId
      this.selectedReportingPortfolios = this.reportingPortfoliosList.map(
        (s) => s.id
      )
    }
  }

  @action
  setRestrictionParameters = ({
    assetClass,
    selectedCategory,
    selectedAdditionalInfo,
    selectedPosition,
  }: RestrictionFilterParameters) => {
    if (
      assetClass ||
      selectedCategory ||
      selectedAdditionalInfo ||
      selectedPosition
    ) {
      this.assetClass =
        assetClass === ASSET_CLASS_ID_CASH ? undefined : assetClass
      this.selectedCategory = selectedCategory
      this.selectedAdditionalInfo = selectedAdditionalInfo
      this.selectedPosition = selectedPosition
    }
    if (selectedPosition) {
      this.fetchPurchaseLots(selectedPosition)
      this.fetchSecurityTimeSerie(selectedPosition)
    }
    this.fetchIndex()
    this.setRestrictionOpen(true)
  }

  @action
  customDateChanged = async (startDate: string, endDate: string) => {
    this.dateRange = CUSTOM_DATE_RANGE
    this.startDate = startDate
    this.endDate = endDate
    await this.fetchData()
  }

  @action
  fetchData = async () => {
    try {
      console.log(this.startDate)
      const positionsTask = this.fetchPositions()
      const indexTask = this.fetchIndex()
      const portfolioIndexTask = this.fetchPortfolioIndex()
      await Promise.all([positionsTask, indexTask, portfolioIndexTask])
    } catch (error) {
      logger.devInfo('Fetch data was cancelled')
    }
  }

  @action
  fetchToken = async () => {
    this.loadingToken = true
    try {
      const { response, success } = await getTransactionToken(
        AppStore.customerId
      )
      if (response && success) {
        this.token = response
      }
    } catch (e) {
      logger.devInfo(`Fetching token failed`)
    }
    this.loadingToken = false
  }

  @action
  fetchBenchMarkList = async () => {
    this.loadingBenchMarkList = true
    try {
      this.benchMarkList = await getBenchMarkList()
    } catch (error) {
      logger.devInfo(`Fetching benchmark list was cancelled`)
    }
    this.loadingBenchMarkList = false
  }

  @action
  fetchBenchmarkByTicker = async (ticker: string) => {
    this.loadingBenchMarkIndex = true
    try {
      const { customerId } = AppStore
      const startDate =
        this.dateRange === 'FROM_BEGINNING'
          ? this.additionalFilterRestrictions
            ? formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
            : formatDateDash(EARLIEST_POSSIBLE_DATE)
          : this.startDate ?? ''
      const endDate = this.endDate ?? ''
      const benchMarkIndex = await getBenchMarkByTicker(
        customerId,
        startDate,
        endDate,
        ticker
      )
      runInAction(() => {
        this.benchmarkComposition = []
        if (benchMarkIndex instanceof Array && benchMarkIndex.length > 2) {
          this.benchMarkIndex = benchMarkIndex
        } else {
          this.benchMarkIndex = []
        }
      })
    } catch (error) {
      logger.devInfo(`Fetching benchmark index was cancelled`)
    }
    this.loadingBenchMarkIndex = false
  }

  @action
  fetchBenchmarkByPortfolioId = async (portfolioId?: string) => {
    this.loadingBenchMarkIndex = true
    try {
      const { customerId } = AppStore
      const startDate =
        this.dateRange === 'FROM_BEGINNING'
          ? this.additionalFilterRestrictions
            ? formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
            : formatDateDash(EARLIEST_POSSIBLE_DATE)
          : this.startDate ?? ''
      const endDate = this.endDate ?? ''
      const benchMarkResponse = await getBenchMarkByPortfolioId(
        customerId,
        startDate,
        endDate,
        portfolioId
      )
      runInAction(() => {
        this.benchMarkIndex = benchMarkResponse?.indexSeries || []
        this.benchmarkComposition = benchMarkResponse?.indexData.indexes || []
      })
    } catch (error) {
      logger.devInfo(`Fetching benchmark index was cancelled`)
    }
    this.loadingBenchMarkIndex = false
  }

  // Check on init, whether customer bench mark exists or not
  @action
  fetchPortfoliosWithBenchmark = async () => {
    try {
      const { customerId } = AppStore

      const portfoliosWithBenchmark = await getPortfoliosWithBenchMark(
        customerId
      )
      runInAction(() => {
        if (portfoliosWithBenchmark) {
          this.customerBenchmarkId =
            portfoliosWithBenchmark?.customerIndex?.portfolioId || undefined
          this.portfolioBenchmarkIds =
            portfoliosWithBenchmark?.portfolioIndexes.map(
              (e) => e.portfolioId
            ) || []
        }
      })
    } catch (error) {
      logger.devInfo(`Fetching benchmark index was cancelled`)
    }
    this.loadingBenchMarkIndex = false
  }

  @computed get loadingChartData() {
    return (
      this.loadingPortfolioIndex ||
      this.loadingPositions ||
      this.loadingCombinedIndex ||
      this.loadingBenchMarkIndex ||
      this.loadingSecurityPrice
    )
  }

  @action
  fetchIndex = async () => {
    this.loadingCombinedIndex = true
    this.benchMarkIndex = []
    this.benchmarkComposition = []
    this.selectedBenchMark = undefined
    try {
      const combinedIndex = await getFilteredCombinedIndex(
        AppStore.customerId,
        this.selectedReportingPortfolios,
        this.assetClass ?? '',
        this.selectedCategory ?? '',
        this.selectedAdditionalInfo ?? '',
        this.selectedPosition ?? '',
        this.dateRange === 'FROM_BEGINNING' &&
          !this.additionalFilterRestrictions
          ? ''
          : this.additionalFilterRestrictions &&
            new Date(this.startDate) < EARLIEST_POSSIBLE_DATE_CONVERTED
          ? formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
          : this.startDate ?? '',
        this.endDate ?? ''
      )
      runInAction(() => {
        this.combinedIndex = combinedIndex
        // Replace start date with real begin date
        if (this.dateRange === FROM_BEGINNING) {
          if (
            new Date(combinedIndex.beginDate) <
              EARLIEST_POSSIBLE_DATE_CONVERTED &&
            this.additionalFilterRestrictions
          ) {
            this.startDate = formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
          } else {
            this.startDate = formatDateDash(new Date(combinedIndex.beginDate))
          }
        }
      })
    } catch (error) {
      if (error === ABORTERROR) {
        logger.devInfo(`Fetching portfolio combined index was cancelled`)
        return
      }
    }
    this.loadingCombinedIndex = false
  }

  @action
  fetchPortfolioIndex = async () => {
    this.loadingPortfolioIndex = true
    try {
      const combinedIndex = await getFilteredCombinedIndex(
        AppStore.customerId,
        this.selectedReportingPortfolios,
        '',
        '',
        '',
        '',
        this.dateRange === 'FROM_BEGINNING' &&
          !this.additionalFilterRestrictions
          ? ''
          : this.additionalFilterRestrictions &&
            new Date(this.startDate) < EARLIEST_POSSIBLE_DATE_CONVERTED
          ? formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
          : this.startDate ?? '',
        this.endDate ?? '',
        'portfolioCombinedIndex'
      )
      runInAction(() => {
        this.portfolioCombinedIndex = combinedIndex
        // Replace start date with real begin date
        if (this.dateRange === FROM_BEGINNING) {
          if (
            new Date(combinedIndex.beginDate) <
              EARLIEST_POSSIBLE_DATE_CONVERTED &&
            this.additionalFilterRestrictions
          ) {
            this.startDate = formatDateDash(EARLIEST_POSSIBLE_DATE_CONVERTED)
          } else {
            this.startDate = formatDateDash(new Date(combinedIndex.beginDate))
          }
        }
      })
    } catch (error) {
      if (error === ABORTERROR) {
        logger.devInfo(`Fetching filtered combined index was cancelled`)
        return
      }
    }
    this.loadingPortfolioIndex = false
  }

  rebaseVolatilityTimeSeries(timeSeries: TimeSerieItem[]): TimeSerieItem[] {
    return timeSeries.map((t) => {
      return { date: t.date, value: t.value + 100 }
    })
  }

  @action
  fetchSecurityTimeSerie = async (ticker: string) => {
    this.loadingSecurityPrice = true
    this.securityPrice = undefined
    try {
      const { response, success } = await getSecurityPrices(
        ticker,
        this.startDate ?? '',
        this.endDate ?? ''
      )
      if (response && success) {
        this.securityPrice = response
      }
    } catch (e) {
      logger.devInfo(`Fetching securityPrice was cancelled`)
    }
    this.loadingSecurityPrice = false
  }

  @action
  fetchPurchaseLots = async (ticker: string) => {
    this.loadingPurchaselots = true
    try {
      const { response, success } = await getPurchaseLotsByTicker(
        AppStore.customerId,
        ticker,
        this.endDate ?? '',
        this.selectedReportingPortfolios
      )
      if (response && success) {
        this.purchaseLots = response
      }
    } catch (e) {
      logger.devInfo(`Fetching purchaselots was cancelled`)
    }
    this.loadingPurchaselots = false
  }

  @action
  fetchPositions = async () => {
    this.loadingPositions = true
    try {
      const response = await getFilteredPositions(
        AppStore.customerId,
        this.selectedReportingPortfolios,
        '',
        '',
        '',
        '',
        this.endDate ?? ''
      )

      if (response) {
        this.portfolio = response
        this.showEuroProfit = this.showEuroProfitForSingleSelectedPortfolio
      }
    } catch (e) {
      logger.devInfo(`Fetching positions was cancelled`)
    }
    this.loadingPositions = false
  }

  @action
  isPortofolioSelected = (id: string) => {
    return this.selectedReportingPortfolios.includes(id)
  }

  @computed get isDrillDown(): boolean {
    return !(
      !this.assetClass &&
      !this.selectedCategory &&
      !this.selectedAdditionalInfo &&
      !this.selectedPosition
    )
  }

  @computed get reportTimeSeries(): ReportTimeSeries | undefined {
    if (this.combinedIndex) {
      return {
        twrTimeSeries: this.combinedIndex.twrTimeSerie,
        marketValueTimeSeries: this.combinedIndex.marketValueTimeSeries,
        volatilityTimeSeries: this.rebaseVolatilityTimeSeries(
          this.combinedIndex.volatilityTimeSeries
        ),
      }
    }
  }

  @computed get portfolioTimeSeries(): ReportTimeSeries | undefined {
    if (this.portfolioCombinedIndex) {
      return {
        twrTimeSeries: this.portfolioCombinedIndex.twrTimeSerie,
        marketValueTimeSeries:
          this.portfolioCombinedIndex.marketValueTimeSeries,
        volatilityTimeSeries: this.rebaseVolatilityTimeSeries(
          this.portfolioCombinedIndex.volatilityTimeSeries
        ),
      }
    }
  }

  @computed get historicalChanges(): IdValuePair[] {
    return this.combinedIndex ? toHistoricalChange(this.combinedIndex) : []
  }

  @computed get keyFigures(): KeyFigure[] {
    if (this.combinedIndex) {
      const keyFigures = []

      const {
        performance,
        sharpe,
        volatilityEndValue,
        marketValueChange,
        volatilityStartValue,
      } = this.combinedIndex
      keyFigures.push(createKeyFigure(MARKET_VALUE, this.marketValue))

      const unrealizedProfit = marketValueChange?.unrealizedProfit
      keyFigures.push(createKeyFigure(UNREALIZED_PROFIT, unrealizedProfit))

      keyFigures.push(createKeyFigure(PERFORMANCE, performance))

      keyFigures.push(createKeyFigure(SHARPE, sharpe))

      const volEnd = volatilityEndValue?.value
      const volStart = volatilityStartValue?.value
      const volatilityChange =
        volEnd && volStart ? volEnd - volStart : undefined
      keyFigures.push(createKeyFigure(VOLATILITY, volEnd, volatilityChange))
      return keyFigures
    }
    return []
  }

  // NEW VOLATILE SHARP CALCULATION
  // @computed get keyFigures(): KeyFigure[] {
  //   if (this.combinedIndex) {
  //     const keyFigures: KeyFigure[] = []

  //     const {
  //       performance,
  //       sharpe,
  //       volatility,
  //       marketValueChange,
  //       volatilityChange,
  //     } = this.combinedIndex
  //     keyFigures.push(createKeyFigure(MARKET_VALUE, this.marketValue))

  //     const unrealizedProfit = marketValueChange?.unrealizedProfit
  //     keyFigures.push(createKeyFigure(UNREALIZED_PROFIT, unrealizedProfit))

  //     keyFigures.push(createKeyFigure(PERFORMANCE, performance))

  //     keyFigures.push(createKeyFigure(SHARPE, sharpe))

  //     keyFigures.push(createKeyFigure(VOLATILITY, volatility, volatilityChange))
  //     return keyFigures
  //   }
  //   return []
  // }

  @computed get customerHasBenchMark(): boolean {
    return Boolean(this.customerBenchmarkId)
  }

  @computed get marketValue(): number {
    if (this.selectedPosition) {
      return (
        flatten(
          this.portfolio?.assetClasses.map((a) => {
            return a.items
          })
        ).find(
          (assetClassItem) => assetClassItem.ticker === this.selectedPosition
        )?.marketValue ?? 0
      )
    }
    return sum(this.percentages().map((p) => p.marketValue))
  }

  @computed get showPortfolioBenchmark(): boolean {
    const selectedPortfolio = this.singleSelectedPortfolio
    if (selectedPortfolio) {
      return this.portfolioBenchmarkIds.includes(selectedPortfolio)
    }
    return false
  }

  @computed get singleSelectedPortfolio(): string | undefined {
    return this.selectedReportingPortfolios.length === 1
      ? this.selectedReportingPortfolios[0]
      : undefined
  }

  @computed get dateRangeTitle(): DateRangeValue | string {
    if (this.dateRange === CUSTOM_DATE_RANGE) {
      return (
        formatDateFull(this.startDate) + ' – ' + formatDateFull(this.endDate)
      )
    }
    const selected = this.dateRangeValues().find((d) => d.id === this.dateRange)
    return selected ? selected : this.dateRangeValues()[0]
  }

  @computed get selectedAssetClassItem(): AssetClassItem | undefined {
    if (this.selectedPosition) {
      return this.currentAssetClassItems().find(
        (e) => e.ticker === this.selectedPosition
      )
    }
  }

  @computed get additionalFilterRestrictions(): boolean {
    const selectedPortfoliosInformation: ReportingPortfolio[] = []
    this.selectedReportingPortfolios.forEach((selectedId) => {
      const portfolioInfo = AppStore.getPortfolioInformation(selectedId)
      if (portfolioInfo !== undefined) {
        selectedPortfoliosInformation.push(portfolioInfo)
      }
    })
    return selectedPortfoliosInformation.some((info) => info.isRestricted)
  }

  @computed get isInsuranceportfolioSelected(): boolean {
    const selectedPortfoliosInformation: ReportingPortfolio[] = []
    this.selectedReportingPortfolios.forEach((selectedId) => {
      const portfolioInfo = AppStore.getPortfolioInformation(selectedId)
      if (portfolioInfo !== undefined) {
        selectedPortfoliosInformation.push(portfolioInfo)
      }
    })

    return selectedPortfoliosInformation.some((info) => info.isInsurance)
  }

  @computed get title(): string | undefined {
    if (this.selectedPosition) {
      return (
        this.positions().find((i) => i.id === this.selectedPosition)?.name || ''
      )
    }
    if (this.selectedAdditionalInfo) {
      const additionalInfoName =
        this.assetAdditionalInfos().find(
          (i) => i.id === this.selectedAdditionalInfo
        )?.name || ''
      const categoryName =
        this.assetCategories().find((i) => i.id === this.selectedCategory)
          ?.name || ''
      return categoryName + ' - ' + additionalInfoName
    }
    if (this.selectedCategory) {
      const categoryName =
        this.assetCategories().find((i) => i.id === this.selectedCategory)
          ?.name || ''
      const assetClassName =
        this.allAssetClasses().find((i) => i.id === this.assetClass)?.name || ''
      return assetClassName + ' - ' + categoryName
    }
    if (this.assetClass) {
      return (
        this.allAssetClasses().find((i) => i.id === this.assetClass)?.name || ''
      )
    }
  }

  dateRangeValues = (): DateRangeValue[] => {
    const dateRangeList: DateRangeValue[] = []
    dateRangeList.push({
      id: FROM_BEGINNING,
      value: 'from-start',
    })
    dateRangeList.push({
      id: LAST_YEAR,
      value: 'year-with-number-' + (new Date().getFullYear() - 1).toFixed(0),
    })
    dateRangeList.push({
      id: PAST_YEAR,
      value: 'year',
    })
    dateRangeList.push({
      id: SIX_MONTHS,
      value: 'months-6',
    })
    dateRangeList.push({
      id: YEAR_TO_DATE,
      value: 'start-of-year',
    })
    dateRangeList.push({
      id: PAST_MONTH,
      value: 'month',
    })

    return dateRangeList
  }

  allAssetClasses = (): AssetClass[] => {
    return (
      this.portfolio?.assetClasses.filter(
        (i) => i.id !== ASSET_CLASS_ID_CASH
      ) || []
    )
  }

  currentAssetClass = (): AssetClass => {
    return (
      this.allAssetClasses().find((a) => a.id === this.assetClass) ||
      this.allAssetClasses()[0]
    )
  }

  assetCategories = () => {
    return uniqBy(
      this.currentAssetClassItems().map((i) => ({
        id: i.assetCategoryId,
        color: assetClassColors[this.assetClass || Colors.text],
        name: formatAssetCategoryName(i.assetCategoryName),
      })),
      'id'
    )
  }

  assetAdditionalInfos = () =>
    // Only for category B05 Strukturoidut tuotteet
    uniqBy(
      this.currentAssetClassItems()
        .filter(
          (i) =>
            i.assetCategoryId === this.selectedCategory &&
            i.assetCategoryId === 'B05'
        )
        .map((i) => ({
          id: i.assetAdditionalInfoId,
          name: i.assetAdditionalInfoName,
        }))
        .filter((i) => i.name !== null),
      'id'
    )

  percentagesSorted = () => {
    const items = this.percentages()
    return reverse(sortBy(items, 'marketValue')).map((a, index) => ({
      ...a,
      color: this.assetClass
        ? assetClassColorsRgb[this.assetClass || Colors.text].replace(
            ', 1)',
            `, ${1 - index / items.length})`
          )
        : assetClassColorsRgb[a.id],
    }))
  }

  percentages = (): PercentagePieItem[] => {
    if (!this.portfolio?.assetClasses) {
      return []
    }
    if (
      this.assetAdditionalInfos().length > 0 &&
      this.selectedCategory &&
      !this.selectedAdditionalInfo
    ) {
      const total = sumBy(
        this.currentAssetClassItems().filter(
          (i) => i.assetCategoryId === this.selectedCategory
        ),
        (curValue) => curValue.marketValue
      )
      const items = this.assetAdditionalInfos()
      return items.map((a) => {
        const marketValue = sumBy(
          this.currentAssetClassItems().filter(
            (i) => i.assetAdditionalInfoId === a.id
          ),
          (curValue) => curValue.marketValue
        )
        return {
          id: a.name,
          name: a.name,
          color: Colors.text,
          percentage: (marketValue / total) * 100,
          marketValue,
        }
      })
    }
    if (this.selectedAdditionalInfo) {
      const total = sumBy(
        this.currentAssetClassItems().filter(
          (i) => i.assetAdditionalInfoId === this.selectedAdditionalInfo
        ),
        (curValue) => curValue.marketValue
      )

      return this.assetCategoryItems()
        .filter((i) => i.assetAdditionalInfoId === this.selectedAdditionalInfo)
        .map((a) => {
          const getSecurityName = (): string =>
            AppStore.currentLanguage === fiKey ? a.nameFi : a.nameSv
          return {
            id: a.name,
            name: getSecurityName(),
            color: Colors.text,
            percentage: (a.marketValue / total) * 100,
            marketValue: a.marketValue,
          }
        })
    }
    if (this.selectedCategory) {
      const total = sumBy(
        this.currentAssetClassItems().filter(
          (i) => i.assetCategoryId === this.selectedCategory
        ),
        (curValue) => curValue.marketValue
      )
      return this.assetCategoryItems().map((a) => {
        const getSecurityName = (): string =>
          AppStore.currentLanguage === fiKey ? a.nameFi : a.nameSv
        return {
          id: a.name,
          name: getSecurityName(),
          color: Colors.text,
          percentage: (a.marketValue / total) * 100,
          marketValue: a.marketValue,
        }
      })
    }

    if (this.assetClass) {
      const total = sumBy(
        this.currentAssetClassItems(),
        (curValue) => curValue.marketValue
      )

      const items = this.assetCategories()
      return items.map((a, index) => {
        const marketValue = sumBy(
          this.currentAssetClassItems().filter(
            (i) => i.assetCategoryId === a.id
          ),
          (curValue) => curValue.marketValue
        )
        return {
          id: a.id,
          name: a.name,
          color: assetClassColorsRgb[this.assetClass || Colors.text].replace(
            ', 1)',
            `, ${1 - index / items.length})`
          ),
          percentage: (marketValue / total) * 100,
          marketValue,
        }
      })
    }

    return this.portfolio?.assetClasses.map((s) => ({
      id: s.id,
      name: s.name,
      color: assetClassColors[s.id] || Colors.text,
      percentage: s.percentageOfTotal,
      marketValue: s.marketValue,
    }))
  }

  currentAssetClassItems = (): AssetClassItem[] =>
    this.currentAssetClass()?.items || []

  assetCategoryItems = (): AssetClassItem[] =>
    this.currentAssetClassItems().filter(
      (i) => i.assetCategoryId === this.selectedCategory
    )

  positions = () => {
    const items = this.assetCategoryItems()
    const filteredItems = this.selectedAdditionalInfo
      ? items.filter(
          (i) => i.assetAdditionalInfoId === this.selectedAdditionalInfo
        )
      : items

    return filteredItems.map((i) => {
      const getSecurityName = (): string =>
        AppStore.currentLanguage === fiKey ? i.nameFi : i.nameSv
      return {
        id: i.ticker,
        name: getSecurityName(),
      }
    })
  }

  positionsCount = (categoryId: string) =>
    this.currentAssetClassItems().filter(
      (i) => i.assetCategoryId === categoryId
    ).length

  positionsCountInfo = (infoId: string) =>
    this.currentAssetClassItems().filter(
      (i) => i.assetAdditionalInfoId === infoId
    ).length
}

export default new ReportStore()
