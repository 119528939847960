import AccountNumberForm from '@taaleri/components/src/screens/onboarding/AccountNumberForm'
import React from 'react'
import QuestionContainer from 'screens/questions/QuestionContainer'
import ContentContainer from 'ui/container/ContentContainer'

export default function AccountNumberScreen() {
  return (
    <QuestionContainer noPadding={true}>
      <ContentContainer noPadding={true}>
        <AccountNumberForm />
      </ContentContainer>
    </QuestionContainer>
  )
}
