interface AssetClassColorIndex {
  [key: string]: string
}

// Osakkeet (A), Korot (B), käteinen (C), muut (D), fellow finance (E), impact (I)

export const assetClassColors: AssetClassColorIndex = {
  A: '#00AADA',
  B: '#2BC9AF',
  C: '#FF5FA2',
  D: '#FFAA4D',
  E: '#FFE900',
  I: '#5B618F',
}

export const assetClassColorsRgb: AssetClassColorIndex = {
  A: 'rgba(0, 170, 218, 1)',
  B: 'rgba(43, 201, 175, 1)',
  C: 'rgba(255, 95, 162, 1)',
  D: 'rgba(255, 170, 77, 1)',
  E: 'rgba(255, 233, 0, 1)',
  I: 'rgba(91, 97, 143, 1)',
}

export interface AssetClassPercentage {
  id: string
  name: string
  percentage: number
  currentPercentage?: number
}

export interface PercentagePieItem {
  id: string
  name: string
  percentage: number
  color: string
  marketValue: number
}
