import React, { useEffect, useState } from 'react'

import { authentication } from '@taaleri/core/src/services/authentication'
import Header from './Header'
import { Content } from './Content'
import Footer from './Footer'
import { View } from 'react-native'
import { getIdentity } from '@taaleri/core/src/services/storage'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import AppStore from '@taaleri/core/src/stores/AppStore'
import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import routePath, {
  routePathApp,
} from '@taaleri/components/src/navigation/routeHelper'
import {
  ROUTE_APP,
  ROUTE_SIGN_IN,
  ROUTE_TWO_FACTOR_TOGGLE,
  ROUTE_CONTRACTS,
  ROUTE_ORDERS,
  ROUTE_REPORT_ARCHIVE,
} from '@taaleri/components/src/navigation/routes'
import {
  ROUTE_IMPACT,
  ROUTE_IMPACT_PRODUCT_DETAIL,
  ROUTE_IMPACT_TOGGLE_ON,
  ROUTE_IMPACT_TOGGLE_OFF,
  ROUTE_IMPACT_TOGGLE_VERIFICATION,
  ROUTE_IMPACT_TOGGLE_DONE,
  ROUTE_IMPACT_INTRO,
  ROUTE_IMPACT_TOGGLE_CONTRACT,
  ROUTE_IMPACT_FUND_DETAIL,
} from '@taaleri/components/src/screens/impact/ImpactRoutes'
import {
  ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL,
  ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL,
  ROUTE_PORTFOLIO_PHOTO_ID,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_LIST,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE,
  ROUTE_PORTFOLIO_PAYMENT_BANK,
  ROUTE_PORTFOLIO_PAYMENT_SELECT,
  ROUTE_PORTFOLIO_PAYMENT_SUM,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM,
  ROUTE_PORTFOLIO_PAYMENT_MONTHLY,
  ROUTE_PORTFOLIO_PAYMENT_INFORMATION,
  ROUTE_PORTFOLIO_PAYMENT_CONFIRM,
  ROUTE_PORTFOLIO_BANK_TRANSFER,
  ROUTE_PORTFOLIO_BANK_TRANSFER_DONE,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_DONE,
  ROUTE_PORTFOLIO,
  ROUTE_PURCHASE_LOTS,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { ROUTE_CONTRACT } from '@taaleri/components/src/screens/contract/ContractRoutes'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import UserStore from '@taaleri/core/src/stores/UserStore'
import {
  ROUTE_IDENTITY_CARD_PHOTO_ID,
  ROUTE_IDENTITY_CARD_VALIDITY,
  ROUTE_IDENTITY_CARD_SUMMARY,
} from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'
import {
  ROUTE_FUNDS_DETAIL,
  ROUTE_FUNDS_INVEST_START,
  ROUTE_FUNDS_INVEST_DISTRIBUTION,
  ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW,
  ROUTE_FUNDS_INVEST_START_MONTHLY,
  ROUTE_DUE_DATE,
  ROUTE_MONTHLY_CONTRACT,
  ROUTE_MONTHLY_CONTRACT_PAYMENT,
  ROUTE_MONTHLY_CONTRACT_VERIFICATION,
  ROUTE_BLOCKED_CONS_CUSTOMER,
} from '@taaleri/components/src/screens/funds/FundsRoutes'
import { ROUTE_RECURRING_ORDER_DETAILS } from '@taaleri/components/src/screens/recurringorders/RecurringOrderRoutes'
import { ROUTE_PROFILE_FAQ } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import {
  ROUTE_REPORT,
  ROUTE_REPORT_PROFIT,
  ROUTE_REPORT_PROFIT_DETAIL,
  ROUTE_REPORT_TRANSACTION,
  ROUTE_REPORT_TRANSACTION_DETAIL,
  ROUTE_REPORT_DISCOUNT,
  ROUTE_REPORT_FEE,
} from '@taaleri/components/src/screens/report/ReportRoutes'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import { loginToDataLayer } from 'utils/DataLayer'
import { ROUTE_SAVINGS_PLAN } from '@taaleri/components/src/screens/SavingsPlan/SavingsPlanRoutes'
import GradientContainer from 'ui/GradientContainer'

const fullBackgroundApp = [
  ROUTE_CONTRACT,
  ROUTE_IMPACT_TOGGLE_VERIFICATION,
  ROUTE_IMPACT_TOGGLE_ON,
  ROUTE_IMPACT_TOGGLE_OFF,
  ROUTE_IMPACT_INTRO,
  ROUTE_TWO_FACTOR_TOGGLE,
  ROUTE_IMPACT_TOGGLE_DONE,
  ROUTE_BLOCKED_CONS_CUSTOMER,
  ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL,
  ROUTE_PORTFOLIO_PHOTO_ID,
  ROUTE_IDENTITY_CARD_PHOTO_ID,
  ROUTE_IDENTITY_CARD_VALIDITY,
  ROUTE_IDENTITY_CARD_SUMMARY,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL,
  ROUTE_FUNDS_INVEST_START,
  ROUTE_FUNDS_INVEST_DISTRIBUTION,
  ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW,
  ROUTE_FUNDS_INVEST_START_MONTHLY,
  ROUTE_DUE_DATE,
  ROUTE_IMPACT_TOGGLE_ON,
  ROUTE_IMPACT_TOGGLE_OFF,
  ROUTE_IMPACT_TOGGLE_VERIFICATION,
  ROUTE_IMPACT_TOGGLE_CONTRACT,
  ROUTE_IMPACT_TOGGLE_DONE,
  ROUTE_MONTHLY_CONTRACT,
  ROUTE_MONTHLY_CONTRACT_PAYMENT,
  ROUTE_MONTHLY_CONTRACT_VERIFICATION,
  ROUTE_PORTFOLIO_PAYMENT_CONFIRM,
  ROUTE_PORTFOLIO_PAYMENT_INFORMATION,
  ROUTE_PORTFOLIO_PAYMENT_BANK,
  ROUTE_PORTFOLIO_PAYMENT_SELECT,
  ROUTE_PORTFOLIO_PAYMENT_SUM,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM,
  ROUTE_PORTFOLIO_PAYMENT_MONTHLY,
  ROUTE_PROFILE_FAQ,
  ROUTE_PORTFOLIO_BANK_TRANSFER,
  ROUTE_PORTFOLIO_MONTHLY_PAYMENT_DONE,
  ROUTE_PORTFOLIO_BANK_TRANSFER_DONE,
  ROUTE_SAVINGS_PLAN,
]

const screensOtherWithoutContent = [
  ROUTE_IMPACT + '/' + ROUTE_IMPACT_PRODUCT_DETAIL,
  ROUTE_IMPACT + '/' + ROUTE_IMPACT_FUND_DETAIL,
  ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL,
  ROUTE_PURCHASE_LOTS,
  ROUTE_FUNDS_DETAIL,
  ROUTE_RECURRING_ORDER_DETAILS,
]

const screenWithGradientBackground = [
  ROUTE_PURCHASE_LOTS,
  ROUTE_REPORT_DISCOUNT,
  ROUTE_ORDERS,
  ROUTE_CONTRACTS,
  ROUTE_REPORT_ARCHIVE,
  ROUTE_REPORT_FEE,
]

const screensWithoutContent = fullBackgroundApp.concat(
  screensOtherWithoutContent
)

const exactWithoutContent = [
  ROUTE_IMPACT,
  ROUTE_REPORT + '/',
  ROUTE_REPORT,
  ROUTE_REPORT_TRANSACTION,
  ROUTE_REPORT_PROFIT,
  ROUTE_REPORT_TRANSACTION_DETAIL,
  ROUTE_REPORT_PROFIT_DETAIL,
].map(routePathApp)

function isHomeScreen(currentRoute: string) {
  return [
    routePath(ROUTE_APP),
    routePath(ROUTE_APP) + '/',
    routePathApp(ROUTE_PORTFOLIO),
  ].includes(currentRoute)
}

const wrapWithContent = (children: any, currentRoute: string) => {
  const wrapWithGradientContainer = screenWithGradientBackground.some(
    (c: string) => currentRoute.startsWith(routePathApp(c))
  )

  if (wrapWithGradientContainer) {
    return <GradientContainer>{children}</GradientContainer>
  }

  const wrap =
    !screensWithoutContent.some(
      (c: string) =>
        currentRoute.startsWith(routePathApp(c)) ||
        exactWithoutContent.includes(currentRoute) ||
        isHomeScreen(currentRoute)
    ) ||
    currentRoute.includes(ROUTE_CONTRACTS) ||
    currentRoute.includes(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_LIST) ||
    currentRoute.includes(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION) ||
    currentRoute.includes(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE)
  return wrap ? <Content marginTop={true}>{children}</Content> : children
}

function AppContainer(props: PropsChildren) {
  const { children } = props
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (AppStore.portfolio) {
      setLoading(false)
      return
    }
    // TODO FIX AUTHENTICATION
    const refresh = async () => {
      const identity = await getIdentity()
      const refreshTask = AppStore.adminToken
        ? authentication.signInAdmin(identity.customerId, AppStore.adminToken)
        : authentication.refresh(identity.refreshToken)
      const { success } = await refreshTask
      if (!success) {
        UserStore.loggedIn = false
        await AppStore.signOutWeb()
        AdminStore.logoutAdmin()
        window.location.href = routePath(ROUTE_SIGN_IN)
        return
      }
      loginToDataLayer(AppStore.baseCustomerId, AppStore.customerId)
      setLoading(false)
    }
    refresh()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  const showFooter =
    (location.pathname.indexOf(ROUTE_CONTRACT) === -1 &&
      !fullBackgroundApp.map(routePathApp).includes(location.pathname)) ||
    location.pathname.indexOf(ROUTE_CONTRACTS) > -1

  return (
    <View style={{ minHeight: '100vh' }}>
      <Header />
      {wrapWithContent(children, location.pathname)}
      {showFooter && <Footer />}
    </View>
  )
}

export default observer(AppContainer)
