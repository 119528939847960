import Colors, { getChangeColor } from '@taaleri/core/src/constants/Colors'
import { removeLineBreaks } from '@taaleri/core/src/services/translationService'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { formatCurrency, sum } from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatValueAndPrice } from './Rows/CapitalGainRow'
import { SumBoxes } from './SumBoxes'

function TotalProfit() {
  const { t } = useTranslation()
  const { filteredProfitItems, profitCategory, onlyOneInstrumentSelected } =
    ProfitStore

  // Nothing to sum, nothing to show
  if (filteredProfitItems.length === 0) {
    return null
  }

  const items = []

  if (profitCategory === 'CapitalGain') {
    const totalProfit = sum(
      filteredProfitItems
        .filter((e) => e.profit && e.profit > 0)
        .map((f) => f.profit)
    )

    const totalLoss = sum(
      filteredProfitItems
        .filter((e) => e.profit && e.profit < 0)
        .map((f) => f.profit)
    )

    const netProfit = totalProfit + totalLoss

    const avgSellPrice =
      sum(
        filteredProfitItems.filter((e) => e.sellPrice).map((f) => f.sellPrice)
      ) / filteredProfitItems.length

    const avgPurchasePrice =
      sum(
        filteredProfitItems
          .filter((e) => e.purchasePrice)
          .map((f) => f.purchasePrice)
      ) / filteredProfitItems.length

    items.push({
      title: t('report.profit-total'),
      value: formatCurrency(totalProfit, true),
      color: getChangeColor(totalProfit),
      hidden: totalProfit === 0,
    })
    items.push({
      title: t('report.loss-total'),
      value: formatCurrency(totalLoss, true),
      color: getChangeColor(totalLoss),
      hidden: totalLoss === 0,
    })
    items.push({
      title: t('report.profit-loss'),
      value: formatCurrency(netProfit, true),
      color: getChangeColor(netProfit),
      hidden: netProfit === 0,
    })
    onlyOneInstrumentSelected &&
      items.push({
        title: t('report.mid-price'),
        value: formatValueAndPrice(avgPurchasePrice, avgSellPrice, false),
        color: Colors.text,
        hidden: avgPurchasePrice === 0 && avgSellPrice === 0,
      })
  } else if (profitCategory === 'Profit') {
    const totalProfit = sum(
      filteredProfitItems.map((f) => {
        const showInterest = ['Koronmaksu', 'Jälkimarkkinahyvitys'].includes(
          f.transactionTypeName
        )
        return showInterest ? f.accruedInterest : f.sellValue
      })
    )

    const totalProfitCosts = sum(
      filteredProfitItems.filter((e) => e.advanceTax).map((f) => f.advanceTax)
    )

    const net = sum(
      filteredProfitItems.map((f) => {
        const showInterest = ['Koronmaksu', 'Jälkimarkkinahyvitys'].includes(
          f.transactionTypeName
        )
        return showInterest
          ? f.accruedInterest - f.advanceTax
          : f.totalSellValue
      })
    )

    items.push({
      title: t('portfolio.total'),
      value: formatCurrency(totalProfit, true),
      color: Colors.text,
      hidden: totalProfit === 0,
    })
    items.push({
      title: removeLineBreaks(t('report.withholding-tax')),
      value: formatCurrency(totalProfitCosts, true),
      color: Colors.text,
      hidden: totalProfitCosts === 0,
    })
    items.push({
      title: t('report.net'),
      value: formatCurrency(net, true),
      color: Colors.text,
      hidden: net === 0,
    })
  }

  return <SumBoxes {...{ items }} />
}

export default observer(TotalProfit)
