import React from 'react'
import GradientContainer from 'ui/GradientContainer'
import ProfitDetailContent from '@taaleri/components/src/screens/reportprofit/ProfitDetailContent'
import { ROUTE_REPORT_PROFIT } from '@taaleri/components/src/screens/report/ReportRoutes'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { BackButton } from '@taaleri/components/src/ui/BackButton'

export function ProfitDetailScreen() {
  const { navigateApp } = useNavigation()
  return (
    <GradientContainer>
      <>
        <BackButton
          onPress={() => {
            ProfitStore.skipFetching = true
            navigateApp(ROUTE_REPORT_PROFIT)
          }}
          style={{ marginBottom: Spacings.S24 }}
        />
        <ProfitDetailContent />
      </>
    </GradientContainer>
  )
}
