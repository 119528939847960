import React from 'react'

import QuestionContainer from 'screens/questions/QuestionContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import IdentityValidityForm from '@taaleri/components/src/screens/onboarding/IdentityValidityForm'
import { useHistory } from 'react-router-dom'
import ContentContainer from 'ui/container/ContentContainer'

export default function IdentityValidityScreen() {
  const history = useHistory()
  return (
    <QuestionContainer noPadding={true}>
      <ContentContainer noPadding={true}>
        <TopBottomContainer>
          <IdentityValidityForm navigate={history.push} />
        </TopBottomContainer>
      </ContentContainer>
    </QuestionContainer>
  )
}
