import { RouteProps, Route } from 'react-router'
import React from 'react'
import UserStore from '@taaleri/core/src/stores/UserStore'
import { observer } from 'mobx-react'
import SignInScreen from 'screens/signin/SignInScreen'

const PrivateRoute = (props: RouteProps) => {
  return UserStore.loggedIn ? <Route {...props} /> : <SignInScreen />
}

export default observer(PrivateRoute)
