import { useState, useEffect } from 'react'
import { Fund } from '@taaleri/core/src/models/Fund'
import { fundListAll } from '@taaleri/core/src/services/funds'
import AppStore from '@taaleri/core/src/stores/AppStore'

export function useAllFunds() {
  const [allFunds, setAllFunds] = useState<Fund[]>()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  async function fetchAllFunds() {
    const cachedFunds = AppStore.allFunds
    if (cachedFunds && cachedFunds.length > 0) {
      setAllFunds(cachedFunds)
      return
    }
    setLoading(true)
    setError(false)
    const result = await fundListAll()
    if (result.response && result.success && result.response.length > 0) {
      setAllFunds(result.response)
      AppStore.allFunds = result.response
      setError(false)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllFunds()
  }, [])

  return { allFunds, error, retry: fetchAllFunds, loading }
}
