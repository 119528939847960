import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import FlexRow from '../FlexRow'

import { PaddingHorizontal } from '../PaddingHorizontal'
import Subtitle from './Subtitle'
import InfoButton from '../InfoButton'

const SubtitleSection: React.FC<{ style?: StyleProp<ViewStyle> }> = ({
  children,
  style,
}) => (
  <PaddingHorizontal>
    <Subtitle style={[styles.title, style]}>{children}</Subtitle>
  </PaddingHorizontal>
)

export const SubtitleSectionNoPadding: React.FC<{
  style?: StyleProp<ViewStyle>
}> = ({ children, style }) => (
  <Subtitle style={[styles.title, style]}>{children}</Subtitle>
)

export const SubtitleSectionWithInfo: React.FC<{
  style?: StyleProp<ViewStyle>
  infoTitle?: string
  infoText: string
}> = ({ children, infoTitle, infoText, style }) => (
  <PaddingHorizontal>
    <FlexRow
      style={[
        {
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        },
        styles.title,
        style,
      ]}
    >
      <Subtitle style={{ flex: 1, marginBottom: 0 }}>{children}</Subtitle>
      <View style={{ marginTop: 2 }}>
        <InfoButton title={infoTitle} text={infoText} />
      </View>
    </FlexRow>
  </PaddingHorizontal>
)

export default SubtitleSection

export const styles = StyleSheet.create({
  title: {
    marginTop: Spacings.S32,
    marginBottom: Spacings.S16,
  },
})
