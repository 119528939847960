import React from 'react'
import BackgroundContainer from 'ui/container/BackgroundContainer'
import { TwoFactorVerification } from '@taaleri/components/src/screens/signin/TwoFactorVerification'

export default function TwoFactorAuthScreen() {
  return (
    <BackgroundContainer contentType="centered">
      <TwoFactorVerification />
    </BackgroundContainer>
  )
}
