import React from 'react'
import PaymentMonthlyContent from '@taaleri/components/src/screens/portfolio/PaymentMonthlyContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { PaymentSteps } from '@taaleri/components/src/components/Steps'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { createSavingsPlan } from '@taaleri/core/src/services/savingsPlan'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ROUTE_SAVINGS_PLAN } from '@taaleri/components/src/screens/SavingsPlan/SavingsPlanRoutes'
import { useTranslation } from 'react-i18next'

function PaymentMonthlyScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <>
      <PaymentSteps currentStep={2} />
      <ContentContainerWide>
        <PaymentMonthlyContent />
        <Buttons
          title={t('invest.recurring-text')}
          onPress={async () => {
            const { success } = await createSavingsPlan(
              AppStore.customerId,
              AppStore.newInvestment
            )
            if (success) {
              AppStore.setShowSavingsPlanDoneModal(true)
              navigateApp(ROUTE_SAVINGS_PLAN)
            }
          }}
        />
      </ContentContainerWide>
    </>
  )
}

export default observer(PaymentMonthlyScreen)
