import {
  QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY,
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
} from '@taaleri/components/src/screens/questions/questions'

import { BasicContractResponse, BasicContract } from './../models/Contract'
import { OrderPdf, ContractPdf } from './../models/ContractPdf'
import { toBasicContract } from './contract/toBasicContract'
import { customers } from './customers'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { api } from '../api/api'
import { ProductKnowledgeOption } from '../constants/Options'
import Countries from '../models/Countries'
import { Identity } from '../models/Identity'
import { CONFLICT, ServiceResponse } from '../models/ServiceResponse'
import AppStore from '../stores/AppStore'
import QuestionStore from '../stores/QuestionStore'
import logger from '../utils/logger'

export async function loadBasicInfo(): Promise<ServiceResponse<void>> {
  try {
    const customerBasicInfo = await customers.getCustomerBasicInfo(
      AppStore.customerId
    )

    if (customerBasicInfo) {
      QuestionStore.customerMaybe = customerBasicInfo.customer
      QuestionStore.regulatoryQuestions = customerBasicInfo.requlatoryQuestions
      QuestionStore.answers = customerBasicInfo.answers
      QuestionStore.monthlyIncome = customerBasicInfo.monthlyIncome
      QuestionStore.monthlyExpenses = customerBasicInfo.monthlyExpenses
      QuestionStore.monthlyCapitalIncome =
        customerBasicInfo.monthlyCapitalIncome
      QuestionStore.basicInfoLoaded = true
      QuestionStore.monthlyForeignMoneyTransfersCountry =
        customerBasicInfo.privateMonetaryTransactions.monthlyForeignMoneyTransfersCountries.map(
          (code) => Countries.find((c) => c.code === code)!
        )
      QuestionStore.purposeOfForeignMoneyTransfers =
        customerBasicInfo.privateMonetaryTransactions.purposeOfForeignMoneyTransfers
      QuestionStore.purposeOfForeignMoneyTransfersOther =
        customerBasicInfo.privateMonetaryTransactions
          .purposeOfForeignMoneyTransfersOther ?? ''
      QuestionStore.intendedUseOfCash =
        customerBasicInfo.privateMonetaryTransactions.intendedUseOfCash
      QuestionStore.intendedUseOfCashOther =
        customerBasicInfo.privateMonetaryTransactions.intendedUseOfCashOther
      return { success: true }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e as any, 'loadBasicInfo', 'fatal')
    return { success: false, error }
  }
}

export async function loadForcedKnowledge(): Promise<ServiceResponse<void>> {
  try {
    const forcedKnowledge = await customers.getCustomerForcedKnowledge(
      AppStore.customerId
    )

    if (forcedKnowledge) {
      QuestionStore.updateForcedKnowledge(forcedKnowledge)
      return { success: true }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e as any, 'loadForcedKnowledge', 'fatal')
    return { success: false, error }
  }
}

export async function createContract(): Promise<
  ServiceResponse<BasicContractResponse>
> {
  try {
    const identity: Identity = await getIdentity()
    const basicContract: BasicContract = toBasicContract(
      QuestionStore.answers,
      QuestionStore.customer,
      QuestionStore.regulatoryQuestions,
      AppStore.portfolio,
      QuestionStore.riskLevel,
      QuestionStore.strategyIdentifier,
      QuestionStore.discountCode
    )

    const assetManagementFamiliarity = QuestionStore.getAnswer(
      QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY
    ) as string

    basicContract.basicInfo.knowledgeDiscretionaryAssetManagement =
      assetManagementFamiliarity || ProductKnowledgeOption.DoesNotKnow

    basicContract.basicInfo.knowledgeCapitalInvestments =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeCapitalSecuredStructuredProducts =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeCombinationFunds =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeCommodities =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeComplexDebt =
      ProductKnowledgeOption.DoesNotKnow

    basicContract.basicInfo.knowledgeEquities =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeFixedIncome =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeLeveragedSecurities =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeMoneyMarketFunds =
      ProductKnowledgeOption.DoesNotKnow
    basicContract.basicInfo.knowledgeUnsecuredStructuredProducts =
      ProductKnowledgeOption.DoesNotKnow
    const { smsConfirmationId, assetContractId, basicInfoContractId } =
      await api().customer.createContract(identity, basicContract)

    logger.devInfo('basicInfoPost response', smsConfirmationId)

    if (smsConfirmationId) {
      return {
        success: true,
        response: { smsConfirmationId, assetContractId, basicInfoContractId },
      }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e as any, 'createContract', 'fatal')
    return { success: false, error }
  }
}

export async function updateStrategy(): Promise<
  ServiceResponse<BasicContractResponse>
> {
  try {
    const identity: Identity = await getIdentity()
    const allowIlliquid =
      QuestionStore.getAnswer(QUESTION_ILLIQUID_ASSETS_ALLOWED) !== 'None'
    const strategyIdentifier = QuestionStore.strategyIdentifier
    const response = await api().customer.updateStrategy(
      identity,
      allowIlliquid,
      strategyIdentifier
    )

    logger.devInfo('update strategy response', response.smsConfirmationId)

    if (response.smsConfirmationId && response.assetContractId) {
      return { success: true, response }
    }
    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e as any, 'updateStrategy', 'fatal')
    return { success: false, error }
  }
}

export async function updateContract(): Promise<
  ServiceResponse<BasicContractResponse>
> {
  try {
    const identity: Identity = await getIdentity()
    const basicContract: BasicContract = toBasicContract(
      QuestionStore.answers,
      QuestionStore.customer,
      QuestionStore.regulatoryQuestions,
      AppStore.portfolio,
      AppStore.isDigiDiscretionary ? QuestionStore.riskLevel : undefined,
      AppStore.isDigiDiscretionary
        ? QuestionStore.strategyIdentifier
        : undefined
    )
    logger.devInfo('basicContract', basicContract)
    const { smsConfirmationId, assetContractId } =
      await api().customer.basicInfoPost(identity, basicContract)

    logger.devInfo('basicInfoPost response', smsConfirmationId)

    if (smsConfirmationId) {
      return { success: true, response: { smsConfirmationId, assetContractId } }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e as any, 'updateContract', 'fatal')
    return { success: false, error }
  }
}

export async function getContractDocument(
  contractId: string
): Promise<ServiceResponse<string>> {
  try {
    const identity: Identity = await getIdentity()

    const response = await api().customer.getContractDocument(
      identity,
      contractId
    )

    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e as any, 'getContractDocument', 'fatal')
    return { success: false, error }
  }
}

export async function getMultipleContracts(
  contractIds: string[]
): Promise<ServiceResponse<string>> {
  try {
    const identity: Identity = await getIdentity()

    const response = await api().customer.getMultipleContracts(
      identity,
      contractIds
    )

    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e as any, 'getMultipleContracts', 'fatal')
    return { success: false, error }
  }
}

export async function contractPdfList(
  customerId: number,
  isPrivate: boolean
): Promise<ServiceResponse<ContractPdf[]>> {
  try {
    const identity: Identity = await getIdentity()

    const response = await api().contract.contracts(identity, customerId)
    let signedContracts: ContractPdf[] = []

    if (isPrivate) {
      signedContracts = response.filter(
        (c) => !['rejected', 'sent_for_banker_approval'].includes(c.state)
      )
    } else {
      signedContracts = response.filter((c) =>
        [
          'finished',
          'opening_service',
          'review',
          'reviewed',
          'signed',
        ].includes(c.state)
      )
    }
    return { success: true, response: signedContracts }
  } catch (e) {
    const error = handleErrors(e as any, 'contractPdfList')
    return { success: false, error }
  }
}

export async function contractOrdersPdfList(
  customerId: number
): Promise<ServiceResponse<OrderPdf[]>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().contract.contractOrders(identity, customerId)
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e as any, 'contractOrdersPdfList')
    return { success: false, error }
  }
}
