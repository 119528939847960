import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  YearArchive,
  getYearlyReportOrder,
} from '@taaleri/core/src/models/ReportArchive'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { BottomView, MiddleView, SectionDivider, TopView } from './BoxSections'
import ButtonRow from './ButtonRow'
import { toReportLink } from './ReportArchiveContent'
import ReportListItem from './ReportListItem'
import { openUrl } from '../../ui/openUrl'
import { TextDefault } from '../../ui/text/UiText'

function YearsBox({ years, token }: { years: YearArchive; token: string }) {
  const { t } = useTranslation()
  const [yearReport, setYearReport] = useState<string>(Object.keys(years)[0])

  if (years[yearReport] === undefined) {
    setYearReport(Object.keys(years)[0])
    return <View />
  }

  return (
    <>
      <TopView>
        <TextDefault type={'h4'}>
          {t('reportArchive.yearbox-year-reports')}
        </TextDefault>
      </TopView>
      <SectionDivider />
      <MiddleView>
        <ButtonRow
          selectedText={yearReport}
          texts={Object.keys(years).sort(
            (a, b) => getYearlyReportOrder(a) - getYearlyReportOrder(b)
          )}
          onClick={(r) => setYearReport(r)}
        />
      </MiddleView>
      <SectionDivider />
      <BottomView>
        <View>
          {Object.entries(years[yearReport]).map(
            ([key, value], index: number) => {
              return (
                <View key={index}>
                  <TextDefault
                    type={'h6'}
                    style={{ paddingVertical: Spacings.S16 }}
                  >
                    {t(key)}
                  </TextDefault>
                  {value.map((report, index2) => (
                    <ReportListItem
                      key={index2}
                      report={report}
                      onPress={() => openUrl(toReportLink(report.path, token))}
                    />
                  ))}
                </View>
              )
            }
          )}
        </View>
      </BottomView>
    </>
  )
}

export default YearsBox
