import { SmallVersionNumber } from '@taaleri/components/src/components/VersionNumber'
import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components'

const FooterContainer = styled.div`
  background-color: ${Colors.chartLineGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
`
export default function Footer() {
  return (
    <>
      <FooterContainer style={{ marginTop: 20 }}>
        <AktiaLogo small={true} style={{ paddingTop: 20 }} />
      </FooterContainer>
      <View
        style={{
          alignItems: 'flex-end',
          backgroundColor: Colors.chartLineGrey,
          width: '100%',
          paddingRight: 20,
        }}
      >
        <SmallVersionNumber />
      </View>
    </>
  )
}
