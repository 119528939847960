import React from 'react'
import RecommendationBox from './RecommendationBox'
import Box from '../../ui/box/Box'
import RecommendationsHeader from './RecommendationsHeader'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '../../navigation/useNavigation'
import { fundIntroRoute, startInvest } from '../portfolio/PortfolioRoutes'
import { ROUTE_IMPACT_INTRO } from '../impact/ImpactRoutes'
import { ROUTE_REPORT_DISCOUNT } from '../report/ReportRoutes'
import { useTranslation } from 'react-i18next'

function RecommendationsBox() {
  const { navigate, navigateApp } = useNavigation()
  const recommendations = []
  const { t } = useTranslation()

  // if (AppStore.isConsultative && AppStore.isBaseCustomerActive) {
  //   recommendations.push(
  //     <RecommendationBox
  //       text={`${t('recommendations.investinFunds')}`}
  //       onPress={() => {
  //         startInvest()
  //         navigate(fundIntroRoute())
  //       }}
  //       type="fundBox"
  //       key="fundBox"
  //     />
  //   )
  // }
  // if (AppStore.isDigiEtfCustomer) {
  //   recommendations.push(
  //     <RecommendationBox
  //       text={`${t('recommendations.impakti')}`}
  //       onPress={() => {
  //         navigateApp(ROUTE_IMPACT_INTRO)
  //       }}
  //       type="impactBox"
  //       key="impactBox"
  //     />
  //   )
  // }

  return recommendations.length !== 0 ? (
    <Box style={{ marginBottom: Spacings.S24 }}>
      <RecommendationsHeader />
      {recommendations}
    </Box>
  ) : null
}

export default observer(RecommendationsBox)
