import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextDefault } from '../../../ui/text/UiText'
import TransactionRowContainer from '../Rows/TransactionRowContainer'

function OpenTransactionHeader() {
  const { t } = useTranslation()
  return (
    <TransactionRowContainer hideBorder={true}>
      <TextDefault
        type="h6"
        style={{ flex: 4, paddingHorizontal: Spacings.S2 }}
      >
        {t('portfolio.text')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 2, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('reportTransaction.event-type')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 3, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('security')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 2, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('report.term')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('reportTransaction.total-value')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        PDF
      </TextDefault>
    </TransactionRowContainer>
  )
}

export default OpenTransactionHeader
