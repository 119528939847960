import React, { useEffect, useState } from 'react'
import ReportOrderStore from '@taaleri/core/src/stores/ReportOrderStore'
import LoadingIndicator from '../../ui/LoadingIndicator'
import {
  ReportOrder,
  REPORTS,
  REPORT_FILE_TYPES,
  reportTimePeriods,
} from '@taaleri/core/src/models/ReportOrders'
import { getReportOrders } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import Box from '../../ui/box/Box'
import { Paragraph } from '../../ui/text/Paragraph'
import { Formik } from 'formik'
import UiForm from '../../ui/form/UiForm'
import UiButton from '../../ui/UiButton'
import { Dropdown } from '../../ui/form/Dropdown'
import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import without from 'lodash/without'
import findIndex from 'lodash/findIndex'
import { View, StyleSheet } from 'react-native'
import UiListItemSwitchText from '../../ui/form/UiListItemSwitchText'
import SubtitleSection from '../../ui/text/SubtitleSection'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import Colors from '@taaleri/core/src/constants/Colors'
import { reportOrderNew } from './reportOrderNew'
import Title, { TitleLarge } from '../../ui/text/Title'
import ErrorMessage from '../../ui/form/ErrorMessage'
import { IconCheckmarkCircle } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { isWeb } from '../../constants/Platforms'
import useLayout from '../../ui/useLayout'
import { IconCircle } from '../../ui/icons/CircleIcons'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
  customerId: string
  navigateToDelivery: (customerId: string, id: string) => void
}

export default function ReportOrderReportsContent({
  id,
  customerId,
  navigateToDelivery,
}: Props) {
  const { t } = useTranslation()
  const [order, setOrder] = useState<ReportOrder>()
  const [error, setError] = useState<boolean>(false)
  const { isNonSmall } = useLayout()

  useEffect(() => {
    const currentOrder = ReportOrderStore.orders.find(
      (o) => o.id === Number(id) && o.customerId === customerId
    )

    async function findReportOrders() {
      const { success, response } = await getReportOrders(AppStore.customerId)
      if (success && response) {
        setOrder(
          response.find(
            (o) => o.id === Number(id) && o.customerId === customerId
          )
        )
      }
    }

    if (currentOrder) {
      setOrder(currentOrder)
    } else {
      if (id === 'new') {
        setOrder(ReportOrderStore.reportOrder || reportOrderNew())
      } else {
        findReportOrders()
      }
    }
  }, [])

  if (!order) {
    return <LoadingIndicator />
  }

  return (
    <Formik
      initialValues={order}
      onSubmit={async (values: ReportOrder) => {
        if (values.selectedReports.length === 0) {
          setError(true)
          return
        }
        ReportOrderStore.reportOrder = values
        navigateToDelivery(customerId, id)
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <UiForm>
          {!isWeb && <Title>{t('reportOrder.choose-reports')}</Title>}
          {isWeb && <TitleLarge>{t('reportOrder.choose-reports')}</TitleLarge>}
          <View>
            {REPORTS.map((report) => {
              const reportType = report.reportType
              const selected = values.selectedReports
              const isSelected = selected.includes(report.reportType)
              const index = findIndex(
                values.subscriptions,
                (r) => r.reportType === reportType
              )
              return (
                <Box
                  key={report.reportType}
                  style={{
                    marginBottom: Spacings.S24,
                    paddingBottom: Spacings.S24,
                  }}
                >
                  <UiTouchable
                    onPress={() => {
                      if (isSelected) {
                        setFieldValue(
                          'selectedReports',
                          without(selected, reportType)
                        )
                      } else {
                        setError(false)
                        setFieldValue(
                          'selectedReports',
                          selected.concat([report.reportType])
                        )
                      }
                    }}
                  >
                    <FlexRow style={{ alignItems: 'center' }}>
                      {isSelected && (
                        <IconCheckmarkCircle style={styles.icon} />
                      )}
                      {!isSelected && <IconCircle style={styles.icon} />}

                      <PaddingHorizontal style={{ flex: 1 }}>
                        <FlexRow
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingVertical: Spacings.S16,
                          }}
                        >
                          <TextDefault
                            type="h3"
                            style={{
                              marginRight: Spacings.S24,
                            }}
                          >
                            {t(report.name)}
                          </TextDefault>
                        </FlexRow>

                        <Paragraph>{t(report.description)}</Paragraph>
                      </PaddingHorizontal>
                    </FlexRow>
                  </UiTouchable>

                  {isSelected && (
                    <View
                      style={{
                        borderTopWidth: 1,
                        borderTopColor: Colors.border,
                        paddingTop: Spacings.S24,
                      }}
                    >
                      {!report.hideTime && (
                        <View>
                          <PaddingHorizontal
                            style={{ marginBottom: Spacings.S16 }}
                          >
                            <TextMedium>
                              {t('reportOrder.select-time-interval')}
                            </TextMedium>
                          </PaddingHorizontal>
                          <Dropdown
                            name={`subscriptions[${index}].timePeriod`}
                            options={reportTimePeriods(report.reportType).map(
                              (period) => ({
                                value: period.value,
                                label: t(period.label, { day: period.value }),
                              })
                            )}
                            padding={true}
                          />
                        </View>
                      )}
                      {!report.hideFormat && (
                        <View style={{ marginBottom: Spacings.S16 }}>
                          <SubtitleSection>{t('file-format')}</SubtitleSection>
                          <CheckListSingleForm
                            name={`subscriptions[${index}].format`}
                            options={REPORT_FILE_TYPES}
                            padding={true}
                          />
                        </View>
                      )}

                      {report.reportType === 'Purchaselot' && (
                        <UiListItemSwitchText
                          name={`subscriptions[${index}].sumLevel`}
                          title={t('reportOrder.breakdown-by-purchase')}
                          yesValue="Purchaselot"
                          noValue="Security"
                          topDivider={false}
                        />
                      )}
                      {!report.hideLevel &&
                        values.subscriptions[index].format === 'Pdf' && (
                          <UiListItemSwitchText
                            name={`subscriptions[${index}].level`}
                            title={t('reportOrder.by-portfolio')}
                            yesValue="ByPortfolio"
                            noValue="Aggregated"
                            topDivider={false}
                          />
                        )}
                    </View>
                  )}
                </Box>
              )
            })}
          </View>

          {error && <ErrorMessage message={t('validation.choose-report')} />}

          <UiButton
            title={t('button.continue')}
            onPress={isWeb ? () => '' : handleSubmit}
            containerStyle={{
              marginTop: Spacings.S24,
              marginBottom: Spacings.S24,
              alignSelf: isNonSmall ? 'flex-end' : 'stretch',
            }}
          />
        </UiForm>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: Spacings.S24,
    width: 24,
    height: 24,
    justifyContent: 'center',
  },
})
