import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import useLayout from '../../ui/useLayout'
import { OwnershipRowStyles } from './OwnershipRow'
import { HeadingItem, HeadingText } from './OwnerShipHeadingSimple'

interface Props {
  isCash?: boolean
  isBond?: boolean
  isStructured?: boolean
  hideDocumentColumn?: boolean
  hideYieldToMaturity?: boolean
  hideDuration?: boolean
  hideCommitmentAmount?: boolean
  hideAccruedInterest?: boolean
}

export function OwnershipHeadingAdvanced({
  isBond,
  isStructured,
  hideDocumentColumn,
  hideDuration,
  hideYieldToMaturity,
  hideCommitmentAmount,
  hideAccruedInterest,
  isCash,
}: Props) {
  const { isSmall, isMedium } = useLayout()

  if (isSmall) {
    return null
  }

  const styles = OwnershipRowStyles
  const headingItems: HeadingItem[] = [
    {
      text: 'portfolio.instrument-name',
      style: styles.title,
    },
    {
      text: 'portfolio.report',
      style: [styles.downloadLink, { textAlign: 'center' }],
      hidden: hideDocumentColumn,
    },
    {
      text: 'portfolio.investment-commitment',
      hidden: hideCommitmentAmount,
    },
    {
      text: 'portfolio.amount',
      hidden: isMedium || isCash,
    },
    {
      text: 'portfolio.purchase-price',
      style: styles.numberNarrow,
      hidden: isMedium,
    },
    { text: 'portfolio.acquisition', style: styles.number, hidden: isMedium },
    {
      text: 'portfolio.market-price',
      style: styles.numberNarrow,
      hidden: isMedium,
    },
    {
      text: isBond
        ? isStructured
          ? 'portfolio.net-worth-include-interest'
          : 'portfolio.net-worth-no-interest'
        : 'netWorth',
    },
    {
      text: 'portfolio.accumulated-interest',
      hidden: !isBond || isStructured || hideAccruedInterest,
    },
    {
      text: isBond ? 'portfolio.share-include-interest' : 'portfolio.share',
      style: styles.numberNarrow,
    },
    {
      text: isBond
        ? 'portfolio.change-euro-include-interest'
        : 'portfolio.change-euro',
      hidden: isMedium,
    },
    {
      text: isBond
        ? 'portfolio.change-percent-include-interest'
        : 'portfolio.change-percent',
      style: styles.numberNarrow,
      hidden: isMedium,
    },
    {
      text: 'portfolio.yield',
      hidden: !isBond || isStructured || hideYieldToMaturity,
    },
    {
      text: 'portfolio.duration',
      style: styles.numberNarrow,
      hidden: !isBond || isStructured || hideDuration,
    },
  ]

  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingBottom: Spacings.S16,
        marginRight: Spacings.S24,
      }}
    >
      {headingItems.map((props) => (
        <HeadingText {...props} key={props.text} />
      ))}
    </FlexRow>
  )
}
