import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  AktiaCustomerPortfolio,
  CONTENT_OF_PORTFOLIO,
  PreserverAsset,
  YEARLY_BANK_STATEMENT,
  YearStatement,
} from '@taaleri/core/src/models/ReportArchive'
import { getDate } from 'date-fns'
import getQuarter from 'date-fns/get_quarter'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { TopView, SectionDivider, MiddleView } from './BoxSections'
import CollapsibleReportList, {
  CollapsibleReportListItemComponent,
  toPublicationServiceLink,
} from './CollapsibleReportList'
import { DividerNoMargin } from '../../ui/Divider'
import { FlexContainer } from '../../ui/FlexContainer'
import Spacer from '../../ui/Spacer'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronUp, IconChevronDown } from '../../ui/icons/ChevronIcons'
import { openUrl } from '../../ui/openUrl'
import { TextDefault } from '../../ui/text/UiText'

const QuartalButton = (props: any) => {
  const Container = props.disabled ? View : UiTouchable

  const containerStyle = props.borderRight
    ? {
        flex: 1,
        borderBottomWidth: 1,
        borderColor: Colors.gray40,
        borderRightWidth: 1,
      }
    : { flex: 1, borderBottomWidth: 1, borderColor: Colors.gray40 }

  const selectedStyle = props.selected
    ? {
        borderTopWidth: 5,
        borderTopColor: Colors.primary,
        borderBottomWidth: 0,
      }
    : {}

  const disabledStyle = props.disabled && {
    cursor: 'not-allowed',
    backgroundColor: Colors.backgroundGray,
  }

  return (
    <Container
      onPress={!props.disabled && props.onPress}
      style={[
        containerStyle,
        selectedStyle,
        disabledStyle,
        {
          flexDirection: 'row',
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          paddingVertical: 14,
        },
      ]}
    >
      <TextDefault style={props.disabled && { color: Colors.gray20 }}>
        {props.children}
      </TextDefault>
    </Container>
  )
}

export const FIRST_QUARTAL = 'Q1'
export const SECOND_QUARTAL = 'Q2'
export const THIRD_QUARTAL = 'Q3'
export const FOURTH_QUARTAL = 'Q4'

export const AktiaCustomerBox = ({
  reports,
}: {
  reports: AktiaCustomerPortfolio[]
}) => {
  const { t } = useTranslation()
  const [listOpen, setListOpen] = useState<boolean>(false)

  const getCurrentQuarter = (quartal: string) => {
    switch (quartal) {
      case FIRST_QUARTAL:
        return 1
      case SECOND_QUARTAL:
        return 2
      case THIRD_QUARTAL:
        return 3
      case FOURTH_QUARTAL:
        return 4
      default:
        return 1
    }
  }

  const isThereReportsToShow = (quartal: string) => {
    if (
      reports.filter(
        (report) =>
          getQuarter(new Date(report.endDate)) === getCurrentQuarter(quartal)
      ).length > 0
    ) {
      return true
    }
    return false
  }

  const getQuartalHavingReports = () => {
    if (isThereReportsToShow(FOURTH_QUARTAL)) {
      return FOURTH_QUARTAL
    }
    if (isThereReportsToShow(THIRD_QUARTAL)) {
      return THIRD_QUARTAL
    }
    if (isThereReportsToShow(SECOND_QUARTAL)) {
      return SECOND_QUARTAL
    }
    if (isThereReportsToShow(FIRST_QUARTAL)) {
      return FIRST_QUARTAL
    }
    return ''
  }

  const [selectedQuartal, setSelectedQuartal] = useState<string>('')

  useEffect(() => {
    setSelectedQuartal(getQuartalHavingReports())
  }, [])

  const reportsToShowOnQuartal = reports.filter(
    (report) =>
      getQuarter(new Date(report.endDate)) ===
      getCurrentQuarter(selectedQuartal)
  )

  const openDocument = async (item: AktiaCustomerPortfolio) => {
    openUrl(
      await toPublicationServiceLink(
        item.documentFileId,
        item.reportId,
        item.customerId
      )
    )
  }

  return (
    <>
      <TopView>
        <TextDefault type={'h4'}>
          {t('reportArchive.financial-reports')}
        </TextDefault>
      </TopView>
      {reports.length > 0 && (
        <>
          <SectionDivider />
          <MiddleView>
            <View
              style={{
                backgroundColor: Colors.white,
                borderWidth: 1,
                borderColor: Colors.gray40,
                borderRadius: 6,
                overflow: 'hidden',
                marginBottom: Spacings.S8,
                borderBottomWidth: reportsToShowOnQuartal.length > 0 ? 1 : 0,
              }}
            >
              <View>
                <FlexContainer
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <QuartalButton
                    onPress={() => setSelectedQuartal(FIRST_QUARTAL)}
                    borderRight={true}
                    selected={selectedQuartal === FIRST_QUARTAL}
                    disabled={!isThereReportsToShow(FIRST_QUARTAL)}
                  >
                    Q1
                  </QuartalButton>
                  <QuartalButton
                    onPress={() => setSelectedQuartal(SECOND_QUARTAL)}
                    borderRight={true}
                    selected={selectedQuartal === SECOND_QUARTAL}
                    disabled={!isThereReportsToShow(SECOND_QUARTAL)}
                  >
                    Q2
                  </QuartalButton>
                  <QuartalButton
                    onPress={() => setSelectedQuartal(THIRD_QUARTAL)}
                    borderRight={true}
                    selected={selectedQuartal === THIRD_QUARTAL}
                    disabled={!isThereReportsToShow(THIRD_QUARTAL)}
                  >
                    Q3
                  </QuartalButton>
                  <QuartalButton
                    onPress={() => setSelectedQuartal(FOURTH_QUARTAL)}
                    selected={selectedQuartal === FOURTH_QUARTAL}
                    disabled={!isThereReportsToShow(FOURTH_QUARTAL)}
                  >
                    Q4
                  </QuartalButton>
                </FlexContainer>
              </View>
              <UiTouchable
                onPress={() =>
                  listOpen ? setListOpen(false) : setListOpen(true)
                }
              >
                <FlexContainer
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 16,
                  }}
                >
                  <TextDefault
                    type={'t2'}
                    style={{ flex: 3, paddingRight: Spacings.S4 }}
                  >
                    {t('portfolio.report-purchaselot')}
                  </TextDefault>
                  {listOpen ? (
                    <IconChevronUp color={Colors.text} />
                  ) : (
                    <IconChevronDown color={Colors.text} />
                  )}
                </FlexContainer>
                {listOpen && <DividerNoMargin />}
              </UiTouchable>
              {listOpen && (
                <FlexContainer style={{ flexDirection: 'column' }}>
                  {reportsToShowOnQuartal.length > 0 &&
                    reportsToShowOnQuartal.map((item, index) => {
                      const day = getDate(new Date(item.endDate))
                      const month = new Date(item.endDate).getMonth() + 1
                      const year = new Date(item.endDate).getFullYear()
                      return (
                        <React.Fragment key={index}>
                          <CollapsibleReportListItemComponent
                            onPress={() => openDocument(item)}
                            title={`${day}.${month}.${year}`}
                          />
                          {index < reports.length - 1 && <DividerNoMargin />}
                        </React.Fragment>
                      )
                    })}
                </FlexContainer>
              )}
            </View>
          </MiddleView>
          <Spacer />
        </>
      )}
    </>
  )
}
