import { getConfig } from '@taaleri/core/src/config'
import { unauthenticatedGet } from './rest'

import { IVersionApi } from './IVersionApi'

const MAINTENANCE_TIMEOUT = 5000

async function appVersion() {
  const url = `${getConfig().apiUrl}/Version`
  return unauthenticatedGet(url)
}

const NoMaintenance = {
  ongoing: false,
  header: '',
  headerSe: '',
  paragraph: '',
  paragraphSe: '',
  warning: {
    ongoing: false,
    header: '',
    headerSe: '',
    paragraph: '',
    paragraphSe: '',
  },
}

async function maintenance() {
  const url = `${getConfig().azureFunctionUrl}/api/AppStatus?code=${
    getConfig().functionKey
  }`
  try {
    const res = await unauthenticatedGet(url, MAINTENANCE_TIMEOUT)
    if (res.ongoing || res.warning.ongoing) {
      return res
    }
    return NoMaintenance
  } catch (error) {
    return NoMaintenance
  }
}

export const versionApi: IVersionApi = {
  appVersion,
  maintenance,
}
