import React from 'react'
import RecurringOrderRow, { NewRecurringOrder } from './RecurringOrderRow'
import Title from '../../ui/text/Title'
import LoadingIndicator from '../../ui/LoadingIndicator'
import Box from '../../ui/box/Box'
import ValueLabel from '../impact/ValueLabel'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { sum, formatCurrency } from '@taaleri/core/src/utils/format'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import { Divider } from '../../ui/Divider'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { observer } from 'mobx-react'
import { FundGrid } from '../funds/FundGrid'
import useLayout from '../../ui/useLayout'
import { View } from 'react-native'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { useAllFunds } from '../funds/useAllFunds'
import { useTranslation } from 'react-i18next'

function getAssetClassItem(ticker: string | null): AssetClassItem | undefined {
  if (ticker === null) {
    return undefined
  }
  return AppStore.getAssetClassItemByTickerFirst(ticker, 'consultative')
}

function RecurringOrderList() {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()

  const { loading, error, retry, allFunds } = useAllFunds()

  if (loading || !allFunds) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={retry} />
  }
  const orders = AppStore.recurringOrders

  const totalValue = sum(
    orders.map((order) => {
      const item = getAssetClassItem(order.securityId)
      return item ? item.marketValue : 0
    })
  )

  const monthlyValue = sum(orders.map((order) => order.amount))

  return (
    <>
      <View
        style={{
          marginHorizontal: isNonSmall ? 'auto' : 0,
          minWidth: isNonSmall ? 640 : 0,
          maxWidth: 640,
          marginBottom: Spacings.S40,
        }}
      >
        <Box>
          <ValueLabel
            label={`${t('questions.recurring-order-list-batch-total')}`}
            loading={false}
            value={`${formatCurrency(monthlyValue)}/${t('form.month-short')}`}
          />
          <ValueLabel
            label={`${t('questions.recurring-order-list-savings-total')}`}
            loading={false}
            value={`${formatCurrency(totalValue)}`}
          />
        </Box>
      </View>

      <>
        <Divider />
        <Title size={FontSize.S16}>
          {t('questions.recurring-month-saving')}
        </Title>
        <FundGrid>
          {[
            ...orders.map((order) => {
              const fund = allFunds.find((f) => f.ticker === order.securityId)
              const item = getAssetClassItem(order.securityId)
              return (
                <RecurringOrderRow
                  order={order}
                  fund={fund}
                  item={item}
                  key={order.id}
                />
              )
            }),
            <NewRecurringOrder key="newRecurringOrder" />,
          ]}
        </FundGrid>
      </>
    </>
  )
}

export default observer(RecurringOrderList)
