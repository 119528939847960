import { AssetClassItem } from '../models/Portfolio'
import { sum } from './format'

export function calculateTotalDuration(items: AssetClassItem[]): number {
  return calculateWeightedAverage(
    items
      .filter((e) => e.duration && e.marketValue)
      .map(({ duration, marketValue }) => {
        return { value: duration || 0, weight: marketValue }
      })
  )
}

export function calculateAverageYieldToMaturity(
  items: AssetClassItem[]
): number {
  return calculateWeightedAverage(
    items
      .filter((e) => e.yieldToMaturity && e.marketValue)
      .map(({ yieldToMaturity, marketValue }) => {
        return { value: yieldToMaturity || 0, weight: marketValue }
      })
  )
}

function calculateWeightedAverage(
  items: { weight: number; value: number }[]
): number {
  if (items.length === 0) {
    return 0
  }
  const weightSum = sum(items.map((e) => e.weight))
  if (weightSum === 0) {
    return 0
  }
  const weightedSum = sum(items.map(({ weight, value }) => weight * value))
  return weightedSum / weightSum
}
