import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import { fiKey } from '@taaleri/core/src/i18n'
import { OptionQuestion } from '@taaleri/core/src/models/Question'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import AssetManagementInfo from './AssetManagementInfo'
import LiquidityInfo from './LiquidityInfo'
import {
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  ALL_ALLOWED,
  showAssetManagementInfo,
  showLiquidityInfo,
  showEsgInfoPdfLink,
} from './questions'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import Spacer from '../../ui/Spacer'
import { ButtonSecondaryWithChildren } from '../../ui/UiButton'
import Box, { BoxWithPadding } from '../../ui/box/Box'
import BoxCheck from '../../ui/box/BoxCheck'
import { openUrl } from '../../ui/openUrl'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { TitleWithInfo } from '../../ui/text/TitleWithInfo'
import { TextDefault } from '../../ui/text/UiText'

interface Props {
  question: OptionQuestion
  onAnswer: (answer: string) => void
}

function getOptions(question: OptionQuestion, isDigiCustomer: boolean) {
  if (question.id === QUESTION_ILLIQUID_ASSETS_ALLOWED && isDigiCustomer) {
    return question.options.filter((o) => o.value !== ALL_ALLOWED)
  }
  return question.options
}

function EsgPdfLink() {
  const { t } = useTranslation()
  return (
    <BoxWithPadding backgroundColor="background">
      <Paragraph>{t('questions.esgStart.pdf-title')}</Paragraph>
      <FlexRow style={esgStyles.esgLinkRow}>
        <ButtonSecondaryWithChildren
          onPress={() =>
            // TODO change last link when swedish document available
            openUrl(
              AppStore.currentLanguage === fiKey
                ? 'https://misc.aktia.fi/data-service/documents/investment/Soveltuvuusarviointi/Kestavyystekijat/Kestavyystekijat_Aktia_Wealth.pdf'
                : 'https://misc.aktia.fi/data-service/documents/investment/Soveltuvuusarviointi/Kestavyystekijat/Hallbarhetsfaktorer_Aktia_Wealth.pdf'
            )
          }
        >
          <FlexRow>
            <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
              PDF
            </TextDefault>
            <IconDownload />
          </FlexRow>
        </ButtonSecondaryWithChildren>
      </FlexRow>
    </BoxWithPadding>
  )
}

const esgStyles = StyleSheet.create({
  esgLinkRow: {
    margin: 'auto',
  },
})

function OptionQuestionContent(props: Props) {
  const { t } = useTranslation()
  const { question, onAnswer } = props
  const {
    id,
    help,
    questionFn,
    questionInfo,
    additionalTextSection,
    boxtext1,
    boxtext2,
  } = question
  const isDigiCustomer =
    !(
      AppStore.customerMaybe && AppStore.customerMaybe.isDiscretionary === false
    ) || QuestionStore.questionMode === 'onboarding'

  const options = getOptions(question, isDigiCustomer)
  const currentAnswer = QuestionStore.getAnswer(id)
  const questionTitle = questionFn
    ? questionFn(AppStore.portfolio)
    : question.question
  const showIlliquid = showLiquidityInfo(id)
  const showDiscreationaryInfo = showAssetManagementInfo(id)
  const showEsgInfoLink = showEsgInfoPdfLink(id)
  return (
    <>
      {questionInfo ? (
        <TitleWithInfo infoText={questionInfo}>
          {t(questionTitle)}
        </TitleWithInfo>
      ) : (
        <Title>{t(questionTitle)}</Title>
      )}
      {additionalTextSection && (
        <>
          <Title>{t(additionalTextSection)}</Title>
        </>
      )}
      {boxtext1 && (
        <Box style={styles.boxStyle}>
          <Title style={styles.boxTextStyle}>{t(boxtext1)}</Title>
        </Box>
      )}
      {boxtext2 && (
        <Box style={styles.boxStyle}>
          <Title style={styles.boxTextStyle}>{t(boxtext2)}</Title>
        </Box>
      )}
      {showEsgInfoLink && (
        <>
          <EsgPdfLink />
          <Spacer />
        </>
      )}
      {help && <Paragraph>{t(help)}</Paragraph>}

      {options.map((option) => (
        <BoxCheck
          key={option.value}
          isSelected={option.value === currentAnswer}
          option={option}
          onPress={onAnswer}
        />
      ))}

      {showIlliquid && <LiquidityInfo />}
      {showDiscreationaryInfo && <AssetManagementInfo />}
    </>
  )
}

const styles = StyleSheet.create({
  boxStyle: {
    paddingHorizontal: 20,
    marginBottom: 15,
    backgroundColor: Colors.backgroundPale,
  },
  boxTextStyle: {
    fontWeight: 'normal',
    fontFamily: Fonts.avenirNextMedium,
    fontSize: 18,
  },
})

export default observer(OptionQuestionContent)
