import React from 'react'
import ImpactDisableContent from '@taaleri/components/src/screens/impact/ImpactDisableContent'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { ROUTE_IMPACT_TOGGLE_OFF } from '@taaleri/components/src/screens/impact/ImpactRoutes'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

export default function ImpactDisableScreen() {
  const { t } = useTranslation()
  const { navigateApp, navigate } = useNavigation()
  return (
    <ContentContainerBox leadingTitle={false}>
      <TopBottomContainer>
        <ImpactDisableContent />
        <Buttons
          title={t('profile.investmentSection.change-etf')}
          titleSecondary={`${t('button.return')}`}
          onPress={() => navigateApp(ROUTE_IMPACT_TOGGLE_OFF)}
          onPressSecondary={() => navigate(routePath(ROUTE_APP))}
        />
      </TopBottomContainer>
    </ContentContainerBox>
  )
}
