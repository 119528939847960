import React from 'react'
import Title from '@taaleri/components/src/ui/text/Title'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import { ContactCustomerServiceBox } from '@taaleri/components/src/screens/profile/ContactCustomerServiceBox'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

export default function ProfileAccountRemovalScreen() {
  const { t } = useTranslation()
  return (
    <>
      <Title>{t('profile.contractSection.service-end')}</Title>
      <Paragraph style={{ textAlign: isWeb ? 'justify' : 'left' }}>
        {t('profile.account-removal-text-1')}
      </Paragraph>
      <Paragraph style={{ textAlign: isWeb ? 'justify' : 'left' }}>
        {t('profile.account-removal-text-2')}
      </Paragraph>
      <ContactCustomerServiceBox />
    </>
  )
}
