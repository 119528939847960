import React, { ReactNode } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'

export interface BorderContainerProps {
  style?: StyleProp<ViewStyle>
  children: ReactNode
}

export function BorderContainer({ style, children }: BorderContainerProps) {
  return (
    <View
      style={[
        {
          borderRadius: 6,
          borderWidth: 1,
          padding: Spacings.S16,
          marginTop: Spacings.S16,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
