import { TFunction } from 'i18next'

import {
  FROM_BEGINNING,
  LAST_YEAR,
  YEAR_TO_DATE,
  PAST_MONTH,
  PAST_YEAR,
} from './YieldHistory'

interface DateRange {
  id: string
  value: string
}

export const dateRangeValues = (t: TFunction): DateRange[] => [
  {
    id: FROM_BEGINNING,
    value: t('filter.from-start'),
  },
  {
    id: LAST_YEAR,
    value: t('app.calculator.year', {
      value: (new Date().getFullYear() - 1).toFixed(0),
    }),
  },
  {
    id: PAST_YEAR,
    value: t('filter.year'),
  },
  {
    id: YEAR_TO_DATE,
    value: t('filter.start-of-year'),
  },

  {
    id: PAST_MONTH,
    value: t('filter.month'),
  },
]

export const dateRangeValueKeys = (): DateRange[] => [
  {
    id: FROM_BEGINNING,
    value: 'filter.from-start',
  },
  {
    id: LAST_YEAR,
    value: `app.calculator.year:${(new Date().getFullYear() - 1).toFixed(0)}`,
  },
  {
    id: PAST_YEAR,
    value: 'filter.year',
  },
  {
    id: YEAR_TO_DATE,
    value: 'filter.start-of-year',
  },

  {
    id: PAST_MONTH,
    value: 'filter.month',
  },
]
