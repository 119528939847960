import React from 'react'
import { StyleSheet, ViewStyle, StyleProp } from 'react-native'
import {
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useFund } from '../funds/useFunds'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import { BoxWithPadding } from '../../ui/box/Box'
import { useTranslation } from 'react-i18next'

interface RowProps {
  label: string
  value: string
  hidden?: boolean
}

function Row({ value, label, hidden }: RowProps) {
  return hidden ? null : (
    <FlexRow style={styles.valueRow}>
      <TextDefault type="t1">{label}</TextDefault>
      <TextDefault type="h5">{value}</TextDefault>
    </FlexRow>
  )
}

interface Props {
  noMargin?: boolean
  fundId?: string
  investment: number
  feeValueEstimate: number
  fee: number
  style: StyleProp<ViewStyle>
}

export default function FundPaymentDetail({
  fundId,
  investment,
  fee,
  feeValueEstimate,
  style,
}: Props) {
  const { t } = useTranslation()
  const { fund } = useFund(fundId)
  if (!fundId) {
    return null
  }
  if (!fund) {
    return null
  }

  const showFee = fee && feeValueEstimate
  return (
    <BoxWithPadding style={[{ paddingTop: 0 }, style]}>
      <TextDefault style={styles.valueRow} type="h5">
        {t('steps.payment')}
      </TextDefault>
      <Row label={t('invest.payment-target')} value={fund.name} />
      <Row label={t('steps.sum')} value={formatCurrency(investment)} />
      <Row
        label={`${t('fund.subscriptionfee')} (${formatFundProfit(fee)})`}
        value={formatCurrency(feeValueEstimate)}
        hidden={!showFee}
      />
    </BoxWithPadding>
  )
}

const styles = StyleSheet.create({
  valueRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: Spacings.S16,
    borderColor: Colors.border,
    borderBottomWidth: 1,
  },
})
