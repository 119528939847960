import Colors from '@taaleri/core/src/constants/Colors'
import { SizeLargeResponsive } from '@taaleri/core/src/constants/Fonts'
import { KeyFigure, MARKET_VALUE } from '@taaleri/core/src/constants/KeyFigures'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatNumber } from '@taaleri/core/src/utils/format'
import { isNullOrUndefined } from '@taaleri/core/src/utils/keyFigureHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View } from 'react-native'

import { DropDownBox } from '../../components/DropDownBox'
import FlexRow from '../../ui/FlexRow'
import { IconInfo } from '../../ui/Icons'
import InfoButton from '../../ui/InfoButton'
import Box from '../../ui/box/Box'
import { TextBold, TextDefault, UiTextProps } from '../../ui/text/UiText'

interface KeyFiguresProps {
  keyFigures: KeyFigure[]
  loading: boolean
  marketValue: number | undefined
}

export const KeyFiguresDropDownBox = ({
  keyFigures,
  loading,
  marketValue,
}: KeyFiguresProps) => {
  const { t } = useTranslation()
  const padding = Spacings.PADDING
  const otherKeyFigures = keyFigures.filter(
    (keyFigure) => keyFigure.id != MARKET_VALUE
  )
  return (
    <Box style={{ marginBottom: Spacings.S24 }}>
      <View
        style={{
          justifyContent: 'space-between',
          paddingHorizontal: padding,
          paddingTop: Spacings.S16,
          paddingBottom: 13,
        }}
      >
        <FlexRow
          style={{
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginBottom: 10,
          }}
        >
          <TextDefault type="h5">{t('netWorth')}</TextDefault>
          {loading ? (
            <ActivityIndicator
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            />
          ) : (
            <TextDefault color={Colors.textSecondary} type="t2">
              {AppStore.portfolioDate}
            </TextDefault>
          )}
        </FlexRow>
        <FlexRow
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <View style={{ alignItems: 'baseline', flexDirection: 'row' }}>
            <TextDefault type="h1">
              {formatNumber(marketValue ?? 0)}
            </TextDefault>
            <TextBold
              size={SizeLargeResponsive()}
              style={{ letterSpacing: -0.7 }}
            >
              {' '}
              €
            </TextBold>
          </View>
        </FlexRow>
      </View>
      {otherKeyFigures.length > 0 && !loading && (
        <DropDownBox label={`${t('portfolio.keyfigures-text')}`}>
          {otherKeyFigures.map((keyFigure, index) => (
            <KeyFigureBox key={index} keyFigure={keyFigure} />
          ))}
        </DropDownBox>
      )}
    </Box>
  )
}

interface ColorFormatedProps extends UiTextProps {
  value: number
  format: (value: number) => string
  inverted?: boolean
}

function ColorFormatedNumber(props: ColorFormatedProps) {
  const { value, inverted, format } = props
  const percentUp = inverted ? value < 0 : value > 0
  const color = percentUp
    ? Colors.textPrimary
    : value === 0
    ? Colors.text
    : Colors.error
  return (
    <TextDefault {...props} style={[props.style, { color }]}>
      {format(value)}
    </TextDefault>
  )
}

export const KeyFigureBox = ({ keyFigure }: { keyFigure: KeyFigure }) => {
  const { t } = useTranslation()
  const { value, label, difference, format, inverted, description } = keyFigure
  return (
    <View style={{ flex: 1 }}>
      <FlexRow style={{ alignItems: 'center' }}>
        <TextDefault type="t3" color={Colors.gray70} numberOfLines={1}>
          {t(label)}
        </TextDefault>
        {description ? (
          <InfoButton
            title={`${t(label)}`}
            text={`${t(description)}`}
            infoElement={
              <IconInfo
                color={Colors.gray40}
                style={{ marginLeft: Spacings.S4 }}
                width={14}
                height={14}
              />
            }
          />
        ) : null}
      </FlexRow>
      {isNullOrUndefined(value) ? (
        <TextDefault
          style={{ marginTop: Spacings.S2 }}
          color={Colors.gray20}
          type="h5"
        >
          {t('no-data')}
        </TextDefault>
      ) : (
        <>
          <TextDefault
            style={{ marginTop: Spacings.S2 }}
            type="h5"
            numberOfLines={1}
          >
            {format(value || 0)}
          </TextDefault>

          {difference ? (
            <FlexRow style={{ marginTop: Spacings.S10 }}>
              <TextDefault type="t5">{t('change')} </TextDefault>
              <ColorFormatedNumber
                format={format}
                value={difference}
                type="t5"
                inverted={inverted}
              />
            </FlexRow>
          ) : null}
        </>
      )}
    </View>
  )
}
