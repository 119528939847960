import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { portfolioChange } from './portfolioChange'
import { formatNumberWithPrefix } from '@taaleri/core/src/utils/format'
import {
  StyleSheet,
  View,
  ImageBackground,
  ViewStyle,
  StyleProp,
} from 'react-native'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import useLayout from '../../ui/useLayout'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { observer } from 'mobx-react'
import UiButton from '../../ui/UiButton'
import Box from '../../ui/box/Box'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_CALCULATOR } from '../../navigation/routes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useTranslation } from 'react-i18next'

function HomeProgress({ style }: { style?: StyleProp<ViewStyle> }) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const { navigateApp } = useNavigation()
  const { percentage } = portfolioChange(t)
  if (percentage === null) {
    return null
  }
  const showCalculatorLink = false //AppStore.isDigiOrConsultative && !isSmall
  const image = require('./rowan.png')

  return (
    <View style={style}>
      <ImageBackground
        source={image}
        resizeMode="cover"
        style={[styles.backgroundImage]}
        imageStyle={[
          showCalculatorLink ? styles.borderRadius : styles.borderRadiusFull,
          { backgroundColor: Colors.imageBackground },
        ]}
      >
        <View style={{ paddingVertical: Spacings.PADDING }}>
          <TextDefault type="h5" color={Colors.white} style={[styles.text]}>
            {t('app.portfolio-development')}
          </TextDefault>
          <TextDefault type="h1" color={Colors.white}>
            {formatNumberWithPrefix(percentage)} %
          </TextDefault>
        </View>
      </ImageBackground>
      {showCalculatorLink && (
        <Box
          style={{
            backgroundColor: Colors.white,
            paddingHorizontal: Spacings.PADDING,
            paddingVertical: Spacings.PADDING,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            alignItems: 'center',

            flexDirection: isSmall ? 'column' : 'row',
          }}
        >
          <TextDefault type="t2" style={{ flexShrink: 1 }}>
            {t('app.tuotto.watch')}
          </TextDefault>
          <UiButton
            containerStyle={[
              isSmall && { width: '100%', marginTop: Spacings.S16 },
              !isSmall && { marginLeft: Spacings.S16 },
            ]}
            type="secondary"
            size="small"
            title={`${t('savings-calculator')}`}
            onPress={() => {
              navigateApp(ROUTE_CALCULATOR)
            }}
          />
        </Box>
      )}
    </View>
  )
}

export default observer(HomeProgress)

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
  },

  text: {
    marginBottom: 6,
  },

  backgroundImage: {
    paddingHorizontal: Spacings.PADDING,
    justifyContent: 'center',
  },
  borderRadius: {
    borderTopLeftRadius: BorderRadius.default,
    borderTopRightRadius: BorderRadius.default,
  },
  borderRadiusFull: {
    borderRadius: BorderRadius.default,
  },
})
