import styled from 'styled-components'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { BreakPoint } from '../constants/BreakPoint'

const PortfolioGrid = styled.div`
  margin-top: ${Spacings.S40}px;
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 24px;
  align-items: start;
  @media (min-width: ${BreakPoint.S}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${BreakPoint.L}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ImpactGrid = styled.div`
  display: grid;
  align-items: start;
  grid-row-gap: ${Spacings.S32}px;
  grid-column-gap: ${Spacings.S32}px;
  @media (min-width: ${BreakPoint.S}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const ReportChartGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  @media (min-width: 1000px) {
    grid-gap: 40px;
    grid-template-columns: 4fr minmax(384px, 2fr);
  }
  margin-bottom: ${Spacings.S16}px;

  @media (min-width: 1000px) {
    grid-row-gap: 38px;
    grid-column-gap: 24px;
  }
`

export { PortfolioGrid, ImpactGrid, ReportChartGrid }
