import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { FundPie } from './FundPie'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { Fund } from '@taaleri/core/src/models/Fund'

interface Props {
    containerStyle?: StyleProp<ViewStyle>
    fund: Fund | undefined
    error: boolean
    retry: () => void
    notFound: boolean
  }

function FundPieContainerNoLoad({ containerStyle, fund, error, retry, notFound }: Props) {

    if (notFound) {
      return null
    }
  
    if (error) {
      return <ErrorWithRetry onRetry={retry} />
    }
  
    if (!fund || AppStore.loadingFund) {
      return <LoadingIndicator type="inline" />
    }
  
    return <FundPie fund={fund} containerStyle={containerStyle} />
  }
  
export default observer(FundPieContainerNoLoad)