import React from 'react'
import { View } from 'react-native'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { useTranslation } from 'react-i18next'

export const TwoFactorToggleDisableContent = () => {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('profile.remove-two-way-authentication')}</Title>
      <Paragraph>
        {t('profile.remove-two-way-info')}
      </Paragraph>
      <Paragraph>
        {t('profile.remove-two-way-info-2')}
      </Paragraph>
    </View>
  )
}
