import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Colors from '@taaleri/core/src/constants/Colors'
import { StyleSheet } from 'react-native'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'

const GlobalStyles = StyleSheet.create({
  topBorder: {
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  smallText: {
    fontSize: FontSize.S10,
    letterSpacing: 1,
  },
  shadow: {
    borderRadius: BorderRadius.default,
    shadowColor: Colors.shadow,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.12,
    elevation: 2,
  },
})

export default GlobalStyles
