import React from 'react'
import { View } from 'react-native'
import { PaymentSumInfo } from '@taaleri/components/src/screens/portfolio/BankSelection'
import PaymentInfoSection from '@taaleri/components/src/screens/portfolio/PaymentInfoSection'
import UiButton from '@taaleri/components/src/ui/UiButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '../../navigation/useNavigation'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

interface Props {
  nextRoute: string
  isOnboarding: boolean
}

function BankTransferContent({ nextRoute, isOnboarding }: Props) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <View>
      <PaymentSumInfo isOnboarding={isOnboarding} title="invest.bank-transaction" />
      <PaymentInfoSection
        isConsultative={!isOnboarding && AppStore.investmentMode === 'fund'}
        sum={AppStore.newInvestment}
      />
      {isOnboarding ? (
        <>
          <UiButton
            title={t('button.bank-transaction-done')}
            onPress={() => navigate(nextRoute)}
            containerStyle={{ marginTop: Spacings.S40 }}
          />

          <UiButton
            type="secondaryplain"
            title={t('button.first-invest-later')}
            onPress={() => navigate(nextRoute)}
            containerStyle={{ marginTop: Spacings.S16 }}
          />
        </>
      ) : (
        <UiButton
          title={t('button.bank-transaction-consent')}
          onPress={() => navigate(nextRoute)}
          containerStyle={{
            marginTop: Spacings.S40,
            marginBottom: Spacings.S16,
          }}
        />
      )}
    </View>
  )
}

export default observer(BankTransferContent)
