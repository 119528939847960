import React from 'react'
import Box from '../../ui/box/Box'
import { Order } from '@taaleri/core/src/models/Order'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ImageBackground, StyleSheet } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import { Fund } from '@taaleri/core/src/models/Fund'
import { getFundImageSource } from '@taaleri/core/src/constants/AssetClass'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import CardHover from '../../ui/CardHover'
import UiTouchable from '../../ui/UiTouchable'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_RECURRING_ORDER_DETAILS } from './RecurringOrderRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ValueLabel from '../impact/ValueLabel'
import { LabelValueWithPadding } from '../../ui/text/LabelValue'
import { isWeb } from '../../constants/Platforms'
import { TextBold } from '../../ui/text/UiText'
import { ROUTE_FUNDS_INTRO } from '../funds/FundsRoutes'
import { IconPlus } from '../../ui/Icons'
import GlobalStyles from '../../constants/GlobalStyles'
import { useTranslation } from 'react-i18next'

interface Props {
  order: Order
  item?: AssetClassItem
  fund?: Fund
}

export function NewRecurringOrder() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const marginBottom = isWeb ? 0 : Spacings.S40
  return (
    <CardHover style={{ marginBottom }}>
      <Box>
        <UiTouchable
          onPress={() => {
            navigateApp(ROUTE_FUNDS_INTRO)
          }}
          style={{
            flex: 1,
            minHeight: 272,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconPlus height={45} width={45} />
          <TextBold style={{ color: Colors.primary }}>
            {t('questions.add-recurring-order')}
          </TextBold>
        </UiTouchable>
      </Box>
    </CardHover>
  )
}

function RecurringOrderRow({ order, fund, item }: Props) {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  if (fund === undefined) {
    return null
  }

  const marketValue = item ? item.marketValue : 0
  const marginBottom = isWeb ? 0 : Spacings.S40
  return (
    <CardHover style={{ marginBottom }}>
      <Box>
        <UiTouchable
          onPress={() => {
            AppStore.fundId = fund.ticker
            AppStore.recurringOrderId = order.id
            navigateApp(ROUTE_RECURRING_ORDER_DETAILS)
          }}
          style={{
            flex: 1,
          }}
        >
          <ImageBackground
            source={getFundImageSource(fund)}
            style={styles.backgroundImage}
            imageStyle={styles.image}
            resizeMode="cover"
          />
          <LabelValueWithPadding
            label={fund.name}
            value={`${formatCurrency(order.amount)}/${t('form.month-short')}`}
            labelComponent="bold"
            containerStyle={GlobalStyles.bottomBorder}
          />
          <ValueLabel
            label={t('netWorth')}
            value={formatCurrency(marketValue)}
          />
          <ValueLabel
            label={t('questions.recurring-order-finalize')}
            value={`${t('form.n-th-day', {
              day: order.recurringTransactionDay,
            })}`}
          />
        </UiTouchable>
      </Box>
    </CardHover>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    height: 108,
  },
  image: {
    borderTopLeftRadius: BorderRadius.default,
    borderTopRightRadius: BorderRadius.default,
  },
  chevronStyle: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: Spacings.S24,
  },
})

export default RecurringOrderRow
