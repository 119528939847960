import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet } from 'react-native'
import Box from '../../ui/box/Box'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Colors from '@taaleri/core/src/constants/Colors'
import { Fund } from '@taaleri/core/src/models/Fund'
import ValueLabel from '../impact/ValueLabel'
import { ColorFormattedPercentage } from '../../ui/text/ColorFormattedNumber'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

interface Props {
  fund: Fund
}

const FundHeaderBox = ({ fund }: Props) => {
  const { t } = useTranslation()
  return (
    <Box style={[styles.box]}>
      {fund.fundPerformanceHistorySummary.performance1Y && (
        <ValueLabel
          value={
            <ColorFormattedPercentage
              value={fund.fundPerformanceHistorySummary.performance1Y}
            />
          }
          loading={AppStore.loading}
          label={`${t('graph.profit')} ${t('filter.year')}`}
          key={'Tuotto 1 vuosi'}
        />
      )}
      {fund.isAvailableForSubscription ? (
        <ValueLabel
          value={`${t('immediately')}`}
          loading={false}
          label={`${t('fund.markable')}`}
          key={'Merkittävissä'}
        />
      ) : (
        <ValueLabel
          value={formatDateFull(fund.fundSubscription)}
          loading={false}
          label={`${t('fund.next')}`}
          key={'Seuraava merkintä'}
        />
      )}
    </Box>
  )
}

export default FundHeaderBox

const styles = StyleSheet.create({
  box: {
    marginBottom: Spacings.S24,
    maxWidth: isWeb ? 640 : '100%',
    marginTop: -28,
  },
  valueRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    borderColor: Colors.border,
    borderBottomWidth: 1,
  },
  textPadding: { paddingHorizontal: Spacings.S16 },
  icon: {
    backgroundColor: Colors.white,
    paddingHorizontal: Spacings.S16,
  },
})
