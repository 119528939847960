import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore, {
  PickerItem,
} from '@taaleri/core/src/stores/TransactionStore'
import Selector from './Selector'
import { ViewStyle, StyleProp } from 'react-native'
import { SelectorTitlePicker } from './SelectorTitle'
import { useTranslation } from 'react-i18next'

interface Props {
  onToggle: () => void
  isOpen: boolean
  containerStyle?: StyleProp<ViewStyle>
}

function PortfolioSelector({ onToggle, isOpen, containerStyle }: Props) {
  const { t } = useTranslation()
  const { selectedPortfolios, allPortfolios, filterByPortfolios } =
    TransactionStore
  const count = filterByPortfolios
    ? selectedPortfolios.length
    : allPortfolios.length

  const titleComponent = (
    <SelectorTitlePicker
      title={`${t('portfolio.all')}`}
      selectedItems={selectedPortfolios}
      onRemove={(s: PickerItem) => TransactionStore.removeSelectedPortfolio(s)}
    />
  )

  return (
    <Selector
      {...{ onToggle, isOpen, count, titleComponent, containerStyle }}
    />
  )
}

export default observer(PortfolioSelector)
