import React from 'react'
import { TextDefault } from '../../../ui/text/UiText'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import { ProfitItem } from '@taaleri/core/src/models/ProfitReport'
import TransactionRowContainerMobile from '../../reporttransaction/Rows/TransactionRowContainerMobile'
import useNavigation from '../../../navigation/useNavigation'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { ROUTE_REPORT_PROFIT_DETAIL } from '../../report/ReportRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  item: ProfitItem
}

export function CapitalGainRowMobile({ item }: Props) {
  const { navigateApp } = useNavigation()
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.securityNameFinnish
      : item.securityNameSwedish
  return (
    <TransactionRowContainerMobile
      onPress={() => {
        ProfitStore.selectedProfitItem = item
        navigateApp(ROUTE_REPORT_PROFIT_DETAIL)
      }}
    >
      <TextDefault type="t5" style={{ flex: 1 }}>
        {formatDateFull(item.transactionDate) +
          ' | ' +
          item.transactionTypeName}
      </TextDefault>
      <TextDefault type="t2" style={{ flex: 1 }}>
        {getName() ?? ''}
      </TextDefault>
    </TransactionRowContainerMobile>
  )
}
