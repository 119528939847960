import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from './UiText'
import FlexRow from '../FlexRow'
import PropsChildren from '../../components/PropsChildren'

type LabelComponent = 'medium' | 'bold'
interface LabelValueProps {
  label: string
  value?: string
  valueComponent?: React.FC
  containerStyle?: StyleProp<ViewStyle>
  labelComponent?: LabelComponent
}

const LabelValue: React.FC<LabelValueProps> = ({
  label,
  value,
  valueComponent,
  containerStyle,
  labelComponent,
}) => {
  const ValueComponent = valueComponent
    ? valueComponent
    : ({ children }: PropsChildren) => (
        <TextDefault type="h5">{children}</TextDefault>
      )
  const isBold = labelComponent === 'bold'
  return (
    <FlexRow style={[styles.container, containerStyle]}>
      {!isBold && <TextDefault>{label}</TextDefault>}
      {isBold && <TextDefault type="h5">{label}</TextDefault>}
      <ValueComponent>{value}</ValueComponent>
    </FlexRow>
  )
}

export default LabelValue

export const LabelValueWithMargin: React.FC<LabelValueProps> = (props) => (
  <LabelValue containerStyle={{ marginVertical: Spacings.S24 }} {...props} />
)

export const LabelValueWithPadding: React.FC<LabelValueProps> = (props) => (
  <LabelValue
    {...props}
    containerStyle={[styles.containerPadding, props.containerStyle]}
  />
)

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerPadding: {
    padding: Spacings.S16,
  },
})
