import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import { OwnershipRowStyles } from './OwnershipRow'
import { useTranslation } from 'react-i18next'

export interface HeadingItem {
  text: string
  style?: StyleProp<TextStyle>
  hidden?: boolean
}

export function HeadingText({ text, style, hidden }: HeadingItem) {
  const { t } = useTranslation()

  if (hidden) {
    return null
  }
  const textStyle = style || OwnershipRowStyles.number
  return (
    <TextDefault type="t4" style={textStyle} key={text}>
      {t(text)}
    </TextDefault>
  )
}

export function OwnershipHeadingSimple() {
  const { isSmall, isMedium } = useLayout()

  if (isSmall) {
    return null
  }

  const styles = OwnershipRowStyles
  const headingItems: HeadingItem[] = [
    {
      text: 'portfolio.instrument-name',
      style: styles.title,
    },
    { text: 'portfolio.acquisition', hidden: isMedium },
    { text: 'netWorth' },
    {
      text: 'portfolio.share',
      style: styles.numberNarrow,
    },
    { text: 'portfolio.change-euro', hidden: isMedium },
    {
      text: 'portfolio.change-percent',
      style: styles.numberNarrow,
    },
  ]

  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingBottom: Spacings.S16,
        marginRight: Spacings.S24,
      }}
    >
      {headingItems.map((props) => (
        <HeadingText {...props} key={props.text} />
      ))}
    </FlexRow>
  )
}
