import React from 'react'

import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_INTRO } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import Buttons from 'ui/Buttons'
import QuestionIntroContent from '@taaleri/components/src/screens/questions/QuestionIntroContent'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

export default function QuestionIntroScreen() {
  const { t } = useTranslation()
  const nextRoute = nextQuestionRoute(ROUTE_QUESTION_INTRO)

  return (
    <QuestionContainer>
      <TopBottomContainer>
        <QuestionIntroContent />
        <Buttons title={t('button.continue')} route={nextRoute} />
      </TopBottomContainer>
    </QuestionContainer>
  )
}
