import { Identity } from '../models/Identity'
import { sleep } from './mockHelper'
import IImpactApi from './IImpactApi'
import { ImpactResponse, SdgResponse } from '../models/ImpactProduct'

const CustomerImpactResponse: ImpactResponse = (() =>
  require(`../fixtures/impact/impactResponse.json`))()

const CustomerImpactResponseDigi: ImpactResponse = (() =>
  require(`../fixtures/impact/impactResponseDigi.json`))()

async function getCustomerImpact(
  _: Identity,
  customerNumber: number
): Promise<ImpactResponse> {
  await sleep(100)
  return new Promise<ImpactResponse>((resolve) =>
    resolve(
      customerNumber === 11111112
        ? CustomerImpactResponseDigi
        : CustomerImpactResponse
    )
  )
}

async function getCustomerSdgProducts(
  _: Identity,
  _1: number
): Promise<SdgResponse> {
  await sleep(100)
  const response = require(`../fixtures/impact/sdgResponse.json`)
  return new Promise<SdgResponse>((resolve) => resolve(response))
}

export const impactApiMock: IImpactApi = {
  getCustomerImpact,
  getCustomerSdgProducts,
}
