export const addThousandIndicators = (str: string) =>
  str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export function nonBreakingSpaces(str: string): string {
  return str ? str.replace(/ /g, '\u00a0') : str
}

export function formatCurrencyWithoutCents(
  value: number,
  forceDecimals?: boolean
): string {
  return formatCurrency(Math.round(value), forceDecimals)
}

export function formatCurrency(value: number, forceDecimals?: boolean): string {
  if (!value || Math.abs(value) < 0.01) {
    return forceDecimals ? '0,00 €' : '0 €'
  }
  const parts = Number(value.toFixed(2)).toString().split('.')
  const leftFromDecimalPoint = addThousandIndicators(parts[0])
  const decimals = parts[1]
    ? parts[1].length === 1
      ? `,${parts[1]}0`
      : `,${parts[1].substr(0, 2)}`
    : forceDecimals
    ? ',00'
    : ''
  return nonBreakingSpaces(leftFromDecimalPoint + decimals + ' €')
}

export function formatCurrencyWithPrefix(value: number) {
  const prefix = value > 0 ? '+' : ''
  return `${prefix}${formatCurrency(value)}`
}
