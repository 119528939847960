import React from 'react'
import { TextDefault } from '../../../ui/text/UiText'
import { StyleSheet } from 'react-native'
import { PickerItem } from '@taaleri/core/src/stores/TransactionStore'
import FlexRow from '../../../ui/FlexRow'
import { SelectorItem } from './SelectorItem'

interface PickerProps extends TitleProps {
  selectedItems: PickerItem[]
  onRemove: (item: PickerItem) => void
}

interface TitleProps {
  title: string
}

function Title({ title }: TitleProps) {
  return (
    <TextDefault type="h6" style={{ marginLeft: 10 }}>
      {title}
    </TextDefault>
  )
}

export function SelectorTitlePicker({
  title,
  selectedItems,
  onRemove,
}: PickerProps) {
  if (selectedItems.length === 0) {
    return <Title {...{ title }} />
  }

  return (
    <FlexRow style={styles.container}>
      {selectedItems.map((s) => (
        <SelectorItem
          key={s.value}
          title={s.label}
          onRemove={() => onRemove(s)}
        />
      ))}
    </FlexRow>
  )
}

interface StringProps extends TitleProps {
  selectedItems: string[]
  onRemove: (item: string) => void
}

export function SelectorTitleString({
  title,
  selectedItems,
  onRemove,
}: StringProps) {
  if (selectedItems.length === 0) {
    return <Title {...{ title }} />
  }

  return (
    <FlexRow style={styles.container}>
      {selectedItems.map((s) => (
        <SelectorItem key={s} title={s} onRemove={() => onRemove(s)} />
      ))}
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  container: { flexWrap: 'wrap', flexShrink: 1 },
})
