import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { FeedNewsType } from '@taaleri/core/src/models/Feed'
import React from 'react'
import styled, { css } from 'styled-components'

import { NotificationTitleProps } from './NotificationTitle'

export const NotificationTitleContainer = styled.div<{
  type: FeedNewsType
}>`
  height: 24px;

  background: linear-gradient(
      270deg,
      rgba(91, 97, 143, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #5b618f, #5b618f), #ffffff;

  ${(props) =>
    (props.type === 'service_article' || props.type === 'target_message') &&
    css`
      background: linear-gradient(
          270deg,
          rgba(255, 170, 77, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #ffaa4d, #ffaa4d), #ffffff;
    `}

  ${(props) =>
    props.type === 'other' &&
    css`
      background: linear-gradient(
          270deg,
          rgba(174, 205, 200, 0.5) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #aecdc8, #aecdc8), #ffffff;
    `}

  
  border-radius: ${BorderRadius.default}px ${BorderRadius.default}px 0 0;
  display: flex;
  align-items: center;
  padding-left: ${Spacings.S24}px;
`

export const NotificationTitle = (props: NotificationTitleProps) => (
  <NotificationTitleContainer type={props.type}></NotificationTitleContainer>
)
