import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import GradientContainer from 'ui/GradientContainer'
import TransactionsFilter from '@taaleri/components/src/screens/reporttransaction/TransactionsFilter'
import TransactionsList from '@taaleri/components/src/screens/reporttransaction/TransactionsList'
import AppStore from '@taaleri/core/src/stores/AppStore'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import Spacings from '@taaleri/core/src/constants/Spacings'
import TotalTransactions from '@taaleri/components/src/screens/reporttransaction/TotalTransactions'
import { getTransactionToken } from '@taaleri/core/src/services/reports'

function TransactionsScreen() {
  const [error, setError] = useState<boolean>(false)
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    AppStore.currentLanguage
  )
  const isInitialMount = useRef(true)

  async function getTransactions() {
    if (TransactionStore.skipFetching) {
      TransactionStore.skipFetching = false
      return
    }

    // Initialize date range only on first render
    if (isInitialMount.current) {
      isInitialMount.current = false
      TransactionStore.initializeDateRange()
      TransactionStore.initializeFilters()
    }
    setError(false)
    const success = await TransactionStore.fetchTransaction(AppStore.customerId)
    setError(!success)
  }

  async function getToken() {
    TransactionStore.token = ''
    TransactionStore.loadingToken = true
    const result = await getTransactionToken(AppStore.customerId)
    if (result.response && result.success) {
      TransactionStore.token = result.response
    }
    TransactionStore.loadingToken = false
  }

  useEffect(() => {
    getTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TransactionStore.transactionCategory, AppStore.customerId])

  useEffect(() => {
    if (currentLanguage !== AppStore.currentLanguage) {
      setCurrentLanguage(AppStore.currentLanguage)
      getTransactions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  useEffect(() => {
    getToken()
    TransactionStore.initializeFilters()
    TransactionStore.initializeDateRange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.customerId])

  return (
    <GradientContainer width={1280}>
      <>
        <TransactionsFilter />
        {error ? (
          <ErrorWithRetry onRetry={getTransactions} />
        ) : TransactionStore.loadingTransactions || AppStore.loading ? (
          <LoadingIndicator style={{ marginTop: Spacings.S64 }} type="inline" />
        ) : (
          <>
            <TotalTransactions />
            <TransactionsList />
          </>
        )}
      </>
    </GradientContainer>
  )
}

export default observer(TransactionsScreen)
