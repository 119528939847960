import Colors from '@taaleri/core/src/constants/Colors'
import styled from 'styled-components'

const RowHover = styled.div`
  flex: 1;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${Colors.background};
    }
  }
`

export default RowHover
