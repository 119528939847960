import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import Downshift from 'downshift'
import { Input, Item, Menu } from './form/CountryLink.web'
import { SelectDropdownProps } from './SelectDropdown'
import { INPUT_HEIGHT, UiTextInputLabel } from './form/UiTextInput.web'
import { IconChevronDown, IconChevronUp } from './icons/ChevronIcons'
import UiTouchable from './UiTouchable.web'
import { View } from 'react-native'

export default function SelectDropdown({
  onChange,
  items,
  selectedItem,
  title,
  style,
}: SelectDropdownProps) {
  return (
    <View style={style}>
      <Downshift
        onChange={(selection) => {
          onChange(selection)
        }}
        itemToString={(item) => item.label}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          openMenu,
          closeMenu,
        }) => (
          <div
            style={{
              zIndex: 10,
              width: '100%',
            }}
          >
            <UiTouchable
              style={{
                position: 'relative',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
              }}
              onBlur={() => {
                closeMenu()
              }}
              onPress={() => {
                if (isOpen) {
                  closeMenu()
                } else {
                  openMenu()
                }
              }}
            >
              <Input
                {...getInputProps()}
                onKeyPress={(event: any) => {
                  if (event.nativeEvent.keyCode === 13) {
                    event.nativeEvent.preventDefault()
                  }
                }}
                cursorPointer={true}
                caretTransparent={true}
                isFlex={true}
              ></Input>
              <UiTextInputLabel
                test={false}
                label={title}
                isFocused={true}
                isPlaceholder={false}
                labelColor={Colors.text}
                disableFloatingLabel={false}
              />
              <div
                style={{
                  height: INPUT_HEIGHT,
                  width: INPUT_HEIGHT,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {isOpen ? <IconChevronUp /> : <IconChevronDown />}
              </div>
            </UiTouchable>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Menu {...getMenuProps()} isOpen={isOpen} overlay={true}>
                {isOpen
                  ? items.map((item: any, index: number) => (
                      <Item
                        key={index}
                        {...getItemProps({
                          key: item.value,
                          index,
                          item,
                          style: {
                            backgroundColor:
                              highlightedIndex === index
                                ? Colors.background
                                : 'white',
                            fontFamily:
                              selectedItem === item
                                ? Fonts.avenirNextBold
                                : Fonts.avenirNextMedium,
                          },
                        })}
                      >
                        {item.label}
                      </Item>
                    ))
                  : null}
              </Menu>
            </div>
          </div>
        )}
      </Downshift>
    </View>
  )
}
