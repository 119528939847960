import React from 'react'
import { Title3 } from '../../ui/text/Title'
import { View } from 'react-native'
import {
  Fund,
  InvestmentStyles,
  InvestmentStyle,
} from '@taaleri/core/src/models/Fund'
import groupBy from 'lodash/groupBy'
import { FundCardSmall } from './FundCardSmall'
import { FundGridList } from './FundGrid'
import { TextDefault } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { investmentStyleLocalization } from '@taaleri/core/src/services/translationService'
import { useTranslation } from 'react-i18next'

interface Props {
  funds: Fund[]
}

export default function FundListContent({ funds }: Props) {
  const { t } = useTranslation()
  if (funds.length === 0) {
    return null
  }

  const groups = groupBy(funds, (f: Fund) => f.investmentStyle)
  const groupNames = Object.keys(groups).sort()
  return (
    <View>
      {groupNames.map((g) => {
        const groupFunds = groups[g]
        const investmentStyle = InvestmentStyles.find(
          (i: InvestmentStyle) => i.name.toLowerCase() === g.toLowerCase()
        )
        const description = investmentStyle ? investmentStyle.description : ''
        return (
          <View key={g}>
            <Title3 style={{ marginBottom: Spacings.S8 }}>
              {investmentStyleLocalization(g, t)}
            </Title3>

            <TextDefault style={{ marginBottom: 32 }}>
              {/* TODO: localize */}
              {t(description)}
            </TextDefault>

            <FundGridList>
              {groupFunds.map((fund: Fund) => (
                <FundCardSmall fund={fund} key={fund.ticker} />
              ))}
            </FundGridList>
          </View>
        )
      })}
    </View>
  )
}
