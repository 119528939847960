import React from 'react'
import TransactionRowContainer from '../../reporttransaction/Rows/TransactionRowContainer'
import { HeaderItem } from './HeaderItem'
import { useTranslation } from 'react-i18next'

export function ProfitHeader() {
  const { t } = useTranslation()
  return (
    <TransactionRowContainer hideBorder={true}>
      <HeaderItem text={t('portfolio.text')} flex={2} />
      <HeaderItem text={t('report.event-date')} />
      <HeaderItem text={t('report.term')} />
      <HeaderItem text={t('report.event')} />
      <HeaderItem text={t('security')} flex={2} />
      <HeaderItem text={t('portfolio.total')} />
      <HeaderItem text={t('report.withholding-tax')} />
      <HeaderItem text={t('report.net')} />
    </TransactionRowContainer>
  )
}
