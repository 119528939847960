import { useState, useEffect } from 'react'
import { PortfolioDistributions } from '@taaleri/core/src/models/investmentProposal'
import { customers } from '@taaleri/core/src/services/customers'
import AppStore from '@taaleri/core/src/stores/AppStore'

export default function useImpactStrategy() {
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [impactStrategy, setImpactStrategy] =
    useState<PortfolioDistributions | undefined>()

  useEffect(() => {
    const getImpactStrategy = async () => {
      const strategy = AppStore.impactStrategy
      if (strategy) {
        setImpactStrategy(strategy)
      } else {
        setError(false)
        setRetry(false)
        try {
          const proposal = await customers.getCorrespondingImpactStrategy(
            AppStore.customerId
          )
          setImpactStrategy(proposal)
          AppStore.impactStrategy = proposal
        } catch (error) {
          setError(true)
        }
      }
    }
    getImpactStrategy()
  }, [retry])
  return { impactStrategy, error, setRetry }
}
