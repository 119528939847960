import React from 'react'
import indexOf from 'lodash/indexOf'
import range from 'lodash/range'
import UiSlider from '../../ui/form/UiSlider'
import { BoxWithPadding } from '../../ui/box/Box'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import LabelValue from '../../ui/text/LabelValue'
import { Divider } from '../../ui/Divider'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import useLayout from '../../ui/useLayout'
import InvestmentButton from '../../ui/InvestmentButton'
import CalculatorSwitch from '../../ui/CalculatorSwitch'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

const monthlyValues = [
  ...range(0, 100, 10),
  ...range(100, 1000, 50),
  ...range(1000, 5100, 100),
]

const initialValues = [
  ...range(0, 1000, 100),
  ...range(1000, 10000, 1000),
  ...range(10000, 100000, 10000),
  ...range(100000, 600000, 100000),
]

function valueFromPosition(values: number[], position: number) {
  return values[position]
}

function positionFromValue(values: number[], value: number) {
  const closestValue = getClosest(values, value)
  return indexOf(values, closestValue)
}

function getClosest(arr: number[], value: number) {
  return arr.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
  )
}

interface FooterProps {
  setIncludeMarketValue: (value: boolean) => void
  includeMarketValue: boolean
  marketValue: number
}

function SliderFooter({
  setIncludeMarketValue,
  includeMarketValue,
  marketValue,
}: FooterProps) {
  const { isWide } = useLayout()
  return isWide ? (
    <>
      <Divider style={{ marginVertical: Spacings.S32 }} />
      <FlexRow style={{ flex: 2, alignItems: 'center' }}>
        <CalculatorSwitch
          style={{ flex: 1, alignItems: 'center' }}
          onValueChange={setIncludeMarketValue}
          value={includeMarketValue}
          visible={marketValue > 0}
        />
        {/* <InvestmentButton style={{ flex: 1 }} /> */}
      </FlexRow>
    </>
  ) : null
}

interface Props {
  setMonthlyInvestment: (value: number) => void
  setInitialInvestment: (value: number) => void
  setInvestmentTime: (value: number) => void
  setIncludeMarketValue: (value: boolean) => void
  monthlyInvestment: number
  initialInvestment: number
  investmentTime: number
  includeMarketValue: boolean
  marketValue: number
}

function CalculatorSliders({
  setMonthlyInvestment,
  setInitialInvestment,
  setInvestmentTime,
  setIncludeMarketValue,
  monthlyInvestment,
  initialInvestment,
  investmentTime,
  includeMarketValue,
  marketValue,
}: Props) {
  const { isSmall } = useLayout()
  const { t } = useTranslation()
  return (
    <BoxWithPadding
      title={`${t('app.calculator.headerquestion')}`}
      style={{
        flex: 1,
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: Spacings.S16,
        paddingBottom: Spacings.S24,
      }}
    >
      <View style={{ margin: 8 }}>
        <View
          style={
            isSmall
              ? { paddingTop: 2, paddingBottom: 6 }
              : { paddingTop: Spacings.S16, paddingBottom: Spacings.S24 }
          }
        >
          <LabelValue
            label={t('app.calculator.monthlyinvestment')}
            value={`${formatCurrency(monthlyInvestment)} `}
          />
        </View>

        <UiSlider
          min={0}
          max={monthlyValues.length - 1}
          step={1}
          initialValue={positionFromValue(monthlyValues, monthlyInvestment)}
          onChange={(value: string) => {
            setMonthlyInvestment(
              valueFromPosition(monthlyValues, Number(value))
            )
          }}
        />
        <View
          style={
            isSmall
              ? { paddingTop: Spacings.S16, paddingBottom: 6 }
              : { paddingVertical: Spacings.S24 }
          }
        >
          <LabelValue
            label={t('app.calculator.oneoffinvestment')}
            value={`${formatCurrency(initialInvestment)} `}
          />
        </View>
        <UiSlider
          min={0}
          max={initialValues.length - 1}
          step={1}
          initialValue={positionFromValue(initialValues, initialInvestment)}
          onChange={(value: string) => {
            setInitialInvestment(
              valueFromPosition(initialValues, Number(value))
            )
          }}
        />
        <View
          style={
            isSmall
              ? { paddingTop: Spacings.S16, paddingBottom: 6 }
              : { paddingTop: Spacings.S24, paddingBottom: Spacings.S16 }
          }
        >
          <LabelValue
            label={t('app.calculator.investmenthorizon')}
            value={investmentTime.toString() + ' v'}
          />
        </View>
        <UiSlider
          min={1}
          max={50}
          step={1}
          initialValue={investmentTime}
          onChange={(value: string) => {
            setInvestmentTime(Number(value))
          }}
        />
      </View>
      <SliderFooter
        includeMarketValue={includeMarketValue}
        setIncludeMarketValue={setIncludeMarketValue}
        marketValue={marketValue}
      />
    </BoxWithPadding>
  )
}

export default CalculatorSliders
