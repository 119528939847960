import React from 'react'

import ErrorMessage from '../../components/ErrorMessage'
import { Paragraph } from '../../ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function ChangePhoneErrorContent() {
  const { t } = useTranslation()
  return (
    <ErrorMessage title={`${t('validation.unknown-error')}`}>
      <Paragraph>{t('validation.use-same-login-as-signed')}</Paragraph>
    </ErrorMessage>
  )
}
