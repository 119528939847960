import React from 'react'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import BlockedCustomerContent from '@taaleri/components/src/screens/funds/BlockedCustomerContent'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import Buttons from 'ui/Buttons'
import { useTranslation } from 'react-i18next'

function BlockedConsCustomerScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <BackgroundContainerApp>
      <TopBottomContainer>
        <BlockedCustomerContent />
        <Buttons
          title={t('button.move-frontpage')}
          onPress={() => navigate(routePath(ROUTE_APP))}
        />
      </TopBottomContainer>
    </BackgroundContainerApp>
  )
}

export default BlockedConsCustomerScreen
