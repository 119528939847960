import Svg, { G, Polygon, Path } from 'react-native-svg'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { IconProps } from '../IconProps'
import { View } from 'react-native'

export const IconChevronLeft = () => {
  return (
    <Svg width={8} height={12} viewBox="0 0 8 12" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91 1.41L6.5 0l-6 6 6 6 1.41-1.41L3.33 6l4.58-4.59z"
        fill="#6E6763"
      />
    </Svg>
  )
}

export const IconChevronRight: React.FC<IconProps> = ({ color, style }) => {
  return (
    <View style={style}>
      <Svg height={24} width={24} viewBox="0 0 24 24">
        <G fill="none">
          <Polygon points="0 0 24 0 24 24 0 24" />
          <Polygon
            fill={color || Colors.text}
            points="10 6 8.59 7.41 13.17 12 8.59 16.59 10 18 16 12"
          />
        </G>
      </Svg>
    </View>
  )
}

export const IconChevronDown: React.FC<IconProps> = ({ color, hidden }) => {
  return (
    <Svg width={12} height={8} viewBox="0 0 12 8" opacity={hidden ? 0 : 1}>
      <G stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <G transform="translate(-199.000000, -304.000000)">
          <G transform="translate(24.000000, 280.000000)">
            <G transform="translate(77.000000, 16.000000)">
              <G transform="translate(92.000000, 0.000000)">
                <Polygon points="7.41 8.59 12 13.17 16.59 8.59 18 10 12 16 6 10" />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}

export const IconChevronUp: React.FC<IconProps> = ({ color }) => {
  return (
    <Svg width="12" height="8" viewBox="0 0 12 8" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.00016L1.41 7.41016L6 2.83016L10.59 7.41016L12 6.00016L6 0.000156403L0 6.00016Z"
        fill={color || Colors.text}
      />
    </Svg>
  )
}

export const IconChevronDownSmall = () => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0105 5.2155L8.3055 4.5105L6.0105 6.8005L3.7155 4.5105L3.0105 5.2155L6.0105 8.2155L9.0105 5.2155Z"
        fill="white"
      />
    </Svg>
  )
}

export const IconChevronUpSmall = () => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0105 7.71565L3.7155 8.42065L6.0105 6.13065L8.3055 8.42065L9.0105 7.71565L6.0105 4.71565L3.0105 7.71565Z"
        fill="white"
      />
    </Svg>
  )
}
