import React from 'react'
import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import UiTouchable from '../../ui/UiTouchable.web'
import { IconClose } from '../../ui/Icons'
import LegendHover from './LegendHover'
import InfoButton from '../../ui/InfoButton'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

interface Props {
  style?: StyleProp<ViewStyle>
  showBenchmarkLegend: boolean
  chartTitle: string
  benchmarkTitle?: string
  portfolioTitle?: string
  showPortfolioLegend: boolean
  showPortfolioChart: boolean
  setShowPortfolioChart: (value: boolean) => void
}

function ReportChartLegend({
  style,
  showBenchmarkLegend,
  showPortfolioLegend,
  showPortfolioChart,
  setShowPortfolioChart,
  chartTitle,
  benchmarkTitle,
  portfolioTitle,
}: Props) {
  const { t } = useTranslation()
  const { selectedBenchMark, chartColor, benchmarkComposition } = ReportStore
  const benchmarkInfoTitle = 'portfolio.benchmark-info-title'
  return (
    <FlexRow style={style}>
      <FlexRow style={{ flexShrink: 1, flexWrap: 'wrap' }}>
        {showPortfolioLegend && portfolioTitle && (
          <LegendHover
            style={{ marginRight: Spacings.S24, marginTop: Spacings.S2 }}
          >
            <UiTouchable
              style={[
                styles.row,
                styles.benchMarkButton,
                { opacity: showPortfolioChart ? 1 : 0.5 },
              ]}
              onPress={() => {
                setShowPortfolioChart(!showPortfolioChart)
              }}
            >
              <View
                style={[
                  styles.label,
                  styles.portfolio,
                  { backgroundColor: Colors.gray80 },
                ]}
              />
              <TextDefault
                style={{ lineHeight: 13 }}
                type="t5"
                numberOfLines={1}
              >
                {portfolioTitle}
              </TextDefault>
            </UiTouchable>
          </LegendHover>
        )}
        <FlexRow
          style={[
            styles.row,
            {
              flexShrink: 1,
              marginRight: Spacings.S24,
              marginTop: Spacings.S2,
            },
          ]}
        >
          <View
            style={[
              styles.label,
              styles.portfolio,
              { backgroundColor: chartColor },
            ]}
          />
          <TextDefault style={{ lineHeight: 13 }} numberOfLines={1} type="t5">
            {chartTitle}
          </TextDefault>
        </FlexRow>

        {selectedBenchMark && showBenchmarkLegend && benchmarkTitle && (
          <LegendHover style={{ marginTop: Spacings.S2, overflow: 'hidden' }}>
            <UiTouchable
              style={[styles.row, styles.benchMarkButton]}
              onPress={() => {
                ReportStore.benchMarkIndex = []
                ReportStore.selectedBenchMark = undefined
              }}
            >
              <View style={[styles.label, styles.benchMark]} />
              <TextDefault
                type="t5"
                style={styles.benchMarkName}
                numberOfLines={1}
              >
                {benchmarkTitle}
              </TextDefault>
              <IconClose width={14} height={14} />
            </UiTouchable>
          </LegendHover>
        )}
      </FlexRow>
      {benchmarkComposition.length > 0 && selectedBenchMark !== undefined && (
        <InfoButton
          infoElement={
            <TextDefault type="t3" style={{ color: Colors.primary }}>
              {t(benchmarkInfoTitle)}
            </TextDefault>
          }
        >
          <TextDefault type="h3" style={{ marginBottom: Spacings.S24 }}>
            {t(benchmarkInfoTitle)}
          </TextDefault>
          <TextDefault style={{ marginBottom: Spacings.S16 }}>{`${t(
            'portfolio.consist-indexes',
            { benchmark: t(selectedBenchMark) }
          )}\n`}</TextDefault>
          {benchmarkComposition.map((b) => (
            <FlexRow
              style={{ justifyContent: 'space-between' }}
              key={b.securityId}
            >
              <TextDefault>{`• ${b.securityName}`}</TextDefault>
              <TextDefault
                numberOfLines={1}
                style={{ minWidth: 60, textAlign: 'right' }}
              >{`${b.share.toFixed(0)} %`}</TextDefault>
            </FlexRow>
          ))}
        </InfoButton>
      )}
    </FlexRow>
  )
}

export default observer(ReportChartLegend)

const styles = StyleSheet.create({
  container: {
    paddingVertical: Spacings.S8,
  },
  row: {
    alignItems: 'center',
    padding: Spacings.S4,
    height: 24,
  },
  label: {
    marginRight: Spacings.S8,
    width: 10,
  },
  benchMarkName: { marginRight: Spacings.S4, lineHeight: 13 },
  portfolio: {
    borderRadius: 2,
    height: 2,
  },
  benchMark: {
    height: 2,
    borderRadius: 2,
    backgroundColor: Colors.inactiveTint,
  },
  benchMarkButton: {
    borderColor: Colors.border,
    borderWidth: 1,
    borderRadius: 6,
    flexDirection: 'row',
  },
})
