import PercentagePieAsset from '@taaleri/components/src/components/PercentagePieAsset'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import { nameForAssetClassId } from '@taaleri/core/src/constants/AssetClass'
import {
  assetClassColors,
  AssetClassPercentage,
} from '@taaleri/core/src/models/assetClassColors'
import {
  formatPercentageFull,
  formatPercentageTwoDecimal,
} from '@taaleri/core/src/utils/format'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

function translateAssetClassName(name: string) {
  return name === 'cash' ? 'fund.cash' : name
}

export default function AssetClassDistribution({
  namePercentages,
  decimals,
}: {
  namePercentages: AssetClassPercentage[]
  decimals?: number
}) {
  const { t } = useTranslation()
  const p = 0
  const formatFunction =
    decimals === 2 ? formatPercentageTwoDecimal : formatPercentageFull

  return (
    <View>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <View
          style={{
            justifyContent: 'center',
          }}
        >
          {namePercentages.map((assetClass: AssetClassPercentage, index) => {
            return (
              <FlexRow key={index} style={{ marginBottom: 5 }}>
                <View>
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      marginTop: 5,
                      marginRight: 12,
                      backgroundColor: assetClassColors[assetClass.id],
                      borderRadius: 5,
                    }}
                  />
                </View>
                <TextDefault>
                  {formatFunction(assetClass.percentage)} %{'  '}{' '}
                  {t(
                    translateAssetClassName(nameForAssetClassId(assetClass.id))
                  )}
                </TextDefault>
              </FlexRow>
            )
          })}
        </View>
        <PercentagePieAsset
          assetClasses={namePercentages}
          percentage={p}
          borderWidth={8}
        />
      </FlexRow>
    </View>
  )
}
