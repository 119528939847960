import React from 'react'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { TwoFactorToggleDisableContent } from './TwoFactorToggleDisableContent'
import { TwoFactorToggleEnableContent } from './TwoFactorToggleEnableContent'

const TwoFactorToggleStartContent = () =>
  AppStore.twoFactorEnabled ? (
    <TwoFactorToggleDisableContent />
  ) : (
    <TwoFactorToggleEnableContent />
  )

export default observer(TwoFactorToggleStartContent)
