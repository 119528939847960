import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import { SizeLargeResponsive } from '@taaleri/core/src/constants/Fonts'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Box from '../../ui/box/Box'
import FlexRow from '../../ui/FlexRow'
import { TextBold, TextDefault } from '../../ui/text/UiText'
import UiTouchable from '../../ui/UiTouchable'
import { formatNumber } from '@taaleri/core/src/utils/format'
import PortfolioOrdersList from './PortfolioOrdersList'
import Colors from '@taaleri/core/src/constants/Colors'
import { IconChevronRight } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'

interface PortfolioValueProps {
  value: number
  loading: boolean
  showChevron?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  bottomMargin?: boolean
}

const PortfolioValueBox = ({
  value,
  loading,
  showChevron,
  style,
  onPress,
  bottomMargin,
}: PortfolioValueProps) => {
  const { t } = useTranslation()
  const padding = Spacings.PADDING
  return (
    <Box
      style={[
        {
          paddingVertical: 0,
        },
        style,
        bottomMargin && { marginBottom: Spacings.S24 },
      ]}
    >
      <UiTouchable onPress={onPress} disabled={!onPress}>
        <View
          style={{
            justifyContent: 'space-between',
            paddingHorizontal: padding,
            paddingTop: Spacings.S16,
            paddingBottom: 13,
          }}
        >
          <FlexRow
            style={{
              justifyContent: 'space-between',
              alignItems: 'baseline',
              marginBottom: 10,
            }}
          >
            <TextDefault type="h5">{t('netWorth')}</TextDefault>
            {!loading && (
              <TextDefault color={Colors.textSecondary} type="t2">
                {AppStore.portfolioDate}
              </TextDefault>
            )}
            {loading && (
              <ActivityIndicator
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              />
            )}
          </FlexRow>
          <FlexRow
            style={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <View style={{ alignItems: 'baseline', flexDirection: 'row' }}>
              <TextDefault type="h1">{formatNumber(value)}</TextDefault>
              <TextBold size={SizeLargeResponsive()} style={styles.value}>
                {' '}
                €
              </TextBold>
            </View>
            {showChevron && <IconChevronRight />}
          </FlexRow>
        </View>
      </UiTouchable>
      <PortfolioOrdersList />
    </Box>
  )
}

export default PortfolioValueBox

const styles = StyleSheet.create({
  value: {
    letterSpacing: -0.7,
  },
})
