import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import React from 'react'
import { View } from 'react-native'
import { AppPadding } from 'screens/app/AppPadding'
import { Content } from 'screens/app/Content'
import HeaderLargeContainer from 'screens/app/HeaderLargeContainer'
import HeaderSmallContainer from 'screens/app/HeaderSmallContainer'

export function Header() {
  return (
    <>
      <HeaderLargeContainer>
        <AktiaLogo />
      </HeaderLargeContainer>
      <HeaderSmallContainer>
        <View />
      </HeaderSmallContainer>
    </>
  )
}

export function AdminLayout(props: PropsChildren) {
  return (
    <View>
      <Header />
      <Content marginTop={true}>
        <AppPadding>{props.children}</AppPadding>
      </Content>
    </View>
  )
}
