import Analytics from '@taaleri/core/src/analytics/Analytics'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ProfitType } from '@taaleri/core/src/models/ProfitReport'
import { profitFilterTranslateHelper } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { PickerItem } from '@taaleri/core/src/stores/TransactionStore'
import { formatDateDash } from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import InstrumentFilter from './InstrumentFilter'
import PortfolioFilter from './PortfolioFilter'
import TypeFilter from './TypeFilter'
import { isWeb } from '../../constants/Platforms'
import useNavigation from '../../navigation/useNavigation'
import { BackButton } from '../../ui/BackButton'
import FlexRow from '../../ui/FlexRow'
import Title from '../../ui/text/Title'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import DownloadReportButton, {
  ReportValues,
} from '../portfolio/DownloadReportButton'
import { ROUTE_REPORT } from '../report/ReportRoutes'
import DateFilter from '../reporttransaction/Filters/DateFilter'
import {
  CategoryItem,
  CategorySelector,
} from '../reporttransaction/Selector/CategorySelector'
import Selector from '../reporttransaction/Selector/Selector'
import {
  SelectorTitlePicker,
  SelectorTitleString,
} from '../reporttransaction/Selector/SelectorTitle'
import TransactionCategoryContainer from '../reporttransaction/Selector/TransactionCategoryContainer'
import { getProfitReportPdfUrl } from '../reporttransaction/TransactionPdfUrl'

const items: CategoryItem[] = [
  { title: 'report.capital-gain', category: 'CapitalGain' },
  { title: 'report.profit', category: 'Profit' },
]

function ProfitFilter() {
  const [isDateOpen, setDateOpen] = useState<boolean>(false)
  const [isInstrumentOpen, setInstrumentOpen] = useState<boolean>(false)
  const [isTypeOpen, setTypeOpen] = useState<boolean>(false)
  const [isPortfoliosOpen, setPortfoliosOpen] = useState<boolean>(false)
  const { isWide } = useLayout()
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()

  const SelectorContainer = isWide ? FlexRow : View
  useEffect(() => {
    setInstrumentOpen(false)
    setTypeOpen(false)
    setPortfoliosOpen(false)
    setDateOpen(false)
  }, [])

  /* 
  Show all selector on wide screen. On mobile devices, 
  hide unnecessary filters to save space. 
  */

  const {
    allPortfolios,
    allTransactionTypes,
    allInstruments,
    startDate,
    filterByInstrument,
    filterByTransactionType,
    selectedTransactionTypes,
    selectedInstruments,
    endDate,
    loadingToken,
    token,
    selectedPortfolios,
    loadingProfitReport,
    profitCategory,
    filterByPortfolios,
    filteredProfitItems,
    dateRangeTitle,
  } = ProfitStore

  const portfolioIds =
    selectedPortfolios.length > 0
      ? selectedPortfolios.map((e) => e.value)
      : allPortfolios.map((e) => e.value)

  const hasFilteredProfitItems = filteredProfitItems.length > 0

  const dateFilter = (
    <DateFilter
      onDateRangePress={(dateRange: string) => {
        setDateOpen(false)
        ProfitStore.setDateRange(dateRange)
        ProfitStore.fetchProfitReport(AppStore.customerId)
      }}
      onConfirm={(start: Date, end: Date) => {
        ProfitStore.setCustomDateRange(start, end)
        ProfitStore.fetchProfitReport(AppStore.customerId)
        setDateOpen(false)
      }}
      initialStartDate={ProfitStore.startDate}
      initialEndDate={ProfitStore.endDate}
      dateRange={ProfitStore.dateRange}
    />
  )

  // For now show download button only for cash transactions on desktop
  const showPdfButton =
    !loadingToken &&
    token !== '' &&
    isWide &&
    isWeb &&
    !loadingProfitReport &&
    hasFilteredProfitItems

  return (
    <View style={{ alignItems: 'center' }}>
      <View style={{ maxWidth: 1200, width: '100%' }}>
        <FlexRow style={{ alignItems: 'center' }}>
          {isWeb && (
            <BackButton
              style={{ marginRight: Spacings.S16 }}
              onPress={() => navigateApp(ROUTE_REPORT)}
            />
          )}
          <Title>{t('reportBox.withrawed-earnings')}</Title>
        </FlexRow>
        <TransactionCategoryContainer>
          <>
            {items.map((item) => (
              <CategorySelector
                selectedCategory={ProfitStore.profitCategory}
                setSelectedCategory={(category) => {
                  ProfitStore.profitCategory = category as ProfitType
                  // ProfitStore.initializeFilters()
                }}
                item={item}
                key={item.category}
              />
            ))}
            {showPdfButton && (
              <DownloadReportButton
                title={t('report.load-events')}
                reportType="transactions"
                buttonStyle={styles.downloadButton}
                fetchUrl={(values: ReportValues) => {
                  Analytics.event('Reports', 'Profits pdf', profitCategory)
                  return getProfitReportPdfUrl(
                    AppStore.customerId,
                    formatDateDash(startDate),
                    formatDateDash(endDate),
                    token,
                    profitCategory,
                    values.byPortfolio ? 'Portfolio' : 'List',
                    portfolioIds,
                    values.fileFormat
                  )
                }}
              />
            )}
          </>
        </TransactionCategoryContainer>
        <View
          style={{
            backgroundColor: Colors.white,
            padding: isWide ? Spacings.S40 : Spacings.S16,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: isWide ? 0 : 6,
            borderTopRightRadius: 6,
          }}
        >
          <SelectorContainer>
            <Selector
              count={
                filterByPortfolios
                  ? selectedPortfolios.length
                  : allPortfolios.length
              }
              titleComponent={
                <SelectorTitlePicker
                  title={`${t('portfolio.all')}`}
                  selectedItems={selectedPortfolios}
                  onRemove={(s: PickerItem) =>
                    ProfitStore.removeSelectedPortfolio(s)
                  }
                />
              }
              onToggle={() => {
                setInstrumentOpen(false)
                setTypeOpen(false)
                setPortfoliosOpen(!isPortfoliosOpen)
                setDateOpen(false)
              }}
              isOpen={isPortfoliosOpen}
              containerStyle={{
                marginBottom: isWide ? Spacings.S24 : 0,
                justifyContent: 'center',
              }}
            />
            {isPortfoliosOpen && !isWide && <PortfolioFilter />}
            <Selector
              titleComponent={
                <TextDefault type="h6" style={{ marginLeft: 10 }}>
                  {profitFilterTranslateHelper(dateRangeTitle, t)}
                </TextDefault>
              }
              onToggle={() => {
                setInstrumentOpen(false)
                setTypeOpen(false)
                setPortfoliosOpen(false)
                setDateOpen(!isDateOpen)
              }}
              isOpen={isDateOpen}
              containerStyle={{
                marginTop: isWide ? 0 : Spacings.S24,
                marginBottom: isWide ? Spacings.S24 : 0,
                marginLeft: isWide ? Spacings.S40 : 0,
              }}
              isDateFilter={true}
            />
            {isDateOpen && !isWide && dateFilter}
          </SelectorContainer>
          <SelectorContainer>
            <Selector
              count={
                filterByInstrument
                  ? selectedInstruments.length
                  : allInstruments.length
              }
              titleComponent={
                <SelectorTitlePicker
                  title={t('report.search-by-security-name')}
                  selectedItems={selectedInstruments}
                  onRemove={(s: PickerItem) =>
                    ProfitStore.removeSelectedInstrument(s)
                  }
                />
              }
              onToggle={() => {
                setInstrumentOpen(!isInstrumentOpen)
                setTypeOpen(false)
                setPortfoliosOpen(false)
                setDateOpen(false)
              }}
              isOpen={isInstrumentOpen}
              containerStyle={{
                marginBottom: 0,
                marginTop: isWide ? 0 : Spacings.S24,
              }}
            />
            {isInstrumentOpen && !isWide && <InstrumentFilter />}
            <Selector
              onToggle={() => {
                setInstrumentOpen(false)
                setTypeOpen(!isTypeOpen)
                setPortfoliosOpen(false)
                setDateOpen(false)
              }}
              count={
                filterByTransactionType
                  ? selectedTransactionTypes.length
                  : allTransactionTypes.length
              }
              titleComponent={
                <SelectorTitleString
                  title={t('report.search-by-event-type')}
                  selectedItems={selectedTransactionTypes}
                  onRemove={(s: string) =>
                    ProfitStore.removeSelectedTransactionType(s)
                  }
                />
              }
              isOpen={isTypeOpen}
              containerStyle={{
                marginBottom: 0,
                marginTop: isWide ? 0 : Spacings.S24,
                marginLeft: isWide ? Spacings.S40 : 0,
              }}
            />
            {isTypeOpen && !isWide && <TypeFilter />}
          </SelectorContainer>
          {isDateOpen && isWide && dateFilter}
          {isPortfoliosOpen && isWide && <PortfolioFilter />}
          {isInstrumentOpen && isWide && <InstrumentFilter />}
          {isTypeOpen && isWide && <TypeFilter />}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  downloadButton: {
    height: Spacings.S32,
    marginLeft: 'auto',
    minWidth: 0,
    paddingLeft: Spacings.S16,
    paddingRight: Spacings.S16,
  },
})

export default observer(ProfitFilter)
