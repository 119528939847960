import React from 'react'

import { FundPerformanceHistorySummary } from '@taaleri/core/src/models/Fund'
import { Title3 } from '../../ui/text/Title'
import { View, StyleProp, ViewStyle } from 'react-native'
import Box from '../../ui/box/Box'
import ListItem from '../../ui/ListItem'
import { ColorFormattedPercentage } from '../../ui/text/ColorFormattedNumber'
import GlobalStyles from '../../constants/GlobalStyles'
import { useTranslation } from 'react-i18next'

interface Props {
  fundPerformanceHistorySummary?: FundPerformanceHistorySummary
  containerStyle?: StyleProp<ViewStyle>
}

function HistoryItem({ title, value }: { title: string; value: number }) {
  if (value === 0) {
    return null
  }
  return (
    <ListItem
      key={title}
      topDivider={false}
      title={title}
      textStyle={GlobalStyles.smallText}
      rightComponent={<ColorFormattedPercentage value={value} />}
    />
  )
}

export default function FundPerformanceTable({
  fundPerformanceHistorySummary,
  containerStyle,
}: Props) {
  const { t } = useTranslation()
  if (fundPerformanceHistorySummary === undefined) {
    return null
  }

  const { performanceYtd, performance3Y, performance5Y, performance10Y } =
    fundPerformanceHistorySummary

  // Do not show performance summary if all values are either null or zeroes
  const noFundPerformanceData = ![
    performanceYtd,
    performance3Y,
    performance5Y,
    performance10Y,
  ].some((value) => value)

  if (noFundPerformanceData) {
    return null
  }

  return (
    <View style={containerStyle}>
      <Title3>{t('fundChart.historic-title')}</Title3>

      <Box>
        {performanceYtd && (
          <HistoryItem
            title={`${t('filter.start-of-year')}`.toUpperCase()}
            value={performanceYtd}
          />
        )}
        {performance3Y && (
          <HistoryItem
            title={`${t('filter.years', { value: 3 }).toUpperCase()} P.A.`}
            value={performance3Y}
          />
        )}
        {performance5Y && (
          <HistoryItem
            title={`${t('filter.years', { value: 5 }).toUpperCase()} P.A.`}
            value={performance5Y}
          />
        )}
        {performance10Y && (
          <HistoryItem
            title={`${t('filter.years', { value: 10 }).toUpperCase()} P.A.`}
            value={performance10Y}
          />
        )}
      </Box>
    </View>
  )
}
