import React, { useEffect } from 'react'
import Title from '@taaleri/components/src/ui/text/Title'

import { observer } from 'mobx-react'
import { useInvestmentProposalOnboardingFinal } from '@taaleri/components/src/screens/questions/investmentproposal/useInvestmentProposal'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'

import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { View } from 'react-native'
import Spacer from '@taaleri/components/src/ui/Spacer'
import PropsChildren from '../../components/PropsChildren'
import PortfolioTypeBox from './PortfolioTypeBox'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FinanceChart from './FinanceChart'
import { TextSmall } from '../../ui/text/UiText'
import { portfolioTypeName } from '@taaleri/core/src/models/PortfolioType'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import PortfolioDistributionEdit from './investmentproposal/PortfolioDistributionEdit'
import { useTranslation } from 'react-i18next'

function InvestmentProposalContent({ children }: PropsChildren) {
  const { t } = useTranslation()
  const { investmentProposal, error, setRetry } =
    useInvestmentProposalOnboardingFinal()

  if (!investmentProposal) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const typeName = portfolioTypeName(QuestionStore.portfolioType)  

  return (
    <View>
      <Title>{t('questions.investmentProposalResult.title')}</Title>
      <Paragraph>
        {t('questions.investmentProposalResult.description')}
      </Paragraph>

      <BoxWithPadding
        title={`${t('portfolio.distribution')}`}
        titleStyle={{ marginBottom: Spacings.S24 }}
        titleRightComponent={<TextSmall>{t(typeName).toUpperCase()}</TextSmall>}
      >
        <PortfolioDistributionEdit
          investmentProposal={investmentProposal}
          singlePie={true}
          hideRiskInfo={true}
          t={t}
        />
      </BoxWithPadding>
      <Spacer />

      <BoxWithPadding
        title={`${t('questions.proposal.forecast')}`}
        titleStyle={{ marginBottom: Spacings.S24 }}
        titleRightComponent={<TextSmall>{t(typeName).toUpperCase()}</TextSmall>}
      >
        <FinanceChart {...{ investmentProposal }} />
      </BoxWithPadding>

      <Spacer />
      <PortfolioTypeBox investmentProposal={investmentProposal} />
      {children}
    </View>
  )
}

export default observer(InvestmentProposalContent)
