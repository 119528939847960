import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import { StyleSheet, View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import {
  formatCurrency,
  formatFundProfit,
  formatNumber,
} from '@taaleri/core/src/utils/format'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import { ScreenWidth } from '../../constants/BreakPoint'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import RowHover from '../../ui/RowHover'
import SecurityPdfButton from './SecurityPdfButton'
import useLayout from '../../ui/useLayout'
import { OwnerShipRowItem } from './OwnerShipRowItem'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  assetClassItem: AssetClassItem
  borderBottom?: boolean
  total?: boolean
  screenWidth: ScreenWidth
  topBorderRadius?: boolean
  bottomBorderRadius?: boolean
  showHover?: boolean
  isCash?: boolean
  isBond?: boolean
  isStructured?: boolean
  isAdvanced?: boolean
  hideDocumentColumn?: boolean
  hideDuration?: boolean
  hideYieldToMaturity?: boolean
  hideCommitmentAmount?: boolean
  hideAccruedInterest?: boolean
  color: string
}

interface HoverProps {
  children: any
  showHover?: boolean
}
function Hover({ showHover, children }: HoverProps) {
  return showHover ? <RowHover>{children}</RowHover> : <>{children}</>
}

export function OwnershipRow({
  assetClassItem,
  borderBottom,
  color,
  total,
  bottomBorderRadius,
  topBorderRadius,
  showHover,
  isBond,
  isStructured,
  isAdvanced,
  hideDocumentColumn,
  hideDuration,
  hideYieldToMaturity,
  hideCommitmentAmount,
  hideAccruedInterest,
  isCash,
}: Props) {
  const {
    marketValue,
    purchaseValue,
    sharePercentage,
    marketValueChangePercent,
    duration,
    yieldToMaturity,
    marketPriceWithoutInterests,
    accruedInterest,
    quantity,
    purchasePrice,
    commitmentAmount,
    nameFi,
    nameSv,
  } = assetClassItem
  const marketValueChange = marketValue - purchaseValue

  const numberStyles = [styles.text, total && styles.bold, styles.number]
  const numberPercentageStyles = [
    styles.text,
    total && styles.bold,
    styles.number,
  ]
  const { isSmall, isMedium } = useLayout()

  const getSecurityName = (): string =>
    AppStore.currentLanguage === fiKey ? nameFi : nameSv

  return (
    <Hover showHover={showHover}>
      <FlexRow
        style={[
          {
            borderLeftColor: color,
            borderLeftWidth: isSmall ? 0 : 4,
          },
          topBorderRadius && { borderTopLeftRadius: BorderRadius.default },
          bottomBorderRadius && {
            borderBottomLeftRadius: BorderRadius.default,
          },
        ]}
      >
        <View />

        {!isSmall && (
          <FlexRow
            style={[
              styles.main,
              styles.mainWide,
              borderBottom && styles.borderBottom,
              { justifyContent: 'space-between' },
            ]}
          >
            <View style={[styles.title]}>
              <TextDefault
                type="h7"
                style={[styles.text, { paddingLeft: Spacings.S20 }]}
              >
                {getSecurityName()}
              </TextDefault>
            </View>
            <SecurityPdfButton
              assetClassItem={assetClassItem}
              style={[styles.downloadLink, { alignItems: 'center' }]}
              hidden={!isAdvanced || hideDocumentColumn}
            />
            <OwnerShipRowItem
              value={commitmentAmount}
              style={numberStyles}
              format={formatCurrency}
              hidden={isMedium || hideCommitmentAmount || !isAdvanced}
            />

            <OwnerShipRowItem
              value={quantity}
              style={[numberStyles]}
              format={formatNumber}
              hidden={isMedium || !isAdvanced || isCash}
            />
            <OwnerShipRowItem
              value={purchasePrice}
              style={[numberStyles, { flex: 2 }]}
              format={formatNumber}
              hidden={isMedium || !isAdvanced}
            />
            <OwnerShipRowItem
              value={purchaseValue}
              style={numberStyles}
              format={formatCurrency}
              hidden={isMedium}
            />
            <OwnerShipRowItem
              value={marketPriceWithoutInterests}
              style={[numberStyles, { flex: 2 }]}
              format={formatNumber}
              hidden={isMedium || !isAdvanced}
            />
            <OwnerShipRowItem
              value={
                /* 
                Calculate market value without interests 
                for bonds excluding structured investments
                */
                isBond && !isStructured
                  ? Math.max(0, marketValue - (accruedInterest || 0))
                  : marketValue
              }
              style={numberStyles}
              format={formatCurrency}
            />
            <OwnerShipRowItem
              value={accruedInterest}
              style={numberStyles}
              format={formatCurrency}
              hidden={!isBond || isStructured || hideAccruedInterest}
            />
            <OwnerShipRowItem
              value={sharePercentage}
              style={[numberStyles, { flex: 2 }]}
              format={formatFundProfit}
            />
            <OwnerShipRowItem
              value={marketValueChange}
              style={numberStyles}
              format={formatCurrency}
              colorFormatted={true}
              hidden={isMedium}
            />
            <OwnerShipRowItem
              value={marketValueChangePercent}
              style={[numberPercentageStyles, { flex: 2 }]}
              format={formatFundProfit}
              colorFormatted={true}
            />
            <OwnerShipRowItem
              value={yieldToMaturity}
              style={numberPercentageStyles}
              format={formatFundProfit}
              hidden={!isBond || isStructured || hideYieldToMaturity}
            />
            <OwnerShipRowItem
              value={duration}
              style={[numberPercentageStyles, { flex: 2 }]}
              format={(value: number) => value.toFixed(1).replace('.', ',')}
              hidden={!isBond || isStructured || hideDuration}
            />
          </FlexRow>
        )}

        {isSmall && (
          <OwnershipMobile
            assetClassItem={assetClassItem}
            borderBottom={borderBottom}
            isSmall={isSmall}
            total={total}
          />
        )}
      </FlexRow>
    </Hover>
  )
}

export function OwnershipMobile({
  assetClassItem,
  borderBottom,
  total,
  isSmall,
}: {
  assetClassItem: AssetClassItem
  isSmall: boolean
  total?: boolean
  borderBottom?: boolean
}) {
  const {
    marketValue,
    sharePercentage,
    marketValueChangePercent,
    nameFi,
    nameSv,
  } = assetClassItem

  const getSecurityName = (): string =>
    AppStore.currentLanguage === fiKey ? nameFi : nameSv

  if (!isSmall) {
    return null
  }
  return (
    <FlexRow style={[styles.main, borderBottom && styles.borderBottom]}>
      <View style={{ flex: 80 }}>
        <TextDefault type="t2" style={[styles.text, total && styles.bold]}>
          {getSecurityName()}
        </TextDefault>

        <FlexRow style={{ alignItems: 'center' }}>
          <OwnerShipRowItem
            value={marketValue}
            style={styles.text}
            format={formatCurrency}
            type="h5"
          />
          <OwnerShipRowItem
            value={marketValueChangePercent}
            style={[
              styles.textSmall,
              {
                marginLeft: 10,
              },
            ]}
            format={formatFundProfit}
            colorFormatted={true}
          />
        </FlexRow>
      </View>
      <OwnerShipRowItem
        value={sharePercentage}
        style={[
          styles.percentageSmall,
          total && styles.bold,
          { opacity: sharePercentage === 0 ? 0 : 1 },
        ]}
        format={formatFundProfit}
      />
    </FlexRow>
  )
}

const lineHeight = 22
const styles = StyleSheet.create({
  title: {
    flex: 8,
  },
  hidden: {
    opacity: 0,
  },
  number: {
    flex: 3,
    textAlign: 'right',
    marginHorizontal: Spacings.S2,
    maxWidth: 120,
  },
  numberNarrow: {
    flex: 2,
    textAlign: 'right',
    marginHorizontal: Spacings.S2,
    maxWidth: 120,
  },
  downloadLink: {
    flex: 1,
    marginHorizontal: Spacings.S2,
  },
  text: {
    lineHeight,
  },
  textSmall: {
    fontSize: FontSize.S10,
    lineHeight,
  },
  bold: {
    fontFamily: Fonts.avenirNextBold,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  main: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: Spacings.PADDING,
    paddingTop: 12,
    paddingBottom: 14,
  },
  mainWide: {
    paddingVertical: Spacings.S16,
    paddingLeft: 0,
  },
  percentageSmall: {
    flex: 20,
    lineHeight,
    fontSize: FontSize.S12,
    textAlign: 'right',
  },
})
export const OwnershipRowStyles = styles
