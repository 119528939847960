import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

export default function PaymentSelectContent() {
  const { t } = useTranslation()
  return (
    <>
      <Title style={{ marginBottom: Spacings.S16 }}>{t('app.lastupdates.additional-purchase')}</Title>
      <Paragraph>
        {t('invest.one-time-or-monthly')}
      </Paragraph>
    </>
  )
}
