import { TimeSerieItem } from '../models/YieldHistory'

export function getFinalMarketValue(
  marketValueTimeSeries: TimeSerieItem[] = []
) {
  return marketValueTimeSeries.length > 1
    ? marketValueTimeSeries[marketValueTimeSeries.length - 1].value
    : 0
}

export function calculateMarketValueChange(
  marketValueTimeSeries: TimeSerieItem[] = []
): number {
  return marketValueTimeSeries.length > 1
    ? marketValueTimeSeries[marketValueTimeSeries.length - 1].value -
        marketValueTimeSeries[0].value
    : 0
}

export function calculateTwrChange(twrTimeSeries: TimeSerieItem[] = []) {
  return twrTimeSeries.length > 0 ? twrTimeSeries.slice(-1)[0].value - 100 : 0
}

export function calculateVolatilityChange(
  volatilityTimeSeries: TimeSerieItem[] = []
) {
  return volatilityTimeSeries.length > 0
    ? volatilityTimeSeries.slice(-1)[0].value - 100
    : 0
}

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined
}
