import random from 'lodash/random'

export const DayTexts = [
  'hello',
  'hello-moi',
  'hi',
  'greeting',
]
export const EveningTexts = [
  'evening',
  'good-evening',
]
export const NightTexts = [
  'hello-moi',
  'hi',
  'greeting',
]

function randomItem(arr: string[]) {
  return arr[random(0, arr.length - 1)]
}

export function dayTimeText(date: Date) {
  const hours = date.getHours()
  if (hours >= 5 && hours <= 10) {
    return 'morning'
  }
  if (hours >= 11 && hours <= 16) {
    return randomItem(DayTexts)
  }
  if (hours >= 17 && hours <= 23) {
    return randomItem(EveningTexts)
  }

  return randomItem(NightTexts)
}
