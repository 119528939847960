import {
  isMoney,
  ASSET_CLASS_ID_CASH,
  MONEY,
} from '@taaleri/core/src/constants/AssetClass'
import { Fund } from '@taaleri/core/src/models/Fund'
import { NOTFOUND } from '@taaleri/core/src/models/ServiceResponse'
import { findFund } from '@taaleri/core/src/services/funds'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatDateDash } from '@taaleri/core/src/utils/format'
import { useState, useEffect } from 'react'

export function useFund(ticker: string | undefined) {
  const [fund, setFund] = useState<Fund>()
  const [error, setError] = useState<boolean>(false)
  const [notFound, setNotFound] = useState<boolean>(false)

  async function fetchFund() {
    if (!(typeof ticker === 'string')) {
      return
    }
    setNotFound(false)
    const cachedFund = AppStore.fund
    if (cachedFund && cachedFund.ticker === ticker) {
      setFund(cachedFund)
      return
    }
    if (isMoney(ticker)) {
      // @ts-ignore
      setFund({
        ticker: MONEY,
        name: 'Käteinen',
        assetClassId: ASSET_CLASS_ID_CASH,
        fundRedemption: formatDateDash(new Date()),
        isAvailableForRedemption: true,
      })
      AppStore.loadingFund = false

      return
    }
    AppStore.loadingFund = true
    setError(false)
    // As a temporarily solution we hide the donut chart in the Omistukset page if the fund is not found
    const fundResult = await findFund(ticker, true)
    if (fundResult.success && fundResult.response) {
      setFund(fundResult.response)
      AppStore.fund = fundResult.response
    } else if (fundResult.success === false && fundResult.error === NOTFOUND) {
      setNotFound(true)
    } else {
      setError(true)
    }
    AppStore.loadingFund = false
  }

  useEffect(() => {
    fetchFund()
  }, [ticker])

  return { fund, error, retry: fetchFund, notFound }
}
