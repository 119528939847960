import React from 'react'
import { View, StyleSheet } from 'react-native'
import FlexRow from '../../ui/FlexRow'
import { TextMedium, TextBold } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import GlobalStyles from '../../constants/GlobalStyles'
import { IconCircle, IconCheckCircle } from '../../ui/icons/CircleIcons'
import { useTranslation } from 'react-i18next'

interface TableRowProps {
  label: string
  etfValue: string
  impactValue: string
}

interface TableRowCheckProps {
  label: string
  etfCheck?: boolean
  impactCheck?: boolean
}

function TableRow({ label, etfValue, impactValue }: TableRowProps) {
  return (
    <FlexRow style={styles.tableRow}>
      <TextMedium style={styles.labelColumn}>{label}</TextMedium>
      <FlexRow style={styles.numberContainer}>
        <View style={[styles.tableCell, styles.borderRight]}>
          <TextBold style={[styles.tableCell]}>{impactValue}</TextBold>
        </View>
        <TextBold style={styles.tableCell}>{etfValue}</TextBold>
      </FlexRow>
    </FlexRow>
  )
}

const getCheckMark = (checked?: boolean) =>
  checked ? (
    <IconCheckCircle style={styles.tableCell} />
  ) : (
    <IconCircle style={styles.tableCell} />
  )

function TableRowCheckCirle({
  label,
  etfCheck,
  impactCheck,
}: TableRowCheckProps) {
  return (
    <FlexRow style={styles.tableRow}>
      <TextMedium style={styles.labelColumn}>{label}</TextMedium>
      <FlexRow style={styles.numberContainer}>
        <View style={[styles.tableCell, styles.borderRight]}>
          {getCheckMark(impactCheck)}
        </View>
        {getCheckMark(etfCheck)}
      </FlexRow>
    </FlexRow>
  )
}

function TableHeader({ riskLevel }: { riskLevel: number }) {
  const { t } = useTranslation()
  return (
    <FlexRow style={styles.tableHeader}>
      <TextBold style={styles.labelColumn}>{`${t('comparison.risk-level', {
        level: riskLevel,
      })}`}</TextBold>
      <FlexRow style={styles.numberContainer}>
        <TextMedium style={[styles.tableCell, GlobalStyles.smallText]}>
          {t('menu.impact').toUpperCase()}
        </TextMedium>

        <TextMedium style={[styles.tableCell, GlobalStyles.smallText]}>
          {t('profile.contractSection.etf')}
        </TextMedium>
      </FlexRow>
    </FlexRow>
  )
}

type RowKeys = 'cost' | 'companyCount' | 'co2'

interface Value {
  etfValue: string
  impactValue: string
}

type Values = Record<RowKeys, Value>

export default function EtfComparisonTableContent({
  cost,
  co2,
  companyCount,
  riskLevel,
}: Values & { riskLevel: number }) {
  const { t } = useTranslation()
  return (
    <>
      <TableHeader riskLevel={riskLevel} />
      <TableRow
        label={t('fund.managementfee')}
        impactValue="0,45 %"
        etfValue="0,45 %"
      />
      <TableRow
        label={t('comparison.cost-of-products')}
        impactValue={cost.impactValue}
        etfValue={cost.etfValue}
      />
      <TableRow
        label={t('comparison.company-count')}
        impactValue={companyCount.impactValue}
        etfValue={companyCount.etfValue}
      />
      <TableRow
        label={t('comparison.co2-value')}
        impactValue={co2.impactValue}
        etfValue={co2.etfValue}
      />
      <TableRowCheckCirle
        label={t('comparison.for-you')}
        impactCheck={true}
        etfCheck={true}
      />
      <TableRowCheckCirle
        label={t('comparison.etf')}
        impactCheck={true}
        etfCheck={true}
      />
      <TableRowCheckCirle
        label={t('comparison.balanced')}
        impactCheck={true}
        etfCheck={true}
      />
      <TableRowCheckCirle
        label={t('comparison.invest-responsible')}
        impactCheck={true}
      />
      <TableRowCheckCirle
        label={t('comparison.environmental-benefit')}
        impactCheck={true}
      />
      <TableRowCheckCirle
        label={t('comparison.social-benefit')}
        impactCheck={true}
      />
    </>
  )
}

const styles = StyleSheet.create({
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: Colors.border,
  },
  tableHeader: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    paddingBottom: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    paddingVertical: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelColumn: {
    flex: 3,
  },
  numberContainer: {
    flex: 2,
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  tableCell: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flex: 1,
    maxWidth: 80,
  },
})
