import React from 'react'
import { DiscountCodeDoneContent } from '@taaleri/components/src/screens/profile/DiscountCodeDoneContent'
import DoneScreen from './DoneScreen'
import { ROUTE_REPORT } from '@taaleri/components/src/screens/report/ReportRoutes'

export function DiscountCodeDoneScreen() {
  return (
    <DoneScreen nextScreen={ROUTE_REPORT}>
      <DiscountCodeDoneContent />
    </DoneScreen>
  )
}
