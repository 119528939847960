import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { api } from '../api/api'
import { Identity } from '../models/Identity'

export async function getOrders(customerId: number): Promise<any> {
  try {
    const identity: Identity = await getIdentity()
    const orders = await api().orders.orders(identity, customerId)

    return orders
  } catch (e) {
    handleErrors(e, 'orders', 'fatal')
    // DO NOT FAIL IF ORDERS NOT FOUND
    return []
  }
}
