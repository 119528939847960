import FeedContent from '@taaleri/components/src/screens/feed/FeedContent'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { SubtitleHome } from './SubtitleHome'

export default function HomeFeed() {
  const { t } = useTranslation()
  return (
    <View>
      <SubtitleHome>{t('app.lastupdates.header')}</SubtitleHome>
      <FeedContent />
    </View>
  )
}
