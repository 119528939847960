import styled from 'styled-components'
import { TextMedium, TextBold } from './UiText'
import Colors from '@taaleri/core/src/constants/Colors'

export const TextMediumHover = styled(TextMedium)`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${Colors.textHover} !important;
    }
  }
`

export const TextMediumHoverPrimary = styled(TextMedium)`
  color: ${Colors.primary};
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${Colors.textPrimaryHover} !important;
    }
  }
`

export const TextBoldHover = styled(TextBold)`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${Colors.textHover} !important;
    }
  }
`
