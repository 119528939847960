import React from 'react'
import BankTransferContent from '@taaleri/components/src/screens/onboarding/BankTransferContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { ROUTE_PORTFOLIO_BANK_TRANSFER_DONE } from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper.native'
import { PaymentSteps } from '@taaleri/components/src/components/Steps'

export default function PaymentBankTransferScreen() {
  return (
    <>
      <PaymentSteps currentStep={2} />
      <ContentContainerWide>
        <BankTransferContent
          isOnboarding={false}
          nextRoute={routePathApp(ROUTE_PORTFOLIO_BANK_TRANSFER_DONE)}
        />
      </ContentContainerWide>
    </>
  )
}
