import AssetDetails from '@taaleri/components/src/screens/portfolio/AssetDetails'
import PortfolioActions from '@taaleri/components/src/screens/portfolio/PortfolioActions'
import PortfolioChartContainer from '@taaleri/components/src/screens/portfolio/PortfolioChartContainer'
import PortfolioPie from '@taaleri/components/src/screens/portfolio/PortfolioPie'
import PortfolioValueBox from '@taaleri/components/src/screens/portfolio/PortfolioValueBox'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import useQueryParams from 'navigation/useQueryParams'
import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import GradientContainer from 'ui/GradientContainer'

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  @media (min-width: 1000px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  margin-bottom: ${Spacings.S16}px;

  @media (min-width: 1000px) {
    grid-row-gap: 38px;
    grid-column-gap: 24px;
    div:nth-child(2) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
`

const ValuePieContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

function PortfolioContentDigi() {
  const params = useQueryParams()
  const paymentResult = params.paymentResult
  const portfolio = AppStore.portfolio
  const yieldHistory = AppStore.yieldHistory
  const { isSmall } = useLayout()

  if (paymentResult) {
    AppStore.newInvestment = 0
  }

  if (!portfolio || !yieldHistory) {
    return <LoadingIndicator />
  }

  return (
    <GradientContainer>
      <>
        <Grid>
          <ValuePieContainer>
            <PortfolioValueBox
              value={portfolio.marketValue}
              loading={AppStore.loading}
              style={{ marginBottom: Spacings.S24 }}
            />
            <PortfolioPie portfolio={portfolio} />
          </ValuePieContainer>
          <View>
            <PortfolioChartContainer
              yieldHistory={yieldHistory}
              portfolio={portfolio}
            />
          </View>
          {isSmall && <PortfolioActions />}
        </Grid>
        <AssetDetails linkTitle={true} portfolio={portfolio} />
      </>
    </GradientContainer>
  )
}

export default observer(PortfolioContentDigi)
