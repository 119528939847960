import DownloadReportButton, {
  ReportValues,
} from '@taaleri/components/src/screens/portfolio/DownloadReportButton'
import {
  getPurchaselotsReportPdfUrl,
  getSummaryReportPdfUrl,
  getSummaryReportPdfUrlNew,
} from '@taaleri/components/src/screens/reporttransaction/TransactionPdfUrl'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import {
  IconPortfolioReport,
  IconCalendarFilter,
  IconCircleMedium,
} from '@taaleri/components/src/ui/Icons'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import {
  IconChevronDown,
  IconChevronUp,
} from '@taaleri/components/src/ui/icons/ChevronIcons'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { translateFilterItem } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { observer } from 'mobx-react'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import styled from 'styled-components'

import DateFilter from './DateFilter'
import PortfolioFilter from './PortfolioFilter'
import RestrictionFilterDesktop from './RestrictionFilterDesktop'

const SelectBox = styled.div<{ isOpen: boolean }>`
  flex: 1;
  border: 1px solid ${Colors.border};
  border-radius: ${BorderRadius.default}px;
  padding: 15px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.backgroundPale};
  }
  background-color: ${Colors.white};
`

const Count = styled.div`
  position: absolute;
  top: 8px;
  display: flex;
  justify-content: center;
  width: 24px;
  font-size: 10px;
  color: ${Colors.primary};
  font-family: ${Fonts.avenirNextBold};
`

interface SelectorProps {
  onToggle: () => void
  isOpen: boolean
  style?: CSSProperties
}

export interface ToggleProps {
  onToggle: (value: boolean) => void
  isMobile?: boolean
  isPortfolioScreen?: boolean
}

export const FilterBox = styled.div`
  border: 1px solid ${Colors.border};
  border-radius: ${BorderRadius.default}px;
  padding: 12px;
`

function PortfolioSelectorInternal({ onToggle, isOpen, style }: SelectorProps) {
  const { t } = useTranslation()
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <View>
            <IconPortfolioReport />
            <Count>{ReportStore.selectedReportingPortfolios.length}</Count>
          </View>
          <TextDefault type="h6" style={{ marginLeft: 10 }}>
            {ReportStore.allReportingPortfoliosSelected
              ? `${t('portfolio.all')}`
              : `${t('portfolio.selected')}`}
          </TextDefault>
        </FlexRow>
        {isOpen && <IconChevronDown />}
        {!isOpen && <IconChevronUp />}
      </FlexRow>
    </SelectBox>
  )
}

const PortfolioSelector = observer(PortfolioSelectorInternal)

function DateSelectorInternal({ onToggle, isOpen, style }: SelectorProps) {
  const { t } = useTranslation()
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <View>
            <IconCalendarFilter />
          </View>
          <TextDefault type="h6" style={{ marginLeft: 10 }}>
            {typeof ReportStore.dateRangeTitle === 'string'
              ? ReportStore.dateRangeTitle
              : translateFilterItem(ReportStore.dateRangeTitle, t)}
          </TextDefault>
        </FlexRow>
        {isOpen && <IconChevronDown />}
        {!isOpen && <IconChevronUp />}
      </FlexRow>
    </SelectBox>
  )
}

const DateSelector = observer(DateSelectorInternal)

const RestrictionSelector = observer(RestrictionSelectorInternal)

function RestrictionSelectorInternal({
  onToggle,
  isOpen,
  style,
}: SelectorProps) {
  const { t } = useTranslation()
  const assetClassCount = ReportStore.allAssetClasses().length
  if (assetClassCount === 0) {
    return null
  }
  return (
    <SelectBox onClick={onToggle} isOpen={isOpen} style={style}>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <View>
            <IconCircleMedium />
            <Count style={{ top: 5, fontSize: 12 }}>{assetClassCount}</Count>
          </View>
          <TextDefault type="h6" style={{ marginLeft: 10 }}>
            {t('portfolio.limit')}
          </TextDefault>
        </FlexRow>
        {isOpen && <IconChevronDown />}
        {!isOpen && <IconChevronUp />}
      </FlexRow>
    </SelectBox>
  )
}

export interface RestrictionProps {
  restrictionOpen: boolean
  setRestrictionOpen: (value: boolean) => void
  portfolioOpen: boolean
  setPortfolioOpen: (value: boolean) => void
  dateOpen: boolean
  setDateOpen: (value: boolean) => void
}

function ReportFiltersDesktop({
  restrictionOpen,
  setRestrictionOpen,
  portfolioOpen,
  setPortfolioOpen,
  dateOpen,
  setDateOpen,
}: RestrictionProps) {
  const { t } = useTranslation()

  if (!AppStore.reportingPortfolioList) {
    return null
  }

  const {
    reportingPortfoliosList,
    selectedReportingPortfolios,
    token,
    loadingToken,
    startDate,
    endDate,
  } = ReportStore

  const { isAdmin } = AppStore

  const filtersOpen = restrictionOpen || dateOpen || portfolioOpen

  return (
    <BoxWithPadding style={{ marginBottom: 24 }}>
      <FlexRow>
        {(reportingPortfoliosList.length > 1 || isAdmin) && (
          <PortfolioSelector
            onToggle={() => {
              setPortfolioOpen(!portfolioOpen)
              setDateOpen(false)
              setRestrictionOpen(false)
            }}
            isOpen={portfolioOpen}
            style={{
              marginRight: Spacings.S24,
              borderColor: portfolioOpen ? Colors.primary : Colors.border,
              borderWidth: portfolioOpen ? 2 : 1,
            }}
          />
        )}

        <DateSelector
          onToggle={() => {
            setDateOpen(!dateOpen)
            setPortfolioOpen(false)
            setRestrictionOpen(false)
          }}
          isOpen={dateOpen}
          style={{
            marginRight: Spacings.S24,
            borderColor: dateOpen ? Colors.primary : Colors.border,
            borderWidth: dateOpen ? 2 : 1,
          }}
        />
        <RestrictionSelector
          onToggle={() => {
            setRestrictionOpen(!restrictionOpen)
            setPortfolioOpen(false)
            setDateOpen(false)
          }}
          isOpen={restrictionOpen}
          style={{
            marginRight: Spacings.S24,
            borderColor: restrictionOpen ? Colors.primary : Colors.border,
            borderWidth: restrictionOpen ? 2 : 1,
          }}
        />
        {loadingToken || (
          <DownloadReportButton
            title={`${t('portfolio.load-report')}`}
            reportType="summary"
            fetchUrl={(values: ReportValues) => {
              const { customerId } = AppStore
              if (values.reportType === 'summary') {
                return getSummaryReportPdfUrl(
                  customerId,
                  startDate,
                  endDate,
                  token,
                  values.loadBenchmarkIndexData,
                  selectedReportingPortfolios
                )
              } else if (values.reportType === 'summaryNew') {
                return getSummaryReportPdfUrlNew(
                  customerId,
                  startDate,
                  endDate,
                  token,
                  values.loadBenchmarkIndexData,
                  selectedReportingPortfolios
                )
              } else {
                return getPurchaselotsReportPdfUrl(
                  customerId,
                  startDate,
                  endDate,
                  token,
                  selectedReportingPortfolios,
                  values.fileFormat,
                  values.byPortfolio ? 'Portfolio' : 'List',
                  values.byPurchaselots ? 'Purchaselot' : 'Security'
                )
              }
            }}
            type="secondary"
            buttonStyle={{
              height: '100%',
              borderColor: Colors.border,
              minWidth: 0,
              paddingLeft: Spacings.S16,
              paddingRight: Spacings.S16,
            }}
          />
        )}
      </FlexRow>
      <View style={{ marginTop: filtersOpen ? Spacings.S20 : 0 }}>
        {portfolioOpen && <PortfolioFilter onToggle={setPortfolioOpen} />}
        {dateOpen && (
          <DateFilter onToggle={setDateOpen} isPortfolioScreen={true} />
        )}
        {restrictionOpen && <RestrictionFilterDesktop />}
      </View>
    </BoxWithPadding>
  )
}

export default observer(ReportFiltersDesktop)
