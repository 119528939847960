import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import SmsVerification from '../contract/SmsVerification'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { observer } from 'mobx-react'
import { ROUTE_PROFILE } from './ProfileRoutes'
import { authentication } from '@taaleri/core/src/services/authentication'
import { ROUTE_TWO_FACTOR_TOGGLE_DONE } from '../../navigation/routes'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

export interface Props {
  navigate: (route: string, params?: any) => void
}

function TwoFactorToggleVerification(props: Props) {
  const { t } = useTranslation()
  const onSuccess = async () => {
    Analytics.event(
      'Profile',
      AppStore.twoFactorEnabled ? 'Disable 2FA' : 'Enable 2F2'
    )
    AppStore.twoFactorEnabled = !AppStore.twoFactorEnabled
    props.navigate(ROUTE_TWO_FACTOR_TOGGLE_DONE)
  }

  const title = isWeb ? `${t('profile.two-way-authentication')}` : ''
  const phone = AppStore.customer.phone
  const twoFactorEnabled = AppStore.twoFactorEnabled
  const description = twoFactorEnabled
    ? t('profile.verify-two-way-removal', { phone: phone })
    : t('profile.verify-two-way-activation', { phone: phone })

  return (
    <SmsVerification
      t={t}
      title={title}
      description={description}
      onSuccess={() => onSuccess()}
      phone={phone}
      afterConflictNavigateTo={ROUTE_PROFILE}
      onPrepareVerification={async () => {
        const { success, response } = await authentication.setTwoFactorAuth(
          !twoFactorEnabled
        )
        return success && response && response.smsConfirmationId
          ? response.smsConfirmationId
          : null
      }}
      navigate={props.navigate}
    />
  )
}

export default observer(TwoFactorToggleVerification)
