import { ProductKnowledgeOption } from '../../constants/Options'
import { CustomerBasicInfoResponse } from '../../models/Customer'
import {
  findQuestionById,
  productKnowledgeTags,
  QUESTION_DAILY_IMPORTANCE,
  QUESTION_DEBT,
  QUESTION_ESG_ENVIRONMENT,
  QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
  QUESTION_ESG_SFDR,
  QUESTION_ESG_START,
  QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
  QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
  QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  QUESTION_INVESTMENT_ASSETS,
  QUESTION_INVESTMENT_GOAL,
  QUESTION_INVESTMENT_HORIZONT,
  QUESTION_INVESTOR_EXPERIENCE,
  QUESTION_LOSS_TOLERANCE,
  QUESTION_LOSS_TOLERANCE_BEHAVIOR,
  QUESTION_MONTHLY_CASH_DEPOSITS,
  QUESTION_MONTHLY_CASH_WITHDRAWALS,
  QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
  QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
  QUESTION_PHYSICAL_ASSETS,
  QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
  QUESTION_SHARE_TO_TAALERI,
} from '@taaleri/components/src/screens/questions/questions'
import { Answers } from '../../models/Question'
import { t } from 'i18next'

export interface BasicInfoProductFamiliarity {
  [key: string]: string
  knowledgeDiscretionaryAssetManagement: string
  knowledgeEquities: string
  knowledgeCapitalInvestments: string
  knowledgeLeveragedSecurities: string
  knowledgeCommodities: string
  knowledgeFixedIncome: string
  knowledgeMoneyMarketFunds: string
  knowledgeComplexDebt: string
  knowledgeCombinationFunds: string
  knowledgeCapitalSecuredStructuredProducts: string
  knowledgeUnsecuredStructuredProducts: string
}

const unFamiliar = ProductKnowledgeOption.DoesNotKnow
const isFamiliar = (value: string) =>
  value &&
  (value === ProductKnowledgeOption.Knows ||
    value === ProductKnowledgeOption.HasInvested)
    ? true
    : false
const hasInvested = (value: string) =>
  value && value === ProductKnowledgeOption.HasInvested ? true : false

export function toProductFamiliarity(basicInfo: BasicInfoProductFamiliarity) {
  return productKnowledgeTags.map(({ value, label }) => ({
    value,
    label,
    selected: isFamiliar(basicInfo[value]),
    forced: false,
  }))
}

export function toProductInvestments(basicInfo: BasicInfoProductFamiliarity) {
  return productKnowledgeTags.map(({ value, label }) => ({
    value,
    label,
    selected: hasInvested(basicInfo[value]),
    forced: false,
  }))
}

export function toForcedKnows(
  forcedKnowledgeResponse: BasicInfoProductFamiliarity
): string[] {
  return toProductFamiliarity(forcedKnowledgeResponse)
    .filter((a) => a.selected)
    .map((b) => b.value)
}

export function toForcedHasInvested(
  forcedKnowledgeResponse: BasicInfoProductFamiliarity
): string[] {
  return toProductInvestments(forcedKnowledgeResponse)
    .filter((a) => a.selected)
    .map((b) => b.value)
}

export function toAnswers(
  customerBasicInfoResponse: CustomerBasicInfoResponse
): Answers {
  const { basicInfo } = customerBasicInfoResponse
  const physicalAssets = findQuestionById(QUESTION_PHYSICAL_ASSETS, t)
  const investmentAssets = findQuestionById(QUESTION_INVESTMENT_ASSETS, t)
  const dailyImportance = findQuestionById(QUESTION_DAILY_IMPORTANCE, t)
  const debt = findQuestionById(QUESTION_DEBT, t)
  const investorExperience = findQuestionById(QUESTION_INVESTOR_EXPERIENCE, t)
  const investmentGoal = findQuestionById(QUESTION_INVESTMENT_GOAL, t)
  const investmentHorizont = findQuestionById(QUESTION_INVESTMENT_HORIZONT, t)
  const lossTolerance = findQuestionById(QUESTION_LOSS_TOLERANCE, t)
  const lossToleranceBehaviour = findQuestionById(
    QUESTION_LOSS_TOLERANCE_BEHAVIOR,
    t
  )
  const hasMonthlyMoneyTransfersFinland = findQuestionById(
    QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
    t
  )

  const incomingMoneyWithinFinland = findQuestionById(
    QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
    t
  )
  const outgoingMoneyWithinFinland = findQuestionById(
    QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
    t
  )
  const hasMonthlyForeignTransfers = findQuestionById(
    QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
    t
  )

  const incomingForeignMoneyTransfers = findQuestionById(
    QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
    t
  )
  const outgoingForeignMoneyTarnsfers = findQuestionById(
    QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
    t
  )
  const hasMonthlyCashTransactions = findQuestionById(
    QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
    t
  )
  const cashDeposits = findQuestionById(QUESTION_MONTHLY_CASH_DEPOSITS, t)
  const cashWithdrawals = findQuestionById(QUESTION_MONTHLY_CASH_WITHDRAWALS, t)
  const esgStart = findQuestionById(QUESTION_ESG_START, t)
  const esgEnvironment = findQuestionById(QUESTION_ESG_ENVIRONMENT, t)
  const esgSfdr = findQuestionById(QUESTION_ESG_SFDR, t)
  const esgHarmful = findQuestionById(QUESTION_ESG_HARMFUL_EMISSION_EFFECTS, t)
  const illiquidAllowed = findQuestionById(QUESTION_ILLIQUID_ASSETS_ALLOWED, t)
  const shareToTaaleri = findQuestionById(QUESTION_SHARE_TO_TAALERI, t)
  const productKnowledgePartOne = findQuestionById(
    QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
    t
  )
  const productKnowledgePartTwo = findQuestionById(
    QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
    t
  )

  const basicInfoProductFamiliarity = {
    knowledgeDiscretionaryAssetManagement:
      basicInfo.knowledgeDiscretionaryAssetManagement || unFamiliar,
    knowledgeEquities: basicInfo.knowledgeEquities || unFamiliar,
    knowledgeCapitalInvestments:
      basicInfo.knowledgeCapitalInvestments || unFamiliar,
    knowledgeLeveragedSecurities:
      basicInfo.knowledgeLeveragedSecurities || unFamiliar,
    knowledgeCommodities: basicInfo.knowledgeCommodities || unFamiliar,
    knowledgeFixedIncome: basicInfo.knowledgeFixedIncome || unFamiliar,
    knowledgeMoneyMarketFunds:
      basicInfo.knowledgeMoneyMarketFunds || unFamiliar,
    knowledgeComplexDebt: basicInfo.knowledgeComplexDebt || unFamiliar,
    knowledgeCombinationFunds:
      basicInfo.knowledgeCombinationFunds || unFamiliar,
    knowledgeCapitalSecuredStructuredProducts:
      basicInfo.knowledgeCapitalSecuredStructuredProducts || unFamiliar,
    knowledgeUnsecuredStructuredProducts:
      basicInfo.knowledgeUnsecuredStructuredProducts || unFamiliar,
  }

  return {
    [physicalAssets.id]: basicInfo.otherAssets || '',
    [investmentAssets.id]: basicInfo.savings || '',
    [dailyImportance.id]: basicInfo.impactOnLife || '',
    [debt.id]: basicInfo.totalDebt || '',
    [investorExperience.id]: basicInfo.investorExperience || '',
    [investmentGoal.id]: basicInfo.investmentGoal || '',
    [investmentHorizont.id]: basicInfo.investmentHorizon || '',
    [lossTolerance.id]: basicInfo.negativeReaction || '',
    [lossToleranceBehaviour.id]: basicInfo.lossReaction || '',
    [illiquidAllowed.id]: basicInfo.illiquidOptions || '',
    [shareToTaaleri.id]: basicInfo.portfolioShare || '',
    [esgStart.id]: booleanToYesNoAnswer(basicInfo.esgStart),
    [esgEnvironment.id]: basicInfo.esgTaxonomy || '',
    [esgHarmful.id]: basicInfo.esgHarmfulEmissionEffect || '',
    [esgSfdr.id]: basicInfo.esgSfdr || '',
    [productKnowledgePartOne.id]: toProductFamiliarity(
      basicInfoProductFamiliarity
    ),
    [productKnowledgePartTwo.id]: toProductInvestments(
      basicInfoProductFamiliarity
    ),
    [hasMonthlyMoneyTransfersFinland.id]: booleanToYesNoAnswer(
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.hasMonthlyMoneyTransfersWithinFinland
    ),
    [incomingMoneyWithinFinland.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.monthlyIncomingMoneyTransfersWithinFinland,
    [outgoingMoneyWithinFinland.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.monthlyOutgoingMoneyTransfersWithinFinland,
    [hasMonthlyForeignTransfers.id]: booleanToYesNoAnswer(
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.hasMonthlyForeignMoneyTransfers
    ),
    [incomingForeignMoneyTransfers.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.monthlyIncomingForeignMoneyTransfers,
    [outgoingForeignMoneyTarnsfers.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.monthlyOutgoingForeignMoneyTransfers,
    [hasMonthlyCashTransactions.id]: booleanToYesNoAnswer(
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.hasMonthlyCashTransactions
    ),
    [cashDeposits.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia?.monthlyCashDeposits,
    [cashWithdrawals.id]:
      basicInfo.privateCustomerMonetaryTransactionsInAktia
        ?.monthlyCashWithdrawals,
  }
}

function booleanToYesNoAnswer(value: boolean | undefined | null) {
  return value ? 'Yes' : 'No'
}
