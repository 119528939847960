import React, { useState } from 'react'
import styled from 'styled-components'
import Colors from '@taaleri/core/src/constants/Colors'
import AdminCustomer, {
  CustomerView,
} from '@taaleri/core/src/models/AdminCustomer'
import { authentication } from '@taaleri/core/src/services/authentication'
import { useHistory } from 'react-router-dom'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import ErrorMessage from '@taaleri/components/src/ui/form/ErrorMessage'
import RowHover from '@taaleri/components/src/ui/RowHover'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { loginToDataLayer } from 'utils/DataLayer'
import { clearCustomerCache } from '@taaleri/core/src/services/admin'
import { IconClearCache } from '@taaleri/components/src/ui/Icons'
import useModal from '@taaleri/components/src/ui/modal/useModal'
import UiModal from '@taaleri/components/src/ui/modal/UiModal'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'

const Grid = styled.div`
  background-color: ${Colors.white};
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 24px;
  margin-bottom: 100px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid ${Colors.border};
  cursor: pointer;
`

const Cell = styled.div``

function getLatestView({ views }: AdminCustomer): CustomerView | undefined {
  if (views.length > 0) {
    views.sort((a, b) => {
      const bDate = new Date(b.createdOn).getTime()
      const aDate = new Date(a.createdOn).getTime()
      return bDate - aDate
    })
    return views[0]
  }
  return undefined
}

export default function CustomerList({
  customers,
  adminToken,
  adminSurname,
}: {
  customers: AdminCustomer[]
  adminToken: string
  adminSurname?: string
}) {
  const { isVisible, toggle } = useModal()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [customer, setCustomer] = useState<AdminCustomer | undefined>()
  const history = useHistory()
  const { isSmall } = useLayout()

  async function selectCustomer(adminCustomer: AdminCustomer) {
    setCustomer(adminCustomer)
    setLoading(true)
    setError(false)
    const { success } = await authentication.signInAdmin(
      adminCustomer.customerId,
      adminToken
    )
    if (success) {
      if (adminSurname) {
        loginToDataLayer(`ADMIN/${adminSurname}`, adminCustomer.customerId)
      }
      history.push('/' + ROUTE_APP)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <LoadingIndicator
        text={`Haetaan ${customer ? customer.name : ''} tietoja`}
        type="inline"
        style={{ marginTop: Spacings.S24 }}
      />
    )
  }
  return (
    <>
      {error && (
        <ErrorMessage
          message="Jotain meni vikaan. Yritä uudelleen"
          style={{ marginTop: Spacings.S24 }}
        />
      )}

      {customers.length && !isSmall ? <Header /> : null}
      <Grid>
        {customers.map((customerCurrent: AdminCustomer, index: number) => {
          const latestCustomerView = getLatestView(customerCurrent)
          const latestLogin = latestCustomerView
            ? formatDateFull(latestCustomerView.createdOn)
            : ''
          const latestCustomerName = latestCustomerView
            ? latestCustomerView.authorizedCustomerName
            : ''
          return (
            <RowHover key={index}>
              <Row onClick={() => selectCustomer(customerCurrent)}>
                <Cell style={{ flex: 4 }}>{customerCurrent.name}</Cell>
                <Cell style={{ flex: 2 }}>{customerCurrent.customerId}</Cell>
                {isSmall || (
                  <Cell style={{ flex: 3 }}>{customerCurrent.banker}</Cell>
                )}
                {isSmall || <Cell style={{ flex: 2 }}>{latestLogin}</Cell>}
                {isSmall || (
                  <Cell style={{ flex: 3 }}>{latestCustomerName}</Cell>
                )}
                <Cell style={{ flex: 1 }}>
                  <div
                    title="Tyhjennä välimuisti"
                    onClick={(e) => {
                      e.stopPropagation()
                      clearCustomerCache(
                        adminToken,
                        customerCurrent.customerId.toString()
                      )
                      toggle(true)
                    }}
                  >
                    <IconClearCache color={Colors.primary} />
                  </div>
                </Cell>
              </Row>
            </RowHover>
          )
        })}
      </Grid>
      <div
        style={{
          position: 'absolute',
          flex: 1,
        }}
      >
        <UiModal isVisible={isVisible} onBackdropPress={() => toggle(false)}>
          <TextDefault
            type="h3"
            style={{
              marginBottom: Spacings.S16,
              textAlign: 'center',
              width: '100%',
            }}
          >
            Käyttäjän välimuisti tyhjennetty.
          </TextDefault>
        </UiModal>
      </div>
    </>
  )
}

function Header() {
  return (
    <FlexRow
      style={{
        paddignVertical: 18,
        paddingHorizontal: Spacings.S24,
        alignItems: 'flex-end',
      }}
    >
      <Cell style={{ flex: 4 }}>Nimi</Cell>
      <Cell style={{ flex: 2 }}>Asiakastunnus</Cell>
      <Cell style={{ flex: 3 }}>Pankkiiri</Cell>
      <Cell style={{ flex: 2 }}>Viimeisin kirjautuminen</Cell>
      <Cell style={{ flex: 3 }}>Kirjautuja</Cell>
      <Cell style={{ flex: 1 }}></Cell>
    </FlexRow>
  )
}
