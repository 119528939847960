import { observable, computed, action, makeAutoObservable } from 'mobx'
import {
  LAST_YEAR,
  CUSTOM_DATE_RANGE,
  DATE_RANGE,
} from '../constants/YieldHistory'
import { dateYearAgo, yesterday, dateRangeToDates } from '../utils/date'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import { getProfitReport } from '../services/reports'
import { formatDateDash, formatDateFull } from '../utils/format'
import { dateRangeValueKeys } from '../constants/DateRange'
import { ProfitItem, ProfitType } from '../models/ProfitReport'
import { PickerItem } from './TransactionStore'
import AppStore from './AppStore'
import { fiKey } from '../i18n'

function sortProfitItems(items: ProfitItem[]): ProfitItem[] {
  return items.sort((a: ProfitItem, b: ProfitItem) => {
    const transactionTimeA = new Date(a.transactionDate).getTime()
    const transactionTimeB = new Date(b.transactionDate).getTime()
    const transactionTimeComparison = transactionTimeB - transactionTimeA
    if (transactionTimeComparison !== 0) {
      return transactionTimeComparison
    }

    const paymentTimeA = new Date(a.paymentDate).getTime()
    const paymentTimeB = new Date(b.paymentDate).getTime()
    const paymentTimeComparison = paymentTimeB - paymentTimeA
    if (paymentTimeComparison !== 0) {
      return paymentTimeComparison
    }

    return 0
  })
}

function sortCapitalGainItems(items: ProfitItem[]): ProfitItem[] {
  return items.sort((a: ProfitItem, b: ProfitItem) => {
    const transactionTimeA = new Date(a.transactionDate).getTime()
    const transactionTimeB = new Date(b.transactionDate).getTime()
    const transactionTimeComparison = transactionTimeB - transactionTimeA
    if (transactionTimeComparison !== 0) {
      return transactionTimeComparison
    }

    const purchaseTimeA = new Date(a.purchaseDate).getTime()
    const purchaseTimeB = new Date(b.purchaseDate).getTime()
    const purchaseTimeComparison = purchaseTimeB - purchaseTimeA
    if (purchaseTimeComparison !== 0) {
      return purchaseTimeComparison
    }

    return 0
  })
}

class ProfitStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable.shallow
  selectedInstruments: PickerItem[] = []

  @observable
  loadingProfitReport: boolean = false

  @observable
  selectedProfitItem?: ProfitItem = undefined

  @observable
  loadingToken: boolean = false

  @observable
  token: string = ''

  @observable.shallow
  selectedTransactionTypes: string[] = []

  @observable.shallow
  selectedPortfolios: PickerItem[] = []

  @observable
  startDate: Date = dateYearAgo()

  @observable
  endDate: Date = yesterday()

  @observable
  dateRange: string = LAST_YEAR

  @observable
  fetchingError: boolean = false

  @observable
  skipFetching: boolean = false

  @observable
  profitCategory: ProfitType = 'CapitalGain'

  @observable.shallow
  profitItems: ProfitItem[] = []

  @observable.shallow
  capitalGainItems: ProfitItem[] = []

  @computed get items(): ProfitItem[] {
    if (this.profitCategory === 'CapitalGain') {
      return this.capitalGainItems
    } else if (this.profitCategory === 'Profit') {
      return this.profitItems
    }
    return []
  }

  @computed get filterByInstrument(): boolean {
    return this.selectedInstruments.length > 0
  }

  @computed get onlyOneInstrumentSelected(): boolean {
    return this.selectedInstruments.length === 1
  }

  @computed get filterByTransactionType(): boolean {
    return this.selectedTransactionTypes.length > 0
  }

  @computed get filterByPortfolios(): boolean {
    return this.selectedPortfolios.length > 0
  }

  @computed get itemsFilteredByPortfolios(): ProfitItem[] {
    let items = this.items
    if (this.filterByPortfolios) {
      items = items.filter((item: ProfitItem) =>
        this.selectedPortfolios.find((e) => e.value === item.portfolioId)
      )
    }
    return items
  }

  @computed get itemsFilteredByInstruments(): ProfitItem[] {
    let items = this.itemsFilteredByPortfolios

    if (this.filterByInstrument) {
      items = items.filter((item: ProfitItem) =>
        this.selectedInstruments.find((e) => e.value === item?.securityId)
      )
    }
    return items
  }

  @computed get itemsFilteredByTransactionType(): ProfitItem[] {
    let items = this.itemsFilteredByPortfolios

    if (this.filterByTransactionType) {
      items = items.filter((item: ProfitItem) =>
        this.selectedTransactionTypes.find(
          (e) => e === item.transactionTypeName
        )
      )
    }
    return items
  }

  @computed get filteredProfitItems(): ProfitItem[] {
    let items = this.itemsFilteredByPortfolios

    if (this.filterByInstrument) {
      items = items.filter((item: ProfitItem) =>
        this.selectedInstruments.find((e) => e.value === item?.securityId)
      )
    }

    if (this.filterByTransactionType) {
      items = items.filter((item: ProfitItem) =>
        this.selectedTransactionTypes.find(
          (e) => e === item.transactionTypeName
        )
      )
    }

    return items
  }

  @computed get dateRangeTitle(): string {
    const customRange = `${formatDateFull(this.startDate)} - ${formatDateFull(
      this.endDate
    )}`
    if (this.dateRange !== CUSTOM_DATE_RANGE) {
      return (
        dateRangeValueKeys().find((d) => d.id === this.dateRange)?.value ||
        customRange
      )
    }
    return customRange
  }

  isInstrumentSelected(item: PickerItem): boolean {
    return Boolean(this.selectedInstruments.find((e) => e.value === item.value))
  }

  isTransactionTypeSelected(item: string): boolean {
    return this.selectedTransactionTypes.includes(item)
  }

  @action
  initializeDateRange(): void {
    this.setDateRange(LAST_YEAR)
  }

  @action
  initializeFilters(): void {
    this.selectedPortfolios = []
    this.selectedTransactionTypes = []
    this.selectedInstruments = []
  }

  @action
  initializeLists(): void {
    this.profitItems = []
    this.capitalGainItems = []
    this.initializeFilters()
  }

  @action
  setCustomDateRange(startDate: Date, endDate: Date): void {
    this.startDate = startDate
    this.endDate = endDate
    this.dateRange = CUSTOM_DATE_RANGE
  }

  @action
  setDateRange(dateRange: string): void {
    const { startDate, endDate } = dateRangeToDates(dateRange as DATE_RANGE)
    if (startDate && endDate) {
      this.startDate = startDate
      this.endDate = endDate
      this.dateRange = dateRange
    }
  }

  isPortfolioSelected(item: PickerItem): boolean {
    return Boolean(this.selectedPortfolios.find((e) => e.value === item.value))
  }

  @action
  addSelectedInstrument(item: PickerItem) {
    this.selectedInstruments.push(item)
  }

  @action
  addSelectedTransactionType(item: string) {
    this.selectedTransactionTypes.push(item)
  }

  @action
  addSelectedPortfolio(item: PickerItem) {
    this.selectedPortfolios.push(item)
  }

  @action
  removeSelectedInstrument(item: PickerItem) {
    this.selectedInstruments = this.selectedInstruments.filter(
      (i) => i.value !== item.value
    )
  }

  @action
  removeSelectedTransactionType(item: string) {
    this.selectedTransactionTypes = this.selectedTransactionTypes.filter(
      (i) => i !== item
    )
  }

  @computed get allInstruments() {
    const getName = (profitItem: ProfitItem): string =>
      AppStore.currentLanguage === fiKey
        ? profitItem.securityNameFinnish
        : profitItem.securityNameSwedish
    return sortBy(
      uniqBy(
        this.itemsFilteredByTransactionType
          .filter((e) => {
            return (
              e.securityNameFinnish && e.securityNameSwedish && e.securityId
            )
          })
          .map((item) => {
            return {
              label: getName(item),
              value: item.securityId,
            }
          }),
        'value'
      ),
      (instrument) => instrument.label.toLowerCase()
    )
  }

  @computed get allPortfolios(): PickerItem[] {
    // Get all reporting portfolios from AppStore
    const reportingPortfolios =
      AppStore.reportingPortfolioList
        ?.filter((p) => !p.hideTransactions)
        .map((portfolio) => {
          return {
            label:
              AppStore.currentLanguage === fiKey
                ? portfolio.name
                : portfolio.nameSv,
            value: portfolio.id,
          }
        }) || []

    // Get all portfolios from profits list
    const portfoliosFromProfits = uniqBy(
      this.items
        .filter((e) => {
          return e.portfolioNameForCustomer && e.portfolioId
        })
        .map((item) => {
          return {
            label:
              AppStore.currentLanguage === fiKey
                ? item.portfolioNameForCustomer
                : item.portfolioNameForCustomerSv,
            value: item.portfolioId,
          }
        }),
      'value'
    )

    // Combine two lists into one and remove duplicate values
    const portfolios = sortBy(
      uniqBy(reportingPortfolios.concat(portfoliosFromProfits), 'value'),
      'label'
    )
    return portfolios
  }

  @computed get allTransactionTypes(): string[] {
    return uniq(
      this.itemsFilteredByInstruments
        .filter((e) => e.transactionTypeName)
        .map((item) => item.transactionTypeName)
    )
      .filter((t) => t)
      .sort()
  }

  @action
  removeSelectedPortfolio(item: PickerItem) {
    this.selectedPortfolios = this.selectedPortfolios.filter(
      (i) => i.value !== item.value
    )
  }

  @action
  async fetchProfitReport(customerId: number) {
    this.loadingProfitReport = true
    this.fetchingError = false
    this.initializeLists()
    const result = await getProfitReport(
      customerId,
      formatDateDash(this.startDate),
      formatDateDash(this.endDate)
    )
    if (result.success && result.response) {
      this.capitalGainItems = sortCapitalGainItems(
        result.response?.capitalGainItems ?? []
      )
      this.profitItems = sortProfitItems(result.response?.profitItems ?? [])
    } else {
      this.fetchingError = true
    }
    // this.initializeFilters()
    this.loadingProfitReport = false
  }
}

export default new ProfitStore()
