import { StyleSheet } from 'react-native'

import Spacings from '@taaleri/core/src/constants/Spacings'

import { TextDefault } from '../text/UiText'

import React from 'react'
import UiTouchable from '../UiTouchable'
import { IconAddCircle } from '../Icons'
import Colors from '@taaleri/core/src/constants/Colors'

const AddRow: React.FC<{ onPress: () => void; title: string }> = ({
  onPress,
  title,
}) => {
  return (
    <UiTouchable onPress={onPress} style={styles.container}>
      <IconAddCircle style={styles.icon} />
      <TextDefault color={Colors.primary}>{title}</TextDefault>
    </UiTouchable>
  )
}

export default AddRow

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: { marginRight: Spacings.S10 },
})
