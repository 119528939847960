import React from 'react'

import QuestionContainer from 'screens/questions/QuestionContainer'
import { useHistory } from 'react-router-dom'
import RequlatoryQuestionsForm from '@taaleri/components/src/screens/onboarding/RequlatoryQuestionsForm'
import ContentContainer from 'ui/container/ContentContainer'

export default function RequlatoryQuestionScreen() {
  const history = useHistory()
  return (
    <QuestionContainer noPadding={true}>
      <ContentContainer noPadding={true}>
        <RequlatoryQuestionsForm navigate={history.push} />
      </ContentContainer>
    </QuestionContainer>
  )
}
