import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import Selector from './Selector'
import { StyleProp, ViewStyle } from 'react-native'
import { SelectorTitleString } from './SelectorTitle'
import { useTranslation } from 'react-i18next'

interface Props {
  onToggle: () => void
  isOpen: boolean
  containerStyle?: StyleProp<ViewStyle>
}

function TypeSelector({ onToggle, isOpen, containerStyle }: Props) {
  const { t } = useTranslation()
  const {
    selectedTransactionTypes,
    allTransactionTypes,
    filterByTransactionType,
  } = TransactionStore
  const count = filterByTransactionType
    ? selectedTransactionTypes.length
    : allTransactionTypes.length

  const titleComponent = (
    <SelectorTitleString
      title={t('report.search-by-event-type')}
      onRemove={(s: string) =>
        TransactionStore.removeSelectedTransactionType(s)
      }
      selectedItems={selectedTransactionTypes}
    />
  )

  return (
    <Selector
      {...{ onToggle, isOpen, count, titleComponent, containerStyle }}
    />
  )
}

export default observer(TypeSelector)
