export const ROUTE_PROFILE = 'Profile'
export const ROUTE_PROFILE_STYLE_GUIDE = 'StyleGuide'
export const ROUTE_PROFILE_FAQ = 'Faq'
export const ROUTE_PROFILE_TESTING = 'ProfileTesting'
export const ROUTE_PROFILE_CHANGE_PASSWORD = 'ProfileChangePassword'
export const ROUTE_PROFILE_CHANGE_PASSWORD_DONE = 'ProfileChangePasswordDone'
export const ROUTE_PROFILE_CHANGE_USERNAME = 'ProfileChangeUsername'
export const ROUTE_PROFILE_CHANGE_USERNAME_VERIFICATION =
  'ProfileChangeUsernameVerification'
export const ROUTE_PROFILE_CHANGE_USERNAME_DONE = 'ProfileChangeUsernameDone'
export const ROUTE_PROFILE_CHANGE_PHONE = 'ProfileChangePhone'
export const ROUTE_PROFILE_DISCOUNT_CODE = 'ProfileDiscount'
export const ROUTE_PROFILE_DISCOUNT_CODE_DONE = 'ProfileDiscountDone'
export const ROUTE_PROFILE_CHANGE_PHONE_DONE = 'ProfileChangePhoneDone'
export const ROUTE_PROFILE_CHANGE_PHONE_ERROR = 'ProfileChangePhoneError'
export const ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER = 'ProfileChangeAccountNumber'
export const ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER_DONE =
  'ProfileChangeAccountNumberDone'
export const ROUTE_PROFILE_CHANGE_AUTHORIZATION = 'ProfileChangeAuthorization'
export const ROUTE_PROFILE_DIGI_PAYMENT_INFO = 'ProfileDigiPaymentInfo'
export const ROUTE_PROFILE_CONS_PAYMENT_INFO = 'ProfileConsPaymentInfo'
export const ROUTE_PROFILE_INVESTMENT_PROPOSAL = 'ProfileInvestmentProposal'
export const ROUTE_PROFILE_ACCOUNT_REMOVAL = 'ProfileAccountRemoval'