import React from 'react'
import { BackButtonRow } from '@taaleri/components/src/ui/BackButton'
import ContractsList from '@taaleri/components/src/screens/contracts/ContractsList'
import { useTranslation } from 'react-i18next'

function ContractScreen() {
  const { t } = useTranslation()
  return (
    <>
      <BackButtonRow title={`${t('reportBox.contracts')}`} />
      <ContractsList />
    </>
  )
}

export default ContractScreen
