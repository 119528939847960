import * as Yup from 'yup'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { TFunction } from 'i18next'

export const emailValidationSchema = (t: TFunction) => {
  return Yup.string()
    .email(`${t(ErrorMessages.incorrectEmail)}`)
    .required(`${t(ErrorMessages.missingEmail)}`)
}
const signinValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: emailValidationSchema(t),
    password: Yup.string()
      .min(10, `${t(ErrorMessages.passwordAtLeastNChars)}`)
      .required(`${t(ErrorMessages.missingPassword)}`),
  })

export default signinValidationSchema
