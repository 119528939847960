import React from 'react'
import ContentLoader from '../../ui/ContentLoader'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isWeb } from '../../constants/Platforms'

export function ReportLoadingBox() {
  return (
    <BoxWithPadding style={{ marginBottom: isWeb ? 0 : Spacings.S20 }}>
      <ContentLoader variant="facebook" />
    </BoxWithPadding>
  )
}
