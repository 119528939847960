import React from 'react'
import { AssetClassItem, PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import { View } from 'react-native'
import PurchaseLotChart from './PurchaseLotChart'
import PurchaseLotDetail from 'screens/portfolio/PurchaseLotDetail'
import { ReportChartGrid } from '@taaleri/components/src/ui/Grid'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { assetClassColorsRgb } from '@taaleri/core/src/models/assetClassColors'
import { BackButton } from '../../ui/BackButton'
import { isWeb } from '../../constants/Platforms'
import { HistoricalChangeDesktop } from './HistoricalChangeDesktop'
import { CombinedIndex } from '@taaleri/core/src/models/CombinedIndex'
import { toHistoricalChange } from './toHistoricalChange'
import useLayout from '../../ui/useLayout'
import FundPieContainerNoLoad from '../funds/FundPieContainerNoLoad'
import { useFund } from '../funds/useFunds'

interface Props {
  purchaseLots: PurchaseLot[]
  combinedIndex?: CombinedIndex
  assetClassItem: AssetClassItem
  token?: string
}

const PurchaseLotsContentWide = ({
  purchaseLots,
  combinedIndex,
  assetClassItem,
  token,
}: Props) => {
  const { ownFund, ticker, assetClassId } = assetClassItem
  const { fund, error, retry, notFound } = useFund(ticker)
  const { isExtraWide } = useLayout()
  return (
    <>
      <View>
        {isWeb && <BackButton />}
        {combinedIndex && (
          <ReportChartGrid
            style={
              ownFund
                ? { marginTop: Spacings.S16, marginBottom: Spacings.S16 }
                : {
                    display: 'block',
                    marginTop: Spacings.S16,
                    marginBottom: Spacings.S16,
                  }
            }
          >
            <PurchaseLotChart
              combinedIndex={combinedIndex}
              assetClassItem={assetClassItem}
              token={token}
            />
            {ownFund ? (
              <FundPieContainerNoLoad
                fund={fund}
                error={error}
                retry={retry}
                notFound={notFound}
              />
            ) : null}
          </ReportChartGrid>
        )}
      </View>
      {combinedIndex ? (
        <HistoricalChangeDesktop
          historicalChanges={toHistoricalChange(combinedIndex)}
          style={{ marginTop: Spacings.S24, marginBottom: Spacings.S64 }}
        />
      ) : null}
      <PurchaseLotDetail
        purchaseLots={purchaseLots}
        style={{
          marginBottom: Spacings.S32,
          marginHorizontal: isExtraWide ? -Spacings.S64 : 0,
        }}
        color={assetClassId ? assetClassColorsRgb[assetClassId] : undefined}
      />
    </>
  )
}

export default PurchaseLotsContentWide
