export const ROUTE_CONTRACT = 'Contract'
export const ROUTE_CONTRACT_START = 'ContractStart'
export const ROUTE_CONTRACT_START_CONSULTATIVE = 'ContractStartConsultative'
export const ROUTE_CONTRACT_FORCE_START_CONSULTATIVE =
  'ContractForceStartConsultative'
export const ROUTE_CONTRACT_START_OPEN_CONSULTATIVE =
  'ContractStartOpenConsultative'
export const ROUTE_CONTRACT_START_EVERVEST = 'ContractStartEvervest'
export const ROUTE_CONTRACT_CUSTOMER_FORM = 'ContractCustomerForm'
export const ROUTE_CONTRACT_ACCOUNT_NUMBER = 'ContractAccountNumber'
export const ROUTE_CONTRACT_VERIFICATION = 'ContractVerification'
export const ROUTE_CONTRACT_UNSUCCESSFULL = 'ContractUnsuccessfull'
export const ROUTE_CONTRACT_CONFIRM = 'ContractConfirm'
export const ROUTE_CONTRACT_CONFIRM_DIGI = 'ContractConfirmDigi'
export const ROUTE_CONTRACT_RISK = 'ContractRisk'
export const ROUTE_CONTRACT_SUMMARY = 'ContractSummary'
export const ROUTE_CONTRACT_FUND_EXPLANATION = 'ContractFundExplanation'
