import { Feed } from './../models/Feed'
import INewsfeedApi from './INewsfeedApi'
import { sleep } from './mockHelper'
import take from 'lodash/take'
async function feed(_: string, _1: number): Promise<Feed[]> {
  await sleep(100)
  const feedItems = require('../fixtures/newsfeed/feed.json')
  return new Promise<Feed[]>((resolve) => {
    return resolve(take(feedItems, 10))
  })
}

export const newsfeedApiMock: INewsfeedApi = {
  feed,
}
