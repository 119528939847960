import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { NewsItem } from '@taaleri/core/src/models/Feed'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import React from 'react'
import { useWindowDimensions, View } from 'react-native'

import AuthorCard from './AuthorCard'
import { formatContent } from './messageFormat'
import Title from '../../ui/text/Title'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

export function FeedDetailContent({ item }: { item: NewsItem }) {
  const { width, height } = useWindowDimensions()

  const { isNonSmall } = useLayout()
  return (
    <View style={isNonSmall && { paddingTop: Spacings.S24 }}>
      <TextDefault type="t2" color={Colors.textSecondary}>
        {formatDateFull(item.publishDate)}
      </TextDefault>
      <Title
        style={{ marginTop: Spacings.S8, lineHeight: 28, marginBottom: 0 }}
      >
        {item.title}
      </Title>
      <AuthorCard author={item.author as string} />
      {formatContent(item.contentItems, width, height)}
    </View>
  )
}
