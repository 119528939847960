import {
  IconAddCircle,
  IconCloseCircle,
} from '@taaleri/components/src/ui/Icons'
import TabContainer, {
  TabDetails,
} from '@taaleri/components/src/ui/TabContainer'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import { SdgIcon } from '@taaleri/components/src/ui/icons/SdgIcons'
import UiModal from '@taaleri/components/src/ui/modal/UiModal'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import AdminTargetMessage, {
  CustomerSimple,
} from '@taaleri/core/src/models/AdminTargetMessage'
import {
  addMessage,
  getCustomers,
  updateMessage,
} from '@taaleri/core/src/services/admin'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import {
  formatDateWithoutLeadingZeros,
  formatFiFormatToUniversalDashed,
} from '@taaleri/core/src/utils/format'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'

interface MessageProps {
  message: AdminTargetMessage
  customers: CustomerSimple[]
  onUpdateClose: () => void
}

interface MessageForm {
  titleFi: string
  titleSv: string
  customers: CustomerSimple[]
  contentFi: string
  contentSv: string
  customerId: string
  validFrom: string
  validTo: string
}

const Message: React.FC<MessageProps> = (props) => {
  const { message, customers } = props
  const [showAddCustomerModal, setShowAddCustomerModal] =
    React.useState<boolean>(false)
  const [searchResult, setSearchResult] = React.useState<CustomerSimple[]>([])
  const [showPreviewModal, setShowPreviewModal] = React.useState<boolean>(false)
  const [contentToToggle, setContentToToggle] = React.useState<string>('fi')
  const [updateModalMessage, setUpdateModalMessage] = React.useState<string>('')

  const toggle = (value: boolean) => {
    setShowAddCustomerModal(value)
  }

  const togglePreview = (value: boolean) => {
    setShowPreviewModal(value)
  }

  const contentFi = () => {
    return (
      <View style={{ marginTop: 24 }}>
        <UiTextInputForm
          name="titleFi"
          label="Otsikko Suomeksi"
          type="name"
          grayBackground={true}
          hideCheckCircle={true}
        />
        <View style={styles.fieldContainer}>
          <View style={styles.customersHeader}>
            <Text style={styles.htmlTextContentTitle}></Text>
            <UiTouchable
              onPress={() => {
                setContentToToggle('fi')
                togglePreview(true)
              }}
              style={[
                styles.borders,
                {
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                },
              ]}
            >
              <Text style={styles.previewText}>Esikatsele</Text>
              <SdgIcon sdgNumber={4} style={styles.previewIcon} />
            </UiTouchable>
          </View>
          <UiTextInputForm
            name="contentFi"
            type="name"
            label="Viestin sisältö Suomeksi"
            grayBackground={true}
            multiline={true}
            style={{ height: 400 }}
            hideCheckCircle={true}
          />
        </View>
      </View>
    )
  }

  const contentSv = () => {
    return (
      <View style={{ marginTop: 24 }}>
        <UiTextInputForm
          name="titleSv"
          label="Otsikko Ruotsiksi"
          type="name"
          grayBackground={true}
          hideCheckCircle={true}
        />
        <View style={styles.fieldContainer}>
          <View style={styles.customersHeader}>
            <Text style={styles.htmlTextContentTitle}></Text>
            <UiTouchable
              onPress={() => {
                setContentToToggle('sv')
                togglePreview(true)
              }}
              style={[
                styles.borders,
                {
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                },
              ]}
            >
              <Text style={styles.previewText}>Esikatsele</Text>
              <SdgIcon sdgNumber={4} style={styles.previewIcon} />
            </UiTouchable>
          </View>
          <UiTextInputForm
            name="contentSv"
            type="name"
            label="Viestin sisältö Ruotsiksi"
            grayBackground={true}
            multiline={true}
            style={{ height: 400 }}
            hideCheckCircle={true}
          />
        </View>
      </View>
    )
  }

  const tabs: TabDetails[] = [
    {
      id: `${message.id}-fi`,
      order: 1,
      title: 'Suomeksi',
      content: contentFi(),
    },
    {
      id: `${message.id}-sv`,
      order: 2,
      title: 'Ruotsiksi',
      content: contentSv(),
    },
  ]

  const searchCustomers = async (customerIdString: string) => {
    if (AdminStore.token) {
      let customerIds: string[] = []

      if (customerIdString.includes(',')) {
        customerIds = customerIdString.split(',')
      } else {
        customerIds.push(customerIdString)
      }

      const result = await getCustomers(AdminStore.token, customerIds)

      setSearchResult(result)
    }
  }

  const updateMessageAction = async (formikMessage: MessageForm) => {
    if (AdminStore.token) {
      const messageToUpdate: AdminTargetMessage = {
        id: props.message.id,
        name: props.message.name,
        contentFi: formikMessage.contentFi,
        contentSv: formikMessage.contentSv,
        titleFi: formikMessage.titleFi,
        titleSv: formikMessage.titleSv,
        validFrom: new Date(
          formatFiFormatToUniversalDashed(formikMessage.validFrom)
        ),
        validTo: new Date(
          formatFiFormatToUniversalDashed(formikMessage.validTo)
        ),
        order: props.message.order,
        customers: formikMessage.customers.map((c) => c.customerId),
      }

      const result = await updateMessage(AdminStore.token, messageToUpdate)

      if (result) {
        setUpdateModalMessage('Päivitys onnistui')
      } else {
        setUpdateModalMessage('Päivitys epäonnistui')
      }
    }
  }

  const addMessageAction = async (formikMessage: MessageForm) => {
    if (AdminStore.token) {
      const messageToUpdate: AdminTargetMessage = {
        id: '00000000-0000-0000-0000-000000000000',
        name: props.message.name,
        contentFi: formikMessage.contentFi,
        contentSv: formikMessage.contentSv,
        titleFi: formikMessage.titleFi,
        titleSv: formikMessage.titleSv,
        validFrom: new Date(
          formatFiFormatToUniversalDashed(formikMessage.validFrom)
        ),
        validTo: new Date(
          formatFiFormatToUniversalDashed(formikMessage.validTo)
        ),
        order: props.message.order,
        customers: formikMessage.customers.map((c) => c.customerId),
      }

      const result = await addMessage(AdminStore.token, messageToUpdate)

      if (result) {
        setUpdateModalMessage('Lisäys onnistui')
      } else {
        setUpdateModalMessage('Lisäys epäonnistui')
      }
    }
  }

  const checkValues = (values: MessageForm) => {
    console.log(values)
    return true
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        titleFi: message.titleFi,
        titleSv: message.titleSv,
        customers,
        contentFi: message.contentFi,
        contentSv: message.contentSv,
        customerId: '',
        validFrom: message.validFrom
          ? formatDateWithoutLeadingZeros(message.validFrom)
          : '',
        validTo: message.validTo
          ? formatDateWithoutLeadingZeros(message.validTo)
          : '',
      }}
      onSubmit={async (
        _newMessage: MessageForm,
        actions: FormikHelpers<MessageForm>
      ) => {
        actions.setSubmitting(false)
      }}
    >
      {({ values }) => {
        return (
          <Form noValidate={true} style={{ flex: 1, paddingTop: 20 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginTop: 24,
              }}
            >
              <UiTextInputForm
                name="validFrom"
                label="Voimassa alkaen (esim. 1.1.2024)"
                type="name"
                grayBackground={true}
                hideCheckCircle={true}
                placeholder="pp.kk.vvvv"
                disableFloatingLabel={true}
              />
              <Text
                style={{
                  fontSize: FontSize.S32,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                -
              </Text>
              <UiTextInputForm
                name="validTo"
                label="Voimassa asti (esim. 31.12.2024)"
                type="name"
                grayBackground={true}
                hideCheckCircle={true}
                placeholder="pp.kk.vvvv"
                disableFloatingLabel={true}
              />
            </View>
            <View style={styles.fieldContainer}>
              <View style={styles.customersHeader}>
                <Text style={{ fontWeight: 'bold' }}>
                  Asiakkaat joille viesti näytetään.
                </Text>
                <UiTouchable
                  onPress={() => toggle(true)}
                  style={{
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                  }}
                >
                  <IconAddCircle />
                </UiTouchable>
              </View>
              <hr />
              {checkValues(values) && (
                <FieldArray
                  name="customers"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.customers.map((c, id) => {
                          return (
                            <View
                              key={`${message.name}-customers-${id}`}
                              style={[
                                styles.searchResultcontainer,
                                {
                                  backgroundColor:
                                    id % 2 === 0
                                      ? Colors.backgroundGray
                                      : Colors.backgroundBlue,
                                },
                              ]}
                            >
                              <Text style={styles.customerNumber}>
                                {c.customerId}
                              </Text>
                              <Text style={styles.name}>{c.customerName}</Text>
                              <View style={{ flex: 1 }}>
                                <UiTouchable
                                  onPress={() => {
                                    arrayHelpers.remove(id)
                                  }}
                                  style={{
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <IconCloseCircle color="red" />
                                </UiTouchable>
                              </View>
                            </View>
                          )
                        })}
                        <UiModal
                          isVisible={showAddCustomerModal}
                          onBackdropPress={() => toggle(false)}
                          style={{ maxWidth: 800 }}
                        >
                          <Text style={{ marginBottom: 24 }}>
                            Hae asiakkaita joille viesti näytetään (voit hakea
                            useamman kerralla pilkulla erotettuna)
                          </Text>
                          <View style={styles.modalSearchContainer}>
                            <View
                              style={{
                                flex: 1,
                                paddingTop: 5,
                                marginRight: 10,
                              }}
                            >
                              <UiTextInputForm
                                name="customerId"
                                label="Asiakasnumero"
                                type="name"
                                grayBackground={false}
                              />
                            </View>
                            <UiButton
                              containerStyle={styles.button}
                              title="Hae"
                              onPress={() => {
                                searchCustomers(values.customerId)
                              }}
                            />
                          </View>
                          {searchResult &&
                            searchResult.map((result, resultId) => {
                              return (
                                <View
                                  key={`${message.name}-customers-${result.customerId}`}
                                  style={[
                                    styles.searchResultcontainer,
                                    {
                                      backgroundColor:
                                        resultId % 2 === 0
                                          ? Colors.white
                                          : Colors.backgroundBlue,
                                    },
                                  ]}
                                >
                                  <Text style={styles.customerNumber}>
                                    {result.customerId}
                                  </Text>
                                  <Text style={styles.name}>
                                    {result.customerName}
                                  </Text>
                                </View>
                              )
                            })}
                          <UiButton
                            type={
                              searchResult.length === 0 ? 'disabled' : 'primary'
                            }
                            containerStyle={styles.modalButton}
                            disabled={searchResult.length === 0}
                            title="Lisää"
                            onPress={() => {
                              const customersList = values.customers

                              if (searchResult.length > 0) {
                                for (let i = 0; i < searchResult.length; i++) {
                                  if (
                                    !customersList.some(
                                      (c) =>
                                        c.customerId ===
                                        searchResult[i].customerId
                                    )
                                  ) {
                                    arrayHelpers.push(searchResult[i])
                                  }
                                }
                              }
                              values.customerId = ''
                              setSearchResult([])
                              toggle(false)
                            }}
                          />
                        </UiModal>
                      </>
                    )
                  }}
                />
              )}
            </View>
            <View style={{ marginTop: 24 }}>
              <TabContainer tabs={tabs} />
            </View>

            <UiButton
              containerStyle={styles.button}
              title={message.id ? 'Päivitä' : 'Lisää'}
              onPress={() =>
                message.id !== ''
                  ? updateMessageAction(values)
                  : addMessageAction(values)
              }
            />

            <View style={styles.container}>
              <UiModal
                isVisible={showPreviewModal}
                onBackdropPress={() => togglePreview(false)}
                style={{ maxWidth: 800 }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    width: '100%',
                    borderBottomWidth: 1,
                    fontSize: FontSize.S18,
                  }}
                >
                  Esikatselu
                </Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      contentToToggle === 'fi'
                        ? values.contentFi
                        : values.contentSv,
                  }}
                />
                <UiButton
                  containerStyle={styles.modalButton}
                  title="Sulje"
                  onPress={() => {
                    togglePreview(false)
                  }}
                />
              </UiModal>
              <UiModal
                isVisible={!!updateModalMessage}
                onBackdropPress={() => {
                  if (updateModalMessage.includes('onnistui')) {
                    props.onUpdateClose()
                  }
                  setUpdateModalMessage('')
                }}
              >
                <Text>{updateModalMessage}</Text>
              </UiModal>
            </View>
          </Form>
        )
      }}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: { width: 800 },
  htmlTextContentTitle: { fontWeight: 'bold', marginBottom: 10, paddingTop: 5 },
  name: {
    flex: 5,
    textAlignVertical: 'middle',
    paddingTop: 2,
  },
  customerNumber: {
    flex: 1,
    textAlignVertical: 'middle',
    paddingTop: 2,
  },
  searchResultcontainer: {
    height: 25,
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    marginLeft: 0,
    marginTop: 5,
  },
  modalButton: {
    marginLeft: 0,
    marginTop: 10,
  },
  modalSearchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.gray20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
  },
  customersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 10,
  },
  borders: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: Colors.primary,
    paddingLeft: 7,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 7,
  },
  previewText: { color: Colors.primary, marginRight: 5, marginTop: 3 },
  previewIcon: { marginTop: 3 },
})

export default Message
