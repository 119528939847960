import { StyleProp, View, ViewStyle } from 'react-native'
import Svg, {
  Path,
  G,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from 'react-native-svg'
import React from 'react'
import { IconProps } from './IconProps'

export const IconBriefcaseEcoGradient: React.FC<{
  size?: number
  style?: StyleProp<ViewStyle>
}> = ({ size = 24, style }) => {
  return (
    <View style={style}>
      <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.742.918c.14-.056.437-.168.723-.168h3.07v.75l.095-.744h.005l.008.002.023.003a3.104 3.104 0 01.312.062c.16.04.458.124.7.299.373.272.584.597.708.973l.757 2.298h1.707v-.464a.75.75 0 011.5 0v.464h.45c1.085 0 1.95.888 1.95 1.964V11a.75.75 0 01-1.5 0V6.357a.457.457 0 00-.45-.464H3.2c-.24 0-.45.2-.45.464v10.929c0 .264.21.464.45.464h6.265a.75.75 0 010 1.5H3.2a1.957 1.957 0 01-1.95-1.964V6.357c0-1.076.865-1.964 1.95-1.964h.45v-.464a.75.75 0 011.5 0v.464h1.707l.757-2.298c.09-.27.286-.517.383-.634a4.313 4.313 0 01.21-.237l.017-.016.005-.005.001-.002h.001l.532.528-.34-.668.001-.001.003-.001.006-.003.02-.01a3.943 3.943 0 01.29-.128zm.021.811l-.34-.668a.753.753 0 00-.192.14l.532.528zm.45.617a2.684 2.684 0 00-.159.201.518.518 0 00-.02.034l-.597 1.812h5.126l-.602-1.828a.385.385 0 00-.159-.225c-.003-.001-.016-.008-.042-.018a1.24 1.24 0 00-.141-.043 2.009 2.009 0 00-.135-.028l-.006-.001H9.484a1.543 1.543 0 00-.271.096zm3.322-.846l.095-.744a.76.76 0 00-.095-.006v.75zM4.85 9.393a.75.75 0 01.75-.75h10.8a.75.75 0 010 1.5H5.6a.75.75 0 01-.75-.75zm0 4.857a.75.75 0 01.75-.75H11a.75.75 0 010 1.5H5.6a.75.75 0 01-.75-.75z"
          fill="url(#prefix__paint0_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.845 13.25a9.85 9.85 0 014.483 1.034.75.75 0 01-.668 1.342 8.35 8.35 0 00-3.8-.875.75.75 0 01-.015-1.5z"
          fill="url(#prefix__paint1_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.313 14.363a.75.75 0 01.13 1.053c-.287.37-.522.96-.776 1.763-.069.218-.139.45-.212.693-.18.6-.378 1.256-.613 1.87-.67 1.751-1.8 3.588-4.322 3.819-1.365.125-2.395-.65-3.02-1.318a6.486 6.486 0 01-.97-1.363l-.014-.029-.005-.01-.002-.002v-.002l.673-.33-.674.33a.75.75 0 011.347-.66l.008.014.033.061a4.997 4.997 0 00.7.967c.497.532 1.102.911 1.788.848 1.636-.15 2.446-1.265 3.057-2.86.215-.562.386-1.13.56-1.708.076-.256.154-.513.236-.772.25-.793.551-1.626 1.023-2.233a.75.75 0 011.053-.13z"
          fill="url(#prefix__paint2_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.226 18.715a.75.75 0 00-.195-1.487l.092.744-.093-.744h-.004l-.007.001-.015.003a2.474 2.474 0 00-.173.034 3.613 3.613 0 00-.42.128 5.454 5.454 0 00-1.322.727c-1.04.762-2.276 2.137-3.283 4.597a.75.75 0 001.388.568c.915-2.235 1.99-3.375 2.782-3.955.398-.291.733-.448.955-.53a2.094 2.094 0 01.292-.085h.003z"
          fill="url(#prefix__paint3_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.46 13.994a.75.75 0 01-.743.757 8.35 8.35 0 00-3.772.939c-.91.519-1.285 1.138-1.332 1.764-.05.665.255 1.54 1.137 2.563a.75.75 0 11-1.136.98c-1.02-1.183-1.587-2.438-1.496-3.655.093-1.255.87-2.268 2.1-2.964a9.85 9.85 0 014.486-1.128.75.75 0 01.756.744z"
          fill="url(#prefix__paint4_linear)"
        />
        <Defs>
          <LinearGradient
            id="prefix__paint0_linear"
            x1={12.497}
            y1={1.469}
            x2={12.497}
            y2={23.034}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset={1} stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="prefix__paint1_linear"
            x1={12.497}
            y1={1.469}
            x2={12.497}
            y2={23.034}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset={1} stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="prefix__paint2_linear"
            x1={12.497}
            y1={1.469}
            x2={12.497}
            y2={23.034}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset={1} stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="prefix__paint3_linear"
            x1={12.497}
            y1={1.469}
            x2={12.497}
            y2={23.034}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset={1} stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="prefix__paint4_linear"
            x1={12.497}
            y1={1.469}
            x2={12.497}
            y2={23.034}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset={1} stopColor="#00C2A1" />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  )
}

export const IconGraphRising: React.FC<{
  style?: StyleProp<ViewStyle>
}> = ({ style }) => {
  return (
    <View style={style}>
      <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 23.9441C0 23.5299 0.335786 23.1941 0.75 23.1941H23.25C23.6642 23.1941 24 23.5299 24 23.9441C24 24.3583 23.6642 24.6941 23.25 24.6941H0.75C0.335786 24.6941 0 24.3583 0 23.9441Z"
          fill="url(#paint0_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.93934 17.6334C2.22065 17.3521 2.60218 17.1941 3 17.1941H6C6.39782 17.1941 6.77935 17.3521 7.06066 17.6334C7.34197 17.9147 7.5 18.2963 7.5 18.6941V23.9441C7.5 24.3583 7.16421 24.6941 6.75 24.6941H2.25C1.83579 24.6941 1.5 24.3583 1.5 23.9441V18.6941C1.5 18.2963 1.65803 17.9147 1.93934 17.6334ZM6 18.6941H3V23.1941H6V18.6941Z"
          fill="url(#paint1_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43934 10.1334C9.72064 9.85213 10.1022 9.69409 10.5 9.69409H13.5C13.8978 9.69409 14.2794 9.85213 14.5607 10.1334C14.842 10.4147 15 10.7963 15 11.1941V23.9441C15 24.3583 14.6642 24.6941 14.25 24.6941H9.75C9.33579 24.6941 9 24.3583 9 23.9441V11.1941C9 10.7963 9.15804 10.4147 9.43934 10.1334ZM13.5 11.1941H10.5V23.1941H13.5V11.1941Z"
          fill="url(#paint2_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9393 13.1334C17.2206 12.8521 17.6022 12.6941 18 12.6941H21C21.3978 12.6941 21.7794 12.8521 22.0607 13.1334C22.342 13.4147 22.5 13.7963 22.5 14.1941V23.9441C22.5 24.3583 22.1642 24.6941 21.75 24.6941H17.25C16.8358 24.6941 16.5 24.3583 16.5 23.9441V14.1941C16.5 13.7963 16.658 13.4147 16.9393 13.1334ZM21 14.1941H18V23.1941H21V14.1941Z"
          fill="url(#paint3_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 1.44409C18 1.02988 18.3358 0.694092 18.75 0.694092H23.25C23.6642 0.694092 24 1.02988 24 1.44409V5.94409C24 6.35831 23.6642 6.69409 23.25 6.69409C22.8358 6.69409 22.5 6.35831 22.5 5.94409V2.19409H18.75C18.3358 2.19409 18 1.85831 18 1.44409Z"
          fill="url(#paint4_linear)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7803 0.913762C24.0732 1.20665 24.0732 1.68153 23.7803 1.97442L18.7304 7.02435C18.3475 7.40737 17.8679 7.67961 17.3427 7.81202C16.8214 7.94344 16.2745 7.93232 15.7591 7.77992L10.0606 6.25673C9.80629 6.18885 9.53861 6.18904 9.28441 6.25727C9.03023 6.3255 8.79845 6.45936 8.61233 6.64542L2.78033 12.4774C2.48744 12.7703 2.01256 12.7703 1.71967 12.4774C1.42678 12.1845 1.42678 11.7097 1.71967 11.4168L7.55167 5.58476C7.92381 5.2127 8.38731 4.94498 8.89555 4.80856C9.40379 4.67214 9.93897 4.67176 10.4474 4.80746L16.1567 6.33353C16.164 6.33549 16.1713 6.33756 16.1786 6.33975C16.4379 6.41757 16.7135 6.42372 16.976 6.35753C17.2385 6.29135 17.4782 6.1553 17.6696 5.96383L22.7197 0.913762C23.0126 0.620869 23.4874 0.620868 23.7803 0.913762Z"
          fill="url(#paint5_linear)"
        />
        <Defs>
          <LinearGradient
            id="paint0_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="paint1_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="paint2_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="paint3_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="paint4_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
          <LinearGradient
            id="paint5_linear"
            x1="12"
            y1="1.44409"
            x2="12"
            y2="23.9441"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00AADA" />
            <Stop offset="1" stopColor="#00C2A1" />
          </LinearGradient>
        </Defs>
      </Svg>
    </View>
  )
}

export function IconRecommend({ style, color = '#2BC9AF' }: IconProps) {
  return (
    <View style={style}>
      <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <G fill={color} clipPath="url(#clipRecommend)">
          <Path d="M.75 24c-.19 0-.39-.08-.53-.22a.766.766 0 01-.15-.85l2.91-6.11c-.97-1.7-1.49-3.63-1.48-5.6C1.51 5.03 6.55 0 12.73 0h.04c2.1 0 4.15.59 5.94 1.7a11.22 11.22 0 015.02 7c.69 2.93.18 5.94-1.4 8.5-3.2 5.15-9.92 6.81-15.14 3.83l-6.11 2.91a.96.96 0 01-.33.06zM12.73 1.5C7.37 1.5 3.01 5.86 3 11.22c0 1.83.51 3.61 1.47 5.16.14.22.15.49.04.72l-2.17 4.57 4.57-2.17c.23-.11.5-.1.72.04 4.56 2.84 10.59 1.43 13.43-3.13a9.622 9.622 0 001.21-7.36 9.766 9.766 0 00-4.35-6.07 9.775 9.775 0 00-5.15-1.47h-.02c-.01-.01-.01-.01-.02-.01z" />
          <Path d="M12.75 17.25C10.68 17.25 9 15.57 9 13.5c0-.41.34-.75.75-.75h6c.41 0 .75.34.75.75 0 2.07-1.68 3.75-3.75 3.75zM8.25 10.13a.75.75 0 01-.75-.75c0-.2.08-.4.22-.53.28-.28.78-.28 1.06 0a.75.75 0 01-.53 1.28z" />
          <Path d="M8.25 10.88c-.62 0-1.12-.5-1.12-1.12 0-.62.5-1.12 1.12-1.12.62 0 1.12.5 1.12 1.12 0 .62-.5 1.12-1.12 1.12zm0-1.5c-.21 0-.37.17-.37.38s.17.38.37.38c.2 0 .38-.17.38-.38a.38.38 0 00-.38-.38zM17.25 10.12a.71.71 0 01-.53-.21.767.767 0 01-.22-.53c0-.2.08-.4.22-.54.07-.07.15-.12.24-.16.28-.11.61-.04.82.16.14.14.22.34.22.54 0 .19-.08.39-.22.53a.74.74 0 01-.53.21z" />
          <Path d="M17.25 10.88c-.62 0-1.12-.5-1.12-1.12 0-.62.5-1.12 1.12-1.12.62 0 1.12.5 1.12 1.12 0 .62-.5 1.12-1.12 1.12zm0-1.5a.38.38 0 100 .76.38.38 0 000-.76z" />
        </G>
        <Defs>
          <ClipPath id="clipRecommend">
            <Path fill="#fff" d="M0 0H24V24H0z" />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  )
}
