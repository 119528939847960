import { Identity } from '../models/Identity'
import { sleep } from './mockHelper'
import { DiscountCodeValidationResponse } from '../models/DiscountCodeValidationResponse'
import IDiscountCodeApi from './IDiscountCodeApi'
import { DiscountCodeResponse } from '../models/DiscountCodeResponse'
import { Discount } from '../models/Discount'

// Can also be used to update savings plan
async function useDiscountCode(
  _: Identity,
  _1: number,
  _2: string
): Promise<DiscountCodeValidationResponse> {
  await sleep(100)
  const response = require(`../fixtures/discountCode/discountCodeValidationResponse.json`)
  return new Promise<DiscountCodeValidationResponse>((resolve) =>
    resolve(response)
  )
}

async function validateDiscountCode(
  _: Identity,
  discountCode: string,
  _1?: number
): Promise<DiscountCodeValidationResponse> {
  await sleep(100)
  const response =
    require(`../fixtures/discountCode/discountCodeValidationResponse.json`) as DiscountCodeValidationResponse
  const invalidRespone =
    require(`../fixtures/discountCode/invalidDiscountCodeValidationResponse.json`) as DiscountCodeValidationResponse
  return new Promise<DiscountCodeValidationResponse>((resolve) =>
    resolve(discountCode === 'ISVALID' ? response : invalidRespone)
  )
}

async function getDiscountCode(
  _: Identity,
  _1: number
): Promise<DiscountCodeResponse> {
  await sleep(300)
  const response =
    require(`../fixtures/discountCode/discountCodeResponse.json`) as DiscountCodeResponse
  return new Promise<DiscountCodeResponse>((resolve) => resolve(response))
}

async function getDiscounts(_: Identity, _1: number): Promise<Discount[]> {
  await sleep(300)
  const response =
    require(`../fixtures/discountCode/discountsResponse.json`) as Discount[]
  return new Promise<Discount[]>((resolve) => resolve(response))
}

export const discountCodeApiMock: IDiscountCodeApi = {
  useDiscountCode,
  validateDiscountCode,
  getDiscountCode,
  getDiscounts,
}
