import React from 'react'
import PaymentWithdrawalVerificationContent from '@taaleri/components/src/screens/portfolio/PaymentWithdrawalVerificationContent'
import { ContentContainerBox } from 'ui/container/ContentContainer'

export default function PaymentWithdrawalVerificationScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <PaymentWithdrawalVerificationContent />
    </ContentContainerBox>
  )
}
