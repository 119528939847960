export const ROUTE_APP = 'App'
export const ROUTE_ADMIN = 'admin'
export const ROUTE_ADMIN_CUSTOMER = 'adminCustomer'
export const ROUTE_CHANGE_PASSWORD = 'ChangePassword'
export const ROUTE_CUSTOMER_NOT_FOUND = 'CustomerNotFound'
export const ROUTE_CONTRACTS = 'Contracts'
export const ROUTE_ORDERS = 'Orders'
export const ROUTE_FEED = 'Feed'
export const ROUTE_FEED_TAB = 'FeedTab'
export const ROUTE_REPORT_TAB = 'ReportTab'
export const ROUTE_HOME_TAB = 'HomeTab'
export const ROUTE_IMPACT_TAB = 'Impact'
export const ROUTE_ONBOARDING = 'Onboarding'
export const ROUTE_PROFILE_TAB = 'ProfileTab'
export const ROUTE_PORTFOLIO_TAB = 'PortfolioTab'
export const ROUTE_REPORT_ARCHIVE = 'ReportArchive'
export const ROUTE_REPORT_ORDERS = 'ReportOrders'
export const ROUTE_REPORT_ORDERS_PACKAGE = 'ReportOrdersPackage'
export const ROUTE_REPORT_ORDERS_REPORTS = 'ReportOrdersReports'
export const ROUTE_REPORT_ORDERS_DELIVERY = 'ReportOrdersDelivery'
export const ROUTE_QUESTIONS = 'Questions'
export const ROUTE_SIGN_IN = 'SignIn'
export const ROUTE_SIGN_IN_TUPAS_RETURN = 'SignInTupasReturn'
export const ROUTE_TWO_FACTOR = 'TwoFactorAuth'
export const ROUTE_TWO_FACTOR_AUTH_FAILED = 'TwoFactorAuthFailed'
export const ROUTE_TWO_FACTOR_TOGGLE_START = 'TwoFactorToggleStart'
export const ROUTE_TWO_FACTOR_TOGGLE = 'TwoFactorToggle'
export const ROUTE_TWO_FACTOR_TOGGLE_DONE = 'TwoFactorToggleDone'
export const ROUTE_WELCOME = 'Welcome'
export const ROUTE_WELCOME_STACK = 'WelcomeStack'
export const ROUTE_WELCOME_SWITCH = 'WelcomeSwitch'
export const ROUTE_CALCULATOR = 'Calculator'
export const ROUTE_APP_INFO = 'Info'
export const ROUTE_APP_OPINION = 'Opinion'
