import { Environment } from './config'

export const isLocal = window.location.host.includes('localhost')

export const isStg = window.location.host.includes('taaleriapp-dev-stg')

export const isDev =
  window.location.host.includes('app.aktiaflare.net') ||
  isLocal ||
  (window.location.host.includes('taaleriapp-dev') &&
    !window.location.host.includes('taaleriapp-dev-stg'))

export const isProd = !isStg && process.env.REACT_APP_TYPE !== 'dev'

export function getEnvironment(): Environment {
  return isProd ? 'production' : isLocal ? 'development' : 'test'
}
