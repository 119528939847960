import React from 'react'
import { View, Image, StyleSheet, ImageSourcePropType } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'

export function IntroImage({ image }: { image: ImageSourcePropType }) {
  return (
    <View style={{ alignItems: 'center' }}>
      <Image
        width={327}
        height={228}
        style={styles.image}
        source={image}
        resizeMode="contain"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    marginTop: Spacings.S32,
    height: 228,
    width: '100%',
  },
})
