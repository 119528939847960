import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { isWeb } from '../../constants/Platforms'
import FlexRow from '../../ui/FlexRow'
import LoadingIndicator from '../../ui/LoadingIndicator'
import UiButton from '../../ui/UiButton'
import { BoxWithPadding } from '../../ui/box/Box'
import { Paragraph } from '../../ui/text/Paragraph'
import PortfolioDistributionEdit from '../questions/investmentproposal/PortfolioDistributionEdit'
import RiskInfoButton from '../questions/investmentproposal/RiskInfoButton'

interface Props {
  onPress: () => void
}

function ProfileInvestmentProposalContent(props: Props) {
  const { t } = useTranslation()
  const investmentProposal = AppStore.investmentProposalSaved
  const impactShare = AppStore.impactShare

  useEffect(() => {
    AppStore.loadInvestmentProposalSaved()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  if (!investmentProposal) {
    return <LoadingIndicator type="inline" />
  }

  return (
    <>
      <FlexRow>
        <Paragraph>{t('profile.investment-proposal')}</Paragraph>
        {isWeb && (
          <View style={{ marginLeft: Spacings.S8 }}>
            <RiskInfoButton />
          </View>
        )}
      </FlexRow>
      <BoxWithPadding style={{ marginBottom: Spacings.S24 }}>
        <PortfolioDistributionEdit
          investmentProposal={investmentProposal}
          hideRiskLevel={true}
          hideRiskInfo={isWeb}
          impactShare={impactShare}
          singlePie={true}
          t={t}
        />
      </BoxWithPadding>
      <Paragraph style={{ marginVertical: Spacings.S32 }}>
        {t('profile.investment-proposal-info')}
      </Paragraph>
      <UiButton
        title={t('home.action-box-update')}
        onPress={props.onPress}
        containerStyle={{ marginTop: Spacings.S24 }}
      />
    </>
  )
}

export default observer(ProfileInvestmentProposalContent)
