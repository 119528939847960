import { Maintenance } from '@taaleri/core/src/models/Maintenance'
import { TFunction } from 'i18next'
import React from 'react'
import { View, StyleSheet } from 'react-native'

import Colors from '../../../../core/src/constants/Colors'
import Spacings from '../../../../core/src/constants/Spacings'
import { Feed } from '../../../../core/src/models/Feed'
import { formatDateFull } from '../../../../core/src/utils/format'
import PropsChildren, {
  PropsChildrenWithStyle,
} from '../../components/PropsChildren'
import FlexRow from '../../ui/FlexRow'
import { BoxWithPadding } from '../../ui/box/Box'
import { TextDefault } from '../../ui/text/UiText'

export function RowFeed({ children }: PropsChildren) {
  return (
    <FlexRow
      style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
    >
      {children}
    </FlexRow>
  )
}

export function MainContentFeed({ children }: PropsChildren) {
  return <View style={{ flex: 1 }}>{children}</View>
}

export function ContentRow({ children, style }: PropsChildrenWithStyle) {
  return <View style={[styles.contentRow, style]}>{children}</View>
}

export function DateFeed({ children }: PropsChildren) {
  return (
    <TextDefault type="t3" style={styles.date} color={Colors.textSecondary}>
      {formatDateFull(children)}
    </TextDefault>
  )
}

export function TitleFeed({ children }: PropsChildren) {
  return <TextDefault style={styles.title}>{children}</TextDefault>
}

export function BodyText({ children, style }: PropsChildrenWithStyle) {
  return (
    <TextDefault type="t3" color={Colors.textSecondary} style={[style]}>
      {children}
    </TextDefault>
  )
}

const styles = StyleSheet.create({
  row: {
    justifyContent: 'flex-start',
  },

  date: {
    marginRight: Spacings.S24,
    marginBottom: Spacings.S4,
  },

  title: {
    marginBottom: 4,
  },

  contentRow: {
    justifyContent: 'space-between',
    flex: 1,
  },
})

export interface FeedProps {
  feed: Feed
  t: TFunction
}

export interface WarningRowProps {
  warning: Maintenance
}

export function BoxFeed({ children, style }: PropsChildrenWithStyle) {
  return (
    <BoxWithPadding
      style={[{ marginBottom: Spacings.S24, marginLeft: 0 }, style]}
    >
      <View style={styles.row}>{children}</View>
    </BoxWithPadding>
  )
}
