import { observer } from 'mobx-react'
import React from 'react'

import TagSelectQuestionContent, {
  useTagQuestion,
} from '@taaleri/components/src/screens/questions/TagSelectQuestionContent'
import QuestionContainer from './QuestionContainer'
import { TagSelectQuestion } from '@taaleri/core/src/models/Question'
import Buttons from 'ui/Buttons'
import { useTranslation } from 'react-i18next'

function TagSelectQuestionScreen({
  question,
  navigate,
}: {
  question: TagSelectQuestion
  navigate: (path: string) => void
}) {
  const { t } = useTranslation()
  const tagQuestionProps = useTagQuestion(question, navigate)

  return (
    <QuestionContainer>
      <TagSelectQuestionContent {...tagQuestionProps} question={question} />

      <Buttons
        title={t(tagQuestionProps.buttonTitle)}
        onPress={tagQuestionProps.onAnswer}
      />
    </QuestionContainer>
  )
}

export default observer(TagSelectQuestionScreen)
