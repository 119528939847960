import React from 'react'
import CreatingCustomerContent from '@taaleri/components/src/screens/onboarding/CreatingCustomerContent'
import QuestionContainer from 'screens/questions/QuestionContainer'
import { useHistory } from 'react-router-dom'

export default function CreatingCustomerScreen() {
  const history = useHistory()
  return (
    <QuestionContainer>
      <CreatingCustomerContent navigate={history.push} />
    </QuestionContainer>
  )
}
