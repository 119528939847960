import { ReportOrder } from './../models/ReportOrders'
import { Portfolio, FilteredPositions, PurchaseLot } from '../models/Portfolio'
import {
  TimeSerieItem,
  YieldHistory,
  YieldHistoryResponse,
} from '../models/YieldHistory'
import { getConfig } from '../config'
import IReportsApi from './IReportsApi'
import { toPortfolio, toPortfolioList } from './normalization/toPortfolio'
import { toPurchaseLots } from './normalization/toPurchaseLots'
import { toYieldHistory } from './normalization/toYieldHistory'
import { get, put, post, deleteApi } from './rest'
import { ReportArchiveFolder } from '../models/ReportArchive'
import { toReportingPortfolioList } from './normalization/toReportingPortfolio'
import { ReportingPortfolio } from '../models/ReportingPortfolio'
import { CombinedIndex } from '../models/CombinedIndex'
import abortService from './abortService'
import { FeeReport } from '../models/FeeReport'
import {
  TransactionReport,
  TransactionCategory,
} from '../models/TransactionReport'
import { ProfitReport } from '../models/ProfitReport'
import { TokenResponse } from '../models/TokenResponse'
import { CurstomerPurchaseLotsResponse } from '../models/CustomerPurchaseLots'
import {
  BenchMark,
  BenchmarkIndexResponse,
  PortfoliosWithBenchmark,
} from '../models/BenchMark'
import AppStore from '../stores/AppStore'

async function fetchPortfolio(
  customerId: number,
  accessToken: string
): Promise<Portfolio> {
  return get(
    `${getConfig().apiUrl}/Reports/positions/${customerId}?language=${
      AppStore.currentLanguage
    }`,
    accessToken,
    null,
    300000
  ).then(toPortfolio)
}

async function fetchPortfolioList(
  customerId: number,
  accessToken: string
): Promise<Portfolio[]> {
  return get(
    `${getConfig().apiUrl}/Reports/positions/${customerId}/portfolio?language=${
      AppStore.currentLanguage
    }`,
    accessToken,
    null,
    300000
  ).then(toPortfolioList)
}

async function fetchFilteredPositions(
  customerId: number,
  reportingPortfolioIds: string[],
  assetClassId: string,
  assetCategoryId: string,
  assetAdditionalInfoId: string,
  ticker: string,
  endDate: string,
  accessToken: string
): Promise<FilteredPositions> {
  let parameters = ``
  parameters += `?customerId=${customerId}`

  reportingPortfolioIds.forEach((Id) => {
    parameters += `&portfolioIds=${Id}`
  })

  parameters += assetClassId ? `&assetClassId=${assetClassId}` : ''
  parameters += assetCategoryId ? `&assetCategoryId=${assetCategoryId}` : ''
  parameters += assetAdditionalInfoId
    ? `&assetAdditionalInfoId=${assetAdditionalInfoId}`
    : ''
  parameters += ticker ? `&ticker=${ticker}` : ''
  parameters += endDate ? `&endDate=${endDate}` : ''
  parameters += `&calculateKeyFigures=true`
  parameters += `&addTransferredPortfolios=true`
  parameters += `&addCommitments=true`
  parameters += `&language=${AppStore.currentLanguage}`

  return get(
    `${getConfig().apiUrl}/Reports/positions/filter${parameters}`,
    accessToken,
    null,
    300000
  ).then(toPortfolio)
}

async function fetchBenchmarkByPortfolioId(
  accessToken: string,
  customerId: number,
  startDate: string,
  endDate: string,
  portfolioId?: string
): Promise<BenchmarkIndexResponse> {
  let parameters = ``
  parameters += endDate ? `?toDate=${endDate}` : ''
  parameters += startDate ? `&fromDate=${startDate}` : ''
  parameters += portfolioId ? `&portfolioId=${portfolioId}` : ''
  parameters += `&language=${AppStore.currentLanguage}`

  return get(
    `${getConfig().apiUrl}/Benchmarks/${customerId}/extended${parameters}`,
    accessToken,
    null,
    300000
  )
}

async function fetchBenchmarkByTicker(
  accessToken: string,
  customerId: number,
  startDate: string,
  endDate: string,
  ticker: string
): Promise<TimeSerieItem[]> {
  let parameters = ``
  parameters += endDate ? `?toDate=${endDate}` : ''
  parameters += startDate ? `&fromDate=${startDate}` : ''
  parameters += ticker ? `&securityId=${ticker}` : ''

  return get(
    `${getConfig().apiUrl}/Benchmarks/${customerId}${parameters}`,
    accessToken,
    null,
    300000
  )
}

async function fetchBenchMarkList(accessToken: string): Promise<BenchMark[]> {
  return get(
    `${getConfig().apiUrl}/Benchmarks/securities`,
    accessToken,
    null,
    300000
  )
}

async function fetchCustomerBenchMarkList(
  customerId: number,
  accessToken: string
): Promise<PortfoliosWithBenchmark> {
  return get(
    `${getConfig().apiUrl}/Benchmarks/indexes/${customerId}`,
    accessToken,
    null,
    300000
  )
}

async function fetchYieldHistory(
  customerId: number,
  accessToken: string
): Promise<YieldHistory> {
  const url = `${getConfig().apiUrl}/Reports/twr/${customerId}`

  const yieldHistory = (await get(
    url,
    accessToken,
    null,
    300000
  )) as YieldHistoryResponse

  return toYieldHistory(yieldHistory)
}

async function fetchFilteredCombinedIndex(
  customerId: number,
  reportingPortfolioIds: string[],
  assetClassId: string,
  assetCategoryId: string,
  assetAdditionalInfoId: string,
  ticker: string,
  startDate: string,
  endDate: string,
  accessToken: string,
  abortControllerId: string = 'combinedIndex'
): Promise<CombinedIndex> {
  let parameters = ``
  parameters += `?customerId=${customerId}`

  reportingPortfolioIds.forEach((Id) => {
    parameters += `&portfolioIds=${Id}`
  })

  parameters += assetClassId ? `&assetClassId=${assetClassId}` : ''
  parameters += assetCategoryId ? `&assetCategoryId=${assetCategoryId}` : ''
  parameters += assetAdditionalInfoId
    ? `&assetAdditionalInfoId=${assetAdditionalInfoId}`
    : ''
  parameters += ticker ? `&tickers=${ticker}` : ''
  parameters += `&calculateKeyFigures=true`
  parameters += `&addTransferredPortfolios=true`
  parameters += startDate ? `&beginDate=${startDate}` : ''
  parameters += endDate ? `&endDate=${endDate}` : ''
  parameters += `&addCommitments=true`
  parameters += `&language=${AppStore.currentLanguage}`

  // Cancel previous request and start a new one
  const signal = abortService.abortAndGetNewSignal(abortControllerId)
  return get(
    `${getConfig().apiUrl}/Reports/combinedIndex/filter${parameters}`,
    accessToken,
    {
      signal,
    },
    300000
  )
}

async function fetchPurchaseLotsByTicker(
  customerId: number,
  ticker: string,
  date: string,
  accessToken: string,
  reportingPortfolioIds: string[] = []
): Promise<PurchaseLot[]> {
  let parameters = ``

  reportingPortfolioIds.forEach((Id) => {
    parameters += `&portfolioIds=${Id}`
  })
  const url = `${
    getConfig().apiUrl
  }/Reports/purchaselots/${customerId}/${ticker}?date=${date}${parameters}&language=${
    AppStore.currentLanguage
  }`

  // Cancel previous request and start a new one
  const signal = abortService.abortAndGetNewSignal('purchaselotsByTicker')
  return get(
    url,
    accessToken,
    {
      signal,
    },
    300000
  ).then(toPurchaseLots)
}

async function fetchPurchaseLotsByCustomer(
  customerId: number,
  date: string,
  accessToken: string
): Promise<CurstomerPurchaseLotsResponse> {
  const url = `${
    getConfig().apiUrl
  }/Reports/purchaselots/${customerId}?date=${date}`

  // Cancel previous request and start a new one
  const signal = abortService.abortAndGetNewSignal('purchaselotsByCustomer')
  return get(
    url,
    accessToken,
    {
      signal,
    },
    300000
  )
}

async function fetchReportingPortfolioList(
  customerId: number,
  accessToken: string
): Promise<ReportingPortfolio[]> {
  return get(
    `${getConfig().apiUrl}/Reports/portfolios/${customerId}`,
    accessToken,
    null,
    300000
  ).then(toReportingPortfolioList)
}

async function reportOrders(
  customerId: number,
  accessToken: string
): Promise<ReportOrder[]> {
  const url = `${getConfig().apiUrl}/Reports/packages/${customerId}`
  return (await get(url, accessToken, null, 300000)) as ReportOrder[]
}

async function reportOrderSave(
  customerId: number,
  accessToken: string,
  reportOrder: ReportOrder
): Promise<void> {
  if (reportOrder.id) {
    const url = `${getConfig().apiUrl}/Reports/packages/${customerId}/${
      reportOrder.id
    }`
    await put(url, accessToken, reportOrder)
  } else {
    const url = `${getConfig().apiUrl}/Reports/packages/${customerId}`
    await post(url, accessToken, reportOrder)
  }
}

async function reportOrderRemove(
  customerId: number,
  accessToken: string,
  reportId: number
): Promise<void> {
  const url = `${
    getConfig().apiUrl
  }/Reports/packages/${customerId}/${reportId}/delete`
  await deleteApi(url, accessToken)
}

async function hasAcceptedEmailReporting(
  customerId: number,
  accessToken: string
): Promise<boolean> {
  const url = `${getConfig().apiUrl}/Reports/packages/${customerId}/checkterms`
  const res = await get(url, accessToken, null, 300000)
  return res.value
}

async function acceptEmailReporting(customerId: number, accessToken: string) {
  const url = `${getConfig().apiUrl}/Reports/packages/${customerId}/acceptterms`
  await post(url, accessToken)
  return true
}

async function reportArchive(
  customerId: number,
  accessToken: string
): Promise<ReportArchiveFolder[]> {
  const url = `${getConfig().apiUrl}/Document/archive/${customerId}`
  const res = await get(url, accessToken, null, 300000)
  return res
}

async function profitReport(
  customerId: number,
  accessToken: string,
  startDate?: string,
  endDate?: string
): Promise<ProfitReport> {
  let url = `${getConfig().apiUrl}/ReportingEvent/profits/${customerId}`
  url = url + `?language=${AppStore.currentLanguage}`
  if (startDate) {
    url = url + `&startDate=${startDate}`
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`
  }

  return get(url, accessToken, null, 300000)
}

async function feeReport(
  customerId: number,
  accessToken: string,
  startDate?: string,
  endDate?: string
): Promise<FeeReport> {
  let url = `${getConfig().apiUrl}/ReportingEvent/fees/${customerId}`
  if (startDate) {
    url = url + `?startDate=${startDate}`
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`
  }
  return get(url, accessToken, null, 300000)
}

async function transactionReport(
  customerId: number,
  accessToken: string,
  requestType: TransactionCategory,
  startDate?: string,
  endDate?: string
): Promise<TransactionReport> {
  let url = `${
    getConfig().apiUrl
  }/ReportingEvent/transactions/${customerId}/${requestType}`
  if (startDate) {
    url = url + `?startDate=${startDate}`
  }
  if (endDate) {
    url = url + `&endDate=${endDate}`
  }
  // Cancel previous request and start a new one
  const signal = abortService.abortAndGetNewSignal('transactionReport')
  return get(url, accessToken, { signal }, 300000)
}

async function transactionToken(
  customerId: number,
  accessToken: string
): Promise<TokenResponse> {
  const url = `${getConfig().apiUrl}/ReportDocuments/token/${customerId}`

  return get(url, accessToken, null, 300000)
}

export const reportsApi: IReportsApi = {
  fetchPortfolio,
  fetchPortfolioList,
  fetchFilteredPositions,
  fetchYieldHistory,
  fetchFilteredCombinedIndex,
  fetchPurchaseLotsByTicker,
  fetchPurchaseLotsByCustomer,
  fetchReportingPortfolioList,
  reportOrders,
  reportOrderSave,
  reportOrderRemove,
  hasAcceptedEmailReporting,
  acceptEmailReporting,
  reportArchive,
  fetchBenchmarkByTicker,
  fetchBenchmarkByPortfolioId,
  feeReport,
  fetchBenchMarkList,
  profitReport,
  transactionReport,
  transactionToken,
  fetchCustomerBenchMarkList,
}
