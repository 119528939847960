import React from 'react'
import ChangeAccountNumberDoneContent from '@taaleri/components/src/screens/profile/ChangeAccountNumberDoneContent'
import DoneScreen from './DoneScreen'

export default function ChangeAccountNumberDoneScreen() {
  return (
    <DoneScreen>
      <ChangeAccountNumberDoneContent />
    </DoneScreen>
  )
}
