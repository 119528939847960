import { TFunction } from 'i18next'

import {
  FROM_BEGINNING,
  PAST_MONTH,
  PAST_YEAR,
  YEAR_TO_DATE,
  LAST_YEAR,
  SIX_MONTHS,
} from '../constants/YieldHistory'
import { DateRangeValue } from '../stores/ReportStore'

export const translateFilterItem = (
  item: DateRangeValue,
  t: TFunction
): string => {
  switch (item.id) {
    case FROM_BEGINNING:
    case PAST_YEAR:
    case YEAR_TO_DATE:
    case PAST_MONTH:
      return t(`filter.${item.value}`)
    case LAST_YEAR:
    case SIX_MONTHS: {
      const splittedValue = item.value.split('-')
      const value = splittedValue.pop()
      const key = splittedValue.join('-')
      return t(`filter.${key}`, { value })
    }

    default:
      return ''
  }
}

export const translateBenchmarkCategory = (
  category: string,
  t: TFunction
): string => {
  switch (category) {
    case 'Osakeindeksit':
      return t('graph.benchmark.share')
    case 'Korkoindeksit':
      return t('graph.benchmark.interest')
    case 'Rahamarkkinaindeksit':
      return t('graph.benchmark.currency-index')
    default:
      return t('graph.benchmark.other')
  }
}

export const translateHistoricalChangeId = (
  id: string,
  t: TFunction
): string => {
  if (id.includes('form.month-short')) {
    const splittedValue = id.split(':')
    const value = splittedValue.pop()
    return `${value} ${t(splittedValue[0])}`
  }

  if (id.includes('form.year')) {
    const splittedValue = id.split(':')
    const value = Number.parseInt(splittedValue.pop() ?? '', 10)

    if (value < 10) {
      return `${value} ${t(splittedValue[0]).toLowerCase()}`
    }

    return `${t(splittedValue[0])} ${value}`
  }

  return t(id)
}

export const investmentStyleLocalization = (
  text: string,
  t: TFunction
): string => {
  let translatedText = ''

  if (text.includes('Arvosijoittaminen')) {
    translatedText = t('investmentStyle.value')
  }
  if (text.includes('Osinkosijoittaminen')) {
    translatedText = t('investmentStyle.dividend')
  }
  if (text.includes('Kasvu- ja pienyhtiösijoittaminen')) {
    translatedText = t('investmentStyle.growth')
  }
  if (text.includes('Hajautetut sijoitukset')) {
    translatedText = t('investmentStyle.diversified')
  }
  if (text.includes('Hajautetut osakesijoitukset')) {
    translatedText = t('investmentStyle.diversified-equity')
  }
  if (text.includes('Hajautetut vaihtoehtoiset sijoitukset')) {
    translatedText = t('investmentStyle.diversified-alternate')
  }
  if (text.includes('Hajautetut korkosijoitukset')) {
    translatedText = t('investmentStyle.diversified-interest')
  }
  if (text.includes('Lyhyet korkosijoitukset')) {
    translatedText = t('investmentStyle.interest')
  }
  if (text.includes('Yrityslainasijoitukset')) {
    translatedText = t('investmentStyle.company')
  }
  if (text.includes('Yhdistelmäratkaisut')) {
    translatedText = t('investmentStyle.combined')
  }
  if (text.includes('Kiinteistösijoitukset')) {
    translatedText = t('investmentStyle.real-estate')
  }

  return translatedText !== '' ? translatedText : text
}

export const profitFilterTranslateHelper = (
  text: string,
  t: TFunction
): string => {
  if (text.includes('app.calculator.year')) {
    const splittedValue = text.split(':')
    const value = Number.parseInt(splittedValue.pop() ?? '', 10)
    return t(splittedValue[0], { value })
  }
  return t(text)
}

export const removeLineBreaks = (text: string): string => {
  text = text.replaceAll('-\n', '')
  return text.replaceAll('\n', '')
}
