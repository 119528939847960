import { Config } from './config'
import { getEnvironment, isProd, isStg } from './environment'

export const productionConfig: Config = isStg
  ? {
      appUrl: 'https://taaleriapp-dev-stg.azurewebsites.net/',
      apiUrl: 'https://wealth-apistg.aktia.fi/api/mobile',
      environment: getEnvironment(),
      testMode: false,
      useDevTupas: false,
      useMockAPI: false,
      useActualTupasResult: false,
      azureFunctionUrl: 'https://taalerimaintenance-dev.azurewebsites.net',
      functionKey: 'ATxIPMmO8DJGT3O4GerMV9jRH50QON3/5NrVT21DiTtXIK3xwp0jdw==',
      adminGroup: '',
    }
  : isProd
  ? {
      appUrl: 'https://app.aktia.fi',
      apiUrl: 'https://wealth-api.aktia.fi/api/mobile',
      useActualTupasResult: true,
      azureFunctionUrl: 'https://taalerimaintenance-prod.azurewebsites.net',
      functionKey: 'kB/llnpcPVzLRDmgLMimeSR/EsvkRkUn5nk6w5JCZaXmyRH/WV1CPQ==',
      environment: getEnvironment(),
      testMode: false,
      useDevTupas: false,
      useMockAPI: false,
      adminGroup: '',
    }
  : {
      appUrl: 'https://app.aktiaflare.net',
      apiUrl: 'https://apitest.aktia.biz/api/mobile',
      environment: getEnvironment(),
      testMode: false,
      useDevTupas: false,
      useMockAPI: false,
      useActualTupasResult: false,
      azureFunctionUrl: 'https://taalerimaintenance-dev.azurewebsites.net',
      functionKey: 'ATxIPMmO8DJGT3O4GerMV9jRH50QON3/5NrVT21DiTtXIK3xwp0jdw==',
      adminGroup: 'pm-user-dev',
    }

export const isLocalMock = () => false

export function getConfig(): Config {
  return productionConfig
}
