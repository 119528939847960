import {
  ReportOrder,
  REPORT_DELIVERY_LAST_DAY_OF_MONTH,
  SubscriptionTimePeriod,
} from '../models/ReportOrders'
import ReportOrderStore from '../stores/ReportOrderStore'

export default function toReportOrder(reportOrder: ReportOrder) {
  // @ts-ignore
  reportOrder.receivers = reportOrder.receivers.map((r) => r.email)

  // CUSTOM
  reportOrder.subscriptions = reportOrder.subscriptions
    .filter((s) => reportOrder.selectedReports.includes(s.reportType))
    .map((s) => {
      if (
        s.reportType === 'Purchaselot' &&
        s.timePeriod !== 'Latest' &&
        s.timePeriod !== 'LastDayOfMonth'
      ) {
        s.timePeriodDay = s.timePeriod
        s.timePeriod = 'SpecificDay'
      } else if (reportOrder.addDelay) {
        switch (s.timePeriod) {
          case 'Latest':
            s.timePeriod = 'LastDayOfMonth'
            break
          case 'BeginningOfTheYear':
            s.timePeriod = 'BeginningOfTheYearToLastMonthEnd'
            break
          case 'PreviousThreeMonths':
            s.timePeriod = 'PreviousCalendarQuarter'
            break
        }
      }
      return s
    })

  // Apparently some hack to set delivery time to last day of the month, who knows
  if (
    reportOrder.delivery === 'Monthly' &&
    reportOrder.deliveryDay === REPORT_DELIVERY_LAST_DAY_OF_MONTH
  ) {
    reportOrder.delivery = 'LastDayOfMonth'
    reportOrder.deliveryDay = null
  }

  const { delivery, addDelay } = reportOrder

  // WEALTH

  if (ReportOrderStore.reportPackage === 'Wealth') {
    let timePeriodProfit: SubscriptionTimePeriod = 'BeginningOfTheYear'
    let timePeriodPurchaseslot: SubscriptionTimePeriod = 'BeginningOfTheYear'
    // Weekly: Change timePeriod of ProfitSummary to BeginningOfTheYear and change timePeriod for Purchaselot to Latest
    if (delivery === 'Weekly') {
      timePeriodProfit = 'BeginningOfTheYear'
      timePeriodPurchaseslot = 'Latest'
    }

    // Monthly or LastDayOfMonth: Change timePeriod of ProfitSummary to PreviousMonth and change timePeriod for Purchaselot to LastDayOfMonth
    if (delivery === 'Monthly' || delivery === 'LastDayOfMonth') {
      timePeriodProfit = 'PreviousMonth'
      timePeriodPurchaseslot = 'LastDayOfMonth'
    }
    // EveryThreeMonths or EverySixMonths: Change timePeriod for ProfitSummary to BeginningOfTheYear and change timePeriod for Purchaselot to Latest
    if (delivery === 'EveryThreeMonths' || delivery === 'EverySixMonths') {
      timePeriodProfit = addDelay
        ? 'BeginningOfTheYearToLastMonthEnd'
        : 'BeginningOfTheYear'
      timePeriodPurchaseslot = addDelay ? 'LastDayOfMonth' : 'Latest'
    }

    // YearlySpecificDay or LastDayOfYear: Change timePeriod for ProfitSummary to PreviousYear and change timePeriod for Purchaselot to Latest
    if (delivery === 'YearlySpecificDay') {
      timePeriodProfit = 'PreviousYear'
      timePeriodPurchaseslot = 'Latest'
    }

    reportOrder.subscriptions = [
      {
        reportType: 'ProfitSummary',
        timePeriod: timePeriodProfit,
        timePeriodDay: null,
        beginningOfOwnership: null,
        level: null,
        sumLevel: null,
        format: 'Pdf',
      },

      {
        reportType: 'Purchaselot',
        timePeriod: timePeriodPurchaseslot,
        beginningOfOwnership: null,
        level: 'Aggregated',
        sumLevel: 'Security',
        format: 'Pdf',
      },
    ]
  }

  // BOOKKEEPER

  if (ReportOrderStore.reportPackage === 'Bookkeeper') {
    let timePeriodTransactionsAndCashAccount: SubscriptionTimePeriod =
      'BeginningOfTheYear'

    let timePeriodPurchaseslot: SubscriptionTimePeriod = 'Latest'
    const level = reportOrder.level || 'Aggregated'
    const format = reportOrder.format || 'Pdf'

    // Weekly: Change timePeriod of Transactions and CashAccountStatement to FromPreviousToNext and change timePeriod for Purchaselot to Latest
    if (delivery === 'Weekly') {
      timePeriodTransactionsAndCashAccount = 'FromPreviousToNext'
      timePeriodPurchaseslot = 'Latest'
    }

    // Monthly or LastDayOfMonth: Change timePeriod of Transactions and CashAccountStatement to PreviousMonth and change timePeriod for Purchaselot to LastDayOfMonth
    if (delivery === 'Monthly' || delivery === 'LastDayOfMonth') {
      timePeriodTransactionsAndCashAccount = 'PreviousMonth'
      timePeriodPurchaseslot = 'LastDayOfMonth'
    }
    // EveryThreeMonths or EverySixMonths: Change timePeriod of Transactions and CashAccountStatement to BeginningOfTheYear and change timePeriod for Purchaselot to Latest
    if (delivery === 'EveryThreeMonths' || delivery === 'EverySixMonths') {
      timePeriodTransactionsAndCashAccount = addDelay
        ? 'BeginningOfTheYearToLastMonthEnd'
        : 'BeginningOfTheYear'
      timePeriodPurchaseslot = addDelay ? 'LastDayOfMonth' : 'Latest'
    }

    // TODO LastDayOfYear
    // YearlySpecificDay or LastDayOfYear: Change timePeriod of Transactions and CashAccountStatement to PreviousYear and change timePeriod for Purchaselot to Latest
    if (delivery === 'YearlySpecificDay') {
      timePeriodTransactionsAndCashAccount = 'PreviousYear'
      timePeriodPurchaseslot = 'Latest'
    }

    reportOrder.subscriptions = [
      {
        reportType: 'Purchaselot',
        timePeriod: timePeriodPurchaseslot,
        timePeriodDay: null,
        beginningOfOwnership: null,
        level,
        sumLevel: 'Security',
        format,
      },
      {
        reportType: 'Transactions',
        timePeriod: timePeriodTransactionsAndCashAccount,
        timePeriodDay: null,
        beginningOfOwnership: null,
        level,
        sumLevel: null,
        format,
      },
      {
        reportType: 'OpenTransactions',
        timePeriod: 'PreviousTwoMonths',
        timePeriodDay: null,
        beginningOfOwnership: null,
        level,
        sumLevel: null,
        format: 'Pdf',
      },
      {
        reportType: 'CashAccountStatements',
        timePeriod: timePeriodTransactionsAndCashAccount,
        timePeriodDay: null,
        beginningOfOwnership: null,
        level: null,
        sumLevel: null,
        format: 'Pdf',
      },
    ]
  }
  return reportOrder
}
