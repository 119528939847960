import React from 'react'

import Svg, { Path, Circle, Line, Rect } from 'react-native-svg'
import { View, StyleSheet } from 'react-native'
import PropsChildren from '../../components/PropsChildren'
import useLayout from '../../ui/useLayout'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconProps } from '../../ui/IconProps'

export const IconCash = () => {
  return (
    <IconContainer>
      <Svg width="42" height="49" viewBox="0 0 42 49" fill="none">
        <Path
          d="M4.32513 22.8014C3.40135 24.6489 2.93945 26.7736 2.93945 28.9907C2.93945 33.3325 4.97179 37.2124 8.20504 40.0762C9.12883 40.9076 10.0526 42.3857 10.0526 43.9561C10.0526 44.9723 10.0526 47.1893 10.0526 47.1893H18.2743V44.6027H23.1704V47.1893H31.3921C31.3921 47.1893 31.3921 44.5104 31.3921 43.5866C31.3921 42.0161 32.1311 40.9076 32.593 40.5381C35.0873 38.5981 36.8425 36.0115 37.7662 33.1478H40.1681C40.63 33.1478 40.9995 32.7783 40.9995 32.3164V26.2194C40.9995 25.7575 40.63 25.388 40.1681 25.388H37.951C37.0272 22.2471 35.0873 19.1062 32.5007 17.0739C32.5007 15.873 32.6854 14.1178 33.4244 13.1016C34.0711 12.1778 34.8101 11.1616 31.854 11.8083C29.8217 12.2702 28.5284 12.7321 27.2351 13.4711"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M27.6045 26.3118L29.914 24.0947L32.1311 26.3118"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M14.0254 27.0508C21.2191 27.0508 27.0508 21.2191 27.0508 14.0254C27.0508 6.83167 21.2191 1 14.0254 1C6.83167 1 1 6.83167 1 14.0254C1 21.2191 6.83167 27.0508 14.0254 27.0508Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <Path
          d="M16.1241 16.9108C16.1241 18.1352 15.1264 18.8061 14.0654 18.8061C12.6084 18.8061 11.9591 17.7662 11.9591 16.3908C11.9591 16.3405 11.975 16.2399 12.07 16.2399H14.6988C14.873 16.2399 15.0155 16.0889 15.0155 15.9044V15.2C15.0155 15.0155 14.873 14.8645 14.6988 14.8645H12.0066C11.8958 14.8645 11.8799 14.7471 11.8799 14.6968V13.7911C11.8799 13.7407 11.9116 13.6569 11.9908 13.6569H14.6988C14.873 13.6569 15.0155 13.5059 15.0155 13.3214V12.617C15.0155 12.4325 14.873 12.2815 14.6988 12.2815H12.0383C11.9908 12.2815 11.975 12.2144 11.975 12.1641C11.975 10.5372 13.036 9.98366 14.0654 9.98366C15.3164 10.0004 16.1241 10.8726 16.1241 11.879V12.1138C16.1241 12.2983 16.2666 12.4492 16.4408 12.4492H17.3118C17.486 12.4492 17.6285 12.2983 17.6285 12.1138V11.879C17.6285 9.69853 15.9657 8.39026 14.0654 8.39026C11.975 8.39026 10.4705 9.95012 10.4705 12.1641C10.4705 12.2312 10.3913 12.2815 10.328 12.2815H9.63118C9.45698 12.2815 9.31445 12.4325 9.31445 12.617V13.3214C9.31445 13.5059 9.45698 13.6569 9.63118 13.6569H10.2646C10.3755 13.6569 10.3755 13.7407 10.3755 13.7743V14.7303C10.3755 14.7806 10.3913 14.8645 10.2488 14.8645H9.63118C9.45698 14.8645 9.31445 15.0155 9.31445 15.2V15.9044C9.31445 16.0889 9.45698 16.2399 9.63118 16.2399H10.2963C10.4388 16.2399 10.4547 16.3405 10.4547 16.3908C10.4547 18.5713 11.7691 20.3995 14.0654 20.3995C16.1558 20.3995 17.6285 18.7725 17.6285 16.9108V16.676C17.6285 16.4915 17.486 16.3405 17.3118 16.3405H16.4408C16.2666 16.3405 16.1241 16.4915 16.1241 16.676V16.9108Z"
          fill="#D8D2C9"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconOther = () => {
  return (
    <IconContainer>
      <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <Circle cx="20" cy="20" r="19" stroke="#D8D2C9" strokeWidth="2" />
        <Rect x="19" y="17" width="2" height="9" fill="#D8D2C9" />
        <Circle cx="20" cy="14" r="1" fill="#D8D2C9" />
      </Svg>
    </IconContainer>
  )
}

export const IconAccount = () => {
  return (
    <IconContainer>
      <Svg width="42" height="50" viewBox="0 0 42 50" fill="none">
        <Path
          d="M11.3 42.5C5.2 39.1 1 32.5 1 25C1 14 10 5 21 5"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M19 1L23 5L19 9"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M30.5 7.40002C36.8 10.8 41 17.4 41 25C41 36 32 45 21 45"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M23 49L19 45L23 41"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M23.6 27.9C23.6 27.7 23.8 27.5 24 27.5H25.1C25.3 27.5 25.5 27.7 25.5 27.9V28.2C25.5 30.4 23.6 32.3 21 32.3C18.1 32.3 16.5 30.1 16.5 27.5C16.5 27.4 16.5 27.3 16.3 27.3H15.4C15.2 27.3 15 27.2 15 26.9V26.1C15 25.9 15.2 25.7 15.4 25.7H16.2C16.4 25.7 16.4 25.6 16.4 25.5V24.4C16.4 24.4 16.4 24.3 16.3 24.3H15.4C15.2 24.3 15 24.1 15 23.9V23C15 22.8 15.2 22.6 15.4 22.6H16.3C16.4 22.6 16.5 22.5 16.5 22.5C16.5 19.9 18.4 18 21 18C23.4 18 25.5 19.6 25.5 22.1V22.4C25.5 22.6 25.3 22.8 25.1 22.8H24C23.8 22.8 23.6 22.6 23.6 22.4V22.1C23.6 20.9 22.6 19.9 21 19.8C19.7 19.8 18.4 20.5 18.4 22.4C18.4 22.5 18.4 22.5 18.5 22.5H21.9C22 22.6 22.2 22.8 22.2 23V23.8C22.2 24 22 24.2 21.8 24.2H18.4C18.3 24.2 18.3 24.3 18.3 24.4V25.5C18.3 25.6 18.3 25.7 18.5 25.7H21.9C22.1 25.7 22.3 25.9 22.3 26.1V26.9C22.3 27.1 22.1 27.3 21.9 27.3H18.5C18.4 27.3 18.4 27.4 18.4 27.5C18.4 29.1 19.2 30.4 21.1 30.4C22.4 30.4 23.6 29.6 23.6 28.1V27.9Z"
          fill="#D8D2C9"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconSecurity = () => {
  return (
    <IconContainer>
      <Svg width="40" height="34" viewBox="0 0 40 34" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2V6H2C0.89 6 0.0100002 6.89 0.0100002 8L0 32C0 33.11 0.89 34 2 34H38C39 34 40 33 40 32V8C40 6.896 39.104 6 38 6H28V2C28 0.896 27.104 0 26 0H14C12.896 0 12 0.896 12 2ZM2 8H38V32H2V8ZM26 2H14V6H26V2Z"
          fill="#D8D2C9"
        />
        <Path
          d="M23.3465 22.5906C23.3465 22.3691 23.5213 22.1943 23.7428 22.1943H24.8384C25.0599 22.1943 25.2347 22.3691 25.2347 22.5906V22.8704C25.2347 25.0733 23.3814 27.0082 20.7589 27.0082C17.8682 27.0082 16.2247 24.8402 16.2247 22.2526C16.2247 22.1943 16.2014 22.0777 16.0265 22.0777H15.1873C14.9659 22.0777 14.791 21.9029 14.791 21.6814V20.8422C14.791 20.6208 14.9659 20.4459 15.1873 20.4459H15.9683C16.1431 20.4459 16.1314 20.341 16.1314 20.2827V19.1521C16.1314 19.1171 16.1314 19.0122 15.9916 19.0122H15.199C14.9775 19.0122 14.8027 18.8374 14.8027 18.6159V17.7767C14.8027 17.5552 14.9775 17.3804 15.199 17.3804H16.0732C16.1548 17.3804 16.248 17.3221 16.248 17.2405C16.248 14.6179 18.1363 12.7646 20.7705 12.7646C23.16 12.7646 25.2464 14.3149 25.2464 16.9025V17.1823C25.2464 17.4037 25.0716 17.5786 24.8501 17.5786H23.7544C23.533 17.5786 23.3581 17.4037 23.3581 17.1823V16.9025C23.3581 15.7136 22.3441 14.6762 20.7705 14.6529C19.4767 14.6529 18.1479 15.3056 18.1479 17.2405C18.1479 17.2988 18.1712 17.3804 18.2295 17.3804H21.5748C21.7962 17.3804 21.9711 17.5552 21.9711 17.7767V18.6159C21.9711 18.8374 21.7962 19.0122 21.5748 19.0122H18.1712C18.0663 19.0122 18.0314 19.1171 18.0314 19.1754V20.2478C18.0314 20.306 18.0547 20.4459 18.1946 20.4459H21.5748C21.7962 20.4459 21.9711 20.6208 21.9711 20.8422V21.6814C21.9711 21.9029 21.7962 22.0777 21.5748 22.0777H18.2761C18.1596 22.0777 18.1363 22.1943 18.1363 22.2526C18.1363 23.8844 18.9522 25.1199 20.7822 25.1199C22.1109 25.1199 23.3698 24.3273 23.3698 22.8704V22.5906H23.3465Z"
          fill="#D8D2C9"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconPortfolioTransaction = () => {
  return (
    <IconContainer>
      <Svg width="42" height="40" viewBox="0 0 42 40" fill="none">
        <Path
          d="M30.3328 11.5556H11.6661H5.22168V17.8889V30.6667V31.7778C5.22168 35.3334 8.11057 38.1111 11.555 38.1111H30.2217C33.7772 38.1111 36.555 35.2222 36.555 31.7778V30.6667V17.8889V11.5556H30.3328Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M5.22222 30.3333H3.22222C2 30.3333 1 29.4445 1 28.3333V21.3333C1 20.2222 2 19.3333 3.22222 19.3333H5.22222"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M36.7783 30.3333H38.7783C40.0005 30.3333 41.0005 29.4445 41.0005 28.3333V21.3333C41.0005 20.2222 40.0005 19.3333 38.7783 19.3333H36.7783"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M13.8889 24.1111C14.9321 24.1111 15.7778 23.2655 15.7778 22.2222C15.7778 21.179 14.9321 20.3334 13.8889 20.3334C12.8457 20.3334 12 21.179 12 22.2222C12 23.2655 12.8457 24.1111 13.8889 24.1111Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M28.2219 24.1111C29.2651 24.1111 30.1108 23.2655 30.1108 22.2222C30.1108 21.179 29.2651 20.3334 28.2219 20.3334C27.1787 20.3334 26.333 21.179 26.333 22.2222C26.333 23.2655 27.1787 24.1111 28.2219 24.1111Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M31.2214 11.4445C28.7769 8.44447 25.1102 6.44447 20.888 6.44447C16.6658 6.44447 12.9991 8.44447 10.5547 11.4445"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M27.4448 29.3333H14.3337H13.667C15.3337 31.4444 18.0003 32.8889 20.8892 32.8889C23.7781 32.8889 26.4448 31.5556 28.1114 29.3333H27.4448Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M9.22287 4.77778C10.2661 4.77778 11.1118 3.93209 11.1118 2.88889C11.1118 1.84568 10.2661 1 9.22287 1C8.17967 1 7.33398 1.84568 7.33398 2.88889C7.33398 3.93209 8.17967 4.77778 9.22287 4.77778Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10.5566 4.22225L14.4455 8.00003"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M32.6662 4.77778C33.7094 4.77778 34.5551 3.93209 34.5551 2.88889C34.5551 1.84568 33.7094 1 32.6662 1C31.623 1 30.7773 1.84568 30.7773 2.88889C30.7773 3.93209 31.623 4.77778 32.6662 4.77778Z"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M31.3332 4.22225L27.4443 8.00003"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconProfit = () => {
  return (
    <IconContainer>
      <Svg width="35" height="52" viewBox="0 0 35 52" fill="none">
        <Path
          d="M20.3465 36.5906C20.3465 36.3691 20.5213 36.1943 20.7428 36.1943H21.8384C22.0599 36.1943 22.2347 36.3691 22.2347 36.5906V36.8704C22.2347 39.0733 20.3814 41.0082 17.7589 41.0082C14.8682 41.0082 13.2247 38.8402 13.2247 36.2526C13.2247 36.1943 13.2014 36.0777 13.0265 36.0777H12.1873C11.9659 36.0777 11.791 35.9029 11.791 35.6814V34.8422C11.791 34.6208 11.9659 34.4459 12.1873 34.4459H12.9683C13.1431 34.4459 13.1314 34.341 13.1314 34.2827V33.1521C13.1314 33.1171 13.1314 33.0122 12.9916 33.0122H12.199C11.9775 33.0122 11.8027 32.8374 11.8027 32.6159V31.7767C11.8027 31.5552 11.9775 31.3804 12.199 31.3804H13.0732C13.1548 31.3804 13.248 31.3221 13.248 31.2405C13.248 28.6179 15.1363 26.7646 17.7705 26.7646C20.16 26.7646 22.2464 28.3149 22.2464 30.9025V31.1823C22.2464 31.4037 22.0716 31.5786 21.8501 31.5786H20.7544C20.533 31.5786 20.3581 31.4037 20.3581 31.1823V30.9025C20.3581 29.7136 19.3441 28.6762 17.7705 28.6529C16.4767 28.6529 15.1479 29.3056 15.1479 31.2405C15.1479 31.2988 15.1712 31.3804 15.2295 31.3804H18.5748C18.7962 31.3804 18.9711 31.5552 18.9711 31.7767V32.6159C18.9711 32.8374 18.7962 33.0122 18.5748 33.0122H15.1712C15.0663 33.0122 15.0314 33.1171 15.0314 33.1754V34.2478C15.0314 34.306 15.0547 34.4459 15.1946 34.4459H18.5748C18.7962 34.4459 18.9711 34.6208 18.9711 34.8422V35.6814C18.9711 35.9029 18.7962 36.0777 18.5748 36.0777H15.2761C15.1596 36.0777 15.1363 36.1943 15.1363 36.2526C15.1363 37.8844 15.9522 39.1199 17.7822 39.1199C19.1109 39.1199 20.3698 38.3273 20.3698 36.8704V36.5906H20.3465Z"
          fill="#D8D2C9"
        />
        <Circle
          cx="17.5"
          cy="33.5247"
          r="16.5"
          stroke="#D8D2C9"
          strokeWidth="2"
        />
        <Line
          x1="8.20605"
          y1="6.34155"
          x2="8.20605"
          y2="12.3539"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Line
          x1="26.7354"
          y1="6.34155"
          x2="26.7354"
          y2="12.3539"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <Line
          x1="17.4707"
          y1="1"
          x2="17.4707"
          y2="12.3538"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconWithdrawal = () => {
  return (
    <IconContainer>
      <Svg width="43" height="46" viewBox="0 0 43 46" fill="none">
        <Circle
          cx="21"
          cy="13"
          r="12.0734"
          stroke="#D8D2C9"
          strokeWidth="1.85321"
        />
        <Path
          d="M21.7576 8.09345L21.7576 8.0934H21.7509C21.1544 8.0934 20.5023 8.25318 19.992 8.69584C19.4687 9.14984 19.1605 9.8441 19.1605 10.7739C19.1605 10.8389 19.1691 10.9419 19.2149 11.0486C19.2583 11.1497 19.4061 11.3913 19.7239 11.3913H22.2011V11.7666H19.6763C19.429 11.7666 19.2593 11.9088 19.1727 12.0413C19.0962 12.1584 19.0655 12.2892 19.0655 12.4008V13.3065C19.0655 13.3666 19.0725 13.4977 19.14 13.6328C19.1768 13.7064 19.239 13.7958 19.3415 13.8665C19.4483 13.9403 19.5708 13.9743 19.6922 13.9743H22.2011V14.3496H19.7555C19.6382 14.3496 19.5189 14.3825 19.4146 14.4543C19.3147 14.5231 19.2538 14.6099 19.2179 14.6813C19.1519 14.8124 19.1447 14.9396 19.1447 15.0006C19.1447 15.7583 19.3225 16.4865 19.7605 17.0357C20.2123 17.6022 20.8915 17.9158 21.7509 17.9158C22.3819 17.9158 23.0153 17.717 23.4997 17.3103C23.9938 16.8954 24.3096 16.2818 24.3096 15.5205V15.4503H24.8141V15.5205C24.8141 17.1136 23.5578 18.5092 21.7509 18.5092C20.7476 18.5092 19.9856 18.1153 19.4666 17.5001C18.9399 16.8759 18.6402 15.9943 18.6402 15.0006C18.6402 14.9381 18.6334 14.7774 18.5256 14.6216C18.3942 14.4318 18.1865 14.3496 17.9819 14.3496H17.5V13.9743H17.9343C18.0964 13.9743 18.3611 13.9196 18.4968 13.6526C18.5491 13.5498 18.5583 13.455 18.5607 13.4071C18.5619 13.382 18.561 13.3008 18.561 13.3401V12.384C18.561 12.3839 18.561 12.3835 18.5611 12.3829C18.5615 12.3677 18.5661 12.2184 18.4851 12.0715C18.4363 11.9828 18.3595 11.8982 18.2512 11.8399C18.1467 11.7837 18.0402 11.7666 17.9502 11.7666H17.5V11.3913H18.0135C18.1648 11.3913 18.3046 11.3364 18.4102 11.2565C18.5151 11.1771 18.6561 11.0155 18.6561 10.7739C18.6561 8.81459 19.9576 7.5 21.7509 7.5C23.4163 7.5 24.8141 8.62387 24.8141 10.4887V10.559H24.3096V10.4887C24.3096 9.1864 23.2592 8.11358 21.7576 8.09345Z"
          fill="#D8D2C9"
          stroke="#D8D2C9"
        />
        <Rect
          x="1.07339"
          y="29.9106"
          width="7.18654"
          height="14.2977"
          stroke="#D8D2C9"
          strokeWidth="1.85321"
          strokeLinejoin="round"
          strokeLinecap="round"
        />
        <Path
          d="M21.1117 37.5038H30.8894C30.8894 36.4668 30.445 34.2149 28.6672 33.5038C26.445 32.6149 22.445 33.5038 20.6672 33.0594C18.8894 32.6149 16.2228 30.8372 13.5561 30.8372C11.4228 30.8372 8.81536 32.0223 7.77832 32.6149"
          stroke="#D8D2C9"
          strokeWidth="1.85321"
          strokeLinejoin="round"
        />
        <Path
          d="M7.77832 42.3926C7.77832 42.3926 10.5388 41.0593 11.3339 41.0593C14.0005 41.0593 20.6672 44.6149 24.2228 44.6149C27.7783 44.6149 37.1117 37.5038 42.0005 33.9483C41.1117 32.6149 39.245 31.9927 37.1117 33.0593C34.9783 34.126 32.3709 35.4297 30.8894 36.1705"
          stroke="#D8D2C9"
          strokeWidth="1.85321"
          strokeLinejoin="round"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconExpense = () => {
  return (
    <IconContainer>
      <Svg width="37" height="42" viewBox="0 0 37 42" fill="none">
        <Path
          d="M3.90039 1H36.0004V40.9L32.3004 37.4L28.5004 40.9L24.8004 37.4L21.0004 40.9L17.3004 37.4L13.5004 40.9L9.70039 37.4L6.00039 40.9V27.3"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1 28.4V3.6C1 2.2 1.9 1 3.1 1H3.9C5 1 6 2.2 6 3.6V28.4"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1 28.4H6"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M24.2998 19.3C24.2998 19.1 24.4998 18.9 24.6998 18.9H25.7998C25.9998 18.9 26.1998 19.1 26.1998 19.3V19.6C26.1998 21.8 24.2998 23.7 21.6998 23.7C18.7998 23.7 17.1998 21.5 17.1998 18.9C17.1998 18.8 17.1998 18.7 16.9998 18.7H16.1998C15.9998 18.7 15.7998 18.5 15.7998 18.3V17.5C15.7998 17.3 15.9998 17.1 16.1998 17.1H16.9998C17.1998 17.1 17.1998 17 17.1998 16.9V15.8C17.1998 15.8 17.1998 15.7 17.0998 15.7H16.2998C16.0998 15.7 15.8998 15.5 15.8998 15.3V14.5C15.8998 14.3 16.0998 14.1 16.2998 14.1H16.9998C17.0998 14.1 17.1998 14 17.1998 14C17.1998 11.4 19.0998 9.5 21.6998 9.5C24.0998 9.5 26.1998 11.1 26.1998 13.6V13.9C26.1998 14.1 25.9998 14.3 25.7998 14.3H24.6998C24.4998 14.3 24.2998 14.1 24.2998 13.9V13.6C24.2998 12.4 23.2998 11.4 21.6998 11.3C20.3998 11.3 19.0998 12 19.0998 13.9C19.0998 14 19.0998 14 19.1998 14H22.5998C22.7998 14 22.9998 14.2 22.9998 14.4V15.2C22.9998 15.4 22.7998 15.6 22.5998 15.6H19.1998C19.0998 15.6 19.0998 15.7 19.0998 15.8V17C19.0998 17.1 19.0998 17.2 19.2998 17.2H22.6998C22.8998 17.2 23.0998 17.4 23.0998 17.6V18.4C23.0998 18.6 22.8998 18.8 22.6998 18.8H19.3998C19.2998 18.8 19.2998 18.9 19.2998 19C19.2998 20.6 20.0998 21.9 21.9998 21.9C23.2998 21.9 24.5998 21.1 24.5998 19.6V19.3H24.2998Z"
          fill="#D8D2C9"
        />
        <Path
          d="M27.8002 29.4H14.2002"
          stroke="#D8D2C9"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </IconContainer>
  )
}

export const IconBell: React.FC<IconProps> = ({ style }) => (
  <View {...{ style }}>
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M13.4199 4.16109C16.5651 4.84776 18.9199 7.64888 18.9199 11V16L20.9199 18V19H2.91992V18L4.91992 16V11C4.91992 7.64888 7.27474 4.84776 10.4199 4.16109V3.5C10.4199 2.67157 11.0915 2 11.9199 2C12.7483 2 13.4199 2.67157 13.4199 3.5V4.16109ZM6.91992 11V17H16.9199V11C16.9199 8.23858 14.6813 6 11.9199 6C9.1585 6 6.91992 8.23858 6.91992 11ZM9.91992 20H13.9199C13.9199 21.1046 13.0245 22 11.9199 22C10.8154 22 9.91992 21.1046 9.91992 20Z"
        fill="#473729"
      />
    </Svg>
  </View>
)

function IconContainer({ children }: PropsChildren) {
  const { isNonSmall } = useLayout()
  return (
    <View style={[styles.container, isNonSmall && styles.containerLg]}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginLeft: Spacings.S16,
  },
  containerLg: {
    paddingHorizontal: Spacings.S16,
  },
})
