import React from 'react'
import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_AMOUNT } from '@taaleri/components/src/screens/questions/QuestionRoutes'

import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import AmountContent from '@taaleri/components/src/screens/questions/AmountContent'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function AmountScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const nextRoute = nextQuestionRoute(ROUTE_QUESTION_AMOUNT)

  return (
    <QuestionContainer>
      <TopBottomContainer>
        <AmountContent />
        <Buttons
          title={t('button.next')}
          onPress={() => {
            history.push(nextRoute)
          }}
        />
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(AmountScreen)
