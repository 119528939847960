import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  PortfolioManagementTransactionItem,
  SecurityTransaction,
  TransactionItem,
} from '@taaleri/core/src/models/Feed'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'
import groupBy from 'lodash/groupBy'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import {
  FeedProps,
  BoxFeed,
  RowFeed,
  MainContentFeed,
  DateFeed,
  TitleFeed,
  ContentRow,
  BodyText,
} from './FeedComponents'
import {
  IconAccount,
  IconBell,
  IconCash,
  IconExpense,
  IconOther,
  IconPortfolioTransaction,
  IconProfit,
  IconSecurity,
  IconWithdrawal,
} from './FeedIcons'
import { FeedNewsRow, WarningRow } from './FeedNewsRow'
import PropsChildren from '../../components/PropsChildren'
import FlexRow from '../../ui/FlexRow'
import UiTouchable from '../../ui/UiTouchable'
import {
  IconChevronUpSmall,
  IconChevronDownSmall,
} from '../../ui/icons/ChevronIcons'
import { TextBold, TextMedium, TextDefault } from '../../ui/text/UiText'

export function FeedRow({ feed, t }: FeedProps) {
  const { itemType, warningNotification } = feed
  if (itemType === 'SecurityTransaction') {
    return <FeedSecurityRow feed={feed} t={t} />
  }
  if (itemType === 'CashTransaction') {
    return <FeedCashRow feed={feed} t={t} />
  }
  if (itemType === 'PortfolioManagementTransaction') {
    return <FeedPortfolioManagementRow feed={feed} t={t} />
  }
  if (itemType === 'NewsArticle') {
    return <FeedNewsRow feed={feed} t={t} />
  }
  if (itemType === 'WarningNotification' && warningNotification !== undefined) {
    return <WarningRow warning={warningNotification} />
  }
  return <div>Not implemented</div>
}

const expenseTypes = [
  'Varainhoitopalkkio',
  'Tilinylityskorko',
  'Säilytys- ja tapahtumamaksu',
  'Kultataaleri säilytysmaksu',
  'Vakuutuksen hoitopalkkio',
  'Arvopaperisäilytyksen järjestämispalkkio',
  'Tuottopalkkio',
  'Vakuutuksen kiinteä hoitopalkkio',
  'Limiitin muutosmaksu',
  'Arvopaperisäilytyksen järjestämispalkkio',
  'Viivästyskorko',
  'Palkkionpalautus',
  'Muu kulu',
  'Negatiivinen talletuskorko',
  'Pankkikulu',
]

export function FeedCashRow({ feed, t }: FeedProps) {
  const [open, setOpen] = useState<boolean>(false)
  function toTitle(securityTransaction: SecurityTransaction) {
    const { transactionTypeName, amount } = securityTransaction
    let name = securityTransaction.transactionTypeName
    if (transactionTypeName === 'Lisäsijoitus/osanosto') {
      name =
        amount > 0
          ? t('app.lastupdates.additional-purchase')
          : t('app.lastupdates.withdraw')
    } else if (transactionTypeName === 'Tilisiirto') {
      name = t('invest.bank-transaction')
    } else if (transactionTypeName === 'Tilitapahtuma') {
      name = t('app.lastupdates.transactions')
    } else if (transactionTypeName === 'Vakuutuksen hoitopalkkio') {
      name = t('transaction-type-name.insurance-fee')
    }

    if (
      AppStore.isDigiDiscretionary &&
      expenseTypes.includes(transactionTypeName)
    ) {
      name = t('app.lastupdates.rewards')
    }
    return `${name} ${formatCurrency(securityTransaction.amount)}`
  }

  function toBody(transactionItem: TransactionItem) {
    const { description, securityTransaction } = transactionItem
    const { transactionTypeName, amount } = securityTransaction
    if (
      AppStore.isDigiDiscretionary &&
      transactionTypeName === 'Lisäsijoitus/osanosto'
    ) {
      return amount > 0
        ? t('app.lastupdates.purchase-done-description')
        : t('app.lastupdates.purchase-description')
    }
    if (
      AppStore.isDigiDiscretionary &&
      transactionTypeName === 'Varainhoitopalkkio'
    ) {
      return t('app.lastupdates.fee')
    }
    if (!AppStore.isDigiDiscretionary && description) {
      return description
    }
    return undefined
  }

  function CashImage({
    securityTransaction,
  }: {
    securityTransaction: SecurityTransaction
  }) {
    const { transactionTypeName, amount } = securityTransaction
    if (transactionTypeName === 'Lisäsijoitus/osanosto') {
      return amount > 0 ? <IconCash /> : <IconWithdrawal />
    }
    if (expenseTypes.includes(transactionTypeName)) {
      return <IconExpense />
    }
    return <IconCash />
  }

  if (feed.cashTransactionItems.length > 1) {
    return (
      <BoxFeed>
        <RowFeed>
          <View style={styles.iconWrapper}>
            <IconAccount />
          </View>
          <View style={styles.newsContentWrapper}>
            <DateFeed>{feed.date}</DateFeed>
            <MainContentFeed>
              <TitleFeed>{t('app.lastupdates.transactions')}</TitleFeed>
              <ToggleHeader
                count={feed.cashTransactionItems.length}
                onPress={() => setOpen(!open)}
                open={open}
                t={t}
              />
            </MainContentFeed>
          </View>
        </RowFeed>
        {open && (
          <DetailContainer>
            {feed.cashTransactionItems.map((item, index: number) => {
              const body = toBody(item)
              const { securityTransaction } = item
              return (
                <ContentRow
                  key={index}
                  style={[
                    index !== feed.cashTransactionItems.length - 1 &&
                      styles.contentRowMargin,
                  ]}
                >
                  <TextMedium>{toTitle(securityTransaction)}</TextMedium>
                  {body && <BodyText>{body}</BodyText>}
                </ContentRow>
              )
            })}
          </DetailContainer>
        )}
      </BoxFeed>
    )
  }

  return (
    <BoxFeed>
      <RowFeed>
        <View style={styles.iconWrapper}>
          <CashImage
            securityTransaction={
              feed.cashTransactionItems[0].securityTransaction
            }
          />
        </View>
        <View style={styles.newsContentWrapper}>
          <DateFeed>{feed.date}</DateFeed>
          <MainContentFeed>
            {feed.cashTransactionItems.map((item, index: number) => {
              const body = toBody(item)
              const { securityTransaction } = item
              return (
                <ContentRow
                  key={index}
                  style={[
                    index !== feed.cashTransactionItems.length - 1 &&
                      styles.contentRowMargin,
                  ]}
                >
                  <TitleFeed>{toTitle(securityTransaction)}</TitleFeed>
                  {body && <BodyText>{body}</BodyText>}
                </ContentRow>
              )
            })}
          </MainContentFeed>
        </View>
      </RowFeed>
    </BoxFeed>
  )
}

function ToggleHeader({
  onPress,
  count,
  open,
  t,
}: {
  onPress: () => void
  count: number
  open: boolean
  t: TFunction
}) {
  return (
    <UiTouchable
      onPress={onPress}
      style={{ marginTop: 20, flexDirection: 'row' }}
    >
      <View
        style={[
          {
            backgroundColor: Colors.primary,
            borderRadius: 20,
            height: 20,
            justifyContent: 'center',
            alignSelf: 'center',
            paddingLeft: 16,
            paddingRight: Spacings.S8,
          },
        ]}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <TextBold
            style={{
              color: Colors.white,
              fontSize: FontSize.S10,
              textAlign: 'center',
              lineHeight: 20,
              marginRight: Spacings.S8,
            }}
          >
            {t('app.lastupdates.transaction-amount', { value: count })}
          </TextBold>
          {open ? <IconChevronUpSmall /> : <IconChevronDownSmall />}
        </FlexRow>
      </View>
    </UiTouchable>
  )
}

function DetailContainer({ children }: PropsChildren) {
  return (
    <View
      style={{
        marginTop: Spacings.S16,
        paddingTop: Spacings.S16,
        borderTopWidth: 1,
        borderTopColor: Colors.border,
        marginHorizontal: -Spacings.PADDING,
      }}
    >
      <View style={{ paddingHorizontal: Spacings.PADDING }}>{children}</View>
    </View>
  )
}

interface Mapping {
  [key: string]: string
}

const securityTitleMappings: Mapping = {
  Kauppa: 'app.lastupdates.trade',
  Muu: 'app.lastupdates.other-transactions',
  Tuottotapahtuma: 'app.lastupdates.returns',
}

function toSecuritySubTypeTitle(title: string) {
  return securityTitleMappings[title] || title
}

export function FeedSecurityRow({ feed, t }: FeedProps) {
  const [open, setOpen] = useState<boolean>(false)
  const grouped = groupBy(
    feed.securityTransactionItems.filter(
      (sti) =>
        !AppStore.hideTransactions(
          sti.securityTransaction.portfolio.portfolioId
        )
    ),
    'securityTransaction.transactionTypeName'
  )
  const keys = Object.keys(grouped)
  const oneRow = keys.length === 1 && feed.securityTransactionItems.length === 1
  return (
    <>
      <BoxFeed>
        <RowFeed>
          <View style={styles.iconWrapper}>
            {feed.itemSubType === 'Kauppa' && <IconSecurity />}
            {feed.itemSubType === 'Tuottotapahtuma' && <IconProfit />}
            {feed.itemSubType === 'Muu' && <IconOther />}
          </View>
          <View style={styles.newsContentWrapper}>
            <DateFeed>{feed.date}</DateFeed>
            <MainContentFeed>
              <TitleFeed>
                {t(toSecuritySubTypeTitle(feed.itemSubType))}
              </TitleFeed>
              {!oneRow && (
                <ToggleHeader
                  count={
                    feed.securityTransactionItems.filter(
                      (sti) =>
                        !AppStore.hideTransactions(
                          sti.securityTransaction.portfolio.portfolioId
                        )
                    ).length
                  }
                  onPress={() => setOpen(!open)}
                  open={open}
                  t={t}
                />
              )}
            </MainContentFeed>
          </View>
        </RowFeed>
        {(oneRow || open) && (
          <DetailContainer>
            {keys.map((k, keyIndex) => {
              const items = grouped[k]
              return (
                <View
                  key={k}
                  style={[
                    keyIndex !== keys.length - 1 && styles.contentRowMargin,
                  ]}
                >
                  <FlexRow
                    style={{ marginBottom: Spacings.S8, alignItems: 'center' }}
                  >
                    <TextBold style={styles.detailTitle}>
                      {k.toUpperCase()} ({items.length})
                    </TextBold>
                  </FlexRow>
                  {items.map((item, index: number) => {
                    const securityTransaction = item.securityTransaction
                    return (
                      <FlexRow
                        key={index}
                        style={[
                          {
                            justifyContent: 'space-between',
                            flexWrap: 'nowrap',
                          },
                          index !== items.length - 1 && styles.contentRowMargin,
                        ]}
                      >
                        <TextDefault numberOfLines={1} style={[{ flex: 7 }]}>
                          {securityTransaction.financialInstrument?.name ??
                            t('app.lastupdates.deleted-security')}
                        </TextDefault>
                        {securityTransaction.totalValue && (
                          <TextDefault
                            style={[
                              {
                                marginLeft: 10,
                                flex: 4,
                                textAlign: 'right',
                              },
                            ]}
                          >
                            {formatCurrency(
                              securityTransaction.totalValue.baseCurrency.value
                            )}
                          </TextDefault>
                        )}
                      </FlexRow>
                    )
                  })}
                </View>
              )
            })}
          </DetailContainer>
        )}
      </BoxFeed>
    </>
  )
}

export function EmptyFeed({ t }: { t: TFunction }) {
  return (
    <BoxFeed style={styles.emptyFeed}>
      <IconBell style={styles.bell} />
      <TextDefault>{t('app.lastupdates.no-new-transactions')}</TextDefault>
    </BoxFeed>
  )
}

function portfolioManagementTitle(
  {
    orderType,
    sellEverything,
    isDirectOrder,
    securityName,
    amount,
  }: PortfolioManagementTransactionItem,
  { t }: { t: TFunction }
) {
  if (orderType === 'Buy') {
    return (
      `${t('app.lastupdates.additional-purchase-title', {
        currency: formatCurrency(Number(amount)),
      })}` +
      (isDirectOrder
        ? ` ${t('app.lastupdates.to-fund', { security: securityName })}` ?? ''
        : '')
    )
  }
  if (orderType === 'Sell') {
    if (isDirectOrder) {
      // Customer has withdrawn everything from a single fund
      if (sellEverything && securityName) {
        return t('app.lastupdates.withdraw-from', { security: securityName })
      }
      // Customer has withdrawn a portion from a single fund
      else if (!sellEverything && securityName && amount) {
        return t('app.lastupdates.withdraw-title', {
          currency: formatCurrency(Number(amount)),
          security: securityName,
        })
      }
      // SecurityName is undefined for cash
      // Customer has withdrawn a portion of cash
      else if (!securityName && amount) {
        return t('app.lastupdates.withdraw-cash', {
          currency: formatCurrency(Number(amount)),
        })
      }
      // Customer has withdrawn all cash
      else if (!securityName && sellEverything) {
        return t('app.lastupdates.withdraw-all-cash')
      }
      // Generic withdraw message, this case should never occur.
      else {
        return t('app.lastupdates.did-withdraw')
      }
    }
    return sellEverything
      ? t('app.lastupdates.withdraw-all-title')
      : t('app.lastupdates.withdraw-short', {
          currency: formatCurrency(Number(amount)),
        })
  }

  if (orderType === 'RiskLevelChange') {
    return t('app.lastupdates.risk-level-change')
  }
  if (orderType === 'Rebalancing') {
    return t('app.lastupdates.not-balanced')
  }

  return orderType
}

function portfolioManagementBody(
  { orderType, securityName, description }: PortfolioManagementTransactionItem,
  { t }: { t: TFunction }
) {
  if (orderType === 'Buy') {
    return t('app.lastupdates.additional-purchase-delay')
  }
  if (orderType === 'Sell') {
    // SecurityName is undefined for cash
    const isCash = !securityName
    return AppStore.isConsultative
      ? isCash
        ? t('app.lastupdates.transaction-delay')
        : t('app.lastupdates.after-transaction')
      : t('app.lastupdates.description')
  }
  if (orderType === 'RiskLevelChange') {
    return t('app.lastupdates.risk-level-change-info')
  }
  if (orderType === 'Rebalancing') {
    return t('app.lastupdates.rebalancing-info')
  }
  return description
}

export function FeedPortfolioManagementRow({ feed, t }: FeedProps) {
  return (
    <View>
      {feed.portfolioManagementTransactionItems.map((item, index: number) => {
        return (
          <BoxFeed key={index}>
            <RowFeed>
              <View style={styles.iconWrapper}>
                <IconPortfolioTransaction />
              </View>
              <View style={styles.newsContentWrapper}>
                <DateFeed>{feed.date}</DateFeed>
                <MainContentFeed>
                  <ContentRow
                    style={[
                      index !==
                        feed.portfolioManagementTransactionItems.length - 1 &&
                        styles.contentRowMargin,
                    ]}
                  >
                    <TitleFeed>
                      {portfolioManagementTitle(item, { t })}
                    </TitleFeed>
                    <BodyText>{portfolioManagementBody(item, { t })}</BodyText>
                  </ContentRow>
                </MainContentFeed>
              </View>
            </RowFeed>
          </BoxFeed>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  detailTitle: {
    color: Colors.textSecondary,
    fontSize: FontSize.S12,
  },

  contentRowMargin: {
    marginBottom: Spacings.S8,
  },
  emptyFeed: {
    alignItems: 'center',
    padding: Spacings.S40,
    marginBottom: Spacings.S24,
  },
  bell: {
    alignItems: 'center',
    paddingBottom: Spacings.S8,
  },
  iconWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 15,
    marginTop: 5,
    marginRight: 15,
  },
  newsContentWrapper: {
    flex: 11,
  },
})
