import React from 'react'
import FlexRow from '../../ui/FlexRow'
import { AssetClassItem, PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import { formatDateFull, sum } from '@taaleri/core/src/utils/format'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import PurchaseLotChart from './PurchaseLotChart'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { isWeb } from '../../constants/Platforms'
import FundPieContainer from '../funds/FundPieContainer'
import { BackButton } from '../../ui/BackButton'
import { CombinedIndex } from '@taaleri/core/src/models/CombinedIndex'
import { TextDefault } from '../../ui/text/UiText'
import Box from '../../ui/box/Box'
import Colors from '@taaleri/core/src/constants/Colors'
import { PurchaseLotRowMobile } from './PurchaseLotRowMobile'
import FundPieContainerNoLoad from '../funds/FundPieContainerNoLoad'
import { useFund } from '../funds/useFunds'
import { useTranslation } from 'react-i18next'

interface Props extends DetailProps {
  combinedIndex?: CombinedIndex
  assetClassItem: AssetClassItem
  token?: string
}

interface DetailProps {
  purchaseLots: PurchaseLot[]
}

export function PurchaseLotDetailMobile({ purchaseLots }: DetailProps) {
  const { t } = useTranslation()
  if (purchaseLots.length === 0) {
    return null
  }
  const totalQuantity = sum(purchaseLots.map((p) => p.quantity))
  const totalMarketValue = sum(purchaseLots.map((p) => p.marketValue))
  const totalPurchaseValue = sum(purchaseLots.map((p) => p.purchaseValue))
  const totalMarketValueChange = totalMarketValue - totalPurchaseValue
  const averagePurchasePrice = totalQuantity
    ? sum(purchaseLots.map((e) => e.quantity * (e.purchasePrice ?? 0))) /
      totalQuantity
    : undefined
  const totalMarketValueChangePercent =
    (100 * totalMarketValueChange) / totalPurchaseValue

  const averageMarketPrice = totalQuantity
    ? sum(purchaseLots.map((e) => e.quantity * (e.marketPrice ?? 0))) /
      totalQuantity
    : undefined

  return (
    <>
      <PurchaseLotRowMobile
        key="Yhteensä"
        title={`${t('portfolio.total')}`}
        borderBottom={false}
        purchaseLot={{
          marketValueChangePercent: totalMarketValueChangePercent,
          quantity: totalQuantity,
          marketValue: totalMarketValue,
          purchaseValue: totalPurchaseValue,
          purchaseDate: purchaseLots[0]?.purchaseDate,
          marketPrice: averageMarketPrice,
          purchasePrice: averagePurchasePrice,
          securityTypeId: purchaseLots[0]?.securityTypeId,
        }}
      />
      {purchaseLots.map((purchaseLot: PurchaseLot, index: number) => {
        const { purchaseDate } = purchaseLot
        return (
          <PurchaseLotRowMobile
            purchaseLot={purchaseLot}
            key={index}
            title={formatDateFull(purchaseDate)}
            borderBottom={index === purchaseLots.length - 1}
          />
        )
      })}
    </>
  )
}

export default function PurchaseLotsContent({
  purchaseLots,
  combinedIndex,
  assetClassItem,
  token,
}: Props) {
  const { t } = useTranslation()
  const { ticker, ownFund } = assetClassItem
  const { fund, error, retry, notFound } = useFund(ticker)

  return (
    <>
      {isWeb && <BackButton />}
      <TouchableWithoutFeedback
        onPress={() => {
          AppStore.showPicker = false
        }}
      >
        <View>
          {combinedIndex && (
            <PurchaseLotChart
              assetClassItem={assetClassItem}
              combinedIndex={combinedIndex}
              token={token}
              style={{ marginVertical: Spacings.S16 }}
            />
          )}
          {ownFund ? (
            <FundPieContainerNoLoad
              fund={fund}
              error={error}
              retry={retry}
              notFound={notFound}
              containerStyle={{ marginVertical: Spacings.S16 }}
            />
          ) : null}
          <Box
            style={{
              paddingHorizontal: Spacings.PADDING,
              paddingTop: 12,
            }}
          >
            <FlexRow style={styles.title}>
              <TextDefault type="h5">{t('portfolio.purchaselots')}</TextDefault>
              <TextDefault type="t2" color={Colors.textSecondary}>
                {t('portfolio.value-change')}
              </TextDefault>
            </FlexRow>
            <PurchaseLotDetailMobile {...{ purchaseLots }} />
          </Box>
        </View>
      </TouchableWithoutFeedback>
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    justifyContent: 'space-between',
    paddingBottom: 12,
  },
  marketPriceLabel: {
    marginTop: isWeb ? -Spacings.S8 : Spacings.S16,
  },
})
