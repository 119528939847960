import React from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import BoxCheck from '../../ui/box/BoxCheck'
import useNavigation from '../../navigation/useNavigation'
import {
  investStartRouteForContract,
  getWithdrawalRouteContract,
} from './PortfolioRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useLayout from '../../ui/useLayout'
import { useTranslation } from 'react-i18next'

export interface SelectContractContentProps {
  mode: 'invest' | 'withdraw'
}

export default function SelectContractContent({
  mode,
}: SelectContractContentProps) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { isSmall } = useLayout()
  const isWithdrawal = mode === 'withdraw'
  const { impactShare } = AppStore

  function navigateNext(isConsultative: boolean) {
    navigate(
      isWithdrawal
        ? getWithdrawalRouteContract(isConsultative)
        : investStartRouteForContract(isConsultative)
    )
  }
  const backgroundColor = isSmall ? 'background' : 'white'
  const digiPortfolioName = impactShare
    ? `${t('impact-portfolio')}`
    : `${t('etf-portfolio')}`
  return (
    <View>
      <Title>
        {isWithdrawal ? t('invest.choose-portfolio') : t('invest.title')}{' '}
      </Title>
      <Paragraph>
        {`${t('invest.description', { name: digiPortfolioName })} `}
        {isWithdrawal
          ? t('invest.choose-either-portfolio')
          : t('invest.desc-choose')}
      </Paragraph>

      <BoxCheck
        isSelected={false}
        backgroundColor={backgroundColor}
        onPress={() => {
          AppStore.investmentMode = isWithdrawal ? 'withdraw' : 'digi'
          navigateNext(false)
        }}
        option={{ label: digiPortfolioName, value: 'digi' }}
      />

      <BoxCheck
        isSelected={false}
        backgroundColor={backgroundColor}
        onPress={() => {
          AppStore.investmentMode = isWithdrawal ? 'withdrawfund' : 'fund'
          navigateNext(true)
        }}
        option={{ label: t('invest.fund-portfolio'), value: 'cons' }}
      />
    </View>
  )
}
