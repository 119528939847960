import Colors from '@taaleri/core/src/constants/Colors'
import CustomerService from '@taaleri/core/src/constants/CustomerService'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import styled from 'styled-components'

import { IconEmail, IconPhone } from './ContactIcons'
import { TextBold, TextDefault } from '../../ui/text/UiText'

const Container = styled.div`
  display: flex;

  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    width: 740px;
  }
  @media (min-width: 1024px) {
    width: 800px;
  }
  border: 1px solid ${Colors.border};
`

const ContactBox = styled.a`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 32px 24px;
  align-items: center;
  &:first-of-type {
    border-bottom: 1px solid ${Colors.border};
  }
  @media (min-width: 768px) {
    padding: 32px 48px;
    &:first-of-type {
      border-bottom: none;
    }

    border-right: 1px solid ${Colors.border};
  }
  @media (hover: hover) and (pointer: fine) {
    :hover,
    :focus {
      color: ${Colors.text};
      background: rgba(71, 55, 41, 0.05);
      text-decoration: none;
    }
    :active {
      color: ${Colors.text};
      background: rgba(71, 55, 41, 0.15);
      text-decoration: none;
    }
  }
`

export default function ContactButtons() {
  const { t } = useTranslation()
  return (
    <Container>
      <ContactBox
        href={`tel:${CustomerService.phoneNumber.split(' ').join('')}`}
      >
        <View style={styles.icon}>
          <IconPhone />
        </View>
        <View>
          <TextBold type="h4" style={styles.contactTitle}>
            {t('faq.call-us')}
          </TextBold>
          <TextDefault>{CustomerService.phoneNumberFormatted}</TextDefault>
        </View>
      </ContactBox>
      <ContactBox href={CustomerService.messageUrl} target="_blank">
        <View style={styles.icon}>
          <IconEmail />
        </View>
        <View>
          <TextBold type="h4" style={styles.contactTitle}>
            {t('faq.mail-us')}
          </TextBold>
          <TextDefault></TextDefault>
        </View>
      </ContactBox>
    </Container>
  )
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 720,
  },
  paragraph: {
    alignItems: 'center',
  },
  icon: {
    flexBasis: 48,
    marginRight: 40,
  },
  title: {
    marginBottom: 24,
  },
  contactContainer: {
    borderWidth: 1,
    borderColor: Colors.border,
  },
  contactContainerLarge: {
    flexDirection: 'row',
  },

  contactTitle: {
    marginBottom: 4,
    fontSize: 18,
  },
})
