import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import UiButton from '@taaleri/components/src/ui/UiButton'

import { boxPadding } from '@taaleri/components/src/ui/box/Box'

import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { Customer } from '@taaleri/core/src/models/Customer'
import { observer } from 'mobx-react'
import { sleep } from '@taaleri/core/src/api/mockHelper'
import { validateIban } from '@taaleri/core/src/services/validationService'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import { PaddingHorizontal } from '@taaleri/components/src/ui/PaddingHorizontal'
import HelpText from '@taaleri/components/src/ui/text/HelpText'
import Title from '@taaleri/components/src/ui/text/Title'
import { formatIban } from '@taaleri/core/src/utils/format'
import { ROUTE_CONTRACT_ACCOUNT_NUMBER } from '@taaleri/components/src/screens/contract/ContractRoutes'
import { nextContractRoute } from '@taaleri/components/src/screens/questions/questions'

import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_ACCOUNT_NUMBER,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import UiForm from '../../ui/form/UiForm'
import { isWeb } from '../../constants/Platforms'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import useNavigation from '../../navigation/useNavigation'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface Props {
  onDone?: (accountNumber: string) => void
  buttonTitle?: string
  title?: string
  label?: string
  children?: any
  defaultValue?: string
}

function AccountNumberForm({
  onDone,
  label,
  title,
  defaultValue,
  buttonTitle,
  children,
}: Props) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  let formattedBankAccountNumber: string | undefined

  const validationSchema = (t: TFunction) =>
    Yup.object().shape({
      bankAccountNumber: Yup.string()
        .required(`${t(ErrorMessages.required)}`)
        .test(
          'bankAccountNumber',
          `${t(ErrorMessages.checkBankAccountNumber)}`,
          async (iban) => {
            const validationResponse = await validateIban(iban ?? '')

            if (validationResponse.success) {
              formattedBankAccountNumber = formatIban(
                validationResponse.response || ''
              )
            }
            return validationResponse.success
          }
        ),
    })

  const customer = QuestionStore.customerMaybe
    ? QuestionStore.customer
    : AppStore.activeCustomer

  const initialValues: Customer = {
    ...customer,
    bankAccountNumber: defaultValue ?? customer.bankAccountNumber,
  }
  const isContractUpdate = QuestionStore.questionMode === 'contract'
  return (
    <View>
      <PaddingHorizontal>
        <Title>
          {title ?? `${t('questions.accountNumber.give-account-number-title')}`}
        </Title>
        {children}
      </PaddingHorizontal>
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: Customer,
          actions: FormikHelpers<Customer>
        ) => {
          actions.setSubmitting(true)

          const result = await validationSchema(t).isValid(values)

          if (result) {
            QuestionStore.customerMaybe = {
              ...values,
              ...{ bankAccountNumber: formattedBankAccountNumber || '' },
            }
            actions.setFieldValue(
              'bankAccountNumber',
              formatIban(formattedBankAccountNumber || '')
            )
            if (onDone) {
              await onDone(values.bankAccountNumber)
            } else {
              await sleep(400)
              navigate(
                isContractUpdate
                  ? nextContractRoute(ROUTE_CONTRACT_ACCOUNT_NUMBER)
                  : nextOnboardingRoute(ROUTE_ONBOARDING_ACCOUNT_NUMBER)
              )
            }
          } else {
            formattedBankAccountNumber = undefined
            actions.setFieldError(
              'bankAccountNumber',
              ErrorMessages.checkBankAccountNumber
            )
            actions.setSubmitting(false)
          }
        }}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <UiForm>
            <View
              style={{
                paddingVertical: Spacings.S24,
                paddingHorizontal: boxPadding,
              }}
            >
              <UiTextInputForm
                name="bankAccountNumber"
                label={label ?? `${t('questions.accountNumber.title')}`}
                type="name"
                requiredNotFilled={true}
                containerStyle={{ marginBottom: 0 }}
                autoCapitalize="characters"
                value={formatIban(values.bankAccountNumber)}
                maxLength={22}
                editable={!isSubmitting}
                hideCheckCircle={true}
                grayBackground={true}
              />
            </View>
            <HelpText>
              {t('questions.accountNumber.account-description')}
            </HelpText>

            <PaddingHorizontal>
              <UiButton
                containerStyle={{
                  marginTop: Spacings.S24,
                  marginBottom: Spacings.S40,
                }}
                title={buttonTitle || t('button.next')}
                loading={isSubmitting}
                disabled={isSubmitting}
                onPress={isWeb ? () => '' : handleSubmit}
              />
            </PaddingHorizontal>
          </UiForm>
        )}
      </Formik>
    </View>
  )
}

export default observer(AccountNumberForm)
