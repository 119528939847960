export const ROUTE_QUESTION_START = 'QuestionStart'
export const ROUTE_QUESTION_AGE = 'QuestionAge'
export const ROUTE_QUESTION_AMOUNT = 'QuestionAmount'
export const ROUTE_QUESTION_INTRO = 'QuestionIntro'
export const ROUTE_QUESTION_PORTFOLIO_TYPE = 'QuestionPortfolioType'
export const ROUTE_QUESTION_FIRST_PROPOSAL = 'QuestionFirstProposal'
export const ROUTE_QUESTION_FINANCE = 'QuestionFinance'
export const ROUTE_QUESTION_CAPITAL_INCOME = 'QuestionCapitalIncome'
export const ROUTE_QUESTION_INVESTMENT_PROPOSAL = 'QuestionInvestmentProposal'
export const ROUTE_QUESTION_DAILY_IMPORTANCE_INFO =
  'QuestionDailyImportanceInfo'
export const ROUTE_QUESTION_EXISTING_AUTHORIZATION =
  'QuestionExistingAuthorization'
export const ROUTE_QUESTION_EXISTING_CUSTOMER = 'QuestionExistingCustomer'
export const ROUTE_QUESTION_ETF_COMPARISON = 'QuestionEtfComparison'
export const ROUTE_QUESTION_ESG_SUITABILITY_WARNING = 'EsgSuitabilityWarning'
export const ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER = 'ForeignMoneyTransfer'
export const ROUTE_QUESTION_CASH_PURPOSE = 'CashPurpose'
