import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { View, ImageBackground } from 'react-native'

import PercentagePieWithNumber from '../../components/PercentagePieWithNumber'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../ui/FlexRow'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'

interface Props {
  title: string
  image: any
  weights: number[]
  weight: number
}

const ImpactAssetDetailBox: React.FC<Props> = ({
  title,
  image,
  weights,
  weight,
}) => {
  const pieSize = 68
  const height = 100
  const borderRadiusBottom = 0

  return (
    <ImageBackground
      source={image}
      style={{
        height,
        borderRadius: BorderRadius.default,
        borderBottomLeftRadius: borderRadiusBottom,
        borderBottomRightRadius: borderRadiusBottom,
        overflow: 'hidden',
      }}
      resizeMode="cover"
    >
      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: Spacings.S24,
          height,
        }}
      >
        <View>
          <TextDefault
            type="h3"
            color={Colors.white}
            style={{
              marginBottom: 4,
            }}
          >
            {title}
          </TextDefault>
          {/*<FlexRow style={{ alignItems: 'center' }}>
            <TextMedium style={{ color: Colors.white }}>
              Tuotto-odotus + 8,00 %
            </TextMedium>
          </FlexRow>*/}
        </View>
        <PercentagePieWithNumber
          percentages={weights}
          percentage={weight}
          theme="white"
          size={pieSize}
        />
      </FlexRow>
    </ImageBackground>
  )
}

export default ImpactAssetDetailBox
