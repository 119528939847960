import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import ChangePhoneForm from '@taaleri/components/src/screens/profile/ChangePhoneForm'

export default function ChangePhoneScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <ChangePhoneForm />
    </ContentContainerBox>
  )
}
