import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import OpenLink from '../../ui/OpenLink'
import UiButton from '../../ui/UiButton'
import UiLink from '../../ui/UiLink'
import { BoxWithPadding } from '../../ui/box/Box'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import useLayout from '../../ui/useLayout'

interface Props {
  url: string
  urlBasicInfo?: string
  showPrices?: boolean
}

function LinkRow({ text, url, last }: LinkItem & { last: boolean }) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  return (
    <UiLink
      text={`${t(text)}`}
      style={[styles.row, last && { borderBottomWidth: 0, paddingBottom: 0 }]}
      url={url}
      target={'_blank'}
      fullWidth={isSmall}
    />
  )
}

interface LinkItem {
  url: string
  text: string
}

export default function ContractContent({ url, showPrices = false }: Props) {
  const { t } = useTranslation()
  const { isSmall, isNonSmall } = useLayout()

  const investInformationUrl = () =>
    AppStore.currentLanguage === fiKey
      ? 'https://www.aktia.fi/fi/tietoja-aktian-sijoituspalveluista'
      : 'https://www.aktia.fi/sv/tietoja-aktian-sijoituspalveluista'

  const termsUrl = () =>
    AppStore.currentLanguage === fiKey
      ? 'https://misc.aktia.fi/data-service/documents/investment/Asiakassopimukset/Sopimusehdot/Aktian_varainhoitopalveluiden_sopimusehdot.pdf'
      : 'https://misc.aktia.fi/data-service/documents/investment/Asiakassopimukset/Sopimusehdot/Aktia_kapitalforvaltningstjanster_avtalsvillkor.pdf'

  const LinkItems: LinkItem[] = [
    ...insertIf(
      !AppStore.customerMaybe ||
        !AppStore.isConsultativeOrOpening ||
        showPrices,
      {
        text: 'app.ImpactToggleContract.Impaktipricelink',
        url: 'https://varainhoito.aktia.fi/download_file/1106/0',
      }
    ),
    {
      text: 'app.ImpactToggleContract.Impaktiinvestmentinformationlink',
      url: investInformationUrl(),
    },
    {
      text: 'app.ImpactToggleContract.impaktitermslink',
      url: termsUrl(),
    },
  ]
  return (
    <View style={{ marginBottom: Spacings.S64 }}>
      <Title>{t('steps.confirmation')}</Title>
      <Paragraph style={{ marginBottom: Spacings.S32 }}>
        {t('questions.accountCreation.confirm-text')}
      </Paragraph>
      <BoxWithPadding
        title={`${t('questions.accountCreation.contract-details')}`}
        titleStyle={{
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
          marginBottom: Spacings.S20,
        }}
        style={isNonSmall && styles.boxDesktop}
      >
        <UiButton
          containerStyle={isNonSmall && { maxWidth: 300 }}
          title={t('app.ImpactToggleContract.checkcontractbuttton')}
          type="secondary"
          onPress={() => OpenLink(url, 'aktia')}
        />
        <View
          style={[
            {
              marginTop: 20,
            },
            isSmall && { borderTopColor: Colors.border, borderTopWidth: 1 },
          ]}
        >
          {LinkItems.map((l, index) => (
            <LinkRow
              key={l.text}
              {...l}
              last={index === LinkItems.length - 1}
            />
          ))}
        </View>
      </BoxWithPadding>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomColor: Colors.border,
    borderBottomWidth: 1,
    paddingVertical: 18,
  },
  boxDesktop: {
    backgroundColor: 'transparent',
    shadowOffset: undefined,
    shadowRadius: undefined,
  },
})
