import Analytics from '@taaleri/core/src/analytics/Analytics'
import CustomerService from '@taaleri/core/src/constants/CustomerService'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'

import UiButton from './UiButton'
import { openUrl } from './openUrl'
import useNavigation from '../navigation/useNavigation'

interface ContactCustomerServiceButtonsProps {
  buttonType?: 'primary' | 'secondary'
}

export default function ContactCustomerServiceButtons({
  buttonType,
}: ContactCustomerServiceButtonsProps) {
  const { t } = useTranslation()
  const { navigateBack } = useNavigation()
  const callSupport = () => {
    const url = `tel:${CustomerService.phoneNumber.split(' ').join('')}`
    Analytics.event('Contact', 'Call')
    return openUrl(url)
  }

  const type = buttonType ? buttonType : 'primary'
  return (
    <>
      <UiButton
        type={type}
        title={t('customerService.call')}
        onPress={callSupport}
        containerStyle={{
          marginBottom: Spacings.S24,
          marginTop: Spacings.S16,
          width: '100%',
        }}
      />
      <UiButton
        type="secondary"
        title={t('button.back')}
        onPress={navigateBack}
        containerStyle={{ width: '100%' }}
      />
    </>
  )
}
