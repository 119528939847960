import { TimeseriesType } from './TimeseriesType'
import { sum, roundToNearest } from '@taaleri/core/src/utils/format'
import { getRevenueTimeseries } from './RevenueCalculator'

export const YEARLY_CO_FOOTPRINT = 10300
export const CO2_HELSINKI_BANGKOK_HELSINKI = 2850
const CO2_BOAT = 144
const CO2_SAUNA = 2.384615384
export const CAR_CO2_PER_KILOMETER = 0.158
export const BANANA_CO2 = 0.164

const ANNUAL_CO2_PER_EUR_CONSTANT = 2.873866
const ANNUAL_CO2_PER_EUR_CONSTANT_IMPACT = 0.410605935

const calculateCO2TimeSeries = (
  timeseries: TimeseriesType,
  impactPercentage: number,
  coefficient: number
) =>
  timeseries.slice(1).map((c: { x: number; y: number }) => ({
    x: c.x,
    y: (c.y * coefficient * impactPercentage) / 100,
  }))

const calculateTotalCO2 = (co2Timeseries: TimeseriesType): number => {
  return sum(co2Timeseries.map((c: { x: number; y: number }) => c.y))
}

export function co2OffsetPrivateEquity(
  timeseries: TimeseriesType,
  impactPercentage: number
) {
  const co2Timeseries = calculateCO2TimeSeries(
    timeseries,
    impactPercentage,
    ANNUAL_CO2_PER_EUR_CONSTANT
  )
  const co2OffSet = calculateTotalCO2(co2Timeseries)
  return roundToNearest(co2OffSet, 10)
}

export function co2OffsetImpact(
  timeseries: TimeseriesType,
  impactPercentage: number
) {
  const co2Timeseries = calculateCO2TimeSeries(
    timeseries,
    impactPercentage,
    ANNUAL_CO2_PER_EUR_CONSTANT_IMPACT
  )
  const co2OffSet = calculateTotalCO2(co2Timeseries)
  return roundToNearest(co2OffSet, 10)
}

export function averageCo2Footprint(
  yearlyCo2Offset: number,
  rounded: boolean = true
) {
  const co2Footprint = yearlyCo2Offset / YEARLY_CO_FOOTPRINT
  return rounded ? Math.floor(co2Footprint) : co2Footprint
}

export function thailandFlights(yearlyCo2Offset: number) {
  return Math.floor(yearlyCo2Offset / (CO2_HELSINKI_BANGKOK_HELSINKI * 2))
}

export function carKilometers(yearlyCo2Offset: number) {
  return Math.floor(yearlyCo2Offset / CAR_CO2_PER_KILOMETER)
}

export function bananaCo2(yearlyCo2Offset: number) {
  return Math.floor(yearlyCo2Offset / BANANA_CO2)
}

export function boatCo2(yearlyCo2Offset: number) {
  return Math.floor(yearlyCo2Offset / CO2_BOAT)
}

export function saunaCo2(yearlyCo2Offset: number) {
  return Math.floor(yearlyCo2Offset / CO2_SAUNA)
}

export interface ImpactParameters {
  impactShare: number
  expectedReturn: number
  initialInvestment: number
  monthlyInvestment: number
  investmentTime: number
  actualizedCo2Kilos: number
  isPrivateEquity?: boolean
}

export function calculateCo2Compensation({
  impactShare,
  expectedReturn,
  initialInvestment,
  monthlyInvestment,
  investmentTime,
  actualizedCo2Kilos,
  isPrivateEquity,
}: ImpactParameters): number {
  // Calculate expected revenue as timeserie
  const timeseries = getRevenueTimeseries(
    expectedReturn,
    initialInvestment,
    monthlyInvestment,
    0,
    investmentTime
  )

  const expectedCo2Kilos = isPrivateEquity
    ? co2OffsetPrivateEquity(timeseries, impactShare)
    : co2OffsetImpact(timeseries, impactShare)

  // Calculate expected co2 compensation
  const co2Compensation = actualizedCo2Kilos + expectedCo2Kilos

  // Return rounded value
  return Math.round(co2Compensation)
}
