import { BasicContract, BasicContractResponse } from '../models/Contract'
import {
  BasicInfoOnboarding,
  Customer,
  CustomerBasicInfo,
  CustomerBasicInfoResponse,
  CustomerResponse,
} from '../models/Customer'
import { Identity } from '../models/Identity'
import { ValidityDate } from '../models/IdentityCard'
import { ValidationResult } from '../models/Validation'
import {
  InvestmentProposal,
  PreliminaryProposal,
  PortfolioDistributions,
} from '../models/investmentProposal'
import logger from '../utils/logger'
import ICustomerApi from './ICustomerApi'
import { sleep } from './mockHelper'
import { toCustomer } from './normalization/toCustomer'
import { toCustomerBasicInfo } from './normalization/toCustomerBasicInfo'
import {
  toInvestmentProposal,
  toPreliminaryProposal,
  toInvestmentProposalFromSaved,
  toPortfolioDistribution,
} from './normalization/toInvestmentProposal'
import { BasicInfoProductFamiliarity } from './normalization/toAnswers'
import { FeedbackResponse } from '../models/FeedbackResponse'
import { InvestmentStrategy } from '../models/InvestmentStrategy'

export const TestTupasCustomer: Customer = (() => {
  const identifiedCustomerResponse = require(`../fixtures/customers/identifiedCustomerResponse.json`)
  const basicInfo = toCustomerBasicInfo(identifiedCustomerResponse)
  return basicInfo.customer
})()

export const TestCustomer: Customer = (() => {
  const customerResponse = require(`../fixtures/customers/customerResponse.json`)
  return toCustomer(customerResponse)
})()

export const AuthorizationCustomers: {
  [key: string]: CustomerResponse
} = (() => {
  const authorizationCustomers =
    require(`../fixtures/customers/authorizationCustomers.json`) as {
      [key: string]: CustomerResponse
    }
  return authorizationCustomers
})()

export const BasicInfoResponse: CustomerBasicInfoResponse = (() => {
  const basicInfoResponse = require(`../fixtures/customers/basicInfoResponse.json`)
  return basicInfoResponse
})()

async function getCustomer(_: Identity, customerId: number): Promise<Customer> {
  await sleep(100)

  return new Promise<Customer>((resolve) => {
    if (AuthorizationCustomers[customerId]) {
      return resolve(toCustomer(AuthorizationCustomers[customerId]))
    }

    const customer: Customer = { ...TestCustomer }
    return resolve(customer)
  })
}

async function getImpactStrategy(
  _1: number,
  _2: Identity
): Promise<PortfolioDistributions> {
  await sleep(100)
  const response = require('../fixtures/customers/impactStrategyResponse.json')
  return new Promise<PortfolioDistributions>((resolve) => {
    return resolve(toPortfolioDistribution(response))
  })
}

async function getCustomerBasicInfo(
  _: Identity,
  _1: number
): Promise<CustomerBasicInfo> {
  await sleep(1000)

  return new Promise<CustomerBasicInfo>((resolve) => {
    return resolve(toCustomerBasicInfo(BasicInfoResponse))
  })
}

async function getCustomerForcedKnowledge(
  _: Identity,
  _1: number
): Promise<BasicInfoProductFamiliarity> {
  await sleep(200)
  const forcedKnowledgeResponse = require('../fixtures/customers/forcedKnowledge.json')
  return new Promise<BasicInfoProductFamiliarity>((resolve) => {
    return resolve(forcedKnowledgeResponse)
  })
}

async function getTupasCustomer(_: Identity): Promise<Customer> {
  await sleep(1000)

  return new Promise<Customer>((resolve) => {
    const customer: Customer = TestTupasCustomer
    return resolve(customer)
  })
}

async function getInvestmentProposal(
  _1: number,
  _2: Identity,
  basicInfoOnboarding: BasicInfoOnboarding
): Promise<InvestmentProposal> {
  logger.devInfo('basicInfoOnboarding', basicInfoOnboarding)
  await sleep(500)
  const { withImpact } = basicInfoOnboarding
  const strategyImpact = require('../fixtures/customers/investmentStrategyImpact.json')
  const strategyNormal = require('../fixtures/customers/investmentStrategy.json')
  const investmentStrategy = withImpact ? strategyImpact : strategyNormal
  return toInvestmentProposal(investmentStrategy)
}

async function getInvestmentProposalSaved(
  customerId: number,
  _2: Identity
): Promise<InvestmentProposal> {
  await sleep(500)

  const investmentStrategy = require(`../fixtures/customers/currentStrategy.json`)
  const investmentStrategyImpact = require(`../fixtures/customers/currentStrategyImpact.json`)

  return toInvestmentProposalFromSaved(
    customerId === 11111112 ? investmentStrategyImpact : investmentStrategy
  )
}

async function getInvestmentProposalOnboarding(
  basicInfoOnboarding: BasicInfoOnboarding,
  withImpact?: boolean
): Promise<InvestmentProposal> {
  logger.devInfo('getInvestmentProposalOnboarding', basicInfoOnboarding)
  await sleep(500)
  const investmentStrategy = withImpact
    ? require(`../fixtures/customers/investmentStrategyOnboarding-impact.json`)
    : require(`../fixtures/customers/investmentStrategyOnboarding.json`)

  return toInvestmentProposal(investmentStrategy)
}

async function getFirstProposal(
  basicInfoOnboarding: BasicInfoOnboarding,
  age: number,
  withImpact: boolean
): Promise<InvestmentProposal> {
  logger.devInfo('getFirstProposal', basicInfoOnboarding)
  logger.devInfo('age', age)
  await sleep(500)
  return getInvestmentProposalOnboarding(basicInfoOnboarding, withImpact)
}

async function getPreliminaryProposal(
  basicInfoOnboarding: BasicInfoOnboarding,
  age: number
): Promise<PreliminaryProposal> {
  logger.devInfo('getPreliminaryProposal', basicInfoOnboarding)
  logger.devInfo('age', age)
  await sleep(500)
  const res = require(`../fixtures/customers/preliminaryProposalResponse.json`)
  return toPreliminaryProposal(res)
}

async function createContract(
  _: Identity,
  basicContract: BasicContract
): Promise<BasicContractResponse> {
  logger.devInfo('onboardingCustomer', basicContract)
  await sleep(1000)
  return new Promise<BasicContractResponse>((resolve) => {
    return resolve({
      smsConfirmationId: '',
      assetContractId: '',
    })
  })
}

let contractPolls = 0
async function pollContract(_: Identity): Promise<boolean> {
  contractPolls++

  if (contractPolls >= 5) {
    contractPolls = 0
    return true
  }

  return false
}

let customerPolls = 0
async function pollCustomer(_: Identity, _1: number): Promise<Customer | null> {
  customerPolls++
  const customer: Customer = TestCustomer

  if (customerPolls >= 2) {
    customerPolls = 0
    return customer
  }

  return null
}

async function validateIban(
  _: Identity,
  _1: string
): Promise<ValidationResult> {
  await sleep(500)

  return new Promise<ValidationResult>((resolve) => {
    return resolve({
      isSuccess: true,
      formattedValue: '',
    })
  })
}

async function basicInfoPost(
  _: Identity,
  basicInfoContract: BasicContract
): Promise<BasicContractResponse> {
  await sleep(2000)
  logger.devInfo(`basicInfoPost`, basicInfoContract)
  return new Promise<BasicContractResponse>((resolve) => {
    return resolve({
      smsConfirmationId: '',
      assetContractId: '',
    })
  })
}

async function updateStrategy(
  _: Identity,
  _1: boolean,
  _2: string
): Promise<BasicContractResponse> {
  await sleep(2000)
  return new Promise<BasicContractResponse>((resolve) => {
    return resolve({
      smsConfirmationId: '',
    })
  })
}

async function saveIdentityCard(
  _: Identity,
  _2: number,
  _3: string,
  _4: ValidityDate,
  _5: string
): Promise<boolean> {
  await sleep(2000)
  return new Promise<boolean>((resolve) => {
    return resolve(true)
  })
}

async function getContractDocument(_: Identity, _1: string): Promise<string> {
  await sleep(2000)
  return new Promise<string>((resolve) => {
    return resolve('http://www.africau.edu/images/default/sample.pdf')
  })
}

async function getMultipleContracts(
  _: Identity,
  _1: string[]
): Promise<string> {
  return new Promise<string>((resolve) => {
    return resolve('')
  })
}
async function getOnboardingState(_: Identity): Promise<any> {
  return { id: '' }
}

async function sendFeedback(
  _: Identity,
  _1: string,
  _2: string,
  _3: number
): Promise<FeedbackResponse> {
  await sleep(5000)
  return { value: true }
}

async function getStrategyByRiskLevel(
  _: Identity,
  isImpact: boolean,
  _1: number
): Promise<InvestmentStrategy> {
  const impactStrategy = require(`../fixtures/customers/impactStrategy.json`)
  const etfStrategy = require(`../fixtures/customers/etfStrategy.json`)
  return new Promise<InvestmentStrategy>((resolve) => {
    return resolve(isImpact ? impactStrategy : etfStrategy)
  })
}

async function postCustomerView(_: Identity, _1: number): Promise<boolean> {
  await sleep(1000)
  return true
}

export const customerApiMock: ICustomerApi = {
  postCustomerView,
  basicInfoPost,
  getCustomer,
  getImpactStrategy,
  getCustomerBasicInfo,
  getContractDocument,
  getTupasCustomer,
  createContract,
  pollContract,
  pollCustomer,
  getInvestmentProposal,
  getInvestmentProposalSaved,
  getInvestmentProposalOnboarding,
  getCustomerForcedKnowledge,
  getFirstProposal,
  getPreliminaryProposal,
  saveIdentityCard,
  validateIban,
  getMultipleContracts,
  getOnboardingState,
  sendFeedback,
  updateStrategy,
  getStrategyByRiskLevel,
}
