import { Identity } from '../models/Identity'
import { CONFLICT, ServiceResponse } from '../models/ServiceResponse'
import { api } from '../api/api'
import logger from '../utils/logger'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'

import AppStore from '../stores/AppStore'
import { Withdrawal, PaymentInfo } from '../models/Payment'
import { getConfig } from '../config'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { isLocal } from '../environment'

export async function setWithdrawal(
  withdrawal: Withdrawal
): Promise<ServiceResponse<{ smsConfirmationId?: string }>> {
  try {
    const identity: Identity = await getIdentity()
    const { smsConfirmationId } = await api().payments.setupWithdrawal(
      identity,
      AppStore.customerId,
      withdrawal
    )

    logger.devInfo('setWithdrawal response', smsConfirmationId)

    if (smsConfirmationId) {
      return { success: true, response: { smsConfirmationId } }
    }

    return { success: false, error: CONFLICT }
  } catch (e) {
    const error = handleErrors(e, 'setWithdrawal', 'fatal')
    return { success: false, error }
  }
}

export async function getPaymentMeta(
  sum: number,
  isConsultative: boolean
): Promise<PaymentInfo> {
  try {
    const identity: Identity = await getIdentity()
    const paymentMeta = await api().payments.getPaymentMeta(
      identity,
      AppStore.customerId,
      sum,
      isConsultative
    )

    logger.devInfo('paymentMeta', paymentMeta)

    return paymentMeta
  } catch (e) {
    const error = handleErrors(e, 'setWithdrawal', 'fatal')
    throw new Error(error)
  }
}

export async function getPaymentStatus(
  customerId: number,
  requestId: string
): Promise<boolean> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().payments.getPaymentStatus(
      identity,
      customerId,
      requestId
    )
    return response.value
  } catch (e) {
    handleErrors(e, 'paymentStatus', 'fatal')
    return false
  }
}

export function paymentUrl(bankId: string, customerId: number) {
  return `${
    getConfig().apiUrl
  }/Payments/${customerId}/InitPayment/${bankId}?amount=${
    AppStore.newInvestment
  }&clientType=${isWeb ? 'Web' : 'Mobile'}${isLocal ? '&isDebug=true' : ''}${
    AppStore.investmentMode === 'fund'
      ? `&smsConfirmationId=${AppStore.smsConfirmationId}`
      : ''
  }`
}
