import React from 'react'
import { Paragraph } from '../../ui/text/Paragraph'
import { observer } from 'mobx-react'
import SuccessMessage from '../../components/SuccessMessage'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { useTranslation } from 'react-i18next'

function ImpactToggleDoneContent() {
  const { t } = useTranslation()
  const impactOffText =
    'impact.toggle-done-impact-off'
  const impactOnText =
    'impact.toggle-done-impact-on'
  return (
    <SuccessMessage title={`${t('impact.toggle-done-title')}`}>
      <Paragraph>
        {t(QuestionStore.isImpact ? impactOnText : impactOffText)}
      </Paragraph>
    </SuccessMessage>
  )
}

export default observer(ImpactToggleDoneContent)
