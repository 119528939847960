import React from 'react'
import { Fund } from '@taaleri/core/src/models/Fund'
import UiTouchable from '../../ui/UiTouchable'
import { TextDefault } from '../../ui/text/UiText'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import { ROUTE_FUNDS_DETAIL } from './FundsRoutes'
import { isWeb } from '../../constants/Platforms'
import Box from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View, ImageBackground, StyleSheet } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../ui/FlexRow'
import CardHover from '../../ui/CardHover'
import { getFundImageSource } from '@taaleri/core/src/constants/AssetClass'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import useLayout from '../../ui/useLayout'
import FundReadMoreRow, { FundYearColumn } from './FundReadMoreRow'
import { IconChevronRight } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'
import { investmentStyleLocalization } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

export function FundCardSmall({ fund }: { fund: Fund }) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { isNonSmall } = useLayout()
  const getDescription = (): string =>
    (AppStore.currentLanguage === fiKey
      ? fund.shortDescription
      : fund.shortDescriptionSv) ?? ''

  const getName = (): string =>
    (AppStore.currentLanguage === fiKey ? fund.name : fund.nameSv) ?? ''
  return (
    <CardHover
      style={{
        flex: 1,
        display: 'flex',
        marginBottom: isWeb ? 0 : Spacings.S24,
        alignSelf: 'stretch',
      }}
    >
      <Box
        key={fund.ticker}
        style={{
          flex: 1,
          borderRadius: BorderRadius.default,
        }}
      >
        <UiTouchable
          onPress={() => {
            if (isWeb) {
              navigate(routePathApp(ROUTE_FUNDS_DETAIL + '/' + fund.ticker))
            } else {
              navigate(ROUTE_FUNDS_DETAIL, { id: fund.ticker })
            }
          }}
          style={{
            justifyContent: 'space-between',
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <View style={{ flex: 3 }}>
            <View
              style={[
                { overflow: 'hidden' },
                isNonSmall ? styles.backgroundImageLg : styles.backgroundImage,

                styles.image,
              ]}
            >
              <ImageBackground
                source={getFundImageSource(fund)}
                style={[
                  isNonSmall
                    ? styles.backgroundImageLg
                    : styles.backgroundImage,
                ]}
                imageStyle={styles.image}
                resizeMode="cover"
              />
            </View>
          </View>

          <View
            style={{
              flex: 8,
              justifyContent: isNonSmall ? 'space-between' : 'center',
            }}
          >
            <FlexRow
              style={{
                justifyContent: 'space-between',
                paddingLeft: Spacings.S16,
                paddingRight: Spacings.S8,
              }}
            >
              <View
                style={{
                  justifyContent: 'space-between',
                  paddingVertical: Spacings.S16,
                  flex: 1,
                }}
              >
                <TextDefault type="t5">
                  {investmentStyleLocalization(fund.investmentStyle, t).toUpperCase()}
                </TextDefault>
                <TextDefault type="h5" style={{ marginBottom: Spacings.S8 }}>                  
                  {getName()}
                </TextDefault>
                {isNonSmall && (
                  <TextDefault color={Colors.textSecondary}>                    
                    {getDescription()}
                  </TextDefault>
                )}
                {!isNonSmall && <FundYearColumn fund={fund} type="compact" />}
              </View>
              {!isNonSmall && (
                <View style={{ alignSelf: 'center' }}>
                  <IconChevronRight color={Colors.primary} />
                </View>
              )}
            </FlexRow>
            {isNonSmall && (
              <View
                style={{
                  borderTopColor: Colors.border,
                  borderTopWidth: 1,
                  paddingVertical: Spacings.S8,
                  height: 48,
                  justifyContent: 'center',
                }}
              >
                <View style={{ paddingHorizontal: Spacings.S16 }}>
                  <FundReadMoreRow fund={fund} isSmallCard={true} />
                </View>
              </View>
            )}
          </View>
        </UiTouchable>
      </Box>
    </CardHover>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    height: '100%',
  },
  backgroundImageLg: {
    height: '100%',
  },
  image: {
    borderTopLeftRadius: BorderRadius.default,
    borderBottomLeftRadius: BorderRadius.default,
  },
})
