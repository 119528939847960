import Colors from '@taaleri/core/src/constants/Colors'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import isFunction from 'lodash/isFunction'
import React from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import styled, { css } from 'styled-components'

import { ButtonSize, ButtonType, UiButtonProps } from './UiButtonProps'

const Button = styled.button<{
  buttontype: ButtonType
  size: ButtonSize
  backgroundColor?: string
}>`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  padding: 0 ${Spacings.S24}px;
  height: ${(props) => (props.size === 'small' ? 40 : 56)}px;

  ${(props) =>
    props.buttontype === 'primary' &&
    css`
      background: ${props.backgroundColor || Colors.primary};
      color: ${Colors.white};
      :hover {
        background-color: ${props.backgroundColor || Colors.textPrimaryHover};
        opacity: ${props.backgroundColor ? 0.7 : 1};
      }
      :active {
        background: ${props.backgroundColor || Colors.primary};
        box-shadow: inset 0px 0px 15px rgba(19, 26, 45, 0.15);
      }
    `}

  ${(props) =>
    props.buttontype === 'secondary' &&
    css`
      border: 1px solid ${Colors.text};
      background-color: transparent;
      @media (hover: hover) and (pointer: fine) {
        :hover {
          background: rgba(71, 55, 41, 0.05);
        }
        :active {
          background: rgba(71, 55, 41, 0.15);
        }
      }
    `}

    ${(props) =>
    props.buttontype === 'primaryplain' &&
    css`
      background-color: transparent;
    `}

    ${(props) =>
    props.buttontype === 'secondaryplain' &&
    css`
      background-color: transparent;
    `}

      ${(props) =>
    props.buttontype === 'secondarywhite' &&
    css`
      border: 1px solid ${Colors.white};
      background-color: transparent;
      @media (hover: hover) and (pointer: fine) {
        :hover {
          box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.15),
            0px 2px 2px rgba(0, 0, 0, 0.1);
        }
        :active {
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.12);
        }
      }
    `}
      ${(props) =>
    props.buttontype === 'disabled' &&
    css`
      background: ${Colors.gray20};
      background-color: ${Colors.gray20};
      pointer-events: none;
    `}
`

const Text = styled.div<{
  type: ButtonType
  size: ButtonSize
  textColor?: string
}>`
  font-family: ${Fonts.avenirNextBold};
  font-size: ${(props) =>
    props.size === 'small' ? FontSize.S14 : FontSize.BASE}px;
  color: ${Colors.text};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.size === 'small' ? 40 : 56)}px;
  ${(props) =>
    props.type === 'primaryplain' &&
    css`
      color: ${Colors.primary};
    `}
  ${(props) =>
    props.type === 'primary' &&
    css`
      color: ${props.textColor || Colors.white};
    `}
  ${(props) =>
    props.type === 'secondaryplain' &&
    css`
      :hover {
        color: ${Colors.textHover};
      }
    `}

  ${(props) =>
    props.type === 'secondarywhite' &&
    css`
      color: ${Colors.white};
    `}

    ${(props) =>
    props.type === 'disabled' &&
    css`
      color: ${Colors.white};
    `}
`

export default function UiTabButton(props: UiButtonProps) {
  const {
    onPress,
    title,
    containerStyle,
    loading,
    textStyle,
    disabled,
    backgroundColor,
    textColor,
    id,
  } = props
  const type: ButtonType = props.type || 'primary'
  const size: ButtonSize = props.size || 'default'

  return (
    <Button
      buttontype={type}
      type="button"
      size={size}
      backgroundColor={backgroundColor}
      style={StyleSheet.flatten(containerStyle)}
      onClick={() => {
        if (onPress && isFunction(onPress)) {
          setTimeout(() => {
            onPress()
          }, 0)
        }
      }}
      disabled={disabled}
      id={id}
    >
      {!loading && (
        <Text
          type={type}
          size={size}
          textColor={textColor}
          style={StyleSheet.flatten(textStyle)}
        >
          {title}
        </Text>
      )}

      {loading && (
        <ActivityIndicator
          animating={true}
          color={
            type === 'secondary' ||
            type === 'secondaryplain' ||
            type === 'secondarywhite'
              ? Colors.text
              : Colors.white
          }
        />
      )}
    </Button>
  )
}
