// @ts-ignore
// import { AppRegistry } from "react-native";
// import Helo from "@taaleri/components/src/Helo";

// AppRegistry.registerComponent("taaleri", () => Helo);
// AppRegistry.runApplication("taaleri", {
//   rootTag: document.getElementById("root")
// });
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import '@taaleri/core/src/i18n'

const domNode = document.getElementById('root')
const root = createRoot(domNode)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
