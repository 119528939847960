import React from 'react'
import { observer } from 'mobx-react'
import Box from '@taaleri/components/src/ui/box/Box'
import {
  formatDateWithoutLeadingZeros,
  formatCurrency,
  formatNumber,
} from '@taaleri/core/src/utils/format'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { ProfitItem } from '@taaleri/core/src/models/ProfitReport'
import { formatValueAndPrice } from './Rows/CapitalGainRow'
import { TransactionRow } from '../reporttransaction/TransactionDetailContent'
import { useTranslation } from 'react-i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

function CapitalGainTable(item: ProfitItem) {
  const { t } = useTranslation()
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.securityNameFinnish
      : item.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.portfolioNameForCustomer
      : item.portfolioNameForCustomerSv

  return (
    <>
      <TransactionRow label={t('portfolio.text')} value={getPortfolioName()} />
      <TransactionRow label={t('security')} value={getName()} />
      <TransactionRow
        label={t('report.pcs')}
        value={formatNumber(item.amount)}
      />
      <TransactionRow
        label={t('report.acquisition-period')}
        value={formatDateWithoutLeadingZeros(item.purchaseDate)}
      />
      <TransactionRow
        label={t('report.delivery-time')}
        value={formatDateWithoutLeadingZeros(item.transactionDate)}
      />
      <TransactionRow
        label={t('report.purchase-value-price')}
        value={formatValueAndPrice(item.purchaseValue, item.purchasePrice)}
      />
      <TransactionRow
        label={t('report.surrender-value-price')}
        value={formatValueAndPrice(item.sellValue, item.sellPrice)}
      />
      <TransactionRow
        label={`${t('report.profit-acquisition-cost')}`}
        value={formatCurrency(item.profitCosts)}
      />
      {item.purchaseCostAssumption ? (
        <TransactionRow
          label={`${t('report.acquisition-cost-assumption')}`}
          value={formatCurrency(item.purchaseCostAssumption)}
        />
      ) : null}
      <TransactionRow
        label={t('report.profit-loss')}
        value={formatCurrency(item.profit)}
        hideBorder={true}
      />
    </>
  )
}

function ProfitTable(item: ProfitItem) {
  const { t } = useTranslation()
  const showInterest = ['Koronmaksu', 'Jälkimarkkinahyvitys'].includes(
    item.transactionTypeName
  )
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.securityNameFinnish
      : item.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.portfolioNameForCustomer
      : item.portfolioNameForCustomerSv

  return (
    <>
      <TransactionRow label={t('portfolio.text')} value={getPortfolioName()} />
      <TransactionRow
        label={t('report.event-date')}
        value={formatDateWithoutLeadingZeros(item.transactionDate)}
      />
      <TransactionRow
        label={t('report.term')}
        value={formatDateWithoutLeadingZeros(item.paymentDate)}
      />
      <TransactionRow
        label={t('report.event')}
        value={item.transactionTypeName}
      />
      <TransactionRow label={t('security')} value={getName()} />
      <TransactionRow
        label={t('portfolio.total')}
        value={
          showInterest
            ? formatCurrency(item.accruedInterest)
            : formatCurrency(item.sellValue)
        }
      />
      <TransactionRow
        label={t('report.withholding-tax')}
        value={formatCurrency(item.advanceTax)}
      />
      <TransactionRow
        label={t('report.net')}
        value={
          showInterest
            ? formatCurrency(item.accruedInterest - item.advanceTax)
            : formatCurrency(item.totalSellValue)
        }
        hideBorder={true}
      />
    </>
  )
}

function ProfitDetailContent() {
  const item = ProfitStore.selectedProfitItem
  const category = ProfitStore.profitCategory

  if (!item) {
    return null
  }

  return (
    <Box>
      {category === 'Profit' && <ProfitTable {...item} />}
      {category === 'CapitalGain' && <CapitalGainTable {...item} />}
    </Box>
  )
}

export default observer(ProfitDetailContent)
