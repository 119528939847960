import React, { forwardRef, useImperativeHandle } from 'react'
import { Formik, FormikProps, FormikConfig } from 'formik'
/**
 * Workaround to get ref to work with formik form
 * @see https://github.com/jaredpalmer/formik/issues/1603
 */
function FormikWithRef(props: FormikConfig<any>, ref: any) {
  let formikProps: FormikProps<any>

  useImperativeHandle(ref, () => formikProps)

  return (
    <Formik {...props}>
      {(currentFormikProps) => {
        formikProps = currentFormikProps
        if (typeof props.children === 'function') {
          return props.children(formikProps)
        }
        return props.children
      }}
    </Formik>
  )
}

export default forwardRef(FormikWithRef)
