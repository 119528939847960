export class ApiError extends Error {
  codeName: string
  code: number

  constructor(message: string, code: number) {
    super(message)
    this.name = 'ApiError'
    this.codeName = 'ApiError'
    this.code = code
  }
}

export class TimeoutError extends Error {
  codeName: string
  code: number

  constructor(message: string) {
    super(message)
    this.name = 'TimeoutError'
    this.codeName = 'TimeoutError'
    this.code = 408
  }
}

export class TeapotError extends Error {
  codeName: string
  code: number

  constructor(message: string) {
    super(message)
    this.name = 'Teapot'
    this.codeName = 'Teapot'
    this.code = 418
  }
}

export class UnauthorizedError extends Error {
  codename: string
  code: number
  skipSentry?: boolean

  constructor(message: string, code: number) {
    super(message)
    this.name = 'Unauthorized'
    this.codename = 'Unauthorized'
    this.code = code
  }
}
