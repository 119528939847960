import React from 'react'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { ROUTE_PROFILE } from '../profile/ProfileRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ROUTE_IMPACT_TOGGLE_DONE } from './ImpactRoutes'
import SmsVerification from '../contract/SmsVerification'
import useNavigation from '../../navigation/useNavigation'
import { updateStrategy } from '@taaleri/core/src/services/contracts'
import { useTranslation } from 'react-i18next'

export function ImpactVerification() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const onSuccess = async () => {
    const investmentProposal = QuestionStore.investmentProposal
    AppStore.investmentProposalSaved = investmentProposal
    AppStore.impactShare = QuestionStore.impactShare
    navigate(ROUTE_IMPACT_TOGGLE_DONE)
  }
  const phone = QuestionStore.customer.phone
  return (
    <SmsVerification
      t={t}
      title={t('impact.verification')}
      description={t('impact.verification-description', { phone: phone })}
      onSuccess={() => onSuccess()}
      phone={phone}
      afterConflictNavigateTo={ROUTE_PROFILE}
      onPrepareVerification={async () => {
        if (AppStore.smsConfirmationId) {
          return AppStore.smsConfirmationId
        }
        const { success, response } = await updateStrategy()
        return success && response && response.smsConfirmationId
          ? response.smsConfirmationId
          : null
      }}
      navigate={navigate}
    />
  )
}
