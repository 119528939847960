import { getAdminRoles } from '@taaleri/core/src/services/admin'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import * as React from 'react'

interface SecureComponentProps {
  children: JSX.Element
  securityGroups: string[]
}

const SecureComponent: React.FC<SecureComponentProps> = (props) => {
  const hasRights = () => {
    let hasRights = false
    try {
      hasRights =
        AdminStore.token !== undefined &&
        AdminStore.token !== '' &&
        getAdminRoles(AdminStore.token).some((role) =>
          props.securityGroups.includes(role)
        )
    } catch (error) {
      console.log('Error in SecureComponent', error)
    }
    return hasRights
  }
  
  return <>{hasRights() && <>{props.children}</>}</>
}

export default SecureComponent
