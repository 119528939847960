import React from 'react'
import { TransactionCategory } from '@taaleri/core/src/models/TransactionReport'
import useLayout from '../../../ui/useLayout'
import { StyleSheet } from 'react-native'
import FlexRow from '../../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'

export interface TransactionCategoryItem {
  title: string
  category: TransactionCategory
}

function TransactionCategoryContainer({ children }: { children: Element[] }) {
  const { isSmall } = useLayout()
  return (
    <FlexRow style={isSmall ? styles.scrollable : { zIndex: 1 }}>
      {children}
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  scrollable: {
    overflowX: 'auto',
    marginBottom: Spacings.S24,
  },
})

export default TransactionCategoryContainer
