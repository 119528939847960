import React from 'react'
import ContentContainer from 'ui/container/ContentContainer'
import ProfileAccountRemovalContent from '@taaleri/components/src/screens/profile/ProfileAccountRemovalContent'

export default function ProfileAccountRemovalScreen() {
  return (
    <ContentContainer leadingTitle={true} noPadding={true}>
      <ProfileAccountRemovalContent />
    </ContentContainer>
  )
}
