import React from 'react'

import SuccessMessage from '../../components/SuccessMessage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import routePath, { routePathApp } from '../../navigation/routeHelper.native'
import {
  paymentStartRoute,
  getWithdrawalRoute,
} from '../portfolio/PortfolioRoutes'
import { ROUTE_PROFILE } from '../profile/ProfileRoutes'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

export default function IdentityCardSummaryContent() {
  const { t } = useTranslation()
  return <SuccessMessage title={`${t('basicInfoUpdate.thanks-title')}`} />
}

export function identityCardButtonTitle(t: TFunction) {
  return AppStore.investmentMode !== 'default'
    ? `${t('button.continue')}`
    : `${t('button.close')}`
}

export function identityCardSummaryNextScreen() {
  if (
    AppStore.investmentMode === 'fund' ||
    AppStore.investmentMode === 'digi'
  ) {
    return routePathApp(paymentStartRoute())
  }
  if (
    AppStore.investmentMode === 'withdraw' ||
    AppStore.investmentMode === 'withdrawfund'
  ) {
    return getWithdrawalRoute()
  }
  return routePath(ROUTE_PROFILE)
}
