import React, { useState, useEffect } from 'react'

import { View } from 'react-native'

import Title from '../../ui/text/Title'
import { UiButtonAppAbsolute } from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import AppStore from '@taaleri/core/src/stores/AppStore'
import AssetClassSecuritiesDistribution from '../portfolio/AssetClassSecuritiesDistribution'
import {
  paymentStartRoute,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION,
} from '../portfolio/PortfolioRoutes'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Paragraph } from '../../ui/text/Paragraph'
import { AssetClassSecurities } from '@taaleri/core/src/models/investmentProposal'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { fundProposition } from '@taaleri/core/src/services/funds'
import { Portfolio } from '@taaleri/core/src/models/Portfolio'
import { FundAction } from './FundAction'
import { FundProposition } from '@taaleri/core/src/models/FundProposition'
import { TextBold } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { ROUTE_BLOCKED_CONS_CUSTOMER } from './FundsRoutes'
import { Allocation } from '@taaleri/core/src/models/InvestmentStrategy'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { fiKey } from '@taaleri/core/src/i18n'

function riskChangeText(
  actualVolatility: number,
  simulatedVolatility: number,
  isWithDraw: boolean,
  t: TFunction
) {
  const difference = simulatedVolatility - actualVolatility
  const transactionType = isWithDraw
    ? 'app.lastupdates.withdraw'
    : 'invest.investment'

  if (difference < -0.02) {
    return t('risklevel.decreases-significantly', {
      action: t(transactionType),
    })
  }
  if (difference < -0.01) {
    return t('risklevel.decreases-somewhat', {
      action: t(transactionType),
    })
  }
  if (difference < 0) {
    return t('risklevel.decreases-little', {
      action: t(transactionType),
    })
  }
  if (difference === 0) {
    return t('risklevel.stays-same')
  }
  if (difference < 0.01) {
    return t('risklevel.raise-little', {
      action: t(transactionType),
    })
  }
  if (difference < 0.02) {
    return t('risklevel.raise-somewhat', {
      action: t(transactionType),
    })
  }
  if (difference > 0.02) {
    return t('risklevel.raise-significantly', {
      action: t(transactionType),
    })
  }
}

export function getAssetClassSecurities(
  portfolio: Portfolio
): AssetClassSecurities[] {
  if (!portfolio) {
    return []
  }

  return portfolio.assetClasses.map((a) => {
    return {
      id: a.id,
      name: a.name,
      percentage: a.percentageOfTotal,
      securities: a.items.map((i) => {
        const getSecurityName = (): string =>
          AppStore.currentLanguage === fiKey ? i.nameFi : i.nameSv
        return {
          securityId: i.ticker,
          name: getSecurityName(),
          percentage: i.sharePercentage,
          allocation: {} as Allocation,
          sustainableDevelopmentGoals: [],
        }
      }),
    }
  })
}

export default function FundInvestDistributionContent({
  action,
}: {
  action: FundAction
}) {
  const { t } = useTranslation()
  const isWithdraw = action === 'Sell'
  const [riskLevel, setRiskLevel] = useState<FundProposition | undefined>()
  const [assetClassSecurities, setAssetClassSecurities] =
    useState<AssetClassSecurities[]>()

  const { navigateApp } = useNavigation()

  useEffect(() => {
    async function fetchAll() {
      AppStore.loadingFund = true
      const result = await fundProposition(action)

      if (result.success && result.response) {
        setRiskLevel(result.response)
        const securities = await getAssetClassSecurities(
          result.response.portfolio
        )
        setAssetClassSecurities(securities)
      }
      AppStore.loadingFund = false
    }
    fetchAll()
  }, [])

  useEffect(() => {
    async function updateIsBlocked() {
      await AppStore.updateIsBlocked()
    }
    updateIsBlocked()
  }, [])

  if (!assetClassSecurities || !riskLevel) {
    return <LoadingIndicator />
  }

  // If customer is blocked, redirect to blocked customer screen
  if (AppStore.isCustomerBlocked) {
    navigateApp(ROUTE_BLOCKED_CONS_CUSTOMER)
  }

  const selectedAssetClass = assetClassSecurities.find((assetClass) => {
    return assetClass.securities.find((s) => {
      return s.securityId === (AppStore.fundId || 'Dummy')
    })
  })
  const openClassId = selectedAssetClass ? selectedAssetClass.id : 'A'

  const isPortfolioEmpty = assetClassSecurities.length === 0

  return (
    <View>
      <Title>
        {t('invest.portfolio-after-one-time', {
          empty: isPortfolioEmpty ? ` ${t('invest.is-empty')}` : '',
          action: isWithdraw
            ? t('invest.withdraw-action')
            : t('invest.investment-action'),
        })}
      </Title>
      {isPortfolioEmpty ? null : (
        <BoxWithPadding
          style={{ marginBottom: Spacings.S24 }}
          title={`${t('portfolio.distribution')}`}
          titleStyle={{ marginBottom: Spacings.S24 }}
        >
          <AssetClassSecuritiesDistribution
            assetClassSecurities={assetClassSecurities}
            openClassId={openClassId}
            selectedSecurityId={AppStore.fundId}
          />
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: Colors.border,
              marginBottom: Spacings.S24,
            }}
          />
          <TextBold style={{ marginBottom: Spacings.S16 }}>
            {t('app.calculator.risklevel')}
          </TextBold>
          <Paragraph>
            {riskChangeText(
              riskLevel.actualVolatility,
              riskLevel.simulatedVolatility,
              isWithdraw,
              t
            )}
          </Paragraph>
        </BoxWithPadding>
      )}
    </View>
  )
}

export function FundInvestDistrubutionNextButton() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <UiButtonAppAbsolute
      title={t('button.continue')}
      onPress={() => {
        AppStore.investmentMode = 'fund'
        AppStore.paymentType = 'onetime'
        navigate(routePathApp(paymentStartRoute()))
      }}
    />
  )
}

export function FundInvestDistrubutionWithdrawNextButton() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <UiButtonAppAbsolute
      title={t('button.continue')}
      onPress={() => {
        navigate(routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION))
      }}
    />
  )
}
