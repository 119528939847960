import React from 'react'
import { PaddingHorizontal } from '@taaleri/components/src/ui/PaddingHorizontal'
import SavingsPlanContent from '@taaleri/components/src/screens/SavingsPlan/SavingsPlanContent'
import { TitleLarge } from '@taaleri/components/src/ui/text/Title'
import { GradientBackground } from 'ui/GradientContainer'
import { Content } from 'screens/app/Content'
import { useTranslation } from 'react-i18next'

export function SavingsPlanScreen() {
  const { t } = useTranslation()
  return (
    <GradientBackground>
      <Content marginTop={true}>
        <PaddingHorizontal>
          <TitleLarge>{t('reportBox.monthly-savings')}</TitleLarge>
          <SavingsPlanContent />
        </PaddingHorizontal>
      </Content>
    </GradientBackground>
  )
}
