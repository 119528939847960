import { useState, useEffect } from 'react'
import { getTransactionToken } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'

export function usePdfToken() {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [token, setToken] = useState<string>()

  useEffect(() => {
    loadToken()
  }, [retry])

  async function loadToken() {
    setRetry(false)
    setLoading(true)
    setError(false)
    const { success, response } = await getTransactionToken(AppStore.customerId)

    if (success && response) {
      setToken(response)
      setError(false)
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }
  return { error, loading, token, setRetry }
}
