import { useState, useEffect } from 'react'

import { PreliminaryProposal } from '@taaleri/core/src/models/investmentProposal'

import { customers } from '@taaleri/core/src/services/customers'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import AppStore from '@taaleri/core/src/stores/AppStore'

export default function usePreliminaryProposal() {
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [preliminaryProposal, setPreliminaryProposal] =
    useState<PreliminaryProposal | undefined>()

  useEffect(() => {
    const getPreliminaryProposal = async () => {
      setError(false)
      setRetry(false)
      try {
        const proposal = await customers.getPreliminaryProposal()
        QuestionStore.riskLevel = proposal.riskLevel
        setPreliminaryProposal(proposal)
        AppStore.contentLoaded = true
      } catch (error) {
        setError(true)
      }
    }
    getPreliminaryProposal()
  }, [retry])
  return { preliminaryProposal, error, setRetry }
}
