import { toTwoFactor } from '@taaleri/core/src/api/normalization/toTwoFactor'

import { toCustomer } from './toCustomer'
import { toIdentity } from './toIdentity'
import { Login, LoginResponse } from '../../models/Identity'

export function toLogin(loginResponse: LoginResponse): Login {
  return {
    identity: toIdentity(loginResponse),
    // @ts-ignore
    customer: !loginResponse.customer
      ? {
          customerId: loginResponse.customerId,
          accountType: 'Person',
          isDigiCustomer: true,
          isCustomer: false,
          customerAuthorizations: [],
        }
      : toCustomer(
          loginResponse.customer,
          loginResponse.customerAuthorizations
        ),
    twoFactor: toTwoFactor(
      loginResponse.isTwoFactor,
      loginResponse.twoFactorId
    ),
    isOnboardingInProgress: loginResponse.isOnboardingInProgress,
    lastUsedLanguage: loginResponse.lastUsedLanguage ?? '',
  }
}
