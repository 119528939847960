import React from 'react'
import { observer } from 'mobx-react'
import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'
import ImpactFactsCard from '../impact/ImpactFactsCard'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { portfolioTypeName } from '@taaleri/core/src/models/PortfolioType'
import { co2OffsetImpact } from './impactCalculator'
import { toFinanceChartProps } from './investmentproposal/toFinanceChartProps'
import { selectedPortfolioDistribution } from './investmentproposal/selectedPortfolioDistrubution'
import { useTranslation } from 'react-i18next'

interface Props {
  investmentProposal: InvestmentProposal
  currentScreen?: string
}

function PortfolioTypeBox({ investmentProposal, currentScreen }: Props) {
  const { t } = useTranslation()
  const isImpact = QuestionStore.isImpact
  const portfolioDistribution = selectedPortfolioDistribution(
    investmentProposal,
    QuestionStore.riskLevel
  )
  const impactPercentage = 100 * portfolioDistribution.impactShare
  const {
    initialInvestment,
    monthlyInvestment,
    age,
    investmentTime,
    endAge,
    riskLevel,
  } = QuestionStore

  const { revenueTimeseries } = toFinanceChartProps(
    investmentProposal,
    initialInvestment,
    monthlyInvestment,
    age,
    investmentTime,
    endAge,
    riskLevel
  )
  const co2Compensation = co2OffsetImpact(revenueTimeseries, impactPercentage)
  const sdgCount = isImpact ? 6 : 0
  const portfolioType = isImpact ? 'impact' : 'etf'
  const title = t(portfolioTypeName(portfolioType))
  return (
    <ImpactFactsCard
      {...{
        title,
        portfolioDistribution,
        sdgCount,
        isOnboarding: true,
        co2Compensation,
        isImpact,
        currentScreen,
        mode: 'return',
      }}
    />
  )
}

export default observer(PortfolioTypeBox)
