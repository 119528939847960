import { Identity } from '../models/Identity'
import { api } from '../api/api'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { ServiceResponse } from '../models/ServiceResponse'
import { SavingsPlan } from '../models/SavingsPlan'

export async function getSavingsPlan(
  customerId: number
): Promise<ServiceResponse<SavingsPlan>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().savingsPlan.getSavingsPlan(
      identity,
      customerId
    )
    return { success: true, response }
  } catch (error) {
    handleErrors(error, 'getSavingsPlan')
    return { success: false, error }
  }
}

export async function deleteSavingsPlan(
  customerId: number
): Promise<ServiceResponse<SavingsPlan>> {
  try {
    const identity: Identity = await getIdentity()
    await api().savingsPlan.deleteSavingsPlan(identity, customerId)
    return { success: true }
  } catch (error) {
    handleErrors(error, 'deleteSavingsPlan')
    return { success: false, error }
  }
}

export async function createSavingsPlan(
  customerId: number,
  targetAmount: number
): Promise<ServiceResponse<SavingsPlan>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().savingsPlan.createSavingsPlan(
      identity,
      customerId,
      targetAmount
    )
    return { response, success: true }
  } catch (error) {
    handleErrors(error, 'createSavingsPlan')
    return { success: false, error }
  }
}
