import {
  CONFLICT,
  NETWORK,
  TIMEOUT,
  UNEXPECTED,
  ABORTERROR,
  TEAPOT,
  NOTFOUND,
} from '../models/ServiceResponse'
import {
  ApiError,
  TimeoutError,
  UnauthorizedError,
  TeapotError,
} from '../errors'
import logger, { LoggerLevel } from '../utils/logger'

interface ErrorType {
  [key: number]: 'UNEXPECTED' | 'CONFLICT' | 'NETWORK' | 'TIMEOUT' | 'TEAPOT'
}

const errorTypes: ErrorType = {
  401: CONFLICT,
  403: UNEXPECTED,
  404: UNEXPECTED,
  408: TIMEOUT,
  409: CONFLICT,
  418: TEAPOT,
  500: UNEXPECTED,
}

export const handleErrors = (
  error: ApiError | Error,
  title: string,
  level: LoggerLevel = 'error',
  ignoreUnauthorized: boolean = false,
  shouldCheckIfNotFound: boolean = false,
):
  | 'UNEXPECTED'
  | 'CONFLICT'
  | 'NETWORK'
  | 'TIMEOUT'
  | 'ABORTERROR'
  | 'TEAPOT'
  | 'NOTFOUND' => {
  if (error.name === 'AbortError') {
    // No reason to send abort errors to sentry
    return ABORTERROR
  }
  if (error instanceof ApiError) {
    const errorType = errorTypes[error.code] || null

    if (!errorType || errorType === UNEXPECTED) {
      logger.error(error, title, level)
    }

    if (error.code === 404 && shouldCheckIfNotFound) {
      return NOTFOUND
    }

    return errorType ? errorType : UNEXPECTED
  } else if (error instanceof TimeoutError) {
    logger.error(error, title, level)
    return UNEXPECTED
  } else if (error instanceof UnauthorizedError) {
    if (!ignoreUnauthorized && !error?.skipSentry) {
      logger.error(error, title, level)
    }
    return CONFLICT
  } else if (error instanceof TeapotError) {
    logger.error(error, title, level)
    return TEAPOT
  } else {
    logger.error(error, title, level)
    return NETWORK
  }
}
