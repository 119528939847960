import versionNumber from '@taaleri/components/src/screens/profile/versionNumber'
import AppStore from '@taaleri/core/src/stores/AppStore'

import ICustomerApi from './ICustomerApi'
import { BasicInfoProductFamiliarity } from './normalization/toAnswers'
import { toCustomer } from './normalization/toCustomer'
import { toCustomerBasicInfo } from './normalization/toCustomerBasicInfo'
import {
  toInvestmentProposal,
  toInvestmentProposalFromSaved,
  toPreliminaryProposal,
  toPortfolioDistribution,
} from './normalization/toInvestmentProposal'
import { get, post, unauthenticatedGet, unauthenticatedPost } from './rest'
import { getConfig } from '../config'
import { BasicContract, BasicContractResponse } from '../models/Contract'
import {
  BasicInfoOnboarding,
  Customer,
  CustomerBasicInfo,
} from '../models/Customer'
import { FeedbackResponse } from '../models/FeedbackResponse'
import { Identity } from '../models/Identity'
import { IdVerificationData } from '../models/IdentityCard'
import { InvestmentStrategy } from '../models/InvestmentStrategy'
import { ValidationResponse, ValidationResult } from '../models/Validation'
import {
  InvestmentProposal,
  PreliminaryProposal,
  PortfolioDistributions,
} from '../models/investmentProposal'
import { getOnboardingProcessIdentity } from '../services/storage'
import logger from '../utils/logger'

async function getCustomer(
  identity: Identity,
  customerId: number
): Promise<Customer> {
  const { accessToken } = identity
  return get(
    `${getConfig().apiUrl}/Customers/account/${customerId}?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  ).then(toCustomer)
}

async function getTupasCustomer(identity: Identity): Promise<Customer> {
  const { accessToken } = identity
  const processId = await getOnboardingProcessIdentity()
  const response = await get(
    `${getConfig().apiUrl}/Customers/${processId}`,
    accessToken
  )
  const basicInfo = toCustomerBasicInfo(response)
  return basicInfo.customer
}

async function getCustomerBasicInfo(
  identity: Identity,
  customerId: number
): Promise<CustomerBasicInfo> {
  const { accessToken } = identity
  return get(
    `${getConfig().apiUrl}/Customers/V2/basicInfo/${customerId}`,
    accessToken
  ).then(toCustomerBasicInfo)
}

async function getCustomerBasicInfoWithToken(
  token: string,
  customerId: number
): Promise<CustomerBasicInfo> {
  return get(
    `${getConfig().apiUrl}/Customers/V2/basicInfo/${customerId}`,
    token
  ).then(toCustomerBasicInfo)
}

async function getCustomerForcedKnowledge(
  identity: Identity,
  customerId: number
): Promise<BasicInfoProductFamiliarity> {
  const { accessToken } = identity
  return get(
    `${getConfig().apiUrl}/Customers/basicInfo/${customerId}/forced`,
    accessToken
  )
}

async function getInvestmentProposal(
  customerId: number,
  identity: Identity,
  basicInfoOnboarding: BasicInfoOnboarding
): Promise<InvestmentProposal> {
  const { accessToken } = identity
  logger.devInfo('basicInfoOnboarding', basicInfoOnboarding)
  logger.devInfo('identity', identity)
  const res = await post(
    `${getConfig().apiUrl}/InvestmentStrategies/${customerId}`,
    accessToken,
    {
      amount: basicInfoOnboarding.initialInvestment,
      monthlySavingAmount: basicInfoOnboarding.monthlyInvestment,
      investmentHorizonYears: 0,
      basicInfo: basicInfoOnboarding,
      withImpact: basicInfoOnboarding.withImpact,
      language: AppStore.currentLanguage,
    }
  )

  return toInvestmentProposal(res)
}

async function getImpactStrategy(
  customerId: number,
  identity: Identity
): Promise<PortfolioDistributions> {
  const { accessToken } = identity
  const res = await get(
    `${getConfig().apiUrl}/InvestmentStrategies/impact/${customerId}?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  )

  return toPortfolioDistribution(res)
}

async function getStrategyByRiskLevel(
  identity: Identity,
  isImpact: boolean,
  riskLevel: number
): Promise<InvestmentStrategy> {
  const { accessToken } = identity
  const res = await get(
    `${getConfig().apiUrl}/InvestmentStrategies/${
      isImpact ? 'impact' : 'etf'
    }/risklevel/${riskLevel}`,
    accessToken
  )

  return res
}

async function getInvestmentProposalSaved(
  customerId: number,
  identity: Identity
): Promise<InvestmentProposal> {
  const { accessToken } = identity
  logger.devInfo('identity', identity)
  const res = await get(
    `${
      getConfig().apiUrl
    }/InvestmentStrategies/${customerId}/currentstrategy?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  )

  return toInvestmentProposalFromSaved(res)
}

async function getInvestmentProposalOnboarding(
  basicInfoOnboarding: BasicInfoOnboarding
): Promise<InvestmentProposal> {
  logger.devInfo('basicInfoOnboarding', basicInfoOnboarding)
  const res = await unauthenticatedPost(
    `${getConfig().apiUrl}/InvestmentStrategies`,
    {
      amount: basicInfoOnboarding.initialInvestment,
      monthlySavingAmount: basicInfoOnboarding.monthlyInvestment,
      investmentHorizonYears: 0,
      basicInfo: basicInfoOnboarding,
      withImpact: basicInfoOnboarding.withImpact,
    }
  )
  logger.devInfo('res', res)
  return toInvestmentProposal(res)
}

async function getFirstProposal(
  basicInfoOnboarding: BasicInfoOnboarding,
  age: number,
  withImpact: boolean
): Promise<InvestmentProposal> {
  logger.devInfo('getFirstProposal', basicInfoOnboarding)
  const res = await unauthenticatedPost(
    `${getConfig().apiUrl}/InvestmentStrategies/simple`,
    {
      age,
      investmentGoal: basicInfoOnboarding.investmentGoal,
      withImpact,
      language: AppStore.currentLanguage,
    }
  )

  return toInvestmentProposal(res)
}

async function getPreliminaryProposal(
  basicInfoOnboarding: BasicInfoOnboarding,
  age: number
): Promise<PreliminaryProposal> {
  logger.devInfo(
    'getPreliminaryProposal',
    `age: ${age} investmentGoal: ${basicInfoOnboarding.investmentGoal}`
  )
  const res = await unauthenticatedGet(
    `${
      getConfig().apiUrl
    }/InvestmentStrategies/preliminary?age=${age}&investmentGoal=${
      basicInfoOnboarding.investmentGoal
    }`
  )

  return toPreliminaryProposal(res)
}

async function pollContract(identity: Identity): Promise<boolean> {
  const { accessToken } = identity
  const processId = await getOnboardingProcessIdentity()

  const response = await get(
    `${getConfig().apiUrl}/Customers/contractpreview/${processId}/poll`,
    accessToken
  )
  logger.devInfo('pollContract response', response)

  return !!response.isSuccess
}

async function pollCustomer(
  identity: Identity,
  customerId: number
): Promise<Customer | null> {
  const { accessToken } = identity

  /*
  VR 17.12.2020: requireCustomer flags makes it sure a customer with digi portfolio is returned.
  */

  const response = await get(
    `${
      getConfig().apiUrl
    }/Customers/account/${customerId}?requireCustomer=true`,
    accessToken
  )
  logger.devInfo('pollCustomer response', response)

  return response.basicContractSigned ? toCustomer(response) : null
}

async function validateIban(
  identity: Identity,
  iban: string
): Promise<ValidationResult> {
  const { accessToken } = identity

  const response = (await post(
    `${getConfig().apiUrl}/Validation/AccountNumber`,
    accessToken,
    {
      value: iban,
    }
  )) as ValidationResponse

  return {
    isSuccess: response.result,
    formattedValue: response.formattedValue,
  }
}

async function updateStrategy(
  identity: Identity,
  allowIlliquid: boolean,
  strategyIdentifier: string
): Promise<BasicContractResponse> {
  const { accessToken, customerId } = identity

  const request = {
    allowIlliquid,
    strategyIdentifier,
  }

  const response = await post(
    `${getConfig().apiUrl}/StrategyUpdate/${customerId}`,
    accessToken,
    request
  )

  return response
}

function toBasicInfoRequest(basicContract: BasicContract) {
  const { maxImpact, discountCode } = basicContract
  const phoneNumber = basicContract.customer.phoneNumber
  return {
    strategyIdentifier: basicContract.strategyIdentifier,
    basicInfo: basicContract,
    maxImpact,
    phoneNumber,
    discountCode,
  }
}

async function basicInfoPost(
  identity: Identity,
  basicContract: BasicContract
): Promise<BasicContractResponse> {
  const { accessToken } = identity
  const { customer, riskLevel } = basicContract

  let url = `${getConfig().apiUrl}/Customers/V2/basicInfo/${
    customer.customerId
  }${riskLevel ? '/digi' : ''}`

  if (AppStore.isConsultativeWithoutContract) {
    url = `${getConfig().apiUrl}/Customers/V2/consultative/${
      customer.customerId
    }`
  }

  const response = await post(
    url,
    accessToken,
    toBasicInfoRequest(basicContract)
  )

  return {
    smsConfirmationId: response.smsConfirmationId
      ? response.smsConfirmationId
      : null,
    assetContractId: response.assetContractId,
  }
}

async function createContract(
  identity: Identity,
  basicContract: BasicContract
): Promise<BasicContractResponse> {
  const { accessToken } = identity
  const processId = await getOnboardingProcessIdentity()

  const response = await post(
    `${getConfig().apiUrl}/Customers/V2/onboarding/${processId}`,
    accessToken,
    toBasicInfoRequest(basicContract)
  )

  return {
    smsConfirmationId: response.smsConfirmationId,
    assetContractId: response.assetContractId,
    basicInfoContractId: response.basicInfoContractId,
  }
}

async function saveIdentityCard(
  identity: Identity,
  customerId: number,
  customerName: string,
  idData: IdVerificationData,
  photoIdBase64: string
): Promise<boolean> {
  const { accessToken } = identity
  const body = {
    customerName,
    base64Image: photoIdBase64,
    expirationDate: `${idData.year}-${idData.month}-${idData.day}T00:00:00`,
    identityMethodIdentificationNumber: idData.identityNumber,
    identityMethodIssuer: idData.identityIssuer,
  }

  const response = await post(
    `${getConfig().apiUrl}/document/identificationdocument/${customerId}`,
    accessToken,
    body
  )

  return !!response.isSuccess
}

async function getContractDocument(
  identity: Identity,
  contractId: string
): Promise<string> {
  const { accessToken } = identity

  const response = await get(
    `${getConfig().apiUrl}/document/contract/${contractId}`,
    accessToken
  )

  return response.value
}

async function postCustomerView(
  identity: Identity,
  customerId: number
): Promise<boolean> {
  const { accessToken } = identity
  await post(`${getConfig().apiUrl}/CustomerViews/${customerId}`, accessToken)
  return true
}

async function getMultipleContracts(
  identity: Identity,
  contractIds: string[]
): Promise<string> {
  const { accessToken } = identity
  let parameters = ``
  contractIds.forEach((Id) => {
    if (parameters.length === 0) {
      parameters += `?contractIds=${Id}`
    } else {
      parameters += `&contractIds=${Id}`
    }
  })

  const response = await get(
    `${getConfig().apiUrl}/document/contracts${parameters}`,
    accessToken
  )

  return response.value
}

async function getOnboardingState(identity: Identity): Promise<any> {
  const response = await get(
    `${getConfig().apiUrl}/Customers/processState`,
    identity.accessToken
  )
  return response
}

async function sendFeedback(
  identity: Identity,
  question: string,
  message: string,
  customerId: number,
  skipEmail: boolean
): Promise<FeedbackResponse> {
  const { accessToken } = identity
  const body = {
    customerId,
    source: `${versionNumber}`,
    question,
    message,
    skipEmail,
    createdOn: new Date().toISOString(),
  }

  const response = await post(
    `${getConfig().apiUrl}/Feedback`,
    accessToken,
    body
  )

  return response
}

async function saveLastUsedLanguage(
  identity: Identity,
  customerId: string,
  language: string
): Promise<boolean> {
  const { accessToken } = identity
  const body = {
    customerId,
    language,
  }

  return await post(
    `${getConfig().apiUrl}/Customers/updateLanguage`,
    accessToken,
    body
  )
}

export const customerApi: ICustomerApi = {
  updateStrategy,
  basicInfoPost,
  getContractDocument,
  getMultipleContracts,
  getCustomer,
  getCustomerBasicInfo,
  getCustomerBasicInfoWithToken,
  getCustomerForcedKnowledge,
  getTupasCustomer,
  createContract,
  pollContract,
  pollCustomer,
  getInvestmentProposal,
  getImpactStrategy,
  getInvestmentProposalSaved,
  getInvestmentProposalOnboarding,
  getFirstProposal,
  getPreliminaryProposal,
  saveIdentityCard,
  validateIban,
  getOnboardingState,
  sendFeedback,
  getStrategyByRiskLevel,
  postCustomerView,
  saveLastUsedLanguage,
}
