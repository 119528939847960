import React from 'react'
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { TextDefault } from '../ui/text/UiText'
import FlexRow from '../ui/FlexRow'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isWeb } from '../constants/Platforms'
import { nonBreakingSpaces } from '@taaleri/core/src/utils/format'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import {
  IconCheckCircle,
  IconCircle,
  IconExclamationCircle,
} from '../ui/icons/CircleIcons'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

type StepIcon = 'check' | 'circle-gray' | 'circle-primary' | 'circle-warning'

function Step({
  title,
  icon,
  hideTitle,
}: {
  title: string
  icon?: StepIcon
  hideTitle?: boolean
}) {
  return (
    <View>
      {icon === 'circle-warning' && (
        <IconExclamationCircle color={Colors.warning} style={styles.icon} />
      )}
      {icon === 'check' && <IconCheckCircle style={styles.icon} />}
      {icon === 'circle-gray' && <IconCircle style={styles.icon} />}
      {icon === 'circle-primary' && (
        <IconCircle style={styles.icon} color={Colors.primary} />
      )}
      {hideTitle || (
        <TextDefault type="t5" style={styles.title}>
          {title.toUpperCase()}
        </TextDefault>
      )}
    </View>
  )
}

function IndicatorLine() {
  return (
    <View
      style={{
        height: 1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
        width: '100%',
        backgroundColor: Colors.border,
        flex: 1,
      }}
    />
  )
}

export function PaymentSteps({
  currentStep,
  style,
}: {
  currentStep: number
  style?: StyleProp<ViewStyle>
}) {
  const steps = ['steps.way', 'steps.sum', 'steps.payment']  
  return <Steps steps={steps} currentStep={currentStep} style={style} />
}

export function PaymentStepsFund({
  currentStep,
  recurring,
}: {
  currentStep: number
  recurring?: boolean
}) {
  const steps = recurring
    ? ['steps.sum', 'steps.due-date', 'steps.confirmation', 'steps.payment']
    : ['steps.sum', 'portfolio.text', 'steps.payment']
  return <Steps steps={steps} currentStep={currentStep} />
}

export function ImpactToggleSteps({ currentStep }: { currentStep: number }) {
  const steps = ['portfolio.text', 'steps.confirmation', 'steps.finalize']
  return <Steps steps={steps} currentStep={currentStep} />
}

export function NewCustomerSteps(props: {
  currentStep: number
  style?: StyleProp<ViewStyle>
  hideTitle?: boolean
  warning?: boolean  
}) {  
  const { t } = useTranslation()
  const steps = [
    nonBreakingSpaces(t('steps.customer-creation')),
    'steps.invest-plan',
    'steps.first-investment',
  ]
  return <Steps steps={steps} {...props} />
}

interface StepsProps {
  steps: string[]
  currentStep: number
  style?: StyleProp<ViewStyle>
  hideTitle?: boolean
  warning?: boolean
}

export default function Steps({
  steps,
  currentStep,
  style,
  hideTitle,
  warning,
}: StepsProps) {
  const { t } = useTranslation()
  return (
    <FlexRow
      style={[
        {
          backgroundColor: Colors.white,
          justifyContent: 'center',
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
          paddingBottom: 11,
        },
        !isWeb && { borderTopWidth: 1, borderTopColor: Colors.border },
        style,
      ]}
    >
      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          maxWidth: 640,
          paddingVertical: 24,
          paddingHorizontal: 24,
        }}
      >
        {steps.map((step, index) => {
          const icon: StepIcon =
            index === currentStep
              ? warning
                ? 'circle-warning'
                : 'circle-primary'
              : index > currentStep
              ? 'circle-gray'
              : 'check'
          return (
            <React.Fragment key={step}>
              <Step title={t(step)} icon={icon} hideTitle={hideTitle} />
              {index < steps.length - 1 && <IndicatorLine />}
            </React.Fragment>
          )
        })}
      </FlexRow>
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  title: {
    position: 'absolute',
    bottom: -Spacings.S16,
    left: -20,
    right: -20,
    textAlign: 'center',
    flexWrap: 'nowrap',
    fontSize: FontSize.S8,
    overflow: 'visible',
    flex: 1,
    color: Colors.text,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    justifyContent: 'center',
  },
})
