import { OptionType } from '../models/OptionType'

export enum CurrencyRange {
  LessThan25000 = 'LessThan25000',
  LessThan50000 = 'LessThan50000',
  LessThan100000 = 'LessThan100000',
  LessThan200000 = 'LessThan200000',
  LessThan500000 = 'LessThan500000',
  LessThanOneMillion = 'LessThan1000000',
  LessThanFiveMillion = 'LessThan5000000',
  MoreThanFiveMillion = 'MoreThan5000000',
}

export enum MoneyRange {
  None = 'None',
  Between1And1000Eur = 'Between1And1000Eur',
  Between1001And5000Eur = 'Between1001And5000Eur',
  Between5001And10000Eur = 'Between5001And10000Eur',
  Between10001And50000Eur = 'Between10001And50000Eur',
  Between50001And100000Eur = 'Between50001And100000Eur',
  Over100000Eur = 'Over100000Eur',
}

export enum MoneyRangeWithoutNone {
  Between0And1000Eur = 'Between0And1000Eur',
  Between1001And5000Eur = 'Between1001And5000Eur',
  Between5001And10000Eur = 'Between5001And10000Eur',
  Between10001And50000Eur = 'Between10001And50000Eur',
  Between50001And100000Eur = 'Between50001And100000Eur',
  Over100000Eur = 'Over100000Eur',
}

export enum AssetSource {
  earnedIncome = 'sourceOfFundsIncome',
  acquisition = 'sourceOfFundsExit',
  inheritance = 'sourceOfFundsInheritance',
  investing = 'sourceOfFundsInvesting',
  gift = 'sourceOfFundsGift',
  other = 'sourceOfFundsOther',
  property = 'sourceOfFundsRealEstate',
  pension = 'sourceOfFundsPension',
  benefits = 'sourceOfFundsBenefits',
}

export const ASSET_SOURCES: OptionType[] = [
  { label: 'questions.sourceOption.earned-income', value: AssetSource.earnedIncome },
  { label: 'questions.sourceOption.property', value: AssetSource.property },
  { label: 'questions.sourceOption.pension', value: AssetSource.pension },
  { label: 'questions.sourceOption.benefits', value: AssetSource.benefits },
  { label: 'questions.sourceOption.gift', value: AssetSource.gift },
  { label: 'questions.sourceOption.inheritance', value: AssetSource.inheritance },
  { label: 'questions.sourceOption.investing', value: AssetSource.investing },
  { label: 'questions.sourceOption.acquisition', value: AssetSource.acquisition },
  { label: 'questions.foreignMoneyTransfer.other', value: AssetSource.other },
]

export enum AccountPurpose {
  Daily = 'DailyBusiness',
  Financial = 'FinancialServices',
  Foreign = 'ForeignPayments',
  Insurance = 'InsuranceServices',
  Deposits = 'Deposits',
  Investing = 'InvestmentServices',
  Other = 'Other',
}

export const PURPOSE_OPTIONS: OptionType[] = [
  { label: 'questions.purposeOptions.daily', value: AccountPurpose.Daily },
  { label: 'questions.purposeOptions.financial', value: AccountPurpose.Financial },
  { label: 'questions.purposeOptions.foreign', value: AccountPurpose.Foreign },
  { label: 'questions.purposeOptions.insurance', value: AccountPurpose.Insurance },
  { label: 'questions.purposeOptions.deposits', value: AccountPurpose.Deposits },
  { label: 'questions.purposeOptions.investing', value: AccountPurpose.Investing },
  { label: 'questions.foreignMoneyTransfer.other', value: AccountPurpose.Other },
]

export enum EducationOption {
  ComprehensiveSchool = 'ComprehensiveSchool', // Peruskoulu
  VocationalSchool = 'VocationalSchool', // Ammattikoulu
  UpperSecondarySchool = 'UpperSecondarySchool', // Lukio
  UniversityOfAppliedSciences = 'UniversityOfAppliedSciences', // Ammattikorkeakoulu
  LowerUniversityDegree = 'LowerUniversityDegree', // Alempi korkeakoulututkinto
  HigherUniversityDegree = 'HigherUniversityDegree', // Ylempi korkeakoulututkinto
  Other = 'Other', // Muu
}

export const EDUCATION_OPTIONS: OptionType[] = [
  { label: 'form.education.comprehensive', value: EducationOption.ComprehensiveSchool },
  { label: 'form.education.vocational', value: EducationOption.VocationalSchool },
  { label: 'form.education.upper-secondary', value: EducationOption.UpperSecondarySchool },
  {
    label: 'form.education.university-applied-science',
    value: EducationOption.UniversityOfAppliedSciences,
  },
  {
    label: 'form.education.lower-university',
    value: EducationOption.LowerUniversityDegree,
  },
  {
    label: 'form.education.higher-university',
    value: EducationOption.HigherUniversityDegree,
  },
  { label: 'form.education.other', value: EducationOption.Other },
]

export enum WorkStatus {
  Employee = 'Employee',
  Official = 'Official',
  SeniorOfficial = 'SeniorOfficial',
  Entrepreneur = 'Entrepreneur',
  Unemployed = 'Unemployed',
  LongTermUnemployed = 'LongTermUnemployed',
  Retired = 'Pensioner',
  Student = 'Student',
  UnderAge = 'UnderagePerson',
}

export const WORK_STATUS_OPTIONS: OptionType[] = [
  { label: 'form.sosioeconomicStatus.employee', value: WorkStatus.Employee },
  { label: 'form.sosioeconomicStatus.official', value: WorkStatus.Official },
  { label: 'form.sosioeconomicStatus.senior-official', value: WorkStatus.SeniorOfficial },
  { label: 'form.sosioeconomicStatus.entrepreneur', value: WorkStatus.Entrepreneur },
  { label: 'form.sosioeconomicStatus.unemployed', value: WorkStatus.Unemployed },
  { label: 'form.sosioeconomicStatus.long-term-unemployed', value: WorkStatus.LongTermUnemployed },
  { label: 'form.sosioeconomicStatus.retired', value: WorkStatus.Retired },
  { label: 'form.sosioeconomicStatus.student', value: WorkStatus.Student },
  { label: 'form.sosioeconomicStatus.under-age', value: WorkStatus.UnderAge },
]

export const DEFAULT_FINANCE_OPTIONS: OptionType[] = [
  { label: 'questions.financeOptions.under-25k', value: CurrencyRange.LessThan25000 },
  { label: 'questions.financeOptions.between-25k-50k', value: CurrencyRange.LessThan50000 },
  { label: 'questions.financeOptions.between-50k-100k', value: CurrencyRange.LessThan100000 },
  { label: 'questions.financeOptions.between-100k-200k', value: CurrencyRange.LessThan200000 },
  { label: 'questions.financeOptions.between-200k-500k', value: CurrencyRange.LessThan500000 },
  { label: 'questions.financeOptions.between-500k-1M', value: CurrencyRange.LessThanOneMillion },
  { label: 'questions.financeOptions.over-1M', value: CurrencyRange.LessThanFiveMillion },
  { label: 'questions.financeOptions.over-5M', value: CurrencyRange.MoreThanFiveMillion },
]

export const DEBT_OPTIONS: OptionType[] = [
  { label: 'questions.debtOptions.none', value: 'None' },
  { label: 'questions.financeOptions.under-25k', value: CurrencyRange.LessThan25000 },
  { label: 'questions.financeOptions.between-25k-50k', value: CurrencyRange.LessThan50000 },
  { label: 'questions.financeOptions.between-50k-100k', value: CurrencyRange.LessThan100000 },
  { label: 'questions.financeOptions.between-100k-200k', value: CurrencyRange.LessThan200000 },
  { label: 'questions.financeOptions.between-200k-500k', value: CurrencyRange.LessThan500000 },
  { label: 'questions.financeOptions.between-500k-1M', value: CurrencyRange.LessThanOneMillion },
  { label: 'questions.financeOptions.over-1M', value: CurrencyRange.LessThanFiveMillion },
  { label: 'questions.financeOptions.over-5M', value: CurrencyRange.MoreThanFiveMillion },
]

export enum CapitalOption {
  None = 'None',
  LessThan10000 = 'LessThan10000',
  LessThan50000 = 'LessThan50000',
  LessThan100000 = 'LessThan100000',
  MoreThan100000 = 'MoreThan100000',
}

export enum ProductKnowledgeOption {
  DoesNotKnow = 'DoesNotKnow',
  Knows = 'Knows',
  HasInvested = 'HasInvested',
}

export enum EsgKnowledgeOptions {
  NotImportant = 'NotImportant',
  SomeWhatImportant = 'SomeWhatImportant',
  Important = 'Important',
  VeryImportant = 'VeryImportant',
}

export enum PoliticalPersonOptions {
  PresidentOrOtherHeadOfState = 'PresidentOrOtherHeadOfState', // Presidentti tai muu valtionpäämies
  PrimeMinister = 'PrimeMinister', // Pääministeri
  MemberOrDeputyOfCouncilOfState = 'MemberOrDeputyOfCouncilOfState', // Valtioneuvoston vara- tai jäsen
  MemberOfParliament = 'MemberOfParliament', // Kansanedustaja
  MemberOfEuropeanParliament = 'MemberOfEuropeanParliament', // Europpan parlamentin jäsen
  MemberOfPoliticalPartyManagement = 'MemberOfPoliticalPartyManagement', // Poliittisen puolueen johdon jäsen
  MemberOfSupremeCourt = 'MemberOfSupremeCourt', // Korkeimman oikeuden jäsen
  MemberOfSupremeAdministrativeCourt = 'MemberOfSupremeAdministrativeCourt', // Korkeimmassa hallinto-oikeudessa
  MemberOfIncuranceCourt = 'MemberOfIncuranceCourt', // Vakuutustuomioistuimen jäsen
  MemberOfLabourCourt = 'MemberOfLabourCourt', // Työtuomioistuimen jäsen
  MemberOfCourtOfAuditors = 'MemberOfCourtOfAuditors', // Tilintarkastustuomioistuimen jäsen
  ChiefDirectorOfNationalAuditOffice = 'ChiefDirectorOfNationalAuditOffice', // Tilintarkastusviraston ylijohtaja
  MemberOfCentralBankExecutiveBoard = 'MemberOfCentralBankExecutiveBoard', // Keskuspankin johtokunnan jäsen
  CeoOfStateOwnedCompany = 'CeoOfStateOwnedCompany', // Valtionyhtiön toimitusjohtaja
  MemberOrDeputyOfBoardOfStateOwnedCompany = 'MemberOrDeputyOfBoardOfStateOwnedCompany', // V-yht. hallituksen vara- tai jäsen
  MemberOfAdministrativeCouncilOfStateOwnedCompany = 'MemberOfAdministrativeCouncilOfStateOwnedCompany', // V-yht. hallintoneuvoston jäsen
  DirectorOrDeputyDirectorOfInternationalOrganization = 'DirectorOrDeputyDirectorOfInternationalOrganization', // Kv järjestön vara- tai johtaja
  MemberOfBoardOfInternationalOrganization = 'MemberOfBoardOfInternationalOrganization', // Kv järjestön hallituksen jäsen
  GeneralOfficer = 'GeneralOfficer', // Kenraalikunnan jäsen
  Ambassador = 'Ambassador', // Suurlähettiläs tai asiainhoitaja
}

export const POLITICAL_PERSON_OPTIONS: OptionType[] = [
  {
    label: 'questions.pepPersonOptions.president',
    value: PoliticalPersonOptions.PresidentOrOtherHeadOfState,
  },
  { label: 'questions.pepPersonOptions.prime-minister', value: PoliticalPersonOptions.PrimeMinister },
  {
    label: 'questions.pepPersonOptions.council-of-state',
    value: PoliticalPersonOptions.MemberOrDeputyOfCouncilOfState,
  },
  { label: 'questions.pepPersonOptions.parliament', value: PoliticalPersonOptions.MemberOfParliament },
  {
    label: 'questions.pepPersonOptions.european-parliament',
    value: PoliticalPersonOptions.MemberOfEuropeanParliament,
  },
  {
    label: 'questions.pepPersonOptions.political-party',
    value: PoliticalPersonOptions.MemberOfPoliticalPartyManagement,
  },
  {
    label: 'questions.pepPersonOptions.supreme-court',
    value: PoliticalPersonOptions.MemberOfSupremeCourt,
  },
  {
    label: 'questions.pepPersonOptions.supreme-administrative-court',
    value: PoliticalPersonOptions.MemberOfSupremeAdministrativeCourt,
  },
  {
    label: 'questions.pepPersonOptions.incurance-court',
    value: PoliticalPersonOptions.MemberOfIncuranceCourt,
  },
  {
    label: 'questions.pepPersonOptions.labour-cort',
    value: PoliticalPersonOptions.MemberOfLabourCourt,
  },
  {
    label: 'questions.pepPersonOptions.court-of-auditors',
    value: PoliticalPersonOptions.MemberOfCourtOfAuditors,
  },
  {
    label: 'questions.pepPersonOptions.national-audit-office',
    value: PoliticalPersonOptions.ChiefDirectorOfNationalAuditOffice,
  },
  {
    label: 'questions.pepPersonOptions.central-bank',
    value: PoliticalPersonOptions.MemberOfCentralBankExecutiveBoard,
  },
  {
    label: 'questions.pepPersonOptions.state-owned',
    value: PoliticalPersonOptions.CeoOfStateOwnedCompany,
  },
  {
    label: 'questions.pepPersonOptions.board-of-state-owned',
    value: PoliticalPersonOptions.MemberOrDeputyOfBoardOfStateOwnedCompany,
  },
  {
    label: 'questions.pepPersonOptions.council-of-state-owned',
    value:
      PoliticalPersonOptions.MemberOfAdministrativeCouncilOfStateOwnedCompany,
  },
  {
    label: 'questions.pepPersonOptions.international-director',
    value:
      PoliticalPersonOptions.DirectorOrDeputyDirectorOfInternationalOrganization,
  },
  {
    label: 'questions.pepPersonOptions.international-member',
    value: PoliticalPersonOptions.MemberOfBoardOfInternationalOrganization,
  },
  {
    label: 'questions.pepPersonOptions.general-officer',
    value: PoliticalPersonOptions.GeneralOfficer,
  },
  {
    label: 'questions.pepPersonOptions.ambassador',
    value: PoliticalPersonOptions.Ambassador,
  },
]

export enum PoliticalRelationshipOptions {
  Self = 'Self',
  FamilyMember = 'FamilyMember',
  Associate = 'Associate',
}

export const POLITICAL_RELATIONSHIP_OPTIONS: OptionType[] = [
  {
    label: 'questions.pepOptions.self',
    value: PoliticalRelationshipOptions.Self,
  },
  {
    label: 'questions.pepOptions.family-member',
    value: PoliticalRelationshipOptions.FamilyMember,
  },
  {
    label:
      'questions.pepOptions.associate',
    value: PoliticalRelationshipOptions.Associate,
  },
]

export const MONEY_TRANSFER_OPTIONS: OptionType[] = [
  { label: 'questions.moneyTransferOptions.none', value: MoneyRange.None },
  {
    label: 'questions.moneyTransferOptions.between-1-1000',
    value: MoneyRange.Between1And1000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-1001-5000',
    value: MoneyRange.Between1001And5000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-5001-10000',
    value: MoneyRange.Between5001And10000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-10001-50000',
    value: MoneyRange.Between10001And50000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-50001-100000',
    value: MoneyRange.Between50001And100000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.over-100000',
    value: MoneyRange.Over100000Eur,
  },
]

export const MONEY_TRANSFER_WITHOUT_NONE_OPTIONS: OptionType[] = [
  {
    label: 'questions.moneyTransferOptions.between-0-1000',
    value: MoneyRangeWithoutNone.Between0And1000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-1001-5000',
    value: MoneyRangeWithoutNone.Between1001And5000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-5001-10000',
    value: MoneyRangeWithoutNone.Between5001And10000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-10001-50000',
    value: MoneyRangeWithoutNone.Between10001And50000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.between-50001-100000',
    value: MoneyRangeWithoutNone.Between50001And100000Eur,
  },
  {
    label: 'questions.moneyTransferOptions.over-100000',
    value: MoneyRangeWithoutNone.Over100000Eur,
  },
]

export const YES_NO_OPTIONS: OptionType[] = [
  { label: 'yes', value: 'Yes' },
  { label: 'no', value: 'No' },
]

export enum PurposeOfForeignMoneyTransfers {
  ReceivingOrGivingSupportForRelatives = 'ReceivingOrGivingSupportForRelatives', // Läheisten tuen vastaanottaminen tai läheisille tuen lähettäminen ulkomaille
  DonationsToPublicBenefitOrganizations = 'DonationsToPublicBenefitOrganizations', // Lahjoitukset yleishyödyllisille yhteisöille
  PaymentOrGrantingOfLoan = 'PaymentOrGrantingOfLoan', // Lainan maksu tai lainananto ulkomaille
  SalaryPaymentsFromAbroad = 'SalaryPaymentsFromAbroad', // Palkkatulojen vastaanottaminen ulkomailta
  InvestmentsAbroad = 'InvestmentsAbroad', // Sijoitus ulkomaille tai sijoitustuottojen kotiutus
  TransferOfOwnFundsBetweenFinancialInstitutions = 'TransferOfOwnFundsBetweenFinancialInstitutions', // Omien varojen siirto finanssilaitoksesta toiseen
  Other = 'Other',
}

export const PURPOSE_OF_FOREIGN_MONEY_TRANSFERS_OPTIONS: OptionType[] = [
  {
    label: 'questions.foreignMoneyTransfer.support-for-relatives',
    value: PurposeOfForeignMoneyTransfers.ReceivingOrGivingSupportForRelatives,
  },
  {
    label: 'questions.foreignMoneyTransfer.donations',
    value: PurposeOfForeignMoneyTransfers.DonationsToPublicBenefitOrganizations,
  },
  {
    label: 'questions.foreignMoneyTransfer.loan',
    value: PurposeOfForeignMoneyTransfers.PaymentOrGrantingOfLoan,
  },
  {
    label: 'questions.foreignMoneyTransfer.salary-payments',
    value: PurposeOfForeignMoneyTransfers.SalaryPaymentsFromAbroad,
  },
  {
    label: 'questions.foreignMoneyTransfer.investment-abroad',
    value: PurposeOfForeignMoneyTransfers.InvestmentsAbroad,
  },
  {
    label: 'questions.foreignMoneyTransfer.transfer-funds',
    value:
      PurposeOfForeignMoneyTransfers.TransferOfOwnFundsBetweenFinancialInstitutions,
  },
  {
    label: 'questions.foreignMoneyTransfer.other',
    value: PurposeOfForeignMoneyTransfers.Other,
  },
]

export enum IntendedUseOfCash {
  GiftsAndDonations = 'GiftsAndDonations', // Lahjat ja lahjoitukset
  TransfersBetweenOwnBankAccounts = 'TransfersBetweenOwnBankAccounts', // Siirrot omien pankkitilien välillä
  SalaryPaymentInCash = 'SalaryPaymentInCash', // Palkanmaksu käteisellä
  SaleOfAssets = 'SaleOfAssets', // Omaisuuden myynti
  LargerAcquisitions = 'LargerAcquisitions', // Suuremmat hankinnat
  DailyUseOfMoney = 'DailyUseOfMoney', // Päivittäinen rahankäyttö
  Other = 'Other',
}

export const INTENDED_USE_OF_CASH_OPTIONS: OptionType[] = [
  {
    label: 'questions.cashPurpose.gifts-donations',
    value: IntendedUseOfCash.GiftsAndDonations,
  },
  {
    label: 'questions.cashPurpose.transfer',
    value: IntendedUseOfCash.TransfersBetweenOwnBankAccounts,
  },
  {
    label: 'questions.cashPurpose.salary',
    value: IntendedUseOfCash.SalaryPaymentInCash,
  },
  {
    label: 'questions.cashPurpose.sale-assets',
    value: IntendedUseOfCash.SaleOfAssets,
  },
  {
    label: 'questions.cashPurpose.large-acquisitions',
    value: IntendedUseOfCash.LargerAcquisitions,
  },
  {
    label: 'questions.cashPurpose.daily-use-of-money',
    value: IntendedUseOfCash.DailyUseOfMoney,
  },
  {
    label: 'questions.foreignMoneyTransfer.other',
    value: IntendedUseOfCash.Other,
  },
]

export enum TaxationChoices {
  OnlyFinland = 'OnlyFinland',
  FinlandAndAbroad = 'FinlandAndAbroad',
  OnlyAbroad = 'OnlyAbroad',
}

export const TAXATION_OPTIONS: OptionType[] = [
  {
    label: 'questions.taxationOptions.only-finland',
    value: TaxationChoices.OnlyFinland,
  },
  {
    label: 'questions.taxationOptions.finland-and-abroad',
    value: TaxationChoices.FinlandAndAbroad,
  },
  {
    label: 'questions.taxationOptions.only-abroad',
    value: TaxationChoices.OnlyAbroad,
  },
]

export const TAXATION_OPTIONS_ABROAD: OptionType[] = TAXATION_OPTIONS.filter(
  (to) => to.value !== TaxationChoices.OnlyFinland
)
