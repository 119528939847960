import CustomerService from './CustomerService'

export const ErrorMessages = {
  authenticationCancelledByUser: 'validation.cancelled-by-user', //'Käyttäjä keskeytti tunnistautumisen',
  authenticationFailed: `Tunnistautuminen epäonnistui. Yritä uudelleen tai ota yhteyttä asiakaspalveluun sähköpostitse ${CustomerService.email} tai puhelimitse ${CustomerService.phoneNumberFormatted}`,
  checkBankAccountNumber: 'validation.check-account-number',
  checkMissingInformation: 'validation.check-missing-information',
  checkPhoneNumber: 'validation.check-phone-number',
  chooseAccountPurpose: 'validation.choose-account-purpose',
  chooseAtLeastOne: 'validation.choose-at-least-one', // Valitse vähintään yksi
  chooseAtLeastOneOption: 'validation.at-least-one-option', // Valitse vähintään yksi vaihtoehto
  chooseOneSourceOfFunds: 'validation.choose-source-of-funds',
  chooseEducation: 'validation.choose-education',
  chooseWorkStatus: 'validation.choose-work-status',
  emailTaken: 'validation.email-taken', // Tarkista sähköposti
  expiredCredentials: 'validation.expired-credentials', //'Tunnukset vanhentuneet. Kirjaudu pankkitunnuksilla.',
  expiredMobileCredentials: 'validation.expired-mobile-credentials', // Kirjaudu pankkitunnuksilla
  financeExpensesAsNumbers: 'validation.expenses-as-numbers', // Menot numeroina
  financeIncomeAsNumbers: 'validation.income-as-numbers', // Tulot numeroina
  initialInvestmentAsNumbers: 'validation.initial-investment-as-numbers', // Kertasijoitus numeroina
  monthlyInvestmentAsNumbers: 'validation.monthly-investment-as-numbers', //Kuukausisijoitus numeroina
  investmentMustBePositive: 'validation.invest-must-be-positive', // Sijoitettavan summan tulee olla positiivinen
  incomeMustBePositive: 'validation.income-positive', // Tulojen tulee olla positiiviset
  eitherInitialOrMonthlyInvestment: 'validation.initial-or-monthly', // Anna joko kerta- tai kuukausisijoitus
  expensesMustBePositive: 'validation.expenses-positive', // Menojen tulee olla positiiviset
  incorrectCredentials: 'validation.incorrect-credentials', //'Virheellinen tunnus tai salasana',
  noCredentials: 'validation.no-credentials', //'Asiakkuutta ei löytynyt',
  incorrectEmail: 'validation.email-incorrect', //'Virheellinen sähköposti',
  mandatory: 'validation.mandatory',
  required: 'validation.required',
  missingEmail: 'validation.email-missing', //'Sähköposti puuttuu',
  missingPassword: 'validation.password-missing', // 'Salasana puuttuu',
  passwordAtLeastNChars: 'validation.password-short', //'Salasanan oltava vähintään 10 merkkiä',
  paymentCancelled: 'validation.payment-cancelled', // Maksu keskeytyi
  paymentFailed: 'validation.payment-failed', // Maksu epäonnistui
  termsUnaccepted: 'validation.need-to-accept',
  unexpectedError: 'validation.unexpected-error', //'Jokin meni vikaan',
  emptyField: 'validation.empty-field', // Hakukenttä on tyhjä
  queryAtLeastNChars: 'validation.at-least-3-char', // Anna vähintään 3 merkkiä
  registrationError: `Tunnuksia luotaessa tapahtui virhe. Yritä uudelleen tai ota yhteyttä asiakaspalveluun sähköpostitse ${CustomerService.email} tai puhelimitse ${CustomerService.phoneNumberFormatted}`,
  idExpired: 'validation.id-expired', // Henkilöllisyystodistuksesi on vanhentunut. Sijoituksen tekeminen palveluun edellyttää valokuvan voimassaolevasta henkilöllisyystodistuksesta, kuten passista, henkilö- tai ajokortista.
  invalidDiscountCode: 'validation.invalid-discount-code', // Kampanjakoodi ei ole voimassa
  uniqueEmail: 'validation.email-already-used', // Sähköposti on jo lisätty tilaukseen,
  privacyAccepted: 'validation.privacy-accept', // Sinun on hyväksyttävä tietosuojaseloste
  infoVerified: 'validation.info-verified', // Sinun on hyväksyttävä tiedot
  primaryReportingVerified: 'validation.primary-reporting-verified', // Sinun on hyväksyttävä pääasiallinen raportointikanava
}
