import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import routePath, {
  routePathApp,
} from '@taaleri/components/src/navigation/routeHelper'
import {
  ROUTE_SIGN_IN,
  ROUTE_REPORT_ORDERS,
  ROUTE_REPORT_ARCHIVE,
} from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import {
  getWithdrawalRoute,
  startWithdraw,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { ROUTE_PROFILE_FAQ } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { IconCheckmarkCircle } from '@taaleri/components/src/ui/Icons'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import Box from '@taaleri/components/src/ui/box/Box'
import { IconArrowRightSlim } from '@taaleri/components/src/ui/icons/ArrowIcons'
import {
  IconChevronDown,
  IconChevronUp,
} from '@taaleri/components/src/ui/icons/ChevronIcons'
import { IconCircle } from '@taaleri/components/src/ui/icons/CircleIcons'
import { TextMedium } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { changeLanguage, getChangeText } from '@taaleri/core/src/i18n'
import { CustomerAuthorization } from '@taaleri/core/src/models/Customer'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Link, LinkProps, useLocation } from 'react-router-dom'
import usePortal from 'react-useportal'
import styled, { css } from 'styled-components'
import { customerChangeToDataLayer } from 'utils/DataLayer'

import { LogoutImage, HamburgerOpen, HamburgerClosed } from './NavImages'
import navItems from './navItems'

const padding = Spacings.S24

const NaviContent = styled.div`
  padding: 0 ${padding}px;
`

const Section = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid ${Colors.border};
  margin-left: -${padding}px;
  margin-right: -${padding}px;
  padding: 0 ${padding}px 16px ${padding}px;
`

const Divider = () => (
  <View
    style={{
      borderTopWidth: 1,
      borderTopColor: Colors.border,
      marginLeft: -padding,
      marginRight: -padding,
    }}
  />
)

interface LinkAppProps extends LinkProps {
  $bold?: boolean
  $paddingLarge?: boolean
  $largeFont?: boolean
  $noBorder?: boolean
}

const NavText = styled.div<{
  $bold?: boolean
  $largeFont?: boolean
  noHover?: boolean
  $noBorder?: boolean
}>`
  font-family: ${(props) =>
    props.$bold ? 'calibre-medium' : 'calibre-regular'};
  text-decoration: none;
  color: ${Colors.text};
  font-size: ${(props) => (props.$largeFont ? '16px' : '12px')};
  @media (min-width: ${BreakPoint.S}px) {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.border};
  padding: 16px 0;
  ${(props) =>
    props.$noBorder &&
    css`
      border-bottom: none;
    `}
  ${(props) =>
    !props.noHover &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: ${Colors.textHover};
        }
      }
    `}
`

const StyledLink = styled(Link)<LinkAppProps>`
  font-family: ${(props) =>
    props.$bold ? 'calibre-medium' : 'calibre-regular'};
  text-decoration: none;
  color: ${(props) => (props.$bold ? Colors.primary : Colors.text)};
  font-size: ${(props) => (props.$largeFont ? '16px' : '12px')};
  @media (min-width: ${BreakPoint.S}px) {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  display: flex;
  align-items: center;
  padding: ${(props) => (props.$paddingLarge ? '16px 0' : '8px 0')};
  &:hover {
    color: ${Colors.textHover};
  }
  ${(props) =>
    props.$largeFont &&
    css`
      border-bottom: 1px solid ${Colors.border};
      padding: 16px 0;
    `}
  ${(props) =>
    props.$noBorder &&
    css`
      border-bottom: none;
    `}
`

const NavContainer = styled.div<{ small?: boolean }>`
  position: absolute;
  top: 65px;
  ${(props) =>
    props.small
      ? 'left: 0; right: 0;'
      : 'position: absolute; right: -24px; top: 36px; min-width: 300px;'}
`

const NavLink: React.FC<LinkAppProps> = (props: LinkAppProps) => {
  return <StyledLink {...props}>{props.children}</StyledLink>
}

type NavType = 'mobile' | 'desktop'

interface Props {
  type: NavType
}

interface NavItemProps {
  to: string
  title: string
  onClick?: () => void
  icon?: any
  image?: any
  paddingLarge?: boolean
  largeFont?: boolean
  noActiveStyle?: boolean
  arrowRight?: boolean
  noBorder?: boolean
}

const useNavi = (isMobile: boolean) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { isSmall, isNonSmall } = useLayout()
  const { navigate } = useNavigation()
  const authorizations = AppStore.customer.customerAuthorizations
  const { Portal, togglePortal, openPortal, isOpen, closePortal } = usePortal({
    bindTo: isMobile
      ? document.body
      : (document.getElementById('#navDesktop') as HTMLElement),
  })

  function NavItem({
    to,
    title,
    onClick,
    image,
    icon,
    paddingLarge,
    largeFont,
    noActiveStyle,
    arrowRight,
    noBorder,
  }: NavItemProps) {
    const selected = !noActiveStyle && pathname === to
    return (
      <NavLink
        to={to}
        type="text"
        onClick={() => {
          Analytics.event('Nav', 'Nav click', title)
          if (onClick) {
            onClick()
          }
          closePortal()
        }}
        $bold={!noActiveStyle && pathname === to}
        $paddingLarge={paddingLarge}
        $largeFont={largeFont}
        $noBorder={noBorder}
      >
        {icon && (
          <View style={{ marginRight: 10 }}>
            {React.createElement(icon, {
              color: selected ? Colors.primary : Colors.text,
            })}
          </View>
        )}
        <div>{title}</div>
        {image && image}
        {arrowRight && (
          <IconArrowRightSlim style={{ marginLeft: 6, marginTop: 4 }} />
        )}
      </NavLink>
    )
  }

  async function selectCustomer(customer: CustomerAuthorization) {
    Analytics.event('Portfolio', 'Select customer')
    closePortal()
    Analytics.event('Nav', 'Select customer')
    await AppStore.changeCustomer(customer)
    customerChangeToDataLayer(AppStore.customerId)
  }

  const NaviPortal = () => {
    const activeItems = navItems(AppStore.showPortfolio, AppStore.showImpact, t)
    const [customerOpen, setCustomerOpen] = useState<boolean>(isNonSmall)
    return (
      <Portal>
        <NavContainer small={isMobile}>
          <Box
            style={[{ width: '100%' }, isSmall && { borderRadius: 0 }]}
            type={isSmall ? 'border' : 'shadow'}
          >
            <NaviContent>
              {authorizations.length > 1 && (
                <>
                  <View>
                    {customerOpen && (
                      <UiTouchable
                        onPress={() => {
                          setCustomerOpen(!customerOpen)
                        }}
                      >
                        <FlexRow
                          style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottomWidth: 1,
                            borderBottomColor: Colors.border,
                          }}
                        >
                          <NavText noHover={true} $noBorder={true}>
                            Valitse asiakas
                          </NavText>

                          <IconChevronUp
                            style={{
                              marginLeft: 10,
                            }}
                          />
                        </FlexRow>
                      </UiTouchable>
                    )}
                    {authorizations.map((authCustomer, index: number) => {
                      const selected =
                        authCustomer.id === String(AppStore.customerId)
                      if (selected && !customerOpen) {
                        return (
                          <UiTouchable
                            onPress={() => {
                              setCustomerOpen(!customerOpen)
                            }}
                            key={authCustomer.id + index}
                          >
                            <FlexRow
                              style={{
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                              key={index}
                            >
                              <NavText noHover={true} $noBorder={true}>
                                {authCustomer.name}
                              </NavText>

                              <IconChevronDown style={{ marginLeft: 10 }} />
                            </FlexRow>
                          </UiTouchable>
                        )
                      }
                      if (!customerOpen) {
                        return null
                      }
                      return (
                        <UiTouchable
                          key={index}
                          onPress={() => {
                            selectCustomer(authCustomer)
                          }}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottomWidth:
                              index < authorizations.length - 1 ? 1 : 0,
                            borderBottomColor: Colors.border,
                          }}
                        >
                          <NavText $noBorder={true} $bold={true}>
                            {authCustomer.name}
                          </NavText>
                          {selected && (
                            <IconCheckmarkCircle style={{ marginLeft: 10 }} />
                          )}
                          {!selected && (
                            <IconCircle style={{ marginLeft: 10 }} />
                          )}
                        </UiTouchable>
                      )
                    })}
                  </View>
                  <Divider />
                </>
              )}

              {isMobile && (
                <>
                  <View>
                    {activeItems.map((n, index) => (
                      <NavItem
                        key={n.title}
                        title={n.title}
                        to={n.path}
                        icon={n.icon}
                        largeFont={true}
                        noBorder={index === activeItems.length - 1}
                      />
                    ))}
                  </View>
                  <Divider />
                </>
              )}

              {AppStore.adminToken && (
                <Section>
                  <NavItem
                    title="Hae asiakasta"
                    to="/admin"
                    onClick={async () => {
                      await AppStore.signOutWeb()
                      AdminStore.logoutAdmin()
                    }}
                  />
                </Section>
              )}

              {AppStore.showPortfolioActions && (
                <Section>
                  <FlexRow>
                    {AppStore.showWithdrawAction && (
                      <UiButton
                        containerStyle={{
                          minWidth: 'auto',
                          flex: 1,
                          marginLeft: Spacings.S16,
                        }}
                        type="secondary"
                        title={`${t('button.withdraw')}`}
                        size="small"
                        onPress={() => {
                          startWithdraw()
                          navigate(getWithdrawalRoute())
                          closePortal()
                        }}
                      />
                    )}
                  </FlexRow>
                </Section>
              )}
              {AppStore.hasPortfolio && (
                <>
                  <Section>
                    {AppStore.isDigiOrConsultative && (
                      <NavItem
                        title={`${t('menu.faq')}`}
                        noActiveStyle={true}
                        to={routePathApp(ROUTE_PROFILE_FAQ)}
                        arrowRight={true}
                      />
                    )}
                    {AppStore.IsReportOrdersForbidden() !== true && (
                      <NavItem
                        title={`${t('menu.report-orders')}`}
                        noActiveStyle={true}
                        to={routePathApp(ROUTE_REPORT_ORDERS)}
                        arrowRight={true}
                      />
                    )}

                    <NavItem
                      title={`${t('menu.report-archive')}`}
                      to={routePathApp(ROUTE_REPORT_ARCHIVE)}
                      noActiveStyle={true}
                      arrowRight={true}
                    />
                  </Section>
                </>
              )}
              <Section>
                <NavItem
                  title={getChangeText()}
                  noActiveStyle={true}
                  to={window.location.pathname}
                  onClick={async () => {
                    changeLanguage(true)
                  }}
                />
              </Section>
              <NavItem
                title={`${t('menu.logout')}`}
                paddingLarge={true}
                noBorder={true}
                largeFont={true}
                to={routePath(ROUTE_SIGN_IN)}
                onClick={async () => {
                  await AppStore.signOutWeb()
                  AdminStore.logoutAdmin()
                }}
                image={<LogoutImage />}
              />
            </NaviContent>
          </Box>
        </NavContainer>
      </Portal>
    )
  }

  return {
    Navi: NaviPortal,
    togglePortal,
    openPortal,
    isOpen,
    closePortal,
  }
}

function Nav(props: Props) {
  const { Portal } = usePortal()

  const isMobile = props.type === 'mobile'

  const { Navi, togglePortal, isOpen, closePortal } = useNavi(isMobile)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        closePortal()
      }
    }
    if (isOpen && isMobile) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isOpen, isMobile, closePortal])

  if (AppStore.forceEvervestContract) {
    return null
  }

  const customerName = AppStore.customer.customerAuthorizations.find(
    (authCustomer) => authCustomer.id === String(AppStore.customerId)
  )?.name

  const name = AppStore.adminToken
    ? customerName
      ? `ADMIN - ${customerName?.toUpperCase()}`
      : 'ADMIN'
    : customerName

  return (
    <View nativeID="#navDesktop">
      <UiTouchable onPress={togglePortal}>
        {isMobile && (isOpen ? <HamburgerOpen /> : <HamburgerClosed />)}
        {!isMobile && (
          <div
            style={{ alignItems: 'center', flexDirection: 'row' }}
            data-testid={isMobile ? 'userMenuMobile' : 'userMenuDesktop'}
          >
            <TextMedium
              style={{ marginRight: 5, maxWidth: 230 }}
              numberOfLines={1}
              size={FontSize.S14}
            >
              {name}
            </TextMedium>
            {isOpen ? <IconChevronUp /> : <IconChevronDown />}
          </div>
        )}
      </UiTouchable>
      {isOpen && <Navi />}
      {isOpen && isMobile && (
        <Portal>
          <BackDrop />
        </Portal>
      )}
    </View>
  )
}

const BackDrop = styled.div`
  position: fixed;
  background-color: rgb(4, 4, 15);
  opacity: 0.4;
  width: 100vw;
  height: calc(100vh - 200px);
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
`

export default observer(Nav)
