import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import { Security } from '@taaleri/core/src/models/investmentProposal'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatPercentageFull } from '@taaleri/core/src/utils/format'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

import { ROUTE_IMPACT_FUND_DETAIL, ROUTE_IMPACT } from './ImpactRoutes'
import { ScreenWidth } from '../../constants/BreakPoint'
import { isWeb } from '../../constants/Platforms'
import useNavigation from '../../navigation/useNavigation'
import FlexRow from '../../ui/FlexRow'
import { IconCheckCircleOutline } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronRight } from '../../ui/icons/ChevronIcons'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

interface Props {
  assetClassItem: Security
  borderBottom?: boolean
  screenWidth: ScreenWidth
  topBorderRadius?: boolean
  bottomBorderRadius?: boolean
  linked: boolean
}

function TextChecked({ value }: { value: string }) {
  return (
    <FlexRow style={{ alignItems: 'center', marginTop: Spacings.S4 }}>
      <TextMedium
        style={[
          styles.text,
          styles.textSecondary,
          { marginRight: Spacings.S10 },
        ]}
      >
        {value}
      </TextMedium>
      <IconCheckCircleOutline />
    </FlexRow>
  )
}

function TextCheckedDesktop({ value }: { value: string }) {
  return (
    <FlexRow
      style={{
        alignItems: 'center',
        paddingRight: Spacings.S16,
        marginRight: Spacings.S16,
        borderRightWidth: 1,
        borderRightColor: Colors.border,
      }}
    >
      <TextDefault type="t5" style={[{ marginRight: Spacings.S10 }]}>
        {value}
      </TextDefault>
      <IconCheckCircleOutline />
    </FlexRow>
  )
}

const Container = (props: any) =>
  props.linked ? <UiTouchable {...props} /> : <View {...props} />

export function ImpactOwnershipRow(props: Props) {
  const {
    assetClassItem,
    borderBottom,
    bottomBorderRadius,
    topBorderRadius,
    linked,
  } = props
  const { isNonSmall } = useLayout()
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()

  const sharePercentage = assetClassItem.percentage
    ? formatPercentageFull(assetClassItem.percentage) + ' %'
    : '0 %'

  function onPress() {
    linked &&
      navigateApp(
        isWeb
          ? `${ROUTE_IMPACT}/${ROUTE_IMPACT_FUND_DETAIL}/${assetClassItem.securityId}/false`
          : ROUTE_IMPACT_FUND_DETAIL,
        { allocation: assetClassItem.allocation }
      )
  }

  const assetClassName = () =>
    AppStore.currentLanguage === fiKey
      ? assetClassItem.name
      : assetClassItem.nameSv

  if (isNonSmall) {
    return (
      <Container linked={linked} onPress={onPress}>
        <FlexRow
          style={[
            topBorderRadius && { borderTopLeftRadius: BorderRadius.default },
            bottomBorderRadius && {
              borderBottomLeftRadius: BorderRadius.default,
            },
            { alignItems: 'center' },
          ]}
        >
          <FlexRow
            style={[
              styles.main,
              borderBottom && styles.borderBottom,
              { paddingHorizontal: 32 },
            ]}
          >
            <TextMedium style={[styles.text, { flex: 1 }]}>
              {assetClassName()}
            </TextMedium>
            <FlexRow
              style={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                flex: 1,
              }}
            >
              {assetClassItem.sustainableDevelopmentGoals.length > 0 && (
                <TextCheckedDesktop
                  value={`${t('impactIntro.impaktifund.socialimpact')}`}
                />
              )}
              <TextCheckedDesktop
                value={`${t('impactIntro.responsiblestocks.responsible')}`}
              />

              <FlexRow style={{ alignItems: 'center' }}>
                <TextDefault type="t5" style={{ marginRight: 6 }}>
                  {t('impactIntro.responsiblestocks.shareofportfolio')}
                </TextDefault>

                <TextMedium
                  style={[
                    styles.percentageSmall,
                    {
                      width: linked ? 50 : 40,
                    },
                    linked && {
                      borderRightWidth: 1,
                      borderRightColor: Colors.border,
                      paddingRight: Spacings.S16,
                      marginRight: Spacings.S10,
                    },
                  ]}
                >
                  {assetClassItem.percentage < 1 &&
                  assetClassItem.percentage > 0
                    ? '< 1 %'
                    : sharePercentage}
                </TextMedium>
                {linked && <IconChevronRight />}
              </FlexRow>
            </FlexRow>
          </FlexRow>
        </FlexRow>
      </Container>
    )
  }

  return (
    <Container onPress={onPress} linked={true}>
      <FlexRow
        style={[
          topBorderRadius && { borderTopLeftRadius: BorderRadius.default },
          bottomBorderRadius && {
            borderBottomLeftRadius: BorderRadius.default,
          },
        ]}
      >
        <FlexRow
          style={[
            styles.main,
            borderBottom && styles.borderBottom,
            { paddingHorizontal: 20 },
          ]}
        >
          <View style={{ flex: 80 }}>
            <TextMedium style={[styles.text]}>{assetClassItem.name}</TextMedium>
            <TextChecked
              value={`${t('impactIntro.responsiblestocks.responsible')}`}
            />
            {assetClassItem.sustainableDevelopmentGoals.length > 0 && (
              <TextChecked
                value={`${t('impactIntro.impaktifund.socialimpact')}`}
              />
            )}
          </View>
          <TextMedium style={[styles.percentageSmall]}>
            {assetClassItem.percentage < 1 && assetClassItem.percentage > 0
              ? '< 1 %'
              : sharePercentage}
          </TextMedium>
          {linked && <IconChevronRight />}
        </FlexRow>
      </FlexRow>
    </Container>
  )
}
const lineHeight = 22
const styles = StyleSheet.create({
  title: {
    flex: 4,
  },
  number: {
    flex: 2,
    textAlign: 'right',
  },

  text: {
    fontSize: FontSize.S16,
    lineHeight,
  },
  textSecondary: {
    color: Colors.textSecondary,
    fontSize: FontSize.S12,
    lineHeight,
  },
  textSmall: {
    fontSize: FontSize.S12,
    lineHeight,
  },

  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  main: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: Spacings.S24,
    paddingVertical: 20,
  },

  percentageSmall: {
    lineHeight,
    fontSize: FontSize.S12,
    textAlign: 'right',
  },
})
