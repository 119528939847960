import React from 'react'
import Title from '../../ui/text/Title'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import * as Yup from 'yup'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from '../../ui/text/UiText'
import { ColorFormattedNumber } from '../../ui/text/ColorFormattedNumber'
import { StyleSheet, View } from 'react-native'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import InfoButton from '../../ui/InfoButton'
import { useTranslation } from 'react-i18next'

export const questionFinanceValidationSchema = Yup.object().shape({
  monthlyIncome: Yup.number()
    .required(ErrorMessages.required)
    .typeError(ErrorMessages.financeIncomeAsNumbers)
    .min(0, ErrorMessages.incomeMustBePositive),
  monthlyExpenses: Yup.number()
    .required(ErrorMessages.required)
    .typeError(ErrorMessages.financeExpensesAsNumbers)
    .min(0, ErrorMessages.expensesMustBePositive),
})

export function toInitValue(value: any) {
  return !value || value === 0 ? '' : value.toString()
}

export function toSaveValue(value: any) {
  return !value || (typeof value === 'string' && value.length === 0)
    ? 0
    : Number(value)
}

export interface IncomeAndExpenses {
  monthlyIncome: number
  monthlyExpenses: number
}

export default function QuestionFinanceContent({
  values,
}: {
  values: IncomeAndExpenses
}) {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('questions.finance.question')}</Title>

      <BoxWithPaddingMargin
        title={`${t('questions.finance.net-question')}`}
        titleStyle={styles.paragraph}
        titleRightComponent={
          <InfoButton text="questions.finance.net-question-info" />
        }
      >
        <UiTextInputFormAutoFocus
          grayBackground={true}
          name="monthlyIncome"
          label={`${t('questions.finance.monthly-income')}`}
          type="digits"
          returnKeyType="next"
          maxLength={6}
        />
      </BoxWithPaddingMargin>

      <BoxWithPaddingMargin
        title={`${t('questions.finance.expenses-question')}`}
        titleStyle={styles.paragraph}
      >
        <UiTextInputFormAutoFocus
          grayBackground={true}
          name="monthlyExpenses"
          label={`${t('questions.finance.monthly-expense')}`}
          type="digits"
          returnKeyType="done"
          maxLength={6}
        />
      </BoxWithPaddingMargin>

      <FlexRow
        style={{
          justifyContent: 'space-between',
          marginTop: Spacings.S24,
          marginBottom: Spacings.S24,
        }}
      >
        <TextDefault type="h5" style={{ lineHeight: 32 }}>
          {t('questions.finance.capital')}:
        </TextDefault>
        <ColorFormattedNumber
          value={values.monthlyIncome - values.monthlyExpenses}
          postFix={`€/${t('form.month-short')}`}
          style={{ lineHeight: 32 }}
        />
      </FlexRow>
    </View>
  )
}

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: Spacings.S24,
  },
})
