import React from 'react'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import RecommendationsContainer from './RecommendationsContainer'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

function RecommendationsHeader() {
  const { t } = useTranslation()
  return (
    <RecommendationsContainer>
      <PaddingHorizontal style={{ paddingVertical: Spacings.PADDING }}>
        <TextDefault type="h5" color={Colors.white}>
          {t('recommendations.header')}
        </TextDefault>
      </PaddingHorizontal>
    </RecommendationsContainer>
  )
}

export default RecommendationsHeader
