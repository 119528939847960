import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Formik } from 'formik'
import React from 'react'
import { View } from 'react-native'
import * as Yup from 'yup'
import AppStore from '@taaleri/core/src/stores/AppStore'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import Title from '../../ui/text/Title'
import { ButtonAbsolute } from '../../ui/UiButton'
import FlexRow from '../../ui/FlexRow'
import InfoButton from '../../ui/InfoButton'
import { Withdrawal } from '@taaleri/core/src/models/Payment'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { formatIban } from '@taaleri/core/src/utils/format'
import { isWeb } from '../../constants/Platforms'
import PropsChildren from '../../components/PropsChildren'
import { TextDefault, TextMedium } from '../../ui/text/UiText'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { observer } from 'mobx-react'
import { WarningContainer } from '../../ui/WarningContainer'
import { LiquidityPieContainer } from '../questions/LiquidityInfo'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

export interface WithdrawalForm {
  amount?: string
  sellEverything: boolean
  whyToRedeem?: string
}

interface Props {
  container: (props: PropsChildren) => any
  onSubmit: () => void
}

function getFundMarketValue(isFund: boolean): number {
  if (isFund && AppStore.fundId) {
    const assetClassItem = AppStore.getAssetClassItemByTickerFirst(
      AppStore.fundId,
      'consultative'
    )
    return assetClassItem ? assetClassItem.marketValue : Number.MAX_SAFE_INTEGER
  }
  return AppStore.discreationaryPortfolioMarketValue
}

function PaymentWithdrawalForm({ container, onSubmit }: Props) {
  const { t } = useTranslation()
  const initialValues: WithdrawalForm = {
    amount: '',
    sellEverything: false,
    whyToRedeem: '',
  }
  const bankAccountNumber = formatIban(
    AppStore.activeCustomer.bankAccountNumber
  )

  const accountText = t('withdraw.to-account', { account: bankAccountNumber })

  const Container = container

  const { impactShare, discreationaryPortfolioMarketValue } = AppStore

  const isFund = AppStore.investmentMode === 'withdrawfund'

  const validationSchema = Yup.object().shape({
    sellEverything: Yup.boolean(),
    amount: Yup.number().when('sellEverything', {
      is: false,
      then: Yup.number()
        .required(ErrorMessages.required)
        .min(5, `${t('validation.minimum-withdraw', { value: 5 })}`)
        .max(
          0.9 * getFundMarketValue(isFund),
          `${t('validation.greater-than-market-value')}`
        )
        .typeError(`${t('validation.must-be-integer')}`),
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        AppStore.newWithdrawal = Number(values.amount)
        const withdrawal: Withdrawal = {
          amount: Number(values.amount),
          sellEverything: values.sellEverything,
        }
        if (isFund && AppStore.fundId && values.sellEverything) {
          const assetClassItem = AppStore.getAssetClassItemByTickerFirst(
            AppStore.fundId,
            'consultative'
          )
          if (assetClassItem) {
            withdrawal.amount = assetClassItem.quantity
          }
        }
        AppStore.withdrawal = {
          ...withdrawal,
          whytoRedeem: values.whyToRedeem,
        }

        onSubmit()
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const showImpactLiquidityWarning =
          !isFund &&
          impactShare &&
          ((0.95 * (discreationaryPortfolioMarketValue * (100 - impactShare))) /
            100 <
            (values.amount || 0) ||
            values.sellEverything)
        return (
          <>
            <Container>
              <FlexRow
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginVertical: Spacings.S16,
                }}
              >
                <Title style={{ marginTop: 0, marginBottom: 0 }}>
                  {t('withdraw.withdraw-additional-info')}
                </Title>
                <InfoButton
                  text={
                    isFund
                      ? `${t('withdraw.fund-redeem-info')}`
                      : `${t('withdraw.only-withdrawable-info')}`
                  }
                />
              </FlexRow>
              <TextMedium style={{ marginBottom: Spacings.S24 }}>
                {accountText}
              </TextMedium>

              <View>
                {!values.sellEverything && (
                  <UiTextInputFormAutoFocus
                    name="amount"
                    label={`${t('steps.sum')}`}
                    type="digits"
                    grayBackground={true}
                    returnKeyType="done"
                    editable={!values.sellEverything}
                  />
                )}
                {values.sellEverything && (
                  <View
                    style={{
                      justifyContent: 'center',
                      borderWidth: 1,
                      borderColor: Colors.gray20,
                      borderRadius: BorderRadius.default,
                      height: 56,
                      marginBottom: Spacings.S24,
                      paddingLeft: Spacings.S16,
                    }}
                  >
                    <TextMedium style={{ color: Colors.textSecondary }}>
                      {t('withdraw.all-funds')}
                    </TextMedium>
                  </View>
                )}
                <UiListItemSwitch
                  name="sellEverything"
                  title={
                    isFund
                      ? `${t('withdraw.withdraw-all')}`
                      : `${t('withdraw.all-from-app')}`
                  }
                  topDivider={false}
                  containerStyle={{
                    backgroundColor: 'transparent',
                    paddingHorizontal: 0,
                    paddingTop: 0,
                  }}
                  onChangeText={(value: boolean) => {
                    if (value) {
                      setFieldValue('amount', '')
                    }
                  }}
                />
                {showImpactLiquidityWarning ? (
                  <WarningContainer>
                    <TextDefault
                      type="h5"
                      style={{ marginBottom: Spacings.S16 }}
                    >
                      {t('withdraw.impact-withdraw-notification')}
                    </TextDefault>
                    <TextDefault style={{ marginBottom: Spacings.S16 }}>
                      {t('withdraw.impact-withdraw-notification-2')}
                    </TextDefault>
                    <LiquidityPieContainer
                      impactShare={Math.round(impactShare)}
                    />
                  </WarningContainer>
                ) : null}
                <UiTextInputFormAutoFocus
                  containerStyle={{ marginTop: Spacings.S24 }}
                  placeholder={`${t('withdraw.why-withdraw')}`}
                  placeholderTextColor={Colors.text}
                  grayBackground={true}
                  name="whyToRedeem"
                  type="name"
                  hideCheckCircle={true}
                  multiline={true}
                  numberOfLines={3}
                  height={90}
                />
              </View>
            </Container>

            {!isWeb && (
              <ButtonAbsolute
                title={withdrawNextButtonText(t)}
                withoutGradient={true}
                onPress={handleSubmit}
              />
            )}
          </>
        )
      }}
    </Formik>
  )
}

export function withdrawNextButtonText(t: TFunction) {
  return AppStore.investmentMode === 'withdrawfund'
    ? t('button.continue')
    : t('button.continue-to-confirm')
}

export default observer(PaymentWithdrawalForm)
