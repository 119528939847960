import React from 'react'

import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_CONTRACTS,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import QuestionContainer from 'screens/questions/QuestionContainer'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import ContractContent from '@taaleri/components/src/screens/contract/ContractContent'
import CreatingContractContent from '@taaleri/components/src/screens/questions/CreatingContractContent'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { useCreateContract } from '@taaleri/components/src/screens/contract/useUpdateContract'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

export default function ContractsScreen() {
  const { t } = useTranslation()
  const { url, error, setRetry } = useCreateContract()

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  if (!url) {
    return (
      <QuestionContainer>
        <CreatingContractContent />
        <LoadingIndicator
          text={`${t('questions.accountCreation.creating')}`}
          type="inline"
        />
      </QuestionContainer>
    )
  }

  return (
    <QuestionContainer>
      <TopBottomContainer>
        <ContractContent url={url} />

        <Buttons
          title={t('button.next')}
          route={nextOnboardingRoute(ROUTE_ONBOARDING_CONTRACTS)}
        />
      </TopBottomContainer>
    </QuestionContainer>
  )
}
