import { TwoFactor } from '../../models/Identity'

export function toTwoFactor(
  isTwoFactor?: boolean,
  twoFactorId?: string
): TwoFactor {
  return {
    twoFactorId: twoFactorId ? twoFactorId : '',
    isTwoFactor: isTwoFactor ? isTwoFactor : false,
  }
}
