import { FINLAND } from './Countries'
import { Answers } from './Question'
import { RegulatoryQuestions } from './RequlatoryQuestions'

type RepresentativeType = 'Representative'

export enum ADDRESS_TYPE {
  Mailing,
}

export interface Address {
  addressType?: ADDRESS_TYPE
  street: string
  postalCode: string
  city: string
  country: string
}

export const emptyAddress: Address = {
  street: '',
  postalCode: '',
  city: '',
  country: FINLAND.code,
}

export interface CustomerAuthorization {
  name: string
  id: string
}

export interface CustomerRules {
  reportOrdersForbidden: boolean
  hideIncomeReport: boolean
}

export interface Customer {
  accountPurpose: string | null
  accountPurposeDescription: string | null
  accountManager?: AccountManager
  address: Address | null
  customerAuthorizations: CustomerAuthorization[]
  bankAccountNumber: string
  basicContractSigned: string | null
  digiContractSigned: string | null
  consultativeContractSigned: string | null
  countryOfBirth: string
  customerId: number
  customerName?: string
  levelOfEducation: string
  email: string
  firstName?: string
  identityNumber: string | null
  incompetent?: boolean
  lastName: string
  fullName: string | null
  marketingAllowance: boolean
  nationality: string
  onboardingId: string | null
  isPostAddress: boolean
  postAddress: Address | null
  phone: string
  profession: string
  socioeconomicStatus: string
  isRepresentative: boolean
  accountType: string
  idValidity?: string
  isDigiCustomer: boolean
  isConsultative: boolean
  isDigiConversionAllowed: boolean
  isDigiConverted: boolean
  isDiscretionary: boolean
  isCustomer: boolean
  isBlocked: boolean
  hasDigiBanker: boolean
  age: number | null
  rules?: CustomerRules
}

export interface CustomerBasicInfo {
  customer: Customer
  requlatoryQuestions: RegulatoryQuestions
  answers: Answers
  monthlyIncome: number
  monthlyExpenses: number
  monthlyCapitalIncome: number
  privateMonetaryTransactions: PrivateMonetaryTransactions
}

export interface AccountManager {
  company: string
  description: string
  email: string
  firstName: string
  lastName: string
  location: string
  imgRound: string
  phoneNumber: string
}

export interface CustomerResponse {
  accountPurpose: string | null
  accountPurposeDescription: string | null
  basicContractSigned: string | null
  digiContractSigned: string | null
  consultativeContractSigned: string | null
  customerId: string
  firstName: string
  lastName: string
  fullName: string
  phoneNumber: string
  emailAddress: string
  identityNumber: string
  incompetent: boolean
  address: Address
  accountManager?: AccountManager
  representativeType: RepresentativeType | null
  accountType: string
  idValidity?: string
  iban?: string
  isDigiCustomer: boolean
  isCustomer: boolean
  isBlocked: boolean
  isConsultative: boolean
  isDigiConversionAllowed: boolean
  isDigiConverted: boolean
  hasDigiBanker: boolean
  isDiscretionary: boolean
  age: number | null
  rules: CustomerRules
}

export interface PoliticallyExposedPersonDescription {
  country: string | null
  name: string | null
  position: string | null
}

export interface LegalInformation {
  accountPurpose: string
  accountPurposeDescription: string | null
  isPoliticallyExposed: boolean
  politicallyExposedPersonDescriptions: PoliticallyExposedPersonDescription[]
  sourceOfFunds: string[]
  sourceOfFundsDescription: string | null
}

export interface CustomerInformation {
  address: Address
  customerId: string
  countryOfBirth: string | null
  email: string | null
  ssnBic: string | null
  fullName: string | null
  isMarketingPermitted: boolean | null
  nationality: string | null
  postalAddress: Address
  phoneNumber: string | null
  firstName: string | null
  lastName: string | null
}

export interface BasicInfoQuestions {
  capitalIncome: string
  monthlyCapitalIncome: number
  illiquidOptions: string | null
  impactOnLife: string | null
  investmentGoal: string | null
  investmentHorizon: string | null
  investorExperience: string | null
  lossReaction: string | null
  monthlyExpenditure: number
  monthlyIncome: number
  negativeReaction: string | null
  otherAssets: string | null
  portfolioShare: string | null
  savings: string | null
  totalDebt: string | null
  knowledgeCapitalInvestments: string | null
  knowledgeCapitalSecuredStructuredProducts: string | null
  knowledgeCombinationFunds: string | null
  knowledgeCommodities: string | null
  knowledgeComplexDebt: string | null
  knowledgeDiscretionaryAssetManagement: string | null
  knowledgeEquities: string | null
  knowledgeFixedIncome: string | null
  knowledgeLeveragedSecurities: string | null
  knowledgeMoneyMarketFunds: string | null
  knowledgeUnsecuredStructuredProducts: string | null
  esgStart: boolean
  esgTaxonomy: string | null
  esgSfdr: string | null
  esgHarmfulEmissionEffect: string | null
  hasMonthlyMoneyTransfersWithinFinland: boolean
  monthlyIncomingMoneyTransfersWithinFinland: string
  monthlyOutgoingMoneyTransfersWithinFinland: string
  hasMonthlyForeignMoneyTransfers: boolean
  monthlyIncomingForeignMoneyTransfers: string
  monthlyOutgoingForeignMoneyTransfers: string
  hasMonthlyCashTransactions: boolean
  monthlyCashDeposits: string
  monthlyCashWithdrawals: string
}

export interface BasicInfoOnboarding extends BasicInfoQuestions {
  age: number
  initialInvestment: number
  monthlyInvestment: number
  withImpact: boolean
}

export interface PrivateMonetaryTransactions {
  hasMonthlyMoneyTransfersWithinFinland: boolean
  monthlyIncomingMoneyTransfersWithinFinland: string
  monthlyOutgoingMoneyTransfersWithinFinland: string
  hasMonthlyForeignMoneyTransfers: boolean
  monthlyIncomingForeignMoneyTransfers: string
  monthlyOutgoingForeignMoneyTransfers: string
  monthlyForeignMoneyTransfersCountries: string[]
  purposeOfForeignMoneyTransfers: string[]
  purposeOfForeignMoneyTransfersOther: string | null
  hasMonthlyCashTransactions: boolean
  monthlyCashDeposits: string
  monthlyCashWithdrawals: string
  intendedUseOfCash: string[]
  intendedUseOfCashOther: string
}

export interface BasicInfo extends BasicInfoQuestions {
  accountPurpose: string | null
  accountPurposeDescription: string | null
  bankAccountNumber: string | null
  calculatedPortfolioValue: number
  calculatedPortfolioValueAfterLoss: number
  customerId: string | null
  dualNationalityCountry: string | null
  levelOfEducation: string | null
  socioeconomicStatus: string | null
  esgHarmfulEmissionEffect: string | null
  esgSfdr: string | null
  esgStart: boolean
  esgTaxonomy: string | null
  extraTaxCountries: string | null
  extraTins: string | null
  foreignPostalServiceCountry: string | null
  foreignPowerOfAttorneyCountry: string | null
  hasDualNationality: boolean
  hasForeignPostalService: boolean
  hasForeignPowerOfAttorney: boolean
  hasUsEstate: boolean
  hasWorkOrResidencePermit: boolean
  isPep: boolean | null
  pepCountry: string | null
  pepPosition: string | null
  pepConnection: string | null
  profession: string | null
  sourceOfFundsBenefits: boolean
  sourceOfFundsExit: boolean
  sourceOfFundsGift: boolean
  sourceOfFundsIncome: boolean
  sourceOfFundsInheritance: boolean
  sourceOfFundsInvesting: boolean
  sourceOfFundsInvestments: boolean
  sourceOfFundsOther: boolean
  sourceOfFundsOtherDescription: string | null
  sourceOfFundsPension: boolean
  sourceOfFundsRealEstate: boolean
  taxCountry1: string | null
  taxCountry2: string | null
  taxCountry3: string | null
  tin1: string | null
  tin2: string | null
  tin3: string | null
  workOrResidencePermitCountry: string | null
  isAktiaMainBank: boolean | null
  privateCustomerMonetaryTransactionsInAktia: PrivateMonetaryTransactions
}

interface Contract {
  impactMax: number
}

export interface CustomerBasicInfoResponse {
  basicInfo: BasicInfo
  customer: CustomerInformation
  contract?: Contract
}
