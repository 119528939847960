import { observable, action, makeAutoObservable } from 'mobx'
import { create, persist } from 'mobx-persist'

class AdminStoreInternal {
  constructor() {
    makeAutoObservable(this)
  }
  @observable
  @persist
  token: string | undefined

  @observable
  @persist
  adminSurname?: string

  @observable
  @persist
  customerId?: string

  @action
  logoutAdmin = () => {
    this.token = undefined
    this.adminSurname = undefined
    this.customerId = undefined
  }
}

const hydrate = create({ localStorage, jsonify: true })
const AdminStore = new AdminStoreInternal()
export default AdminStore

const result = hydrate('adminStore', AdminStore)
export async function rehydrateQuestionStore() {
  await result.rehydrate()
}
