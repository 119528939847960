import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'

import UiButton from '../../ui/UiButton'

function ButtonRow({
  selectedText,
  texts,
  onClick,
}: {
  selectedText: string
  texts: string[]
  onClick: (text: string) => void
}) {
  const { t } = useTranslation()
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={{ marginHorizontal: -20 }}
    >
      <View
        style={{ marginHorizontal: Spacings.PADDING, flexDirection: 'row' }}
      >
        {texts.map((text, index) => {
          return (
            <UiButton
              key={index}
              title={t(text)}
              size={'small'}
              type={selectedText === text ? 'primary' : 'secondary'}
              onPress={() => {
                onClick(text)
              }}
              containerStyle={{
                marginRight: Spacings.S4,
              }}
            />
          )
        })}
      </View>
    </ScrollView>
  )
}

export default ButtonRow
