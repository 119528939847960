import { Order } from './Order'

interface Instrument {
  id: string
  securityName: string
}

interface Value {
  value: number
}

export interface OpenOrderResponse {
  value: Value
  orderId: string
  transactionType: string
  sellEverything: boolean
  instrument: Instrument
  createdOn: string
  amount: number
  dueDate: string
}

export function toOrders(openOrders: OpenOrderResponse[]): Order[] {
  const orders: Order[] = openOrders.map((openOrder) => {
    return {
      id: openOrder.orderId,
      amount: openOrder.value.value,
      securityId: openOrder.instrument.id,
      securityName: openOrder.instrument.securityName,
      sellEverything: openOrder.amount !== 0 && openOrder.value.value === 0,
      createdOn: openOrder.createdOn,
      orderType: openOrder.transactionType === 'Purchase' ? 'Buy' : 'Sell',
      paidOutOn: null,
      processedOn: null,
      approvedOn: null,
      isDeleted: false,
      dueDate: openOrder.dueDate,
    }
  })
  return orders
}
