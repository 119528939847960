import versionNumber from '@taaleri/components/src/screens/profile/versionNumber'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import { TextMedium } from '@taaleri/components/src/ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { View } from 'react-native'
import ContentContainer from 'ui/container/ContentContainer'

export default function VersionScreen() {
  return (
    <View style={{ marginTop: Spacings.S40 }}>
      <ContentContainer>
        <BoxWithPadding>
          <TextMedium>Versio {versionNumber}</TextMedium>
        </BoxWithPadding>
      </ContentContainer>
    </View>
  )
}
