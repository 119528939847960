import React from 'react'
import ProfileInvestmentProposalContent from '@taaleri/components/src/screens/profile/ProfileInvestmentProposalContent'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { routePathContract } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_CONTRACT_START } from '@taaleri/components/src/screens/contract/ContractRoutes'
import Title from '@taaleri/components/src/ui/text/Title'
import ContentContainer from 'ui/container/ContentContainer'
import { useTranslation } from 'react-i18next'

export default function ProfileInvestmentProposalScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <ContentContainer>
      <Title>{t('profile.investmentSection.invest-plan')}</Title>
      <ProfileInvestmentProposalContent
        onPress={() => navigate(routePathContract(ROUTE_CONTRACT_START))}
      />
    </ContentContainer>
  )
}
