
import CustomerNotFoundContent from '@taaleri/components/src/screens/signin/CustomerNotFoundContent'
import React from 'react'
import { View } from 'react-native'
import BackgroundContainer from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'

export default function CustomerNotFoundScreen() {
  return (
    <BackgroundContainer contentType="centered">
      <TopBottomContainer>
        <View>
          <CustomerNotFoundContent />
        </View>
      </TopBottomContainer>
    </BackgroundContainer>
  )
}
