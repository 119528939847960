import { fiKey } from '@taaleri/core/src/i18n'
import { ProfitItem } from '@taaleri/core/src/models/ProfitReport'
import AppStore from '@taaleri/core/src/stores/AppStore'
import {
  formatCurrency,
  formatDateWithoutLeadingZeros,
} from '@taaleri/core/src/utils/format'
import React from 'react'

import { RowItem } from './RowItem'
import TransactionRowContainer from '../../reporttransaction/Rows/TransactionRowContainer'

interface Props {
  item: ProfitItem
}

export function ProfitRow({ item }: Props) {
  const showInterest = ['Koronmaksu', 'Jälkimarkkinahyvitys'].includes(
    item.transactionTypeName
  )
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.securityNameFinnish
      : item.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item?.portfolioNameForCustomer
      : item?.portfolioNameForCustomerSv

  return (
    <TransactionRowContainer>
      <RowItem style={{ flex: 2 }}>{getPortfolioName()}</RowItem>
      <RowItem>{formatDateWithoutLeadingZeros(item.transactionDate)}</RowItem>
      <RowItem>{formatDateWithoutLeadingZeros(item.paymentDate)}</RowItem>
      <RowItem>{item.transactionTypeName}</RowItem>
      <RowItem style={{ flex: 2 }}>{getName()}</RowItem>
      <RowItem>
        {showInterest
          ? formatCurrency(item.accruedInterest, true)
          : formatCurrency(item.sellValue, true)}
      </RowItem>
      <RowItem>{formatCurrency(item.advanceTax, true)}</RowItem>
      <RowItem>
        {showInterest
          ? formatCurrency(item.accruedInterest - item.advanceTax, true)
          : formatCurrency(item.totalSellValue, true)}
      </RowItem>
    </TransactionRowContainer>
  )
}
