import { sleep } from '@taaleri/core/src/api/mockHelper'
import AdminCustomer from '@taaleri/core/src/models/AdminCustomer'
import IAdminApi from './IAdminApi'

const TestCustomers: AdminCustomer[] = []

async function searchCustomers() {
  await sleep(500)
  return Promise.resolve(TestCustomers)
}

async function pushToken() {
  return Promise.resolve({ value: '' })
}

export const adminApiMock: IAdminApi = {
  searchCustomers,
  pushToken,
}
