import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  assetClassColors,
  AssetClassPercentage,
} from '@taaleri/core/src/models/assetClassColors'
import { AssetClass, Portfolio } from '@taaleri/core/src/models/Portfolio'
import React from 'react'
import { View } from 'react-native'
import PercentagePieAsset from '../../components/PercentagePieAsset'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { BoxWithPadding } from '../../ui/box/Box'
import FlexRow from '../../ui/FlexRow'
import { TextMedium } from '../../ui/text/UiText'
import {
  toFullPercentageText,
  formatCurrencyNoDecimal,
} from '@taaleri/core/src/utils/format'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

interface PortfolioPieProps {
  portfolio: Portfolio
}
const assetClasslengthToPieHeight: { [key: number]: number } = {
  1: 192,
  2: 192,
  3: 169,
  4: 146,
  5: 123,
  6: 100,
}

export default function PortfolioPie({ portfolio }: PortfolioPieProps) {
  const { t } = useTranslation()
  const assetClasses: AssetClass[] = portfolio.assetClasses
  // For mock testing all
  // const assetClasses: AssetClass[] = TestAllAssetClass

  const pieHeight = assetClasslengthToPieHeight[assetClasses.length]

  const percentageColors: AssetClassPercentage[] = assetClasses.map((s) => ({
    id: s.id,
    name: s.name,
    color: assetClassColors[s.id] || Colors.text,
    percentage: s.percentageOfTotal,
  }))

  const lineHeight = FontSize.S14

  return (
    <BoxWithPadding
      style={{
        flex: 1,
      }}
      titleStyle={{ marginHorizontal: -20, paddingLeft: 20 }}
    >
      <View style={{ flex: 1 }}>
        <FlexRow
          style={{
            marginVertical: 'auto',
            paddingBottom: Spacings.S24,
            justifyContent: 'center',
          }}
        >
          <PercentagePieAsset
            style={{
              flex: 1,
              alignItems: 'center',
            }}
            assetClasses={percentageColors}
            percentage={0}
            size={pieHeight}
          />
        </FlexRow>

        <View style={{ justifyContent: 'flex-end' }}>
          {assetClasses.map((assetClass: AssetClass) => {
            const namePercentage = percentageColors.find(
              (n) => n.id === assetClass.id
            )
            const color = namePercentage
              ? assetClassColors[assetClass.id]
              : Colors.text
            return (
              <FlexRow
                key={assetClass.id}
                style={{
                  paddingTop: 7,
                  alignItems: 'center',
                }}
              >
                <View style={{ width: 22 }}>
                  <View
                    style={{
                      width: 10,
                      height: 10,
                      marginTop: isWeb ? 0 : -1,
                      marginRight: 12,
                      backgroundColor: color,
                      borderRadius: 5,
                    }}
                  />
                </View>
                <TextMedium
                  style={{
                    flex: 1,
                    fontSize: FontSize.S14,
                    lineHeight,
                  }}
                  numberOfLines={1}
                >
                  {t(assetClass.name)}
                </TextMedium>
                <TextMedium
                  size={FontSize.S14}
                  style={{
                    flex: 1,
                    textAlign: 'right',
                    fontSize: FontSize.S14,
                    lineHeight,
                  }}
                >
                  {formatCurrencyNoDecimal(assetClass.marketValue)}
                </TextMedium>
                {assetClass.percentageOfTotal < 100 && (
                  <TextMedium
                    style={{
                      marginLeft: Spacings.S16,
                      width: 48,
                      textAlign: 'right',
                      paddingLeft: Spacings.S8,
                      borderLeftColor: Colors.border,
                      borderLeftWidth: 1,
                      fontSize: FontSize.S14,
                      lineHeight,
                    }}
                  >{`${toFullPercentageText(
                    assetClass.percentageOfTotal
                  )} %`}</TextMedium>
                )}
              </FlexRow>
            )
          })}
        </View>
      </View>
    </BoxWithPadding>
  )
}
