interface Customer {
  customerId: number
  description: string
}

export const TEST_CUSTOMER_ID = 125849

export const TEST_CUSTOMERS: Customer[] = [
  {
    customerId: 125849,
    description: 'Maltillinen salkku - vain suorat osakkeet ja bondit',
  },
  {
    customerId: 492967,
    description: 'Yksi salkku -  eri tuotteita',
  },
  {
    customerId: 613745,
    description: 'Yksi salkku - useampi rahasto',
  },
  {
    customerId: 647123,
    description: 'Yksi salkku - yksi osake',
  },
  {
    customerId: 518258,
    description: 'Yksi salkku - yksi rahasto',
  },
  {
    customerId: 100248,
    description: 'Yksi salkku - pelkkiä pääomarahastoja',
  },
  {
    customerId: 614297,
    description: 'Fellow Finance + Taalerin rahasto',
  },
  {
    customerId: 1078286,
    description: 'Uusi asiakas - tyhjä salkku',
  },
]
