import React, { useState } from 'react'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { View } from 'react-native'
import PhotoIdUpload from './PhotoIdUpload'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { TextError } from '@taaleri/components/src/ui/text/UiText'
import ConfirmPassIdentityModal from '@taaleri/components/src/screens/onboarding/ConfirmPassIdentityModal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Buttons from 'ui/Buttons'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Type = 'onboarding' | 'contract'

interface Props {
  nextRoute: string
  type: Type
}

function PhotoIdContent({ nextRoute, type }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const history = useHistory()
  const isOnboarding = type === 'onboarding'
  return (
    <TopBottomContainer>
      <ConfirmPassIdentityModal
        visible={showModal}
        onYes={() => {
          setShowModal(false)
        }}
        onNo={() => {
          setShowModal(false)
          history.push(routePath(ROUTE_APP))
        }}
      />
      <View>
        <PhotoIdUpload />
        {!AppStore.photoId && error && (
          <TextError style={{ marginVertical: Spacings.S16 }}>
            {t('idUpdate.id-missing')}
          </TextError>
        )}
      </View>

      <Buttons
        title={t('button.next')}
        onPress={() => {
          if (AppStore.photoId) {
            history.push(nextRoute)
          } else {
            setError(true)
          }
        }}
        titleSecondary={isOnboarding ? `${t('idUpdate.later')}` : undefined}
        onPressSecondary={
          isOnboarding
            ? () => {
                setShowModal(true)
              }
            : undefined
        }
      />
    </TopBottomContainer>
  )
}

export default observer(PhotoIdContent)
