import React from 'react'
import { View, StyleSheet } from 'react-native'
import { TextBold, TextDefault } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

interface Benefit {
  title: string
  content: string
}

const Benefits: Benefit[] = [
  {
    title: 'impact.benefit-economic',
    content:
      'impact.benefit-economic-content',
  },
  {
    title: 'impact.benefit-responsible',
    content:
      'impact.benefit-responsible-content',
  },
  {
    title: 'comparison.social-benefit',
    content:
      'impact.benefit-social-content',
  },
]

export default function ImpactBenefits({ isWide }: { isWide: boolean }) {
  const { t } = useTranslation()
  const textAlign = isWide ? 'center' : 'left'
  return (
    <View style={{ paddingBottom: Spacings.S24 }}>
      <TextDefault
        type="h2"
        style={[styles.heading, isWide && styles.headingLarge, { textAlign }]}
      >
        {t('impactIntro.infobox2.header')}
      </TextDefault>
      <View style={{ flexDirection: isWide ? 'row' : 'column' }}>
        {Benefits.map((b, index) => (
          <View
            key={b.title}
            style={[
              styles.benefit,
              isWide &&
                index === 1 && { borderLeftWidth: 0, borderRightWidth: 0 },
              !isWide &&
                index === 1 && { borderTopWidth: 0, borderBottomWidth: 0 },
            ]}
          >
            <FlexRow style={{ margin: 'auto' }}>
              <TextBold style={[styles.number, isWide && styles.numberLarge]}>
                {index + 1}
              </TextBold>
              <View style={{ flex: 1, marginRight: Spacings.S24 }}>
                <TextBold style={styles.title}>{t(b.title)}</TextBold>
                <TextDefault type="t2" color={Colors.textSecondary}>
                  {t(b.content)}
                </TextDefault>
              </View>
            </FlexRow>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    marginBottom: Spacings.S40,
  },
  headingLarge: {
    marginBottom: Spacings.S56,
  },
  benefit: {
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.border,
    paddingVertical: Spacings.S24,
  },
  number: {
    flexBasis: 70,
    fontSize: FontSize.S48,
    lineHeight: FontSize.S48,
    textAlign: 'center',
    color: Colors.backgroundGreen,
    alignSelf: 'center',
  },
  numberLarge: {
    flexBasis: 100,
  },
  title: { fontSize: FontSize.S16, marginBottom: Spacings.S4 },
})
