import Colors from '@taaleri/core/src/constants/Colors'
import React, { useState } from 'react'
import useLayout from '../../ui/useLayout'
import { View, StyleProp, ViewStyle } from 'react-native'
import {
  LineSegment,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryVoronoiContainer,
  VictoryLine,
} from '@taaleri/components/src/ui/Victory'
import { formatDateWithoutLeadingZeros } from '@taaleri/core/src/utils/format'
import { Defs, Svg, G } from 'react-native-svg'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import { DateValue } from '@taaleri/core/src/models/DateValue'
import { KeyFigureType } from '@taaleri/core/src/constants/KeyFigures'

const lineStyle = {
  stroke: Colors.chartLineGrey,
  strokeWidth: 1,
}

// Plot dash line when y = 0 and normal line otherwise
const CustomLineSegment = (props: any) => {
  const dashStyle = {
    stroke: Colors.gray70,
    strokeDasharray: '1,3',
    strokeWidth: 1,
    strokeOpacity: 1,
  }

  const style = props.datum === 100 ? dashStyle : lineStyle
  return <LineSegment {...props} style={style} />
}

const CustomLabelComponent = (props: any) => {
  const {
    style,
    datum,
    formatTooltipValue,
    x,
    scale,
    activePoints,
    colors,
    showSimpleTooltip,
  } = props
  const { date } = datum
  const space = 75
  const boxWidth = space / 3 + activePoints.length * space
  return props ? (
    <>
      <Svg style={style}>
        <Defs>
          <filter id="dropShadow">
            <feDropShadow
              dx={0}
              dy={2}
              stdDeviation={4}
              floodColor="rgba(19, 26, 45)"
              floodOpacity={0.08}
            />
          </filter>
        </Defs>
        <G>
          <rect
            style={{ filter: 'url(#dropShadow)' }}
            x={props.x - boxWidth / 2}
            y={-10}
            rx={2}
            ry={2}
            width={boxWidth}
            height={55}
            fill={Colors.white}
          />
          <VictoryLabel
            {...props}
            text={formatDateWithoutLeadingZeros(date)}
            style={{
              fontFamily: 'calibre-semibold',
              color: Colors.text,
            }}
            y={10}
            textAnchor={'middle'}
          />

          {activePoints.map((point: any, index: number) => {
            const key = point.childName
            if (showSimpleTooltip && key !== 'data') {
              return null
            }
            const dx = ((-activePoints.length + 1) * space) / 2 + index * space
            return (
              <G key={key}>
                <rect
                  style={{ filter: 'url(#dropShadow)' }}
                  x={props.x + dx - (space - 10) / 2}
                  y={20}
                  rx={2}
                  ry={2}
                  width={space - 10}
                  height={20}
                  fill={showSimpleTooltip ? 'none' : colors[key]}
                />
                <VictoryLabel
                  {...props}
                  text={formatTooltipValue(point.value)}
                  style={{
                    fontFamily: 'calibre-semibold',
                    fill: showSimpleTooltip ? colors[key] : Colors.white,
                  }}
                  y={30}
                  dx={dx}
                  textAnchor={'middle'}
                />
              </G>
            )
          })}
        </G>
      </Svg>
      <LineSegment
        style={{
          stroke: Colors.chartLineGrey,
          strokeWidth: 1,
        }}
        x1={x}
        x2={x}
        y1={Math.max(...scale.y.range())}
        y2={Math.min(...scale.y.range()) + 40}
      />
    </>
  ) : null
}

interface Props {
  data: DateValue[]
  benchMark?: DateValue[]
  portfolioData?: DateValue[]
  dateFormatFunction: (date: Date) => string
  valueFormatFunction: (value: number) => string
  formatTooltipValue: (value: number) => string
  containerStyle?: StyleProp<ViewStyle>
  domainBottom: number
  domainTop: number
  color: string
  graphType: KeyFigureType
  showPortfolioChart: boolean
}

function getDomain(
  data: DateValue[] = [],
  benchMark: DateValue[] = [],
  portfolioData: DateValue[] = []
): { domainBottom: number; domainTop: number } {
  const minValue = Math.min.apply(
    null,
    [...data, ...benchMark, ...portfolioData].map(
      (historyItem: DateValue) => historyItem.value
    )
  )
  const maxValue = Math.max.apply(
    null,
    [...data, ...benchMark, ...portfolioData].map(
      (historyItem: DateValue) => historyItem.value
    )
  )
  const domainBottom = minValue - Math.max((maxValue - minValue) * 0.1, 1)
  const domainTop = maxValue + Math.max((maxValue - minValue) * 0.1, 1)
  return { domainBottom, domainTop }
}

export default function ReportChart({
  data,
  benchMark,
  portfolioData,
  dateFormatFunction,
  valueFormatFunction,
  formatTooltipValue,
  containerStyle,
  color,
  graphType,
  showPortfolioChart,
}: Props) {
  /*
  VR: 15.11.2019
  VictoryChart cannot handle touch events automatically on mobile devices,
  which is why the tooltip has to set be visible/hidden manually 
  */
  // Chart width and height must be set manually
  const [width, setWidth] = useState(0)
  const { isSmall, isWide } = useLayout()
  const tickCount = isSmall ? 3 : 5
  const fontSize = isSmall ? FontSize.S10 : FontSize.S12

  const { domainBottom, domainTop } = getDomain(
    data,
    benchMark,
    showPortfolioChart ? portfolioData : []
  )

  const yAxisStyle = {
    axis: { stroke: 'transparent' },
    tickLabels: {
      fill: Colors.text,
      fontFamily: Fonts.avenirNextMedium,
      fontSize,
      textAnchor: 'end',
    },
    grid: {
      stroke: Colors.border,
      strokeWidth: 0.7,
      strokeOpacity: 1,
    },
  }

  const xAxisStyle = {
    axis: { stroke: 'transparent' },
    tickLabels: {
      fill: Colors.text,
      fontFamily: Fonts.avenirNextMedium,
      fontSize,
      textAnchor: 'start',
    },
  }

  const isMarketValue = graphType === 'MARKET_VALUE'
  const isSecurityPrice = graphType === 'SECURITY_PRICE'

  return (
    <View
      onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
      style={[
        { ...{ WebkitUserSelect: 'none', height: '100%' } },
        containerStyle,
      ]}
      pointerEvents="none"
    >
      <VictoryChart
        padding={{ top: 0, bottom: 25, left: 25, right: 50 }}
        maxDomain={{ y: domainTop }}
        minDomain={{ y: domainBottom }}
        // Use hardcoded height for medium and small screen
        height={isWide ? 334 : 224}
        width={width}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            voronoiBlacklist={
              isMarketValue ? ['benchmark', 'portfolioData'] : []
            }
            // labels must be defined in order to show tooltip
            labels={({ datum }) => {
              return datum.date
            }}
            labelComponent={
              <CustomLabelComponent
                formatTooltipValue={formatTooltipValue}
                colors={{
                  benchmark: Colors.inactiveTint,
                  data: color,
                  portfolioData: Colors.gray80,
                }}
                showSimpleTooltip={isMarketValue || isSecurityPrice}
              />
            }
          />
        }
      >
        <VictoryAxis
          dependentAxis={true}
          offsetX={width}
          style={yAxisStyle}
          tickFormat={(t: number): string => valueFormatFunction(t)}
          gridComponent={<CustomLineSegment />}
        />
        <VictoryAxis
          style={xAxisStyle}
          tickFormat={(date: Date): string => dateFormatFunction(date)}
          tickCount={tickCount}
          offsetY={25}
          orientation={'bottom'}
        />

        {benchMark && benchMark.length > 2 ? (
          <VictoryLine
            name="benchmark"
            style={{ data: { stroke: Colors.inactiveTint, strokeWidth: 2 } }}
            data={benchMark}
            x="date"
            y="value"
          />
        ) : null}
        {showPortfolioChart && portfolioData && portfolioData.length > 2 ? (
          <VictoryLine
            name="portfolioData"
            style={{ data: { stroke: Colors.gray80, strokeWidth: 2 } }}
            data={portfolioData}
            x="date"
            y="value"
          />
        ) : null}
        <VictoryLine
          name="data"
          style={{ data: { stroke: color, strokeWidth: 2 } }}
          data={data}
          x="date"
          y="value"
        />
      </VictoryChart>
    </View>
  )
}
