import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import SmsVerification from '../contract/SmsVerification'
import {
  ROUTE_FUNDS_INTRO,
  ROUTE_MONTHLY_CONTRACT_PAYMENT,
} from './FundsRoutes'
import useNavigation from '../../navigation/useNavigation'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { useTranslation } from 'react-i18next'

export function MonthlyContractVerification() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const onSuccess = async () => {
    AppStore.updateFeedCache = true
    Analytics.event('Profile', 'Start recurring order')
    navigateApp(ROUTE_MONTHLY_CONTRACT_PAYMENT)
  }
  const phone = AppStore.customer.phone
  const title = t('invest.confirm-transaction')
  const description = t('invest::confirmation-sent-desc', { phone: phone })
  return (
    <>
      <SmsVerification
        t={t}
        title={title}
        description={description}
        phone={phone}
        onSuccess={() => onSuccess()}
        afterConflictNavigateTo={ROUTE_FUNDS_INTRO}
        onPrepareVerification={async () => {
          if (AppStore.smsConfirmationId) {
            return AppStore.smsConfirmationId
          }
          return null
        }}
        navigate={navigateApp}
      />
    </>
  )
}
