import React from 'react'

import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import ContactCustomerService from '../../ui/ContactCustomerService'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

export default function ExistingOrderContent() {
  const { t } = useTranslation()
  return (
    <View>
      <Title style={{ marginTop: isWeb ? 24 : 100, textAlign: 'center' }}>
        {t('invest.assignment-in-process')}
      </Title>

      <Paragraph>
        {t('invest.cant-create-assignment')}
      </Paragraph>
      <ContactCustomerService />
    </View>
  )
}
