import React, { useState } from 'react'

import QuestionContainer from 'screens/questions/QuestionContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { Formik } from 'formik'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import SumModel from '@taaleri/components/src/screens/portfolio/SumModel'
import paymentSumValidationSchema from '@taaleri/components/src/screens/portfolio/paymentSumValidationSchema'
import OnboardingPaymentSumContent from '@taaleri/components/src/screens/onboarding/OnboardingPaymentSumContent'
import Buttons from 'ui/Buttons'
import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_PAYMENT_SUM,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { useHistory } from 'react-router-dom'
import { TextDefault, TextError } from '@taaleri/components/src/ui/text/UiText'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function OnboardingPaymentSumScreen() {
  const { t } = useTranslation()
  const [warning, setWarning] = useState<boolean>()
  const history = useHistory()
  const initialValues: SumModel = {
    sum: QuestionStore.initialInvestment.toString(),
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={paymentSumValidationSchema(t)}
      onSubmit={(values: SumModel) => {
        AppStore.newInvestment = Number(values.sum)
        AppStore.investmentMode = 'default'
        if (warning === undefined && AppStore.newInvestment < 1000) {
          setWarning(true)
          return
        }
        history.push(nextOnboardingRoute(ROUTE_ONBOARDING_PAYMENT_SUM))
      }}
    >
      {() => (
        <UiForm>
          <QuestionContainer>
            <TopBottomContainer>
              <View>
                <OnboardingPaymentSumContent />
                <TextDefault type="h5" style={{ marginBottom: Spacings.S32 }}>
                  Kertasijoitus
                </TextDefault>

                <View>
                  <UiTextInputForm
                    name="sum"
                    label={`${t('invest.sum-title')}`}
                    type="digits"
                    grayBackground={true}
                    returnKeyType="done"
                  />
                </View>
                {warning && <TextError>{t('invest.invest-remark')}</TextError>}
              </View>
              {/*<Box>
                <ValueLabel
                  label="Salkku"
                  value={AppStore.hasImpactStrategy ? 'Impakti' : 'ETF'}
                />
                <ValueLabel
                  label="Riskitaso"
                  value={AppStore.riskLevel.toFixed(0)}
                  hidden={AppStore.riskLevel === 0}
                />
              </Box>*/}
              <Buttons
                title={t('button.next')}
                titleSecondary={`${t('button.first-invest-later')}`}
                onPressSecondary={() => history.push(routePath(ROUTE_APP))}
              />
            </TopBottomContainer>
          </QuestionContainer>
        </UiForm>
      )}
    </Formik>
  )
}

export default observer(OnboardingPaymentSumScreen)
