import React from 'react'
import ChangeUsernameDoneContent from '@taaleri/components/src/screens/profile/ChangeUsernameDoneContent'
import DoneScreen from './DoneScreen'

export default function ChangeUsernameDoneScreen() {
  return (
    <DoneScreen>
      <ChangeUsernameDoneContent />
    </DoneScreen>
  )
}
