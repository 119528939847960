import { isNarrowDevice } from '../utils/dimensions'

const avenirNextMedium = 'calibre-regular'
const avenirNextDemiBold = 'calibre-medium'
export const avenirNextBold = 'calibre-semibold'

export default {
  avenirNextMedium,
  avenirNextBold,
  avenirNextDemiBold,
}

export enum FontSize {
  S8 = 10,
  S10 = 12,
  S12 = 14,
  S14 = 16,
  S16 = 18,
  BASE = 18,
  S18 = 20,
  S20 = 22,
  S24 = 26,
  S32 = 34,
  S34 = 36,
  S48 = 50,
}

export function SizeLargeResponsive() {
  return isNarrowDevice() ? 18 : FontSize.S20
}
