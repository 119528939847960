import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import { IconProps } from '../IconProps'

/*
VR: 18.5.2021
React-native-svg has a bug, which makes every svg focusable. If a svg element is placed inside of TextInput,
tab press moves focus to svg instead of next input element. More info:
https://github.com/react-native-svg/react-native-svg/issues/1471
 */

export function IconVisibilityOff({ color = Colors.text }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69 6.54995L2.01 3.86995L3.42 2.44995L21.15 20.19L19.74 21.6L16.32 18.18C14.98 18.71 13.52 19 12 19C7 19 2.73 15.89 1 11.5C1.77 9.52995 3.06 7.82995 4.69 6.54995ZM11.9999 5.99994C15.7899 5.99994 19.1699 8.12994 20.8199 11.4999C20.2299 12.7199 19.3999 13.7699 18.4099 14.6199L19.8199 16.0299C21.2099 14.7999 22.3099 13.2599 22.9999 11.4999C21.2699 7.10994 16.9999 3.99994 11.9999 3.99994C10.7299 3.99994 9.50986 4.19994 8.35986 4.56994L10.0099 6.21994C10.6599 6.08994 11.3199 5.99994 11.9999 5.99994ZM10.9302 7.13994L13.0002 9.20994C13.5702 9.45994 14.0302 9.91994 14.2802 10.4899L16.3502 12.5599C16.4302 12.2199 16.4902 11.8599 16.4902 11.4899C16.5002 9.00994 14.4802 6.99994 12.0002 6.99994C11.6302 6.99994 11.2802 7.04994 10.9302 7.13994ZM9.51 11.3699L12.12 13.9799C12.08 13.9899 12.04 13.9999 12 13.9999C10.62 13.9999 9.5 12.8799 9.5 11.4999C9.5 11.4749 9.5025 11.4549 9.505 11.435L9.505 11.4349L9.505 11.4349C9.5075 11.4149 9.51 11.3949 9.51 11.3699ZM7.86018 9.71997L6.11018 7.96997C4.90018 8.88997 3.88018 10.07 3.18018 11.5C4.83018 14.87 8.21018 17 12.0002 17C12.9502 17 13.8702 16.86 14.7502 16.62L13.7702 15.64C13.2302 15.87 12.6302 16 12.0002 16C9.52018 16 7.50018 13.98 7.50018 11.5C7.50018 10.87 7.63018 10.27 7.86018 9.71997Z"
        fill={color}
      />
    </svg>
  )
}

export function IconVisibilityOn({ color = Colors.text }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.5C2.73 7.11 7 4 12 4C17 4 21.27 7.11 23 11.5C21.27 15.89 17 19 12 19C7 19 2.73 15.89 1 11.5ZM20.8202 11.5C19.1702 8.13 15.7902 6 12.0002 6C8.21018 6 4.83018 8.13 3.18018 11.5C4.83018 14.87 8.21018 17 12.0002 17C15.7902 17 19.1702 14.87 20.8202 11.5ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM7.5 11.5C7.5 9.02 9.52 7 12 7C14.48 7 16.5 9.02 16.5 11.5C16.5 13.98 14.48 16 12 16C9.52 16 7.5 13.98 7.5 11.5Z"
        fill={color}
      />
    </svg>
  )
}
