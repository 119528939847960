import Spacings from '@taaleri/core/src/constants/Spacings'
import { OptionType } from '@taaleri/core/src/models/OptionType'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { Formik, FormikHelpers } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReportValues } from './DownloadReportButton'
import FlexRow from '../../ui/FlexRow'
import UiButton from '../../ui/UiButton'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import UiForm from '../../ui/form/UiForm'
import UiListItemSwitch from '../../ui/form/UiListItemSwitch'
import UiModal from '../../ui/modal/UiModal'
import { TextDefault } from '../../ui/text/UiText'

interface Props {
  isVisible: boolean
  setVisible: (visible: boolean) => void
  onSubmit: (values: ReportValues, actions: FormikHelpers<ReportValues>) => void
}

const FILE_FORMAT_OPTIONS: OptionType[] = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Excel', value: 'excel' },
]

const REPORT_TYPE_OPTIONS: OptionType[] = [
  { label: 'portfolio.report-summary', value: 'summaryNew' },
  { label: 'portfolio.report-purchaselot', value: 'purchaselots' },
]

const initialValues = (): ReportValues => {
  return {
    fileFormat: 'pdf',
    reportType:
      AppStore.IsIncomeReportHidden() === true ? 'purchaselots' : 'summary',
    byPortfolio: false,
    byPurchaselots: false,
    loadBenchmarkIndexData: false,
  }
}

export function DownloadSummaryReportModal({
  isVisible,
  setVisible,
  onSubmit,
}: Props) {
  const { t } = useTranslation()
  return (
    <UiModal
      isVisible={isVisible}
      onBackButtonPress={() => setVisible(false)}
      useNativeDriver={true}
      onBackdropPress={() => setVisible(false)}
    >
      <Formik initialValues={initialValues()} onSubmit={onSubmit}>
        {({ handleSubmit, values }) => {
          const purchaseLotsSelected = values.reportType === 'purchaselots'
          const pdfSelected = values.fileFormat === 'pdf'
          return (
            <UiForm>
              <FlexRow style={{ marginBottom: Spacings.S20 }}>
                <TextDefault type="h3">
                  {t('portfolio.load-report')}
                </TextDefault>
              </FlexRow>
              <BoxWithPaddingMargin title={`${t('portfolio.report-title')}`}>
                <CheckListSingleForm
                  name="reportType"
                  options={REPORT_TYPE_OPTIONS}
                  lastBottomDivider={false}
                />
              </BoxWithPaddingMargin>
              {purchaseLotsSelected && (
                <BoxWithPaddingMargin title={`${t('portfolio.report-format')}`}>
                  <CheckListSingleForm
                    name="fileFormat"
                    options={FILE_FORMAT_OPTIONS}
                    lastBottomDivider={false}
                  />
                </BoxWithPaddingMargin>
              )}
              {purchaseLotsSelected && (
                <BoxWithPaddingMargin
                  title={`${t('portfolio.report-itemized')}`}
                >
                  {pdfSelected && (
                    <UiListItemSwitch
                      name="byPortfolio"
                      title={`${t('portfolio.report-per-portfolio')}`}
                      topDivider={false}
                      bottomDivider={false}
                    />
                  )}
                  <UiListItemSwitch
                    name="byPurchaselots"
                    title={`${t('portfolio.report-per-purchaselot')}`}
                    topDivider={false}
                    bottomDivider={false}
                  />
                </BoxWithPaddingMargin>
              )}
              {purchaseLotsSelected || (
                <BoxWithPaddingMargin title={`${t('portfolio.report-more')}`}>
                  <UiListItemSwitch
                    name="loadBenchmarkIndexData"
                    title={`${t('portfolio.report-benchmark-index')}`}
                    topDivider={false}
                    bottomDivider={false}
                  />
                </BoxWithPaddingMargin>
              )}
              <UiButton
                title={t('portfolio.load-report')}
                onPress={handleSubmit}
              />
            </UiForm>
          )
        }}
      </Formik>
      <UiButton
        title={t('button.cancel')}
        type="secondaryplain"
        onPress={() => setVisible(false)}
      />
    </UiModal>
  )
}
