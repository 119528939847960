import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import { ManagementFee } from '@taaleri/core/src/models/FeeReport'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatCurrency, formatDateFull } from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import FlexRow from '../../ui/FlexRow'
import UiButton from '../../ui/UiButton'
import Box from '../../ui/box/Box'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

interface Props {
  list: ManagementFee[]
}

export default function ReportFeeList({ list }: Props) {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const { isWide } = useLayout()
  const rowsPerPage = 20

  const allRows = list.length
  const noItems = list.length === 0
  const showedRows = Math.min(page * rowsPerPage, allRows)

  return (
    <View style={{ marginTop: Spacings.S40 }}>
      {isWide ? ManagementFeeHeader(t) : ManagementFeeHeaderMobile(t)}
      <Box>
        {noItems ? (
          <TextDefault type="h5" style={{ flex: 2, padding: Spacings.S20 }}>
            {t('reportBox.no-events')}
          </TextDefault>
        ) : (
          list.slice(0, page * rowsPerPage).map((item, index) => {
            return isWide
              ? ManagementFeeRow(item, index, t)
              : ManagementFeeRowMobile(item, index, t)
          })
        )}
      </Box>
      <View style={{ alignItems: 'center' }}>
        <TextDefault type="t2" style={{ marginTop: Spacings.S24 }}>{`${t(
          'report.showing'
        )} ${showedRows}/${allRows}`}</TextDefault>
        {allRows > showedRows && (
          <UiButton
            title={t('button.load-more')}
            onPress={() => setPage(page + 1)}
            containerStyle={{
              marginTop: Spacings.S24,
              width: '100%',
              maxWidth: 327,
            }}
          />
        )}
      </View>
    </View>
  )
}

function ManagementFeeRow(fee: ManagementFee, index: number, t: TFunction) {
  return (
    <FlexRow
      key={index}
      style={{
        flex: 5,
        padding: Spacings.S20,
        alignItems: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
      }}
    >
      <FlexRow style={{ flex: 1, alignItems: 'space-between' }}>
        <TextDefault
          type="h7"
          style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
        >
          {t('reportFee.asset-management-fee')}
        </TextDefault>
        <TextDefault
          type="t3"
          style={{
            flex: 1,
            paddingHorizontal: Spacings.S2,
            alignSelf: 'center',
          }}
        >
          {formatDateFull(fee.date)}
        </TextDefault>
      </FlexRow>
      <TextDefault
        type="t3"
        style={{
          flex: 4,
          paddingLeft: Spacings.S2,
          color: fee.fee < 0 ? Colors.error : Colors.text,
          textAlign: 'right',
          alignSelf: 'center',
        }}
      >
        {formatCurrency(fee.fee, true)}
      </TextDefault>
    </FlexRow>
  )
}

function ManagementFeeHeader(t: TFunction) {
  return (
    <FlexRow
      style={{
        flex: 5,
        padding: Spacings.S20,
        alignItems: 'space-between',
      }}
    >
      <FlexRow style={{ flex: 1, alignItems: 'space-between' }}>
        <TextDefault
          type="h6"
          style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
        >
          {t('report.event')}
        </TextDefault>
        <TextDefault
          type="t5"
          style={{
            flex: 1,
            paddingHorizontal: Spacings.S2,
            alignSelf: 'center',
          }}
        >
          {t('form.day')}
        </TextDefault>
      </FlexRow>
      <TextDefault
        type="t5"
        style={{
          flex: 4,
          paddingLeft: Spacings.S2,
          color: Colors.text,
          textAlign: 'right',
          alignSelf: 'center',
        }}
      >
        {t('steps.sum')}
      </TextDefault>
    </FlexRow>
  )
}

function ManagementFeeRowMobile(
  fee: ManagementFee,
  index: number,
  t: TFunction
) {
  const getReason = (): string =>
    AppStore.currentLanguage === fiKey ? fee.reason : fee.reasonSwedish

  return (
    <FlexRow
      key={index}
      style={{
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
        padding: Spacings.S16,
      }}
    >
      <View style={{ flexDirection: 'column' }}>
        <TextDefault
          type="t3"
          style={{
            flex: 1,
            paddingHorizontal: Spacings.S2,
          }}
        >
          {formatDateFull(fee.date)}
        </TextDefault>
        <TextDefault
          type="h7"
          style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
        >
          {getReason()}
        </TextDefault>
      </View>
      <TextDefault
        type="t3"
        style={{
          flex: 1,
          paddingLeft: Spacings.S2,
          color: fee.fee < 0 ? Colors.error : Colors.text,
          textAlign: 'right',
          alignSelf: 'center',
        }}
      >
        {formatCurrency(fee.fee, true)}
      </TextDefault>
    </FlexRow>
  )
}

function ManagementFeeHeaderMobile(t: TFunction) {
  return (
    <FlexRow
      style={{
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
        padding: Spacings.S16,
      }}
    >
      <TextDefault
        type="h6"
        style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
      >
        {t('report.event')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{
          flex: 4,
          paddingLeft: Spacings.S2,
          color: Colors.text,
          textAlign: 'right',
          alignSelf: 'center',
        }}
      >
        {t('steps.sum')}
      </TextDefault>
    </FlexRow>
  )
}
