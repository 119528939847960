import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isNarrowDevice } from '@taaleri/core/src/utils/dimensions'
import isFunction from 'lodash/isFunction'
import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import styled, { css } from 'styled-components'
import AllFormErrors from './form/AllFormErrors'
import {
  ButtonAbsoluteProps,
  ButtonSize,
  ButtonType,
  UiButtonProps,
} from './UiButtonProps'

const Button = styled.button<{
  buttontype: ButtonType
  size: ButtonSize
  backgroundColor?: string
}>`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  display: flex;
  flex-direction: column;
  border-radius: ${BorderRadius.default}px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  padding: 0 ${Spacings.S24}px;
  height: ${(props) => (props.size === 'small' ? 40 : 56)}px;

  ${(props) =>
    props.buttontype === 'primary' &&
    css`
      background: ${props.backgroundColor || Colors.primary};
      color: ${Colors.white};
      :hover {
        background-color: ${props.backgroundColor || Colors.textPrimaryHover};
        opacity: ${props.backgroundColor ? 0.7 : 1};
      }
      :active {
        background: ${props.backgroundColor || Colors.primary};
        box-shadow: inset 0px 0px 15px rgba(19, 26, 45, 0.15);
      }
    `}

  ${(props) =>
    props.buttontype === 'secondary' &&
    css`
      border: 1px solid ${Colors.text};
      background-color: transparent;
      @media (hover: hover) and (pointer: fine) {
        :hover {
          background: rgba(71, 55, 41, 0.05);
        }
        :active {
          background: rgba(71, 55, 41, 0.15);
        }
      }
    `}

    ${(props) =>
    props.buttontype === 'primaryplain' &&
    css`
      background-color: transparent;
    `}

    ${(props) =>
    props.buttontype === 'secondaryplain' &&
    css`
      background-color: transparent;
    `}

      ${(props) =>
    props.buttontype === 'secondarywhite' &&
    css`
      border: 1px solid ${Colors.white};
      background-color: transparent;
      @media (hover: hover) and (pointer: fine) {
        :hover {
          box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.15),
            0px 2px 2px rgba(0, 0, 0, 0.1);
        }
        :active {
          box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.12);
        }
      }
    `}
      ${(props) =>
    props.buttontype === 'disabled' &&
    css`
      background: ${Colors.gray20};
      background-color: ${Colors.gray20};
      pointer-events: none;
    `}
`

const Text = styled.div<{
  type: ButtonType
  size: ButtonSize
  textColor?: string
}>`
  font-family: ${Fonts.avenirNextBold};
  font-size: ${(props) =>
    props.size === 'small' ? FontSize.S14 : FontSize.BASE}px;
  color: ${Colors.text};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.size === 'small' ? 40 : 56)}px;
  ${(props) =>
    props.type === 'primaryplain' &&
    css`
      color: ${Colors.primary};
    `}
  ${(props) =>
    props.type === 'primary' &&
    css`
      color: ${props.textColor || Colors.white};
    `}
  ${(props) =>
    props.type === 'secondaryplain' &&
    css`
      :hover {
        color: ${Colors.textHover};
      }
    `}

  ${(props) =>
    props.type === 'secondarywhite' &&
    css`
      color: ${Colors.white};
    `}

    ${(props) =>
    props.type === 'disabled' &&
    css`
      color: ${Colors.white};
    `}
`

const ButtonFlex = styled.button<{
  backgroundColor?: string
}>`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: ${BorderRadius.default}px;
  background: ${(props) => props.backgroundColor || 'transparent'};
  color: ${Colors.white};
  border: 1px solid ${Colors.text};
  :hover {
    background-color: ${(props) => props.backgroundColor || Colors.gray10};
    opacity: ${(props) => (props.backgroundColor ? 0.7 : 1)};
  }
  :active {
    background: ${(props) => props.backgroundColor || 'transparent'};
    box-shadow: inset 0px 0px 15px rgba(19, 26, 45, 0.15);
  }
`
export const UiButtonAppAbsolute = UiButton

export default function UiButton(props: UiButtonProps) {
  const {
    onPress,
    title,
    containerStyle,
    loading,
    textStyle,
    disabled,
    backgroundColor,
    textColor,
    id,
  } = props
  const type: ButtonType = props.type || 'primary'
  const size: ButtonSize = props.size || 'default'

  return (
    <Button
      type={
        props.isSubmitButton || props.isSubmitButton === undefined
          ? 'submit'
          : 'button'
      }
      buttontype={type}
      size={size}
      backgroundColor={backgroundColor}
      style={StyleSheet.flatten(containerStyle)}
      onClick={() => {
        if (onPress && isFunction(onPress)) {
          setTimeout(() => {
            onPress()
          }, 0)
        }
      }}
      disabled={disabled}
      id={id}
    >
      {!loading && (
        <Text
          type={type}
          size={size}
          textColor={textColor}
          style={StyleSheet.flatten(textStyle)}
        >
          {title}
        </Text>
      )}

      {loading && (
        <ActivityIndicator
          animating={true}
          color={
            type === 'secondary' ||
            type === 'secondaryplain' ||
            type === 'secondarywhite'
              ? Colors.text
              : Colors.white
          }
        />
      )}
    </Button>
  )
}

export const ButtonAbsoluteWithSecondary: React.FC<ButtonAbsoluteProps> = (
  props
) => {
  const isSmallDevice = isNarrowDevice()
  return (
    <UiButton
      {...props}
      containerStyle={{
        position: 'absolute',
        bottom: 70,
        right: isSmallDevice ? Spacings.S16 : Spacings.S24,
        left: isSmallDevice ? Spacings.S16 : Spacings.S24,
      }}
    />
  )
}

export const ButtonAbsolute: React.FC<ButtonAbsoluteProps> = (props) => {
  const { withoutGradient, ...uiButtonProps } = props
  const isSmallDevice = isNarrowDevice()
  return !withoutGradient ? (
    <UiButton
      {...uiButtonProps}
      containerStyle={{
        position: 'absolute',
        bottom: isSmallDevice ? 20 : 40,
        right: isSmallDevice ? Spacings.S16 : Spacings.S24,
        left: isSmallDevice ? Spacings.S16 : Spacings.S24,
      }}
    />
  ) : (
    <UiButton
      {...uiButtonProps}
      containerStyle={{
        position: 'absolute',
        bottom: isSmallDevice ? 20 : 40,
        right: isSmallDevice ? Spacings.S16 : Spacings.S24,
        left: isSmallDevice ? Spacings.S16 : Spacings.S24,
      }}
    />
  )
}

export const ButtonAction: React.FC<UiButtonProps> = (props) => {
  const { containerStyle, ...restProps } = props
  return (
    <UiButton
      containerStyle={[
        { marginTop: 40, marginBottom: Spacings.S24 },
        containerStyle,
      ]}
      {...restProps}
    />
  )
}

export const ButtonActionWithErrors: React.FC<
  UiButtonProps & {
    errors: any
    touched: any
  }
> = (props) => (
  <View style={{ marginTop: 36 }}>
    <AllFormErrors errors={props.errors} touched={props.touched} />
    <UiButton {...props} containerStyle={{ marginBottom: 40 }} />
  </View>
)

export function ButtonSecondaryWithChildren({
  children,
  onPress,
}: {
  children: any
  onPress: () => void
}) {
  return (
    <ButtonFlex
      onClick={onPress}
      style={StyleSheet.flatten({
        borderColor: Colors.gray10,
        alignItems: 'center',
        minWidth: 0,
        minHeight: 0,
        paddingLeft: Spacings.S8,
        paddingRight: Spacings.S8,
        paddingTop: Spacings.S10,
        paddingBottom: Spacings.S10,
      })}
    >
      {children}
    </ButtonFlex>
  )
}
