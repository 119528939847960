import { ROUTE_FUNDS_INTRO } from '@taaleri/components/src/screens/funds/FundsRoutes'
import {
  ROUTE_CONTRACT_START,
  ROUTE_CONTRACT_START_CONSULTATIVE,
} from './../contract/ContractRoutes'
import {
  routePathContract,
  routePathApp,
} from '@taaleri/components/src/navigation/routeHelper'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { getConfig } from '@taaleri/core/src/config'
import { ROUTE_IDENTITY_CARD_PHOTO_ID } from '../identitycard/IdentityCardRoutes'

export const ROUTE_PORTFOLIO = 'Portfolio'
export const ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL = 'PortfolioAssetClassDetail'
export const ROUTE_PURCHASE_LOTS = 'PortfolioPurchaseLots'
export const ROUTE_PORTFOLIO_PAYMENT_SUM = 'PortfolioPaymentSum'
export const ROUTE_PORTFOLIO_MONTHLY_PAYMENT_SUM = 'PortfolioMonthlyPaymentSum'
export const ROUTE_PORTFOLIO_PAYMENT_CONFIRM = 'PortfolioPaymentConfirm'
export const ROUTE_PORTFOLIO_PAYMENT_RECURRING = 'PortfolioPaymentRecurring'
export const ROUTE_PORTFOLIO_PAYMENT_RECURRING_CONFIRM =
  'PortfolioPaymentRecurringConfirm'
export const ROUTE_PORTFOLIO_PAYMENT_INFORMATION = 'PortfolioPaymentInformation'
export const ROUTE_PORTFOLIO_PAYMENT_SELECT = 'PortfolioPaymentSelect'
export const ROUTE_PORTFOLIO_PAYMENT_MONTHLY = 'PortfolioPaymentMonthly'
export const ROUTE_PORTFOLIO_BANK_TRANSFER = 'PortfolioBankTransfer'
export const ROUTE_PORTFOLIO_BANK_TRANSFER_DONE = 'PortfolioBankTransferDone'
export const ROUTE_PORTFOLIO_PAYMENT_BANK = 'PortfolioPaymentBank'
export const ROUTE_PORTFOLIO_WAIT_PAYMENT_STATUS = 'PortfolioWaitPaymentStatus'
export const ROUTE_PORTFOLIO_MONTHLY_PAYMENT_DONE =
  'PortfolioMonthlyPaymentDone'
export const ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL = 'PortfolioPaymentWithdrawal'
export const ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_LIST =
  'PortfolioPaymentWithdrawalList'
export const ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE =
  'PortfolioPaymentWithdrawalDone'
export const ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION =
  'PortfolioPaymentWithdrawalVerification'
export const ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL =
  'PortfolioVerificationUnsuccessfull'

export const ROUTE_PORTFOLIO_PHOTO_ID = 'PortfolioIdPhoto'
export const ROUTE_PORTFOLIO_EXISTING_ORDER = 'PortfolioExistingOrder'

export const ROUTE_PORTFOLIO_INVEST_SELECT_CONTRACT =
  'PortfolioInvestSelectContract'
export const ROUTE_PORTFOLIO_WITHDRAW_SELECT_CONTRACT =
  'PortfolioWithdrawSelectContract'

export function paymentStartRoute() {
  let startRoute = ''

  // Determine startRoute
  if (
    AppStore.investmentMode === 'fund' &&
    AppStore.paymentType === 'recurring'
  ) {
    startRoute = ROUTE_PORTFOLIO_PAYMENT_RECURRING
  } else if (
    AppStore.investmentMode === 'fund' &&
    AppStore.paymentType === 'onetime'
  ) {
    startRoute = ROUTE_PORTFOLIO_PAYMENT_BANK
  } else {
    startRoute = ROUTE_PORTFOLIO_PAYMENT_SELECT
  }

  // Check if user has a valid identity card
  if (AppStore.identityCardUpdated) {
    return startRoute
  } else {
    return ROUTE_IDENTITY_CARD_PHOTO_ID
  }
}

export function fundIntroRoute() {
  if (AppStore.isConsultativeWithoutContract) {
    return routePathContract(ROUTE_CONTRACT_START_CONSULTATIVE)
  } else if (AppStore.basicInfoExpired) {
    return routePathContract(ROUTE_CONTRACT_START)
  }
  return routePathApp(ROUTE_FUNDS_INTRO)
}

export function withdrawalNotAllowed() {
  if (AppStore.investmentMode === 'withdrawfund') {
    return false
  }

  // Test flag is needed for cypress tests
  return (
    AppStore.newWithdrawalConfirmed > 0 ||
    (AppStore.withdrawAll && !getConfig().testMode)
  )
}

export function investStartRoute() {
  if (AppStore.basicInfoExpired && !AppStore.isConsultativeWithoutContract) {
    return routePathContract(ROUTE_CONTRACT_START)
  }
  if (!AppStore.identityCardUpdated) {
    return routePathApp(ROUTE_IDENTITY_CARD_PHOTO_ID)
  }
  if (AppStore.isDigiAndConsultative) {
    return routePathApp(ROUTE_PORTFOLIO_INVEST_SELECT_CONTRACT)
  }
  return investStartRouteForContract(AppStore.isConsultative)
}

export function investStartRouteForContract(isConsultative: boolean) {
  return isConsultative ? fundIntroRoute() : routePathApp(paymentStartRoute())
}

export function startInvest() {
  if (AppStore.isConsultative) {
    AppStore.investmentMode = 'fund'
    if (AppStore.isConsultativeWithoutContract) {
      AppStore.contractNextScreen = ROUTE_FUNDS_INTRO
    }
  } else {
    AppStore.investmentMode = 'digi'
  }
  if (!AppStore.identityCardUpdated) {
    QuestionStore.questionMode = 'contract'
  }
}

export function startWithdraw() {
  AppStore.investmentMode = AppStore.isConsultative
    ? 'withdrawfund'
    : 'withdraw'
}

export function getWithdrawalRoute() {
  if (AppStore.isDigiAndConsultative) {
    return routePathApp(ROUTE_PORTFOLIO_WITHDRAW_SELECT_CONTRACT)
  }
  return getWithdrawalRouteContract(AppStore.isConsultative)
}

export function getWithdrawalRouteContract(consultative: boolean) {
  if (consultative && AppStore.isConsultativeWithoutContract) {
    return routePathContract(ROUTE_CONTRACT_START_CONSULTATIVE)
  }

  if (AppStore.basicInfoExpired) {
    return routePathContract(ROUTE_CONTRACT_START)
  }

  if (!AppStore.identityCardUpdated) {
    return routePathApp(ROUTE_IDENTITY_CARD_PHOTO_ID)
  }

  if (consultative) {
    return routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_LIST)
  } else {
    return routePathApp(
      withdrawalNotAllowed()
        ? ROUTE_PORTFOLIO_EXISTING_ORDER
        : ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL
    )
  }
}
