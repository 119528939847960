import React from 'react'
import { routePathApp } from '../../navigation/routeHelper'
import LoadingIndicator from '../../ui/LoadingIndicator'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import EtfComparisonTable from '../questions/EtfComparisonTable'
import { ROUTE_IMPACT_INTRO } from './ImpactRoutes'
import { observer } from 'mobx-react'
import useImpactStrategy from './useImpactStrategy'
import {
  ImpactParameters,
  calculateCo2Compensation,
} from '../questions/impactCalculator'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { selectedPortfolioDistribution } from '../questions/investmentproposal/selectedPortfolioDistrubution'

function ImpactComparisonContent() {
  const backRoute = routePathApp(ROUTE_IMPACT_INTRO)

  const { impactStrategy, error, setRetry } = useImpactStrategy()

  if (!impactStrategy || !AppStore.investmentProposalSaved) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const { impactShare, riskLevel, expectedReturn } = impactStrategy
  const impactParameters: ImpactParameters = {
    impactShare: 100 * impactShare,
    expectedReturn,
    initialInvestment: AppStore.portfolioMarketValue,
    monthlyInvestment: 0,
    investmentTime: 20,
    actualizedCo2Kilos: AppStore.customerCo2Actualized,
  }
  const co2Compensation = calculateCo2Compensation(impactParameters)

  const currentStrategy = selectedPortfolioDistribution(
    AppStore.investmentProposalSaved,
    riskLevel
  )

  return (
    <EtfComparisonTable
      impactEtfDistribution={impactStrategy}
      regularEtfDistribution={currentStrategy}
      co2Compensation={co2Compensation}
      riskLevel={riskLevel}
      backRoute={backRoute}
    />
  )
}

export default observer(ImpactComparisonContent)
