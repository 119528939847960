import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import * as Yup from 'yup'
import { TFunction } from 'i18next'

const paymentSumValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    sum: Yup.number()
      .required(ErrorMessages.required)
      .min(
        5,
        `${t('validation.minimum-invest', {
          value: 5,
        })}`
      )
      .typeError(`${t('validation.must-be-integer')}`),
  })

const minimumInvestmentValue = (ticker: string, recurring: string): number => {
  const isRecurring: boolean = recurring === 'recurring' ? true : false

  // Asunnot+ rahasto
  if (ticker === 'TTKIINTAO') {
    return 5000
  }

  return isRecurring ? 20 : 50
}

export const paymentFundSumValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    paymentType: Yup.string(),
    ticker: Yup.string(),
    sum: Yup.number()
      .required(ErrorMessages.required)
      .test({
        name: 'Kertasijoituksen minimisijoitus',
        test: function (value, context) {
          const isValid =
            value !== undefined &&
            value >=
              minimumInvestmentValue(
                context.parent.ticker,
                context.parent.paymentType
              )

          if (isValid) {
            return true
          } else {
            return this.createError({
              path: 'sum',
              message: `${t('validation.minimum-invest', {
                value: minimumInvestmentValue(
                  context.parent.ticker,
                  context.parent.paymentType
                ),
              })}`,
            })
          }
        },
      })
      .typeError(`${t('validation.must-be-integer')}`),
  })

export default paymentSumValidationSchema
