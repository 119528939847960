import React from 'react'
import { useParams } from 'react-router-dom'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ContentContainerBoxWide } from 'ui/container/ContentContainer'
import ReportOrderReportsContent from '@taaleri/components/src/screens/reportorders/ReportOrderReportsContent'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_REPORT_ORDERS } from '@taaleri/components/src/navigation/routes'

export default function ReportOrderReportsScreen() {
  const { id, customerId } = useParams<{ customerId: string; id: string }>()
  const { navigate } = useNavigation()

  return (
    <ContentContainerBoxWide>
      <ReportOrderReportsContent
        id={id}
        customerId={customerId}
        navigateToDelivery={(orderId: string) => {
          navigate(routePathApp(`${ROUTE_REPORT_ORDERS}/${orderId}/delivery`))
        }}
      />
    </ContentContainerBoxWide>
  )
}
