import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet } from 'react-native'
import Box from '@taaleri/components/src/ui/box/Box'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ValueLabel from '@taaleri/components/src/screens/impact/ValueLabel'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import { observer } from 'mobx-react'
import { ColorFormattedPercentage } from '@taaleri/components/src/ui/text/ColorFormattedNumber'
import { LabelValueWithPadding } from '@taaleri/components/src/ui/text/LabelValue'
import GlobalStyles from '@taaleri/components/src/constants/GlobalStyles'
import { PaddingHorizontal } from '@taaleri/components/src/ui/PaddingHorizontal'
import { useTranslation } from 'react-i18next'

interface Props {
  assetClass: AssetClass
}

const MarketValueBox = ({ assetClass }: Props) => {
  const { t } = useTranslation()
  return (
    <PaddingHorizontal
      style={{
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box style={styles.box}>
        <ValueLabel
          value={formatCurrency(assetClass.marketValue)}
          loading={AppStore.loading}
          label={`${t('impact.value')}`}
        />
        <LabelValueWithPadding
          label={`${t('portfolio.change-euro')}`}
          value={formatCurrency(
            assetClass.marketValue - assetClass.purchaseValue
          )}
        />

        <LabelValueWithPadding
          label={`${t('portfolio.change-percent')}`}
          valueComponent={() => (
            <ColorFormattedPercentage
              value={assetClass.marketValueChangePercent || 0}
            />
          )}
          containerStyle={GlobalStyles.topBorder}
        />
      </Box>
    </PaddingHorizontal>
  )
}

export default observer(MarketValueBox)

const styles = StyleSheet.create({
  box: {
    marginTop: -Spacings.S32,
    marginHorizontal: Spacings.S24,
    marginBottom: Spacings.S40,
    maxWidth: 448,
    width: '100%',
  },
})
