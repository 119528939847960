import React from 'react'
import { View } from 'react-native'
import {
  InvestmentProposal,
  AssetClassSecurities,
} from '@taaleri/core/src/models/investmentProposal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import PercentagePieAsset from '../../components/PercentagePieAsset'
import { selectedPortfolioDistribution } from './investmentproposal/selectedPortfolioDistrubution'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import { formatPercentageFull } from '@taaleri/core/src/utils/format'
import Colors from '@taaleri/core/src/constants/Colors'
import useLayout from '../../ui/useLayout'
import { useTranslation } from 'react-i18next'

interface Props {
  investmentProposal: InvestmentProposal
}

interface PercentageRowProp {
  percentage: number
  name: string
  color: string
  noPadding?: boolean
}

function Dot({ color }: { color: string }) {
  return (
    <View
      style={{
        width: 16,
        height: 6,
        marginRight: 12,
        backgroundColor: color,
        borderRadius: 5,
      }}
    />
  )
}

function PercentageRowDesktop({ percentage, name, color }: PercentageRowProp) {
  return (
    <FlexRow
      style={{
        alignItems: 'center',
      }}
    >
      <Dot color={color} />
      <FlexRow>
        <TextDefault type={'t2'}>{name}</TextDefault>
        <TextDefault type={'h6'} style={{ marginLeft: Spacings.S8 }}>
          {formatPercentageFull(percentage)} %
        </TextDefault>
      </FlexRow>
    </FlexRow>
  )
}

function PercentageRowMobile({
  percentage,
  name,
  color,
  noPadding,
}: PercentageRowProp) {
  return (
    <View style={{ paddingBottom: noPadding ? 0 : Spacings.S8 }}>
      <FlexRow
        style={{
          alignItems: 'center',
        }}
      >
        <Dot color={color} />
        <TextDefault type={'h7'}>
          {formatPercentageFull(percentage)} %
        </TextDefault>
      </FlexRow>
      <TextDefault type={'t3'}>{name}</TextDefault>
    </View>
  )
}

export default function FirstProposalPieSection({ investmentProposal }: Props) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const selectedDistrubution = selectedPortfolioDistribution(
    investmentProposal,
    QuestionStore.riskLevel
  )

  const namePercentages: AssetClassSecurities[] =
    selectedDistrubution.securities

  const PercentageRowComponent = isSmall
    ? PercentageRowMobile
    : PercentageRowDesktop

  const pieSize = isSmall ? 117 : 144
  const borderSize = pieSize / 9

  return (
    <FlexRow style={{ marginVertical: isSmall ? 0 : Spacings.S48 }}>
      <View
        style={{
          alignItems: 'center',
          flex: 1,
          justifyContent: 'center',
          paddingRight: isSmall ? 0 : Spacings.S24,
        }}
      >
        <PercentagePieAsset
          assetClasses={namePercentages}
          percentage={0}
          size={pieSize}
          borderWidth={borderSize}
        />
      </View>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          borderLeftColor: Colors.border,
          borderLeftWidth: isSmall ? 0 : 1,
        }}
      >
        <View style={{ padding: isSmall ? 0 : Spacings.S24 }}>
          {namePercentages.map((percentage: AssetClassSecurities, index) => (
            <PercentageRowComponent
              color={assetClassColors[percentage.id]}
              percentage={percentage.percentage}
              name={t(percentage.name)}
              key={index}
              noPadding={index + 1 === namePercentages.length}
            />
          ))}
        </View>
      </View>
    </FlexRow>
  )
}
