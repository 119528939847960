import { Formik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { View } from 'react-native'
import UiButton from '@taaleri/components/src/ui/UiButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { UiTextInputForm } from '@taaleri/components/src/ui/form/UiTextInput'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { unauthenticatedPost } from '@taaleri/core/src/api/rest'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Buttons from 'ui/Buttons'
import { TextBold } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'

interface PushForm {
  title: string
  body: string
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ErrorMessages.required),
  body: Yup.string().required(ErrorMessages.required),
})

export default function PushNotificationForm({ token }: { token: string }) {
  const [open, setOpen] = useState<boolean>(false)
  const [sent, setSent] = useState<boolean>(false)

  if (sent) {
    return (
      <View style={{ marginBottom: Spacings.S24 }}>
        <TextBold style={{ color: Colors.primary }}>Viesti lähetetty</TextBold>
      </View>
    )
  }

  if (!open) {
    return (
      <UiButton
        containerStyle={{ marginBottom: Spacings.S24 }}
        size="small"
        onPress={() => {
          setOpen(true)
        }}
        title="Lähetä vapaamuotoinen viesti"
      />
    )
  }

  return (
    <View style={{ marginBottom: Spacings.S24, maxWidth: 400 }}>
      <Formik
        initialValues={{ title: '', body: '' }}
        validationSchema={validationSchema}
        onSubmit={async ({ title, body }: PushForm) => {
          const url = 'https://expo.io/--/api/v2/push/send'
          const message = [
            {
              to: token,
              title,
              body,
            },
          ]
          await unauthenticatedPost(url, message)
          setSent(true)
          setTimeout(() => {
            setOpen(false)
            setSent(false)
          }, 2000)
        }}
      >
        {({ isSubmitting }) => {
          return (
            <UiForm>
              <UiTextInputForm
                name="title"
                label="Otsikko"
                grayBackground={true}
              />
              <UiTextInputForm
                name="body"
                label="Viesti"
                hideCheckCircle={true}
                multiline={true}
                grayBackground={true}
                numberOfLines={3}
                height={100}
              />
              <Buttons
                title="Lähetä"
                loading={isSubmitting}
                titleSecondary="Peruuta"
                onPressSecondary={() => setOpen(false)}
              />
            </UiForm>
          )
        }}
      </Formik>
    </View>
  )
}
