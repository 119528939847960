import { Identity } from '../models/Identity'
import { api } from '../api/api'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import {
  ImpactResponse,
  EmptyImpactResponse,
  SdgResponse,
} from '../models/ImpactProduct'
import { ServiceResponse } from '../models/ServiceResponse'

async function getCustomerImpact(customerId: number): Promise<ImpactResponse> {
  try {
    const identity: Identity = await getIdentity()
    const impactResponse = await api().impact.getCustomerImpact(
      identity,
      customerId
    )
    return impactResponse
  } catch (e) {
    handleErrors(e, 'getCustomerImpact')
    return EmptyImpactResponse
  }
}

export async function getCustomerSdgProducts(
  customerId: number
): Promise<ServiceResponse<SdgResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().impact.getCustomerSdgProducts(
      identity,
      customerId
    )
    return { response, success: true }
  } catch (e) {
    handleErrors(e, 'getCustomerSdgs')
    return { success: false }
  }
}

export const impact = {
  getCustomerImpact,
  getCustomerSdgProducts,
}
