import React, { useEffect, useState } from 'react'

import Spacings from '@taaleri/core/src/constants/Spacings'

import { BoxWithPaddingMargin } from '../../ui/box/Box'

import {
  getReportOrders,
  removeReportOrder,
} from '@taaleri/core/src/services/reports'
import {
  ReportOrder,
  REPORTS,
  REPORT_DELIVERY_OPTIONS,
} from '@taaleri/core/src/models/ReportOrders'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportOrderStore from '@taaleri/core/src/stores/ReportOrderStore'
import LoadingIndicator from '../../ui/LoadingIndicator'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { View } from 'react-native'
import useLayout from '../../ui/useLayout'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import YesNoModal from '../../ui/modal/YesNoModal'
import { IconAlert, IconEmail } from '../../ui/Icons'
import { observer } from 'mobx-react'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { ChildWrapper, FlexContainer } from '../../ui/FlexContainer'
import UiButton from '../../ui/UiButton'
import { isWeb } from '../../constants/Platforms'
import { BorderedContainer } from '../../ui/BorderedContainer'
import { useTranslation } from 'react-i18next'

interface Props {
  navigateToOrder: (customerId: string, orderId?: number) => void
  refresh?: number
}

function ReportOrdersList({ navigateToOrder, refresh }: Props) {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  const [orders, setOrders] = useState<ReportOrder[]>()
  const [removedOrder, setRemovedOrder] = useState<ReportOrder>()
  const [loading, setLoading] = useState<boolean>(false)

  async function findReportOrders() {
    const { success, response } = await getReportOrders(AppStore.customerId)
    ReportOrderStore.reportPackage = undefined
    if (success && response) {
      setOrders(response)
      ReportOrderStore.orders = response
    }
  }

  useEffect(() => {
    findReportOrders()
  }, [refresh, AppStore.customerId])

  async function removeOrder() {
    setLoading(true)
    let success = false
    if (removedOrder && removedOrder?.id) {
      const response = await removeReportOrder(
        parseInt(removedOrder.customerId, 10),
        removedOrder.id
      )
      success = response.success
      Analytics.event('ReportOrders', 'Remove report order')
      setRemovedOrder(undefined)
    }

    setLoading(false)
    if (success) {
      await findReportOrders()
    }
  }

  if (!orders) {
    return <LoadingIndicator type="inline" />
  }

  if (orders.length === 0) {
    return (
      <View>
        <PaddingHorizontal>
          <BoxWithPaddingMargin>
            <BorderedContainer style={{ marginBottom: 0 }}>
              <TextDefault type="h6">
                {t('reportOrder.no-orders')}
              </TextDefault>
            </BorderedContainer>
          </BoxWithPaddingMargin>
        </PaddingHorizontal>
      </View>
    )
  }

  return (
    <View>
      <YesNoModal
        isVisible={removedOrder !== undefined}
        title={`${t('reportOrder.confirm-delete')}`}
        content={`${t('reportOrder.confirm-delete-content')}`}
        icon={<IconAlert />}
        onYes={removeOrder}
        yesText={`${t('app.ImpactToggleContract.confirmbuttton')}`}
        noText={`${t('button.cancel')}`}
        onNo={() => setRemovedOrder(undefined)}
        loading={loading}
      />
      <PaddingHorizontal>
        <BoxWithPaddingMargin>
          {orders.map((order) => {
            const reportNames = REPORTS.filter((r) =>
              order.selectedReports.includes(r.reportType)
            )
              .map((s) => t(s.name))
              .join(', ')
            const deliveryOption = REPORT_DELIVERY_OPTIONS.find(
              (o) => o.value === order.delivery
            )
            const deliveryText = deliveryOption ? t(deliveryOption.label) : ''
            const singleReport = order.selectedReports.length === 1
            const title = singleReport ? reportNames : `${t('reportOrder.report-package')}`
            return (
              <BorderedContainer key={order.id}>
                <FlexContainer>
                  <ChildWrapper
                    style={[
                      {
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flex: 3,
                        paddingRight: isNonSmall ? Spacings.S8 : 0,
                      },
                    ]}
                    childStyle={{ marginVertical: Spacings.S4 }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: isNonSmall
                          ? 'flex-start'
                          : 'space-between',
                      }}
                    >
                      <TextDefault
                        type={'h6'}
                        style={{
                          marginRight: Spacings.S16,
                        }}
                      >
                        {title}
                      </TextDefault>
                      <TextDefault color={Colors.textSecondary} type={'t2'}>
                        {deliveryText}
                      </TextDefault>
                    </View>
                    {!singleReport && (
                      <TextDefault type={'t2'}>{reportNames}</TextDefault>
                    )}
                    <FlexRow
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ minWidth: 16, minHeight: 17 }}>
                        <IconEmail />
                      </View>
                      <TextDefault
                        type={'t2'}
                        style={{ marginLeft: Spacings.S16 }}
                      >
                        {order.receivers.map((r) => r.email).join(', ')}
                      </TextDefault>
                    </FlexRow>
                  </ChildWrapper>
                  <FlexContainer
                    flexDirectionSmall={'row'}
                    flexDirectionBig={'column'}
                    alignContentSmall={'flex-end'}
                    style={[
                      !isWeb && { flexWrap: 'wrap' },
                      { alignItems: isNonSmall ? 'flex-end' : 'stretch' },
                    ]}
                  >
                    <UiButton
                      title={t('button.modify')}
                      size={'small'}
                      type={'secondary'}
                      textStyle={{ color: Colors.primary }}
                      containerStyle={{
                        minHeight: 0,
                        minWidth: 0,
                        borderColor: Colors.gray10,
                        margin: Spacings.S4,
                      }}
                      onPress={() => {
                        Analytics.event('ReportOrders', 'Select report order')
                        ReportOrderStore.reportPackage = 'Custom'
                        navigateToOrder(order.customerId, order.id)
                      }}
                    />
                    <UiButton
                      title={t('button.remove')}
                      size={'small'}
                      backgroundColor={'rgba(255, 68, 17, 0.1)'}
                      textColor={Colors.error}
                      containerStyle={{
                        minHeight: 0,
                        minWidth: 0,
                        margin: Spacings.S4,
                      }}
                      onPress={() => setRemovedOrder(order)}
                    />
                  </FlexContainer>
                </FlexContainer>
              </BorderedContainer>
            )
          })}
        </BoxWithPaddingMargin>
      </PaddingHorizontal>
    </View>
  )
}

export default observer(ReportOrdersList)
