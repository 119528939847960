import React from 'react'
import Title, { TitleProps } from './Title'
import FlexRow from '../FlexRow'
import { View } from 'react-native'
import InfoButton from '../InfoButton'

interface TitleWithInfoProps extends TitleProps {
  infoTitle?: string
  infoText: string
}

export const TitleWithInfo: React.FC<TitleWithInfoProps> = ({
  children,
  style,
  infoTitle,
  infoText,
}) => (
  <FlexRow style={{ justifyContent: 'space-between' }}>
    <Title style={[{ flex: 1 }, style]}>{children}</Title>
    <View style={{ marginTop: 28, marginLeft: 8 }}>
      <InfoButton title={infoTitle} text={infoText} />
    </View>
  </FlexRow>
)
