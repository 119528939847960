import Analytics from '@taaleri/core/src/analytics/Analytics'
import { getConfig } from '@taaleri/core/src/config'
import { OrderPdf } from '@taaleri/core/src/models/ContractPdf'
import { Identity } from '@taaleri/core/src/models/Identity'
import { contractOrdersPdfList } from '@taaleri/core/src/services/contracts'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatDateFull, formatYear } from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ITabItem,
  ITabListItem,
  TabBox,
  tabReducer,
} from '../../components/TabBox'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import OpenLink from '../../ui/OpenLink'

function orderPdfToListItem(
  orderPdf: OrderPdf,
  tabValue: string,
  t: TFunction
): ITabListItem {
  return {
    id: orderPdf.contractId,
    tabValue,
    title:
      orderPdf.financialInstrumentName +
      ' ' +
      (orderPdf.side === 'Buy'
        ? `${t('contract.order-type-buy')}`
        : `${t('contract.order-type-sell')}`),
    titleRight: formatDateFull(orderPdf.createdDate),
  }
}

function openUrl(listItem: ITabListItem, customerId: number, token: string) {
  const url = `${getConfig().apiUrl}/Contracts/${customerId}/${
    listItem.id
  }/contract.pdf?token=${token}`
  Analytics.event('Contract', 'Open Contract order Pdf', listItem.title)
  OpenLink(url, 'aktia')
}

function ContractOrdersList() {
  const [tabs, setTabs] = useState<ITabItem[]>([])
  const [token, setToken] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  async function findContracts() {
    setError(false)
    setLoading(true)

    const result = await contractOrdersPdfList(AppStore.customerId)
    if (result.success) {
      const identity: Identity = await getIdentity()
      setToken(identity.accessToken)

      const listItems: ITabListItem[] = []
      let tTabs: ITabItem[] = []

      result.response?.forEach((orderPdf) => {
        const year = formatYear(orderPdf.createdDate)
        // Checks that formatYear has returned a valid number
        if (!isNaN(+year)) {
          listItems.push(orderPdfToListItem(orderPdf, year, t))
        }
      })

      tTabs = listItems.reduce(tabReducer, tTabs)

      setTabs(tTabs)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    findContracts()
  }, [AppStore.customerId])

  if (error) {
    return <ErrorWithRetry onRetry={findContracts} />
  }

  if (loading) {
    return <LoadingIndicator type="inline" />
  }

  return (
    <TabBox
      titles={tabs}
      onListItemPressed={(listItem) =>
        openUrl(listItem, AppStore.customerId, token)
      }
      emptyText={`${t('reportBox.no-assignments')}`}
    />
  )
}

export default observer(ContractOrdersList)
