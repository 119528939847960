import React, { useState, useContext } from 'react'

import { BreakPoint, ScreenWidth } from '../constants/BreakPoint'
import { Dimensions, View } from 'react-native'

interface LayoutResponse {
  width: number
  isWide: boolean
  isExtraWide: boolean
  isSmall: boolean
  isMedium: boolean
  isMediumPlus: boolean
  isNonSmall: boolean
  screenWidth: ScreenWidth
}

const LayoutContext = React.createContext({
  windowWidth: Dimensions.get('window').width,
})

export function LayoutProvider({ children }: { children: any }) {
  const [width, setWidth] = useState(Dimensions.get('screen').width)

  const onLayout = ({ nativeEvent }: { nativeEvent: any }) => {
    if (Math.abs(nativeEvent.layout.width - width) > 30) {
      setWidth(nativeEvent.layout.width)
    }
  }

  return (
    <LayoutContext.Provider value={{ windowWidth: width }}>
      <View onLayout={onLayout}>{children}</View>
    </LayoutContext.Provider>
  )
}

export const WidthProvider = LayoutContext.Provider

export default function useLayout(): LayoutResponse {
  const context = useContext(LayoutContext)

  const width = context.windowWidth
  const screenWidth =
    width > BreakPoint.L ? 'wide' : width < BreakPoint.S ? 'small' : 'medium'
  const isExtraWide = width > BreakPoint.XL
  const isWide = screenWidth === 'wide'
  const isSmall = screenWidth === 'small'
  const isMedium = screenWidth === 'medium'
  return {
    width,
    isExtraWide,
    isWide,
    isSmall,
    isMedium,
    isNonSmall: screenWidth !== 'small',
    isMediumPlus: isWide || isExtraWide,
    screenWidth,
  }
}
