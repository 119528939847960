import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Box from '@taaleri/components/src/ui/box/Box'
import { PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native'
import { PurchaseLotRow, PurchaseLotHeading } from './PurchaseLotRow'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { sum } from '@taaleri/core/src/utils/format'

interface Props {
  purchaseLots: PurchaseLot[]
  loading?: boolean
  color?: string
  style?: StyleProp<ViewStyle>
}

function PurchaseLotDetail({ purchaseLots, loading, color, style }: Props) {
  if (purchaseLots.length === 0) {
    return null
  }
  const totalMarketValue = sum(purchaseLots.map((e) => e.marketValue))
  const totalPurchaseValue = sum(purchaseLots.map((e) => e.purchaseValue))
  const marketValueChange = totalMarketValue - totalPurchaseValue
  const marketValueChangePercent = totalPurchaseValue
    ? (100 * marketValueChange) / totalPurchaseValue
    : 0
  const totalQuantity = sum(purchaseLots.map((e) => e.quantity))
  const averagePurchasePrice = totalQuantity
    ? sum(purchaseLots.map((e) => e.quantity * (e.purchasePrice ?? 0))) /
      totalQuantity
    : undefined
  const averageMarketPrice = totalQuantity
    ? sum(purchaseLots.map((e) => e.quantity * (e.marketPrice ?? 0))) /
      totalQuantity
    : undefined
  return (
    <View {...{ style }}>
      <PurchaseLotHeading />
      <Box>
        {loading ? (
          <LoadingIndicator style={styles.loadingIndicator} />
        ) : (
          purchaseLots.map((purchaseLot, index) => (
            <PurchaseLotRow
              purchaseLot={purchaseLot}
              key={index}
              borderBottom={true}
              color={color}
              topBorderRadius={index === 0}
              bottomBorderRadius={false}
            />
          ))
        )}
        {
          <PurchaseLotRow
            isTotalRow={true}
            purchaseLot={{
              securityTypeId: purchaseLots[0]?.securityTypeId,
              quantity: sum(purchaseLots.map((e) => e.quantity)),
              marketValue: totalMarketValue,
              purchaseValue: totalPurchaseValue,
              marketValueChangePercent,
              purchaseDate: purchaseLots[0]?.purchaseDate,
              purchasePrice: averagePurchasePrice,
              marketPrice: averageMarketPrice,
            }}
            key="Yhteensä"
            borderBottom={true}
            color={color}
            topBorderRadius={false}
            bottomBorderRadius={true}
          />
        }
      </Box>
    </View>
  )
}

const styles = StyleSheet.create({
  loadingIndicator: { top: 0, marginVertical: Spacings.S48 },
})

export default PurchaseLotDetail
