import { jwtDecode } from 'jwt-decode'

import { handleErrors } from './handleErrors'
import { api } from '../api/api'
import AdminCustomer from '../models/AdminCustomer'
import AdminTargetMessage, {
  CustomerSimple,
} from '../models/AdminTargetMessage'
import { ServiceResponse } from '../models/ServiceResponse'

export async function searchCustomers(
  token: string,
  userCustomersOnly: boolean,
  includeLostCustomers: boolean,
  query?: string
): Promise<ServiceResponse<AdminCustomer[]>> {
  try {
    const response = await api().admin.searchCustomers(
      token,
      userCustomersOnly,
      includeLostCustomers,
      query
    )
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'adminSearch', 'error', true)
    return { success: false, error }
  }
}

export async function clearCustomerCache(
  token: string,
  customerId: string
): Promise<void> {
  return await api().admin.clearCustomerCache(token, customerId)
}

export async function getTargetMessaging(
  token: string
): Promise<AdminTargetMessage[]> {
  return await api().admin.getTargetMessages(token)
}

export async function getCustomers(
  token: string,
  customerIds: string[]
): Promise<CustomerSimple[]> {
  const result: CustomerSimple[] = []
  if (!customerIds) return result
  if (customerIds.length > 10) {
    while (customerIds.length > 10) {
      const batch = customerIds.splice(0, 10)
      result.push(...(await api().admin.getCustomers(token, batch)))
    }
  } else {
    result.push(...(await api().admin.getCustomers(token, customerIds)))
  }

  return result
}

export async function updateMessage(
  token: string,
  message: AdminTargetMessage
): Promise<boolean> {
  const response = await api().admin.updateMessage(token, message)

  return response
}

export async function addMessage(
  token: string,
  message: AdminTargetMessage
): Promise<boolean> {
  const response = await api().admin.addMessage(token, message)

  return response
}

export function getAdminRoles(token: string): string[] {
  const user = jwtDecode(token)

  return user['role']
}
