import React from 'react'
import Colors, { getChangeColor } from '@taaleri/core/src/constants/Colors'
import { StyleProp, TextStyle } from 'react-native'
import { isAlmostZero } from '@taaleri/core/src/utils/isAlmostZero'
import { TextDefault, TextType } from '../../ui/text/UiText'
import { isNullOrUndefined } from '@taaleri/core/src/utils/keyFigureHelper'

interface Props {
  value?: number
  format: (value: number) => string
  style: StyleProp<TextStyle>
  colorFormatted?: boolean
  hidden?: boolean
  type?: TextType
}

export function OwnerShipRowItem({
  value,
  format,
  style,
  colorFormatted,
  hidden,
  type,
}: Props) {
  if (hidden) {
    return null
  }

  const formatted = isNullOrUndefined(value)
    ? ''
    : isAlmostZero(value)
    ? format(0)
    : // @ts-ignore
    colorFormatted && value > 0
    ? // @ts-ignore
      '+' + format(value)
    : // @ts-ignore
      format(value)

  return (
    <TextDefault
      type={type ? type : 't3'}
      style={style}
      color={colorFormatted ? getChangeColor(value) : Colors.text}
    >
      {formatted}
    </TextDefault>
  )
}
