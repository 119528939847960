import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'

import { ListItemLink, ListItemLinkData } from './ListItem'
import { BoxWithPadding } from './box/Box'
import useNavigation from '../navigation/useNavigation'

export default function CardWithLinks({
  items,
  title,
}: {
  items: ListItemLinkData[]
  title: string
}) {
  const { navigate } = useNavigation()
  return (
    <BoxWithPadding title={title} style={{ marginBottom: Spacings.S24 }}>
      {items.map((l, index) => (
        <ListItemLink
          noPadding={true}
          key={l.title}
          title={l.title}
          onPress={() => {
            if (l.onPress) {
              l.onPress()
            }
            if (l.link) {
              navigate(l.link)
            }
          }}
          topDivider={index > 0}
          bottomDivider={index === items.length - 1}
          titleRight={l.value}
          chevron={
            (l.link !== undefined || l.onPress !== undefined) &&
            l.value === undefined
          }
          {...l.props}
        />
      ))}
    </BoxWithPadding>
  )
}
