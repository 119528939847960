/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TFunction } from 'i18next'
import capitalize from 'lodash/capitalize'
import flatten from 'lodash/flatten'

export const MONTH_PROPERTY = 'Kuukausiraportti_koontivarallisuus'
export const MONTH_OWN_PROPERTY = 'Kuukausiraportti_omavarallisuus'
export const MONTH_INSURANCE_PROPERTY = 'Kuukausiraportti_vakuutusvarallisuus'
export const OTHER_TAX = 'Veroraportti'
export const OTHER_INSURANCE = 'Vuosiraportti_vakuutusvarallisuus'
export const OTHER_KUPARA = 'Vuosiraportti_kupara'
export const OTHER_OWN_PROPERTY = 'Vuosiraportti_omavarallisuus'
export const YEAR = 'Vuosiraportti'

export type FolderCategory =
  | typeof MONTH_PROPERTY
  | typeof MONTH_OWN_PROPERTY
  | typeof MONTH_INSURANCE_PROPERTY
  | typeof OTHER_KUPARA
  | typeof OTHER_TAX
  | typeof OTHER_INSURANCE
  | typeof OTHER_OWN_PROPERTY
  | typeof YEAR

export const PATH_YEAR_OWN_PROPERTY = 'Omavarallisuus.'
export const PATH_YEAR_FINANCE = 'Varainhoitopalkkiot'
export const PATH_YEAR_INSURANCE = '_Vakuutus_'
export const YEAR_INSURANCE = 'portfolio.insurance'
export const YEAR_CONTRACT = 'reportArchive.year-per-contract'
export const YEAR_OWN_PROPERTY = 'portfolio.wealth-own'

export const PATH_YEAR_SUB_PROPERTY = '_Omistukset_'
export const PATH_YEAR_SUB_FINANCE = '_Varainhoitopalkkiot_'

export const YEAR_SUB_PROPERTY = 'reportArchive.year-property'
export const YEAR_SUB_FINANCE = 'reportArchive.year-expenses'
export const YEAR_SUB_INCOME = 'reportArchive.year-income'

export const REPORT_ARCHIVE_TYPE = 'ReportArchive'
export const PUBLICATION_SERVICE_TYPE = 'PublicationService'
export const BANK_STATEMENT = 'BankStatement'
export const YEARLY_BANK_STATEMENT = 'YearlyBankStatement'
export const CONTENT_OF_PORTFOLIO = 'ContentOfPortfolio'
export const AKTIA_PORTFOLIO = 'AktiaCustomersPortfolioStatement'

export function isReportArchiveReport(
  item:
    | AccountStatement
    | YearStatement
    | PreserverAsset
    | ReportArchiveReport
    | AktiaCustomerPortfolio
): item is ReportArchiveReport {
  return (item as ReportArchiveReport).path !== undefined
}

export type ArchiveResponseObject =
  | ReportArchiveFolder
  | AccountStatement
  | YearStatement
  | PreserverAsset
  | AktiaCustomerPortfolio

export interface ReportArchiveFolder {
  type: typeof REPORT_ARCHIVE_TYPE
  reportId?: string
  folderName: string
  path: string
  subFolders: ReportArchiveFolder[]
  reports: ReportArchiveReport[]
}

export interface ReportArchiveReport {
  filename: string
  path: string
  date?: Date
  title?: string
}

export interface MonthReport extends ReportArchiveReport {
  year: number
  month: number
  accountStatements?: AccountStatement[]
}

export interface YearReport extends ReportArchiveReport {
  year: number
}

export interface OtherYearArchive {
  title: string
  reports: YearReport[]
}

export interface YearSubArchive {
  [key: string]: YearReport[]
}

export interface YearArchive {
  [key: string]: YearSubArchive
}

export interface MonthArchive {
  [key: string]: MonthReport[]
}
export interface RArchive {
  months?: MonthArchive
  otherTax?: OtherYearArchive
  otherInsurance?: OtherYearArchive
  otherOwnProperty?: OtherYearArchive
  otherKupara?: OtherYearArchive
  years?: YearArchive
  preserver?: (PreserverAsset | YearStatement | AktiaCustomerPortfolio)[]
}
export interface CollapsibleReportListItem {
  url: string
  title: string
  item:
    | AccountStatement
    | YearStatement
    | PreserverAsset
    | AktiaCustomerPortfolio
}

interface ReportMap {
  [key: number]: any[]
}

export interface ArchiveMap {
  [key: number]: RArchive
}

export interface YearsMap {
  [key: number]: YearArchive
}

export interface PublicationServiceBase {
  category1: string
  category2: string
  category3: string
  customerId: string
  customerSsn: string
  startDate: string
  endDate: string
  documentFileId: number
  iban: string
  language: string
  name: string
  systemId: string
  type: string
}

export interface PublicationService extends PublicationServiceBase {
  reportId: string
}

export interface AccountStatement extends PublicationServiceBase {
  reportId: typeof BANK_STATEMENT
}

export interface YearStatement extends PublicationServiceBase {
  reportId: typeof YEARLY_BANK_STATEMENT
}

export interface PreserverAsset extends PublicationServiceBase {
  reportId: typeof CONTENT_OF_PORTFOLIO
}

export interface AktiaCustomerPortfolio extends PublicationServiceBase {
  reportId: typeof AKTIA_PORTFOLIO
}

interface FolderType {
  category: FolderCategory
  folderName: string
  title: string
  longTitle: string
}

const MonthMap: { [key: string]: number } = {
  Tammikuu: 1,
  Helmikuu: 2,
  Maaliskuu: 3,
  Huhtikuu: 4,
  Toukokuu: 5,
  Kesäkuu: 6,
  Heinäkuu: 7,
  Elokuu: 8,
  Syyskuu: 9,
  Lokakuu: 10,
  Marraskuu: 11,
  Joulukuu: 12,
}

export function camelCaseToWords(word: string) {
  return capitalize(word.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase())
}

export const reportNameMappings: { [key: string]: string } = {
  Omavarallisuus: 'Oma varallisuus',
}

const folderTypes: FolderType[] = [
  {
    category: MONTH_OWN_PROPERTY,
    folderName: 'Kuukausiraportti_omavarallisuus',
    longTitle: 'reportArchive.monthly-own',
    title: 'portfolio.wealth-own',
  },
  {
    category: MONTH_INSURANCE_PROPERTY,
    folderName: 'Kuukausiraportti_vakuutusvarallisuus',
    longTitle: 'reportArchive.monthly-insurance',
    title: 'portfolio.insurance',
  },
  {
    category: MONTH_PROPERTY,
    folderName: 'Kuukausiraportti_koontivarallisuus',
    longTitle: 'reportArchive.monthly-aggregate',
    title: 'portfolio.aggregate',
  },
  {
    category: OTHER_TAX,
    folderName: 'Veroraportti',
    longTitle: 'reportArchive.tax-report',
    title: 'reportArchive.tax-report',
  },
  {
    category: OTHER_INSURANCE,
    folderName: 'Vuosiraportti_vakuutusvarallisuus',
    longTitle: 'reportArchive.year-insurance',
    title: 'portfolio.insurance',
  },
  {
    category: OTHER_OWN_PROPERTY,
    folderName: 'Vuosiraportti_omavarallisuus',
    longTitle: 'reportArchive.year-own',
    title: 'portfolio.wealth-own',
  },
  {
    category: OTHER_KUPARA,
    folderName: 'Vuosiraportti_kupara',
    longTitle: 'reportArchive.year-kupara',
    title: 'reportArchive.year-kupara',
  },
  {
    category: YEAR,
    folderName: 'Vuosiraportti',
    title: 'reportArchive.year-report',
    longTitle: 'reportArchive.year-report',
  },
]

export function getMonthReportOrder(category: string): number {
  switch (category) {
    case MONTH_OWN_PROPERTY:
      return 1
    case MONTH_PROPERTY:
      return 2
    case MONTH_INSURANCE_PROPERTY:
      return 3
    default:
      return 4
  }
}

export function getYearlyReportOrder(category: string): number {
  switch (category) {
    case YEAR_OWN_PROPERTY:
      return 1
    case YEAR_CONTRACT:
      return 2
    case YEAR_INSURANCE:
      return 3
    default:
      return 4
  }
}

export function toReportArchive(
  folders: ReportArchiveFolder,
  t: TFunction
): ArchiveMap {
  const subFolders: ReportArchiveFolder[] = folders.subFolders || []

  const archiveMap: ArchiveMap = {}

  function setResults(
    value: ReportMap,
    setValue: (key: number, arg: any[]) => void
  ) {
    Object.keys(value).map((key) => {
      const keyValue = Number(key)
      if (!(keyValue in archiveMap)) {
        archiveMap[keyValue] = {}
      }

      setValue(keyValue, value[keyValue])
    })
  }

  folderTypes.forEach((folderType) => {
    const sFolder = subFolders.find(
      (f) => f.folderName === folderType.folderName
    )
    if (sFolder) {
      switch (folderType.category) {
        case MONTH_PROPERTY:
        case MONTH_OWN_PROPERTY:
        case MONTH_INSURANCE_PROPERTY: {
          setResults(
            parseMonthFolder(sFolder, folderType.longTitle),
            (key, arg) => {
              if (!archiveMap[key].months) {
                archiveMap[key].months = {}
              }
              archiveMap[key].months![folderType.title] = arg as MonthReport[]
            }
          )
          break
        }
        case OTHER_TAX: {
          setResults(
            parseOtherFolder(sFolder, folderType.category, t),
            (key, arg) => {
              archiveMap[key].otherTax = {
                title: folderType.title,
                reports: arg,
              }
            }
          )
          break
        }
        case OTHER_OWN_PROPERTY: {
          setResults(
            parseOtherFolder(sFolder, folderType.category, t),
            (key, arg) => {
              archiveMap[key].otherOwnProperty = {
                title: folderType.title,
                reports: arg,
              }
            }
          )
          break
        }
        case OTHER_INSURANCE: {
          setResults(
            parseOtherFolder(sFolder, folderType.category, t),
            (key, arg) => {
              archiveMap[key].otherInsurance = {
                title: folderType.title,
                reports: arg,
              }
            }
          )
          break
        }
        case OTHER_KUPARA: {
          setResults(
            parseOtherFolder(sFolder, folderType.category, t),
            (key, arg) => {
              archiveMap[key].otherKupara = {
                title: folderType.title,
                reports: arg,
              }
            }
          )
          break
        }
        case YEAR: {
          const value = parseYearFolder(sFolder, t)
          Object.keys(value).map((key) => {
            const keyValue = Number(key)
            if (!(keyValue in archiveMap)) {
              archiveMap[keyValue] = {}
            }

            archiveMap[keyValue].years = value[keyValue]
          })
          break
        }
      }
    }
  })

  return archiveMap
}

export function updateReportArchive(
  archive: ArchiveMap,
  accountStatements: AccountStatement[],
  preserverItems: (PreserverAsset | YearStatement)[],
  aktiaItems: AktiaCustomerPortfolio[]
): ArchiveMap {
  accountStatements.forEach((statement) => {
    const reportDate = new Date(statement.endDate)
    const year = reportDate.getFullYear()
    const month = reportDate.getMonth() + 1
    const archiveYear = archive[year]
    if (archiveYear === undefined) {
      archive[year] = {
        months: {},
      } as RArchive
      archive[year].months![YEAR_OWN_PROPERTY] = [
        {
          year,
          month,
          filename: '',
          path: '',
        },
      ] as MonthReport[]
    } else {
      if (archiveYear.months) {
        const foundMonth = archiveYear.months[YEAR_OWN_PROPERTY].find(
          (m) => m.month === month
        )
        if (!foundMonth) {
          archive[year].months![YEAR_OWN_PROPERTY].push({
            year,
            month,
            filename: '',
            path: '',
          } as MonthReport)
        }
      } else {
        archive[year].months = {}
        archive[year].months![YEAR_OWN_PROPERTY] = [
          {
            year,
            month,
            filename: '',
            path: '',
          },
        ] as MonthReport[]
      }
    }
  })

  Object.keys(archive).forEach((yearKey) => {
    if (
      archive[parseInt(yearKey, 10)].months &&
      archive[parseInt(yearKey, 10)].months![YEAR_OWN_PROPERTY]
    ) {
      archive[parseInt(yearKey, 10)].months![YEAR_OWN_PROPERTY] = archive[
        parseInt(yearKey, 10)
      ].months![YEAR_OWN_PROPERTY].map((monthReport) => {
        const specificAccountStatements = accountStatements.filter(
          (statement) =>
            new Date(statement.endDate).getMonth() + 1 === monthReport.month &&
            new Date(statement.endDate).getFullYear() === monthReport.year
        )
        if (specificAccountStatements.length > 0) {
          return {
            ...monthReport,
            accountStatements: specificAccountStatements,
          }
        } else {
          return monthReport
        }
      })
    }
  })

  preserverItems.forEach((item) => {
    const reportDate = new Date(item.endDate)
    const year = reportDate.getFullYear()
    if (!archive[year]) {
      archive[year] = {} as RArchive
    }
    if (!archive[year].preserver) {
      archive[year].preserver = [] as (PreserverAsset | YearStatement)[]
    }
  })

  aktiaItems.forEach((item) => {
    const reportDate = new Date(item.endDate)
    const year = reportDate.getFullYear()
    if (!archive[year]) {
      archive[year] = {} as RArchive
    }
    if (!archive[year].preserver) {
      archive[year].preserver = [] as AktiaCustomerPortfolio[]
    }
  })

  Object.keys(archive).forEach(
    (year) =>
      (archive[parseInt(year, 10)].preserver = archive[
        parseInt(year, 10)
      ].preserver?.concat(
        preserverItems.filter(
          (item) => new Date(item.endDate).getFullYear() === parseInt(year, 10)
        )
      ))
  )

  Object.keys(archive).forEach(
    (year) =>
      (archive[parseInt(year, 10)].preserver = archive[
        parseInt(year, 10)
      ].preserver?.concat(
        aktiaItems.filter(
          (item) => new Date(item.endDate).getFullYear() === parseInt(year, 10)
        )
      ))
  )
  return archive
}

export function toReportArchiveFromAccountStatements(
  accountStatements: AccountStatement[],
  preserverItems: (PreserverAsset | YearStatement)[],
  aktiaItems: AktiaCustomerPortfolio[]
): ArchiveMap {
  const archive = {} as ArchiveMap
  const updatedArchive = updateReportArchive(
    archive,
    accountStatements,
    preserverItems,
    aktiaItems
  )

  return updatedArchive
}

export function mapMonthlyReport(
  report: ReportArchiveReport,
  title: string
): MonthReport {
  const parts = report.filename.split('_')
  const year = Number(parts[1])
  const month = parts[2].split(' ')[0]
  const monthNumber = MonthMap[month]
  const date = new Date(year, monthNumber - 1)
  return { ...report, year, month: monthNumber, date, title }
}

function parseMonthFolder(
  folder: ReportArchiveFolder,
  title: string
): ReportMap {
  const sortedObject: ReportMap = {}
  folder.reports.forEach((report) => {
    const mReport = mapMonthlyReport(report, title)

    if (!(mReport.year in sortedObject)) {
      sortedObject[mReport.year] = []
    }

    // checks that no duplicate months are shown for same year
    if (
      sortedObject[mReport.year].findIndex(
        (value) => value.month === mReport.month
      ) === -1
    )
      sortedObject[mReport.year].push(mReport)
  })

  return sortedObject
}

function parseOtherFolder(
  folder: ReportArchiveFolder,
  key: FolderCategory,
  t: TFunction
): ReportMap {
  const sortedObject: ReportMap = {}
  folder.reports.forEach((report) => {
    const filenameSplit = report.filename.split('_')
    let year = Number(filenameSplit[1])

    let reportName
    if (key === OTHER_KUPARA) {
      reportName = `${t('reportArchive.year-kupara')} ${year}`
    } else if (key === OTHER_TAX) {
      year = Number(filenameSplit[1].split('-')[0])
      reportName = filenameSplit[1].split('-')[1]
      // deletes .pdf at the end
      if (reportName.includes('.')) {
        reportName = reportName.substr(0, reportName.indexOf('.') - 1)
      }
    } else {
      reportName = filenameSplit[2]
        ? report.filename.substr(12, report.filename.length - 16)
        : ''
    }

    if (!(year in sortedObject)) {
      sortedObject[year] = []
    }

    sortedObject[year].push({
      filename: report.filename,
      path: report.path,
      year,
      title: reportName,
      date: new Date(year + 1, 0),
    })
  })

  return sortedObject
}

const fixCategoryTitle = (category: string): string => {
  switch (category) {
    case PATH_YEAR_OWN_PROPERTY:
      return 'portfolio.wealth-own'
    case YEAR_CONTRACT:
      return 'reportArchive.year-per-contract'
    case YEAR_INSURANCE:
      return 'portfolio.insurance'
    default:
      return category
  }
}

function parseYearFolder(folder: ReportArchiveFolder, t: TFunction): YearsMap {
  const sortedObject: YearsMap = {}
  const sFolder = flatten(folder.subFolders.map((s) => s.reports))
  sFolder.forEach((report) => {
    const filename = report.filename
    // 655605_V2018_OmaVarallisuus_Omistukset_Omavarallisuus.pdf
    const parts = filename.split('_')
    const year = Number(parts[1].substr(1))
    const categoryName = camelCaseToWords(parts[3])
    let reportName = parts[4].substr(0, parts[4].length - 4)

    // checks if the report name is empty
    if (!/\S/.test(reportName)) {
      reportName = 'Yhteensä'
    }

    const correctedReportName = reportNameMappings[reportName] || reportName

    if (!(year in sortedObject)) {
      sortedObject[year] = {}
    }

    const yCategory =
      filename.indexOf(PATH_YEAR_OWN_PROPERTY) > -1 ||
      filename.indexOf(PATH_YEAR_FINANCE) > -1
        ? YEAR_OWN_PROPERTY
        : filename.indexOf(PATH_YEAR_INSURANCE) > -1
        ? YEAR_INSURANCE
        : YEAR_CONTRACT

    if (!(yCategory in sortedObject[year])) {
      sortedObject[year][yCategory] = {}
    }

    const sCategory =
      filename.indexOf(PATH_YEAR_SUB_PROPERTY) > -1
        ? YEAR_SUB_PROPERTY
        : filename.indexOf(PATH_YEAR_SUB_FINANCE) > -1
        ? YEAR_SUB_FINANCE
        : YEAR_SUB_INCOME

    if (!(sCategory in sortedObject[year][yCategory])) {
      sortedObject[year][yCategory][sCategory] = []
    }

    sortedObject[year][yCategory][sCategory].push({
      filename,
      path: report.path,
      year,
      title: `${t(fixCategoryTitle(categoryName))} - ${correctedReportName}`,
      date: new Date(year + 1, 0),
    })
  })

  return sortedObject
}
