import Spacings from '@taaleri/core/src/constants/Spacings'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { CapitalGainHeader } from './Headers/GapitalGainHeader'
import { ProfitHeader } from './Headers/ProfitHeader'
import { ProfitHeaderMobile } from './Headers/ProfitHeaderMobile'
import { CapitalGainRow } from './Rows/CapitalGainRow'
import { CapitalGainRowMobile } from './Rows/CapitalGainRowMobile'
import { ProfitRow } from './Rows/ProfitRow'
import { ProfitRowMobile } from './Rows/ProfitRowMobile'
import UiButton from '../../ui/UiButton'
import Box from '../../ui/box/Box'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

function ProfitList() {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const { isWide } = useLayout()
  const rowsPerPage = 20

  const {
    selectedInstruments,
    selectedTransactionTypes,
    selectedPortfolios,
    filteredProfitItems,
    profitCategory,
  } = ProfitStore

  useEffect(() => {
    setPage(1)
  }, [
    selectedInstruments.length,
    selectedTransactionTypes.length,
    selectedPortfolios.length,
  ])

  const allRows = filteredProfitItems.length
  const showedRows = Math.min(page * rowsPerPage, allRows)

  const noTransactions = allRows === 0

  const RowComponent = isWide
    ? profitCategory === 'Profit'
      ? ProfitRow
      : CapitalGainRow
    : profitCategory === 'Profit'
    ? ProfitRowMobile
    : CapitalGainRowMobile

  const HeaderComponent = isWide
    ? profitCategory === 'Profit'
      ? ProfitHeader
      : CapitalGainHeader
    : ProfitHeaderMobile

  return (
    <View style={{ marginTop: Spacings.S40, zIndex: 0 }}>
      <HeaderComponent />
      <Box>
        {noTransactions ? (
          <TextDefault type="h5" style={{ flex: 2, padding: Spacings.S20 }}>
            {t('reportBox.no-events')}
          </TextDefault>
        ) : (
          filteredProfitItems
            .slice(0, page * rowsPerPage)
            .map((item) => <RowComponent key={item.id} {...{ item }} />)
        )}
      </Box>
      <View style={{ alignItems: 'center' }}>
        <TextDefault type="t2" style={{ marginTop: Spacings.S24 }}>{`${t(
          'report.showing'
        )} ${showedRows}/${allRows}`}</TextDefault>
        {allRows > showedRows && (
          <UiButton
            title={t('button.load-more')}
            onPress={() => setPage(page + 1)}
            containerStyle={{
              marginTop: Spacings.S24,
              width: '100%',
              maxWidth: 327,
            }}
          />
        )}
      </View>
    </View>
  )
}

export default observer(ProfitList)
