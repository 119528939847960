import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import { TitleValue } from '../portfolio/PaymentInfoSection'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, View } from 'react-native'
import { Fund } from '@taaleri/core/src/models/Fund'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import Colors from '@taaleri/core/src/constants/Colors'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { createFundOrder } from '@taaleri/core/src/services/funds'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { ROUTE_MONTHLY_CONTRACT_VERIFICATION } from './FundsRoutes'
import useNavigation from '../../navigation/useNavigation'
import UiButton from '../../ui/UiButton'
import { useTranslation } from 'react-i18next'

interface Props {
  fund: Fund
}

function MonthlyContractContent({ fund }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { navigateApp } = useNavigation()

  useEffect(() => {
    async function createMonthlyContract() {
      setError(false)
      setLoading(true)
      const { success, response } = await createFundOrder({
        securityId: AppStore.fundId || '',
        orderType: 'BUY',
        amount: AppStore.newInvestment,
        isRecurring: true,
        recurringTransactionDay: AppStore.paymentDay,
        customerId: AppStore.customerId,
      })

      if (!success) {
        setError(true)
      }
      if (response && response.smsConfirmationId) {
        AppStore.smsConfirmationId = response.smsConfirmationId
      }
      setLoading(false)
    }

    createMonthlyContract()
  }, [retry])

  if (error) {
    return <ErrorWithRetry onRetry={() => setRetry(true)} />
  }

  return (
    <>
      <Title>{t('invest.monthly-saving-assignment')}</Title>
      <Paragraph>{t('invest.monthly-saving-stop-info')}</Paragraph>
      {loading ? (
        <LoadingIndicator style={{ height: 200 }} />
      ) : (
        <View>
          <BoxWithPaddingMargin title={`${t('invest.assignment-info')}`}>
            <TitleValue
              title={t('invest.invest-target')}
              value={fund.name}
              style={styles.titleValue}
            />
            <TitleValue
              title={t('invest.monthly-saving-sum')}
              value={formatCurrency(AppStore.newInvestment)}
            />
            <TitleValue
              title={t('invest.chosen-debit-date')}
              value={t('invest.months-day', {
                paymentDay: AppStore.paymentDay,
              })}
            />
          </BoxWithPaddingMargin>
          <UiButton
            title={t('button.continue')}
            onPress={() => navigateApp(ROUTE_MONTHLY_CONTRACT_VERIFICATION)}
            containerStyle={{ marginBottom: Spacings.S24 }}
          />
        </View>
      )}
    </>
  )
}

export default observer(MonthlyContractContent)

const styles = StyleSheet.create({
  titleValue: {
    paddingVertical: Spacings.S16,
    marginBottom: Spacings.S16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
})
