/* eslint-disable @typescript-eslint/no-explicit-any */
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native'
import { webNonSmall } from '@taaleri/core/src/utils/dimensions'
import SlideDown from '../animation/SlideDown'
import FlexRow from '../FlexRow'
import GlobalStyles from '../../constants/GlobalStyles'
import { TextDefault } from '../text/UiText'
import UiTouchable from '../UiTouchable.web'

export type BoxType = 'border' | 'verticalBorders' | 'shadow' | 'plain'
export type BoxBackgroundColor = 'white' | 'background'

interface BoxProps {
  style?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<ViewStyle>
  titleRightComponent?: any
  type?: BoxType
  backgroundColor?: BoxBackgroundColor
  title?: string
  onPress?: () => void
  children?: any
}
const Box: React.FC<BoxProps> = (props) => {
  const { titleStyle, titleRightComponent, onPress } = props
  const type: BoxType = props.type || 'shadow'
  const backgroundColor: string =
    props.backgroundColor === 'background' ? Colors.background : Colors.white

  const Container = onPress ? UiTouchable : View
  return (
    <Container
      onPress={onPress}
      style={[
        boxStyle[type],
        { backgroundColor },
        props.style,
        type === 'verticalBorders' && webNonSmall && styles.verticalBordersLg,
      ]}
    >
      {props.title && (
        <FlexRow
          style={[
            {
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottomWidth: 1,
              borderBottomColor: Colors.border,
              paddingBottom: Spacings.S16,
            },
            titleStyle,
          ]}
        >
          <TextDefault type="h5">{props.title}</TextDefault>
          {titleRightComponent}
        </FlexRow>
      )}
      {props.children}
    </Container>
  )
}

export const boxPadding = Spacings.PADDING

export const BoxWithPadding: React.FC<BoxProps> = (props) => {
  const padding = boxPadding

  return (
    <Box
      {...props}
      style={[
        {
          padding,
        },
        props.style,
      ]}
    />
  )
}

export const BoxWithPaddingMargin: React.FC<BoxProps> = (props) => {
  return (
    <BoxWithPadding
      {...props}
      style={[
        {
          marginBottom: Spacings.S32,
        },
        props.style,
      ]}
    />
  )
}

export const BoxWithPaddingM: React.FC<BoxProps> = (props) => (
  <Box
    {...props}
    style={[
      {
        paddingHorizontal: Spacings.S16,
        paddingVertical: Spacings.S16,
      },
      props.style,
    ]}
  />
)

export const BoxWithPaddingInside: React.FC<BoxProps> = (props) => (
  <SlideDown>
    <BoxWithPadding
      {...props}
      type="plain"
      style={[{ borderTopWidth: 0, paddingBottom: 0 }, props.style]}
    />
  </SlideDown>
)

const styles = StyleSheet.create({
  verticalBordersLg: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
})

const boxStyle: { [key in BoxType]: object } = {
  border: {
    borderColor: Colors.border,
    borderWidth: 1,
    borderRadius: BorderRadius.default,
  },
  verticalBorders: {
    borderColor: Colors.border,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
  },
  shadow: GlobalStyles.shadow,
  plain: {},
}

export default Box
