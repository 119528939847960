import React from 'react'

import SmsVerification from '../contract/SmsVerification'
import {
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE,
} from './PortfolioRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { setWithdrawal } from '@taaleri/core/src/services/payments'
import { createFundOrder } from '@taaleri/core/src/services/funds'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import { customers } from '@taaleri/core/src/services/customers'
import { useTranslation } from 'react-i18next'

export default function PaymentWithdrawalVerificationContent() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  if (!AppStore.withdrawal) {
    return null
  }
  const withdrawal = AppStore.withdrawal
  const whyToRedeem = withdrawal.whytoRedeem
  async function onSuccess() {
    if (withdrawal.sellEverything) {
      AppStore.withdrawAll = true
    } else {
      AppStore.newWithdrawalConfirmed = AppStore.newWithdrawal
    }

    AppStore.updateFeedCache = true
    Analytics.event('Withdrawal', 'withdraw done')
    if (whyToRedeem) {
      customers.sendFeedback(
        'Lunastus',
        whyToRedeem,
        AppStore.customerId,
        false
      )
    }
    navigate(routePathApp(ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_DONE))
  }
  const phone = AppStore.customer.phone
  const { sellEverything, amount } = withdrawal
  return (
    <SmsVerification
      t={t}
      title={`${t('portfolio.payment-withdraw-confirmation')}`}
      description={`${t('portfolio.payment-withdraw-confirm-info', {
        phone: phone,
      })}`}
      onSuccess={() => onSuccess()}
      phone={phone}
      afterConflictNavigateTo={ROUTE_PORTFOLIO}
      onPrepareVerification={async () => {
        if (
          AppStore.investmentMode === 'withdrawfund' &&
          AppStore.fundId !== 'MONEY'
        ) {
          const { success, response } = await createFundOrder({
            securityId: AppStore.fundId || '',
            orderType: 'SELL',
            amount: sellEverything ? undefined : AppStore.newWithdrawal,
            unitAmount: sellEverything ? amount : undefined,
            isRecurring: false,
            customerId: AppStore.customerId,
            sellEverything,
          })

          return success && response && response.smsConfirmationId
            ? response.smsConfirmationId
            : null
        } else {
          const { success, response } = await setWithdrawal(withdrawal)

          return success && response && response.smsConfirmationId
            ? response.smsConfirmationId
            : null
        }
      }}
      navigate={navigate}
    />
  )
}
