import { ServiceResponse } from '../models/ServiceResponse'
import { api } from '../api/api'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { SecurityPrice } from '../models/SecurityPriceResponse'

export async function getSecurityPrices(
  ticker: string,
  startDate: string,
  endDate: string
): Promise<ServiceResponse<SecurityPrice>> {
  const identity = await getIdentity()

  try {
    const response = await api().securityPrices.getSecurityPrices(
      identity,
      ticker,
      startDate,
      endDate
    )
    return { success: true, response }
  } catch (e) {
    handleErrors(e, 'getSecurityPrices')
    throw e
  }
}
