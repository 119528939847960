import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { View, Animated, TextProps } from 'react-native'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import GlobalStyles from '../../constants/GlobalStyles'
import useLayout from '../useLayout'

export type TextType =
  | 'h1'
  | 'h1white'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'ingress'
  | 't1'
  | 't2'
  | 't3'
  | 't4'
  | 't5'

export interface UiTextProps extends TextProps {
  fontFamily?: string
  size?: FontSize
  type?: TextType
  color?: string
}

const UiText: React.FC<UiTextProps> = (props) => {
  const fontSize: number = props.size ? props.size : FontSize.BASE
  return (
    <Animated.Text
      {...props}
      style={[
        {
          fontFamily: props.fontFamily,
          fontSize,
          color: props.color || Colors.text,
        },
        props.style,
      ]}
    >
      {props.children}
    </Animated.Text>
  )
}

export const TextDefault: React.FC<UiTextProps> = (props) => {
  const type = props.type || 't1'
  return (
    <UiText {...props} style={[textStyle[type], props.style]} type={type} />
  )
}

export const TextMedium: React.FC<UiTextProps> = (props) => (
  <UiText {...props} fontFamily={Fonts.avenirNextMedium} />
)
export const TextDemiBold: React.FC<UiTextProps> = (props) => (
  <UiText {...props} fontFamily={Fonts.avenirNextDemiBold} />
)

export const TextBold: React.FC<UiTextProps> = (props) => (
  <UiText {...props} fontFamily={Fonts.avenirNextBold} />
)

export const TextSmall: React.FC<UiTextProps> = (props) => (
  <TextMedium {...props} style={[GlobalStyles.smallText, props.style]} />
)

export const TextError: React.FC<UiTextProps> = (props) => (
  <TextMedium
    {...props}
    style={[{ color: Colors.error, fontSize: FontSize.S14 }, props.style]}
  />
)

type BorderVariant = 'default' | 'centeredDesktop'

export const TextWithBorder: React.FC<
  UiTextProps & { variant?: BorderVariant }
> = (props) => {
  const { isWide } = useLayout()
  const centereDesktop = props.variant === 'centeredDesktop'
  return (
    <>
      <TextDefault
        type="t1"
        style={[
          {
            color: Colors.textPrimary,
            letterSpacing: 0.4,
            marginBottom: 12,
            fontFamily: Fonts.avenirNextBold,
          },
          isWide && centereDesktop && { textAlign: 'center' },
        ]}
        {...props}
      >
        {props.children}
      </TextDefault>
      <View
        style={[
          {
            width: 32,
            height: 1,
            backgroundColor: Colors.textPrimary,
            marginBottom: 24,
          },
          isWide && centereDesktop && { alignSelf: 'center' },
        ]}
      />
    </>
  )
}

const textStyle: { [key in TextType]: {} } = {
  h1: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S32,
    lineHeight: 40,
    letterSpacing: -0.2,
  },
  h1white: {
    color: Colors.white,
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S34,
    lineHeight: 42,
    letterSpacing: -0.2,
  },
  h2: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S24,
    lineHeight: 32,
    letterSpacing: -0.3,
  },
  h3: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S20,
    lineHeight: 28,
    letterSpacing: -0.1,
  },
  h4: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S18,
    lineHeight: 26,
    letterSpacing: -0.1,
  },
  h5: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S16,
    lineHeight: 24,
  },
  h6: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S14,
    lineHeight: 22,
    letterSpacing: -0.1,
  },
  h7: {
    fontFamily: Fonts.avenirNextBold,
    fontSize: FontSize.S12,
    lineHeight: 18,
    letterSpacing: -0.1,
  },
  ingress: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S24,
    lineHeight: 36,
    letterSpacing: 0.2,
  },
  t1: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S16,
    lineHeight: 26,
  },
  t2: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S14,
    lineHeight: 22,
  },
  t3: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S12,
    lineHeight: 18,
  },
  t4: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S10,
    lineHeight: 14,
  },
  t5: {
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S10,
    lineHeight: 14,
    color: Colors.textSecondary,
    textTransform: 'uppercase',
    letterSpacing: 0.6,
  },
}
