import React from 'react'
import { BackButtonRow } from '@taaleri/components/src/ui/BackButton'
import ContractOrdersList from '@taaleri/components/src/screens/contracts/ContractOrdersList'
import { useTranslation } from 'react-i18next'

function ContractOrderScreen() {
  const { t } = useTranslation()
  return (
    <>
      <BackButtonRow title={`${t('reportBox.assignments')}`} />
      <ContractOrdersList />
    </>
  )
}

export default ContractOrderScreen
