import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import {
  formatNumber,
  formatNumberWithPrefix,
} from '@taaleri/core/src/utils/format'
import { TextBold, TextMedium } from './UiText'

interface ColorFormattedNumberBaseProps {
  value: number
  style?: StyleProp<TextStyle>
  hidePrefix?: boolean
  medium?: boolean
}

interface ColorFormattedNumberProps extends ColorFormattedNumberBaseProps {
  postFix: string
}

export const ColorFormattedNumber: React.FC<ColorFormattedNumberProps> = ({
  value,
  style,
  postFix,
  hidePrefix,
  medium,
}) => {
  const percentUp = value > 0
  const percentStyle = percentUp
    ? styles.percentUp
    : value === 0
    ? styles.percentZero
    : styles.percentDown
  const formattedValue = hidePrefix
    ? formatNumber(value)
    : formatNumberWithPrefix(value)
  const TextComponent = medium ? TextMedium : TextBold
  return (
    <TextComponent
      style={[percentStyle, style]}
    >{`${formattedValue} ${postFix}`}</TextComponent>
  )
}

export const FormattedCurrency: React.FC<ColorFormattedNumberBaseProps> = ({
  value,
  style,
}) => {
  return (
    <TextBold style={[style]}>{formatNumberWithPrefix(value) + ' €'}</TextBold>
  )
}

const styles = StyleSheet.create({
  percentZero: {
    color: Colors.text,
  },
  percentUp: {
    color: Colors.textPrimary,
  },
  percentDown: {
    color: Colors.error,
  },
})

export const ColorFormattedPercentage: React.FC<ColorFormattedNumberBaseProps> =
  (props) => <ColorFormattedNumber {...props} postFix="%" />

export const ColorFormattedCurrency: React.FC<ColorFormattedNumberBaseProps> = (
  props
) => <ColorFormattedNumber {...props} postFix="€" />

export function getChangeColor(value: number, inverted?: boolean): string {
  if (value === 0) {
    return Colors.text
  }
  const percentUp = value > 0
  if (inverted) {
    return percentUp ? Colors.error : Colors.textPrimary
  }
  return percentUp ? Colors.textPrimary : Colors.error
}
