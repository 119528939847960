import React from 'react'
import QuestionContainer from './QuestionContainer'
import OnboardingComparisonContent from '@taaleri/components/src/screens/questions/OnboardingComparisonContent'

export default function OnboardingComparisonScreen() {
  return (
    <QuestionContainer>
      <OnboardingComparisonContent />
    </QuestionContainer>
  )
}
