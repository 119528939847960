import { CombinedIndex } from '@taaleri/core/src/models/CombinedIndex'
import { getPreviousYear } from '@taaleri/core/src/utils/date'
import { IdValuePair } from '@taaleri/core/src/models/IdValuePair'

export function toHistoricalChange(  
  combinedIndex: CombinedIndex
): IdValuePair[] {
  return [
    { id: 'form.month-short:1', value: combinedIndex.performance1M },
    { id: 'form.month-short:3', value: combinedIndex.performance3M },
    { id: 'form.year:1', value: combinedIndex.performance1Year },
    {
      id: `form.year:${getPreviousYear(new Date(combinedIndex.endDate))}`,
      value: combinedIndex.performanceLastYear,
    },
    {
      id: 'form.from-start-short',
      value: combinedIndex.performanceSinceInception,
    },
  ]
}
