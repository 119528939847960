import React from 'react'
import { StyleProp, ViewStyle, TextStyle } from 'react-native'
import { useField } from 'formik'
import ListItem from '../ListItem'

interface Props {
  bottomDivider?: boolean
  containerStyle?: StyleProp<ViewStyle>
  topDivider?: boolean
  name: string
  title: string
  onChangeText?: (value: any) => void
  value?: boolean
  textStyle?: StyleProp<TextStyle>
}

const UiListItemSwitch: React.FC<Props> = (props) => {
  const {
    bottomDivider,
    containerStyle,
    topDivider,
    title,
    name,
    textStyle,
    onChangeText,
  } = props

  const [field, , helpers] = useField(name)
  const value = field.value
  return (
    <ListItem
      textStyle={textStyle}
      title={title}
      topDivider={topDivider === false ? false : true}
      bottomDivider={bottomDivider || false}
      containerStyle={containerStyle}
      noPadding={true}
      switchProps={{
        value,
        onValueChange: (currentValue: boolean) => {
          if (onChangeText) {
            onChangeText(currentValue)
          }
          helpers.setValue(currentValue)
        },
      }}
    />
  )
}

export default UiListItemSwitch
