import React from 'react'
import siginInRoute from '@taaleri/components/src/screens/signin/signInRoute'
import {
  PasswordRecoveryMode,
  Registration,
} from '@taaleri/core/src/models/Identity'

import { authentication } from '@taaleri/core/src/services/authentication'

import { TitleWithoutNav } from '@taaleri/components/src/ui/text/Title'
import RegisterForm from './RegisterForm'
import BackgroundContainer from 'ui/container/BackgroundContainer'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import routePath, {
  routePathAfterSignIn,
} from '@taaleri/components/src/navigation/routeHelper'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { ROUTE_SIGN_IN } from '@taaleri/components/src/navigation/routes'
import { loginToDataLayer } from 'utils/DataLayer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { useTranslation } from 'react-i18next'

export default function ChangePasswordScreen() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const history = useHistory()

  /*
  Redirect customer to login, if state is null. 
  This might happen i.e. if customer tries to 
  navigate to this screen directly
  */
  if (!state) {
    return <Redirect to={routePath(ROUTE_SIGN_IN)} />
  }

  const { mode, email, token, customerid } = state as any

  const isForgotPassword = mode === PasswordRecoveryMode.ForgotPassword

  const title = isForgotPassword
    ? 'profile.settingsSection.change-password'
    : 'profile.settingsSection.change-password-info'

  const registrationApiCall = async (values: Registration) => {
    return authentication.doRegistrationExisting(token, values)
  }

  const apiCall = isForgotPassword
    ? authentication.changeForgottenPassword(token)
    : registrationApiCall
  return (
    <BackgroundContainer contentType="centered">
      <TitleWithoutNav>{t(title)}</TitleWithoutNav>

      <RegisterForm
        isOnboarding={false}
        registration={{
          email: email || '',
          password: '',
          customerid: customerid || '',
        }}
        emailDisabled={isForgotPassword && !customerid}
        buttonTitle={t('button.save-and-login')}
        apiCall={apiCall}
        analyticsCategory="Forgot password"
        analyticsAction={
          isForgotPassword ? 'Change password' : 'Create account'
        }
        toNextRoute={async () => {
          QuestionStore.email = email
          const route = await siginInRoute()
          history.push(route)
          loginToDataLayer(AppStore.baseCustomerId, AppStore.customerId)
        }}
        token={token}
      />
    </BackgroundContainer>
  )
}
