/* eslint-disable prefer-spread */
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useState, useRef } from 'react'
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { isWeb } from '../constants/Platforms'
import { FontSize } from '@taaleri/core/src/constants/Fonts'

interface Props {
  value: string
  onTextChange: (code: string) => any
  onFulfill: (code: string) => any
  error: boolean
}

const CODE_LENGTH = 4

export default function ConfirmationCodeInput({
  value,
  error,
  onTextChange,
  onFulfill,
}: Props) {
  const [focused, setFocused] = useState<boolean>(false)

  const textInputRef = useRef<TextInput>(null)

  const inputCode = (code: any) => {
    if (onTextChange) {
      onTextChange(code)
    }
    if (code.length === CODE_LENGTH && onFulfill) {
      onFulfill(code)
    }
  }

  const cellSize = 56

  return (
    <View
      style={[
        {
          flex: 1,
        },
      ]}
    >
      <View
        style={{
          height: '100%',
          width: '100%',
          flexDirection: 'row',
          justifyContent:
            Dimensions.get('window').width < 400 ? 'space-between' : 'center',
          alignItems: 'center',
        }}
      >
        {Array.apply(null, Array(CODE_LENGTH)).map((_, idx: number) => {
          const cellFocused = focused && idx === value.length
          return (
            <TouchableWithoutFeedback
              key={idx}
              onPress={() => {
                if (textInputRef.current) {
                  textInputRef.current.focus()
                }
              }}
            >
              <View
                style={[
                  styles.cell,
                  cellFocused ? styles.cellFocused : {},
                  error ? styles.cellError : {},
                  {
                    flex: 1,
                    height: cellSize,
                    maxWidth: 70,
                    marginRight: idx === CODE_LENGTH - 1 ? 0 : Spacings.S8,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                ]}
              >
                <Text style={[styles.text]}>{value.charAt(idx)}</Text>
              </View>
            </TouchableWithoutFeedback>
          )
        })}
      </View>
      <TextInput
        ref={textInputRef}
        value={value}
        onChangeText={inputCode}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        spellCheck={false}
        autoFocus={true}
        keyboardType="phone-pad"
        textContentType="oneTimeCode"
        numberOfLines={1}
        maxLength={CODE_LENGTH}
        selection={{
          start: value.length,
          end: value.length,
        }}
        style={[
          {
            opacity: 0,
          },
          isWeb && {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        ]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  cell: {
    borderColor: Colors.gray20,
    borderWidth: 1,
  },
  cellFocused: {
    borderColor: Colors.primary,
    borderWidth: 2,
  },
  cellError: {
    borderColor: Colors.error,
    borderWidth: 2,
  },
  text: {
    color: Colors.text,
    fontSize: FontSize.S24,
  },
  textFocused: {
    color: Colors.text,
  },
})
