import React from 'react'
import { TextDefault, TextType } from '../../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleProp, TextStyle } from 'react-native'

interface Props {
  children?: string
  type?: TextType
  style?: StyleProp<TextStyle>
}

export function RowItem({ children = '', type = 't3', style }: Props) {
  return (
    <TextDefault
      type={type}
      style={[{ paddingHorizontal: Spacings.S2, flex: 1 }, style]}
    >
      {children}
    </TextDefault>
  )
}
