import React, { useState } from 'react'
import { View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { LinearGradient, Defs, Stop } from 'react-native-svg'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
} from '@taaleri/components/src/ui/Victory'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { FinanceChartProps } from './FinanceChartProps'
import { TimeseriesType } from './TimeseriesType'
import { normal } from 'jstat'
import range from 'lodash/range'
import useLayout from '../../ui/useLayout'
import { observer } from 'mobx-react'
import FinanceChartIntro from './FinanceChartIntro'
import {
  InitialInvestmentSlider,
  MonthlyInvestmentSlider,
} from './AmountContent'
import { toFinanceChartProps } from './investmentproposal/toFinanceChartProps'
import { formatYLabel } from '../calculator/CalculatorGraph'
import { Paragraph } from '../../ui/text/Paragraph'
import { ChartLegend } from './ChartLegend'
import { useTranslation } from 'react-i18next'

const yAxisStyle = {
  axis: { stroke: 'transparent' },
  tickLabels: {
    fill: Colors.gray70,
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S10,
    textAnchor: 'start',
  },
  grid: {
    stroke: Colors.inactiveTint,
    strokeDasharray: '1,3',
    strokeWidth: 1,
    strokeOpacity: 1,
  },
}

const xAxisStyle = {
  axis: { stroke: Colors.border, strokeWidth: 1 },
  axisLabel: { stroke: Colors.border, strokeWidth: 1 },
  ticks: { color: Colors.error },
  tickLabels: {
    fill: Colors.gray70,
    fontFamily: Fonts.avenirNextMedium,
    fontSize: FontSize.S10,
    textAnchor: 'start',
  },
}

const lineLinearGradientId = 'lineLinearGradient'
const areaLinearGradientId = 'areaLinearGradient'
const lineStyle = {
  data: {
    strokeWidth: 2,
    fill: 'transparent',
    stroke: `url(#${lineLinearGradientId})`,
  },
}

const areaStyle = {
  data: {
    fill: `url(#${areaLinearGradientId})`,
    stroke: `transparent`,
  },
}

const getCone = (
  data: TimeseriesType,
  stdDev: number,
  p: number,
  p2: number
): TimeseriesType => {
  const r = data.map((item, index) => {
    const realStd = item.y * stdDev * Math.sqrt(index)
    return {
      x: item.x,
      y: normal.inv(p, item.y, realStd),
      y0: normal.inv(p2, item.y, realStd),
    }
  })
  return r
}

function FinanceChart({ investmentProposal }: FinanceChartProps) {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>(450)
  const { isSmall } = useLayout()
  const {
    initialInvestment,
    monthlyInvestment,
    age,
    investmentTime,
    endAge,
    riskLevel,
  } = QuestionStore

  const { chartProps } = toFinanceChartProps(
    investmentProposal,
    initialInvestment,
    monthlyInvestment,
    age,
    investmentTime,
    endAge,
    riskLevel
  )

  const { data, standardDeviation, investedCapital, revenue, finalBalance } =
    chartProps

  /*
  Cone variables: VR: 20.08.2019
  - ConfidenceInterval defines width of the cone
  - Step defines the smoothness of the gradient
  - MinOpacity is the opacity of the outermost area
  */
  const confidenceInterval = 0.6
  const step = 0.1
  const minOpacity = 0.12

  // areaLimits is an array which is used to create the gradient area. VR: 20.08.2019
  const areaLimits = range(0.5 - confidenceInterval / 2, 0.5, step)
  const showMessage = QuestionStore.initialInvestment < 1000
  return (
    <View onLayout={(event) => setWidth(event.nativeEvent.layout.width)}>
      <FinanceChartIntro
        {...{ endAge, finalBalance, revenue, investedCapital }}
      />
      <InitialInvestmentSlider />
      <MonthlyInvestmentSlider />
      {showMessage && (
        <Paragraph style={{ color: Colors.error, marginTop: Spacings.S24 }}>
          {t('invest.invest-remark')}
        </Paragraph>
      )}

      <View pointerEvents="none" style={{ marginTop: Spacings.S24 }}>
        <VictoryChart
          padding={{
            top: isSmall ? 0 : Spacings.S24,
            bottom: Spacings.S32,
          }}
          width={width}
          height={isSmall ? 250 : 400}
        >
          <Defs>
            <LinearGradient
              id={lineLinearGradientId}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <Stop offset="0%" stopColor={Colors.primary} />
              <Stop offset="100%" stopColor="#00AADA" />
            </LinearGradient>
          </Defs>
          <Defs>
            <LinearGradient
              id={areaLinearGradientId}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <Stop offset="0%" stopColor="#00AADA" stopOpacity={minOpacity} />
              <Stop
                offset="100%"
                stopColor="#00AADA"
                stopOpacity={minOpacity}
              />
            </LinearGradient>
          </Defs>
          {areaLimits.map((lowerLimit) => (
            <VictoryArea
              key={lowerLimit}
              style={areaStyle}
              data={getCone(
                data,
                standardDeviation,
                1 - lowerLimit,
                lowerLimit
              )}
            />
          ))}
          <VictoryLine data={data} style={lineStyle} />
          <VictoryAxis
            crossAxis={true}
            tickFormat={(t: number): string =>
              t === QuestionStore.age ? '' : t.toString() + 'v'
            }
            fixLabelOverlap={true}
            style={xAxisStyle}
          />
          <VictoryAxis
            dependentAxis={true}
            tickLabelComponent={<VictoryLabel dy={-7} x={0} />}
            tickFormat={formatYLabel}
            style={yAxisStyle}
          />
        </VictoryChart>
      </View>
      <ChartLegend items={[{ title: `${t('questions.proposal.forecast')}`, color: '#00AADA' }]} />
    </View>
  )
}

export default observer(FinanceChart)
