import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useEffect } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import ContactCustomerServiceButtons from '../../ui/ContactCustomerServiceButtons'
import { IconWarning } from '../../ui/Icons'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextBold } from '../../ui/text/UiText'
import { customers } from '@taaleri/core/src/services/customers'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function FurtherClarificationContent() {
  const { t } = useTranslation()
  async function sendSlackNotification() {
    const isOnboarding = QuestionStore.questionMode === 'onboarding'
    QuestionStore.furtherClarificationReason &&
      customers.sendFeedback(
        'Lisäselvityspyyntö',
        `${isOnboarding ? 'Uusi asiakas' : 'Perustiedot'}: ${
          QuestionStore.furtherClarificationReason
        }`,
        QuestionStore.customer.customerId,
        true
      )
    QuestionStore.setFurtherClarificationReason('')
  }
  useEffect(() => {
    sendSlackNotification()
  }, [])
  return (
    <ScrollView
      contentContainerStyle={[
        {
          flex: 1,
          paddingVertical: Spacings.S32,
          paddingHorizontal: Spacings.S24,
        },
      ]}
    >
      <View style={styles.container}>
        <IconWarning color={Colors.text} style={styles.icon} />
        <TextBold style={{ marginBottom: Spacings.S8 }}>
          {t('onboarding.further-clarification')}
        </TextBold>
        <Paragraph style={styles.paragraph}>
          {t('onboarding.further-clarification-content1')}
        </Paragraph>
        <Paragraph style={[styles.paragraph, { marginBottom: Spacings.S64 }]}>
          {t('onboarding.further-clarification-content2')}
        </Paragraph>

        <ContactCustomerServiceButtons />
      </View>
    </ScrollView>
  )
}

export default observer(FurtherClarificationContent)

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'flex-end', alignItems: 'center' },
  icon: { marginBottom: Spacings.S16 },
  paragraph: { textAlign: 'center' },
  restartButton: {
    marginTop: Spacings.S24,
    marginBottom: Spacings.S16,
    color: Colors.primary,
    textAlign: 'center',
  },
})
