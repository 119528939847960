import { fiKey } from '../i18n'

type dictionaryItem = {
  fi: string
  sv: string
}

const dictionary: dictionaryItem[] = [
  { fi: 'Täyden valtakirjan sopimus', sv: 'Avtal - diskret. portföljförv.' },
  { fi: 'Konsultatiivinen sopimus', sv: 'Konsultativt avtal' },
  { fi: 'Koontiraportointisopimus', sv: 'Rapporteringsavtal' },
  { fi: 'Arvopaperinvälityssopimus', sv: 'Avtal - värdepappersförmedling' },
  { fi: 'Muu varainhoitaja', sv: 'Övrig kapitalförvaltare' },
  { fi: 'Omat rahastot', sv: 'Egna fonder' },
  { fi: 'Tehtaan salkku', sv: 'Fabriksportfölj' },
  { fi: 'Siirretty', sv: 'Överförd' },
  { fi: 'Mallisalkku', sv: 'Modellportfölj' },
  { fi: 'Tehtaanmyymälä', sv: 'Fabriksbutik' },
  { fi: 'Taaleri Kauppa', sv: 'Taaleri Handel' },
  { fi: 'FOF rahastot', sv: 'FOF-fonder' },
  { fi: 'Aktia Salkku', sv: 'Aktia Portfölj' },
  { fi: 'Digikonsultatiivinen sopimus', sv: 'Digitalt konsultativt avtal' },
  { fi: 'Uusi OHS', sv: 'Avtal - förmögenhetsförvaltn.' },
  { fi: 'Asiakassuhdesopimus', sv: 'Avtal om kundförhållande' },
  { fi: 'Verkkopankki', sv: 'Nätbanken' },
  { fi: 'Kapitalisaatioallokointi', sv: 'Kapitaliseringsallokering' },
  { fi: 'Allokointipalvelu+', sv: 'Allokeringstjänst+' },
  { fi: 'Säästövakuutus', sv: 'Sparförsäkring' },
  { fi: 'Profiilipalvelu', sv: 'Profiltjänst' },
  { fi: 'Aktiivi', sv: 'Aktiv' },
  { fi: 'Eurooppa osinko', sv: 'Europa dividend' },
  { fi: 'Kehittyvien osakkeet', sv: 'Aktier på utvecklingsmarknader' },
  { fi: 'Korko allokaatio', sv: 'Ränteallokering' },
  { fi: 'Laajennettu allokaatio', sv: 'Omfattande allokering' },
  { fi: 'Suomi osinko', sv: 'Finland dividend' },
  { fi: 'Dynaaminen', sv: 'Dynamisk' },
  { fi: 'Passiivi', sv: 'Passiv' },
  { fi: 'Yksilöllinen JVK', sv: 'Individuell obligation' },
  { fi: 'Yksilöllinen V', sv: 'Individuell V' },
  { fi: 'Yksilöllinen IV', sv: 'Individuell IV' },
  { fi: 'Yksilöllinen III', sv: 'Individuell III' },
  { fi: 'Yksilöllinen II', sv: 'Individuell II' },
  { fi: 'Yksilöllinen I', sv: 'Individuell I' },
  { fi: 'Yksilöllinen urheilijarahasto', sv: 'Individuell idrottarfond' },
  { fi: 'Yksilöllinen Digiasiakkuus', sv: 'Individ. digital kundrelation' },
  { fi: 'Yksilöllinen', sv: 'Individuell' },
  { fi: 'Maltillinen', sv: 'Moderat' },
  { fi: 'Tuottohakuinen', sv: 'Avkastningsinriktad' },
  { fi: 'Tasapainoinen', sv: 'Balanserad' },
  { fi: 'Rohkea', sv: 'Modig' },
  { fi: 'Varovainen', sv: 'Försiktig' },
  { fi: 'Vaihtoehtoiset', sv: 'Alternativa' },
  { fi: 'POR', sv: 'Kapitalfonder' },
  { fi: 'JVK', sv: 'Obligation' },
  { fi: 'Allokaatio', sv: 'Allokering' },
  { fi: 'Yhteensä', sv: 'Totalt' },
  { fi: 'strategia', sv: 'strategi' },
  { fi: 'Strategia', sv: 'Strategi' },
  { fi: 'Omistukset', sv: 'Innehav' },
  { fi: 'Varainhoitopalkkiot', sv: 'Kapitalförvaltningsprovisioner' },
  {
    fi: 'Realisoituneet voitot ja tappiot',
    sv: 'Realiserade vinster och förluster',
  },
  { fi: 'Tuotot', sv: 'Avkastning' },
  {
    fi: 'Maksetut jälkimarkkinahyvitykset',
    sv: 'Betalda eftermarknadsgottgörelser',
  },
  { fi: 'Oma varallisuus', sv: 'Egna tillgångar' },
]

const contractNames: dictionaryItem[] = [
  {
    fi: 'Digiasiakkaan varainhoitosopimus',
    sv: 'Kapitalförvaltningsavtal för digitala kunder',
  },
  {
    fi: 'Digiperustiedot',
    sv: 'Grunduppgifter, digital kund',
  },
]

export function replaceNameParts(name: string, language: string): string {
  if (language !== fiKey && name !== '') {
    dictionary.forEach((item) => {
      if (name.includes(item.fi)) {
        name = name.replaceAll(item.fi, item.sv)
      }
    })
  }

  return name
}

export function replaceContractName(name: string, language: string): string {
  if (name !== '') {
    if (language !== fiKey) {
      contractNames.forEach((item) => {
        if (name.includes(item.fi)) {
          name = name.replaceAll(item.fi, item.sv)
        }
      })
    } else {
      contractNames.forEach((item) => {
        if (name.includes(item.sv)) {
          name = name.replaceAll(item.sv, item.fi)
        }
      })
    }
  }

  return name
}
