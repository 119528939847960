import React from 'react'
import Box from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import HeaderImage from '../portfolio/HeaderImage'
import { getFundImageSource } from '@taaleri/core/src/constants/AssetClass'
import { useFund } from '../funds/useFunds'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import ValueLabel from '../impact/ValueLabel'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import { View } from 'react-native'
import useLayout from '../../ui/useLayout'
import { MobilePadding } from '../funds/FundDetailContent'
import PaymentInfoSection from '../portfolio/PaymentInfoSection'
import ListItemToggle from '../profile/ListItemToggle'
import UiButton from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_FUNDS_INVEST_START_MONTHLY } from '../funds/FundsRoutes'
import { TextMedium } from '../../ui/text/UiText'
import UiTouchable from '../../ui/UiTouchable'
import Colors from '@taaleri/core/src/constants/Colors'
import {
  ROUTE_RECURRING_ORDER_CANCEL,
  ROUTE_RECURRING_ORDERS_LIST,
} from './RecurringOrderRoutes'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

function RecurringOrderDetail() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const { isNonSmall } = useLayout()
  const order = AppStore.orders.find((o) => o.id === AppStore.recurringOrderId)

  const { fund, error, retry } = useFund(order.securityId)
  if (error) {
    return <ErrorWithRetry onRetry={retry} />
  }

  if (!fund) {
    return <LoadingIndicator type="inline" />
  }

  if (!order) {
    return null
  }

  const item = AppStore.getAssetClassItemByTickerFirst(
    fund.ticker,
    'consultative'
  )

  const marketValue = item ? item.marketValue : 0

  return (
    <>
      <HeaderImage title={fund.name} imageSource={getFundImageSource(fund)} />
      <View
        style={{
          marginHorizontal: isNonSmall ? 'auto' : 0,
          minWidth: isNonSmall ? 640 : 0,
          maxWidth: 640,
          flex: 1,
          marginBottom: Spacings.S24,
        }}
      >
        <MobilePadding>
          <Box style={{ marginBottom: Spacings.S40, marginTop: -28 }}>
            <ValueLabel
              label={`${t('app.calculator.monthlyinvestment')}`}
              value={`${formatCurrency(order.amount)}/${t('form.month-short')}`}
            />
            <ValueLabel
              label={`${t('impact.value')}`}
              value={formatCurrency(marketValue)}
            />
            <ValueLabel
              label={`${t('steps.due-date')}`}
              value={`${t('invest.months-day', {
                paymentDay: order.recurringTransactionDay,
              })}`}
            />
            <ListItemToggle
              title={`${t('invest.bank-transaction-payment-info')}`}
              listItemStyle={{ paddingHorizontal: Spacings.S16 }}
            >
              <PaymentInfoSection
                hideTitle={true}
                sum={order.amount}
                isConsultative={true}
                noPadding={true}
              />
            </ListItemToggle>
            <View style={{ padding: Spacings.S24 }}>
              <UiButton
                title={t('button.change-assingment')}
                onPress={() => navigateApp(ROUTE_FUNDS_INVEST_START_MONTHLY)}
              />

              <UiTouchable
                style={{
                  marginTop: Spacings.S32,
                  marginBottom: Spacings.S8,
                  alignItems: 'center',
                }}
                onPress={() => navigateApp(ROUTE_RECURRING_ORDER_CANCEL)}
              >
                <TextMedium style={{ color: Colors.textSecondary }}>
                  {t('questions.cancel-assingment')}
                </TextMedium>
              </UiTouchable>
            </View>
          </Box>
          {isWeb && (
            <UiButton
              title={t('button.back')}
              type="secondary"
              onPress={() => navigateApp(ROUTE_RECURRING_ORDERS_LIST)}
            />
          )}
        </MobilePadding>
      </View>
    </>
  )
}

export default RecurringOrderDetail
