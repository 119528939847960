import React from 'react'

import QuestionContainer from 'screens/questions/QuestionContainer'
import { useHistory } from 'react-router-dom'
import { OnboardingVerification } from '@taaleri/components/src/screens/onboarding/OnboardingVerification'

export default function OnboardingVerificationScreen() {
  const history = useHistory()
  return (
    <QuestionContainer>
      <OnboardingVerification navigate={history.push} />
    </QuestionContainer>
  )
}
