import styled from 'styled-components'
import { BreakPoint } from '../constants/BreakPoint'

export const HiddenSmall = styled.div`
  display: none;
  @media (min-width: ${BreakPoint.S}px) {
    display: block;
  }
`

export const HiddenGtSmall = styled.div`
  display: block;
  @media (min-width: ${BreakPoint.S}px) {
    display: none;
  }
`
