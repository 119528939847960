import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { TextBold, TextDefault } from './UiText'
import useScaledSize from '../hooks/useScaledSize'
import { isNarrowDevice } from '@taaleri/core/src/utils/dimensions'
import { isWeb } from '../../constants/Platforms'
import { TextBoldHover } from './TextHover'

export interface TitleProps {
  style?: StyleProp<TextStyle>
  size?: FontSize
  hover?: boolean
  children?: any
}

const Title: React.FC<TitleProps> = ({ children, style, size, hover }) => {
  const sizeUsed = size || FontSize.S20
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fontSize = isWeb ? useScaledSize(1, sizeUsed) : sizeUsed
  const TextComponent = hover ? TextBoldHover : TextBold
  const titleStyles = [styles.title, style]

  return (
    <TextComponent
      size={fontSize}
      style={isWeb ? StyleSheet.flatten(titleStyles) : titleStyles}
    >
      {children}
    </TextComponent>
  )
}

export const Title3: React.FC<TitleProps> = ({ children, style }) => {
  return (
    <TextDefault
      type="h5"
      style={[
        styles.title,
        { marginTop: 0, marginBottom: Spacings.S16 },
        style,
      ]}
    >
      {children}
    </TextDefault>
  )
}

export const TitleLarge: React.FC<TitleProps> = ({ children, style }) => (
  <Title size={FontSize.S24} style={[styles.titleLarge, style]}>
    {children}
  </Title>
)

export const TitleWithoutNav: React.FC<TitleProps> = ({ children, style }) => (
  <Title style={[{ marginTop: 88, marginBottom: Spacings.S32 }, style]}>
    {children}
  </Title>
)

const isSmallDevice = isNarrowDevice()

export default Title

const styles = StyleSheet.create({
  titleBox: {
    marginTop: 0,
    marginBottom: Spacings.S16,
  },
  title: {
    marginTop: isSmallDevice ? Spacings.S16 : Spacings.S24,
    marginBottom: isSmallDevice ? Spacings.S16 : Spacings.S24,
    letterSpacing: -0.2,
    lineHeight: 26,
    display: 'flex',
  },
  titleLarge: {
    textAlign: 'center',
    marginTop: 0,
    justifyContent: 'center',
    marginBottom: Spacings.S40,
  },
})
