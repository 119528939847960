import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import UiTouchable from '../../../ui/UiTouchable'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../../ui/FlexRow'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { IconCircleMedium, IconCalendarFilter } from '../../../ui/Icons'
import Fonts from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconChevronDown, IconChevronUp } from '../../../ui/icons/ChevronIcons'

function SelectBox({
  isOpen,
  children,
  onPress,
  containerStyle,
}: {
  isOpen: boolean
  children: any
  onPress: () => void
  containerStyle?: StyleProp<ViewStyle>
}) {
  const style = {
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.border,
    borderRadius: BorderRadius.default,
    paddingVertical: Spacings.S10,
    paddingHorizontal: Spacings.S20,
    backgroundColor: isOpen ? Colors.backgroundPale : Colors.white,
  }
  return (
    <UiTouchable style={[style, containerStyle]} onPress={onPress}>
      {children}
    </UiTouchable>
  )
}

function Count({ children }: { children: any }) {
  return (
    <View
      style={{
        position: 'absolute',
        top: 5,
        display: 'flex',
        justifyContent: 'center',
        width: Spacings.S24,
        alignItems: 'center',
      }}
    >
      <TextDefault
        type="t3"
        style={{ color: Colors.primary, fontFamily: Fonts.avenirNextBold }}
      >
        {children}
      </TextDefault>
    </View>
  )
}

interface Props {
  onToggle: () => void
  isOpen: boolean
  count?: number
  titleComponent: Element
  containerStyle?: StyleProp<ViewStyle>
  isDateFilter?: boolean
}

function Selector({
  onToggle,
  isOpen,
  count = 0,
  titleComponent,
  containerStyle,
  isDateFilter,
}: Props) {
  return (
    <SelectBox
      onPress={onToggle}
      isOpen={isOpen}
      containerStyle={[
        { minHeight: 58, justifyContent: 'center' },
        containerStyle,
      ]}
    >
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <FlexRow style={{ alignItems: 'center', flex: 1 }}>
          <View>
            {isDateFilter ? (
              <IconCalendarFilter />
            ) : (
              <>
                <IconCircleMedium />
                <Count>{count}</Count>
              </>
            )}
          </View>
          {titleComponent}
        </FlexRow>
        {isOpen && <IconChevronDown />}
        {!isOpen && <IconChevronUp />}
      </FlexRow>
    </SelectBox>
  )
}

export default Selector
