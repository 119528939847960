export const CONFLICT = 'CONFLICT'
export const UNEXPECTED = 'UNEXPECTED'
export const NETWORK = 'NETWORK'
export const TIMEOUT = 'TIMEOUT'
export const ABORTERROR = 'ABORTERROR'
export const EXPIRED = 'EXPIRED'
export const TEAPOT = 'TEAPOT'
export const NOTFOUND = 'NOTFOUND'

/** Response type used by services */
export interface ServiceResponse<T> {
  success: boolean
  response?: T
  error?:
    | 'CONFLICT'
    | 'UNEXPECTED'
    | 'NETWORK'
    | 'TIMEOUT'
    | 'ABORTERROR'
    | 'EXPIRED'
    | 'TEAPOT'
    | 'NOTFOUND'
}

export interface SmsResponse {
  validationErrors: string[]
  smsConfirmationId: string
}
