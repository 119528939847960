import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Spacings from '@taaleri/core/src/constants/Spacings'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TextBold } from '../text/UiText'
import { IconExclamationCircle } from '../icons/CircleIcons'
import { useTranslation } from 'react-i18next'

const flatten = (list: any[]): any[] =>
  list.reduce(
    (acc: any[], item: any) =>
      acc.concat(Array.isArray(item) ? flatten(item) : item),
    []
  )

function flatErrorMessageValues(errors: any): any[] {
  const errorValues = Object.values(errors || {})
  const values = errorValues.map((item: any) => {
    if (typeof item === 'string') {
      return item
    }
    // prettier-ignore
    // @ts-ignore
    const arrayValues = Object.values(item).filter(Boolean).map(Object.values)
    return arrayValues
  })
  return flatten(values)
}

const AllFormErrors: React.FC<any> = ({ errors, touched }) => {
  const { t } = useTranslation()
  const errorMessages: string[] = flatErrorMessageValues(errors)
  if (errorMessages.length === 0 || isEmpty(touched)) {
    return null
  }
  return (
    <View style={styles.box}>
      <IconExclamationCircle color={Colors.error} style={styles.icon} />
      <TextBold style={styles.text}>
        {t(ErrorMessages.checkMissingInformation)}
      </TextBold>
    </View>
  )
}

export default AllFormErrors

const styles = StyleSheet.create({
  box: {
    paddingHorizontal: Spacings.S24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: Spacings.S16,
    borderRadius: BorderRadius.default,
  },
  icon: {
    marginRight: 10,
  },
  text: {
    color: Colors.error,
  },
})
