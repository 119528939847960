import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  ArchiveResponseObject,
  PublicationServiceBase,
} from '@taaleri/core/src/models/ReportArchive'
import { reportsArchive } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import sortBy from 'lodash/sortBy'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import { isWeb } from '../../constants/Platforms'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { ButtonSecondaryWithChildren } from '../../ui/UiButton'
import { BoxWithPadding } from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import { toPublicationServiceLink } from '../reportarchive/CollapsibleReportList'
import { formatDateFull } from '@taaleri/core/src/utils/format'

export default function OpinionsContent() {
  const suitabilityReportId = 'SuitabilityStatement'
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const [suitabilityStatement, setSuitabilityStatement] = useState<
    ArchiveResponseObject[] | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  async function findStatements() {
    const res = await reportsArchive(AppStore.customerId)
    if (res.response) {
      const reports = res.response.filter(
        (r) => r.reportId === suitabilityReportId
      )

      const sortedReports = sortBy(reports, 'startDate').reverse()
      if (sortedReports.length > 0) {
        setSuitabilityStatement(sortedReports)
      }
    }
    setLoading(false)
  }

  const openDocument = (statement: ArchiveResponseObject) => {
    toPublicationServiceLink(
      (statement as unknown as PublicationServiceBase).documentFileId,
      statement?.reportId ?? '',
      (statement as unknown as PublicationServiceBase).customerId
    ).then((x) => {
      openUrl(x)
    })
  }

  React.useEffect(() => {
    if (loading) {
      findStatements()
    }
  }, [loading])

  React.useEffect(() => {
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  return (
    <BoxWithPadding
      style={{
        marginHorizontal: isWeb
          ? isSmall
            ? Spacings.S8
            : Spacings.S128
          : Spacings.S8,
        marginBottom: Spacings.S32,
      }}
    >
      {loading && <LoadingIndicator />}
      {!loading && (
        <>
          {suitabilityStatement === undefined && (
            <Paragraph style={infoStyles.paragraph}>
              {t('profile.no-statements')}
            </Paragraph>
          )}
          {suitabilityStatement &&
            suitabilityStatement.map((statement, key) => (
              <FlexRow style={infoStyles.infoLinkRow} key={key}>
                <Paragraph style={infoStyles.paragraph}>
                  {formatDateFull(
                    (statement as unknown as PublicationServiceBase).endDate
                  )}{' '}
                  - {t('profile.suitability-statement')}
                </Paragraph>

                <ButtonSecondaryWithChildren
                  onPress={() => openDocument(statement)}
                >
                  <FlexRow>
                    <TextDefault
                      type="h6"
                      style={{ color: Colors.textPrimary }}
                    >
                      PDF
                    </TextDefault>
                    <IconDownload />
                  </FlexRow>
                </ButtonSecondaryWithChildren>
              </FlexRow>
            ))}
        </>
      )}
    </BoxWithPadding>
  )
}

const infoStyles = StyleSheet.create({
  infoLinkRow: {
    justifyContent: 'space-between',
    marginBottom: 5,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.chartLineGrey,
  },
  paragraph: {
    paddingTop: 8,
    marginBottom: 0,
  },
})
