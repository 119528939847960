import React, { useState } from 'react'
import { View } from 'react-native'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { AktiaLogo } from '../../ui/AktiaLogo'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IntroImage } from '../questions/IntroImage'
import { MaintenanceWithWarning } from '@taaleri/core/src/models/Maintenance'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { changeLanguage, fiKey, svKey } from '@taaleri/core/src/i18n'
import UiButton from '../../ui/UiButton'
import { useTranslation } from 'react-i18next'

export interface MaintenanceProps {
  maintenance: MaintenanceWithWarning
}

export default function MaintenanceContent(props: MaintenanceProps) {
  const { t } = useTranslation()

  const [header, setHeader] = useState<string | undefined>(
    AppStore.currentLanguage === fiKey
      ? props.maintenance.header
      : props.maintenance.headerSe
  )

  const [paragraph, setParagraph] = useState<string | undefined>(
    AppStore.currentLanguage === fiKey
      ? props.maintenance.paragraph
      : props.maintenance.paragraphSe
  )
  
  const [currentLanguage, setCurrentLanguage] = useState<string>(AppStore.currentLanguage)
  const [changingLanguage, setChangingLanguage] = useState<boolean>(false)

  React.useEffect(() => {
    if (changingLanguage) {
      if (currentLanguage === fiKey) {
        setHeader(props.maintenance.header)
        setParagraph(props.maintenance.paragraph)
      } else {
        setHeader(props.maintenance.headerSe)
        setParagraph(props.maintenance.paragraphSe)
      }
      setChangingLanguage(false)
    }
  }, [changingLanguage])

  const image = require('./maintenance.png')
  return (
    <View
      style={{
        alignItems: 'center',
        marginTop: Spacings.S40,
        padding: Spacings.S24,
      }}
    >
      <AktiaLogo />
      <View style={{ width: '100%' }}>
        <IntroImage image={image} />
      </View>
      <Title>{header}</Title>
      <Paragraph style={{ textAlign: 'center', maxWidth: 400 }}>
        {paragraph}
      </Paragraph>
      <UiButton
        title={t('languageChange')}
        onPress={() => {
          changeLanguage()
          setCurrentLanguage(currentLanguage === fiKey ? svKey : fiKey)
          setChangingLanguage(true)
        }}
        type="primaryplain"
      />
    </View>
  )
}
