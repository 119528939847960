import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import ConfirmLeaveOnboardingModal from '@taaleri/components/src/screens/onboarding/ConfirmLeaveOnboardingModal'
import { ROUTE_PROFILE } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import EsgSuitabilityWarningContent from '@taaleri/components/src/screens/questions/EsgSuitabilityWarningContent'
import { QUESTION_ESG_START } from '@taaleri/components/src/screens/questions/questions'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'

import QuestionContainer from './QuestionContainer'

const EsgSuitabilityWarning = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const { navigateApp } = useNavigation()
  return (
    <QuestionContainer>
      <ConfirmLeaveOnboardingModal
        onYes={() => {
          if (QuestionStore.questionMode === 'contract') {
            navigateApp(ROUTE_PROFILE)
          } else {
            window.location.href = 'https://aktia.fi/'
          }
        }}
        onNo={() => setShowConfirmationModal(false)}
        visible={showConfirmationModal}
      />
      <TopBottomContainer>
        <EsgSuitabilityWarningContent />
        <Buttons
          title={t('button.return-to-questions')}
          titleSecondary={`${t('button.stop')}`}
          onPress={() => {
            Analytics.event('Onboarding', 'Esg info back')
            history.push(QUESTION_ESG_START)
            QuestionStore.resetEsgQuestions()
          }}
          onPressSecondary={() => setShowConfirmationModal(true)}
        />
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(EsgSuitabilityWarning)
