import { useState, useEffect } from 'react'

import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'

import { customers } from '@taaleri/core/src/services/customers'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'

export default function useFirstProposal() {
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [investmentProposal, setInvestmentProposal] =
    useState<InvestmentProposal | undefined>()

  useEffect(() => {
    const getInvestmentProposal = async () => {
      setError(false)
      setRetry(false)
      try {
        const proposal = await customers.getFirstProposal()
        QuestionStore.riskLevel = proposal.riskLevel
        setInvestmentProposal(proposal)
      } catch (error) {
        setError(true)
      }
    }
    getInvestmentProposal()
  }, [retry])
  return { investmentProposal, error, setRetry }
}
