import {
  Portfolio,
  PortfolioResponse,
  PurchaseLotResponse,
  FilteredPositions,
  PurchaseLot,
} from '../models/Portfolio'
import {
  TimeSerieItem,
  YieldHistory,
  YieldHistoryResponse,
} from '../models/YieldHistory'
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'
import logger from '../utils/logger'
import IReportsApi from './IReportsApi'
import { sleep } from './mockHelper'
import { toPortfolio, toPortfolioList } from './normalization/toPortfolio'
import { toPurchaseLots } from './normalization/toPurchaseLots'
import { toYieldHistory } from './normalization/toYieldHistory'
import { ReportOrder } from '../models/ReportOrders'
import { ReportArchiveFolder } from '../models/ReportArchive'
import { TEST_CUSTOMER_ID } from '../models/testCustomers'
import { CombinedIndex } from '../models/CombinedIndex'
import { ReportingPortfolio } from '../models/ReportingPortfolio'
import { FeeReport } from '../models/FeeReport'
import {
  TransactionReport,
  TransactionCategory,
} from '../models/TransactionReport'
import { ProfitReport } from '../models/ProfitReport'
import { TokenResponse } from '../models/TokenResponse'
import { formatDateDash } from '../utils/format'
import { today } from '../utils/date'
import { CurstomerPurchaseLotsResponse } from '../models/CustomerPurchaseLots'
import {
  BenchMark,
  BenchmarkIndexResponse,
  PortfoliosWithBenchmark,
} from '../models/BenchMark'

const timeSerieDateFormat = 'YYYY-MM-DDT00:00:00'

function randomTimeSerieItemValue() {
  const min = Math.ceil(100.0)
  const max = Math.floor(200.0)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

function getNextTimeSerieItem(date: string): TimeSerieItem {
  return {
    date,
    value: randomTimeSerieItemValue(),
  }
}

let yieldHistoryCallCounter = 0
let yieldHistoryCustomerId: number = 0
async function fetchPortfolio(
  customerId: number,
  accessToken: string
): Promise<Portfolio> {
  logger.devInfo(`Fetch portfolio for customer ${customerId} ${accessToken}`)
  await sleep(100)
  const positions = require(`../fixtures/reports/positions/positions.json`)
  const portfolio =
    positions[customerId] || (positions[TEST_CUSTOMER_ID] as PortfolioResponse)
  return toPortfolio(portfolio)
}

async function fetchFilteredPositions(
  _: number,
  _1: string[],
  _2: string,
  _3: string,
  _4: string,
  _5: string,
  _6: string
): Promise<FilteredPositions> {
  return fetchPortfolioForReportingPortfolios(TEST_CUSTOMER_ID, [], '')
}

async function fetchFilteredCombinedIndex(
  _1: number,
  _2: string[],
  _3: string,
  _4: string,
  _5: string,
  _6: string,
  _7: string,
  _8: string,
  _9: string
): Promise<CombinedIndex> {
  return fetchCombinedIndexForReportingPortfolios([], '')
}

async function fetchPortfolioForReportingPortfolios(
  customerId: number,
  _1: string[],
  accessToken: string
): Promise<Portfolio> {
  return fetchPortfolio(customerId, accessToken)
}

async function fetchPortfolioList(
  customerId: number,
  accessToken: string
): Promise<Portfolio[]> {
  logger.devInfo(
    `Fetch portfolioList for customer ${customerId} ${accessToken}`
  )
  await sleep(100)
  const positions = require(`../fixtures/reports/positions/positionsList.json`)
  return toPortfolioList(positions)
}

function generateYieldHistory(customerId: number): YieldHistory {
  const yieldHistories = require(`../fixtures/reports/yield-history/yield-history.json`)
  const yieldHistory =
    yieldHistories[customerId] ||
    (yieldHistories[TEST_CUSTOMER_ID] as YieldHistoryResponse)

  const endYear = yieldHistory.endDate.substr(0, 4)

  yieldHistory.twrTimeSerie = (yieldHistory.twrTimeSerie || []).map(
    (t: TimeSerieItem) => {
      const currentRealYear = new Date().getFullYear()
      const currentYear = t.date.substr(0, 4)
      const year = Number(currentYear) + (currentRealYear - endYear)
      const date = t.date.substr(4)
      return {
        ...t,
        date: `${year}${date}`,
      }
    }
  )

  const resetCallCounter =
    yieldHistoryCustomerId && yieldHistoryCustomerId !== customerId

  if (resetCallCounter) {
    yieldHistoryCallCounter = 0
  }

  yieldHistoryCustomerId = customerId

  if (yieldHistoryCallCounter > 0) {
    const thisDay = format(new Date(), timeSerieDateFormat)
    const nextSerieItemDate =
      yieldHistoryCallCounter === 1
        ? thisDay
        : addDays(thisDay, yieldHistoryCallCounter - 1)
    const nextTimeSerieItem = getNextTimeSerieItem(
      format(nextSerieItemDate, timeSerieDateFormat)
    )

    yieldHistory.twrTimeSerie = yieldHistory.twrTimeSerie
      ? yieldHistory.twrTimeSerie.concat(nextTimeSerieItem)
      : []
    yieldHistory.endDate = nextTimeSerieItem.date
  }

  yieldHistoryCallCounter++
  return toYieldHistory(yieldHistory)
}

async function fetchYieldHistory(
  customerId: number,
  accessToken: string
): Promise<YieldHistory> {
  logger.devInfo(
    `Fetch yield history for customer ${customerId} ${accessToken}`
  )
  await sleep(100)
  return generateYieldHistory(customerId)
}

async function fetchBenchMarkList(_: string): Promise<BenchMark[]> {
  const response = require(`../fixtures/reports/positions/benchmarkList.json`)
  return Promise.resolve(response)
}

async function fetchBenchmarkByPortfolioId(
  _: string,
  _1: number,
  _2: string,
  _3: string,
  _4?: string,
  _5?: string
): Promise<BenchmarkIndexResponse> {
  const response = require(`../fixtures/reports/positions/benchmarkByPortfolio.json`)
  return Promise.resolve(response)
}

async function fetchBenchmarkByTicker(
  _: string,
  _1: number,
  _2: string,
  _3: string,
  _4?: string,
  _5?: string
): Promise<TimeSerieItem[]> {
  const response = require(`../fixtures/reports/positions/benchmarkByTicker.json`)
  return Promise.resolve(response)
}

async function fetchCombinedIndexForReportingPortfolios(
  _: string[],
  _1: string
): Promise<CombinedIndex> {
  const date = formatDateDash(today())
  const yieldHistory = await fetchYieldHistory(TEST_CUSTOMER_ID, '')
  const combinedHistory: CombinedIndex = {
    beginDate: yieldHistory.beginDate,
    endDate: yieldHistory.endDate,
    startDate: yieldHistory.beginDate,
    twrTimeSerie: yieldHistory.timeSerieItems,
    marketValueChange: {
      oneMonth: 0.2,
      oneYear: 0.3,
      yearToDate: 1.2,
      total: 0.8,
    },
    performance: 0.5,
    sharpe: 1,
    volatility: 2,
    volatilityChange: 3,
    volatilityEndValue: { date, value: 4 },
    volatilityStartValue: { date, value: 5 },
    volatilityTimeSeries: [
      { date, value: 4 },
      { date, value: 5 },
    ],
    marketValueTimeSeries: [{ date, value: 4 }],
    performanceYtd: 1,
    performance1M: 1,
    performance1Year: 1,
    performance3M: 1,
    performanceLastYear: 1,
    performanceSinceInception: 1,
  }
  return combinedHistory
}

async function fetchPurchaseLotsByTicker(
  _1: number,
  _2: string,
  _3: string,
  _4: string,
  _5: string[]
): Promise<PurchaseLot[]> {
  await sleep(100)
  const purchaseLotsResponse =
    require(`../fixtures/reports/purchase-lots/purchaselots.json`) as PurchaseLotResponse[]
  const mockPurchaseLots = [
    purchaseLotsResponse,
    purchaseLotsResponse,
    purchaseLotsResponse,
    purchaseLotsResponse,
  ].reduce((arr, current) => arr.concat(current), [])
  const purchaseLots = toPurchaseLots(mockPurchaseLots)
  return purchaseLots
}

async function reportOrders(_1: number, _2: string): Promise<ReportOrder[]> {
  const orders =
    require(`../fixtures/reports/reportOrders.json`) as ReportOrder[]
  return Promise.resolve(orders)
}

async function reportOrderSave(
  _: number,
  _1: string,
  _2: ReportOrder
): Promise<void> {
  return Promise.resolve()
}

async function reportOrderRemove(
  _: number,
  _1: string,
  _2: number
): Promise<void> {
  return Promise.resolve()
}

async function hasAcceptedEmailReporting(_: number) {
  return Promise.resolve(true)
}

async function acceptEmailReporting(_: number) {
  return Promise.resolve(true)
}

async function reportArchive(): Promise<ReportArchiveFolder[]> {
  const orders =
    require(`../fixtures/reportarchive/reportarchive.json`) as ReportArchiveFolder[]
  return Promise.resolve(orders)
}

async function fetchPurchaseLotsByCustomer(
  _: number,
  _1: string,
  _2: string
): Promise<CurstomerPurchaseLotsResponse> {
  const orders =
    require(`../fixtures/reports/purchase-lots/customerPurchaselots.json`) as CurstomerPurchaseLotsResponse
  return Promise.resolve(orders)
}

async function fetchReportingPortfolioList(
  _: number,
  _1: string
): Promise<ReportingPortfolio[]> {
  return []
}

async function feeReport(
  _: number,
  _1: string,
  _2: string,
  _3: string
): Promise<FeeReport> {
  await sleep(500)
  const orders = require(`../fixtures/report/feeReport.json`) as FeeReport
  return Promise.resolve(orders)
}

async function profitReport(
  _: number,
  _1: string,
  _2?: string,
  _3?: string
): Promise<ProfitReport> {
  await sleep(500)
  const res = require(`../fixtures/report/profitReport.json`) as ProfitReport
  return Promise.resolve(res)
}

async function transactionReport(
  _: number,
  _1: string,
  _2: TransactionCategory,
  _3?: string,
  _4?: string
): Promise<TransactionReport> {
  await sleep(500)
  const res =
    require(`../fixtures/report/transactionReport.json`) as TransactionReport
  return Promise.resolve(res)
}

async function transactionToken(_: number, _1: string): Promise<TokenResponse> {
  await sleep(500)
  return { value: '' }
}

async function fetchCustomerBenchMarkList(
  _: number,
  _1: string
): Promise<PortfoliosWithBenchmark> {
  await sleep(500)
  const res =
    require(`../fixtures/report/customerBenchMark.json`) as PortfoliosWithBenchmark
  return Promise.resolve(res)
}

export const reportsApiMock: IReportsApi = {
  fetchReportingPortfolioList,
  fetchPortfolio,
  fetchFilteredCombinedIndex,
  fetchFilteredPositions,
  fetchPortfolioList,
  fetchYieldHistory,
  fetchPurchaseLotsByTicker,
  fetchBenchmarkByTicker,
  fetchBenchmarkByPortfolioId,
  reportArchive,
  reportOrders,
  fetchPurchaseLotsByCustomer,
  reportOrderSave,
  reportOrderRemove,
  hasAcceptedEmailReporting,
  acceptEmailReporting,
  fetchBenchMarkList,
  feeReport,
  profitReport,
  transactionReport,
  transactionToken,
  fetchCustomerBenchMarkList,
}
