import React, { useState } from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { Formik, FormikHelpers } from 'formik'
import UiForm from '../../ui/form/UiForm'
import { View } from 'react-native'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import UiButton from '../../ui/UiButton'
import * as Yup from 'yup'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { ROUTE_PROFILE_CHANGE_USERNAME_VERIFICATION } from './ProfileRoutes'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import useLayout from '../../ui/useLayout'
import { isWeb } from '../../constants/Platforms'
import { authentication } from '@taaleri/core/src/services/authentication'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface UsernameChange {
  username: string
}

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    username: Yup.string()
      .email(`${t(ErrorMessages.incorrectEmail)}`)
      .required(`${t(ErrorMessages.missingEmail)}`),
  })

export default function ChangeUsernameForm() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const { navigate } = useNavigation()
  const { isSmall } = useLayout()
  return (
    <Formik
      validationSchema={validationSchema(t)}
      initialValues={{ username: '' }}
      onSubmit={async (
        values: UsernameChange,
        actions: FormikHelpers<UsernameChange>
      ) => {
        setLoading(true)
        QuestionStore.email = values.username

        const { success, response } = await authentication.changeUsername(
          QuestionStore.email || ''
        )
        if (success && response?.validationErrors.length === 0) {
          AppStore.smsConfirmationId = response.smsConfirmationId
          navigate(routePathApp(ROUTE_PROFILE_CHANGE_USERNAME_VERIFICATION))
        } else {
          actions.setFieldError(
            'username',
            `${t('validation.incorrect-username')}`
          )

          setLoading(false)
        }
      }}
    >
      {({ handleSubmit }) => (
        <UiForm>
          <FormNextInputAutofocus>
            <View>
              <Title>{t('profile.change-username')}</Title>
              <Paragraph>
                {t('profile.change-username-confirmation-info')}{' '}
              </Paragraph>
              <View>
                <UiTextInputFormAutoFocus
                  name="username"
                  label={`${t('email')}`}
                  type="email"
                  returnKeyType="done"
                  grayBackground={isSmall}
                />
              </View>
            </View>
            <UiButton
              containerStyle={{ marginTop: Spacings.S24 }}
              title={t('button.continue')}
              onPress={isWeb ? () => '' : handleSubmit}
              loading={loading}
            />
          </FormNextInputAutofocus>
        </UiForm>
      )}
    </Formik>
  )
}
