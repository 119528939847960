import React from 'react'
import { observer } from 'mobx-react'
import Colors from '@taaleri/core/src/constants/Colors'

import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import PositionContainer from '../report/PositionContainer'
import FilterButton from '../report/FilterButton'
import { AssetClassIconEmpty } from '../report/AssetClassIcon'
import { TextDefault } from '../../ui/text/UiText'

function PortfolioFilter() {
  const { allPortfolios, selectedPortfolios } = ProfitStore

  if (allPortfolios.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allPortfolios
        .filter((a) => !selectedPortfolios.includes(a))
        .map((portfolio) => (
          <FilterButton
            key={portfolio.value}
            onPress={() => ProfitStore.addSelectedPortfolio(portfolio)}
            selected={false}
          >
            <AssetClassIconEmpty />

            <TextDefault
              type="t2"
              style={{
                color: Colors.text,
                letterSpacing: 0.2,
              }}
            >
              {portfolio.label}
            </TextDefault>
          </FilterButton>
        ))}
    </PositionContainer>
  )
}

export default observer(PortfolioFilter)
