import { Identity } from '../models/Identity'
import INotificationsApi from './INotificationsApi'

async function setPushNotificationToken(
  _: Identity,
  _1: string
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    return resolve(true)
  })
}

export const notificationsApiMock: INotificationsApi = {
  setPushNotificationToken,
}
