import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'
import { loadBasicInfo } from '@taaleri/core/src/services/contracts'
import { customers } from '@taaleri/core/src/services/customers'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import logger from '@taaleri/core/src/utils/logger'
import { useState, useEffect } from 'react'

import { QUESTION_PORTFOLIO_TYPE } from '../questions'

export type InvestmentProposalType = 'onboarding' | 'contract' | 'impactchange'

function useInvestmentProposal(getProposal: () => Promise<InvestmentProposal>) {
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<boolean>(false)
  const [investmentProposal, setInvestmentProposal] = useState<
    InvestmentProposal | undefined
  >()

  useEffect(() => {
    let isSubscribed = true
    const findProposal = async () => {
      setError(false)
      setRetry(false)
      try {
        const proposal = await getProposal()
        QuestionStore.riskLevel = proposal.riskLevel
        const distribution = proposal.portfolioDistributions.find(
          (a) => a.riskLevel === proposal.riskLevel
        )
        const strategyIdentifier = distribution
          ? distribution.strategyIdentifier
          : '7'
        QuestionStore.strategyIdentifier = strategyIdentifier
        QuestionStore.investmentProposal = proposal
        if (isSubscribed) {
          setInvestmentProposal(proposal)
        }
      } catch (error) {
        logger.error(error, 'useInvestmentProposal')
        setError(true)
      }
    }
    findProposal()
    return () => {
      isSubscribed = false
    }
  }, [retry])

  return { investmentProposal, setRetry, error }
}

export function useInvestmentProposalOnboardingFinal() {
  return useInvestmentProposal(async () => {
    return customers.getInvestmentProposalOnboarding()
  })
}

export function useInvestmentProposalSaved(customerId: number) {
  return useInvestmentProposal(() =>
    customers.getInvestmentProposalSaved(customerId)
  )
}

export function useInvestmentProposalContractUpdate(customerId: number) {
  return useInvestmentProposal(async () => {
    // Dirty hack to prevent changing the portfolio type during basic info update
    const { isImpact } = await customers.getInvestmentProposalSaved(customerId)
    QuestionStore.setAnswer(
      QUESTION_PORTFOLIO_TYPE,
      isImpact ? 'impact' : 'etf'
    )
    const investmentProposal = await customers.getInvestmentProposal(customerId)
    QuestionStore.investmentProposal = investmentProposal
    return investmentProposal
  })
}

export function useInvestmentProposalToggleImpact(
  customerId: number,
  withImpact: boolean
) {
  return useInvestmentProposal(async () => {
    if (!QuestionStore.basicInfoLoaded) {
      await loadBasicInfo()
    }
    QuestionStore.setAnswer(
      QUESTION_PORTFOLIO_TYPE,
      withImpact ? 'impact' : 'etf'
    )
    const investmentProposal = await customers.getInvestmentProposal(customerId)
    QuestionStore.investmentProposal = investmentProposal
    return investmentProposal
  })
}
