import React from 'react'
import QuestionContainer from 'screens/questions/QuestionContainer'

import NotFoundContent from './NotFoundContent'

export default function NotFoundScreen() {
  return (
    <QuestionContainer>
      <NotFoundContent />
    </QuestionContainer>
  )
}
