import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Identity } from '@taaleri/core/src/models/Identity'
import {
  toReportArchive,
  ReportArchiveReport,
  AccountStatement,
  isReportArchiveReport,
  REPORT_ARCHIVE_TYPE,
  ReportArchiveFolder,
  PublicationService,
  BANK_STATEMENT,
  CONTENT_OF_PORTFOLIO,
  YEARLY_BANK_STATEMENT,
  PreserverAsset,
  YearStatement,
  AktiaCustomerPortfolio,
  AKTIA_PORTFOLIO,
} from '@taaleri/core/src/models/ReportArchive'
import { reportsArchive } from '@taaleri/core/src/services/reports'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { getQuarter } from 'date-fns'
import sortBy from 'lodash/sortBy'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ReportBox from './ReportBox'
import ReportRowSmall from './ReportRowSmall'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_REPORT_ARCHIVE } from '../../navigation/routes'
import useNavigation from '../../navigation/useNavigation'
import ContentLoader from '../../ui/ContentLoader'
import { IconFileLarge } from '../../ui/Icons'
import Box from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { toPublicationServiceLink } from '../reportarchive/CollapsibleReportList'
import { toReportLink } from '../reportarchive/ReportArchiveContent'

function formatDate(date: Date) {
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return month + '/' + year
}

const icon = (
  <IconFileLarge
    style={{
      position: 'absolute',
      top: Spacings.S16,
      right: -Spacings.S32,
    }}
    color={Colors.backgroundIcon}
    scale={4}
  />
)

type ReportArchiveBoxItems = (
  | ReportArchiveReport
  | (AccountStatement & { date: Date; title: string })
  | (YearStatement & { date: Date; title: string })
  | (PreserverAsset & { date: Date; title: string })
  | (AktiaCustomerPortfolio & { date: Date; title: string })
)[]

function ReportArchiveBox() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [reports, setReports] = useState<ReportArchiveBoxItems>([])

  useEffect(() => {
    async function findReportArchive() {
      setLoading(true)
      const res = await reportsArchive(AppStore.customerId)
      if (res.response) {
        const folder = res.response.find(
          (archiveItem) => archiveItem.type === REPORT_ARCHIVE_TYPE
        ) as ReportArchiveFolder
        const responseReports: ReportArchiveBoxItems = []
        if (folder) {
          const reportArchive = toReportArchive(folder, t)

          const keys = Object.keys(reportArchive)

          keys.forEach((key) => {
            const rArchive = reportArchive[Number(key)]
            if (rArchive.months) {
              Object.values(rArchive.months).forEach((values) =>
                responseReports.push(...values)
              )
            }

            if (rArchive.years) {
              Object.values(rArchive.years).forEach((yearSubArchive) => {
                Object.values(yearSubArchive).forEach((values) =>
                  responseReports.push(...values)
                )
              })
            }
          })
        }

        const publicationServiceResponse = res.response.filter(
          (archiveItem) => {
            if (archiveItem.type !== REPORT_ARCHIVE_TYPE) {
              if (
                !AppStore.isDigiCustomer ||
                archiveItem.reportId === AKTIA_PORTFOLIO
              )
                return archiveItem
            }
          }
        ) as PublicationService[]

        const items = publicationServiceResponse.map((item) => {
          if (item.reportId === BANK_STATEMENT) {
            return {
              ...item,
              date: new Date(item.endDate),
              title: `${t('reportBox.bank-statement')} - ${item.iban}`,
            }
          }
          if (item.reportId === CONTENT_OF_PORTFOLIO) {
            return {
              ...item,
              date: new Date(item.endDate),
              title: `${t('reportBox.custodian-reports')}: ${
                item.name
              } Q${getQuarter(new Date(item.endDate))}`,
            }
          }
          if (item.reportId === YEARLY_BANK_STATEMENT) {
            return {
              ...item,
              date: new Date(item.endDate),
              title: `${t('reportBox.custodian-reports')}: ${
                item.name
              } ${new Date(item.endDate).getFullYear()}`,
            }
          }
          if (item.reportId === AKTIA_PORTFOLIO) {
            return {
              ...item,
              date: new Date(item.endDate),
              title: `${t('reportArchive.financial-reports')}: ${
                item.name
              } Q${getQuarter(new Date(item.endDate))}`,
            }
          }
        })

        const responseReportsWithStatements = responseReports.concat(
          items as ReportArchiveBoxItems
        )

        // Sort reports by date
        let sortedReports = sortBy(
          responseReportsWithStatements,
          'date'
        ).reverse()

        sortedReports = sortedReports.filter((r) => r !== undefined)

        setReports(sortedReports)
      }
      const identity: Identity = await getIdentity()
      setToken(identity.accessToken)
      setLoading(false)
    }

    findReportArchive()
  }, [AppStore.customerId, AppStore.currentLanguage])

  if (!reports) {
    return null
  }

  const noReports = reports.length === 0

  /*
  Show latest monthly report and two firswt yearly report. 
  Choose later, which yearly reports are preferred
   */
  return (
    <ReportBox
      title={t('menu.report-archive')}
      subtitle={t('latests')}
      buttonTitle={`${t('reportBox.show-reports')}`}
      onButtonPress={() => navigateApp(ROUTE_REPORT_ARCHIVE)}
      backgroundColor={Colors.backgroundBlue}
      icon={icon}
    >
      <Box style={{ marginBottom: Spacings.S16 }}>
        {loading ? (
          <ContentLoader variant={isWeb ? 'flex' : 'facebook'} />
        ) : noReports ? (
          <ReportRowSmall label={`${t('reportBox.no-reports')}`} />
        ) : (
          reports.slice(0, 3).map(
            (
              report:
                | ReportArchiveReport
                | ((
                    | AccountStatement
                    | PreserverAsset
                    | YearStatement
                    | AktiaCustomerPortfolio
                  ) & {
                    date: Date
                    title: string
                  }),
              index
            ) => {
              if (report && report.title && report.date) {
                const open = async () => {
                  if (isReportArchiveReport(report)) {
                    openUrl(toReportLink(report.path, token))
                  } else {
                    openUrl(
                      await toPublicationServiceLink(
                        report.documentFileId,
                        report.reportId,
                        report.customerId
                      )
                    )
                  }
                }

                const key = isReportArchiveReport(report)
                  ? report.path + index
                  : report.documentFileId + report.iban + index

                const date = isReportArchiveReport(report)
                  ? report.date
                  : new Date(report.endDate)

                return (
                  <ReportRowSmall
                    label={`${t(report.title)}`}
                    value={formatDate(date)}
                    onPress={() => open()}
                    key={key}
                  />
                )
              }
            }
          )
        )}
      </Box>
    </ReportBox>
  )
}

export default observer(ReportArchiveBox)
