import { FundOrder } from './../models/Fund'
import IFundApi from './IFundApi'
import { getConfig } from '../config'
import { post, get } from './rest'
import { FundProposition } from '../models/FundProposition'
import { toFundProposition } from './normalization/toPortfolio'
import { Order } from '../models/Order'
import { toOrders } from '../models/OpenOrderResponse'
import AppStore from '../stores/AppStore'

async function createFundOrder(
  accessToken: string,
  customerId: number,
  fundOrder: FundOrder
) {
  const url = `${getConfig().apiUrl}/FundOrders/${customerId}/create`
  const result = await post(url, accessToken, fundOrder)

  return result
}

async function fundRecommendations(customerId: number, accessToken: string) {
  const funds = await get(
    `${getConfig().apiUrl}/Fund/recommendations/${customerId}`,
    accessToken
  )
  return funds
}

async function cancelFundOrder(
  customerId: number,
  orderId: string,
  accessToken: string
) {
  return post(
    `${
      getConfig().apiUrl
    }/FundOrders/${customerId}/recurringOrders/${orderId}/delete`,
    accessToken
  )
}

async function getOpenOrders(
  customerId: number,
  accessToken: string
): Promise<Order[]> {
  const response = await get(
    `${getConfig().apiUrl}/FundOrders/${customerId}/openOrders?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  ).then(toOrders)
  return response
}

async function fundProposition(
  customerId: number,
  value: number,
  ticker: string,
  accessToken: string,
  orderType: string,
  sellEverything: boolean
): Promise<FundProposition> {
  const response = await get(
    `${
      getConfig().apiUrl
    }/Fund/proposition/${customerId}?ticker=${ticker}&value=${value}&orderType=${orderType}&sellEverything=${sellEverything}`,
    accessToken
  ).then(toFundProposition)
  return response
}

async function fundList(customerId: number, accessToken: string) {
  const funds = await get(
    `${getConfig().apiUrl}/Fund/funds/${customerId}`,
    accessToken
  )
  return funds
}

async function fundListAll(accessToken: string) {
  const funds = await get(`${getConfig().apiUrl}/Fund/funds`, accessToken)
  return funds
}

async function findFund(ticker: string, accessToken: string) {
  const fund = await get(
    `${getConfig().apiUrl}/Fund/ticker/${ticker}?language=${
      AppStore.currentLanguage
    }`,
    accessToken
  )
  return fund
}

export const fundApi: IFundApi = {
  getOpenOrders,
  createFundOrder,
  cancelFundOrder,
  findFund,
  fundList,
  fundListAll,
  fundRecommendations,
  fundProposition,
}
