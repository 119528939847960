import styled from 'styled-components'

const CardHover = styled.div`
  @media (hover: hover) and (pointer: fine) {
    :hover {
      box-shadow: 0px 4px 11px rgba(19, 26, 45, 0.1);
    }
  }
`

export default CardHover
