import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_PROFILE } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import { useTranslation } from 'react-i18next'

interface Props {
  children: any
  nextScreen?: string
}

export default function DoneScreen({ children, nextScreen }: Props) {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <ContentContainerBox>
      <TopBottomContainer>
        {children}
        <Buttons
          title={t('button.close')}
          onPress={() => navigateApp(nextScreen ?? ROUTE_PROFILE)}
        />
      </TopBottomContainer>
    </ContentContainerBox>
  )
}
