import AsyncStorage from '@react-native-async-storage/async-storage'

export async function setSecureObject(key: string, value: any) {
  const jsonObjectString = JSON.stringify(value)
  await AsyncStorage.setItem(key, jsonObjectString)
}

export async function getSecureObject(key: string): Promise<any> {
  const jsonObjectString = await AsyncStorage.getItem(key)
  return jsonObjectString ? JSON.parse(jsonObjectString) : {}
}

export async function removeSecureObject(key: string): Promise<any> {
  await AsyncStorage.removeItem(key)
}

export async function clearSecureStore(): Promise<void> {
  await AsyncStorage.clear()
}
