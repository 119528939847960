import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { IconAddCircle } from '@taaleri/components/src/ui/Icons'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import Accordion from '@taaleri/components/src/ui/form/Accordion'
import UiTextInput from '@taaleri/components/src/ui/form/UiTextInput'
import UiModal from '@taaleri/components/src/ui/modal/UiModal'
import { TextMedium } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import AdminTargetMessage, {
  CustomerSimple,
} from '@taaleri/core/src/models/AdminTargetMessage'
import {
  getCustomers,
  getTargetMessaging,
} from '@taaleri/core/src/services/admin'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import { formatDateWithoutLeadingZeros } from '@taaleri/core/src/utils/format'
import { Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { View, StyleSheet } from 'react-native'

import Message from './MessageForm'

interface customersPerMessage {
  id: string
  customers: CustomerSimple[]
}

interface NewMessageForm {
  messageName: string
}

const TargetMessaging: React.FC = () => {
  const [messages, setMessages] = React.useState<AdminTargetMessage[]>([])
  const [messageCustomers, setMessageCustomers] = React.useState<
    customersPerMessage[]
  >([])
  const [showAddMessageModal, setShowAddMessageModal] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const [messageOpen, setMessageOpen] = React.useState<
    { messageId: string; open: boolean }[]
  >([])

  async function getMessages(token: string) {
    if (AdminStore.token || token) {
      setMessages(
        (await getTargetMessaging(AdminStore.token ?? token)).sort(
          (a, b) => a.order - b.order
        )
      )
      setLoading(false)
    }
  }

  const fetchCustomerOnOpen = async (
    customerIds: string[],
    messageId: string
  ) => {
    if (AdminStore.token) {
      const result = await getCustomers(AdminStore.token, customerIds)

      const originalMessageCustomers = messageCustomers

      if (originalMessageCustomers.some((mc) => mc.id === messageId)) {
        originalMessageCustomers.find((mc) => mc.id === messageId)!.customers =
          result
        setMessageCustomers(originalMessageCustomers)
      } else {
        setMessageCustomers([
          ...messageCustomers,
          { id: messageId, customers: result },
        ])
      }
    }
  }

  const getCustomersForMessage = (messageId: string) => {
    return messageCustomers.find((mc) => mc.id === messageId)?.customers ?? []
  }

  const getOpenStatus = (messageId: string) => {
    const messageToToggle = messageOpen.find((mo) => mo.messageId === messageId)
    if (messageToToggle) {
      return messageToToggle.open
    }

    return false
  }

  const toggleMessage = (messageId: string, value: boolean) => {
    const messageToToggle = messageOpen.find((mo) => mo.messageId === messageId)
    if (messageToToggle) {
      const oldMessages = messageOpen.filter((m) => m.messageId !== messageId)
      setMessageOpen([...oldMessages, { messageId, open: value }])
    } else {
      setMessageOpen([...messageOpen, { messageId, open: value }])
    }
  }

  const createNewMessage = (NewMessageForm: NewMessageForm) => {
    const newMessage: AdminTargetMessage = {
      name: NewMessageForm.messageName,
      order: messages.length + 1,
      contentFi: '',
      contentSv: '',
      titleFi: '',
      titleSv: '',
      validFrom: new Date(),
      validTo: new Date(),
      customers: [],
      id: '',
    }
    setMessages([...messages, newMessage])
  }

  React.useEffect(() => {
    if (AdminStore.token) {
      getMessages(AdminStore.token)
    }
  }, [])

  return (
    <>
      {!loading && (
        <View>
          <UiTouchable
            onPress={() => {
              setShowAddMessageModal(true)
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 24,
              width: 800,
              justifyContent: 'flex-end',
            }}
          >
            <TextMedium
              style={{ paddingTop: 2, marginRight: 5, color: Colors.primary }}
            >
              Lisää uusi viesti
            </TextMedium>
            <IconAddCircle />
          </UiTouchable>
          {messages.map((m) => {
            return (
              <FlexRow
                key={`${m.id}-${m.name}`}
                style={{
                  maxWidth: 800,
                }}
              >
                <Accordion
                  title={`${m.order}: ${m.name}${
                    m.validFrom && m.validTo
                      ? ` ${formatDateWithoutLeadingZeros(
                          m.validFrom
                        )} - ${formatDateWithoutLeadingZeros(m.validTo)}`
                      : ''
                  }`}
                  defaultOpen={false}
                  onOpen={() => {
                    fetchCustomerOnOpen(m.customers, m.id)
                    toggleMessage(m.id, true)
                  }}
                  onClose={async () => {}}
                  close={!getOpenStatus(m.id)}
                >
                  <>
                    <Message
                      message={m}
                      customers={getCustomersForMessage(m.id)}
                      onUpdateClose={async () => {
                        setMessageCustomers([])
                        toggleMessage(m.id, false)
                        setLoading(true)
                        await getMessages(AdminStore.token ?? '')
                      }}
                    />
                  </>
                </Accordion>
              </FlexRow>
            )
          })}
          <View style={styles.container}>
            <UiModal
              isVisible={showAddMessageModal}
              onBackdropPress={() => {
                setShowAddMessageModal(false)
              }}
              style={{ maxWidth: 800 }}
            >
              <Formik
                enableReinitialize={true}
                initialValues={{
                  messageName: '',
                }}
                onSubmit={async (
                  newMessage: NewMessageForm,
                  actions: FormikHelpers<NewMessageForm>
                ) => {
                  createNewMessage(newMessage)
                  actions.setSubmitting(false)
                  setShowAddMessageModal(false)
                }}
              >
                {({ handleSubmit }) => {
                  return (
                    <>
                      <UiTextInput
                        name="messageName"
                        label="Viestin nimi"
                        type="name"
                        grayBackground={false}
                      />
                      <UiButton
                        containerStyle={styles.modalButton}
                        title="Lisää"
                        onPress={handleSubmit}
                      />
                    </>
                  )
                }}
              </Formik>
            </UiModal>
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: { width: 800 },
  modalButton: {
    marginLeft: 0,
    marginTop: 10,
  },
})

export default TargetMessaging
