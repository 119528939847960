import Spacings from '@taaleri/core/src/constants/Spacings'
import { OptionType } from '@taaleri/core/src/models/OptionType'
import {
  MonthArchive,
  getMonthReportOrder,
} from '@taaleri/core/src/models/ReportArchive'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { BottomView, MiddleView, SectionDivider, TopView } from './BoxSections'
import ButtonRow from './ButtonRow'
import CollapsibleReportList from './CollapsibleReportList'
import { toReportLink } from './ReportArchiveContent'
import ReportListItem from './ReportListItem'
import { Divider } from '../../ui/Divider'
import SelectDropdown from '../../ui/SelectDropdown'
import { openUrl } from '../../ui/openUrl'
import { TextDefault } from '../../ui/text/UiText'

const monthNames = [
  'datetime.january',
  'datetime.february',
  'datetime.march',
  'datetime.april',
  'datetime.may',
  'datetime.june',
  'datetime.july',
  'datetime.august',
  'datetime.september',
  'datetime.october',
  'datetime.november',
  'datetime.december',
]

function MonthsBox({
  months,
  token,
  isSmall,
}: {
  months: MonthArchive
  token: string
  isSmall: boolean
}) {
  const { t } = useTranslation()
  const [monthReport, setMonthReport] = useState<string>(Object.keys(months)[0])
  const [selectedMonth, setSelectedMonth] = useState<OptionType>({
    label: '',
    value: 0,
  })
  const reports = months[monthReport]
    ? months[monthReport].sort((a, b) => a.month - b.month)
    : []
  const monthDropdownOptions = reports.map((report) => {
    return { label: t(monthNames[report.month - 1]), value: report.month }
  })
  const selectedReport = reports.find((r) => r.month === selectedMonth.value)
  useEffect(() => {
    const d = new Date()
    const currentYear = d.getFullYear()

    if (reports[0].year === currentYear) {
      setSelectedMonth(monthDropdownOptions[monthDropdownOptions.length - 1])
    } else {
      setSelectedMonth(monthDropdownOptions[0])
    }
  }, [months])

  if (months[monthReport] === undefined) {
    setMonthReport(Object.keys(months)[0])
    return <View />
  }

  const showableReportListItems = selectedReport?.accountStatements

  const collapsibleReportListItems =
    showableReportListItems &&
    showableReportListItems.length > 0 &&
    monthReport === 'portfolio.wealth-own'
      ? showableReportListItems
      : []
  return (
    <>
      <TopView>
        <TextDefault type={'h4'}>
          {t('reportArchive.monthbox-monthly-reports')}
        </TextDefault>
      </TopView>
      <SectionDivider />
      <MiddleView
        style={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        <ButtonRow
          selectedText={monthReport}
          texts={Object.keys(months).sort(
            (a, b) => getMonthReportOrder(a) - getMonthReportOrder(b)
          )}
          onClick={(r) => setMonthReport(r)}
        />
        <SelectDropdown
          title={`${t('reportArchive.monthbox-choose-month')}`}
          items={monthDropdownOptions}
          onChange={(selection: OptionType) => {
            setSelectedMonth(selection)
          }}
          selectedItem={selectedMonth}
          style={{
            width: isSmall ? '100%' : '15rem',
            marginBottom: Spacings.S16,
            marginTop: Spacings.S20,
            position: 'relative',
            zIndex: 10,
          }}
        />
      </MiddleView>
      <Divider />
      {selectedReport?.path ? (
        <MiddleView>
          <ReportListItem
            title={`${t('reportArchive.monthbox-monthly-report')}`}
            onPress={() =>
              openUrl(toReportLink(selectedReport?.path ?? '', token))
            }
          />
        </MiddleView>
      ) : null}
      <BottomView>
        {collapsibleReportListItems.length > 0 && (
          <CollapsibleReportList
            title={`${t('reportArchive.monthbox-account-statements')}`}
            items={collapsibleReportListItems}
          />
        )}
      </BottomView>
    </>
  )
}

export default MonthsBox
