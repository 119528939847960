import indexOf from 'lodash/indexOf'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { ROUTE_QUESTION_INVESTMENT_PROPOSAL } from '../questions/QuestionRoutes'

export const ROUTE_ONBOARDING_REGISTER = 'OnboardingRegister'
export const ROUTE_ONBOARDING_CUSTOMER_FORM = 'OnboardingCustomerForm'
export const ROUTE_ONBOARDING_REQULATORY_QUESTIONS =
  'OnboardingRequlatoryQuestions'
export const ROUTE_ONBOARDING_ACCOUNT_NUMBER = 'OnboardingAccountNumber'

export const ROUTE_ONBOARDING_CONTRACTS = 'OnboardingContracts'
export const ROUTE_ONBOARDING_CONTRACTS_VERIFICATION =
  'OnboardingContractsVerification'
export const ROUTE_ONBOARDING_CREATING_CUSTOMER = 'OnboardingCreatingCustomer'
export const ROUTE_ONBOARDING_PHOTO_ID = 'OnboardingIdPhoto'
export const ROUTE_ONBOARDING_IDENTITY_VALIDITY = 'OnboardingIdentityValidity'
export const ROUTE_ONBOARDING_SELECT_COUNTRY = 'OnboardingSelectCountry'
export const ROUTE_ONBOARDING_CAMERA = 'OnboardingCamera'
export const ROUTE_ONBOARDING_FURTHER_CLARIFICATION =
  'OnboardingFurtherClarification'

export const ROUTE_ONBOARDING_PAYMENT_SUM = 'OnboardingPaymentSum'
export const ROUTE_ONBOARDING_PAYMENT_BANK = 'OnboardingPaymentBank'
export const ROUTE_ONBOARDING_TRANSACTION = 'OnboardingPaymentTransaction'
const onboardingRoutes = [
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
  ROUTE_ONBOARDING_REGISTER,
  ROUTE_ONBOARDING_CUSTOMER_FORM,
  ROUTE_ONBOARDING_REQULATORY_QUESTIONS,
  ROUTE_ONBOARDING_ACCOUNT_NUMBER,
  ROUTE_ONBOARDING_CONTRACTS,
  ROUTE_ONBOARDING_CONTRACTS_VERIFICATION,
  ROUTE_ONBOARDING_CREATING_CUSTOMER,
  ROUTE_ONBOARDING_PHOTO_ID,
  ROUTE_ONBOARDING_CAMERA,
  ROUTE_ONBOARDING_IDENTITY_VALIDITY,
  ROUTE_ONBOARDING_PAYMENT_SUM,
  ROUTE_ONBOARDING_PAYMENT_BANK,
  'App',
]

export function nextOnboardingRoute(currentRoute: string) {
  if (
    currentRoute === ROUTE_QUESTION_INVESTMENT_PROPOSAL &&
    QuestionStore.email
  ) {
    return ROUTE_ONBOARDING_CUSTOMER_FORM
  }
  const index = indexOf(onboardingRoutes, currentRoute)
  return onboardingRoutes[index + 1]
}

export function onboardingPhase(currentRoute: string) {
  return indexOf(onboardingRoutes, currentRoute) + 1
}

export function onboardingPhaseCount() {
  return onboardingRoutes.length
}
