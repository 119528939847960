import { getConfig } from '@taaleri/core/src/config'

import IAdminApi from './IAdminApi'
import { get, post } from './rest'
import AdminTargetMessage from '../models/AdminTargetMessage'

async function searchCustomers(
  token: string,
  userCustomersOnly: boolean,
  includeLostCustomer: boolean,
  query?: string
) {
  let parameters = `userCustomersOnly=${userCustomersOnly}&includeLostCustomers=${includeLostCustomer}`
  if (query) {
    parameters += `&keyword=${query}`
  }
  const response = await get(
    `${getConfig().apiUrl}/Search?${parameters}`,
    token,
    {},
    60000,
    true
  )

  return response
}

async function pushToken(customerId: string, token: string) {
  const response = await get(
    `${getConfig().apiUrl}/Notifications/push/${customerId}`,
    token
  )

  return response
}

async function clearCustomerCache(token: string, customerId: string) {
  const response = await post(
    `${getConfig().apiUrl}/Customers/clearCache`,
    token,
    customerId as any
  )

  return response
}

async function getTargetMessages(token: string) {
  const response = await get(`${getConfig().apiUrl}/Messages`, token)

  return response
}

async function getCustomers(token: string, customerIds: string[]) {
  let params = ''

  for (let i = 0; i < customerIds.length; i++) {
    const id = customerIds[i]
    params += `customerIds=${id}`

    if (i !== customerIds.length - 1) {
      params += '&'
    }
  }

  const response = await get(`${getConfig().apiUrl}/customers?${params}`, token)

  return response
}

async function updateMessage(token: string, message: AdminTargetMessage) {
  const response = await post(`${getConfig().apiUrl}/update`, token, message)

  return response
}

async function addMessage(token: string, message: AdminTargetMessage) {
  const response = await post(`${getConfig().apiUrl}/add`, token, message)

  return response
}

export const adminApi: IAdminApi = {
  searchCustomers,
  pushToken,
  clearCustomerCache,
  getTargetMessages,
  getCustomers,
  updateMessage,
  addMessage,
}
