import {
  FilteredPositions,
  PurchaseLot,
  Portfolio,
} from './../models/Portfolio'
import {
  ReportOrder,
  reportSubscriptionsDefault,
  REPORT_DELIVERY_LAST_DAY_OF_MONTH,
} from './../models/ReportOrders'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import toReportOrder from './toReportOrder'
import { api } from '../api/api'
import {
  BenchMark,
  BenchmarkIndexResponse,
  PortfoliosWithBenchmark,
} from '../models/BenchMark'
import { CombinedIndex } from '../models/CombinedIndex'
import { CurstomerPurchaseLotsResponse } from '../models/CustomerPurchaseLots'
import { FeeReport } from '../models/FeeReport'
import { ProfitReport } from '../models/ProfitReport'
import { ArchiveResponseObject } from '../models/ReportArchive'
import { ReportingPortfolio } from '../models/ReportingPortfolio'
import { ServiceResponse } from '../models/ServiceResponse'
import {
  TransactionReport,
  TransactionCategory,
} from '../models/TransactionReport'
import { TimeSerieItem, YieldHistory } from '../models/YieldHistory'

export async function getCustomerPortfolio(
  customerId: number
): Promise<Portfolio> {
  const { accessToken } = await getIdentity()

  try {
    return api().reports.fetchPortfolio(customerId, accessToken)
  } catch (e) {
    handleErrors(e, 'getCustomerPortfolio')
    throw e
  }
}

export async function getCustomerPortfolioList(
  customerId: number
): Promise<Portfolio[]> {
  const { accessToken } = await getIdentity()

  try {
    return api().reports.fetchPortfolioList(customerId, accessToken)
  } catch (e) {
    handleErrors(e, 'getCustomerPortfolioList')
    throw e
  }
}

export async function getFilteredPositions(
  customerId: number,
  reportingPortfolioIds: string[],
  assetClassId: string,
  assetCategoryId: string,
  assetAdditionalInfoId: string,
  ticker: string,
  endDate: string
): Promise<FilteredPositions> {
  const { accessToken } = await getIdentity()

  try {
    return api().reports.fetchFilteredPositions(
      customerId,
      reportingPortfolioIds,
      assetClassId,
      assetCategoryId,
      assetAdditionalInfoId,
      ticker,
      endDate,
      accessToken
    )
  } catch (e) {
    handleErrors(e, 'getFilteredPositions')
    throw e
  }
}

export async function getYieldHistory(
  customerId: number
): Promise<YieldHistory> {
  const { accessToken } = await getIdentity()
  try {
    return await api().reports.fetchYieldHistory(customerId, accessToken)
  } catch (e) {
    handleErrors(e, 'getYieldHistory')
    throw e
  }
}

export async function getFeeReport(
  customerId: number,
  startDate?: string,
  endDate?: string
): Promise<ServiceResponse<FeeReport>> {
  const { accessToken } = await getIdentity()
  try {
    const response = await api().reports.feeReport(
      customerId,
      accessToken,
      startDate,
      endDate
    )
    return { response, success: true }
  } catch (error) {
    handleErrors(error, 'getFeeReport')
    return { success: false, error }
  }
}

export async function getProfitReport(
  customerId: number,
  startDate?: string,
  endDate?: string
): Promise<ServiceResponse<ProfitReport>> {
  const { accessToken } = await getIdentity()
  try {
    const response = await api().reports.profitReport(
      customerId,
      accessToken,
      startDate,
      endDate
    )
    return { response, success: true }
  } catch (error) {
    handleErrors(error, 'getProfitReport')
    return { success: false, error }
  }
}

export async function getTransactionReport(
  customerId: number,
  requestType: TransactionCategory = 'Latest',
  startDate?: string,
  endDate?: string
): Promise<ServiceResponse<TransactionReport>> {
  const { accessToken } = await getIdentity()
  try {
    const response = await api().reports.transactionReport(
      customerId,
      accessToken,
      requestType,
      startDate,
      endDate
    )
    return { response, success: true }
  } catch (e) {
    const error = handleErrors(e, 'getTransactionReport')
    return { success: false, error }
  }
}

export async function getTransactionToken(
  customerId: number
): Promise<ServiceResponse<string>> {
  const { accessToken } = await getIdentity()
  try {
    const response = await api().reports.transactionToken(
      customerId,
      accessToken
    )
    return { response: response.value, success: true }
  } catch (error) {
    handleErrors(error, 'getTransactionToken')
    return { success: false, error }
  }
}

export async function getFilteredCombinedIndex(
  customerId: number,
  reportingPortfolioIds: string[],
  assetClassId: string,
  assetCategoryId: string,
  assetAdditionalInfoId: string,
  ticker: string,
  startDate: string,
  endDate: string,
  abortControllerId?: string
): Promise<CombinedIndex> {
  const { accessToken } = await getIdentity()

  try {
    return await api().reports.fetchFilteredCombinedIndex(
      customerId,
      reportingPortfolioIds,
      assetClassId,
      assetCategoryId,
      assetAdditionalInfoId,
      ticker,
      startDate,
      endDate,
      accessToken,
      abortControllerId
    )
  } catch (e) {
    const error = handleErrors(e, 'getFilteredCombinedIndex')
    throw error
  }
}

export async function getBenchMarkByTicker(
  customerId: number,
  startDate: string,
  endDate: string,
  ticker: string
): Promise<TimeSerieItem[]> {
  const { accessToken } = await getIdentity()

  try {
    return await api().reports.fetchBenchmarkByTicker(
      accessToken,
      customerId,
      startDate,
      endDate,
      ticker
    )
  } catch (e) {
    handleErrors(e, 'getBenchmarkByTicker')
    throw e
  }
}

export async function getBenchMarkByPortfolioId(
  customerId: number,
  startDate: string,
  endDate: string,
  portfolioId?: string
): Promise<BenchmarkIndexResponse> {
  const { accessToken } = await getIdentity()

  try {
    return await api().reports.fetchBenchmarkByPortfolioId(
      accessToken,
      customerId,
      startDate,
      endDate,
      portfolioId
    )
  } catch (e) {
    handleErrors(e, 'getBenchmarkByCustomerId')
    throw e
  }
}

export async function getBenchMarkList(): Promise<BenchMark[]> {
  const { accessToken } = await getIdentity()
  try {
    return await api().reports.fetchBenchMarkList(accessToken)
  } catch (e) {
    handleErrors(e, 'getBenchMarkList')
    throw e
  }
}

export async function getPortfoliosWithBenchMark(
  customerId: number
): Promise<PortfoliosWithBenchmark> {
  const { accessToken } = await getIdentity()
  try {
    return await api().reports.fetchCustomerBenchMarkList(
      customerId,
      accessToken
    )
  } catch (e) {
    handleErrors(e, 'getCustomerBenchmark')
    throw e
  }
}

export async function getReportingPortfolioList(
  customerId: number
): Promise<ReportingPortfolio[]> {
  const { accessToken } = await getIdentity()

  try {
    return api().reports.fetchReportingPortfolioList(customerId, accessToken)
  } catch (e) {
    handleErrors(e, 'getReportingPortfolioList')
    throw e
  }
}

export async function getPurchaseLotsByTicker(
  customerId: number,
  ticker: string,
  date: string,
  reportingPortfolioIds: string[] = []
): Promise<ServiceResponse<PurchaseLot[]>> {
  const { accessToken } = await getIdentity()

  try {
    const purhaseLots = await api().reports.fetchPurchaseLotsByTicker(
      customerId,
      ticker,
      date,
      accessToken,
      reportingPortfolioIds
    )
    return {
      success: true,
      response: purhaseLots,
    }
  } catch (e) {
    const error = handleErrors(e, 'getPurchaseLots')
    return { success: false, error }
  }
}

export async function getPurchaseLotsByCustomer(
  customerId: number,
  date: string
): Promise<ServiceResponse<CurstomerPurchaseLotsResponse>> {
  const { accessToken } = await getIdentity()

  try {
    const response = await api().reports.fetchPurchaseLotsByCustomer(
      customerId,
      date,
      accessToken
    )
    return {
      success: true,
      response,
    }
  } catch (e) {
    const error = handleErrors(e, 'getPurchaseLotsByCustomer')
    return { success: false, error }
  }
}

export async function getReportOrders(
  customerId: number
): Promise<ServiceResponse<ReportOrder[]>> {
  const { accessToken } = await getIdentity()

  try {
    const response = await api().reports.reportOrders(customerId, accessToken)

    const mapped = response.map((r) => ({
      ...r,
      selectedReports: r.subscriptions.map((s) => s.reportType),
      delivery: r.delivery === 'LastDayOfMonth' ? 'Monthly' : r.delivery,
      deliveryDay:
        r.delivery === 'LastDayOfMonth'
          ? REPORT_DELIVERY_LAST_DAY_OF_MONTH
          : r.deliveryDay,
      subscriptions: reportSubscriptionsDefault().map((defaultSubscription) => {
        const currentSubscription = r.subscriptions.find(
          (s) => s.reportType === defaultSubscription.reportType
        )
        if (
          currentSubscription &&
          currentSubscription.reportType === 'Purchaselot' &&
          currentSubscription.timePeriod === 'SpecificDay'
        ) {
          currentSubscription.timePeriod = String(
            currentSubscription.timePeriodDay
          )
        }
        return currentSubscription ? currentSubscription : defaultSubscription
      }),
    }))
    return {
      success: true,
      response: mapped,
    }
  } catch (e) {
    handleErrors(e, 'getReportOrders')
    return { success: false }
  }
}

export async function saveReportOrder(
  customerId: number,
  reportOrder: ReportOrder
): Promise<ServiceResponse<void>> {
  const { accessToken } = await getIdentity()
  const mappedOrder = toReportOrder(reportOrder)
  try {
    await api().reports.reportOrderSave(customerId, accessToken, mappedOrder)
    return {
      success: true,
    }
  } catch (e) {
    handleErrors(e, 'updateReportOrder')
    return { success: false }
  }
}

export async function removeReportOrder(
  customerId: number,
  reportId: number
): Promise<ServiceResponse<void>> {
  const { accessToken } = await getIdentity()

  try {
    await api().reports.reportOrderRemove(customerId, accessToken, reportId)
    return {
      success: true,
    }
  } catch (e) {
    handleErrors(e, 'removeReportOrder')
    return { success: false }
  }
}

export async function hasAcceptedEmailReporting(
  customerId: number
): Promise<ServiceResponse<boolean>> {
  const { accessToken } = await getIdentity()

  try {
    const accepted = await api().reports.hasAcceptedEmailReporting(
      customerId,
      accessToken
    )
    return {
      success: true,
      response: accepted,
    }
  } catch (e) {
    handleErrors(e, 'hasAcceptedEmailReporting')
    return { success: false }
  }
}

export async function acceptEmailReporting(
  customerId: number
): Promise<ServiceResponse<boolean>> {
  const { accessToken } = await getIdentity()

  try {
    const accepted = await api().reports.acceptEmailReporting(
      customerId,
      accessToken
    )
    return {
      success: true,
      response: accepted,
    }
  } catch (e) {
    handleErrors(e, 'acceptEmailReporting')
    return { success: false }
  }
}

export async function reportsArchive(
  customerId: number
): Promise<ServiceResponse<ArchiveResponseObject[]>> {
  const { accessToken } = await getIdentity()
  try {
    const response = await api().reports.reportArchive(customerId, accessToken)
    return {
      success: true,
      response,
    }
  } catch (e) {
    handleErrors(e, 'reportsArchive')
    return { success: false }
  }
}
