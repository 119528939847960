import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { observable, computed, action, makeAutoObservable } from 'mobx'

import AppStore from './AppStore'
import { dateRangeValueKeys } from '../constants/DateRange'
import {
  PAST_YEAR,
  CUSTOM_DATE_RANGE,
  DATE_RANGE,
} from '../constants/YieldHistory'
import { fiKey } from '../i18n'
import {
  TransactionCategory,
  TransactionReport,
  Transaction,
} from '../models/TransactionReport'
import { getTransactionReport } from '../services/reports'
import { dateYearAgo, yesterday, dateRangeToDates } from '../utils/date'
import { formatDateDash, formatDateFull } from '../utils/format'

export interface PickerItem {
  value: string
  label: string
}

class TransactionStore {
  constructor() {
    makeAutoObservable(this)
  }

  @observable
  selectedInstruments: PickerItem[] = []

  @observable
  loadingTransactions = false

  @observable
  loadingToken = false

  @observable
  token = ''

  @observable
  skipFetching = false

  @observable
  selectedTransactionTypes: string[] = []

  @observable
  selectedReasons: string[] = []

  @observable
  selectedPortfolios: PickerItem[] = []

  @observable
  startDate: Date = dateYearAgo()

  @observable
  endDate: Date = yesterday()

  @observable
  dateRange: string = PAST_YEAR

  @observable
  transactionCategory: TransactionCategory = 'Paid'

  @action setCategory(category: string) {
    this.transactionCategory = category as TransactionCategory
  }

  @observable
  transactionReport?: TransactionReport

  @observable
  selectedTransaction?: Transaction = undefined

  @computed get filterByInstrument(): boolean {
    return this.selectedInstruments.length > 0
  }

  @computed get filterByTransactionType(): boolean {
    return this.selectedTransactionTypes.length > 0
  }

  @computed get filterByPortfolios(): boolean {
    return this.selectedPortfolios.length > 0
  }

  @computed get isCashCategory(): boolean {
    return this.transactionCategory === 'Cash'
  }

  @computed get filterByReasons(): boolean {
    return this.selectedReasons.length > 0 && this.isCashCategory
  }

  @computed get transactionsFilteredByPortfolios(): Transaction[] {
    if (!this.transactionReport) {
      return []
    }

    let transactions = this.transactionReport.transactions

    if (this.filterByPortfolios) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedPortfolios.find(
          (e) => e.value === tran.portfolio?.portfolioId
        )
      )
    }

    return transactions
  }

  @computed get transactionsFilteredByTransactionType(): Transaction[] {
    let transactions = this.transactionsFilteredByPortfolios

    if (this.filterByTransactionType) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedTransactionTypes.find(
          (e) =>
            e === tran.transactionTypeName ||
            e === tran.transactionTypeNameSwedish
        )
      )
    }

    return transactions
  }

  @computed get transactionsFilteredByInstruments(): Transaction[] {
    let transactions = this.transactionsFilteredByPortfolios
    if (this.filterByInstrument) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedInstruments.find((e) => e.value === tran.securityId)
      )
    }

    return transactions
  }

  @computed get filteredTransactions(): Transaction[] {
    let transactions = this.transactionsFilteredByPortfolios
    if (this.filterByInstrument && !this.isCashCategory) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedInstruments.find((e) => e.value === tran.securityId)
      )
    }

    if (this.filterByTransactionType && !this.isCashCategory) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedTransactionTypes.find(
          (e) =>
            e === tran.transactionTypeName ||
            e === tran.transactionTypeNameSwedish
        )
      )
    }

    if (this.filterByReasons && this.isCashCategory) {
      transactions = transactions.filter((tran: Transaction) =>
        this.selectedReasons.find(
          (e) => e === tran.reason || e === tran.reasonSwedish
        )
      )
    }

    return transactions
  }

  @computed get dateRangeTitle(): string {
    const customRange = `${formatDateFull(this.startDate)} - ${formatDateFull(
      this.endDate
    )}`
    if (this.dateRange !== CUSTOM_DATE_RANGE) {
      return (
        dateRangeValueKeys().find((d) => d.id === this.dateRange)?.value ||
        customRange
      )
    }
    return customRange
  }

  @computed get allPortfolios(): PickerItem[] {
    // Get all reporting portfolios from AppStore
    const reportingPortfolios =
      AppStore.reportingPortfolioList
        ?.filter((p) => !p.hideTransactions)
        .map((portfolio) => {
          return {
            label:
              AppStore.currentLanguage === fiKey
                ? portfolio.name
                : portfolio.nameSv,
            value: portfolio.id,
          }
        }) || []

    // Get all portfolios from profits list
    const portfoliosFromProfits = uniqBy(
      this.transactionReport?.transactions
        .filter(
          (e) =>
            e?.portfolio?.portfolioNameForCustomer && e?.portfolio?.portfolioId
        )
        .map((transaction) => {
          return {
            label:
              AppStore.currentLanguage === fiKey
                ? transaction.portfolio.portfolioNameForCustomer
                : transaction.portfolio.portfolioNameForCustomerSv,
            value: transaction.portfolio.portfolioId ?? '',
          }
        }) ?? [],
      'value'
    ).filter((t) => t.value)

    // Combine two lists into one and remove duplicate values
    const portfolios = sortBy(
      uniqBy(reportingPortfolios.concat(portfoliosFromProfits), 'value'),
      'label'
    )
    return portfolios
  }

  isInstrumentSelected(item: PickerItem): boolean {
    return Boolean(this.selectedInstruments.find((e) => e.value === item.value))
  }

  isTransactionTypeSelected(item: string): boolean {
    return this.selectedTransactionTypes.includes(item)
  }

  isReasonSelected(item: string): boolean {
    return this.selectedReasons.includes(item)
  }

  @action
  initializeDateRange(): void {
    this.setDateRange(PAST_YEAR)
  }

  @action initializeFilters(): void {
    this.selectedPortfolios = []
    this.selectedInstruments = []
    this.selectedReasons = []
    this.selectedTransactionTypes = []
  }

  @action
  initializeTransactionLists(): void {
    this.transactionReport = undefined
  }

  @action
  setCustomDateRange(startDate: Date, endDate: Date): void {
    this.startDate = startDate
    this.endDate = endDate
    this.dateRange = CUSTOM_DATE_RANGE
  }

  @action
  setDateRange(dateRange: string): void {
    const { startDate, endDate } = dateRangeToDates(dateRange as DATE_RANGE)
    if (startDate && endDate) {
      this.startDate = startDate
      this.endDate = endDate
      this.dateRange = dateRange
    }
  }

  isPortfolioSelected(item: PickerItem): boolean {
    return Boolean(this.selectedPortfolios.find((e) => e.value === item.value))
  }

  @action
  addSelectedInstrument(item: PickerItem) {
    this.selectedInstruments.push(item)
  }

  @action
  addSelectedTransactionType(item: string) {
    this.selectedTransactionTypes.push(item)
  }

  @action
  addSelectedReason(item: string) {
    this.selectedReasons.push(item)
  }

  @action
  addSelectedPortfolio(item: PickerItem) {
    this.selectedPortfolios.push(item)
  }

  @action
  removeSelectedInstrument(item: PickerItem) {
    this.selectedInstruments = this.selectedInstruments.filter(
      (i) => i.value !== item.value
    )
  }

  @action
  removeSelectedTransactionType(item: string) {
    this.selectedTransactionTypes = this.selectedTransactionTypes.filter(
      (i) => i !== item
    )
  }

  @action
  removeSelectedReason(item: string) {
    this.selectedReasons = this.selectedReasons.filter((i) => i !== item)
  }

  @action
  removeSelectedPortfolio(item: PickerItem) {
    this.selectedPortfolios = this.selectedPortfolios.filter(
      (i) => i.value !== item.value
    )
  }

  @computed get allTransactionTypes(): string[] {
    return uniq(
      this.transactionsFilteredByInstruments
        .filter((e) => e.transactionTypeName && e.transactionTypeNameSwedish)
        .map((item) =>
          AppStore.currentLanguage === fiKey
            ? item.transactionTypeName
            : item.transactionTypeNameSwedish
        )
    )
      .filter((t) => t)
      .sort()
  }

  @computed get allInstruments() {
    const getName = (transaction: Transaction): string =>
      AppStore.currentLanguage === fiKey
        ? transaction.securityNameFinnish
        : transaction.securityNameSwedish
    return sortBy(
      uniqBy(
        this.transactionsFilteredByTransactionType
          .filter((e) => {
            return (
              e.securityNameFinnish && e.securityNameSwedish && e.securityId
            )
          })
          .map((item) => {
            return {
              label: getName(item),
              value: item.securityId,
            }
          }),
        'value'
      ),
      (instrument) => instrument.label.toLowerCase()
    )
  }

  @computed get allReasons() {
    return uniq(
      this.transactionsFilteredByPortfolios.map((transaction) =>
        AppStore.currentLanguage === fiKey
          ? transaction.reason
          : transaction.reasonSwedish
      )
    )
      .filter((t) => t)
      .sort()
  }

  @action
  async fetchTransaction(customerId: number): Promise<boolean> {
    this.loadingTransactions = true
    this.initializeTransactionLists()
    const res = await getTransactionReport(
      customerId,
      this.transactionCategory,
      formatDateDash(this.startDate),
      this.transactionCategory === 'Open' ? '' : formatDateDash(this.endDate)
    )
    let result = false
    if (res.success) {
      this.transactionReport = res.response
      result = true
    } else if (res.error === 'ABORTERROR') {
      return true
    }
    this.loadingTransactions = false
    return result
  }
}

export default new TransactionStore()
