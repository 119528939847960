import Colors from '@taaleri/core/src/constants/Colors'
import CustomerService from '@taaleri/core/src/constants/CustomerService'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, View } from 'react-native'

import UiTouchable from '../../ui/UiTouchable'
import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'
import { TextMedium } from '../../ui/text/UiText'

export default function EsgSuitabilityWarningContent() {
  const { t } = useTranslation()

  function messageSupport() {
    return Linking.openURL(CustomerService.messageUrl)
  }

  return (
    <View>
      <Title>{t('questions.esg-suitability-warning-title')}</Title>
      <Paragraph>{t('questions.esg-suitability-warning-text1')}</Paragraph>
      <Paragraph style={{ marginBottom: 0 }}>
        {t('questions.daily-importance-text2')}
      </Paragraph>
      <UiTouchable onPress={messageSupport}>
        <TextMedium style={{ color: Colors.textPrimary }}>
          Verkkoviesti
        </TextMedium>
      </UiTouchable>
    </View>
  )
}
