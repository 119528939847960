import React from 'react'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleProp, ViewStyle } from 'react-native'
import {
  formatDateWithoutLeadingZeros,
  formatPercentage,
} from '@taaleri/core/src/utils/format'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { observer } from 'mobx-react'
import Colors from '@taaleri/core/src/constants/Colors'
import { getSecurityPdfUrl } from '@taaleri/components/src/screens/reporttransaction/TransactionPdfUrl'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { PdfLink } from '@taaleri/components/src/screens/portfolio/PdfLink'
import { ASSET_CATEGORY_ID_PRIVATE_EQUITY } from '@taaleri/core/src/constants/AssetClass'
import { useTranslation } from 'react-i18next'

function BondValue({
  value,
  label,
  style,
}: {
  label: string
  value: string
  style?: StyleProp<ViewStyle>
}) {
  return (
    <FlexRow style={[{ alignItems: 'center' }, style]}>
      <TextDefault type="t5">{label}</TextDefault>
      <TextDefault type="h6" style={{ marginLeft: Spacings.S8 }}>
        {value}
      </TextDefault>
    </FlexRow>
  )
}

function ReportBondRow() {
  const { t } = useTranslation()
  const position = ReportStore.selectedAssetClassItem

  if (!position) {
    return null
  }
  const {
    couponRate,
    maturityDate,
    isSecurityDocumentAvailable,
    ticker,
    assetCategoryId,
  } = position

  const pdfUrl =
    isSecurityDocumentAvailable && ReportStore.token
      ? getSecurityPdfUrl(AppStore.customerId, ticker, ReportStore.token)
      : undefined

  if (!pdfUrl && !maturityDate && !couponRate) {
    return null
  }

  const isPrivateEquity = ASSET_CATEGORY_ID_PRIVATE_EQUITY === assetCategoryId

  return (
    <FlexRow
      style={{
        paddingRight: Spacings.PADDING,
        borderBottomColor: Colors.border,
        borderBottomWidth: 1,
        paddingVertical: Spacings.S10,
        flexWrap: 'wrap',
      }}
    >
      <PdfLink {...{ pdfUrl }} style={{ marginLeft: Spacings.PADDING }} />

      {couponRate && !isPrivateEquity ? (
        <BondValue
          label={`${t('reports.coupon')}`}
          value={formatPercentage(couponRate, 3)}
          style={{ marginLeft: Spacings.PADDING }}
        />
      ) : null}
      {maturityDate && !isPrivateEquity ? (
        <BondValue
          label={`${t('steps.due-date')}`}
          value={formatDateWithoutLeadingZeros(maturityDate)}
          style={{ marginLeft: Spacings.PADDING }}
        />
      ) : null}
    </FlexRow>
  )
}

export default observer(ReportBondRow)
