import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import AppStore from '@taaleri/core/src/stores/AppStore'
import UiButton from '../../ui/UiButton'
import BankList from './BankList'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import BankSelectionFund from './BankSelectionFund'
import Box, { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import ValueLabel from '../impact/ValueLabel'
import { Divider } from '../../ui/Divider'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_ONBOARDING_TRANSACTION } from '../onboarding/OnboardingRoutes'
import { routePathQuestions, routePathApp } from '../../navigation/routeHelper'
import { ROUTE_PORTFOLIO_BANK_TRANSFER } from './PortfolioRoutes'
import { useTranslation } from 'react-i18next'

export interface BankSelectionProps {
  onSuccess: () => void
  hideSteps?: boolean
  error?: boolean
  isOnboarding?: boolean
}

export function OtherPaymentMethods({ nextRoute }: { nextRoute: string }) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <BoxWithPadding title={`${t('invest.other-payment-types')}`}>
      <Divider />
      <UiButton
        type="secondary"
        title={t('invest.bank-transaction')}
        onPress={() => navigate(nextRoute)}
      />
    </BoxWithPadding>
  )
}

export function PaymentSumInfo({
  isOnboarding,
  title,
}: {
  isOnboarding?: boolean
  title: string
}) {
  const { t } = useTranslation()
  const riskLevel = AppStore.riskLevel
  return (
    <View>
      <Title>{t(title)}</Title>
      <Paragraph>{t('invest.making-invest')}</Paragraph>
      <Box style={{ marginBottom: Spacings.S24 }}>
        <ValueLabel
          label={t('steps.sum')}
          value={formatCurrency(AppStore.newInvestment)}
        />
        <ValueLabel
          label={t('portfolio.text')}
          value={
            AppStore.hasImpactStrategy
              ? t('menu.impact')
              : t('profile.contractSection.etf')
          }
          hidden={!isOnboarding}
        />

        <ValueLabel
          label={t('app.calculator.risklevel')}
          value={riskLevel.toFixed(0)}
          hidden={riskLevel === 0 || !isOnboarding}
        />
      </Box>
    </View>
  )
}

function BankSelectionDigi(props: BankSelectionProps) {
  return (
    <View>
      <PaymentSumInfo isOnboarding={false} title="steps.payment" />
      <BankList {...props} />
      <OtherPaymentMethods
        nextRoute={routePathApp(ROUTE_PORTFOLIO_BANK_TRANSFER)}
      />
    </View>
  )
}

function BankSelectionOnboarding(props: BankSelectionProps) {
  return (
    <View>
      <PaymentSumInfo isOnboarding={true} title="steps.payment" />
      <BankList {...props} />
      <OtherPaymentMethods
        nextRoute={routePathQuestions(ROUTE_ONBOARDING_TRANSACTION)}
      />
    </View>
  )
}

function BankSelection(props: BankSelectionProps) {
  if (props.isOnboarding) {
    return <BankSelectionOnboarding {...props} />
  } else if (AppStore.investmentMode === 'fund') {
    return <BankSelectionFund {...props} />
  }
  return <BankSelectionDigi {...props} />
}

export default observer(BankSelection)
