import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  PAST_MONTH,
  PAST_YEAR,
  SIX_MONTHS,
  YEAR_TO_DATE,
} from '@taaleri/core/src/constants/YieldHistory'
import { Portfolio } from '@taaleri/core/src/models/Portfolio'
import { YieldHistory } from '@taaleri/core/src/models/YieldHistory'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { filterYieldHistoryByScale } from '@taaleri/core/src/utils/yieldHistory'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, View } from 'react-native'

import PortfolioChart, {
  PortfolioChartProps,
  toPortfolioChartProps,
} from './PortfolioChart'
import PortfolioProfitContent from './PortfolioProfitContent'
import { Divider } from '../../ui/Divider'
import LoadingIndicator from '../../ui/LoadingIndicator'
import Box, { boxPadding } from '../../ui/box/Box'
import Subtitle from '../../ui/text/Subtitle'
import useLayout from '../../ui/useLayout'

const opacity = new Animated.Value(1)

interface PortfolioChartContainerProps {
  yieldHistory: YieldHistory
  portfolio: Portfolio
  children?: any
}

export default function PortfolioChartContainer({
  yieldHistory,
  portfolio,
  children,
}: PortfolioChartContainerProps) {
  const { t } = useTranslation()
  const [scale, setScale] = useState<string>(PAST_YEAR)
  const { isNonSmall } = useLayout()
  const [loading, setLoading] = useState<boolean>(false)
  const [portfolioChartProps, setPortfolioChartProps] =
    useState<PortfolioChartProps>()

  useEffect(() => {
    setPortfolioChartProps(portfolioChartProps)
  }, [yieldHistory])

  const hideChart = yieldHistory.timeSerieItems.length === 0
  if (hideChart) {
    return null
  }

  function setMarketValueChange(scaleNew: string) {
    switch (scaleNew) {
      case PAST_YEAR:
        return portfolio.marketValueChange.oneYear
      case PAST_MONTH:
        return portfolio.marketValueChange.oneMonth
      case YEAR_TO_DATE:
        return portfolio.marketValueChange.yearToDate
      case SIX_MONTHS:
        return portfolio.marketValueChange.sixMonths
      default:
        return portfolio.marketValueChange.total
    }
  }

  async function setHistoryScale(scaleNew: string) {
    opacity.setValue(0)
    setScale(scaleNew)
    setLoading(true)
    setTimeout(() => {
      setPortfolioChartProps(
        toPortfolioChartProps(
          yieldHistory
            ? AppStore.portfoliosContainsRestricted
              ? yieldHistory.timeSerieItems.filter(
                  (ts) => new Date(ts.date) > new Date(2021, 11, 31)
                )
              : yieldHistory.timeSerieItems
            : [],
          scaleNew
        )
      )
      setLoading(false)
      Animated.timing(opacity, {
        toValue: 1,
        duration: 1000,
      } as Animated.TimingAnimationConfig).start()
    })
  }

  const marketValueChange = setMarketValueChange(scale)
  const history = yieldHistory ? yieldHistory.timeSerieItems : []
  const portfolioData =
    portfolioChartProps || toPortfolioChartProps(history, scale)
  const marketValueChangePercent = portfolioData.marketValueChangePercent
  const showPastMonthFilter =
    filterYieldHistoryByScale(PAST_MONTH, history).length > 1
  const showYearToDateFilter =
    filterYieldHistoryByScale(YEAR_TO_DATE, history).length > 1
  const showSixMonthsFilter =
    filterYieldHistoryByScale(SIX_MONTHS, history).length > 1

  return (
    <Box style={{ paddingBottom: Spacings.S16 }}>
      {isNonSmall && (
        <Subtitle
          style={{
            paddingHorizontal: boxPadding,
            paddingVertical: Spacings.S16,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
            marginBottom: 0,
          }}
        >
          {t('portfolio.profit')}
        </Subtitle>
      )}
      <View>
        <PortfolioProfitContent
          showYearToDateFilter={showYearToDateFilter}
          showPastMonthFilter={showPastMonthFilter}
          setHistoryScale={setHistoryScale}
          showSixMonthsFilter={showSixMonthsFilter}
          marketValueChange={marketValueChange}
          marketValueChangePercent={marketValueChangePercent}
        />
        <View style={{ zIndex: -1, flex: 1 }}>
          {loading && (
            <LoadingIndicator
              style={{
                paddingTop: Spacings.S16,
                position: 'absolute',
                top: 0,
                height: '100%',
                width: '100%',
                backgroundColor: Colors.white,
                zIndex: 1000,
              }}
            />
          )}
          <Animated.View
            style={{
              opacity,
              flex: 1,
            }}
          >
            <Divider />
            <PortfolioChart {...portfolioData} />
          </Animated.View>
        </View>
        {children}
      </View>
    </Box>
  )
}
