import { ApiError, TimeoutError, UnauthorizedError } from '../errors'
import logger from '../utils/logger'
import UserStore from '../stores/UserStore'
import { authentication } from '../services/authentication'
import { ServiceResponse } from '../models/ServiceResponse'

function fetchWithTimeout(url: string, options: any, timeout: number = 60000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new TimeoutError(url)), timeout)
    ),
  ])
}

function fetchWithRetry(
  url: string,
  options: any,
  n: number = 1,
  timeout: number = 60000
): Promise<unknown> {
  return fetchWithTimeout(url, options, timeout).catch((error) => {
    if (n === 1) {
      throw error
    } else {
      return fetchWithRetry(url, options, n - 1, timeout)
    }
  })
}

const handleUnauthorized = (res: any) => {
  logger.devInfo('Unauthorized', String(JSON.stringify(res)).substr(0, 500))
  UserStore.loggedIn = false
  throw new UnauthorizedError(res.statusText || '', res.status)
}

const handleResponse = (res: any) => {
  if (res.ok) {
    return res
      .text()
      .then((text: string) => (text.length ? JSON.parse(text) : {}))
      .then((json: any) => json)
  } else if (res.status === 401) {
    handleUnauthorized(res)
  } else {
    logger.devInfo(
      'res.ok === false ',
      String(JSON.stringify(res)).substr(0, 500)
    )
    throw new ApiError(res.statusText || '', res.status)
  }
}

const handleResponseText = (res: any) => {
  logger.devInfo('Response', JSON.stringify(res))
  if (res.ok) {
    return res
  } else {
    throw new ApiError(res.statusText || '', res.status)
  }
}

const wrapPossibleError = (url: string) => (e: any) => {
  if (
    e instanceof ApiError ||
    e instanceof TimeoutError ||
    e instanceof UnauthorizedError ||
    e.name === 'AbortError'
  ) {
    throw e
  } else {
    throw new Error(`${e.message} ${url}`)
  }
}

/*
VR: 25.1.2021
This could be used instead regular get method for every get request, 
but I'd rather not risk it.
*/
export const getWithRetry = (
  url: string,
  accessToken: string,
  options?: any,
  timeout?: number,
  retries: number = 5
): Promise<any> => {
  return authentication
    .refreshAccessToken()
    .then((response: ServiceResponse<any>) => {
      if (response.success) {
        accessToken = response.response
      }
    })
    .then(() => {
      return fetchWithRetry(
        url,
        {
          ...options,
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        },
        retries,
        timeout
      )
    })
    .then(handleResponse)
    .catch(wrapPossibleError(url))
}

export const get = (
  url: string,
  accessToken: string,
  options?: any,
  timeout?: number,
  skipRefresh?: boolean
): Promise<any> => {
  // logger.devInfo(`GET to url ${url}`)
  return authentication
    .refreshAccessToken(skipRefresh)
    .then((response: ServiceResponse<any>) => {
      if (response.success) {
        accessToken = response.response
      }
    })
    .then(() => {
      return fetchWithTimeout(
        url,
        {
          ...options,
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
          }),
        },
        timeout
      )
        .then(handleResponse)
        .catch(wrapPossibleError(url))
    })
}

export const unauthenticatedGet = (
  url: string,
  timeout?: number
): Promise<any> => {
  return fetchWithTimeout(
    url,
    {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    },
    timeout
  )
    .then(handleResponse)
    .catch(wrapPossibleError(url))
}

export const unauthenticatedPost = (
  url: string,
  body?: object,
  timeout?: number
): Promise<any> => {
  return fetchWithTimeout(
    url,
    {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: body ? JSON.stringify(body) : JSON.stringify({}),
    },
    timeout
  )
    .then(handleResponse)
    .catch(wrapPossibleError(url))
}

export const unauthenticatedPostTextResponse = (
  url: string,
  body?: object
): Promise<any> => {
  return fetchWithTimeout(url, {
    headers: new Headers({}),
    method: 'POST',
    body: body ? JSON.stringify(body) : JSON.stringify({}),
  })
    .then(handleResponseText)
    .catch(wrapPossibleError(url))
}

const apiCall = (
  url: string,
  accessToken: string,
  method: string,
  body?: object,
  skipRefresh?: boolean
): Promise<any> => {
  logger.devInfo(`${method} to url ${url} with body:`, JSON.stringify(body))
  return authentication
    .refreshAccessToken(skipRefresh)
    .then((response: ServiceResponse<any>) => {
      if (response.success) {
        accessToken = response.response
      }
    })
    .then(() => {
      return fetchWithTimeout(url, {
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }),
        method,
        body: body ? JSON.stringify(body) : JSON.stringify({}),
      })
        .then(handleResponse)
        .catch(wrapPossibleError(url))
    })
}

export const post = (
  url: string,
  accessToken: string,
  body?: object,
  skipRefresh?: boolean
) => apiCall(url, accessToken, 'POST', body, skipRefresh)

export const put = (url: string, accessToken: string, body?: object) =>
  apiCall(url, accessToken, 'PUT', body)

export const deleteApi = (url: string, accessToken: string, body?: object) =>
  apiCall(url, accessToken, 'DELETE', body)
