import React, { useState, useEffect } from 'react'

export default function useModal() {
  const [isVisible, setIsVisible] = useState(false)

  function handleEvent(event: any) {
    if (event.keyCode === 27) {
      setIsVisible(false)
    }
  }

  const toggle = (isModalVisible: boolean) => {
    setIsVisible(isModalVisible)
  }

  useEffect(() => {
    window.document.addEventListener('keydown', handleEvent)
    return () => {
      window.document.removeEventListener('keydown', handleEvent)
    }
  }, [])
  return { isVisible, toggle }
}
