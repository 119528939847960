import React, { useState, useEffect } from 'react'
import { View } from 'react-native'

import { AdminLayout } from './AdminLayout'
import { useParams } from 'react-router-dom'
import Title from '@taaleri/components/src/ui/text/Title'
import UiButton from '@taaleri/components/src/ui/UiButton'
import useQueryParams from 'navigation/useQueryParams'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { unauthenticatedPost } from '@taaleri/core/src/api/rest'
import { ROUTE_CONTRACT_START } from '@taaleri/components/src/screens/contract/ContractRoutes'
import { TextBold, TextError } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { ROUTE_PORTFOLIO } from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import PushNotificationForm from './PushNotificationForm'
import { api } from '@taaleri/core/src/api/api'
import AppStore from '@taaleri/core/src/stores/AppStore'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { ROUTE_IDENTITY_CARD_PHOTO_ID } from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'

type PushType = 'contract' | 'identity' | 'firstInvestment'

interface MessageType {
  key: PushType
  title: string
  action: () => void
}

interface PushMessage {
  key: PushType
  title: string
  body: string
  route: string
}

function AdminCustomerScreen() {
  const [loading, setLoading] = useState<string>()
  const [noToken, setNoToken] = useState<boolean>(false)
  const [token, setToken] = useState<string>()
  const [sent, setSent] = useState<string>()
  const { customerId } = useParams<{ customerId: string }>()
  const { name } = useQueryParams()
  const { adminToken } = AppStore

  useEffect(() => {
    async function findToken() {
      const result = await api().admin.pushToken(customerId, adminToken || '')
      if (result.value) {
        setToken(result.value)
      } else {
        setNoToken(true)
      }
    }
    findToken()
  }, [adminToken, customerId])

  if (!token && !noToken) {
    return <LoadingIndicator />
  }

  async function sendPush({ key, title, body, route }: PushMessage) {
    setLoading(key)
    const url = 'https://expo.io/--/api/v2/push/send'
    const message = [
      {
        to: token,
        title,
        body,
        data: { route, key },
      },
    ]
    await unauthenticatedPost(url, message)
    setLoading(undefined)
    setSent(key)
  }

  async function sendContractNotification() {
    await sendPush({
      key: 'contract',
      title: 'Perustiedot päivitettävä',
      body: 'Lain mukaan perustiedot on päivitettävä kerran vuodessa',
      route: ROUTE_CONTRACT_START,
    })
  }

  async function sendIdentityNotification() {
    await sendPush({
      key: 'identity',
      title: 'Henkilötodistus päivitettävä',
      body: 'Henkilötodistuksesi voimassaolo vanhenee',
      route: ROUTE_IDENTITY_CARD_PHOTO_ID,
    })
  }

  async function sendFirstInvestment() {
    await sendPush({
      key: 'firstInvestment',
      title: 'Tee ensimmäinen sijoitus',
      body: 'Aloita säästäminen',
      route: ROUTE_PORTFOLIO,
    })
  }

  const messages: MessageType[] = [
    {
      key: 'contract',
      title: 'Lähetä muistutus sopimuksen päivityksestä',
      action: sendContractNotification,
    },
    {
      key: 'identity',
      title: 'Lähetä muistutus hetun päivityksestä',
      action: sendIdentityNotification,
    },
    {
      key: 'firstInvestment',
      title: 'Lähetä tee ensimmäinen sijoitus',
      action: sendFirstInvestment,
    },
  ]

  return (
    <AdminLayout>
      <Title>
        {name} {customerId}
      </Title>
      <View style={{ marginBottom: Spacings.S24 }} />
      {noToken && <TextError>Push ei käytössä</TextError>}
      {!noToken && (
        <>
          <PushNotificationForm token={token as string} />
          {messages.map((message) => {
            return (
              <FlexRow style={{ alignItems: 'center' }} key={message.key}>
                <UiButton
                  type="secondary"
                  title={message.title}
                  size="small"
                  containerStyle={{
                    marginBottom: Spacings.S24,
                    marginRight: Spacings.S24,
                  }}
                  onPress={message.action}
                  loading={loading === message.key}
                />
                {message.key === sent && (
                  <TextBold
                    style={{
                      marginBottom: Spacings.S24,
                      color: Colors.primary,
                    }}
                  >
                    Viesti lähetetty
                  </TextBold>
                )}
              </FlexRow>
            )
          })}
        </>
      )}
    </AdminLayout>
  )
}

export default AdminCustomerScreen
