import {
  PAST_MONTH,
  PAST_YEAR,
  YEAR_TO_DATE,
  FROM_BEGINNING,
  PAST_THREE_YEARS,
  PAST_FIVE_YEARS,
  LAST_YEAR,
  SIX_MONTHS,
} from '../constants/YieldHistory'
import { TimeSerieItem } from '../models/YieldHistory'
import startOfYear from 'date-fns/start_of_year'
import isSameDay from 'date-fns/is_same_day'
import endOfYear from 'date-fns/end_of_year'
import isAfter from 'date-fns/is_after'
import isBefore from 'date-fns/is_before'
import subDays from 'date-fns/sub_days'
import subYears from 'date-fns/sub_years'
import subMonths from 'date-fns/sub_months'

export function calculateFrequency<T>(amount: number, history: T[]): number {
  return Math.round(history.length / amount)
}

export function takeEveryNth<T>(items: T[], n: number): T[] {
  const result: T[] = []

  for (let i = 0; i < items.length - 1; i = i + n) {
    result.push(items[i])
  }

  // Add last element to the array
  result.push(items[items.length - 1])

  return result
}

/**
 * Filters history items by given scale
 * @param nowInSeconds when defined, filtering uses it instead of Date.now()
 */
export const filterYieldHistoryByScale = (
  scale: string,
  history: TimeSerieItem[],
  nowInSeconds?: number
): TimeSerieItem[] => {
  const now = nowInSeconds || Date.now()

  // Use day before yesterday for filtering twr as value is end of date
  const yesterday = subDays(now, 1)
  const takeAfter = (fromDate: Date, items: TimeSerieItem[]) =>
    items.filter(
      (it) =>
        isAfter(new Date(it.date), fromDate) ||
        isSameDay(new Date(it.date), fromDate)
    )

  const takeBetween = (
    fromDate: Date,
    toDate: Date,
    items: TimeSerieItem[]
  ) => {
    return items.filter(
      (it) =>
        (isAfter(new Date(it.date), fromDate) ||
          isSameDay(new Date(it.date), fromDate)) &&
        (isBefore(new Date(it.date), toDate) ||
          isSameDay(new Date(it.date), toDate))
    )
  }
  switch (scale) {
    case PAST_YEAR:
      const yearAgo = subYears(yesterday, 1)
      return takeAfter(yearAgo, history)
    case PAST_THREE_YEARS:
      const threeYearsAgo = subYears(yesterday, 3)
      const threeYearHistory = takeAfter(threeYearsAgo, history)
      return threeYearHistory.length > 365
        ? takeEveryNth(threeYearHistory, 7)
        : threeYearHistory
    case PAST_FIVE_YEARS:
      const fiveYearsAgo = subYears(yesterday, 5)
      const fiveYearHistory = takeAfter(fiveYearsAgo, history)
      return fiveYearHistory.length > 365
        ? takeEveryNth(fiveYearHistory, 7)
        : fiveYearHistory
    case PAST_MONTH:
      const monthAgo = subMonths(yesterday, 1)
      return takeAfter(monthAgo, history)
    case SIX_MONTHS:
      const sixMonthsAgo = subMonths(yesterday, 6)
      return takeAfter(sixMonthsAgo, history)
    case YEAR_TO_DATE:
      const startOfTheYear = startOfYear(now)
      return takeAfter(startOfTheYear, history)
    case LAST_YEAR:
      const startOfLastYear = startOfYear(subYears(now, 1))
      const endOflastYear = endOfYear(startOfLastYear)
      return takeBetween(startOfLastYear, endOflastYear, history)
    case FROM_BEGINNING:
      return history.length > 365 ? takeEveryNth(history, 7) : history
    default:
      return history
  }
}
