import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import SmsVerification from '../contract/SmsVerification'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { observer } from 'mobx-react'
import { isWeb } from '../../constants/Platforms'
import useNavigation from '../../navigation/useNavigation'
import {
  ROUTE_RECURRING_ORDER_CANCEL_DONE,
  ROUTE_RECURRING_ORDERS_LIST,
} from './RecurringOrderRoutes'
import { useTranslation } from 'react-i18next'

function RecurringOrderCancelVerification() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const onSuccess = async () => {
    Analytics.event('Profile', 'Cancel recurring order')
    await AppStore.fetchOrders()
    navigateApp(ROUTE_RECURRING_ORDER_CANCEL_DONE)
  }

  const title = isWeb ? `${t('questions.cancel-month-saving')}` : ''
  const phone = AppStore.customer.phone
  const description = `${t('questions.verify-monthly-saving-cancel', {
    phone: phone,
  })}`

  return (
    <SmsVerification
      t={t}
      title={title}
      description={description}
      onSuccess={() => onSuccess()}
      phone={phone}
      afterConflictNavigateTo={ROUTE_RECURRING_ORDERS_LIST}
      onPrepareVerification={async () => {
        return AppStore.smsConfirmationId ? AppStore.smsConfirmationId : null
      }}
      navigate={navigateApp}
    />
  )
}

export default observer(RecurringOrderCancelVerification)
