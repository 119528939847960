import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import { NewsFeedSvUrl } from '@taaleri/core/src/constants/ExternalUrls'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import AppStore from '@taaleri/core/src/stores/AppStore'
import capitalize from 'lodash/capitalize'
import marked from 'marked'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageBackground, useWindowDimensions, View } from 'react-native'

import {
  BoxFeed,
  DateFeed,
  FeedProps,
  MainContentFeed,
  RowFeed,
  TitleFeed,
  WarningRowProps,
} from './FeedComponents'
import { ROUTE_FEED_DETAIL } from './FeedRoutes'
import { NotificationTitle } from './NotificationTitle'
import { shortenedContent } from './messageFormat'
import { IconLamp } from '../../components/impact/IconSocial'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_APP } from '../../navigation/routes'
import useNavigation from '../../navigation/useNavigation'
import { NewsIcon } from '../../ui/Icons'
import OpenLink from '../../ui/OpenLink'
import TouchableShrink from '../../ui/TouchableShrink'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

let lastUsedImage = 0

const shortenedLength = 100

const FEED_DETAIL = '/' + ROUTE_APP + '/' + ROUTE_FEED_DETAIL + '/'

export function FeedNewsRow(props: FeedProps) {
  const { feed } = props
  const newsItem = feed.newsItems[0]
  const { type, security } = newsItem
  if (security?.isin === 'DIGIDISCRETIONARY') {
    return <DigiNewsRow {...props} />
  } else if (type === 'product_article') {
    return <ProductNewsRow {...props} />
  } else {
    return <NotificationRow {...props} />
  }
}

function NotificationRow({ feed }: FeedProps) {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const newsItem = feed.newsItems[0]
  const { title, contentItems, type } = newsItem
  const { navigate } = useNavigation()
  const firstItem = contentItems[0].text
    ? contentItems[0].text
    : contentItems[0].subHeading
  const content = firstItem
    ? shortenedContent(firstItem, shortenedLength)
    : undefined

  const htmlSource = (content: string) => {
    return {
      html: marked(
        content.replaceAll(
          '<img src="',
          '<img width="580" src="https://www.aktia.fi'
        )
      ),
    }
  }

  return (
    <TouchableShrink
      onPress={() =>
        navigate(isWeb ? FEED_DETAIL + newsItem.id : ROUTE_FEED_DETAIL, {
          message: newsItem,
        })
      }
    >
      <NotificationTitle type={type} />
      <BoxFeed style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <RowFeed>
          <MainContentFeed>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                margin: 0,
                gap: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginLeft: isWeb ? 0 : 15,
                  marginTop: 5,
                  marginRight: isWeb ? 20 : 5,
                }}
              >
                {type !== 'other' &&
                type !== 'service_article' &&
                type !== 'target_message' ? (
                  <NewsIcon color={'#5B618F'} width={40} height={40} />
                ) : type === 'other' ? (
                  <></>
                ) : (
                  <IconLamp size={40} />
                )}
              </View>
              <View style={{ flex: 11 }}>
                <DateFeed>{feed.date}</DateFeed>

                <TextDefault
                  type="t1"
                  style={{
                    marginBottom: Spacings.S4,
                  }}
                >
                  {title}
                </TextDefault>
                {content && isWeb && (
                  <Paragraph
                    type="t2"
                    color={Colors.textSecondary}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlSource(content).html,
                      }}
                      style={{ marginBottom: 0, marginTop: 0 }}
                    />
                  </Paragraph>
                )}
                <TextDefault type="t2" style={{ color: Colors.textPrimary }}>
                  {t('more')} ›
                </TextDefault>
              </View>
            </View>
          </MainContentFeed>
        </RowFeed>
      </BoxFeed>
    </TouchableShrink>
  )
}

export function SvRow() {
  return (
    <TouchableShrink onPress={() => OpenLink(NewsFeedSvUrl, 'sv-news')}>
      <NotificationTitle sv={true} type="product_article" />
      <BoxFeed style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <TextDefault type="t2" style={{ color: Colors.textPrimary }}>
          Gå till nyhetsarkivet
        </TextDefault>
      </BoxFeed>
    </TouchableShrink>
  )
}

export function WarningRow({ warning }: WarningRowProps) {
  const { header, paragraph, headerSe, paragraphSe } = warning
  return (
    <>
      <NotificationTitle type="other" />
      <BoxFeed style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <RowFeed>
          <MainContentFeed>
            <TextDefault
              type="t1"
              style={{
                marginBottom: Spacings.S4,
              }}
            >
              {AppStore.currentLanguage === fiKey ? header : headerSe}
            </TextDefault>
            <Paragraph
              type="t2"
              color={Colors.textSecondary}
              style={{
                marginBottom: Spacings.S16,
              }}
            >
              {AppStore.currentLanguage === fiKey ? paragraph : paragraphSe}
            </Paragraph>
          </MainContentFeed>
        </RowFeed>
      </BoxFeed>
    </>
  )
}

function ProductNewsRow({ feed }: FeedProps) {
  const { t } = useTranslation()
  const [imageNumber, setImageNumber] = useState<number>()
  const { isWide } = useLayout()
  const newsItem = feed.newsItems[0]
  const { title } = newsItem
  const { navigate } = useNavigation()
  useEffect(() => {
    const nextNumber = (lastUsedImage + 1) % 4
    setImageNumber(nextNumber)
    lastUsedImage = lastUsedImage + 1
  }, [])

  const productImage1 = require('./product1.jpg')
  const productImage2 = require('./product2.jpg')
  const productImage3 = require('./product3.jpg')
  const productImage4 = require('./product4.jpg')
  const productImages = [
    productImage1,
    productImage2,
    productImage3,
    productImage4,
  ]
  if (!imageNumber) {
    return null
  }
  const image = productImages[imageNumber]
  return (
    <TouchableShrink
      onPress={() =>
        navigate(isWeb ? FEED_DETAIL + newsItem.id : ROUTE_FEED_DETAIL, {
          message: newsItem,
        })
      }
    >
      <ImageBackground
        source={image}
        resizeMode="cover"
        style={{
          height: isWide ? 209 : 135,
          paddingVertical: 24,
          paddingHorizontal: Spacings.PADDING,
          borderTopLeftRadius: BorderRadius.default,
          borderTopRightRadius: BorderRadius.default,
          overflow: 'hidden',
        }}
      >
        {newsItem.productName && (
          <TextDefault type="h3" color={Colors.white}>
            {capitalize(newsItem.productName.toLowerCase())}
          </TextDefault>
        )}
      </ImageBackground>
      <BoxFeed style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <RowFeed>
          <MainContentFeed>
            <DateFeed>{feed.date}</DateFeed>
            <TitleFeed>{title}</TitleFeed>
          </MainContentFeed>
        </RowFeed>

        <TextDefault
          type="t2"
          style={{ color: Colors.textPrimary, marginTop: Spacings.S16 }}
        >
          {t('more')} ›
        </TextDefault>
      </BoxFeed>
    </TouchableShrink>
  )
}

function DigiNewsRow({ feed }: FeedProps) {
  const { t } = useTranslation()
  const [imageNumber, setImageNumber] = useState<number>()
  const { isWide } = useLayout()
  const newsItem = feed.newsItems[0]
  const { contentItems, title } = newsItem
  const firstItem = contentItems[0].text
    ? contentItems[0].text
    : contentItems[0].subHeading
  const content = firstItem
    ? shortenedContent(firstItem, shortenedLength)
    : undefined

  const { navigate } = useNavigation()
  useEffect(() => {
    const nextNumber = (lastUsedImage + 1) % 4
    setImageNumber(nextNumber)
    lastUsedImage = lastUsedImage + 1
  }, [])

  const productImage1 = require('./product1.jpg')
  const productImage2 = require('./product2.jpg')
  const productImage3 = require('./product3.jpg')
  const productImage4 = require('./product4.jpg')
  const productImages = [
    productImage1,
    productImage2,
    productImage3,
    productImage4,
  ]
  if (!imageNumber) {
    return null
  }
  const image = productImages[imageNumber]
  return (
    <TouchableShrink
      onPress={() =>
        navigate(isWeb ? FEED_DETAIL + newsItem.id : ROUTE_FEED_DETAIL, {
          message: newsItem,
        })
      }
    >
      <ImageBackground
        source={image}
        resizeMode="cover"
        style={{
          height: isWide ? 109 : 135,
          paddingVertical: 24,
          paddingHorizontal: Spacings.PADDING,
          borderTopLeftRadius: BorderRadius.default,
          borderTopRightRadius: BorderRadius.default,
          overflow: 'hidden',
        }}
      />
      <BoxFeed style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <RowFeed>
          <MainContentFeed>
            <DateFeed>{feed.date}</DateFeed>
            <TextDefault
              type="t1"
              style={{
                marginBottom: Spacings.S4,
              }}
            >
              {title}
            </TextDefault>
            {content && (
              <Paragraph
                type="t2"
                color={Colors.textSecondary}
                style={{
                  marginBottom: Spacings.S16,
                }}
              >
                {content}
              </Paragraph>
            )}
          </MainContentFeed>
        </RowFeed>

        <TextDefault type="t2" style={{ color: Colors.textPrimary }}>
          {t('more')} ›
        </TextDefault>
      </BoxFeed>
    </TouchableShrink>
  )
}
