import React from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph, ParagraphBoldFirst } from '../../ui/text/Paragraph'
import PaymentInfoSection from './PaymentInfoSection'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { NumberedSection } from './NumberedSection'
import { NumberedSectionBox } from './NumberedSectionBox'
import { InfoContainer } from '../../ui/InfoContainer'
import { Divider } from '../../ui/Divider'
import Colors from '@taaleri/core/src/constants/Colors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function PaymentMonthlyContent() {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('steps.payment')}</Title>
      <Paragraph>{t('invest.monthly-payment-text')}</Paragraph>
      <PaymentInfoSection
        hideTitle={true}
        isConsultative={false}
        sum={AppStore.newInvestment}
      >
        <NumberedSection
          count={1}
          title={t('invest.bank-transaction-payment-info')}
          paragraph={t('invest.bank-transaction-payment-info-desc')}
        />
        <Divider style={{ marginBottom: 0, marginTop: Spacings.S16 }} />
      </PaymentInfoSection>
      <NumberedSectionBox
        count={2}
        title={t('invest.online-payment')}
        paragraph={t('invest.online-payment-desc')}
        style={{ marginTop: Spacings.S56 }}
      />
      <InfoContainer style={{ marginTop: Spacings.S24 }}>
        <ParagraphBoldFirst color={Colors.textSecondary}>
          {t('invest.online-payment-info')}
        </ParagraphBoldFirst>
      </InfoContainer>
      <NumberedSectionBox
        count={3}
        title={t('invest.finalize-payment')}
        paragraph={t('invest.finalize-payment-desc')}
        style={{ marginTop: Spacings.S40 }}
      />
    </View>
  )
}

export default observer(PaymentMonthlyContent)
