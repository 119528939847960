import React from 'react'
import TransactionRowContainer from '../Rows/TransactionRowContainer'
import { TextDefault } from '../../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

function CashTransactionHeader() {
  const { t } = useTranslation()
  return (
    <TransactionRowContainer hideBorder={true}>
      <TextDefault
        type="h6"
        style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
      >
        {t('portfolio.text')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('report.event')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 2, paddingHorizontal: Spacings.S2, color: Colors.text }}
        color={Colors.text}
      >
        {t('security')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('report.event-date')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('report.pcs')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('reportTransaction.pcs-price')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('portfolio.total')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('reportBox.expenses')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingHorizontal: Spacings.S2, color: Colors.text }}
      >
        {t('report.net')}
      </TextDefault>
      <TextDefault
        type="t5"
        style={{ flex: 1, paddingLeft: Spacings.S2, color: Colors.text }}
      >
        PDF
      </TextDefault>
    </TransactionRowContainer>
  )
}

export default CashTransactionHeader
