import { Order } from './../models/Order'
import { Identity } from '../models/Identity'
import IOrdersApi from './IOrdersApi'

function orders(_1: Identity, _2: number): Promise<Order[]> {
  return require(`../fixtures/orders/orders.json`)
}

export const ordersApiMock: IOrdersApi = {
  orders,
}
