import React from 'react'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { observer } from 'mobx-react'
import { MonthlyContractVerification } from '@taaleri/components/src/screens/funds/MonthlyContractVerification'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'

function MonthlyContractVerificationScreen() {
  return (
    <>
      <PaymentStepsFund currentStep={2} recurring={true} />
      <ContentContainerWide>
        <MonthlyContractVerification />
      </ContentContainerWide>
    </>
  )
}

export default observer(MonthlyContractVerificationScreen)
