import CustomerService from '@taaleri/core/src/constants/CustomerService'
import { nonBreakingSpaces } from '@taaleri/core/src/utils/format'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ContactCustomerServiceButtons from './ContactCustomerServiceButtons'
import { TextBold, TextMedium } from './text/UiText'

export default function ContactCustomerService() {
  const { t } = useTranslation()
  return (
    <>
      <TextMedium style={{ marginBottom: 22, lineHeight: 22 }}>
        <TextMedium>{t('customerService.open')} </TextMedium>

        <TextBold>{nonBreakingSpaces(CustomerService.phoneNumber)}</TextBold>

        <TextMedium>.</TextMedium>
      </TextMedium>
      <ContactCustomerServiceButtons buttonType="secondary" />
    </>
  )
}
