import React from 'react'

import ReactContentLoader, { Facebook } from 'react-content-loader'
import useLayout from './useLayout'
import { ContentLoaderProps } from './ContentLoader'

const ContentLoader = ({ variant }: ContentLoaderProps) => {
  const { isExtraWide } = useLayout()
  if (variant === 'flex') {
    return (
      <ReactContentLoader
        speed={2}
        width="100%"
        height="100%"
        backgroundColor="#ebebea"
        foregroundColor="#dad8d5"
      >
        <rect x="0" y="182" rx="4" ry="4" width="88" height="8" />
        <rect x="0" y="208" rx="6" ry="6" width="375" height="12" />
        <rect x="0" y="232" rx="6" ry="6" width="320" height="12" />
        <rect x="0" y="0" rx="6" ry="6" width="375" height="168" />
        <rect x="0" y="256" rx="6" ry="6" width="350" height="12" />
        <rect x="0" y="306" rx="4" ry="4" width="375" height="8" />
      </ReactContentLoader>
    )
  } else if (variant === 'facebook') {
    return <Facebook style={{ width: '100%' }} />
  }
  return (
    <ReactContentLoader
      speed={2}
      width={isExtraWide ? 375 : '100%'}
      height={340}
      viewBox="0 0 375 340"
      backgroundColor="#ebebea"
      foregroundColor="#dad8d5"
    >
      <rect x="0" y="182" rx="4" ry="4" width="88" height="8" />
      <rect x="0" y="208" rx="6" ry="6" width="375" height="12" />
      <rect x="0" y="232" rx="6" ry="6" width="320" height="12" />
      <rect x="0" y="0" rx="6" ry="6" width="375" height="168" />
      <rect x="0" y="256" rx="6" ry="6" width="350" height="12" />
      <rect x="0" y="306" rx="4" ry="4" width="375" height="8" />
    </ReactContentLoader>
  )
}

export default ContentLoader
