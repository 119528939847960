import { ProductKnowledgeOption } from '../../constants/Options'
import {
  findTagAnswerById,
  QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
} from '@taaleri/components/src/screens/questions/questions'
import { Question, Tag } from '../../models/Question'

interface ProductKnowledge {
  [key: string]: string
  knowledgeCapitalInvestments: string
  knowledgeCapitalSecuredStructuredProducts: string
  knowledgeCombinationFunds: string
  knowledgeCommodities: string
  knowledgeComplexDebt: string
  knowledgeDiscretionaryAssetManagement: string
  knowledgeEquities: string
  knowledgeFixedIncome: string
  knowledgeLeveragedSecurities: string
  knowledgeMoneyMarketFunds: string
  knowledgeUnsecuredStructuredProducts: string
}

const deriveKnowledge = (isFamiliar: boolean, hasInvested: boolean) =>
  isFamiliar
    ? hasInvested
      ? ProductKnowledgeOption.HasInvested
      : ProductKnowledgeOption.Knows
    : ProductKnowledgeOption.DoesNotKnow

export function toProductKnowledge(questions: Question[]): ProductKnowledge {
  const productKnowledgeIsFamiliarAnswers = findTagAnswerById(
    questions,
    QUESTION_PRODUCT_KNOWLEDGE_PART_ONE
  )
  const productKnowledgeHasInvestedAnswers = findTagAnswerById(
    questions,
    QUESTION_PRODUCT_KNOWLEDGE_PART_TWO
  )

  const hasInvestedIn: {
    [key: string]: boolean
  } = productKnowledgeHasInvestedAnswers.reduce(
    (obj: { [key: string]: boolean }, item: Tag) => {
      obj[item.value] = item.selected
      return obj
    },
    {}
  )

  return productKnowledgeIsFamiliarAnswers
    .map((item: Tag) => ({
      id: item.value,
      value: deriveKnowledge(item.selected, hasInvestedIn[item.value]),
    }))
    .reduce((obj: ProductKnowledge, item) => {
      obj[item.id] = item.value
      return obj
    }, {} as any)
}
