import { Identity } from '../models/Identity'
import ISavingsPlanApi from './ISavingsPlanApi'
import { SavingsPlan } from '../models/SavingsPlan'
import { sleep } from './mockHelper'

async function getSavingsPlan(_: Identity, _1: number): Promise<SavingsPlan> {
  await sleep(100)
  const response = require(`../fixtures/savingsPlans/savingsPlanResponse.json`)
  return new Promise<SavingsPlan>((resolve) => resolve(response))
}

// Can also be used to update savings plan
async function createSavingsPlan(
  _: Identity,
  _1: number,
  _2: number
): Promise<SavingsPlan> {
  await sleep(100)
  const response = require(`../fixtures/savingsPlans/savingsPlanResponse.json`)
  return new Promise<SavingsPlan>((resolve) => resolve(response))
}

async function deleteSavingsPlan(_: Identity, _1: number): Promise<void> {
  await sleep(100)
  return
}

export const savingsPlanApiMock: ISavingsPlanApi = {
  getSavingsPlan,
  createSavingsPlan,
  deleteSavingsPlan,
}
