import React from 'react'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import { TextDefault } from '../../../ui/text/UiText'
import {
  formatDateWithoutLeadingZeros,
  formatCurrency,
} from '@taaleri/core/src/utils/format'
import TransactionRowContainer from './TransactionRowContainer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  transaction: Transaction
}

function CashTransactionRow({ transaction }: Props) {
  const getReasonName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.reason
      : transaction.reasonSwedish

  const getPortfolioName = (): string =>
    (AppStore.currentLanguage === fiKey
      ? transaction?.portfolio?.portfolioNameForCustomer
      : transaction?.portfolio?.portfolioNameForCustomerSv) ?? ''

  return (
    <TransactionRowContainer>
      <TextDefault type="h7" style={{ flex: 4 }}>
        {getPortfolioName()}
      </TextDefault>
      <TextDefault type="t3" style={{ flex: 3 }}>
        {getReasonName()}
      </TextDefault>
      <TextDefault type="t3" style={{ flex: 2 }}>
        {formatDateWithoutLeadingZeros(transaction.paymentDate)}
      </TextDefault>
      <TextDefault type="t3" style={{ flex: 2 }}>
        {formatCurrency(transaction.amount, true)}
      </TextDefault>
      <TextDefault type="t3" style={{ flex: 2 }}>
        {transaction.note}
      </TextDefault>
    </TransactionRowContainer>
  )
}

export default CashTransactionRow
