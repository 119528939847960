import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import FlexRow from '../ui/FlexRow'
import { IconChevronDown, IconChevronUp } from '../ui/icons/ChevronIcons'
import { TextDefault } from '../ui/text/UiText'
import UiTouchable from '../ui/UiTouchable'

export const DropDownBox: React.FC<{ label: String }> = ({
  children,
  label,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <View>
      <UiTouchable
        onPress={() => setOpen(!open)}
        style={styles.dropDownTouchable}
      >
        <FlexRow style={styles.dropDownBoxRow}>
          <TextDefault type={'t2'}>{label}</TextDefault>
          {open ? <IconChevronUp /> : <IconChevronDown color={Colors.text} />}
        </FlexRow>
      </UiTouchable>
      {open &&
        React.Children.map(children, (child) => {
          return <View style={styles.dropDownItem}>{child}</View>
        })}
    </View>
  )
}

const styles = StyleSheet.create({
  dropDownTouchable: {
    paddingVertical: Spacings.S16,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  dropDownBoxRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Spacings.PADDING,
  },
  dropDownBox: {
    paddingHorizontal: Spacings.PADDING,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  dropDownItem: {
    paddingHorizontal: Spacings.PADDING,
    paddingVertical: Spacings.S16,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
})
