import React from 'react'
import ChangePasswordDoneContent from '@taaleri/components/src/screens/profile/ChangePasswordDoneContent'
import DoneScreen from './DoneScreen'

export default function ChangePasswordDoneScreen() {
  return (
    <DoneScreen>
      <ChangePasswordDoneContent />
    </DoneScreen>
  )
}
