import { getDistanceInFullDays } from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'

import AppStore from '@taaleri/core/src/stores/AppStore'

function formatTwrChangeDate(date: string, t: TFunction) {
  const days = getDistanceInFullDays(date)
  return `${days} ${days === 1 ? t('day') : t('days')}`
}

export function portfolioChange(t: TFunction) {
  const twrChange =
    AppStore.yieldHistory && AppStore.yieldHistory.twrChange
      ? AppStore.yieldHistory && AppStore.yieldHistory.twrChange
      : null
  const percentage =
    twrChange && twrChange.percentage !== null ? twrChange.percentage : null
  const date =
    twrChange && twrChange.date ? formatTwrChangeDate(twrChange.date, t) : null

  return { date, percentage }
}
