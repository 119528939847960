import React from 'react'
import { View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'

interface IconProps {
  color?: string
}

function AssetClassIcon({ color }: IconProps) {
  return (
    <View
      style={{
        marginRight: 10,
        height: 12,
        width: 12,
        borderRadius: 12,
        backgroundColor: color ? color : Colors.text,
      }}
    />
  )
}

export function AssetClassIconEmpty({ color }: IconProps) {
  return (
    <View
      style={{
        width: 12,
        height: 12,
        borderRadius: 12,
        borderColor: color || Colors.border,
        borderWidth: 2,
        marginRight: 10,
        backgroundColor: color || 'transparent',
      }}
    />
  )
}

export default AssetClassIcon
