import React from 'react'
import { StyleProp, StyleSheet, View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'

export const BorderedContainer: React.FC<{ style?: StyleProp<any> }> = ({
  children,
  style,
}) => <View style={[styles.borderedContainer, style]}>{children}</View>

const styles = StyleSheet.create({
  borderedContainer: {
    justifyContent: 'space-between',
    backgroundColor: Colors.white,
    flex: 1,
    padding: Spacings.S16,
    borderWidth: 1,
    borderColor: Colors.gray40,
    borderRadius: 6,
    marginBottom: Spacings.S8,
  },
})
