import { FormikHelpers } from 'formik'
import React, { useState } from 'react'

import { DownloadSummaryReportModal } from './DownloadSummaryReportModal'
import OpenLink from '../../ui/OpenLink'
import UiButton from '../../ui/UiButton'
import { ButtonType } from '../../ui/UiButtonProps'
import { DownloadReportModal } from '../portfolio/DownloadReportModal'

interface Props {
  title: string
  buttonStyle: object
  fetchUrl: (values: ReportValues) => string
  hideByPortfolio?: boolean
  type?: ButtonType
  reportType: ReportType
}

export type ReportFileFormat = 'pdf' | 'excel'
export type ReportType =
  | 'purchaselots'
  | 'summary'
  | 'transactions'
  | 'summaryNew'
export type SumLevel = 'Security' | 'Purchaselot'

export interface ReportValues {
  fileFormat: ReportFileFormat
  reportType: ReportType
  byPortfolio: boolean
  byPurchaselots: boolean
  loadBenchmarkIndexData: boolean
}

function DownloadReportButton({
  title,
  buttonStyle,
  fetchUrl,
  type,
  reportType,
}: Props) {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const Modal =
    reportType === 'transactions'
      ? DownloadReportModal
      : DownloadSummaryReportModal
  return (
    <>
      <UiButton
        title={title}
        containerStyle={buttonStyle}
        onPress={() => setModalVisible(true)}
        type={type}
      />
      <Modal
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        onSubmit={(
          values: ReportValues,
          actions: FormikHelpers<ReportValues>
        ) => {
          actions.setSubmitting(true)
          const url = fetchUrl(values)
          OpenLink(url, reportType)
          setModalVisible(false)
        }}
      />
    </>
  )
}

export default DownloadReportButton
