import React from 'react'
import { View } from 'react-native'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import ContractContent from '@taaleri/components/src/screens/contract/ContractContent'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { useUpdateStrategy } from '@taaleri/components/src/screens/contract/useUpdateContract'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { ROUTE_IMPACT_TOGGLE_VERIFICATION } from '@taaleri/components/src/screens/impact/ImpactRoutes'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper.native'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { ImpactToggleSteps } from '@taaleri/components/src/components/Steps'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

export default function ImpactToggleContractScreen() {
  const { t } = useTranslation()
  const { url, error, setRetry } = useUpdateStrategy()
  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  return (
    <>
      <ImpactToggleSteps currentStep={1} />
      {url ? (
        <>
          <View style={{ marginBottom: Spacings.S32 }} />
          <ContentContainerBox leadingTitle={false}>
            <TopBottomContainer>
              <ContractContent url={url} showPrices={true} />
              <Buttons
                title={t('app.ImpactToggleContract.confirmbuttton')}
                route={routePathApp(ROUTE_IMPACT_TOGGLE_VERIFICATION)}
              />
            </TopBottomContainer>
          </ContentContainerBox>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </>
  )
}
