import { getConfig } from '@taaleri/core/src/config'
import { ProfitType } from '@taaleri/core/src/models/ProfitReport'
import { TransactionCategory } from '@taaleri/core/src/models/TransactionReport'
import AppStore from '@taaleri/core/src/stores/AppStore'

import { SumLevel } from '../portfolio/DownloadReportButton'
import { ReportFileFormat } from '../portfolio/DownloadReportModal'

type ReportLevel = 'List' | 'Portfolio'

function portfoliosToParam(portfolioIds: string[]) {
  return portfolioIds.reduce((curr, acc) => curr + `&portfolioIds=${acc}`, '')
}

export function getDiscountCodeUrl(discountCode: string) {
  return `${getConfig().appUrl}/Questions?discountCode=${discountCode}`
}

export function getSummaryReportPdfUrl(
  customerId: number,
  startDate: string,
  endDate: string,
  token: string,
  loadBenchmarkIndexData: boolean,
  portfolioIds: string[] = [],
  format: ReportFileFormat = 'pdf'
) {
  const por = portfoliosToParam(portfolioIds)
  return `${
    getConfig().apiUrl
  }/ReportDocuments/summary/${customerId}/generated.pdf?startDate=${startDate}&endDate=${endDate}&token=${token}${por}&reportCurrency=EUR&format=${format}&loadBenchmarkIndexData=${loadBenchmarkIndexData}&language=${
    AppStore.currentLanguage
  }`
}

export function getSummaryReportPdfUrlNew(
  customerId: number,
  startDate: string,
  endDate: string,
  token: string,
  loadBenchmarkIndexData: boolean,
  portfolioIds: string[] = [],
  format: ReportFileFormat = 'pdf'
) {
  const por = portfoliosToParam(portfolioIds)
  return `${
    getConfig().apiUrl
  }/ReportDocuments/profitsV2/${customerId}/generated.pdf?startDate=${startDate}&endDate=${endDate}&token=${token}${por}&reportCurrency=EUR&format=${format}&includeBenchmark=${loadBenchmarkIndexData}&language=${
    AppStore.currentLanguage
  }`
}

export function getProfitReportPdfUrl(
  customerId: number,
  startDate: string,
  endDate: string,
  token: string,
  profitType: ProfitType,
  reportLevel: ReportLevel,
  portfolioIds: string[] = [],
  format: ReportFileFormat = 'pdf'
) {
  const por = portfoliosToParam(portfolioIds)
  return `${
    getConfig().apiUrl
  }/ReportDocuments/profits/${customerId}/generated.pdf?startDate=${startDate}&reportLevel=${reportLevel}&endDate=${endDate}&token=${token}${por}&reportCurrency=EUR&format=${format}&profitType=${profitType}&language=${
    AppStore.currentLanguage
  }`
}

export function getPurchaselotsReportPdfUrl(
  customerId: number,
  startDate: string,
  endDate: string,
  token: string,
  portfolioIds: string[] = [],
  format: ReportFileFormat,
  reportLevel: ReportLevel,
  sumLevel: SumLevel
) {
  const por = portfoliosToParam(portfolioIds)
  return `${
    getConfig().apiUrl
  }/ReportDocuments/purchaselots/${customerId}/generated.pdf?startDate=${startDate}&endDate=${endDate}&valueDate=${endDate}&token=${token}${por}&reportCurrency=EUR&format=${format}&reportLevel=${reportLevel}&sumLevel=${sumLevel}&language=${
    AppStore.currentLanguage
  }`
}

export function getTransactionListPdfUrl(
  category: TransactionCategory,
  customerId: number,
  startDate: string,
  endDate: string,
  token: string,
  format: ReportFileFormat,
  reportLevel: ReportLevel,
  portfolioIds: string[] = []
) {
  const isOpen = category === 'Open'
  const isCash = category === 'Cash'
  const endpointType = isCash ? 'cashaccountstatement' : 'transactions'
  const por = portfoliosToParam(portfolioIds)
  return `${
    getConfig().apiUrl
  }/ReportDocuments/${endpointType}/${customerId}/generated.pdf?startDate=${startDate}&endDate=${endDate}&format=${format}&token=${token}&reportLevel=${reportLevel}${por}&getOpenTransactions=${isOpen}&language=${
    AppStore.currentLanguage
  }`
}

export function getCashAccountStatementPdf(
  customerId: number,
  startDate: string,
  endDate: string,
  token: string
) {
  return `${
    getConfig().apiUrl
  }/ReportDocuments/cashaccountstatement/${customerId}/generated.pdf?startDate=${startDate}&endDate=${endDate}&format=pdf&token=${token}&language=${
    AppStore.currentLanguage
  }`
}

export function getTransactionPdfUrl(
  customerId: number,
  transactionId: number,
  token: string
) {
  return `${
    getConfig().apiUrl
  }/ReportDocuments/transactionConfirmations/${customerId}/generated.pdf?token=${token}&transactionId=${transactionId}&language=${
    AppStore.currentLanguage
  }`
}

export function getSecurityPdfUrl(
  customerId: number,
  ticker: string,
  token: string
) {
  return `${
    getConfig().apiUrl
  }/ReportDocuments/securities/${customerId}/${ticker}/file.pdf?token=${token}&language=${
    AppStore.currentLanguage
  }`
}
