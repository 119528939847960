import React from 'react'
import {
  formatCurrency,
  formatDateWithoutLeadingZeros,
  formatNumber,
} from '@taaleri/core/src/utils/format'
import { ProfitItem } from '@taaleri/core/src/models/ProfitReport'
import TransactionRowContainer from '../../reporttransaction/Rows/TransactionRowContainer'
import { RowItem } from './RowItem'
import { getChangeColor } from '@taaleri/core/src/constants/Colors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  item: ProfitItem
}

export function formatValueAndPrice(
  value?: number,
  price?: number,
  forceNewLine: boolean = true
) {
  if (value && price) {
    return `${formatCurrency(value, true)} /${
      forceNewLine ? '\n' : ''
    }${formatCurrency(price, true)}`
  } else if (value) {
    return `${formatCurrency(value, true)} / - `
  } else if (price) {
    return ` - / ${formatCurrency(price, true)} `
  }
  return '- / -'
}

export function CapitalGainRow({ item }: Props) {
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.securityNameFinnish
      : item.securityNameSwedish

  const getPortfolioName = (): string =>
    AppStore.currentLanguage === fiKey
      ? item.portfolioNameForCustomer
      : item.portfolioNameForCustomerSv

  return (
    <TransactionRowContainer>
      <RowItem style={{ flex: 2 }}>{getPortfolioName()}</RowItem>
      <RowItem style={{ flex: 2 }}>{getName()}</RowItem>
      <RowItem>{formatNumber(item.amount)}</RowItem>
      <RowItem>{formatDateWithoutLeadingZeros(item.purchaseDate)}</RowItem>

      <RowItem>{formatDateWithoutLeadingZeros(item.transactionDate)}</RowItem>
      <RowItem>
        {formatValueAndPrice(item.purchaseValue, item.purchasePrice)}
      </RowItem>
      <RowItem>{formatValueAndPrice(item.sellValue, item.sellPrice)}</RowItem>
      <RowItem>{formatCurrency(item.profitCosts, true)}</RowItem>
      <RowItem>
        {item.purchaseCostAssumption
          ? formatCurrency(item.purchaseCostAssumption, true)
          : ''}
      </RowItem>
      <RowItem style={{ color: getChangeColor(item.profit) }}>
        {formatCurrency(item.profit, true)}
      </RowItem>
    </TransactionRowContainer>
  )
}
