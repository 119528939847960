import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { View } from 'react-native'
import FeedContent from '@taaleri/components/src/screens/feed/FeedContent'

export default function FeedScreen() {
  return (
    <View style={{ paddingHorizontal: Spacings.S16 }}>
      <FeedContent />
    </View>
  )
}
