import React from 'react'
import { observer } from 'mobx-react'
import AssetDetail from './AssetDetail'
import { View, StyleProp, ViewStyle } from 'react-native'
import { AssetClass, Portfolio } from '@taaleri/core/src/models/Portfolio'
import { AssetClassOrder } from '@taaleri/core/src/constants/AssetClass'

interface Props {
  linkTitle?: boolean
  portfolio: Portfolio
  showAdvanced?: boolean
  style?: StyleProp<ViewStyle>
}

function sortAssetClasses(assetClasses: AssetClass[]) {
  return assetClasses
    .slice()
    .sort(
      (a, b) => AssetClassOrder.indexOf(a.id) - AssetClassOrder.indexOf(b.id)
    )
}

function AssetDetails({ linkTitle, portfolio, showAdvanced, style }: Props) {
  // Sort asset classes alphabetically. Set cash always as the last asset class.
  const assetClasses = showAdvanced
    ? sortAssetClasses(portfolio.assetClasses)
    : portfolio.assetClasses

  return (
    <View style={style}>
      {assetClasses.map((assetClass) => {
        return (
          <AssetDetail
            key={assetClass.id}
            assetClass={assetClass}
            linkTitle={linkTitle}
            showAdvanced={showAdvanced}
          />
        )
      })}
    </View>
  )
}

export default observer(AssetDetails)
