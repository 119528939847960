import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useField, connect } from 'formik'
import ListItem from '../ListItem'
import UiTouchable from '../UiTouchable'
import { isWeb } from '../../constants/Platforms'

interface Props {
  bottomDivider?: boolean
  containerStyle?: StyleProp<ViewStyle>
  topDivider?: boolean
  name: string
  title: string
  yesValue: string
  noValue: string
}

const UiListItemSwitchText: React.FC<Props> = (props) => {
  const {
    bottomDivider,
    containerStyle,
    topDivider,
    title,
    name,
    yesValue,
    noValue,
  } = props

  const [field, , helpers] = useField(name)
  const value = field.value
  return (
    <UiTouchable
      onPress={() => {
        helpers.setValue(value === noValue ? yesValue : noValue)
      }}
    >
      <ListItem
        title={title}
        topDivider={topDivider === false ? false : true}
        bottomDivider={bottomDivider || false}
        containerStyle={containerStyle}
        switchProps={{
          value: value === yesValue,
          onValueChange: (_: boolean) => {
            if (!isWeb) {
              helpers.setValue(value === noValue ? yesValue : noValue)
            }
          },
        }}
      />
    </UiTouchable>
  )
}

export default connect<Props>(UiListItemSwitchText)
