import React from 'react'
import PaymentRecurringConfirmContent from '@taaleri/components/src/screens/portfolio/PaymentRecurringConfirmContent'
import { ContentContainerBox } from 'ui/container/ContentContainer'

export default function PaymentRecurringConfirmScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <PaymentRecurringConfirmContent />
    </ContentContainerBox>
  )
}
