import React from 'react'

import { ListItemLinkData } from '../../ui/ListItem'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import {
  ROUTE_PROFILE_INVESTMENT_PROPOSAL,
  ROUTE_PROFILE_DISCOUNT_CODE,
} from './ProfileRoutes'
import {
  ROUTE_REPORT_ORDERS,
  ROUTE_REPORT_ARCHIVE,
} from '../../navigation/routes'
import CardWithLinks from '../../ui/CardWithLinks'
import { ROUTE_CONTRACT_START_OPEN_CONSULTATIVE } from '../contract/ContractRoutes'
import { ROUTE_RECURRING_ORDERS_LIST } from '../recurringorders/RecurringOrderRoutes'
import { routePathContract, routePathApp } from '../../navigation/routeHelper'
import {
  ROUTE_IMPACT_DISABLE,
  ROUTE_IMPACT_TOGGLE_ON,
} from '../impact/ImpactRoutes'
import { ROUTE_SAVINGS_PLAN } from '../SavingsPlan/SavingsPlanRoutes'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'

function InvestmentSection() {
  const { t } = useTranslation()
  const customer = AppStore.activeCustomer
  const isDigiImpact = AppStore.isDigiImpactCustomer
  const isDigi = customer.isDiscretionary
  const isDigiEtf = AppStore.isDigiEtfCustomer

  const showRecurringOrders =
    AppStore.isConsultative && !AppStore.isCustomerBlocked

  const linkItems: ListItemLinkData[] = [
    ...insertIf(isDigi, {
      title: t('profile.investmentSection.invest-plan'),
      link: routePathApp(ROUTE_PROFILE_INVESTMENT_PROPOSAL),
    }),
    ...insertIf(AppStore.showOpenConsultative, {
      title: t('profile.investmentSection.create-invest-portfolio'),
      link: routePathContract(ROUTE_CONTRACT_START_OPEN_CONSULTATIVE),
    }),
    ...insertIf(AppStore.IsReportOrdersForbidden() !== true, {
      title: t('menu.report-orders'),
      link: routePathApp(ROUTE_REPORT_ORDERS),
    }),
    {
      title: t('menu.report-archive'),
      link: routePathApp(ROUTE_REPORT_ARCHIVE),
    },
    // ...insertIf(showRecurringOrders, {
    //   title: t('profile.investmentSection.fund-monthly-saving'),
    //   link: routePathApp(ROUTE_RECURRING_ORDERS_LIST),
    // }),
    // ...insertIf(AppStore.isDigiDiscretionary, {
    //   title: t('profile.investmentSection.monthly-saving'),
    //   link: routePathApp(ROUTE_SAVINGS_PLAN),
    // }),
    // ...insertIf(isDigiImpact, {
    //   title: t('profile.investmentSection.change-etf'),
    //   link: routePathApp(ROUTE_IMPACT_DISABLE),
    // }),
    // ...insertIf(isDigi, {
    //   title: t('profile.investmentSection.campaign-code'),
    //   link: routePathApp(ROUTE_PROFILE_DISCOUNT_CODE),
    // }),
    // // Does not work yet on app, because ImpactNavigator is not mounted
    // ...insertIf(isDigiEtf && isWeb, {
    //   title: t('profile.investmentSection.change-impact'),
    //   link: routePathApp(ROUTE_IMPACT_TOGGLE_ON),
    // }),
  ]

  return (
    <CardWithLinks
      title={`${t('profile.investmentSection.investing')}`}
      items={linkItems}
    />
  )
}

export default observer(InvestmentSection)
