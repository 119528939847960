import FundsIntroContent from '@taaleri/components/src/screens/funds/FundsIntroContent'
import React from 'react'
import { AppPadding } from 'screens/app/AppPadding'

export default function FundsIntroScreen() {
  return (
    <AppPadding>
      <FundsIntroContent />
    </AppPadding>
  )
}
