import React from 'react'
import { View } from 'react-native'
import { TextDefault } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import UiTouchable from '../../ui/UiTouchable'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { replaceContractName } from '@taaleri/core/src/utils/documentNameHelper'

interface ReportRowProps {
  label?: string
  value?: string
  onPress?: () => void
  topBorder?: boolean
  icon?: JSX.Element
}

function ReportRow({ label, value, topBorder, onPress, icon }: ReportRowProps) {
  const Container = onPress ? UiTouchable : View
  return (
    <Container
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingVertical: Spacings.S10,
        paddingHorizontal: Spacings.S10,
        borderBottomWidth: 1,
        borderTopWidth: topBorder ? 1 : 0,
        borderColor: Colors.border,
      }}
      onPress={onPress}
    >
      {value ? (
        <TextDefault
          type="t5"
          style={{
            paddingRight: 8,
            borderRightWidth: 1,
            borderColor: Colors.border,
          }}
        >
          {value}
        </TextDefault>
      ) : null}
      {label ? (
        <TextDefault
          type="t2"
          numberOfLines={1}
          style={{
            paddingHorizontal: Spacings.S8,
            flex: 1,
          }}
        >
          {replaceContractName(label, AppStore.currentLanguage)}
        </TextDefault>
      ) : null}
      {icon}
    </Container>
  )
}

export default ReportRow
