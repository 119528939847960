import React, { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
} from '@taaleri/components/src/ui/Victory'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import useLayout from '../../ui/useLayout'
import { observer } from 'mobx-react'
import { TimeseriesType } from '../questions/TimeseriesType'
import { isWeb } from '../../constants/Platforms'
import { InterpolationPropType } from 'victory-core'
import { ChartLegend } from '../questions/ChartLegend'
import { useTranslation } from 'react-i18next'

interface Props {
  revenueTimeseries: TimeseriesType
  capitalTimeseries: TimeseriesType
  style?: StyleProp<ViewStyle>
  interpolation?: InterpolationPropType
}

export function formatYLabel(t: number): string {
  if (Math.abs(t) < 1) {
    return '0 €'
  }
  if (Math.abs(t) < 1000) {
    return t.toString() + ' €'
  }
  if (Math.abs(t) < 1000000) {
    return (t / 1000).toString() + ' k€'
  } else {
    return (t / 1000000).toString() + ' M€'
  }
}

export function formatXLabel(t: number): string {
  const yearNow = new Date().getFullYear()
  const year = yearNow + t
  return Number.isInteger(year) ? year.toString() : ''
}

function getTicks(timeSeries: TimeseriesType) {
  const first = timeSeries[0].x
  const last = timeSeries[timeSeries.length - 1].x
  return isWeb ? undefined : [first, last]
}

function getTextAnchor(tick: number, timeSeries: TimeseriesType) {
  const last = timeSeries[timeSeries.length - 1].x
  if (last === tick && !isWeb) {
    return 'end'
  }
  return 'start'
}
function CalculatorGraph({
  revenueTimeseries,
  capitalTimeseries,
  style,
  interpolation,
}: Props) {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>(450)
  const { isSmall } = useLayout()
  const labelFontSize = FontSize.S10
  const yAxisStyle = {
    axis: { stroke: 'transparent' },
    grid: {
      stroke: Colors.inactiveTint,
      strokeDasharray: '1,3',
      strokeWidth: 1,
      strokeOpacity: 1,
    },
    tickLabels: {
      fill: Colors.text,

      fontSize: labelFontSize,
      textAnchor: 'start',
    },
  }

  const xAxisStyle = {
    axis: { stroke: Colors.border, strokeWidth: 1 },
    axisLabel: { stroke: Colors.border, strokeWidth: 1 },
    ticks: { color: Colors.error },
    tickLabels: {
      fill: Colors.text,

      fontSize: labelFontSize,
      textAnchor: (o: any) => getTextAnchor(o.tick, revenueTimeseries),
    },
  }

  return (
    <View style={style}>
      <View
        pointerEvents="none"
        onLayout={(event) => setWidth(event.nativeEvent.layout.width)}
      >
        <VictoryChart
          padding={{
            top: isSmall ? 0 : Spacings.S24,
            bottom: Spacings.S32,
          }}
          width={width}
          height={isSmall ? 250 : 300}
        >
          <VictoryArea
            data={revenueTimeseries}
            style={{ data: { fill: Colors.primary } }}
            interpolation={interpolation || 'linear'}
          />
          <VictoryArea
            data={capitalTimeseries}
            style={{ data: { fill: Colors.white80 } }}
            interpolation={interpolation || 'linear'}
          />
          <VictoryAxis
            crossAxis={false}
            tickFormat={formatXLabel}
            fixLabelOverlap={true}
            style={xAxisStyle}
            tickValues={getTicks(revenueTimeseries)}
          />
          <VictoryAxis
            dependentAxis={true}
            tickLabelComponent={<VictoryLabel x={0} dy={-9} />}
            tickFormat={formatYLabel}
            style={yAxisStyle}
          />
        </VictoryChart>
      </View>
      <ChartLegend
        items={[
          { title: `${t('graph.profit')}`, color: Colors.primary },
          { title: `${t('graph.savings')}`, color: Colors.primaryLight },
        ]}
      />
    </View>
  )
}

export default observer(CalculatorGraph)
