import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isWeb } from '../../constants/Platforms'

import { routePathApp } from '../../navigation/routeHelper'
import { ROUTE_APP_INFO, ROUTE_APP_OPINION } from '../../navigation/routes'
import CardWithLinks from '../../ui/CardWithLinks'
import { ListItemLinkData } from '../../ui/ListItem'

function AppInfoSection() {
  const { t } = useTranslation()
  const linkItems: ListItemLinkData[] = [
    {
      title: t('profile.appInfoSection.announcements'),
      link: routePathApp(ROUTE_APP_INFO),
    },
    {
      title: t('profile.appInfoSection.opinions'),
      link: routePathApp(ROUTE_APP_OPINION),
    },
  ]

  const linkItemsMobile: ListItemLinkData[] = [
    {
      title: t('profile.appInfoSection.announcements'),
      link: routePathApp(ROUTE_APP_INFO),
    },
  ]

  return (
    <CardWithLinks
      title={`${t('profile.appInfoSection.documents')}`}
      items={isWeb ? linkItems : linkItemsMobile}
    />
  )
}

export default observer(AppInfoSection)
