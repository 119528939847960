import React from 'react'
import PaymentConfirmContent from '@taaleri/components/src/screens/portfolio/PaymentConfirmContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'

export default function PaymentConfirmScreen() {
  return (
    <>
      <PaymentStepsFund currentStep={2} />
      <ContentContainerWide>
        <PaymentConfirmContent />
      </ContentContainerWide>
    </>
  )
}
