import AppStore from '@taaleri/core/src/stores/AppStore'

export interface FundForm {
  sum: string
  paymentDay: number
  paymentType: string
  ticker: string
}

export function getInitialFundFormValues(isRecurring: boolean): FundForm {
  return {
    sum: AppStore.newInvestment > 0 ? AppStore.newInvestment.toString() : '',
    paymentDay: AppStore.paymentDay,
    paymentType: isRecurring ? 'recurring' : 'onetime',
    ticker: AppStore.fundId ?? ''
  }
}
