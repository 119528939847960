import React from 'react'
import { observer } from 'mobx-react'
import Box from '../../ui/box/Box'
import { StyleSheet, View } from 'react-native'
import ReportBoxHeader from './ReportBoxHeader'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiButton from '../../ui/UiButton'
import { isWeb } from '../../constants/Platforms'

interface Props {
  title: string
  subtitle: string
  children?: any
  buttonTitle?: string
  onButtonPress?: () => void
  image?: any
  backgroundColor?: string
  icon?: any
}

function ReportBox({
  title,
  subtitle,
  children,
  buttonTitle,
  onButtonPress,
  backgroundColor,
  icon,
}: Props) {
  const showButton = Boolean(buttonTitle && onButtonPress)

  return (
    <Box style={{ marginBottom: isWeb ? 0 : Spacings.S20 }}>
      <View style={[styles.backgroundImage, { backgroundColor }]}>
        {icon}
        <ReportBoxHeader {...{ title, subtitle }} />
        <View style={{ flex: 1, justifyContent: 'flex-end' }}>
          {children}

          {showButton && (
            <UiButton
              title={buttonTitle || ''}
              onPress={onButtonPress}
              size="small"
            />
          )}
        </View>
      </View>
    </Box>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    padding: Spacings.PADDING,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
})

export default observer(ReportBox)
