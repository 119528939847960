import sumBy from 'lodash/sumBy'

type OrderType = 'Buy' | 'Sell'
export interface Order {
  amount: number
  id: string
  orderType: OrderType
  processedOn: string | null
  approvedOn: string | null
  sellEverything: boolean
  paidOutOn: string | null
  isDeleted: boolean
  securityId: string | null
  securityName: string | null
  createdOn: string
  recurringTransactionDay?: number
  isRecurring?: boolean
  isDirectOrder?: boolean // Is used to separate etf orders from fund orders
  dueDate?: string
}

function ordersByType(orders: Order[], orderType: OrderType): Order[] {
  return orders.filter((o) => o.orderType === orderType)
}

export function pendingOrdersBuySum(orders: Order[]): number {
  const buyOrders = ordersByType(orders, 'Buy').filter(
    (p) => !p.processedOn && !p.isDeleted && !p.isRecurring && !p.isDirectOrder
  )
  return sumBy(buyOrders, (order) => order.amount)
}

export function incominOrdersBuySum(orders: Order[]): number {
  const buyOrders = ordersByType(orders, 'Buy').filter(
    (p) => !p.approvedOn && !p.isDeleted && !p.isRecurring && !p.isDirectOrder
  )
  return sumBy(buyOrders, (order) => order.amount)
}

export function pendingOrdersSellSum(orders: Order[]): number {
  const sellOrders = ordersByType(orders, 'Sell').filter(
    (p) => (!p.processedOn || !p.paidOutOn) && !p.isDeleted && !p.isDirectOrder
  )
  return sumBy(sellOrders, (order) => order.amount)
}

export function pendingOrdersSellEverything(orders: Order[]): boolean {
  const buyOrders = ordersByType(orders, 'Sell').filter(
    (p) =>
      p.sellEverything && (!p.processedOn || !p.paidOutOn) && !p.isDirectOrder
  )
  return buyOrders.length > 0
}

export function sellOrdersBySecurityId(
  orders: Order[],
  securityId: string
): Order | undefined {
  return orders.find(
    (o) =>
      o.orderType === 'Sell' &&
      o.securityId === securityId &&
      (!o.processedOn || !o.paidOutOn)
  )
}
