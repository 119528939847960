import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconWarning } from '../../ui/Icons'
import { useTranslation } from 'react-i18next'

export const ExistingAuthorizationContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <IconWarning style={{ alignItems: 'center', marginTop: Spacings.S24 }} />
      <Title style={{ textAlign: 'center' }}>
        {t('questions.existing-authorization-title')}
      </Title>
      <Paragraph style={{ textAlign: 'center' }}>
        {t('questions.existing-authorization-text')}
      </Paragraph>
    </>
  )
}
