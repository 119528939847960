import React, { useEffect, useState } from 'react'
import useQueryParams from 'navigation/useQueryParams'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { getPaymentStatus } from '@taaleri/core/src/services/payments'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { sleep } from '@taaleri/core/src/api/mockHelper'
import { Redirect } from 'react-router-dom'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_PORTFOLIO_PAYMENT_BANK } from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'

export default function PaymentWaitStatusScreen() {
  const params = useQueryParams()
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const { requestId } = params

  async function fetchStatus(id: any) {
    if (requestId) {
      let paymentStatus = false
      let i = 0
      do {
        paymentStatus = await getPaymentStatus(AppStore.customerId, id)
        if (!paymentStatus) {
          i++
          await sleep(2000)
        }
      } while (!paymentStatus && i < 5)
      // If payment status is true, payment has succeeded
      paymentStatus ? setSuccess(true) : setError(true)
    } else {
      setError(true)
    }
  }
  useEffect(() => {
    fetchStatus(requestId)
  }, [requestId])

  if (success) {
    return (
      <Redirect
        to={{
          pathname: '',
          search: '?paymentResult=true',
        }}
      />
    )
  }

  if (error) {
    return (
      <Redirect
        to={{
          pathname: routePathApp(ROUTE_PORTFOLIO_PAYMENT_BANK),
          search: '?paymentResult=false',
        }}
      />
    )
  }

  return <LoadingIndicator />
}
