import React from 'react'
import Title from '../../ui/text/Title'
import { View } from 'react-native'
import { Paragraph } from '../../ui/text/Paragraph'
import { FundGrid } from './FundGrid'
import { isWeb } from '../../constants/Platforms'
import { FundCard } from './FundCard'
import { DividerNoMargin } from '../../ui/Divider'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Fund } from '@taaleri/core/src/models/Fund'
import ContentLoader from '../../ui/ContentLoader'
import { useTranslation } from 'react-i18next'

interface Props {
  recommendations: Fund[] | undefined
}

export default function FundRecommendationContent({ recommendations }: Props) {
  const { t } = useTranslation()
  return (
    <View style={{ marginBottom: Spacings.S40 }}>
      <Title style={[isWeb && { marginTop: 0, marginBottom: 12 }]}>
        {t('invest.funds-title')}
      </Title>
      <Paragraph style={{ marginBottom: Spacings.S32 }}>
        {t('invest.funds-similar')}
      </Paragraph>

      <FundGrid>
        {!recommendations &&
          [1, 2, 3].map((id) => <ContentLoader variant="custom" key={id} />)}
        {recommendations &&
          recommendations.map((fund) => (
            <FundCard fund={fund} key={fund.ticker} />
          ))}
      </FundGrid>
      <DividerNoMargin />
    </View>
  )
}
