import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW } from '@taaleri/components/src/screens/funds/FundsRoutes'
import ExistingOrderContent from '@taaleri/components/src/screens/portfolio/ExistingOrderContent'
import PaymentWithdrawalForm, {
  withdrawNextButtonText,
} from '@taaleri/components/src/screens/portfolio/PaymentWithdrawalForm'
import {
  ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION,
  withdrawalNotAllowed,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import WithdrawalHeader from '@taaleri/components/src/screens/portfolio/WithdrawalHeader'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Buttons from 'ui/Buttons'
import {
  ContentContainerBox,
  ContentContainerWide,
} from 'ui/container/ContentContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

function PaymentWithdrawalScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const notAllowed = withdrawalNotAllowed()

  if (notAllowed) {
    return (
      <ContentContainerBox leadingTitle={true}>
        <ExistingOrderContent />
      </ContentContainerBox>
    )
  }

  return (
    <>
      <WithdrawalHeader />
      {!AppStore.loadingFund && (
        <ContentContainerWide leadingTitle={true}>
          <TopBottomContainer>
            <PaymentWithdrawalForm
              container={(props2: PropsChildren) => (
                <>
                  <UiForm>
                    {props2.children}
                    <Buttons title={withdrawNextButtonText(t)} />
                  </UiForm>
                </>
              )}
              onSubmit={() => {
                history.push(
                  routePathApp(
                    AppStore.investmentMode === 'withdrawfund'
                      ? ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW
                      : ROUTE_PORTFOLIO_PAYMENT_WITHDRAWAL_VERIFICATION
                  )
                )
              }}
            />
          </TopBottomContainer>
        </ContentContainerWide>
      )}
    </>
  )
}

export default observer(PaymentWithdrawalScreen)
