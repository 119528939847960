import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import FundListContent from './FundListContent'
import FundRecommendationContent from './FundRecommendationContent'
import { Fund } from '@taaleri/core/src/models/Fund'
import BlockedCustomerWarning from './BlockedCustomerWarning'
import AppStore from '@taaleri/core/src/stores/AppStore'

export default function FundsIntroContent() {
  const [funds, setFunds] = useState<Fund[]>([])
  const [recommendations, setRecommendations] = useState<Fund[]>()
  const maxRetries = 5
  const timeOutInMs = 2000

  useEffect(() => {
    async function findFunds(retries: number) {
      const customerFunds = AppStore.customerFunds
      const retry = customerFunds.length === 0
      // If fetching funds fails or fund list is empty, retry few times.
      if (retry && retries < maxRetries) {
        retries += 1
        await AppStore.updateIsBlocked()
        await AppStore.loadCustomerFunds()
        setTimeout(findFunds, timeOutInMs, retries)
      } else {
        setFunds(customerFunds)
      }
    }
    findFunds(0)
  }, [])

  useEffect(() => {
    async function findRecommendations(retries: number) {
      const recommendedFunds = AppStore.recommendedFunds
      const retry = recommendedFunds.length === 0
      // If fetching funds fails or fund list is empty, retry few times.
      if (retry && retries < maxRetries) {
        retries += 1
        await AppStore.loadCustomerRecommendedFunds()
        setTimeout(findRecommendations, timeOutInMs, retries)
      } else {
        setRecommendations(recommendedFunds)
      }
    }
    findRecommendations(0)
  }, [])

  return (
    <View>
      <BlockedCustomerWarning />
      <FundRecommendationContent recommendations={recommendations} />
      <FundListContent {...{ funds }} />
    </View>
  )
}
