import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import useLayout from '../../ui/useLayout'
import FlexRow from '../../ui/FlexRow'

export interface NumberedSectionProps {
  title: string
  paragraph: string
  count: number
}

export function NumberedSection({
  title,
  paragraph,
  count,
}: NumberedSectionProps) {
  const { isWide } = useLayout()
  const Container = isWide ? FlexRow : View
  return (
    <Container>
      <View
        style={{
          backgroundColor: Colors.primary,
          width: Spacings.S32,
          height: Spacings.S32,
          borderRadius: Spacings.S32,
          marginBottom: Spacings.S10,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: isWide ? 'center' : 'flex-start',
          marginRight: Spacings.S32,
        }}
      >
        <TextDefault type="h5" color={Colors.white}>
          {count}
        </TextDefault>
      </View>
      <View style={{ flex: 1 }}>
        <TextDefault type="h5" style={{ marginBottom: Spacings.S10 }}>
          {title}
        </TextDefault>
        <TextDefault color={Colors.textSecondary}>{paragraph}</TextDefault>
      </View>
    </Container>
  )
}
