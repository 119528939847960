import Analytics from '@taaleri/core/src/analytics/Analytics'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import { SizeLargeResponsive } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  FROM_BEGINNING,
  PAST_MONTH,
  PAST_YEAR,
  YEAR_TO_DATE,
  PAST_THREE_YEARS,
  PAST_FIVE_YEARS,
  SIX_MONTHS,
} from '@taaleri/core/src/constants/YieldHistory'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, StyleSheet, View } from 'react-native'
import usePortal from 'react-useportal'

import { HistoryScaleButtonsProps } from './HistoryScaleButtons'
import { MarketValueChangeRow } from './PortfolioProfitContent'
import FlexRow from '../../ui/FlexRow'
import UiTouchable from '../../ui/UiTouchable'
import { IconChevronDown } from '../../ui/icons/ChevronIcons'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

interface IdValue {
  id: string
  value: string
}

const useScale = (
  items: IdValue[],
  onPress: (id: string) => void,
  isPickerItemActive: (id: string) => boolean,
  containerStyle: any
) => {
  const { Portal, togglePortal, openPortal, isOpen, closePortal } = usePortal({
    bindTo: document.getElementById('#navScaleSelection') as HTMLElement,
  })
  const ScaleSelection = () => (
    <Portal>
      <Animated.View style={containerStyle}>
        {items.map(({ id, value }) => (
          <UiTouchable
            key={id}
            onPress={() => {
              onPress(id)
              closePortal()
            }}
            style={styles.dropDownPickerItem}
          >
            <TextDefault
              style={[isPickerItemActive(id) ? { color: Colors.primary } : {}]}
            >
              {value}
            </TextDefault>
          </UiTouchable>
        ))}
      </Animated.View>
    </Portal>
  )
  return {
    ScaleSelection,
    togglePortal,
    openPortal,
    isOpen,
    closePortal,
  }
}

export default function HistoryScaleButtons(props: HistoryScaleButtonsProps) {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  const [activePickerItemId, setActivePickerItemId] = useState<string>(
    props.initialScale ? props.initialScale : PAST_YEAR
  )

  const pickerVisibility = new Animated.Value(1)

  const isPickerItemActive = (buttonId: string): boolean => {
    return activePickerItemId === buttonId
  }

  const onPress = (id: string) => {
    Animated.timing(pickerVisibility, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      setActivePickerItemId(id)
      props.onChange(id)
    })
    const eventAction = `Scale Change${
      props.type === 'purchaselot' ? ' purchaselot' : ''
    }`
    Analytics.event('Portfolio', eventAction, id)
  }

  const { pastMonth, yearToDate, threeYears, fiveYears, sixMonth } = props
  const items = [
    {
      id: FROM_BEGINNING,
      value: t('filter.from-start'),
    },
    fiveYears
      ? {
          id: PAST_FIVE_YEARS,
          value: t('filter.years', { value: 5 }),
        }
      : undefined,
    threeYears
      ? {
          id: PAST_THREE_YEARS,
          value: t('filter.years', { value: 3 }),
        }
      : undefined,
    {
      id: PAST_YEAR,
      value: t('filter.year'),
    },
    yearToDate
      ? {
          id: YEAR_TO_DATE,
          value: t('filter.start-of-year'),
        }
      : undefined,
    sixMonth
      ? {
          id: SIX_MONTHS,
          value: t('filter.months', { value: 6 }),
        }
      : undefined,
    pastMonth
      ? {
          id: PAST_MONTH,
          value: t('filter.month'),
        }
      : undefined,
  ].filter(Boolean) as IdValue[]
  const activeItem = items.find((item) => item.id === activePickerItemId)
  const activeItemText = activeItem ? activeItem.value : ''

  const containerStyle = {
    opacity: pickerVisibility.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
    }),
    transform: [
      {
        scale: pickerVisibility.interpolate({
          inputRange: [0, 1],
          outputRange: [0.9, 1],
        }),
      },
    ],
  }

  const { ScaleSelection, togglePortal, isOpen } = useScale(
    items,
    onPress,
    isPickerItemActive,
    containerStyle
  )

  const { marketValueChange, marketValueChangePercent } = props

  return (
    <View
      style={[
        styles.container,
        isNonSmall && {
          left: Spacings.S24,
          right: Spacings.S24,
        },
      ]}
    >
      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingTop: Spacings.S16,
          paddingBottom: Spacings.S16,
        }}
      >
        <FlexRow style={{ alignItems: 'center', minHeight: 32 }}>
          <TextDefault type="t5" style={styles.label}>
            {t('app.tuotto.profit')}
          </TextDefault>
          {isNonSmall && (
            <MarketValueChangeRow
              {...{ marketValueChange, marketValueChangePercent }}
            />
          )}
        </FlexRow>

        <div
          id="#navScaleSelection"
          style={{
            borderRadius: BorderRadius.default,
            boxShadow: `${Colors.gray20} 0px 6px 12px`,
            backgroundColor: Colors.white,
          }}
        />
        {!isOpen && (
          <UiTouchable onPress={togglePortal}>
            <FlexRow style={{ alignItems: 'center', minHeight: 32 }}>
              <TextDefault
                type="t2"
                color={Colors.textPrimary}
                style={{ marginRight: Spacings.S8 }}
              >
                {activeItemText}
              </TextDefault>

              <View style={{ paddingTop: 2 }}>
                <IconChevronDown color={Colors.primary} />
              </View>
            </FlexRow>
          </UiTouchable>
        )}
        {isOpen && <ScaleSelection />}
      </FlexRow>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    position: 'absolute',
    left: Spacings.S16,
    right: Spacings.S16,
    minHeight: Spacings.S64,
  },

  buttons: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  label: {
    paddingRight: Spacings.S24 / 2,
  },
  dropDownPickerItem: {
    paddingVertical: Spacings.S16,
    paddingHorizontal: Spacings.S32,
    minWidth: 177,
  },
  marketValueChange: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
    marginRight: Spacings.S16,
  },

  percentage: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
  },
})
