import { useState, useEffect } from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { getOnboardingProcessIdentity } from '@taaleri/core/src/services/storage'
import { authentication } from '@taaleri/core/src/services/authentication'
import isEmpty from 'lodash/isEmpty'

export default function useStartOnboarding() {
  const [loading, setLoading] = useState<boolean>(true)
  const [initOnboardingFailed, setInitOnboardingFailureState] =
    useState<boolean>(false)

  useEffect(() => {
    AppStore.newInvestment = 0
    AppStore.newInvestmentConfirmed = 0
    AppStore.incomingInvestmentConfirmed = 0
    QuestionStore.questionMode = 'onboarding'
    if (QuestionStore.preventRestart || QuestionStore.isOldFillDate) {
      QuestionStore.reset()
    }
  }, [])

  async function reset() {
    QuestionStore.reset()
    await tryToStartOnboardingProcess()
  }

  useEffect(() => {
    async function createProcessIdIfNeeded() {
      const currentId = await getOnboardingProcessIdentity()
      if (
        !currentId ||
        isEmpty(currentId) ||
        !QuestionStore.allowRestart ||
        QuestionStore.preventRestart ||
        currentId.indexOf('mocka-api') > -1
      ) {
        await tryToStartOnboardingProcess()
      }
      setLoading(false)
    }
    createProcessIdIfNeeded()
  }, [initOnboardingFailed])

  async function tryToStartOnboardingProcess(): Promise<void> {
    setLoading(true)
    const { success } = await authentication.startOnboardingProcess()
    if (!success) {
      setInitOnboardingFailureState(true)
    }
    setLoading(false)
  }
  return { initOnboardingFailed, setInitOnboardingFailureState, reset, loading }
}
