import React from 'react'
import FlexRow from '../../ui/FlexRow'
import { BoxWithPadding } from '../../ui/box/Box'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconClose } from '../../ui/Icons'
import {
  formatFundProfit,
  formatCo2Kilos,
} from '@taaleri/core/src/utils/format'
import { PortfolioDistributions } from '@taaleri/core/src/models/investmentProposal'
import UiButton from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import Title from '../../ui/text/Title'
import { isWeb } from '../../constants/Platforms'
import UiTouchable from '../../ui/UiTouchable'
import EtfComparisonTableContent from './EtfComparisonTableContent'
import Faq, { findFaqs } from '@taaleri/core/src/models/Faq'
import FaqSection from './FaqSection'
import { useTranslation } from 'react-i18next'

interface Props {
  regularEtfDistribution: PortfolioDistributions
  impactEtfDistribution: PortfolioDistributions
  riskLevel: number
  co2Compensation: number
  backRoute: string
}

export function findCompareFaqs(): Faq[] {
  return findFaqs().filter((f) =>
    [
      'faq.impactfaq.what-is-impact',
      'faq.impactfaq.difference',
    ].includes(f.question)
  )
}

export default function EtfComparisonTable({
  regularEtfDistribution,
  impactEtfDistribution,
  riskLevel,
  co2Compensation,
  backRoute,
}: Props) {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <>
      <FlexRow
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Title>{t('questions.portfolios.compare')}</Title>
        {isWeb && (
          <UiTouchable
            onPress={() => {
              navigate(backRoute)
            }}
          >
            <IconClose />
          </UiTouchable>
        )}
      </FlexRow>
      <BoxWithPadding style={{ marginBottom: 40 }}>
        <EtfComparisonTableContent
          riskLevel={riskLevel}
          cost={{
            etfValue: formatFundProfit(
              regularEtfDistribution.totalExpenseRatio
            ),
            impactValue: formatFundProfit(
              impactEtfDistribution.totalExpenseRatio
            ),
          }}
          companyCount={{
            etfValue:
              regularEtfDistribution.totalCompaniesInvestedIn.toFixed(0),
            impactValue:
              impactEtfDistribution.totalCompaniesInvestedIn.toFixed(0),
          }}
          co2={{
            etfValue: '0 kg',
            impactValue: formatCo2Kilos(co2Compensation),
          }}
        />
      </BoxWithPadding>

      <FaqSection
        title={t('faq.more-questions')}
        items={findCompareFaqs()}
      />
      <UiButton
        containerStyle={{ marginTop: Spacings.S24 }}
        type="primaryplain"
        onPress={() => navigate(backRoute)}
        title={t('button.close-comparison')}
      />
    </>
  )
}
