import React from 'react'
import { observer } from 'mobx-react'
import { SumBoxes, SumBoxItem } from '../reportprofit/SumBoxes'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { formatCurrency, sum } from '@taaleri/core/src/utils/format'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

function TotalTransactions() {
  const { t } = useTranslation()
  const { filteredTransactions, transactionCategory } = TransactionStore

  if (filteredTransactions.length === 0) {
    return null
  }

  const invisibleItem: SumBoxItem = {
    value: '',
    color: '',
    title: '',
    hidden: true,
    invisible: true,
  }
  const items: SumBoxItem[] = []

  switch (transactionCategory) {
    case 'Cash':
      const totalAmount = sum(
        filteredTransactions.filter((e) => e.amount).map((f) => f.amount)
      )

      items.push(invisibleItem)
      items.push({
        value: formatCurrency(totalAmount, true),
        title: t('steps.sum'),
        color: Colors.text,
        hidden: totalAmount === 0 && false,
      })
      break
    case 'Open':
      const netOpen = sum(
        filteredTransactions
          .filter((e) => e.totalValue)
          .map((f) => f.totalValue)
      )
      items.push(invisibleItem)
      items.push({
        value: formatCurrency(netOpen, true),
        title: t('reportTransaction.total-value'),
        color: Colors.text,
        hidden: netOpen === 0,
      })
      break
    case 'Paid':
      const totalValue = sum(
        filteredTransactions.filter((e) => e.value).map((f) => f.value)
      )
      const totalFees = sum(
        filteredTransactions.filter((e) => e.fees).map((f) => f.fees)
      )
      const net = sum(
        filteredTransactions
          .filter((e) => e.totalValue)
          .map((f) => f.totalValue)
      )
      items.push({
        value: formatCurrency(totalValue, true),
        title: t('portfolio.total'),
        color: Colors.text,
        hidden: totalValue === 0,
      })
      items.push({
        value: formatCurrency(totalFees, true),
        title: t('reportBox.expenses'),
        color: Colors.text,
        hidden: totalFees === 0,
      })
      items.push({
        value: formatCurrency(net, true),
        title: t('report.net'),
        color: Colors.text,
        hidden: net === 0,
      })
      break
  }

  return <SumBoxes {...{ items }} />
}

export default observer(TotalTransactions)
