import React from 'react'
import { StyleSheet, View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import { observer } from 'mobx-react'
import PercentagePieAsset from '../../../components/PercentagePieAsset'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import FlexRow from '../../../ui/FlexRow'
import { IconArrowRight } from '../../../ui/icons/ArrowIcons'
import { TextMedium, TextBold, TextDefault } from '../../../ui/text/UiText'
import { TextMediumHover } from '../../../ui/text/TextHover'
import {
  AssetClassSecurities,
  InvestmentProposal,
  Security,
} from '@taaleri/core/src/models/investmentProposal'
import RiskInfoButton from './RiskInfoButton'
import { formatPercentageFull } from '@taaleri/core/src/utils/format'
import { selectedPortfolioDistribution } from './selectedPortfolioDistrubution'
import RiskSelector, { RiskLevelIdentifier } from './RiskSelector'
import some from 'lodash/some'
import UiTouchable from '../../../ui/UiTouchable'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacer from '../../../ui/Spacer'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { IconChevronDown } from '../../../ui/icons/ChevronIcons'
import { TFunction } from 'i18next'

interface Props {
  investmentProposal: InvestmentProposal
  hideRiskLevel?: boolean
  impactShare?: number
  singlePie?: boolean
  hideRiskInfo?: boolean
  hideItems?: boolean
  title?: string
  t: TFunction
}

interface State {
  [showDetail: string]: any
}

interface ComparePiesProps {
  currentPercentages: AssetClassSecurities[]
  namePercentages: AssetClassSecurities[]
  t: TFunction
}

const ComparePies: React.FC<ComparePiesProps> = ({
  currentPercentages,
  namePercentages,
  t
}) => {
  const p = 0
  return (
    <FlexRow
      style={{
        alignItems: 'center',
        marginBottom: Spacings.S24,
        justifyContent: 'center',
      }}
    >
      <View style={[styles.pieContainer, { marginRight: Spacings.S24 }]}>
        <PercentagePieAsset
          assetClasses={currentPercentages}
          percentage={p}
          size={100}
          borderWidth={10}
        />
        <TextMedium style={styles.textMedium}>{t('app.ImpactToggleOn.comparebox.currentportfolio')}</TextMedium>
      </View>
      <View
        style={[
          styles.pieContainer,
          { marginRight: Spacings.S24, marginTop: -32 },
        ]}
      >
        <IconArrowRight />
      </View>

      <View style={styles.pieContainer}>
        <PercentagePieAsset
          assetClasses={namePercentages}
          percentage={p}
          size={100}
          borderWidth={10}
        />
        <TextMedium style={styles.textMedium}>{t('app.ImpactToggleOn.comparebox.newportfolio')}</TextMedium>
      </View>
    </FlexRow>
  )
}

@observer
export default class PortfolioDistributionEdit extends React.Component<
  Props,
  State
> {
  state: State = {
    showDetail: {},
  }
  
  render() {
    const {
      investmentProposal,
      hideRiskLevel,
      singlePie,
      hideRiskInfo,
      hideItems,
      title,
      t
    } = this.props
    const riskLevel = QuestionStore.riskLevel
    const investmentProposalRiskLevel = investmentProposal.riskLevel
    const maximumValue = investmentProposal.maxRiskLevel

    const selectedDistrubution = selectedPortfolioDistribution(
      investmentProposal,
      QuestionStore.riskLevel
    )

    const namePercentages: AssetClassSecurities[] =
      selectedDistrubution.securities

    const hasCurrent = some(namePercentages, (n) => n.currentPercentage)
    const showComparePie = hasCurrent && !singlePie

    const currentPercentages = namePercentages.map((a) => {
      return { ...a, percentage: a.currentPercentage || 0 }
    })

    const riskLevels: RiskLevelIdentifier[] = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    ].map((i) => {
      const distribution = investmentProposal.portfolioDistributions.find(
        (a) => a.riskLevel === i
      )
      const strategyIdentifier = distribution
        ? distribution.strategyIdentifier
        : ''

      const maxImpact = distribution ? distribution.impactShare : 0

      return {
        riskLevel: i,
        strategyIdentifier,
        maxImpact,
      }
    })

    const p = 0

    return (
      <View>
        {!hideRiskInfo && <RiskInfoButton />}
        {showComparePie && (
          <ComparePies
            currentPercentages={currentPercentages}
            namePercentages={namePercentages}
            t={t}
          />
        )}
        {!showComparePie && (
          <View style={{ alignItems: 'center', marginBottom: Spacings.S24 }}>
            <PercentagePieAsset
              assetClasses={namePercentages}
              percentage={p}
              size={144}
              borderWidth={16}
            />
            {title && (
              <View
                style={{
                  position: 'absolute',
                  top: -0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TextDefault
                  type="t1"
                  style={{
                    textAlign: 'center',
                    fontSize: 12,
                    letterSpacing: 0.2,
                  }}
                >
                  {title}
                </TextDefault>
              </View>
            )}
          </View>
        )}

        {namePercentages.map((percentage: AssetClassSecurities, index) => {
          const color = assetClassColors[percentage.id]
          const detailOpen = this.state.showDetail[percentage.name]
          // TODO Temporaily hide Impact detail until there is stuff
          const hasSecurities =
            percentage.securities.length > 0 && percentage.id !== 'I'
          const ContainerComponent =
            hideItems || !hasSecurities ? View : UiTouchable
          return (
            <View
              key={index}
              style={[
                {
                  paddingVertical: Spacings.S8,
                },
              ]}
            >
              <ContainerComponent
                onPress={
                  !hideItems && hasSecurities
                    ? () => {
                        const showDetail = this.state.showDetail
                        showDetail[percentage.name] =
                          !showDetail[percentage.name]
                        this.setState({
                          showDetail,
                        })
                      }
                    : () => ''
                }
              >
                <FlexRow
                  key={index}
                  style={{
                    justifyContent: 'space-between',
                    marginBottom: detailOpen ? Spacings.S16 : 0,
                  }}
                >
                  <FlexRow
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 12,
                        backgroundColor: color,
                        borderRadius: 5,
                      }}
                    />
                    <TextMediumHover
                      style={{ lineHeight: 14, fontSize: FontSize.S14 }}
                    >
                      {t(percentage.name)}
                    </TextMediumHover>
                  </FlexRow>
                  <FlexRow
                    style={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {showComparePie &&
                      percentage.currentPercentage !== undefined && (
                        <TextDefault
                          type="t3"
                          color="#B0A9A3"
                          style={{
                            marginRight: Spacings.S8,
                            fontSize: 15,
                            lineHeight: 15,
                          }}
                        >
                          ({formatPercentageFull(percentage.currentPercentage)}{' '}
                          %)
                        </TextDefault>
                      )}
                    <TextBold style={{ fontSize: 15, lineHeight: 15 }}>
                      {formatPercentageFull(percentage.percentage)} %
                    </TextBold>
                    {!hideItems && (
                      <View
                        style={{
                          marginLeft: Spacings.S8,
                          transform: [
                            { rotate: detailOpen ? '180deg' : '0deg' },
                          ],
                        }}
                      >
                        <IconChevronDown
                          color={Colors.text}
                          hidden={!hasSecurities}
                        />
                      </View>
                    )}
                  </FlexRow>
                </FlexRow>
              </ContainerComponent>
              {detailOpen && (
                <View>
                  {percentage.securities.map((security: Security) => {
                    return (
                      <FlexRow
                        key={security.name}
                        style={{
                          justifyContent: 'space-between',
                          flex: 1,
                          flexWrap: 'wrap',
                          marginBottom: 6,
                        }}
                      >
                        <TextDefault type="t3" style={[{ flex: 1 }]}>
                          {security.name}
                        </TextDefault>
                        <TextDefault
                          type="t3"
                          style={[
                            {
                              flexBasis: 50,
                              textAlign: 'right',
                            },
                          ]}
                        >
                          {formatPercentageFull(security.percentage)} %
                        </TextDefault>
                      </FlexRow>
                    )
                  })}
                </View>
              )}
            </View>
          )
        })}

        {!hideRiskLevel && (
          <>
            <Spacer />

            <RiskSelector
              maximumValue={maximumValue}
              value={riskLevel}
              suggestedValue={investmentProposalRiskLevel}
              riskLevels={riskLevels}
              riskTolerance={investmentProposal.investmentProfile.riskTolerance}
            />
          </>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  pieContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  textMedium: {
    marginTop: Spacings.S16,
  },
})
