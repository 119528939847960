import { Identity, Registration } from '../models/Identity'

import {
  getSecureObject,
  setSecureObject,
  clearSecureStore,
  removeSecureObject,
} from './secureStorage'

import AsyncStorage from '@react-native-async-storage/async-storage'
const ONBOARDING_PROCESS_IDENTITY = 'onboardingProcessIdentity'
const FIRST_SIGN_IN_DONE = 'firstSignInDone'
const LOCAL_AUTHENTICATION = 'localAuthentication'
const LOGGED_IN = 'loggedIn'
const LOCAL_SIGNED_IN = 'localSignedIn'
const PUSH_NOTIFICATIONS_TOKEN = 'pushNotificationsToken'
const IDENTITY = 'identity'
const CREDENTIALS = 'credentials'
const VISIT_COUNT = 'visitCountNew'
const FELLOW_NOTICE_DISMISSED = 'fellowNoticeDismissed'

async function getBoolean(key: string): Promise<boolean> {
  const value = await AsyncStorage.getItem(key)
  return value !== null
}

async function setBoolean(key: string): Promise<void> {
  await AsyncStorage.setItem(key, 'true')
}

export async function setFellowNoticeDismissed() {
  return setBoolean(FELLOW_NOTICE_DISMISSED)
}

export async function isFellowNoticeDismissed() {
  return getBoolean(FELLOW_NOTICE_DISMISSED)
}

export const setInsecureObject = async (
  key: string,
  value: any
): Promise<void> => {
  await AsyncStorage.setItem(key, JSON.stringify(value))
}

export async function getInsecureObject<T>(key: string): Promise<T> {
  const value = await AsyncStorage.getItem(key)
  return value ? JSON.parse(value) : undefined
}

export const getLoggedIn = async (): Promise<boolean> => {
  const value = await AsyncStorage.getItem(LOGGED_IN)
  return value !== null
}

export const setLoggedIn = async (): Promise<void> => {
  await AsyncStorage.setItem(LOGGED_IN, 'true')
}

export const setVisitCount = async (count: number): Promise<void> => {
  await AsyncStorage.setItem(VISIT_COUNT, JSON.stringify(count))
}

export const getVisitCount = async (): Promise<number> => {
  const value = await AsyncStorage.getItem(VISIT_COUNT)
  return value !== null ? Number(value) : 1
}

export const removeLoggedIn = async (): Promise<void> => {
  await AsyncStorage.removeItem(LOGGED_IN)
}

export const setLocalSignedIn = async (): Promise<void> => {
  await AsyncStorage.setItem(LOCAL_SIGNED_IN, 'true')
}

export const isLocalSignedIn = async (): Promise<boolean> => {
  const value = await AsyncStorage.getItem(LOCAL_SIGNED_IN)
  return value !== null
}

export const removeLocalSignedIn = async (): Promise<void> => {
  await AsyncStorage.removeItem(LOCAL_SIGNED_IN)
}

export const setLocalAuthentication = async (): Promise<void> => {
  await AsyncStorage.setItem(LOCAL_AUTHENTICATION, 'enabled')
}

export const getLocalAuthentication = async (): Promise<boolean> => {
  const value = await AsyncStorage.getItem(LOCAL_AUTHENTICATION)
  return value !== null
}

export const removeLocalAuthentication = async (): Promise<void> => {
  await AsyncStorage.removeItem(LOCAL_AUTHENTICATION)
}

export const setFirstSignInDone = async (): Promise<void> => {
  await AsyncStorage.setItem(FIRST_SIGN_IN_DONE, 'true')
}

export const getFirstSignInDone = async (): Promise<boolean> => {
  const value = await AsyncStorage.getItem(FIRST_SIGN_IN_DONE)
  return value !== null
}

export const removeFirstSignInDone = async (): Promise<void> => {
  await AsyncStorage.removeItem(FIRST_SIGN_IN_DONE)
}

export const setPushNotificationToken = async (
  token: string
): Promise<void> => {
  await AsyncStorage.setItem(PUSH_NOTIFICATIONS_TOKEN, token)
}

export const getPushNotificationToken = async (): Promise<string> => {
  const value = await AsyncStorage.getItem(PUSH_NOTIFICATIONS_TOKEN)
  return value || ''
}

export const getOnboardingProcessIdentity = async (): Promise<string> => {
  const onboardingProcessIdentity: string = await getSecureObject(
    ONBOARDING_PROCESS_IDENTITY
  )
  return onboardingProcessIdentity
}

export const setOnboardingProcessIdentity = async (
  onboardingProcessIdentity: string
): Promise<void> => {
  await setSecureObject(ONBOARDING_PROCESS_IDENTITY, onboardingProcessIdentity)
}

export const getIdentity = async (): Promise<Identity> => {
  const identity: Identity = await getSecureObject(IDENTITY)
  return identity
}

export const setIdentity = async (identity: Identity): Promise<void> => {
  await setSecureObject(IDENTITY, identity)
}

export const removeIdentity = async (): Promise<void> => {
  await removeSecureObject(IDENTITY)
}

export const getCredentials = async (): Promise<Registration> => {
  const registration: Registration = await getSecureObject(CREDENTIALS)
  return registration
}

export const setCredentials = async (
  registration: Registration
): Promise<void> => {
  await setSecureObject(CREDENTIALS, registration)
}

export const clearStorage = async (): Promise<void> => {
  try {
    await AsyncStorage.clear()
    await clearSecureStore()
  } catch (e) {
    // Do Nothing
  }
}
