import React from 'react'
import logger from '@taaleri/core/src/utils/logger'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'

interface ErrorBoundaryState {
  hasError: boolean
}

function isChunkLoadError(error: any) {
  return error?.name === 'ChunkLoadError'
}

function isFirstRefresh() {
  return !window.location.href.endsWith('#refresh')
}

export default class ErrorBoundary extends React.Component<
  object,
  ErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  state = {
    hasError: false,
  }

  retry = () => {
    this.setState({ hasError: false })
    window.location.reload()
  }

  /* 
  VR 21.4.2021: ChunkLoadError might occur, when using code splitting. 
  Usually this can be fixed simply by refreshing the page. More info:
  https://imrecsige.dev/garden/how-to-solve-missing-chunk-code-splitting-errors-after-deploy/
  */
  recoverFromError(error: any): boolean {
    // Try to refresh, if ChunkLoadError and refresh has not been tried yet
    if (isChunkLoadError(error) && isFirstRefresh()) {
      logger.info('ChunkLoadError caught by error boundary, retrying...')
      window.location.href = window.location.href + '#refresh'
      this.retry()
      return true
    }
    return false
  }

  componentDidCatch(error: any) {
    const hasRecovered = this.recoverFromError(error)
    if (!hasRecovered) {
      logger.error(error, 'error boundary')
      this.setState({ hasError: true })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <View style={{ marginTop: Spacings.S40 }} />
          <ContentContainerBox>
            <ErrorWithRetry onRetry={this.retry} />
          </ContentContainerBox>
        </>
      )
    }

    return this.props.children
  }
}
