import React from 'react'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import { useHistory } from 'react-router-dom'
import TwoFactorToggleVerification from '@taaleri/components/src/screens/profile/TwoFactorToggleVerification'

export default function TwoFactorToggleVerificationScreen() {
  const history = useHistory()
  return (
    <BackgroundContainerApp>
      <TwoFactorToggleVerification navigate={history.push} />
    </BackgroundContainerApp>
  )
}
