import React from 'react'
import { Transaction } from '@taaleri/core/src/models/TransactionReport'
import { TextDefault } from '../../../ui/text/UiText'
import {
  formatCurrency,
  formatDateWithoutLeadingZeros,
} from '@taaleri/core/src/utils/format'
import TransactionRowContainer from './TransactionRowContainer'
import Spacings from '@taaleri/core/src/constants/Spacings'
import TransactionPdfButton from '../TransactionPdfButton'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  transaction: Transaction
}

function PaidTransactionRow({ transaction }: Props) {
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.securityNameFinnish
      : transaction.securityNameSwedish

  const getTransactionTypeName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.transactionTypeName
      : transaction.transactionTypeNameSwedish

  const getPortfolioName = (): string =>
    (AppStore.currentLanguage === fiKey
      ? transaction?.portfolio?.portfolioNameForCustomer
      : transaction?.portfolio?.portfolioNameForCustomerSv) ?? ''

  return (
    <TransactionRowContainer>
      <TextDefault
        type="h7"
        style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
      >
        {getPortfolioName()}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {getTransactionTypeName()}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 2, paddingHorizontal: Spacings.S2 }}
      >
        {getName()}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {formatDateWithoutLeadingZeros(transaction.transactionDate)}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {transaction.amount}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {formatCurrency(transaction.price?.reportCurrency?.value || 0, true)}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {formatCurrency(transaction.value, true)}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {formatCurrency(transaction.fees, true)}
      </TextDefault>
      <TextDefault
        type="t3"
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      >
        {formatCurrency(transaction.totalValue, true)}
      </TextDefault>
      <TransactionPdfButton
        transaction={transaction}
        style={{ flex: 1, paddingHorizontal: Spacings.S2 }}
      />
    </TransactionRowContainer>
  )
}

export default PaidTransactionRow
