import React from 'react'
import TwoFactorToggleDoneContent from '@taaleri/components/src/screens/profile/TwoFactorToggleDoneContent'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import { useHistory } from 'react-router-dom'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import { ROUTE_PROFILE } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import { useTranslation } from 'react-i18next'

export function TwoFactorToggleDoneScreen() {
  const { t } = useTranslation()
  const { push } = useHistory()
  return (
    <BackgroundContainerApp>
      <TopBottomContainer>
        <TwoFactorToggleDoneContent />
        <Buttons
          title={t('button.continue')}
          onPress={() => push(ROUTE_PROFILE)}
        />
      </TopBottomContainer>
    </BackgroundContainerApp>
  )
}
