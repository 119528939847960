import React from 'react'
import PhotoIdContent from 'screens/onboarding/PhotoIdContent'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_IDENTITY_CARD_VALIDITY } from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'

export default function IdentityPhotoIdScreen() {
  return (
    <BackgroundContainerApp>
      <PhotoIdContent
        nextRoute={routePathApp(ROUTE_IDENTITY_CARD_VALIDITY)}
        type="contract"
      />
    </BackgroundContainerApp>
  )
}
