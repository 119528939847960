import React from 'react'
import { observer } from 'mobx-react'
import Title from '../../ui/text/Title'
import range from 'lodash/range'
import { TextMedium } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import { StyleSheet } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { Paragraph } from '../../ui/text/Paragraph'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useLayout from '../../ui/useLayout'
import UiTouchable from '../../ui/UiTouchable'
import { useTranslation } from 'react-i18next'

function DueDateContent() {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const size = isSmall ? 32 : 48
  const days = range(1, 29)
  const paymentDay = AppStore.paymentDay
  let row: React.ReactElement[] = []
  return (
    <>
      <Title>{t('invest.debit-date')}</Title>
      <Paragraph>
        {t('invest.debit-date-question')}
      </Paragraph>

      {days.map((day) => {
        const isPaymentDay = paymentDay === day
        const itemStyle = isPaymentDay ? styles.chosenItem : styles.item
        const color = isPaymentDay ? Colors.white : Colors.text
        row.push(
          <UiTouchable
            onPress={() => (AppStore.paymentDay = day)}
            style={[itemStyle, { height: size, width: size }]}
            key={day}
          >
            <TextMedium style={{ color }}>{day}</TextMedium>
          </UiTouchable>
        )
        if (day % 7 === 0) {
          const rowItem = (
            <FlexRow style={styles.container} key={day / 7}>
              {row}
            </FlexRow>
          )
          row = []
          return rowItem
        }
      })}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  item: {
    margin: Spacings.S4,
    borderRadius: 24,
    borderColor: Colors.border,
    borderWidth: 1,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chosenItem: {
    margin: Spacings.S4,
    borderRadius: 24,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default observer(React.forwardRef(DueDateContent))
