import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Box from '../../ui/box/Box'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import OpenTransactionRow from './Rows/OpenTransactionRow'
import CashTransactionRow from './Rows/CashTransactionRow'
import PaidTransactionRow from './Rows/PaidTransactionRow'
import OpenTransactionHeader from './Headers/OpenTransactionHeader'
import CashTransactionHeader from './Headers/CashTransactionHeader'
import PaidTransactionHeader from './Headers/PaidTransactionHeader'
import UiButton from '../../ui/UiButton'
import useLayout from '../../ui/useLayout'
import TransactionHeaderMobile from './Headers/TransactionHeaderMobile'
import PaidTransactionRowMobile from './Rows/PaidTransactionRowMobile'
import OpenTransactionRowMobile from './Rows/OpenTransactionRowMobile'
import CashTransactionRowMobile from './Rows/CashTransactionRowMobile'
import { TextDefault } from '../../ui/text/UiText'
import { useTranslation } from 'react-i18next'

function TransactionsList() {
  const { t } = useTranslation()
  const [page, setPage] = useState<number>(1)
  const { isWide } = useLayout()
  const rowsPerPage = 20

  const {
    selectedInstruments,
    selectedTransactionTypes,
    selectedPortfolios,
    selectedReasons,
    transactionCategory,
    filteredTransactions,
  } = TransactionStore

  useEffect(() => {
    setPage(1)
  }, [
    transactionCategory,
    selectedInstruments.length,
    selectedTransactionTypes.length,
    selectedPortfolios.length,
    selectedReasons.length,
  ])

  let RowComponent: Element

  if (isWide) {
    RowComponent =
      transactionCategory === 'Paid'
        ? PaidTransactionRow
        : transactionCategory === 'Open'
        ? OpenTransactionRow
        : CashTransactionRow
  } else {
    RowComponent =
      transactionCategory === 'Paid'
        ? PaidTransactionRowMobile
        : transactionCategory === 'Open'
        ? OpenTransactionRowMobile
        : CashTransactionRowMobile
  }

  const HeaderComponent = isWide
    ? transactionCategory === 'Paid'
      ? PaidTransactionHeader
      : transactionCategory === 'Open'
      ? OpenTransactionHeader
      : CashTransactionHeader
    : TransactionHeaderMobile

  const allRows = filteredTransactions.length
  const showedRows = Math.min(page * rowsPerPage, allRows)

  const noTransactions = allRows === 0

  return (
    <View style={{ marginTop: Spacings.S40, zIndex: 0 }}>
      <HeaderComponent />

      <Box>
        {noTransactions ? (
          <TextDefault type="h5" style={{ flex: 2, padding: Spacings.S20 }}>
            {t('reportBox.no-events')}
          </TextDefault>
        ) : (
          filteredTransactions
            .slice(0, page * rowsPerPage)
            .map((transaction) => (
              <RowComponent
                transaction={transaction}
                isWide={isWide}
                key={transaction.transactionId}
              />
            ))
        )}
      </Box>
      <View style={{ alignItems: 'center' }}>
        <TextDefault
          type="t2"
          style={{ marginTop: Spacings.S24 }}
        >{`${t('report.showing')} ${showedRows}/${allRows}`}</TextDefault>
        {allRows > showedRows && (
          <UiButton
            title={t('button.load-more')}
            onPress={() => setPage(page + 1)}
            containerStyle={{
              marginTop: Spacings.S24,
              width: '100%',
              maxWidth: 327,
            }}
          />
        )}
      </View>
    </View>
  )
}

export default observer(TransactionsList)
