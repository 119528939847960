export const ROUTE_FUNDS_INTRO = 'FundsIntro'
export const ROUTE_FUNDS_DETAIL = 'FundDetail'
export const ROUTE_FUNDS_INVEST_START = 'FundInvestStart'
export const ROUTE_FUNDS_INVEST_START_MONTHLY = 'FundInvestStartMonthly'
export const ROUTE_FUNDS_INVEST_DISTRIBUTION = 'FundInvestDistribution'
export const ROUTE_FUNDS_INVEST_DISTRIBUTION_WITHDRAW =
  'FundInvestDistributionWithdraw'
export const ROUTE_DUE_DATE = 'FundInvestDueDate'
export const ROUTE_MONTHLY_CONTRACT = 'MonthlyContract'
export const ROUTE_MONTHLY_CONTRACT_VERIFICATION = 'MonthlyContractVerification'
export const ROUTE_MONTHLY_CONTRACT_PAYMENT = 'MonthlyContractPayment'
export const ROUTE_BLOCKED_CONS_CUSTOMER = 'BlockedCustomer'
