import React, { Component } from 'react'

import { Paragraph } from '../../ui/text/Paragraph'
import Title from '../../ui/text/Title'

import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { CONFLICT } from '@taaleri/core/src/models/ServiceResponse'
import {
  ActivityIndicator,
  BackHandler,
  Dimensions,
  StyleSheet,
  View,
} from 'react-native'
import ConfirmationCodeInput from '../../components/ConfimationCodeInput'
import { authentication } from '@taaleri/core/src/services/authentication'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { TextMedium } from '../../ui/text/UiText'
import UiTouchable from '../../ui/UiTouchable'
import { ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL } from '../portfolio/PortfolioRoutes'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_TWO_FACTOR_AUTH_FAILED } from '../../navigation/routes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { TFunction } from 'i18next'

const VERIFICATION_CODE_LENGTH = 4

interface SmsVerificationState {
  preparingVerification: boolean
  errorPreparingVerification: boolean
  smsConfirmationId: string | null
  verificationCode: string
  isCodeChecked: boolean
  isCheckingCode: boolean
  isSendingAnotherSms: boolean
  isSendingAnotherSmsDone: boolean
  errorCheckingCode: boolean
  showSmsResendButton: boolean
}

interface SmsVerificationProps {
  title: string
  description: string
  onPrepareVerification: () => Promise<string | null>
  onSuccess: () => void
  afterConflictNavigateTo: string
  phone?: string
  navigate: (path: string, params?: any) => void
  isTwoFactor?: boolean
  t: TFunction
}

class SmsVerification extends Component<
  SmsVerificationProps,
  SmsVerificationState
> {
  state: SmsVerificationState = {
    preparingVerification: false,
    errorPreparingVerification: false,
    isCheckingCode: false,
    smsConfirmationId: null,
    verificationCode: '',
    isCodeChecked: false,
    isSendingAnotherSms: false,
    isSendingAnotherSmsDone: false,
    errorCheckingCode: false,
    showSmsResendButton: false,
  }

  resendButtonTimeout: any
  clearVerificationInput: any

  async componentDidMount() {
    await this.prepareVerification()

    BackHandler.addEventListener('hardwareBackPress', () => {
      return true
    })
  }

  componentWillUnmount() {
    clearTimeout(this.resendButtonTimeout)
    clearTimeout(this.clearVerificationInput)
    BackHandler.removeEventListener('hardwareBackPress', () => {
      return true
    })
  }

  async prepareVerification() {
    this.setState({ preparingVerification: true })

    const smsConfirmationId = await this.props.onPrepareVerification()

    if (smsConfirmationId) {
      this.setState({
        smsConfirmationId,
        preparingVerification: false,
      })
      if (!this.props.isTwoFactor) {
        await authentication.sendVerificationSms(smsConfirmationId)
      }
      this.showSmsResendButtonAfterTimeout()
    } else {
      this.setState({
        preparingVerification: false,
        errorPreparingVerification: true,
      })
    }
  }

  async trySmsAgain() {
    const { smsConfirmationId } = this.state
    if (smsConfirmationId) {
      this.setState({ isSendingAnotherSms: true, showSmsResendButton: false })
      await authentication.sendVerificationSms(smsConfirmationId)
      this.showSmsResendButtonAfterTimeout()
      this.setState({
        isSendingAnotherSms: false,
        isSendingAnotherSmsDone: true,
      })
    }
  }

  showSmsResendButtonAfterTimeout() {
    this.resendButtonTimeout = setTimeout(
      () =>
        this.props.isTwoFactor || this.setState({ showSmsResendButton: true }),
      16000
    )
  }

  clearVerificationInputAfterTimeout() {
    this.clearVerificationInput = setTimeout(() => {
      this.setState({ verificationCode: '' })
    }, 700)
  }

  onInputChange = async (text: string) => {
    this.setState({ verificationCode: text, isCodeChecked: false })

    const { smsConfirmationId } = this.state
    const verificationCodeValid =
      text.toString().length === VERIFICATION_CODE_LENGTH

    if (smsConfirmationId && verificationCodeValid) {
      this.setState({ isCheckingCode: true })
      try {
        const { success, error } = await authentication.checkVerificationCode(
          smsConfirmationId,
          text,
          this.props.isTwoFactor
        )

        if (success) {
          if (this.props.onSuccess) {
            AppStore.smsConfirmationId = undefined
            this.props.onSuccess()
          }
          return
        }

        if (error === CONFLICT) {
          if (this.props.afterConflictNavigateTo) {
            const redirectRoute = this.props.isTwoFactor
              ? ROUTE_TWO_FACTOR_AUTH_FAILED
              : ROUTE_PORTFOLIO_VERIFICATION_UNSUCCESSFULL
            this.props.navigate(redirectRoute, {
              title: this.props.title,
              navigateTo: this.props.afterConflictNavigateTo,
            })
          }
          return
        }

        this.setState({
          isCheckingCode: false,
          isCodeChecked: true,
          errorCheckingCode: true,
        })
        this.clearVerificationInputAfterTimeout()
      } catch (e) {
        this.setState({ isCheckingCode: false })
      }
    }
  }

  render() {
    const { phone } = this.props
    const {
      verificationCode,
      isCodeChecked,
      isCheckingCode,
      isSendingAnotherSms,
      isSendingAnotherSmsDone,
      errorCheckingCode,
      preparingVerification,
      errorPreparingVerification,
      showSmsResendButton,
    } = this.state

    const showLoadingIndicator = isSendingAnotherSms || isCheckingCode
    const { width, height } = Dimensions.get('window')

    if (preparingVerification) {
      return (
        <View
          style={[
            !isWeb && {
              position: 'absolute',
              top: height / 2 - 100,
              left: width / 2 - 50,
              height: 100,
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}
        >
          <LoadingIndicator />
        </View>
      )
    }

    if (errorPreparingVerification) {
      return <ErrorWithRetry onRetry={async () => this.prepareVerification()} />
    }

    return (
      <View>
        <Title>{this.props.title}</Title>
        <Paragraph style={{ marginBottom: 0 }}>
          {this.props.description}
        </Paragraph>

        {showLoadingIndicator && (
          <ActivityIndicator
            size="large"
            style={{ marginVertical: Spacings.S16 }}
          />
        )}
        {!showLoadingIndicator && (
          <View style={{ height: 36, marginVertical: Spacings.S16 }} />
        )}

        <ConfirmationCodeInput
          value={verificationCode}
          onTextChange={this.onInputChange}
          onFulfill={() => ''}
          error={
            isCodeChecked &&
            verificationCode.length === VERIFICATION_CODE_LENGTH
          }
        />
        {!errorCheckingCode && isSendingAnotherSmsDone && (
          <TextMedium style={styles.anotherSmsResult}>
            {phone
              ? this.props.t('invest.new-confirmation-number', { phone: phone })
              : this.props.t('invest.new-confirmation')}
          </TextMedium>
        )}
        {errorCheckingCode && (
          <TextMedium
            style={styles.errorCheckingCode}
          >{this.props.t('validation.wrong-code')}</TextMedium>
        )}
        {showSmsResendButton && !showLoadingIndicator && (
          <UiTouchable
            style={{ marginTop: Spacings.S24 }}
            onPress={() => this.trySmsAgain()}
          >
            <TextMedium style={styles.anotherSmsLink}>
              {this.props.t('invest.send-new-code')}
            </TextMedium>
          </UiTouchable>
        )}
        {this.props.isTwoFactor || (
          <>
            <TextMedium style={{ marginTop: Spacings.S56 }}>
              {this.props.t('invest.change-phone')}
            </TextMedium>
          </>
        )}
      </View>
    )
  }
}

export default SmsVerification

const styles = StyleSheet.create({
  anotherSmsResult: {
    textAlign: 'center',
    marginTop: Spacings.S24,
  },
  anotherSmsLink: {
    color: Colors.primary,
    textAlign: 'center',
  },
  errorCheckingCode: {
    color: Colors.error,
    textAlign: 'center',
    marginTop: Spacings.S24,
  },
})
