import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import UiButton from '../../ui/UiButton'
import {
  getWithdrawalRoute,
  startWithdraw,
  startInvest,
  investStartRoute,
} from './PortfolioRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useNavigation from '../../navigation/useNavigation'
import { useTranslation } from 'react-i18next'

interface Props {
  style?: StyleProp<ViewStyle>
}

export default function PortfolioActions({ style }: Props) {
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const isConsultative = AppStore.isConsultative
  if (!AppStore.showPortfolioActions && !isConsultative) {
    return null
  }

  return (
    <View
      style={[
        {
          marginTop: Spacings.S16,
          marginBottom: Spacings.S24,
          paddingBottom: Spacings.S40,
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        },
        style,
      ]}
    >
      {AppStore.showWithdrawAction ? (
        <UiButton
          containerStyle={{ marginTop: Spacings.S24 }}
          type="secondary"
          onPress={() => {
            Analytics.event('Payment', 'Withdrawal click')
            startWithdraw()
            navigate(getWithdrawalRoute())
          }}
          title={`${t('button.withdraw')}`}
        />
      ) : null}
    </View>
  )
}
