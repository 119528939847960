import {
  loadBasicInfo,
  loadForcedKnowledge,
} from '@taaleri/core/src/services/contracts'
import { customers } from '@taaleri/core/src/services/customers'
import {
  getCredentials,
  getIdentity,
  setIdentity,
} from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'

import { isWeb } from '../../constants/Platforms'
import { ROUTE_APP, ROUTE_TWO_FACTOR } from '../../navigation/routes'
import {
  ROUTE_CONTRACT_START_EVERVEST,
  ROUTE_CONTRACT_FORCE_START_CONSULTATIVE,
} from '../contract/ContractRoutes'

export default async function signInRoute() {
  if (AppStore.isTwoFactor) {
    return ROUTE_TWO_FACTOR
  } else if (AppStore.isNewCustomerWithoutContract) {
    if (AppStore.customer.phone) {
      await loadBasicInfo()
      await loadForcedKnowledge()
    }

    const identity = await getIdentity()
    const { success } = await customers.getOnboardingState()
    if (success) {
      await setIdentity(identity)
    }
    if (!isWeb) {
      const { email } = await getCredentials()
      QuestionStore.email = email
    }
    QuestionStore.tupasDone = true
    const startRoute = ROUTE_APP
    return startRoute
  } else if (AppStore.isEvervestCustomerWithoutContract && AppStore.isPerson) {
    AppStore.showContractBox = true
    return ROUTE_CONTRACT_START_EVERVEST
  } else if (AppStore.forceConsultativeConversion) {
    return ROUTE_CONTRACT_FORCE_START_CONSULTATIVE
  } else {
    const route = AppStore.routeAfterLogin || ROUTE_APP
    AppStore.routeAfterLogin = undefined
    return route
  }
}
