import Title from '@taaleri/components/src/ui/text/Title'
import useLayout from '@taaleri/components/src/ui/useLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NotFoundContent() {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  return (
    <Title
      style={{
        justifyContent: 'center',
        marginTop: isSmall ? 120 : 0,
      }}
    >
      {t('not-found')}
    </Title>
  )
}
