import { LoggerLevel, Logger } from './logger'
import * as Sentry from '@sentry/browser'
import { getConfig } from '../config'
import { isLocal } from '../environment'
import versionNumber from '@taaleri/components/src/screens/profile/versionNumber'

let AppStore: any = null

const useSentryInDev = getConfig().useSentryDevMode

export const loggerInit = (appStore: any) => {
  AppStore = appStore

  if (useSentryInDev || !isLocal) {
    return
  }
}

const logger: Logger = {
  /** Outputs log entry as console.log only in development environment */
  devInfo: (message: string, params?: any) => {
    if (isLocal) {
      if (params) {
        console.log(message, params)
      } else {
        console.log(message)
      }
    }
  },
  /** Outputs log entry as console.log in development environment and on other environments pushes it to Sentry */
  info: (message: string) => {
    if (useSentryInDev || !isLocal) {
      // Sentry.captureMessage(message)
    } else {
      console.log(message)
    }
  },
  /** Outputs log entry as console.log in development environment and on other environments pushes it to Sentry */
  error: (error: any, title: string, level: LoggerLevel = 'error') => {
    if (useSentryInDev || !isLocal) {
      const customer = AppStore.customerMaybe
      const activeCustomer = AppStore.activeCustomerMaybe
      const isApiError = error && error.codeName === 'ApiError'
      const errorParams = isApiError ? { ...error } : { errorMessage: error }
      const customerId = customer ? customer.customerId : 'not-known'
      const activeCustomerId = activeCustomer
        ? activeCustomer.customerId
        : 'not-known'

      const { environment } = getConfig()

      /* Sentry.withScope((scope: Sentry.Scope) => {
        scope.addEventProcessor(
          (event) =>
            new Promise((resolve) =>
              resolve({
                ...event,
                environment,
              })
            )
        )
        scope.setLevel(
          level === 'fatal' ? Sentry.Severity.Fatal : Sentry.Severity.Error
        )
        scope.setTags({
          environment,
          title,
          customerId,
          activeCustomerId,
          versionNumber,
        })
        scope.setExtras(errorParams)
        Sentry.captureException(
          error instanceof Error ? error : new Error(error)
        )
      }) */
    } else {
      console.log(error)
    }
  },
}

export default logger
