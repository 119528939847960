import React from 'react'
import { observer } from 'mobx-react'
import PurchaseLotDetail from './PurchaseLotDetail'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { PurchaseLotDetailMobile } from '@taaleri/components/src/screens/portfolio/PurchaseLotsContent'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { PurchaseLot } from '@taaleri/core/src/models/Portfolio'

interface Props {
  purchaseLots: PurchaseLot[]
  loading: boolean
  color: string
}

function PurchaseLotsReport({ purchaseLots, loading, color }: Props) {
  const { isSmall } = useLayout()

  if (!isSmall) {
    return (
      <PurchaseLotDetail
        {...{ purchaseLots, loading, color }}
        style={{ marginBottom: Spacings.S32, marginTop: Spacings.S16 }}
      />
    )
  }
  return <PurchaseLotDetailMobile {...{ purchaseLots }} />
}

export default observer(PurchaseLotsReport)
