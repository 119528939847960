import groupBy from 'lodash/groupBy'

export interface Country {
  code: string
  name: string
}

export const FINLAND = { code: 'FI', name: 'countryCode.FI' }
const SWEDEN = { code: 'SE', name: 'countryCode.SE' }
const ESTONIA = { code: 'EE', name: 'countryCode.EE' }
const RUSSIA = { code: 'RU', name: 'countryCode.RU' }
export const USA = { code: 'US', name: 'countryCode.US' }

const Countries: Country[] = [
  { code: 'AA', name: 'countryCode.AA' },
  { code: 'AD', name: 'countryCode.AD' },
  { code: 'AE', name: 'countryCode.AE' },
  { code: 'AF', name: 'countryCode.AF' },
  { code: 'AG', name: 'countryCode.AG' },
  { code: 'AI', name: 'countryCode.AI' },
  { code: 'AL', name: 'countryCode.AL' },
  { code: 'AM', name: 'countryCode.AM' },
  { code: 'AN', name: 'countryCode.AN' },
  { code: 'AO', name: 'countryCode.AO' },
  { code: 'AQ', name: 'countryCode.AQ' },
  { code: 'AR', name: 'countryCode.AR' },
  { code: 'AS', name: 'countryCode.AS' },
  { code: 'AT', name: 'countryCode.AT' },
  { code: 'AU', name: 'countryCode.AU' },
  { code: 'AW', name: 'countryCode.AW' },
  { code: 'AX', name: 'countryCode.AX' },
  { code: 'AZ', name: 'countryCode.AZ' },
  { code: 'BA', name: 'countryCode.BA' },
  { code: 'BB', name: 'countryCode.BB' },
  { code: 'BD', name: 'countryCode.BD' },
  { code: 'BE', name: 'countryCode.BE' },
  { code: 'BF', name: 'countryCode.BF' },
  { code: 'BG', name: 'countryCode.BG' },
  { code: 'BH', name: 'countryCode.BH' },
  { code: 'BI', name: 'countryCode.BI' },
  { code: 'BJ', name: 'countryCode.BJ' },
  { code: 'BM', name: 'countryCode.BM' },
  { code: 'BN', name: 'countryCode.BN' },
  { code: 'BO', name: 'countryCode.BO' },
  { code: 'BR', name: 'countryCode.BR' },
  { code: 'BS', name: 'countryCode.BS' },
  { code: 'BT', name: 'countryCode.BT' },
  { code: 'BV', name: 'countryCode.BV' },
  { code: 'BW', name: 'countryCode.BW' },
  { code: 'BY', name: 'countryCode.BY' },
  { code: 'BZ', name: 'countryCode.BZ' },
  { code: 'CA', name: 'countryCode.CA' },
  { code: 'CC', name: 'countryCode.CC' },
  { code: 'CF', name: 'countryCode.CF' },
  { code: 'CG', name: 'countryCode.CG' },
  { code: 'CH', name: 'countryCode.CH' },
  { code: 'CI', name: 'countryCode.CI' },
  { code: 'CK', name: 'countryCode.CK' },
  { code: 'CL', name: 'countryCode.CL' },
  { code: 'CM', name: 'countryCode.CM' },
  { code: 'CN', name: 'countryCode.CN' },
  { code: 'CO', name: 'countryCode.CO' },
  { code: 'CR', name: 'countryCode.CR' },
  { code: 'CU', name: 'countryCode.CU' },
  { code: 'CV', name: 'countryCode.CV' },
  { code: 'CW', name: 'countryCode.CW' },
  { code: 'CX', name: 'countryCode.CX' },
  { code: 'CY', name: 'countryCode.CY' },
  { code: 'CZ', name: 'countryCode.CZ' },
  { code: 'DE', name: 'countryCode.DE' },
  { code: 'DJ', name: 'countryCode.DJ' },
  { code: 'DK', name: 'countryCode.DK' },
  { code: 'DM', name: 'countryCode.DM' },
  { code: 'DO', name: 'countryCode.DO' },
  { code: 'DZ', name: 'countryCode.DZ' },
  { code: 'EC', name: 'countryCode.EC' },
  { code: 'EE', name: 'countryCode.EE' },
  { code: 'EG', name: 'countryCode.EG' },
  { code: 'EH', name: 'countryCode.EH' },
  { code: 'ER', name: 'countryCode.ER' },
  { code: 'ES', name: 'countryCode.ES' },
  { code: 'ET', name: 'countryCode.ET' },
  { code: 'EU', name: 'countryCode.EU' },
  { code: 'FI', name: 'countryCode.FI' },
  { code: 'FJ', name: 'countryCode.FJ' },
  { code: 'FK', name: 'countryCode.FK' },
  { code: 'FL', name: 'countryCode.FL' },
  { code: 'FM', name: 'countryCode.FM' },
  { code: 'FO', name: 'countryCode.FO' },
  { code: 'FR', name: 'countryCode.FR' },
  { code: 'GA', name: 'countryCode.GA' },
  { code: 'GB', name: 'countryCode.GB' },
  { code: 'GD', name: 'countryCode.GD' },
  { code: 'GE', name: 'countryCode.GE' },
  { code: 'GF', name: 'countryCode.GF' },
  { code: 'GG', name: 'countryCode.GG' },
  { code: 'GH', name: 'countryCode.GH' },
  { code: 'GI', name: 'countryCode.GI' },
  { code: 'GL', name: 'countryCode.GL' },
  { code: 'GM', name: 'countryCode.GM' },
  { code: 'GN', name: 'countryCode.GN' },
  { code: 'GP', name: 'countryCode.GP' },
  { code: 'GQ', name: 'countryCode.GQ' },
  { code: 'GR', name: 'countryCode.GR' },
  { code: 'GS', name: 'countryCode.GS' },
  { code: 'GT', name: 'countryCode.GT' },
  { code: 'GU', name: 'countryCode.GU' },
  { code: 'GW', name: 'countryCode.GW' },
  { code: 'GY', name: 'countryCode.GY' },
  { code: 'HK', name: 'countryCode.HK' },
  { code: 'HM', name: 'countryCode.HM' },
  { code: 'HN', name: 'countryCode.HN' },
  { code: 'HR', name: 'countryCode.HR' },
  { code: 'HT', name: 'countryCode.HT' },
  { code: 'HU', name: 'countryCode.HU' },
  { code: 'ID', name: 'countryCode.ID' },
  { code: 'IE', name: 'countryCode.IE' },
  { code: 'IL', name: 'countryCode.IL' },
  { code: 'IM', name: 'countryCode.IM' },
  { code: 'IN', name: 'countryCode.IN' },
  { code: 'IQ', name: 'countryCode.IQ' },
  { code: 'IR', name: 'countryCode.IR' },
  { code: 'IS', name: 'countryCode.IS' },
  { code: 'IT', name: 'countryCode.IT' },
  { code: 'JE', name: 'countryCode.JE' },
  { code: 'JM', name: 'countryCode.JM' },
  { code: 'JO', name: 'countryCode.JO' },
  { code: 'JP', name: 'countryCode.JP' },
  { code: 'KE', name: 'countryCode.KE' },
  { code: 'KG', name: 'countryCode.KG' },
  { code: 'KH', name: 'countryCode.KH' },
  { code: 'KI', name: 'countryCode.KI' },
  { code: 'KM', name: 'countryCode.KM' },
  { code: 'KN', name: 'countryCode.KN' },
  { code: 'KP', name: 'countryCode.KP' },
  { code: 'KR', name: 'countryCode.KR' },
  { code: 'KV', name: 'countryCode.KV' },
  { code: 'KW', name: 'countryCode.KW' },
  { code: 'KY', name: 'countryCode.KY' },
  { code: 'KZ', name: 'countryCode.KZ' },
  { code: 'LA', name: 'countryCode.LA' },
  { code: 'LB', name: 'countryCode.LB' },
  { code: 'LC', name: 'countryCode.LC' },
  { code: 'LI', name: 'countryCode.LI' },
  { code: 'LK', name: 'countryCode.LK' },
  { code: 'LR', name: 'countryCode.LR' },
  { code: 'LS', name: 'countryCode.LS' },
  { code: 'LT', name: 'countryCode.LT' },
  { code: 'LU', name: 'countryCode.LU' },
  { code: 'LV', name: 'countryCode.LV' },
  { code: 'LY', name: 'countryCode.LY' },
  { code: 'MA', name: 'countryCode.MA' },
  { code: 'MC', name: 'countryCode.MC' },
  { code: 'MD', name: 'countryCode.MD' },
  { code: 'ME', name: 'countryCode.ME' },
  { code: 'MG', name: 'countryCode.MG' },
  { code: 'MH', name: 'countryCode.MH' },
  { code: 'MK', name: 'countryCode.MK' },
  { code: 'ML', name: 'countryCode.ML' },
  { code: 'MM', name: 'countryCode.MM' },
  { code: 'MN', name: 'countryCode.MN' },
  { code: 'MO', name: 'countryCode.MO' },
  { code: 'MP', name: 'countryCode.MP' },
  { code: 'MQ', name: 'countryCode.MQ' },
  { code: 'MR', name: 'countryCode.MR' },
  { code: 'MS', name: 'countryCode.MS' },
  { code: 'MT', name: 'countryCode.MT' },
  { code: 'MU', name: 'countryCode.MU' },
  { code: 'MV', name: 'countryCode.MV' },
  { code: 'MW', name: 'countryCode.MW' },
  { code: 'MX', name: 'countryCode.MX' },
  { code: 'MY', name: 'countryCode.MY' },
  { code: 'MZ', name: 'countryCode.MZ' },
  { code: 'NA', name: 'countryCode.NA' },
  { code: 'NC', name: 'countryCode.NC' },
  { code: 'NE', name: 'countryCode.NE' },
  { code: 'NF', name: 'countryCode.NF' },
  { code: 'NG', name: 'countryCode.NG' },
  { code: 'NI', name: 'countryCode.NI' },
  { code: 'NL', name: 'countryCode.NL' },
  { code: 'NO', name: 'countryCode.NO' },
  { code: 'NP', name: 'countryCode.NP' },
  { code: 'NR', name: 'countryCode.NR' },
  { code: 'NU', name: 'countryCode.NU' },
  { code: 'NZ', name: 'countryCode.NZ' },
  { code: 'OM', name: 'countryCode.OM' },
  { code: 'PA', name: 'countryCode.PA' },
  { code: 'PE', name: 'countryCode.PE' },
  { code: 'PF', name: 'countryCode.PF' },
  { code: 'PG', name: 'countryCode.PG' },
  { code: 'PH', name: 'countryCode.PH' },
  { code: 'PK', name: 'countryCode.PK' },
  { code: 'PL', name: 'countryCode.PL' },
  { code: 'PM', name: 'countryCode.PM' },
  { code: 'PN', name: 'countryCode.PN' },
  { code: 'PR', name: 'countryCode.PR' },
  { code: 'PS', name: 'countryCode.PS' },
  { code: 'PT', name: 'countryCode.PT' },
  { code: 'PW', name: 'countryCode.PW' },
  { code: 'PY', name: 'countryCode.PY' },
  { code: 'QA', name: 'countryCode.QA' },
  { code: 'RE', name: 'countryCode.RE' },
  { code: 'RO', name: 'countryCode.RO' },
  { code: 'RS', name: 'countryCode.RS' },
  { code: 'RU', name: 'countryCode.RU' },
  { code: 'RW', name: 'countryCode.RW' },
  { code: 'SA', name: 'countryCode.SA' },
  { code: 'SB', name: 'countryCode.SB' },
  { code: 'SC', name: 'countryCode.SC' },
  { code: 'SD', name: 'countryCode.SD' },
  { code: 'SE', name: 'countryCode.SE' },
  { code: 'SG', name: 'countryCode.SG' },
  { code: 'SH', name: 'countryCode.SH' },
  { code: 'SI', name: 'countryCode.SI' },
  { code: 'SJ', name: 'countryCode.SJ' },
  { code: 'SK', name: 'countryCode.SK' },
  { code: 'SL', name: 'countryCode.SL' },
  { code: 'SM', name: 'countryCode.SM' },
  { code: 'SN', name: 'countryCode.SN' },
  { code: 'SNA', name: 'countryCode.SNA' },
  { code: 'SO', name: 'countryCode.SO' },
  { code: 'SR', name: 'countryCode.SR' },
  { code: 'ST', name: 'countryCode.ST' },
  { code: 'SV', name: 'countryCode.SV' },
  { code: 'SY', name: 'countryCode.SY' },
  { code: 'SZ', name: 'countryCode.SZ' },
  { code: 'TC', name: 'countryCode.TC' },
  { code: 'TD', name: 'countryCode.TD' },
  { code: 'TF', name: 'countryCode.TF' },
  { code: 'TG', name: 'countryCode.TG' },
  { code: 'TH', name: 'countryCode.TH' },
  { code: 'TJ', name: 'countryCode.TJ' },
  { code: 'TK', name: 'countryCode.TK' },
  { code: 'TL', name: 'countryCode.TL' },
  { code: 'TM', name: 'countryCode.TM' },
  { code: 'TN', name: 'countryCode.TN' },
  { code: 'TO', name: 'countryCode.TO' },
  { code: 'TR', name: 'countryCode.TR' },
  { code: 'TT', name: 'countryCode.TT' },
  { code: 'TU', name: 'countryCode.TU' },
  { code: 'TV', name: 'countryCode.TV' },
  { code: 'TW', name: 'countryCode.TW' },
  { code: 'TZ', name: 'countryCode.TZ' },
  { code: 'UA', name: 'countryCode.UA' },
  { code: 'UG', name: 'countryCode.UG' },
  { code: 'UM', name: 'countryCode.UM' },
  { code: 'US', name: 'countryCode.US' },
  { code: 'UY', name: 'countryCode.UY' },
  { code: 'UZ', name: 'countryCode.UZ' },
  { code: 'VA', name: 'countryCode.VA' },
  { code: 'VC', name: 'countryCode.VC' },
  { code: 'VE', name: 'countryCode.VE' },
  { code: 'WF', name: 'countryCode.WF' },
  { code: 'VG', name: 'countryCode.VG' },
  { code: 'VI', name: 'countryCode.VI' },
  { code: 'VN', name: 'countryCode.VN' },
  { code: 'WS', name: 'countryCode.WS' },
  { code: 'VU', name: 'countryCode.VU' },
  { code: 'XK', name: 'countryCode.XK' },
  { code: 'YE', name: 'countryCode.YE' },
  { code: 'YT', name: 'countryCode.YT' },
  { code: 'ZA', name: 'countryCode.ZA' },
  { code: 'ZM', name: 'countryCode.ZM' },
  { code: 'ZW', name: 'countryCode.ZW' },
  { code: 'ZZ', name: 'countryCode.ZZ' },
]

export const CountriesExcludingFinland = excludeCountry(FINLAND)

function excludeCountry(country: Country): Country[] {
  return Countries.filter((c) => c.code !== country.code)
}

function excludeCommonCountry(country: Country): Country[] {
  return CommonCountries.filter((c) => c.code !== country.code)
}

export const CommonCountries = [FINLAND, SWEDEN, ESTONIA, RUSSIA]

export const COUNTRIES_COMMON_TITLE = 'Common'

export function CountriesGroupedByFirstChar(excludeFinland?: boolean) {
  const countryList = excludeFinland ? excludeCountry(FINLAND) : Countries
  const commonCountryList = excludeFinland
    ? excludeCommonCountry(FINLAND)
    : CommonCountries

  const grouped = groupBy(countryList, (country: Country) => {
    return country.name[0]
  })

  const allCountries = Object.keys(grouped).map((key) => {
    return {
      title: key,
      data: grouped[key],
    }
  })
  return [{ title: COUNTRIES_COMMON_TITLE, data: commonCountryList }].concat(
    allCountries
  )
}

export function findCountryByCode(code: string): Country {
  const conuntries = Countries.filter((c) => c.code === code)
  return conuntries.length > 0 ? conuntries[0] : FINLAND
}

export default Countries
