import Spacings from '@taaleri/core/src/constants/Spacings'
import { FieldArray } from 'formik'
import { View } from 'react-native'
import CountryLink from '../../ui/form/CountryLink'

import {
  DEFAULT_COUNTRY,
  RegulatoryQuestions,
  TaxationCountry,
  US_COUNTRY,
} from '@taaleri/core/src/models/RequlatoryQuestions'
import React, { useEffect, useState } from 'react'
import AddRow from '../../ui/form/AddRow'
import { IconCloseCircle } from '../../ui/Icons'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import UiTouchable from '../../ui/UiTouchable'
import { TaxationChoices } from '@taaleri/core/src/constants/Options'
import InfoButton from '../../ui/InfoButton'
import { useTranslation } from 'react-i18next'

const Taxation: React.FC<{
  values: RegulatoryQuestions
  navigate: (path: string) => void
}> = ({ values }) => {
  const [countriesResetted, setCountriesResetted] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    setCountriesResetted(true)
  }, [values.taxationChoise, values.isUnderUsTaxation])

  useEffect(() => {
    if (
      values.isUnderUsTaxation === 'Yes' &&
      values.taxationChoise === TaxationChoices.OnlyFinland
    ) {
      values.taxationChoise = ''
      setCountriesResetted(true)
    }
  }, [values.isUnderUsTaxation])

  useEffect(() => {
    if (countriesResetted) {
      setCountriesResetted(false)

      if (
        values.isUnderUsTaxation === 'No' &&
        values.taxationCountries.some((tc) => tc.country === US_COUNTRY)
      ) {
        const oldCountries = values.taxationCountries.filter(
          (tc) => tc.country !== US_COUNTRY
        )
        values.taxationCountries = []
        values.taxationCountries.push(...oldCountries)
      }

      if (values.taxationChoise === TaxationChoices.OnlyFinland) {
        values.taxationCountries = []
        values.taxationCountries.push({ country: DEFAULT_COUNTRY, tin: '' })
      }

      if (values.taxationChoise === TaxationChoices.FinlandAndAbroad) {
        if (
          values.taxationCountries.length > 0 &&
          values.taxationCountries[0].country !== DEFAULT_COUNTRY
        ) {
          values.taxationCountries.unshift({
            country: DEFAULT_COUNTRY,
            tin: '',
          })
        }

        if (
          values.isUnderUsTaxation === 'Yes' &&
          values.taxationCountries.every((tc) => tc.country !== US_COUNTRY)
        ) {
          values.taxationCountries.splice(1, 0, {
            country: US_COUNTRY,
            tin: '',
          })
        }

        if (values.taxationCountries.length < 2) {
          values.taxationCountries.push({ country: '', tin: '' })
        }
      }

      if (values.taxationChoise === TaxationChoices.OnlyAbroad) {
        const oldCountries = values.taxationCountries.filter(
          (tc) => tc.country !== DEFAULT_COUNTRY
        )
        values.taxationCountries = []
        values.taxationCountries.push(...oldCountries)

        if (
          values.isUnderUsTaxation === 'Yes' &&
          values.taxationCountries.every((tc) => tc.country !== US_COUNTRY)
        ) {
          values.taxationCountries.unshift({ country: US_COUNTRY, tin: '' })
        }
      }

      if (values.taxationCountries.length < 1) {
        values.taxationCountries.push({ country: '', tin: '' })
      }
    }
  }, [countriesResetted])

  const isEditable = (country: TaxationCountry) => {
    if (
      (values.isUnderUsTaxation === 'Yes' && country.country === US_COUNTRY) ||
      country.country === DEFAULT_COUNTRY
    ) {
      return false
    }
    return true
  }

  const canBeRemoved = (index: number) => {
    if (index === 0) return false
    if (
      index === 1 &&
      values.taxationChoise === TaxationChoices.FinlandAndAbroad
    )
      return false

    return true
  }

  return (
    <FieldArray name="taxationCountries">
      {({ push, remove }) => {
        const hasOneTaxationCountrySelected =
          values.taxationChoise !== TaxationChoices.OnlyFinland

        return (
          <View>
            {!countriesResetted && (
              <>
                {values.taxationCountries.map((taxationCountry, index) => (
                  <View
                    key={index}
                    style={[
                      {
                        borderWidth: 0,
                        borderBottomWidth: 0,
                        paddingBottom: 0,
                      },
                      index > 0 && { paddingTop: 0, borderTopWidth: 0 },
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <CountryLink
                          name={`taxationCountries.${index}.country`}
                          title={
                            values.taxationCountries[index].country !==
                            DEFAULT_COUNTRY
                              ? `${t(
                                  'questions.onboardingRegulatoryForm.foreign-tax'
                                )}`
                              : ''
                          }
                          editable={isEditable(values.taxationCountries[index])}
                          excludeFinland={true}
                        />
                      </View>
                      {canBeRemoved(index) && (
                        <UiTouchable
                          onPress={() => {
                            remove(index)
                            setCountriesResetted(true)
                          }}
                        >
                          <IconCloseCircle
                            style={{
                              marginLeft: 14,
                              marginBottom: Spacings.S24,
                            }}
                          />
                        </UiTouchable>
                      )}
                    </View>
                    {taxationCountry.country !== DEFAULT_COUNTRY && (
                      <>
                        <UiTextInputForm
                          name={`taxationCountries.${index}.tin`}
                          label={`${t(
                            'questions.onboardingRegulatoryForm.foreign-tax-tin'
                          )}`}
                          type="name"
                          infoLabel={[
                            {
                              text: 'questions.onboardingRegulatoryForm.tax-tin-info',
                            },
                            {
                              text: 'questions.onboardingRegulatoryForm.tin-example',
                            },
                          ]}
                        />
                      </>
                    )}
                  </View>
                ))}
              </>
            )}
            {hasOneTaxationCountrySelected &&
              values.taxationChoise !== TaxationChoices.OnlyFinland &&
              values.taxationCountries.length < 3 && (
                <View
                  style={{
                    paddingBottom: Spacings.S24,
                    paddingTop: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <AddRow
                    title={t(
                      'questions.onboardingRegulatoryForm.add-foreign-tax'
                    )}
                    onPress={() => {
                      push({ country: '', tin: '' })
                    }}
                  />
                  <InfoButton
                    style={{
                      paddingTop: 4,
                    }}
                    title='questions.onboardingRegulatoryForm.foreign-tax-info'
                    text='questions.onboardingRegulatoryForm.foreign-tax-info-text'
                  />
                </View>
              )}
          </View>
        )
      }}
    </FieldArray>
  )
}

export default Taxation
