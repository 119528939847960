import React from 'react'

import QuestionContainer from 'screens/questions/QuestionContainer'
import BankSelection from '@taaleri/components/src/screens/portfolio/BankSelection'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import UiButton from '@taaleri/components/src/ui/UiButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { useTranslation } from 'react-i18next'

export default function OnboardingBankScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <QuestionContainer>
      <BankSelection
        onSuccess={() => {
          navigate(routePath(ROUTE_APP))
        }}
        hideSteps={true}
        isOnboarding={true}
      />
      <UiButton
        title={t('button.first-invest-later')}
        onPress={() => navigate(routePath(ROUTE_APP))}
        containerStyle={{ marginTop: Spacings.S24 }}
        type="secondaryplain"
      />
    </QuestionContainer>
  )
}
