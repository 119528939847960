import React from 'react'
import { observer } from 'mobx-react'
import { BoxWithPadding } from '../../ui/box/Box'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import {
  IconCo2,
  IconUnitedNations,
  IconBriefcase,
  IconGraph,
  IconSaveTheWorldSmall,
} from '../../ui/Icons'
import Colors from '@taaleri/core/src/constants/Colors'
import UiTouchable from '../../ui/UiTouchable'
import {
  formatNumber,
  formatFundProfit,
  formatCo2Kilos,
} from '@taaleri/core/src/utils/format'
import useNavigation from '../../navigation/useNavigation'
import { routePathQuestions } from '../../navigation/routeHelper.native'
import IconBox, { IconBoxItem } from '../../components/IconBox'
import { ROUTE_QUESTION_PORTFOLIO_TYPE } from '../questions/QuestionRoutes'
import UiButton from '../../ui/UiButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ROUTE_IMPACT_COMPARISON } from './ImpactRoutes'
import { PortfolioDistributions } from '@taaleri/core/src/models/investmentProposal'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  portfolioDistribution: PortfolioDistributions
  isOnboarding?: boolean
  co2Compensation: number
  sdgCount: number
  isImpact?: boolean
  showComparisonButton?: boolean
  currentScreen?: string
  columns?: number
  mode: 'return' | 'impact'
}

function ImpactFactsCard({
  title,
  portfolioDistribution,
  isOnboarding,
  sdgCount,
  co2Compensation,
  showComparisonButton,
  isImpact,
  currentScreen,
  columns,
  mode,
}: Props) {
  const { t } = useTranslation()
  const { navigate, navigateApp } = useNavigation()

  const { totalExpenseRatio, expectedReturn } = portfolioDistribution
  const showReturn = mode === 'return'
  const Items: IconBoxItem[] = [
    {
      title: formatFundProfit(totalExpenseRatio),
      text: t('questions.proposal.total-costs'),
      icon: IconBriefcase,
    },
    ...insertIf(showReturn, {
      title: formatFundProfit(100 * expectedReturn),
      text: t('questions.proposal.expected-profit-your-risk-level'),
      icon: IconGraph,
    }),
    ...insertIf(!showReturn, {
      title: t('questions.proposal.responsible'),
      text: t('questions.proposal.only-responsible-companies'),
      icon: IconSaveTheWorldSmall,
    }),
    {
      title: formatCo2Kilos(co2Compensation),
      text: t('questions.proposal.co2'),
      icon: IconCo2,
      disabled: !isImpact,
    },
    {
      title: formatNumber(sdgCount),
      text: t('questions.portfolios.un'),
      icon: IconUnitedNations,
      disabled: !isImpact,
    },
  ]

  return (
    <BoxWithPadding
      title={title}
      titleStyle={{ marginBottom: 0 }}
      titleRightComponent={
        isOnboarding && currentScreen ? (
          <UiTouchable
            onPress={() => {
              QuestionStore.proposalScreen = currentScreen
              navigate(routePathQuestions(ROUTE_QUESTION_PORTFOLIO_TYPE))
            }}
          >
            <TextDefault type="t2" color={Colors.primary}>
              {t('questions.proposal.change-portfolio')}
            </TextDefault>
          </UiTouchable>
        ) : undefined
      }
    >
      <FlexRow
        style={[
          {
            flexWrap: 'wrap',
          },
          showComparisonButton && {
            marginBottom: Spacings.S16,
            paddingBottom: Spacings.S16,
            borderBottomColor: Colors.border,
            borderBottomWidth: 1,
          },
        ]}
      >
        {Items.map((i, index) => (
          <IconBox
            key={i.title}
            {...i}
            index={index}
            columnsDesktop={columns || 4}
            itemCount={Items.length}
            textStyle={{ maxWidth: 140 }}
          />
        ))}
      </FlexRow>
      {showComparisonButton && (
        <UiButton
          type="secondary"
          size="small"
          title={t('button.compare-current')}
          onPress={() => navigateApp(ROUTE_IMPACT_COMPARISON)}
        />
      )}
    </BoxWithPadding>
  )
}

export default observer(ImpactFactsCard)
