import Analytics from '@taaleri/core/src/analytics/Analytics'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { profitFilterTranslateHelper } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { today } from '@taaleri/core/src/utils/date'
import { formatDateDash } from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'

import DateFilter from './Filters/DateFilter'
import InstrumentFilter from './Filters/InstrumentFilter'
import PortfolioFilter from './Filters/PortfolioFilter'
import ReasonsFilter from './Filters/ReasonsFilter'
import TypeFilter from './Filters/TypeFilter'
import { CategoryItem, CategorySelector } from './Selector/CategorySelector'
import InstrumentSelector from './Selector/InstrumentSelector'
import PortfolioSelector from './Selector/PortfolioSelector'
import ReasonSelector from './Selector/ReasonSelector'
import Selector from './Selector/Selector'
import TransactionCategoryContainer from './Selector/TransactionCategoryContainer'
import TypeSelector from './Selector/TypeSelector'
import {
  getCashAccountStatementPdf,
  getTransactionListPdfUrl,
} from './TransactionPdfUrl'
import { isWeb } from '../../constants/Platforms'
import useNavigation from '../../navigation/useNavigation'
import { BackButton } from '../../ui/BackButton'
import FlexRow from '../../ui/FlexRow'
import OpenLink from '../../ui/OpenLink'
import UiButton from '../../ui/UiButton'
import Title from '../../ui/text/Title'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import DownloadReportButton, {
  ReportValues,
} from '../portfolio/DownloadReportButton'
import { ROUTE_REPORT } from '../report/ReportRoutes'

const transactionItems: CategoryItem[] = [
  { title: 'reportTransaction.portfolio-events', category: 'Paid' },
  { title: 'reportTransaction.cash-events', category: 'Cash' },
  { title: 'reportTransaction.open-events', category: 'Open' },
]

function TransactionsFilter() {
  const { t } = useTranslation()
  const [isDateOpen, setDateOpen] = useState<boolean>(false)
  const [isInstrumentOpen, setInstrumentOpen] = useState<boolean>(false)
  const [isTypeOpen, setTypeOpen] = useState<boolean>(false)
  const [isPortfoliosOpen, setPortfoliosOpen] = useState<boolean>(false)
  const [isReasonsOpen, setReasonsOpen] = useState<boolean>(false)
  const { isWide } = useLayout()
  const { navigateApp } = useNavigation()

  const SelectorContainer = isWide ? FlexRow : View
  useEffect(() => {
    setInstrumentOpen(false)
    setTypeOpen(false)
    setPortfoliosOpen(false)
    setDateOpen(false)
    setReasonsOpen(false)
  }, [TransactionStore.transactionCategory])

  /* 
  Show all selector on wide screen. On mobile devices, 
  hide unnecessary filters to save space. 
  */

  const {
    allPortfolios,
    transactionCategory,
    startDate,
    endDate,
    loadingToken,
    token,
    selectedPortfolios,
    loadingTransactions,
    filteredTransactions,
    dateRangeTitle,
    isCashCategory,
  } = TransactionStore

  const noFilteredTransactions = filteredTransactions.length === 0

  const portfolioIds =
    selectedPortfolios.length > 0
      ? selectedPortfolios.map((e) => e.value)
      : allPortfolios.map((e) => e.value)

  const dateFilter = (
    <DateFilter
      onDateRangePress={(dateRange: string) => {
        setDateOpen(false)
        TransactionStore.setDateRange(dateRange)
        TransactionStore.fetchTransaction(AppStore.customerId)
      }}
      onConfirm={(start: Date, end: Date) => {
        TransactionStore.setCustomDateRange(start, end)
        TransactionStore.fetchTransaction(AppStore.customerId)
        setDateOpen(false)
      }}
      initialStartDate={TransactionStore.startDate}
      initialEndDate={TransactionStore.endDate}
      dateRange={TransactionStore.dateRange}
    />
  )

  // For now show download button only for cash transactions on desktop
  const showPdfButton =
    !loadingToken &&
    token !== '' &&
    isWide &&
    isWeb &&
    !loadingTransactions &&
    (!noFilteredTransactions || isCashCategory)

  return (
    <View style={{ alignItems: 'center' }}>
      <View style={{ maxWidth: 1200, width: '100%' }}>
        <FlexRow style={{ alignItems: 'center' }}>
          {isWeb && (
            <BackButton
              onPress={() => navigateApp(ROUTE_REPORT)}
              style={{ marginRight: Spacings.S16 }}
            />
          )}
          <Title>{t('reportBox.events')}</Title>
        </FlexRow>
        <TransactionCategoryContainer>
          <>
            {transactionItems.map((transactionItem) => (
              <CategorySelector
                selectedCategory={TransactionStore.transactionCategory}
                setSelectedCategory={(category) =>
                  TransactionStore.setCategory(category)
                }
                item={transactionItem}
                key={transactionItem.category}
              />
            ))}
            {showPdfButton &&
              (isCashCategory ? (
                <UiButton
                  title={t('button.download-bank-statement')}
                  containerStyle={styles.downloadButton}
                  onPress={() => {
                    Analytics.event('Transactions', 'Transaction pdf', 'Cash')
                    const url = getCashAccountStatementPdf(
                      AppStore.customerId,
                      formatDateDash(startDate),
                      formatDateDash(endDate),
                      TransactionStore.token
                    )
                    OpenLink(url, 'transaction-pdf')
                  }}
                />
              ) : (
                <DownloadReportButton
                  title={t('report.load-events')}
                  reportType="transactions"
                  buttonStyle={styles.downloadButton}
                  fetchUrl={(values: ReportValues) => {
                    Analytics.event(
                      'Transactions',
                      'Transaction pdf',
                      TransactionStore.transactionCategory
                    )
                    return getTransactionListPdfUrl(
                      transactionCategory,
                      AppStore.customerId,
                      formatDateDash(startDate),
                      transactionCategory === 'Open'
                        ? formatDateDash(today())
                        : formatDateDash(endDate),
                      TransactionStore.token,
                      values.fileFormat,
                      values.byPortfolio ? 'Portfolio' : 'List',
                      portfolioIds
                    )
                  }}
                />
              ))}
          </>
        </TransactionCategoryContainer>
        <View
          style={{
            backgroundColor: Colors.white,
            padding: isWide ? Spacings.S40 : Spacings.S16,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: isWide ? 0 : 6,
            borderTopRightRadius: 6,
          }}
        >
          <SelectorContainer>
            <PortfolioSelector
              onToggle={() => {
                setInstrumentOpen(false)
                setTypeOpen(false)
                setPortfoliosOpen(!isPortfoliosOpen)
                setDateOpen(false)
                setReasonsOpen(false)
              }}
              isOpen={isPortfoliosOpen}
              containerStyle={{
                marginBottom: isWide && !isCashCategory ? Spacings.S24 : 0,
              }}
            />

            {isPortfoliosOpen && !isWide && <PortfolioFilter />}
            <Selector
              titleComponent={
                <TextDefault type="h6" style={{ marginLeft: 10 }}>
                  {profitFilterTranslateHelper(dateRangeTitle, t)}
                </TextDefault>
              }
              onToggle={() => {
                setInstrumentOpen(false)
                setTypeOpen(false)
                setPortfoliosOpen(false)
                setDateOpen(!isDateOpen)
                setReasonsOpen(false)
              }}
              isOpen={isDateOpen}
              containerStyle={{
                marginTop: isWide ? 0 : Spacings.S24,
                marginBottom: isWide && !isCashCategory ? Spacings.S24 : 0,
                marginLeft: isWide ? Spacings.S40 : 0,
                justifyContent: 'center',
              }}
              isDateFilter={true}
            />
            {isDateOpen && !isWide && dateFilter}
          </SelectorContainer>
          <SelectorContainer>
            {isCashCategory || (
              <InstrumentSelector
                onToggle={() => {
                  setInstrumentOpen(!isInstrumentOpen)
                  setTypeOpen(false)
                  setPortfoliosOpen(false)
                  setDateOpen(false)
                  setReasonsOpen(false)
                }}
                isOpen={isInstrumentOpen}
                containerStyle={{
                  marginBottom: 0,
                  marginTop: isWide ? 0 : Spacings.S24,
                }}
              />
            )}
            {isInstrumentOpen && !isWide && <InstrumentFilter />}
            {isCashCategory && (
              <ReasonSelector
                onToggle={() => {
                  setInstrumentOpen(false)
                  setTypeOpen(false)
                  setPortfoliosOpen(false)
                  setDateOpen(false)
                  setReasonsOpen(!isReasonsOpen)
                }}
                isOpen={isReasonsOpen}
                containerStyle={{
                  marginBottom: 0,
                  marginTop: Spacings.S24,
                }}
              />
            )}
            {isReasonsOpen && !isWide && <ReasonsFilter />}
            {!isCashCategory ? (
              <TypeSelector
                onToggle={() => {
                  setInstrumentOpen(false)
                  setTypeOpen(!isTypeOpen)
                  setPortfoliosOpen(false)
                  setDateOpen(false)
                  setReasonsOpen(false)
                }}
                isOpen={isTypeOpen}
                containerStyle={{
                  marginBottom: 0,
                  marginTop: isWide ? 0 : Spacings.S24,
                  marginLeft: isWide ? Spacings.S40 : 0,
                }}
              />
            ) : (
              // Use empty View to set correct width for ReasonSelector
              <View
                style={{ flex: 1, marginHorizontal: isWide ? Spacings.S40 : 0 }}
              />
            )}
            {isTypeOpen && !isWide && <TypeFilter />}
          </SelectorContainer>
          {isDateOpen && isWide && dateFilter}
          {isPortfoliosOpen && isWide && <PortfolioFilter />}
          {isInstrumentOpen && isWide && <InstrumentFilter />}
          {isTypeOpen && isWide && <TypeFilter />}
          {isReasonsOpen && isWide && <ReasonsFilter />}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  downloadButton: {
    height: Spacings.S32,
    marginLeft: 'auto',
    minWidth: 0,
    paddingLeft: Spacings.S16,
    paddingRight: Spacings.S16,
  },
})

export default observer(TransactionsFilter)
