import React from 'react'
import Subtitle from '@taaleri/components/src/ui/text/Subtitle'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { PropsChildrenWithStyle } from '@taaleri/components/src/components/PropsChildren'

export function SubtitleHome(props: PropsChildrenWithStyle) {
  return (
    <Subtitle
      style={[
        {
          marginBottom: Spacings.S16,
          display: 'flex',
        },
        props.style,
      ]}
    >
      {props.children}
    </Subtitle>
  )
}
