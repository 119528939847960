import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import ContractUnsuccesfulContent from '@taaleri/components/src/screens/contract/ContractUnsuccessfullContent'
import { ButtonAction } from '@taaleri/components/src/ui/UiButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { useTranslation } from 'react-i18next'

export default function ContractUnsuccessfullScreen() {
  const { t } = useTranslation()
  const { location, push } = useHistory()
  const state: any = location.state
  return (
    <BackgroundContainerApp>
      <TopBottomContainer>
        <ContractUnsuccesfulContent />
        <ButtonAction
          title={t('button.close')}
          onPress={() => push(state ? state.navigateTo : routePath(ROUTE_APP))}
        />
      </TopBottomContainer>
    </BackgroundContainerApp>
  )
}
