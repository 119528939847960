import React, { useState, useEffect } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { validateDiscountCode } from '@taaleri/core/src/services/discountCode'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  getDiscountSuccessMessage,
  getDiscountErrorMessage,
} from '@taaleri/core/src/utils/discountMessages'
import { useTranslation } from 'react-i18next'

interface Props {
  discountCode: string
  containerStyle?: StyleProp<ViewStyle>
}

export function DiscountCodeInput({ discountCode, containerStyle }: Props) {
  const { t } = useTranslation()
  const [errorMessage, setErrorMessage] =
    useState<string | undefined>(undefined)
  const [successMessage, setSuccessMessage] =
    useState<string | undefined>(undefined)
  const [hideActionLabel, setActionLabelHidden] = useState<boolean>(false)

  const validateCode = async () => {
    const { success, response } = await validateDiscountCode(discountCode)
    if (success && response?.isSuccess) {
      setErrorMessage(undefined)
      setSuccessMessage(getDiscountSuccessMessage(response, t))
    } else if (response) {
      setErrorMessage(getDiscountErrorMessage(response, t))
      setSuccessMessage(undefined)
    } else {
      setErrorMessage(`${t('validation.code-not-valid')}`)
      setSuccessMessage(undefined)
    }
    setActionLabelHidden(true)
  }

  useEffect(() => {
    if (discountCode) {
      validateCode()
    }
  }, [])

  return (
    <>
      <UiTextInputForm
        forcedErrorMessage={errorMessage}
        forceFocused={successMessage !== undefined}
        hideCheckCircle={!hideActionLabel}
        onChangeText={() => setActionLabelHidden(false)}
        actionLabel={
          !discountCode || hideActionLabel
            ? undefined
            : `${t('onboarding.discount-code-use')}`
        }
        onActionPress={validateCode}
        containerStyle={containerStyle}
        name="discountCode"
        label={`${t('onboarding.discount-code')}`}
        type="name"
      />
      {successMessage ? (
        <TextDefault
          color={Colors.primary}
          style={{ marginLeft: Spacings.S16, marginTop: -18, marginBottom: 24 }}
        >
          {successMessage}
        </TextDefault>
      ) : null}
    </>
  )
}
