import * as React from 'react'
import { View } from 'react-native'

import SecureComponent from './SecureComponent'
import UiTabButton from './UiTabButton'

export type TabDetails = {
  id: string
  title: string
  order: number
  content: React.JSX.Element
  securityGroups?: string[]
}

interface TabContainerProps {
  tabs: TabDetails[]
}

const TabContainer: React.FC<TabContainerProps> = (props) => {
  const orderedTabs = props.tabs.sort((a, b) => a.order - b.order)

  const [activeTab, setActiveTab] = React.useState<string>(
    orderedTabs[0].id ?? ''
  )

  const getActiveTab = () => {
    return props.tabs.find((t) => t.id === activeTab)
  }

  return (
    <>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {orderedTabs.map((tab, index) => {
          return (
            <React.Fragment key={`${tab.id}-${tab.order}-${index}`}>
              {tab.securityGroups && tab.securityGroups.length > 0 && (
                <SecureComponent securityGroups={tab.securityGroups}>
                  <UiTabButton
                    title={tab.title}
                    onPress={() => setActiveTab(tab.id)}
                    type={activeTab === tab.id ? 'primary' : 'secondary'}
                  />
                </SecureComponent>
              )}
              {!tab.securityGroups && (
                <UiTabButton
                  title={tab.title}
                  onPress={() => setActiveTab(tab.id)}
                  type={activeTab === tab.id ? 'primary' : 'secondary'}
                />
              )}
            </React.Fragment>
          )
        })}
      </View>
      {activeTab && getActiveTab() && <>{getActiveTab()?.content}</>}
    </>
  )
}

export default TabContainer
