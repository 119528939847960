import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

export const Padding: React.FC<{ style?: StyleProp<ViewStyle> }> = ({
  children,
  style,
}) => <View style={[{ padding: Spacings.S16 }, style]}>{children}</View>

export const PaddingVertical: React.FC = ({ children }) => (
  <View style={{ paddingVertical: Spacings.S16 }}>{children}</View>
)
