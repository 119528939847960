export interface Bank {
  id: string
  image: any
}

export const BankIds = {
  op: 'op_new',
  nordea: 'nordea',
  danske: 'danskebank',
  handelsbanken: 'handelsbanken',
  saastopankki: 'sp',
  pop: 'pop',
  aktia: 'aktia',
  spankki: 'spankki',
  alandsbanken: 'alandsbanken'
}

export const BANKS: Bank[] = [
  {
    id: BankIds.nordea,
    image: require('./banks/nordea.png'),
  },
  {
    id: BankIds.danske,
    image: require('./banks/danske.png'),
  },
  {
    id: BankIds.op,
    image: require('./banks/op.png'),
  },
  {
    id: BankIds.handelsbanken,
    image: require('./banks/handelsbanken.png'),
  },
  {
    id: BankIds.saastopankki,
    image: require('./banks/sp.png'),
  },
  {
    id: BankIds.pop,
    image: require('./banks/pop.png'),
  },
  {
    id: BankIds.aktia,
    image: require('./banks/aktia.png'),
  },
  {
    id: BankIds.spankki,
    image: require('./banks/spankki.png'),
  },
  {
    id: BankIds.alandsbanken,
    image: require('./banks/alandsbanken.png'),
  },
]
