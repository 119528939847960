import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import React, { useEffect, useState, ReactElement } from 'react'
import { View, StyleSheet } from 'react-native'
import { TextDefault } from '../../ui/text/UiText'
import LoadingIndicator from '../../ui/LoadingIndicator'
import {
  getFinalBalance,
  getInvestedCapital,
  getInvestedCapitalTimeseries,
  getRevenueTimeseries,
} from '../questions/RevenueCalculator'
import CalculatorGraph from '../calculator/CalculatorGraph'
import { BoxWithPadding } from '../../ui/box/Box'
import useLayout from '../../ui/useLayout'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  formatCurrency,
  formatDateWithoutLeadingZeros,
  formatPercentageTwoDecimal,
  sum,
} from '@taaleri/core/src/utils/format'
import UiSlider from '../../ui/form/UiSlider'
import Colors from '@taaleri/core/src/constants/Colors'
import TransactionCategoryContainer from '../reporttransaction/Selector/TransactionCategoryContainer'
import {
  InvestmentTimeSelector,
  InvestmentTimeItem,
} from './InvestmentTimeSelector'
import { IconInfo, IconMoneyHand, IconPencil } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { InfoContainer } from '../../ui/InfoContainer'
import UiButton from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_PORTFOLIO_PAYMENT_MONTHLY } from '../portfolio/PortfolioRoutes'
import { deleteSavingsPlan } from '@taaleri/core/src/services/savingsPlan'
import YesNoModal from '../../ui/modal/YesNoModal'
import InfoButton from '../../ui/InfoButton'
import { ParagraphBoldFirst } from '../../ui/text/Paragraph'
import { SavingsPlan } from '@taaleri/core/src/models/SavingsPlan'
import { MarketValueGraph } from './MarketValueGraph'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const items: InvestmentTimeItem[] = [
  { title: 'savingsPlan.now', time: 0 },
  { title: 'savingsPlan.after-n-years', time: 20 },
  { title: 'savingsPlan.after-n-years', time: 10 },
  { title: 'savingsPlan.after-n-years', time: 5 },
]

const orderTypes: {
  [key: string]: string
} = {
  Buy: 'app.lastupdates.additional-purchase',
  Sell: 'app.lastupdates.withdraw',
}

const CreateSavingsPlanButton = observer(
  ({
    monthlyContribution,
    disabled,
    t,
  }: {
    monthlyContribution: number
    disabled: boolean
    t: TFunction
  }) => {
    const { navigateApp } = useNavigation()
    const { isDigiDiscretionary, isBaseCustomerActive } = AppStore
    if (isDigiDiscretionary && isBaseCustomerActive) {
      return (
        <UiButton
          onPress={() => {
            AppStore.newInvestment = monthlyContribution
            navigateApp(ROUTE_PORTFOLIO_PAYMENT_MONTHLY)
          }}
          title={`${t('savingsPlan.set-goal-start-saving')}`}
          containerStyle={{ marginTop: Spacings.S24 }}
          type={disabled ? 'disabled' : 'primary'}
        />
      )
    }
    return null
  }
)

const RemoveSavingsPlanButton = observer(
  ({ onPress, t }: { onPress: () => void; t: TFunction }) => {
    const { isDigiDiscretionary, isBaseCustomerActive } = AppStore
    if (isDigiDiscretionary && isBaseCustomerActive) {
      return (
        <UiButton
          onPress={onPress}
          title={`${t('savingsPlan.stop-monthly-saving')}`}
          containerStyle={{ marginTop: Spacings.S24 }}
          type="secondary"
        />
      )
    }
    return null
  }
)

interface NowTabProps {
  savingsPlan: SavingsPlan
  finalBalance: number
}

function NowTab({ savingsPlan, finalBalance }: NowTabProps) {
  const { t } = useTranslation()
  const { isWide } = useLayout()
  const series = savingsPlan.savingsPlanTimeSeries
  if (series.length === 0) {
    return null
  }

  const contributionsTotal = series[series.length - 1].netInvestmentsValue
  const revenue = finalBalance - contributionsTotal
  const revenueTimeseries = series.map((e) => {
    return { value: e.marketValue, date: new Date(e.date) }
  })
  const capitalTimeseries = series.map((e) => {
    return { value: e.netInvestmentsValue, date: new Date(e.date) }
  })
  return (
    <BoxWithPadding style={{ flex: 2, borderTopLeftRadius: isWide ? 0 : 6 }}>
      <FlexRow
        style={[
          styles.row,
          {
            paddingBottom: Spacings.S16,
            borderBottomColor: Colors.border,
            borderBottomWidth: 1,
            marginBottom: Spacings.S16,
          },
        ]}
      >
        <TextDefault type={isWide ? 't2' : 't1'}>
          {t('savingsPlan.your-portfolio-value-now')}
        </TextDefault>
        <TextDefault type="h6">
          {formatCurrency(Math.round(finalBalance))}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.row}>
        <TextDefault type="t5">{t('savingsPlan.saved-total')}</TextDefault>
        <TextDefault type="h6">
          {formatCurrency(Math.round(contributionsTotal))}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.row}>
        <TextDefault type="t5">{t('savingsPlan.profit-total')}</TextDefault>
        <TextDefault type="h6">{`${formatCurrency(
          Math.round(revenue)
        )}`}</TextDefault>
      </FlexRow>
      <MarketValueGraph {...{ revenueTimeseries, capitalTimeseries }} />
    </BoxWithPadding>
  )
}

interface FutureTabProps {
  investmentTime: number
  initialValue: number
  expectedReturn: number
  savingsPlan: SavingsPlan
  setConfirmationModalVisible: (value: boolean) => void
  monthlyContribution: number
  setMonthlyContribution: (value: number) => void
}

function FutureTab({
  investmentTime,
  initialValue,
  expectedReturn,
  monthlyContribution,
}: FutureTabProps) {
  const { t } = useTranslation()
  const { isWide } = useLayout()
  const revenueTimeseries = getRevenueTimeseries(
    expectedReturn,
    initialValue,
    monthlyContribution,
    0,
    investmentTime
  )
  const capitalTimeseries = getInvestedCapitalTimeseries(
    initialValue,
    monthlyContribution,
    0,
    investmentTime
  )
  const finalBalance = getFinalBalance(revenueTimeseries, investmentTime)

  const investedCapital = getInvestedCapital(
    initialValue,
    monthlyContribution,
    investmentTime
  )
  const revenue = finalBalance - investedCapital

  return (
    <BoxWithPadding style={{ flex: 2, borderTopLeftRadius: isWide ? 0 : 6 }}>
      <FlexRow
        style={[
          styles.row,
          {
            paddingBottom: Spacings.S16,
            borderBottomColor: Colors.border,
            borderBottomWidth: 1,
            marginBottom: Spacings.S16,
          },
        ]}
      >
        <TextDefault type={isWide ? 't2' : 't1'}>
          {t('savingsPlan.your-portfolio-value-n-years', {
            years: investmentTime,
          })}
        </TextDefault>
        <TextDefault type="h6">
          {formatCurrency(Math.round(finalBalance))}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.row}>
        <TextDefault type="t5">
          {t('savingsPlan.portfolio-value-now')}
        </TextDefault>
        <TextDefault type="h6">
          {formatCurrency(Math.round(initialValue))}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.row}>
        <TextDefault type="t5">{t('savingsPlan.saved-total')}</TextDefault>
        <TextDefault type="h6">
          {formatCurrency(
            Math.round(monthlyContribution * 12 * investmentTime)
          )}
        </TextDefault>
      </FlexRow>
      <FlexRow style={styles.row}>
        <FlexRow style={{ alignItems: 'center' }}>
          <TextDefault type="t5">{`${t(
            'savingsPlan.profit-total'
          )} (${formatPercentageTwoDecimal(
            100 * expectedReturn,
            1
          )} %)`}</TextDefault>
          <InfoButton
            style={{ marginLeft: Spacings.S4 }}
            infoElement={
              <IconInfo color={Colors.gray40} width={14} height={14} />
            }
          >
            <TextDefault style={{ marginBottom: Spacings.S16 }}>
              {t('app.savingsplantooltip')}
            </TextDefault>
          </InfoButton>
        </FlexRow>
        <TextDefault type="h6">{`+${formatCurrency(
          Math.round(revenue)
        )}`}</TextDefault>
      </FlexRow>
      <CalculatorGraph {...{ revenueTimeseries, capitalTimeseries }} />
    </BoxWithPadding>
  )
}

function SavingsPlanContent() {
  const { t } = useTranslation()
  const [investmentTime, setInvestmentTime] = useState<number>(20)
  const [monthlyContribution, setMonthlyContribution] = useState<number>(0)
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [mounted, setMounted] = useState<boolean>(false)
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false)
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false)

  const { isWide } = useLayout()
  const {
    savingsPlan,
    loadingSavingsPlan,
    loading,
    customerId,
    showSavingsPlanDoneModal,
  } = AppStore

  function Modals(): ReactElement {
    return (
      <>
        <YesNoModal
          isVisible={removeModalVisible}
          title={`${t('invest.monthly-saving-plan-ended')}`}
          content={t('invest.activate-monthly-saving-plan-again')}
          icon={<IconMoneyHand />}
          onYes={() => {
            setRemoveModalVisible(false)
          }}
          yesText={`${t('button.continue')}`}
        />

        <YesNoModal
          isVisible={confirmationModalVisible}
          title={`${t('invest.end-monthly-saving-plan-question')}`}
          content={t('invest.monthly-invest-desc')}
          icon={<IconMoneyHand />}
          onYes={async () => {
            await deleteSavingsPlan(customerId)
            await AppStore.fetchSavingsPlan()
            setConfirmationModalVisible(false)
            setRemoveModalVisible(true)
          }}
          onNo={() => setConfirmationModalVisible(false)}
          buttonDirection="column"
        />

        <YesNoModal
          isVisible={showSavingsPlanDoneModal}
          title={`${t('invest.monthly-saving-started')}`}
          content=""
          icon={<IconMoneyHand />}
          onYes={async () => {
            AppStore.setShowSavingsPlanDoneModal(false)
          }}
          yesText={`${t('button.continue')}`}
        />
      </>
    )
  }

  async function fetchSavingsPlan() {
    await AppStore.fetchSavingsPlan()
    setMonthlyContribution(AppStore.savingsPlan?.targetAmount ?? 0)
    setMounted(true)
  }

  useEffect(() => {
    fetchSavingsPlan()
  }, [AppStore.customerId])

  if (loadingSavingsPlan || !savingsPlan || !mounted || loading) {
    return <LoadingIndicator />
  }

  const currentStrategy = AppStore.getCurrentStrategy
  const expectedReturn = currentStrategy?.expectedReturn ?? 0.05
  const initialValue = AppStore.discreationaryPortfolioMarketValue
  const nowTabSelected = investmentTime === 0

  const SliderContainer = isWide ? View : UiTouchable

  return (
    <>
      <Modals />
      <View style={{ marginBottom: Spacings.S24 }}>
        <TransactionCategoryContainer>
          {items.map((item) => (
            <InvestmentTimeSelector
              investmentTime={investmentTime}
              setInvestmentTime={setInvestmentTime}
              item={item}
              key={item.title}
            />
          ))}
        </TransactionCategoryContainer>
        <View
          style={{
            flexDirection: isWide ? 'row' : 'column-reverse',
            flex: 3,
          }}
        >
          {nowTabSelected ? (
            <NowTab {...{ savingsPlan }} finalBalance={initialValue} />
          ) : (
            <FutureTab
              expectedReturn={expectedReturn}
              investmentTime={investmentTime}
              initialValue={initialValue}
              savingsPlan={savingsPlan}
              setConfirmationModalVisible={setConfirmationModalVisible}
              monthlyContribution={monthlyContribution}
              setMonthlyContribution={setMonthlyContribution}
            />
          )}
          <View
            style={{
              flex: isWide ? 1 : undefined,
              marginLeft: isWide ? Spacings.S24 : 0,
              marginBottom: isWide ? 0 : Spacings.S24,
            }}
          >
            <BoxWithPadding>
              <SliderContainer
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onPress={() => setShowSlider(!showSlider)}
              >
                <TextDefault type={isWide ? 't2' : 't1'}>
                  {t('savingsPlan.monthly-saving-goal')}
                </TextDefault>
                <FlexRow>
                  <TextDefault type="h6">
                    {formatCurrency(monthlyContribution)}
                  </TextDefault>
                  {isWide || <IconPencil color={Colors.text} />}
                </FlexRow>
              </SliderContainer>
              {(showSlider || isWide) && (
                <View
                  style={{
                    marginTop: Spacings.S16,
                    paddingTop: Spacings.S16,
                    borderTopColor: Colors.border,
                    borderTopWidth: 1,
                  }}
                >
                  <UiSlider
                    min={0}
                    max={1000}
                    step={10}
                    initialValue={savingsPlan?.targetAmount ?? 0}
                    onChange={(value: string) => {
                      setMonthlyContribution(Number(value))
                    }}
                  />
                  <InfoContainer style={{ marginTop: Spacings.S24 }}>
                    <ParagraphBoldFirst color={Colors.textSecondary}>
                      {t('savingsPlan.monthly-saving-info')}
                    </ParagraphBoldFirst>
                  </InfoContainer>

                  {savingsPlan.targetAmount && monthlyContribution === 0 ? (
                    <RemoveSavingsPlanButton
                      t={t}
                      onPress={() => setConfirmationModalVisible(true)}
                    />
                  ) : (
                    <CreateSavingsPlanButton
                      t={t}
                      monthlyContribution={monthlyContribution}
                      disabled={
                        monthlyContribution === 0 ||
                        monthlyContribution === savingsPlan.targetAmount
                      }
                    />
                  )}
                </View>
              )}
            </BoxWithPadding>
          </View>
        </View>
        {isWide || (
          <CreateSavingsPlanButton
            t={t}
            monthlyContribution={monthlyContribution}
            disabled={
              monthlyContribution === 0 ||
              monthlyContribution === savingsPlan.targetAmount
            }
          />
        )}
        {savingsPlan?.transactions.length > 0 ? (
          <BoxWithPadding style={{ marginTop: Spacings.S24 }}>
            <TextDefault
              type="h5"
              style={{
                paddingBottom: Spacings.S10,
                borderBottomColor: Colors.border,
                borderBottomWidth: 1,
              }}
            >
              {t('savingsPlan.last-invests')}
            </TextDefault>
            {savingsPlan?.transactions.map(
              ({ id, amount, createdOn, orderType }) => (
                <FlexRow
                  key={id}
                  style={{
                    alignItems: 'center',
                    paddingTop: Spacings.S10,
                    paddingBottom: Spacings.S10,
                    borderBottomColor: Colors.border,
                    borderBottomWidth: 1,
                  }}
                >
                  <TextDefault
                    type="t5"
                    style={{
                      paddingRight: Spacings.S8,
                      marginRight: Spacings.S8,
                      borderRightColor: Colors.border,
                      borderRightWidth: 1,
                      minWidth: 70,
                    }}
                  >
                    {formatDateWithoutLeadingZeros(createdOn)}
                  </TextDefault>
                  <TextDefault style={{ flex: 1 }}>
                    {t(orderTypes[orderType] ?? '')}
                  </TextDefault>
                  <TextDefault type="h5">
                    {formatCurrency(Math.round(amount))}
                  </TextDefault>
                </FlexRow>
              )
            )}
          </BoxWithPadding>
        ) : null}
      </View>
    </>
  )
}

export default observer(SavingsPlanContent)

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: Spacings.S10,
  },
})
