import LegendHover from '@taaleri/components/src/screens/portfolio/LegendHover'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import Box from '@taaleri/components/src/ui/box/Box'
import {
  IconBenchMark,
  IconBenchMarkSelected,
} from '@taaleri/components/src/ui/icons/ChartIcons'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import { BenchMark } from '@taaleri/core/src/models/BenchMark'
import {
  addFriendlyBenchmarkName,
  BenchmarkCategory,
} from '@taaleri/core/src/models/benchmarkNames'
import { translateBenchmarkCategory } from '@taaleri/core/src/services/translationService'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import groupBy from 'lodash/groupBy'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import usePortal from 'react-useportal'

interface Props {
  style?: StyleProp<ViewStyle>
}

const benchmarkId = '#benchmarkSelection'
const customerBenchmarkName = 'Sopimuksen vertailuindeksi'
const customerBenchmarkNameKey = 'graph.benchmark.contract-benchmark'
const portfolioBenchmarkName = 'Salkun vertailuindeksi'
const portfolioBenchmarkNameKey = 'graph.benchmark.portfolio-benchmark'

const useScale = (
  showOwnIndexes: boolean,
  customerHasBenchMark: boolean,
  showPortfolioBenchmark: boolean,
  benchMarkList: BenchMark[],
  selectCustomerBenchmark: () => void,
  selectPortfolioBenchmark: () => void,
  selectBenchmarkByTicker: (
    ticker: string,
    name: string,
    nameSv: string
  ) => void,
  selectedBenchmark?: string,
  containerStyle?: any
) => {
  const { Portal, togglePortal, openPortal, isOpen, closePortal } = usePortal({
    bindTo: document.getElementById(benchmarkId) as HTMLElement,
  })
  const { t } = useTranslation()
  const getColor = (name: string) =>
    name === selectedBenchmark ? Colors.primary : Colors.text

  const groupedBenchmarks = groupBy(
    benchMarkList.map((b) => addFriendlyBenchmarkName(b)),
    'category'
  )

  const categoryOrder: BenchmarkCategory[] = [
    'Osakeindeksit',
    'Korkoindeksit',
    'Rahamarkkinaindeksit',
    'Muut indeksit',
  ]

  const categories = Object.keys(groupedBenchmarks).sort(
    (a, b) =>
      categoryOrder.indexOf(a as BenchmarkCategory) -
      categoryOrder.indexOf(b as BenchmarkCategory)
  )

  const BenchmarkSelection = () => (
    <Portal>
      <Animated.View style={containerStyle}>
        <Box style={styles.menu}>
          {showOwnIndexes && (
            <>
              <TextDefault
                type="h6"
                style={{ marginHorizontal: Spacings.PADDING }}
              >
                {t('graph.benchmark.own-indexes')}
              </TextDefault>
              {customerHasBenchMark && (
                <LegendHover>
                  <UiTouchable
                    style={{ marginHorizontal: Spacings.PADDING }}
                    onPress={() => {
                      selectCustomerBenchmark()
                      closePortal()
                    }}
                  >
                    <TextDefault
                      style={{ color: getColor(customerBenchmarkName) }}
                    >
                      {t(customerBenchmarkNameKey)}
                    </TextDefault>
                  </UiTouchable>
                </LegendHover>
              )}
              {showPortfolioBenchmark && (
                <LegendHover>
                  <UiTouchable
                    style={{ marginHorizontal: Spacings.PADDING }}
                    onPress={() => {
                      selectPortfolioBenchmark()
                      closePortal()
                    }}
                  >
                    <TextDefault
                      style={{ color: getColor(portfolioBenchmarkName) }}
                    >
                      {t(portfolioBenchmarkNameKey)}
                    </TextDefault>
                  </UiTouchable>
                </LegendHover>
              )}
            </>
          )}
          {categories.map((category) => (
            <View key={category}>
              <TextDefault
                type="h6"
                style={{ marginHorizontal: Spacings.PADDING }}
              >
                {translateBenchmarkCategory(category, t)}
              </TextDefault>
              {groupedBenchmarks[category].map(
                ({ securityId, name, friendlyName, nameSv }) => (
                  <LegendHover key={securityId}>
                    <UiTouchable
                      style={{ marginHorizontal: Spacings.PADDING }}
                      onPress={() => {
                        selectBenchmarkByTicker(securityId, name, nameSv)
                        closePortal()
                      }}
                    >
                      <TextDefault
                        numberOfLines={1}
                        style={{
                          color: getColor(name),
                        }}
                      >
                        {t(friendlyName)}
                      </TextDefault>
                    </UiTouchable>
                  </LegendHover>
                )
              )}
            </View>
          ))}
        </Box>
      </Animated.View>
    </Portal>
  )
  return {
    BenchmarkSelection,
    togglePortal,
    openPortal,
    isOpen,
    closePortal,
  }
}

export function BenchMarkMenu({ style }: Props) {
  const { isMediumPlus } = useLayout()
  const { benchMarkList, customerHasBenchMark, showPortfolioBenchmark } =
    ReportStore

  const showOwnIndexes = customerHasBenchMark || showPortfolioBenchmark

  const selectCustomerBenchmark = () => {
    ReportStore.fetchBenchmarkByPortfolioId()
    ReportStore.selectedBenchMark = customerBenchmarkNameKey
  }

  const selectPortfolioBenchmark = () => {
    if (ReportStore.singleSelectedPortfolio) {
      ReportStore.fetchBenchmarkByPortfolioId(
        ReportStore.singleSelectedPortfolio
      )
      ReportStore.selectedBenchMark = portfolioBenchmarkNameKey
    }
  }

  const selectPortfolioByTicker = (
    ticker: string,
    name: string,
    nameSv: string
  ) => {
    ReportStore.fetchBenchmarkByTicker(ticker)
    ReportStore.selectedBenchMark =
      AppStore.currentLanguage === fiKey ? name : nameSv
  }

  const pickerVisibility = new Animated.Value(1)

  const containerStyle = [
    {
      opacity: pickerVisibility.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      }),
      transform: [
        {
          scale: pickerVisibility.interpolate({
            inputRange: [0, 1],
            outputRange: [0.9, 1],
          }),
        },
      ],
    },
    isMediumPlus ? {} : { position: 'absolute', top: -80 },
  ]

  const { BenchmarkSelection, togglePortal, isOpen } = useScale(
    showOwnIndexes,
    customerHasBenchMark,
    showPortfolioBenchmark,
    benchMarkList,
    selectCustomerBenchmark,
    selectPortfolioBenchmark,
    selectPortfolioByTicker,
    ReportStore.selectedBenchMark,
    containerStyle
  )

  return (
    <View style={style}>
      <UiTouchable onPress={togglePortal}>
        {isOpen ? <IconBenchMarkSelected /> : <IconBenchMark />}
      </UiTouchable>
      {isOpen && <BenchmarkSelection />}
      <div id={benchmarkId} />
    </View>
  )
}

const styles = StyleSheet.create({
  menu: {
    position: 'absolute',
    paddingVertical: Spacings.PADDING,
    right: 0,
  },
})
