import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet, View } from 'react-native'

interface AuthorCardProps {
  author: string
}

const AuthorCard: React.FC<AuthorCardProps> = ({ author }) => {
  if (!author) {
    return null
  }
  return (
    <FlexRow
      style={[
        {
          alignItems: 'center',
          marginVertical: Spacings.S16,
          marginTop: 0,
        },
      ]}
    >
      {/* <Image
        style={styles.image}
        source={{
          uri: img,
        }}
      /> */}
      <View style={{ flexWrap: 'wrap', flex: 1 }}>
        <TextDefault type="t5" style={styles.text}>
          {author}
        </TextDefault>
      </View>
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  image: { width: 56, height: 56, marginRight: 12, borderRadius: 28 },
  text: { letterSpacing: 0.1, color: Colors.textDisabled },
  authorTitle: { color: Colors.textDisabled },
})

export default AuthorCard
