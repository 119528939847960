import React, { useState } from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { Formik } from 'formik'
import UiForm from '../../ui/form/UiForm'
import { View } from 'react-native'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import UiButton from '../../ui/UiButton'
import * as Yup from 'yup'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import useLayout from '../../ui/useLayout'
import { isWeb } from '../../constants/Platforms'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import { customers } from '@taaleri/core/src/services/customers'
import { openPhoneTupas } from './OpenTupas'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { CurrentValue } from './CurrentValue'
import { phoneNumberRegex } from '@taaleri/core/src/utils/format'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface PhoneChange {
  phone: string
}

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    phone: Yup.string()
      .matches(phoneNumberRegex, `${t(ErrorMessages.checkPhoneNumber)}`)
      .required(`${t(ErrorMessages.required)}`),
  })

export default function ChangePhoneForm() {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <Formik
      validationSchema={validationSchema(t)}
      initialValues={{ phone: '' }}
      onSubmit={async (values: PhoneChange) => {
        const phone = values.phone
        setLoading(true)
        const url = await customers.getTupasUrlChangePhone(phone)
        await openPhoneTupas(url, phone)
      }}
    >
      {({ handleSubmit }) => (
        <UiForm>
          <FormNextInputAutofocus>
            <View>
              <Title>{t('profile.change-phonenumber')}</Title>
              <Paragraph>{t('profile.phonenumber-change-info')}</Paragraph>
              <CurrentValue
                label={`${t('profile.current-phone')}`}
                value={AppStore.customer.phone}
              />

              <View style={{ marginTop: Spacings.S24 }}>
                <UiTextInputFormAutoFocus
                  name="phone"
                  label={`${t('form.phone-number')}`}
                  type="digits"
                  returnKeyType="done"
                  grayBackground={isSmall}
                />
              </View>
            </View>
            <UiButton
              containerStyle={{ marginTop: Spacings.S24 }}
              title={`${t('profile.proceed-to-login')}`}
              onPress={isWeb ? () => '' : handleSubmit}
              loading={isWeb && loading}
            />
          </FormNextInputAutofocus>
        </UiForm>
      )}
    </Formik>
  )
}
