import React from 'react'
import QuestionContainer from 'screens/questions/QuestionContainer'
import { observer } from 'mobx-react'
import PhotoIdContent from './PhotoIdContent'
import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_CAMERA,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'

function PhotoIdScreen() {
  return (
    <QuestionContainer>
      <PhotoIdContent
        type="onboarding"
        nextRoute={nextOnboardingRoute(ROUTE_ONBOARDING_CAMERA)}
      />
    </QuestionContainer>
  )
}

export default observer(PhotoIdScreen)
