import React from 'react'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import CheckRow from '../../ui/CheckRow'
import { useTranslation } from 'react-i18next'

export default function AcceptedIdentifications() {
  const { t } = useTranslation()
  return (
    <>
      <View style={{ marginBottom: Spacings.S24 }}>
        <CheckRow text={t('idUpdate.finnish-passport')} plusSign={true} />
        <CheckRow text={t('idUpdate.finnish-idcard')} plusSign={true} />
        <CheckRow
          text={t('idUpdate.finnish-driving-license')}
          plusSign={true}
        />
        <CheckRow text={t('idUpdate.foreign-passport')} plusSign={true} />
        <CheckRow text={t('idUpdate.foreign-id')} plusSign={true} />
      </View>
    </>
  )
}
