import Svg, { G, Polygon } from 'react-native-svg'
import React from 'react'

export const IconContainer: React.FC = ({ children }) => (
  <Svg width={24} height={24} viewBox="0 0 24 24">
    <G fill="none" fillRule="evenodd">
      <Polygon points="0 0 24 0 24 24 0 24" />
      {children}
    </G>
  </Svg>
)
