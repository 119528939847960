import React, { useState, useRef, useEffect } from 'react'
import { TextMedium, TextDefault } from '../../../ui/text/UiText'
import { StyleSheet, View, ScrollView } from 'react-native'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import Colors from '@taaleri/core/src/constants/Colors'
import { Paragraph } from '../../../ui/text/Paragraph'
import UiModal, { modalContentStyles } from '../../../ui/modal/UiModal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiTouchable from '../../../ui/UiTouchable'
import useLayout from '../../../ui/useLayout'
import FlexRow from '../../../ui/FlexRow'
import RiskInfoButton from './RiskInfoButton'
import { useTranslation } from 'react-i18next'

interface Props {
  maximumValue: number
  value: number
  suggestedValue: number
  riskLevels: RiskLevelIdentifier[]
  riskTolerance: number
}

export interface RiskLevelIdentifier {
  riskLevel: number
  strategyIdentifier: string
  maxImpact: number
}

function RiskSelector(props: Props) {
  const { t } = useTranslation()
  const scrollViewRef: any = useRef()
  const { isNonSmall } = useLayout()
  const [showModal, setShowModal] = useState<boolean>(false)
  const { maximumValue, value, suggestedValue, riskLevels, riskTolerance } =
    props

  useEffect(() => {
    if (scrollViewRef) {
      scrollViewRef.current.scrollToEnd({ animated: false })
    }
  }, [])

  function renderItem(item: RiskLevelIdentifier) {
    const { riskLevel, strategyIdentifier } = item
    const isDisabled = riskLevel > maximumValue
    const recommendedRiskLevel = Math.abs(suggestedValue - riskLevel) < 2
    const isCurrent = riskLevel === value
    const minLevel = 1
    const maxLevel = 10
    const isMinLevel = riskLevel === minLevel
    const isMaxLevel = riskLevel === maxLevel
    const recommendedMinus1 = riskLevel === suggestedValue - 1
    const recommendedPlus1 =
      riskLevel === Math.min(suggestedValue + 1, maximumValue)
    return (
      <View key={riskLevel} style={{ height: 60 }}>
        <UiTouchable
          key={riskLevel}
          onPress={() => {
            if (isDisabled) {
              setShowModal(true)
            } else {
              QuestionStore.riskLevel = riskLevel
              QuestionStore.strategyIdentifier = strategyIdentifier
            }
          }}
          style={[
            styles.backgroundColor,
            isMinLevel && value !== minLevel && styles.borderRadiusLeft,
            isMaxLevel && value !== maxLevel && styles.borderRadiusRight,
          ]}
        >
          <View
            style={[
              isCurrent && recommendedRiskLevel && styles.recommendedBackground,
              recommendedMinus1 && styles.borderRadiusLeft,
              recommendedPlus1 && styles.borderRadiusRight,
              isMinLevel && styles.borderRadiusLeft,
              isMaxLevel && styles.borderRadiusRight,
            ]}
          >
            <View
              style={[
                styles.box,
                isCurrent && styles.boxSelected,
                recommendedRiskLevel &&
                  !isDisabled &&
                  !isCurrent &&
                  styles.recommendedBackground,
                recommendedMinus1 && styles.borderRadiusLeft,
                recommendedPlus1 && styles.borderRadiusRight,
                isMinLevel && value !== minLevel && styles.borderRadiusLeft,
                isMaxLevel && value !== maxLevel && styles.borderRadiusRight,
              ]}
            >
              <TextMedium
                style={[
                  isDisabled && styles.textDisabled,
                  isCurrent && styles.textSelected,
                ]}
              >
                {riskLevel}
              </TextMedium>
            </View>
          </View>
        </UiTouchable>
        {riskLevel === suggestedValue && riskLevel < 9 && (
          <View
            style={{
              position: 'absolute',
              width: 100,
              bottom: 0,
              left: riskLevel > 1 ? -10 : 0,
            }}
          >
            <TextDefault type="t3">
              {t('app.calculator.recommended')}
            </TextDefault>
          </View>
        )}
      </View>
    )
  }

  let shareText = '-'
  if (value > suggestedValue + 1) {
    shareText = 'app.calculator.higher-than-recommended'
  }
  if (value < suggestedValue - 1) {
    shareText = 'app.calculator.lower-than-recommended'
  }

  let riskLevelText = 'app.calculator.risk-tolerant'
  if (riskTolerance > 7) {
    riskLevelText = 'app.calculator.risk-seeking'
  }
  if (riskTolerance < 4) {
    riskLevelText = 'app.calculator.risk-taker'
  }

  return (
    <View style={{ paddingBottom: Spacings.S24 }}>
      <UiModal
        isVisible={showModal}
        onBackdropPress={() => setShowModal(false)}
      >
        <View
          style={[
            modalContentStyles,
            { paddingTop: Spacings.S24, paddingBottom: Spacings.S24 },
          ]}
        >
          <Paragraph style={{ marginBottom: 0 }}>
            {t('app.calculator.maximum-risklevel', { value: maximumValue })}
          </Paragraph>
        </View>
      </UiModal>

      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginVertical: Spacings.S24,
        }}
      >
        <TextDefault type="h5">
          {t('app.calculator.risklevel')} {value}
        </TextDefault>
        <RiskInfoButton />
      </FlexRow>
      <Paragraph>
        {t('app.calculator.risk-description', { riskText: t(riskLevelText) })}
      </Paragraph>

      <TextDefault
        type="t2"
        style={[
          styles.shareNote,
          { color: shareText.length < 2 ? Colors.white : Colors.error },
        ]}
      >
        {t(shareText)}
      </TextDefault>

      <View style={isNonSmall && { alignItems: 'center' }}>
        <ScrollView
          ref={scrollViewRef}
          horizontal={true}
          contentContainerStyle={styles.container}
        >
          <View style={{ width: 10 }} />
          {riskLevels.map((r) => renderItem(r))}
          <View style={{ width: 10 }} />
        </ScrollView>
      </View>
    </View>
  )
}

export default RiskSelector

const styles = StyleSheet.create({
  container: {
    marginVertical: Spacings.S8,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  backgroundColor: {
    backgroundColor: Colors.background,
  },
  box: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.background,
  },
  textDisabled: {
    color: Colors.textDisabled,
  },
  boxSelected: {
    borderWidth: 0,
    backgroundColor: Colors.primary,
    borderRadius: 16,
  },
  textSelected: {
    color: Colors.white,
  },
  recommendedBackground: {
    backgroundColor: 'rgba(43, 201, 175, 0.3)',
  },
  borderRadiusLeft: {
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  borderRadiusRight: {
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },

  shareNote: {
    marginBottom: 4,
  },
})
