import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import ChangePasswordForm from '@taaleri/components/src/screens/profile/ChangePasswordForm'

export default function ChangePasswordScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <ChangePasswordForm />
    </ContentContainerBox>
  )
}
