import React from 'react'
import { View } from 'react-native'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_FUNDS_INVEST_DISTRIBUTION, ROUTE_DUE_DATE } from './FundsRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'

import { paymentFundSumValidationSchema } from '../portfolio/paymentSumValidationSchema'
import UiForm from '../../ui/form/UiForm'
import FormikWithRef from '../../ui/form/FormikWithRef'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import { observer } from 'mobx-react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { getInitialFundFormValues, FundForm } from './FundForm'
import { useTranslation } from 'react-i18next'

function FundInvestStartContent(props: any, ref: any) {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const initialValues = getInitialFundFormValues(props.recurring)
  return (
    <>
      <Title>
        {props.recurring
          ? t('app.calculator.monthlyinvestment')
          : t('app.calculator.oneoffinvestment')}
      </Title>
      <Paragraph>{t('invest.sum-title')}</Paragraph>
      <FormikWithRef
        ref={ref}
        initialValues={initialValues}
        validationSchema={paymentFundSumValidationSchema(t)}
        onSubmit={(values: FundForm) => {
          AppStore.paymentType = props.recurring ? 'recurring' : 'onetime'
          AppStore.newInvestment = Number(values.sum)
          navigateApp(
            props.recurring ? ROUTE_DUE_DATE : ROUTE_FUNDS_INVEST_DISTRIBUTION
          )
        }}
      >
        <UiForm>
          <FormNextInputAutofocus>
            <View style={{ marginTop: Spacings.S24 }}>
              <UiTextInputFormAutoFocus
                name="sum"
                label={`${t('invest.sum')}`}
                type="digits"
                returnKeyType="done"
                grayBackground={true}
              />
            </View>
          </FormNextInputAutofocus>
        </UiForm>
      </FormikWithRef>
    </>
  )
}

export default observer(React.forwardRef(FundInvestStartContent))
