import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

export const PaddingHorizontal: React.FC<{ style?: StyleProp<ViewStyle> }> = ({
  children,
  style,
}) => (
  <View style={[{ paddingHorizontal: Spacings.PADDING }, style]}>
    {children}
  </View>
)
