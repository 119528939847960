const success = (value: string) => {
  return
}
const error = (value: any) => {
  return
}

function screenView(screen: string) {
  return
}

export type EventCategory =
  | 'Camera'
  | 'Contact'
  | 'Contract'
  | 'Demo'
  | 'Faq'
  | 'Feedback'
  | 'Forgot password'
  | 'Home'
  | 'IdentityCard'
  | 'Impact'
  | 'Login'
  | 'Onboarding'
  | 'Payment'
  | 'Portfolio'
  | 'Product'
  | 'Profile'
  | 'PurchaseLots'
  | 'PushNotification'
  | 'PushNotifications'
  | 'Register'
  | 'ReportOrders'
  | 'Reports'
  | 'Transactions'
  | 'Nav'
  | 'Update'
  | 'Video'
  | 'Withdrawal'
  | 'Welcome'

function event(
  category: EventCategory,
  action: string,
  label?: string,
  value?: number
) {
  return
}

export default {
  screenView,
  event,
}
