import { KycUrl } from '@taaleri/core/src/constants/ExternalUrls'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER } from './ProfileRoutes'
import { routePathContract, routePathApp } from '../../navigation/routeHelper'
import { ROUTE_CONTRACTS } from '../../navigation/routes'
import CardWithLinks from '../../ui/CardWithLinks'
import { ListItemLinkData } from '../../ui/ListItem'
import OpenLink from '../../ui/OpenLink'
import { ROUTE_CONTRACT_START } from '../contract/ContractRoutes'
import { ROUTE_IDENTITY_CARD_PHOTO_ID } from '../identitycard/IdentityCardRoutes'

function ContractSection() {
  const { t } = useTranslation()

  const linkItems: ListItemLinkData[] = [
    ...insertIf(AppStore.showContractUpdate, {
      title: AppStore.isDigiCustomer
        ? t('profile.contractSection.update-contract')
        : t('profile.contractSection.update-kyc'),
      link: AppStore.isDigiCustomer
        ? routePathContract(ROUTE_CONTRACT_START)
        : '',
      onPress: () => {
        if (!AppStore.isDigiCustomer) {
          OpenLink(KycUrl, 'kyc')
        }
      },
    }),

    ...insertIf(AppStore.isBaseCustomerActive && AppStore.isPerson, {
      title: t('profile.contractSection.update-id'),
      link: routePathApp(ROUTE_IDENTITY_CARD_PHOTO_ID),
      onPress: () => {
        AppStore.investmentMode = 'default'
        QuestionStore.questionMode = 'contract'
      },
    }),

    ...insertIf(AppStore.isBaseCustomerActive && AppStore.isPerson, {
      title: t('profile.contractSection.update-bankno'),
      link: ROUTE_PROFILE_CHANGE_ACCOUNT_NUMBER,
    }),
    // ...insertIf(AppStore.isDigiDiscretionary, {
    //   title: `${t('profile.contractSection.portfolio-payment', {
    //     portfolio: AppStore.isDigiImpactCustomer
    //       ? `${t('impactIntro.infobox1.header')}`
    //       : `${t('profile.contractSection.etf')}`,
    //   })}`,
    //   link: ROUTE_PROFILE_DIGI_PAYMENT_INFO,
    // }),
    // ...insertIf(AppStore.isConsultative, {
    //   title: t('profile.contractSection.fund-portfolio-payment'),
    //   link: ROUTE_PROFILE_CONS_PAYMENT_INFO,
    // }),
    {
      title: t('reportBox.contracts'),
      link: routePathApp(ROUTE_CONTRACTS),
    },
  ]

  return (
    <CardWithLinks title={`${t('reportBox.contracts')}`} items={linkItems} />
  )
}

export default observer(ContractSection)
