import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ImageBackground, StyleSheet, View } from 'react-native'
import { TextBold } from '../../ui/text/UiText'
import {
  findFaqs,
  groupFaqByCategory,
  FaqCategoryIcon,
  FaqCategory,
} from '@taaleri/core/src/models/Faq'
import Colors from '@taaleri/core/src/constants/Colors'
import FaqList from './FaqList'
import useLayout from '../../ui/useLayout'
import FlexRow from '../../ui/FlexRow'
import {
  IconStart,
  IconAccount,
  IconContract,
  IconImpact,
  IconPortfolio,
  IconFund,
  IconHandShake,
} from './FaqIcons'
import FaqContactCustomerService from './FaqContactCustomerService'
import { useTranslation } from 'react-i18next'

export const faqCategoryIcons: FaqCategoryIcon = {
  'Sijoittamisen aloittaminen': <IconStart />,
  'Salkkuun liittyviä kysymyksiä': <IconPortfolio />,
  Asiakkuus: <IconAccount />,
  'Sopimukset ja raportointi': <IconContract />,
  'Impakti-sijoittaminen': <IconImpact />,
  'Sijoittaminen rahastoihin': <IconFund />,
  Sijoittaminen: <IconFund />,
}

export const faqTranslationkeys: FaqCategoryIcon = {
  'Sijoittamisen aloittaminen': 'faq.title-invest-start',
  'Salkkuun liittyviä kysymyksiä': 'app.faq.portfoliofaq.portfoliofaqheader',
  Asiakkuus: 'app.faq.customershipfaq.customershipfaqheader',
  'Sopimukset ja raportointi': 'faq.title-contract-reports',
  'Impakti-sijoittaminen': 'app.faq.impaktifaq.impaktifaqheader',
  'Sijoittaminen rahastoihin': 'app.faq.fundinvestingfaq.fundinvestingfaqheader',
  Sijoittaminen: 'app.faq.investingfaq.investingfaqheader',
}

export default function FaqContent() {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  const allFaqs = findFaqs()
  const faqs = allFaqs.filter(
    (f) =>
      (AppStore.isConsultative && f.group === 'Digi eligible/Digi kons') ||
      (AppStore.isDigiDiscretionary && f.group === 'Digi TV')
  )

  const categories = groupFaqByCategory(faqs)
  const categoryNames = Object.keys(categories) as FaqCategory[]

  const image = isNonSmall
    ? require('./hero-faq-desktop.jpg')
    : require('./hero-faq-mobile.jpg')
  return (
    <>
      <ImageBackground
        source={image}
        style={[styles.image, isNonSmall && styles.imageLarge]}
      >
        <TextBold style={[styles.text, isNonSmall && styles.textLarge]}>
           {t('faq.faq')}
        </TextBold>
      </ImageBackground>
      <View style={styles.contentContainer}>
        {categoryNames.map((c: FaqCategory) => {
          const category = categories[c]
          return (
            <View key={c} style={styles.categoryOuter}>
              <FlexRow style={styles.categoryContainer}>
                {faqCategoryIcons[c as FaqCategory]}
                <TextBold style={styles.categoryTitle}>{t(faqTranslationkeys[c as FaqCategory])}</TextBold>
              </FlexRow>
              <FaqList items={category} />
            </View>
          )
        })}
      </View>
      <FaqContactCustomerService />
    </>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    marginHorizontal: 'auto',
    maxWidth: 1000,
    paddingHorizontal: 20,
    marginBottom: 0,
  },
  image: {
    width: '100%',
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 48,
  },
  imageLarge: {
    height: 336,
  },
  text: {
    color: Colors.white,
    fontSize: 36,
  },
  textLarge: {
    fontSize: 46,
  },
  categoryOuter: {
    marginBottom: 60,
  },
  categoryContainer: {
    alignItems: 'center',
    marginBottom: 40,
  },
  categoryTitle: {
    fontSize: 18,
    lineHeight: 26,
    marginLeft: 20,
  },
})
