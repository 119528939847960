import React from 'react'
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import { TextBold } from './text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { LoadingIndicatorType } from './LoadingIndicator'

const LoadingIndicator: React.FC<{
  style?: StyleProp<ViewStyle>
  text?: string
  type?: LoadingIndicatorType
}> = ({ style, text, type }) => {
  const { isNonSmall } = useLayout()
  const isInline = type === 'inline'
  return (
    <View
      style={[
        styles.container,
        isNonSmall && styles.containerNonSmall,
        isInline && styles.inline,
        style,
      ]}
    >
      {text && <TextBold style={styles.text}>{text}</TextBold>}
      <ActivityIndicator size="large" />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    top: '40vh',
  },
  inline: {
    top: 0,
  },
  containerNonSmall: {
    top: '20vh',
  },
  text: {
    lineHeight: 22,
    marginBottom: Spacings.S32,
    paddingHorizontal: Spacings.S16,
    textAlign: 'center',
  },
})

export default LoadingIndicator
