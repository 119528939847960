import Spacings from '@taaleri/core/src/constants/Spacings'
import styled from 'styled-components'
import { BreakPoint } from '../../constants/BreakPoint'

const FundGridBase = styled.div`
  display: grid;
  grid-gap: 40px;
  align-items: start;
  margin-bottom: ${Spacings.S56}px;
  @media (min-width: ${BreakPoint.S}px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const FundGrid = styled(FundGridBase)`
  @media (min-width: ${BreakPoint.L}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const FundGridList = styled(FundGridBase)`
  @media (min-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
