import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { OrderPdf } from '@taaleri/core/src/models/ContractPdf'
import { Identity } from '@taaleri/core/src/models/Identity'
import { contractOrdersPdfList } from '@taaleri/core/src/services/contracts'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import take from 'lodash/take'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ReportBox from './ReportBox'
import ReportRowSmall from './ReportRowSmall'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_ORDERS } from '../../navigation/routes'
import useNavigation from '../../navigation/useNavigation'
import ContentLoader from '../../ui/ContentLoader'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { IconAssingmentLarge } from '../../ui/Icons'
import Box from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { GetContractUrl } from '../contracts/ContractsList'

const icon = (
  <IconAssingmentLarge
    style={{
      position: 'absolute',
      top: Spacings.S16,
      right: -Spacings.S32,
    }}
    color={Colors.backgroundIcon}
    scale={4}
  />
)

function ReportContractOrderBox() {
  const { navigateApp } = useNavigation()
  const [contractList, setContractList] = useState<OrderPdf[] | undefined>()
  const [token, setToken] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { t } = useTranslation()

  async function findContracts() {
    setError(false)
    setLoading(true)
    const result = await contractOrdersPdfList(AppStore.customerId)
    if (result.success) {
      setContractList(result.response)
      const identity: Identity = await getIdentity()
      setToken(identity.accessToken)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    findContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.customerId])

  useEffect(() => {
    findContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  if (error) {
    return <ErrorWithRetry onRetry={findContracts} />
  }

  const noContracts = contractList?.length === 0

  const { customerId } = AppStore
  return (
    <ReportBox
      title={t('reportBox.assignments')}
      subtitle={t('latests')}
      buttonTitle={`${t('reportBox.show-assignments')}`}
      onButtonPress={() => navigateApp(ROUTE_ORDERS)}
      icon={icon}
      backgroundColor={Colors.backgroundBlue}
    >
      <Box style={{ marginBottom: Spacings.S16 }}>
        {loading ? (
          <ContentLoader variant={isWeb ? 'flex' : 'facebook'} />
        ) : noContracts ? (
          <ReportRowSmall label={`${t('reportBox.no-assignments')}`} />
        ) : (
          take(contractList, 3).map((contract) => {
            const contractUrl = GetContractUrl(
              +contract.contractId, // + converts string to int
              customerId,
              token
            )
            return (
              <ReportRowSmall
                label={contract.financialInstrumentName}
                value={formatDateFull(contract.createdDate)}
                onPress={() => openUrl(contractUrl)}
                key={contract.contractId}
              />
            )
          })
        )}
      </Box>
    </ReportBox>
  )
}

export default observer(ReportContractOrderBox)
