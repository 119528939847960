import React from 'react'
import EtfComparisonTable from './EtfComparisonTable'
import { ROUTE_QUESTION_PORTFOLIO_TYPE } from './QuestionRoutes'
import { routePathQuestions } from '../../navigation/routeHelper'
import usePreliminaryProposal from './usePreliminaryProposal'
import LoadingIndicator from '../../ui/LoadingIndicator'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'

export default function OnboardingComparisonContent() {
  const backRoute = routePathQuestions(ROUTE_QUESTION_PORTFOLIO_TYPE)

  const { preliminaryProposal, error, setRetry } = usePreliminaryProposal()

  if (!preliminaryProposal) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const { expectedReturn } = preliminaryProposal.impactEtfDistribution
  const {
    impactShare,
    impactEtfDistribution,
    regularEtfDistribution,
    riskLevel,
  } = preliminaryProposal
  const co2Compensation = QuestionStore.getCo2Compensation20Years(
    expectedReturn,
    impactShare
  )

  return (
    <EtfComparisonTable
      {...{
        impactEtfDistribution,
        regularEtfDistribution,
        co2Compensation,
        riskLevel,
        backRoute,
      }}
    />
  )
}
