import React, { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import useLayout from '../../ui/useLayout'
import AppStore from '@taaleri/core/src/stores/AppStore'
import CalculatorSliders from './CalculatorSliders'
import CalculatorGraphContainer from './CalculatorGraphContainer'
import InvestmentButton from '../../ui/InvestmentButton'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { useTranslation } from 'react-i18next'

export interface CalculatorInputs {
  monthlyInvestment: number
  initialInvestment: number
  investmentTime: number
}

interface CalculatorContentProps {
  containerStyle?: StyleProp<ViewStyle>
}

function CalculatorContent({ containerStyle }: CalculatorContentProps) {
  const { t } = useTranslation()
  const [monthlyInvestment, setMonthlyInvestment] = useState<number>(100)
  const [initialInvestment, setInitialInvestment] = useState<number>(0)
  const { isWide } = useLayout()
  const age = AppStore.customerAge ?? undefined
  const initialInvestmentTime = 20 // age ? timetoRetirement(age) : 30
  const [investmentTime, setInvestmentTime] = useState<number>(
    initialInvestmentTime
  )
  const [includeMarketValue, setIncludeMarketValue] = useState<boolean>(false)
  const currentStrategy = AppStore.getCurrentStrategy
  const expectedReturn = currentStrategy ? currentStrategy.expectedReturn : 0.05
  const riskLevel = currentStrategy ? currentStrategy.riskLevel : 0
  const impactShare = currentStrategy ? 100 * currentStrategy.impactShare : 0
  const marketValue = AppStore.portfolioMarketValue
  const isPrivateEquity = AppStore.isPrivateCustomer
  const actualizedCo2Kilos = AppStore.customerCo2Actualized

  return (
    <View style={containerStyle}>
      <View style={{ flexDirection: isWide ? 'row' : 'column' }}>
        <CalculatorSliders
          {...{
            setMonthlyInvestment,
            setInitialInvestment,
            setInvestmentTime,
            setIncludeMarketValue,
            monthlyInvestment,
            initialInvestment,
            investmentTime,
            includeMarketValue,
            marketValue,
          }}
        />
        <CalculatorGraphContainer
          {...{
            expectedReturn,
            monthlyInvestment,
            initialInvestment,
            investmentTime,
            riskLevel,
            marketValue,
            age,
            impactShare,
            includeMarketValue,
            setIncludeMarketValue,
            actualizedCo2Kilos,
            isPrivateEquity,
          }}
        />
        {/* {isWide ? null : (
          <InvestmentButton style={{ marginTop: Spacings.S40 }} />
        )} */}
      </View>
      <TextDefault
        type="t5"
        style={{
          textTransform: 'none',
          marginTop: Spacings.S32,
          letterSpacing: 0.2,
          paddingTop: Spacings.S16,
          borderTopWidth: 1,
          borderColor: Colors.border,
        }}
      >
        {t('app.savingsplantooltip')}
      </TextDefault>
    </View>
  )
}

export default CalculatorContent
