import React from 'react'
import FlexRow from '../../ui/FlexRow'
import { ActivityIndicator, StyleSheet } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextMedium, TextBold } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'

interface ValueLabelProps {
  loading?: boolean
  hidden?: boolean
  label: string
  value: string
}

const ValueLabel = ({ value, label, loading, hidden }: ValueLabelProps) =>
  hidden ? null : (
    <FlexRow style={styles.valueRow}>
      <TextMedium style={styles.textPadding}>{label}</TextMedium>
      {loading ? (
        <ActivityIndicator style={styles.activityIndicator} />
      ) : (
        <TextBold style={styles.textPadding}>{value}</TextBold>
      )}
    </FlexRow>
  )

export default ValueLabel

const styles = StyleSheet.create({
  valueRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    borderColor: Colors.border,
    borderBottomWidth: 1,
  },
  textPadding: { paddingHorizontal: Spacings.S16 },
  activityIndicator: {
    backgroundColor: Colors.white,
    paddingHorizontal: Spacings.S16,
    flex: 0,
  },
})
