import React from 'react'
import { AppPaddingMedium } from 'screens/app/AppPadding'
import CalculatorContent from '@taaleri/components/src/screens/calculator/CalculatorContent'

function CalculatorScreen() {
  return (
    <AppPaddingMedium>
      <CalculatorContent />
    </AppPaddingMedium>
  )
}

export default CalculatorScreen
