import Colors from '@taaleri/core/src/constants/Colors'
import { AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { observer } from 'mobx-react'
import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'

import { IconExternalLink } from '../../ui/IconExternalLink'
import OpenLink from '../../ui/OpenLink'
import UiTouchable from '../../ui/UiTouchable'
import { getSecurityPdfUrl } from '../reporttransaction/TransactionPdfUrl'

interface Props {
  assetClassItem: AssetClassItem
  style?: StyleProp<ViewStyle>
  hidden?: boolean
}

function SecurityPdfButton({ assetClassItem, style, hidden }: Props) {
  if (hidden) {
    return null
  }
  const { ticker, isSecurityDocumentAvailable } = assetClassItem
  const { token } = ReportStore
  const { customerId } = AppStore
  if (!isSecurityDocumentAvailable || !token) {
    return <View style={style} />
  }
  const url = getSecurityPdfUrl(customerId, ticker, token)

  return <PdfButton {...{ url, style }} />
}

export function PdfButton({
  url,
  style,
  color,
}: {
  url: string
  style?: StyleProp<ViewStyle>
  color?: string
}) {
  return (
    <UiTouchable style={style} onPress={() => OpenLink(url, 'security-pdf')}>
      <IconExternalLink color={color ?? Colors.gray50} />
    </UiTouchable>
  )
}

export default observer(SecurityPdfButton)
