import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import AppStore from '@taaleri/core/src/stores/AppStore'

import AssetClassBackground from 'screens/portfolio/AssetClassBackground'
import { Content } from 'screens/app/Content'
import { AppPadding } from 'screens/app/AppPadding'
import MarketValueBox from 'screens/portfolio/MarketValueBox'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import { ROUTE_PORTFOLIO } from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import AssetDetail from '@taaleri/components/src/screens/portfolio/AssetDetail'

function AssetClassScreen() {
  const { assetClassId } = useParams<{ assetClassId: string }>()
  const history = useHistory()
  const assetClass = AppStore.getAssetClassById(
    assetClassId,
    AppStore.portfolio
  )
  if (!assetClass) {
    history.push(routePathApp(ROUTE_PORTFOLIO))
    return null
  }
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
      }}
    >
      <AssetClassBackground assetClass={assetClass} />
      <MarketValueBox assetClass={assetClass} />
      <Content>
        <AppPadding>
          <AssetDetail assetClass={assetClass} />
        </AppPadding>
      </Content>
    </View>
  )
}

export default observer(AssetClassScreen)
