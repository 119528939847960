import { View, StyleProp, ViewStyle } from 'react-native'
import Svg from 'react-native-svg'
import React from 'react'
import PercentagePie from './PercentagePie'
import Colors from '@taaleri/core/src/constants/Colors'

interface Props {
  size?: number
  style?: StyleProp<ViewStyle>
  impactShare: number
}

export default function LiquidityPie({
  size = 120,
  style,
  impactShare,
}: Props) {
  const pieRadius = size / 2
  const data = [
    { x: 'current', y: 100 - impactShare },
    { x: 'other', y: impactShare },
  ]
  const colorScale = [Colors.primary, Colors.gray30]
  const borderSize = 12

  return (
    <View style={style}>
      <Svg width={size} height={size}>
        <PercentagePie
          size={size}
          pieRadius={pieRadius}
          data={data}
          colorScale={colorScale}
          borderSize={borderSize}
        />
      </Svg>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </View>
  )
}
