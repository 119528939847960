import React from 'react'
import { View } from 'react-native'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_RECURRING_ORDERS_LIST } from '@taaleri/components/src/screens/recurringorders/RecurringOrderRoutes'
import RecurringOrderCancelDone from '@taaleri/components/src/screens/recurringorders/RecurringOrderCancelDone'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import { useTranslation } from 'react-i18next'

export default function RecurringOrderCancelDoneScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <ContentContainerBox>
      <View style={{ justifyContent: 'space-between', flex: 1 }}>
        <RecurringOrderCancelDone />
        <Buttons
          title={t('button.close')}
          onPress={() => navigateApp(ROUTE_RECURRING_ORDERS_LIST)}
        />
      </View>
    </ContentContainerBox>
  )
}
