import React from 'react'
import { View, StyleSheet } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'

export default function Spacer() {
  return <View style={styles.divider} />
}

const styles = StyleSheet.create({
  divider: {
    height: Spacings.S24,
  },
})
