import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet, StyleProp, View, TextStyle, ViewStyle } from 'react-native'
import { TextDefault } from '../text/UiText'
import UiButton from '../UiButton'
import UiModal, { modalContentStyles } from './UiModal'
import { isWeb } from '../../constants/Platforms'
import { TFunction } from 'i18next'


type ButtonDirection = 'row' | 'column'
interface Props {
  isVisible: boolean
  title?: string
  content: string
  icon?: Element
  onNo?: () => void
  onYes: (t:TFunction) => void
  yesText?: string
  contentStyle?: StyleProp<TextStyle>
  noText?: string
  children?: any
  loading?: boolean
  buttonDirection?: ButtonDirection
  hideContainer?: boolean
}

function Container({children, hideContainer, style}: { children: any, hideContainer?: boolean, style: StyleProp<ViewStyle> }){
  if (hideContainer) {
    return <>{children}</>
  }
  return <View style={styles.container}>{children}</View>
}

export default function YesNoModal({
  icon,
  isVisible,
  title,
  content,
  onNo,
  onYes,
  yesText = 'Kyllä',
  noText = 'Ei',
  contentStyle,
  children,
  loading,
  buttonDirection,
  hideContainer,
}: Props) {
  const columnDirection = buttonDirection === 'column'
  return (
    <Container style={styles.container} hideContainer={hideContainer}>
      <UiModal isVisible={isVisible} style={{ marginHorizontal: Spacings.S24 }}>
        <View style={styles.modalContent}>
          {icon && <View style={styles.icon}>{icon}</View>}

          {title && (
            <TextDefault type="h3" style={styles.textMain}>
              {title}
            </TextDefault>
          )}

          <TextDefault style={[styles.textNote, contentStyle]}>
            {content}
          </TextDefault>
          {children && children}
          <View
            style={[
              styles.modalButtons,
              columnDirection && styles.modalButtonsColumn,
            ]}
          >
            {onNo && (
              <View
                style={[
                  styles.flex,
                  columnDirection
                    ? styles.buttonMarginBottom
                    : styles.buttonMargin,
                ]}
              >
                <UiButton
                  buttonStyle={{
                    paddingHorizontal: Spacings.S8,
                  }}
                  containerStyle={{ paddingHorizontal: 0 }}
                  size={columnDirection ? 'default' : 'small'}
                  type="secondary"
                  title={noText}
                  onPress={onNo}
                />
              </View>
            )}

            <View style={styles.flex}>
              <UiButton
                buttonStyle={{ paddingHorizontal: Spacings.S8 }}
                size={onNo && !columnDirection ? 'small' : 'default'}
                title={yesText}
                onPress={onYes}
                loading={loading}
              />
            </View>
          </View>
        </View>
      </UiModal>
    </Container>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: Spacings.S16,
    alignItems: 'center',
  },
  textMain: {
    textAlign: 'center',
  },
  textNote: {
    marginTop: Spacings.S16,
    textAlign: 'center',
  },
  flex: {
    flex: 1,
  },
  buttonMargin: {
    marginRight: Spacings.S24,
  },
  buttonMarginBottom: {
    marginBottom: Spacings.S24,
  },
  modalButtons: {
    marginBottom: Spacings.S24,
    marginTop: Spacings.S32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  modalButtonsColumn: {
    flexDirection: 'column',
    height: 136,
    marginBottom: isWeb ? Spacings.S16 : Spacings.S40,
  },
  modalContent: modalContentStyles,
})
