import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import {
  routePathApp,
  routePathContract,
} from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_CONTRACT_START } from '@taaleri/components/src/screens/contract/ContractRoutes'
import ContractUpdateBox from '@taaleri/components/src/screens/home/ContractUpdateBox'
import IdentityCardUpdateBox from '@taaleri/components/src/screens/home/IdentityCardUpdateBox'
import SimpleFeedbackContent from '@taaleri/components/src/screens/home/simplefeedback/SimpleFeedbackContent'
import { ROUTE_IDENTITY_CARD_PHOTO_ID } from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'
import AuthorizationsModal from '@taaleri/components/src/screens/portfolio/AuthorizationsModal'
import PaymentReceivedModal from '@taaleri/components/src/screens/portfolio/PaymentReceivedModal'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { CustomerAuthorization } from '@taaleri/core/src/models/Customer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import logger from '@taaleri/core/src/utils/logger'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { customerChangeToDataLayer } from 'utils/DataLayer'
import WarningNotificationModal from '@taaleri/components/src/screens/home/WarningNotificationModal'

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: ${Spacings.S10}px;
  margin-right: ${Spacings.S10}px;
  z-index: 10;
`

const FeedbackContainer = styled.div`
  margin: 0 auto 10px auto;
  @media (min-width: ${BreakPoint.S}px) {
    max-width: 600px;
  }
`

const UpdateContainer = styled.div`
  margin: 0 auto 10px auto;
  max-width: 1200px;
`

function FrontModals() {
  const history = useHistory()
  const showFeedback =
    !AppStore.simpleFeedbackDismissed && AppStore.showFeedbackModal
  const showBox =
    showFeedback ||
    (AppStore.showContractBox &&
      AppStore.showContractUpdate &&
      !AppStore.isConsultativeWithoutContract) ||
    AppStore.showIdentityCardBox

  return (
    <>
      <PaymentReceivedModal />
      <AuthorizationsModal
        defaultCustomerSelection={true}
        isVisible={AppStore.showDefaultCustomerSelection}
        onSelectCustomer={async (
          customerAuthorization: CustomerAuthorization
        ) => {
          AppStore.showDefaultCustomerSelection = false
          await AppStore.changeCustomer(customerAuthorization)
          customerChangeToDataLayer(AppStore.customerId)
        }}
      />
      {showBox && (
        <ModalContainer>
          {showFeedback && (
            <FeedbackContainer>
              <SimpleFeedbackContent />
            </FeedbackContainer>
          )}
          <UpdateContainer>
            <ContractUpdateBox
              onPress={() =>
                history.push(routePathContract(ROUTE_CONTRACT_START))
              }
            />
          </UpdateContainer>
        </ModalContainer>
      )}
      <WarningNotificationModal />
    </>
  )
}

export default observer(FrontModals)
