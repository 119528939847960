import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import YesNoModal from '../../ui/modal/YesNoModal'
import { useTranslation } from 'react-i18next'
import { fiKey } from '@taaleri/core/src/i18n'

function WarningNotificationModal() {
  const { t } = useTranslation()
  const { warningNotificationData } = AppStore

  if (!warningNotificationData) {
    return null
  }

  const header =
    AppStore.currentLanguage === fiKey
      ? warningNotificationData.header
      : warningNotificationData.headerSe
  const paragraph =
    AppStore.currentLanguage === fiKey
      ? warningNotificationData.paragraph
      : warningNotificationData.paragraphSe
  const showWarningNotificationModal =
    AppStore.warningNotificationData.ongoing &&
    !AppStore.warningNotificationDismissed

  return (
    <YesNoModal
      isVisible={showWarningNotificationModal}
      title={header}
      content={paragraph}
      onYes={() => {
        AppStore.setWarningNotificationDismissed(true)
      }}
      yesText={`${t('button.continue')}`}
    />
  )
}

export default observer(WarningNotificationModal)
