import React from 'react'
import IdentityCardSummaryContent, {
  identityCardButtonTitle,
  identityCardSummaryNextScreen,
} from '@taaleri/components/src/screens/identitycard/IdentityCardSummaryContent'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function IdentityCardSummaryScreen() {
  const { push } = useHistory()
  const { t } = useTranslation()
  return (
    <BackgroundContainerApp>
      <TopBottomContainer>
        <IdentityCardSummaryContent />
        <Buttons
          title={identityCardButtonTitle(t)}
          onPress={() => push(identityCardSummaryNextScreen())}
        />
      </TopBottomContainer>
    </BackgroundContainerApp>
  )
}
