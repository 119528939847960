import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { IconProps } from '../IconProps'

interface DirectionProps extends IconProps {
  direction: 'left' | 'right'
}

export function IconArrowRightSlim({
  style,
  color = Colors.inactiveTint,
}: IconProps) {
  return (
    <View style={style}>
      <Svg width="13" height="7" viewBox="0 0 13 7" fill="none">
        <Path
          d="M12.5 3.3313H1M12.5 3.3313L10 5.8313M12.5 3.3313L10 0.831299"
          stroke={color}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </View>
  )
}

export function IconArrowLeft(iconProps: IconProps) {
  return <IconArrow {...iconProps} direction="left" />
}

export function IconArrowRight(iconProps: IconProps) {
  return <IconArrow {...iconProps} direction="right" />
}

function IconArrow({ style, color = Colors.text, direction }: DirectionProps) {
  const isLeft = direction === 'left'
  return (
    <View style={style}>
      <Svg
        width={19}
        height={10}
        fill="none"
        viewBox="0 0 19 10"
        style={{
          transform: [{ rotateZ: `${isLeft ? 180 : 0}deg` }],
        }}
      >
        <Path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 5H1m17 0l-3.696 4M18 5l-3.696-4"
        />
      </Svg>
    </View>
  )
}

export function IconArrowUpSlim({ style, color = Colors.primary }: IconProps) {
  return (
    <View style={style}>
      <Svg width="6" height="12" viewBox="0 0 6 12" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.98297 2.55085L3.48297 0L0.982971 2.55085H2.98301V12H3.98296V2.55085H5.98297Z"
          fill={color}
        />
      </Svg>
    </View>
  )
}

export function IconArrowDownSlim({ style, color = Colors.error }: IconProps) {
  return (
    <View style={style}>
      <Svg width="5" height="12" viewBox="0 0 5 12" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.44915L2.5 12L5 9.44915H2.99996L2.99996 4.76837e-05H2.00001L2.00001 9.44915L0 9.44915Z"
          fill={color || Colors.error}
        />
      </Svg>
    </View>
  )
}
