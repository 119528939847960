import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import AppStore from '@taaleri/core/src/stores/AppStore'
import FlexRow from '../../ui/FlexRow'
import { IconBuyOrder, IconWithdrawOrder } from '../../ui/icons/OrderIcons'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextDefault, TextBold } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { formatCurrency, formatDateFull } from '@taaleri/core/src/utils/format'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { observer } from 'mobx-react'
import { boxPadding } from '../../ui/box/Box'
import UiTouchable from '../../ui/UiTouchable'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { IconChevronDown, IconChevronUp } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'

interface OrderItem {
  title: string
  text: string
  icon: any
  key: string
}

function PortfolioOrdersList() {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const incomingInvestmentConfirmed = AppStore.incomingInvestmentConfirmed
  const newWithdrawalConfirmed = AppStore.newWithdrawalConfirmed
  const hasNewInvestment = incomingInvestmentConfirmed > 0
  const hasNewWithdrawal = newWithdrawalConfirmed > 0
  const hasWithdrawAll = AppStore.withdrawAll
  const openOrders = AppStore.openOrders

  const items: OrderItem[] = [
    ...insertIf(hasNewInvestment, {
      title: `${t('app.lastupdates.additional-purchase-title', {
        currency: formatCurrency(incomingInvestmentConfirmed),
      })}`,
      text: `${t('app.lastupdates.additional-purchase-delay')}`,
      icon: IconBuyOrder,
      key: 'buy',
    }),
    ...insertIf(hasNewWithdrawal, {
      title: `${t('app.lastupdates.withdraw-waiting-title', {
        currency: formatCurrency(newWithdrawalConfirmed),
      })}`,
      text: `${t('app.lastupdates.description')}`,
      icon: IconWithdrawOrder,
      key: 'withdraw',
    }),
    ...insertIf(hasWithdrawAll, {
      title: `${t('app.lastupdates.withdraw-all-title')} ${
        AppStore.isDigiEtfCustomer
          ? t('from-etf-portfolio')
          : t('from-impact-portfolio')
      }`,
      text: `${t('app.lastupdates.description')}`,
      icon: IconWithdrawOrder,
      key: 'withdrawall',
    }),
  ]

  const transactions = openOrders.map((o) => {
    return o.orderType === 'Buy'
      ? {
          title: `${t('app.lastupdates.additional-purchase-title', {
            currency: formatCurrency(incomingInvestmentConfirmed),
          })} ${o.securityName}`,
          text: `${t('app.lastupdates.next-invest-day', {
            value: o.dueDate
              ? formatDateFull(o.dueDate)
              : t('app.lastupdates.next-subscription-day'),
          })}`,
          icon: IconBuyOrder,
          key: o.id,
        }
      : o.sellEverything
      ? {
          title: `${t('app.lastupdates.withdrawed-all-title', {
            security: o.securityName,
          })}`,
          text: `${t('app.lastupdates.withdraw-asap')}`,
          icon: IconWithdrawOrder,
          key: o.id,
        }
      : {
          title: `${t('app.lastupdates.withdraw-title', {
            currency: formatCurrency(o.amount),
            security: o.securityName,
          })}`,
          text: `${t('app.lastupdates.withdraw-asap')}`,
          icon: IconWithdrawOrder,
          key: o.id,
        }
  })

  transactions && items.push(...transactions)

  if (items.length === 0) {
    return null
  }

  return (
    <View>
      <UiTouchable
        onPress={() => {
          setOpen(!open)
        }}
        style={styles.toggleRow}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <View style={styles.circle}>
            <TextBold
              color={Colors.white}
              size={FontSize.S10}
              style={{ lineHeight: FontSize.S20 }}
            >
              {items.length < 10 ? items.length : ''}
            </TextBold>
          </View>
          <TextDefault
            type="t5"
            style={{ marginLeft: Spacings.S8, color: Colors.text }}
          >
            {t('app.lastupdates.open-orders')}
          </TextDefault>
        </FlexRow>
        {!open && <IconChevronDown color={Colors.text} />}
        {open && <IconChevronUp />}
      </UiTouchable>
      {open &&
        items.map((l) => (
          <FlexRow style={styles.assignment} key={l.key}>
            <View style={{ flex: 1, paddingRight: Spacings.S8 }}>
              <TextDefault type="t2" style={{ marginLeft: Spacings.S16 }}>
                {l.title}
              </TextDefault>
              <TextDefault
                type="t3"
                color={Colors.textSecondary}
                style={{ marginLeft: Spacings.S16 }}
              >
                {l.text}
              </TextDefault>
            </View>
            {React.createElement(l.icon, {
              style: styles.icon,
            })}
          </FlexRow>
        ))}
    </View>
  )
}

const styles = StyleSheet.create({
  toggleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: boxPadding,
    paddingVertical: Spacings.S16,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  assignment: {
    borderTopColor: Colors.border,
    borderTopWidth: 1,
    paddingRight: boxPadding,
    paddingVertical: Spacings.S16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    borderLeftColor: Colors.border,
    borderLeftWidth: 1,
    paddingLeft: Spacings.S16,
    paddingVertical: Spacings.S8,
  },
  circle: {
    width: Spacings.S20,
    height: Spacings.S20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: Colors.warning,
  },
})

export default observer(PortfolioOrdersList)
