import React from 'react'
import { getAssetClassWideImageById } from '@taaleri/core/src/constants/AssetClass'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import AssetDetailBox from './AssetDetailBox'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useNavigation from '../../navigation/useNavigation'
import TouchableShrink from '../../ui/TouchableShrink'
import useAssetClassDetailRoute from '../portfolio/useAssetClassDetailRoute'
import { useTranslation } from 'react-i18next'

function AssetDetails() {
  const { t } = useTranslation()
  const { portfolio } = AppStore
  const { navigateApp } = useNavigation()

  if (!portfolio) {
    return null
  }

  const assetClasses = portfolio.assetClasses

  const weights = assetClasses.map((s) => s.percentageOfTotal)

  return (
    <>
      {assetClasses.map((assetClass) => {
        const image = getAssetClassWideImageById(assetClass.id)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { routeName, params } = useAssetClassDetailRoute(assetClass)
        return (
          <TouchableShrink
            key={assetClass.id}
            style={{ marginBottom: Spacings.S24 }}
            onPress={() => {
              navigateApp(routeName, params)
            }}
          >
            <AssetDetailBox
              image={image}
              weights={weights}
              weight={assetClass.percentageOfTotal}
              title={t(assetClass.name)}
              marketValue={assetClass.marketValue}
            />
          </TouchableShrink>
        )
      })}
    </>
  )
}

export default observer(AssetDetails)
