import Colors from '@taaleri/core/src/constants/Colors'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'
import { observer } from 'mobx-react'
import React from 'react'

import { TextDefault } from '../../ui/text/UiText'
import { AssetClassIconEmpty } from '../report/AssetClassIcon'
import FilterButton from '../report/FilterButton'
import PositionContainer from '../report/PositionContainer'

function TypeFilter() {
  const { allTransactionTypes, selectedTransactionTypes } = ProfitStore

  if (allTransactionTypes.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allTransactionTypes
        .filter((a) => !selectedTransactionTypes.includes(a))
        .map((t) => (
          <FilterButton
            key={t}
            onPress={() => ProfitStore.addSelectedTransactionType(t)}
            selected={false}
          >
            <AssetClassIconEmpty />
            <TextDefault
              type="t2"
              style={{
                color: Colors.text,
                letterSpacing: 0.2,
              }}
            >
              {t}
            </TextDefault>
          </FilterButton>
        ))}
    </PositionContainer>
  )
}

export default observer(TypeFilter)
