import ISecurityPriceApi from './ISecurityPriceApi'
import { Identity } from '../models/Identity'
import { SecurityPrice } from '../models/SecurityPriceResponse'

async function getSecurityPrices(
  _: Identity,
  _1: string,
  _2: string,
  _3: string
): Promise<SecurityPrice> {
  const response = require('../fixtures/securityPrices/securityPrices.json')
  return Promise.resolve(response)
}

export const securityPricesApiMock: ISecurityPriceApi = {
  getSecurityPrices,
}
