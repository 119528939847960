import React from 'react'

import SuccessMessage from '../../components/SuccessMessage'
import { Paragraph } from '../../ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function PaymentWithdrawalDoneContent() {
  const { t } = useTranslation()
  return (
    <SuccessMessage title={t('invest.assignment-received')}>
      <Paragraph>
        {t('invest.after-transaction-done-info')}
      </Paragraph>
    </SuccessMessage>
  )
}
