import React from 'react'
import { TitleWithoutNav } from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function CustomerNotFoundContent() {
  const { t } = useTranslation()
  return (
    <>
      <TitleWithoutNav>{t('signin.customer-info-not-found')}</TitleWithoutNav>
      <Paragraph>{t('signin.not-yet-customer')}</Paragraph>
    </>
  )
}
