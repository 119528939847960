import React, { useState } from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { ScrollView, StyleSheet, View } from 'react-native'
import useLayout from '../ui/useLayout'
import Colors from '@taaleri/core/src/constants/Colors'
import UiTouchable from '../ui/UiTouchable'
import { TextDefault } from '../ui/text/UiText'
import { BoxWithPaddingMargin } from '../ui/box/Box'
import FlexRow from '../ui/FlexRow'
import { IconDownload } from '../ui/Icons'
import GlobalStyles from '../constants/GlobalStyles'
import UiButton, { ButtonSecondaryWithChildren } from '../ui/UiButton'
import { isWeb } from '../constants/Platforms'
import { BorderedContainer } from '../ui/BorderedContainer'
import { FlexContainer } from '../ui/FlexContainer'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { replaceContractName } from '@taaleri/core/src/utils/documentNameHelper'

export interface ITabItem {
  title: string
  items: ITabListItem[]
}

export interface ITabListItem {
  id: any
  tabValue: string
  title: string
  titleRight: string
}

export function tabReducer(prev: ITabItem[], curr: ITabListItem): ITabItem[] {
  const index = prev.findIndex((value) => value.title === curr.tabValue)
  if (index === -1) {
    prev.push({ title: curr.tabValue, items: [curr] })
  } else {
    prev[index].items.push(curr)
  }
  return prev
}

export function TabBox({
  titles,
  onListItemPressed,
  emptyText,
}: {
  titles: ITabItem[]
  onListItemPressed: (item: ITabListItem) => void
  emptyText: string
}) {
  const { isSmall } = useLayout()
  const [selectedTab, setSelectedTab] = useState<ITabItem | undefined>(
    titles[0]
  )
  const [page, setPage] = useState<number>(1)
  const rowsPerPage = 20

  if (!selectedTab) {
    return (
      <BoxWithPaddingMargin
        style={{ marginHorizontal: isWeb ? 0 : Spacings.S8 }}
      >
        <BorderedContainer style={{ marginBottom: 0 }}>
          <TextDefault type="h6">{emptyText}</TextDefault>
        </BorderedContainer>
      </BoxWithPaddingMargin>
    )
  }

  const allRows = selectedTab.items.length
  const showedRows = Math.min(page * rowsPerPage, allRows)

  return (
    <>
      <ScrollView
        style={[
          {
            marginBottom: isSmall ? Spacings.S24 : 0,
            marginRight: Spacings.S8,
          },
          !isWeb && { paddingHorizontal: Spacings.S8 },
        ]}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        {titles.map((item: ITabItem, index) => {
          return (
            <TabButton
              key={index}
              isSmall={isSmall}
              title={item.title}
              selectedCategory={selectedTab.title}
              setSelectedCategory={(_) => {
                setSelectedTab(item)
              }}
            />
          )
        })}
      </ScrollView>
      <BoxWithPaddingMargin
        style={{
          borderTopStartRadius: isSmall ? 6 : 0,
          marginHorizontal: isWeb ? 0 : Spacings.S8,
        }}
      >
        {selectedTab.items
          .slice(0, page * rowsPerPage)
          .map((listItem, index) => {
            return (
              <TabListItem
                key={index}
                isSmall={isSmall}
                iListItem={listItem}
                onPressed={() => onListItemPressed(listItem)}
              />
            )
          })}
        <View style={{ alignItems: 'center' }}>
          <TextDefault
            type="t2"
            style={{ marginTop: Spacings.S24 }}
          >{`Näytetään ${showedRows}/${allRows}`}</TextDefault>
          {allRows > showedRows && (
            <UiButton
              title="Lataa lisää"
              onPress={() => setPage(page + 1)}
              containerStyle={{
                marginTop: Spacings.S24,
                width: '100%',
                maxWidth: 327,
              }}
            />
          )}
        </View>
      </BoxWithPaddingMargin>
    </>
  )
}

export function TabButton({
  isSmall,
  title,
  selectedCategory,
  setSelectedCategory,
}: {
  isSmall: boolean
  title: string
  selectedCategory: string
  setSelectedCategory: (tab: string) => void
}) {
  const isSelected = title === selectedCategory

  return (
    <UiTouchable
      style={[
        styles.tabButton,
        isSelected && GlobalStyles.shadow,
        {
          backgroundColor: isSelected ? Colors.white : Colors.backgroundPale,
          borderTopWidth: isSelected ? Spacings.S2 : 0,
          borderBottomRightRadius: isSmall ? 6 : 0,
          borderBottomLeftRadius: isSmall ? 6 : 0,
          zIndex: 10,
        },
      ]}
      onPress={() => setSelectedCategory(title)}
    >
      <TextDefault
        type="h6"
        style={{ color: isSelected ? Colors.text : Colors.gray70 }}
      >
        {title}
      </TextDefault>
    </UiTouchable>
  )
}

function TabListItem({
  isSmall,
  iListItem,
  onPressed,
}: {
  isSmall: boolean
  iListItem: ITabListItem
  onPressed: () => void
}) {
  return (
    <BorderedContainer>
      <FlexContainer>
        <FlexRow
          style={{
            flex: 3,
            flexDirection: isSmall ? 'column' : 'row',
            paddingBottom: isSmall ? Spacings.S8 : 0,
          }}
        >
          <TextDefault type="t2" style={{ paddingRight: Spacings.S8 }}>
            {iListItem.titleRight}
          </TextDefault>
          <TextDefault type="h6">
            {replaceContractName(iListItem.title, AppStore.currentLanguage)}
          </TextDefault>
        </FlexRow>
        <View style={{ alignItems: 'flex-start' }}>
          <ButtonSecondaryWithChildren onPress={onPressed}>
            <FlexRow>
              <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
                PDF
              </TextDefault>
              <IconDownload />
            </FlexRow>
          </ButtonSecondaryWithChildren>
        </View>
      </FlexContainer>
    </BorderedContainer>
  )
}

const styles = StyleSheet.create({
  tabButton: {
    paddingVertical: Spacings.S16,
    paddingHorizontal: Spacings.S20,
    marginRight: Spacings.S8,
    borderTopColor: Colors.primary,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  listButton: {
    paddingVertical: Spacings.S10,
    paddingHorizontal: Spacings.S10,
    marginRight: Spacings.S8,
    marginLeft: Spacings.S8,
    borderTopColor: Colors.gray30,
    borderRadius: 6,
  },
})
