import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import {
  formatCurrency,
  formatQuantity,
  formatDateFull,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import { OwnerShipRowItem } from '@taaleri/components/src/screens/portfolio/OwnerShipRowItem'
import { OwnershipRowStyles } from '@taaleri/components/src/screens/portfolio/OwnershipRow'
import {
  HeadingItem,
  HeadingText,
} from '@taaleri/components/src/screens/portfolio/OwnerShipHeadingSimple'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { isPrivateEquity } from '@taaleri/core/src/constants/AssetClass'
import { useTranslation } from 'react-i18next'

interface Props {
  purchaseLot: PurchaseLot
  color?: string
  topBorderRadius?: boolean
  bottomBorderRadius?: boolean
  borderBottom?: boolean
  isTotalRow?: boolean
}

const styles = OwnershipRowStyles

export function PurchaseLotHeading() {
  const { t } = useTranslation()
  const headingItems: HeadingItem[] = [
    {
      text: `${t('purchaselots.acquisition-date')}`,
      style: styles.title,
    },
    { text: `${t('portfolio.amount')}` },
    {
      text: `${t('portfolio.purchase-price')}`,
    },
    {
      text: `${t('graph.market-price')}`,
    },
    { text: `${t('portfolio.change-euro')}` },
    {
      text: `${t('portfolio.change-percent')}`,
    },
  ]
  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingBottom: Spacings.S16,
        marginRight: Spacings.S24,
      }}
    >
      {headingItems.map((props) => (
        <HeadingText {...props} key={props.text} />
      ))}
    </FlexRow>
  )
}

export function PurchaseLotRow({
  purchaseLot,
  topBorderRadius,
  bottomBorderRadius,
  color,
  borderBottom,
  isTotalRow,
}: Props) {
  const {
    marketValue,
    purchaseValue,
    purchaseDate,
    quantity,
    purchasePrice,
    marketPrice,
    marketValueChangePercent,
    securityTypeId,
  } = purchaseLot
  const { t } = useTranslation()
  const marketValueChange = marketValue - purchaseValue

  const privateEquity = isPrivateEquity(securityTypeId)

  const textStyle = [
    styles.text,
    isTotalRow && { fontFamily: 'calibre-medium' },
  ]

  const numberStyles = [textStyle, styles.number]

  return (
    <FlexRow
      style={[
        {
          borderLeftColor: color,
          borderLeftWidth: 4,
        },
        topBorderRadius && { borderTopLeftRadius: BorderRadius.default },
        bottomBorderRadius && {
          borderBottomLeftRadius: BorderRadius.default,
        },
      ]}
    >
      <FlexRow
        style={[
          styles.main,
          styles.mainWide,
          borderBottom && styles.borderBottom,
          { justifyContent: 'space-between' },
        ]}
      >
        <TextDefault
          type="t3"
          style={[textStyle, styles.title, { paddingLeft: Spacings.S20 }]}
        >
          {isTotalRow ? `${t('portfolio.total')}` : formatDateFull(purchaseDate)}
        </TextDefault>
        <OwnerShipRowItem
          value={quantity}
          style={numberStyles}
          format={privateEquity ? formatCurrency : formatQuantity}
        />
        <OwnerShipRowItem
          value={purchasePrice}
          style={numberStyles}
          format={privateEquity ? formatFundProfit : formatCurrency}
        />
        <OwnerShipRowItem
          value={marketPrice}
          style={numberStyles}
          format={privateEquity ? formatFundProfit : formatCurrency}
        />
        <OwnerShipRowItem
          value={marketValueChange}
          style={numberStyles}
          format={formatCurrency}
          colorFormatted={true}
        />
        <OwnerShipRowItem
          value={marketValueChangePercent || 100}
          style={numberStyles}
          format={formatFundProfit}
          colorFormatted={true}
        />
      </FlexRow>
    </FlexRow>
  )
}
