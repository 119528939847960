import {
  PAST_MONTH,
  PAST_YEAR,
  SIX_MONTHS,
  YEAR_TO_DATE,
} from '@taaleri/core/src/constants/YieldHistory'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import Box from '../../ui/box/Box'
import { filterYieldHistoryByScale } from '@taaleri/core/src/utils/yieldHistory'
import HistoryScaleButtons from './HistoryScaleButtons'
import PortfolioChart, { toPortfolioChartProps } from './PortfolioChart'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextDefault } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '../../ui/useLayout'
import {
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import FlexRow from '../../ui/FlexRow'
import { AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import { getSecurityPdfUrl } from '../reporttransaction/TransactionPdfUrl'
import AppStore from '@taaleri/core/src/stores/AppStore'
import UiTouchable from '../../ui/UiTouchable.web'
import { CombinedIndex } from '@taaleri/core/src/models/CombinedIndex'
import { HistoricalChangeMobile } from './HistoricalChangeMobile'
import { toHistoricalChange } from './toHistoricalChange'
import { PdfLink } from './PdfLink'
import { IconChevronUp, IconChevronDown } from '../../ui/icons/ChevronIcons'
import { isPrivateEquity } from '@taaleri/core/src/constants/AssetClass'
import { useTranslation } from 'react-i18next'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  combinedIndex: CombinedIndex
  assetClassItem: AssetClassItem
  style?: StyleProp<ViewStyle>
  token?: string
}

function PortfolioName({ name }: { name: string }) {
  const { isSmall } = useLayout()
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
      }}
    >
      <TextDefault
        type="h5"
        style={{
          marginTop: Spacings.S16,
          marginBottom: 12,
          marginHorizontal: isSmall ? Spacings.S16 : Spacings.S24,
        }}
      >
        {name}
      </TextDefault>
    </View>
  )
}

function MarketPrice({
  marketPrice,
  style,
  privateEquity,
}: {
  marketPrice?: number
  privateEquity?: boolean
  style?: StyleProp<ViewStyle>
}) {
  const { t } = useTranslation()
  return marketPrice ? (
    <FlexRow style={[{ alignItems: 'center' }, style]}>
      <TextDefault type="t5" style={{ letterSpacing: 0.9 }}>
        {t('portfolio.latest-price')}
      </TextDefault>
      <TextDefault
        type={'h6'}
        style={{ marginLeft: Spacings.S8, marginBottom: 2 }}
      >
        {privateEquity
          ? formatFundProfit(marketPrice)
          : formatCurrency(marketPrice)}
      </TextDefault>
    </FlexRow>
  ) : null
}

function InfoRowMobile({
  pdfUrl,
  marketPrice,
  privateEquity,
}: {
  pdfUrl?: string
  privateEquity?: boolean
  marketPrice?: number
}) {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <>
      <UiTouchable
        style={{
          flexDirection: 'row',
          borderTopColor: Colors.border,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
          paddingHorizontal: Spacings.S20,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        onPress={() => setOpen(!open)}
      >
        <TextDefault type="t2" style={{ marginTop: 12, marginBottom: 12 }}>
          {t('portfolio.report-more')}
        </TextDefault>
        {open ? <IconChevronUp /> : <IconChevronDown color={Colors.text} />}
      </UiTouchable>
      {open && (
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
            paddingHorizontal: Spacings.S20,
            paddingVertical: Spacings.S8,
          }}
        >
          <PdfLink pdfUrl={pdfUrl} style={{ paddingVertical: 6 }} />
          <MarketPrice
            privateEquity={privateEquity}
            marketPrice={marketPrice}
            style={{ paddingVertical: 6 }}
          />
        </View>
      )}
    </>
  )
}

function InfoRowDesktop({
  pdfUrl,
  marketPrice,
  privateEquity,
}: {
  pdfUrl?: string
  marketPrice?: number
  privateEquity: boolean
}) {
  return (
    <FlexRow
      style={{
        borderBottomWidth: 1,
        borderBottomColor: Colors.border,
        paddingHorizontal: Spacings.S20,
        paddingVertical: Spacings.S10,
      }}
    >
      <PdfLink pdfUrl={pdfUrl} style={{ marginRight: Spacings.S24 }} />
      <MarketPrice marketPrice={marketPrice} privateEquity={privateEquity} />
    </FlexRow>
  )
}

function PurchaseLotChart({
  combinedIndex,
  style,
  assetClassItem,
  token,
}: Props) {
  const {
    marketPrice,
    ticker,
    isSecurityDocumentAvailable,
    securityTypeId,
    nameFi,
    nameSv,
  } = assetClassItem
  const { isSmall } = useLayout()

  const privateEquity = isPrivateEquity(securityTypeId)
  const { twrTimeSerie } = combinedIndex
  const historicalChanges = toHistoricalChange(combinedIndex)
  const [scale, setScale] = useState<string>(PAST_YEAR)

  const portofolioData = toPortfolioChartProps(twrTimeSerie, scale)
  const showPastMonthFilter =
    filterYieldHistoryByScale(PAST_MONTH, twrTimeSerie).length > 1
  const showYearToDateFilter =
    filterYieldHistoryByScale(YEAR_TO_DATE, twrTimeSerie).length > 1
  const showSixMonthsFilter =
    filterYieldHistoryByScale(SIX_MONTHS, twrTimeSerie).length > 1
  const marketValueChangePercent = portofolioData.marketValueChangePercent

  const pdfUrl =
    token && isSecurityDocumentAvailable
      ? getSecurityPdfUrl(AppStore.customerId, ticker, token)
      : undefined

  const getSecurityName = (): string =>
    AppStore.currentLanguage === fiKey ? nameFi : nameSv

  return (
    <Box style={style}>
      <PortfolioName name={getSecurityName()} />
      {isSmall || (
        <InfoRowDesktop
          marketPrice={marketPrice}
          pdfUrl={pdfUrl}
          privateEquity={privateEquity}
        />
      )}
      <View style={{ zIndex: 5 }}>
        <HistoryScaleButtons
          yearToDate={showYearToDateFilter}
          pastMonth={showPastMonthFilter}
          sixMonth={showSixMonthsFilter}
          onChange={setScale}
          type="purchaselot"
          marketValueChangePercent={marketValueChangePercent}
        />
        <View
          style={{
            height: 64,
            borderBottomColor: Colors.border,
            borderBottomWidth: 1,
          }}
        />
        <PortfolioChart {...portofolioData} />
      </View>
      {isSmall && (
        <InfoRowMobile
          marketPrice={marketPrice}
          pdfUrl={pdfUrl}
          privateEquity={privateEquity}
        />
      )}
      {isSmall && <HistoricalChangeMobile {...{ historicalChanges }} />}
    </Box>
  )
}

export default observer(PurchaseLotChart)
