import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'

import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextDefault } from './UiText'

interface SubtitleProps {
  style?: StyleProp<TextStyle>
}

const Subtitle: React.FC<SubtitleProps> = ({ children, style }) => (
  <TextDefault type="h5" style={[styles.text, style]}>
    {children && typeof children === 'string' ? children : ' '}
  </TextDefault>
)

export default Subtitle

const styles = StyleSheet.create({
  text: {
    marginBottom: Spacings.S8,
  },
})
