import React from 'react'
import UiButton from '@taaleri/components/src/ui/UiButton'
import { ButtonType } from '@taaleri/components/src/ui/UiButtonProps'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import styled from 'styled-components'

interface Props {
  title: string
  route?: string
  onPress?: (ev: any) => void
  loading?: boolean
  primaryButtonType?: ButtonType
}

const FixedContainer = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 24px;
`

export function FixedButton({
  title,
  route,
  onPress,
  loading,
  primaryButtonType,
}: Props) {
  const { navigate } = useNavigation()
  return (
    <FixedContainer>
      <UiButton
        type={primaryButtonType}
        title={title}
        loading={loading}
        onPress={(event: any) => {
          if (route) {
            navigate(route)
          } else if (onPress) {
            onPress(event)
          }
        }}
        containerStyle={{ width: '100%' }}
      />
    </FixedContainer>
  )
}
