import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { YearReport } from '@taaleri/core/src/models/ReportArchive'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { replaceNameParts } from '@taaleri/core/src/utils/documentNameHelper'
import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { OpacityContainer } from './OpacityContainer'
import { isWeb } from '../../constants/Platforms'
import { FlexContainer } from '../../ui/FlexContainer'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { TextDefault } from '../../ui/text/UiText'

function ReportListItem({
  report,
  onPress,
  title,
  containerStyle,
}: {
  report?: YearReport
  onPress: () => void
  title?: string
  containerStyle?: StyleProp<ViewStyle>
}) {
  const fixName = (name: string) => {
    return replaceNameParts(name, AppStore.currentLanguage)
  }

  const Content = (
    <FlexContainer style={styleSheetStyles.flexContainer}>
      <TextDefault
        type={'t2'}
        color={Colors.text}
        style={{ flex: 3, paddingRight: Spacings.S4 }}
      >
        {fixName((title || (report && report.title)) ?? '')}
      </TextDefault>
      <FlexRow>
        <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
          PDF
        </TextDefault>
        <IconDownload />
      </FlexRow>
    </FlexContainer>
  )

  const styles = [
    {
      marginBottom: Spacings.S8,
    },
    containerStyle,
  ]

  if (isWeb) {
    return (
      <View style={styles}>
        <OpacityContainer onClick={onPress}>{Content}</OpacityContainer>
      </View>
    )
  }

  return (
    <UiTouchable onPress={onPress} style={styles}>
      {Content}
    </UiTouchable>
  )
}

const styleSheetStyles = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: Spacings.S16,
    borderWidth: 1,
    borderColor: Colors.gray40,
    borderRadius: 6,
  },
})

export default ReportListItem
