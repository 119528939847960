import React from 'react'
import { StyleProp, View } from 'react-native'
import useLayout from './useLayout'

interface FlexContainerProps {
  style?: StyleProp<any>
  flexDirectionSmall?: string
  flexDirectionBig?: string
  alignContentSmall?: string
  alignContentBig?: string
  justifyContent?: string
}

interface FlexContainerWrapperProps extends FlexContainerProps {
  childStyle: StyleProp<any>
}

export const FlexContainer: React.FC<FlexContainerProps> = ({
  children,
  style,
  flexDirectionSmall = 'column',
  flexDirectionBig = 'row',
  alignContentSmall = 'flex-start',
  alignContentBig = 'center',
  justifyContent = 'space-between',
}) => {
  const { isSmall } = useLayout()

  return (
    <View
      style={[
        {
          flexDirection: isSmall ? flexDirectionSmall : flexDirectionBig,
          alignContent: isSmall ? alignContentSmall : alignContentBig,
          justifyContent: justifyContent,
          flex: 1,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export const ChildWrapper: React.FC<{
  style?: StyleProp<any>
  childStyle: StyleProp<any>
}> = ({ children, style, childStyle }) => {
  return (
    <View style={style}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            key: index,
            style: [child.props.style, childStyle],
          })
        }
        return child
      })}
    </View>
  )
}

export const FlexContainerWrapper: React.FC<FlexContainerWrapperProps> = ({
  children,
  childStyle,
  style,
  flexDirectionSmall,
  flexDirectionBig,
  alignContentSmall,
  alignContentBig,
}) => {
  return (
    <FlexContainer
      style={style}
      flexDirectionSmall={flexDirectionSmall}
      flexDirectionBig={flexDirectionBig}
      alignContentSmall={alignContentSmall}
      alignContentBig={alignContentBig}
    >
      <ChildWrapper childStyle={childStyle}>{children}</ChildWrapper>
    </FlexContainer>
  )
}
