import React, { useEffect } from 'react'

import IdentityValidityForm from '@taaleri/components/src/screens/onboarding/IdentityValidityForm'
import { useHistory } from 'react-router-dom'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_IDENTITY_CARD_SUMMARY } from '@taaleri/components/src/screens/identitycard/IdentityCardRoutes'
import { BackgroundContainerApp } from 'ui/container/BackgroundContainer'

export default function IdentityValidityScreen() {
  const history = useHistory()
  useEffect(() => {
    QuestionStore.questionMode = 'contract'
    QuestionStore.nextRouteIdentityUpdate = routePathApp(
      ROUTE_IDENTITY_CARD_SUMMARY
    )
  }, [])

  return (
    <BackgroundContainerApp>
      <IdentityValidityForm navigate={history.push} whiteBackground={false} />
    </BackgroundContainerApp>
  )
}
