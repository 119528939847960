import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import QuestionContainer from './QuestionContainer'
import Buttons from 'ui/Buttons'
import QuestionPortfolioTypeContent, {
  questionPortfolioTypeNextRoute,
} from '@taaleri/components/src/screens/questions/QuestionPortfolioTypeContent'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { FixedButton } from 'ui/FixedButton'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { ROUTE_QUESTIONS } from '@taaleri/components/src/navigation/routes'
import { RedirectIf } from 'components/RedirectIf'
import { useTranslation } from 'react-i18next'

function QuestionPortfolioTypeScreen() {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  useEffect(() => {
    AppStore.contentLoaded = false
  }, [])

  const redirectCondition = !QuestionStore.investmentGoal
  if (redirectCondition) {
    return (
      <RedirectIf to={`/${ROUTE_QUESTIONS}`} condition={redirectCondition} />
    )
  }

  const showDeskTopButtons = isNonSmall && AppStore.contentLoaded
  const showMobileButtons = !isNonSmall && AppStore.contentLoaded
  return (
    <>
      <QuestionContainer>
        <TopBottomContainer>
          <QuestionPortfolioTypeContent
            containerStyle={{ marginBottom: showMobileButtons ? 80 : 0 }}
          >
            {showDeskTopButtons && (
              <Buttons
                title={t('button.next')}
                route={questionPortfolioTypeNextRoute()}
              />
            )}
          </QuestionPortfolioTypeContent>
        </TopBottomContainer>
      </QuestionContainer>
      {showMobileButtons && (
        <FixedButton
          title={t('button.next')}
          route={questionPortfolioTypeNextRoute()}
        />
      )}
    </>
  )
}

export default observer(QuestionPortfolioTypeScreen)
