/* eslint-disable no-prototype-builtins */
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Countries, {
  CountriesExcludingFinland,
  Country,
  findCountryByCode,
} from '@taaleri/core/src/models/Countries'
import Downshift from 'downshift'
import { useField } from 'formik'
import matchSorter from 'match-sorter'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { BoxWithPaddingInside } from '../box/Box'
import { CountryLinkProps } from './CountryLinkProps'
import { UiTextInputForm } from './UiTextInput'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'

interface InputProps {
  isOpen: boolean
  hasError?: boolean
  value?: string
  disabled?: boolean
  overlay?: boolean
  cursorPointer?: boolean
  caretTransparent?: boolean
  isFlex?: boolean
}

export const Input = styled.input<InputProps>`
  margin: 0;
  font-size: 16px;
  padding: 0 16px;
  word-wrap: break-word;
  outline: 0;
  white-space: normal;
  display: ${(props) => (props.isFlex ? 'flex' : 'inline-block')};
  ${(props) => props.isFlex && 'flex: 1'};
  height: 56px;
  color: ${(props) => (props.disabled ? Colors.textDisabled : Colors.text)};
  border: ${(props) => (props.hasError ? '2px' : '1px')} solid
    ${(props) => (props.hasError ? Colors.error : Colors.gray20)};
  z-index: 1;
  background-color: ${(props) => (props.value ? Colors.white : 'transparent')};
  border-radius: ${BorderRadius.small}px;
  -webkit-appearance: none;
  font-family: 'calibre-semibold';
  ${(props) => props.cursorPointer && 'cursor: pointer;'}
  ${(props) => props.caretTransparent && 'caret-color: transparent;'}
`

export const Menu = styled.ul<InputProps>`
  padding: 0;
  margin: 0;
  background-color: ${Colors.white};
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  border-color: ${Colors.gray20};
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  border-radius: 2px;
  z-index: 1;

  ${(props) =>
    !props.isOpen &&
    css`    
      border-width:0;
      display:none;
    `}

  ${(props) =>
    props.isOpen &&
    css`
    display:block;
    `}

  ${(props) =>
    props.isOpen && props.overlay
      ? css`
    position: absolute;
    width: 100%;
    `
      : css`
          position: static;
        `}
`

export const Item = styled.li`
  position: relative;
  cursor: pointer;
  border: none;
  height: auto;
  border-top: none;
  line-height: 1em;
  text-transform: none;
  padding: 0.8rem 1.1rem;
  white-space: normal;
  z-index: 1;
  font-family: 'calibre-regular';
`
const ControllerButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: ${Spacings.S16}px;
  top: 0;
  cursor: pointer;
  width: 47;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke={Colors.gray40}
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  )
}

function getItems(allItems: Country[], filter: string | null, t: TFunction) {
  return filter
    ? matchSorter(allItems, filter, {
        keys: [(Item: Country) => t(Item.name)],
      })
    : allItems
}

const CountryLink = (props: CountryLinkProps) => {
  const {
    title,
    name,
    editable,
    excludeFinland,
    onCountryChange,
    clearOnChange,
  } = props
  const [field, meta, helpers] = useField(name)

  const { t } = useTranslation()
  const showError: boolean = meta.touched === true && meta.error !== undefined
  const value = field.value
  const country: Country | undefined = value
    ? findCountryByCode(value)
    : undefined
  const [inputValue, setInputValue] = useState(t(country?.name ?? ''))

  const disabled: boolean = editable === false
  const countries = (
    excludeFinland ? CountriesExcludingFinland : Countries
  ).sort((a: Country, b: Country) => {
    if (t(a.name) < t(b.name)) return -1
    if (t(a.name) > t(b.name)) return 1
    return 0
  })

  useEffect(() => {
    setInputValue(t(country?.name ?? ''))
  },[AppStore.currentLanguage])

  return (
    <Downshift
      onChange={(selection, ddHelpers) => {
        if (selection && selection.code) {
          helpers.setValue(selection.code)
          if (onCountryChange) onCountryChange(selection)
        } else {
          helpers.setValue('')
        }
        if (clearOnChange) {
          ddHelpers.clearSelection()
        }
      }}
      onStateChange={(changes, _sHelper) => {
        if (changes.hasOwnProperty('inputValue')) {
          setInputValue(changes.inputValue ?? '')
        }
      }}
      inputValue={inputValue}
      initialSelectedItem={country}
      initialHighlightedIndex={0}
      itemToString={(item) => (item ? t(item.name) : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        openMenu,
        clearSelection,
      }) => (
        <div style={{ marginBottom: '24px' }}>
          <div style={{ position: 'relative' }}>
            <UiTextInputForm
              label={title}
              name={name}
              hideCheckCircle={true}
              containerStyle={{ marginBottom: 0 }}
              hideError={isOpen}
            >
              <Input
                {...getInputProps()}
                onFocus={() => {
                  openMenu()
                }}
                onClick={() => {
                  if (clearOnChange) openMenu()
                }}
                onKeyPress={(event: any) => {
                  if (event.nativeEvent.keyCode === 13) {
                    event.nativeEvent.preventDefault()
                  }
                }}
                hasError={showError}
                disabled={disabled}
              />
            </UiTextInputForm>
            {selectedItem && !disabled && (
              <ControllerButton
                onClick={clearSelection}
                aria-label={`${t('clear-selection')}`}
              >
                <XIcon />
              </ControllerButton>
            )}
          </div>
          <Menu {...getMenuProps()} isOpen={isOpen}>
            {isOpen
              ? getItems(countries, inputValue, t).map(
                  (item: Country, index: number) => (
                    <Item
                      key={index}
                      {...getItemProps({
                        key: item.code,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index
                              ? Colors.background
                              : 'white',
                          fontFamily:
                            selectedItem === item
                              ? Fonts.avenirNextBold
                              : Fonts.avenirNextMedium,
                        },
                      })}
                    >
                      {t(item.name)}
                    </Item>
                  )
                )
              : null}
          </Menu>
        </div>
      )}
    </Downshift>
  )
}

export const CountryLinkWithPadding = (props: CountryLinkProps) => (
  <BoxWithPaddingInside>
    <CountryLink {...props} />
  </BoxWithPaddingInside>
)

export default CountryLink
