import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { useTranslation } from 'react-i18next'

export default function OnboardingPaymentSumContent() {
  const { t } = useTranslation()
  return (
    <>
      <Title>{t('onboarding.payment-sum-title')}</Title>
      <Paragraph>{t('onboarding.payment-sum-content')}</Paragraph>
    </>
  )
}
