import React from 'react'
import { View } from 'react-native'
import { Paragraph } from '../../ui/text/Paragraph'
import { observer } from 'mobx-react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Title from '../../ui/text/Title'
import { useTranslation } from 'react-i18next'

function TwoFactorToggleDoneContent() {
  const { t } = useTranslation()
  const paragraph = AppStore.twoFactorEnabled
    ? 'profile.two-way-enabled'
    : 'profile.two-way-disabled'
  return (
    <View>
      <Title>{t('profile.two-way-authentication')}</Title>
      <Paragraph>{t(paragraph)}</Paragraph>
    </View>
  )
}

export default observer(TwoFactorToggleDoneContent)
