import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_CUSTOMER,
  ROUTE_APP,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CUSTOMER_NOT_FOUND,
  ROUTE_QUESTIONS,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_IN_TUPAS_RETURN,
  ROUTE_TWO_FACTOR,
  ROUTE_TWO_FACTOR_AUTH_FAILED,
} from '@taaleri/components/src/navigation/routes'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { LayoutProvider } from '@taaleri/components/src/ui/useLayout'
import { api } from '@taaleri/core/src/api/api'
import { setLanguage } from '@taaleri/core/src/i18n'
import { MaintenanceWithWarning } from '@taaleri/core/src/models/Maintenance'
import { getLoggedIn } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import UserStore from '@taaleri/core/src/stores/UserStore'
import { observer } from 'mobx-react'
import AppRouter from 'navigation/AppRouter'
import IdentificationRouter from 'navigation/IdentificationRouter'
import PrivateRoute from 'navigation/PrivateRoute'
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import AdminCustomerScreen from 'screens/admin/AdminCustomerScreen'
import AdminScreen from 'screens/admin/AdminScreen'
import QuestionRouter from 'screens/questions/QuestionRouter'
import ChangePasswordScreen from 'screens/signin/ChangePasswordScreen'
import CustomerNotFoundScreen from 'screens/signin/CustomerNotFoundScreen'
import SignInScreen from 'screens/signin/SignInScreen'
import SignInTupasReturn from 'screens/signin/SignInTupasReturn'
import TwoFactorAuthFailedScreen from 'screens/signin/TwoFactorAuthFailedScreen'
import TwoFactorAuthScreen from 'screens/signin/TwoFactorAuthScreen'

import ErrorBoundary from './ErrorBoundary'
import MaintenanceScreen from './MaintenanceScreen'
import NotFoundScreen from './NotFoundScreen'
import ScrollToTop from './ScrollToTop'
import VersionScreen from './VersionScreen'

// TODO: tarkista miksi lazy loading päällä ja saako korjattua, tällä hetkellä antaa virheen
//const AppRouter = lazy(() => import('navigation/AppRouter'))
//const QuestionRouter = lazy(() => import('screens/questions/QuestionRouter'))

function RootContentInternal() {
  const toPath = (path: string): string => '/' + path

  return (
    <ScrollToTop>
      <Switch>
        <Route path="/index.html">
          <Redirect to="/" />
        </Route>
        <Route path="/" exact={true}>
          <IdentificationRouter />
        </Route>
        <Route path={toPath(ROUTE_QUESTIONS)}>
          <QuestionRouter />
        </Route>
        <PrivateRoute path={toPath(ROUTE_APP)} component={AppRouter} />
        <Route path={toPath(ROUTE_SIGN_IN)}>
          <SignInScreen />
        </Route>
        <Route path={toPath(ROUTE_TWO_FACTOR)}>
          <TwoFactorAuthScreen />
        </Route>
        <Route path={toPath(ROUTE_TWO_FACTOR_AUTH_FAILED)}>
          <TwoFactorAuthFailedScreen />
        </Route>
        <Route path={toPath(ROUTE_SIGN_IN_TUPAS_RETURN)}>
          <SignInTupasReturn />
        </Route>
        <Route path={toPath(ROUTE_CHANGE_PASSWORD)}>
          <ChangePasswordScreen />
        </Route>
        <Route path={toPath(ROUTE_CUSTOMER_NOT_FOUND)}>
          <CustomerNotFoundScreen />
        </Route>
        <Route path={toPath(ROUTE_ADMIN + '/:customerId')}>
          <AdminScreen />
        </Route>
        <Route path={toPath(ROUTE_ADMIN)}>
          <AdminScreen />
        </Route>
        <Route path={toPath(ROUTE_ADMIN_CUSTOMER + '/:customerId')}>
          <AdminCustomerScreen />
        </Route>
        <Route path="/version" exact={true}>
          <VersionScreen />
        </Route>
        <Route>
          <NotFoundScreen />
        </Route>
      </Switch>
    </ScrollToTop>
  )
}

const RootContent = observer(RootContentInternal)

function RootRouter() {
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingMaintenance, setLoadingMaintenance] = useState<boolean>(true)
  const [maintenance, setMaintenance] = useState<MaintenanceWithWarning>()

  useEffect(() => {
    const load = async () => {
      const maintenaceDetail = await api().versionApi.maintenance()
      if (maintenaceDetail.ongoing) {
        setMaintenance(maintenaceDetail)
      }
      setLoadingMaintenance(false)
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      const isLoggedIn = await getLoggedIn()
      UserStore.loggedIn = isLoggedIn
      setLoading(false)
    }
    load()
    setLanguage(AppStore.currentLanguage)
  }, [])

  if (maintenance) {
    return <MaintenanceScreen maintenance={maintenance} />
  }

  if (loading || loadingMaintenance) {
    return null
  }

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingIndicator />}>
        <LayoutProvider>
          <Router>
            <RootContent />
          </Router>
        </LayoutProvider>
      </React.Suspense>
    </ErrorBoundary>
  )
}
export default observer(RootRouter)
