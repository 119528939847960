import React from 'react'
import { TextDefault } from '../../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

function TransactionHeaderMobile() {
  const { t } = useTranslation()
  return (
    <TextDefault type="h6" style={{ flex: 1, marginBottom: Spacings.S24 }}>
      {t('reportBox.events')}
    </TextDefault>
  )
}

export default TransactionHeaderMobile
