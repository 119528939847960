import Colors, { getChangeColor } from '@taaleri/core/src/constants/Colors'
import { SizeLargeResponsive } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { formatFundProfit } from '@taaleri/core/src/utils/format'
import { formatCurrencyWithPrefix } from '@taaleri/core/src/utils/formatCurrency'
import { observer } from 'mobx-react'
import React from 'react'
import { Animated, StyleSheet, StyleProp } from 'react-native'

import HistoryScaleButtons from './HistoryScaleButtons'
import { PortfolioProfitProps } from './PortfolioProfitProps'
import { isWeb } from '../../constants/Platforms'
import FlexRow from '../../ui/FlexRow'
import MarketValueInfoButton from '../../ui/MarketValueInfoButton'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'
import AppStore from '@taaleri/core/src/stores/AppStore'

const opacity = new Animated.Value(1)

const PortfolioProfitContent = ({
  showYearToDateFilter,
  showPastMonthFilter,
  setHistoryScale,
  showSixMonthsFilter,
  marketValueChange,
  marketValueChangePercent,
}: PortfolioProfitProps) => {
  const { isSmall } = useLayout()

  return (
    <>
      <HistoryScaleButtons
        yearToDate={showYearToDateFilter}
        pastMonth={showPastMonthFilter}
        onChange={setHistoryScale}
        sixMonth={showSixMonthsFilter}
        type="portfolio"
        marketValueChange={marketValueChange}
        marketValueChangePercent={isWeb ? marketValueChangePercent : undefined}
      />

      <PaddingHorizontal
        style={[
          {
            height: 64,
            justifyContent: 'flex-end',
          },
          isSmall && {
            marginTop: 56,
            borderTopWidth: 1,
            justifyContent: 'center',
            borderTopColor: Colors.border,
          },
        ]}
      >
        {isSmall && (
          <MarketValueChangeRow
            style={{ opacity }}
            {...{ marketValueChange, marketValueChangePercent }}
          />
        )}
      </PaddingHorizontal>
    </>
  )
}

export function MarketValueChangeRow({
  marketValueChange,
  marketValueChangePercent,
  style,
}: {
  marketValueChange?: number
  marketValueChangePercent?: number
  style?: StyleProp<any>
}) {
  const oneBillion = 1000000000
  return (
    <FlexRow style={style}>
      {marketValueChange &&
      marketValueChange !== undefined &&
      AppStore.showEuroProfit &&
      Math.abs(marketValueChange) < oneBillion ? (
        <TextDefault type="h2" style={styles.marketValueChange}>
          {formatCurrencyWithPrefix(marketValueChange)}
        </TextDefault>
      ) : null}
      {marketValueChangePercent !== undefined ? (
        <TextDefault
          type="h2"
          style={styles.percentage}
          color={getChangeColor(marketValueChangePercent)}
        >
          {formatFundProfit(marketValueChangePercent, true)}
        </TextDefault>
      ) : null}
      <MarketValueInfoButton style={styles.infoButton} />
    </FlexRow>
  )
}

export default observer(PortfolioProfitContent)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  marketValueChange: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
    marginRight: Spacings.S16,
  },
  percentage: {
    fontSize: SizeLargeResponsive(),
    letterSpacing: -0.2,
  },
  infoButton: {
    justifyContent: 'center',
    marginLeft: Spacings.S8,
  },
})
