import React from 'react'
import { UiTextInputForm } from '../../ui/form/UiTextInput'
import * as Yup from 'yup'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, View } from 'react-native'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import MultiCountry from '../../components/MultiCountry'
import { Country } from '@taaleri/core/src/models/Countries'
import { BoxWithPaddingMargin } from '../../ui/box/Box'
import {
  PurposeOfForeignMoneyTransfers,
  PURPOSE_OF_FOREIGN_MONEY_TRANSFERS_OPTIONS,
} from '@taaleri/core/src/constants/Options'
import CheckListMultiple from '../../ui/form/CheckListMultiple'
import InfoButton from '../../ui/InfoButton'
import { useTranslation } from 'react-i18next'

export const questionForeignMoneyTransferValidationSchema = Yup.object().shape({
  monthlyForeignMoneyTransfersCountry: Yup.array()
    .required(ErrorMessages.required)
    .test(
      'hasElements',
      ErrorMessages.required,
      (val) => val !== undefined && val.length > 0
    ),
  purposeOfForeignMoneyTransfers: Yup.array()
    .required(ErrorMessages.required)
    .test(
      'hasElements',
      ErrorMessages.required,
      (val) => val !== undefined && val.length > 0
    ),
  purposeOfForeignMoneyTransfersOther: Yup.string(),
})

export function toInitValue(value: any) {
  if (!value) {
    if (typeof value === 'string') return ''
    return []
  }

  return value
}

export function toSaveValue(value: any) {
  if (!value) {
    if (value.length === 0) return []
    return ''
  }

  return value
}

export interface ForeignMoneyTransfer {
  monthlyForeignMoneyTransfersCountry: Country[]
  purposeOfForeignMoneyTransfers: string[]
  purposeOfForeignMoneyTransfersOther: string
}

export default function QuestionForeignMoneyTransferContent(props: {
  values: ForeignMoneyTransfer
}) {
  const { t } = useTranslation()
  return (
    <View>
      <BoxWithPaddingMargin
        title={`${t('questions.foreignMoneyTransfer.which-country-question')}`}
        titleStyle={styles.titleMargin}
      >
        <MultiCountry name="monthlyForeignMoneyTransfersCountry"></MultiCountry>
      </BoxWithPaddingMargin>
      <BoxWithPaddingMargin
        title={`${t('questions.foreignMoneyTransfer.purpose-question')}`}
        titleStyle={styles.titleMargin}
        titleRightComponent={
          <InfoButton text="questions.foreignMoneyTransfer.question-info" />
        }
      >
        <CheckListMultiple
          name="purposeOfForeignMoneyTransfers"
          options={PURPOSE_OF_FOREIGN_MONEY_TRANSFERS_OPTIONS}
          lastBottomDivider={true}
        />
        {props.values.purposeOfForeignMoneyTransfers.length > 0 &&
          props.values.purposeOfForeignMoneyTransfers.some(
            (p) => p === PurposeOfForeignMoneyTransfers.Other
          ) && (
            <UiTextInputForm
              name="purposeOfForeignMoneyTransfersOther"
              label={`${t(
                'questions.foreignMoneyTransfer.additional-info-title'
              )}`}
              type="name"
              infoLabel={[
                {
                  text: t(
                    'questions.foreignMoneyTransfer.additional-info-info'
                  ),
                },
              ]}
            />
          )}
      </BoxWithPaddingMargin>
    </View>
  )
}

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: Spacings.S24,
  },
  titleMargin: {
    marginBottom: Spacings.S24,
  },
})
