import React from 'react'
import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_REPORT_ORDERS } from '@taaleri/components/src/navigation/routes'
import { observer } from 'mobx-react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import ReportOrderDeliveryContent from '@taaleri/components/src/screens/reportorders/ReportOrderDeliveryContent'

function ReportOrderDeliveryScreen() {
  const { navigate } = useNavigation()

  return (
    <ContentContainerBox leadingTitle={true}>
      <ReportOrderDeliveryContent
        navigateToReports={() => {
          navigate(routePathApp(ROUTE_REPORT_ORDERS))
        }}
      />
    </ContentContainerBox>
  )
}

export default observer(ReportOrderDeliveryScreen)
