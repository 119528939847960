import React, { useState, Children, useEffect } from 'react'

import { ListItemLink } from '@taaleri/components/src/ui/ListItem'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import Colors from '@taaleri/core/src/constants/Colors'
import Fonts from '@taaleri/core/src/constants/Fonts'
import { StyleProp, ViewStyle } from 'react-native'

interface Props {
  title: string
  children: any
  hide?: boolean
  listItemStyle?: StyleProp<ViewStyle>
}

function ListItemToggle(props: Props) {
  const { title, children, hide, listItemStyle } = props
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    let mounted = true
    if (mounted && hide) {
      setOpen(false)
    }

    return () => {
      mounted = false
    }
  }, [hide])
  return (
    <>
      <ListItemLink
        title={title}
        noPadding={true}
        textStyle={{
          fontFamily: open ? Fonts.avenirNextBold : Fonts.avenirNextMedium,
        }}
        onPress={() => {
          setOpen(!open)
        }}
        topDivider={false}
        bottomDivider={!open}
        open={open}
        containerStyle={listItemStyle}
      />
      {open && (
        <BoxWithPadding
          style={{
            borderTopWidth: 1,
            borderTopColor: Colors.border,
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
          }}
        >
          {children}
        </BoxWithPadding>
      )}
    </>
  )
}

export default ListItemToggle
