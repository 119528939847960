import React from 'react'
import { AppPadding } from 'screens/app/AppPadding'
import ImpactComparisonContent from '@taaleri/components/src/screens/impact/ImpactComparisonContent'

export default function ImpactComparisonScreen() {
  return (
    <AppPadding>
      <ImpactComparisonContent />
    </AppPadding>
  )
}
