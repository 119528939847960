import { AssetSource, TaxationChoices } from '../../constants/Options'
import { FINLAND } from '../../models/Countries'
import { CustomerBasicInfoResponse } from '../../models/Customer'
import {
  RegulatoryQuestions,
  TaxationCountry,
  US_COUNTRY,
} from '../../models/RequlatoryQuestions'
import pick from 'lodash/pick'

export interface BasicInfoTaxation {
  taxCountry1: string | null
  taxCountry2: string | null
  taxCountry3: string | null
  tin1: string | null
  tin2: string | null
  tin3: string | null
  extraTaxCountries: string | null
  extraTins: string | null
}

export interface BasicInfoSourceOfFunds {
  sourceOfFundsIncome: boolean
  sourceOfFundsInheritance: boolean
  sourceOfFundsGift: boolean
  sourceOfFundsExit: boolean
  sourceOfFundsInvesting: boolean
  sourceOfFundsOther: boolean
  sourceOfFundsBenefits: boolean
  sourceOfFundsRealEstate: boolean
  sourceOfFundsPension: boolean
}

export function toTaxationCountries(
  basicInfoTaxation: BasicInfoTaxation
): TaxationCountry[] {
  const extraTaxCountries = basicInfoTaxation.extraTaxCountries
    ? basicInfoTaxation.extraTaxCountries.split(',')
    : []
  const extraTins = basicInfoTaxation.extraTins
    ? basicInfoTaxation.extraTins.split(',')
    : []
  const extraCountries = extraTaxCountries.reduce(
    (
      arr: TaxationCountry[],
      country: string,
      index: number
    ): TaxationCountry[] =>
      extraTins[index] ? arr.concat([{ country, tin: extraTins[index] }]) : arr,
    []
  )

  const taxationCountries = [
    { country: basicInfoTaxation.taxCountry1, tin: basicInfoTaxation.tin1 },
    { country: basicInfoTaxation.taxCountry2, tin: basicInfoTaxation.tin2 },
    { country: basicInfoTaxation.taxCountry3, tin: basicInfoTaxation.tin3 },
  ]
    .concat(extraCountries)
    .reduce(
      (
        arr: TaxationCountry[],
        current: { country: string | null; tin: string | null }
      ): TaxationCountry[] =>
        (current.country && current.tin) || current.country === FINLAND.code
          ? arr.concat([{ country: current.country, tin: current.tin || '' }])
          : arr,
      []
    )

  return taxationCountries.length === 0 ? [] : taxationCountries
}

export function toAssetSources(
  basicInfoSourceOfFunds: BasicInfoSourceOfFunds
): string[] {
  const sourceOfFundsFields: BasicInfoSourceOfFunds = pick(
    basicInfoSourceOfFunds,
    [
      'sourceOfFundsIncome',
      'sourceOfFundsInheritance',
      'sourceOfFundsGift',
      'sourceOfFundsExit',
      'sourceOfFundsInvesting',
      'sourceOfFundsOther',
      'sourceOfFundsBenefits',
      'sourceOfFundsRealEstate',
      'sourceOfFundsPension',
    ]
  )
  return Object.entries(sourceOfFundsFields)
    .filter(([_, value]) => value)
    .reduce((arr: string[], [key]) => arr.concat(key), [])
}

export function toRequlatoryQuestions(
  customerBasicInfoResponse: CustomerBasicInfoResponse
): RegulatoryQuestions {
  const { basicInfo } = customerBasicInfoResponse

  const taxationCountries = toTaxationCountries(basicInfo)
  const sourceOfFunds = toAssetSources(basicInfo)

  return {
    isAktiaPrimaryBank:
      basicInfo.isAktiaMainBank === null ||
      basicInfo.isAktiaMainBank === undefined
        ? ''
        : basicInfo.isAktiaMainBank
        ? 'Yes'
        : 'No',
    accountPurpose: basicInfo.accountPurpose || '',
    accountPurposeOther: basicInfo.accountPurposeDescription || '',
    dualCitizenshipCountry: basicInfo.dualNationalityCountry || '',
    foreignStreetAddressCountry: basicInfo.foreignPostalServiceCountry || '',
    foreignPowerOfAttorneyCountry:
      basicInfo.foreignPowerOfAttorneyCountry || '',
    foreignWorkOrResidencePermitCountry:
      basicInfo.workOrResidencePermitCountry || '',
    hasDualCitizenship: basicInfo.hasDualNationality,
    hasForeignWorkOrResidencePermit: basicInfo.hasWorkOrResidencePermit,
    hasForeignStreetAddress: basicInfo.hasForeignPostalService,
    hasForeignPowerOfAttorney: basicInfo.hasForeignPowerOfAttorney,
    hasUsShareOfEstate: basicInfo.hasUsEstate,
    incompetent: false,
    isPep: basicInfo.isPep ?? false,
    pepCountry: basicInfo.pepCountry || '',
    pepPosition: basicInfo.pepPosition || '',
    pepConnection: basicInfo.pepConnection || '',
    sourceOfFunds,
    sourceOfFundsOtherDescription:
      sourceOfFunds.includes(AssetSource.other) &&
      basicInfo.sourceOfFundsOtherDescription
        ? basicInfo.sourceOfFundsOtherDescription
        : '',
    taxationChoise:
      taxationCountries.length === 1 &&
      taxationCountries[0].country === FINLAND.code
        ? TaxationChoices.OnlyFinland
        : taxationCountries.every((tc) => tc.country !== FINLAND.code) === true
        ? TaxationChoices.OnlyAbroad
        : taxationCountries.some((tc) => tc.country === FINLAND.code) ===
            true && taxationCountries.length > 1
        ? TaxationChoices.FinlandAndAbroad
        : '',
    taxationCountries,
    isUnderUsTaxation:
      taxationCountries.length === 0
        ? ''
        : taxationCountries.some((tc) => tc.country === US_COUNTRY) === true
        ? 'Yes'
        : 'No',
  }
}
