import GlobalStyles from '@taaleri/components/src/constants/GlobalStyles'
import FundPieContainerNoLoad from '@taaleri/components/src/screens/funds/FundPieContainerNoLoad'
import { useFund } from '@taaleri/components/src/screens/funds/useFunds'
import AssetDetails from '@taaleri/components/src/screens/portfolio/AssetDetails'
import DisclaimerBox from '@taaleri/components/src/screens/portfolio/DisclaimerBox'
import { HistoricalChangeDesktop } from '@taaleri/components/src/screens/portfolio/HistoricalChangeDesktop'
import { HistoricalChangeMobile } from '@taaleri/components/src/screens/portfolio/HistoricalChangeMobile'
import { KeyFiguresDropDownBox } from '@taaleri/components/src/screens/portfolio/KeyFiguresBox'
import PortfolioActions from '@taaleri/components/src/screens/portfolio/PortfolioActions'
import { ReportChartGrid } from '@taaleri/components/src/ui/Grid'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import useLayout from '@taaleri/components/src/ui/useLayout'
import {
  KeyFigureType,
  PERFORMANCE,
} from '@taaleri/core/src/constants/KeyFigures'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { observer } from 'mobx-react'
import useQueryParams from 'navigation/useQueryParams'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { AppPadding } from 'screens/app/AppPadding'
import { Content } from 'screens/app/Content'
import { GradientBackground } from 'ui/GradientContainer'

import KeyFigures from './KeyFigures'
import PortfolioPieReport from './PortfolioPieReport'
import PurchaseLotsReport from './PurchaseLotsReport'
import ReportChartContainer from './ReportChartContainer'
import { RestrictionProps, ReportFilters } from './ReportFilters'

function PortfolioContentReport() {
  const params = useQueryParams()
  const paymentResult = params.paymentResult
  const portfolio = ReportStore.portfolioFiltered
  const {
    reportTimeSeries,
    portfolioTimeSeries,
    loadingCombinedIndex,
    loadingPositions,
    loadingChartData,
    isDrillDown,
    selectedPosition,
    restrictionOpen,
    setRestrictionOpen,
    purchaseLots,
    loadingPurchaselots,
    securityPrice,
    selectedAssetClassItem,
  } = ReportStore

  const { isMediumPlus, isWide } = useLayout()
  const [graphType, setGraphType] = useState<KeyFigureType>(PERFORMANCE)
  const [portfolioOpen, setPortfolioOpen] = useState<boolean>(false)
  const [dateOpen, setDateOpen] = useState<boolean>(false)
  const { fund, error, retry, notFound } = useFund(selectedPosition)
  const [language, setLanguage] = useState<string>(AppStore.currentLanguage)

  const { t } = useTranslation()

  useEffect(() => {
    initReportStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (language !== AppStore.currentLanguage) {
      setLanguage(AppStore.currentLanguage)
      ReportStore.reset()
      initReportStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  const initReportStore = () => {
    async function initializeReportStore() {
      await ReportStore.init()
      const parametersGiven =
        params.assetClass ||
        params.selectedCategory ||
        params.selectedAdditionalInfo ||
        params.selectedPosition
      // Do drilldown if parameters are given
      if (parametersGiven) {
        ReportStore.setRestrictionParameters(params)
      }
      setGraphType(PERFORMANCE)
    }
    initializeReportStore()
  }

  useEffect(() => {
    function scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    scrollToTop()
  }, [loadingCombinedIndex])

  const filterProps: RestrictionProps = {
    ...{
      restrictionOpen,
      setRestrictionOpen,
      portfolioOpen,
      setPortfolioOpen,
      dateOpen,
      setDateOpen,
    },
  }

  if (paymentResult) {
    AppStore.newInvestment = 0
  }

  if (!portfolio || !reportTimeSeries || !portfolioTimeSeries) {
    return <LoadingIndicator type="inline" />
  }

  const showPortfolioPie = !selectedPosition
  const showFundPie =
    selectedPosition && ReportStore.isOwnFund(selectedPosition) && !notFound

  return (
    <>
      <GradientBackground>
        <AppPadding>
          <Content marginTop={true} gridWidth={1350}>
            <View>
              <ReportFilters {...filterProps} isMobile={!isMediumPlus} />
              <ReportChartGrid
                style={
                  isMediumPlus
                    ? (showPortfolioPie || showFundPie) && isMediumPlus
                      ? {}
                      : { display: 'flex', flexDirection: 'column' }
                    : { display: 'flex', flexDirection: 'column-reverse' }
                }
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginTop: isMediumPlus ? 0 : Spacings.S24,
                  }}
                >
                  <ReportChartContainer
                    reportTimeSeries={reportTimeSeries}
                    portfolioTimeSeries={portfolioTimeSeries}
                    isDrillDown={isDrillDown}
                    securityPrice={securityPrice}
                    subtitle={ReportStore.title}
                    benchMarkTimeSeries={ReportStore.benchMarkIndex}
                    loading={loadingChartData}
                    graphType={graphType}
                    setGraphType={setGraphType}
                  >
                    {!loadingCombinedIndex && !isMediumPlus && (
                      <HistoricalChangeMobile
                        historicalChanges={ReportStore.historicalChanges}
                        style={GlobalStyles.topBorder}
                      />
                    )}
                  </ReportChartContainer>
                </View>
                {showFundPie && selectedPosition ? (
                  <FundPieContainerNoLoad
                    fund={fund}
                    error={error}
                    retry={retry}
                    notFound={notFound}
                  />
                ) : showPortfolioPie ? (
                  <View>
                    <PortfolioPieReport
                      {...filterProps}
                      loading={loadingPositions}
                      percentages={ReportStore.percentagesSorted()}
                      showPie={
                        ReportStore.selectedCategory === undefined ||
                        (ReportStore.assetAdditionalInfos().length > 1 &&
                          ReportStore.selectedPosition === undefined)
                      }
                    />
                  </View>
                ) : null}
                {!isMediumPlus && (
                  <KeyFiguresDropDownBox
                    keyFigures={ReportStore.keyFigures}
                    loading={loadingCombinedIndex}
                    marketValue={ReportStore.portfolioFiltered?.marketValue}
                  />
                )}
              </ReportChartGrid>
              {!isMediumPlus && (
                <PortfolioActions
                  style={{ borderBottomWidth: 0, marginBottom: 0 }}
                />
              )}

              {!loadingCombinedIndex &&
                isMediumPlus &&
                selectedAssetClassItem?.isCapitalFund !== true && (
                  <KeyFigures
                    keyFigures={ReportStore.keyFigures}
                    setGraphType={setGraphType}
                  />
                )}
              {!loadingCombinedIndex &&
                isMediumPlus &&
                selectedAssetClassItem?.isCapitalFund !== true && (
                  <HistoricalChangeDesktop
                    historicalChanges={ReportStore.historicalChanges}
                    style={{
                      marginTop: Spacings.S24,
                      zIndex: -1,
                      marginBottom: ReportStore.isInsuranceportfolioSelected
                        ? Spacings.S24
                        : Spacings.S48,
                    }}
                  />
                )}
            </View>

            {ReportStore.isInsuranceportfolioSelected && (
              <DisclaimerBox
                content={[t('portfolio.insurance-disclaimer')]}
                style={{ marginBottom: Spacings.S48 }}
              />
            )}

            {selectedPosition && (
              <PurchaseLotsReport
                purchaseLots={purchaseLots}
                loading={loadingPurchaselots}
                color={ReportStore.chartColor}
              />
            )}
          </Content>
          {!selectedPosition && !loadingPositions && (
            <AssetDetails
              linkTitle={true}
              portfolio={portfolio}
              showAdvanced={true}
              style={{
                maxWidth: 1480,
                marginHorizontal: 'auto',
                paddingHorizontal: isWide ? Spacings.S20 : 0,
              }}
            />
          )}
        </AppPadding>
      </GradientBackground>
    </>
  )
}

export default observer(PortfolioContentReport)
