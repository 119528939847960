import { ROUTE_TWO_FACTOR_TOGGLE_START } from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import AccountManagerSection from '@taaleri/components/src/screens/profile/AccountManagerSection'
import { ContactCustomerServiceBox } from '@taaleri/components/src/screens/profile/ContactCustomerServiceBox'
import ContactSection from '@taaleri/components/src/screens/profile/ContactSection'
import ContractSection from '@taaleri/components/src/screens/profile/ContractSection'
import HelpSection from '@taaleri/components/src/screens/profile/HelpSection'
import InvestmentSection from '@taaleri/components/src/screens/profile/InvestmentSection'
import AppInfoSection from '@taaleri/components/src/screens/profile/AppInfoSection'
import { ROUTE_PROFILE_CHANGE_PASSWORD } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import TestingSection from '@taaleri/components/src/screens/profile/TestingSection'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import { ListItemLink } from '@taaleri/components/src/ui/ListItem'
import { TitleLarge } from '@taaleri/components/src/ui/text/Title'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import { View } from 'react-native'
import { AppPadding } from 'screens/app/AppPadding'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
//import ProfileFeedback from './ProfileFeedback'

const Grid = styled.div`
  display: grid;
  grid-gap: 0;
  max-width: 556px;
  margin: auto;
  width: 100%;
  @media (min-width: 1000px) {
    max-width: 1000px;
    grid-gap: ${Spacings.S24}px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`

const InnerGrid = styled.div`
  grid-gap: 0;

  display: grid;
  align-items: flex-start;
  align-content: flex-start;
`

function ProfileScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const customerName = AppStore.customer.customerAuthorizations.find(
    (authCustomer) => authCustomer.id === String(AppStore.customerId)
  )?.name
  const isBaseCustomer = AppStore.isBaseCustomerActive
  const showAccountManager =
    !AppStore.showContactCustomerServiceBox && AppStore.isPrivateCustomer

  return (
    <AppPadding>
      {customerName ? <TitleLarge>{`${customerName}`}</TitleLarge> : null}
      <Grid>
        <InnerGrid>
          <ContactSection />
          {AppStore.showContactCustomerServiceBox && (
            <ContactCustomerServiceBox />
          )}
          {/* <HelpSection /> */}
          {showAccountManager && <AccountManagerSection />}
          {isBaseCustomer && (
            <BoxWithPadding
              title={`${t('profile.settingsSection.title')}`}
              style={{ marginBottom: Spacings.S24 }}
            >
              <ListItemLink
                noPadding={true}
                title={
                  AppStore.twoFactorEnabled
                    ? `${t('profile.settingsSection.remove-two-factor')}`
                    : `${t('profile.settingsSection.add-two-factor')}`
                }
                onPress={() => navigateApp(ROUTE_TWO_FACTOR_TOGGLE_START)}
                topDivider={false}
              />
              <ListItemLink
                noPadding={true}
                title={`${t('profile.settingsSection.change-password')}`}
                onPress={() => navigateApp(ROUTE_PROFILE_CHANGE_PASSWORD)}
                topDivider={false}
              />
            </BoxWithPadding>
          )}
        </InnerGrid>

        <View>
          <InvestmentSection />
          <ContractSection />
          <AppInfoSection />
          <TestingSection />
        </View>
      </Grid>
      {/* Feedback box removed from web temporarily,
      because there isn't active development going on at the moment
       <ProfileFeedback />*/}
    </AppPadding>
  )
}

export default observer(ProfileScreen)
