import format from 'date-fns/format'
import locale from 'date-fns/locale/fi'
import {
  formatCurrency,
  addThousandIndicators,
  nonBreakingSpaces,
} from './formatCurrency'

export const phoneNumberRegex = /^[+]?[\d ]+$/

export function formatNumber(value: number): string {
  if (!value || Math.abs(value) < 0.01 || isNaN(value)) {
    return '0'
  }
  const parts = Number(value.toFixed(2)).toString().split('.')
  const leftFromDecimalPoint = addThousandIndicators(parts[0])
  const decimals = parts[1]
    ? parts[1].length === 1
      ? `,${parts[1].substr(0, 2)}0`
      : `,${parts[1].substr(0, 2)}`
    : ''
  return leftFromDecimalPoint + decimals
}

export function formatNumberWithPrefix(value: number): string {
  const formattedNumber = formatNumber(value)
  const prefix = value > 0 ? '+' : ''
  return `${prefix}${formattedNumber}`
}

export function formatAssetCategoryName(categoryName?: string): string {
  if (!categoryName) {
    return ''
  }
  return categoryName
    .replace(/Osakkeet - /g, '')
    .replace(/Osakkeet -/g, '')
    .replace(/Osakkeet-/g, '')
    .replace(/Aktier - /g, '')
    .replace(/Aktier -/g, '')
    .replace(/Aktier-/g, '')
    .replace(/Muut - /g, '')
    .replace(/Muut -/g, '')
    .replace(/Övriga - /g, '')
    .replace(/Övriga -/g, '')
    .replace(/JVK - /g, '')
    .replace(/JVK -/g, '')
    .replace(/Obligationer - /g, '')
    .replace(/Obligationer -/g, '')
    .replace(/Rahamarkkinat - /g, '')
    .replace(/Rahamarkkinat -/g, '')
    .replace(/Penningmarknaden - /g, '')
    .replace(/Penningmarknaden -/g, '')
}

export function formatPercentageTwoDecimal(value: number, decimal: number = 2) {
  return parseFloat(String(Math.round(value * 100) / 100))
    .toFixed(decimal)
    .replace('.', ',')
}

export function formatPercentageTwoFromDecimal(value: number) {
  return formatPercentageFromDecimal(value, 2)
}

export function formatPercentageFromDecimal(value: number, decimals: number) {
  return (
    parseFloat(String(Math.round(value * 100)))
      .toFixed(decimals)
      .replace('.', ',') + ' %'
  )
}

export function formatPercentage(value: number, decimals: number) {
  const multiplier = 10 ** decimals
  return (
    parseFloat(String(Math.round(value * multiplier) / multiplier))
      .toString()
      .replace('.', ',') + ' %'
  )
}

export function formatFundProfit(profit: number, withPrefix?: boolean) {
  const prefix = withPrefix && profit > 0 ? '+' : ''
  return `${prefix}${formatPercentageTwoDecimal(profit)} %`
}

export function formatPercentageFull(value: number) {
  return parseFloat(String(Math.round(value * 100) / 100)).toFixed(0)
}

export function toFullPercentage(percentage: number) {
  const roundedNumber = Math.round(percentage)
  return roundedNumber > 0 ? roundedNumber : 2
}

export function toFullPercentageText(percentage: number) {
  if (percentage === 0) {
    return '0'
  }
  const roundedNumber = Math.round(percentage)
  return roundedNumber > 0 ? roundedNumber : '< 1'
}

export function formatDate(date: Date): string {
  return format(date, 'M/YYYY')
}

export function formatDateWithoutYear(date: Date): string {
  return format(date, 'DD.MM')
}

export function formatDateFull(date: string | Date): string {
  return format(date, 'DD.MM.YYYY')
}

export function formatDateWithoutLeadingZeros(date: string | Date): string {
  return formatDateFull(date).replace(/\b0/g, '')
}

export function formatDateDash(date: Date): string {
  return format(date, 'YYYY-MM-DD')
}

export function getMonthTranslation(date: string): string {
  return format(date, 'MMMM', { locale })
}

export function formatYear(date: string): string {
  return format(date, 'YYYY')
}

export function formatToLocalDateString(date: string) {
  return new Date(date).toLocaleDateString('fi-FI', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export function formatFiFormatToUniversalDashed(date: string) {
  const splittedString = date.split('.')
  if (splittedString.length === 3) {
    return `${splittedString[2]}-${splittedString[1]}-${splittedString[0]}`
  }

  return date
}

export function getDistanceInFullDays(
  date: string,
  nowInSeconds?: number
): number {
  const now = nowInSeconds ? nowInSeconds : Date.now()
  const difference = Math.abs(now - new Date(date).getTime())
  return Math.floor(difference / (1000 * 3600 * 24))
}

export function formatIban(iban: string): string {
  const notIban = /^\d{2,2}/.test(iban)
  if (!iban || notIban) {
    return iban
  }

  const grouped = iban.replace(/\s/g, '').match(/.{1,4}/g)
  return grouped ? grouped.join(' ') : ''
}

export { formatCurrency, nonBreakingSpaces }

export function formatCurrencyNoDecimal(value: number): string {
  if (!value) {
    return '0 €'
  }
  const leftFromDecimalPoint = addThousandIndicators(
    Math.round(value).toString()
  )
  return nonBreakingSpaces(leftFromDecimalPoint + ' €')
}

export const sum = (arr: number[]) =>
  arr.reduce((a: number, b: number) => a + (b || 0), 0)

export const roundToNearest = (x: number, nearest: number) =>
  Math.round(Math.ceil(x / nearest)) * nearest

export const roundToNearestHundredValue = (x: number) => roundToNearest(x, 100)

export function roundToNearestThousandValue(value: number) {
  const roundWith = value <= 100000 ? 1000 : value <= 1000000 ? 10000 : 100000

  return roundToNearest(value, roundWith)
}

export function inTons(value: number): number {
  const rounded = roundToNearestThousandValue(value)
  return rounded === 0 ? 0 : rounded / 1000
}

export const inKilos = (tons: number): number => tons * 1000

const useTonsLimit = 10

export const formatCo2 = (co2Tons: number): string => {
  const useTons = co2Tons > useTonsLimit
  if (useTons) {
    const formattedCo2Tons = formatRoundedNumber(co2Tons)
    return nonBreakingSpaces(`${formattedCo2Tons} t`)
  }
  const co2Kilos = inKilos(co2Tons)
  const formattedCo2Kilos = formatRoundedNumber(co2Kilos)
  return nonBreakingSpaces(`${formattedCo2Kilos} kg`)
}

export const formatCo2Kilos = (co2Kilos: number): string =>
  formatCo2(co2Kilos / 1000)

const formatRoundedNumber = (value: number): string =>
  formatNumber(Math.round(value))

export const formatQuantity = (value: number): string =>
  formatNumber(roundToNearest(value, 0.01)) + ' kpl'

export const formatMWh = (value: number): string =>
  nonBreakingSpaces(`${formatRoundedNumber(value)} MWh`)

export const formatMWhEstimate = (value: number): string =>
  `(+${formatMWh(value)})`

export function formatFootprint(value: number): string {
  return nonBreakingSpaces(
    value < 1
      ? formatPercentageFromDecimal(value, 0)
      : `${formatRoundedNumber(value)} hlö`
  )
}

export const formatCo2Estimate = (value: number): string =>
  `(+${formatCo2(value)})`

export const formatCo2EstimateKilos = (valueInKilos: number): string =>
  formatCo2Estimate(valueInKilos / 1000)

export const formatFootprintEstimate = (value: number): string =>
  `(+${formatFootprint(value)})`

export function getDateYearAgo() {
  const yearAgo = new Date()
  yearAgo.setFullYear(yearAgo.getFullYear() - 1)
  return yearAgo
}

export function formatElementId(id: string) {
  return id.toLowerCase().replace(/ö|ä|\s/g, '')
}
