import React from 'react'
import { ViewStyle, StyleProp } from 'react-native'
import { observer } from 'mobx-react'
import TransactionStore, {
  PickerItem,
} from '@taaleri/core/src/stores/TransactionStore'
import Selector from './Selector'
import { SelectorTitlePicker } from './SelectorTitle'
import { useTranslation } from 'react-i18next'

interface Props {
  onToggle: () => void
  isOpen: boolean
  containerStyle?: StyleProp<ViewStyle>
}

function InstrumentSelector({ onToggle, isOpen, containerStyle }: Props) {
  const { t } = useTranslation()
  const { filterByInstrument, selectedInstruments, allInstruments } =
    TransactionStore
  const count = filterByInstrument
    ? selectedInstruments.length
    : allInstruments.length

  const titleComponent = (
    <SelectorTitlePicker
      title={`${t('report.search-by-security-name')}`}
      selectedItems={selectedInstruments}
      onRemove={(s: PickerItem) => TransactionStore.removeSelectedInstrument(s)}
    />
  )

  return (
    <Selector
      {...{ onToggle, isOpen, count, titleComponent, containerStyle }}
    />
  )
}

export default observer(InstrumentSelector)
