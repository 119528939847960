import { Animated } from 'react-native'

import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { TextBold } from '../../../ui/text/UiText'
import { BOTTOM_WITHOUT_KEYBOARD } from '@taaleri/core/src/utils/dimensions'
import { useTranslation } from 'react-i18next'

let opacity = new Animated.Value(1)

export default function FadingFeedback() {
  const { t } = useTranslation()
  opacity = new Animated.Value(1)
  Animated.timing(opacity, {
    toValue: 0,
    duration: 3000,
  }).start((val) => {
    if (val.finished) {
      AppStore.simpleFeedbackDismissed = true
    }
  })

  return (
    <Animated.View
      style={{
        height: 145,
        alignItems: 'center',
        justifyContent: 'center',
        opacity,
        backgroundColor: Colors.white,
        bottom: BOTTOM_WITHOUT_KEYBOARD,
        left: 0,
        right: 0,
        borderColor: Colors.border,
        borderWidth: 1,
        borderRadius: BorderRadius.default,
        zIndex: 10000,
      }}
    >
      <TextBold>{t('feedback.thanks')}</TextBold>
    </Animated.View>
  )
}
