/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Animated, StyleProp, StyleSheet } from 'react-native'

const FlexRow: React.FC<{ children?: any; style?: StyleProp<any> }> = ({
  children,
  style,
}) => <Animated.View style={[styles.flexRow, style]}>{children}</Animated.View>

export default FlexRow

const styles = StyleSheet.create({
  flexRow: { flexDirection: 'row' },
})
