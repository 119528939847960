import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { View, ImageBackground } from 'react-native'

import PercentagePieWithNumber from '../../components/PercentagePieWithNumber'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../ui/FlexRow'
import {
  formatCurrency,
  formatNumberWithPrefix,
} from '@taaleri/core/src/utils/format'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { IconArrowDownSlim, IconArrowUpSlim } from '../../ui/icons/ArrowIcons'

type BoxType = 'default' | 'special'

interface ImageWithPercentageProps {
  title: string
  image: any
  weights: number[]
  weight: number
  boxType?: BoxType
  marketValue: number
  marketValueChangePercentage?: number
}

const AssetDetailBox: React.FC<ImageWithPercentageProps> = ({
  title,
  image,
  weights,
  weight,
  marketValue,
  marketValueChangePercentage,
  boxType,
}) => {
  const isSpecial = boxType === 'special'
  const pieSize = 68
  const height = isSpecial ? 100 : 135
  const borderRadiusBottom = isSpecial ? 0 : BorderRadius.default

  return (
    <ImageBackground
      source={image}
      style={{
        height,
        borderRadius: BorderRadius.default,
        borderBottomLeftRadius: borderRadiusBottom,
        borderBottomRightRadius: borderRadiusBottom,
        overflow: 'hidden',
      }}
      resizeMode="cover"
    >
      <FlexRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: Spacings.S24,
          height,
        }}
      >
        <View>
          <TextDefault type="h4" color={Colors.white}>
            {title}
          </TextDefault>
          <FlexRow style={{ alignItems: 'center' }}>
            <TextDefault
              type="t2"
              color={Colors.white}
              style={{ letterSpacing: 0.1 }}
            >
              {formatCurrency(marketValue)}
            </TextDefault>
            {marketValueChangePercentage ? (
              <>
                <TextDefault
                  type="t3"
                  color={Colors.white}
                  style={{
                    marginLeft: Spacings.S8,
                    letterSpacing: -0.8,
                    marginRight: 2,
                  }}
                >
                  {formatNumberWithPrefix(marketValueChangePercentage)} %
                </TextDefault>
                {marketValueChangePercentage > 0 && (
                  <IconArrowUpSlim color={Colors.white} />
                )}
                {marketValueChangePercentage < 0 && (
                  <IconArrowDownSlim color={Colors.white} />
                )}
              </>
            ) : null}
          </FlexRow>
        </View>
        <PercentagePieWithNumber
          percentages={weights}
          percentage={weight}
          theme="white"
          size={pieSize}
        />
      </FlexRow>
    </ImageBackground>
  )
}

export default AssetDetailBox
