import { Image, View, StyleSheet } from 'react-native'

import React from 'react'

import Spacings from '@taaleri/core/src/constants/Spacings'
import { observer } from 'mobx-react'

import AppStore from '@taaleri/core/src/stores/AppStore'
import { BoxWithPadding } from '../../ui/box/Box'
import FlexRow from '../../ui/FlexRow'

import { Title3 } from '../../ui/text/Title'
import { TextMedium, TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { IconPhone, IconEmail } from '../../ui/Icons'
import UiTouchable from '../../ui/UiTouchable'
import { openUrl } from '../../ui/openUrl'
import { AccountManager } from '@taaleri/core/src/models/Customer'
import useLayout from '../../ui/useLayout'
import { useTranslation } from 'react-i18next'
import CustomerService from '@taaleri/core/src/constants/CustomerService'

interface Props {
  accountManager?: AccountManager
}

function AccountManagerSection(props: Props) {
  const { t } = useTranslation()
  const { isSmall } = useLayout()
  const accountManager = props.accountManager || AppStore.getAccountManager()
  if (!accountManager || !accountManager.lastName) {
    return null
  }

  function onCall(phoneNumber: string) {
    const url = `tel:${phoneNumber}`
    openUrl(url)
  }

  function onEmail(email: string) {
    const url = `mailto:${email}`
    openUrl(url)
  }

  const showContact = props.accountManager === undefined
  console.log(showContact)

  const imageSize = isSmall ? 80 : 112

  return (
    <BoxWithPadding
      style={{ marginBottom: Spacings.S24, paddingBottom: 0 }}
      title={
        showContact
          ? accountManager.firstName
            ? `${t('profile.accountManagerSection.banker')}`
            : `${t('profile.customerServiceSection.contact')}`
          : undefined
      }
    >
      <FlexRow
        style={[
          {
            alignItems: 'center',
            paddingVertical: Spacings.S24,
          },
          showContact && {
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
          },
        ]}
      >
        <Image
          style={[styles.image, { width: imageSize, height: imageSize }]}
          source={{
            uri: accountManager.imgRound,
          }}
        />
        <View>
          <Title3 style={[{ marginBottom: 4, marginTop: 0 }]}>
            {`${accountManager.firstName ?? ''} `}
            {`${accountManager.lastName}`}
          </Title3>
          {accountManager.description && (
            <TextDefault
              type="t2"
              color={Colors.textSecondary}
              style={[{ marginBottom: Spacings.S8 }]}
            >
              {accountManager.description}
            </TextDefault>
          )}
          <TextDefault
            type="t2"
            style={[
              {
                marginBottom: Spacings.S4,
                alignSelf: accountManager.firstName ? 'flex-start' : 'center',
              },
            ]}
          >
            {accountManager.phoneNumber}
          </TextDefault>
          {accountManager.email && (
            <TextMedium style={[{ marginBottom: Spacings.S24 }]}>
              {accountManager.email}
            </TextMedium>
          )}
        </View>
      </FlexRow>
      {showContact && (
        <FlexRow
          style={{
            justifyContent: 'space-between',
            paddingVertical: Spacings.S16,
          }}
        >
          <UiTouchable
            onPress={() => onCall(accountManager.phoneNumber)}
            style={[
              styles.contactContainer,
              {
                borderRightWidth: 1,
                borderRightColor: Colors.border,
              },
            ]}
          >
            <IconPhone />
            <TextMedium style={styles.contactText}>
              {t('profile.customerServiceSection.call')}
            </TextMedium>
          </UiTouchable>
          <UiTouchable
            style={styles.contactContainer}
            onPress={() =>
              accountManager.email
                ? onEmail(accountManager.email)
                : openUrl(CustomerService.messageUrl)
            }
          >
            <IconEmail />

            <TextMedium style={styles.contactText}>
              {t('profile.customerServiceSection.message')}
            </TextMedium>
          </UiTouchable>
        </FlexRow>
      )}
    </BoxWithPadding>
  )
}

const styles = StyleSheet.create({
  image: {
    borderRadius: 56,
    marginRight: Spacings.S24,
  },

  contactContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactText: {
    fontSize: FontSize.S14,
    marginLeft: Spacings.S16,
  },
})

export default observer(AccountManagerSection)
