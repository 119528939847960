import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { OwnershipRow } from './OwnershipRow'
import Box from '../../ui/box/Box'
import UiTouchable from '../../ui/UiTouchable'
import { AssetClass, AssetClassItem } from '@taaleri/core/src/models/Portfolio'
import {
  calculateTotalDuration,
  calculateAverageYieldToMaturity,
} from '@taaleri/core/src/utils/durationHelper'
import useLayout from '../../ui/useLayout'
import { View } from 'react-native'
import {
  ASSET_CLASS_ID_CASH,
  isCashCategory,
} from '@taaleri/core/src/constants/AssetClass'
import { TextDefault } from '../../ui/text/UiText'
import { formatAssetCategoryName, sum } from '@taaleri/core/src/utils/format'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import { OwnershipHeadingAdvanced } from './OwnerShipHeadingAdvanced'
import sortBy from 'lodash/sortBy'
import { CashRow } from './CashRow'
import { CashHeading } from './CashHeading'
import { useTranslation } from 'react-i18next'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  items: AssetClassItem[]
  assetCategoryName: string
  assetCategoryId: string
  assetClass: AssetClass
  isBond?: boolean
  isStructured?: boolean
  onPress?: (ticker: string) => void
}

export function AssetCategoryDetail({
  assetCategoryName,
  items,
  assetClass,
  assetCategoryId,
  isBond,
  isStructured,
  onPress,
}: Props) {
  const { t } = useTranslation()
  const { screenWidth, isSmall } = useLayout()
  const cashCategory = isCashCategory(assetCategoryId)
  const isCash = assetClass.id === ASSET_CLASS_ID_CASH
  const totalMarketValue = sum(items.map((e) => e.marketValue))
  const totalPurchaseValue = sum(items.map((e) => e.purchaseValue))
  const totalSharePercentage = sum(items.map((e) => e.sharePercentage))
  const totalDuration = calculateTotalDuration(items)
  const totalAccruedInterest = sum(items.map((e) => e.accruedInterest || 0))
  const avgYieldToMaturity = calculateAverageYieldToMaturity(items)
  const totalMarketValueChangePercent = totalPurchaseValue
    ? (100 * (totalMarketValue - totalPurchaseValue)) / totalPurchaseValue
    : 0
  const totalCommitments = sum(items.map((e) => e.commitmentAmount || 0))

  const hideDocumentColumn = !items.some((e) => e.isSecurityDocumentAvailable)
  const hideDuration = totalDuration === 0
  const hideYieldToMaturity = avgYieldToMaturity === 0
  const hideCommitmentAmount = totalCommitments === 0
  const hideAccruedInterest = totalAccruedInterest === 0

  return (
    <View style={{ marginBottom: Spacings.S24 }}>
      <TextDefault type="h6" style={{ marginBottom: 12 }}>
        {cashCategory ? t('cash') : formatAssetCategoryName(assetCategoryName)}
      </TextDefault>
      {cashCategory ? (
        <CashHeading />
      ) : (
        <OwnershipHeadingAdvanced
          isCash={isCash}
          isBond={isBond}
          isStructured={isStructured}
          hideDocumentColumn={hideDocumentColumn}
          hideDuration={hideDuration}
          hideYieldToMaturity={hideYieldToMaturity}
          hideCommitmentAmount={hideCommitmentAmount}
          hideAccruedInterest={hideAccruedInterest}
        />
      )}

      <Box>
        {sortBy(items, 'name').map((security, index) => {
          const topBorderRadius = !isSmall && index === 0
          const getSecurityName = (): string =>
            AppStore.currentLanguage === fiKey
              ? security.nameFi
              : security.nameSv
          return cashCategory ? (
            <CashRow
              borderBottom={true}
              key={index}
              color={assetClassColors[assetClass.id]}
              sharePercentage={security.sharePercentage}
              marketValue={security.marketValue}
              name={getSecurityName()}
              topBorderRadius={topBorderRadius}
            />
          ) : (
            <UiTouchable
              key={index}
              onPress={() => {
                if (onPress) {
                  onPress(security.ticker)
                }
              }}
            >
              <OwnershipRow
                borderBottom={true}
                key={index}
                color={assetClassColors[assetClass.id]}
                assetClassItem={security}
                screenWidth={screenWidth}
                topBorderRadius={topBorderRadius}
                showHover={true}
                bottomBorderRadius={isSmall && index === items.length - 1}
                isBond={isBond}
                isStructured={isStructured}
                isAdvanced={true}
                hideDocumentColumn={hideDocumentColumn}
                hideDuration={hideDuration}
                hideYieldToMaturity={hideYieldToMaturity}
                isCash={isCash}
                hideCommitmentAmount={hideCommitmentAmount}
                hideAccruedInterest={hideAccruedInterest}
              />
            </UiTouchable>
          )
        })}
        {!isSmall && cashCategory ? (
          <CashRow
            marketValue={totalMarketValue}
            name={`${t('portfolio.total')}`}
            sharePercentage={totalSharePercentage}
            total={true}
            borderBottom={false}
            color={assetClassColors[assetClass.id]}
          />
        ) : (
          <OwnershipRow
            screenWidth={screenWidth}
            total={true}
            borderBottom={false}
            color={assetClassColors[assetClass.id]}
            assetClassItem={{
              name: t('portfolio.total'),
              friendlyName: t('portfolio.total'),
              securityName: t('portfolio.total'),
              marketValue: totalMarketValue,
              purchaseValue: totalPurchaseValue,
              ticker: '',
              sharePercentage: totalSharePercentage,
              duration: totalDuration,
              accruedInterest: totalAccruedInterest,
              yieldToMaturity: avgYieldToMaturity,
              marketValueChangePercent: totalMarketValueChangePercent,
              assetAdditionalInfoId: '',
              assetCategoryId: '',
              assetCategoryName: '',
              assetAdditionalInfoName: '',
              commitmentAmount: totalCommitments,
              nameFi: t('portfolio.total'),
              nameSv: t('portfolio.total'),
              nameEn: t('portfolio.total'),
            }}
            bottomBorderRadius={true}
            hideDuration={hideDuration}
            hideYieldToMaturity={hideYieldToMaturity}
            isCash={isCash}
            isBond={isBond}
            isAdvanced={true}
            isStructured={isStructured}
            hideDocumentColumn={hideDocumentColumn}
            hideCommitmentAmount={hideCommitmentAmount}
            hideAccruedInterest={hideAccruedInterest}
          />
        )}
      </Box>
    </View>
  )
}
