import { PurchaseLot, PurchaseLotResponse } from '../../models/Portfolio'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'

export function toPurchaseLots(
  purchaseLotsResponse: PurchaseLotResponse[]
): PurchaseLot[] {
  return reverse(
    sortBy(
      purchaseLotsResponse.map((purchaseLot) => ({
        quantity: purchaseLot.quantity,
        securityTypeId: purchaseLot.financialInstrument?.securityTypeId,
        purchaseDate: purchaseLot.purchaseDate,
        purchasePrice: purchaseLot.purchasePrice.baseCurrency.value,
        marketValue: purchaseLot.marketValue.baseCurrency.value,
        marketPrice: purchaseLot.marketPrice
          ? purchaseLot.marketPrice.baseCurrency.value
          : undefined,
        marketPriceWithoutInterests: purchaseLot.marketPriceWithoutInterests
          ? purchaseLot.marketPriceWithoutInterests.baseCurrency.value
          : undefined,
        purchaseValue: purchaseLot.purchaseValue.baseCurrency.value,
        marketValueChangePercent: purchaseLot.marketValueChangePercent
          .baseCurrency
          ? purchaseLot.marketValueChangePercent.baseCurrency.value * 100
          : undefined,
      })),
      (purchaseLot: PurchaseLot) => purchaseLot.purchaseDate
    )
  )
}
