import React from 'react'

import { ListItemLinkData } from '../../ui/ListItem'

import { getConfig } from '@taaleri/core/src/config'
import {
  ROUTE_PROFILE_STYLE_GUIDE,
  ROUTE_PROFILE_TESTING,
} from './ProfileRoutes'
import CardWithLinks from '../../ui/CardWithLinks'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  ROUTE_IMPACT_INTRO,
  ROUTE_IMPACT_DISABLE,
} from '../impact/ImpactRoutes'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { isWeb } from '../../constants/Platforms'
import { ROUTE_REPORT_TRANSACTION } from '../report/ReportRoutes'

const TestingSection: React.FC = () => {
  if (!getConfig().testMode) {
    return null
  }
  const isApp = !isWeb
  const linkItems: ListItemLinkData[] = [
    { title: 'Tapahtumat', link: ROUTE_REPORT_TRANSACTION },
    {
      title: 'Style guide',
      link: ROUTE_PROFILE_STYLE_GUIDE,
    },
    ...insertIf(isApp, { title: 'Testaus', link: ROUTE_PROFILE_TESTING }),
  ]
  return (
    <View style={{ marginTop: Spacings.S56 }}>
      <CardWithLinks title="Testaus" items={linkItems} />
    </View>
  )
}

export default TestingSection
