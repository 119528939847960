import React from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { TextMedium } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import FlexRow from '../../ui/FlexRow'
import { IconError } from '../../ui/Icons'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { useTranslation } from 'react-i18next'

export default function BlockedCustomerWarning({
  style,
}: {
  style?: StyleProp<ViewStyle>
}) {
  const { t } = useTranslation()
  return AppStore.isCustomerBlocked ? (
    <FlexRow style={[styles.container, style]}>
      <IconError color={Colors.warning} style={styles.icon} />
      <TextMedium style={{ flex: 1 }}>
        {t('invest.previous-in-progress')}
      </TextMedium>
    </FlexRow>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    borderRadius: BorderRadius.default,
    padding: Spacings.S16,
    borderColor: Colors.warning,
    borderWidth: 1,
    marginBottom: Spacings.S40,
  },
  icon: { justifyContent: 'center', marginRight: Spacings.S16, marginLeft: 0 },
})
