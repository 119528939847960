import React from 'react'
import { BackButtonRow } from '@taaleri/components/src/ui/BackButton'
import ReportFeeContent from '@taaleri/components/src/screens/reportfee/ReportFeeContent'
import { Content } from 'screens/app/Content'
import { useTranslation } from 'react-i18next'

export function ReportFeeScreen() {
  const { t } = useTranslation()
  return (
    <Content gridWidth={1280}>
      <>
        <Content gridWidth={1200}>
          <BackButtonRow title={`${t('navigator.expense-report')}`} />
        </Content>
        <ReportFeeContent t={t} />
      </>
    </Content>
  )
}
