import routePath, {
  routePathContract,
} from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_CONTRACT_START_EVERVEST } from '@taaleri/components/src/screens/contract/ContractRoutes'
import {
  investStartRoute,
  startInvest,
} from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import LinkApp from '@taaleri/components/src/ui/LinkApp'
import UiButton from '@taaleri/components/src/ui/UiButton'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import Nav from 'navigation/Nav'
import navItems from 'navigation/navItems'
import React from 'react'
import { Link } from 'react-router-dom'
import HeaderLargeContainer from './HeaderLargeContainer'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const AktiaLogoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const LinksContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
`

export const SmallAktiaLogoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

function HeaderLarge() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { isMediumPlus } = useLayout()
  return (
    <HeaderLargeContainer>
      <AktiaLogoContainer>
        <Link
          to={
            AppStore.forceEvervestContract
              ? routePathContract(ROUTE_CONTRACT_START_EVERVEST)
              : routePath(ROUTE_APP)
          }
        >
          <AktiaLogo />
        </Link>
      </AktiaLogoContainer>
      {!AppStore.forceEvervestContract && (
        <LinksContainer>
          {navItems(AppStore.showPortfolio, AppStore.showImpact, t).map((n) => (
            <LinkApp
              key={n.title}
              onClick={() => {
                Analytics.event('Nav', 'Nav click', n.title)
              }}
              to={n.path}
              style={{
                marginRight: '48px',
                whiteSpace: 'nowrap',
                paddingBottom: Spacings.S8,
                fontSize: FontSize.S14,
                letterSpacing: 0.2,
              }}
              type="text"
              activeStyle={{
                borderBottom: `2px solid ${Colors.primary}`,
              }}
              exact={true}
            >
              {n.title}
            </LinkApp>
          ))}
          <Nav type="desktop" />
          {/* {AppStore.showPortfolioActions && isMediumPlus && (
            <UiButton
              containerStyle={{
                marginLeft: Spacings.S32,
                minWidth: 'initial',
              }}
              size="small"
              title={`${t('button.invest')}`}
              onPress={() => {
                startInvest()
                navigate(investStartRoute())
              }}
            />
          )}
          {AppStore.showFirstInvestment &&
            isMediumPlus &&
            !AppStore.isConsultative && (
              <UiButton
                containerStyle={{
                  marginLeft: Spacings.S32,
                  minWidth: 'initial',
                  whiteSpace: 'noWrap',
                }}
                title={`${t('button.start-invest')}`}
                onPress={() => {
                  startInvest()
                  navigate(investStartRoute())
                }}
              />
            )} */}
        </LinksContainer>
      )}
    </HeaderLargeContainer>
  )
}

export default observer(HeaderLarge)
