import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'
import ChangeUsernameForm from '@taaleri/components/src/screens/profile/ChangeUsernameForm'

export default function ChangeUsernameScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <ChangeUsernameForm />
    </ContentContainerBox>
  )
}
