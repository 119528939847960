import React from 'react'
import FlexRow from './FlexRow'
import { UiSwitch } from './form/UiSwitch'
import { TextMedium } from './text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleProp, ViewStyle } from 'react-native'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { useTranslation } from 'react-i18next'

interface Props {
  onValueChange: (value: boolean) => void
  value: boolean
  visible: boolean
  style?: StyleProp<ViewStyle>
}

export default function CalculatorSwitch({
  style,
  value,
  onValueChange,
  visible,
}: Props) {
  const { t } = useTranslation()

  return visible ? (
    <FlexRow style={[style]}>
      <UiSwitch onValueChange={onValueChange} value={value} />
      <TextMedium
        style={{ marginLeft: Spacings.S16, fontSize: FontSize.S12, flex: 1 }}
      >
        {t('app.calculator.addcurrentinvestments')}
      </TextMedium>
    </FlexRow>
  ) : null
}
