import React from 'react'
import { isWeb } from '../../constants/Platforms'
import {
  ROUTE_PORTFOLIO,
  ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL,
} from './PortfolioRoutes'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import useAdvancedPortfolio from './useAdvancedPortfolio'

export default function useAssetClassDetailRoute(assetClass: AssetClass): {
  routeName: string
  params?: any
} {
  const showAdvancedPortfolio = useAdvancedPortfolio()
  const { id, name } = assetClass
  if (isWeb) {
    if (showAdvancedPortfolio) {
      return { routeName: ROUTE_PORTFOLIO + '?assetClass=' + id }
    }
    return { routeName: ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL + '/' + id }
  } else {
    return {
      routeName: ROUTE_PORTFOLIO_ASSET_CLASS_DETAIL,
      params: { assetClass: name },
    }
  }
}
