import React from 'react'
import { BoxWithPadding } from '../../ui/box/Box'
import {
  formatCurrency,
  formatPercentageTwoDecimal,
  formatCo2Kilos,
} from '@taaleri/core/src/utils/format'
import {
  getRevenueTimeseries,
  getFinalBalance,
  getInvestedCapital,
  getInvestedCapitalTimeseries,
} from '../questions/RevenueCalculator'
import FlexRow from '../../ui/FlexRow'
import { TextMedium, TextBold, TextDefault } from '../../ui/text/UiText'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import GlobalStyles from '../../constants/GlobalStyles'
import { StyleSheet, View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '../../ui/useLayout'
import CalculatorGraph from './CalculatorGraph'
import { IconCalendar, IconSingle, IconCo2 } from '../../ui/Icons'
import {
  calculateCo2Compensation,
  ImpactParameters,
} from '../questions/impactCalculator'
import CalculatorSwitch from '../../ui/CalculatorSwitch'
import { useTranslation } from 'react-i18next'

function Year({ year }: { year: number }) {
  const { t } = useTranslation()
  return (
    <>
      <IconCalendar style={styles.icon} />
      <TextMedium style={{ fontSize: FontSize.S10 }}>{`${t(
        'form.year'
      )} `}</TextMedium>
      <TextBold>{` ${year.toString()}`}</TextBold>
    </>
  )
}

function Age({
  age,
  investmentTime,
}: {
  age?: number
  investmentTime: number
}) {
  const { t } = useTranslation()
  return age ? (
    <>
      <IconSingle style={styles.icon} />
      <TextMedium style={{ fontSize: FontSize.S10 }}>{` ${t(
        'questions.age-title'
      )} `}</TextMedium>
      <TextBold>{` ${(age + investmentTime).toString()}v`}</TextBold>
    </>
  ) : null
}

function Co2Compensation({ co2 }: { co2: number }) {
  const { t } = useTranslation()
  return (
    <FlexRow style={{ alignItems: 'center' }}>
      <IconCo2 style={styles.icon} />
      <TextMedium style={GlobalStyles.smallText}>
        {t('app.calculator.co2-compensation')}
      </TextMedium>
      <TextDefault type="h5" style={styles.revenue}>
        {formatCo2Kilos(co2)}
      </TextDefault>
    </FlexRow>
  )
}

interface Props {
  monthlyInvestment: number
  initialInvestment: number
  investmentTime: number
  expectedReturn: number
  impactShare: number
  includeMarketValue: boolean
  marketValue: number
  setIncludeMarketValue: (value: boolean) => void
  riskLevel: number
  age?: number
  actualizedCo2Kilos: number
  isPrivateEquity: boolean
}

function CalculatorGraphContainer({
  monthlyInvestment,
  initialInvestment,
  investmentTime,
  expectedReturn,
  riskLevel,
  age,
  impactShare,
  marketValue,
  includeMarketValue,
  setIncludeMarketValue,
  isPrivateEquity,
  actualizedCo2Kilos,
}: Props) {
  const totalInitialValue = includeMarketValue
    ? initialInvestment + marketValue
    : initialInvestment

  const revenueTimeseries = getRevenueTimeseries(
    expectedReturn,
    totalInitialValue,
    monthlyInvestment,
    0,
    investmentTime
  )
  const capitalTimeseries = getInvestedCapitalTimeseries(
    totalInitialValue,
    monthlyInvestment,
    0,
    investmentTime
  )

  const parameters: ImpactParameters = {
    impactShare,
    expectedReturn,
    initialInvestment: totalInitialValue,
    monthlyInvestment,
    investmentTime,
    actualizedCo2Kilos,
    isPrivateEquity,
  }
  const co2 = calculateCo2Compensation(parameters)
  const finalBalance = getFinalBalance(revenueTimeseries, investmentTime)
  const investedCapital = getInvestedCapital(
    totalInitialValue,
    monthlyInvestment,
    investmentTime
  )
  const revenue = finalBalance - investedCapital
  const lastYear = new Date().getFullYear() + investmentTime
  const { isWide } = useLayout()
  const showCompensation = isWide && co2 > 0 && impactShare > 0
  const showSlider = !isWide && marketValue > 0
  const { t } = useTranslation()

  return (
    <BoxWithPadding
      title={`${t('app.calculator.estimatedyield')}`}
      style={{
        flex: 1,
        marginTop: isWide ? 0 : Spacings.S16,
        marginLeft: isWide ? Spacings.S24 : 0,
      }}
      titleRightComponent={
        <FlexRow style={{ alignItems: 'center' }}>
          {isWide ? <Age age={age} investmentTime={investmentTime} /> : null}
          <Year year={lastYear} />
        </FlexRow>
      }
    >
      <FlexRow style={[styles.row, { paddingVertical: 12 }]}>
        <FlexRow style={{ alignItems: 'center' }}>
          <TextMedium style={GlobalStyles.smallText}>
            {t('app.calculator.total-title')}
          </TextMedium>
          <TextDefault type="h3" style={styles.revenue}>
            {formatCurrency(Math.round(finalBalance))}
          </TextDefault>
        </FlexRow>
        {showCompensation ? <Co2Compensation co2={co2} /> : null}
      </FlexRow>
      <FlexRow
        style={[
          styles.row,
          {
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: Spacings.S24,
          },
        ]}
      >
        <FlexRow style={{ alignItems: 'center', paddingBottom: Spacings.S4 }}>
          <TextMedium style={GlobalStyles.smallText}>
            {t('app.calculator.saved-principal-title')}
          </TextMedium>
          <TextBold size={FontSize.S14} style={styles.revenue}>
            {formatCurrency(Math.round(investedCapital))}
          </TextBold>
        </FlexRow>
        <FlexRow style={{ alignItems: 'center' }}>
          <TextMedium style={GlobalStyles.smallText}>{`${t(
            'app.calculator.for-profit'
          )} (${formatPercentageTwoDecimal(
            100 * expectedReturn
          )} %)`}</TextMedium>
          <TextBold size={FontSize.S14} style={styles.revenue}>
            {formatCurrency(Math.round(revenue))}
          </TextBold>
        </FlexRow>
        {riskLevel ? (
          <FlexRow
            style={{
              alignItems: 'center',
            }}
          >
            <TextMedium style={GlobalStyles.smallText}>
              {t('app.calculator.risklevel').toUpperCase()}
            </TextMedium>
            <View style={styles.circle}>
              <TextBold size={FontSize.S12} style={{ color: Colors.white }}>
                {riskLevel}
              </TextBold>
            </View>
          </FlexRow>
        ) : null}
      </FlexRow>
      <CalculatorGraph {...{ revenueTimeseries, capitalTimeseries }} />
      <CalculatorSwitch
        style={styles.switch}
        onValueChange={setIncludeMarketValue}
        value={includeMarketValue}
        visible={showSlider}
      />
    </BoxWithPadding>
  )
}

const styles = StyleSheet.create({
  row: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
    paddingVertical: Spacings.S16,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  revenue: {
    letterSpacing: -0.7,
    marginLeft: Spacings.S8,
  },
  icon: { marginRight: Spacings.S8, marginLeft: Spacings.S24 },
  circle: {
    marginLeft: Spacings.S8,
    width: Spacings.S24,
    height: Spacings.S24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: Colors.primary,
  },
  switch: {
    alignItems: 'center',
    paddingTop: Spacings.S16,
    marginTop: Spacings.S16,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
})

export default CalculatorGraphContainer
