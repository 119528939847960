import React from 'react'
import ContractUnsuccesfulContent from '@taaleri/components/src/screens/contract/ContractUnsuccessfullContent'
import { ButtonAction } from '@taaleri/components/src/ui/UiButton'
import BackgroundContainer from 'ui/container/BackgroundContainer'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { ROUTE_SIGN_IN } from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { useTranslation } from 'react-i18next'

export default function TwoFactorAuthFailedScreen() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <BackgroundContainer contentType="centered">
      <TopBottomContainer>
        <ContractUnsuccesfulContent />
        <ButtonAction
          title={t('button.close')}
          onPress={() => navigate(ROUTE_SIGN_IN)}
        />
      </TopBottomContainer>
    </BackgroundContainer>
  )
}
