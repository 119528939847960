import React from 'react'
import QuestionContainer from './QuestionContainer'
import { QUESTION_DAILY_IMPORTANCE } from '@taaleri/components/src/screens/questions/questions'
import DailyImportanceContent from '@taaleri/components/src/screens/questions/DailyImportanceContent'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DailyImportanceInfo = () => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <DailyImportanceContent />
        <Buttons
          title={t('button.back')}
          onPress={() => {
            Analytics.event('Onboarding', 'Daily importance info back')
            history.push(QUESTION_DAILY_IMPORTANCE)
          }}
        />
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default DailyImportanceInfo
