import React from 'react'
import { customers } from '@taaleri/core/src/services/customers'
import { openAccountNumberTupas } from './OpenTupas'
import AccountNumberForm from '../onboarding/AccountNumberForm'
import { Paragraph } from '../../ui/text/Paragraph'
import AppStore from '@taaleri/core/src/stores/AppStore'

import { formatIban } from '@taaleri/core/src/utils/format'
import { CurrentValue } from './CurrentValue'
import { useTranslation } from 'react-i18next'

export default function ChangeAccountNumberForm() {
  const { t } = useTranslation()
  async function onSuccess(accountNumber: string) {
    const url = await customers.getTupasUrlChangeAccountNumber(accountNumber)
    await openAccountNumberTupas(url, accountNumber)
  }
  return (
    <AccountNumberForm
      onDone={onSuccess}
      buttonTitle={`${t('profile.proceed-to-login')}`}
      title={`${t('profile.contractSection.update-bankno')}`}
      defaultValue=""
      label={`${t('profile.new-account-number')}`}
    >
      <Paragraph>{t('profile.account-number-change-info')}</Paragraph>
      <CurrentValue
        label={`${t('profile.current-account-number')}:`}
        value={formatIban(AppStore.activeCustomer.bankAccountNumber)}
      />
    </AccountNumberForm>
  )
}
