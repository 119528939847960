import React from 'react'
import UiTouchable from '../../../ui/UiTouchable'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleProp, ViewStyle, StyleSheet } from 'react-native'
import { IconCheckCircleWhite } from '../../../ui/icons/CircleIcons'

interface Props {
  title: string
  onRemove: () => void
  containerStyle?: StyleProp<ViewStyle>
}

export function SelectorItem({ title, onRemove, containerStyle }: Props) {
  return (
    <UiTouchable style={[styles.container, containerStyle]} onPress={onRemove}>
      <IconCheckCircleWhite width={16} height={16} style={styles.icon} />
      <TextDefault type="t2" numberOfLines={1} style={{ flexShrink: 1 }}>
        {title}
      </TextDefault>
    </UiTouchable>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderColor: Colors.border,
    paddingVertical: Spacings.S4,
    borderWidth: 1,
    marginHorizontal: Spacings.S4,
    paddingHorizontal: Spacings.S4,
    backgroundColor: Colors.white,
    flexShrink: 1,
    flexDirection: 'row',
    marginVertical: Spacings.S2,
  },
  icon: { justifyContent: 'center', marginRight: Spacings.S4 },
})
