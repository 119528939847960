import React, { useState } from 'react'
import * as Yup from 'yup'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import Title from '../../ui/text/Title'
import { Formik } from 'formik'
import FormNextInputAutofocus from '../../ui/form/FormNextInputAutofocus'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  UiTextInputForm,
  UiTextInputFormAutoFocus,
} from '../../ui/form/UiTextInput'
import { ButtonAction } from '../../ui/UiButton'
import { View, StyleSheet } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import { TextDefault } from '../../ui/text/UiText'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { IdVerificationData } from '@taaleri/core/src/models/IdentityCard'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { customers } from '@taaleri/core/src/services/customers'
import { ROUTE_IDENTITY_CARD_SUMMARY } from '../identitycard/IdentityCardRoutes'
import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_IDENTITY_VALIDITY,
} from './OnboardingRoutes'
import Navigate from '../../navigation/Navigate'
import UiForm from '../../ui/form/UiForm'
import { isWeb } from '../../constants/Platforms'
import { today } from '@taaleri/core/src/utils/date'
import { IconExclamationCircle } from '../../ui/icons/CircleIcons'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

function isIdExpirationDateValid({
  day,
  month,
  year,
}: IdVerificationData): boolean {
  if (year && month && day) {
    const idExpirationDate: Date = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    )
    return idExpirationDate.getTime() > today().getTime()
  }
  return false
}

const currentYear = new Date().getFullYear()
const maxYear = currentYear + 100

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    day: Yup.number()
      .min(1, '1-31')
      .max(31, '1-31')
      .required(`${t(ErrorMessages.mandatory)}`)
      .typeError('1-31'),
    month: Yup.number()
      .min(1, '1-12')
      .max(12, '1-12')
      .required(`${t(ErrorMessages.mandatory)}`)
      .typeError('1-12'),
    year: Yup.number()
      .min(currentYear, `${currentYear}-${maxYear}`)
      .max(maxYear, `${currentYear}-${maxYear}`)
      .required(`${t(ErrorMessages.mandatory)}`)
      .typeError(`${currentYear}-${maxYear}`),
    identityNumber: Yup.string().required(`${t(ErrorMessages.required)}`),
    identityIssuer: Yup.string().required(`${t(ErrorMessages.required)}`),
  })

const initialValue: IdVerificationData = {
  day: '',
  month: '',
  year: '',
  identityIssuer: '',
  identityNumber: '',
}

interface Props {
  navigate: Navigate
  whiteBackground?: boolean
}

export default function IdentityValidityForm(props: Props) {
  const { t } = useTranslation()
  const { whiteBackground } = props
  const [focusMonth, setFocusMonth] = useState<boolean>(false)
  const [focusYear, setFocusYear] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [expired, setExpired] = useState<boolean>(false)

  async function sendUpdate(values: IdVerificationData, update: boolean) {
    if (!AppStore.photoId) {
      return
    }
    Analytics.event('IdentityCard', update ? 'Update submit' : 'Create submit')
    setLoading(true)
    setError(false)
    const { success } = await customers.updateIdentityCard(
      values,
      update ? AppStore.customer : QuestionStore.customer,
      AppStore.photoId
    )
    if (!success) {
      setError(true)
      setLoading(false)
    }
    return success
  }

  async function submit(values: IdVerificationData) {
    setExpired(false)
    const isValid = isIdExpirationDateValid(values)
    if (!isValid) {
      setExpired(true)
    } else {
      QuestionStore.validityDate = values
      const update = QuestionStore.questionMode === 'contract'
      const didUpdate = await sendUpdate(values, update)
      if (!didUpdate) {
        return
      }
      AppStore.identityCardUpdated = true
      AppStore.showIdentityCardBox = false
      const nextRoute =
        QuestionStore.nextRouteIdentityUpdate.length > 0
          ? QuestionStore.nextRouteIdentityUpdate
          : update
          ? ROUTE_IDENTITY_CARD_SUMMARY
          : nextOnboardingRoute(ROUTE_ONBOARDING_IDENTITY_VALIDITY)
      props.navigate(nextRoute)
      QuestionStore.nextRouteIdentityUpdate = ''
    }
  }

  const initialValues: IdVerificationData = initialValue
  const buttonText = error ? t('button.try-again') : t('button.next')
  return (
    <View>
      <PaddingHorizontal>
        <Title>{t('idUpdate.valid-text')}</Title>
      </PaddingHorizontal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <FormNextInputAutofocus>
            <UiForm>
              <PaddingHorizontal
                style={{
                  marginTop: Spacings.S24,
                  marginBottom: 0,
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                }}
              >
                <UiTextInputFormAutoFocus
                  grayBackground={!whiteBackground}
                  containerStyle={{ flex: 1 }}
                  name="day"
                  label={`${t('form.day')}`}
                  type="digits"
                  hideCheckCircle={true}
                  value={values.day}
                  maxLength={2}
                  blurOnSubmit={false}
                  disableFloatingLabel={true}
                  onChangeText={(text: string) => {
                    if (Number(text) > 3 && Number(text) < 32) {
                      setFocusMonth(true)
                    }
                  }}
                />

                <UiTextInputFormAutoFocus
                  grayBackground={!whiteBackground}
                  containerStyle={{ flex: 1, paddingHorizontal: Spacings.S16 }}
                  name="month"
                  label={`${t('form.month')}`}
                  type="digits"
                  hideCheckCircle={true}
                  value={values.month}
                  maxLength={2}
                  disableFloatingLabel={true}
                  focus={focusMonth}
                  onFocus={() => {
                    setFocusMonth(false)
                  }}
                  blurOnSubmit={false}
                  onChangeText={(text: string) => {
                    if (Number(text) > 1 && Number(text) < 13) {
                      setFocusMonth(false)
                      setFocusYear(true)
                    }
                  }}
                />

                <UiTextInputFormAutoFocus
                  grayBackground={!whiteBackground}
                  containerStyle={{ flex: 1 }}
                  onFocus={() => {
                    setFocusYear(false)
                  }}
                  name="year"
                  label={`${t('form.year')}`}
                  type="digits"
                  hideCheckCircle={true}
                  value={values.year}
                  maxLength={4}
                  disableFloatingLabel={true}
                  focus={focusYear}
                />
              </PaddingHorizontal>

              <PaddingHorizontal>
                <UiTextInputForm
                  name="identityNumber"
                  label={`${t('form.identity-number')}`}
                  type="name"
                  requiredNotFilled={true}
                  editable={true}
                  disableAutoComplete={true}
                />
              </PaddingHorizontal>

              <PaddingHorizontal>
                <UiTextInputForm
                  name="identityIssuer"
                  label={`${t('form.identity-issuer')}`}
                  type="name"
                  requiredNotFilled={true}
                  editable={true}
                  disableAutoComplete={true}
                />
              </PaddingHorizontal>

              <PaddingHorizontal>
                <ButtonAction
                  containerStyle={{ marginTop: 0, marginBottom: Spacings.S16 }}
                  title={buttonText}
                  onPress={isWeb ? () => '' : handleSubmit}
                  loading={loading}
                />
              </PaddingHorizontal>
              {expired && (
                <PaddingHorizontal>
                  <View style={styles.box}>
                    <IconExclamationCircle
                      color={Colors.error}
                      style={styles.icon}
                    />
                    <TextDefault type="h5" style={styles.text}>
                      {ErrorMessages.idExpired}
                    </TextDefault>
                  </View>
                </PaddingHorizontal>
              )}
              {error && (
                <PaddingHorizontal>
                  <View style={styles.box}>
                    <IconExclamationCircle
                      color={Colors.error}
                      style={styles.icon}
                    />
                    <TextDefault type="h5" style={styles.text}>
                      {t('validation.sent-error')}
                    </TextDefault>
                  </View>
                </PaddingHorizontal>
              )}
            </UiForm>
          </FormNextInputAutofocus>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    paddingHorizontal: Spacings.S24,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: Spacings.S16,
  },
  icon: {
    marginRight: 10,
  },
  text: {
    color: Colors.error,
  },
})
