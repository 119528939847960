import React from 'react'
import { StyleProp, ViewStyle, StyleSheet } from 'react-native'
import Box from '@taaleri/components/src/ui/box/Box'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IdValuePair } from '@taaleri/core/src/models/IdValuePair'
import useLayout from '@taaleri/components/src/ui/useLayout'
import { HistoricalChangeSection } from './HistoricalChangeSection'
import { useTranslation } from 'react-i18next'

interface Props {
  historicalChanges: IdValuePair[]
  style?: StyleProp<ViewStyle>
}

export function HistoricalChangeDesktop({ historicalChanges, style }: Props) {
  const { t } = useTranslation()
  const { width } = useLayout()

  if (width < 1350 && !historicalChanges.some((e) => e.value)) {
    return null
  }
  return (
    <Box {...{ style }}>
      <FlexRow style={styles.container}>
        <TextDefault
          type="t5"
          color={Colors.gray70}
          style={{
            paddingLeft: Spacings.S20,
            paddingRight: Spacings.S40,
            marginVertical: 'auto',
          }}
        >
          {t('portfolio.historic-profit')}
        </TextDefault>

        {historicalChanges.map(({ value, id }) => {
          if (width < 1350 && (id === '1kk' || !value)) {
            return null
          }
          return (
            <HistoricalChangeSection
              {...{ value, id }}
              key={id}
              style={{
                borderColor: Colors.border,
                borderLeftWidth: 1,
                paddingRight: Spacings.S40,
                paddingLeft: Spacings.S40,
                alignItems: 'center',
              }}
            />
          )
        })}
      </FlexRow>
    </Box>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    marginVertical: Spacings.S20,
  },
})
