import ImpactChangeForm, {
  useUpdateImpact,
} from '@taaleri/components/src/screens/impact/ImpactChangeForm'
import { useInvestmentProposalToggleImpact } from '@taaleri/components/src/screens/questions/investmentproposal/useInvestmentProposal'
import ErrorWithRetry from '@taaleri/components/src/ui/ErrorWithRetry'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import UiButton from '../../../ui/UiButton'

interface Props {
  withImpact: boolean
}

function ImpactToggleContent({ withImpact }: Props) {
  const { t } = useTranslation()
  const { investmentProposal, error, setRetry } =
    useInvestmentProposalToggleImpact(AppStore.customerId, withImpact)

  const {
    loading,
    accept,
    setAccept,
    setError,
    error: impactError,
    updateImpact,
  } = useUpdateImpact()

  useEffect(() => {
    setRetry(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.currentLanguage])

  if (!investmentProposal) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={async () => setRetry(true)} />
  }

  const type = accept || !withImpact ? 'primary' : 'disabled'
  return (
    <>
      <ImpactChangeForm
        setError={setError}
        setAccept={setAccept}
        error={impactError}
      />
      <UiButton
        loading={loading}
        type={type}
        title={t('button.continue')}
        onPress={() => updateImpact()}
      />
    </>
  )
}

export default observer(ImpactToggleContent)
