import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { IconProps } from './IconProps'

export const IconExternalLink: React.FC<IconProps> = ({ style, color }) => (
  <View style={style}>
    <Svg width={13} height={12} viewBox="0 0 13 12" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.823 1.333v9.334h9.333V6h1.333v4.667c0 .736-.597 1.333-1.333 1.333H1.823a1.333 1.333 0 01-1.334-1.333V1.333C.49.6 1.083 0 1.823 0h4.666v1.333H1.823zm6 0V0h4.666v4.667h-1.333V2.273L4.602 8.827l-.94-.94 6.554-6.554H7.822z"
        fill={color || Colors.primary}
      />
    </Svg>
  </View>
)
