import { Allocation } from './InvestmentStrategy'
import { SustainableDevelopmentGoal } from './investmentProposal'

type ProjectStateType = 'Funding' | 'Building' | 'Producing' | 'Sold'

const projectStateTranslations = {
  Funding: 'impact.projectState.funding',
  Building: 'impact.projectState.building',
  Producing: 'impact.projectState.producing',
  Sold: 'impact.projectState.sold',
}

export function translateProjectState(projectState: ProjectStateType): string {
  return projectStateTranslations[projectState]
}

export interface ImpactOverPeriod {
  oneMonth: number
  threeMonth: number
  oneYear: number
  total: number
  estimation: number
}

export const EmptyImpactOverPeriod: ImpactOverPeriod = {
  oneMonth: 0,
  threeMonth: 0,
  oneYear: 0,
  total: 0,
  estimation: 0,
}

export interface ImpactProduct {
  ticker?: string
  name: string
  nameFi: string
  nameSv: string
  forSale?: boolean
  projectState?: ProjectStateType
  energy: ImpactOverPeriod
  emission: ImpactOverPeriod
  description?: string
  descriptionSv?: string
  imageUrl?: string
  isPrivateEquity?: boolean
  commitments?: number
}

export interface ImpactResponse {
  impactValue: number
  totalEmission: ImpactOverPeriod
  totalEnergy: ImpactOverPeriod
  recommendedImpactProducts: ImpactProduct[]
  pastImpactProducts: ImpactProduct[]
  ownImpactProducts: ImpactProduct[]
}

export interface SdgResponse {
  allocations: Allocation[]
  sustainableDevelopmentGoals: SustainableDevelopmentGoal[]
}

export const EmptyImpactResponse: ImpactResponse = {
  impactValue: 0,
  totalEmission: EmptyImpactOverPeriod,
  totalEnergy: EmptyImpactOverPeriod,
  recommendedImpactProducts: [],
  pastImpactProducts: [],
  ownImpactProducts: [],
}
