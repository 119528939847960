import { ReportOrder } from './../models/ReportOrders'
import { observable, makeAutoObservable } from 'mobx'

export type ReportPackage = 'Wealth' | 'Bookkeeper' | 'Custom'

class ReportOrderStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable
  orders: ReportOrder[] = []
  @observable
  reportOrder: ReportOrder | undefined
  @observable
  reportPackage: ReportPackage | undefined
}

export default new ReportOrderStore()
