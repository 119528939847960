import { routePathApp } from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_REPORT_ORDERS } from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_REPORT } from '@taaleri/components/src/screens/report/ReportRoutes'
import ReportOrderPackageContent from '@taaleri/components/src/screens/reportorders/ReportOrderPackageContent'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React from 'react'
import { ContentContainerBox } from 'ui/container/ContentContainer'

function ReportOrderPackageScreen() {
  const { navigate } = useNavigation()

  if (AppStore.IsReportOrdersForbidden() === true) {
    navigate(routePathApp(ROUTE_REPORT))
  }

  return (
    <ContentContainerBox leadingTitle={true}>
      <ReportOrderPackageContent
        navigateDelivery={() =>
          navigate(routePathApp(ROUTE_REPORT_ORDERS + '/new/delivery'))
        }
        navigateNewReports={() => {
          navigate(routePathApp(ROUTE_REPORT_ORDERS + '/new/reports'))
        }}
      />
    </ContentContainerBox>
  )
}

export default observer(ReportOrderPackageScreen)
