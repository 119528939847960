import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '../../../ui/FlexRow'

interface Props {
  children: Element[]
  hideBorder?: boolean
}

function TransactionRowContainer({ children, hideBorder }: Props) {
  return (
    <FlexRow
      style={{
        flex: 13,
        padding: Spacings.S20,
        borderBottomWidth: hideBorder ? 0 : 1,
        borderBottomColor: Colors.border,
      }}
    >
      {children}
    </FlexRow>
  )
}

export default TransactionRowContainer
