import { Identity } from '../models/Identity'
import { getConfig } from '../config'
import { post, get } from './rest'
import IDiscountCodeApi from './IDiscountCodeApi'
import { DiscountCodeValidationResponse } from '../models/DiscountCodeValidationResponse'
import { Discount } from '../models/Discount'
import { DiscountCodeResponse } from '../models/DiscountCodeResponse'

function getBaseUrl() {
  return `${getConfig().apiUrl}/Discount/`
}

async function useDiscountCode(
  identity: Identity,
  customerId: number,
  discountCode: string
): Promise<DiscountCodeValidationResponse> {
  const { accessToken } = identity
  return post(
    `${getBaseUrl()}${customerId}/use?code=${discountCode}`,
    accessToken
  )
}

async function validateDiscountCode(
  identity: Identity,
  discountCode: string,
  customerId?: number
): Promise<DiscountCodeValidationResponse> {
  const { accessToken } = identity
  let parameters = `?code=${discountCode}`
  parameters += customerId ? `&customerId=${customerId}` : ''
  return post(`${getBaseUrl()}validate${parameters}`, accessToken)
}

async function getDiscounts(
  identity: Identity,
  customerId?: number
): Promise<Discount[]> {
  const { accessToken } = identity
  return get(`${getBaseUrl()}${customerId}/ineffect`, accessToken)
}

async function getDiscountCode(
  identity: Identity,
  customerId?: number
): Promise<DiscountCodeResponse> {
  const { accessToken } = identity
  return get(`${getBaseUrl()}${customerId}/code`, accessToken)
}

export const discountCodeApi: IDiscountCodeApi = {
  useDiscountCode,
  validateDiscountCode,
  getDiscounts,
  getDiscountCode,
}
