export type FaqCategory =
  | 'Sijoittamisen aloittaminen'
  | 'Salkkuun liittyviä kysymyksiä'
  | 'Asiakkuus'
  | 'Sopimukset ja raportointi'
  | 'Impakti-sijoittaminen'
  | 'Sijoittaminen rahastoihin'
  | 'Sijoittaminen'

export type FaqCategoryIcon = {
  [key in FaqCategory]: any
}

export default interface Faq {
  group?: 'Digi TV' | 'Digi eligible/Digi kons' | 'Onboarding'
  question: string
  answer: string
  category: FaqCategory
  page?: 'etf'
}

export type FaqCategories = {
  [key in FaqCategory]: Faq[]
}

export function groupFaqByCategory(xs: Faq[]): FaqCategories {
  return xs.reduce((rv: any, x: any) => {
    (rv[x.category] = rv[x.category] || []).push(x)
    return rv
  }, {})
}

export function findFaqs(): Faq[] {
  return require('./faqApp.json')
}

export function findStartFaqs(): Faq[] {
  const allFaqs: Faq[] = require('./faqApp.json')
  return allFaqs.filter((faq) => faq?.group === 'Onboarding')
}
