import React from 'react'
import { TextMedium } from '@taaleri/components/src/ui/text/UiText'
import Title from '@taaleri/components/src/ui/text/Title'
import { IntroImage } from '@taaleri/components/src/screens/questions/IntroImage'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

function BlockedCustomerContent() {
  const { t } = useTranslation()
  const image = require('@taaleri/components/src/screens/questions/contract.png')

  return (
    <>
      <Title>{t('fundsSection.blocked-customer-title')}</Title>
      <IntroImage image={image} />
      <TextMedium style={{ textAlign: 'center', marginTop: Spacings.S24 }}>
        {t('fundsSection.blocked-customer-text')}
      </TextMedium>
    </>
  )
}

export default BlockedCustomerContent
