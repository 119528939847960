function initVersionNumber() {
  const appJson = require(`../../../../../../packages/app/app.json`)
  return appJson.expo.version
}

const versionNumber: string = initVersionNumber()

export function toSemanticVersion(version: string) {
  const [major, minor, patch] = version.split('.')
  return {
    major: Number(major) || 0,
    minor: Number(minor) || 0,
    patch: Number(patch) || 0,
  }
}

export function hasOldVersion(latestVersion: string, currentVersion: string) {
  const latest = toSemanticVersion(latestVersion)
  const current = toSemanticVersion(currentVersion)
  const sameMajor = latest.major === current.major
  const sameMinor = latest.minor === current.minor
  if (latest.major > current.major) {
    return true
  }
  if (sameMajor && latest.minor > current.minor) {
    return true
  }
  if (sameMajor && sameMinor && latest.patch > current.patch) {
    return true
  }
  return false
}

export default versionNumber
