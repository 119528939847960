import {
  InvestmentProposal,
  PortfolioDistributions,
} from '@taaleri/core/src/models/investmentProposal'
export function selectedPortfolioDistribution(
  investmentProposal: InvestmentProposal,
  riskLevel: number
): PortfolioDistributions {
  return (
    investmentProposal.portfolioDistributions.find(
      (d) => d.riskLevel === riskLevel
    ) || investmentProposal.portfolioDistributions[0]
  )
}
