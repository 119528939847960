import AsyncStorage from '@react-native-async-storage/async-storage'
import { getRevenueTimeseries } from '@taaleri/components/src/screens/questions/RevenueCalculator'
import { co2OffsetImpact } from '@taaleri/components/src/screens/questions/impactCalculator'
import {
  QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
  QUESTION_PORTFOLIO_TYPE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
  QUESTION_INVESTMENT_GOAL,
  QUESTION_ESG_START,
  QUESTION_ESG_ENVIRONMENT,
  QUESTION_ESG_SFDR,
  QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
} from '@taaleri/components/src/screens/questions/questions'
import isBefore from 'date-fns/is_before'
import subWeeks from 'date-fns/sub_weeks'
import isEmpty from 'lodash/isEmpty'
import {
  computed,
  get,
  observable,
  set,
  action,
  makeAutoObservable,
  remove,
} from 'mobx'
import { create, persist } from 'mobx-persist'
import { Platform } from 'react-native'

import {
  toForcedKnows,
  toForcedHasInvested,
  BasicInfoProductFamiliarity,
} from '../api/normalization/toAnswers'
import { Country } from '../models/Countries'
import { Customer } from '../models/Customer'
import { IdVerificationData } from '../models/IdentityCard'
import { PortfolioType } from '../models/PortfolioType'
import { Answers, Tag } from '../models/Question'
import {
  RegulatoryQuestions,
  requlatoryQuestionsDefault,
} from '../models/RequlatoryQuestions'
import { InvestmentProposal } from '../models/investmentProposal'
import { formatDateDash } from '../utils/format'

type QuestionMode = 'onboarding' | 'contract'
const AGE = 35
const MONTHLY_INVESTMENT = 100
const INITIAL_INVESTMENT = 5000
class QuestionStoreInternal {
  constructor() {
    makeAutoObservable(this)
  }
  @persist
  @observable
  questionMode: QuestionMode = 'onboarding'

  @persist('object')
  @observable
  answers: Answers = {}

  @persist
  @observable
  age: number = AGE

  @persist
  @observable
  email?: string

  @persist
  @observable
  customerId?: string

  @persist('object')
  @observable
  customerMaybe?: Customer

  @persist
  @observable
  furtherClarificationReason = ''

  @action
  setFurtherClarificationReason(reason: string) {
    this.furtherClarificationReason = reason
  }

  @computed get customer(): Customer {
    if (!this.customerMaybe) {
      throw new Error('Question Customer missing')
    }
    return this.customerMaybe
  }

  @computed get endAge(): number {
    return this.age + this.investmentTime
  }

  @computed get investmentTime(): number {
    return this.age < 55 ? 65 - this.age : 10
  }

  @persist('object')
  @observable
  regulatoryQuestions: RegulatoryQuestions = requlatoryQuestionsDefault()

  @persist
  @observable
  monthlyInvestment: number = MONTHLY_INVESTMENT

  @persist
  @observable
  initialInvestment: number = INITIAL_INVESTMENT

  @persist
  @observable
  monthlyIncome = 0

  @persist
  @observable
  monthlyExpenses = 0

  @persist
  @observable
  monthlyCapitalIncome = 0

  @persist
  @observable
  riskLevel = 0

  @persist
  @observable
  discountCode = ''

  @persist
  @observable
  strategyIdentifier = ''

  @observable
  validityDate: IdVerificationData = {
    day: '',
    month: '',
    year: '',
    identityIssuer: '',
    identityNumber: '',
  }

  @persist
  @observable
  tupasDone = false

  @persist
  @observable
  preventRestart = false

  @persist
  @observable
  fillDate: string = formatDateDash(new Date())

  @persist('object')
  @observable
  monthlyForeignMoneyTransfersCountry: Country[] = []

  @persist('object')
  @observable
  purposeOfForeignMoneyTransfers: string[] = []

  @persist
  @observable
  purposeOfForeignMoneyTransfersOther = ''

  @persist('object')
  @observable
  intendedUseOfCash: string[] = []

  @persist
  @observable
  intendedUseOfCashOther = ''

  @observable
  investmentProposal?: InvestmentProposal

  @observable
  nextRouteIdentityUpdate = ''

  @observable
  basicInfoLoaded = false

  @observable
  proposalScreen: string | undefined = undefined

  knowsEquities = (): boolean => {
    const tags = this.getAnswer(QUESTION_PRODUCT_KNOWLEDGE_PART_ONE) as Tag[]
    const tag = tags.find(
      (answer: Tag) => answer.value === 'knowledgeEquities'
    ) as Tag
    return tag.selected
  }

  knowsFixedIncome = (): boolean => {
    const tags = this.getAnswer(QUESTION_PRODUCT_KNOWLEDGE_PART_ONE) as Tag[]
    const tag = tags.find(
      (answer: Tag) => answer.value === 'knowledgeFixedIncome'
    ) as Tag
    return tag.selected
  }

  @computed
  get investmentGoal() {
    return this.getAnswer(QUESTION_INVESTMENT_GOAL)
  }

  knowsCombinationFunds = (): boolean => {
    const tags = this.getAnswer(QUESTION_PRODUCT_KNOWLEDGE_PART_ONE) as Tag[]
    const tag = tags.find(
      (answer: Tag) => answer.value === 'knowledgeCombinationFunds'
    ) as Tag
    return tag.selected
  }

  missingFundKnowledge = (): boolean =>
    !(
      this.knowsEquities() &&
      this.knowsFixedIncome() &&
      this.knowsCombinationFunds()
    )

  setFundKnowledge = () => {
    const answers = this.getAnswer(QUESTION_PRODUCT_KNOWLEDGE_PART_ONE) as Tag[]
    const productsToBeSet = [
      'knowledgeEquities',
      'knowledgeFixedIncome',
      'knowledgeCombinationFunds',
    ]
    this.setAnswer(
      QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
      answers.map((answer: Tag) => {
        if (productsToBeSet.includes(answer.value)) {
          answer.selected = true
        }
        return answer
      })
    )
  }

  updateForcedKnowledge(forcedKnowledgeResponse: BasicInfoProductFamiliarity) {
    const forcedKnows = toForcedKnows(forcedKnowledgeResponse)
    const forcedInvestments = toForcedHasInvested(forcedKnowledgeResponse)

    const firstPartAnswers = QuestionStore.getAnswer(
      QUESTION_PRODUCT_KNOWLEDGE_PART_ONE
    ) as Tag[]
    const secondPartAnswers = QuestionStore.getAnswer(
      QUESTION_PRODUCT_KNOWLEDGE_PART_ONE
    ) as Tag[]

    QuestionStore.setAnswer(
      QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
      firstPartAnswers.map((tag) => {
        const forced = forcedKnows.includes(tag.value)
        return { ...tag, selected: forced || tag.selected, forced }
      })
    )
    QuestionStore.setAnswer(
      QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
      secondPartAnswers.map((tag) => {
        const forced = forcedInvestments.includes(tag.value)
        return { ...tag, selected: forced || tag.selected, forced }
      })
    )
  }

  getCo2Compensation = (expectedReturn: number, impactShare: number) => {
    const timeSeries = getRevenueTimeseries(
      expectedReturn,
      this.initialInvestment,
      this.monthlyInvestment,
      this.age,
      this.investmentTime
    )

    return co2OffsetImpact(timeSeries, impactShare)
  }

  getCo2Compensation20Years = (expectedReturn: number, impactShare: number) => {
    const timeSeries = getRevenueTimeseries(
      expectedReturn,
      this.initialInvestment,
      this.monthlyInvestment,
      this.age,
      20
    )

    return co2OffsetImpact(timeSeries, impactShare)
  }

  setAge = (age: number) => {
    this.age = age
  }

  @action
  setDiscountCode(discountCode: string) {
    this.discountCode = discountCode
  }

  setMonthlyInvestment = (monthlyInvestment: number) => {
    this.monthlyInvestment = monthlyInvestment
  }

  setInitialInvestment = (initialInvestment: number) => {
    this.initialInvestment = initialInvestment
  }

  getAnswer = (id: string): string | number | Tag[] => {
    return get(this.answers, id)
  }

  setAnswer = (id: string, answer: string | number | Tag[]) => {
    set(this.answers, id, answer)
    this.fillDate = formatDateDash(new Date())
  }

  deleteAnswer = (id: string) => {
    remove(this.answers, id)
  }

  resetEsgQuestions = () => {
    this.deleteAnswer(QUESTION_ESG_START)
    this.deleteAnswer(QUESTION_ESG_ENVIRONMENT)
    this.deleteAnswer(QUESTION_ESG_SFDR)
    this.deleteAnswer(QUESTION_ESG_HARMFUL_EMISSION_EFFECTS)
  }

  reset = (): void => {
    this.age = AGE
    this.answers = {}
    this.monthlyIncome = 0
    this.monthlyExpenses = 0
    this.monthlyCapitalIncome = 0
    this.monthlyInvestment = MONTHLY_INVESTMENT
    this.initialInvestment = INITIAL_INVESTMENT
    this.customerMaybe = undefined
    this.tupasDone = false
    this.preventRestart = false
    this.email = undefined
    this.basicInfoLoaded = false
    this.proposalScreen = undefined
    this.intendedUseOfCashOther = ''
    this.intendedUseOfCash = []
    this.purposeOfForeignMoneyTransfersOther = ''
    this.purposeOfForeignMoneyTransfers = []
    this.monthlyForeignMoneyTransfersCountry = []
    this.regulatoryQuestions = requlatoryQuestionsDefault()
    this.riskLevel = 0
    this.strategyIdentifier = ''
  }

  @computed get allowRestart(): boolean {
    return !isEmpty(this.answers) || this.age !== AGE
  }

  @computed get impactShare(): number {
    if (this.investmentProposal) {
      const selectedDistribution =
        this.investmentProposal.portfolioDistributions.find(
          (d) => d.riskLevel === this.riskLevel
        )
      return selectedDistribution ? 100 * selectedDistribution.impactShare : 0
    }
    return 0
  }

  @computed get isOldFillDate(): boolean {
    return isBefore(this.fillDate, subWeeks(Date.now(), 1))
  }

  @computed get isImpact(): boolean {
    return this.getAnswer(QUESTION_PORTFOLIO_TYPE) === 'impact'
  }

  @computed get portfolioType(): PortfolioType {
    return this.isImpact ? 'impact' : 'etf'
  }
}

// prettier-ignore
// @ts-ignore
const hydrate = create({ storage: Platform.OS === 'web' ? localStorage : AsyncStorage, jsonify: true })
const QuestionStore = new QuestionStoreInternal()
export default QuestionStore

const result = hydrate('questionStore', QuestionStore)
export async function rehydrateQuestionStore() {
  await result.rehydrate()
}
