import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import { BorderContainer, BorderContainerProps } from './BorderContainer'

export function InfoContainer({ style, children }: BorderContainerProps) {
  return (
    <BorderContainer style={[{ borderColor: Colors.border }, style]}>
      {children}
    </BorderContainer>
  )
}
