import React from 'react'
import { View } from 'react-native'
import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import styled from 'styled-components'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import { ZIndex } from 'ui/ZIndex'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'

const HeaderContainer = styled.div`
  display: none;
  @media (min-width: ${BreakPoint.NAV}px) {
    display: block;
    background-color: ${Colors.white};
  }
`

const HeaderInnerContainer = styled.div`
  position: relative;
  z-index: ${ZIndex.NAV};
`

const Container = styled.div`
  display: flex;
  margin: ${Spacings.S24}px 0;
  justify-content: space-between;
  align-items: center;
`

const Divider = styled.div`
  border-bottom: 1px solid ${Colors.border};
`

export default function HeaderLargeContainer(props: PropsChildren) {
  return (
    <HeaderContainer data-testid="header">
      <HeaderInnerContainer>
        <View
          style={{
            maxWidth: 1248,
            flex: 1,
            marginHorizontal: 'auto',
            paddingHorizontal: Spacings.S24,
          }}
        >
          <Container>{props.children}</Container>
        </View>
      </HeaderInnerContainer>
      <Divider />
    </HeaderContainer>
  )
}
