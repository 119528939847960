import React from 'react'

import ChangeUsernameVerification from '@taaleri/components/src/screens/profile/ChangeUsernameVerification'
import { ContentContainerBox } from 'ui/container/ContentContainer'

export default function ChangeUsernameVerificationScreen() {
  return (
    <ContentContainerBox leadingTitle={true}>
      <ChangeUsernameVerification />
    </ContentContainerBox>
  )
}
