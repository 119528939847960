import { OptionType } from '@taaleri/core/src/models/OptionType'
import range from 'lodash/range'

export const REPORT_DELIVERY_OPTIONS: OptionType[] = [
  { label: 'reportOrder.weekly', value: 'Weekly' },
  { label: 'reportOrder.monthly', value: 'Monthly' },
  { label: 'reportOrder.everyThreeMonths', value: 'EveryThreeMonths' },
  { label: 'reportOrder.everySixMonths', value: 'EverySixMonths' },
  { label: 'reportOrder.yearlySpecificDay', value: 'YearlySpecificDay' },
]

export const DELIVERY_OPTIONS_WITH_DELAY = [
  'EveryThreeMonths',
  'EverySixMonths',
]

export const REPORT_FILE_TYPES: OptionType[] = [
  { label: 'PDF', value: 'Pdf' },
  { label: 'Excel', value: 'Csv' },
]

export const REPORT_DELIVERY_WEEKDAYS: OptionType[] = [
  { label: 'datetime.monday', value: 1 },
  { label: 'datetime.tuesday', value: 2 },
  { label: 'datetime.wednesday', value: 3 },
  { label: 'datetime.thursday', value: 4 },
  { label: 'datetime.friday', value: 5 },
  { label: 'datetime.saturday', value: 6 },
  { label: 'datetime.sunday', value: 7 },
]

export const REPORT_DELIVERY_MONTHS: OptionType[] = [
  { label: 'datetime.january', value: 1 },
  { label: 'datetime.february', value: 2 },
  { label: 'datetime.march', value: 3 },
  { label: 'datetime.april', value: 4 },
  { label: 'datetime.may', value: 5 },
  { label: 'datetime.june', value: 6 },
  { label: 'datetime.july', value: 7 },
  { label: 'datetime.august', value: 8 },
  { label: 'datetime.september', value: 9 },
  { label: 'datetime.october', value: 10 },
  { label: 'datetime.november', value: 11 },
  { label: 'datetime.december', value: 12 },
]

export const REPORT_DELIVERY_LAST_DAY_OF_MONTH = 0
export const REPORT_DELIVERY_FIRST_DAY_OF_MONTH = 1

export const REPORT_DELIVERY_MONTH_DAYS: OptionType[] = [
  {
    label: 'reportOrder.lastDayOfMonth',
    value: REPORT_DELIVERY_LAST_DAY_OF_MONTH,
  },
].concat(
  range(1, 29).map((i) => {
    const value = i
    if (i === 1 || i === 2 || i === 21 || i === 22) {
      return {
        label: 'reportOrder.firstAndsecondDay',
        value,
      }
    } else {
      return {
        label: 'reportOrder.nthDay',
        value,
      }
    }
  })
)

export const REPORT_TIME_PERIODS: OptionType[] = [
  { label: 'reportOrder.fromPreviousToNext', value: 'FromPreviousToNext' },
  { label: 'reportOrder.previousMonth', value: 'PreviousMonth' },
  { label: 'reportOrder.previousThreeMonths', value: 'PreviousThreeMonths' },
  {
    label: 'reportOrder.previousCalendarQuarter',
    value: 'PreviousCalendarQuarter',
  },
  { label: 'reportOrder.previousYear', value: 'PreviousYear' },
  { label: 'reportOrder.beginningOfTheYear', value: 'BeginningOfTheYear' },
  {
    label: 'reportOrder.beginningOfTheYearToLastMonthEnd',
    value: 'BeginningOfTheYearToLastMonthEnd',
  },
  { label: 'reportOrder.beginningOfOwnership', value: 'BeginningOfOwnership' },
]

const OMIT_BEGINNING_OF_OWNERSHIP: ReportType[] = [
  'AccumulatedProfits',
  'ProfitsOrLosses',
  'Transactions',
]

export function reportTimePeriods(reportType: ReportType) {
  if (reportType === 'Purchaselot') {
    return REPORT_TIME_PERIODS_PURCHASE_SLOTS
  }
  if (OMIT_BEGINNING_OF_OWNERSHIP.includes(reportType)) {
    return REPORT_TIME_PERIODS.filter((r) => r.value !== 'BeginningOfOwnership')
  }
  return REPORT_TIME_PERIODS
}

export const REPORT_TIME_PERIODS_PURCHASE_SLOTS: OptionType[] = [
  { label: 'reportOrder.latest', value: 'Latest' },
  { label: 'reportOrder.lastDayOfMonth', value: 'LastDayOfMonth' },
].concat(
  range(1, 29).map((i) => {
    const value = String(i)
    if (i === 1 || i === 2 || i === 21 || i === 22) {
      return {
        label: 'reportOrder.firstAndsecondDay',
        value,
      }
    } else {
      return {
        label: 'reportOrder.nthDay',
        value,
      }
    }
  })
)

interface Report {
  reportType: ReportType
  name: string
  description: string
  selected?: boolean
  hideFormat?: boolean
  hideLevel?: boolean
  hideTime?: boolean
}

export const REPORTS: Report[] = [
  {
    reportType: 'ProfitSummary',
    name: 'reportOrder.profit-summary.title',
    description: 'reportOrder.profit-summary.description',
    hideFormat: true,
    hideLevel: true,
  },

  {
    reportType: 'Purchaselot',
    name: 'reportOrder.purchaselot.title',
    description: 'reportOrder.purchaselot.description',
  },

  {
    reportType: 'Transactions',
    name: 'reportOrder.transactions.title',
    description: 'reportOrder.transactions.description',
  },
  {
    reportType: 'OpenTransactions',
    name: 'reportOrder.open-transactions.title',
    description: 'reportOrder.open-transactions.description',
    hideFormat: true,
    hideTime: true,
  },

  {
    reportType: 'CashAccountStatements',
    name: 'reportOrder.cashAccountStatements.title',
    description: 'reportOrder.cashAccountStatements.description',
    hideFormat: true,
    hideLevel: true,
  },

  {
    reportType: 'ProfitsOrLosses',
    name: 'reportOrder.profitsOrLosses.title',
    description: 'reportOrder.profitsOrLosses.description',
  },
  {
    reportType: 'AccumulatedProfits',
    name: 'reportOrder.accumulatedProfits.title',
    description: 'reportOrder.accumulatedProfits.description',
  },
]

export interface Receiver {
  email: string
  token: string
}

export type ReportType =
  | 'ProfitSummary'
  | 'Purchaselot'
  | 'Transactions'
  | 'OpenTransactions'
  | 'CashAccountStatements'
  | 'ProfitsOrLosses'
  | 'AccumulatedProfits'

export type SubscriptionTimePeriod =
  | 'PreviousMonth'
  | 'PreviousThreeMonths'
  | 'PreviousYear'
  | 'PreviousCalendarQuarter'
  | 'BeginningOfTheYear'
  | 'BeginningOfTheYearToLastMonthEnd'
  | 'BeginningOfOwnership'
  | 'Latest'
  | 'LastDayOfMonth'
  | 'FromPreviousToNext'

export interface Subscription {
  reportType: ReportType
  timePeriod?: string | null
  timePeriodDay?: string | null
  beginningOfOwnership: string | null
  level: string | null
  sumLevel: string | null
  format: 'Pdf' | 'Csv'
}

export interface Schedule {
  previous: string | null
  next: string | null
}

export type ReportDelivery =
  | 'Weekly'
  | 'Monthly'
  | 'EveryThreeMonths'
  | 'EverySixMonths'
  | 'YearlySpecificDay'
  | 'LastDayOfMonth'
export interface ReportOrder {
  id?: number
  customerId: string
  delivery: ReportDelivery
  deliveryDay: number | null
  deliveryMonth: number | null
  message: string
  targetCustomerId: string
  receivers: Receiver[]
  subscriptions: Subscription[]
  schedule?: Schedule
  selectedReports: ReportType[]
  addDelay?: boolean
  format?: 'Pdf' | 'Csv'
  level?: 'Aggregated' | 'ByPortfolio'
}

const DEFAULT_FORMAT = 'Pdf'

export function reportSubscriptionsDefault(): Subscription[] {
  return [
    {
      reportType: 'ProfitSummary',
      timePeriod: 'BeginningOfTheYear',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: null,
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },

    {
      reportType: 'Purchaselot',
      timePeriod: 'Latest',
      beginningOfOwnership: null,
      level: 'Aggregated',
      sumLevel: 'Security',
      format: DEFAULT_FORMAT,
    },
    {
      reportType: 'Transactions',
      timePeriod: 'BeginningOfTheYear',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: 'Aggregated',
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },
    {
      reportType: 'OpenTransactions',
      timePeriod: 'PreviousTwoMonths',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: 'Aggregated',
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },
    {
      reportType: 'CashAccountStatements',
      timePeriod: 'BeginningOfTheYear',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: null,
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },
    {
      reportType: 'ProfitsOrLosses',
      timePeriod: 'PreviousMonth',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: 'Aggregated',
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },
    {
      reportType: 'AccumulatedProfits',
      timePeriod: 'PreviousMonth',
      timePeriodDay: null,
      beginningOfOwnership: null,
      level: 'Aggregated',
      sumLevel: null,
      format: DEFAULT_FORMAT,
    },
  ]
}
