import { isMoney } from '@taaleri/core/src/constants/AssetClass'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import { AccountManager } from '@taaleri/core/src/models/Customer'
import { AssetClassPercentage } from './assetClassColors'
import { TimeSerieItem } from './YieldHistory'
import { CurrencyValue, Position } from './Portfolio'

interface Link {
  title: string
  url: string
}

interface FundFeeInformation {
  subscriptionFee: number
  redemptionFee: number
  managementFee: number
  performanceFee: number
  performanceFeeDescription: string
  performanceFeeDescriptionEn: string
}

export interface Fund {
  ticker: string
  imageUrl: string
  name: string
  nameSv: string
  shortDescription: string
  shortDescriptionSv: string
  description: string
  descriptionSv: string
  riskLevel: string
  fundPerformanceHistorySummary: FundPerformanceHistorySummary
  marketValueTimeSeries: TimeSerieItem[]
  assetClassDistribution: AssetClassPercentage[]
  managementFee: string
  performanceFee: string
  fundManagers: AccountManager[]
  documentLinks: Link[]
  investmentStyle: string
  assetClassId: string
  fundRedemption: string
  fundSubscription: string
  isAvailableForRedemption: boolean
  isAvailableForSubscription: boolean
  isAktiaFund: boolean
  fundFeeInformation: FundFeeInformation
  positionsData: PositionsData
  holdingsDate: string
}

export interface PositionsData {
  marketValue: CurrencyValue
  positions: Position[]
}

export interface FundPerformanceHistorySummary {
  performanceYtd?: number
  performance1Y?: number
  performance3Y?: number
  performance5Y?: number
  performance10Y?: number
}

export interface FundOrder {
  securityId: string
  amount?: number
  customerId: number
  isRecurring: boolean
  recurringTransactionDay?: number
  orderType: 'BUY' | 'SELL'
  unitAmount?: number
  sellEverything?: boolean
}

export interface InvestmentStyle {
  name: string
  description: string
}

export const InvestmentStyles: InvestmentStyle[] = [
  {
    name: 'Arvosijoittaminen',
    description:
      'investmentStyle.value-desc',
  },
  {
    name: 'Osinkosijoittaminen',
    description: 'investmentStyle.dividend-desc',
  },
  {
    name: 'Kasvu- ja pienyhtiösijoittaminen',
    description:
      'investmentStyle.growth-desc',
  },
  {
    name: 'Hajautetut osakesijoitukset',
    description:
      'investmentStyle.diversified-equity-desc',
  },
  {
    name: 'Lyhyet korkosijoitukset',
    description:
      'investmentStyle.interest-desc',
  },
  {
    name: 'Yrityslainasijoitukset',
    description:
      'investmentStyle.company-desc',
  },
  {
    name: 'Yhdistelmäratkaisut',
    description:
      'investmentStyle.combined-desc',
  },
]

export function fundRedemptionDateFormatted(funds: Fund[], ticker: string) {
  const fund = (funds || []).find((f) => f.ticker === ticker)
  return fund ? fundRedemptionDateFormattedByFund(fund) : 'Seuraava pankkipäivä'
}

export function fundRedemptionDateFormattedByFund(fund: Fund) {
  return isMoney(fund.ticker)
    ? 'Seuraava pankkipäivä'
    : formatDateFull(fund.fundRedemption)
}
