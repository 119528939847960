import React from 'react'
import RecurringOrderCancelVerification from '@taaleri/components/src/screens/recurringorders/RecurringOrderCancelVerification'
import Buttons from 'ui/Buttons'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_RECURRING_ORDER_CANCEL_VERIFICATION } from '@taaleri/components/src/screens/recurringorders/RecurringOrderRoutes'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { useTranslation } from 'react-i18next'

export default function RecurringOrderCancelVerificationScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <ContentContainerWide>
      <RecurringOrderCancelVerification />
      <Buttons
        title={t('button.continue')}
        onPress={() => navigateApp(ROUTE_RECURRING_ORDER_CANCEL_VERIFICATION)}
      />
    </ContentContainerWide>
  )
}
