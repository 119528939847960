import React from 'react'
import { View } from 'react-native'
import ImpactAssetDetail from './ImpactAssetDetail'
import {
  AssetClassSecurities,
  PortfolioDistributions,
} from '@taaleri/core/src/models/investmentProposal'

function ImpactAssetDetails({
  impactStrategy,
}: {
  impactStrategy: PortfolioDistributions
}) {
  const assetClassSecurities: AssetClassSecurities[] = impactStrategy.securities

  return (
    <View>
      {assetClassSecurities.map((assetClass) => {
        return (
          <ImpactAssetDetail
            key={assetClass.id}
            assetClasses={assetClassSecurities}
            assetClass={assetClass}
          />
        )
      })}
    </View>
  )
}

export default ImpactAssetDetails
