import React from 'react'
import { IconFeedback } from '../../ui/Icons'
import YesNoModal from '../../ui/modal/YesNoModal'
import { useTranslation } from 'react-i18next'

interface Props {
  visible: boolean
  onYes: () => void
  onNo: () => void
}
export default function ConfirmPassIdentityModal(props: Props) {
  const { t } = useTranslation()
  return (
    <YesNoModal
      isVisible={props.visible}
      title={`${t('idUpdate.are-you-sure-title')}`}
      content={`${t('idUpdate.are-you-sure-content')}`}
      icon={<IconFeedback />}
      onYes={props.onYes}
      onNo={props.onNo}
      yesText={`${t('idUpdate.are-you-sure-yes')}`}
      noText={`${t('idUpdate.are-you-sure-no')}`}
      buttonDirection="column"
    />
  )
}
