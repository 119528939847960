import React, { useState } from 'react'
// @ts-ignore
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Colors from '@taaleri/core/src/constants/Colors'
import { UiSliderProps } from './UiSliderProps'

const UISlider: React.FC<UiSliderProps> = ({
  initialValue,
  onChange,
  min,
  max,
  step,
}) => {
  const [value, setValue] = useState<number>(initialValue)
  return (
    <Slider
      value={value}
      min={min}
      max={max}
      step={step || 1}
      trackStyle={{ backgroundColor: Colors.primary, height: 4 }}
      railStyle={{ backgroundColor: Colors.chartLineGrey, height: 4 }}
      handleStyle={{
        borderColor: Colors.primary,
        height: 24,
        width: 24,
        marginLeft: 0,
        marginTop: -10,
        backgroundColor: Colors.primary,
      }}
      onChange={(newValue: number) => {
        setValue(newValue)
        onChange(String(newValue))
      }}
    />
  )
}

export default UISlider
