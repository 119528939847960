import { ErrorMessages } from './../../../../core/src/constants/ErrorMessages'
import * as Yup from 'yup'
import { phoneNumberRegex } from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'

export const customerFormValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    nationality: Yup.string().required(`${t(ErrorMessages.required)}`),
    countryOfBirth: Yup.string().required(`${t(ErrorMessages.required)}`),
    phone: Yup.string()
      .matches(phoneNumberRegex, `${t(ErrorMessages.checkPhoneNumber)}`)
      .required(`${t(ErrorMessages.required)}`),
    profession: Yup.string().required(`${t(ErrorMessages.required)}`),
    levelOfEducation: Yup.string().required(
      `${t(ErrorMessages.chooseEducation)}`
    ),
    socioeconomicStatus: Yup.string().required(
      `${t(ErrorMessages.chooseWorkStatus)}`
    ),
    address: Yup.object().shape({
      street: Yup.string().required(`${t(ErrorMessages.required)}`),
      postalCode: Yup.string().required(`${t(ErrorMessages.required)}`),
      city: Yup.string().required(`${t(ErrorMessages.required)}`),
      country: Yup.string().required(`${t(ErrorMessages.required)}`),
    }),
    isPostAddress: Yup.boolean().nullable(),
    postAddress: Yup.object().when('isPostAddress', {
      is: true,
      then: Yup.object({
        street: Yup.string().required(`${t(ErrorMessages.required)}`),
        postalCode: Yup.string().required(`${t(ErrorMessages.required)}`),
        city: Yup.string().required(`${t(ErrorMessages.required)}`),
        country: Yup.string().required(`${t(ErrorMessages.required)}`),
      }),
    }),
    email: Yup.string()
      .nullable(true)
      .email(`${t(ErrorMessages.incorrectEmail)}`)
      .required(`${t(ErrorMessages.missingEmail)}`),
  })
