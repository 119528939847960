import React from 'react'

import AppStore from '@taaleri/core/src/stores/AppStore'
import { addressOrUnknown } from '@taaleri/core/src/utils/contactHelper'
import { ListItemLinkData } from '../../ui/ListItem'
import { observer } from 'mobx-react'
import { routePathApp } from '../../navigation/routeHelper'
import {
  ROUTE_PROFILE_CHANGE_USERNAME,
  ROUTE_PROFILE_CHANGE_PHONE,
} from './ProfileRoutes'
import CardWithLinks from '../../ui/CardWithLinks'
import { insertIf } from '@taaleri/core/src/utils/arrayUtils'
import { useTranslation } from 'react-i18next'

function ContactSection() {
  const { t } = useTranslation()
  if (!AppStore.isBaseCustomerActive) {
    return null
  }

  const customer = AppStore.customer
  const { email, phone, address } = customer

  const linkItems: ListItemLinkData[] = [
    ...insertIf(Boolean(email && email.length > 0), {
      title: email,
      link: routePathApp(ROUTE_PROFILE_CHANGE_USERNAME),
    }),
    ...insertIf(Boolean(phone && phone.length > 0), {
      title: phone,
      link: routePathApp(ROUTE_PROFILE_CHANGE_PHONE),
    }),
    {
      title: addressOrUnknown(address, t),
    },
  ]

  return (
    <CardWithLinks
      title={`${t('profile.contactSection.title')}`}
      items={linkItems}
    />
  )
}

export default observer(ContactSection)
