import { TimeseriesType } from './TimeseriesType'

export interface RevenueParameters {
  percentage: number
  initialInvestment: number
  monthlyInvestment: number
  age: number
  investmentTime: number
}

function range(size: number, startAt: number) {
  return [...Array(size).keys()].map((i) => i + startAt)
}

export function timetoRetirement(age: number): number {
  if (age < 55) {
    return 65 - age
  }
  return 10
}

function calculateAnnualBalance(
  startBalance: number,
  monthlyContribution: number,
  monthlyRate: number
): number {
  for (let i = 0; i < 12; i++) {
    startBalance = startBalance * monthlyRate + monthlyContribution
  }
  return Math.round(startBalance * 100) / 100
}

export function getRevenueTimeseries(
  percentage: number,
  initialInvestment: number,
  monthlyInvestment: number,
  age: number,
  investmentTime: number
): TimeseriesType {
  const ages = range(investmentTime + 1, age)
  const monthlyRate = 1 + percentage / 12
  let currentBalance = initialInvestment
  return ages.map((currentAge) => {
    const revenueItem = {
      y: currentBalance,
      x: currentAge,
    }

    currentBalance = calculateAnnualBalance(
      currentBalance,
      monthlyInvestment,
      monthlyRate
    )

    return revenueItem
  })
}

export function getInvestedCapital(
  initialInvestment: number,
  monthlyInvestment: number,
  investmentTime: number
): number {
  return initialInvestment + monthlyInvestment * 12 * investmentTime
}

export function getInvestedCapitalTimeseries(
  initialInvestment: number,
  monthlyInvestment: number,
  age: number,
  investmentTime: number
): TimeseriesType {
  const ages = range(investmentTime + 1, age)
  let currentBalance = initialInvestment
  return ages.map((currentAge) => {
    const revenueItem = {
      y: currentBalance,
      x: currentAge,
    }
    currentBalance = currentBalance + monthlyInvestment * 12
    return revenueItem
  })
}

export function getFinalBalance(data: TimeseriesType, endAge: number): number {
  const lastPoint = data[data.length - 1]
  const endValue = lastPoint.y
  const valueAtAge = data.find((row) => row.x === endAge)
  const finalRevenue = valueAtAge ? valueAtAge.y : endValue
  return finalRevenue
}
