import React, { useRef, useCallback } from 'react'
import { TouchableOpacity, TouchableOpacityProps } from 'react-native'
import { TouchableProps } from 'react-native-svg'

export const activeOpacity = 0.6

const UiTouchable: React.FC<TouchableOpacityProps> = (props) => {
  const pressInPagePointRef: any = useRef({ x: 0, y: 0 })
  const { onPress, onPressIn, onPressOut } = props

  const onPressInInternal = useCallback<
    NonNullable<TouchableProps['onPressIn']>
  >(
    (e) => {
      pressInPagePointRef.current = {
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
      }

      if (onPressIn) {
        onPressIn(e)
      }
    },
    [onPressIn]
  )

  const onPressOutInternal = useCallback<
    NonNullable<TouchableProps['onPressOut']>
  >(
    (e) => {
      if (onPressOut) {
        onPressOut(e)
      }

      const [x, y] = [e.nativeEvent.pageX, e.nativeEvent.pageY]
      if (
        Math.abs(pressInPagePointRef.current.x - x) > 5 ||
        Math.abs(pressInPagePointRef.current.y - y) > 5
      ) {
        e.preventDefault()
      }
    },
    [onPressOut]
  )

  const onPressInternal = useCallback<NonNullable<TouchableProps['onPress']>>(
    (e) => {
      if (e.isDefaultPrevented()) {
        return
      }
      if (onPress) {
        onPress(e)
      }
    },
    [onPress]
  )

  return (
    <TouchableOpacity
      ref={pressInPagePointRef}
      activeOpacity={activeOpacity}
      {...props}
      onPress={onPressInternal}
      onPressOut={onPressOutInternal}
      onPressIn={onPressInInternal}
      delayPressIn={50}
    />
  )
}

export default UiTouchable
