import React from 'react'

import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'
export function IconPhone() {
  return (
    <Svg width={48} height={48} viewBox="0 0 48 48" fill="none">
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M29.6 48c-2 0-3.96-.56-5.66-1.64-.02 0-.04-.02-.06-.04A82.138 82.138 0 011.66 24.1C.36 22.04-.2 19.64.06 17.26c.26-2.36 1.34-4.6 3.02-6.28l1.26-1.26c1.7-1.7 4.66-1.7 6.36 0l5.3 5.3c.42.42.74.92.98 1.46.24.54.34 1.12.34 1.72 0 .58-.12 1.16-.34 1.72-.24.56-.56 1.06-.98 1.48a1.499 1.499 0 000 2.12L24.48 32c.56.56 1.56.56 2.12 0 .42-.42.92-.74 1.46-.98a4.46 4.46 0 013.44 0c.54.22 1.04.56 1.46.98l5.3 5.3a4.468 4.468 0 011.32 3.18c0 .6-.12 1.18-.34 1.72-.22.56-.56 1.04-.98 1.46L37 44.92a10.576 10.576 0 01-6.28 3.02c-.36.04-.74.06-1.12.06zm-4.02-4.16c1.42.92 3.16 1.3 4.84 1.12 1.68-.18 3.28-.96 4.48-2.16l1.26-1.26c.14-.14.24-.3.32-.48.08-.18.12-.38.12-.58 0-.2-.04-.4-.12-.58-.08-.18-.18-.34-.32-.5l-5.3-5.3c-.14-.14-.3-.24-.48-.32-.36-.16-.78-.16-1.14 0-.2.08-.36.18-.5.32-1.7 1.7-4.68 1.68-6.36 0l-8.48-8.48c-.84-.84-1.32-2-1.32-3.18 0-1.18.48-2.34 1.32-3.18.12-.12.24-.3.3-.48a1.412 1.412 0 000-1.14c-.08-.18-.18-.36-.32-.5l-5.3-5.3c-.56-.56-1.56-.56-2.12 0L5.2 13.1c-1.2 1.2-1.98 2.8-2.16 4.5-.18 1.7.22 3.42 1.12 4.84a79.877 79.877 0 0021.42 21.4zM27 22.5c-.38 0-.76-.14-1.06-.44-.58-.58-.58-1.54 0-2.12L42.88 3H34.5c-.82 0-1.5-.68-1.5-1.5S33.68 0 34.5 0h12c.2 0 .4.04.58.12.18.08.34.18.48.32.14.14.26.3.32.48.08.18.12.38.12.58v12c0 .82-.68 1.5-1.5 1.5s-1.5-.68-1.5-1.5V5.12L28.06 22.06c-.3.3-.68.44-1.06.44z"
          fill="#2BC9AF"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path d="M0 0h48v48H0V0z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export function IconEmail() {
  return (
    <Svg width={48} height={48} viewBox="0 0 48 48" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 15a9 9 0 100 18 9 9 0 000-18zm-6.667-.978a12 12 0 1113.333 19.955 12 12 0 01-13.333-19.955z"
        fill="#2BC9AF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.83 2.265A23.998 23.998 0 0148 24v4.5a7.5 7.5 0 11-15 0V24a1.5 1.5 0 113 0v4.5a4.5 4.5 0 009 0V24a20.998 20.998 0 10-8.4 16.8 1.5 1.5 0 011.8 2.4A23.997 23.997 0 1113.83 2.265z"
        fill="#2BC9AF"
      />
    </Svg>
  )
}
