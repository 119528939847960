import Colors from '@taaleri/core/src/constants/Colors'
import { OptionType } from '@taaleri/core/src/models/OptionType'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TextMedium, TextBold } from '../text/UiText'
import BoxCheckTouchable from './BoxCheckTouchable'
import Box, { BoxBackgroundColor } from './Box'
import { IconCheckmarkCircle } from '../Icons'
import CardHover from '../CardHover'
import { Paragraph } from '../text/Paragraph'
import Spacings from '@taaleri/core/src/constants/Spacings'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { IconCircle } from '../icons/CircleIcons'
import { useTranslation } from 'react-i18next'

interface BoxCheckProps {
  isSelected: boolean
  option: OptionType
  onPress: (option: any) => void
  backgroundColor?: BoxBackgroundColor
}

const BoxCheck: React.FC<BoxCheckProps> = ({
  isSelected,
  onPress,
  option,
  backgroundColor,
}) => {
  const { t } = useTranslation()
  const { value, label, description } = option

  return (
    <View style={{ marginVertical: 6 }}>
      <BoxCheckTouchable onPress={() => onPress(value)}>
        <CardHover>
          <Box
            type="border"
            backgroundColor={backgroundColor || 'background'}
            style={[styles.optionBox, isSelected && styles.activeOptionBox]}
          >
            {isSelected && <IconCheckmarkCircle style={styles.icon} />}
            {!isSelected && <IconCircle style={styles.icon} />}
            {!description && (
              <TextMedium style={styles.option}>{t(label)}</TextMedium>
            )}
            {description && (
              <View style={{ flex: 1 }}>
                <TextBold
                  style={[styles.option, { marginBottom: Spacings.S8 }]}
                >
                  {t(label)}
                </TextBold>
                <Paragraph>{t(description)}</Paragraph>
              </View>
            )}
          </Box>
        </CardHover>
      </BoxCheckTouchable>
    </View>
  )
}

const styles = StyleSheet.create({
  optionBox: {
    paddingVertical: 16,
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: BorderRadius.small,
  },

  activeOptionBox: {
    borderColor: Colors.primary,
    borderWidth: 2,
    paddingVertical: 15,
    paddingHorizontal: 13,
  },
  icon: {
    marginRight: 8,
    width: 24,
    height: 24,
    justifyContent: 'center',
  },
  option: {
    flex: 1,
    lineHeight: 22,
  },
})

export default BoxCheck
