import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { observer } from 'mobx-react'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { FilterBox } from './ReportFiltersDesktop'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import Colors from '@taaleri/core/src/constants/Colors'
import AssetClassIcon, {
  AssetClassIconEmpty,
} from '@taaleri/components/src/screens/report/AssetClassIcon'
import PositionContainer from '@taaleri/components/src/screens/report/PositionContainer'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import styled from 'styled-components'
import Fonts from '@taaleri/core/src/constants/Fonts'
import FilterButton, {
  DateButton,
} from '@taaleri/components/src/screens/report/FilterButton'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconChevronLeft } from '@taaleri/components/src/ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'

const SearchInput = styled.input`
  height: 34px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: ${Colors.gray70};
  padding: 0 40px;
  border: 1px solid ${Colors.gray20};
  border-radius: 6px;
  font-family: ${Fonts.avenirNextMedium};
  background: url('data:image/svg+xml;utf8,<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.75158 9.25177L13.5 13.0007M10.7824 3.94421C11.8265 6.40081 10.6813 9.23864 8.22474 10.2827C5.76813 11.3267 2.93031 10.1816 1.88628 7.72498C0.842248 5.26838 1.98736 2.43055 4.44397 1.38652C6.90057 0.342492 9.73839 1.48761 10.7824 3.94421Z" stroke="%236E6763" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat scroll;
  background-position: 14px 50%;
`

function getAssetClassColor() {
  return ReportStore.assetClass
    ? assetClassColors[ReportStore.assetClass]
    : Colors.text
}

function SelectableButtonInternal({
  selected,
  value,
  onClick,
  color,
}: {
  selected: boolean
  value: string
  onClick: () => void
  color?: string
}) {
  return (
    <FilterButton
      onPress={onClick}
      selected={selected}
      style={{ marginHorizontal: Spacings.S4 }}
    >
      {selected && <AssetClassIcon color={getAssetClassColor()} />}
      {!selected && <AssetClassIconEmpty color={color} />}
      <TextDefault
        type="t2"
        style={[
          {
            color: Colors.text,
          },
          styles.buttonText,
        ]}
      >
        {value}
      </TextDefault>
    </FilterButton>
  )
}

const SelectableButton = observer(SelectableButtonInternal)

function RestrictionFilter() {
  const { isSmall } = useLayout()
  const [search, setSearch] = useState<string>('')
  const allAssetClasses = ReportStore.allAssetClasses()
  const percentages = ReportStore.percentagesSorted()
  const assetClassSelected = ReportStore.assetClass !== undefined
  const assetClasses = assetClassSelected
    ? allAssetClasses.filter((a) => a.id === ReportStore.assetClass)
    : allAssetClasses
  const selectedCategory = ReportStore.selectedCategory
  const selectedPosition = ReportStore.selectedPosition
  const categories = selectedCategory
    ? ReportStore.assetCategories().filter((c) => c.id === selectedCategory)
    : percentages
  const categorySelected = ReportStore.selectedCategory !== undefined
  const positionSelected = selectedPosition !== undefined
  const positions = ReportStore.positions().filter(
    (p) => p.name.toLowerCase().includes(search.toLowerCase()) || search === ''
  )
  const additonalInfoSelected = ReportStore.selectedAdditionalInfo !== undefined
  const additonalInfos = additonalInfoSelected
    ? ReportStore.assetAdditionalInfos().filter(
        (c) => c.id === ReportStore.selectedAdditionalInfo
      )
    : ReportStore.assetAdditionalInfos()

  const hasAdditionalInfo = additonalInfos.length > 0
  const showPositions =
    categorySelected && !(hasAdditionalInfo && !additonalInfoSelected)
  const { t } = useTranslation()

  return (
    <FilterBox>
      <FlexRow
        style={{
          flexWrap: 'wrap',
          marginTop: -10,
          display: 'inline-flex',
        }}
      >
        {assetClassSelected && (
          <FilterButton
            onPress={() => {
              ReportStore.goBack()
            }}
            style={{
              borderColor: Colors.gray70,
              marginHorizontal: Spacings.S4,
            }}
            selected={false}
          >
            <IconChevronLeft />
            <TextDefault type="t2" style={{ marginLeft: 10 }}>
              {t('button.back')}
            </TextDefault>
          </FilterButton>
        )}
        {showPositions &&
          !isSmall &&
          (positions.length > 5 || search.length > 0) && (
            <SearchInput
              value={search}
              onChange={(event) => {
                setSearch(event.target.value)
              }}
              placeholder={`${t('portfolio.searching')}`}
            />
          )}
        {!categorySelected && (
          <>
            {assetClasses.map((assetClass) => (
              <DateButton
                key={assetClass.id}
                selected={false}
                onPress={() => {
                  ReportStore.selectAssetClass(assetClass.id)
                }}
                style={{ marginHorizontal: Spacings.S4 }}
              >
                {assetClassSelected && (
                  <AssetClassIcon color={getAssetClassColor()} />
                )}
                {!assetClassSelected && (
                  <AssetClassIconEmpty
                    color={assetClassColors[assetClass.id]}
                  />
                )}
                <TextDefault
                  type="t2"
                  style={[
                    {
                      color: Colors.text,
                    },
                    styles.buttonText,
                  ]}
                >
                  {t(assetClass.name)} ({assetClass.items.length})
                </TextDefault>
              </DateButton>
            ))}
          </>
        )}
      </FlexRow>
      {assetClassSelected && !categorySelected && (
        <FlexRow
          style={{
            borderBottomWidth: 1,
            borderBottomColor: Colors.border,
            marginVertical: 10,
          }}
        />
      )}

      {assetClassSelected && !(hasAdditionalInfo && additonalInfoSelected) && (
        <FlexRow
          style={{
            flexWrap: 'wrap',
            display: 'inline-flex',
            marginTop: -10,
          }}
        >
          {categories.map((category) => {
            return (
              <SelectableButton
                key={category.id}
                selected={categorySelected && !positionSelected}
                color={
                  categorySelected && !positionSelected
                    ? category.color
                    : undefined
                }
                value={`${category.name} (${ReportStore.positionsCount(
                  category.id
                )})`}
                onClick={() => {
                  setSearch('')
                  ReportStore.selectCategory(category.id)
                }}
              />
            )
          })}
        </FlexRow>
      )}

      {hasAdditionalInfo && categorySelected && (
        <FlexRow
          style={{
            flexWrap: 'wrap',
            display: additonalInfoSelected ? 'inline-flex' : 'flex',
            marginTop: additonalInfoSelected ? -10 : 0,
          }}
        >
          {additonalInfos.map((info) => {
            return (
              <SelectableButton
                key={info.id}
                selected={additonalInfoSelected && !positionSelected}
                value={`${info.name} (${ReportStore.positionsCountInfo(
                  info.id
                )})`}
                onClick={() => {
                  setSearch('')
                  ReportStore.selectAdditionalInfo(info.id)
                }}
              />
            )
          })}
        </FlexRow>
      )}

      {showPositions && (
        <PositionContainer>
          {positions.map((category) => {
            return (
              <SelectableButton
                key={category.id}
                selected={category.id === ReportStore.selectedPosition}
                value={category.name}
                onClick={() => ReportStore.selectPosition(category.id)}
              />
            )
          })}
        </PositionContainer>
      )}
    </FilterBox>
  )
}

export default observer(RestrictionFilter)

const styles = StyleSheet.create({
  buttonText: {
    letterSpacing: 0.2,
  },
})
