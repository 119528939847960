import { Address } from '../models/Customer'
import { TFunction } from 'i18next'

export const valueOrUnknown = (value: string, t:TFunction) =>
  value && value.length > 0 ? value : `${t('utils.not-known')}`
export const addressOrUnknown = (address: Address | null, t:TFunction) =>
  address && address.street && address.postalCode && address.city
    ? `${address.street.replace('\n', '')}\n${address.postalCode} ${
        address.city
      }`
    : `${t('utils.not-known')}`
