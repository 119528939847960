import React from 'react'
import { PropsStyle } from '../../components/PropsChildren'
import { BoxWithPadding } from '../../ui/box/Box'
import { NumberedSection, NumberedSectionProps } from './NumberedSection'

export function NumberedSectionBox(
  props: NumberedSectionProps & PropsStyle & { children?: any }
) {
  return (
    <BoxWithPadding style={props.style}>
      <NumberedSection {...props} />
      {props.children}
    </BoxWithPadding>
  )
}
