import groupBy from 'lodash/groupBy'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'

import { nameForAssetClassId } from '../../constants/AssetClass'
import { FundProposition } from '../../models/FundProposition'
import {
  AssetClass,
  AssetClassItem,
  Portfolio,
  PortfolioResponse,
  Position,
  FundPropositionResponse,
  PortfolioType,
} from '../../models/Portfolio'

function sortItemsBySharePercentage(items: AssetClassItem[]): AssetClassItem[] {
  return reverse(sortBy(items, (item: AssetClassItem) => item.sharePercentage))
}

function sortAssetClassesBySharePercentage(items: AssetClass[]): AssetClass[] {
  return reverse(sortBy(items, (item: AssetClass) => item.percentageOfTotal))
}

function getGroupField(position: Position) {
  return position.financialInstrument.assetClassId
}

function itemsByAssetClass(
  positions: Position[],
  assetClass: string,
  totalValue: number
): AssetClassItem[] {
  return positions
    .filter((position) => position !== null)
    .filter((position) => getGroupField(position) === assetClass)
    .map((position) => {
      const {
        financialInstrument,
        marketValue,
        purchaseValue,
        quantity,
        purchasePrice,
        marketPrice,
        isSecurityDocumentAvailable,
        commitmentAmount,
        uncommitmentAmount,
        marketPriceWithoutInterests,
      } = position
      const value = marketValue?.baseCurrency.value
      const purchaseValueValue = purchaseValue?.baseCurrency.value

      const {
        name,
        friendlyName,
        securityName,
        ticker,
        isImpact,
        ownFund,
        assetCategoryId,
        assetCategoryName,
        assetAdditionalInfoId,
        assetAdditionalInfoName,
        assetClassId,
        couponRate,
        securityTypeId,
        maturityDate,
        securityNameFinnish,
        securityNameSwedish,
        securityNameEnglish,
        isCapitalFund,
      } = financialInstrument

      const accruedInterest = position?.positionFiguresData?.accruedInterest
      const duration = position?.positionFiguresData?.duration
      const yieldToMaturity = position?.positionFiguresData?.yieldToMaturity
      const isStrukt = assetCategoryId === 'B05'
      return {
        name,
        ticker,
        marketValue: value,
        purchaseValue: purchaseValueValue,
        sharePercentage: (value / totalValue) * 100,
        marketValueChangePercent:
          position.marketValueChangePercent &&
          position.marketValueChangePercent?.baseCurrency
            ? position.marketValueChangePercent.baseCurrency.value * 100
            : undefined,
        isImpact,
        ownFund,
        quantity,
        assetCategoryId,
        assetCategoryName,
        assetAdditionalInfoId:
          !assetAdditionalInfoId && isStrukt
            ? 'NOT_KNOWN'
            : assetAdditionalInfoId,
        assetAdditionalInfoName:
          !assetAdditionalInfoName && isStrukt
            ? 'Ei määritelty'
            : assetAdditionalInfoName,
        accruedInterest,
        yieldToMaturity,
        duration,
        purchasePrice: purchasePrice?.baseCurrency?.value,
        marketPrice: marketPrice?.baseCurrency?.value,
        marketPriceWithoutInterests:
          marketPriceWithoutInterests?.baseCurrency?.value,
        isSecurityDocumentAvailable,
        commitmentAmount,
        uncommitmentAmount,
        friendlyName,
        securityName,
        assetClassId,
        couponRate,
        maturityDate,
        securityTypeId,
        nameFi: securityNameFinnish,
        nameSv: securityNameSwedish,
        nameEn: securityNameEnglish,
        isCapitalFund,
      }
    })
}

function valueChangePercent(
  purchaseValue: number,
  currentValue: number
): number | undefined {
  if (!purchaseValue) {
    return undefined
  }
  return ((currentValue - purchaseValue) / purchaseValue) * 100
}

function toAssetClasses(
  positions: Position[],
  totalMarketValue: number
): AssetClass[] {
  const assetClasses = positions
    .filter((p) => p !== null)
    .map((position: Position) => {
      const assetClass = getGroupField(position)
      const value = position.marketValue?.baseCurrency.value
      const purchaseValue =
        position.purchaseValue && position.purchaseValue.baseCurrency
          ? position.purchaseValue.baseCurrency.value
          : 0
      return {
        assetClass,
        value,
        purchaseValue,
      }
    })

  const grouped = groupBy(assetClasses, 'assetClass')
  const keys = Object.keys(grouped)
  const totals = keys.map((key) => {
    const totalValue = sumBy(grouped[key], (curValue) => curValue.value)
    const totalPurchaseValue = sumBy(
      grouped[key],
      (curValue) => curValue.purchaseValue
    )
    const assetClass: AssetClass = {
      id: key,
      name: nameForAssetClassId(key),
      marketValue: totalValue,
      purchaseValue: totalPurchaseValue,
      percentageOfTotal: (totalValue / totalMarketValue) * 100,
      items: sortItemsBySharePercentage(
        itemsByAssetClass(positions, key, totalMarketValue)
      ),
      marketValueChangePercent: valueChangePercent(
        totalPurchaseValue,
        totalValue
      ),
      marketValueChange: totalValue - totalPurchaseValue,
    }
    return assetClass
  })
  return totals
}

export function toPortfolioList(res: any) {
  return Object.keys(res).map((r) => toPortfolio(res[r], r as PortfolioType))
}

export function toPortfolio(
  portfolioSource: PortfolioResponse,
  portfolioType?: PortfolioType
): Portfolio {
  const totalMarketValue = portfolioSource.marketValue.value
  return {
    portfolioType,
    marketValue: portfolioSource.marketValue.value,
    purchaseValue: portfolioSource.purchaseValue.value,
    marketValueChange: portfolioSource.marketValueChange,
    assetClasses: sortAssetClassesBySharePercentage(
      toAssetClasses(portfolioSource.positions, totalMarketValue)
    ),
  }
}

export function toFundProposition(
  fundSource: FundPropositionResponse
): FundProposition {
  return {
    simulatedRisk: fundSource.simulatedRisk,
    simulatedVolatility: fundSource.simulatedVolatility,
    actualRisk: fundSource.actualRisk,
    actualVolatility: fundSource.actualVolatility,
    portfolio: toPortfolio(fundSource.positions),
  }
}
