import React from 'react'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import PaymentInformationContent from '@taaleri/components/src/screens/portfolio/PaymentInformationContent'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_FUNDS_INTRO } from '@taaleri/components/src/screens/funds/FundsRoutes'
import Buttons from 'ui/Buttons'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'
import { useTranslation } from 'react-i18next'

export default function PaymentInformationScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  return (
    <>
      <PaymentStepsFund currentStep={2} />
      <ContentContainerWide>
        <PaymentInformationContent />
        <Buttons
          title={t('button.payment-set')}
          onPress={() => navigateApp(ROUTE_FUNDS_INTRO)}
        />
      </ContentContainerWide>
    </>
  )
}
