import React, { useState } from 'react'
import { TouchableOpacityProps, View } from 'react-native'
import UiTouchable from './UiTouchable'
import PropsChildren from '../components/PropsChildren'

export default function TouchableShrink(
  props: TouchableOpacityProps & PropsChildren
) {
  const [pressed, setPressed] = useState<boolean>(false)
  return (
    <View
      style={[
        {
          transform: [{ scale: pressed ? 0.97 : 1 }],
        },
        props.style,
      ]}
    >
      <UiTouchable
        delayPressIn={50}
        activeOpacity={1}
        {...props}
        style={{}}
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
      />
    </View>
  )
}
