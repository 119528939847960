import Colors, { getChangeColor } from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import FlexRow from '../../ui/FlexRow'
import { TextDefault } from '../../ui/text/UiText'
import {
  formatQuantity,
  formatCurrency,
  formatFundProfit,
} from '@taaleri/core/src/utils/format'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { PurchaseLot } from '@taaleri/core/src/models/Portfolio'
import { isPrivateEquity } from '@taaleri/core/src/constants/AssetClass'

interface OwnershipRowProps {
  title: string
  borderBottom?: boolean
  purchaseLot: PurchaseLot
}

export const PurchaseLotRowMobile: React.FC<OwnershipRowProps> = ({
  purchaseLot,
  title,
  borderBottom,
}) => {
  const {
    quantity,
    purchasePrice,
    marketValue,
    purchaseValue,
    securityTypeId,
  } = purchaseLot
  const marketValueChange = marketValue - purchaseValue
  const marketValueChangePercent = purchaseLot.marketValueChangePercent ?? 0
  const privateEquity = isPrivateEquity(securityTypeId)

  return (
    <View
      style={[
        styles.row,
        borderBottom && {
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
        },
      ]}
    >
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <TextDefault type="h6" style={{ lineHeight: 26 }}>
          {title}
        </TextDefault>
        <TextDefault style={styles.numberOnRight}>
          <TextDefault type="t2" color={getChangeColor(marketValueChange)}>
            {formatCurrency(marketValueChange)}
          </TextDefault>
          <TextDefault type="t2" color={Colors.gray20}>{`  |  `}</TextDefault>
          <TextDefault
            type="t2"
            color={getChangeColor(marketValueChangePercent)}
          >
            {marketValueChangePercent > 0
              ? `+${formatFundProfit(marketValueChangePercent)}`
              : formatFundProfit(marketValueChangePercent)}
          </TextDefault>
        </TextDefault>
      </FlexRow>
      <FlexRow>
        <TextDefault type="t5" style={{ marginTop: Spacings.S4 }}>
          <TextDefault
            type="t5"
            color={Colors.text}
            style={{ letterSpacing: 0.9, color: Colors.text }}
          >
            {privateEquity
              ? formatCurrency(quantity)
              : formatQuantity(quantity)}
          </TextDefault>
          {purchasePrice ? (
            <>
              <TextDefault
                type="t5"
                color={Colors.gray20}
              >{`  |  `}</TextDefault>
              <TextDefault
                type="t5"
                style={{ letterSpacing: 0.9, color: Colors.text }}
              >
                {privateEquity
                  ? formatFundProfit(purchasePrice)
                  : formatCurrency(purchasePrice)}
              </TextDefault>
            </>
          ) : null}
        </TextDefault>
      </FlexRow>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    paddingBottom: 10,
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: Colors.border,
  },
  numberOnRight: {
    flex: 20,
    textAlign: 'right',
  },
})
