import AppStore from '@taaleri/core/src/stores/AppStore'

import {
  PaymentStatus,
  Withdrawal,
  WithdrawalResponse,
  PaymentInfo,
} from './../models/Payment'
import IPaymentsApi from './IPaymentsApi'
import { get, post } from './rest'
import { getConfig } from '../config'
import { Identity } from '../models/Identity'

async function setupWithdrawal(
  identity: Identity,
  customerId: number,
  withdrawal: Withdrawal
): Promise<WithdrawalResponse> {
  const { accessToken } = identity
  const response = await post(
    `${getConfig().apiUrl}/Payments/withdraw/${customerId}`,
    accessToken,
    {
      ...withdrawal,
      customerId,
      isDirectOrder: AppStore.investmentMode === 'withdrawfund',
    }
  )
  return response
}

async function getPaymentStatus(
  identity: Identity,
  customerId: number,
  requestId: string
): Promise<PaymentStatus> {
  const { accessToken } = identity
  const res = await get(
    `${
      getConfig().apiUrl
    }/Payments/${customerId}/PaymentStatus?requestId=${requestId}`,
    accessToken
  )

  return res
}

async function getPaymentMeta(
  identity: Identity,
  customerId: number,
  amount: number,
  isConsultative: boolean
): Promise<PaymentInfo> {
  const { accessToken } = identity

  const response = await get(
    `${
      getConfig().apiUrl
    }/Payments/${customerId}/PaymentInfo?amount=${amount}&isConsultative=${isConsultative}`,
    accessToken
  )

  return response
}

export const paymentsApi: IPaymentsApi = {
  setupWithdrawal,
  getPaymentMeta,
  getPaymentStatus,
}
