import React from 'react'
import FundInvestDistributionContent, {
  FundInvestDistrubutionNextButton,
  FundInvestDistrubutionWithdrawNextButton,
} from '@taaleri/components/src/screens/funds/FundInvestDistributionContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import { ButtonsContainer } from 'ui/Buttons'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { FundAction } from '@taaleri/components/src/screens/funds/FundAction'
import { observer } from 'mobx-react'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'

function FundInvestDistributionScreen({ action }: { action: FundAction }) {
  const isWithdraw = action === 'Sell'
  return (
    <>
      <PaymentStepsFund currentStep={1} />
      <ContentContainerWide>
        <FundInvestDistributionContent action={action} />
        {!AppStore.loadingFund && (
          <ButtonsContainer>
            {isWithdraw ? (
              <FundInvestDistrubutionWithdrawNextButton />
            ) : (
              <FundInvestDistrubutionNextButton />
            )}
          </ButtonsContainer>
        )}
      </ContentContainerWide>
    </>
  )
}

export default observer(FundInvestDistributionScreen)
