import React, { useEffect, useState } from 'react'
import { BankSelectionProps } from './BankSelection'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { Paragraph } from '../../ui/text/Paragraph'
import BankList from './BankList'

import { createFundOrder } from '@taaleri/core/src/services/funds'
import ErrorWithRetry from '../../ui/ErrorWithRetry'

import FundPaymentDetail from './FundPaymentDetail'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { BoxWithPadding } from '../../ui/box/Box'
import UiButton from '../../ui/UiButton'
import { ROUTE_PORTFOLIO_PAYMENT_CONFIRM } from './PortfolioRoutes'
import { Divider } from '../../ui/Divider'
import useNavigation from '../../navigation/useNavigation'
import { useTranslation } from 'react-i18next'

function BankSelectionFund(props: BankSelectionProps) {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const [error, setError] = useState<boolean>(false)
  const [feeValueEstimate, setFeeValueEstimate] = useState<number>(0)
  const [fee, setFee] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  async function initOrder() {
    setError(false)
    setLoading(true)
    const { success, response } = await createFundOrder({
      securityId: AppStore.fundId || '',
      orderType: 'BUY',
      amount: AppStore.newInvestment,
      isRecurring: false,
      customerId: AppStore.customerId,
    })

    if (!success) {
      setError(true)
      setLoading(false)
    }
    if (response && response.smsConfirmationId) {
      AppStore.smsConfirmationId = response.smsConfirmationId
      setFeeValueEstimate(response.feeValueEstimate)
      setFee(response.fee)
      setLoading(false)
    }
  }

  useEffect(() => {
    initOrder()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  if (error) {
    return <ErrorWithRetry onRetry={initOrder} />
  }

  return (
    <View style={{ marginBottom: Spacings.S40 }}>
      <FundPaymentDetail
        investment={AppStore.newInvestment}
        feeValueEstimate={feeValueEstimate}
        fee={fee}
        fundId={AppStore.fundId}
        style={{ marginVertical: Spacings.S24 }}
      />

      <Paragraph>{t('invest.only-payment-agreement')}</Paragraph>
      <BankList {...props} />
      <BoxWithPadding title={`${t('invest.other-payment-types')}`}>
        <Divider />
        <UiButton
          type="secondary"
          title={t('invest.bank-transaction')}
          onPress={() => navigateApp(ROUTE_PORTFOLIO_PAYMENT_CONFIRM)}
        />
      </BoxWithPadding>
    </View>
  )
}

export default BankSelectionFund
