import {
  ROUTE_REPORT_ORDERS,
  ROUTE_REPORT_ORDERS_PACKAGE,
} from '@taaleri/components/src/navigation/routes'
import useNavigation from '@taaleri/components/src/navigation/useNavigation'
import { ROUTE_REPORT } from '@taaleri/components/src/screens/report/ReportRoutes'
import ReportOrdersList from '@taaleri/components/src/screens/reportorders/ReportOrdersList'
import { BackButtonRow } from '@taaleri/components/src/ui/BackButton'
import { FlexContainer } from '@taaleri/components/src/ui/FlexContainer'
import UiButton from '@taaleri/components/src/ui/UiButton'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppPadding } from 'screens/app/AppPadding'

export default function ReportOrdersScreen() {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const { isSmall } = useLayout()

  if (AppStore.IsReportOrdersForbidden() === true) {
    navigateApp(ROUTE_REPORT)
  }

  return (
    <AppPadding>
      <FlexContainer
        style={{
          alignItems: isSmall ? 'flex-start' : 'center',
          marginHorizontal: Spacings.S20,
        }}
      >
        <BackButtonRow title={`${t('menu.report-orders')}`} />
        <UiButton
          title={`${t('reports.new-report-order')}`}
          size={'small'}
          containerStyle={{
            marginBottom: isSmall ? Spacings.S24 : 0,
          }}
          onPress={() => {
            Analytics.event('ReportOrders', 'New report order')
            navigateApp(ROUTE_REPORT_ORDERS_PACKAGE)
          }}
        />
      </FlexContainer>
      <ReportOrdersList
        navigateToOrder={(customerId: string, orderId?: number) => {
          navigateApp(
            ROUTE_REPORT_ORDERS + '/' + orderId + '/reports/' + customerId
          )
        }}
      />
    </AppPadding>
  )
}
