
import AppStore from '@taaleri/core/src/stores/AppStore'
import { loggerInit } from '@taaleri/core/src/utils/logger'
import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import RootRouter from './screens/app/RootRouter'

smoothscroll.polyfill()

loggerInit(AppStore)

function App() {
  return <RootRouter />
}

export default App
