import React from 'react'
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { TextDefault } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'

interface LegendItem {
  title: string
  color: string
}

interface Props {
  containerStyle?: StyleProp<ViewStyle>
  items: LegendItem[]
}

export function ChartLegend({ containerStyle, items }: Props) {
  return (
    <FlexRow style={[styles.container, containerStyle]}>
      {items.map(({ title, color }) => (
        <FlexRow style={styles.row} key={title}>
          <View style={[styles.label, { backgroundColor: color }]} />
          <TextDefault type="t5">{title}</TextDefault>
        </FlexRow>
      ))}
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
  },
  row: {
    alignItems: 'center',
    marginBottom: 4,
    marginRight: Spacings.S16,
  },
  label: {
    marginRight: Spacings.S8,
    width: 10,
    height: 10,
    borderRadius: 10,
  },
})
