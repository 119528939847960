import {
  ROUTE_CONTRACT,
  ROUTE_CONTRACT_START_EVERVEST,
  ROUTE_CONTRACT_FORCE_START_CONSULTATIVE,
} from '@taaleri/components/src/screens/contract/ContractRoutes'
import {
  ROUTE_APP,
  ROUTE_QUESTIONS,
} from '@taaleri/components/src/navigation/routes'
import { ROUTE_IMPACT } from '../screens/impact/ImpactRoutes'

export default function routePath(path: string) {
  return '/' + path
}

export function routePathQuestions(path: string) {
  return '/' + ROUTE_QUESTIONS + '/' + path
}

export function routePathApp(path: string) {
  return '/' + ROUTE_APP + '/' + path
}

export function routePathImpact(path: string) {
  return `/${ROUTE_APP}/${ROUTE_IMPACT}/${path}`
}

export function routePathContract(path: string) {
  return `/${ROUTE_APP}/${ROUTE_CONTRACT}/${path}`
}

export function routePathAfterSignIn(route: string) {
  return [
    ROUTE_CONTRACT_START_EVERVEST,
    ROUTE_CONTRACT_FORCE_START_CONSULTATIVE,
  ].includes(route)
    ? routePathContract(route)
    : routePath(route)
}
