import React from 'react'
import { View } from 'react-native'
import PropsChildren from '../../components/PropsChildren'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'

export default function RecommendationsContaner({ children }: PropsChildren) {
  return (
    <View
      style={{
        borderTopLeftRadius: BorderRadius.default,
        borderTopRightRadius: BorderRadius.default,
        overflow: 'hidden',
        backgroundColor: Colors.primary,
      }}
    >
      {children}
    </View>
  )
}
