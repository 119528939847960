import { TextDefault } from '../ui/text/UiText'
import versionNumber from '../screens/profile/versionNumber'
import { StyleSheet } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function VersionNumber() {
  const { t } = useTranslation()
  return (
    <TextDefault type="t2" style={styles.appVersion}>
      {t('versionInfo')} {versionNumber}
    </TextDefault>
  )
}

export function SmallVersionNumber() {
  return (
    <TextDefault type="t4">
      {`v${versionNumber}`}
    </TextDefault>
  )
}

export function TaaleriIsPartOfAktia() {
  return (
    <TextDefault type="t2" style={styles.appVersion}>
      Taaleri Varainhoito on nyt osa Aktiaa
    </TextDefault>
  )
}

const styles = StyleSheet.create({
  appVersion: {
    textAlign: 'center',
    paddingVertical: Spacings.S16,
  },
})

export default VersionNumber
