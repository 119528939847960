import i18n, { t } from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationFI from './locales/fi-FI/translations.json'
import translationSV from './locales/sv-FI/translations.json'
import AppStore from './stores/AppStore'
import { customers } from './services/customers'

export const fiKey = 'fi'
export const svKey = 'sv'

const resources = {
  fi: {
    translation: translationFI,
  },
  sv: {
    translation: translationSV,
  },
}

const sendLanguage = (language: string) => {
  if (AppStore.adminToken === undefined) {
    const customerId = AppStore.activeCustomer?.customerId

    if (customerId) {
      customers.saveLanguage(customerId, language)
    }
  }
}

export const changeLanguage = (save?: boolean) => {
  const language = i18n.language

  switch (language) {
    case fiKey:
      setLanguage(svKey)
      if (save) {
        sendLanguage(svKey)
      }
      break
    case svKey:
      setLanguage(fiKey)
      if (save) {
        sendLanguage(fiKey)
      }
      break
    default:
      break
  }
}

export const setLanguage = (language: string) => {
  i18n.changeLanguage(language)
  AppStore.setLanguage(language)
}

export const getChangeText = () => {
  return t('languageChange')
}

i18n.use(initReactI18next).init(
  {
    fallbackLng: fiKey,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources,
  },
  (err) => {
    if (err) return console.log('i18n error: ', err)
  }
)

export default i18n
