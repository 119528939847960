import React, { useState } from 'react'

import { View } from 'react-native'

import Spacings from '@taaleri/core/src/constants/Spacings'
import { assetClassColors } from '@taaleri/core/src/models/assetClassColors'
import PercentagePieAsset from '../../components/PercentagePieAsset'
import FlexRow from '../../ui/FlexRow'
import UiTouchable from '../../ui/UiTouchable'
import { TextDefault } from '../../ui/text/UiText'
import { TextMediumHover } from '../../ui/text/TextHover'
import Fonts from '@taaleri/core/src/constants/Fonts'
import {
  AssetClassSecurities,
  Security,
} from '@taaleri/core/src/models/investmentProposal'
import { formatPercentageFull } from '@taaleri/core/src/utils/format'
import { IconChevronDown } from '../../ui/icons/ChevronIcons'
import { useTranslation } from 'react-i18next'

interface Props {
  assetClassSecurities: AssetClassSecurities[]
  openClassId?: string
  selectedSecurityId?: string
}

export default function AssetClassSecuritiesDistribution(props: Props) {
  const { t } = useTranslation()
  const [showDetail, setShowDetail] = useState<any>({
    [`${props.openClassId}`]: true,
  })
  const [force, setForce] = useState<number>(0)

  const { assetClassSecurities, selectedSecurityId } = props

  const p = 0
  return (
    <View>
      <View style={{ alignItems: 'center', marginBottom: Spacings.S24 }}>
        <PercentagePieAsset
          assetClasses={assetClassSecurities}
          percentage={p}
          size={144}
          borderWidth={16}
        />
      </View>

      {assetClassSecurities.map((percentage: AssetClassSecurities, index) => {
        const color = assetClassColors[percentage.id]
        const detailOpen = showDetail[percentage.id]
        return (
          <View key={index}>
            <UiTouchable
              onPress={() => {
                const newDetail = showDetail
                newDetail[percentage.id] = !showDetail[percentage.id]
                setShowDetail(newDetail)
                setForce(force + 1)
              }}
            >
              <FlexRow
                key={index}
                style={{
                  justifyContent: 'space-between',
                  marginBottom: Spacings.S16,
                }}
              >
                <FlexRow
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <View
                    style={{
                      marginRight: Spacings.S16,
                      transform: [{ rotate: detailOpen ? '180deg' : '0deg' }],
                    }}
                  >
                    <IconChevronDown color={color} />
                  </View>
                  <TextMediumHover style={{ lineHeight: 22 }}>
                    {t(percentage.name)}
                  </TextMediumHover>
                </FlexRow>
                <FlexRow
                  style={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <TextDefault>
                    {formatPercentageFull(percentage.percentage)} %
                  </TextDefault>
                </FlexRow>
              </FlexRow>
            </UiTouchable>
            {detailOpen && (
              <View style={{ marginBottom: Spacings.S16 }}>
                {percentage.securities.map((security: Security) => {
                  return (
                    <FlexRow
                      key={security.name}
                      style={{
                        justifyContent: 'space-between',
                        flex: 1,
                        flexWrap: 'wrap',
                        marginBottom: 6,
                      }}
                    >
                      <TextDefault
                        type="t3"
                        style={[
                          { flex: 1 },
                          selectedSecurityId === security.securityId && {
                            fontFamily: Fonts.avenirNextBold,
                          },
                        ]}
                      >
                        {security.name}
                      </TextDefault>
                      <TextDefault
                        type="t3"
                        style={[
                          {
                            flexBasis: 50,
                            textAlign: 'right',
                          },
                        ]}
                      >
                        {formatPercentageFull(security.percentage)} %
                      </TextDefault>
                    </FlexRow>
                  )
                })}
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}
