import { ROUTE_QUESTIONS } from '@taaleri/components/src/navigation/routes'
import FirstProposalContent from '@taaleri/components/src/screens/questions/FirstProposalContent'
import { ROUTE_QUESTION_FIRST_PROPOSAL } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { RedirectIf } from 'components/RedirectIf'
import { observer } from 'mobx-react'
import React from 'react'
import Buttons from 'ui/Buttons'
import QuestionContainer from './QuestionContainer'
import { useTranslation } from 'react-i18next'

function FirstProposalScreen() {
  const { t } = useTranslation()
  const redirectCondition = !QuestionStore.investmentGoal
  if (redirectCondition) {
    return (
      <RedirectIf to={`/${ROUTE_QUESTIONS}`} condition={redirectCondition} />
    )
  }
  const nextRoute = nextQuestionRoute(ROUTE_QUESTION_FIRST_PROPOSAL)

  return (
    <QuestionContainer>
      <FirstProposalContent>
        <Buttons title={t('button.next')} route={nextRoute} />
      </FirstProposalContent>
    </QuestionContainer>
  )
}

export default observer(FirstProposalScreen)
