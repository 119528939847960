import Analytics from '@taaleri/core/src/analytics/Analytics'
import { getConfig } from '@taaleri/core/src/config'
import { ContractPdf } from '@taaleri/core/src/models/ContractPdf'
import { Identity } from '@taaleri/core/src/models/Identity'
import { contractPdfList } from '@taaleri/core/src/services/contracts'
import { getIdentity } from '@taaleri/core/src/services/storage'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { formatDateFull, formatYear } from '@taaleri/core/src/utils/format'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ITabItem,
  ITabListItem,
  TabBox,
  tabReducer,
} from '../../components/TabBox'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import LoadingIndicator from '../../ui/LoadingIndicator'
import OpenLink from '../../ui/OpenLink'

export function GetContractUrl(
  contractId: number,
  customerId: number,
  token: string
): string {
  return `${
    getConfig().apiUrl
  }/Contracts/${customerId}/${contractId}/contract.pdf?token=${token}`
}

function contractPdfToListItem(
  contractPdf: ContractPdf,
  tabValue: string
): ITabListItem {
  return {
    id: contractPdf.id,
    tabValue,
    title: contractPdf.contractName,
    titleRight: formatDateFull(contractPdf.creationDate),
  }
}

function openUrl(listItem: ITabListItem, customerId: number, token: string) {
  const url = GetContractUrl(listItem.id, customerId, token)
  Analytics.event('Contract', 'Open Contract Pdf', listItem.title)
  OpenLink(url, 'aktia')
}

function ContractsList() {
  const [tabs, setTabs] = useState<ITabItem[]>([])
  const [token, setToken] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  async function findContracts() {
    setError(false)
    setLoading(true)
    const result = await contractPdfList(
      AppStore.customerId,
      AppStore.isPrivateCustomer
    )
    if (result.success) {
      const identity: Identity = await getIdentity()
      setToken(identity.accessToken)

      const listItems: ITabListItem[] = []
      let tTabs: ITabItem[] = []

      result.response?.forEach((contractPdf) => {
        const year = formatYear(contractPdf.creationDate)
        // Checks that formatYear has returned a valid number
        if (!isNaN(+year)) {
          listItems.push(contractPdfToListItem(contractPdf, year))
        }
      })

      tTabs = listItems.reduce(tabReducer, tTabs)
      setTabs(tTabs)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    findContracts()
  }, [AppStore.customerId])

  if (error) {
    return <ErrorWithRetry onRetry={findContracts} />
  }

  if (loading) {
    return <LoadingIndicator type="inline" />
  }

  return (
    <TabBox
      titles={tabs}
      onListItemPressed={(listItem) =>
        openUrl(listItem, AppStore.customerId, token)
      }
      emptyText={`${t('reportBox.no-contracts')}`}
    />
  )
}

export default observer(ContractsList)
