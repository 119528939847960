import { StyleProp, View, ViewStyle } from 'react-native'
import Svg, { Path, Polyline, Rect } from 'react-native-svg'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { IconProps } from '../IconProps'
import { IconContainer } from '../IconContainer'

/**
 * Use https://react-svgr.com/playground/?native=true&typescript=true
 * to convert icons from designs. Remove props.
 */

export const IconCheckCircle: React.FC<IconProps> = ({ color, style }) => (
  <View style={style}>
    <IconContainer>
      <Path
        fill={color || Colors.primary}
        fillRule="nonzero"
        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
      />
    </IconContainer>
  </View>
)

export const IconCheckCircleWhite: React.FC<IconProps> = ({
  color = Colors.primary,
  style,
  width = 24,
  height = 24,
}) => {
  const scale = width / 24
  return (
    <View style={style}>
      <Svg
        width={width}
        height={height}
        fill="none"
        viewBox={`0 0 ${width} ${height}`}
      >
        <Path
          fill={color}
          scale={scale}
          fillRule="evenodd"
          d="M12 3c-4.967 0-9 4.033-9 9s4.033 9 9 9 9-4.033 9-9-4.033-9-9-9zM1 12C1 5.928 5.928 1 12 1s11 4.928 11 11-4.928 11-11 11S1 18.072 1 12z"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          scale={scale}
          d="M10.405 16.227L6.42 12.24l1.124-1.124 2.862 2.854 6.052-6.051 1.124 1.132-7.176 7.176z"
        />
        <Path
          fill={color}
          scale={scale}
          fillRule="evenodd"
          d="M16.458 7.5c.111 0 .218.045.296.124l1.124 1.132a.419.419 0 010 .59L10.7 16.524a.419.419 0 01-.592 0l-3.986-3.987a.419.419 0 010-.592l1.124-1.124a.419.419 0 01.591 0l2.567 2.559 5.756-5.756a.419.419 0 01.297-.123zm-.002 1.012l-5.755 5.754a.419.419 0 01-.591 0l-2.567-2.559-.532.533 3.394 3.395L16.99 9.05l-.534-.538z"
          clipRule="evenodd"
        />
      </Svg>
    </View>
  )
}

export const IconCircle: React.FC<{
  style?: StyleProp<ViewStyle>
  size?: number
  color?: string
}> = ({ style, color }) => {
  return (
    <View style={style}>
      <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <Path
          d="M1 10C1 5.03228 5.03228 1 10 1C14.9677 1 19 5.03228 19 10C19 14.9677 14.9677 19 10 19C5.03228 19 1 14.9677 1 10Z"
          stroke={color || Colors.text}
          strokeOpacity={color === Colors.primary ? '1' : '0.15'}
          strokeWidth="2"
        />
      </Svg>
    </View>
  )
}

export const IconCircleClose: React.FC<IconProps> = ({
  color = Colors.text,
  width = 24,
  height = 24,
}) => {
  const scale = width / 24
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <Path
        stroke={color}
        strokeWidth="2"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"
        scale={scale}
      />
      <Path
        fill={color}
        scale={scale}
        d="M16.243 9.172L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586l2.828-2.829 1.415 1.415z"
      />
    </Svg>
  )
}

export const IconExclamationCircle: React.FC<{
  color: string
  style?: StyleProp<ViewStyle>
}> = ({ color, style }) => (
  <View style={style}>
    <IconContainer>
      <Path
        fill={color}
        fillRule="nonzero"
        d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,15 L13,15 L13,17 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z"
      />
    </IconContainer>
  </View>
)

export const RefreshIconCircle: React.FC<IconProps> = ({
  color = Colors.black,
  width = 50,
  height = 50,
}) => {
  const scale = 0.5

  return (
    <View>
      <Svg
        width={width}
        height={height}
        fill="none"
        viewBox={`0 0 ${width} ${height}`}
      >
        <Rect fill="none" height={height} width={width} scale={scale} />
        <Polyline
          fill="none"
          points="40,7 40,16   31,15.999 "
          stroke={color}
          stroke-linecap="round"
          stroke-miterlimit="2"
          stroke-width="2"
          scale={scale}
        />
        <Path
          d="M41.999,25  c0,9.39-7.61,17-17,17s-17-7.61-17-17s7.61-17,17-17c5.011,0,9.516,2.167,12.627,5.616c0.618,0.686,1.182,1.423,1.683,2.203"
          stroke={color}
          stroke-width="2"
          scale={scale}
        />
      </Svg>
    </View>
  )
}
