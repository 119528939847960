import styled from 'styled-components'

export const AppPadding = styled.div`
  padding: 0 20px 40px 20px;

  @media (min-width: 1000px) {
    padding: 0;
    padding-bottom: 100px;
  }
`

export const AppPaddingMedium = styled.div`
  padding: 0 20px;
  @media (min-width: 1000px) {
    padding: 0;
  }
`
