import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { TextDefault } from '../../ui/text/UiText'
import FlexRow from '../../ui/FlexRow'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'

interface ReportRowProps {
  label: string
  value: string
  topBorder?: boolean
  valueStyle?: StyleProp<TextStyle>
}

export function ReportRowContainer({
  topBorder,
  children,
}: {
  topBorder?: boolean
  children: any
}) {
  return (
    <FlexRow
      style={{
        justifyContent: 'space-between',
        paddingVertical: Spacings.S16,
        borderBottomWidth: 1,
        borderTopWidth: topBorder ? 1 : 0,
        borderColor: Colors.border,
        alignItems: 'center',
      }}
    >
      {children}
    </FlexRow>
  )
}

function ReportRow({ label, value, topBorder, valueStyle }: ReportRowProps) {
  return (
    <ReportRowContainer {...{ topBorder }}>
      <TextDefault type="t2" style={{ flex: 1 }}>
        {label}
      </TextDefault>
      <TextDefault type="h6" style={valueStyle}>
        {value}
      </TextDefault>
    </ReportRowContainer>
  )
}

export default ReportRow
