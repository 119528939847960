import React from 'react'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import ReportDiscountContent from '@taaleri/components/src/screens/reportfee/ReportDiscountContent'

export function ReportDiscountScreen() {
  return (
    <ContentContainerWide style={{ paddingHorizontal: 0 }}>
      <ReportDiscountContent />
    </ContentContainerWide>
  )
}
