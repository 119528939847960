import { formatFundProfit, formatNumber } from '../utils/format'
import {
  formatCurrencyWithoutCents,
  formatCurrency,
} from '../utils/formatCurrency'

export const SHARPE = 'SHARPE'
export const VOLATILITY = 'VOLATILITY'
export const PERFORMANCE = 'PERFORMANCE'
export const MARKET_VALUE = 'MARKET_VALUE'
export const SECURITY_PRICE = 'SECURITY_PRICE'
export const UNREALIZED_PROFIT = 'UNREALIZED_PROFIT'

export type KeyFigureType =
  | typeof SHARPE
  | typeof VOLATILITY
  | typeof PERFORMANCE
  | typeof MARKET_VALUE
  | typeof UNREALIZED_PROFIT
  | typeof SECURITY_PRICE

type FormatFunction = (value: number) => string

export interface KeyFigure extends KeyFigureBase {
  id: KeyFigureType
  value?: number
  difference?: number
}

interface KeyFigureBase {
  label: string
  format: FormatFunction
  inverted: boolean
  touchable: boolean
  description: string
}

const getKeyFigureProps: { [key in KeyFigureType]: KeyFigureBase } = {
  SHARPE: {
    label: 'portfolio.keyfigures-sharpe',
    format: formatNumber,
    inverted: false,
    touchable: false,
    description: 'portfolio.keyfigures-sharpe-description',
  },
  VOLATILITY: {
    label: 'portfolio.keyfigures-volatility',
    format: (value: number) =>
      formatFundProfit(Math.abs(value) < 0.01 || isNaN(value) ? 0 : value),
    inverted: true,
    touchable: true,
    description: 'portfolio.keyfigures-volatility-description',
  },
  PERFORMANCE: {
    label: 'graph.profit',
    format: (value: number) =>
      formatFundProfit(Math.abs(value) < 0.01 ? 0 : value),
    inverted: false,
    touchable: true,
    description: 'portfolio.keyfigures-profit-description',
  },
  MARKET_VALUE: {
    label: 'netWorth',
    format: formatCurrencyWithoutCents,
    inverted: false,
    touchable: true,
    description: 'portfolio.keyfigures-market-value-description',
  },
  UNREALIZED_PROFIT: {
    label: 'portfolio.keyfigures-unrealized-change',
    format: formatCurrencyWithoutCents,
    inverted: false,
    touchable: false,
    description: 'portfolio.keyfigures-unrealized-change-description',
  },
  SECURITY_PRICE: {
    label: 'portfolio.keyfigures-security-price',
    format: formatCurrency,
    inverted: false,
    touchable: false,
    description: '',
  },
}

export function createKeyFigure(
  id: KeyFigureType,
  value?: number,
  difference?: number
): KeyFigure {
  const { label, format, touchable, inverted, description } =
    getKeyFigureProps[id]
  return {
    id,
    value,
    difference,
    inverted,
    label,
    format,
    touchable,
    description,
  }
}
