import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { IconPortfolioError, IconSadNotification } from './Icons'
import { Paragraph } from './text/Paragraph'
import UiButton from './UiButton'
import { useTranslation } from 'react-i18next'

type IconType = 'portfolio'

const iconTypeToComponent = {
  portfolio: () => (
    <IconPortfolioError
      color={Colors.text}
      style={{ marginBottom: Spacings.S24 }}
    />
  ),
  notification: () => (
    <IconSadNotification
      color={Colors.text}
      style={{ marginBottom: Spacings.S24 }}
    />
  ),
}

const ErrorWithRetry: React.FC<{
  iconType?: IconType
  onRetry: () => void
}> = ({ iconType, onRetry }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      {iconType && iconTypeToComponent[iconType]
        ? iconTypeToComponent[iconType]()
        : iconTypeToComponent.notification()}
      <Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
        {t('validation.error-apologize-part1')}
        {'\n'} {t('validation.error-apologize-part2')}
      </Paragraph>
      <UiButton
        type="secondary"
        title={`${t('button.try-again')}`}
        onPress={onRetry}
        containerStyle={styles.buttonContainer}
      />
    </View>
  )
}

export default ErrorWithRetry

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Spacings.S40,
  },
  buttonContainer: { marginTop: Spacings.S32 },
})
