import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import UiModal, { modalContentStyles } from '../../ui/modal/UiModal'
import { TextMedium } from '../../ui/text/UiText'
import UiScrollView from '../../ui/UiScrollView'
import UiTouchable from '../../ui/UiTouchable'
import PropsChildren from '../../components/PropsChildren'
import useNavigation from '../../navigation/useNavigation'
import { routePathApp } from '../../navigation/routeHelper'
import { ROUTE_PROFILE_CHANGE_PHONE } from '../profile/ProfileRoutes'
import { useTranslation } from 'react-i18next'

export default function ChangePhoneNumberModal({ children }: PropsChildren) {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  function toggle(value: boolean) {
    setModalVisible(value)
  }

  return (
    <>
      <UiTouchable onPress={() => toggle(true)}>{children}</UiTouchable>
      <View style={styles.container}>
        <UiModal
          isVisible={modalVisible}
          onBackdropPress={() => toggle(false)}
          style={{
            marginHorizontal: Spacings.S24,

            marginBottom: Spacings.S32,
          }}
        >
          <View style={styles.modalContent}>
            <UiScrollView
              bounces={false}
              style={{ marginBottom: Spacings.S32 }}
            >
              <TextMedium>
                {t('onboarding.change-phone-part1')}
              </TextMedium>
              <UiTouchable
                onPress={() =>
                  navigate(routePathApp(ROUTE_PROFILE_CHANGE_PHONE))
                }
              >
                <TextMedium style={{ color: Colors.primary }}>
                  {t('onboarding.change-phone-part2')}
                </TextMedium>
              </UiTouchable>
            </UiScrollView>
          </View>
        </UiModal>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
  },
  title: {
    marginBottom: Spacings.S16,
    textAlign: 'center',
    width: '100%',
    lineHeight: 22,
  },
  modalContent: {
    ...modalContentStyles,
    paddingTop: Spacings.S24,
    alignItems: 'flex-start',
  },
})
