import React from 'react'
import ChangePhoneDoneContent from '@taaleri/components/src/screens/profile/ChangePhoneDoneContent'
import DoneScreen from './DoneScreen'

export default function ChangePhoneDoneScreen() {
  return (
    <DoneScreen>
      <ChangePhoneDoneContent />
    </DoneScreen>
  )
}
