import Analytics from '@taaleri/core/src/analytics/Analytics'
import AppStore from '@taaleri/core/src/stores/AppStore'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ActionBox from './ActionBox'
import { PropsOnPress } from '../../components/PropsChildren'

function ContractUpdateBox(props: PropsOnPress) {
  const showBox =
    AppStore.showContractBox &&
    AppStore.showContractUpdate &&
    !AppStore.isConsultativeWithoutContract
  const { t } = useTranslation()
  if (!showBox) {
    return null
  }

  return (
    <ActionBox
      onPress={() => {
        Analytics.event('Home', 'Contract update click')
        QuestionStore.questionMode = 'contract'
        props.onPress()
      }}
    >
      {t('home.contract-update-text')}
    </ActionBox>
  )
}

export default observer(ContractUpdateBox)
