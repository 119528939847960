import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React, { useState, useEffect, ComponentType, useRef } from 'react'
import {
  Animated,
  NativeSyntheticEvent,
  NativeTouchEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Easing,
  TextInput,
} from 'react-native'
import Fonts, { FontSize } from '@taaleri/core/src/constants/Fonts'
import { TextMedium, TextDefault } from '../text/UiText'
import { useField } from 'formik'
import UiTouchable from '../UiTouchable'
import { IconCheckCircleWhiteBg } from './IconCheckCircleWhiteBg'
import { IconVisibilityOff, IconVisibilityOn } from '../icons/VisibilityIcons'
import InfoButton from '../InfoButton'
import { InfoButtonStyledProps } from '../InfoButtonProps'
import { useTranslation } from 'react-i18next'

/*
TODO: remove icons above and use ones declared in Icons.tsx after react-native-svg bug is fixed
https://github.com/react-native-community/react-native-svg/issues/1471
*/

const INPUT_FONT_SIZE = FontSize.S16
const LABEL_FONT_SIZE = FontSize.S14
const COLOR_INPUT = Colors.text
const COLOR_UNIT_LABEL = Colors.text

type TextInputProps = React.ComponentProps<typeof TextInput>
type WebTextProps = TextInputProps & {
  autoComplete?: string
  ref?: any
}
const TextInputWeb = TextInput as ComponentType<WebTextProps>

type InputType = 'email' | 'password' | 'name' | 'digits' | undefined
const getInputTypeProps = (type: InputType) => {
  switch (type) {
    case 'email':
      return {
        autoCorrect: false,
        keyboardType: 'email-address',
        autoCapitalize: 'none',
      }
    case 'password':
      return {
        autoCorrect: false,
        secureTextEntry: true,
        autoCapitalize: 'none',
      }
    case 'digits':
      return {
        keyboardType: 'phone-pad',
      }
    case 'name':
      return {
        autoCorrect: false,
      }
    default:
      return {}
  }
}

export function UiTextInputLabel({
  label,
  grayBackground,
  isFocused,
  isPlaceholder,
  disableFloatingLabel,
  forceFocused,
  labelColor,
  test,
  hasInfoButton,
  hasPlaceholder,
}: {
  label: string
  grayBackground?: boolean
  isFocused: boolean
  isPlaceholder: boolean
  disableFloatingLabel?: boolean
  forceFocused?: boolean
  labelColor: string
  test: boolean
  hasInfoButton: boolean
  hasPlaceholder?: boolean
}) {
  const animatedIsFocused = new Animated.Value(
    test && !disableFloatingLabel ? 0 : 1
  )

  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: isFocused || label !== '' || disableFloatingLabel ? 1 : 0,
      duration: 200,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }).start()
  }, [label, isFocused])

  const labelStyle = [
    {
      position: 'absolute',
      backgroundColor: grayBackground ? Colors.background : Colors.white,
      paddingHorizontal: 6,
      fontFamily:
        isPlaceholder && !isFocused && !disableFloatingLabel && !forceFocused
          ? Fonts.avenirNextBold
          : Fonts.avenirNextMedium,
      left: disableFloatingLabel ? -6 : 10,
      top: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [18, disableFloatingLabel ? -26 : -10],
      }),
      fontSize: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [INPUT_FONT_SIZE, LABEL_FONT_SIZE],
      }),
      color: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [COLOR_INPUT, labelColor],
      }),
      zIndex: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1000],
      }),
    },
    hasInfoButton && {
      paddingLeft: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [40, 6],
      }),
    },
  ]

  return <Animated.Text style={labelStyle}>{label}</Animated.Text>
}

export interface UiTextInputProps extends TextInputProps {
  name: string
  label?: string
  unitLabel?: string
  actionLabel?: string
  onActionPress?: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void
  type?: InputType
  requiredNotFilled?: boolean
  containerStyle?: StyleProp<ViewStyle>
  grayBackground?: boolean
  hideCheckCircle?: boolean
  disableFloatingLabel?: boolean
  focus?: boolean
  height?: number
  editable?: boolean
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  value?: string
  maxLength?: number
  children?: any
  hideError?: boolean
  useEyeOnOff?: boolean
  ignoreTouched?: boolean
  forcedErrorMessage?: string
  forceFocused?: boolean
  infoLabel?: InfoButtonStyledProps[]
  disableAutoComplete?: boolean
}

function UiTextInput(props: UiTextInputProps) {
  const { t } = useTranslation()
  const [isFocused, setFocused] = useState<boolean>(false)
  const [isPlaceholder, setPlaceholder] = useState<boolean>(false)
  const [isPasswordVisible, setPasswordVisbile] = useState<boolean>(false)
  const [field, meta] = useField(props.name)
  const input: any = useRef(null)

  useEffect(() => {
    if (!isFocused && props.focus) {
      input.current.focus()
    }
  }, [props.focus])

  const {
    actionLabel,
    label,
    onActionPress,
    unitLabel,
    editable,
    containerStyle,
    grayBackground,
    disableFloatingLabel,
    hideCheckCircle,
    multiline,
    placeholder,
    hideError,
    autoFocus,
    useEyeOnOff,
    style,
    ignoreTouched,
    forcedErrorMessage,
    forceFocused,
    infoLabel,
    disableAutoComplete,
  } = props

  const showError =
    forcedErrorMessage ||
    (meta.error && !hideError && (ignoreTouched || meta.touched))
  const labelColor = showError
    ? Colors.error
    : isFocused || forceFocused
    ? Colors.primary
    : Colors.textSecondary
  const borderColor = showError
    ? Colors.error
    : isFocused || forceFocused
    ? Colors.primary
    : Colors.gray20
  const value = field.value
  const hasValue = value !== undefined && value !== null && value.length > 0
  const hasPlaceholder = !!placeholder
  const isSpecialState = isFocused || showError || forceFocused
  const textColor =
    editable !== undefined && editable === false
      ? Colors.textDisabled
      : COLOR_INPUT
  const textStyles = {
    borderColor,
    borderWidth: isSpecialState ? 2 : 1,
    paddingHorizontal: isSpecialState ? Spacings.S16 - 1 : Spacings.S16,
    color: textColor,
  }

  const onFocus = (e: any) => {
    if (props.onFocus) {
      props.onFocus(e)
    }
    setFocused(true)
  }

  const onBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e)
    }
    setFocused(false)
  }

  const onEyeClick = () => {
    if (editable) {
      setPasswordVisbile(!isPasswordVisible)
    }
  }

  const secureTextEntry =
    (props.type === 'password' && !useEyeOnOff) ||
    (useEyeOnOff && !isPasswordVisible)

  return (
    <View
      style={[{ marginBottom: showError ? 6 : Spacings.S24 }, containerStyle]}
    >
      <Animated.View>
        {label && (
          <UiTextInputLabel
            grayBackground={grayBackground}
            isFocused={isFocused}
            isPlaceholder={isPlaceholder}
            disableFloatingLabel={disableFloatingLabel}
            forceFocused={forceFocused}
            test={field.value === ''}
            labelColor={labelColor}
            label={label}
            hasInfoButton={!!infoLabel}
            hasPlaceholder={hasPlaceholder}
          />
        )}

        {props.children && props.children}

        {!props.children && (
          <TextInputWeb
            ref={input}
            value={field.value}
            name={field.name}
            onChange={field.onChange(field.name)}
            {...getInputTypeProps(props.type)}
            nativeID={field.name}
            type={secureTextEntry ? 'password' : 'text'}
            secureTextEntry={secureTextEntry}
            onChangeText={props.onChangeText}
            autoCorrect={false}
            onFocus={onFocus}
            autoFocus={autoFocus}
            allowFontScaling={false}
            style={[
              styles.input,
              textStyles,
              !!infoLabel && {
                paddingLeft: 46,
              },
              disableFloatingLabel && {
                textAlign: 'center',
                fontSize: FontSize.S20,
              },
              {
                height: props.height || INPUT_HEIGHT,
                fontFamily: multiline
                  ? Fonts.avenirNextMedium
                  : Fonts.avenirNextBold,
              },

              multiline && {
                lineHeight: 22,
                paddingVertical: Spacings.S16,
                textAlignVertical: 'top',
              },
              style,
            ]}
            autoComplete={props.disableAutoComplete ? 'off' : 'none'}
            onBlur={(e: any) => {
              onBlur(e)
            }}
            multiline={multiline}
            editable={editable}
            placeholder={placeholder}
          />
        )}

        {infoLabel && (
          <InfoButton
            style={styles.infoLabel}
            title={label}
            styledParagraphs={infoLabel}
          />
        )}

        {unitLabel && (
          <TextMedium style={styles.unitLabel}>{unitLabel}</TextMedium>
        )}
        {actionLabel && onActionPress && (
          <TextMedium
            style={[styles.unitLabel, styles.actionLabel]}
            onPress={onActionPress}
          >
            {actionLabel}
          </TextMedium>
        )}
        {useEyeOnOff && (
          <View style={styles.rightIconEye}>
            <UiTouchable
              onPress={onEyeClick}
              style={styles.rightIconEyeTouchArea}
              accessible={false}
            >
              {isPasswordVisible ? <IconVisibilityOn /> : <IconVisibilityOff />}
            </UiTouchable>
          </View>
        )}

        {!useEyeOnOff &&
          hasValue &&
          !meta.error &&
          editable !== false &&
          !hideCheckCircle &&
          !forcedErrorMessage && <IconCheckCircleWhiteBg />}
      </Animated.View>
      {showError && (
        <TextDefault style={styles.errorMessage}>
          {t(meta.error ?? '') || forcedErrorMessage}
        </TextDefault>
      )}
    </View>
  )
}

export default UiTextInput

export const UiTextInputForm = UiTextInput
export const UiTextInputFormAutoFocus = UiTextInput

export const INPUT_HEIGHT = 56

export const styles = StyleSheet.create({
  input: {
    fontSize: INPUT_FONT_SIZE,
    color: COLOR_INPUT,
    borderWidth: 1,
    borderColor: Colors.gray20,
    paddingHorizontal: Spacings.S16,
    borderRadius: BorderRadius.small,
    zIndex: 1,
    height: INPUT_HEIGHT,
  },
  errorMessage: {
    color: Colors.error,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: Spacings.S16,
  },
  unitLabel: {
    color: COLOR_UNIT_LABEL,
    fontSize: INPUT_FONT_SIZE,
    position: 'absolute',
    bottom: 15,
    right: 15,
  },
  infoLabel: {
    color: COLOR_UNIT_LABEL,
    position: 'absolute',
    bottom: 18,
    left: 13,
    zIndex: 1,
  },
  rightIcon: {
    position: 'absolute',
    bottom: 16,
    right: Spacings.S16,
  },
  rightIconEye: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  rightIconEyeTouchArea: {
    height: INPUT_HEIGHT,
    width: INPUT_HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionLabel: {
    color: Colors.primary,
    zIndex: 1,
  },
})
