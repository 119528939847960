import React from 'react'
import { observer } from 'mobx-react'
import TransactionStore from '@taaleri/core/src/stores/TransactionStore'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { AssetClassIconEmpty } from '../../report/AssetClassIcon'
import PositionContainer from '../../report/PositionContainer'
import FilterButton from '../../report/FilterButton'

function TypeFilter() {
  const { allTransactionTypes, selectedTransactionTypes } = TransactionStore

  if (allTransactionTypes.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allTransactionTypes
        .filter((a) => !selectedTransactionTypes.includes(a))
        .map((t) => (
          <FilterButton
            key={t}
            onPress={() => TransactionStore.addSelectedTransactionType(t)}
            selected={false}
          >
            <AssetClassIconEmpty />

            <TextDefault
              type="t2"
              style={{
                color: Colors.text,
                letterSpacing: 0.2,
              }}
            >
              {t}
            </TextDefault>
          </FilterButton>
        ))}
    </PositionContainer>
  )
}

export default observer(TypeFilter)
