import React, { useState } from 'react'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { OptionQuestion } from '@taaleri/core/src/models/Question'
import { View, StyleSheet } from 'react-native'
import QuestionContainer from './QuestionContainer'
import OptionQuestionContent from '@taaleri/components/src/screens/questions/OptionQuestionContent'
import {
  nextQuestionOrContractRoute,
  showLiquidityInfo,
} from '@taaleri/components/src/screens/questions/questions'
import { observer } from 'mobx-react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import Buttons from 'ui/Buttons'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { useTranslation } from 'react-i18next'

interface QuestionProps {
  optionQuestion: OptionQuestion
  history?: any
}
// TODO ADD contract error handling from app OptionQuestionScreen
function OptionQuestionScreen(props: QuestionProps) {
  const { t } = useTranslation()
  const { optionQuestion, history } = props
  const [error, setError] = useState<boolean>(false)

  function toNextScreen() {
    const { id } = optionQuestion
    const currentAnswer = QuestionStore.getAnswer(id)
    const isAnswered = currentAnswer || currentAnswer === 0

    if (
      !isAnswered ||
      !optionQuestion.options.find((o) => o.value === currentAnswer)
    ) {
      setError(true)
      return
    }

    const nextQuestion = nextQuestionOrContractRoute(
      id,
      AppStore.customerMaybe && AppStore.isDigiDiscretionary,
      AppStore.customerMaybe && AppStore.isConsultativeWithoutContract,
      AppStore.customerMaybe && AppStore.isConsultativeOrOpening,
      QuestionStore.tupasDone && isWeb
    )

    if (nextQuestion !== '') {
      setTimeout(() => {
        history.push(nextQuestion)
      }, 400)
    }
  }

  function onAnswer(answer: string) {
    setError(false)
    QuestionStore.setAnswer(optionQuestion.id, answer)
    toNextScreen()
  }

  const contractMode = QuestionStore.questionMode === 'contract'
  const showButton = !showLiquidityInfo(optionQuestion.id)
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <View style={styles.container}>
          <OptionQuestionContent
            question={optionQuestion}
            onAnswer={onAnswer}
          />
        </View>
        {contractMode && (
          <>
            {error && (
              <View
                style={{
                  padding: 5,
                  paddingBottom: 19,
                  position: 'absolute',
                  bottom: 96,
                  left: Spacings.S24,
                  right: Spacings.S24,
                  backgroundColor: Colors.background,
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <TextDefault color={Colors.error}>
                  {t(ErrorMessages.chooseAtLeastOne)}
                </TextDefault>
              </View>
            )}
            {showButton && (
              <Buttons title={t('button.next')} onPress={toNextScreen} />
            )}
          </>
        )}
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(OptionQuestionScreen)

const styles = StyleSheet.create({
  container: {
    paddingBottom: Spacings.S24,
  },
})
