import { isWeb } from '@taaleri/components/src/constants/Platforms'
import ChangePassword from '@taaleri/core/src/models/ChangePassword'

import { TwoFactorResponse } from './../models/TwoFactorResponse'
import { handleErrors } from './handleErrors'
import {
  getCredentials,
  getFirstSignInDone,
  getIdentity,
  getOnboardingProcessIdentity,
  setCredentials,
  setFirstSignInDone,
  setIdentity,
  setLocalSignedIn,
  setOnboardingProcessIdentity,
} from './storage'
import { api } from '../api/api'
import { UnauthorizedError } from '../errors'
import { setLanguage } from '../i18n'
import { Identity, Login, Registration } from '../models/Identity'
import {
  CONFLICT,
  ServiceResponse,
  UNEXPECTED,
  SmsResponse,
  EXPIRED,
} from '../models/ServiceResponse'
import AppStore from '../stores/AppStore'
import { nowInSeconds } from '../utils/date'
import logger from '../utils/logger'

async function doRegistration(
  register: Registration,
  token?: string
): Promise<ServiceResponse<Identity>> {
  try {
    const onboardingProcessIdentity: string =
      await getOnboardingProcessIdentity()

    const identity: Identity | null = await api().authentication.registration(
      onboardingProcessIdentity,
      register,
      token
    )

    if (identity) {
      await AppStore.signOnboardingUserIn(register, identity)
    }

    return { success: !!identity }
  } catch (e) {
    const error = handleErrors(e, 'doRegistration')

    return {
      success: false,
      error,
    }
  }
}

async function doRegistrationExisting(
  token: string,
  register: Registration
): Promise<ServiceResponse<void>> {
  try {
    const login: Login | null = await api().authentication.registrationExisting(
      token,
      register
    )

    if (
      login &&
      login.customer.customerId === undefined &&
      register.customerid !== ''
    ) {
      login.customer.customerId = Number.parseInt(register.customerid, 10)
    }

    if (login) {
      await AppStore.signUserIn(login.identity, login.customer, register)
    }

    return { success: !!(login && login.identity) }
  } catch (e) {
    const error = handleErrors(e, 'doRegistrationExisting')

    return {
      success: false,
      error,
    }
  }
}

async function changePassword(
  register: Registration
): Promise<ServiceResponse<void>> {
  try {
    const { accessToken } = await getIdentity()
    const login: Login | null = await api().authentication.changePassword(
      accessToken,
      register
    )

    if (login && login.identity) {
      if (!isWeb) {
        await setCredentials(register)
      }
      await setIdentity(login.identity)
    }

    return { success: !!(login && login.identity) }
  } catch (e) {
    const error = handleErrors(e, 'changePassword')

    return {
      success: false,
      error,
    }
  }
}

function changeForgottenPassword(accessToken: string) {
  return async (register: Registration): Promise<ServiceResponse<void>> => {
    try {
      const login: Login | null = await api().authentication.changePassword(
        accessToken,
        register
      )

      if (login) {
        await AppStore.signUserIn(login.identity, login.customer, register)
      }

      return { success: !!(login && login.identity) }
    } catch (e) {
      const error = handleErrors(e, 'changeForgottenPassword')

      return {
        success: false,
        error,
      }
    }
  }
}

async function resetInfo(
  token: string
): Promise<ServiceResponse<void> & { email?: string }> {
  try {
    const email = await api().authentication.resetInfo(token)
    return { success: true, email }
  } catch (e) {
    const error = handleErrors(e, 'resetInfo')
    return {
      success: false,
      error,
    }
  }
}

function checkWarnings() {
  AppStore.setWarningNotificationDismissed(false)
  AppStore.setWarningNotificationDataToEmpty()
  AppStore.checkWarningNotification()
}

async function signIn(
  email: string,
  password: string
): Promise<ServiceResponse<void>> {
  if (email !== 'wrong@taaleri.com' && password !== 'wrongwrong') {
    try {
      const {
        identity,
        customer,
        twoFactor,
        isOnboardingInProgress,
        lastUsedLanguage,
      }: Login = await api().authentication.login(email, password)

      const registration = { email, password }
      if (lastUsedLanguage !== '') {
        setLanguage(lastUsedLanguage)
      }
      if (twoFactor.isTwoFactor) {
        AppStore.setTwoFactor(twoFactor, registration)
      } else if (isOnboardingInProgress) {
        return { success: false, error: EXPIRED }
      } else {
        checkWarnings()
        await AppStore.signUserIn(identity, customer, registration)

        const { accessToken } = await getIdentity()

        if (!accessToken || !AppStore.portfolio) {
          return {
            success: false,
            error: UNEXPECTED,
          }
        }
      }
      return { success: true }
    } catch (e) {
      const error = handleErrors(e, 'signIn', 'error', true)
      return { success: false, error }
    }
  }

  return { success: false, error: CONFLICT }
}

async function signInAdmin(
  customerId: number,
  token: string
): Promise<ServiceResponse<void>> {
  try {
    checkWarnings()
    const { identity, customer }: Login = await api().authentication.loginAdmin(
      customerId,
      token
    )

    await AppStore.signUserIn(identity, customer)
    AppStore.adminToken = token
    const { accessToken } = await getIdentity()

    if (!accessToken || !AppStore.portfolio) {
      return {
        success: false,
        error: UNEXPECTED,
      }
    }
    return { success: true }
  } catch (e) {
    const error = handleErrors(e, 'signInAdmin', 'error', true)
    return { success: false, error }
  }
}

async function doLocalAuthentication(): Promise<ServiceResponse<void>> {
  const firstSignInDone = await getFirstSignInDone()
  await AppStore.setLocalAuthenticationEnabled(true)
  await setLocalSignedIn()
  if (firstSignInDone) {
    const registration: Registration = await getCredentials()
    const result = await signIn(registration.email, registration.password)
    return result
  } else {
    await setFirstSignInDone()
  }

  return Promise.resolve({
    success: true,
  })
}

async function signOut(): Promise<void> {
  await AppStore.signOut()
}

async function startOnboardingProcess(): Promise<ServiceResponse<void>> {
  try {
    const identity = await getIdentity()

    const onboardingProcessIdentity: string =
      await api().authentication.getOnboardingProcess(identity)

    await setOnboardingProcessIdentity(onboardingProcessIdentity)
    logger.devInfo(
      'Fetched OnboardingProcessIdentity',
      onboardingProcessIdentity
    )
    return { success: true }
  } catch (e) {
    const error = handleErrors(e, 'startOnboardingProcess')
    return { success: false, error }
  }
}

async function checkVerificationCode(
  smsConfirmationId: string,
  pin: string,
  isTwoFactor?: boolean
): Promise<ServiceResponse<void>> {
  try {
    const identity = await getIdentity()

    const verificationTask = isTwoFactor
      ? api().authentication.checkVerificationCode2FA(smsConfirmationId, pin)
      : api().authentication.checkVerificationCode(
          identity,
          smsConfirmationId,
          pin
        )

    const { success, status, refreshToken } = await verificationTask

    logger.devInfo(
      `checkVerificationCode response with id: ${smsConfirmationId}, pin: ${pin} and success ${success}`
    )

    if (status === 'Expired') {
      logger.error(
        new Error(
          'Sms verification: User ping has expired because of the amount of attemps or idle time'
        ),
        'SmsVerification',
        'fatal'
      )
      return { success, error: CONFLICT }
    }

    if (isTwoFactor && refreshToken) {
      await refresh(refreshToken)
    }

    return { success }
  } catch (e) {
    const error = handleErrors(e, 'checkVerificationCode')
    return { success: false, error }
  }
}

async function sendVerificationSms(
  smsConfirmationId: string
): Promise<ServiceResponse<void>> {
  try {
    const identity = await getIdentity()
    const success = await api().authentication.sendVerificationsCode(
      identity,
      smsConfirmationId
    )
    logger.devInfo(
      `sendVerificationSms response with id: ${smsConfirmationId}, success: ${success}`
    )
    return { success }
  } catch (e) {
    const error = handleErrors(e, 'sendVerificationSms')
    return { success: false, error }
  }
}

async function sendAuthenticationHint(email: string): Promise<void> {
  try {
    await api().authentication.sendAuthenticationHint(email)
  } catch (e) {
    handleErrors(e, 'sendAuthenticationHint')
  }
}

async function setTwoFactorAuth(
  twoFactor: boolean
): Promise<ServiceResponse<TwoFactorResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().authentication.setTwoFactorAuth(
      identity,
      twoFactor
    )
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'setTwoFactorAuth', 'error')
    return { success: false, error }
  }
}

async function refresh(refreshToken: string): Promise<ServiceResponse<void>> {
  try {
    const { identity, customer }: Login = await api().authentication.refresh(
      refreshToken
    )

    await AppStore.signUserIn(identity, customer)

    const { accessToken } = await getIdentity()

    if (!accessToken || !AppStore.portfolio) {
      return {
        success: false,
        error: UNEXPECTED,
      }
    }

    return { success: true }
  } catch (e) {
    const error = handleErrors(e, 'refresh', 'error')

    return { success: false, error }
  }
}

/*
VR 20.5.2021: Can be used for debugging.
*/
async function invalidateAccessToken() {
  const iden = await getIdentity()
  await setIdentity({
    ...iden,
    accessToken: iden.accessToken + 'asd',
    refreshToken: iden.accessToken + 'asd',
    expiresOn: iden.expiresOn - 1000000,
  })
}

async function refreshAccessToken(
  skipRefresh?: boolean,
  debugInvalidate?: boolean
): Promise<ServiceResponse<string>> {
  if (debugInvalidate) await invalidateAccessToken()
  try {
    const { refreshToken, expiresOn } = await getIdentity()
    if (
      refreshToken &&
      expiresOn &&
      nowInSeconds() > expiresOn &&
      !skipRefresh
    ) {
      const identity = await api().authentication.refreshAccessToken(
        refreshToken
      )
      if (!identity.accessToken) {
        return {
          success: false,
          error: UNEXPECTED,
        }
      }

      await setIdentity(identity)

      return { success: true, response: identity.accessToken }
    }

    return { success: false }
  } catch (e) {
    /* 
    VR 20.5.2021:
    UnauthorizedError means the refresh token has expired.
    In this case, user will be logged out and Sentry logging 
    will be skipped.
    */
    if (e instanceof UnauthorizedError) {
      e.skipSentry = true
      throw e
    } else {
      const error = handleErrors(e, 'refreshAccessToken', 'error', true)
      return { success: false, error }
    }
  }
}

async function changeUsername(
  email: string
): Promise<ServiceResponse<SmsResponse>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().authentication.changeUsername(
      identity.accessToken,
      AppStore.customerId,
      email
    )
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'changeUsername')
    return { success: false, error }
  }
}

async function changePasswordSimple(
  changePasswordRequest: ChangePassword
): Promise<ServiceResponse<string>> {
  try {
    const identity: Identity = await getIdentity()
    const response = await api().authentication.changePasswordSimple(
      identity.accessToken,
      AppStore.customerId,
      changePasswordRequest
    )
    return { success: true, response }
  } catch (e) {
    const error = handleErrors(e, 'changePasswordSimple')
    return { success: false, error }
  }
}

export const authentication = {
  checkVerificationCode,
  startOnboardingProcess,
  doLocalAuthentication,
  doRegistration,
  doRegistrationExisting,
  resetInfo,
  changePassword,
  changeForgottenPassword,
  signIn,
  signInAdmin,
  signOut,
  sendVerificationSms,
  sendAuthenticationHint,
  setTwoFactorAuth,
  refresh,
  refreshAccessToken,
  changeUsername,
  changePasswordSimple,
}
