import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { UiLinkProps } from './UiLinkProps'
import Fonts from '@taaleri/core/src/constants/Fonts'
import styled, { css } from 'styled-components'
import { IconExternalLink } from './IconExternalLink'

const Link = styled.a<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${Fonts.avenirNextMedium};
  color: ${Colors.primary};
  ${(props) =>
    props.fullWidth &&
    css`
      display: flex;
      flex: 1;
      justify-content: space-between;
    `}
`

const UiLink: React.FC<UiLinkProps> = ({
  url,
  text,
  style,
  hideIcon,
  target,
  fullWidth,
}) => (
  <View style={[styles.linkContainer, style]}>
    <Link href={url} target={target || '_self'} fullWidth={fullWidth}>
      <span>{text || url}</span>
      {!hideIcon && <IconExternalLink style={{ marginLeft: Spacings.S8 }} />}
    </Link>
  </View>
)

export default UiLink

const styles = StyleSheet.create({
  linkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
