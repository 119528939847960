import React from 'react'
import { StyleSheet } from 'react-native'
import { TextDefault } from './text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { IconInfoReverse } from './Icons'
import FlexRow from './FlexRow'
interface Props {
  text: string
}
export default function ErrorCard({ text }: Props) {
  return (
    <FlexRow style={styles.container}>
      <IconInfoReverse />
      <TextDefault type="h6" style={styles.text}>
        {text}
      </TextDefault>
    </FlexRow>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: Spacings.PADDING,
    backgroundColor: Colors.error,
    borderRadius: BorderRadius.default,
  },
  text: {
    color: Colors.white,
    marginLeft: Spacings.S16,
  },
})
