import Colors from '@taaleri/core/src/constants/Colors'
import * as React from 'react'
import { View, Text, TouchableOpacity, Animated, Easing } from 'react-native'

import UiTouchable from '../UiTouchable'

interface AccordionProps {
  title: string
  defaultOpen: boolean
  children: JSX.Element
  onOpen: () => void
  onClose: () => void
  close?: boolean
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(props.defaultOpen)
  const [close, setClose] = React.useState<boolean>(!props.defaultOpen)
  const [clicked, setClicked] = React.useState<boolean>(false)

  const spinValue = new Animated.Value(0)

  // First set up animation
  Animated.timing(spinValue, {
    toValue: 1,
    duration: 300,
    easing: Easing.linear, // Easing is an additional import from react-native
    useNativeDriver: true, // To make use of native driver for performance
  }).start()

  // Next, interpolate beginning and end values (in this case 0 and 1)
  const spinUp = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['90deg', '-90deg'],
  })

  const spinDown = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['-90deg', '90deg'],
  })

  React.useEffect(() => {
    if (open) {
      props.onOpen()
    }
  }, [open])

  React.useEffect(() => {
    if (close) {
      props.onClose()
    }
  }, [close])

  React.useEffect(() => {
    if (props.close !== undefined) {
      if (props.close === true) {
        setOpen(false)
      }
    }
  }, [props.close])

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 800,
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 20,
        borderColor: Colors.border,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <UiTouchable
          style={{
            backgroundColor: Colors.backgroundGray,
            flex: 1,
          }}
          onPress={() => {
            setClicked(true)
            setClose(open)
            setOpen(!open)
          }}
        >
          <Text
            style={{
              color: Colors.text,
              margin: 0,
              padding: 0,
              fontWeight: 'bold',
            }}
          >
            {props.title}
          </Text>
        </UiTouchable>
        <TouchableOpacity
          style={{
            backgroundColor: Colors.backgroundGray,
          }}
          onPress={() => {
            setClicked(true)
            setOpen(!open)
          }}
        >
          <Animated.Text
            style={{
              fontWeight: 'bold',
              transform: [
                {
                  rotate: open
                    ? clicked
                      ? spinUp
                      : '-90deg'
                    : clicked
                    ? spinDown
                    : '90deg',
                },
              ],
            }}
          >
            {'>'}
          </Animated.Text>
        </TouchableOpacity>
      </View>

      {open && <View style={{ paddingTop: 5 }}>{props.children}</View>}
    </View>
  )
}

export default Accordion
