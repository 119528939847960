import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { AppPadding } from 'screens/app/AppPadding'
import { Content } from 'screens/app/Content'
import styled from 'styled-components'

export const GradientBackground = styled.div`
  background: linear-gradient(
    180deg,
    rgba(174, 205, 200, 0.3) 0%,
    ${Colors.background} 100%
  );
  flex: 1;
`

export default function GradientContainer({
  children,
  width,
}: {
  children: JSX.Element
  width?: number
}) {
  return (
    <GradientBackground>
      <AppPadding>
        <Content marginTop={true} gridWidth={width}>
          {children}
        </Content>
      </AppPadding>
    </GradientBackground>
  )
}
