import IImpactApi from './IImpactApi'
import { get } from './rest'
import { getConfig } from '../config'
import { Identity } from '../models/Identity'
import { ImpactResponse, SdgResponse } from '../models/ImpactProduct'

async function getCustomerImpact(
  identity: Identity,
  customerId: number
): Promise<ImpactResponse> {
  const { accessToken } = identity
  return get(`${getConfig().apiUrl}/Impact/customer/${customerId}`, accessToken)
}

async function getCustomerSdgProducts(
  identity: Identity,
  customerId: number
): Promise<SdgResponse> {
  const { accessToken } = identity
  return get(
    `${getConfig().apiUrl}/Impact/customer/${customerId}/holdings`,
    accessToken
  )
}

export const impactApi: IImpactApi = {
  getCustomerImpact,
  getCustomerSdgProducts,
}
