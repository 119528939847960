import React from 'react'
import ReportFiltersMobile from './ReportFiltersMobile'
import ReportFiltersDesktop from './ReportFiltersDesktop'

export interface RestrictionProps {
  restrictionOpen: boolean
  setRestrictionOpen: (value: boolean) => void
  portfolioOpen: boolean
  setPortfolioOpen: (value: boolean) => void
  dateOpen: boolean
  setDateOpen: (value: boolean) => void
}

interface Props extends RestrictionProps {
  isMobile: boolean
}

export function ReportFilters(props: Props) {
  return props.isMobile ? (
    <ReportFiltersMobile {...{ ...props }} />
  ) : (
    <ReportFiltersDesktop {...{ ...props }} />
  )
}
