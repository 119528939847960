import {
  ReportOrder,
  reportSubscriptionsDefault,
  REPORT_DELIVERY_FIRST_DAY_OF_MONTH,
} from '@taaleri/core/src/models/ReportOrders'
import AppStore from '@taaleri/core/src/stores/AppStore'

export function reportOrderNew(): ReportOrder {
  return {
    customerId: String(AppStore.customer.customerId),
    delivery: 'EveryThreeMonths',
    deliveryDay: REPORT_DELIVERY_FIRST_DAY_OF_MONTH,
    deliveryMonth: null,
    message: '',
    targetCustomerId: String(AppStore.customerId),
    receivers: [
      {
        email: '',
        token: '',
      },
    ],
    subscriptions: reportSubscriptionsDefault(),
    selectedReports: [],
    addDelay: false,
    format: 'Pdf',
    level: 'Aggregated',
  }
}
