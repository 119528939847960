import React from 'react'
import SuccessMessage from '../../components/SuccessMessage'
import { useTranslation } from 'react-i18next'

function RecurringOrderCancelDone() {
  const { t } = useTranslation()
  return (
    <SuccessMessage title={t('invest.monthly-saving-plan-assignment-ended')} />
  )
}

export default RecurringOrderCancelDone
