import React, { useEffect } from 'react'

import { PaddingHorizontal } from '../../ui/PaddingHorizontal'

import Title from '../../ui/text/Title'

import { Formik, FormikHelpers } from 'formik'

import { customerFormValidationSchema } from './customerFormValidationSchema'

import { Customer } from '@taaleri/core/src/models/Customer'

import QuestionStore from '@taaleri/core/src/stores/QuestionStore'

import { Dimensions, StyleSheet, View } from 'react-native'

import { BoxWithPaddingMargin } from '../../ui/box/Box'

import { UiTextInputForm } from '../../ui/form/UiTextInput'

import CountryLink from '../../ui/form/CountryLink'

import UiListItemSwitch from '../../ui/form/UiListItemSwitch'

import Spacings from '@taaleri/core/src/constants/Spacings'

import SlideDown from '../../ui/animation/SlideDown'

import { CheckListSingleForm } from '../../ui/form/CheckListSingle'
import UiForm from '../../ui/form/UiForm'

import {
  EDUCATION_OPTIONS,
  WORK_STATUS_OPTIONS,
} from '@taaleri/core/src/constants/Options'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { observer } from 'mobx-react'
import ChangePhoneNumberModal from './ChangePhoneNumberModal'
import { ButtonActionWithErrors } from '../../ui/UiButton'
import { nextContractRoute } from '../questions/questions'
import {
  ROUTE_CONTRACT_CUSTOMER_FORM,
  ROUTE_CONTRACT_ACCOUNT_NUMBER,
} from '../contract/ContractRoutes'
import {
  nextOnboardingRoute,
  ROUTE_ONBOARDING_CUSTOMER_FORM,
} from './OnboardingRoutes'
import { customers } from '@taaleri/core/src/services/customers'
import { isWeb } from '../../constants/Platforms'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { DiscountCodeInput } from './DiscountCodeInput'
import { useTranslation } from 'react-i18next'
import { setLanguage } from '@taaleri/core/src/i18n'

interface Props {
  isOnboarding: boolean
  navigate: (value: string) => void
}

interface FormValues extends Customer {
  discountCode: string
}

function CustomerForm(props: Props) {
  const { t } = useTranslation()
  const { isOnboarding } = props
  useEffect(() => {
    const find = async () => {
      await customers.getTupasCustomer()
    }

    if (isOnboarding) {
      find()
    }
    setLanguage(AppStore.currentLanguage)
  }, [])

  if (!QuestionStore.customerMaybe) {
    return (
      <LoadingIndicator
        style={{ minHeight: isWeb ? 0 : Dimensions.get('screen').height }}
      />
    )
  }
  const customer = QuestionStore.customer
  const initialValues: FormValues = {
    ...customer,
    discountCode: QuestionStore.discountCode,
  }
  const isContractUpdate = !isOnboarding
  const noPhoneNumber =
    !initialValues.phone ||
    (initialValues.phone && initialValues.phone.length === 0)

  return (
    <>
      <PaddingHorizontal>
        <Title>{t('questions.onboardingCustomerForm.checkinfo')}</Title>

        <Formik
          initialValues={initialValues}
          validationSchema={customerFormValidationSchema(t)}
          onSubmit={async (
            values: FormValues,
            actions: FormikHelpers<FormValues>
          ) => {
            actions.setSubmitting(true)
            QuestionStore.customerMaybe = values
            QuestionStore.setDiscountCode(values.discountCode)
            props.navigate(
              isContractUpdate
                ? nextContractRoute(
                    AppStore.activeCustomer.bankAccountNumber &&
                      AppStore.activeCustomer.bankAccountNumber.length > 0
                      ? ROUTE_CONTRACT_ACCOUNT_NUMBER
                      : ROUTE_CONTRACT_CUSTOMER_FORM
                  )
                : nextOnboardingRoute(ROUTE_ONBOARDING_CUSTOMER_FORM)
            )
          }}
        >
          {({ handleSubmit, errors, values, touched }) => (
            <UiForm>
              <BoxWithPaddingMargin
                title={`${t('questions.onboardingCustomerForm.header')}`}
                titleStyle={styles.titleMargin}
              >
                <UiTextInputForm
                  editable={false}
                  label={`${t('form.name')}`}
                  name="fullName"
                  type="name"
                />
                <UiTextInputForm
                  editable={false}
                  label={`${t('form.personal-id')}`}
                  name="identityNumber"
                  type="name"
                />

                <CountryLink
                  name="countryOfBirth"
                  title={`${t('form.birth-country')}`}
                  editable={
                    initialValues.countryOfBirth && isContractUpdate
                      ? false
                      : true
                  }
                />
                <CountryLink
                  name="nationality"
                  title={`${t('form.nationality')}`}
                  containerStyle={{ marginBottom: 0 }}
                />
              </BoxWithPaddingMargin>

              <BoxWithPaddingMargin
                title={`${t('profile.contactSection.title')}`}
                titleStyle={styles.titleMargin}
              >
                {noPhoneNumber || !isContractUpdate ? (
                  <UiTextInputForm
                    name="phone"
                    label={`${t('form.phone-number')}`}
                    type="digits"
                    requiredNotFilled={true}
                    returnKeyType="done"
                    infoLabel={infoTexts.phone}
                  />
                ) : (
                  <ChangePhoneNumberModal>
                    <UiTextInputForm
                      name="phone"
                      label={`${t('form.phone-number')}`}
                      type="digits"
                      requiredNotFilled={true}
                      editable={false}
                      pointerEvents="none"
                      value={initialValues.phone}
                      returnKeyType="done"
                      infoLabel={infoTexts.phone}
                    />
                  </ChangePhoneNumberModal>
                )}

                <UiTextInputForm
                  name="email"
                  label={`${t('email')}`}
                  type="email"
                  infoLabel={infoTexts.email}
                />
                <UiTextInputForm
                  name="address.street"
                  label={`${t('form.address')}`}
                  type="name"
                  infoLabel={infoTexts.address}
                />
                <UiTextInputForm
                  name="address.postalCode"
                  label={`${t('form.post-number')}`}
                  type="digits"
                  maxLength={5}
                  returnKeyType="done"
                />
                <UiTextInputForm
                  name="address.city"
                  label={`${t('form.post-city')}`}
                  type="name"
                />
                <CountryLink
                  name="address.country"
                  title={`${t('form.country')}`}
                  style={{ marginBottom: 0 }}
                />

                <UiListItemSwitch
                  name="isPostAddress"
                  title={`${t('form.address-different')}`}
                  topDivider={false}
                  bottomDivider={false}
                  containerStyle={{ paddingHorizontal: 0 }}
                />
                {values.isPostAddress && (
                  <View style={{ borderTopWidth: 0, paddingTop: Spacings.S8 }}>
                    <SlideDown>
                      <UiTextInputForm
                        name="postAddress.street"
                        label={`${t('form.post-address')}`}
                        type="name"
                        clearButtonMode="while-editing"
                      />
                      <UiTextInputForm
                        name="postAddress.postalCode"
                        label={`${t('form.post-number')}`}
                        type="digits"
                        maxLength={5}
                        clearButtonMode="while-editing"
                        returnKeyType="done"
                      />
                      <UiTextInputForm
                        name="postAddress.city"
                        label={`${t('form.post-city')}`}
                        type="name"
                        clearButtonMode="while-editing"
                      />

                      <CountryLink
                        name="postAddress.country"
                        title={`${t('form.country')}`}
                        style={{ marginBottom: 0 }}
                      />
                    </SlideDown>
                  </View>
                )}
              </BoxWithPaddingMargin>

              <BoxWithPaddingMargin
                title={`${t('form.education.title')}`}
                titleStyle={styles.cardTitleNoMargin}
              >
                <CheckListSingleForm
                  name="levelOfEducation"
                  options={EDUCATION_OPTIONS}
                  lastBottomDivider={true}
                />
              </BoxWithPaddingMargin>

              <BoxWithPaddingMargin
                title={`${t('form.sosioeconomicStatus.title')}`}
                titleStyle={styles.titleMargin}
              >
                <UiTextInputForm
                  name="profession"
                  label={`${t('form.sosioeconomicStatus.profession')}`}
                  type="name"
                  requiredNotFilled={true}
                  containerStyle={{ marginBottom: 0 }}
                  returnKeyType="done"
                />

                <CheckListSingleForm
                  name="socioeconomicStatus"
                  options={WORK_STATUS_OPTIONS}
                  lastBottomDivider={true}
                />
              </BoxWithPaddingMargin>

              <BoxWithPaddingMargin
                title={`${t(
                  'questions.onboardingCustomerForm.marketing-allowance'
                )}`}
                titleStyle={styles.cardTitleNoMargin}
              >
                <UiListItemSwitch
                  name="marketingAllowance"
                  title={`${t(
                    'questions.onboardingCustomerForm.marketing-allowance-yes'
                  )}`}
                  topDivider={false}
                  bottomDivider={false}
                />
                {isOnboarding && (
                  <DiscountCodeInput
                    discountCode={values.discountCode}
                    containerStyle={{
                      marginTop: Spacings.S16,
                    }}
                  />
                )}
              </BoxWithPaddingMargin>
              {/* <BoxWithPaddingMargin
                title="Testi"
                titleStyle={styles.cardTitleNoMargin}
              >
                <MultiCountry 
                 name='testi'></MultiCountry>
              </BoxWithPaddingMargin> */}

              <ButtonActionWithErrors
                title={t('button.next')}
                onPress={isWeb ? () => '' : handleSubmit}
                errors={errors}
                touched={touched}
              />
            </UiForm>
          )}
        </Formik>
      </PaddingHorizontal>
    </>
  )
}

export default observer(CustomerForm)

const styles = StyleSheet.create({
  cardTitleNoMargin: {
    marginBottom: 0,
  },
  titleMargin: {
    marginBottom: Spacings.S24,
  },
})

const infoTexts = {
  address: [
    {
      text: 'questions.customersForm.address-info',
    },
  ],
  phone: [
    {
      text: 'questions.customersForm.phone-info',
    },
  ],
  email: [
    {
      text: 'questions.customersForm.email-info',
    },
  ],
}
