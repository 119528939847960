import React from 'react'
import { View } from 'react-native'
import { TextMedium, TextBold } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'

import useLayout from '../../ui/useLayout'

export function CurrentValue({
  label,
  value,
}: {
  label: string
  value: string
}) {
  const { isNonSmall } = useLayout()
  return (
    <View style={{ flexDirection: isNonSmall ? 'row' : 'column' }}>
      <TextMedium
        style={{ marginRight: Spacings.S16, marginBottom: Spacings.S8 }}
      >
        {label}
      </TextMedium>
      <TextBold>{value}</TextBold>
    </View>
  )
}
