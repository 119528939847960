import {
  findAnswerById,
  QUESTION_DAILY_IMPORTANCE,
  QUESTION_DEBT,
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  QUESTION_INVESTMENT_ASSETS,
  QUESTION_INVESTMENT_GOAL,
  QUESTION_INVESTMENT_HORIZONT,
  QUESTION_INVESTOR_EXPERIENCE,
  QUESTION_LOSS_TOLERANCE,
  QUESTION_LOSS_TOLERANCE_BEHAVIOR,
  QUESTION_PHYSICAL_ASSETS,
  QUESTION_SHARE_TO_TAALERI,
  QUESTION_ESG_START,
  QUESTION_ESG_ENVIRONMENT,
  QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
  QUESTION_ESG_SFDR,
  questionsWithAnswers,
  QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
  QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
  QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
  QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
  QUESTION_MONTHLY_CASH_DEPOSITS,
  QUESTION_MONTHLY_CASH_WITHDRAWALS,
} from '@taaleri/components/src/screens/questions/questions'

import { BasicContract } from '../../models/Contract'

import {
  BasicInfo,
  BasicInfoOnboarding,
  BasicInfoQuestions,
  Customer,
  emptyAddress,
  PrivateMonetaryTransactions,
} from '../../models/Customer'

import {
  AccountPurpose,
  AssetSource,
  CapitalOption,
  IntendedUseOfCash,
  PurposeOfForeignMoneyTransfers,
} from '../../constants/Options'
import { FINLAND } from '../../models/Countries'
import { Portfolio } from '../../models/Portfolio'
import { RegulatoryQuestions } from '../../models/RequlatoryQuestions'
import {
  portfolioToRoundedMarketValue,
  portfolioToRoundedMarketValueDrop,
} from '@taaleri/components/src/screens/questions/portfolioMarketValue'
import QuestionStore from '../../stores/QuestionStore'
import { toProductKnowledge } from './toProductKnowledge'
import { Answers, Question } from '../../models/Question'
import { t } from 'i18next'

function toBasicQuestions(questions: Question[]): BasicInfoQuestions {
  const otherAssets = findAnswerById(questions, QUESTION_PHYSICAL_ASSETS)
  const savings = findAnswerById(questions, QUESTION_INVESTMENT_ASSETS)
  const impactOnLife = findAnswerById(questions, QUESTION_DAILY_IMPORTANCE)
  const totalDebt = findAnswerById(questions, QUESTION_DEBT)
  const investorExperience = findAnswerById(
    questions,
    QUESTION_INVESTOR_EXPERIENCE
  )
  const investmentGoal = findAnswerById(questions, QUESTION_INVESTMENT_GOAL)
  const investmentHorizon = findAnswerById(
    questions,
    QUESTION_INVESTMENT_HORIZONT
  )
  const negativeReaction = findAnswerById(questions, QUESTION_LOSS_TOLERANCE)
  const lossReaction = findAnswerById(
    questions,
    QUESTION_LOSS_TOLERANCE_BEHAVIOR
  )
  const illiquidOptions = findAnswerById(
    questions,
    QUESTION_ILLIQUID_ASSETS_ALLOWED
  )

  const portfolioShare = findAnswerById(questions, QUESTION_SHARE_TO_TAALERI)
  const {
    knowledgeCapitalInvestments,
    knowledgeCapitalSecuredStructuredProducts,
    knowledgeCombinationFunds,
    knowledgeCommodities,
    knowledgeComplexDebt,
    knowledgeDiscretionaryAssetManagement,
    knowledgeEquities,
    knowledgeFixedIncome,
    knowledgeLeveragedSecurities,
    knowledgeMoneyMarketFunds,
    knowledgeUnsecuredStructuredProducts,
  } = toProductKnowledge(questions)

  const esgStart =
    findAnswerById(questions, QUESTION_ESG_START) === 'Yes' ? true : false
  let esgTaxonomy: string | null = findAnswerById(
    questions,
    QUESTION_ESG_ENVIRONMENT
  )
  let esgSfdr: string | null = findAnswerById(questions, QUESTION_ESG_SFDR)
  let esgHarmfulEmissionEffect: string | null = findAnswerById(
    questions,
    QUESTION_ESG_HARMFUL_EMISSION_EFFECTS
  )

  if (!esgStart) {
    esgTaxonomy = null
    esgSfdr = null
    esgHarmfulEmissionEffect = null
  }

  const hasMonthlyMoneyTransfersWithinFinland =
    findAnswerById(questions, QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND) ===
    'Yes'
      ? true
      : false

  const monthlyIncomingMoneyTransfersWithinFinland = findAnswerById(
    questions,
    QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND
  )

  const monthlyOutgoingMoneyTransfersWithinFinland = findAnswerById(
    questions,
    QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND
  )

  const hasMonthlyForeignMoneyTransfers =
    findAnswerById(questions, QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS) ===
    'Yes'
      ? true
      : false

  const monthlyIncomingForeignMoneyTransfers = findAnswerById(
    questions,
    QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS
  )

  const monthlyOutgoingForeignMoneyTransfers = findAnswerById(
    questions,
    QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS
  )

  const hasMonthlyCashTransactions =
    findAnswerById(questions, QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS) === 'Yes'
      ? true
      : false

  const monthlyCashDeposits = findAnswerById(
    questions,
    QUESTION_MONTHLY_CASH_DEPOSITS
  )

  const monthlyCashWithdrawals = findAnswerById(
    questions,
    QUESTION_MONTHLY_CASH_WITHDRAWALS
  )

  const questionAnswers: BasicInfoQuestions = {
    monthlyCapitalIncome: QuestionStore.monthlyCapitalIncome,
    illiquidOptions,
    impactOnLife,
    investmentGoal,
    investorExperience,
    investmentHorizon,
    lossReaction,
    monthlyExpenditure: QuestionStore.monthlyExpenses,
    monthlyIncome: QuestionStore.monthlyIncome,
    negativeReaction,
    otherAssets,
    savings,
    portfolioShare,
    totalDebt,
    knowledgeCapitalInvestments,
    knowledgeCapitalSecuredStructuredProducts,
    knowledgeCombinationFunds,
    knowledgeCommodities,
    knowledgeComplexDebt,
    knowledgeDiscretionaryAssetManagement,
    knowledgeEquities,
    knowledgeFixedIncome,
    knowledgeLeveragedSecurities,
    knowledgeMoneyMarketFunds,
    knowledgeUnsecuredStructuredProducts,
    esgStart,
    esgTaxonomy,
    esgSfdr,
    esgHarmfulEmissionEffect,
    capitalIncome: fillCapitalIncome(QuestionStore.monthlyCapitalIncome),
    hasMonthlyMoneyTransfersWithinFinland,
    monthlyIncomingMoneyTransfersWithinFinland,
    monthlyOutgoingMoneyTransfersWithinFinland,
    hasMonthlyForeignMoneyTransfers,
    monthlyIncomingForeignMoneyTransfers,
    monthlyOutgoingForeignMoneyTransfers,
    hasMonthlyCashTransactions,
    monthlyCashDeposits,
    monthlyCashWithdrawals,
  }

  return questionAnswers
}

export function toBasicContract(
  answers: Answers,
  customer: Customer,
  regulatoryQuestions: RegulatoryQuestions,
  portfolio?: Portfolio,
  riskLevel?: number,
  strategyIdentifier?: string,
  discountCode?: string
): BasicContract {
  const {
    address,
    bankAccountNumber,
    countryOfBirth,
    customerId,
    email,
    isPostAddress,
    socioeconomicStatus,
    levelOfEducation,
    marketingAllowance,
    nationality,
    profession,
    phone,
    postAddress,
  } = customer

  const {
    accountPurpose,
    accountPurposeOther,
    isPep,
    pepConnection,
    pepCountry,
    pepPosition,
    sourceOfFunds,
    sourceOfFundsOtherDescription,
    hasDualCitizenship,
    hasForeignWorkOrResidencePermit,
    dualCitizenshipCountry,
    foreignWorkOrResidencePermitCountry,
    hasForeignPowerOfAttorney,
    foreignPowerOfAttorneyCountry,
    taxationCountries,
    hasUsShareOfEstate,
    hasForeignStreetAddress,
    foreignStreetAddressCountry,
    isAktiaPrimaryBank,
  } = regulatoryQuestions

  const questions = questionsWithAnswers(answers, t) as Question[]

  const postAddressUpdated = isPostAddress
    ? postAddress || emptyAddress
    : emptyAddress
  const customerIdString = String(customerId)

  const extraTaxCountries =
    taxationCountries.length > 3
      ? taxationCountries
          .slice(3)
          .map((t) => t.country)
          .join(',')
      : null
  const extraTins =
    taxationCountries.length > 3
      ? taxationCountries
          .slice(3)
          .map((t) => t.tin)
          .join(',')
      : null

  const calculatedPortfolioValue = portfolioToRoundedMarketValue(portfolio)
  const calculatedPortfolioValueAfterLoss =
    portfolioToRoundedMarketValueDrop(portfolio)

  const questionAnswers: BasicInfoQuestions = toBasicQuestions(questions)

  const privateCustomerMonetaryTransactionsInAktia: PrivateMonetaryTransactions =
    {
      hasMonthlyMoneyTransfersWithinFinland:
        questionAnswers.hasMonthlyMoneyTransfersWithinFinland,
      monthlyIncomingMoneyTransfersWithinFinland:
        questionAnswers.monthlyIncomingMoneyTransfersWithinFinland,
      monthlyOutgoingMoneyTransfersWithinFinland:
        questionAnswers.monthlyOutgoingMoneyTransfersWithinFinland,
      hasMonthlyForeignMoneyTransfers:
        questionAnswers.hasMonthlyForeignMoneyTransfers,
      monthlyIncomingForeignMoneyTransfers:
        questionAnswers.monthlyIncomingForeignMoneyTransfers,
      monthlyOutgoingForeignMoneyTransfers:
        questionAnswers.monthlyOutgoingForeignMoneyTransfers,
      monthlyForeignMoneyTransfersCountries:
        QuestionStore.monthlyForeignMoneyTransfersCountry.map((c) => c.code),
      purposeOfForeignMoneyTransfers:
        QuestionStore.purposeOfForeignMoneyTransfers,
      purposeOfForeignMoneyTransfersOther:
        QuestionStore.purposeOfForeignMoneyTransfers.includes(
          PurposeOfForeignMoneyTransfers.Other
        )
          ? QuestionStore.purposeOfForeignMoneyTransfersOther
          : '',
      hasMonthlyCashTransactions: questionAnswers.hasMonthlyCashTransactions,
      monthlyCashDeposits: questionAnswers.monthlyCashDeposits,
      monthlyCashWithdrawals: questionAnswers.monthlyCashWithdrawals,
      intendedUseOfCash: QuestionStore.intendedUseOfCash,
      intendedUseOfCashOther: QuestionStore.intendedUseOfCash.includes(
        IntendedUseOfCash.Other
      )
        ? QuestionStore.intendedUseOfCashOther
        : '',
    }

  const basicInfo: BasicInfo = {
    ...questionAnswers,
    isAktiaMainBank: isAktiaPrimaryBank === 'Yes' ? true : false,
    accountPurpose,
    accountPurposeDescription:
      accountPurpose === AccountPurpose.Other ? accountPurposeOther : null,
    bankAccountNumber,
    calculatedPortfolioValue,
    calculatedPortfolioValueAfterLoss,
    customerId: customerIdString,
    dualNationalityCountry: hasDualCitizenship ? dualCitizenshipCountry : null,
    levelOfEducation: levelOfEducation,
    socioeconomicStatus: socioeconomicStatus,
    extraTaxCountries,
    extraTins,
    foreignPostalServiceCountry: hasForeignStreetAddress
      ? foreignStreetAddressCountry
      : null,
    foreignPowerOfAttorneyCountry: hasForeignPowerOfAttorney
      ? foreignPowerOfAttorneyCountry
      : null,
    hasDualNationality: hasDualCitizenship,
    hasForeignPostalService: hasForeignStreetAddress,
    hasForeignPowerOfAttorney,
    hasUsEstate: hasUsShareOfEstate,
    hasWorkOrResidencePermit: hasForeignWorkOrResidencePermit,
    isPep: isPep,
    pepCountry: isPep ? pepCountry : null,
    pepPosition: isPep ? pepPosition : null,
    profession,
    pepConnection: pepConnection,
    sourceOfFundsBenefits: sourceOfFunds.includes(AssetSource.benefits),
    sourceOfFundsExit: sourceOfFunds.includes(AssetSource.acquisition),
    sourceOfFundsGift: sourceOfFunds.includes(AssetSource.gift),
    sourceOfFundsIncome: sourceOfFunds.includes(AssetSource.earnedIncome),
    sourceOfFundsInheritance: sourceOfFunds.includes(AssetSource.inheritance),
    sourceOfFundsInvesting: sourceOfFunds.includes(AssetSource.investing),
    sourceOfFundsInvestments: false,
    sourceOfFundsOther: sourceOfFunds.includes(AssetSource.other),
    sourceOfFundsOtherDescription: sourceOfFunds.includes(AssetSource.other)
      ? sourceOfFundsOtherDescription
      : null,
    sourceOfFundsPension: sourceOfFunds.includes(AssetSource.pension),
    sourceOfFundsRealEstate: sourceOfFunds.includes(AssetSource.property),
    taxCountry1:
      taxationCountries.length > 0 ? taxationCountries[0].country : null,
    taxCountry2:
      taxationCountries.length > 1 ? taxationCountries[1].country : null,
    taxCountry3:
      taxationCountries.length > 2 ? taxationCountries[2].country : null,
    tin1:
      taxationCountries.length > 0 &&
      taxationCountries[0].country !== FINLAND.code
        ? taxationCountries[0].tin
        : null,
    tin2:
      taxationCountries.length > 1 &&
      taxationCountries[1].country !== FINLAND.code
        ? taxationCountries[1].tin
        : null,
    tin3:
      taxationCountries.length > 2 &&
      taxationCountries[2].country !== FINLAND.code
        ? taxationCountries[2].tin
        : null,

    workOrResidencePermitCountry: hasForeignWorkOrResidencePermit
      ? foreignWorkOrResidencePermitCountry
      : null,
    privateCustomerMonetaryTransactionsInAktia,
  }

  const basicContract: BasicContract = {
    basicInfo,
    customer: {
      address: {
        city: address != null ? address.city : '',
        country: address != null ? address.country : '',
        postalCode: address != null ? address.postalCode : '',
        street: address != null ? address.street : '',
      },
      countryOfBirth,
      customerId: customerIdString,
      email,
      fullName: null,
      isMarketingPermitted: marketingAllowance,
      nationality,
      phoneNumber: phone,
      postalAddress: {
        city: postAddressUpdated.city,
        country: postAddressUpdated.country,
        postalCode: postAddressUpdated.postalCode,
        street: postAddressUpdated.street,
      },
      ssnBic: null,
    },
    customerId: customerIdString,
    riskLevel,
    strategyIdentifier,
    discountCode,
  }

  return basicContract
}

export function toBasicInfoOnboarding(answers: Answers): BasicInfoOnboarding {
  const questions = questionsWithAnswers(answers, t) as Question[]
  const questionAnswers: BasicInfoQuestions = toBasicQuestions(questions)
  return {
    ...questionAnswers,
    age: QuestionStore.age,
    initialInvestment: QuestionStore.initialInvestment,
    monthlyInvestment: QuestionStore.monthlyInvestment,
    withImpact: QuestionStore.isImpact,
  }
}

export function fillCapitalIncome(monthlyCapitalIncome: number): string {
  const calculatedCapitalIncome = monthlyCapitalIncome * 12

  if (calculatedCapitalIncome === 0) {
    return CapitalOption.None
  } else if (calculatedCapitalIncome < 10000) {
    return CapitalOption.LessThan10000
  } else if (calculatedCapitalIncome < 50000) {
    return CapitalOption.LessThan50000
  } else if (calculatedCapitalIncome < 100000) {
    return CapitalOption.LessThan100000
  } else if (calculatedCapitalIncome >= 100000) {
    return CapitalOption.MoreThan100000
  }

  return ''
}
