import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import * as React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import FlexRow from '../../ui/FlexRow'
import { IconInfo } from '../../ui/Icons'
import Box from '../../ui/box/Box'
import { TextDefault } from '../../ui/text/UiText'

interface DisclaimerBoxProps {
  content: string[]
  style?: StyleProp<ViewStyle>
}

const DisclaimerBox: React.FC<DisclaimerBoxProps> = (props) => {
  return (
    <Box style={[props.style, { padding: Spacings.S20 }]}>
      {props.content.map((text, index) => (
        <FlexRow style={{ marginBottom: 10 }} key={'disclaimer-' + index}>
          <IconInfo
            color={Colors.gray40}
            style={{ paddingTop: Spacings.S10 }}
            width={20}
            height={20}
          />
          <TextDefault
            type="t2"
            style={{
              marginLeft: Spacings.S10,
              paddingRight: Spacings.S40,
              marginVertical: 'auto',
            }}
          >
            {text}
          </TextDefault>
        </FlexRow>
      ))}
    </Box>
  )
}

export default DisclaimerBox
