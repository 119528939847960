import styled from 'styled-components'

const ImpactIntroGradient = styled.div`
  background: linear-gradient(
      180deg,
      rgba(174, 205, 200, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      359.13deg,
      rgba(174, 205, 200, 0.1) 0.5%,
      rgba(174, 205, 200, 0) 99.54%
    ),
    #ffffff;
`

export default ImpactIntroGradient
