import FilterButton from '@taaleri/components/src/screens/report/FilterButton'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import {
  IconRemoveCircle,
  IconCheckmarkCircleSmall,
  IconCircleSmall,
} from '@taaleri/components/src/ui/Icons'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import { fiKey } from '@taaleri/core/src/i18n'
import { ReportingPortfolioTypeToName } from '@taaleri/core/src/models/ReportingPortfolio'
import AppStore from '@taaleri/core/src/stores/AppStore'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { ToggleProps, FilterBox } from './ReportFiltersDesktop'

function PortfolioFilter({ onToggle, isMobile }: ToggleProps) {
  const { t } = useTranslation()
  const reportingPortfolioTypes = ReportStore.reportingPortfolioTypes
  return (
    <FilterBox>
      {reportingPortfolioTypes.map((reportingPortfolioType, index) => {
        const reportingPortfolios = ReportStore.reportingPortfoliosByType(
          reportingPortfolioType
        )
        const selectedCpunt = ReportStore.selectedReportingPortfoliosByType(
          reportingPortfolioType
        ).length
        return (
          <View
            key={reportingPortfolioType}
            style={{
              marginBottom: index < reportingPortfolioTypes.length - 1 ? 24 : 0,
            }}
          >
            <FlexRow style={{ alignItems: 'center', marginBottom: 10 }}>
              <TextDefault
                type="h6"
                style={{ marginRight: 8, display: 'flex' }}
              >
                {t(ReportingPortfolioTypeToName(reportingPortfolioType))}{' '}
                {selectedCpunt}/{reportingPortfolios.length}
              </TextDefault>
              {selectedCpunt > 0 && (
                <UiTouchable
                  onPress={() => {
                    if (selectedCpunt > 0) {
                      ReportStore.unSelectAllReportingPortfoliosByType(
                        reportingPortfolioType
                      )
                    } else {
                      ReportStore.selectAllReportingPortfoliosByType(
                        reportingPortfolioType
                      )
                    }
                  }}
                >
                  <IconRemoveCircle />
                </UiTouchable>
              )}
            </FlexRow>
            <FlexRow style={{ flexWrap: 'wrap', marginTop: -10 }}>
              {reportingPortfolios.map((reportingPortfolio) => {
                const selected = ReportStore.isPortofolioSelected(
                  reportingPortfolio.id
                )

                const getPortfolioName = () => {
                  return AppStore.currentLanguage === fiKey
                    ? reportingPortfolio.name
                    : reportingPortfolio.nameSv
                }

                return (
                  <FilterButton
                    key={reportingPortfolio.id}
                    selected={selected}
                    onPress={() =>
                      ReportStore.toggleReportingPortfolio(
                        reportingPortfolio.id
                      )
                    }
                  >
                    {selected && <IconCheckmarkCircleSmall />}
                    {!selected && <IconCircleSmall />}
                    <TextDefault
                      type="t2"
                      style={{
                        marginLeft: 10,
                        color: selected ? Colors.text : Colors.gray50,
                      }}
                    >
                      {getPortfolioName()}
                    </TextDefault>
                  </FilterButton>
                )
              })}
            </FlexRow>
          </View>
        )
      })}
      <FlexRow
        style={{
          marginTop: 12,
          flexWrap: 'wrap',
        }}
      >
        <UiButton
          size="small"
          title={`${t('button.confirm-changes')}`}
          type={
            ReportStore.selectedReportingPortfoliosList.length === 0
              ? 'disabled'
              : 'primary'
          }
          containerStyle={{
            marginRight: isMobile ? 0 : 24,
            marginTop: 12,
            width: isMobile ? '100%' : 'auto',
          }}
          disabled={ReportStore.selectedReportingPortfoliosList.length === 0}
          onPress={() => {
            ReportStore.resetRestrictionFilters()
            ReportStore.fetchData()
            onToggle(false)
          }}
        />
        {ReportStore.selectedReportingPortfoliosList.length > 0 && (
          <UiButton
            size="small"
            type="secondary"
            title={`${t('button.deselect-all')}`}
            containerStyle={{
              marginRight: isMobile ? 0 : 24,
              marginTop: 12,
              width: isMobile ? '100%' : 'auto',
            }}
            onPress={ReportStore.unSelectAllReportingPortfolios}
          />
        )}
        {!ReportStore.allReportingPortfoliosSelected && (
          <UiButton
            size="small"
            type="secondary"
            title={`${t('button.choose-all')}`}
            onPress={ReportStore.selectAllReportingPortfolios}
            containerStyle={{
              marginTop: 12,
              width: isMobile ? '100%' : 'auto',
            }}
          />
        )}
      </FlexRow>
    </FilterBox>
  )
}

export default observer(PortfolioFilter)
