import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import QuestionStartScreen from 'screens/questions/QuestionStartScreen'
import FirstProposalScreen from 'screens/questions/FirstProposalScreen'
import QuestionAgeScreen from 'screens/questions/QuestionAgeScreen'

import {
  ROUTE_QUESTION_AGE,
  ROUTE_QUESTION_FIRST_PROPOSAL,
  ROUTE_QUESTION_AMOUNT,
  ROUTE_QUESTION_FINANCE,
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
  ROUTE_QUESTION_DAILY_IMPORTANCE_INFO,
  ROUTE_QUESTION_EXISTING_CUSTOMER,
  ROUTE_QUESTION_INTRO,
  ROUTE_QUESTION_PORTFOLIO_TYPE,
  ROUTE_QUESTION_ETF_COMPARISON,
  ROUTE_QUESTION_EXISTING_AUTHORIZATION,
  ROUTE_QUESTION_ESG_SUITABILITY_WARNING,
  ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER,
  ROUTE_QUESTION_CAPITAL_INCOME,
  ROUTE_QUESTION_CASH_PURPOSE
} from '@taaleri/components/src/screens/questions/QuestionRoutes'
import AmountScreen from './AmountScreen'
import QuestionFinanceScreen from './QuestionFinanceScreen'
import InvestmentProposalScreen from './InvestmentProposalScreen'
import ToInvestmentProposalScreen from './ToInvestmentProposalScreen'
import { rehydrateQuestionStore } from '@taaleri/core/src/stores/QuestionStore'
import {
  ROUTE_ONBOARDING_REGISTER,
  ROUTE_ONBOARDING_CUSTOMER_FORM,
  ROUTE_ONBOARDING_REQULATORY_QUESTIONS,
  ROUTE_ONBOARDING_ACCOUNT_NUMBER,
  ROUTE_ONBOARDING_CONTRACTS,
  ROUTE_ONBOARDING_CONTRACTS_VERIFICATION,
  ROUTE_ONBOARDING_CREATING_CUSTOMER,
  ROUTE_ONBOARDING_PHOTO_ID,
  ROUTE_ONBOARDING_IDENTITY_VALIDITY,
  ROUTE_ONBOARDING_PAYMENT_SUM,
  ROUTE_ONBOARDING_PAYMENT_BANK,
  ROUTE_ONBOARDING_FURTHER_CLARIFICATION,
  ROUTE_ONBOARDING_TRANSACTION,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import RegisterScreen from 'screens/onboarding/RegisterScreen'
import CustomerFormScreen from 'screens/onboarding/CustomerFormScreen'
import RequlatoryQuestionScreen from 'screens/onboarding/RegulatoryQuestionScreen'
import AccountNumberScreen from 'screens/onboarding/AccountNumberScreen'
import ContractsScreen from 'screens/onboarding/ContractsScreen'
import OnboardingVerificationScreen from 'screens/onboarding/OnboardingVerificationScreen'
import CreatingCustomerScreen from 'screens/onboarding/CreatingCustomerScreen'
import PhotoIdScreen from 'screens/onboarding/PhotoIdScreen'
import IdentityValidityScreen from 'screens/onboarding/IdentityValidityScreen'
import OnboardingPaymentSumScreen from 'screens/onboarding/OnboardingPaymentSumScreen'
import OnboardingComparisonScreen from './OnboardingComparisonScreen'
import OnboardingBankScreen from 'screens/onboarding/OnboardingBankScreen'
import OptionQuestionByParamScreen from './OptionQuestionByParamScreen'
import DailyImportanceInfo from './DailyImportanceInfo'
import { FurtherClarificationScreen } from 'screens/onboarding/FurtherClarificationScreen'
import { ExistingCustomerScreen } from './ExistingCustomerScreen'
import { ExistingAuthorizationScreen } from './ExistingAuthorizationScreen'
import QuestionIntroScreen from './QuestionIntroScreen'
import QuestionPortfolioTypeScreen from './QuestionPortfolioTypeScreen'
import OnboardingTransactionScreen from 'screens/onboarding/OnboardingTransactionScreen'
import EsgSuitabilityWarning from './EsgSuitabilityWarning'
import QuestionForeignMoneyTransferScreen from './QuestionForeignMoneyTransferScreen'
import QuestionCapitalIncomeScreen from './QuestionCapitalIncomeScreen'
import QuestionCashPurposeScreen from './QuestionCashPurposeScreen'

function QuestionRouter() {
  const [loading, setLoading] = useState<boolean>(true)

  const match = useRouteMatch()

  useEffect(() => {
    const load = async () => {
      await rehydrateQuestionStore()
      setLoading(false)
    }
    load()
  }, [])

  if (loading) {
    return null
  }

  function toPath(path: string) {
    return match.path + '/' + path
  }

  return (
    <Switch>
      <Route path={toPath('')} exact={true}>
        <QuestionStartScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_AGE)}>
        <QuestionAgeScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_FIRST_PROPOSAL)} exact={true}>
        <FirstProposalScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_INTRO)} exact={true}>
        <QuestionIntroScreen />
      </Route>

      <Route path={toPath(ROUTE_QUESTION_AMOUNT)} exact={true}>
        <AmountScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_PORTFOLIO_TYPE)} exact={true}>
        <QuestionPortfolioTypeScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_FINANCE)} exact={true}>
        <QuestionFinanceScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_INVESTMENT_PROPOSAL)} exact={true}>
        <ToInvestmentProposalScreen />
      </Route>
      <Route
        path={toPath(ROUTE_QUESTION_INVESTMENT_PROPOSAL) + 'Show'}
        exact={true}
      >
        <InvestmentProposalScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_REGISTER)} exact={true}>
        <RegisterScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_CUSTOMER_FORM)} exact={true}>
        <CustomerFormScreen isOnboarding={true} />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_REQULATORY_QUESTIONS)} exact={true}>
        <RequlatoryQuestionScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_ACCOUNT_NUMBER)} exact={true}>
        <AccountNumberScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_CONTRACTS)} exact={true}>
        <ContractsScreen />
      </Route>
      <Route
        path={toPath(ROUTE_ONBOARDING_CONTRACTS_VERIFICATION)}
        exact={true}
      >
        <OnboardingVerificationScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_CREATING_CUSTOMER)} exact={true}>
        <CreatingCustomerScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_PHOTO_ID)} exact={true}>
        <PhotoIdScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_IDENTITY_VALIDITY)} exact={true}>
        <IdentityValidityScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_PAYMENT_SUM)} exact={true}>
        <OnboardingPaymentSumScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_PAYMENT_BANK)} exact={true}>
        <OnboardingBankScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_TRANSACTION)} exact={true}>
        <OnboardingTransactionScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_DAILY_IMPORTANCE_INFO)} exact={true}>
        <DailyImportanceInfo />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_ETF_COMPARISON)} exact={true}>
        <OnboardingComparisonScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_EXISTING_CUSTOMER)} exact={true}>
        <ExistingCustomerScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_EXISTING_AUTHORIZATION)} exact={true}>
        <ExistingAuthorizationScreen />
      </Route>
      <Route path={toPath(ROUTE_ONBOARDING_FURTHER_CLARIFICATION)} exact={true}>
        <FurtherClarificationScreen />
      </Route>

      <Route path={toPath(ROUTE_ONBOARDING_FURTHER_CLARIFICATION)} exact={true}>
        <FurtherClarificationScreen />
      </Route>

      <Route path={toPath(ROUTE_QUESTION_ESG_SUITABILITY_WARNING)} exact={true}>
        <EsgSuitabilityWarning />
      </Route>

      <Route path={toPath(ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER)} exact={true}>
        <QuestionForeignMoneyTransferScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_CAPITAL_INCOME)}>
        <QuestionCapitalIncomeScreen />
      </Route>
      <Route path={toPath(ROUTE_QUESTION_CASH_PURPOSE)}>
        <QuestionCashPurposeScreen />
      </Route>

      <Route path={match.path + '/:questionId'} exact={true}>
        <OptionQuestionByParamScreen />
      </Route>
    </Switch>
  )
}

export default QuestionRouter
