import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import ReportRow from './ReportRow'
import {
  formatCurrency,
  formatDateWithoutLeadingZeros,
} from '@taaleri/core/src/utils/format'
import Colors from '@taaleri/core/src/constants/Colors'
import { ProfitReport } from '@taaleri/core/src/models/ProfitReport'
import { getProfitReport } from '@taaleri/core/src/services/reports'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ReportLoadingBox } from './ReportLoadingBox'
import ReportBoxLarge from './ReportBoxLarge'
import { ROUTE_REPORT_PROFIT } from './ReportRoutes'
import useNavigation from '../../navigation/useNavigation'
import { useTranslation } from 'react-i18next'

function getTextColor(value: number): string {
  if (value > 0) {
    return Colors.textPrimary
  } else if (value < 0) {
    return Colors.error
  } else {
    return Colors.text
  }
}

function ReportTaxBox() {
  const [profitReport, setProfitReport] = useState<ProfitReport>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()

  async function getProfits() {
    setError(false)
    setLoading(true)
    // Use January 1st 2019 as start date in test environment
    const result = await getProfitReport(AppStore.customerId)
    if (result.success) {
      setProfitReport(result.response)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    getProfits()
  }, [AppStore.customerId])

  if (loading) {
    return <ReportLoadingBox />
  }

  if (error || !profitReport) {
    return <ErrorWithRetry onRetry={getProfits} />
  }

  const {
    startDate,
    endDate,
    capitalGain,
    dividends,
    capitalDistributions,
    interest,
  } = profitReport

  return (
    <ReportBoxLarge
      title={t('reportBox.withrawed-earnings')}
      subtitle={`${t('reportBox.from')} ${formatDateWithoutLeadingZeros(
        startDate
      )}-${formatDateWithoutLeadingZeros(endDate)} `}
      onButtonPress={() => navigateApp(ROUTE_REPORT_PROFIT)}
      buttonTitle={`${t('show-more')}`}
    >
      <ReportRow
        label={t('reportBox.gains-losses')}
        value={formatCurrency(capitalGain, true)}
        topBorder={true}
        valueStyle={{
          color: getTextColor(capitalGain),
        }}
      />
      <ReportRow
        label={t('interest')}
        value={formatCurrency(interest, true)}
        valueStyle={{
          color: getTextColor(interest),
        }}
      />
      <ReportRow
        label={t('reportBox.profits')}
        value={formatCurrency(dividends, true)}
        valueStyle={{ color: getTextColor(dividends) }}
      />
      {capitalDistributions > 0 && (
        <ReportRow
          label={t('reportBox.returns')}
          value={formatCurrency(capitalDistributions, true)}
          valueStyle={{
            color: getTextColor(capitalDistributions),
          }}
        />
      )}
    </ReportBoxLarge>
  )
}

export default observer(ReportTaxBox)
