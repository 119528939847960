import Spacings from '@taaleri/core/src/constants/Spacings'
import { ContentItem } from '@taaleri/core/src/models/Feed'
import flatten from 'lodash/flatten'
import marked from 'marked'
import React from 'react'
import { View, Image, Dimensions, StyleSheet } from 'react-native'

import { HtmlWebView } from '../../components/HtmlWebView'
import { isWeb } from '../../constants/Platforms'
import FlexRow from '../../ui/FlexRow'
import UiLink from '../../ui/UiLink'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextMedium, TextDefault } from '../../ui/text/UiText'

const paddingHorizontal = Spacings.S24

function toList(bullets: string[], bulletFunction: (index: number) => string) {
  return bullets.map((b, index) => (
    <FlexRow key={b} style={styles.bulletedList}>
      <TextMedium style={styles.bullet}>{bulletFunction(index)}</TextMedium>
      <TextMedium style={styles.bulletItem}>{b}</TextMedium>
    </FlexRow>
  ))
}

function toImage(image: string) {
  const width = Dimensions.get('window').width - 2 * paddingHorizontal
  return (
    <Image
      width={width}
      source={{
        uri: image,
      }}
    />
  )
}

export function shortenedContent(content: string, length: number) {
  // Remove html tags
  const stripped = content.replace(/<[^>]*>?/gm, '')
  const nextSpace = stripped.indexOf(' ', length)
  const formatted = stripped.substr(0, nextSpace)
  return stripped.length > length ? formatted + '...' : stripped
}

export function formatContent(
  contentItems: ContentItem[],
  width: number,
  height: number
) {
  return contentItems.map((contentItem, index) => {
    const { text, bulletedList, numberedList, image, subHeading, link, table } =
      contentItem

    return (
      <View key={index}>
        {subHeading != null && (
          <TextDefault
            type="h3"
            style={{
              marginBottom: Spacings.S16,
              marginTop: Spacings.S8,
            }}
          >
            {subHeading}
          </TextDefault>
        )}
        {link == null && text != null && (
          <>
            <Paragraph style={[styles.section, styles.text]}>
              {isWeb && (
                <>
                  {/* <Paragraph>{text}</Paragraph> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(
                        text
                          .replaceAll(
                            '<img src="',
                            '<img width="580" src="https://www.aktia.fi'
                          )
                          .replaceAll('href="/', 'href="https://www.aktia.fi/')
                          .replaceAll('href', 'target="_blank" href')
                      ),
                    }}
                  />
                </>
              )}
            </Paragraph>

            {!isWeb && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: height * 2,
                }}
              >
                {/* <Paragraph>{text}</Paragraph> */}
                <HtmlWebView width={width} html={text} />
              </View>
            )}
          </>
        )}
        {bulletedList != null && (
          <View style={styles.section}>
            {toList(bulletedList, () => '\u2022')}
          </View>
        )}
        {numberedList != null && (
          <View style={styles.section}>
            {toList(numberedList, (idx: number) => String(idx + 1) + '.')}
          </View>
        )}
        {image != null && <View style={styles.section}>{toImage(image)}</View>}
        {link != null && <UiLink style={styles.link} url={link} text={text} />}
        {table && (
          <Paragraph>
            {flatten(
              table.map((t) =>
                t.tableBody.map((b) =>
                  b.tableRow.map((r) => r.cellData.replace(/\t/g, '').trim())
                )
              )
            )}
          </Paragraph>
        )}
      </View>
    )
  })
}

const styles = StyleSheet.create({
  bulletedList: {
    marginBottom: 4,
    alignItems: 'baseline',
    lineHeight: 24,
  },
  bullet: {
    alignSelf: 'flex-start',
    lineHeight: 24,
  },
  bulletItem: {
    flex: 1,
    paddingLeft: 8,
    lineHeight: 24,
  },
  section: {
    marginBottom: Spacings.S16,
  },
  text: {
    lineHeight: 24,
  },
  link: {
    marginVertical: Spacings.S8,
  },
})
