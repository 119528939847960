import React from 'react'
import { View, StyleSheet } from 'react-native'

import { Title3 } from '../../ui/text/Title'
import UiButton from '../../ui/UiButton'
import useNavigation from '../../navigation/useNavigation'
import { ROUTE_FUNDS_INVEST_START } from '@taaleri/components/src/screens/funds/FundsRoutes'
import LoadingIndicator from '../../ui/LoadingIndicator'
import { Paragraph } from '../../ui/text/Paragraph'
import UiLink from '../../ui/UiLink'
import Spacings from '@taaleri/core/src/constants/Spacings'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useLayout from '../../ui/useLayout'
import { TextDefault } from '../../ui/text/UiText'
import AssetClassDistribution from '../portfolio/AssetClassDistribution'
import Box, { BoxWithPadding } from '../../ui/box/Box'
import { useFund } from './useFunds'
import { formatFundProfit } from '@taaleri/core/src/utils/format'
import { PaddingHorizontal } from '../../ui/PaddingHorizontal'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { getFundImageSource } from '@taaleri/core/src/constants/AssetClass'
import FundPerformanceTable from './FundPerformanceTable'
import ListItem from '../../ui/ListItem'
import Fonts from '@taaleri/core/src/constants/Fonts'
import GlobalStyles from '../../constants/GlobalStyles'
import HeaderImage from '../portfolio/HeaderImage'
import FundChart from './FundChart'
import FundHeaderBox from './FundHeaderBox'
import { ROUTE_PORTFOLIO_PAYMENT_SELECT } from '../portfolio/PortfolioRoutes'
import BlockedCustomerWarning from './BlockedCustomerWarning'
import AccountManagerSection from '../profile/AccountManagerSection'
import { ROUTE_IDENTITY_CARD_PHOTO_ID } from '../identitycard/IdentityCardRoutes'
import { isWeb } from '../../constants/Platforms'
import { useTranslation } from 'react-i18next'
import { fiKey } from '@taaleri/core/src/i18n'

interface Props {
  fundId: string
}

export function MobilePadding(props: any) {
  const { isSmall } = useLayout()
  return isSmall || !isWeb ? (
    <PaddingHorizontal>{props.children}</PaddingHorizontal>
  ) : (
    props.children
  )
}

export default function FundDetailContent({ fundId }: Props) {
  const { t } = useTranslation()
  const { navigateApp } = useNavigation()
  const { isNonSmall } = useLayout()
  const { fund, error, retry } = useFund(fundId)
  if (error) {
    return <ErrorWithRetry onRetry={retry} />
  }

  if (!fund) {
    return <LoadingIndicator />
  }

  function invest() {
    AppStore.fundId = fundId
    AppStore.investmentMode = 'fund'
    AppStore.paymentType = 'default'
    AppStore.newInvestment = 0
    if (!AppStore.identityCardUpdated) {
      return navigateApp(ROUTE_IDENTITY_CARD_PHOTO_ID)
    } else if (fund && fund.isAvailableForSubscription) {
      navigateApp(ROUTE_PORTFOLIO_PAYMENT_SELECT)
    } else {
      AppStore.paymentType = 'onetime'
      navigateApp(ROUTE_FUNDS_INVEST_START)
    }
  }

  const marginBottom = isNonSmall ? Spacings.S56 : Spacings.S40

  const history = fund.marketValueTimeSeries || []

  function InvestButton({ buttonId }: { buttonId: string }) {
    return AppStore.isCustomerBlocked ? null : (
      <UiButton
        containerStyle={[
          { marginBottom },
          isNonSmall && { alignSelf: 'center', minWidth: 327 },
        ]}
        title={`${t('button.invest')}`}
        onPress={invest}
        id={buttonId}
      />
    )
  }

  const showDistribution = fund.assetClassDistribution.length > 0
  const showFundManagers = fund.fundManagers.length > 0
  const { managementFee, performanceFee, subscriptionFee, redemptionFee } =
    fund.fundFeeInformation
  const { fundPerformanceHistorySummary } = fund

  // Dirty hack for hiding TTKIINTAO redemption fee
  const hideRedemptionFee = fund?.ticker === 'TTKIINTAO'

  const getShortDescription = (): string =>
    (AppStore.currentLanguage === fiKey
      ? fund.shortDescription
      : fund.shortDescriptionSv) ?? ''

  const getDescription = (): string =>
    (AppStore.currentLanguage === fiKey
      ? fund.description
      : fund.descriptionSv) ?? ''

  const getName = (): string =>
    (AppStore.currentLanguage === fiKey ? fund.name : fund.nameSv) ?? ''

  return (
    <View>
      <HeaderImage title={getName()} imageSource={getFundImageSource(fund)} />

      <View
        style={{
          marginHorizontal: isWeb && isNonSmall ? 'auto' : 0,
          minWidth: isNonSmall && isWeb ? 640 : 0,
          maxWidth: isWeb ? 640 : '100%',
        }}
      >
        <MobilePadding>
          <FundHeaderBox fund={fund} />

          <TextDefault
            type="h2"
            style={{ marginTop: Spacings.S40, marginBottom: Spacings.S24 }}
          >
            {getShortDescription()}
          </TextDefault>
          <Paragraph>{getDescription()}</Paragraph>
          {fund.riskLevel ? (
            <Paragraph>
              {t('app.calculator.risklevel').toUpperCase()} {fund.riskLevel}/7
            </Paragraph>
          ) : null}
          {/* <InvestButton buttonId="fundInvestmentButtonTop" /> */}
          <BlockedCustomerWarning />
          <View>
            {history.length > 0 && (
              <FundChart history={history} containerStyle={{ marginBottom }} />
            )}
            <FundPerformanceTable
              fundPerformanceHistorySummary={fundPerformanceHistorySummary}
              containerStyle={{ marginBottom }}
            />
          </View>
          {showDistribution && (
            <View style={{ marginBottom }}>
              <Title3>{t('fund.distribution-title')}</Title3>
              <BoxWithPadding>
                <AssetClassDistribution
                  namePercentages={fund.assetClassDistribution}
                  decimals={2}
                />
              </BoxWithPadding>
            </View>
          )}
          <View style={{ marginBottom }}>
            <Title3>{t('fund.fee-title')}</Title3>
            <Box>
              {managementFee ? (
                <ListItem
                  topDivider={false}
                  title={`${t('fund.managementfee').toUpperCase()}`}
                  textStyle={GlobalStyles.smallText}
                  titleRightStyle={styles.bold}
                  titleRight={formatFundProfit(managementFee)}
                />
              ) : null}
              {performanceFee ? (
                <ListItem
                  topDivider={false}
                  textStyle={GlobalStyles.smallText}
                  title={`${t('fund.performancefee').toUpperCase()}`}
                  titleRightStyle={styles.bold}
                  titleRight={formatFundProfit(performanceFee)}
                />
              ) : null}
              <ListItem
                topDivider={false}
                textStyle={GlobalStyles.smallText}
                title={`${t('fund.subscriptionfee').toUpperCase()}`}
                titleRightStyle={styles.bold}
                titleRight={formatFundProfit(subscriptionFee)}
              />
              {hideRedemptionFee || (
                <ListItem
                  topDivider={false}
                  textStyle={GlobalStyles.smallText}
                  title={`${t('fund.redemptionfee').toUpperCase()}`}
                  titleRightStyle={styles.bold}
                  titleRight={formatFundProfit(redemptionFee)}
                />
              )}
            </Box>
            {hideRedemptionFee && (
              <TextDefault type="t4" style={{ marginTop: Spacings.S24 }}>
                *{t('fund.fee-description')}
              </TextDefault>
            )}
          </View>
          {showFundManagers ? (
            <Title3>{t('fund.managers-title')}</Title3>
          ) : null}
        </MobilePadding>
        {showFundManagers && (
          <View style={{ marginBottom: Spacings.S16 }}>
            {fund.fundManagers.map((manager) => {
              if (!manager) {
                return null
              }
              return (
                <AccountManagerSection
                  accountManager={manager}
                  key={manager.email}
                />
              )
            })}
          </View>
        )}
        <MobilePadding>
          {fund.documentLinks &&
            fund.documentLinks.map((link) => (
              <UiLink
                key={link.title}
                text={link.title}
                url={link.url}
                style={{ marginBottom: Spacings.S8 }}
              />
            ))}
          <View style={{ marginBottom }} />
          {/* <InvestButton buttonId="fundInvestmentButtonBottom" /> */}
        </MobilePadding>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontFamily: Fonts.avenirNextBold,
  },
})
