import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { PercentagePieItem } from '@taaleri/core/src/models/assetClassColors'
import React from 'react'
import { View } from 'react-native'
import PercentagePieAsset from '@taaleri/components/src/components/PercentagePieAsset'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { BoxWithPadding } from '@taaleri/components/src/ui/box/Box'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { RestrictionProps } from './ReportFilters'
import { TextMedium } from '@taaleri/components/src/ui/text/UiText'
import {
  formatCurrencyNoDecimal,
  toFullPercentageText,
} from '@taaleri/core/src/utils/format'
import UiTouchable from '@taaleri/components/src/ui/UiTouchable'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { useTranslation } from 'react-i18next'

interface Props {
  percentages: PercentagePieItem[]
  showPie: boolean
  loading: boolean
}

export default function PortfolioPieReport({
  percentages,
  showPie,
  restrictionOpen,
  setRestrictionOpen,
  setDateOpen,
  setPortfolioOpen,
  loading,
}: Props & RestrictionProps) {
  const lineHeight = FontSize.S14
  const { t } = useTranslation()

  const colorScale = percentages.map((p) => p.color)
  const PieContainer = !restrictionOpen ? UiTouchable : FlexRow
  return (
    <BoxWithPadding
      style={{
        flex: 1,
      }}
      title={`${t('portfolio.distribution')}`}
      titleStyle={{ marginHorizontal: -20, paddingLeft: 20 }}
    >
      {loading ? (
        <LoadingIndicator type="inline" />
      ) : (
        <View style={{ flex: 1 }}>
          {showPie && (
            <PieContainer
              style={{
                flexDirection: 'row',
                marginVertical: 'auto',
                paddingTop: Spacings.S24,
                paddingBottom: Spacings.S48,
                justifyContent: 'center',
              }}
              onPress={() => {
                setDateOpen(false)
                setPortfolioOpen(false)
                setRestrictionOpen(true)
              }}
            >
              <PercentagePieAsset
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}
                assetClasses={percentages}
                percentage={0}
                size={192}
                colorScale={colorScale}
              />
            </PieContainer>
          )}
          <div
            style={{
              maxHeight: showPie ? 'initial' : '380px',
              overflowY: showPie ? 'hidden' : 'auto',
            }}
          >
            <View
              style={{
                justifyContent: 'flex-end',
                marginTop: showPie ? 0 : Spacings.S16,
              }}
            >
              {percentages.map((percentage) => {
                return (
                  <FlexRow
                    key={percentage.id}
                    style={{
                      paddingTop: 7,
                      alignItems: 'center',
                      marginBottom: showPie ? 0 : 17,
                    }}
                  >
                    {showPie && (
                      <View style={{ width: 22 }}>
                        <View
                          style={{
                            width: 10,
                            height: 10,
                            marginTop: isWeb ? 0 : -1,
                            marginRight: 12,
                            backgroundColor: percentage.color,
                            borderRadius: 5,
                          }}
                        />
                      </View>
                    )}
                    <TextMedium
                      style={{
                        flex: 1,
                        fontSize: FontSize.S14,
                        lineHeight,
                      }}
                      numberOfLines={1}
                    >
                      {t(percentage.name)}
                    </TextMedium>
                    {showPie && (
                      <TextMedium
                        size={FontSize.S14}
                        style={{
                          textAlign: 'right',
                          fontSize: FontSize.S14,
                          lineHeight,
                          marginLeft: Spacings.S2,
                        }}
                      >
                        {formatCurrencyNoDecimal(percentage.marketValue)}
                      </TextMedium>
                    )}
                    {percentage.percentage < 100 && (
                      <TextMedium
                        style={{
                          marginLeft: Spacings.S16,
                          width: 48,
                          textAlign: 'right',
                          paddingLeft: Spacings.S8,
                          borderLeftColor: Colors.border,
                          borderLeftWidth: 1,
                          fontSize: FontSize.S14,
                          lineHeight,
                        }}
                      >{`${toFullPercentageText(
                        percentage.percentage
                      )} %`}</TextMedium>
                    )}
                  </FlexRow>
                )
              })}
            </View>
          </div>
        </View>
      )}
    </BoxWithPadding>
  )
}
