import React from 'react'
import styled from 'styled-components'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Colors from '@taaleri/core/src/constants/Colors'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'
import { Content } from './Content'
import { Link } from 'react-router-dom'
import routePath from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'
import PropsChildren from '@taaleri/components/src/components/PropsChildren'
import { AktiaLogo } from '@taaleri/components/src/ui/AktiaLogo'
import { AktiaLogoContainer, SmallAktiaLogoContainer } from './HeaderLarge'

const Container = styled.div`
  display: flex;
  padding: ${Spacings.S16}px 0;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-bottom: 1px solid ${Colors.border};
  background-color: ${Colors.white};
  @media (min-width: ${BreakPoint.NAV}px) {
    display: none;
  }
`

export default function HeaderSmallContainer(props: PropsChildren) {
  return (
    <Container>
      <Content padding={true}>
        <SmallAktiaLogoContainer>
          <AktiaLogoContainer>
            <Link to={routePath(ROUTE_APP)}>
              <AktiaLogo />
            </Link>
          </AktiaLogoContainer>
          {props.children}
        </SmallAktiaLogoContainer>
      </Content>
    </Container>
  )
}
