import { View } from 'react-native'
import Svg, {
  G,
  Path,
  Rect,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg'
import React from 'react'
import { IconProps } from '../IconProps'

export const IconUpdateSmall: React.FC<IconProps> = ({
  style,
  width,
  color,
}) => {
  const size = width || 44
  return (
    <View style={style}>
      <Svg width={size} height={size} viewBox="0 0 44 44">
        <Defs>
          <LinearGradient
            x1="50%"
            y1="100%"
            x2="50%"
            y2="0%"
            id="linearGradient-1"
          >
            <Stop stopColor="#00AADA" offset="0%" />
            <Stop stopColor="#2BC9AF" offset="100%" />
          </LinearGradient>
        </Defs>
        <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <G
            id="id-paivitys-nosto"
            transform="translate(-42.000000, -439.000000)"
          >
            <G id="Overlay" transform="translate(24.000000, 410.000000)">
              <G
                id="icon/icon_update_big"
                transform="translate(16.000000, 27.000000)"
              >
                <G>
                  <Rect
                    id="Rectangle"
                    fillRule="nonzero"
                    x="0"
                    y="0"
                    width={48}
                    height={48}
                  />
                  <Path
                    d="M22.502925,11.25 L24.756675,11.25 L24.756675,23.69925 L35.857425,30.49875 L34.633425,32.226 L22.502925,25.1205 L22.502925,11.25 Z M45.75,17.25 L37.182,17.23875 L40.755,13.49025 C37.077,9 32.1975,4.5 24,4.5 C13.24725,4.5 4.5,13.24725 4.5,24 C4.5,34.75275 13.24725,43.5 24,43.5 C34.75275,43.5 43.5,34.75275 43.5,24 L45.75,24 C45.75,35.9925 35.9925,45.75 24,45.75 C12.0075,45.75 2.25,35.9925 2.25,24 C2.25,12.0075 12.0075,2.25 24,2.25 C33.03675,2.25 38.52825,7.275 42.318,11.85075 L45.75,8.25 L45.75,17.25 Z"
                    id="Combined-Shape"
                    fill={color || 'url(#linearGradient-1)'}
                  />
                </G>
              </G>
            </G>
          </G>
        </G>
      </Svg>
    </View>
  )
}

export const IconUpdate: React.FC<IconProps> = ({ style }) => {
  const boxSize = 64
  const rectSize = 64
  return (
    <View style={style}>
      <Svg
        width={boxSize}
        height={boxSize}
        viewBox={`0 0 ${boxSize} ${boxSize}`}
      >
        <Defs>
          <LinearGradient
            id="linearGradient-1"
            x1="50%"
            y1="100%"
            x2="50%"
            y2="0%"
          >
            <Stop stopColor="#00AADA" offset="0%" />
            <Stop stopColor="#2BC9AF" offset="100%" />
          </LinearGradient>
        </Defs>
        <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <G transform="translate(-159.000000, -289.000000)">
            <G transform="translate(24.000000, 246.000000)">
              <G transform="translate(132.000000, 40.000000)">
                <G>
                  <Rect
                    fillRule="nonzero"
                    x="0"
                    y="0"
                    width={rectSize}
                    height={rectSize}
                  />
                  <Path
                    d="M30.0039,15 L33.0089,15 L33.0089,31.599 L47.8099,40.665 L46.1779,42.968 L30.0039,33.494 L30.0039,15 Z M61,23 L49.576,22.985 L54.34,17.987 C49.436,12 42.93,6 32,6 C17.663,6 6,17.663 6,32 C6,46.337 17.663,58 32,58 C46.337,58 58,46.337 58,32 L61,32 C61,47.99 47.99,61 32,61 C16.01,61 3,47.99 3,32 C3,16.01 16.01,3 32,3 C44.049,3 51.371,9.7 56.424,15.801 L61,11 L61,23 Z"
                    fill="url(#linearGradient-1)"
                    fillRule="nonzero"
                  />
                </G>
              </G>
            </G>
          </G>
        </G>
      </Svg>
    </View>
  )
}
