import React from 'react'
import { observer } from 'mobx-react'
import Colors from '@taaleri/core/src/constants/Colors'
import PositionContainer from '../report/PositionContainer'
import FilterButton from '../report/FilterButton'
import { AssetClassIconEmpty } from '../report/AssetClassIcon'
import { TextDefault } from '../../ui/text/UiText'
import ProfitStore from '@taaleri/core/src/stores/ProfitStore'

function InstrumentFilter() {
  const { allInstruments, selectedInstruments } = ProfitStore

  if (allInstruments.length === 0) {
    return null
  }

  return (
    <PositionContainer>
      {allInstruments
        .filter((a) => !selectedInstruments.includes(a))
        .map((instrument) => {
          return (
            <FilterButton
              key={instrument.value}
              onPress={() => ProfitStore.addSelectedInstrument(instrument)}
              selected={false}
            >
              <AssetClassIconEmpty />

              <TextDefault
                type="t2"
                style={{
                  color: Colors.text,
                  letterSpacing: 0.2,
                }}
              >
                {instrument.label}
              </TextDefault>
            </FilterButton>
          )
        })}
    </PositionContainer>
  )
}

export default observer(InstrumentFilter)
