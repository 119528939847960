import React from 'react'

import QuestionStore from '@taaleri/core/src/stores/QuestionStore'

import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_AGE } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import QuestionAgeContent from '@taaleri/components/src/screens/questions/QuestionAgeContent'
import UiSlider from '@taaleri/components/src/ui/form/UiSlider'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

function QuestionAgeScreen() {
  const { t } = useTranslation()
  const nextRoute = nextQuestionRoute(ROUTE_QUESTION_AGE)
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <View>
          <QuestionAgeContent />
          <UiSlider
            min={18}
            max={100}
            initialValue={QuestionStore.age}
            onChange={(value: string) => {
              QuestionStore.setAge(Number(value))
            }}
          />
        </View>

        <Buttons title={t('button.next')} route={nextRoute} />
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default QuestionAgeScreen
