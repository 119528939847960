import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Divider } from '../../ui/Divider'

interface Props {
  style?: StyleProp<ViewStyle>
}

export const TopView: React.FC<Props> = (props) => {
  return (
    <View
      style={[{
        paddingHorizontal: Spacings.PADDING,
        paddingTop: Spacings.PADDING,
      }, props.style]}
    >
      {props.children}
    </View>
  )
}

export const MiddleView: React.FC<Props> = (props) => {
  return (
    <View
      style={[{
        paddingHorizontal: Spacings.PADDING
      }, props.style]}
    >
      {props.children}
    </View>
  )
}

export const BottomView: React.FC<Props> = (props) => {
  return (
    <View
      style={[{
        paddingHorizontal: Spacings.PADDING,
        paddingBottom: Spacings.PADDING,
      }, props.style]}
    >
      {props.children}
    </View>
  )
}

export const SectionDivider: React.FC = () => {
  return (
    <Divider style={{ marginTop: Spacings.S16 }} />
  )
}