import React from 'react'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import {
  ROUTE_PROFILE,
  ROUTE_PROFILE_CHANGE_USERNAME_DONE,
} from '../profile/ProfileRoutes'
import AppStore from '@taaleri/core/src/stores/AppStore'
import useNavigation from '../../navigation/useNavigation'
import SmsVerification from '../contract/SmsVerification'
import { routePathApp } from '../../navigation/routeHelper'
import { useTranslation } from 'react-i18next'

export default function ChangeUsernameVerification() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const title = 'profile.username-verification'
  const description = 'profile.username-change-verification'
  return (
    <SmsVerification
      t={t}
      title={t(title)}
      description={t(description)}
      onSuccess={() => {
        AppStore.activeCustomerMaybe = {
          ...AppStore.activeCustomer,
          email: QuestionStore.email || AppStore.activeCustomer.email,
        }
        navigate(routePathApp(ROUTE_PROFILE_CHANGE_USERNAME_DONE))
      }}
      afterConflictNavigateTo={ROUTE_PROFILE}
      onPrepareVerification={async () => {
        if (AppStore.smsConfirmationId) {
          return AppStore.smsConfirmationId
        }

        return null
      }}
      navigate={navigate}
    />
  )
}
