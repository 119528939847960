import {
  DEBT_OPTIONS,
  DEFAULT_FINANCE_OPTIONS,
  ProductKnowledgeOption,
  EsgKnowledgeOptions,
  MONEY_TRANSFER_OPTIONS,
  YES_NO_OPTIONS,
  MONEY_TRANSFER_WITHOUT_NONE_OPTIONS,
} from '@taaleri/core/src/constants/Options'
import { Portfolio } from '@taaleri/core/src/models/Portfolio'
import {
  Answers,
  OptionQuestion,
  Question,
  Tag,
  TagSelectQuestion,
} from '@taaleri/core/src/models/Question'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { TFunction } from 'i18next'
import indexOf from 'lodash/indexOf'

import {
  ROUTE_CONTRACT_CONFIRM_DIGI,
  ROUTE_CONTRACT_FUND_EXPLANATION,
  ROUTE_CONTRACT_ACCOUNT_NUMBER,
  ROUTE_CONTRACT_CONFIRM,
  ROUTE_CONTRACT_CUSTOMER_FORM,
  ROUTE_CONTRACT_VERIFICATION,
  ROUTE_CONTRACT_RISK,
} from './../contract/ContractRoutes'
import {
  ROUTE_QUESTION_AGE,
  ROUTE_QUESTION_FINANCE,
  ROUTE_QUESTION_FIRST_PROPOSAL,
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
  ROUTE_QUESTION_START,
  ROUTE_QUESTION_DAILY_IMPORTANCE_INFO,
  ROUTE_QUESTION_INTRO,
  ROUTE_QUESTION_PORTFOLIO_TYPE,
  ROUTE_QUESTION_ESG_SUITABILITY_WARNING,
  ROUTE_QUESTION_CAPITAL_INCOME,
  ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER,
  ROUTE_QUESTION_CASH_PURPOSE,
} from './QuestionRoutes'
import {
  lossToleranceQuestionContractUpdate,
  lossToleranceQuestionOnboarding,
} from './lossToleranceQuestion'
import { ROUTE_ONBOARDING_REQULATORY_QUESTIONS } from '../onboarding/OnboardingRoutes'

export const productKnowledgeTags = [
  {
    value: 'knowledgeDiscretionaryAssetManagement',
    label: 'questions.productKnowledge.tag-discretionary',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeEquities',
    label: 'questions.productKnowledge.tag-equities',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeCombinationFunds',
    label: 'questions.productKnowledge.tag-combination-funds',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeFixedIncome',
    label: 'questions.productKnowledge.tag-fixed-income',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeMoneyMarketFunds',
    label: 'questions.productKnowledge.tag-money-market',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeCapitalInvestments',
    label: 'questions.productKnowledge.tag-capital-investment',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeCapitalSecuredStructuredProducts',
    label: 'questions.productKnowledge.tag-capital-secured',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeUnsecuredStructuredProducts',
    label: 'questions.productKnowledge.tag-unsecured',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeLeveragedSecurities',
    label: 'questions.productKnowledge.tag-leveraged',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeComplexDebt',
    label: 'questions.productKnowledge.tag-complex-debt',
    selected: false,
    forced: false,
  },
  {
    value: 'knowledgeCommodities',
    label: 'questions.productKnowledge.tag-commoditites',
    selected: false,
    forced: false,
  },
]

export const QUESTION_PORTFOLIO_TYPE = 'portfolioType'
export const QUESTION_PHYSICAL_ASSETS = 'otherAssets'
export const QUESTION_INVESTMENT_ASSETS = 'investmentAssets'
export const QUESTION_DAILY_IMPORTANCE = 'impactOnDailyLife'
export const QUESTION_DEBT = 'totalDebts'
export const QUESTION_INVESTOR_EXPERIENCE = 'investmentExperience'
export const QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY =
  'discretionaryAssetManagementFamiliarity'
export const QUESTION_INVESTMENT_GOAL = 'investmentGoals'
export const QUESTION_INVESTMENT_HORIZONT = 'investmentHorizon'
export const QUESTION_LOSS_TOLERANCE = 'negativePress'
export const QUESTION_LOSS_TOLERANCE_BEHAVIOR = 'negativeReturn'
export const QUESTION_ILLIQUID_ASSETS_ALLOWED = 'nonLiquidAssets'

export const QUESTION_SHARE_TO_TAALERI = 'totalInvestmentAssetsManagedByTaaleri'
export const QUESTION_PRODUCT_KNOWLEDGE_PART_ONE = 'productKnowledgePartOne'
export const QUESTION_PRODUCT_KNOWLEDGE_PART_TWO = 'productKnowledgePartTwo'
export const QUESTION_ESG_START = 'esgStart'
export const QUESTION_ESG_ENVIRONMENT = 'esgEnvironment'
export const QUESTION_ESG_SFDR = 'esgSfdr'
export const QUESTION_ESG_HARMFUL_EMISSION_EFFECTS = 'esgHarmfulEmission'
export const QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND =
  'hasMonthlyMoneyTransfersWithinFinland'
export const QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND =
  'monthlyIncomingMoneyTransfersWithinFinland'
export const QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND =
  'monthlyOutgoingMoneyTransfersWithinFinland'
export const QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS =
  'hasMonthlyForeignMoneyTransfers'
export const QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS =
  'monthlyIncomingForeignMoneyTransfers'
export const QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS =
  'monthlyOutgoingForeignMoneyTransfers'
export const QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS =
  'hasMonthlyCashTransactions'
export const QUESTION_MONTHLY_CASH_DEPOSITS = 'monthlyCashDeposits'
export const QUESTION_MONTHLY_CASH_WITHDRAWALS = 'monthlyCashWithdrawals'

export const questionsRoutes = () => [
  ROUTE_QUESTION_START,
  ROUTE_QUESTION_AGE,
  QUESTION_INVESTMENT_GOAL,
  ROUTE_QUESTION_PORTFOLIO_TYPE,
  ROUTE_QUESTION_FIRST_PROPOSAL,
  ROUTE_QUESTION_INTRO,
  QUESTION_INVESTOR_EXPERIENCE,
  QUESTION_INVESTMENT_HORIZONT,
  QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
  QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
  QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
  QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
  ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER,
  QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
  QUESTION_MONTHLY_CASH_DEPOSITS,
  QUESTION_MONTHLY_CASH_WITHDRAWALS,
  ROUTE_QUESTION_CASH_PURPOSE,
  ROUTE_QUESTION_FINANCE,
  ROUTE_QUESTION_CAPITAL_INCOME,
  QUESTION_INVESTMENT_ASSETS,
  QUESTION_PHYSICAL_ASSETS,
  QUESTION_DEBT,
  QUESTION_DAILY_IMPORTANCE,
  QUESTION_SHARE_TO_TAALERI,
  QUESTION_LOSS_TOLERANCE,
  QUESTION_LOSS_TOLERANCE_BEHAVIOR,
  QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY,
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  QUESTION_ESG_START,
  QUESTION_ESG_ENVIRONMENT,
  QUESTION_ESG_SFDR,
  QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
]

const contractRoutes = [
  ROUTE_CONTRACT_CUSTOMER_FORM,
  ROUTE_CONTRACT_ACCOUNT_NUMBER,
  ROUTE_ONBOARDING_REQULATORY_QUESTIONS,
  QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
  QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
  QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
  QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
  ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER,
  QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
  QUESTION_MONTHLY_CASH_DEPOSITS,
  QUESTION_MONTHLY_CASH_WITHDRAWALS,
  ROUTE_QUESTION_CASH_PURPOSE,
  ROUTE_QUESTION_FINANCE,
  ROUTE_QUESTION_CAPITAL_INCOME,
  QUESTION_INVESTMENT_ASSETS,
  QUESTION_PHYSICAL_ASSETS,
  QUESTION_DEBT,
  QUESTION_DAILY_IMPORTANCE,
  QUESTION_INVESTOR_EXPERIENCE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
  QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
  QUESTION_INVESTMENT_GOAL,
  QUESTION_INVESTMENT_HORIZONT,
  QUESTION_LOSS_TOLERANCE,
  QUESTION_LOSS_TOLERANCE_BEHAVIOR,
  QUESTION_SHARE_TO_TAALERI,
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  QUESTION_ESG_START,
  QUESTION_ESG_ENVIRONMENT,
  QUESTION_ESG_SFDR,
  QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
  ROUTE_CONTRACT_RISK, // Only for digi
  ROUTE_CONTRACT_CONFIRM,
  ROUTE_CONTRACT_VERIFICATION,
]

const VeryImportant = 'VeryImportant'
const None = 'None'
export const ALL_ALLOWED = 'AllAllowed'

export function showAssetManagementInfo(currentRoute: string): boolean {
  return (
    currentRoute === QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY &&
    QuestionStore.getAnswer(
      QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY
    ) === ProductKnowledgeOption.DoesNotKnow
  )
}

export function showLiquidityInfo(currentRoute: string): boolean {
  return (
    currentRoute === QUESTION_ILLIQUID_ASSETS_ALLOWED &&
    QuestionStore.getAnswer(QUESTION_ILLIQUID_ASSETS_ALLOWED) === None &&
    QuestionStore.isImpact
  )
}

export function showEsgInfoPdfLink(currentRoute: string): boolean {
  if (currentRoute === QUESTION_ESG_START) {
    return true
  }
  return false
}

export function nextQuestionRoute(currentRoute: string, skipTupas = false) {
  const index = indexOf(questionsRoutes(), currentRoute)

  if (showAssetManagementInfo(currentRoute)) {
    return ''
  } else if (
    currentRoute === QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND) ===
      'No'
  ) {
    return QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS
  } else if (
    currentRoute === QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS) ===
      'No'
  ) {
    return QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS
  } else if (
    currentRoute === QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS) === 'No'
  ) {
    return ROUTE_QUESTION_FINANCE
  } else if (
    currentRoute === QUESTION_DAILY_IMPORTANCE &&
    QuestionStore.getAnswer(QUESTION_DAILY_IMPORTANCE) === VeryImportant
  ) {
    return ROUTE_QUESTION_DAILY_IMPORTANCE_INFO
  } else if (showLiquidityInfo(currentRoute)) {
    return ''
  } else if (currentRoute === QUESTION_ILLIQUID_ASSETS_ALLOWED && skipTupas) {
    return ROUTE_QUESTION_INVESTMENT_PROPOSAL + 'Show'
  } else if (
    currentRoute === QUESTION_ESG_START &&
    QuestionStore.getAnswer(QUESTION_ESG_START) === 'No'
  ) {
    return questionsRoutes()[index + 4]
  } else if (DisplayEsgWarning(currentRoute))
    return ROUTE_QUESTION_ESG_SUITABILITY_WARNING

  const nextRoute = questionsRoutes()[index + 1]
  return nextRoute
}

function DisplayEsgWarning(currentRoute: string) {
  if (
    currentRoute === QUESTION_ESG_ENVIRONMENT &&
    QuestionStore.getAnswer(QUESTION_ESG_ENVIRONMENT) ===
      EsgKnowledgeOptions.VeryImportant
  ) {
    {
      return true
    }
  }
  if (
    currentRoute === QUESTION_ESG_HARMFUL_EMISSION_EFFECTS &&
    QuestionStore.getAnswer(QUESTION_ESG_HARMFUL_EMISSION_EFFECTS) ===
      EsgKnowledgeOptions.VeryImportant
  ) {
    {
      return true
    }
  }
  if (
    currentRoute === QUESTION_ESG_SFDR &&
    QuestionStore.getAnswer(QUESTION_ESG_SFDR) ===
      EsgKnowledgeOptions.VeryImportant
  ) {
    {
      return true
    }
  }
  return false
}

export function nextContractRoute(
  currentRoute: string,
  showRisk = false,
  isConsultativeWithoutContract = false,
  isConsultative = false
) {
  const index = indexOf(contractRoutes, currentRoute)
  const nextRoute = contractRoutes[index + 1]

  if (DisplayEsgWarning(currentRoute)) {
    return ROUTE_QUESTION_ESG_SUITABILITY_WARNING
  }

  if (currentRoute === ROUTE_CONTRACT_RISK) {
    return ROUTE_CONTRACT_CONFIRM_DIGI
  }
  if (nextRoute === ROUTE_CONTRACT_RISK && isConsultativeWithoutContract) {
    return ROUTE_CONTRACT_CONFIRM_DIGI
  }

  if (!showRisk && nextRoute === ROUTE_CONTRACT_RISK) {
    return contractRoutes[index + 2]
  }

  if (
    currentRoute === QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND) ===
      'No'
  ) {
    return QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS
  } else if (
    currentRoute === QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS) ===
      'No'
  ) {
    return QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS
  } else if (
    currentRoute === QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS &&
    QuestionStore.getAnswer(QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS) === 'No'
  ) {
    return ROUTE_QUESTION_FINANCE
  }

  if (
    currentRoute === QUESTION_DAILY_IMPORTANCE &&
    QuestionStore.getAnswer(QUESTION_DAILY_IMPORTANCE) === VeryImportant
  ) {
    return ROUTE_QUESTION_DAILY_IMPORTANCE_INFO
  } else if (
    currentRoute === QUESTION_PRODUCT_KNOWLEDGE_PART_ONE &&
    QuestionStore.missingFundKnowledge() &&
    isConsultative
  ) {
    return ROUTE_CONTRACT_FUND_EXPLANATION
  } else if (showLiquidityInfo(currentRoute)) {
    return ''
  } else if (
    currentRoute === QUESTION_ESG_START &&
    QuestionStore.getAnswer(QUESTION_ESG_START) === 'No'
  ) {
    if (
      contractRoutes[index + 4] === ROUTE_CONTRACT_RISK &&
      isConsultativeWithoutContract
    ) {
      return ROUTE_CONTRACT_CONFIRM_DIGI
    }
    if (!showRisk && contractRoutes[index + 4] === ROUTE_CONTRACT_RISK) {
      return contractRoutes[index + 6]
    }
    return contractRoutes[index + 4]
  }

  return nextRoute
}

export function nextQuestionOrContractRoute(
  currentRoute: string,
  showRisk = false,
  isConsultativeWithoutContract = false,
  isConsultative = false,
  skipTupas = false
) {
  if (QuestionStore.questionMode === 'contract') {
    return nextContractRoute(
      currentRoute,
      showRisk,
      isConsultativeWithoutContract,
      isConsultative
    )
  }
  return nextQuestionRoute(currentRoute, skipTupas)
}

export function questionPhase(currentRoute: string) {
  if (QuestionStore.questionMode === 'contract') {
    return indexOf(contractRoutes, currentRoute) + 1
  }
  return indexOf(questionsRoutes(), currentRoute) + 1
}

export function questionPhaseCount() {
  if (QuestionStore.questionMode === 'contract') {
    return contractRoutes.length + 1
  }
  return questionsRoutes().length
}

export const allQuestions = (
  t: TFunction
): (OptionQuestion | TagSelectQuestion)[] => [
  {
    id: QUESTION_INVESTMENT_ASSETS,
    question: 'questions.investmentAssets.question',
    help: 'questions.investmentAssets.help',
    options: DEFAULT_FINANCE_OPTIONS,
  },
  {
    id: QUESTION_PHYSICAL_ASSETS,
    question: 'questions.physicalAssets.question',
    help: 'questions.physicalAssets.help',
    options: DEFAULT_FINANCE_OPTIONS,
  },
  {
    id: QUESTION_DEBT,
    question: 'questions.debt.question',
    help: 'questions.debt.help',
    options: DEBT_OPTIONS,
  },
  {
    id: QUESTION_DAILY_IMPORTANCE,
    question: 'questions.dailyImportance.question',
    help: 'questions.dailyImportance.help',
    options: [
      {
        value: 'Unimportant',
        label: 'questions.dailyImportance.unimportant',
      },
      {
        value: 'SomewhatImportant',
        label: 'questions.dailyImportance.somewhatImportant',
      },
      {
        value: VeryImportant,
        label: 'questions.dailyImportance.veryImportant',
      },
    ],
  },
  {
    id: QUESTION_INVESTOR_EXPERIENCE,
    question: 'questions.investmentExperience.title',
    options: [
      {
        value: 'VeryExperienced',
        label: 'questions.investmentExperience.very-experienced',
      },
      {
        value: 'Experienced',
        label: 'questions.investmentExperience.experienced',
      },
      {
        value: 'SomeExperience',
        label: 'questions.investmentExperience.some-experience',
      },
      {
        value: 'FirstTimer',
        label: 'questions.investmentExperience.first-timer',
      },
    ],
  },
  {
    id: QUESTION_PRODUCT_KNOWLEDGE_PART_ONE,
    question: 'questions.productKnowledge.title-part1',
    questionInfo: 'questions.productKnowledge.question-info-1',
    help: 'questions.productKnowledge.help-1',
    tags: productKnowledgeTags,
    forcedHelp: 'questions.productKnowledge.forced-help',
    separeteForcedTags: true,
  },
  {
    id: QUESTION_PRODUCT_KNOWLEDGE_PART_TWO,
    question: 'questions.productKnowledge.title-2',
    help: 'questions.productKnowledge.help-2',
    filterTagsAccordingPreviousAnswers: true,
    tags: productKnowledgeTags,
  },
  {
    id: QUESTION_DISCRETIONARY_ASSET_MANAGEMENT_FAMILIARITY,
    question: 'questions.discretionaryAssetManagementFamiliarity.question',
    options: [
      {
        value: ProductKnowledgeOption.HasInvested,
        label: 'questions.discretionaryAssetManagementFamiliarity.has-invested',
      },
      {
        value: ProductKnowledgeOption.Knows,
        label: 'questions.discretionaryAssetManagementFamiliarity.knows',
      },
      {
        value: ProductKnowledgeOption.DoesNotKnow,
        label:
          'questions.discretionaryAssetManagementFamiliarity.does-not-know',
      },
    ],
  },
  {
    id: QUESTION_INVESTMENT_GOAL,
    question: 'questions.investmentGoal.question',
    questionInfo: 'questions.investmentGoal.question-info',
    options: [
      {
        value: 'MaximumGains',
        label: 'questions.investmentGoal.maximum-gains',
      },
      {
        value: 'GainsWithRisk',
        label: 'questions.investmentGoal.gains-with-risk',
      },
      {
        value: 'StableReturns',
        label: 'questions.investmentGoal.stable-returns',
      },
      {
        value: 'SafeguardFromLoss',
        label: 'questions.investmentGoal.safeguard-from-loss',
      },
    ],
  },
  {
    id: QUESTION_INVESTMENT_HORIZONT,
    question: 'questions.investmentHorizon.question',
    questionInfo: 'questions.investmentHorizon.question-info',
    options: [
      {
        value: 'PortfolioGrowth',
        label: 'questions.investmentHorizon.portfolio-growth',
      },
      {
        value: 'NothingSpecific',
        label: 'questions.investmentHorizon.nothing-specific',
      },
      {
        value: 'Trial',
        label: 'questions.investmentHorizon.trial',
      },
      {
        value: 'SellSoonToBuySomething',
        label: 'questions.investmentHorizon.sell-soon-to-buy-something',
      },
    ],
  },
  {
    id: QUESTION_LOSS_TOLERANCE,
    question: 'questions.lossTolerance.question',
    options: [
      {
        value: 'UnderstandsBusinessAsUsual',
        label: 'questions.lossTolerance.understand',
      },
      {
        value: 'WouldNotWorryAboutIt',
        label: 'questions.lossTolerance.no-worry',
      },
      { value: 'Nervous', label: 'questions.lossTolerance.nervous' },
      { value: 'LossOfSleep', label: 'questions.lossTolerance.loss-of-sleep' },
    ],
  },
  {
    id: QUESTION_LOSS_TOLERANCE_BEHAVIOR,
    question: 'questions.loss-tolerance-behaviour-question',
    questionFn: (portfolio?: Portfolio) => {
      if (QuestionStore.questionMode === 'onboarding') {
        return lossToleranceQuestionOnboarding(t)
      }
      return lossToleranceQuestionContractUpdate(t, portfolio)
    },
    options: [
      {
        value: 'InvestMore',
        label: 'questions.lossToleranceBehaviour.invest-more',
      },
      { value: 'Hold', label: 'questions.lossToleranceBehaviour.hold' },
      {
        value: 'SellSome',
        label: 'questions.lossToleranceBehaviour.sell-some',
      },
      { value: 'SellAll', label: 'questions.lossToleranceBehaviour.sell-all' },
      {
        value: 'NoIdeaWhatToDo',
        label: 'questions.lossToleranceBehaviour.no-idea',
      },
    ],
  },
  {
    id: QUESTION_ILLIQUID_ASSETS_ALLOWED,
    question: 'questions.illiquidAssetsAllowed.question',
    options: [
      {
        value: 'AllAllowed',
        label: 'questions.illiquidAssetsAllowed.all-allowed',
      },
      {
        value: 'SomeAllowed',
        label: 'questions.illiquidAssetsAllowed.some-allowed',
      },
      {
        value: None,
        label: 'questions.illiquidAssetsAllowed.none',
      },
    ],
  },
  {
    id: QUESTION_SHARE_TO_TAALERI,
    question: 'questions.shareTo.question',
    options: [
      { value: 'Majority', label: 'questions.shareTo.majority' },
      { value: 'LessThanHalf', label: 'questions.shareTo.less-than-half' },
      { value: 'MinorPart', label: 'questions.shareTo.minor-part' },
    ],
  },
  {
    id: QUESTION_ESG_START,
    question: 'questions.esgStart.question',
    boxtext1: 'questions.esgStart.boxtext1',
    boxtext2: 'questions.esgStart.boxtext2',
    options: [
      {
        value: 'Yes',
        label: 'questions.esgStart.yes',
      },
      {
        value: 'No',
        label: 'questions.esgStart.no',
      },
    ],
  },
  {
    id: QUESTION_ESG_ENVIRONMENT,
    question: 'questions.esgEnvironment.question',
    questionInfo: 'questions.esgEnvironment.info',
    options: [
      {
        value: EsgKnowledgeOptions.NotImportant,
        label: 'questions.esgEnvironment.notImportant',
      },
      {
        value: EsgKnowledgeOptions.SomeWhatImportant,
        label: 'questions.esgEnvironment.some-what-important',
      },
      {
        value: EsgKnowledgeOptions.Important,
        label: 'questions.esgEnvironment.important',
      },
      {
        value: EsgKnowledgeOptions.VeryImportant,
        label: 'questions.esgEnvironment.very-important',
      },
    ],
  },
  {
    id: QUESTION_ESG_SFDR,
    question: 'questions.esgSfdr.question',
    questionInfo: 'questions.esgSfdr.info',
    options: [
      {
        value: EsgKnowledgeOptions.NotImportant,
        label: 'questions.esgSfdr.not-important',
      },
      {
        value: EsgKnowledgeOptions.SomeWhatImportant,
        label: 'questions.esgSfdr.some-what-important',
      },
      {
        value: EsgKnowledgeOptions.Important,
        label: 'questions.esgSfdr.important',
      },
      {
        value: EsgKnowledgeOptions.VeryImportant,
        label: 'questions.esgSfdr.very-important',
      },
    ],
  },
  {
    id: QUESTION_ESG_HARMFUL_EMISSION_EFFECTS,
    question: 'questions.esgHarmfulEmissionEffects.question',
    questionInfo: 'questions.esgHarmfulEmissionEffects.info',
    options: [
      {
        value: EsgKnowledgeOptions.NotImportant,
        label: 'questions.esgHarmfulEmissionEffects.not-important',
      },
      {
        value: EsgKnowledgeOptions.Important,
        label: 'questions.esgHarmfulEmissionEffects.important',
      },
      {
        value: EsgKnowledgeOptions.VeryImportant,
        label: 'questions.esgHarmfulEmissionEffects.very-important',
      },
    ],
  },
  {
    id: QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
    question: 'questions.hasMonthlyMoneyTransfer.question',
    questionInfo: 'questions.hasMonthlyMoneyTransfer.question-info',
    options: YES_NO_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
    question: 'questions.monthlyIncomingoneyWithInFinland.question',
    questionInfo: 'questions.monthlyIncomingoneyWithInFinland.question-info',
    options: MONEY_TRANSFER_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
    question: 'questions.monthlyOutgoingMoneyWithinFinland.question',
    questionInfo: 'questions.monthlyOutgoingMoneyWithinFinland.question-info',
    options: MONEY_TRANSFER_OPTIONS,
  },
  {
    id: QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
    question: 'questions.monthlyForeignMoneyTransfers.question',
    questionInfo: 'questions.monthlyForeignMoneyTransfers.question-info',
    options: YES_NO_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
    question: 'questions.monthlyIncomingForeignMoneyTransfers.question',
    options: MONEY_TRANSFER_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
    question: 'questions.monthlyOutgoingForeignMoneyTransfers.question',
    options: MONEY_TRANSFER_OPTIONS,
  },
  {
    id: QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
    question: 'questions.monthlyCashTransactions.question',
    options: YES_NO_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_CASH_DEPOSITS,
    question: 'questions.cashDeposits.question',
    options: MONEY_TRANSFER_WITHOUT_NONE_OPTIONS,
  },
  {
    id: QUESTION_MONTHLY_CASH_WITHDRAWALS,
    question: 'questions.cashWithdrawals.question',
    options: MONEY_TRANSFER_WITHOUT_NONE_OPTIONS,
  },
]

export function isFinancialQuestion(
  question: OptionQuestion | TagSelectQuestion
): question is OptionQuestion {
  return (question as OptionQuestion).options !== undefined
}

export function isTagSelectQuestion(
  question: OptionQuestion | TagSelectQuestion | Question
): question is TagSelectQuestion {
  return (question as TagSelectQuestion).tags !== undefined
}

export function questionsWithAnswers(answers: Answers, t: TFunction): any[] {
  return allQuestions(t).map((question) => {
    const tags = isTagSelectQuestion(question) ? { tags: question.tags } : {}

    return {
      id: question.id,
      question: question.question,
      answer: answers[question.id],
      ...tags,
    }
  })
}

export function findQuestionById(id: string, t: TFunction): Question {
  const question = allQuestions(t).find((item) => item.id === id)
  if (!question) {
    throw Error('Invalid id: ' + id)
  }
  return question
}

export function findAnswerById(questions: Question[], id: string): string {
  const question = questions.find((item) => item.id === id)
  if (question) {
    return question.answer || ''
  }
  return ''
}

export function findTagAnswerById(questions: Question[], id: string): Tag[] {
  const question = questions.find((item) => item.id === id)
  if (question) {
    return question.answer || []
  }
  return []
}
