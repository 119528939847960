import {
  StyleSheet,
  View,
  Clipboard,
  Alert,
  StyleProp,
  ViewStyle,
} from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { formatCurrency } from '@taaleri/core/src/utils/format'
import React, { useEffect, useState } from 'react'
import { TextBold, TextMedium } from '../../ui/text/UiText'
import { PaymentInfo } from '@taaleri/core/src/models/Payment'
import { getPaymentMeta } from '@taaleri/core/src/services/payments'
import LoadingIndicator from '../../ui/LoadingIndicator'
import Colors from '@taaleri/core/src/constants/Colors'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { TextMediumHoverPrimary } from '../../ui/text/TextHover'
import UiTouchable from '../../ui/UiTouchable'
import { BoxWithPadding } from '../../ui/box/Box'
import useLayout from '../../ui/useLayout'
import { useTranslation } from 'react-i18next'

export const TitleValue: React.FC<{
  title: string
  value: string
  style?: StyleProp<ViewStyle>
}> = ({ title, value, style }) => {
  const { isSmall } = useLayout()
  return (
    <View
      style={[
        styles.titleValue,
        {
          flexDirection: isSmall ? 'column' : 'row',
          justifyContent: isSmall ? 'flex-start' : 'space-between',
        },
        style,
      ]}
    >
      <TextBold style={styles.title}>{title}</TextBold>
      <TextMedium style={styles.value}>{value}</TextMedium>
    </View>
  )
}

interface Props {
  hideSum?: boolean
  hideTitle?: boolean
  sum?: number
  isConsultative: boolean
  children?: any
  noPadding?: boolean
}

export default function PaymentInfoSection({
  hideTitle,
  hideSum,
  sum = 0,
  isConsultative,
  children,
  noPadding,
}: Props) {
  const [error, setError] = useState<boolean>(false)
  const [paymentMeta, setPaymentMeta] = useState<PaymentInfo>()
  const { t } = useTranslation()

  async function findMeta() {
    setError(false)
    try {
      const paymentMetaRes = await getPaymentMeta(sum, isConsultative)
      setPaymentMeta(paymentMetaRes)
    } catch (e) {
      setError(true)
    }
  }

  useEffect(() => {
    findMeta()
  }, [])

  async function copyBarCode() {
    if (paymentMeta) {
      const barCode = paymentMeta.barCode
      await Clipboard.setString(barCode)
      Analytics.event('Payment', 'copybarcode')
      Alert.alert(t('alert.virtual-barcode-copied'))
    }
  }

  if (error) {
    return <ErrorWithRetry onRetry={findMeta} />
  }

  if (!paymentMeta) {
    return <LoadingIndicator type="inline" />
  }

  return (
    <BoxWithPadding
      type={noPadding ? 'plain' : 'shadow'}
      style={[{ marginBottom: -Spacings.S16 }, noPadding && { padding: 0 }]}
      title={hideTitle ? undefined : `${t('invest.bank-transaction-payment-info')}`}
    >
      {children}
      <UiTouchable
        onPress={copyBarCode}
        style={{
          marginTop: Spacings.S16,
          marginBottom: Spacings.S16,
          paddingBottom: Spacings.S16,
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
        }}
      >
        <TextMediumHoverPrimary style={{ color: Colors.textPrimary }}>
          {t('invest.copy-virtual-barcode')}
        </TextMediumHoverPrimary>
      </UiTouchable>
      <TitleValue
        title={t('invest.recipient-account-number')}
        value={paymentMeta.accountNumber}
      />
      <TitleValue title={t('invest.recipient')} value="Aktia Pankki Oyj" />
      <TitleValue title={t('invest.reference')} value={paymentMeta.reference} />
      {!hideSum && <TitleValue title={t('steps.sum')} value={formatCurrency(sum)} />}
    </BoxWithPadding>
  )
}

const styles = StyleSheet.create({
  titleValue: {
    paddingBottom: Spacings.S16,
    marginBottom: Spacings.S16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.border,
  },
  title: {
    lineHeight: 22,
    marginBottom: 4,
  },
  value: {
    lineHeight: 22,
  },
})
