import {
  AssetClassSecurities,
  Security,
  PortfolioDistributions,
  PreliminaryProposal,
} from '@taaleri/core/src/models/investmentProposal'
import { InvestmentProposal } from '../../models/investmentProposal'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import { InvestmentStrategy, Allocation } from '../../models/InvestmentStrategy'

interface RiskProfile {
  customerId: string
  riskLevel: number
  recommendedBondWeight: number
  recommendedEquityWeight: number
  volatility: number
  maxEquityWeight: number
  minEquityWeight: number
  maxBondWeight: number
  minBondWeight: number
  maxMoneyMarketWeight: number
  minMoneyMarketWeight: number
  maxOtherWeight: number
  minOtherWeight: number
  maxOtherShare: number
  minOtherShare: number
  investmentGoal: number
  investmentExperience: number
  riskTolerance: number
}

interface InvestmentStrategyResponse {
  investmentStrategies: InvestmentStrategy[]
  riskProfile: RiskProfile
  currentInvestmentStrategy?: InvestmentStrategy
  maxRiskLevel: number
}

interface PreliminaryProposalResponse {
  riskProfile: RiskProfile
  regularEtfInvestmentStrategy: InvestmentStrategy
  impactInvestmentStrategy: InvestmentStrategy
}

function allocationToSecurity(allocation: Allocation): Security {
  return {
    securityId: allocation.securityId,
    name: allocation.securityName ? allocation.securityName : allocation.name,
    nameSv: allocation.nameSv,
    allocation,
    percentage: allocation.allocationShare,
    sustainableDevelopmentGoals: allocation.sustainableDevelopmentGoals,
  }
}

function toAssetClassSecurities(
  allocations: Allocation[],
  currentInvestmentStrategy?: InvestmentStrategy
): AssetClassSecurities[] {
  const shares: Allocation[] = allocations.filter(
    (a) => a.securityClass === 'Equity'
  )

  const bonds: Allocation[] = allocations.filter(
    (a) => a.securityClass === 'Bond'
  )

  const impact: Allocation[] = allocations.filter(
    (a) => a.securityClass === 'Impact'
  )

  const sharesTotal = sumBy(shares, (curValue) => curValue.allocationShare)
  const bondsTotal = sumBy(bonds, (curValue) => curValue.allocationShare)
  const impactTotal = sumBy(impact, (curValue) => curValue.allocationShare)

  let currentSharePercentage = 0
  let currentBondPercentage = 0
  let currentImpactPercentage = 0

  if (currentInvestmentStrategy) {
    const currentShares: Allocation[] =
      currentInvestmentStrategy.allocations.filter(
        (a) => a.securityClass === 'Equity'
      )
    const currentBonds: Allocation[] =
      currentInvestmentStrategy.allocations.filter(
        (a) => a.securityClass === 'Bond'
      )
    const currentImpact: Allocation[] =
      currentInvestmentStrategy.allocations.filter(
        (a) => a.securityClass === 'Impact'
      )

    currentSharePercentage = sumBy(
      currentShares,
      (curValue) => curValue.allocationShare
    )
    currentBondPercentage = sumBy(
      currentBonds,
      (curValue) => curValue.allocationShare
    )
    currentImpactPercentage = sumBy(
      currentImpact,
      (curValue) => curValue.allocationShare
    )
  }

  const isImpactStrategy = impactTotal !== 0

  const equityGroup: AssetClassSecurities = {
    id: 'A',
    name: isImpactStrategy ? 'questions.proposal.responsibleshares' : 'shares',
    percentage: sharesTotal,
    currentPercentage: currentSharePercentage,
    securities: shares.map(allocationToSecurity),
  }
  const bondGroup: AssetClassSecurities = {
    id: 'B',
    name: isImpactStrategy
      ? 'questions.proposal.responsible-rates'
      : 'interest',
    percentage: bondsTotal,
    currentPercentage: currentBondPercentage,
    securities: bonds.map(allocationToSecurity),
  }
  const impactGroup: AssetClassSecurities = {
    id: 'I',
    name: 'impactIntro.impaktifund.header',
    percentage: impactTotal,
    currentPercentage: currentImpactPercentage,
    securities: impact.map(allocationToSecurity),
  }

  const result: AssetClassSecurities[] = []

  if (equityGroup.securities.length > 0 || equityGroup.currentPercentage) {
    result.push(equityGroup)
  }
  if (bondGroup.securities.length > 0 || bondGroup.currentPercentage) {
    result.push(bondGroup)
  }

  if (impactGroup.securities.length > 0 || impactGroup.currentPercentage) {
    result.push(impactGroup)
  }

  return result
}

export function toPortfolioDistribution(
  strategy: InvestmentStrategy,
  currentInvestmentStrategy?: InvestmentStrategy
): PortfolioDistributions {
  return {
    strategyIdentifier: strategy.strategyIdentifier,
    riskLevel: Number(strategy.riskLevel),
    expectedReturn: strategy.expectedReturn / 100,
    standardDeviation: strategy.standardDeviation / 100,
    impactShare: strategy.impactShare / 100,
    totalExpenseRatio: strategy.totalExpenseRatio,
    totalCompaniesInvestedIn: strategy.totalCompaniesInvestedIn,
    securities: toAssetClassSecurities(
      reverse(sortBy(strategy.allocations, (a) => a.allocationShare)),
      currentInvestmentStrategy
    ),
    equityShare: strategy.equityShare / 100,
    bondShare: strategy.bondShare / 100,
    sustainableDevelopmentGoals: strategy.sustainableDevelopmentGoals,
  }
}

export function toPreliminaryProposal(
  preliminaryProposalResponse: PreliminaryProposalResponse
): PreliminaryProposal {
  const {
    riskProfile,
    regularEtfInvestmentStrategy,
    impactInvestmentStrategy,
  } = preliminaryProposalResponse
  const { riskLevel, investmentExperience, riskTolerance, investmentGoal } =
    riskProfile

  return {
    impactShare:
      preliminaryProposalResponse.impactInvestmentStrategy.impactShare,
    riskLevel,
    investmentProfile: {
      investmentExperience,
      riskTolerance,
      investmentGoal,
    },
    regularEtfDistribution: toPortfolioDistribution(
      regularEtfInvestmentStrategy
    ),
    impactEtfDistribution: toPortfolioDistribution(impactInvestmentStrategy),
  }
}

export function toInvestmentProposal(
  investmentStrategyResponse: InvestmentStrategyResponse
): InvestmentProposal {
  const {
    investmentStrategies,
    riskProfile,
    currentInvestmentStrategy,
    maxRiskLevel,
  } = investmentStrategyResponse
  const portfolioDistributions: PortfolioDistributions[] =
    investmentStrategies.map((strategy) =>
      toPortfolioDistribution(strategy, currentInvestmentStrategy)
    )
  const { riskLevel, investmentExperience, riskTolerance, investmentGoal } =
    riskProfile

  return {
    riskLevel: riskLevel,
    portfolioDistributions,
    investmentProfile: {
      investmentExperience,
      riskTolerance,
      investmentGoal,
    },
    maxRiskLevel,
  }
}

export function toInvestmentProposalFromSaved(
  investmentStrategy: InvestmentStrategy
): InvestmentProposal {
  const portfolioDistributions: PortfolioDistributions[] = [
    investmentStrategy,
  ].map((strategy) => {
    return {
      strategyIdentifier: strategy.strategyIdentifier,
      riskLevel: Number(strategy.riskLevel),
      expectedReturn: strategy.expectedReturn / 100,
      standardDeviation: strategy.standardDeviation / 100,
      impactShare: strategy.impactShare / 100,
      equityShare: strategy.equityShare,
      bondShare: strategy.bondShare,
      totalExpenseRatio: strategy.totalExpenseRatio,
      totalCompaniesInvestedIn: strategy.totalCompaniesInvestedIn,
      securities: toAssetClassSecurities(
        reverse(sortBy(strategy.allocations, (a) => a.allocationShare)),
        undefined
      ),
      sustainableDevelopmentGoals: strategy.sustainableDevelopmentGoals,
      isImpact: strategy.isImpact,
    }
  })
  const { riskLevel, impactShare, expectedReturn, isImpact } =
    investmentStrategy

  return {
    riskLevel,
    portfolioDistributions,
    investmentProfile: {
      investmentExperience: 0,
      riskTolerance: 0,
      investmentGoal: 0,
    },
    maxRiskLevel: riskLevel,
    impactShare,
    expectedReturn,
    isImpact,
  }
}
