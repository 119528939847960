import { ImageBackground, StyleSheet, ImageSourcePropType } from 'react-native'
import React from 'react'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '../../ui/useLayout'

type ImageSize = 'default' | 'small'

function HeaderImage({
  imageSource,
  title,
  imageSize = 'default',
}: {
  imageSource: ImageSourcePropType
  title: string
  imageSize?: ImageSize
}) {
  const { isNonSmall } = useLayout()
  const height = imageSize === 'small' ? 100 : 168
  return (
    <ImageBackground
      source={imageSource}
      style={[styles.image, { height }, isNonSmall && styles.imageNonSmall]}
      resizeMode="cover"
    >
      <TextDefault type="h3" color={Colors.white}>
        {title}
      </TextDefault>
    </ImageBackground>
  )
}

export default HeaderImage

const styles = StyleSheet.create({
  image: {
    width: '100%',
    display: 'flex',
    padding: Spacings.S24,
  },
  imageNonSmall: {
    height: 180,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
