import React from 'react'
import { AssetClass } from '@taaleri/core/src/models/Portfolio'
import ImageBackgroundWithPercentagePie from '@taaleri/components/src/screens/impact/ImageBackgroundWithPercentagePie'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { getAssetClassImageById } from '@taaleri/core/src/constants/AssetClass'

interface Props {
  assetClass: AssetClass
}

function AssetClassBackground({ assetClass }: Props) {
  const image = getAssetClassImageById(assetClass.id)
  const portfolio = AppStore.portfolio
  const assetClasses = portfolio && portfolio.assetClasses
  return (
    <ImageBackgroundWithPercentagePie
      {...{ image, assetClasses, selectedAssetClass: assetClass }}
    />
  )
}

export default observer(AssetClassBackground)
