import React from 'react'
import { View } from 'react-native'
import Spacings from '@taaleri/core/src/constants/Spacings'
import UiButton from '../../ui/UiButton'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import {
  QUESTION_ILLIQUID_ASSETS_ALLOWED,
  nextQuestionOrContractRoute,
} from './questions'
import useNavigation from '../../navigation/useNavigation'
import { TextDefault } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import AppStore from '@taaleri/core/src/stores/AppStore'
import LiquidityPie from '../../components/LiquidityPie'
import FlexRow from '../../ui/FlexRow'
import useLayout from '../../ui/useLayout'
import { isWeb } from '../../constants/Platforms'
import { observer } from 'mobx-react'
import { WarningContainer } from '../../ui/WarningContainer'
import { useTranslation } from 'react-i18next'

function Dot({ color }: { color: string }) {
  return (
    <View
      style={{ height: 6, width: 16, backgroundColor: color, borderRadius: 3 }}
    />
  )
}

export function LiquidityPieContainer({
  impactShare,
}: {
  impactShare: number
}) {
  const { t } = useTranslation()
  const { isWide } = useLayout()
  const type = isWide ? 't2' : 't3'
  return (
    <FlexRow>
      <LiquidityPie
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginVertical: Spacings.S24,
          flex: 1,
          width: '100%',
          borderRightColor: Colors.border,
          borderRightWidth: 1,
          paddingHorizontal: Spacings.S24,
        }}
        impactShare={impactShare}
      />
      <View
        style={{
          flex: 1,
          width: '100%',
          justifyContent: 'center',
          paddingLeft: Spacings.S24,
        }}
      >
        <FlexRow style={{ alignItems: 'center' }}>
          <Dot color={Colors.primary} />
          <TextDefault style={{ marginLeft: Spacings.S8 }} type={type}>
            {`esim. ${100 - impactShare} %`}
          </TextDefault>
        </FlexRow>
        <TextDefault type={type}>
          {t('questions.liquidity-info')}
        </TextDefault>
        <FlexRow style={{ alignItems: 'center' }}>
          <Dot color={Colors.gray30} />
          <TextDefault style={{ marginLeft: Spacings.S8 }} type={type}>
            {`esim. ${impactShare} %`}
          </TextDefault>
        </FlexRow>
        <TextDefault type={type}>
          {t('questions.liquidity-pie-text')}
        </TextDefault>
      </View>
    </FlexRow>
  )
}

function LiquidityInfo() {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  return (
    <WarningContainer>
      <TextDefault type="h5" style={{ marginBottom: Spacings.S16 }}>
        {t('withdraw.impact-withdraw-notification')}
      </TextDefault>
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.liquidity-info-text1')}
      </TextDefault>
      <LiquidityPieContainer impactShare={20} />
      <TextDefault style={{ marginBottom: Spacings.S16 }}>
        {t('questions.liquidity-info-text2')}
      </TextDefault>
      <TextDefault type="h5" style={{ marginBottom: Spacings.S32 }}>
        {t('questions.liquidity-info-text3')}
      </TextDefault>
      <UiButton
        title={t('button.accept')}
        onPress={() => {
          Analytics.event('Onboarding', 'LiquidityInfo', 'Allow')
          QuestionStore.setAnswer(
            QUESTION_ILLIQUID_ASSETS_ALLOWED,
            'SomeAllowed'
          )
          const nextRoute = nextQuestionOrContractRoute(
            QUESTION_ILLIQUID_ASSETS_ALLOWED,
            AppStore.customerMaybe && AppStore.isDigiDiscretionary,
            AppStore.customerMaybe && AppStore.isConsultativeWithoutContract,
            AppStore.customerMaybe && AppStore.isConsultativeOrOpening,
            QuestionStore.tupasDone && isWeb
          )
          navigate(nextRoute)
        }}
      />
    </WarningContainer>
  )
}

export default observer(LiquidityInfo)
