import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { StyleSheet, View } from 'react-native'

export default function ProgressIndicator({
  phase,
  count,
}: {
  phase: number
  count: number
}) {
  const percentage = (phase / count) * 100

  return (
    <View style={styles.percentageIndicatorRow}>
      <View style={[styles.percentageIndicatorShare, { flex: percentage }]} />
      <View
        style={[styles.percentageIndicatorOther, { flex: 100 - percentage }]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  percentageIndicatorRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  percentageIndicatorShare: {
    borderTopWidth: 2,
    borderTopColor: Colors.primary,
  },
  percentageIndicatorOther: {
    borderTopWidth: 2,
    borderTopColor: Colors.border,
  },
})
