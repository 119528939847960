import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { fiKey } from '@taaleri/core/src/i18n'
import AppStore from '@taaleri/core/src/stores/AppStore'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'

import { isWeb } from '../../constants/Platforms'
import FlexRow from '../../ui/FlexRow'
import { IconDownload } from '../../ui/Icons'
import { ButtonSecondaryWithChildren } from '../../ui/UiButton'
import { BoxWithPadding } from '../../ui/box/Box'
import { openUrl } from '../../ui/openUrl'
import { Paragraph } from '../../ui/text/Paragraph'
import { TextDefault } from '../../ui/text/UiText'
import useLayout from '../../ui/useLayout'

export default function AppInfoContent() {
  const { t } = useTranslation()
  const { isSmall } = useLayout()

  const remoteDocumentUrl = () => {
    return AppStore.currentLanguage === fiKey
      ? 'https://www.aktia.fi/docs/default-source/henkiloasiakkaat/tilit/kuluttajasuojalain-6a-luvun-edellyttama-ennakkotiedote-koskien-rahoituspalvelua.pdf?sfvrsn=dddf9fc8_62'
      : 'https://www.aktia.fi/docs/default-source/henkiloasiakkaat/tilit/kuluttajasuojalain-6a-luvun-edellyttama-ennakkotiedote-koskien-rahoituspalvelua_sv.pdf?sfvrsn=dddf9fc8_74'
  }

  const investDocumentUrl = () => {
    return AppStore.currentLanguage === fiKey
      ? 'https://misc.aktia.fi/data-service/documents/investment/Tietoja_sijoituspalveluista/Sijoittajatiedote/Sijoittajatiedote.pdf'
      : 'https://misc.aktia.fi/data-service/documents/investment/Tietoja_sijoituspalveluista/Sijoittajatiedote/Meddelande_till_investerare.pdf'
  }

  const qualityDocumentUrl = () => {
    return AppStore.currentLanguage === fiKey
      ? 'https://misc.aktia.fi/data-service/documents/investment/Kannustinohjelma/Kannustinohjelma.pdf'
      : 'https://misc.aktia.fi/data-service/documents/investment/Kannustinohjelma/Incitamentprogram.pdf'
  }

  return (
    <BoxWithPadding
      style={{
        marginHorizontal: isWeb
          ? isSmall
            ? Spacings.S8
            : Spacings.S128
          : Spacings.S8,
        marginBottom: Spacings.S32,
      }}
    >
      <FlexRow style={infoStyles.infoLinkRow}>
        <Paragraph style={infoStyles.paragraph}>
          {t('profile.distance-bulletin')}
        </Paragraph>

        <ButtonSecondaryWithChildren
          onPress={() => openUrl(remoteDocumentUrl())}
        >
          <FlexRow>
            <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
              PDF
            </TextDefault>
            <IconDownload />
          </FlexRow>
        </ButtonSecondaryWithChildren>
      </FlexRow>
      <FlexRow style={infoStyles.infoLinkRow}>
        <Paragraph style={infoStyles.paragraph}>
          {t('app.ImpactToggleContract.Impaktiinvestmentinformationlink')}
        </Paragraph>

        <ButtonSecondaryWithChildren
          onPress={() => openUrl(investDocumentUrl())}
        >
          <FlexRow>
            <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
              PDF
            </TextDefault>
            <IconDownload />
          </FlexRow>
        </ButtonSecondaryWithChildren>
      </FlexRow>
      <FlexRow style={infoStyles.infoLinkRow}>
        <Paragraph style={infoStyles.paragraph}>
          {t('profile.quality-document')}
        </Paragraph>

        <ButtonSecondaryWithChildren
          onPress={() => openUrl(qualityDocumentUrl())}
        >
          <FlexRow>
            <TextDefault type="h6" style={{ color: Colors.textPrimary }}>
              PDF
            </TextDefault>
            <IconDownload />
          </FlexRow>
        </ButtonSecondaryWithChildren>
      </FlexRow>
    </BoxWithPadding>
  )
}

const infoStyles = StyleSheet.create({
  infoLinkRow: {
    justifyContent: 'space-between',
    marginBottom: 5,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.chartLineGrey,
  },
  paragraph: {
    paddingTop: 8,
    marginBottom: 0,
  },
})
