import React from 'react'
import UiTouchable from '../../../ui/UiTouchable'
import { TextDefault } from '../../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { useTranslation } from 'react-i18next'

export interface CategoryItem {
  title: string
  category: string
}

export function CategorySelector({
  item,
  selectedCategory,
  setSelectedCategory,
}: {
  item: CategoryItem
  selectedCategory: string
  setSelectedCategory: (type: string) => void
}) {
  const { t } = useTranslation()
  const isSelected = item.category === selectedCategory
  const style = {
    backgroundColor: isSelected ? Colors.white : Colors.backgroundPale,
    paddingVertical: Spacings.S10,
    paddingHorizontal: Spacings.S20,
    marginRight: Spacings.S8,
    borderTopColor: Colors.primary,
    borderTopWidth: isSelected ? Spacings.S2 : 0,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  }
  return (
    <UiTouchable
      style={style}
      onPress={() => setSelectedCategory(item.category)}
    >
      <TextDefault type="h6">{t(item.title)}</TextDefault>
    </UiTouchable>
  )
}
