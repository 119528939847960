import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import BoxCheck from '../../ui/box/BoxCheck'
import ReportOrderStore, {
  ReportPackage,
} from '@taaleri/core/src/stores/ReportOrderStore'
import { observer } from 'mobx-react'
import { reportOrderNew } from './reportOrderNew'
import UiTouchable from '../../ui/UiTouchable'
import { TextMedium } from '../../ui/text/UiText'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import {
  hasAcceptedEmailReporting,
  acceptEmailReporting,
} from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import LoadingIndicator from '../../ui/LoadingIndicator'
import UiButton from '../../ui/UiButton'
import useLayout from '../../ui/useLayout'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import Analytics from '@taaleri/core/src/analytics/Analytics'
import { useTranslation } from 'react-i18next'

interface Props {
  navigateDelivery: () => void
  navigateNewReports: () => void
}

function ReportOrderPackageContent({
  navigateDelivery,
  navigateNewReports,
}: Props) {
  const { t } = useTranslation()
  const { isNonSmall } = useLayout()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [retry, setRetry] = useState<number>(0)
  const [accepting, setAccepting] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  useEffect(() => {
    async function checkTerms() {
      const serviceResponse = await hasAcceptedEmailReporting(
        AppStore.customerId
      )
      setLoading(false)
      if (serviceResponse.success) {
        setTermsAccepted(serviceResponse.response === true)
      } else {
        setError(true)
      }
    }
    checkTerms()
  }, [retry])

  async function acceptTerms() {
    setAccepting(true)
    const serviceResponse = await acceptEmailReporting(AppStore.customerId)
    if (serviceResponse.success) {
      setTermsAccepted(true)
    } else {
      setError(true)
    }
  }

  if (error) {
    return <ErrorWithRetry onRetry={() => setRetry(retry + 1)} />
  }

  if (loading) {
    return <LoadingIndicator type="inline" />
  }

  if (!termsAccepted) {
    return (
      <View style={{ justifyContent: 'space-between', flex: 1 }}>
        <View style={{ marginBottom: Spacings.S40 }}>
          <Title>{t('reportOrder.terms-title')}</Title>
          <Paragraph>{t('reportOrder.terms-accept')}</Paragraph>
          <Paragraph>{t('reportOrder.inform-email-change')}</Paragraph>
        </View>

        <UiButton
          title={t('button.accept-terms')}
          onPress={acceptTerms}
          loading={accepting}
          containerStyle={[isNonSmall && { alignSelf: 'flex-end' }]}
        />
      </View>
    )
  }

  return (
    <View>
      <Title>{t('reportOrder.choose-order-reports')}</Title>
      <Paragraph>{t('reportOrder.choose-order-reports-text')}</Paragraph>

      {[
        {
          value: 'Wealth',
          label: `${t('reportOrder.wealth-report-label')}`,
          description: `${t('reportOrder.wealth-report-description')}`,
        },
        {
          value: 'Bookkeeper',
          label: `${t('reportOrder.bookkeeper-report-label')}`,
          description: `${t('reportOrder.bookkeeper-report-description')}`,
        },
      ].map((option) => (
        <BoxCheck
          key={option.value}
          isSelected={ReportOrderStore.reportPackage === option.value}
          option={option}
          backgroundColor="white"
          onPress={(selected: string) => {
            ReportOrderStore.reportPackage = selected as ReportPackage
            Analytics.event('ReportOrders', 'Select report package', selected)
            const reportOrder = reportOrderNew()
            if (selected === 'Wealth') {
              reportOrder.selectedReports = ['ProfitSummary', 'Purchaselot']
            }
            if (selected === 'Bookkeeper') {
              reportOrder.selectedReports = [
                'Purchaselot',
                'Transactions',
                'OpenTransactions',
                'CashAccountStatements',
              ]
            }

            ReportOrderStore.reportOrder = reportOrder
            navigateDelivery()
          }}
        />
      ))}

      <UiTouchable
        style={{ marginTop: Spacings.S24 }}
        onPress={() => {
          const reportOrder = reportOrderNew()
          ReportOrderStore.reportOrder = reportOrder
          ReportOrderStore.reportPackage = 'Custom'
          Analytics.event('ReportOrders', 'Select report package', 'Custom')
          navigateNewReports()
        }}
      >
        <TextMedium style={{ color: Colors.primary }}>
          {t('reportOrder.choose-self-ordering-reports')}
        </TextMedium>
      </UiTouchable>
    </View>
  )
}

export default observer(ReportOrderPackageContent)
