import { ContractPdf, OrderPdf } from './../models/ContractPdf'
import { IContractApi } from './IContractApi'
import { sleep } from '@taaleri/core/src/api/mockHelper'

async function contracts() {
  await sleep(100)
  const contractList: ContractPdf[] = []

  return Promise.resolve(contractList)
}

async function contractOrders() {
  const contractList: OrderPdf[] = []

  return contractList
}

export const contractApiMock: IContractApi = {
  contracts,
  contractOrders,
}
