import { FeedDetailContent } from '@taaleri/components/src/screens/feed/FeedDetailContent'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import Title from '@taaleri/components/src/ui/text/Title'
import { Feed } from '@taaleri/core/src/models/Feed'
import { getFeed } from '@taaleri/core/src/services/feeds'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ContentContainerBox } from 'ui/container/ContentContainer'

function FeedDetailScreen() {
  useEffect(() => {
    async function findFeed() {
      let feeds: Feed[] = []
      feeds = await getFeed(AppStore.customerId, 1)
      AppStore.feeds = feeds
    }

    if (AppStore.feeds === null || !AppStore.feeds) {
      findFeed()
    }
  }, [])

  const { id } = useParams<{ id: string }>()
  if (AppStore.feeds === null || !AppStore.feeds) {
    return <LoadingIndicator />
  }

  const item = AppStore.feeds.find(
    (f) => f.newsItems && f.newsItems.length > 0 && f.newsItems[0].id === id
  )
  if (!item) {
    return <Title>Uutista ei löytynyt</Title>
  }
  return (
    <ContentContainerBox>
      <FeedDetailContent item={item.newsItems[0]} />
    </ContentContainerBox>
  )
}

export default observer(FeedDetailScreen)
