import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import UiTouchable from '../../ui/UiTouchable'
import Spacings from '@taaleri/core/src/constants/Spacings'
import useLayout from '../../ui/useLayout'

interface Props {
  onPress?: () => void
  selected: boolean
  children: any
  style?: StyleProp<ViewStyle>
}

export function DateButton(props: Props) {
  return (
    <FilterButton
      {...props}
      style={[
        props.style,
        {
          backgroundColor: props.selected
            ? Colors.primary
            : Colors.backgroundPale,
          flexGrow: 0,
        },
      ]}
    />
  )
}

function FilterButton({ onPress, selected, children, style }: Props) {
  const { isSmall } = useLayout()
  return (
    <UiTouchable
      onPress={onPress}
      style={[
        {
          flexDirection: 'row',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: Colors.border,
          backgroundColor: selected ? Colors.backgroundPale : Colors.white,
          paddingVertical: 8,
          paddingHorizontal: 10,
          marginHorizontal: isSmall ? 0 : Spacings.S4,
          marginTop: Spacings.S10,
          alignItems: 'center',
          flexGrow: 1,
          maxWidth: '100%',
        },
        style,
      ]}
    >
      {children}
    </UiTouchable>
  )
}

export default FilterButton
