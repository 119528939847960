import React from 'react'
import FundInvestStartContent from '@taaleri/components/src/screens/funds/FundInvestStartContent'
import { ContentContainerWide } from 'ui/container/ContentContainer'
import Buttons from 'ui/Buttons'
import { FormikProps } from 'formik'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { observer } from 'mobx-react'
import { useFund } from '@taaleri/components/src/screens/funds/useFunds'
import LoadingIndicator from '@taaleri/components/src/ui/LoadingIndicator'
import { PaymentStepsFund } from '@taaleri/components/src/components/Steps'
import { useTranslation } from 'react-i18next'

function FundInvestStartScreen() {
  const { t } = useTranslation()
  const formRef = React.createRef<FormikProps<any>>()
  const { fund } = useFund(AppStore.fundId)

  if (!AppStore.fundId) {
    return null
  }

  if (!fund) {
    return <LoadingIndicator />
  }

  return (
    <>
      <PaymentStepsFund currentStep={0} />
      <ContentContainerWide>
        <FundInvestStartContent ref={formRef} fund={fund} />
        {!AppStore.loadingFund && (
          <Buttons
            title={t('button.continue')}
            onPress={() => {
              if (formRef.current) {
                formRef.current.handleSubmit()
              }
            }}
          />
        )}
      </ContentContainerWide>
    </>
  )
}

export default observer(FundInvestStartScreen)
