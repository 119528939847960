import { fundApiMock } from './fundApiMock'
import { fundApi } from './fundApi'
import { contractApiMock } from './contractApiMock'
import { IContractApi } from './IContractApi'
import { contractApi } from './contractApi'
import { versionApi } from './versionApi'
import { discountCodeApi } from './discountCodeApi'
import { versionApiMock } from './versionApiMock'
import { getConfig } from '../config'
import { authenticationApi } from './authenticationApi'
import { authenticationApiMock } from './authenticationApiMock'
import { customerApi } from './customerApi'
import { customerApiMock } from './customerApiMock'
import { savingsPlanApi } from './savingsPlanApi'
import { savingsPlanApiMock } from './savingsPlanApiMock'
import IAuthenticationApi from './IAuthenticationApi'
import ICustomerApi from './ICustomerApi'
import INewsfeedApi from './INewsfeedApi'
import IReportsApi from './IReportsApi'
import IOrdersApi from './IOrdersApi'
import IImpactApi from './IImpactApi'
import IAdminApi from './IAdminApi'

import INotificationsApi from './INotificationsApi'
import IPaymentsApi from './IPaymentsApi'
import { newsfeedApi } from './newsfeedApi'
import { newsfeedApiMock } from './newsfeedApiMock'
import { notificationsApi } from './notificationsApi'
import { notificationsApiMock } from './notificationsApiMock'
import { paymentsApiMock } from './paymentsApiMock'
import { paymentsApi } from './paymentsApi'
import { reportsApi } from './reportsApi'
import { reportsApiMock } from './reportsApiMock'
import { ordersApi } from './ordersApi'
import { ordersApiMock } from './ordersApiMock'
import { impactApi } from './impactApi'
import { adminApi } from './adminApi'
import { impactApiMock } from './impactApiMock'
import { adminApiMock } from './adminApiMock'
import { IVersionApi } from './IVersionApi'
import IFundApi from './IFundApi'
import ISavingsPlanApi from './ISavingsPlanApi'
import IDiscountCodeApi from './IDiscountCodeApi'
import { discountCodeApiMock } from './discountCodeApiMock'
import ISecurityPriceApi from './ISecurityPriceApi'
import { securityPricesApi } from './securityPriceApi'
import { securityPricesApiMock } from './securityPricesApiMock'

interface Api {
  authentication: IAuthenticationApi
  customer: ICustomerApi
  reports: IReportsApi
  newsfeed: INewsfeedApi
  notifications: INotificationsApi
  payments: IPaymentsApi
  orders: IOrdersApi
  impact: IImpactApi
  admin: IAdminApi
  versionApi: IVersionApi
  contract: IContractApi
  funds: IFundApi
  savingsPlan: ISavingsPlanApi
  discountCode: IDiscountCodeApi
  securityPrices: ISecurityPriceApi
}

const mockApi: Api = {
  authentication: authenticationApiMock,
  customer: customerApiMock,
  reports: reportsApiMock,
  newsfeed: newsfeedApiMock,
  notifications: notificationsApiMock,
  payments: paymentsApiMock,
  orders: ordersApiMock,
  impact: impactApiMock,
  admin: adminApiMock,
  versionApi: versionApiMock,
  contract: contractApiMock,
  funds: fundApiMock,
  savingsPlan: savingsPlanApiMock,
  discountCode: discountCodeApiMock,
  securityPrices: securityPricesApiMock,
}

const apiBack: Api = {
  authentication: authenticationApi,
  customer: customerApi,
  reports: reportsApi,
  newsfeed: newsfeedApi,
  notifications: notificationsApi,
  payments: paymentsApi,
  orders: ordersApi,
  impact: impactApi,
  admin: adminApi,
  versionApi,
  contract: contractApi,
  funds: fundApi,
  savingsPlan: savingsPlanApi,
  discountCode: discountCodeApi,
  securityPrices: securityPricesApi,
}

export const api = (): Api => {
  return getConfig().useMockAPI ? mockApi : apiBack
}
