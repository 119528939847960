import React from 'react'
import { View } from 'react-native'
import { observer } from 'mobx-react'
import { TextDefault } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import FlexRow from '../../ui/FlexRow'
import UiTouchable from '../../ui/UiTouchable'
import { IconPlus } from '../../ui/Icons'
import Colors from '@taaleri/core/src/constants/Colors'
import { IconArrowRight } from '../../ui/icons/ArrowIcons'

interface Props {
  title: string
  subtitle: string
  onPrimaryButtonPress?: () => void
  onSecondaryButtonPress?: () => void
  showButton?: boolean
}

function ReportBoxHeader({
  title,
  subtitle,
  showButton,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
}: Props) {
  return (
    <View style={{ marginBottom: Spacings.S16 }}>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <View>
          <TextDefault type="h5">{title}</TextDefault>
          <TextDefault type="t2">{subtitle}</TextDefault>
        </View>
        <FlexRow>
          {showButton && onSecondaryButtonPress && (
            <UiTouchable
              style={{
                width: 40,
                height: 40,
                borderWidth: 1,
                borderRadius: 6,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={onSecondaryButtonPress}
            >
              <IconPlus color={Colors.text} />
            </UiTouchable>
          )}
          {showButton && onPrimaryButtonPress && (
            <UiTouchable
              style={{
                width: 40,
                height: 40,
                borderRadius: 6,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.primary,
                marginLeft: Spacings.S16,
              }}
              onPress={onPrimaryButtonPress}
            >
              <IconArrowRight color={Colors.white} />
            </UiTouchable>
          )}
        </FlexRow>
      </FlexRow>
    </View>
  )
}

export default observer(ReportBoxHeader)
