import { Identity } from '../models/Identity'
import { getConfig } from '../config'
import logger from '../utils/logger'
import INotificationsApi from './INotificationsApi'
import { post } from './rest'

async function setPushNotificationToken(
  identity: Identity,
  token: string
): Promise<boolean> {
  const { accessToken } = identity
  const body = {
    value: token,
  }

  const response = await post(
    `${getConfig().apiUrl}/notifications/grant`,
    accessToken,
    body
  )

  logger.devInfo('setPushNotificationToken response', response)

  return response.isSuccess ? true : false
}

export const notificationsApi: INotificationsApi = {
  setPushNotificationToken,
}
