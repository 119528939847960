import {
  ROUTE_IMPACT,
  ROUTE_IMPACT_INTRO,
} from '@taaleri/components/src/screens/impact/ImpactRoutes'
import { ROUTE_PORTFOLIO } from '@taaleri/components/src/screens/portfolio/PortfolioRoutes'
import { ROUTE_APP } from '@taaleri/components/src/navigation/routes'

import routePath, {
  routePathApp,
} from '@taaleri/components/src/navigation/routeHelper'
import { ROUTE_PROFILE } from '@taaleri/components/src/screens/profile/ProfileRoutes'
import {
  IconHome,
  IconPortfolio,
  IconProfile,
  IconImpact,
  IconReport,
} from '@taaleri/components/src/ui/Icons'
import AppStore from '@taaleri/core/src/stores/AppStore'
import { ROUTE_REPORT } from '@taaleri/components/src/screens/report/ReportRoutes'
import { TFunction } from 'i18next'

interface NavItem {
  title: string
  path: string
  icon: any
}

function navItems(showPortfolio: boolean, showImpact: boolean, t: TFunction): NavItem[] {
  const allItems = [
    {
      title: t('menu.frontpage'),
      path: routePath(ROUTE_APP),
      icon: IconHome,
    },
    {
      title: t('menu.portfolio'),
      path: routePathApp(ROUTE_PORTFOLIO),
      icon: IconPortfolio,
    },
    // {
    //   title: t('menu.impact'),
    //   path: AppStore.isDigiEtfCustomer
    //     ? routePathApp(ROUTE_IMPACT_INTRO)
    //     : routePathApp(ROUTE_IMPACT),
    //   icon: IconImpact,
    // },
    {
      title: t('menu.reports'),
      path: routePathApp(ROUTE_REPORT),
      icon: IconReport,
    },
    {
      title: t('menu.information'),
      path: routePathApp(ROUTE_PROFILE),
      icon: IconProfile,
    },
  ]

  return allItems.filter(
    (item) =>
      (showImpact || item.title !== t('menu.impact')) &&
      (showPortfolio || item.title !== t('menu.portfolio'))
  )
}

export default navItems
