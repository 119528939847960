import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native'

import Svg from 'react-native-svg'

import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { TextDefault } from '../ui/text/UiText'
import {
  toFullPercentage,
  toFullPercentageText,
} from '@taaleri/core/src/utils/format'
import PercentagePie from './PercentagePie'

const PercentagePieWithNumber: React.FC<{
  percentages: number[]
  percentage: number
  theme: 'primary' | 'white' | 'ghost'
  size?: number
  borderWidth?: number
  style?: StyleProp<ViewStyle>
}> = ({
  percentages,
  percentage,
  theme = 'primary',
  size = 68,
  style,
  borderWidth,
}) => {
  const sortedPercentages = percentages.sort((a, b) => b - a)
  const pieRadius = size / 2
  const data = sortedPercentages.map((p) => {
    const x = p === percentage ? 'current' : 'other'
    return { x, y: toFullPercentage(p) }
  })

  const isWhite = theme === 'white'
  const isGhost = theme === 'ghost'

  const colorScale = sortedPercentages.map((p) =>
    p === percentage && isWhite ? Colors.white : Colors.white40
  )

  let textColor = Colors.white40
  if (isGhost) {
    textColor = Colors.white70
  } else if (isWhite) {
    textColor = Colors.white
  }

  const borderSize = borderWidth ? borderWidth : size / 17

  return (
    <View style={[style, { width: size, height: size }]}>
      <Svg width={size} height={size}>
        <PercentagePie
          pieRadius={pieRadius}
          data={data}
          colorScale={colorScale}
          size={size}
          borderSize={borderSize}
        />
      </Svg>
      <View style={styles.percentageContainer}>
        <TextDefault
          type="h5"
          style={{
            color: textColor,
            textAlign: 'center',
          }}
        >
          {`${toFullPercentageText(percentage)} %`}
        </TextDefault>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  percentageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default PercentagePieWithNumber
