import React from 'react'
import { UiTextProps, TextDefault } from './UiText'

export const Paragraph: React.FC<UiTextProps> = (props) => (
  <TextDefault
    {...props}
    type={props.type || 't1'}
    style={[{ marginBottom: 22 }, props.style]}
  />
)

/*
VR 5.2.2021: First word in every info box must be bolded. 
Use hard coded font size.
*/
export const ParagraphBoldFirst: React.FC<UiTextProps> = (props) => {
  if (props.children && typeof props.children === 'string') {
    const sentence = props.children.split(' ')
    if (sentence.length > 1) {
      return (
        <TextDefault {...props}>
          <TextDefault {...props} type="h6">
            {`${sentence[0]} `}
          </TextDefault>
          <TextDefault {...props} type="t2">
            {sentence.slice(1).join(' ')}
          </TextDefault>
        </TextDefault>
      )
    }
  }
  return <TextDefault {...props} type="t2" style={props.style} />
}
