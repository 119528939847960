import { DateButton } from '@taaleri/components/src/screens/report/FilterButton'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiTextInput from '@taaleri/components/src/ui/form/UiTextInput'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import useLayout from '@taaleri/components/src/ui/useLayout'
import Colors from '@taaleri/core/src/constants/Colors'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import Fonts from '@taaleri/core/src/constants/Fonts'
import { translateFilterItem } from '@taaleri/core/src/services/translationService'
import ReportStore from '@taaleri/core/src/stores/ReportStore'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import isDate from 'date-fns/is_date'
import isFuture from 'date-fns/is_future'
import isToday from 'date-fns/is_today'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import DayPicker from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import styled from 'styled-components'
import './day-picker.css'
import * as Yup from 'yup'

import { ToggleProps, FilterBox } from './ReportFiltersDesktop'

const Select = styled.select`
  border: 1px solid ${Colors.border};
  border-radius: 6px;
  padding: 5px;
  option {
    font-family: ${Fonts.avenirNextMedium};
    font-size: 14px;
  }
`

const currentYear = new Date().getFullYear()
const toMonth = new Date(currentYear, 11)
const months = [
  'datetime.january',
  'datetime.february',
  'datetime.march',
  'datetime.april',
  'datetime.may',
  'datetime.june',
  'datetime.july',
  'datetime.august',
  'datetime.september',
  'datetime.october',
  'datetime.november',
  'datetime.december',
]

function tooEarlyDate(date: Date, isPortfolioScreen: boolean): boolean {
  const IsConversionRestricted =
    ReportStore.additionalFilterRestrictions && isPortfolioScreen
  return (
    date <
    (IsConversionRestricted
      ? ReportStore.earliestPossibleConvertedDate
      : ReportStore.earliestPossibleDate)
  )
}

function YearMonthForm({
  date,
  localeUtils,
  onChange,
}: {
  date: any
  localeUtils: any
  onChange: any
}) {
  const allMonths = localeUtils.getMonths()
  const { t } = useTranslation()

  const years: number[] = []
  const fromDate = ReportStore.earliestPossibleDate
  for (let i = fromDate.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  function handleChange(e: any) {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <Select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        style={{ marginRight: 10 }}
      >
        {allMonths.map((month: string, i: number) => (
          <option key={month} value={i}>
            {t(months[i])}
          </option>
        ))}
      </Select>
      <Select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </form>
  )
}

interface DayPickerProps {
  value: string
  onChange: (value: string) => void
  isPortfolioScreen: boolean
}

function DayPickerCustom({
  onChange,
  value,
  isPortfolioScreen,
}: DayPickerProps) {
  const { t } = useTranslation()
  const [date, setDate] = useState<Date>(new Date(parseDate(value)))

  return (
    <DayPicker
      numberOfMonths={1}
      selectedDays={date}
      initialMonth={date}
      month={date}
      months={months}
      showOutsideDays={true}
      disabledDays={(d: Date) =>
        isToday(d) || isFuture(d) || tooEarlyDate(d, isPortfolioScreen)
      }
      onDayClick={(day: Date, modifiers: any, e: any) => {
        if (
          !(
            isToday(day) ||
            isFuture(day) ||
            tooEarlyDate(day, isPortfolioScreen)
          )
        ) {
          setDate(day)
          onChange(formatDateFull(day.toString()))
        }
      }}
      firstDayOfWeek={1}
      weekdaysShort={[
        t('datetime.sunday-short'),
        t('datetime.monday-short'),
        t('datetime.tuesday-short'),
        t('datetime.wednesday-short'),
        t('datetime.thursday-short'),
        t('datetime.friday-short'),
        t('datetime.saturday-short'),
      ]}
      weekdaysLong={[
        t('datetime.sunday'),
        t('datetime.monday'),
        t('datetime.tuesday'),
        t('datetime.wednesday'),
        t('datetime.thursday'),
        t('datetime.friday'),
        t('datetime.saturday'),
      ]}
      captionElement={(props) => (
        <YearMonthForm
          date={props.date}
          localeUtils={props.localeUtils}
          onChange={(monthNew: Date) => {
            setDate(monthNew)
            if (
              !(
                isToday(monthNew) ||
                isFuture(monthNew) ||
                tooEarlyDate(monthNew, isPortfolioScreen)
              )
            ) {
              onChange(formatDateFull(monthNew.toString()))
            }
          }}
        />
      )}
      tabIndex={-1}
    />
  )
}

function parseDate(value: string) {
  const items = value.split('.')
  if (items.length === 3) {
    return `${items[2]}-${items[1]}-${items[0]}`
  }
  return value
}

function parseDateString(value: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : new Date(parseDate(originalValue))

  return parsedDate
}

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .transform(parseDateString)
    .required(ErrorMessages.required),
  endDate: Yup.date()
    .transform(parseDateString)
    .required(ErrorMessages.required)
    .when(
      'startDate',
      (eventStartDate: any, schema: any) =>
        eventStartDate && schema.min(eventStartDate)
    ),
})

function DateFilter({ onToggle, isPortfolioScreen }: ToggleProps) {
  const { t } = useTranslation()
  const { isMediumPlus } = useLayout()
  return (
    <FilterBox style={{ paddingTop: 28, paddingBottom: 28 }}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          startDate: formatDateFull(ReportStore.startDate),
          endDate: formatDateFull(ReportStore.endDate),
        }}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {({ isValid, values, setFieldValue }) => (
          <FlexRow>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {ReportStore.dateRangeValues().map((item) => {
                const selected = ReportStore.dateRange === item.id

                return (
                  <DateButton
                    key={item.id}
                    selected={selected}
                    onPress={() => {
                      if (item.id === 'LAST_YEAR') {
                        ReportStore.resetRestrictionFilters()
                      }
                      ReportStore.setDateRange(item.id)
                      ReportStore.fetchData()
                      onToggle(false)
                    }}
                  >
                    <TextDefault
                      type="t2"
                      style={{
                        color: selected ? Colors.white : Colors.text,
                      }}
                    >
                      {translateFilterItem(item, t)}
                    </TextDefault>
                  </DateButton>
                )
              })}
            </View>

            {isMediumPlus && (
              <View
                style={{
                  flex: 2.5,
                  paddingHorizontal: 20,
                  borderLeftWidth: 1,
                  borderLeftColor: Colors.border,
                }}
              >
                <FlexRow>
                  <View
                    style={{
                      flex: 1,
                      marginRight: 10,
                    }}
                  >
                    <DayPickerCustom
                      value={values.startDate}
                      onChange={(value: string) => {
                        setFieldValue('startDate', value)
                      }}
                      isPortfolioScreen={isPortfolioScreen === true}
                    />
                    <UiTextInput
                      name="startDate"
                      hideCheckCircle={true}
                      editable={false}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginLeft: 10,
                      flexDirection: 'column-reverse',
                    }}
                  >
                    <UiTextInput
                      name="endDate"
                      value={values.endDate}
                      hideCheckCircle={true}
                      editable={false}
                    />
                    <DayPickerCustom
                      value={values.endDate}
                      onChange={(value: string) => {
                        setFieldValue('endDate', value)
                      }}
                      isPortfolioScreen={isPortfolioScreen === true}
                    />
                  </View>
                </FlexRow>
                <FlexRow style={{}}>
                  <UiButton
                    size="small"
                    title={`${t('button.confirm-changes')}`}
                    type={isValid ? 'primary' : 'disabled'}
                    onPress={() => {
                      onToggle(false)
                      const parsedEndDate = parseDate(values.endDate)
                      if (ReportStore.endDate !== parsedEndDate) {
                        ReportStore.resetRestrictionFilters()
                      }
                      ReportStore.customDateChanged(
                        parseDate(values.startDate),
                        parsedEndDate
                      )
                    }}
                  />
                </FlexRow>
              </View>
            )}
          </FlexRow>
        )}
      </Formik>
    </FilterBox>
  )
}

export default observer(DateFilter)
