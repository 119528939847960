import React from 'react'
import { View } from 'react-native'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import PaymentInfoSection from '../portfolio/PaymentInfoSection'
import AppStore from '@taaleri/core/src/stores/AppStore'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

function MonthlyContractPaymentContent() {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('steps.payment')}</Title>
      <Paragraph>
        {t('invest.monthly-payment-info', { paymentDay: AppStore.paymentDay })}
      </Paragraph>
      <PaymentInfoSection isConsultative={true} sum={AppStore.newInvestment} />
      <Paragraph style={{ marginTop: Spacings.S40 }}>
        {t('invest.monthly-payment-info-desc')}
      </Paragraph>
    </View>
  )
}

export default observer(MonthlyContractPaymentContent)
