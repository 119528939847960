import React from 'react'
import Title from '../../ui/text/Title'
import { Paragraph } from '../../ui/text/Paragraph'
import { UiTextInputFormAutoFocus } from '../../ui/form/UiTextInput'
import * as Yup from 'yup'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { StyleSheet, View } from 'react-native'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { useTranslation } from 'react-i18next'

export const questionCapitalIncomeValidationSchema = Yup.object().shape({
  capitalIncome: Yup.number()
    .required(ErrorMessages.required)
    .typeError(ErrorMessages.financeIncomeAsNumbers)
    .min(0, ErrorMessages.incomeMustBePositive),
})

export function toInitValue(value: any) {
  return !value || value === 0 ? '' : value.toString()
}

export function toSaveValue(value: any) {
  return !value || (typeof value === 'string' && value.length === 0)
    ? 0
    : Number(value)
}

export interface CapitalIncome {
  capitalIncome: number
}

export default function QuestionCapitalIncomeContent() {
  const { t } = useTranslation()
  return (
    <View>
      <Title>{t('questions.capitalIncome.question')}</Title>

      <Paragraph style={styles.paragraph}>
       {t('questions.capitalIncome.helper')}
      </Paragraph>
      <UiTextInputFormAutoFocus
        grayBackground={true}
        name="capitalIncome"
        label={`${t('questions.finance.monthly-income')}`}
        type="digits"
        returnKeyType="next"
        maxLength={6}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  paragraph: {
    marginBottom: Spacings.S24,
  },
})
