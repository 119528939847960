import React, { useState } from 'react'
import DayPicker from 'react-day-picker'
import styled from 'styled-components'
import './day-picker.css'
import * as Yup from 'yup'
import isDate from 'date-fns/is_date'
import isToday from 'date-fns/is_today'
import isFuture from 'date-fns/is_future'
import { dateRangeValues } from '@taaleri/core/src/constants/DateRange'
import { Formik } from 'formik'
import { View } from 'react-native'
import Colors from '@taaleri/core/src/constants/Colors'
import FlexRow from '@taaleri/components/src/ui/FlexRow'
import { TextDefault } from '@taaleri/components/src/ui/text/UiText'
import UiTextInput from '@taaleri/components/src/ui/form/UiTextInput'
import UiButton from '@taaleri/components/src/ui/UiButton'
import { ErrorMessages } from '@taaleri/core/src/constants/ErrorMessages'
import { formatDateFull } from '@taaleri/core/src/utils/format'
import Fonts from '@taaleri/core/src/constants/Fonts'
import BorderRadius from '@taaleri/core/src/constants/BorderRadius'
import { DateFilterProps } from './DateFilterProps'
import useLayout from '../../../ui/useLayout'
import { DateButton } from '../../report/FilterButton'
import { useTranslation } from 'react-i18next'

const FilterBox = styled.div`
  margin-top: 20px;
  border: 1px solid ${Colors.border};
  border-radius: ${BorderRadius.default}px;
  padding: 12px;
`

const Select = styled.select`
  border: 1px solid ${Colors.border};
  border-radius: 6px;
  padding: 5px;
  option {
    font-family: ${Fonts.avenirNextMedium};
    font-size: 14px;
  }
`

const currentYear = new Date().getFullYear()
const fromMonth = new Date(2007, 0)
const toMonth = new Date(currentYear, 11)
const months = [
  'datetime.january',
  'datetime.february',
  'datetime.march',
  'datetime.april',
  'datetime.may',
  'datetime.june',
  'datetime.july',
  'datetime.august',
  'datetime.september',
  'datetime.october',
  'datetime.november',
  'datetime.december',
]

function YearMonthForm({
  date,
  localeUtils,
  onChange,
}: {
  date: any
  localeUtils: any
  onChange: any
}) {
  const { t } = useTranslation()
  const allMonths = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  function handleChange(e: any) {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <Select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        style={{ marginRight: 10 }}
      >
        {allMonths.map((month: string, i: number) => (
          <option key={month} value={i}>
            {t(months[i])}
          </option>
        ))}
      </Select>
      <Select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </form>
  )
}

interface DayPickerProps {
  value: string
  onChange: (value: string) => void
}

function DayPickerCustom({ onChange, value }: DayPickerProps) {
  const { t } = useTranslation()
  const [date, setDate] = useState<Date>(new Date(parseDate(value)))
  return (
    <DayPicker
      numberOfMonths={1}
      selectedDays={date}
      initialMonth={date}
      month={date}
      months={months}
      showOutsideDays={true}
      disabledDays={(d: Date) => isToday(d) || isFuture(d)}
      onDayClick={(day: Date, _: any, _1: any) => {
        setDate(day)
        onChange(formatDateFull(day.toString()))
      }}
      firstDayOfWeek={1}
      weekdaysShort={[
        t('datetime.sunday-short'),
        t('datetime.monday-short'),
        t('datetime.tuesday-short'),
        t('datetime.wednesday-short'),
        t('datetime.thursday-short'),
        t('datetime.friday-short'),
        t('datetime.saturday-short'),
      ]}
      weekdaysLong={[
        t('datetime.sunday'),
        t('datetime.monday'),
        t('datetime.tuesday'),
        t('datetime.wednesday'),
        t('datetime.thursday'),
        t('datetime.friday'),
        t('datetime.saturday'),
      ]}
      captionElement={(props) => (
        <YearMonthForm
          date={props.date}
          localeUtils={props.localeUtils}
          onChange={(monthNew: Date) => {
            setDate(monthNew)
            onChange(formatDateFull(monthNew.toString()))
          }}
        />
      )}
      tabIndex={-1}
    />
  )
}

function parseDate(value: string) {
  const items = value.split('.')
  if (items.length === 3) {
    return `${items[2]}-${items[1]}-${items[0]}`
  }
  return value
}

function parseDateString(_: string, originalValue: string) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : new Date(parseDate(originalValue))

  return parsedDate
}

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .transform(parseDateString)
    .required(ErrorMessages.required),
  endDate: Yup.date()
    .transform(parseDateString)
    .required(ErrorMessages.required)
    .when(
      'startDate',
      (eventStartDate: any, schema: any) =>
        eventStartDate && schema.min(eventStartDate)
    ),
})

// string has to be in format DD.MM.YYYY
function toDate(date: string): Date {
  try {
    const items = date.split('.')
    return new Date(
      parseInt(items[2], 10),
      parseInt(items[1], 10) - 1,
      parseInt(items[0], 10)
    )
  } catch (e) {
    throw new Error(`Parsing date failed, date: ${date}`)
  }
}

function DateFilterWide({
  onConfirm,
  onDateRangePress,
  dateRange,
  initialStartDate,
  initialEndDate,
}: DateFilterProps) {
  const { t } = useTranslation()
  const { isWide } = useLayout()
  return (
    <FilterBox style={{ paddingTop: 28, paddingBottom: 28 }}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          startDate: formatDateFull(initialStartDate),
          endDate: formatDateFull(initialEndDate),
        }}
        onSubmit={() => {
          return
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, values, setFieldValue }) => (
          <FlexRow>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                borderRightWidth: isWide ? 1 : 0,
                borderRightColor: Colors.border,
              }}
            >
              {dateRangeValues(t).map((item) => {
                const selected = dateRange === item.id
                return (
                  <DateButton
                    key={item.id}
                    selected={selected}
                    onPress={() => onDateRangePress(item.id)}
                  >
                    <TextDefault
                      type="t2"
                      style={{
                        color: selected ? Colors.white : Colors.text,
                      }}
                    >
                      {item.value}
                    </TextDefault>
                  </DateButton>
                )
              })}
            </View>
            {isWide && (
              <View style={{ flex: 3, paddingHorizontal: 20 }}>
                <FlexRow>
                  <View
                    style={{
                      flex: 1,
                      marginRight: 10,
                    }}
                  >
                    <DayPickerCustom
                      value={values.startDate}
                      onChange={(value: string) => {
                        setFieldValue('startDate', value)
                      }}
                    />
                    <UiTextInput
                      name="startDate"
                      hideCheckCircle={true}
                      editable={false}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      marginLeft: 10,
                      flexDirection: 'column-reverse',
                    }}
                  >
                    <UiTextInput
                      name="endDate"
                      value={values.endDate}
                      hideCheckCircle={true}
                      editable={false}
                    />
                    <DayPickerCustom
                      value={values.endDate}
                      onChange={(value: string) => {
                        setFieldValue('endDate', value)
                      }}
                    />
                  </View>
                </FlexRow>
                <FlexRow style={{}}>
                  <UiButton
                    size="small"
                    title={`${t('button.confirm-changes')}`}
                    type={isValid ? 'primary' : 'disabled'}
                    onPress={() => {
                      const start = toDate(values.startDate)
                      const end = toDate(values.endDate)

                      onConfirm(start, end)
                    }}
                  />
                </FlexRow>
              </View>
            )}
          </FlexRow>
        )}
      </Formik>
    </FilterBox>
  )
}

export default DateFilterWide
