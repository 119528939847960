import { View } from 'react-native'
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg'
import Colors from '@taaleri/core/src/constants/Colors'
import React from 'react'
import { IconProps } from '../IconProps'

export function IconBuyOrder({ style, color = Colors.primary }: IconProps) {
  return (
    <View style={style}>
      <Svg width="20" height="21" viewBox="0 0 20 21" fill="none">
        <G clipPath="url(#clip0)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.37235 0.5H10.1277C10.4967 0.499972 10.8558 0.612015 11.1538 0.819156C11.4517 1.02623 11.6732 1.31753 11.7886 1.65094L12.4677 3.61776H14V3.2204C14 2.86589 14.3013 2.57851 14.6731 2.57851C15.0448 2.57851 15.3462 2.86589 15.3462 3.2204V3.61776H15.75C16.724 3.61776 17.5 4.37762 17.5 5.29891V9.27252C17.5 9.62703 17.1987 9.91441 16.8269 9.91441C16.4552 9.91441 16.1538 9.62703 16.1538 9.27252V5.29891C16.1538 5.07227 15.9655 4.90154 15.75 4.90154H1.75C1.53445 4.90154 1.34615 5.07227 1.34615 5.29891V14.6522C1.34615 14.8788 1.53445 15.0495 1.75 15.0495H8.20559C8.57732 15.0495 8.87867 15.3369 8.87867 15.6914C8.87867 16.0459 8.57732 16.3333 8.20559 16.3333H1.75C0.776014 16.3333 0 15.5735 0 14.6522V5.29891C0 4.37762 0.776015 3.61776 1.75 3.61776H2.15385V3.2204C2.15385 2.86589 2.45519 2.57851 2.82692 2.57851C3.19865 2.57851 3.5 2.86589 3.5 3.2204V3.61776H5.03225L5.71131 1.65127C5.82665 1.31787 6.04833 1.02623 6.34623 0.819156C6.64423 0.612015 7.00326 0.499972 7.37235 0.5ZM6.44952 3.61776H11.0505L10.5102 2.05316C10.4824 1.97303 10.4296 1.90436 10.3609 1.8566C10.2922 1.80886 10.2107 1.78377 10.1278 1.78378H7.37226C7.28944 1.78377 7.20783 1.80886 7.13915 1.8566C7.07044 1.90436 7.01772 1.97269 6.98992 2.05283L6.44952 3.61776ZM3.23077 7.89704C3.23077 7.54253 3.53212 7.25515 3.90385 7.25515H13.5962C13.9679 7.25515 14.2692 7.54253 14.2692 7.89704C14.2692 8.25155 13.9679 8.53893 13.5962 8.53893H3.90385C3.53212 8.53893 3.23077 8.25155 3.23077 7.89704ZM3.23077 12.0541C3.23077 11.6995 3.53212 11.4122 3.90385 11.4122H8.75C9.12173 11.4122 9.42308 11.6995 9.42308 12.0541C9.42308 12.4086 9.12173 12.6959 8.75 12.6959H3.90385C3.53212 12.6959 3.23077 12.4086 3.23077 12.0541Z"
            fill={color}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 11.9583C13.044 11.9583 11.4583 13.544 11.4583 15.5C11.4583 17.456 13.044 19.0417 15 19.0417C16.956 19.0417 18.5417 17.456 18.5417 15.5C18.5417 13.544 16.956 11.9583 15 11.9583ZM10.2083 15.5C10.2083 12.8536 12.3536 10.7083 15 10.7083C17.6464 10.7083 19.7917 12.8536 19.7917 15.5C19.7917 18.1464 17.6464 20.2917 15 20.2917C12.3536 20.2917 10.2083 18.1464 10.2083 15.5Z"
            fill={color}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9106 13.401C15.2558 13.401 15.5356 13.6808 15.5356 14.026V16.9723C15.5356 17.3175 15.2558 17.5973 14.9106 17.5973C14.5655 17.5973 14.2856 17.3175 14.2856 16.9723V14.026C14.2856 13.6808 14.5655 13.401 14.9106 13.401Z"
            fill={color}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8125 15.4991C12.8125 15.154 13.0923 14.8741 13.4375 14.8741H16.3838C16.729 14.8741 17.0088 15.154 17.0088 15.4991C17.0088 15.8443 16.729 16.1241 16.3838 16.1241H13.4375C13.0923 16.1241 12.8125 15.8443 12.8125 15.4991Z"
            fill={color}
          />
        </G>
        <Defs>
          <ClipPath id="clip0">
            <Path d="M0 0.5H20V20.5H0V0.5Z" fill="white" />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  )
}

export function IconWithdrawOrder({
  style,
  color = Colors.warning,
}: IconProps) {
  return (
    <View style={style}>
      <Svg width="20" height="21" viewBox="0 0 20 21" fill="none">
        <G clipPath="url(#clip0)">
          <Path
            d="M6.25 15.5H1.875C0.841667 15.5 0 14.6583 0 13.625V6.75C0 5.71667 0.841667 4.875 1.875 4.875H17.5C18.5333 4.875 19.375 5.71667 19.375 6.75V14.0417C19.375 14.3833 19.0917 14.6667 18.75 14.6667C18.4083 14.6667 18.125 14.3833 18.125 14.0417V6.75C18.125 6.40833 17.8417 6.125 17.5 6.125H1.875C1.53333 6.125 1.25 6.40833 1.25 6.75V13.625C1.25 13.9667 1.53333 14.25 1.875 14.25H6.25C6.59167 14.25 6.875 14.5333 6.875 14.875C6.875 15.2167 6.59167 15.5 6.25 15.5Z"
            fill={color}
          />
          <Path
            d="M5.62504 20.5C5.28338 20.5 5.00004 20.2167 5.00004 19.875V17.9917L4.40004 17.15C4.20004 16.8667 4.26671 16.475 4.54171 16.275C4.82504 16.075 5.21671 16.1417 5.41671 16.4167L6.13338 17.425C6.20838 17.5333 6.25004 17.6583 6.25004 17.7917V19.875C6.25004 20.2167 5.96671 20.5 5.62504 20.5Z"
            fill={color}
          />
          <Path
            d="M3.75 6.12499C3.40833 6.12499 3.125 5.84166 3.125 5.49999V4.42499C3.125 3.39166 3.96667 2.54999 5 2.54999C6.03333 2.54999 6.875 3.39166 6.875 4.42499C6.875 4.76666 6.59167 5.04999 6.25 5.04999C5.90833 5.04999 5.625 4.77499 5.625 4.42499C5.625 4.08333 5.34167 3.79999 5 3.79999C4.65833 3.79999 4.375 4.08333 4.375 4.42499V5.49999C4.375 5.84166 4.09167 6.12499 3.75 6.12499Z"
            fill={color}
          />
          <Path
            d="M8.75 6.12499C8.40833 6.12499 8.125 5.84166 8.125 5.49999V3.59166C8.125 3.24999 7.84167 2.96666 7.5 2.96666C7.15833 2.96666 6.875 3.24999 6.875 3.59166V5.49999C6.875 5.84166 6.59167 6.12499 6.25 6.12499C5.90833 6.12499 5.625 5.84166 5.625 5.49999V3.59166C5.625 2.55833 6.46667 1.71666 7.5 1.71666C8.53333 1.71666 9.375 2.55833 9.375 3.59166V5.49999C9.375 5.84166 9.09167 6.12499 8.75 6.12499Z"
            fill={color}
          />
          <Path
            d="M11.25 6.125C10.9083 6.125 10.625 5.84167 10.625 5.5V2.375C10.625 2.03333 10.3417 1.75 10 1.75C9.65833 1.75 9.375 2.03333 9.375 2.375V5.49999C9.375 5.84166 9.09167 6.12499 8.75 6.12499C8.40833 6.12499 8.125 5.84166 8.125 5.49999V2.375C8.125 1.34167 8.96667 0.5 10 0.5C11.0333 0.5 11.875 1.34167 11.875 2.375V5.5C11.875 5.84167 11.5917 6.125 11.25 6.125Z"
            fill={color}
          />
          <Path
            d="M13.75 6.12499C13.4083 6.12499 13.125 5.84166 13.125 5.49999V3.59166C13.125 3.24999 12.8417 2.96666 12.5 2.96666C12.1583 2.96666 11.875 3.24999 11.875 3.59166V4.45833C11.875 4.79999 11.5917 5.08333 11.25 5.08333C10.9083 5.08333 10.625 4.79999 10.625 4.45833V3.59166C10.625 2.55833 11.4667 1.71666 12.5 1.71666C13.5333 1.71666 14.375 2.55833 14.375 3.59166V5.49999C14.375 5.84166 14.0917 6.12499 13.75 6.12499Z"
            fill={color}
          />
          <Path
            d="M13.1001 20.5C12.7585 20.5 12.4751 20.2167 12.4751 19.875V19.0417C12.4751 18.325 12.7918 17.6583 13.3585 17.2167L15.2085 15.725C15.4668 15.5167 15.6251 15.2083 15.6168 14.8833V11.1083C15.6168 10.7833 15.3501 10.5167 15.0251 10.5083C14.5751 10.5083 14.4251 11.05 14.3918 11.2167L14.1668 12.9C14.0751 13.5917 13.6751 14.2 13.0751 14.5583L11.7501 15.3417C11.4418 15.5333 11.2418 15.875 11.2501 16.25V17.3667C11.2501 17.7083 10.9668 17.9917 10.6251 17.9917C10.2835 17.9917 10.0001 17.7167 10.0001 17.375V16.2583C9.99179 15.4583 10.4168 14.6917 11.1085 14.275L12.4335 13.4917C12.7001 13.325 12.8835 13.05 12.9251 12.7333L13.1668 11.0083C13.3335 10.175 13.9168 9.25 15.0418 9.25C16.0668 9.275 16.8668 10.0833 16.8751 11.0917V14.875C16.8751 15.5917 16.5585 16.2583 15.9918 16.7L14.1418 18.1917C13.8751 18.4 13.7251 18.7083 13.7335 19.0333V19.8667C13.7251 20.2167 13.4501 20.5 13.1001 20.5Z"
            fill={color}
          />
          <Path
            d="M9.125 13.3917C8.375 13.3917 7.64167 13.0333 7.175 12.4167C6.96667 12.1417 7.01667 11.75 7.29167 11.5417C7.56667 11.3333 7.95833 11.3833 8.16667 11.6583C8.40833 11.975 8.79167 12.1417 9.19167 12.1417C9.85833 12.1417 10.3083 11.7917 10.3083 11.475C10.3083 11.1583 9.85833 10.8083 9.21667 10.8083C7.9 10.8083 6.875 9.96666 6.875 8.89166C6.875 7.81666 7.90833 6.975 9.21667 6.975C9.99167 6.94166 10.775 7.30833 11.2583 7.95C11.4667 8.225 11.4167 8.61666 11.1417 8.825C10.8667 9.03333 10.475 8.98333 10.2667 8.70833C10.025 8.39166 9.65 8.20833 9.24167 8.225C8.575 8.225 8.125 8.575 8.125 8.89166C8.125 9.20833 8.575 9.55833 9.21667 9.55833C10.5333 9.55833 11.5583 10.4 11.5583 11.475C11.5583 12.55 10.525 13.3917 9.21667 13.3917C9.18333 13.3917 9.15833 13.3917 9.125 13.3917Z"
            fill={color}
          />
          <Path
            d="M9.21671 14.25C8.87505 14.25 8.59171 13.9667 8.59171 13.625V12.7667C8.59171 12.425 8.87505 12.1417 9.21671 12.1417C9.55838 12.1417 9.84171 12.425 9.84171 12.7667V13.625C9.84171 13.9667 9.56672 14.25 9.21671 14.25Z"
            fill={color}
          />
          <Path
            d="M9.21671 8.23333C8.87505 8.23333 8.59171 7.95 8.59171 7.60833V6.75C8.59171 6.40833 8.87505 6.125 9.21671 6.125C9.55838 6.125 9.84171 6.40833 9.84171 6.75V7.60833C9.84171 7.95 9.56672 8.23333 9.21671 8.23333Z"
            fill={color}
          />
        </G>
        <Defs>
          <ClipPath id="clip0">
            <Path d="M0 0.5H20V20.5H0V0.5Z" fill="white" />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  )
}
