import React from 'react'
import { View } from 'react-native'
import { IconAlert } from '../ui/Icons'
import Spacings from '@taaleri/core/src/constants/Spacings'
import Title from '../ui/text/Title'
import { PaddingHorizontal } from '../ui/PaddingHorizontal'

interface Props {
  title: string
  children?: any
}

export default function ErrorMessage({ title, children }: Props) {
  return (
    <View style={{ alignItems: 'center' }}>
      <IconAlert
        style={{
          alignSelf: 'center',
          marginTop: 48,
          marginBottom: Spacings.S16,
        }}
      />
      <PaddingHorizontal>
        <Title style={{ textAlign: 'center' }}>{title}</Title>
        {children}
      </PaddingHorizontal>
    </View>
  )
}
