import { Dimensions, Platform } from 'react-native'
import { isWeb } from '@taaleri/components/src/constants/Platforms'
import { BreakPoint } from '@taaleri/components/src/constants/BreakPoint'

const iphoneSEUiKitWidth = 320

export function isNarrowDevice(): boolean {
  const { width } = Dimensions.get('window')
  return width <= iphoneSEUiKitWidth ? true : false
}

export const IOS_BOTTOM_HEIGHT = 29
export const BOTTOM_WITHOUT_KEYBOARD =
  Platform.OS === 'android' ? 0 : IOS_BOTTOM_HEIGHT

export const webNonSmall =
  isWeb && Dimensions.get('window').width > BreakPoint.S
