import React, { useEffect, useState } from 'react'
import BackgroundContainer, {
  BackgroundContainerProps,
} from 'ui/container/BackgroundContainer'
import {
  questionPhase,
  questionPhaseCount,
  questionsRoutes,
  QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS,
  QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS,
  QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND,
  QUESTION_INVESTMENT_GOAL,
  QUESTION_MONTHLY_CASH_DEPOSITS,
  QUESTION_MONTHLY_CASH_WITHDRAWALS,
  QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND,
  QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS,
  QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND,
} from '@taaleri/components/src/screens/questions/questions'
import { useLocation } from 'react-router-dom'
import ProgressIndicator from './ProgressIndicator'
import Spacings from '@taaleri/core/src/constants/Spacings'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import ContentContainer from 'ui/container/ContentContainer'
import { View, StyleSheet } from 'react-native'
import { TextBold } from '@taaleri/components/src/ui/text/UiText'
import {
  onboardingPhase,
  onboardingPhaseCount,
  ROUTE_ONBOARDING_REGISTER,
  ROUTE_ONBOARDING_CUSTOMER_FORM,
  ROUTE_ONBOARDING_REQULATORY_QUESTIONS,
  ROUTE_ONBOARDING_ACCOUNT_NUMBER,
  ROUTE_ONBOARDING_CONTRACTS,
  ROUTE_ONBOARDING_CONTRACTS_VERIFICATION,
  ROUTE_ONBOARDING_CREATING_CUSTOMER,
  ROUTE_ONBOARDING_PHOTO_ID,
  ROUTE_ONBOARDING_IDENTITY_VALIDITY,
  ROUTE_ONBOARDING_PAYMENT_SUM,
  ROUTE_ONBOARDING_PAYMENT_BANK,
} from '@taaleri/components/src/screens/onboarding/OnboardingRoutes'
import useLayout from '@taaleri/components/src/ui/useLayout'
import {
  ROUTE_QUESTION_INVESTMENT_PROPOSAL,
  ROUTE_QUESTION_AGE,
  ROUTE_QUESTION_AMOUNT,
  ROUTE_QUESTION_PORTFOLIO_TYPE,
  ROUTE_QUESTION_FIRST_PROPOSAL,
  ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER,
  ROUTE_QUESTION_CASH_PURPOSE,
} from '@taaleri/components/src/screens/questions/QuestionRoutes'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

type Props = BackgroundContainerProps

const ContainerOnboarding: React.FC = ({ children, ...props }) => (
  <BackgroundContainer {...props} contentType="centered">
    {children}
  </BackgroundContainer>
)

const ContainerContract: React.FC = ({ children, ...props }) => (
  <BackgroundContainer
    {...props}
    contentType="centered-top"
    hideLogo={true}
    noPadding={true}
  >
    <ContentContainer {...props}>{children}</ContentContainer>
  </BackgroundContainer>
)

const QuestionContainer: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { noPadding } = props
  const { isNonSmall } = useLayout()
  const [phase, setPhase] = useState<number>(0)
  const location = useLocation()
  const routeName = getRouteName()
  useEffect(() => {
    const questionPhaseCurrent = questionPhase(routeName)

    const phaseCurrent =
      questionPhaseCurrent > 0
        ? questionPhaseCurrent
        : onboardingPhase(routeName)
    setPhase(phaseCurrent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  function getRouteName() {
    if (location.pathname.includes('QuestionInvestmentProposalShow')) {
      return ROUTE_QUESTION_INVESTMENT_PROPOSAL
    }
    return location.pathname.substr(
      QuestionStore.questionMode === 'onboarding' ? 11 : 14
    )
  }

  const isQuestionPhase = questionsRoutes().indexOf(routeName) !== -1

  const isOnboarding = QuestionStore.questionMode === 'onboarding'

  const Container = isOnboarding ? ContainerOnboarding : ContainerContract

  const phaseCount = isQuestionPhase
    ? questionPhaseCount()
    : onboardingPhaseCount()

  return (
    <Container {...props}>
      <View style={styles.paddingTop} />
      {phase > 0 && (
        <View
          style={[
            noPadding && styles.paddingSm,
            noPadding && isNonSmall && styles.paddingNonSmall,
          ]}
        >
          <View style={styles.heading}>
            <TextBold>{questionOnboardingHeading(routeName, t)}</TextBold>
          </View>
          <ProgressIndicator phase={phase} count={phaseCount} />

          <View style={{ marginBottom: Spacings.S16 }} />
        </View>
      )}

      {props.children}
    </Container>
  )
}

export default QuestionContainer

const styles = StyleSheet.create({
  heading: {
    alignItems: 'center',
    marginVertical: Spacings.S16,
  },
  paddingTop: {
    paddingTop: Spacings.S24,
  },
  paddingNonSmall: {
    paddingBottom: Spacings.S24,
  },
  paddingSm: {
    paddingHorizontal: Spacings.S24,
  },
})

function questionOnboardingHeading(currentRoute: string, t: TFunction) {
  if (currentRoute === ROUTE_ONBOARDING_REGISTER) {
    return t('app.questions.onboardingregister.onboardingregisterheader')
  }
  if (currentRoute === ROUTE_ONBOARDING_CUSTOMER_FORM) {
    return t('questions.onboardingCustomerForm.header')
  }
  if (
    currentRoute === QUESTION_HAS_MONTHLY_MONEY_TRANSFER_FINLAND ||
    currentRoute === QUESTION_MONTHLY_INCOMING_MONEY_WITHIN_FINLAND ||
    currentRoute === QUESTION_MONTHLY_OUTGOING_MONEY_WITHIN_FINLAND ||
    currentRoute === QUESTION_HAS_MONTHLY_FOREIGN_MONEY_TRANSFERS ||
    currentRoute === QUESTION_MONTHLY_INCOMING_FOREIGN_MONEY_TRANSFERS ||
    currentRoute === QUESTION_MONTHLY_OUTGOING_FOREIGN_MONEY_TRANSFERS ||
    currentRoute === ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER ||
    currentRoute === QUESTION_HAS_MONTHLY_CASH_TRANSACTIONS ||
    currentRoute === QUESTION_MONTHLY_CASH_DEPOSITS ||
    currentRoute === QUESTION_MONTHLY_CASH_WITHDRAWALS ||
    currentRoute === ROUTE_QUESTION_CASH_PURPOSE
  ) {
    return t('questions.money-transaction-title')
  }
  if (currentRoute === ROUTE_ONBOARDING_REQULATORY_QUESTIONS) {
    return `${t('questions.onboardingRegulatoryForm.title')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_ACCOUNT_NUMBER) {
    return `${t('questions.accountNumber.title')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_CONTRACTS) {
    return `${t('questions.accountCreation.check-contract')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_CONTRACTS_VERIFICATION) {
    return `${t('questions.accountCreation.sign-contract')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_CREATING_CUSTOMER) {
    return `${t('questions.accountCreation.creating-customership')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_PHOTO_ID) {
    return t('idUpdate.id')
  }
  if (currentRoute === ROUTE_ONBOARDING_IDENTITY_VALIDITY) {
    return t('idUpdate.id')
  }
  if (currentRoute === ROUTE_ONBOARDING_PAYMENT_SUM) {
    return `${t('steps.first-investment')}`
  }
  if (currentRoute === ROUTE_ONBOARDING_PAYMENT_BANK) {
    return `${t('steps.first-investment')}`
  }
  if (
    QuestionStore.questionMode === 'onboarding' &&
    [
      ROUTE_QUESTION_AGE,
      ROUTE_QUESTION_AMOUNT,
      QUESTION_INVESTMENT_GOAL,
      ROUTE_QUESTION_PORTFOLIO_TYPE,
      ROUTE_QUESTION_FIRST_PROPOSAL,
    ].includes(currentRoute)
  ) {
    return t('questions.step1-title')
  }

  return QuestionStore.questionMode === 'contract'
    ? t('basicInfoUpdate.title')
    : t('profile.investmentSection.invest-plan')
}
