import Colors from '@taaleri/core/src/constants/Colors'
import { fiKey } from '@taaleri/core/src/i18n'
import {
  TransactionReport,
  Transaction,
} from '@taaleri/core/src/models/TransactionReport'
import { getTransactionReport } from '@taaleri/core/src/services/reports'
import AppStore from '@taaleri/core/src/stores/AppStore'
import {
  formatDateWithoutLeadingZeros,
  formatDateFull,
  formatCurrency,
} from '@taaleri/core/src/utils/format'
import { TFunction } from 'i18next'
import take from 'lodash/take'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ReportBoxLarge from './ReportBoxLarge'
import { ReportLoadingBox } from './ReportLoadingBox'
import { ROUTE_REPORT_TRANSACTION } from './ReportRoutes'
import { ReportRowContainer } from './ReportRow'
import ReportRowSmall from './ReportRowSmall'
import useNavigation from '../../navigation/useNavigation'
import ErrorWithRetry from '../../ui/ErrorWithRetry'
import { IconTransaction, IconCashTransaction } from '../../ui/Icons'
import { TextDefault } from '../../ui/text/UiText'

function getLabel(transaction: Transaction, t: TFunction): string {
  const category = transaction.transactionSource
  const getName = (): string =>
    AppStore.currentLanguage === fiKey
      ? transaction.securityNameFinnish
      : transaction.securityNameSwedish

  const getPortfolioName = (): string | undefined =>
    AppStore.currentLanguage === fiKey
      ? transaction.portfolio.portfolioNameForCustomer
      : transaction.portfolio.portfolioNameForCustomerSv

  const getReason = (): string | undefined =>
    AppStore.currentLanguage === fiKey
      ? transaction.reason
      : transaction.reasonSwedish

  const getTransactionTypeName = (): string | undefined =>
    AppStore.currentLanguage === fiKey
      ? transaction.transactionTypeName
      : transaction.transactionTypeNameSwedish

  switch (category) {
    case 'Cash':
      return getReason() + ': ' + formatCurrency(transaction.amount)
    case 'Paid':
      return getTransactionTypeName() + ': ' + getName()
    case 'Open':
      return getTransactionTypeName() + ` (${t('report.open')}): ` + getName()
    default:
      return getPortfolioName() || ''
  }
}

function getIcon(transaction: Transaction): JSX.Element {
  const category = transaction.transactionSource
  return category === 'Paid' ? (
    <IconTransaction />
  ) : category === 'Cash' ? (
    <IconCashTransaction />
  ) : (
    <IconTransaction color={Colors.gray50} />
  )
}

function ReportTransactionBox() {
  const [transactionReport, setTransactionReport] =
    useState<TransactionReport>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { navigateApp } = useNavigation()
  const { t } = useTranslation()

  async function getTransactions() {
    setError(false)
    setLoading(true)
    // Use January 1st 2019 as start date in test environment
    const result = await getTransactionReport(AppStore.customerId, 'Latest')
    if (result.success) {
      setTransactionReport(result.response)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    getTransactions()
  }, [AppStore.customerId])

  if (loading || !transactionReport) {
    return <ReportLoadingBox />
  }

  if (error) {
    return <ErrorWithRetry onRetry={getTransactions} />
  }

  const noTransactons = transactionReport.transactions.length === 0

  return (
    <ReportBoxLarge
      title={t('reportBox.events')}
      subtitle={`${t('reportBox.from')} ${formatDateWithoutLeadingZeros(
        transactionReport?.startDate
      )}-${formatDateWithoutLeadingZeros(transactionReport?.endDate)} `}
      onButtonPress={() => navigateApp(ROUTE_REPORT_TRANSACTION)}
      buttonTitle={`${t('reportBox.show-all-events')}`}
    >
      {noTransactons && (
        <ReportRowContainer topBorder={true}>
          <TextDefault type="t2">{t('reportBox.no-events')}</TextDefault>
        </ReportRowContainer>
      )}
      {take(transactionReport.transactions, 4).map((transaction, index) => (
        <ReportRowSmall
          label={getLabel(transaction, t)}
          value={formatDateFull(transaction.transactionDate)}
          topBorder={index === 0}
          icon={getIcon(transaction)}
          key={transaction.transactionId}
        />
      ))}
    </ReportBoxLarge>
  )
}

export default observer(ReportTransactionBox)
