import { Feed } from '../models/Feed'
import { api } from '../api/api'
import { handleErrors } from './handleErrors'
import { getIdentity } from './storage'
import { formatDateDash, getDateYearAgo } from '@taaleri/core/src/utils/format'
import AppStore from '../stores/AppStore'
import Colors from '../constants/Colors'

export async function getFeed(
  customerId: number,
  page: number = 1,
  startDate: Date = getDateYearAgo()
): Promise<Feed[]> {
  const { accessToken } = await getIdentity()
  const formattedStartDate = formatDateDash(startDate)

  try {
    const feed = await api().newsfeed.feed(
      accessToken,
      customerId,
      page,
      formattedStartDate,
      AppStore.updateFeedCache
    )
    AppStore.updateFeedCache = false

    if (AppStore.warningNotificationData.ongoing) {
      const warning: Feed = {
        itemType: 'WarningNotification',
        itemSubType: 'Muu',
        date: '',
        portfolioManagementTransactionItems: [],
        cashTransactionItems: [],
        securityTransactionItems: [],
        newsItems: [],
        warningNotification: AppStore.warningNotificationData,
      }
      feed.unshift(warning)
    }

    return feed
  } catch (e) {
    handleErrors(e, 'getMessages', 'error', true)
    throw e
  }
}

export const NewsFeedItemHtmlDecorationForMobile = (htmlContent: string) => {
  return `<html>
            <head>
              <style>
                @font-face {
                  font-family: "Calibre";
                  src: url(https://fonts.cdnfonts.com/css/calibre-2) format("truetype");
                }
                body {
                  background-color:${Colors.backgroundGray} !important; 
                  font-size:40px; 
                  font-family: 'Calibre', sans-serif;
                  height: 100%;
                }
                p {
                  background-color:${Colors.backgroundGray} !important; 
                  font-family: 'Calibre', sans-serif;
                }
              </style>
            </head>
            <body>
              ${htmlContent
                .replaceAll('<a ', '<a target=_blank ')
                .replaceAll(
                  '<img src="',
                  '<img width="950" src="https://www.aktia.fi'
                )}
            </body>
          </html>`
}
