import React from 'react'
import Colors from '@taaleri/core/src/constants/Colors'
import styled from 'styled-components/native'

const PositionContainer = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-top-width: 1px solid ${Colors.border};
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`

export default PositionContainer
