import React, { useState, useEffect } from 'react'
import { Animated, Easing } from 'react-native'
import { PropsChildrenWithStyle } from '../../components/PropsChildren'

export default function SlideDown({ style, children }: PropsChildrenWithStyle) {
  const [translateY] = useState<Animated.Value>(new Animated.Value(-25))
  const [fadeAnim] = useState<Animated.Value>(new Animated.Value(0))

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: 0,
      duration: 600,
      easing: Easing.bezier(0.0, 0.0, 0.25, 1.0),
      useNativeDriver: true,
    }).start()

    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.bezier(0.0, 0.0, 0.25, 1.0),
      useNativeDriver: true,
    }).start()
  }, [])

  return (
    <Animated.View
      style={[
        {
          opacity: fadeAnim,
          transform: [{ translateY }],
        },
        style,
      ]}
    >
      {children}
    </Animated.View>
  )
}
