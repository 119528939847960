import { selectedPortfolioDistribution } from './selectedPortfolioDistrubution'
import {
  getRevenueTimeseries,
  getInvestedCapital,
  getFinalBalance,
} from '../RevenueCalculator'
import { InvestmentProposal } from '@taaleri/core/src/models/investmentProposal'

export function toFinanceChartProps(
  investmentProposal: InvestmentProposal,
  initialInvestment: number,
  monthlyInvestment: number,
  age: number,
  investmentTime: number,
  endAge: number,
  riskLevel: number
) {
  const selectedDistrubution = selectedPortfolioDistribution(
    investmentProposal,
    riskLevel
  )

  const revenueTimeseries = getRevenueTimeseries(
    selectedDistrubution.expectedReturn,
    initialInvestment,
    monthlyInvestment,
    age,
    investmentTime
  )
  const investedCapital = getInvestedCapital(
    initialInvestment,
    monthlyInvestment,
    investmentTime
  )
  const finalBalance = getFinalBalance(revenueTimeseries, endAge)
  const revenue = finalBalance - investedCapital

  const chartProps = {
    data: revenueTimeseries,
    standardDeviation: selectedDistrubution.standardDeviation,
    investedCapital,
    revenue,
    finalBalance,
  }

  return {
    chartProps,
    revenueTimeseries,
  }
}
