import React from 'react'
import QuestionContainer from './QuestionContainer'
import { nextQuestionRoute } from '@taaleri/components/src/screens/questions/questions'
import { ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER } from '@taaleri/components/src/screens/questions/QuestionRoutes'
import QuestionStore from '@taaleri/core/src/stores/QuestionStore'
import { observer } from 'mobx-react'
import Buttons from 'ui/Buttons'
import TopBottomContainer from 'ui/container/TopBottomContainer'
import { Formik } from 'formik'

import QuestionForeignMoneyTransferContent, {
  ForeignMoneyTransfer,
  questionForeignMoneyTransferValidationSchema,
  toInitValue,
  toSaveValue,
} from '@taaleri/components/src/screens/questions/QuestionForeignMoneyTransferContent'
import { useHistory } from 'react-router-dom'
import UiForm from '@taaleri/components/src/ui/form/UiForm'
import { PurposeOfForeignMoneyTransfers } from '@taaleri/core/src/constants/Options'
import { useTranslation } from 'react-i18next'

function QuestionForeignMoneyTransferScreen() {
  const { t } = useTranslation()
  const history = useHistory()
  const monthlyForeignMoneyTransfersCountry = toInitValue(
    QuestionStore.monthlyForeignMoneyTransfersCountry
  )
  const purposeOfForeignMoneyTransfers = toInitValue(
    QuestionStore.purposeOfForeignMoneyTransfers
  )
  const purposeOfForeignMoneyTransfersOther = toInitValue(
    QuestionStore.purposeOfForeignMoneyTransfersOther
  )
  return (
    <QuestionContainer>
      <TopBottomContainer>
        <Formik
          initialValues={{
            monthlyForeignMoneyTransfersCountry,
            purposeOfForeignMoneyTransfers,
            purposeOfForeignMoneyTransfersOther,
          }}
          validationSchema={questionForeignMoneyTransferValidationSchema}
          onSubmit={async (values: ForeignMoneyTransfer) => {
            QuestionStore.monthlyForeignMoneyTransfersCountry = toSaveValue(
              values.monthlyForeignMoneyTransfersCountry
            )
            QuestionStore.purposeOfForeignMoneyTransfers = toSaveValue(
              values.purposeOfForeignMoneyTransfers
            )
            QuestionStore.purposeOfForeignMoneyTransfersOther =
              values.purposeOfForeignMoneyTransfers.some(
                (p) => p === PurposeOfForeignMoneyTransfers.Other
              )
                ? values.purposeOfForeignMoneyTransfersOther
                : ''
            history.push(
              nextQuestionRoute(ROUTE_QUESTION_FOREIGN_MONEY_TRANSFER)
            )
          }}
        >
          {({ values }) => {
            return (
              <UiForm>
                <QuestionForeignMoneyTransferContent values={values} />
                <Buttons title={t('button.next')} />
              </UiForm>
            )
          }}
        </Formik>
      </TopBottomContainer>
    </QuestionContainer>
  )
}

export default observer(QuestionForeignMoneyTransferScreen)
