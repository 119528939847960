import React from 'react'
import { View } from 'react-native'
import { TextBold } from '../../ui/text/UiText'
import Spacings from '@taaleri/core/src/constants/Spacings'
import CheckRow from '../../ui/CheckRow'
import Colors from '@taaleri/core/src/constants/Colors'
import { FontSize } from '@taaleri/core/src/constants/Fonts'
import { useTranslation } from 'react-i18next'

export default function PhotoIdHelp() {
  const { t } = useTranslation()
  return (
    <>
      <View
        style={{
          paddingBottom: Spacings.S8,
          borderBottomColor: Colors.border,
          borderBottomWidth: 1,
        }}
      >
        <TextBold
          style={{
            fontSize: FontSize.S12,
            lineHeight: 18,
          }}
        >
          {t('idUpdate.id-help-title')}
        </TextBold>
      </View>
      <CheckRow text={t('idUpdate.id-help-clear')} />
      <CheckRow text={t('idUpdate.id-help-inside')} />
      <CheckRow text={t('idUpdate.id-help-readable')} />
    </>
  )
}
