import React from 'react'
import Spacings from '@taaleri/core/src/constants/Spacings'

import Box from '../../ui/box/Box'

import useLayout from '../../ui/useLayout'
import { View } from 'react-native'
import { getAssetClassImpactImageById } from '@taaleri/core/src/constants/AssetClass'
import { ImpactOwnershipRow } from './ImpactOwnershipRow'
import ImpactAssetDetailBox from './ImpactAssetDetailBox'
import { AssetClassSecurities } from '@taaleri/core/src/models/investmentProposal'
import { useTranslation } from 'react-i18next'

interface Props {
  assetClass: AssetClassSecurities
  assetClasses: AssetClassSecurities[]
}

function ImpactAssetDetail(props: Props) {
  const { assetClass, assetClasses } = props
  const { screenWidth, isSmall } = useLayout()
  const image = getAssetClassImpactImageById(assetClass.id)
  const weights = assetClasses.map((s) => s.percentage)
  const { t } = useTranslation()
  return (
    <View key={assetClass.id} style={{ marginBottom: Spacings.S32 }}>
      <ImpactAssetDetailBox
        image={image}
        weights={weights}
        weight={assetClass.percentage}
        title={t(assetClass.name)}
      />

      <Box style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        {assetClass.securities
          .filter((s) => s.allocation.securityId !== 'MONEY')
          .map((assetClassItem, index) => {
            const topBorderRadius = !isSmall && index === 0
            return (
              <ImpactOwnershipRow
                linked={true}
                borderBottom={true}
                key={index}
                assetClassItem={assetClassItem}
                screenWidth={screenWidth}
                topBorderRadius={topBorderRadius}
                bottomBorderRadius={
                  isSmall && index === assetClass.securities.length - 1
                }
              />
            )
          })}
      </Box>
    </View>
  )
}

export default ImpactAssetDetail
