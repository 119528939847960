import { observer } from 'mobx-react'
import React from 'react'
import HeaderLarge from './HeaderLarge'
import HeaderSmall from './HeaderSmall'

function Header() {
  return (
    <>
      <HeaderLarge />
      <HeaderSmall />
    </>
  )
}

export default observer(Header)
