import { IconWarning } from '@taaleri/components/src/ui/Icons'
import TabContainer, {
  TabDetails,
} from '@taaleri/components/src/ui/TabContainer'
import UiButton from '@taaleri/components/src/ui/UiButton'
import UiLink from '@taaleri/components/src/ui/UiLink'
import { Paragraph } from '@taaleri/components/src/ui/text/Paragraph'
import Title from '@taaleri/components/src/ui/text/Title'
import { getConfig } from '@taaleri/core/src/config'
import Colors from '@taaleri/core/src/constants/Colors'
import Spacings from '@taaleri/core/src/constants/Spacings'
import { isDev, isLocal, isStg } from '@taaleri/core/src/environment'
import AdminStore from '@taaleri/core/src/stores/AdminStore'
import { observer } from 'mobx-react-lite'
import useQueryParams from 'navigation/useQueryParams'
import React from 'react'
import { View } from 'react-native'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AdminLayout } from './AdminLayout'
import SearchCustomerForm from './SearchCustomerForm'
import TargetMessaging from './TargetMessagingForm'

const adminLinks = [
  {
    title: 'Pankkiirin Työpöytä',
    url: isDev
      ? 'https://apitest.aktia.biz/jumpstart/'
      : isStg
      ? 'https://apistg.aktia.biz/jumpstart'
      : 'https://pt.aktia.fi/',
  },
  {
    title: 'CRM',
    url: isDev
      ? 'http://crmapptest:5555/CRM15TEMP'
      : isStg
      ? 'http://crmapptest:5555/TaaleriTest/'
      : 'http://fund01crmapp:5555/Taaleri/',
  },
  {
    title: 'Myyjän työpöytä',
    url: isDev
      ? 'https://int-myty.aktia.biz/salesdesk/fi-FI/'
      : isStg
      ? 'https://sys-myty.aktia.biz/salesdesk/fi-FI/'
      : 'https://myty.aktia.biz/salesdesk/fi-FI/',
  },
  {
    title: 'Wealth Investment Reports',
    url:
      isDev || isStg
        ? 'https://prodtest-wealthreporting.aktia.biz/investmentreports/Customer'
        : 'https://wealthreporting.aktia.biz/investmentreports/Customer',
  },
]

function AdminScreen() {
  const { token, error, user } = useQueryParams()
  const { customerId } = useParams<{ customerId: string }>()

  if (customerId) {
    AdminStore.customerId = customerId
  }

  if (isLocal) {
    return <AdminForm />
  }

  if (error && error === 'unauthorized') {
    AdminStore.logoutAdmin()
    return <Unauthorized />
  }

  if (token) {
    AdminStore.token = token as string
    if (user) {
      AdminStore.adminSurname = user as string
    }
    return <AdminForm />
  } else {
    return <AdminLogin />
  }
}

function AdminLogin() {
  window.location.href = getConfig().apiUrl + '/authentication/aad'
  return null
}

function AdminForm() {
  const customerContent = () => {
    return (
      <View style={{ marginTop: 20 }}>
        <SearchCustomerForm />
      </View>
    )
  }

  const messageContent = () => {
    return (
      <View style={{ marginTop: 20 }}>
        <TargetMessaging />
      </View>
    )
  }

  const linkContent = () => {
    return (
      <View style={{ marginTop: 20 }}>
        {adminLinks.map((al, alIndex) => {
          return (
            <View key={`adminlinks-${alIndex}`}>
              <UiLink
                url={al.url}
                text={al.title}
                target="_blank"
                style={{ marginBottom: 10 }}
              />
            </View>
          )
        })}
      </View>
    )
  }

  const tabs: TabDetails[] = [
    {
      id: 'customers',
      order: 1,
      title: 'Asiakkaat',
      content: customerContent(),
    },
    {
      id: 'messages',
      order: 2,
      title: 'Viestintä',
      content: messageContent(),
      securityGroups: [getConfig().adminGroup],
    },
    {
      id: 'links',
      order: 3,
      title: 'Linkit',
      content: linkContent(),
    },
  ]

  return (
    <AdminLayout>
      <TabContainer tabs={tabs} />
    </AdminLayout>
  )
}

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

function Unauthorized() {
  return (
    <AdminLayout>
      <UnauthorizedContainer>
        <IconWarning color={Colors.text} style={{ marginTop: 40 }} />
        <Title style={{ marginBottom: Spacings.S24 }}>Ei oikeuksia</Title>
        <Paragraph
          style={{
            maxWidth: 450,
            textAlign: 'center',
            marginBottom: Spacings.S32,
          }}
        >
          Saat sähköpostitse tiedon, kun sinulle on myönnetty oikeudet palvelun
          käyttöön.
        </Paragraph>
        <UiButton
          title="Yritä uudelleen"
          onPress={() => (window.location.href = '/admin')}
        />
      </UnauthorizedContainer>
    </AdminLayout>
  )
}

export default observer(AdminScreen)
